export const RWA = {
  name: {
    common: 'Rwanda',
    official: 'Republic of Rwanda',
    native: {
      eng: { official: 'Republic of Rwanda', common: 'Rwanda' },
      fra: { official: 'République rwandaise', common: 'Rwanda' },
      kin: { official: "Repubulika y'u Rwanda", common: 'Rwanda' },
    },
  },
  cities: ['Kigali', 'Nyarugunga'],
  tld: ['.rw'],
  cca2: 'RW',
  ccn3: '646',
  cca3: 'RWA',
  cioc: 'RWA',
  currency: ['RWF'],
  callingCode: ['250'],
  capital: 'Kigali',
  altSpellings: [
    'RW',
    'Republic of Rwanda',
    "Repubulika y'u Rwanda",
    'République du Rwanda',
  ],
  region: 'Africa',
  subregion: 'Eastern Africa',
  languages: { eng: 'English', fra: 'French', kin: 'Kinyarwanda' },
  translations: {
    deu: { official: 'Republik Ruanda', common: 'Ruanda' },
    fra: { official: 'République rwandaise', common: 'Rwanda' },
    hrv: { official: 'Republika Ruandi', common: 'Ruanda' },
    ita: { official: 'Repubblica del Ruanda', common: 'Ruanda' },
    jpn: { official: 'ルワンダ共和国', common: 'ルワンダ' },
    nld: { official: 'Republiek Rwanda', common: 'Rwanda' },
    por: { official: 'República do Ruanda', common: 'Ruanda' },
    rus: { official: 'Республика Руанда', common: 'Руанда' },
    spa: { official: 'República de Rwanda', common: 'Ruanda' },
    fin: { official: 'Ruandan tasavalta', common: 'Ruanda' },
  },
  latlng: [-2, 30],
  demonym: 'Rwandan',
  landlocked: true,
  borders: ['BDI', 'COD', 'TZA', 'UGA'],
  area: 26338,
  provinces: [
    'Butare',
    'Byumba',
    'Cyangugu',
    'Gikongoro',
    'Gisenyi',
    'Gitarama',
    'Kibungo',
    'Kibuye',
    'Kigali Rurale',
    'Kigali-ville',
    'Ruhengeri',
    'Umutara',
  ],
  nativeName: 'Rwanda',
  timezones: ['UTC+02:00'],
} as const;
