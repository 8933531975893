export const GGY = {
  name: {
    common: 'Guernsey',
    official: 'Bailiwick of Guernsey',
    native: {
      eng: { official: 'Bailiwick of Guernsey', common: 'Guernsey' },
      fra: { official: 'Bailliage de Guernesey', common: 'Guernesey' },
      nfr: { official: 'Dgèrnésiais', common: 'Dgèrnésiais' },
    },
  },
  cities: ['St Peter Port', 'Torteval', 'St. Saviour'],
  tld: ['.gg'],
  cca2: 'GG',
  ccn3: '831',
  cca3: 'GGY',
  cioc: '',
  currency: ['GBP'],
  callingCode: ['44'],
  capital: 'St. Peter Port',
  altSpellings: ['GG', 'Bailiwick of Guernsey', 'Bailliage de Guernesey'],
  region: 'Europe',
  subregion: 'Northern Europe',
  languages: { eng: 'English', fra: 'French', nfr: 'Guernésiais' },
  translations: {
    deu: { official: 'Guernsey', common: 'Guernsey' },
    fra: { official: 'Bailliage de Guernesey', common: 'Guernesey' },
    hrv: { official: 'Struka Guernsey', common: 'Guernsey' },
    ita: { official: 'Baliato di Guernsey', common: 'Guernsey' },
    jpn: { official: 'ガーンジーの得意分野', common: 'ガーンジー' },
    nld: { official: 'Baljuwschap Guernsey', common: 'Guernsey' },
    por: { official: 'Bailiado de Guernsey', common: 'Guernsey' },
    rus: { official: 'Коронное владение Гернси', common: 'Гернси' },
    spa: { official: 'Bailía de Guernsey', common: 'Guernsey' },
    fin: { official: 'Guernsey', common: 'Guernsey' },
  },
  latlng: [49.46666666, -2.58333333],
  demonym: 'Channel Islander',
  landlocked: false,
  borders: [],
  area: 78,
} as const;
