export const AFG = {
  name: {
    common: 'Afghanistan',
    official: 'Islamic Republic of Afghanistan',
    native: {
      prs: { official: 'جمهوری اسلامی افغانستان', common: 'افغانستان' },
      pus: { official: 'د افغانستان اسلامي جمهوریت', common: 'افغانستان' },
      tuk: { official: 'Owganystan Yslam Respublikasy', common: 'Owganystan' },
    },
  },
  cities: [
    'Kabul',
    'Wazir Akbar Khan',
    'Kandahar',
    'Sharif',
    'Shar',
    'Molah',
    'Rana',
    'Herat',
  ],
  provinces: [
    'Badakhshan',
    'Badghis',
    'Baghlan',
    'Balkh',
    'Bamian',
    'Farah',
    'Faryab',
    'Ghazni',
    'Ghowr',
    'Helmand',
    'Herat',
    'Jowzjan',
    'Kabol',
    'Kandahar',
    'Kapisa',
    'Konar',
    'Kondoz',
    'Laghman',
    'Lowgar',
    'Nangarhar',
    'Nimruz',
    'Oruzgan',
    'Paktia',
    'Paktika',
    'Parvan',
    'Samangan',
    'Sar-e Pol',
    'Takhar',
    'Vardak',
    'Zabol',
  ],
  nativeName: 'افغانستان',
  tld: ['.af'],
  cca2: 'AF',
  ccn3: '004',
  cca3: 'AFG',
  cioc: 'AFG',
  currency: ['AFN'],
  callingCode: ['93'],
  capital: 'Kabul',
  altSpellings: ['AF', 'Afġānistān'],
  region: 'Asia',
  subregion: 'Southern Asia',
  languages: { prs: 'Dari', pus: 'Pashto', tuk: 'Turkmen' },
  translations: {
    cym: { official: 'Islamic Republic of Afghanistan', common: 'Affganistan' },
    deu: { official: 'Islamische Republik Afghanistan', common: 'Afghanistan' },
    fra: {
      official: "République islamique d'Afghanistan",
      common: 'Afghanistan',
    },
    hrv: { official: 'Islamska Republika Afganistan', common: 'Afganistan' },
    ita: {
      official: "Repubblica islamica dell'Afghanistan",
      common: 'Afghanistan',
    },
    jpn: {
      official: 'アフガニスタン·イスラム共和国',
      common: 'アフガニスタン',
    },
    nld: {
      official: 'Islamitische Republiek Afghanistan',
      common: 'Afghanistan',
    },
    por: {
      official: 'República Islâmica do Afeganistão',
      common: 'Afeganistão',
    },
    rus: { official: 'Исламская Республика Афганистан', common: 'Афганистан' },
    spa: { official: 'República Islámica de Afganistán', common: 'Afganistán' },
    fin: {
      official: 'Afganistanin islamilainen tasavalta',
      common: 'Afganistan',
    },
  },
  latlng: [33, 65],
  demonym: 'Afghan',
  landlocked: true,
  borders: ['IRN', 'PAK', 'TKM', 'UZB', 'TJK', 'CHN'],
  area: 652230,
  timezones: ['UTC+04:30'],
} as const;
