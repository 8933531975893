import { Module, VuexModule, Mutation, Action } from '@vuex/decorators';

import { api } from '@api';
import { PaymentMethod } from '@models';
import { Root } from '@store';
import { findById, addById, removeById } from '@utils/array';

declare module '@vuex/core' {
  export interface Getters {
    'paymentMethods/findById': PaymentMethods['findById'];
  }

  export interface CommitMap {
    'paymentMethods/SET': PaymentMethods['SET'];
    'paymentMethods/ADD': PaymentMethods['ADD'];
    'paymentMethods/REMOVE': PaymentMethods['REMOVE'];
    'paymentMethods/CLEAR': PaymentMethods['CLEAR'];
  }

  export interface DispatchMap {
    'paymentMethods/list': PaymentMethods['list'];
    'paymentMethods/listMine': PaymentMethods['listMine'];
    'paymentMethods/get': PaymentMethods['get'];
    'paymentMethods/create': PaymentMethods['create'];
    'paymentMethods/update': PaymentMethods['update'];
    'paymentMethods/del': PaymentMethods['del'];
  }
}

@Module({ namespaced: true })
export class PaymentMethods
  extends VuexModule<PaymentMethods.State, Root.State>
  implements PaymentMethods.State
{
  items: PaymentMethod[] = [];

  /**
   * ...
   */
  get findById() {
    return (id: string) => findById(this.items, id);
  }

  //#region Mutations

  /**
   * ...
   */
  @Mutation
  SET(options: PaymentMethods.SetMutationOptions): void {
    this.items = options.data;
  }

  /**
   * ...
   */
  @Mutation
  ADD(options: PaymentMethods.AddMutationOptions) {
    this.items = addById(this.items, options);
  }

  /**
   * ...
   */
  @Mutation
  REMOVE(options: PaymentMethods.DeleteActionOptions) {
    this.items = removeById(this.items, options.paymentMethodId);
  }

  /**
   * ...
   */
  @Mutation
  CLEAR() {
    this.items = [];
  }

  //#endregion Mutations

  //#region Actions

  /**
   * List User Payment Methods.
   */
  @Action
  async list(userId?: Nullable<string>) {
    userId ||= this.context.rootState.me.id;

    if (!userId) {
      throw new Error('Must Provide User ID to Get Payment Methods');
    }

    const data = await api.paymentMethods.getForUser({ userId });

    this.context.commit('SET', { data });

    return this.items;
  }

  /**
   * ...
   */
  @Action
  async listMine() {
    const data = await api.paymentMethods.listMine();

    this.context.commit('SET', { data });

    return this.items;
  }

  /**
   * ...
   */
  @Action
  async get(options: PaymentMethods.GetActionOptions) {
    const data = await api.paymentMethods.get(options);

    this.context.commit('ADD', data);

    return data;
  }

  /**
   * Create Payment Method.
   */
  @Action
  async create(options: PaymentMethods.CreateActionOptions) {
    const data = await api.paymentMethods.create(options);

    this.context.commit('ADD', data);

    return data;
  }

  /**
   * Update Payment Method.
   */
  @Action
  async update(options: PaymentMethods.UpdateActionOptions) {
    await api.paymentMethods.update(options);

    return await this.context.dispatch('get', options);
  }

  /**
   * Remove Payment Method.
   */
  @Action
  async del(options: PaymentMethods.DeleteActionOptions) {
    await api.paymentMethods.del(options);

    this.context.commit('REMOVE', options);
  }

  //#endregion Actions
}

export namespace PaymentMethods {
  /**
   * ...
   */
  export interface State {
    items: PaymentMethod[];
  }

  /**
   * ...
   */
  export interface SetMutationOptions {
    data: PaymentMethod[];
  }

  /** ... */
  export type AddMutationOptions = PaymentMethod;
  /** ... */
  export type DeleteMutationOptions = api.paymentMethods.DeleteOptions;
  /** ... */
  export type GetActionOptions = api.paymentMethods.GetOptions;
  /** ... */
  export type CreateActionOptions = api.paymentMethods.CreateOptions;
  /** ... */
  export type UpdateActionOptions = api.paymentMethods.UpdateOptions;
  /** ... */
  export type DeleteActionOptions = api.paymentMethods.DeleteOptions;
}

export default PaymentMethods;
