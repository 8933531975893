var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.CardLoadable,
    _vm._b(
      { attrs: { "no-body": "" } },
      "CardLoadable",
      { loading: !!_vm.loading },
      false
    ),
    [
      _c(
        "b-card-body",
        [
          _c("b-card-title", [
            _c("div", { staticClass: "h6" }, [
              _c("span", { staticClass: "stat-card-title" }, [
                _vm._v("Flight Time"),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "small" }, [_vm._v("(hh:mm:ss)")]),
            ]),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "value-display" }, [
            _c("span", { staticClass: "h1 m-0" }, [
              _vm._v(_vm._s(_setup.flightTime)),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }