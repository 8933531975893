import Vue from 'vue';

import { isFunction } from '@tools/type-guards';

declare global {
  interface HTMLElement {
    /** ... */
    _vue_clickaway_handler?: (event: MouseEvent) => unknown;
  }
}

/** ... */
type Element = Parameters<Vue.DirectiveFunction>[0];
/** ... */
type Binding = Parameters<Vue.DirectiveFunction>[1];
/** ... */
type VNode = Parameters<Vue.DirectiveFunction>[2];

/**
 * Directive `bind` function.
 */
function bind(el: Element, binding: Binding, vnode: VNode) {
  // If the source element has a `_vue_clickaway_handler` function, remove it
  // and it's corresponding event listener from the node and page, respectively.
  unbind(el);

  const vm = vnode.context;
  const callback: unknown = binding.value;

  // If the binding value is not a function, produce a warning and abort
  // the bind.
  if (!isFunction(callback)) return invalidParameterWarning(binding);

  let initialMacrotaskEnded = false;

  setTimeout(() => {
    initialMacrotaskEnded = true;
  });

  // Global `document` "onclick" handler that will ultimatly call the provided
  // callback function if the source element and any of it's children where NOT
  // the target of the resulting event.
  const handler = (event: MouseEvent) => {
    // Don't continue if "tick" after the directive has been bound has not been
    // reached yet.
    if (!initialMacrotaskEnded) return;

    // Don't continue if the event target is not a DOM `Node`.
    if (!isNode(event.target)) return;

    // Attempt to fetch the event target path array.
    const path = 'composedPath' in event ? event.composedPath() : null;

    // If an event target path was retrieved, check to see if it contains the
    // source element. If either are `false`, fall back to checking if the
    // source element contains the event target element using the `contains`
    // method.
    if ((path && !path.includes(el)) || !el.contains(event.target)) {
      return callback.call(vm, event);
    }
  };

  // Add the handler to the source node so it can later be used to remove
  // the event listner should the directive be unbound.
  el._vue_clickaway_handler = handler;

  document.documentElement.addEventListener('click', handler, false);
}

/**
 * Directive `unbind` function.
 */
function unbind(el: Element) {
  // ...
  if (!el._vue_clickaway_handler) return;

  document.documentElement.removeEventListener(
    'click',
    el._vue_clickaway_handler,
    false,
  );

  delete el._vue_clickaway_handler;
}

/**
 * Directive `update` function.
 */
function update(el: Element, binding: Binding, vnode: VNode) {
  if (binding.value !== binding.oldValue) bind(el, binding, vnode);
}

/**
 * `onClickaway` directive options.
 */
export const onClickaway: Vue.DirectiveOptions = { bind, unbind, update };

export default onClickaway;

// region Helper Functions

/**
 * Determine if a value is an instance of a DOM `Node`.
 *
 * @param value The value to check.
 * @return `true` if the value is a `Node`, otherwise `false`.
 */
function isNode(value: unknown): value is Node {
  return value instanceof Node;
}

/**
 * ...
 */
function invalidParameterWarning({ name, expression }: Binding) {
  Vue.util.warn(`v-${name}="${expression ?? ''}" expects a function value.`);
}

// endregion Helper Functions
