export const GMB = {
  name: {
    common: 'Gambia',
    official: 'Republic of the Gambia',
    native: { eng: { official: 'Republic of the Gambia', common: 'Gambia' } },
  },
  cities: ['Banjul'],
  tld: ['.gm'],
  cca2: 'GM',
  ccn3: '270',
  cca3: 'GMB',
  cioc: 'GAM',
  currency: ['GMD'],
  callingCode: ['220'],
  capital: 'Banjul',
  altSpellings: ['GM', 'Republic of the Gambia'],
  region: 'Africa',
  subregion: 'Western Africa',
  languages: { eng: 'English' },
  translations: {
    deu: { official: 'Republik Gambia', common: 'Gambia' },
    fra: { official: 'République de Gambie', common: 'Gambie' },
    hrv: { official: 'Republika Gambija', common: 'Gambija' },
    ita: { official: 'Repubblica del Gambia', common: 'Gambia' },
    jpn: { official: 'ガンビア共和国', common: 'ガンビア' },
    nld: { official: 'Republiek Gambia', common: 'Gambia' },
    por: { official: 'República da Gâmbia', common: 'Gâmbia' },
    rus: { official: 'Республика Гамбия', common: 'Гамбия' },
    spa: { official: 'República de Gambia', common: 'Gambia' },
    fin: { official: 'Gambian tasavalta', common: 'Gambia' },
  },
  latlng: [13.46666666, -16.56666666],
  demonym: 'Gambian',
  landlocked: false,
  borders: ['SEN'],
  area: 10689,
  provinces: [
    'Banjul',
    'Central River',
    'Lower River',
    'North Bank',
    'Upper River',
    'Western',
  ],
  nativeName: 'Gambia',
} as const;
