export const BDI = {
  name: {
    common: 'Burundi',
    official: 'Republic of Burundi',
    native: {
      fra: { official: 'République du Burundi', common: 'Burundi' },
      run: { official: "Republika y'Uburundi ", common: 'Uburundi' },
    },
  },
  cities: ['Bujumbura'],
  tld: ['.bi'],
  cca2: 'BI',
  ccn3: '108',
  cca3: 'BDI',
  cioc: 'BDI',
  currency: ['BIF'],
  callingCode: ['257'],
  capital: 'Bujumbura',
  altSpellings: [
    'BI',
    'Republic of Burundi',
    "Republika y'Uburundi",
    'République du Burundi',
  ],
  region: 'Africa',
  subregion: 'Eastern Africa',
  languages: { fra: 'French', run: 'Kirundi' },
  translations: {
    cym: { official: 'Republic of Burundi', common: 'Bwrwndi' },
    deu: { official: 'Republik Burundi', common: 'Burundi' },
    fra: { official: 'République du Burundi', common: 'Burundi' },
    hrv: { official: 'Burundi', common: 'Burundi' },
    ita: { official: 'Repubblica del Burundi', common: 'Burundi' },
    jpn: { official: 'ブルンジ共和国', common: 'ブルンジ' },
    nld: { official: 'Republiek Burundi', common: 'Burundi' },
    por: { official: 'República do Burundi', common: 'Burundi' },
    rus: { official: 'Республика Бурунди', common: 'Бурунди' },
    spa: { official: 'República de Burundi', common: 'Burundi' },
    fin: { official: 'Burundin tasavalta', common: 'Burundi' },
  },
  latlng: [-3.5, 30],
  demonym: 'Burundian',
  landlocked: true,
  borders: ['COD', 'RWA', 'TZA'],
  area: 27834,
  provinces: [
    'Bubanza',
    'Bujumbura',
    'Bururi',
    'Cankuzo',
    'Cibitoke',
    'Gitega',
    'Karuzi',
    'Kayanza',
    'Kirundo',
    'Makamba',
    'Muramvya',
    'Muyinga',
    'Mwaro',
    'Ngozi',
    'Rutana',
    'Ruyigi',
  ],
  nativeName: 'Burundi',
  timezones: ['UTC+02:00'],
} as const;
