var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "display-value-text my-2" }, [
    _c("label", { staticClass: "h6 m-0 mb-2" }, [_vm._v(_vm._s(_vm.label))]),
    _vm._v(" "),
    _c("pre", [_vm._v(_vm._s(_vm.value))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }