/**
 * ...
 */
interface DataSet {
  data: number[];
}

/**
 * ...
 */
export interface DataPoint {
  createdAt: string | Date;
  updatedAt: string | Date;
  // status: number;
  // status: number | string;
  // amount: number;
  // total: number;
}

/**
 * ...
 */
export interface TransactionDataPoint extends DataPoint {
  status: string;
  total: number;
}

/**
 * ...
 */
export interface SchoolRevenueDataPoint extends DataPoint {
  status: number;
  amount: number;
}

/**
 * ...
 */
interface ParseAnalyticDataBaseOptions {
  from: Date;
  to: Date;
  // items: DataPoint[];
}

/**
 * ...
 */
export interface ParseTransactionAnalyticData
  extends ParseAnalyticDataBaseOptions {
  type: 'transactions';
  items: TransactionDataPoint[];
}

/**
 * ...
 */
export interface ParseSchoolRevenueAnalyticData
  extends ParseAnalyticDataBaseOptions {
  type: 'school-revenue';
  items: SchoolRevenueDataPoint[];
}

/**
 * ...
 */
// export interface ParseAnalyticDataOptions {
//   type: 'transactions' | 'school-revenue';
//   from: Date;
//   to: Date;
//   items: DataPoint[];
// }

export type ParseAnalyticDataOptions =
  | ParseTransactionAnalyticData
  | ParseSchoolRevenueAnalyticData;

/**
 * ...
 */
export interface ParseAnalyticDataResults {
  datasets: DataSet[];
  labels: string[];
  series: string[];
}

/**
 * ...
 *
 * @param options ...
 * @return ...
 */
export function parseAnalyticData(options: ParseAnalyticDataOptions) {
  const from = options.from;
  const to = options.to;

  const fromYear = from.getFullYear();
  const toYear = to.getFullYear();
  let yearDiff = toYear - fromYear;

  const fromMonth = from.getMonth();
  const toMonth = to.getMonth();
  let monthDiff = 0;

  if (fromMonth < toMonth) {
    monthDiff = toMonth - fromMonth;
  } else if (toMonth < fromMonth && yearDiff > 0) {
    yearDiff--;
    monthDiff = 11 - fromMonth;
    monthDiff = monthDiff + toMonth;
  }

  const monthRange = yearDiff * 12 + monthDiff;
  let monthYear = `${fromMonth + 1}/${fromYear}`;

  const labels: string[] = [];
  const datasets: DataSet[] = [{ data: [] }];

  const dataSet: DataSet = { data: [] };

  // Create the label range.
  for (let i = 0; i <= monthRange; i++) {
    labels.push(monthYear);
    // data[0].push(0);
    dataSet.data.push(0);

    const my = monthYear.split('/');
    let m = parseInt(my[0] as string);
    let y = parseInt(my[1] as string);

    if (monthDiff + 1 > 12) {
      y = y + 1;
      m = 1;
    } else {
      m = m + 1;
    }

    monthYear = `${m}/${y}`;
  }

  const series =
    options.type === 'transactions'
      ? ['Transactions']
      : ['Institutional Revenue'];

  for (const item of options.items) {
    if (options.type === 'school-revenue' && item.status !== 1) continue;

    let date;
    let value;

    if (options.type === 'transactions') {
      date = item.createdAt;
      value = (item as TransactionDataPoint).total;
    } else {
      date = item.updatedAt;
      value = (item as SchoolRevenueDataPoint).amount;
    }

    const dateLabel = createDateLabel(new Date(date));

    labels.forEach((label, j) => {
      if (label !== dateLabel) return;

      // If it's empty or zero, set it to zero.
      dataSet.data[j] = dataSet.data[j] ?? 0;

      // Increment by total amount.
      dataSet.data[j] += value;
    });
  }

  return { datasets, labels, series } as ParseAnalyticDataResults;
}

export namespace parseAnalyticData {
  export type Options = ParseAnalyticDataOptions;
  export type Results = ParseAnalyticDataResults;
}

/**
 * ...
 *
 * @param date ...
 * @return ...
 */
function createDateLabel(date: Date) {
  return `${date.getMonth() + 1}/${date.getFullYear()}`;
}
