var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { ref: "el", staticClass: "scroll-trigger" },
    [_vm._t("default", null, null, { triggered: _setup.triggered })],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }