export const CCK = {
  name: {
    common: 'Cocos (Keeling) Islands',
    official: 'Territory of the Cocos (Keeling) Islands',
    native: {
      eng: {
        official: 'Territory of the Cocos (Keeling) Islands',
        common: 'Cocos (Keeling) Islands',
      },
    },
  },
  cities: ['West Island'],
  tld: ['.cc'],
  cca2: 'CC',
  ccn3: '166',
  cca3: 'CCK',
  cioc: '',
  currency: ['AUD'],
  callingCode: ['61'],
  capital: 'West Island',
  altSpellings: [
    'CC',
    'Territory of the Cocos (Keeling) Islands',
    'Keeling Islands',
  ],
  region: 'Oceania',
  subregion: 'Australia and New Zealand',
  languages: { eng: 'English' },
  translations: {
    cym: {
      official: 'Territory of the Cocos (Keeling) Islands',
      common: 'Ynysoedd Cocos',
    },
    deu: {
      official: 'Gebiet der Cocos (Keeling) Islands',
      common: 'Kokosinseln',
    },
    fra: {
      official: 'Territoire des îles Cocos (Keeling)',
      common: 'Îles Cocos',
    },
    hrv: {
      official: 'Teritoriju Kokosovi (Keeling) Islands',
      common: 'Kokosovi Otoci',
    },
    ita: {
      official: 'Territorio della (Keeling) Isole Cocos',
      common: 'Isole Cocos e Keeling',
    },
    jpn: { official: 'ココス諸島の領土', common: 'ココス（キーリング）諸島' },
    nld: {
      official: 'Grondgebied van de Eilanden Cocos (Keeling )',
      common: 'Cocoseilanden',
    },
    por: {
      official: 'Território dos Cocos (Keeling)',
      common: 'Ilhas Cocos (Keeling)',
    },
    rus: {
      official: 'Территория Кокосовые (Килинг) острова',
      common: 'Кокосовые острова',
    },
    spa: {
      official: 'Territorio de los (Keeling) Islas Cocos',
      common: 'Islas Cocos o Islas Keeling',
    },
    fin: { official: 'Kookossaaret', common: 'Kookossaaret' },
  },
  latlng: [-12.5, 96.83333333],
  demonym: 'Cocos Islander',
  landlocked: false,
  borders: [],
  area: 14,
} as const;
