import Vue from 'vue';

import { isBoolean, isFunction, isObject } from '@tools/type-guards';

/**
 * Determine if a value is a `Vue` component.
 *
 * @param value The value to check.
 * @return `true` if the value is a Vue component, otherwise `false`.
 */
export function isVueComponent(value: unknown): value is typeof Vue {
  // ...
  if (!isFunction(value)) {
    return (
      isObject(value) &&
      isBoolean(value['_compiled']) &&
      isFunction(value['render'])
    );
  }

  let prototype: unknown = value.prototype ?? null;

  while (isObject(prototype)) {
    if (prototype.constructor === Vue) return true;

    prototype = Object.getPrototypeOf(prototype) ?? null;
  }

  return false;
}
