/** Billing day selection options. */
export const billingDayOptions: ZephyrWeb.OptionItem<number>[] = [
  { text: '1st', value: 1 },
  { text: '2nd', value: 2 },
  { text: '3rd', value: 3 },
  { text: '4th', value: 4 },
  { text: '5th', value: 5 },
  { text: '6th', value: 6 },
  { text: '7th', value: 7 },
  { text: '8th', value: 8 },
  { text: '9th', value: 9 },
  { text: '10th', value: 10 },
  { text: '11th', value: 11 },
  { text: '12th', value: 12 },
  { text: '13th', value: 13 },
  { text: '14th', value: 14 },
  { text: '15th', value: 15 },
  { text: '16th', value: 16 },
  { text: '17th', value: 17 },
  { text: '18th', value: 18 },
  { text: '19th', value: 19 },
  { text: '20th', value: 20 },
  { text: '21st', value: 21 },
  { text: '22nd', value: 22 },
  { text: '23rd', value: 23 },
  { text: '24th', value: 24 },
  { text: '25th', value: 25 },
  { text: '26th', value: 26 },
  { text: '27th', value: 27 },
  { text: '28th', value: 28 },
];
