export const GRD = {
  name: {
    common: 'Grenada',
    official: 'Grenada',
    native: { eng: { official: 'Grenada', common: 'Grenada' } },
  },
  cities: ['Sauteurs', "St. George's", 'Gouyave'],
  tld: ['.gd'],
  cca2: 'GD',
  ccn3: '308',
  cca3: 'GRD',
  cioc: 'GRN',
  currency: ['XCD'],
  callingCode: ['1473'],
  capital: "St. George's",
  altSpellings: ['GD'],
  region: 'Americas',
  subregion: 'Caribbean',
  languages: { eng: 'English' },
  translations: {
    deu: { official: 'Grenada', common: 'Grenada' },
    fra: { official: 'Grenade', common: 'Grenade' },
    hrv: { official: 'Grenada', common: 'Grenada' },
    ita: { official: 'Grenada', common: 'Grenada' },
    jpn: { official: 'グレナダ', common: 'グレナダ' },
    nld: { official: 'Grenada', common: 'Grenada' },
    por: { official: 'Grenada', common: 'Granada' },
    rus: { official: 'Гренада', common: 'Гренада' },
    spa: { official: 'Granada', common: 'Grenada' },
    fin: { official: 'Grenada', common: 'Grenada' },
  },
  latlng: [12.11666666, -61.66666666],
  demonym: 'Grenadian',
  landlocked: false,
  borders: [],
  area: 344,
} as const;
