import { api } from '@api';
import { Drone } from '@models';

import { openModal } from '../open-modal';

/**
 * Open Create Drone modal.
 *
 * @return Modal promise.
 */
export async function create() {
  const modalOptions = {
    title: 'CREATE DRONE',
    component: () => import('./CreateDrone.vue'),
  };

  await openModal.safe(modalOptions);
}

/**
 * ...
 */
export interface EditDroneModalOptions {
  droneId: Drone['id'];
}

/**
 * Open Edit Drone modal.
 *
 * @return Modal promise.
 */
export async function edit(options: EditDroneModalOptions) {
  const drone = await api.drones.get(options);

  const modalOptions = {
    title: 'EDIT DRONE',
    props: { drone },
    component: () => import('./EditDrone.vue'),
  };

  await openModal.safe(modalOptions);
}
