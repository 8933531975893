export const BFA = {
  name: {
    common: 'Burkina Faso',
    official: 'Burkina Faso',
    native: {
      fra: { official: 'République du Burkina', common: 'Burkina Faso' },
    },
  },
  cities: ['Ouagadougou', 'Bobo-Dioulasso', 'Tenkodogo'],
  tld: ['.bf'],
  cca2: 'BF',
  ccn3: '854',
  cca3: 'BFA',
  cioc: 'BUR',
  currency: ['XOF'],
  callingCode: ['226'],
  capital: 'Ouagadougou',
  altSpellings: ['BF'],
  region: 'Africa',
  subregion: 'Western Africa',
  languages: { fra: 'French' },
  translations: {
    cym: { official: 'Burkina Faso', common: 'Burkina Faso' },
    deu: { official: 'Burkina Faso', common: 'Burkina Faso' },
    fra: { official: 'République du Burkina', common: 'Burkina Faso' },
    hrv: { official: 'Burkina Faso', common: 'Burkina Faso' },
    ita: { official: 'Burkina Faso', common: 'Burkina Faso' },
    jpn: { official: 'ブルキナファソ', common: 'ブルキナファソ' },
    nld: { official: 'Burkina Faso', common: 'Burkina Faso' },
    por: { official: 'Burkina Faso', common: 'Burkina Faso' },
    rus: { official: 'Буркина -Фасо', common: 'Буркина-Фасо' },
    spa: { official: 'Burkina Faso', common: 'Burkina Faso' },
    fin: { official: 'Burkina Faso', common: 'Burkina Faso' },
  },
  latlng: [13, -2],
  demonym: 'Burkinabe',
  landlocked: true,
  borders: ['BEN', 'CIV', 'GHA', 'MLI', 'NER', 'TGO'],
  area: 272967,
  provinces: [
    'Bale',
    'Bam',
    'Banwa',
    'Bazega',
    'Bougouriba',
    'Boulgou',
    'Boulkiemde',
    'Comoe',
    'Ganzourgou',
    'Gnagna',
    'Gourma',
    'Houet',
    'Ioba',
    'Kadiogo',
    'Kenedougou',
    'Komandjari',
    'Kompienga',
    'Kossi',
    'Koupelogo',
    'Kouritenga',
    'Kourweogo',
    'Leraba',
    'Loroum',
    'Mouhoun',
    'Nahouri',
    'Namentenga',
    'Naumbiel',
    'Nayala',
    'Oubritenga',
    'Oudalan',
    'Passore',
    'Poni',
    'Samentenga',
    'Sanguie',
    'Seno',
    'Sissili',
    'Soum',
    'Sourou',
    'Tapoa',
    'Tuy',
    'Yagha',
    'Yatenga',
    'Ziro',
    'Zondomo',
    'Zoundweogo',
  ],
  nativeName: 'Burkina Faso',
  timezones: ['UTC'],
} as const;
