var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("Transition", { attrs: { appear: "" } }, [
    _c("div", { staticClass: "sidebar" }, [
      _c("div", { staticClass: "sidebar-header" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.top",
                value: "User Settings",
                expression: "'User Settings'",
                modifiers: { top: true },
              },
            ],
            staticClass: "h5 header-item header-button",
            on: { click: _setup.openUserSettings },
          },
          [
            _c("div", { staticClass: "profile-image" }, [
              _c("img", {
                staticClass: "profile-border",
                attrs: { src: _setup.profileBorder },
              }),
              _vm._v(" "),
              _c("img", {
                staticClass: "profile-icon",
                attrs: { src: _setup.profileImage },
              }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "profile-name",
                attrs: { title: _setup.profileName },
              },
              [_vm._v(_vm._s(_setup.profileName))]
            ),
          ]
        ),
        _vm._v(" "),
        _setup.hasMultipleRoles
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.bottom",
                    value: "Change Role",
                    expression: "'Change Role'",
                    modifiers: { bottom: true },
                  },
                ],
                staticClass: "h6 header-item header-button",
                on: { click: _setup.openRoleSelect },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_setup.activeRole?.name || "--") +
                    "\n      "
                ),
              ]
            )
          : _c("div", { staticClass: "h6 header-item" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_setup.activeRole?.name || "--") +
                  "\n      "
              ),
            ]),
        _vm._v(" "),
        _setup.activeRole?.organization?.id
          ? _c("div", { staticClass: "h6 header-item" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_setup.activeRole.organization.name || "--") +
                  "\n      "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "header-item" },
          [
            _c(
              "b-button",
              {
                staticClass: "public-profile-btn",
                attrs: {
                  size: "sm",
                  variant: "outline-primary",
                  pill: "",
                  to: {
                    name: "publicProfile",
                    params: { userId: _setup.me.id },
                  },
                  target: "_blank",
                },
              },
              [_vm._v("\n          Public Profile\n        ")]
            ),
            _vm._v(" "),
            _setup.hasMultipleRoles
              ? _c(
                  "b-button",
                  {
                    staticClass: "public-profile-btn",
                    attrs: { size: "sm", variant: "outline-primary", pill: "" },
                    on: { click: _setup.openRoleSelect },
                  },
                  [_vm._v("\n          Change Role\n        ")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "sidebar-main" },
        [
          _c(_setup.SidebarNav),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("h6", [_vm._v("Downloads")]),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "sidebar-list" },
            _vm._l(_setup.downloadLinks, function ({ key, name, link }) {
              return _c("li", { key: key, staticClass: "sidebar-list-item" }, [
                _c(
                  "a",
                  { staticClass: "sidebar-link", attrs: { href: link } },
                  [
                    _c(
                      "span",
                      { staticClass: "icon-wrapper" },
                      [
                        _c("Icon", {
                          attrs: { icon: _setup.faArrowCircleDown },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(name))]),
                  ]
                ),
              ])
            }),
            0
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }