var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "form-file-drop py-3" }, [
    _c(
      "div",
      [
        _c("transition", [
          _vm.showPreview
            ? _c(
                "div",
                { staticClass: "preview-wrapper" },
                [
                  _c(
                    "div",
                    { staticClass: "preview-bg" },
                    [
                      _vm.loading ? _c("Spinner") : _vm._e(),
                      _vm._v(" "),
                      _c("transition", { attrs: { appear: "" } }, [
                        !_vm.loading
                          ? _c("div", {
                              staticClass: "preview-bg-image",
                              style: { backgroundImage: `url(${_vm.dataUrl})` },
                            })
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.loading
                    ? _c(
                        "b-button",
                        {
                          attrs: { variant: "danger" },
                          on: { click: _vm.removeImage },
                        },
                        [_c("Icon", { attrs: { icon: _vm.icons.faTimes } })],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("transition", [
          !_vm.showPreview
            ? _c(
                "div",
                {
                  class: ["drag-area", { dragging: _vm.dragging }],
                  attrs: { title: "Click to add an image" },
                  on: {
                    dragover: function ($event) {
                      $event.preventDefault()
                    },
                    dragenter: function ($event) {
                      $event.preventDefault()
                      _vm.dragging = true
                    },
                    dragleave: function ($event) {
                      $event.preventDefault()
                      _vm.dragging = false
                    },
                    drop: function ($event) {
                      $event.preventDefault()
                      return _vm.onDrop.apply(null, arguments)
                    },
                    click: _vm.addFile,
                  },
                },
                [
                  _c("div", { staticClass: "drag-area-bg" }, [
                    _c("div", { staticClass: "drag-area-bg-inner" }, [
                      _c(
                        "div",
                        { staticClass: "drag-area-bg-icon mb-3" },
                        [_c("Icon", { attrs: { icon: _vm.icons.faImage } })],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "drag-area-bg-label" }, [
                        _vm._v(
                          "\n              Drag & Drop your image here\n            "
                        ),
                      ]),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }