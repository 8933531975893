import { formatDate } from './format-date';

/** ... */
const MILLISECONDS_IN_DAY = 86_400_000;

/** ... */
export type TimeUnit =
  | 'millisecond'
  | 'second'
  | 'minute'
  | 'hour'
  | 'day'
  | 'week'
  | 'month'
  | 'quarter'
  | 'year';

/**
 * ...
 */
class DateUtilities {
  /**
   * Get a date instance set to the current date and time.
   */
  get now() {
    return new Date();
  }

  /**
   * ...
   *
   * @param value
   * @return
   */
  toDate(value: DateLike) {
    if (value instanceof Date) return value;

    if (typeof value === 'string' && isNaN(Date.parse(value))) {
      throw new Error(
        `The value "${value}" passed to be used as a Date was not valid.`,
      );
    }

    return new Date(value);
  }

  /**
   * ...
   *
   * @param a ...
   * @param b ...
   * @return ...
   */
  diff(a: DateLike, b: DateLike) {
    return this.toDate(a).getTime() - this.toDate(b).getTime();
  }

  /**
   * ...
   *
   * @param a ...
   * @param b ...
   * @return ...
   */
  isBefore(a: DateLike, b: DateLike) {
    return this.toDate(a).getTime() < this.toDate(b).getTime();
  }

  /**
   * ...
   *
   * @param a ...
   * @param b ...
   * @return ...
   */
  isAfter(a: DateLike, b: DateLike) {
    return this.toDate(a).getTime() > this.toDate(b).getTime();
  }

  /**
   * ...
   *
   * @param a ...
   * @param b ...
   * @return ...
   */
  isSame(a: DateLike, b: DateLike) {
    return this.toDate(a).getTime() === this.toDate(b).getTime();
  }

  /**
   * ...
   *
   * @param a ...
   * @param b ...
   * @return ...
   */
  isSameOrBefore(a: DateLike, b: DateLike) {
    const ta = this.toDate(a).getTime();
    const tb = this.toDate(b).getTime();

    return ta === tb || ta < tb;
  }

  /**
   * ...
   *
   * @param a ...
   * @param b ...
   * @param c ...
   * @return ...
   */
  isBetween(a: DateLike, b: DateLike, c: DateLike) {
    const ta = this.toDate(a).getTime();
    const tb = this.toDate(b).getTime();
    const tc = this.toDate(c).getTime();

    return ta > tb && ta < tc;
  }

  /**
   * ...
   *
   * @param a ...
   * @param b ...
   * @return ...
   */
  differenceInSeconds(a: DateLike, b: DateLike) {
    // ...
    const diff = 0.001 * this.diff(a, b);

    return diff < 0 ? Math.ceil(diff) : Math.floor(diff);
  }

  /**
   * ...
   *
   * @param a ...
   * @param b ...
   * @return ...
   */
  differenceInCalendarDays(a: DateLike, b: DateLike) {
    const dateA = this.toDate(a);
    const dateB = this.toDate(b);

    const startOfDayA = startOfDay(dateA);
    const startOfDayB = startOfDay(dateB);

    const timestampA =
      startOfDayA.getTime() - getTimezoneOffsetInMilliseconds(startOfDayA);
    const timestampB =
      startOfDayB.getTime() - getTimezoneOffsetInMilliseconds(startOfDayB);

    return Math.round((timestampA - timestampB) / MILLISECONDS_IN_DAY);
  }

  /**
   * ...
   *
   * @param a ...
   * @param b ...
   * @return ...
   */
  differenceInDays(a: DateLike, b: DateLike) {
    const dateA = this.toDate(a);
    const dateB = this.toDate(b);

    // ...
    const sign = compareLocalAsc(dateA, dateB);

    // ...
    const diff = Math.abs(this.differenceInCalendarDays(dateA, dateB));

    // Math.abs(diff in full days - diff in calendar days) === 1 if last
    // calendar day is not full.
    // If so, result must be decreased by 1 in absolute value
    dateA.setDate(dateA.getDate() - sign * diff);

    const isLastDayNotFull = Number(compareLocalAsc(dateA, dateB) === -sign);
    // Prevent negative zero.
    const result = sign * (diff - isLastDayNotFull);

    return result === 0 ? 0 : result;
  }

  /**
   * ...
   *
   * @param value ...
   * @param units ...
   * @return ...
   */
  getDuration(value: DateLike, units: TimeUnit = 'millisecond') {
    const date = this.toDate(value);

    // if (!this.isValid()) {
    //     return NaN;
    // }

    const milliseconds = Date.now() - date.getTime();

    const days = milliseconds / 864e5;
    const months = daysToMonths(days);

    // units = normalizeUnits(units);

    if (units === 'month' || units === 'quarter' || units === 'year') {
      if (units === 'year') return months / 12;

      if (units === 'quarter') return months / 3;

      return months;
    }

    // Handle milliseconds separately because of floating point math errors
    // (issue #1867).
    // days = date.getDay() + Math.round(monthsToDays(date.getMonth()));

    // ...
    if (units === 'week') return days / 7 + milliseconds / 6048e5;
    // ...
    if (units === 'day') return days + milliseconds / 864e5;
    // ...
    if (units === 'hour') return days * 24 + milliseconds / 36e5;
    // ...
    if (units === 'minute') return days * 1_440 + milliseconds / 6e4;
    // ...
    if (units === 'second') return days * 86_400 + milliseconds / 1_000;

    // millisecond
    return Math.floor(days * 864e5) + milliseconds;

    // throw new Error(`[getDuration] unknown unit "${units}"`);
  }

  /**
   * ...
   *
   * @param value ...
   * @return ...
   */
  getTimeSince(value: DateLike) {
    return Date.now() - this.toDate(value).getTime();
  }

  /**
   * ...
   *
   * @param value ...
   * @return ...
   */
  getSecondsSince(value: DateLike) {
    return 0.001 * this.getTimeSince(value);
  }

  /**
   * ...
   *
   * @param value ...
   * @return ...
   */
  getMinutesSince(value: DateLike) {
    return (1 / 60) * this.getSecondsSince(value);
  }

  /**
   * ...
   *
   * @param value ...
   * @return ...
   */
  getHoursSince(value: DateLike) {
    return (1 / 60) * this.getMinutesSince(value);
  }

  /**
   * ...
   *
   * @param value ...
   * @return ...
   */
  getDaysSince(value: DateLike) {
    return (1 / 24) * this.getHoursSince(value);
  }

  /**
   * ...
   *
   * @param value ...
   * @return ...
   */
  getYearsSince(value: DateLike) {
    return (1 / 365) * this.getDaysSince(value);
  }

  /**
   * ...
   *
   * @param value ...
   * @param format ...
   * @return ...
   */
  format(value: DateLike, format = 'YYYY-MM-DDTHH:mm:ssZ') {
    // return moment(value).format(format);

    return formatDate(this.toDate(value), format);
  }
}

/**
 * Lightweigth collection of useful date-related utilities.
 */
export const dates = new DateUtilities();

// region Helper Functions

/**
 * ...
 *
 * @param days ...
 * @return ...
 */
function daysToMonths(days: number) {
  // 400 years have 146097 days (taking into account leap year rules)
  // 400 years have 12 months === 4800
  return (days * 4_800) / 146_097;
}

/**
 * The reverse of `daysToMonths`.
 *
 * @param months ...
 * @return ...
 */
function monthsToDays(months: number) {
  return (months * 146_097) / 4_800;
}

/**
 * Return the start of a day for the given date. The result will be in the local
 * timezone.
 *
 * @param date The original date.
 * @return The start of a day.
 */
function startOfDay(date: Date) {
  const newData = new Date(date);

  date.setHours(0, 0, 0, 0);

  return newData;
}

/**
 * Returns the timezone offset in milliseconds that takes seconds in account.
 *
 * @param date ...
 * @return ...
 */
function getTimezoneOffsetInMilliseconds(date: Date) {
  const utcDate = new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds(),
    ),
  );
  utcDate.setUTCFullYear(date.getFullYear());

  return date.getTime() - utcDate.getTime();
}

// Like `compareAsc` but uses local time not UTC, which is needed
// for accurate equality comparisons of UTC timestamps that end up
// having the same representation in local time, e.g. one hour before
// DST ends vs. the instant that DST ends.
function compareLocalAsc(dateLeft: Date, dateRight: Date) {
  const diff =
    dateLeft.getFullYear() - dateRight.getFullYear() ||
    dateLeft.getMonth() - dateRight.getMonth() ||
    dateLeft.getDate() - dateRight.getDate() ||
    dateLeft.getHours() - dateRight.getHours() ||
    dateLeft.getMinutes() - dateRight.getMinutes() ||
    dateLeft.getSeconds() - dateRight.getSeconds() ||
    dateLeft.getMilliseconds() - dateRight.getMilliseconds();

  if (diff < 0) {
    return -1;
  } else if (diff > 0) {
    return 1; // Return 0 if diff is 0; return NaN if diff is NaN
  } else {
    return diff;
  }
}

// endregion Helper Functions
