/**
 * ...
 */
export enum BillingCycle {
  Monthly = 1,
  Quarterly = 3,
  BiAnnually = 6,
  Annually = 12,
}

/**
 * List of institution billing cycle options.
 */
export const billingCycles = [
  { value: BillingCycle.Monthly, name: 'Monthly' },
  { value: BillingCycle.Quarterly, name: 'Quarterly' },
  { value: BillingCycle.BiAnnually, name: 'Bi-Annually' },
  { value: BillingCycle.Annually, name: 'Annually' },
];

export default billingCycles;
