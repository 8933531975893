export const NZL = {
  name: {
    common: 'New Zealand',
    official: 'New Zealand',
    native: {
      eng: { official: 'New Zealand', common: 'New Zealand' },
      mri: { official: 'Aotearoa', common: 'Aotearoa' },
      nzs: { official: 'New Zealand', common: 'New Zealand' },
    },
  },
  cities: [
    'Auckland',
    'Palmerston North',
    'Wellington',
    'Windermere',
    'Tauranga',
    'Christchurch',
    'Cheviot',
    'Levels',
    'Panmure',
    'Saint Heliers',
    'Torbay',
    'Waitakere City',
    'Silverdale',
    'Tuakau',
    'Manukau',
    'Manukau',
    'Papakura',
    'Johnsonville',
    'Takapuna',
    'Waitangi',
    'Taradale',
    'Whangarei',
    'Paihia',
    'Waiau',
    'Amberley',
    'Belmont',
    'Wellsford',
    'Hamilton',
    'Red Beach',
    'Avondale',
    'Yaldhurst',
    'Lower Hutt',
    'Greenhithe',
    'Wanganui',
    'Masterton',
    'Stanmore Bay',
    'Mangere',
    'Titirangi North',
    'Waimauku',
    'Rotorua',
    'Ponsonby',
    'Dunedin',
    'Waihi Beach',
    'Kawerau',
    'Taupo',
    'Matakana',
    'Pakuranga',
    'Albany',
    'Waiau Pa',
    'Parnell',
    'Napier City',
    'Nelson',
    'New Plymouth',
    'Winton',
    'Douglas',
    'Marua',
    'Queenstown',
    'Gisborne',
    'Cave',
    'Papamoa',
    'Whakatane',
    'Opotiki',
    'Northcote Point',
    'Hastings',
    'Petone',
    'Lincoln',
    'Otaki',
    'Timaru',
    'Wendon Valley',
    'Wanaka',
    'Greymouth',
    'Westport',
    'Punakaiki',
    'Leeston',
    'Te Kauwhata',
    'Ohaupo',
    'Haast',
    'Katikati',
    'Waihi',
    'Paeroa',
    'Tinwald',
    'Glenbrook',
    'Renwick',
    'Waikawa',
    'Ngaio',
    'Clyde',
    'Invercargill',
    'Pokeno',
    'Oxford',
    'Rakaia',
    'Halswell',
    'Linwood',
    'Mangaroa',
    'Khandallah',
    'Woodend',
    'Ngatea',
    'Balclutha',
    'Takaka',
    'Riversdale',
    'Otautau',
    'Tuatapere',
    'Riverton',
    'Pirongia',
    'Ohura',
    'Te Atatu',
    'Otorohanga',
    'Tirau',
    'Leamington',
    'North Shore',
    'Tokomaru',
    'Hawera',
    'Rolleston',
    'Mount Maunganui',
    'Whangamata',
    'Raglan',
    'Whangaparaoa',
    'Porirua',
    'Opaheke',
    'Drury',
    'Waikuku Beach',
    'Waiuku',
    'Tahoraiti',
    'Levin',
    'Tokoroa',
    'Turangi',
    'Eltham',
    'Waitoa',
    'Paraparaumu',
    'Kaiwharawhara',
    'Riverhead',
    'Warkworth',
    'Takanini',
    'Kumeu',
    'Waikanae',
    'Northland',
    'Waipara',
    'Kaikoura',
    'Thames',
    'Woolston',
    'Wainuiomata',
    'Kaitangata',
    'Lumsden',
    'Murupara',
    'Mangawhai Heads',
    'Kaiwaka',
    'Orewa',
    'Walton',
    'Cashmere Hills',
    'Wairoa',
    'Templeton',
    'Waitara',
    'Urenui',
    'Porangahau',
    'Waiouru',
    'Herne Bay',
    'Howick',
    'Hokitika',
    'Glen Eden',
    'Te Anau',
    'Oban',
    'Balfour',
    'Weston',
    'Lake Tekapo',
    'Kurow',
    'Ahaura',
    'Mangonui',
    'Havelock North',
    'Waipawa',
    'Takapau',
    'Winchester',
    'Opunake',
    'Sawyers Bay',
    'Whitianga',
    'Kawhia',
    'Taupiri',
    'Colville',
    'Upper Moutere',
    'Hikuai',
    'Owaka',
    'Waitati',
    'Hawarden',
    'Karamea',
    'Milton',
    'Kaiata',
    'Franz Josef',
    'Springs Junction',
    'Dargaville',
    'Ashhurst',
    'Foxton',
    'Foxton Beach',
    'Eastbourne',
    'Piopio',
    'Manunui',
    'Pukeatua',
    'Okaiawa',
    'Kimbolton',
    'Otaki Beach',
    'Bulls',
    'Orakei',
    'Awanui',
    'Kohukohu',
    'Okaihau',
    'Ruawai',
    'Mission Bay',
    'Ranfurly',
    'Clinton',
    'Mosgiel',
    'Outram',
    'Menzies Ferry',
    'Mossburn',
    'Rakaia Gorge',
    'West Melton',
    'Fairlie',
    'Wairoa',
    'Puhoi',
    'Campbells Bay',
    'Blackburn',
    'Waipu',
    'Portland',
    'Kerikeri',
    'Moerewa',
    'East Tamaki',
    'Tasman',
    'Favona',
    'Coromandel',
    'Russell',
    'Kaeo',
    'Te Roti',
    'Kirwee',
    'Hunterville',
    'Tamahere',
    'Waimana',
    'Bethlehem',
    'Wakefield',
    'Okato',
    'Maungaturoto',
    'Maungatapere',
    'Kamo',
    'Onga Onga',
    'Myross Bush',
    'Waverley',
    'Beachlands',
    'Otane',
    'Clarks',
    'Weymouth',
    'Wyndham',
    'Oratia',
    'Swanson',
    'Paremata',
    'Paekakariki',
    'Pleasant Point',
    'Greerton',
    'Port Chalmers',
    'Oturehua',
    'Oamaru',
    'Paradise',
    'Rotherham',
    'Longburn',
    'Clive',
    'Otematata',
    'Penrose',
    'Collingwood',
    'Bombay',
    'Clevedon',
    'Waikari',
    'Coalgate',
    'Oakura',
    'Darfield',
    'Duvauchelle',
    'Sefton',
    'Edendale',
    'Browns Bay',
    'Methven',
    'Doyleston',
    'Waikuku',
    'Kawakawa',
    'Brightwater',
    'Paraparaumu Beach',
    'Coatesville',
    'Reporoa',
  ],
  tld: ['.nz'],
  cca2: 'NZ',
  ccn3: '554',
  cca3: 'NZL',
  cioc: 'NZL',
  currency: ['NZD'],
  callingCode: ['64'],
  capital: 'Wellington',
  altSpellings: ['NZ', 'Aotearoa'],
  region: 'Oceania',
  subregion: 'Australia and New Zealand',
  languages: { eng: 'English', mri: 'Māori', nzs: 'New Zealand Sign Language' },
  translations: {
    deu: { official: 'Neuseeland', common: 'Neuseeland' },
    fra: { official: 'Nouvelle-Zélande', common: 'Nouvelle-Zélande' },
    hrv: { official: 'Novi Zeland', common: 'Novi Zeland' },
    ita: { official: 'Nuova Zelanda', common: 'Nuova Zelanda' },
    jpn: { official: 'ニュージーランド', common: 'ニュージーランド' },
    nld: { official: 'Nieuw Zeeland', common: 'Nieuw-Zeeland' },
    por: { official: 'nova Zelândia', common: 'Nova Zelândia' },
    rus: { official: 'Новая Зеландия', common: 'Новая Зеландия' },
    spa: { official: 'nueva Zelanda', common: 'Nueva Zelanda' },
    fin: { official: 'Uusi-Seelanti', common: 'Uusi-Seelanti' },
  },
  latlng: [-41, 174],
  demonym: 'New Zealander',
  landlocked: false,
  borders: [],
  area: 270467,
  provinces: [
    'Akaroa',
    'Amuri',
    'Ashburton',
    'Bay of Islands',
    'Bruce',
    'Buller',
    'Chatham Islands',
    'Cheviot',
    'Clifton',
    'Clutha',
    'Cook',
    'Dannevirke',
    'Egmont',
    'Eketahuna',
    'Ellesmere',
    'Eltham',
    'Eyre',
    'Featherston',
    'Franklin',
    'Golden Bay',
    'Great Barrier Island',
    'Grey',
    'Hauraki Plains',
    'Hawera',
    "Hawke's Bay",
    'Heathcote',
    'Hikurangi',
    'Hobson',
    'Hokianga',
    'Horowhenua',
    'Hurunui',
    'Hutt',
    'Inangahua',
    'Inglewood',
    'Kaikoura',
    'Kairanga',
    'Kiwitea',
    'Lake',
    'Mackenzie',
    'Malvern',
    'Manaia',
    'Manawatu',
    'Mangonui',
    'Maniototo',
    'Marlborough',
    'Masterton',
    'Matamata',
    'Mount Herbert',
    'Ohinemuri',
    'Opotiki',
    'Oroua',
    'Otamatea',
    'Otorohanga',
    'Oxford',
    'Pahiatua',
    'Paparua',
    'Patea',
    'Piako',
    'Pohangina',
    'Raglan',
    'Rangiora',
    'Rangitikei',
    'Rodney',
    'Rotorua',
    'Runanga',
    'Saint Kilda',
    'Silverpeaks',
    'Southland',
    'Stewart Island',
    'Stratford',
    'Strathallan',
    'Taranaki',
    'Taumarunui',
    'Taupo',
    'Tauranga',
    'Thames-Coromandel',
    'Tuapeka',
    'Vincent',
    'Waiapu',
    'Waiheke',
    'Waihemo',
    'Waikato',
    'Waikohu',
    'Waimairi',
    'Waimarino',
    'Waimate',
    'Waimate West',
    'Waimea',
    'Waipa',
    'Waipawa',
    'Waipukurau',
    'Wairarapa South',
    'Wairewa',
    'Wairoa',
    'Waitaki',
    'Waitomo',
    'Waitotara',
    'Wallace',
    'Wanganui',
    'Waverley',
    'Westland',
    'Whakatane',
    'Whangarei',
    'Whangaroa',
    'Woodville',
  ],
  nativeName: 'New Zealand',
  timezones: ['UTC−11:00', 'UTC−10:00', 'UTC+12:00', 'UTC+12:45', 'UTC+13:00'],
} as const;
