export const STP = {
  name: {
    common: 'São Tomé and Príncipe',
    official: 'Democratic Republic of São Tomé and Príncipe',
    native: {
      por: {
        official: 'República Democrática do São Tomé e Príncipe',
        common: 'São Tomé e Príncipe',
      },
    },
  },
  tld: ['.st'],
  cca2: 'ST',
  ccn3: '678',
  cca3: 'STP',
  cioc: 'STP',
  currency: ['STD'],
  callingCode: ['239'],
  capital: 'São Tomé',
  altSpellings: [
    'ST',
    'Democratic Republic of São Tomé and Príncipe',
    'Sao Tome and Principe',
    'República Democrática de São Tomé e Príncipe',
  ],
  region: 'Africa',
  subregion: 'Middle Africa',
  languages: { por: 'Portuguese' },
  translations: {
    deu: {
      official: 'Demokratische Republik São Tomé und Príncipe',
      common: 'São Tomé und Príncipe',
    },
    fra: {
      official: 'République démocratique de São Tomé et Príncipe',
      common: 'São Tomé et Príncipe',
    },
    hrv: {
      official: 'Demokratska Republika São Tome i Principe',
      common: 'Sveti Toma i Princip',
    },
    ita: {
      official: 'Repubblica democratica di São Tomé e Príncipe',
      common: 'São Tomé e Príncipe',
    },
    jpn: {
      official: 'サントメ·プリンシペ民主共和国',
      common: 'サントメ・プリンシペ',
    },
    nld: {
      official: 'Democratische Republiek Sao Tomé en Principe',
      common: 'Sao Tomé en Principe',
    },
    por: {
      official: 'República Democrática de São Tomé e Príncipe',
      common: 'São Tomé e Príncipe',
    },
    rus: {
      official: 'Демократическая Республика Сан-Томе и Принсипи',
      common: 'Сан-Томе и Принсипи',
    },
    spa: {
      official: 'República Democrática de Santo Tomé y Príncipe',
      common: 'Santo Tomé y Príncipe',
    },
    fin: {
      official: 'São Tomé ja Príncipen demokraattinen tasavalta',
      common: 'São Téme ja Príncipe',
    },
  },
  latlng: [1, 7],
  demonym: 'Sao Tomean',
  landlocked: false,
  borders: [],
  area: 964,
} as const;
