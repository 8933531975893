import { GetterTree, ActionTree, MutationTree, Module } from '@vuex';
import { find, findIndex } from 'lodash';

import { api } from '@api';
import { Reseller } from '@models';
import { Root } from '@store';

export interface ResellersState {
  items: Reseller[];
}

export interface GetResellerOptions {
  id: number;
}

export interface AddProductsToResellerOptions {
  id: number;
  productIds: string[];
}

export interface RemoveProductsFromResellerOptions {
  id: number;
  productIds: string[];
}

export interface ResellerSetOptions {
  data: Reseller[];
}

export type ResellerAddOptions = Reseller;

export interface ResellerUpdateOptions {
  id: number;
  announcement: Reseller;
}

const state: ResellersState = {
  items: [],
};

const getters: GetterTree<ResellersState, Root.State> = {
  get: (state) => (id: string) => find(state.items, { id }),
};

const actions: ActionTree<ResellersState, Root.State> = {
  async list({ commit }) {
    const data = await api.resellers.list();

    commit('SET', { data });

    return data;
  },
  async get({ commit }, options: GetResellerOptions) {
    const data = await api.resellers.get(options);

    commit('ADD', data);

    return data;
  },
  async create({ commit }, options: Reseller) {
    const data = await api.resellers.create(options);

    commit('ADD', data);

    return data;
  },
  async addProducts({ dispatch }, options: AddProductsToResellerOptions) {
    await api.resellers.addProducts(options);

    return await dispatch('get', { id: options.id });
  },
  async removeProducts(
    { dispatch },
    options: RemoveProductsFromResellerOptions,
  ) {
    await api.resellers.removeProducts(options);

    return await dispatch('get', { id: options.id });
  },
};

const mutations: MutationTree<ResellersState> = {
  SET(state, options: ResellerSetOptions): void {
    state.items = options.data;
  },
  ADD(state, options: ResellerAddOptions) {
    const items = [...state.items];

    const i = findIndex(items, { id: options.id });

    if (i !== -1) {
      items[i] = options;
    } else {
      items.push(options);
    }

    state.items = items;
  },
  CLEAR(state) {
    state.items = [];
  },
};

export const resellers: Module<ResellersState, Root.State> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default resellers;
