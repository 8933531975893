export const ITA = {
  name: {
    common: 'Italy',
    official: 'Italian Republic',
    native: {
      bar: { official: 'Italienische Republik', common: 'Italien' },
      ita: { official: 'Repubblica italiana', common: 'Italia' },
      srd: { official: 'Repubbricanu Italia', common: 'Italia' },
    },
  },
  cities: [
    'Ferrara',
    'Sarnano',
    'Rome',
    'Gabicce Mare',
    'Sasso Marconi',
    'Sarno',
    'Collegno',
    'Fasano',
    'Bitritto',
    'Bitetto',
    'Bari',
    'Florence',
    'San Filippo',
    'Giardini-Naxos',
    'Augusta',
    'Messina',
    'Carlentini',
    'Troina',
    'Piaggine',
    'Tito',
    'Lampedusa',
    'San Mango Piemonte',
    'Mercato San Severino',
    'San Chirico Raparo',
    'Gesualdo',
    'Taranto',
    'Torricella',
    'Cicognolo',
    'Pedaso',
    'Cesena',
    'Casorezzo',
    'Brescia',
    'Loreto',
    'Vaiano Cremasco',
    'Milan',
    'Monza',
    'Monterosso Calabro',
    'Pesaro',
    'Militello Rosmarino',
    'Floridia',
    'Rionero in Vulture',
    'San Giorgio del Sannio',
    'Modena',
    "Reggio nell'Emilia",
    'Vicenza',
    'Rimini',
    'Piacenza',
    'Saronno',
    'Ancona',
    'Verona',
    'Asti',
    'Legnano',
    'Castellanza',
    'Massa e Cozzile',
    'Montecatini Terme',
    'Pescara',
    'Bergamo',
    'Bollate',
    'San Donato Milanese',
    'Termoli',
    'Turin',
    'Pisa',
    'Pioltello',
    'Gallarate',
    'Varese',
    'Gaeta',
    'Parma',
    'Teramo',
    'Busto Arsizio',
    'Padua',
    'Naples',
    'Catania',
    'Novara',
    'Bologna',
    'Sestri Levante',
    'San Giovanni Valdarno',
    'Sesto San Giovanni',
    'Cinisello Balsamo',
    'Genoa',
    'Bitonto',
    'ForlÃ¬',
    'La Spezia',
    'Latina',
    'Segrate',
    'Peschiera Borromeo',
    'Treviso',
    'Ravenna',
    'Rho',
    'Rosignano Solvay-Castiglioncello',
    'Rosignano Marittimo',
    'Fondi',
    'Formia',
    'Carpaneto Piacentino',
    'Petacciato',
    'Como',
    'Siena',
    'Iglesias',
    'Gerano',
    'Pogno',
    'Vicolungo',
    'Corzano',
    'Albenga',
    'Pero',
    'Pieve Ligure',
    'Domaso',
    'Sarnico',
    'Verdellino',
    'Melegnano',
    'Alessandria',
    'Pavia',
    'Racconigi',
    'Perosa Argentina',
    'Moncalvo',
    'Vigevano',
    'Rivoli',
    'Brandizzo',
    'Castellamonte',
    'Gorgonzola',
    'Ghedi',
    'Tradate',
    'Clusone',
    'Alzano Lombardo',
    'Cremona',
    'Curno',
    'Ovada',
    'Arenzano',
    'Savona',
    'Sanremo',
    'Viareggio',
    'Brugherio',
    'Novate Milanese',
    'Cesano Maderno',
    'Abbiategrasso',
    'Arona',
    'Chieri',
    'Pinerolo',
    'Nichelino',
    'Vigliano Biellese',
    'Romagnano Sesia',
    'Biella',
    'Settimo Torinese',
    'Rivalta di Torino',
    'Domodossola',
    'Piossasco',
    'San Carlo',
    'Terlan',
    'Tolentino',
    'Casalecchio di Reno',
    'Casier',
    'ScorzÃ¨',
    'San Giovanni in Persiceto',
    'Cento',
    'Borgonovo Val Tidone',
    'Albinea',
    'Vascon',
    'Rivignano',
    'Grado',
    'Asolo',
    'Annone Veneto',
    "San Michele all'Adige",
    'Illasi',
    'San Giovanni Lupatoto',
    'Garda',
    'Volpago del Montello',
    'Trivignano Udinese',
    'Cavallino',
    'Zero Branco',
    'Castello di Godego',
    'Sedico',
    'Alberoni',
    'La Valle Agordina',
    'Pasian di Prato',
    'Tavagnacco',
    'Brugnera',
    'Ponzano Veneto',
    'Mira',
    'Ponte di Piave',
    'Marcon',
    'Porcia',
    'Dosson',
    'Pravisdomini',
    'Pianiga',
    'Sagrado',
    'Turriaco',
    'Roncade',
    'Vittorio Veneto',
    'Trieste',
    'Chioggia',
    'Venice',
    'Spinea',
    'Udine',
    'Mogliano Veneto',
    'Pordenone',
    'Legnago',
    'Bolzano',
    'Trento',
    'San Bonifacio',
    'Bussolengo',
    'Isola della Scala',
    'Belluno',
    'Bovolone',
    'Portomaggiore',
    'CittÃ  di Castello',
    'Dozza',
    'Budrio',
    'Imola',
    'Sassuolo',
    'Bastia umbra',
    'Monghidoro',
    'Cervia',
    'Formigine',
    'Castel San Pietro Terme',
    'Santarcangelo di Romagna',
    'Perugia',
    'Schio',
    'Rovigo',
    'Malo',
    'Sarcedo',
    'Somma Lombardo',
    'Erba',
    'Casalpusterlengo',
    'Cermenate',
    'Luino',
    'Solbiate Olona',
    'Codogno',
    'Mariano Comense',
    'Bresso',
    'Lodi',
    'Cologno al Serio',
    'Cologno Monzese',
    'Moncalieri',
    'Grugliasco',
    'Casale Monferrato',
    'Aosta',
    'Gessate',
    'Brivio',
    'Calolziocorte',
    'Parabiago',
    'Zelo',
    'Albiate',
    'Lomagna',
    'Venegono Superiore',
    'Lainate',
    'Villa Cortese',
    'Locate di Triulzi',
    'Inzago',
    'Besana in Brianza',
    'Biassono',
    'Osnago',
    'Gorla Maggiore',
    'Triuggio',
    'Cerro Maggiore',
    "Cornate d'Adda",
    'Binasco',
    'Broni',
    "Lurago d'Erba",
    "Vaprio d'Adda",
    'Calizzano',
    'Mezzanego',
    'Carcare',
    'Azzate',
    'Missaglia',
    'Giussano',
    'Paderno Dugnano',
    'Seveso',
    'Villasanta',
    'Lentate sul Seveso',
    'Corsico',
    'Avezzano',
    'Senigallia',
    'San Benedetto dei Marsi',
    'Massa',
    'Arezzo',
    'Forlimpopoli',
    'Pontedera',
    'Livorno',
    'Formello',
    'Anzio',
    'Frascati',
    'Guidonia',
    'Aprilia',
    'Sansepolcro',
    'Sesto Fiorentino',
    'Pistoia',
    'Prato',
    'Montecarlo',
    'Riccione',
    'Castelfiorentino',
    'Arconate',
    'Varedo',
    'BarzanÃ²',
    'Trezzano sul Naviglio',
    'Usmate Velate',
    'Lonigo',
    'Occhiobello',
    'Albignasego',
    'Cittadella',
    'MaserÃ  di Padova',
    'Longiano',
    'Rovereto',
    'Spoltore',
    'Figline Valdarno',
    'Palermo',
    'Benevento',
    'Alcamo',
    'Trecastagni',
    'Syracuse',
    'Giarre',
    'Acireale',
    'Monreale',
    'Alba',
    'Rapallo',
    'Albissola Marina',
    'Cuneo',
    'Magenta',
    'Novi Ligure',
    'Mortara',
    'Ivrea',
    'Alpignano',
    'Pianezza',
    'Valenza',
    'Tortona',
    'Lungavilla',
    'Olgiate Molgora',
    'Arcore',
    'Garlasco',
    'San Martino in Strada',
    'Cislago',
    'Landriano',
    'Limbiate',
    'Vermezzo',
    'Siziano',
    'San Genesio ed Uniti',
    'Liscate',
    'Fombio',
    'Campertogno',
    "Trezzo sull'Adda",
    'Lugagnano',
    'Trescore Balneario',
    'Mantova',
    'Pieve',
    'Vertova',
    'Darfo',
    'Chiari',
    'Seriate',
    'Travagliato',
    'Cene',
    'Gussago',
    'Urgnano',
    'Casnigo',
    'Saluzzo',
    'Orbassano',
    'Gattinara',
    'Borgosesia',
    'Carmagnola',
    'Fidenza',
    'Bagnolo Cremasco',
    'Ventimiglia',
    'Desio',
    'Cornaredo',
    'Vimercate',
    'Rozzano',
    'Milanesi',
    'Favria',
    'Giaveno',
    'MondovÃ¬',
    'Fossano',
    'San Michele MondovÃ¬',
    'Lessona',
    'Sandigliano',
    'Oglianico',
    'Omegna',
    'Bricherasio',
    'Castelnuovo Don Bosco',
    'Rivarolo Ligure',
    'Occhieppo Inferiore',
    'Varallo Sesia',
    'Varallo Pombia',
    'Momo',
    'San Giovanni',
    'Finale Ligure',
    "Calusco d'Adda",
    'Marano Ticino',
    'Gressoney-Saint-Jean',
    'San Martino di Lupari',
    'RosÃ ',
    'Tricesimo',
    "Sant'Ilario d'Enza",
    'Zola Predosa',
    'Peschiera del Garda',
    'Tregnago',
    'San Pietro in Casale',
    'Maranello',
    'Camposampiero',
    'Abano Terme',
    'Brixen',
    'Marostica',
    'Civezzano',
    'Arco',
    'Portogruaro',
    'Crema',
    'Lecco',
    'Camparada',
    'Massa Lombarda',
    'Savignano sul Panaro',
    'Baricella',
    'Castelfranco Emilia',
    'Comacchio',
    'Masi Torello',
    'Cotignola',
    'Mordano',
    'Montecchio Emilia',
    'Bagnacavallo',
    'Bagnolo in Piano',
    'Copparo',
    'Pianoro',
    'Savignano sul Rubicone',
    'Finale Emilia',
    'Rosolina',
    'Ficarolo',
    'Brugine',
    'Oppeano',
    'Marano Vicentino',
    'San Pietro in Gu',
    'Villadose',
    'Agna',
    'Noventa Vicentina',
    'Villaggio Montegrappa',
    'Castelmassa',
    'Altavilla Vicentina',
    'Tezze sul Brenta',
    'Vescovana',
    'Teolo',
    'Vigonza',
    'Zevio',
    'Recoaro Terme',
    'Sterzing',
    'Castel',
    'Sanguinetto',
    'Sommacampagna',
    'Maniago',
    'Casinalbo',
    'Felino',
    'Mirandola',
    'MansuÃ¨',
    'Martellago',
    'Motta di Livenza',
    'Azzano Decimo',
    'Urbisaglia',
    'Lâ€™Aquila',
    'Notaresco',
    'Civitanova Marche',
    'Castorano',
    'Montegranaro',
    'Folignano',
    'Lettomanoppello',
    'Maiolati Spontini',
    'Cupello',
    'Urbania',
    'Agugliano',
    'Manoppello',
    'Mondolfo',
    'Castelbellino',
    'Orsogna',
    'Ascoli Piceno',
    'Montesilvano Colle',
    'Casalbordino',
    'Porto San Giorgio',
    "Sant'Angelo in Pontano",
    'Chieti',
    'Polverigi',
    'Oratino',
    'Frosinone',
    'Silvi Paese',
    'Iesi',
    'San Benedetto del Tronto',
    'Porto',
    'Lucca',
    'Empoli',
    'Ferentino',
    'Sermoneta',
    'Santa Marinella',
    'Fiumicino',
    'Ardea',
    'Castel Madama',
    'Ischia di Castro',
    'Bassano Romano',
    'Mineo',
    'Bronte',
    "Capo d'Orlando",
    'Motta San Giovanni',
    "Aci Sant'Antonio",
    'Melilli',
    'Piedimonte Etneo',
    'Leonforte',
    'Agira',
    'Patti',
    'Pedara',
    'Biancavilla',
    'Nicosia',
    'Spadafora',
    'Cardito',
    'Torre del Greco',
    "Pomigliano d'Arco",
    'Telese',
    'Giugliano in Campania',
    'Belmonte Mezzagno',
    'Termini Imerese',
    'Salve',
    'Andrano',
    'San Marco Argentano',
    'Reggio Calabria',
    'Novoli',
    'Cosenza',
    'Triggiano',
    'Trapani',
    'Aci Catena',
    "Sant'Agata di Militello",
    'Castenedolo',
    'Iseo',
    'Candiolo',
    'Candelo',
    'Zimone',
    'Sestriere',
    'Cogoleto',
    'Borgomanero',
    'Porlezza',
    'Pontevico',
    'Zogno',
    'Belforte',
    'Piazza Brembana',
    'Rudiano',
    'Nerviano',
    'Montemurlo',
    'Pomezia',
    'Cisterna di Latina',
    'Nettuno',
    'Civitavecchia',
    'Campagnano di Roma',
    'Rieti',
    'Calcata',
    'Sabaudia',
    'Genazzano',
    'Pontinia',
    'Viterbo',
    'Olevano Romano',
    'Cerveteri',
    'Sacrofano',
    'Nepi',
    'Monte Porzio Catone',
    'Veroli',
    'Ladispoli',
    'Fornaci di Barga',
    'Signa',
    'Cascina',
    'Porcari',
    'Pescia',
    'Corciano',
    'Fucecchio',
    "Colle di Val d'Elsa",
    'Grosseto',
    'Capannoli',
    'Quarrata',
    'Montelupo Fiorentino',
    'Magliano',
    'Cervaro',
    'Sarroch',
    'Villaurbana',
    'Elmas',
    'Muravera',
    'Sassari',
    'Sorso',
    'Arbus',
    'Atzara',
    'Soleminis',
    'Olmedo',
    'Gonnostramatza',
    'Terralba',
    'Villanovafranca',
    'Marrubiu',
    'Masainas',
    'Ussana',
    'Alghero',
    'Ossi',
    'Villa San Pietro',
    'Oristano',
    'Musei',
    'Samassi',
    'Villaputzu',
    'Siniscola',
    'Cusano Milanino',
    'San Giuliano Milanese',
    'Grandate',
    'Bareggio',
    'Seregno',
    'Cantu',
    'Baranzate',
    'Colico',
    'Merate',
    'Romano di Lombardia',
    "Fiorenzuola d'Arda",
    'Sarezzo',
    'Stresa',
    'Forno Canavese',
    'Chivasso',
    'Crevalcore',
    "Granarolo dell'Emilia e Viadagola",
    'Cervignano del Friuli',
    'Conegliano',
    'Novellara',
    'Assisi',
    'Foligno',
    'Dolo',
    'Cadelbosco di Sopra',
    'Gualdo Tadino',
    'Vignola',
    'Faenza',
    'Valdobbiadene',
    'Monterenzio',
    'Novi di Modena',
    'Medicina',
    'Palau',
    'Villaspeciosa',
    'Cagliari',
    "Quartu Sant'Elena",
    'Guasila',
    'Castelfidardo',
    'Ostra Vetere',
    'San Giovanni',
    'Macerata',
    'Vasto',
    'Osimo',
    'Fabriano',
    'Chiaravalle',
    'Campobasso',
    'Recanati',
    'San Casciano in Val di Pesa',
    'Montespertoli',
    'Genzano di Roma',
    'Bagno a Ripoli',
    'Piombino',
    'Poggibonsi',
    'Loro Ciuffenna',
    'Bocchignano',
    'Bracciano',
    'Foggia',
    'Melfi',
    'Caivano',
    'Ariano Irpino',
    'Avella',
    'Portici',
    'Caserta',
    'Catanzaro',
    'Andria',
    'Santeramo in Colle',
    'Terlizzi',
    'Altamura',
    'Caltagirone',
    'Piazza Armerina',
    'Niscemi',
    'Lentini',
    'Caltanissetta',
    'Palma di Montechiaro',
    'Piano di Sorrento',
    'Manduria',
    'Acquaviva delle Fonti',
    "Pieve d'Olmi",
    'Misinto',
    'Torre',
    'Zerfaliu',
    'Lacchiarella',
    'Mirano',
    'Tivoli',
    'Villetta',
    'Lido',
    'UrtijÃ«i',
    'Fiume Veneto',
    'Olivetta',
    'Airole',
    'Quart',
    'Vallecrosia',
    'Rovello Porro',
    'Moso',
    'Villagrazia',
    'Piemonte',
    'Antico di Maiolo',
    'Romana',
    'San Miniato',
    'Afragola',
    "Cava de' Tirreni",
    'Ragusa',
    'Ferrari',
    'Torgiano',
    'Anna',
    "Silvano d'Orba",
    'Pineta',
    'Cola',
    'Modugno',
    'San Raineri',
    'San Giorgio',
    'Salerno',
    'Campagna',
    'San Cipriano Picentino',
    'Brunella',
    'Rosa',
    'Fantina',
    'San Giorgio',
    'San Francesco',
    'Piove di Sacco',
    'Gioia',
    'Gazzo Veronese',
    'Corona',
    'Vado Ligure',
    'Zanetti',
    'Bruno',
    'Bianco',
    'Sale',
    'Altofonte',
    'Salsomaggiore Terme',
    'Monteverde',
    'Marco',
    'San Michele al Tagliamento',
    'Sestu',
    'Positano',
    'Ravanusa',
    'Lecce',
    'Portoscuso',
    'Ranieri',
    'Casali',
    'Forte dei Marmi',
    'Nogarole Rocca',
    'Crescentino',
    'Rivanazzano',
    'Taglio di Po',
    'Filottrano',
    'Cairo Montenotte',
    'Sesto al Reghena',
    'Cernusco sul Naviglio',
    'Dello',
    'Vitorchiano',
    'Modica',
    'Lanusei',
    'Bassano del Grappa',
    'Angolo Terme',
    'Bagheria',
    'Piedimonte Matese',
    'Sant Angelo',
    'Cortenova',
    'Bomporto',
    'Pozzo di Gotto',
    'Braccagni',
    'Cassino',
    'San Vendemiano',
    'Carpeneto',
    'Monticello',
    'Mestre',
    'Cerasolo',
    'Ronchi dei Legionari',
    'Colorno',
    'San Giovanni in Marignano',
    'San Martino Buon Albergo',
    'Castel Guelfo di Bologna',
    'Monselice',
    'Aviano',
    'Thiene',
    'Coriano',
    'Adria',
    'FossÃ²',
    'Vas',
    'Bertinoro',
    'Podenzano',
    'Caldogno',
    'Zoppola',
    'Cesenatico',
    'Argelato',
    'Sandon',
    'San Pietro',
    'Brendola',
    'Cordenons',
    'Pescantina',
    'Roverbella',
    'Paese',
    'Carpi',
    'Sacile',
    'Cividale del Friuli',
    'Staranzano',
    'Latisana',
    'Misano Adriatico',
    'Campodarsego',
    'Fogliano Redipuglia',
    'Correggio',
    'Castelfranco di Sopra',
    'Cadoneghe',
    'Dozza',
    'Ala',
    'Marzabotto',
    'Casale sul Sile',
    'Borgo San Lorenzo',
    "Santa Vittoria d'Alba",
    'Zanica',
    'Magliano Alpi',
    'Ronco Scrivia',
    'Moniga del Garda',
    'Canelli',
    'Castel Mella',
    'Baldissero Torinese',
    'Vercelli',
    'Ortovero',
    'Barlassina',
    'Castano Primo',
    'Calcinato',
    'Verdello',
    'Varazze',
    'Cascine Maggio',
    'Cossato',
    'Uscio',
    'MuggiÃ²',
    'Vignate',
    'Treviglio',
    'Sedriano',
    'Roncadelle',
    'Quarona',
    'Nova Milanese',
    'Castiglione Olona',
    'Sesto Calende',
    'Rovagnate',
    'Chiavari',
    'Besozzo',
    'Olgiate Olona',
    'Pavone Canavese',
    'Ponte Nossa',
    'Melzo',
    'Crispiano',
    'Agropoli',
    'Scalea',
    'Toritto',
    'Pozzallo',
    'Manfredonia',
    'Corridonia',
    'Montecorvino Rovella',
    'Ripe',
    'San Donaci',
    'Villaricca',
    'Nocera Superiore',
    'Misterbianco',
    'Aversa',
    'Trentola-Ducenta',
    'Battipaglia',
    'Saviano',
    'San Giovanni la Punta',
    'Vico Equense',
    'Laterza',
    'Mazara del Vallo',
    'Palo del Colle',
    'Torano Castello',
    'Castellammare di Stabia',
    'Veglie',
    'San Giorgio a Cremano',
    'Marcianise',
    'Barletta',
    'Trani',
    'Ercolano',
    'Frattamaggiore',
    'San Tammaro',
    'Falconara Marittima',
    'Brindisi',
    'Ceglie Messapica',
    'NardÃ²',
    'Matera',
    'Molfetta',
    'Partinico',
    'Balestrate-Foce',
    'Carmiano',
    'Noicattaro',
    'Giovinazzo',
    'Grumo Nevano',
    'Scorrano',
    'Ischia',
    'Castelvenere',
    'San Severo',
    "Sant'Arpino",
    'Potenza',
    'Pagani',
    'Regalbuto',
    'Casapulla',
    'Acerra',
    'Casoria',
    'Terni',
    'Crespina',
    'Raiano',
    'Sulmona',
    'Ariccia',
    'San Donato',
    'Marino',
    'Saltocchio',
    'Morlupo',
    'Monterotondo',
    'Assemini',
    'San Salvo',
    'Nazzano',
    'Scandicci',
    'Sora',
    'Montemassi',
    'Vetralla',
    'Santa Croce',
    'Fonni',
    'Castelnuovo di Garfagnana',
    "CittÃ  Sant'Angelo",
    "Monteroni d'Arbia",
    'Mentana',
    'Cecina',
    'Olbia',
    'Le Castella',
    'Giulianova',
    'Nuoro',
    'Silvi',
    'Ficarazzi',
    'Piana degli Albanesi',
    'Civita Castellana',
    'Luco dei Marsi',
    'Licata',
    'Acri',
    "Rignano sull'Arno",
    'Truccazzano',
    'Spotorno',
    'Villalago',
    'Vicari',
    'Monopoli',
    'Sandrigo',
    'Gusti',
    'PatÃ¹',
    'Montecchio Maggiore',
    'Pramaggiore',
    'San Giuseppe Vesuviano',
    'Corbetta',
    'Offanengo',
    'Lancenigo-Villorba',
    'Andorno Micca',
    'Vimodrone',
    "Cassano d'Adda",
    'Campi Bisenzio',
    'Tavullia',
    'Cellamare',
    'Millesimo',
    'Ardesio',
    'Recco',
    'Ponte San Pietro',
    'Sarzana',
    'Carate Brianza',
    'Nave',
    'Bovisio-Masciago',
    'Castegnato',
    'Poirino',
    'Malnate',
    'Cava Manara',
    'Bra',
    'Imperia',
    'Monte',
    'San Mauro Torinese',
    'Castelnuovo Nigra',
    'Lomazzo',
    'Baratte',
    'Trofarello',
    'Carnate',
    'Garbagnate Milanese',
    'Appiano Gentile',
    'Concorezzo',
    'Canegrate',
    'Opera',
    'Mozzanica',
    'Desenzano del Garda',
    'Robassomero',
    'Crespiatica',
    'Acqui Terme',
    'Grosio',
    'Torre Boldone',
    'Carrara',
    'Massa',
    'Velletri',
    'Serravalle Pistoiese',
    'Paliano',
    'Bosa',
    'Campomarino',
    'SenorbÃ¬',
    'Cortona',
    'San Gemini',
    'Camaiore',
    'Calcinaia',
    'Roseto degli Abruzzi',
    'Riano',
    'Santa Giusta',
    'Fiano Romano',
    'Impruneta',
    'Grottaferrata',
    'Marina di Cerveteri',
    'San Sebastiano al Vesuvio',
    'San Gregorio di Catania',
    'Scafa',
    'Nola',
    'Caccamo',
    'Fano',
    'Martina Franca',
    'Santa Maria a Vico',
    'Salemi',
    'Cerignola',
    'Agrigento',
    'Riposto',
    'Atripalda',
    'Cittanova',
    'Pineto',
    'Calcinelli',
    'Lizzano',
    'Fermo',
    'Santo Pietro',
    'Cicciano',
    "Sant'Agata de' Goti",
    'Nocera Inferiore',
    'Favara',
    'Mugnano del Cardinale',
    'Villarosa',
    'Qualiano',
    'Caltavuturo',
    'Erice',
    'Ruvo di Puglia',
    'Poli',
    'Mussomeli',
    'Villa Castelli',
    'Avellino',
    'Alvignano',
    'Mercogliano',
    'San Giuseppe Jato',
    'San Nicola',
    'Boscoreale',
    'Belpasso',
    'Urbino',
    'Lissone',
    'Ozzero',
    'Travedona Monate',
    'Gavirate',
    'Santo Stefano',
    'Beinasco',
    'Saint-Vincent',
    'Fossalta di Portogruaro',
    'San Martino',
    'Rovato',
    'GambolÃ²',
    'Stradella',
    'Trecate',
    'Fino Mornasco',
    'Venaria Reale',
    'Aquileia',
    'Valdagno',
    'Asiago',
    'San Lazzaro di Savena',
    'Limana',
    'Lavis',
    "Castel d'Ario",
    'Cormons',
    'Curtarolo',
    'Feltre',
    'Rubano',
    'Bardolino',
    'ZanÃ¨',
    'Farra di Soligo',
    'Castelnuovo Rangone',
    'Castelvetro di Modena',
    'Noale',
    'Pievepelago',
    'Castelfranco Veneto',
    'San Dona',
    'Selvazzano Dentro',
    'Merano',
    'Palmanova',
    'Villafranca di Verona',
    'Collecchio',
    'Montechiarugolo',
    'Massa',
    'Casalgrande',
    'Cappella Maggiore',
    'Gattatico',
    'San DonÃ  di Piave',
    'Preganziol',
    'Castenaso',
    'Decima',
    'San Giovanni in Croce',
    'Nervesa della Battaglia',
    'Toirano',
    'Voghera',
    'Cardano',
    'Cameri',
    'Oggiono',
    'Lonate Pozzolo',
    'Dairago',
    'Villastellone',
    'Montoggio',
    'Vicoforte',
    'AlmÃ¨',
    'Vittuone',
    'Caselle Torinese',
    'Altessano',
    'Altare',
    'Carugate',
    'Sondrio',
    'Agrate Brianza',
    "Spino d'Adda",
    'Pietrasanta',
    'Monte',
    'Segni',
    'Terranuova Bracciolini',
    'Monsummano Terme',
    'Labico',
    'Grottammare',
    'Palestrina',
    'Putignano',
    'Marano di Napoli',
    'Pozzuoli',
    "Sant'Anastasia",
    'Angri',
    'Cercola',
    'Sciacca',
    'Auletta',
    'Ortona',
    'Fiumefreddo di Sicilia',
    "Sant'Elpidio a Mare",
    'San Michele Salentino',
    'Matelica',
    'Casaluce',
    'Grammichele',
    'Statte',
    'Martinsicuro',
    'Capurso',
    'Maderno',
    'Casamassima',
    'Bisceglie',
    'Mariglianella',
    'Castiglione di Sicilia',
    'Cotronei',
    'Tortora',
    'Petrosino',
    'Pergola',
    'Cervino',
    'Lioni',
    'Mascalucia',
    'San Prisco',
    'Brusciano',
    'Camposano',
    'Rende',
    'Offida',
    'Piana',
    'Adro',
    'Santo Stefano Ticino',
    'Arluno',
    'Fagnano Olona',
    'Monterosso al Mare',
    'Grumello Cremonese',
    'Certosa di Pavia',
    'Castiglione Chiavarese',
    'Bussoleno',
    'Savigliano',
    'Solaro',
    'Stezzano',
    'Belgioioso',
    'Monte Compatri',
    'Celano',
    'Lamporecchio',
    'Francavilla al Mare',
    'Albano Laziale',
    'Fregene',
    'Castel Viscardo',
    'Morciano di Romagna',
    'Casina',
    'Romano Canavese',
    'Cesiomaggiore',
    'Porto Viro',
    'Nogaredo al Torre',
    'Villaverla',
    'Quinto Vicentino',
    'Mandello del Lario',
    'Cizzago-Comezzano',
    'Manerbio',
    'Campofranco',
    'Gadoni',
    'Carema',
    'San Martino Siccomario',
    'Pontecchio Polesine',
    'Apricena',
    'Legnaro',
    'Alviano',
    'San Mauro Pascoli',
    'Guarda Veneta',
    'Golasecca',
    'Forenza',
    'Cerveno',
    'Campodimele',
    'Piancogno',
    'Cepagatti',
    'Ailoche',
    'Suzzara',
    'Vailate',
    'Bassano Bresciano',
    'Valeggio sul Mincio',
    'Gera Lario',
    "San Martino Dall'Argine",
    'Arabba',
    'Casale di Scodosia',
    'Murano',
    'Posta Fibreno',
    'Carceri',
    'Cortina',
    'Isola Vicentina',
    'San Daniele del Friuli',
    'San Giorgio',
    'Este',
    'Falcone',
    'Telgate',
    'Crotone',
    'Castel Maggiore',
    'Mercato Saraceno',
    'Pomarance',
    'Sirignano',
    'Rosolini',
    'Comiso',
    'Costa',
    'Santa Maria di Licodia',
    'Covo',
    'Santa Eufemia Lamezia',
    'San Severino',
    'San Gimignano',
    'Serramazzoni',
    'Farnese',
    'Castiglione della Pescaia',
    'Carapelle',
    'Belvedere di Spinello',
    'Montecarotto',
    'Marotta',
    'Arcevia',
    'Calcara',
    'Milano',
    'Bibbiano',
    'Bellagio',
    'Palombara Sabina',
    'Pavona',
    'Anagni',
    'Zagarolo',
    'Lograto',
    'Colloredo di Prato',
    'Gravedona',
    'Vogogna',
    'Gorno',
    'Gignese',
    'Monte di Malo',
    'Felitto',
    'Manta',
    'Vigone',
    'Monastero',
    'Castelletto',
    'None',
    'Montignoso',
    'Verzuolo',
    'Demonte',
    'Sarsina',
    'Sovicille',
    'Gaiole in Chianti',
    'Soliera',
    'Borgoricco',
    'Barrali',
    'Monterosi',
    'Calamandrana',
    'Mezzomerico',
    'Cerea',
    'Gazzaniga',
    'Ambrogio',
    'Lignano Sabbiadoro',
    'Arre',
    'Orio Litta',
    'Cedessano',
    'Todi',
    'Cannara',
    'Stefania',
    'Massignano',
    'Pioraco',
    'Contigliano',
    'San Giorgio Piacentino',
    'Campolongo Maggiore',
    'Montaldo',
    'Borgo San Dalmazzo',
    'Susa',
    "Sant'Angelo Lodigiano",
    'Castelli Calepio',
    'Casa Paradiso',
    'Camponogara',
    'Roncoferraro',
    'Collalto',
    'Carbonera',
    'Pico',
    'Mirabella',
    'Cattolica',
    'Russo',
    'San Niccolo',
    'Parigi',
    'Lurate Caccivio',
    'Ospedaletto-Expopisa',
    'Morgano',
    'Campiglio',
    'Mestrino',
    'Noventa di Piave',
    'Cimadolmo',
    'Leno',
    'Sumirago',
    'Pontecchio',
    'Ponsacco',
    'Mirabella Eclano',
    'Carosino',
    'Castelfranco di Sotto',
    'Pietrabbondante',
    'Bojano',
    'Montemurro',
    'Monserrato',
    'Gela',
    'Passetto',
    'Bruneck',
    'Trappeto',
    'Montebelluna',
    'Crocetta del Montello',
    'San Fior',
    'Frassineto Po',
    'Casteggio',
    'Fortunago',
    'Villanova MondovÃ¬',
    'Valle',
    'Gioia Tauro',
    'Stella Cilento',
    'Sava',
    'Pozzuolo del Friuli',
    'Montanaro',
    'Balangero',
    'Benna',
    'Caluso',
    'Andezeno',
    'Feletto',
    'Tollegno',
    'Cassano',
    'Vigolo Vattaro',
    'Caldaro sulla Strada del Vino',
    'Costabissara',
    'Orgiano',
    'Roncegno',
    "Quarto d'Altino",
    'Riva del Garda',
    'Pedemonte',
    'Laives',
    'Calenzano',
    'Lanciano',
    'Arsago Seprio',
    'Vergiate',
    'Casorate Sempione',
    'Torri di Quartesolo',
    'Chiampo',
    'Monticello Conte Otto',
    'Gandino',
    'Marnate',
    'Carnago',
    'Albino',
    'Artogne',
    'Santa Giustina',
    'Oderzo',
    'Vedelago',
    'Rubiera',
    'Acquasparta',
    'Ruda',
    'Comerio',
    'Zibido San Giacomo',
    'Arese',
    'Robbiate',
    'Meda',
    'Ospedaletto Euganeo',
    'Malcesine',
    'Valenzano',
    'Palagiano',
    'San Giorgio Ionico',
    'Adelfia',
    'San Vito dei Normanni',
    'Costa',
    'CuorgnÃ¨',
    'Vignale Monferrato',
    'Casanova',
    'Selci',
    'Castelnuovo Scrivia',
    'Bubbio',
    'Bellinzago Novarese',
    'Trino',
    'Madonna',
    'Paullo',
    'Cenaia',
    'Lucino',
    'Casazza',
    'Ponte San Nicolo',
    'Locri',
    'Laigueglia',
    'Lerma',
    'Nembro',
    'Predosa',
    'Bobbio',
    'RiccÃ² del Golfo di Spezia',
    'Saluggia',
    'Valperga',
    'Grignasco',
    'Pozzolo Formigaro',
    'San Piero a Sieve',
    'Trebaseleghe',
    'Castelluccio Inferiore',
    'Fontaniva',
    'Lucito',
    'Storo',
    'Alfonsine',
    'Montebello',
    'Fauglia',
    'Ripa-Pozzi-Querceta-Ponterosso',
    'Sannicandro Garganico',
    'Ponzone',
    'Levanto',
    'Maresca',
    'Ponte San Giovanni',
    'Vittoria',
    'Gorgo al Monticano',
    'Revigliasco',
    'Pederobba',
    'Carobbio',
    'Fontana',
    'Castelvecchio',
    'Castelnuovo',
    'Santa Maria degli Angeli',
    'Carbonara di Bari',
    'Albuzzano',
    'Plan da Tieja',
    'San Giorgio in Bosco',
    'Santa Giustina in Colle',
    'Casalvecchio Siculo',
    'Fossombrone',
    'Azzano San Paolo',
    'Marmirolo',
    'Frontone',
    'Colmurano',
    'Castelplanio',
    'Orciano di Pesaro',
    'San Marcello',
    'Montemarciano',
    'Deruta',
    'Antea',
    'Certaldo',
    'Fastello',
    'Montenerodomo',
    'Guardiagrele',
    'Pretoro',
    'Colledimezzo',
    'Resana',
    'Navacchio',
    'Trissino',
    'Fumane',
    'Palmi',
    'Milazzo',
    'Eboli',
    'Asciano',
    'Marineo',
    'Casalvieri',
    'Pietra Ligure',
    'Mareno di Piave',
    'Bagnolo',
    'Zoldo Alto',
    'Veneto',
    'Costa di Rovigo',
    'Affi',
    'Vaglia',
    'Tomba',
    'Tredozio',
    'Venetico',
    'Caccuri',
    'Mendicino',
    'Tempio Pausania',
    'Porto Torres',
    'Macomer',
    'Sennori',
    'Mezzano',
    'Bozzolo',
    'Barberino di Mugello',
    'Greve in Chianti',
    'Fiesole',
    'Casarano',
    'Bagnoli del Trigno',
    'Cavriglia',
    'Castilenti',
    'Collecorvino',
    'Carpenedolo',
    'Santo Stefano di Magra',
    'Negrar',
    'Piasco',
    'Barbarasco',
    'Asso',
    'Belvedere',
    'Seravezza',
    'San Piero in Bagno',
    'Monteprandone',
    'Monteveglio',
    'Grumello del Monte',
    'Cazzago San Martino',
    'Monticelli',
    'Paderno Franciacorta',
    'Scafati',
    'Calvizzano',
    "Sant'Antimo",
    'Vietri sul Mare',
    "Sant'Antonio Abate",
    "Sant'Antonio",
    'Marigliano',
    'Boscotrecase',
    'Pompei',
    'Pugliano',
    'Sorrento',
    'Casalnuovo di Napoli',
    'Frattaminore',
    'Atella',
    'Casandrino',
    'Torre Annunziata',
    'Ottaviano',
    'Appiano sulla strada del vino',
    'Amaro',
    'Piano',
    'Pozza-San Marco',
    'Casto',
    'Ruviano',
    'Arielli',
    'Torrita Tiberina',
    'Castelnuovo di Verona',
    'Allerona',
    'Pellezzano',
    'Procida',
    'Montalbano Jonico',
    'Nova Siri',
    'Avigliano',
    'San Giorgio',
    'Soave',
    'Filiano',
    'Cologna Veneta',
    'Mafalda',
    'Aquino',
    'Vicopisano',
    'Mezzolombardo',
    'CiriÃ©',
    'Gozzano',
    'Ghemme',
    'Chiomonte',
    'San Giorgio di Piano',
    'Case Franchini',
    'Valdengo',
    'Bagnolo Mella',
    "Palazzolo sull'Oglio",
    'Roccavione',
    'Bosconero',
    'Volpiano',
    'Silea',
    'Pofi',
    'Grotte di Castro',
    'Anghiari',
    'Carini',
    'Collesano',
    'Agerola',
    'Province of Messina',
    'Ispica',
    'Rutigliano',
    'Gioia del Colle',
    'Arnesano',
    "Sant'Angelo Muxaro",
    'Padula',
    'Anzano del Parco',
    'Olginate',
    'Olevano sul Tusciano',
    'Miravalle',
    'Il Ciocco',
    'Fontanella',
    'Torri',
    'Aurelia',
    'Villanova',
    'Monaco',
    'BascapÃ¨',
    'Cuggiono',
    'Viola',
    'Diamante',
    'Carpegna',
    'Uggiate Trevano',
    'Orio al Serio',
    'Nizza Monferrato',
    'Quattordio',
    'Nonantola',
    'Ozzano Monferrato',
    'Crespellano',
    'Minerbio',
    'Bentivoglio',
    'Colbordolo',
    'Monte San Pietro',
    'Montefiore Conca',
    'Saludecio',
    'San Leo',
    'Lugo',
    'Magreta',
    'Montese',
    'Montecchio',
    'Pratola Serra',
    'Berlingo',
    'Castagneto Po',
    'Strambino',
    'AgliÃ¨',
    'Centallo',
    'Bagnolo Piemonte',
    'Morozzo',
    'Rocca',
    'Lanzo Torinese',
    'Almese',
    'Scalenghe',
    'Volvera',
    'Sommariva del Bosco',
    'Quargnento',
    'Cassano Spinola',
    'Lonate Ceppino',
    'Olgiate Comasco',
    'Verrone',
    'ErbÃ¨',
    'Pomponesco',
    'Castel Goffredo',
    'Serravalle Sesia',
    'Valduggia',
    'Coggiola',
    'Agrano',
    "San Maurizio d'Opaglio",
    'Casaleggio Novara',
    'Mottalciata',
    'Masserano',
    'Poncarale',
    'Buscate',
    'Fabbri',
    'Dueville',
    'Seglia San Bernardo',
    'Fornace di Miramare',
    'Busalla',
    'Valentina',
    'Rizzi',
    'Rino',
    'Guido',
    'Nicola',
    'Saline',
    'Manzoni',
    'Pellegrino',
    'Poviglio',
    'San Pietro',
    'Pace',
    'Riviera',
    'Venafro',
    'Ciciliano',
    'Baglio Rizzo',
    'Aragona',
    'San Giuseppe',
    'Atena Lucana',
    'Venturina',
    'Mariano',
    'Lipari',
    'Corno',
    'Fontevivo',
    'Paola',
    'Sasso',
    'Ameglia',
    'Centrale',
    'Portofino',
    'San Francesco',
    'Castelletto',
    'Fabbrico',
    'Villanova Monferrato',
    'Lido di Ostia',
    'Marina di Carrara',
    "Sant'Oreste",
    'Melicucco',
    'Gemona',
    'Fiumicello',
    'Rottofreno',
    'Ittiri',
    'Sedilo',
    'Villasalto',
    'Ploaghe',
    'Selegas',
    'Cornuda',
    'Nurachi',
    'PaternÃ²',
    'Aci Castello',
    'Sinnai',
    'Dolianova',
    'Siliqua',
    'Carbonia',
    'Selargius',
    "Sant'Antioco",
    'Villacidro',
    'Capoterra',
    'Quartucciu',
    'Alleghe',
    'Bagno di Romagna',
    'Ballabio',
    'Baveno',
    'Castronno',
    'San Zenone degli Ezzelini',
    'Salara',
    'Carlazzo',
    'Ternate',
    'Premosello-Chiovenda',
    'Campi',
    'Santa Lucia',
    'Gravellona Toce',
    'Prevalle',
    'Cirimido',
    'Breganze',
    'Borgo Ticino',
    'Pallanza-Intra-Suna',
    'Oliveto Lario',
    'Canzo',
    'Caprino Veronese',
    'Montagnana',
    'San Zeno',
    'Gurro',
    'Cambiago',
    'Brembilla',
    'Castello',
    'Livigno',
    'Sondalo',
    'Trecenta',
    'Falze-Signoressa',
    'Pasturo',
    'Cannobio',
    'Mel',
    'Bianzone',
    'Rancio Valcuvia',
    'Barni',
    'Rosate',
    'Camisano Vicentino',
    'Altopascio',
    'Vecchiano-Nodica',
    'Passarella',
    'Minerbe',
    'Asola',
    'Piazza',
    'Pallerone',
    'Pianello Val Tidone',
    'San Giuliano Terme',
    'Sutri',
    'Anguillara',
    'Sala Bolognese',
    'Montalto delle Marche',
    'Vigolzone',
    'Fontanellato',
    'Gallicano nel Lazio',
    'Caerano di San Marco',
    'Follonica',
    'San Salvatore',
    'SanthiÃ ',
    'Cavallirio',
    'Comun Nuovo',
    'Caravaggio',
    'Dalmine',
    'Coccaglio',
    'Sabbioneta',
    'Pergine Valsugana',
    'Cavareno',
    'Taufers im MÃ¼nstertal',
    'Mori',
    'Dervio',
    'Cogorno',
    'Santa Margherita Ligure',
    'Gazzada Schianno',
    'Gropello Cairoli',
    'Robecchetto con Induno',
    'Godiasco',
    'Aulla',
    'Angera',
    'Cumiana',
    'Ariano nel Polesine',
    'Castiglione delle Stiviere',
    'Vigodarzere',
    'Torreglia',
    'Stanghella',
    'Campo San Martino',
    'Limena',
    'Roveredo',
    'Taggia',
    'Villa Faraldi',
    'Montalto Dora',
    'Azeglio',
    'Mombercelli',
    'Burolo',
    'Villafranca Piemonte',
    "Santa Margherita d'Adige",
    'San Francesco al Campo',
    'Samone',
    'Torre Pellice',
    'Corio',
    'Dogliani',
    "Gradisca d'Isonzo",
    'Monfalcone',
    'San Vito al Tagliamento',
    'Muggia',
    "Cortina d'Ampezzo",
    'Vigasio',
    'Militello in Val di Catania',
    'Noto',
    'San Martino in Rio',
    'Montesarchio',
    'Bisaccia',
    'Dugenta',
    'Capannori',
    'Valmontone',
    'Tossicia',
    'Tolfa',
    'Corato',
    'Nogara',
    "Ronco all'Adige",
    'Santa Lucia di Piave',
    'Braone',
    'Isola Dovarese',
    'Villa Vicentina',
    'Povegliano Veronese',
    'Serravalle Scrivia',
    'Galliate',
    'Ciserano',
    'Cisano Bergamasco',
    'Galbiate',
    'Viadana',
    'Guidizzolo',
    'Ceresara',
    'Orzivecchi',
    'Taio',
    'Castelnuovo Magra',
    'Licciana Nardi',
    'Capriate San Gervasio',
    'Vanzago',
    'San Colombano al Lambro',
    'Tromello',
    'Spineto',
    'Palazzo Canavese',
    'Calimera',
    'Piovene Rocchette',
    'Guastalla',
    'Cengio',
    'Loano',
    'Candia Canavese',
    'Mercenasco',
    'Pessinetto',
    'Verolengo',
    'Rivara',
    'Fontanafredda',
    'Spilimbergo',
    'Basiliano',
    'Arzene',
    'Spresiano',
    'Codroipo',
    'Solagna',
    'Sona',
    'CefalÃ¹',
    'Camporeale',
    'Montelepre',
    'Granitola Torretta',
    'Altavilla Irpina',
    'Piane Crati',
    'Scandiano',
    'Spilamberto',
    'Pavullo nel Frignano',
    'Polesine Parmense',
    'Medesano',
    "Sant'Agata Bolognese",
    'Mirabello',
    'Morrovalle',
    'Sannicandro di Bari',
    'Massafra',
    'Celico',
    "San Gregorio d'Ippona",
    'Villasmundo',
    'Ramacca',
    'Roccalumera',
    'Dipignano',
    'San Demetrio Corone',
    'Calci',
    'San Marco Evangelista',
    'Villa Literno',
    'Caiatia',
    'Monte di Procida',
    'Santa Maria Capua Vetere',
    'Villamagna',
    'Somma Vesuviana',
    'Marsala',
    'Campobello di Licata',
    'Squinzano',
    'Alessano',
    'Casacalenda',
    'San Giovanni',
    'Sigillo',
    'Mesagne',
    'Cellino San Marco',
    'Soleto',
    'Torchiarolo',
    'Narni',
    'Passignano sul Trasimeno',
    'Vernole',
    'Trepuzzi',
    'Cartoceto',
    'Scauri',
    'Sezze',
    'Camerano',
    "Cerreto d'Esi",
    'Pulsano',
    'Ostuni',
    'Palma Campania',
    'San Lucido',
    'Marina di Campo',
    'Terracina',
    'Barga',
    'Mondavio',
    'Ripe San Ginesio',
    'Servigliano',
    'Ostra',
    'Traversetolo',
    "Ponte dell'Olio",
    "Porto Sant'Elpidio",
    'Acerno',
    'Moliterno',
    'Acquaviva Picena',
    'Trinitapoli',
    'Sammichele di Bari',
    'Castelpagano',
    'Santa Croce Camerina',
    'Mazzarino',
    'Castrovillari',
    'Giffoni Valle Piana',
    'Torregrotta',
    "Casole d'Elsa",
    "San Quirico d'Orcia",
    'Cesa',
    'Amalfi',
    'Bacoli',
    'Corleone',
    'Colledara',
    'Spinete',
    'Sambuceto',
    'Jolanda di Savoia',
    'San Vito Lo Capo',
    'Pontenure',
    'Orte',
    'Filettino',
    'Barbiano',
    'Stazione Ponte Rio',
    'Gallipoli',
    "Morro d'Alba",
    'Ponte a Evola',
    'Ripatransone',
    "Baldichieri d'Asti",
    'Caorso',
    'Gatteo a Mare',
    'Cavarzere',
    'Ormea',
    'Bellaria-Igea Marina',
    'Scarlino',
    'Garlenda',
    'Montichiari',
    'Montelparo',
    'Rezzato',
    'Apiro',
    'Castelnuovo dei Sabbioni',
    'Bondeno',
    'Valguarnera Caropepe',
    'Margherita di Savoia',
    'Racalmuto',
    "Sant'Egidio alla Vibrata",
    'Priolo Gargallo',
    'Prizzi',
    'Villa San Sebastiano',
    'Isernia',
    'Barrafranca',
    'Capri',
    'Controguerra',
    'Carinola',
    'Almenno San Bartolomeo',
    'Solbiate Arno',
    'Medolago',
    'Vercurago',
    'Casaglia',
    'Sabbio Chiese',
    'Portoferraio',
    'Jesolo',
    'Arcade',
    'Lancenigo',
    'San Salvatore',
    'Strevi',
    'Oleggio',
    'Pontecurone',
    'San Pellegrino Terme',
    'Valmadrera',
    'Vadena',
    'Dorno',
    'MontÃ¹ Beccaria',
    'Rescaldina',
    'Borghetto Santo Spirito',
    'Alassio',
    'Cona',
    'Bolzano Vicentino',
    'Casalgrasso',
    'Saliceto',
    'Vallo',
    'Lentiai',
    'Cinto Caomaggiore',
    'Grezzana',
    'Calalzo di Cadore',
    'Cantagallo',
    'Puianello',
    'Marghera',
    'Milena',
    'Santa Flavia',
    'Pieris',
    'Pradamano',
    'Paluzza',
    'Pressana',
    'Tarcento',
    'Maserada sul Piave',
    'Castro dei Volsci',
    'Borgo Vercelli',
    'Suno',
    'Mongrando',
    'Castelleone',
    'Gallignano',
    'Mapello',
    'Palazzo',
    'Vezzano Ligure',
    'Bolano',
    'Cologne',
    'Toscolano-Maderno',
    'Ospitaletto',
    'Ceto',
    'Gazoldo degli Ippoliti',
    'Cembra',
    'Cencenighe Agordino',
    'Lozzo di Cadore',
    'Arosio',
    'Dicomano',
    'San Giorgio di Lomellina',
    'Santa Maria',
    'Candia Lomellina',
    'Lodi Vecchio',
    'Motta Visconti',
    'Maslianico',
    'Rondissone',
    'Maddaloni',
    'Montoro',
    'Tombolo',
    'Castellarano',
    'Ceriale',
    'Casalmaiocco',
    'Busca',
    'Druento',
    'Cogne',
    'Castelnuovo',
    'Bibione',
    "Romans d'Isonzo",
    'Trichiana',
    'Gruaro',
    'Migliarino',
    'Gioiosa Marea',
    'Francavilla di Sicilia',
    'Massarosa',
    'Canossa',
    'Fiorano Modenese',
    'Montefalcione',
    'Montecalvoli',
    'Bazzano',
    'Castello di Serravalle',
    'Gallo',
    'Bovino',
    'Monte Urano',
    'Camerino',
    'San Costanzo',
    'Mola di Bari',
    "San NicolÃ² d'Arcidano",
    'Grumo Appula',
    'Taormina',
    'Valverde',
    'Nizza di Sicilia',
    'Casale',
    'Roccapiemonte',
    'Castel Volturno',
    'Montevarchi',
    'Scarperia',
    'Solofra',
    'Baselice',
    'Meta',
    'Calatafimi',
    'Paceco',
    "Sant'Angelo d'Alife",
    'Castellalto',
    'Spoleto',
    'Gualdo Cattaneo',
    'Recale',
    'Montelabbate',
    'Piglio',
    'Cinisi',
    'Capena',
    'Pontecorvo',
    'Colleferro',
    'Gatteo',
    'Rocca San Casciano',
    'Punta Marina',
    'Montecastrilli',
    'Taviano',
    'Aradeo',
    'Sannicola',
    'Chiusi della Verna',
    'Vaiano',
    'Fossato di Vico',
    'San Vincenzo',
    'Soragna',
    'Serino',
    'Capua',
    'San Ginesio',
    'Cingoli',
    'Gravina in Puglia',
    'San Giorgio la Molara',
    'Cassano delle Murge',
    'Solarino',
    'Maletto',
    'San Fratello',
    'Squillace',
    'Roccella Ionica',
    'Calangianus',
    'Vinci',
    'Sinalunga',
    'Pimonte',
    'Monte San Giovanni Campano',
    'Grumento Nova',
    'Menfi',
    'Nereto',
    'Ciminna',
    'Monte San Savino',
    'San Gregorio',
    'Campo Tizzoro',
    'Arzano',
    'Minturno',
    'Trevignano Romano',
    'Galatone',
    'Vitigliano',
    'Tricase',
    'Anacapri',
    'Leporano',
    'Catenanuova',
    'Brolo',
    'San Ferdinando',
    'Grazzano Badoglio',
    'Gavardo',
    'Castelverde',
    'Levico Terme',
    'Feldthurns',
    'Klausen',
    'Cles',
    'Lierna',
    'Abbadia Lariana',
    'Lavagna',
    'Varano',
    'Cavacurta',
    'Casatenovo',
    "Albano Sant'Alessandro",
    'Busto Garolfo',
    'Cesana Torinese',
    'Miradolo Terme',
    'Mozzate',
    'Comazzo',
    "Villanova d'Asti",
    'Pettorazza Grimani',
    'Castelnuovo',
    'Tavernola Bergamasca',
    'Capaci',
    'Foglizzo',
    'San Maurizio Canavese',
    'San Maurizio',
    'Gassino Torinese',
    'Rocca Canavese',
    'Valle Mosso',
    'Castelnuovo',
    'Anguillara Veneta',
    'Morbegno',
    'Ossona',
    'Isorella',
    'Marina di Gioiosa Ionica',
    'Casal Velino',
    'Galdo',
    'Gibellina',
    'Falconara',
    'Altomonte',
    'Lovere',
    'Pontestura',
    'Sotto il Monte Giovanni XXIII',
    'Colle',
    'Capergnanica',
    'Volta Mantovana',
    'Borgo di Terzo',
    'Terzo',
    'Camogli',
    'Pieve Albignola',
    'Rovellasca',
    'Castelnuovo',
    'Gaggiano',
    'Mason',
    'Villanova del Ghebbo-Valdentro',
    'Torricella',
    'Vedano Olona',
    'Caprie',
    'Ovaro',
    'San Rocco',
    'Paterno',
    'Cutrofiano',
    'Morano Calabro',
    'Sogliano Cavour',
    'Maglie',
    'Porretta Terme',
    'Torre San Patrizio',
    "Lesignano de' Bagni",
    'Molinella',
    'Calderara di Reno',
    'San Benedetto Val di Sambro',
    'San Cesareo',
    'Bellocchi',
    'Grottazzolina',
    'San Pietro in Lama',
    'Conversano',
    'San Marcello Pistoiese',
    'Belvedere Marittimo',
    'Fontana Liri',
    'Centuripe',
    'Scordia',
    'Riva',
    'Scaletta Zanclea',
    "Serra d'Aiello",
    'Vibo Valentia',
    'San Casciano dei Bagni',
    'Terzigno',
    'Montesano sulla Marcellana',
    'Vairano Patenora',
    'Baronissi',
    'Ripa Teatina',
    'San Vito Chietino',
    'Trevi',
    'Castiglione',
    'Fisciano',
    'Lake Bolsena',
    'Corleto Perticara',
    'Grottaglie',
    'Specchia',
    'Castrolibero',
    'Atessa',
    'Altino',
    'Rio Saliceto',
    'Cortemaggiore',
    'Gossolengo',
    'Ribera',
    'Santa Margherita di Belice',
    'Alberobello',
    'Poggiomarino',
    "Sant'Agnello",
    'Calatabiano',
    'Acate',
    'Acquaro',
    'Pizzo',
    'Cetraro',
    'Bibbiena',
    'CittÃ  della Pieve',
    'Scanzano',
    'Alessandria della Rocca',
    'Castelvetrano',
    'Rosciano',
    "Mosciano Sant'Angelo",
    'Otricoli',
    'Magione',
    'San Venanzo',
    "Monte Sant'Angelo",
    'Toffia',
    'Torrice',
    'Fragagnano',
    'Ruffano',
    'Diano Marina',
    'Susegana',
    'Cassacco',
    'Cernobbio',
    'Sassoferrato',
    'Porto Potenza Picena',
    'Vestone',
    'Colle Umberto',
    'Montegrotto Terme',
    'Canale',
    'Spinetoli',
    'Villafrati',
    'Pula',
    'Gioiosa Ionica',
    'Cellole',
    'Sicignano degli Alburni',
    'San Cassiano',
    'Castrignano del Capo',
    'Besnate',
    'Civitella del Tronto',
    'Falciano',
    'Lariano',
    'Gragnano',
    'Cantalupo in Sabina',
    'Castel Frentano',
    'Fagagna',
    'Montebello Vicentino',
    'Castiglion Fibocchi',
    'Castel San Giovanni',
    "Incisa in Val d'Arno",
    'Fermignano',
    'Magliano in Toscana',
    'Santa Maria a Monte',
    'Fumone',
    'Agliano',
    'Tronzano Vercellese',
    'Occhieppo Superiore',
    'Riva presso Chieri',
    'Palosco',
    'Villa di Serio',
    'Chiavenna',
    'Vobarno',
    'Calvatone',
    'SalÃ²',
    'Mezzocorona',
    'Lerici',
    'Campo Ligure',
    'Serra RiccÃ²',
    'Lago',
    'Zeme',
    'Zelo Buon Persico',
    "Cassina de' Pecchi",
    'Mornago',
    'Trezzano Rosa',
    'Carmignano di Brenta',
    'Front',
    'Cervere',
    "Sant'Ambrogio di Torino",
    'Gonars',
    'Chions',
    'San Gregorio da Sassola',
    'Rapagnano',
    'Lastra a Signa',
    'Contursi Terme',
    'Lanuvio',
    'Agordo',
    'Castel San Pietro Romano',
    'Cerano',
    'Spigno Saturnia',
    'Martinengo',
    'Dongo',
    'Colere',
    'Calascibetta',
    'Lonato',
    'Ospedaletto',
    'Calliano',
    'Ortonovo',
    'Guamaggiore',
    'Canneto Pavese',
    'Lana',
    'Campione',
    'Cavagnolo',
    'Pastrengo',
    'ArquÃ  Petrarca',
    'Loreggia',
    'Trequanda',
    'Cocquio-Trevisago',
    'Albano Vercellese',
    'Rignano Flaminio',
    'Trevignano',
    'Postioma',
    'Teggiano',
    'Rapolla',
    'San Giovanni Rotondo',
    'Saponara',
    'San Sosti',
    'Badolato',
    'Rubbiano',
    'Casale di Mezzani',
    'Noceto',
    "Anzola dell'Emilia",
    'Porto Garibaldi',
    'Monzuno',
    'Noci',
    'Mogoro',
    'Serrenti',
    'Narcao',
    'Enna',
    'Santa Venerina',
    'Santa Teresa di Riva',
    'Santa Caterina Villarmosa',
    'StalettÃ¬',
    'Civitella in Val di Chiana',
    'Rufina',
    'Camerata Picena',
    'Macerata Campania',
    'Abbasanta',
    'Bucchianico',
    'Bellante',
    'Canistro',
    'Penne',
    'Castiglione del Lago',
    'Novafeltria',
    'Corfinio',
    'Riccia',
    'Oppido Lucano',
    'San Martino',
    'Torella dei Lombardi',
    'Marcellina',
    'Allumiere',
    'Mazzano Romano',
    'San Cesario di Lecce',
    'Parabita',
    'Striano',
    'Cancello ed Arnone',
    'Amorosi',
    'Francavilla in Sinni',
    'Amelia',
    'Castellabate',
    'Bova Marina',
    'Sorbolo',
    'Reno',
    'Falerone',
    'Colli del Tronto',
    'Castel Colonna',
    'Cisternino',
    'Polignano a Mare',
    'Domusnovas',
    'Alife',
    'Itri',
    'Antrodoco',
    'Itala',
    'Piraino',
    'Rometta',
    'Castellina in Chianti',
    'Cerreto Guidi',
    'Reggello',
    'Poggio',
    'Leverano',
    'Castellana Sicula',
    'Gubbio',
    'Nicotera',
    'Ripafratta-Farneta',
    'Priverno',
    'Cittaducale',
    'Poiana Maggiore',
    'Santorso',
    'Costermano',
    'Ampezzo',
    'Rivarolo Mantovano',
    'Cimego',
    'CavagliÃ ',
    'Frascaro',
    'Esino Lario',
    'Trescore Cremasco',
    'Pisogne',
    'Arcene',
    'Rovetta',
    'Prata Camportaccio',
    'Pizzighettone',
    'Pegognaga',
    'Villa Lagarina',
    'Lasino',
    'Castelletto sopra Ticino',
    'Verolavecchia',
    'Airuno',
    'Valle',
    'Ornago',
    'Peglio',
    'Rubiana',
    'Chambave',
    'Cherasco',
    "Sant'Albano Stura",
    'Gorizia',
    'Dignano',
    'Musile di Piave',
    'Borgo',
    "Monforte d'Alba",
    'Pompiano',
    'Cavaion Veronese',
    'Tolmezzo',
    'Cordovado',
    'Sospirolo',
    'Samarate',
    'Pandino',
    'Bottanuco',
    'Sergnano',
    'Buscoldo',
    'Bovezzo',
    'Villa Guardia',
    'Trivero',
    'Quinto di Treviso',
    'Brembate',
    'Magnago',
    'Saletto',
    'Stra',
    'Pontedassio',
    'Cigliano',
    'Ceres',
    'Peveragno',
    'VerrÃ¨s',
    'Alimena',
    'Casarsa della Delizia',
    'Buia',
    'Cerete',
    'Monte San Vito',
    'Cologna',
    'Domanico',
    'Sommatino',
    'Altavilla Silentina',
    'Palazzolo Acreide',
    'Monteodorisio',
    'Stornarella',
    'Cassine Superiore',
    'Turi',
    'Ales',
    'Scilla',
    'Soverato Marina',
    'Mesoraca',
    'Bovalino',
    'Marina',
    'San Pietro a Maida',
    'Trebisacce',
    'Castelpoto',
    'Quarto',
    'Morrone',
    'Barra',
    'Bracigliano',
    'Sgurgola',
    'Sessa Aurunca',
    'Escalaplano',
    'Codaruina',
    'Orvieto',
    'Russi',
    'Vitulazio',
    'Salice Salentino',
    'Zollino',
    'Puglianello',
    'Ailano',
    'Arienzo',
    'Medolla',
    'Guardavalle',
    'Bientina',
    'San Gavino Monreale',
    'Baunei',
    'Ozieri',
    'Erchie',
    'Muro Leccese',
    'Gimigliano',
    'Chiesina Uzzanese',
    'Santa Caterina dello Ionio',
    'Morcone',
    'Buccino',
    'Norcia',
    'Narni Scalo',
    'Spello',
    'Ceccano',
    'Artena',
    'Rocca Massima',
    'Muros',
    'Reggiolo',
    'Roccastrada',
    'Romentino',
    'Zumaglia',
    'Ornavasso',
    'Cressa',
    'Buronzo',
    'Creazzo',
    'Villafranca in Lunigiana',
    'Suardi',
    'San Giovanni Bianco',
    'Sermide',
    'Sirmione',
    'Welsberg-Taisten',
    'Coseano',
    'Menaggio',
    'Casargo',
    'Carasco',
    'Caravino',
    'Rivalba',
    'Villafranca di Forli',
    'Crespino',
    'San Biagio di Callalta',
    'Celle Ligure',
    'Carignano',
    'Avigliana',
    'LeinÃ¬',
    'Bagnara Calabra',
    'Manzano',
    'Torri del Benaco',
    'Verucchio',
    'Giavera del Montello',
    'Santa Maria di Sala',
    'Izano',
    'Amandola',
    'San Giorgio di Nogaro',
    'Gaiarine',
    'Capistrano',
    'San Cataldo',
    'Salvirola',
    'Brignano',
    'Ponte',
    'Borno',
    'Passirano',
    'Dimaro',
    'Livinallongo del Col di Lana',
    'ArsiÃ¨',
    'Subiaco',
    'Borzonasca',
    'Castello',
    'Cavenago di Brianza',
    'Zerbo',
    'Gavi',
    'Laveno-Mombello',
    'Domegliara',
    'Grumolo delle Abbadesse',
    "Paderno d'Adda",
    'Vahrn',
    'Margarita',
    'Badia Calavena',
    'Stretti',
    'Castellana Grotte',
    'Rivello',
    'Altavilla Milicia',
    'Cascia',
    'Roccaromana',
    'Zangarona',
    'Boretto',
    'Langhirano',
    'Ferriere',
    'Piandimeleto',
    'Monte San Giusto',
    'Sorgono',
    'Pietraperzia',
    'Zambrone',
    'Vernio',
    'Chiusano di San Domenico',
    'Tagliacozzo',
    'Palagonia',
    'Mondragone',
    'Ponte Buggianese',
    'Poggiardo',
    'Isola del Giglio',
    'Volterra',
    'Taurianova',
    'Africo Nuovo',
    'Porto',
    'Cugnoli',
    'Gissi',
    'Villafranca Tirrena',
    'Francofonte',
    'Serra Pedace',
    'San Gennaro Vesuviano',
    'Serre',
    'Picerno',
    'Frasso Telesino',
    'Concordia sulla Secchia',
    'Vacri',
    'Tortoreto',
    'Buggiano',
    'Pignataro Interamna',
    'Santi Cosma e Damiano',
    'Tuglie',
    "Zanca-Sant'Andrea",
    'Sulzano',
    'Bruino',
    'Marone',
    'Brusnengo',
    'San Benigno Canavese',
    'Origgio',
    'Marignano',
    'Selva',
    'Bastiglia',
    'Ravarino',
    'Tirrenia',
    'Palmas Arborea',
    'Colliano',
    'Cavallino',
    'San Piero Patti',
    'Casamarciano',
    'Montefredane',
    'Manocalzati',
    'Sortino',
    'Golfo Aranci',
    'Serramezzana',
    'Airola',
    'Roccabernarda',
    'Filadelfia',
    'Soveria Mannelli',
    'Caldarola',
    'Vizzini',
    'Ponte a Moriano',
    'Santa Fiora',
    'Podenzana',
    "Santo Stefano d'Aveto",
    'San Colombano CertÃ©noli',
    'Moneglia',
    'Mignanego',
    'Campofilone',
    'Petritoli',
    'Colle',
    'Mantovana',
    'Montanara',
    'Alcara li Fusi',
    'Seminara',
    'Moglia',
    'Acquafredda',
    'Gualtieri',
    'Gottolengo',
    'Orzinuovi',
    'Bronzolo',
    'Aldeno',
    'Dro',
    'Bosisio Parini',
    'Casaloldo',
    'Rivarolo del Re ed Uniti',
    'Mesola',
    'Piano di Coreglia-Ghivizzano',
    'Viserba',
    'Robecco sul Naviglio',
    'Marsciano',
    'Nanto',
    'Chiusanico',
    'Feroleto della Chiesa',
    'Valdieri',
    'Montalenghe',
    'Cintano',
    "Costigliole d'Asti",
    'Pessione',
    'Casalmaggiore',
    'Nimis',
    'Lizzola',
    'Piadena',
    'Rodeneck-Rodengo',
    'Auronzo di Cadore',
    'Santa Lucia',
    'Tarquinia',
    'Terrasini',
    'Castelraimondo',
    'Pontida',
    'Fontanella',
    'Carenno',
    'Casalromano',
    'Poggio Rusco',
    'San Vito di Cadore',
    'Cassolnovo',
    'Casorate Primo',
    'Robbio',
    'Viagrande',
    'Torrebelvicino',
    'San Nazario',
    'Salzano',
    'Vellezzo Bellini',
    'Bressana Bottarone',
    'La Morra',
    'Bollengo',
    'Badia Polesine',
    'Mereto di Tomba',
    'Campoformido',
    'Cagliari',
    'Inveruno',
    'Berceto',
    'Guiglia',
    'Roncalceci',
    "Barano d'Ischia",
    'San Felice sul Panaro',
    'Ferrara',
    'Galliera',
    'Vigarano Pieve',
    'Bari Sardo',
    "Corigliano d'Otranto",
    'Francavilla Fontana',
    'Riesi',
    'Chiaravalle Centrale',
    'Esperia',
    'Partanna',
    'Capistrello',
    'San Giustino',
    'Giove',
    'Montefranco',
    'Alatri',
    'Norma',
    'San Felice Circeo',
    'Ugento',
    'Caggiano',
    'Roccamonfina',
    'Locorotondo',
    'Grimaldi',
    'San Rufo',
    'Scanzano Jonico',
    'Castelbuono',
    'Misilmeri',
    'Cave',
    'Stagno',
    'Ribolla',
    'San Pancrazio Salentino',
    'Davoli',
    'Castiglion Fiorentino',
    'Brancaleone',
    'Vallefiorita',
    'Prata',
    "San Mango d'Aquino",
    'Casaratta',
    'Volla',
    'Minervino Murge',
    'Canosa di Puglia',
    'Altidona',
    'Vezzano sul Crostolo',
    'Monte Vidon Corrado',
    'San Ferdinando di Puglia',
    'Thiesi',
    'Silanus',
    'Adrano',
    'Spezzano Albanese',
    'Tropea',
    'Civitella Marittima',
    "Barberino Val d'Elsa",
    'Seano',
    'Aiello del Sabato',
    'Cattolica Eraclea',
    'Atri',
    'Rio Marina',
    'Blera',
    'Fusignano',
    'Talsano',
    'Riolo Terme',
    'Castelnovo di Sotto',
    'Migliarino',
    'Bernate Ticino',
    'Murlo',
    'Cusano Mutri',
    'Buonabitacolo',
    'Bompietro',
    'Montorio al Vomano',
    'Castiglione di Garfagnana',
    'Bagnara di Romagna',
    'San Pietro Vernotico',
    'Passo Corese',
    'Montalto Uffugo',
    'Pontecagnano',
    'Vieste',
    'Butera',
    'Siderno',
    'Spezzano della Sila',
    'Lauro',
    'Montevago',
    'Vanzaghello',
    'Saturnia',
    'Civitella San Paolo',
    'San Secondo Parmense',
    'Pietracuta',
    'Arquata Scrivia',
    'Pogliano Milanese',
    'Flero',
    'Caorle',
    'Mortegliano',
    'Villanuova sul Clisi',
    'Cairate',
    "Sant'Antonino",
    'Monteriggioni',
    'Ponso',
    'Corno Giovine',
    'Mascali',
    'Rossano',
    'Rocca di Papa',
    'Polla',
    'Poggio Mirteto',
    "Sant'Andrea",
    'Corigliano Calabro',
    'Futani',
    'San Cipirello',
    'Sala Consilina',
    'Torviscosa',
    'Quinto Vercellese',
    'Mergozzo',
    'Livorno Ferraris',
    'Breno',
    'Arignano',
    'San Gillio',
    'Moretta',
    'La Salle',
    'Foiano della Chiana',
    'Borgaro Torinese',
    'Trana',
    'Chianciano Terme',
    'Ospedaletti',
    'Soncino',
    'Dubino',
    'Vescovato',
    'Bienno',
    'Acquafredda Inferiore',
    'Prad am Stilfser Joch',
    'Pignone',
    'Campomorone',
    'San Fiorano',
    'Confienza',
    'Canaro',
    'Ozegna',
    'Gaggio Montano',
    'Collepasso',
    'Lugnano in Teverina',
    'Sangano',
    'Carlino',
    'Loria',
    'Bagnolo San Vito',
    'La Salute di Livenza',
    'Custonaci',
    'Porto Empedocle',
    'Roasio',
    'Beinette',
    'Edolo',
    'Neumarkt',
    'Naturns',
    'Bertonico',
    'Turbigo',
    'Filighera',
    'Giussago',
    'Senago',
    'Follina',
    'San Germano Vercellese',
    'Buriasco',
    'Oulx',
    'San Lorenzo Isontino',
    'San Quirino',
    'Marina di Ragusa',
    'Tramonti',
    'Sibari',
    'Praia a Mare',
    'Rogliano',
    'Codigoro',
    'Altedo',
    'Serra San Quirico',
    'Curcuris',
    'Scicli',
    'Baiano',
    'Villa San Giovanni',
    'Casteldaccia',
    'Santo Stefano di Briga',
    'Pianella',
    'Moscufo',
    'Lettopalena',
    'Giove',
    'Caianello',
    'Lauria',
    'Lari',
    "Lugagnano Val d'Arda",
    'Santa Sofia',
    'Latiano',
    'Troia',
    'Nicolosi',
    'Casal di Principe',
    'Voghenza',
    'Benestare',
    'Monastir',
    'Tertenia',
    'Masullas',
    'Formicola',
    'Zafferana Etnea',
    'Canicattini Bagni',
    'Siculiana',
    'Miglianico',
    'San Giovanni Suergiu',
    'Vigarano Mainarda',
    'Rocca Priora',
    'Carpineto Romano',
    "Sant'Agata sul Santerno",
    'Gambara',
    'Serraglio',
    'Cimitile',
    'Popoli',
    'Cortandone',
    'BianzÃ¨',
    'Terruggia',
    'Barzana',
    'San Severino Marche',
    'San Lorenzo',
    'Sexten',
    'Avio',
    'Figino',
    'Cozzo',
    'Caravate',
    'Copiano',
    'Valle Lomellina',
    'Arqua Polesine',
    'Lombardore',
    'Scarmagno',
    'Borgone',
    'San Pietro di Cadore',
    'Montegaldella',
    'Arzignano',
    'Lendinara',
    "Fara Gera d'Adda",
    'Montalbano',
    'Graglia',
    'San Carlo',
    'Pezzana',
    'Ghisalba',
    'Calvisano',
    'Corte Franca',
    'Villastrada',
    'Lajen',
    'Cittiglio',
    'Caselle Lurani',
    'Pantigliate',
    'Conselve',
    'Bevilacqua',
    'Cervo',
    'Moimacco',
    'Ponte nelle Alpi',
    'Ponte',
    'Agnone',
    'Castelfranci',
    'Paternopoli',
    'Villamar',
    'Barumini',
    'Rizziconi',
    'Cinquefrondi',
    'Montemiletto',
    'Pelago',
    'Buti',
    'Ancarano',
    'Larino',
    'Arrone',
    'Castagneto Carducci',
    'Lido di Camaiore',
    'Castelnuovo di Val di Cecina',
    'Marlia',
    'Vasanello',
    'Lenola',
    'Camerlona',
    'Tufino',
    'Rovito',
    'Muro Lucano',
    'Moio della Civitella',
    'Bellona',
    'Strongoli',
    'Loiano',
    'Mergo',
    'Bernalda',
    'Verbicaro',
    'Longobardi',
    'Pontassieve',
    "Sant'Andrea di Conza",
    'Ventimiglia di Sicilia',
    'Corropoli',
    'Ponza',
    'Policoro',
    'Monteiasi',
    'Mottola',
    'Calcio',
    'Roveredo in Piano',
    'Bovolenta',
    'Corsano',
    'Dronero',
    "Farra d'Alpago",
    'Balvano',
    'Panaro',
    'Druogno',
    'Traona',
    'Filo',
    'Ascoli Satriano',
    'Circello',
    'Fornovo di Taro',
    'Argenta',
    'Monteforte Irpino',
    'Avola',
    'Milo',
    'Fossacesia',
    'Abbadia San Salvatore',
    'Locati',
    'Santo Stefano Quisquina',
    "Sant'Omero",
    'Castello',
    'Castelnuovo di Porto',
    'Carovigno',
    'Malalbergo',
    'Montegiorgio',
    "Torre de' Passeri",
    'Loreto Aprutino',
    'Monsampolo del Tronto',
    'Rivalta',
    'Cadeo',
    'Ghiffa',
    'Castellar Guidobono',
    'Valfenera',
    'Camisano',
    'Gazzuolo',
    'Comano',
    'Sale Marasino',
    'Longarone',
    'San Marco',
    'San Giorgio Canavese',
    'Ala di Stura',
    'Montecassiano',
    'Buttrio',
    'Favaro Veneto',
    'MÃ¼hlbach',
    'Caldonazzo',
    'Ponte Basso',
    'Calitri',
    'San Mango',
    'Nocera Terinese',
    'Armeno',
    'Gardone Val Trompia',
    'Gonzaga',
    'Toblach',
    "Villafranca d'Asti",
    'Bornasco',
    'Lomello',
    'Arcugnano',
    'Pocenia',
    'Moiano',
    'Triggianello',
    'Nuraminis',
    'Nurri',
    'Chiaramonte Gulfi',
    'Borgia',
    'Laureana di Borrello',
    'Laino Borgo',
    'Castel San Lorenzo',
    'Pisticci',
    'Raffadali',
    'Bagni di Lucca',
    'Arce',
    'Lesina',
    'Surbo',
    'Alliste',
    'Poggio Moiano',
    'Cerreto Laziale',
    'San Filippo',
    'Pianella',
    'Potenza Picena',
    'Ricadi',
    'Poggio a Caiano',
    'Aquilonia',
    'Colonnella',
    'Mugnano',
    'Atina',
    'Campi Salentina',
    'San Giovanni al Natisone',
    'Neive',
    'Castagnole',
    'Crespano del Grappa',
    'Molazzana',
    'Ottiglio',
    'Issime',
    'Cavriana',
    'Tresenda',
    'Grigno',
    'Rossiglione',
    'Mede',
    'Rognano',
    'Vernate',
    'Solesino',
    'Ceregnano',
    'Cuceglio',
    'Torrazza',
    'CarrÃ¹',
    'San Martino al Tagliamento',
    'Revine',
    'Pian Camuno',
    'Zimella',
    'Taverna',
    'Poggio Berni',
    'Gambettola',
    "Sant'Angelo in Vado",
    'Venarotta',
    'San Marco in Lamis',
    'Fluminimaggiore',
    'Cavriago',
    'San Giorgio a Liri',
    'Biancano',
    'San Marco',
    'Gallese',
    'Portico di Caserta',
    'Santa Elisabetta',
    'Vallo della Lucania',
    'Cappelle sul Tavo',
    'Baschi',
    'Vajont',
    'Camino',
    'Barile',
    'Diecimo',
    'Campiglia Marittima',
    'Castel Giorgio',
    'Galatina',
    'Goito',
    'Oliva',
    'Anfo',
    "Sant'Antonino di Susa",
    'Fontanarosa',
    'San Pietro Viminario',
    'Annicco',
    'Pianezze',
    'San Martino',
    'Bellariva',
    'Bedollo',
    'Bosco Chiesanuova',
    'Spin',
    'Rivodutri',
    'Poggio Nativo',
    'Fara in Sabina',
    'Cantalice',
    'Battaglia Terme',
    'Fiera',
    'Regina',
    'Pian-Fossal',
    'Gabbiano',
    'Postiglione',
    'Abetone',
    'Roccella',
    'Montorso Vicentino',
    'Coppe',
    'San Polo',
    'Medea',
    'Lapio',
    "Fiesso d'Artico",
    'San Vittore',
    'Sotto Castello',
    'Gorle',
    'Varese Ligure',
    'Rocchetta di Vara',
    'Gazzola',
    'Sernaglia della Battaglia',
    'Cordignano',
    'Pero',
    'Pieve del Cairo',
    'Villetta',
    'Capriolo',
    'Poggio Renatico',
    'Spineda',
    'San Giorgio della Richinvelda',
    'Scaldasole',
    'Rodigo',
    'Mathi',
    'Duino-Aurisina',
    'Villa Bartolomea',
    'Bagnoli di Sopra',
    'Numana',
    'Ceprano',
    'Minori',
    'San Miniato Basso',
    'Lercara Friddi',
    'Castello',
    'Renazzo',
    'Ramiseto',
    'San Giovanni in Fiore',
    "Robecco d'Oglio",
    'Pozzonovo',
    'Villa Verucchio',
    'Gardone Riviera',
    'Tassullo',
    'Agliana',
    'Piancastagnaio',
    'Roseto Valfortore',
    'Villa Basilica',
    'Roccarainola',
    'Niederdorf',
    'Venegono Inferiore',
    'Valli del Pasubio',
    'Marcallo con Casone',
    'San Lorenzo al Mare',
    'Cavallermaggiore',
    'Montecchio',
    'Martignacco',
    'Gonnesa',
    'Revello',
    'Castellengo',
    'Torre del Lago Puccini',
    'Orta Nova',
    'Castellammare del Golfo',
    'Cuglieri',
    'Ripi',
    'Umbertide',
    'San Sperate',
    'Bonea',
    'Torre Santa Susanna',
    'Monteroni di Lecce',
    'Isola del Liri',
    'Briatico',
    'Porano',
    'Ticengo',
    'Montafia',
    'Ginosa',
    'Sassetta',
    'Bussi sul Tirino',
    'Ronciglione',
    'Vistrorio',
    'Cicagna',
    'Cesara',
    'Fiuggi',
    'Liveri',
    'Torre di Ruggiero',
    'Valganna',
    'Vena di Maida',
    'Casa Bartolacelli-Stella',
    'Arcole',
    'Piegaro',
    'Novalesa',
    'Torrile',
    'Polverara',
    'Molino',
    'Soresina',
    'Casalbuttano ed Uniti',
    'Cignone',
    'Ripalta Cremasca',
    'Pescarolo ed Uniti',
    "Cappella de' Picenardi",
    'Garibaldi',
    'Doria',
    'Riese Pio X',
    'Giuliana',
    'Inverigo',
    'Cantalupo Ligure',
    'Alice Castello',
    'Pallanzeno',
    'Briga Novarese',
    'Garlate',
    'San Zenone al Lambro',
    'Zocca',
    'Tessera',
    'Castellazzo Bormida',
    'Cremolino',
    'Beura-Cardezza',
    'Pagazzano',
    'Sanluri',
    'Lenna',
    'Roccafranca',
    'Villanterio',
    'Casaletto Spartano',
    'Montappone',
    'Belmonte Piceno',
    'Santa Maria',
    'Vicchio',
    'Ceppaloni',
    'Gaggi',
    'Isola del Gran Sasso',
    'San Sossio Baronia',
    "Montegrosso d'Asti",
    'Castel Bolognese',
    'Roncone',
    'Borgo San Giacomo',
    'Montanaso Lombardo',
    'Algund',
    'Strada San Zeno',
    'Pontelongo',
    'Bordighera',
    'Casarile',
    'Terricciola',
    'Remanzacco',
    'Casteltermini',
    'Castel del Piano',
    'Cropani',
    'Torrita di Siena',
    'Poppi',
    'Ocre',
    'Castiglione di Cervia',
    'Paciano',
    'San Marzano sul Sarno',
    'Carentino',
    'Madignano',
    'San Lazzaro degli Armeni',
    'Morter',
    'Taino',
    'Biandronno',
    'Lavena Ponte Tresa',
    'Felegara',
    'Campogalliano',
    'Villa',
    'Casalserugo',
    'Piombino Dese',
    'Pecetto',
    'Venaus',
    'Strigno',
    'Raldon',
    'Campodenno',
    'Guspini',
    'Montemaggiore al Metauro',
    'Assoro',
    'Fornaci',
    'Curinga',
    'Montalcino',
    'Pago Veiano',
    'Campobello di Mazara',
    'Catignano',
    'Oriolo Romano',
    'Tuscania',
    'Roviano',
    'Stimigliano',
    'Erve',
    'Cividate Camuno',
    'Settimo Vittone',
    'Lallio',
    'Leffe',
    'Salorno',
    "Canneto sull'Oglio",
    'Cornedo Vicentino',
    'Sillavengo',
    'Barbarano Vicentino',
    'Grantorto',
    'Eraclea',
    'La Maddalena',
    'Frascineto',
    'Tolve',
    'Santa Maria',
    'Scerni',
    'Meldola',
    'Cori',
    'Calcinate',
    'Foresto',
    'Marcaria',
    'Seniga',
    'Castellucchio',
    'Costa Volpino',
    'Bellano',
    'Viscone',
    'Ossago Lodigiano',
    'Carpineti',
    'Piazzola sul Brenta',
    'Terzorio',
    'Dego',
    'Dolceacqua',
    'Bardonecchia',
    'Crevacuore',
    'Pollein',
    'Sgonico',
    'Sutrio',
    'Lazise',
    'Appignano',
    'San Lorenzo in Campo',
    'Case Catania',
    "Sant'Arcangelo",
    'Coppito',
    'Stia',
    'San Zaccaria',
    'Castellina Marittima',
    'Vallerano',
    'San Donato',
    'Monsano',
    'Gradara',
    'San Benedetto Po',
    'Gerenzago',
    'Chiarano',
    'Maccagno',
    'Corteolona',
    'Fiesso Umbertiano',
    'Deiva Marina',
    'Terrarossa',
    'Premariacco',
    'Bianconese',
    'Basaldella',
    'Lamon',
    'Settimo San Pietro',
    'Escolca',
    'Santo Stefano di Rogliano',
    'Sersale',
    'Croce',
    'Sarteano',
    'Prata Sannita',
    'Mas',
    'Giuliano Teatino',
    'Larciano',
    'Gagliano del Capo',
    'Faggiano',
    'Alezio',
    'Carvico',
    'Capaccio',
    'Offlaga',
    'Pacentro',
    'Fosdinovo',
    "Cavenago d'Adda",
    'Paratico',
    'Torriana',
    'Lesa',
    'Cavour',
    'Tarvisio',
    'Pinzano al Tagliamento',
    'Pollenza',
    'Montefortino',
    'Levane',
    'Castignano',
    'Valle Agricola',
    'Vallata',
    'Sala Baganza',
    'Campegine',
    'Bucine',
    'Guagnano',
    'Roccadaspide',
    'Galliera Veneta',
    "Sant'Agostino",
    'Mazzo di Valtellina',
    'Villasor',
    'Paulilatino',
    'Gabbro',
    'Sindia',
    'Regina Margherita',
    'Flussio',
    'Gavoi',
    'Santo Stefano Belbo',
    'Migliaro',
    'Vo',
    'Sorisole',
    'Manziana',
    'Valentano',
    'Guardamiglio',
    'Bettola',
    'Perinaldo',
    'Brosso',
    'Codevigo',
    'Fanna',
    'Tuili',
    'Pratovecchio',
    'Vignanello',
    "Massa d'Albe",
    'Montenero di Bisaccia',
    'Panicale',
    'Cupramontana',
    'Cagli',
    'Vallombrosa',
    'Tavarnelle Val di Pesa',
    'Massa',
    'Borrello',
    'Avigliano Umbro',
    'Ravi',
    'Ortelle',
    'Gordona',
    'Esine',
    'Arcola',
    'Pellegrino Parmense',
    'Campodoro',
    'Villa',
    'Vergato',
    'Settimo',
    'Flaibano',
    'Folgaria',
    'Boffalora sopra Ticino',
    'Albizzate',
    'Monticiano',
    'Castel Gandolfo',
    'Envie',
    'Busseto',
    'Virgilio',
    'SÃ«lva',
    'Schiavon',
    'Motta San Guglielmo',
    'Baia',
    'Gazzo',
    'Melara',
    'Dossobuono',
    'Neviano degli Arduini',
    'Compiano',
    'Piano',
    'Neirone',
    'Osiglia',
    'Borghetto di Vara',
    'Caravonica',
    'Localita della Chiesa',
    'San Bartolomeo',
    'Diano San Pietro',
    'Lacco Ameno',
    'Arborio',
    'Cambiano',
    'Cerredolo',
    'Pian di ScÃ²',
    'Rassina',
    'Chitignano',
    'Altavilla',
    'Buttigliera Alta',
    'San Giovanni',
    'Coredo',
    'Pannarano',
    'Francolise',
    'San Cesario sul Panaro',
    'Salvaterra',
    'Corvara',
    'Deutschnofen',
    'Marche',
    'Frattocchie',
    'Villa Minozzo',
    'Gonnosfanadiga',
    'Arborea',
    'Albaredo',
    'Saint-Pierre',
    'Istrana',
    'Pont-Saint-Martin',
    'Castiglione',
    'Lavello',
    'Uzzano',
    'Montale',
    'Tribiano',
    'Simaxis',
    'Codrongianos',
    'Isili',
    'Siapiccia',
    'Orsenigo',
    'Castello',
    'Nave',
    'Tione di Trento',
    'San Lorenzo del Vallo',
    'Caulonia',
    'Vidigulfo',
    'Fontanelle',
    'Santa Barbara',
    'Santi',
    'Selvino',
    'San Martino Canavese',
    'Brebbia',
    'Tenno',
    'Seborga',
    'Caminetto',
    'Tina',
    'Castiglione',
    "Albaredo d'Adige",
    'San Pietro Mosezzo',
    'Marini',
    'Maggio',
    'Palazzolo dello Stella',
    'Meran',
    'Grotta',
    'Narbolia',
    'Posta Vecchia',
    'Castello',
    'Rose',
    'Fontanelle',
    'San Luca',
    'Tornaco',
    'Limatola',
    'Viano',
    'Baiso',
    'Montefiorino',
    'Maser',
    'Voltago',
    'Vigo Rendena',
    'Predazzo',
    'Vigo di Fassa',
    'Ronco',
    "Torre de' Busi",
    'Villavesco',
    'Pilcante',
    'Quistello',
    'Rossano Veneto',
    'Antignano',
    'Cerrione',
    'Campagnola',
    'Caselle',
    'Villanova',
    'Cavaria con Premezzo',
    'Castello',
    'Brissago-Valtravaglia',
    'Lozzo Atestino',
    'Soldano',
    'Castellaro',
    'Santa Vittoria di Libiola',
    'ChÃ¢tillon',
    'Breda di Piave',
    'Delianuova',
    'Lungro',
    'Corinaldo',
    'Lucera',
    'Castroreale',
    'Fuscaldo',
    'Castiglione Cosentino',
    'Corea Castelluccia San Paolo',
    'San Giovanni a Piro',
    'Prezza',
    'Lucugnano',
    'Quero',
    'Concordia Sagittaria',
    'Tarzo',
    'Burano',
    'Bogliasco',
    'San Polo di Piave',
    'Castione della Presolana',
    'Castions di Strada',
    'Mossa',
    'San Polo',
    'Ponti',
    'Ponte di Barbarano',
    'Monteroduni',
    'Borgosatollo',
    'Villapiana',
    'Osoppo',
    "Sant'Agata Feltria",
    'Monterubbiano',
    'Montefano',
    'Siamaggiore',
    'Usellus',
    'Carmignano',
    'Roccavivi',
    'Roccasecca',
    'Solarolo',
    'Vattaro',
    'Verolanuova',
    'Strona',
    'Cedrasco',
    'Breguzzo',
    'Portovenere',
    'Sparanise',
    'Calice al Cornoviglio',
    'Cisano sul Neva',
    'Airasca',
    'San Pietro di Feletto',
    'Nuvolento',
    'Ferno',
    'Stroppiana',
    'Schlanders',
    'Canazei',
    'Riomaggiore',
    'Santa Giuletta',
    'Riva Ligure',
    'Tarantasca',
    'Salassa',
    'Viguzzolo',
    'Borgo Val di Taro',
    'Rolo',
    'Castiglione dei Pepoli',
    'Appignano del Tronto',
    'Uta',
    'Girifalco',
    'Campo',
    'Forio',
    'Campolieto',
    'San Vito Romano',
    'Gradoli',
    'Patrica',
    'Otranto',
    'Cerreto Sannita',
    'Terranova da Sibari',
    'Cupra Marittima',
    'Laterina',
    'Serrara Fontana',
    'Siano',
    'Castello',
    'Grottolella',
    'Piedimonte San Germano',
    'Civitella di Romagna',
    'Presicce',
    'Castelcucco',
    'Piavon',
    'Capriva del Friuli',
    'Pieve di Cadore',
    'San Biagio',
    'Conco',
    'Provesano',
    'Deggiano',
    'Sizzano',
    'Tres',
    'Marola',
    'Correzzola',
    'Nove',
    'Ceva',
    'Sparone',
    'Caselette',
    'Montefiascone',
    'Melissano',
    'Luzzano',
    'Capodrise',
    'Staffolo',
    'Santadi',
    'Librizzi',
    'Oliveri',
    'Chiusi',
    'San Lorenzello',
    'Isola delle Femmine',
    'Tornareccio',
    'Valfabbrica',
    'Senerchia',
    'Torre Orsaia',
    'Maratea',
    "Castell'Arquato",
    'Brescello',
    'Piangipane',
    'Subbiano',
    'Condofuri',
    'Crosia',
    'Polistena',
    'Montepaone',
    'Trasacco',
    'Roccavivara',
    'Stigliano',
    'Porto Azzurro',
    'Paglieta',
    'Arsita',
    'Fabrica di Roma',
    'Boves',
    'Calvenzano',
    'Monzambano',
    'Spinazzola',
    'Campofelice di Roccella',
    'Massa Marittima',
    'Naro',
    'Castel San NiccolÃ²',
    'Casalmorano',
    'Soave',
    'Lesegno',
    'Calendasco',
    'Volpedo',
    'Serramanna',
    'Fontanelice',
    'Casa Pace',
    'Fara Filiorum Petri',
    'Lappato',
    'Melendugno',
    'Casale Corte Cerro',
    'Caselle',
    'Gargnano',
    'Fiano',
    'LoranzÃ¨',
    'Isola',
    'Villar',
    'Forno Alpi Graie',
    'Caraglio',
    "Rivolta d'Adda",
    'Bonavicina',
    "San Damiano d'Asti",
    'Monasterolo del Castello',
    'Acquaviva',
    'Trevenzuolo',
    'Bovegno',
    'Qualso',
    'Riccovolto',
    "Castel d'Aiano",
    'Mandas',
    'Siamanna',
    'Guardia Piemontese',
    'Taurano',
    'Lugnano',
    'Santa Maria Codifiume',
    'Capoliveri',
    'Rotondi',
    'Basilicanova',
    'Amantea',
    'San Marco dei Cavoti',
    'Marzano di Nola',
    'Volturara Irpina',
    'Caronia',
    'Porto Ercole',
    'Palaia',
    'Castrocaro Terme',
    'Conselice',
    'Mercatino Conca',
    'Torre a Mare',
    'Arpino',
    'Orani',
    'Arpaia',
    'Foiano di Val Fortore',
    'Delia',
    'Pescina',
    'Trevico',
    'Giarratana',
    'Falconara Albanese',
    'Palizzi Marina',
    'Capodimonte',
    'Sperlonga',
    'Pennabilli',
    'Monterchi',
    'Sassinoro',
    'Tramutola',
    'Ponte',
    'Pistrino',
    'San Mariano',
    'Marzano',
    'Palagianello',
    'Luzzi',
    'Lama Mocogno',
    'Predore',
    'Tiriolo',
    'Ghilarza',
    'Cercepiccola',
    'Forano',
    'Vivaro',
    'Masera',
    'Cartura',
    'Innichen',
    'Visinale',
    'Castelgomberto',
    'San Paolo',
    'Gavinana',
    'Bivongi',
    'Nusco',
    'San Giovanni Gemini',
    'Berbenno di Valtellina',
    'Paderno Ponchielli',
    'Santo Stefano di Cadore',
    'Noli',
    'Buttapietra',
    'Ispra',
    'Charvensod',
    'Pescocostanzo',
    'Castellaneta',
    'Montepulciano Stazione',
    'Ronchi',
    'Pieve',
    'Povegliano',
    'Tusa',
    "Monteforte d'Alpone",
    'Meolo',
    'Loro Piceno',
    'Prata di Pordenone',
    'Ratschings',
    'Torrenova',
    'San Marzano di San Giuseppe',
    'Greci',
    'Pellestrina',
    'Pettinengo',
    'Orotelli',
    'Pettoranello del Molise',
    'Santo Stino di Livenza',
    'Panzano',
    'Marina di Castagneto Carducci',
    'Valle',
    'Lingotto',
    'Albina',
    'San Pietro',
    'Bosco',
    'Fiore',
    'Corte',
    'Porta',
    'Pavia di Udine',
    'Collefontana-Fontana Liri Inferiore',
    'Torre',
    'Brenner',
    'Mora',
    'Bosco',
    'Rocco',
    'Amato',
    'Meduna di Livenza',
    'Fossalta di Piave',
    'Giuliano',
    'Fosco',
    'Rossi',
    'Bagnoli Irpino',
    'Albergo',
    'Castelli',
    'Palazzo',
    'Carcereri',
    'Luca',
    'Giardini',
    'Palladio',
    'La Gala',
    'Aiello',
    'Villaggio',
    'Trabia',
    'Ai Palazzi',
    'Cecchini',
    'Palazzina',
    'Borghetto',
    'Luciana',
    'Certosa',
    'Grottaminarda',
    'Sabatino',
    'Mariae',
    'Sacco',
    'Boville Ernica',
    'Attimis',
    'Macello',
    'Barge',
    'La Loggia',
    'Ramponio',
    'Casate Raffa',
    'DolcÃ¨',
    'Premana',
    'Ormelle',
    'Furore',
    'Giungano',
    'Pollica',
    'Sordevolo',
    'Vespolate',
    'Tirano',
    'Zoagli',
    'PalÃ¹',
    'Cese',
    'Belvedere Ostrense',
    'Villa Marina',
    'San Secondo',
    'Cannella',
    'San Gregorio',
    'Martignano',
    'Roiano',
    'Borgo Tossignano',
    'Agugliaro',
    'Sossano',
    'Sand in Taufers',
    'Termeno',
    'Rignano Garganico',
    'Castelnuovo della Daunia',
    'Volturino',
    'Carpignano Sesia',
    'Colombaro',
    'Limite',
    'Perfugas',
    'Ferrero',
    'Guardialfiera',
    'Novate Mezzola',
    'Pieve Vecchia',
    'Endine Gaiano',
    'Giano Vetusto',
    'Castelliri',
    'Campoli Appennino',
    'Scansano',
    'Decollatura',
    'Nocelleto',
    'Rivergaro',
    'Mairano',
    'Ferrandina',
    'Castelvecchio Pascoli',
    'Ceggia',
    'Vignacastrisi',
    'Tratalias',
    'Serra',
    'Marina di Andora',
    'Romanengo',
    "Casirate d'Adda",
    'Carbonara al Ticino',
    'Mulazzano',
    'Agazzano',
    'Malgrate',
    'San Fedele',
    'Brunate',
    "Brignano Gera d'Adda",
    'Trigolo',
    'Campagnola Emilia',
    "Monticelli d'Ongina",
    'Madonna di Campiglio',
    'Pieve di Soligo',
    'Longare',
    'Vigonovo',
    'Etroubles',
    'Limone Piemonte',
    'Stazzano',
    'Terenzo',
    'Nurallao',
    'Capranica',
    'Ficulle',
    'Albanella',
    'Bisacquino',
    'CanicattÃ¬',
    'Alanno',
    'Modigliana',
    'Treia',
    'Campli',
    "Serra de' Conti",
    'Serracapriola',
    'Rodi Garganico',
    'San Donato di Lecce',
    'Solopaca',
    'Pignataro Maggiore',
    'Pachino',
    'Guarrato',
    'Perdasdefogu',
    'Riparbella',
    'Orbetello',
    'Rodano',
    'Soriano nel Cimino',
    'Santa Maria',
    'Miglionico',
    'Latronico',
    'Magliano Sabina',
    'Isola del Cantone',
    'Costarainera',
    'Condove',
    'Frigento',
    'Montecosaro',
    'Montescudo',
    'Carloforte',
    'Villabate',
    'Attigliano',
    'Antella',
    'Serrano',
    'Maltignano',
    'Polesella',
    'Quaregna',
    'Cison di Valmarino',
    'Cavalese',
    'Badia',
    'Morolo',
    "Castiglione d'Adda",
    'ViggiÃ¹',
    'Vicarello',
    'Montepulciano',
    'Tavarnuzze',
    'Lezzeno',
    'Induno Olona',
    'Benetutti',
    'Bonarcado',
    'Alpette',
    'Ostigliano',
    'Quiliano',
    'Pieve di Cento',
    'Veronella',
    'TortolÃ¬',
    'Ceriano Laghetto',
    'Sovere',
    'Azzano',
    'Capralba',
    "Quinzano d'Oglio",
    'Mirabello Monferrato',
    'Parre',
    'Francenigo',
    'Castelnuovo Berardenga',
    'Marciana Marina',
    'Soci',
    'Arcidosso',
    'Centola',
    'Gragnano Trebbiense',
    'Ronchis',
    "Puos d'Alpago",
    'Cariati',
    'Montalto di Castro',
    'Sonnino',
    'Torri in Sabina',
    'Borgo Priolo',
    'Besano',
    'Levata',
    'Berzo Demo',
    'Revere',
    'Pieve San Giacomo',
    'San Giusto Canavese',
    'Ameno',
    'Omignano',
    'Foglianise',
    'Bassano in Teverina',
    'Lunamatrona',
    'Moricone',
    'Camburzano',
    "Sasso d'Ombrone",
    'Martiniana Po',
    'San Pietro',
    'Sassello',
    'Fara Novarese',
    'Copertino',
    'Carolei',
    'Maccarese',
    'Pirri',
    'Santo Spirito',
    'Sapri',
    'Venosa',
    'Basciano',
    'Cellino Attanasio',
    'Lastra',
    'Montecchia di Crosara',
    'Monteviale',
    'FiavÃ¨',
    'Lago',
    'Pertosa',
    'Ronco-Chiesa',
    'Pray',
    'Carpinello',
    'Lago',
    'Luciano',
    'Camisa',
    'Sommariva Perno',
    'Boschetto',
    'Ville',
    'Casa Gherardi',
    'MalÃ¨',
    'Rovere',
    'Giardinetto Vecchio',
    'Pellegrini',
    'Bettolle',
    'Leonessa',
    'Chiara',
    'Spinetta Marengo',
    'Balzola',
    'Lustra',
    'Bressanvido',
    'Gangi',
    'Benevello',
    'Perteole',
    'Cusino',
    "Torre d'Isola",
    'Frugarolo',
    'Teglio Veneto',
    'San Valentino Torio',
    'Baruchella',
    'Cilavegna',
    'Vidardo',
    'Montemarano',
    'Marta',
    'Germagnano',
    'Lutago',
    'Bargagli',
    'San Prospero',
    'Mozzecane',
    'San Mauro',
    'Montesano Salentino',
    'Ponticelli',
    'Camporosso',
    "Porto d'Ascoli",
    'Monforte San Giorgio',
    'Quarto Inferiore',
    'Zermeghedo',
    'Bolzano',
    'Faiano',
    'Orgosolo',
    'Piobesi Torinese',
    'Bonassola',
    'Peschici',
    'Fonzaso',
    'Mistretta',
    'Petriano',
    'Courmayeur',
    'Serradifalco',
    'Roggiano Gravina',
    'Riola Sardo',
    'Guardia Sanframondi',
    'Pietrapaola',
    'Falerna',
    'Civitanova Alta',
    'Sedegliano',
    'Savio',
    'Cellio',
    'Pozzo',
    'Castel Gabbiano',
    'Sospiro',
    'San Giacomo',
    'Trovo',
    'Villa Estense',
    'Boara',
    'Villa Santa Lucia',
    'Binanuova',
    'Pombia',
    'Medole',
    'Ostiano',
    'Pozza di Fassa',
    'Pinzolo',
    'Bucciano',
    'Pratola Peligna',
    'Santa Maria Imbaro',
    'Chiusavecchia',
    'Mirabella Imbaccari',
    'Montescaglioso',
    'Bonorva',
    'Trivignano',
    'Sarmede',
    'San Martino in Pensilis',
    'San Giacomo degli Schiavoni',
    'Rota Greca',
    'Vado',
    'Villamassargia',
    'Cabras',
    'San Vito',
    'Perdaxius',
    'Collinas',
    'Martirano',
    'Grassano',
    'Castrofilippo',
    'Mozzagrogna',
    'Scanno',
    'Contrada',
    'Piazza al Serchio',
    'Magliano',
    'Piazza Roma',
    'Mignano Monte Lungo',
    'Villanova',
    'Petilia Policastro',
    'Montepiano',
    'Pauli Arbarei',
    'Riva',
    'Campodipietra',
    'Parghelia',
    'Matino',
    'Castri di Lecce',
    'Roccabianca',
    'Santa Maria',
    'Montelanico',
    'Arizzano',
    'Gais',
    'Zambana',
    'Canonica',
    "Sannazzaro de' Burgondi",
    'DonorÃ¬',
    'Alvignanello',
    'Ponte Lambro',
    'Mogliano',
    'Oliena',
    'San Teodoro',
    'Meana Sardo',
    'Sperone',
    'Lammari',
    'Campora',
    'Forino',
    'Melito di Porto Salvo',
    'Peccioli',
    'Ravello',
    'Gallicano',
    'Casperia',
    'Solato',
    'Chiavazza',
    'Welschnofen',
    'Casola di Napoli',
    'Cannero Riviera',
    'Lurago Marinone',
    'Lenno',
    'Nocciano',
    'Grisignano di Zocco',
    'Quattro Castella',
    'Ragogna',
    'Piumazzo',
    'Castello',
    'Ischitella',
    'Grazzanise',
    'Cagnano Varano',
    "Montopoli in Val d'Arno",
    'Arzachena',
    'San Dorligo della Valle',
    'Corno di Rosazzo',
    'Zelarino',
    'Pietra',
    'Zinasco',
    'Tonezza del Cimone',
    'Vitulano',
    'San Martino Sannita',
    'Sturno',
    'Rotello',
    'Santa Maria Nuova',
    'Magliano di Tenna',
    'Boccheggiano',
    'Maierato',
    'Monasterace',
    'San Fili',
    'Roseto Capo Spulico',
    'Segariu',
    'Varapodio',
    'San Sisto',
    'San Nicola Arcella',
    'Pastene',
    'Pozzilli',
    'Trivento',
    'Ficarra',
    'Mammola',
    'Apice',
    'Melizzano',
    'Praiano',
    'Borgo Sabotino-Foce Verde',
    'Solto Collina',
    'Caprino Bergamasco',
    'Meano',
    'Porpetto',
    'Cancello',
    "Morro d'Oro",
    'Santa Vittoria in Matenano',
    'Bitti',
    'Armento',
    'Taranta Peligna',
    'Ferrazzano',
    "Castello d'Argile",
    'Racale',
    'Cavezzo',
    "Montefiore dell'Aso",
    'Stroncone',
    'Acquappesa',
    'Passo di Treia',
    'Caspoggio',
    'Capalbio',
    'Ururi',
    'Castagnaro',
    'Torgnon',
    'San Salvatore Monferrato',
    'Pralungo',
    'San Martino',
    'Vidiciatico',
    'San Martino',
    'Varzo',
    'Moena',
    'Magno',
    'Forno',
    'Case',
    'San Pietro',
    'Pinarella',
    'Milano Marittima',
    'Barisciano',
    'Scoppito',
    'Casignana',
    'Frabosa Sottana',
    'Pontremoli',
    'Santo Stefano al Mare',
    'Ostiglia',
    'Villa di Tirano',
    "Castello dell'Acqua",
    'Metaponto',
    'Paruzzaro',
    'Champdepraz',
    'Pancalieri',
    'Pievebovigliana',
    'Petruro',
    'Novi Velia',
    'Perdifumo',
    'Montecorice',
    'Piano-Vetrale',
    'Scuola',
    'Casalzuigno',
    'Orta San Giulio',
    'Sartirana Lomellina',
    'Stazione Castelguelfo',
    'Toro',
    'Larderello',
    'Belsito',
    'Ripa',
    'Bossolasco',
    'San Vero Milis',
    'Poggio di Roio',
    'Belfiore',
    'Alvito',
    'San Lorenzo',
    'Lido',
    'Candiana',
    'Cornaiano',
    'Casei Gerola',
    'Pozzolo',
    'Santa Cristina',
    'Cisliano',
    'Crodo',
    'Pogliano',
    'Castelseprio',
    'Acquapendente',
    'Vicovaro',
    'Corchiano',
    'Borgorose',
    'Ponzano',
    'Canino',
    'Supino',
    'Castello di Annone',
    'Caprese Michelangelo',
    'Limiti di Greccio',
    'Godo',
    'Monticelli',
    'Filetto',
    'Lestizza',
    "Godega di Sant'Urbano",
    'Begliano',
    'Paderno',
    'Villa del Conte',
    'Maerne',
    'Sottomarina',
    'Sambruson',
    'Nociglia',
    'Palmariggi',
    'CirÃ²',
    'Drapia',
    'Pezze di Greco',
    'Lizzanello',
    'Martano',
    'Mareo',
    'Enemonzo-Quinis',
    'Valle di Cadore',
    'Faedis',
    'Borgetto',
    'Giardinello',
    'Grotte',
    'Montodine',
    'Cumignano sul Naviglio',
    'Zandobbio',
    'Gergei',
    'Castiadas',
    'Santu Lussurgiu',
    'Oria',
    'Transacqua',
    'Cocconato',
    'Pralormo',
    'Garessio',
    'Fenile',
    'Paesana',
    'Prato Sesia',
    'Quagliuzzo',
    'San Bernardino',
    "Vaprio d'Agogna",
    'Volano',
    'Ripoli',
    'Celenza Valfortore',
    'Carpiano',
    'Fanano',
    'Solignano Nuovo',
    'Santa Ninfa',
    'Bagnara',
    'Pignola',
    'Villa Morelli',
    'Secondigliano',
    'Furci Siculo',
    'Randazzo',
    'Palazzolo',
    'Senise',
    'San Salvatore Telesino',
    'Pisciotta',
    'Quindici',
    'Scala',
    'Montecchio',
    'Porto Recanati',
    'Tuoro sul Trasimeno',
    'Monsampietro Morico',
    'Frisa',
    'Civitella Roveto',
    'Carsoli',
    'Garrufo',
    'Casoli',
    'Mercato',
    'Agnadello',
    'Collazzone',
    'Monte Castello di Vibio',
    'Spina',
    'Collelungo',
    'Saccolongo',
    'Vergano-Villa',
    'Casciana Terme',
    'Mottafollone',
    'Serle',
    'Arta Terme',
    'Rotella',
    'Monte Giberto',
    'Capitignano',
    'Montereale',
    'Fossa',
    'Castel di Judica',
    'Gessopalena',
    'Quadri',
    'Massa Fiscaglia',
    'Villa Ottone',
    'Casalvolone',
    'Albareto',
    'Carbonara di Po',
    'Albano di Lucania',
    'Aglientu',
    'Travo',
    'Simone',
    'Agro',
    'Poggio San Lorenzo',
    'Mauro',
    'Sinopoli',
    'Tursi',
    'Pantano',
    'Field',
    'Capo Rizzuto',
    'Lardirago',
    'Novello',
    'Solarolo',
    'Pollina',
    'Mezzolara',
    'Pozzolengo',
    'Dosolo',
    'Castel di Casio',
    'Teglio',
    'Sori',
    "Sant'Angelo dei Lombardi",
    'Piateda',
    'Marina di Ginosa',
    'Lajatico',
    'Morgex',
    'Pitigliano',
    'Lagosanto',
    'Ariano Ferrarese',
    'Gallio',
    'Pozzoleone',
    'Stienta',
    'Massa Martana',
    'RoncÃ ',
    'San Bassano',
    "Isola Sant'Antonio",
    'Maruggio',
    'Corbola',
    'Albettone',
    'Gambellara',
    'Calvanico',
    'Montereale Valcellina',
    'Pedavena',
    'Feletto Umberto',
    'Piobbico',
    'Torrevecchia Teatina',
    'Baselga di PinÃ¨',
    'Taibon Agordino',
    'Orosei',
    'Usini',
    'Santa Teresa Gallura',
    'Serra San Bruno',
    'Tricarico',
    'Porto Tolle',
    'San Zenone al Po',
    'Bettona',
    'Borgo',
    'Vigo di Cadore',
    'Biancade',
    'Lazzaro',
    'Casaccia II',
    'Paganica',
    'Dovadola',
    'San Michele Prazzo',
    'Pont',
    'Molise',
    'Ramon',
    'Pont-Canavese',
    'Maddalena di Cazzano',
    'Galeata',
    'Sassa',
    'Corti',
    'Marsico Nuovo',
    'Baragiano',
    'Ruoti',
    'Castelgrande',
    'Bella',
    'Vaglio Basilicata',
    'Cantarana',
    'Cunico',
    'Casalborgone',
    'Camino',
    'Canosa Sannita',
    'Castiglione Messer Raimondo',
    'Capannelle',
    "Fontaneto d'Agogna",
    'Perignano',
    'Bibbona',
    'Borgonovo',
    "Sant'Angelo Romano",
    'Cameriano',
    'Pertengo',
    'Caresana',
    'Offagna',
    'La Villa',
    'Malonno',
    'Badia',
    'Mals',
    'Natz-Schabs',
    'Casateia',
    'Sarnthein',
    'Ponte Galeria-La Pisana',
    'Minervino di Lecce',
    'Neviano',
    'Morciano di Leuca',
    'Monte Roberto',
    'Treglio',
    'Casacanditella',
    'Mojo Alcantara',
    'MÃ¼hlwald',
    'Terenten',
    'Resia',
    'Anterselva di Mezzo',
    'Solda',
    'Polinago',
    'San Venanzio',
    'Pernumia',
    'Castel Focognano',
    'Bagni',
    'Spirano',
    'Salento',
    'Molinari',
    'Colonna',
    'Cinecitta',
    'Borgo a Mozzano',
    'Brossasco',
    'Salmour',
    'Malcontenta',
    'Baluello',
    'Loreo',
    'Pieve Fosciana',
    'Campoleone',
    'Casa Zola',
    'San Lorenzo',
    'Torretta',
    'Sella',
    'Bagnasco',
    'Fabbrica',
    'San Nicolo',
    'San Nicola',
    'Ambra',
    'Oliveto',
    'Passo',
    'Casavecchia',
    'Santa Severa',
    'Torrecuso',
    'Coltura',
    'Antonimina',
    'Genivolta',
    "Crotta d'Adda",
    'Oliveto Citra',
    'Prarolo',
    'La Via',
    'Radda in Chianti',
    'Riale',
  ],
  tld: ['.it'],
  cca2: 'IT',
  ccn3: '380',
  cca3: 'ITA',
  cioc: 'ITA',
  currency: ['EUR'],
  callingCode: ['39'],
  capital: 'Rome',
  altSpellings: ['IT', 'Italian Republic', 'Repubblica italiana'],
  region: 'Europe',
  subregion: 'Southern Europe',
  languages: {
    bar: 'Austro-Bavarian German',
    ita: 'Italian',
    srd: 'Sardinian',
  },
  translations: {
    deu: { official: 'Italienische Republik', common: 'Italien' },
    fra: { official: 'République italienne', common: 'Italie' },
    hrv: { official: 'talijanska Republika', common: 'Italija' },
    ita: { official: 'Repubblica italiana', common: 'Italia' },
    jpn: { official: 'イタリア共和国', common: 'イタリア' },
    nld: { official: 'Italiaanse Republiek', common: 'Italië' },
    por: { official: 'República Italiana', common: 'Itália' },
    rus: { official: 'итальянская Республика', common: 'Италия' },
    spa: { official: 'República Italiana', common: 'Italia' },
    fin: { official: 'Italian tasavalta', common: 'Italia' },
  },
  latlng: [42.83333333, 12.83333333],
  demonym: 'Italian',
  landlocked: false,
  borders: ['AUT', 'FRA', 'SMR', 'SVN', 'CHE', 'VAT'],
  area: 301336,
  provinces: [
    'Abruzzo',
    'Basilicata',
    'Calabria',
    'Campania',
    'Emilia-Romagna',
    'Friuli-Venezia Giulia',
    'Lazio',
    'Liguria',
    'Lombardia',
    'Marche',
    'Molise',
    'Piemonte',
    'Puglia',
    'Sardegna',
    'Sicilia',
    'Toscana',
    'Trentino-Alto Adige',
    'Umbria',
    "Valle d'Aosta",
    'Veneto',
  ],
  nativeName: 'Italia',
  timezones: ['UTC+01:00'],
} as const;
