var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { class: ["modal-form-section", { disabled: _vm.disabled }] },
    [
      _setup.showHeader
        ? _c("div", { staticClass: "modal-form-section-header" }, [
            _vm.title || _vm.description
              ? _c("div", { staticClass: "flex-grow-1" }, [
                  _vm.title
                    ? _c("div", { staticClass: "h6 mb-0" }, [
                        _vm._v(_vm._s(_vm.title)),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.description
                    ? _c("small", { staticClass: "small text-muted" }, [
                        _vm._v(
                          "\n        " + _vm._s(_vm.description) + "\n      "
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            _setup.hasHeaderContent
              ? _c(
                  "div",
                  { staticClass: "d-flex justify-content-end flex-grow-1" },
                  [_vm._t("header")],
                  2
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            _vm.variant && _vm.variant === "danger"
              ? "modal-form-section-content-danger"
              : "modal-form-section-content",
            { "p-0": _vm.noPadding },
          ],
        },
        [
          _c(
            "div",
            { staticClass: "position-relative" },
            [_vm._t("default")],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }