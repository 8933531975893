var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { class: _vm.classList }, [
    _vm.component
      ? _c(
          "div",
          {
            staticClass: "uib-modal-content",
            on: {
              click: function ($event) {
                $event.stopPropagation()
              },
            },
          },
          [
            _c(
              _vm.component,
              _vm._g(
                _vm._b({ tag: "component" }, "component", _vm.$attrs, false),
                _vm.$listeners
              )
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }