import { Order } from '@models';

import { openModal } from '../open-modal';

/**
 * {@link view View Order} modal configuration options.
 */
export interface ViewOrderModalOptions {
  /**
   * {@link Order}, or ID of the order to view.
   */
  order: Order['id'] | Order;
}

/**
 * Open View Order modal.
 *
 * @param options Modal options.
 * @returns Modal promise.
 */
export async function view(options: ViewOrderModalOptions) {
  await openModal.safe({
    title: 'VIEW ORDER',
    props: { ...options },
    size: 'lg',
    component: () => import('./ViewOrder.vue'),
  });
}
