var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-card",
    { staticClass: "card-expandable", attrs: { "no-body": "" } },
    [
      _c(
        "b-card-header",
        {
          staticClass: "p-3 d-flex justify-content-between",
          attrs: { "header-tag": "header", role: "tab" },
          on: {
            click: function ($event) {
              _setup.expanded = !_setup.expanded
            },
          },
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.label))]),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "pull-right" },
            [
              _c(_setup.Icon, {
                attrs: {
                  icon: _setup.expanded ? _setup.faMinus : _setup.faPlus,
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        {
          attrs: { role: "tabpanel" },
          model: {
            value: _setup.expanded,
            callback: function ($$v) {
              _setup.expanded = $$v
            },
            expression: "expanded",
          },
        },
        [_c("b-card-body", { staticClass: "p-0" }, [_vm._t("default")], 2)],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }