var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-card-group",
    { attrs: { deck: "" } },
    _vm._l(_setup.cards, function ({ key, ...card }) {
      return _c(
        _setup.CardMetric,
        _vm._b({ key: key }, "CardMetric", card, false)
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }