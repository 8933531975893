export const MNG = {
  name: {
    common: 'Mongolia',
    official: 'Mongolia',
    native: { mon: { official: 'Монгол улс', common: 'Монгол улс' } },
  },
  cities: [
    'Ulan Bator',
    'Chihertey',
    'Altai',
    'Bayanhongor',
    'MÃ¶rÃ¶n',
    'Baruun-Urt',
    'SÃ¼hbaatar',
    'Bayangol',
    'Darhan',
    'Han-Uul',
    'Arvayheer',
    'Javhlant',
    'Choyr',
    'Undurkhaan',
    'Ovoot',
    'Ulaan-Uul',
    'Mandalgovi',
    'Dalandzadgad',
    'Toyrim',
    'Cecerleg',
    'Saynshand',
    'Khovd',
    'Uliastay',
  ],
  tld: ['.mn'],
  cca2: 'MN',
  ccn3: '496',
  cca3: 'MNG',
  cioc: 'MGL',
  currency: ['MNT'],
  callingCode: ['976'],
  capital: 'Ulan Bator',
  altSpellings: ['MN'],
  region: 'Asia',
  subregion: 'Eastern Asia',
  languages: { mon: 'Mongolian' },
  translations: {
    deu: { official: 'Mongolei', common: 'Mongolei' },
    fra: { official: 'Mongolie', common: 'Mongolie' },
    hrv: { official: 'Mongolija', common: 'Mongolija' },
    ita: { official: 'Mongolia', common: 'Mongolia' },
    jpn: { official: 'モンゴル', common: 'モンゴル' },
    nld: { official: 'Mongolië', common: 'Mongolië' },
    por: { official: 'Mongólia', common: 'Mongólia' },
    rus: { official: 'Монголия', common: 'Монголия' },
    spa: { official: 'Mongolia', common: 'Mongolia' },
    fin: { official: 'Mongolian tasavalta', common: 'Mongolia' },
  },
  latlng: [46, 105],
  demonym: 'Mongolian',
  landlocked: true,
  borders: ['CHN', 'RUS'],
  area: 1564110,
  provinces: [
    'Arhangay',
    'Bayan-Olgiy',
    'Bayanhongor',
    'Bulgan',
    'Darhan',
    'Dornod',
    'Dornogovi',
    'Dundgovi',
    'Dzavhan',
    'Erdenet',
    'Govi-Altay',
    'Hentiy',
    'Hovd',
    'Hovsgol',
    'Omnogovi',
    'Ovorhangay',
    'Selenge',
    'Suhbaatar',
    'Tov',
    'Ulaanbaatar',
    'Uvs',
  ],
  nativeName: 'Монгол улс',
  timezones: ['UTC+07:00', 'UTC+08:00'],
} as const;
