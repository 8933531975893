import { find, remove, forEach } from 'lodash';

import { Report } from '@models';
import { nistGradingCriteria } from '@values/nist-grading-criteria';
import { dates } from '@utils/dates';

/** ... */
const NIST_TEST_GRADING_RUBRIC = nistGradingCriteria.NIST_TEST_GRADING_RUBRIC;

/**
 * ...
 */
export interface ParsedObjectiveData extends Report.ObjectiveData {
  isSuccess?: boolean;
  failureReason?: string | null;
}

/**
 * ...
 */
export interface ObjectiveInfo {
  details: nistGradingCriteria.Rubric.Objective;
  isSuccess: boolean | undefined;
  failureReason: string | null | undefined;
  timeSinceBeginning: string | undefined;
}

/**
 * ...
 */
export interface NistEvaluationResults {
  totalModuleTime: number;
  objectives: ObjectiveInfo[];
}

/**
 * ...
 *
 * @param reportObjectives ...
 * @param nistGradingRubricType ...
 * @param nistGradingFeet ...
 * @param reportId ...
 * @return ...
 */
export const evaluateNistTest = (
  reportObjectives: ParsedObjectiveData[],
  nistGradingRubricType: nistGradingCriteria.Rubric.Key,
  nistGradingFeet: number,
  reportId: Report['id'],
) => {
  // console.log(reportObjectives);
  // NIST Test Grading Rubric
  const rubric = NIST_TEST_GRADING_RUBRIC[nistGradingRubricType];
  const s = nistGradingFeet;
  const variableMap: Record<string, number> = { '{S}': s, '{2S}': 2 * s };
  const variableRegEx = new RegExp(Object.keys(variableMap).join('|'), 'gi');

  const nistObjectives: ObjectiveInfo[] = [];
  const firstObjectiveStartTime = reportObjectives[0].startTime;
  const lastObjectiveEndTime =
    reportObjectives[reportObjectives.length - 1].endTime;

  forEach(rubric.objectives, (o) => {
    const simReportName = o.simReportName.replace(variableRegEx, (matched) =>
      variableMap[matched]?.toString(),
    );

    const ro = find(reportObjectives, { name: simReportName });

    if (!ro) {
      /* eslint-disable-next-line no-console */
      return console.error(
        `[util.evaluateNistTest]: could not find report objective with name: ${simReportName} | report Id ${reportId}`,
      );
    }

    // ...
    const timeSinceBeginning = find(ro.evaluationDetails, {
      poeType: 'TimerPoE',
    })?.resultValue;

    if (o.type === 'capture' || o.type === 'landing') {
      // Evaluation Capture/Landing Type Objective
      nistObjectives.push({
        details: o,
        isSuccess: ro.isSuccess,
        failureReason: ro.failureReason,
        timeSinceBeginning,
      });
    }
  });

  return {
    objectives: nistObjectives,
    totalModuleTime: dates.differenceInSeconds(
      new Date(lastObjectiveEndTime),
      new Date(firstObjectiveStartTime),
    ),
  } as NistEvaluationResults;
};

/**
 * ...
 *
 * @param objectiveData ...
 * @return
 */
export const parseObjectiveData = (objectiveData: Report.ObjectiveData) => {
  // ...
  const od = objectiveData as ParsedObjectiveData;

  let isSuccess = true;
  let failureReason = '';

  // evaluate evaluationDetails for overall isSuccess
  if (od.evaluationDetails.length) {
    // check if we have a position & target capture to combine
    const insideColliderAtCompletionPoE = find(od.evaluationDetails, {
      poeType: 'InsideColliderAtCompletionPoE',
    });

    const targetOnScreenAtCompletionPoE = find(od.evaluationDetails, {
      poeType: 'TargetOnScreenAtCompletionPoE',
    });

    if (targetOnScreenAtCompletionPoE && insideColliderAtCompletionPoE) {
      let narrative = '';

      if (
        targetOnScreenAtCompletionPoE.numberGrade === 1 &&
        insideColliderAtCompletionPoE.numberGrade === 1
      ) {
        narrative = 'Success | Target Captured';
      } else if (
        targetOnScreenAtCompletionPoE.numberGrade === 0 &&
        insideColliderAtCompletionPoE.numberGrade === 1
      ) {
        isSuccess = false;
        narrative = ' Fail | Target Captured | Target Not On Screen';
        failureReason = `Target Not On Screen`;
      } else if (
        targetOnScreenAtCompletionPoE.numberGrade === 1 &&
        insideColliderAtCompletionPoE.numberGrade === 0
      ) {
        isSuccess = false;
        narrative = 'Fail | Target Captured | Drone Not In Position';
        failureReason = `Drone Not In Position`;
      } else if (
        targetOnScreenAtCompletionPoE.numberGrade === 0 &&
        insideColliderAtCompletionPoE.numberGrade === 0
      ) {
        isSuccess = false;
        narrative =
          'Fail | Target Captured | Drone Not In Position & Target Not On Screen';
        failureReason = `Drone Not In Position | Target Not On Screen`;
      }

      od.evaluationDetails.push({
        poeType: 'InsideColliderAtCompletionPoETargetOnScreenAtCompletionPoE',
        narrative,
        isSuccess,
        numberGrade: isSuccess ? 1 : 0,
      });

      remove(od.evaluationDetails, {
        poeType: 'InsideColliderAtCompletionPoE',
      });

      remove(od.evaluationDetails, {
        poeType: 'TargetOnScreenAtCompletionPoE',
      });
    }

    forEach(od.evaluationDetails, (ed) => {
      if (
        ed.poeType ===
        'InsideColliderAtCompletionPoETargetOnScreenAtCompletionPoE'
      )
        return;

      let narrative = '';

      if (ed.poeType === 'TimerPoE') {
        // TimerPoE
        narrative = `${
          ed.numberGrade === 0 ? 'Fail' : 'Success'
        } | Target Time: ${ed.targetValue} | Result Time: ${ed.resultValue}`;

        if (ed.numberGrade === 0)
          failureReason = failureReason.length
            ? `${failureReason} | Over Time Limit`
            : `Over Time Limit`;
      } else if (ed.poeType === 'InsideColliderAtCompletionPoE') {
        // InsideColliderAtCompletionPoE
        narrative = `${
          ed.numberGrade === 0 ? 'Fail' : 'Success'
        } | In Correct Position`;

        if (ed.numberGrade === 0)
          failureReason = failureReason.length
            ? `${failureReason} | Drone Not In Position`
            : `Drone Not In Position`;
      } else if (ed.poeType === 'TargetOnScreenAtCompletionPoE') {
        // TargetOnScreenAtCompletionPoE
        narrative = `${
          ed.numberGrade === 0 ? 'Fail' : 'Success'
        } | Target Captured`;

        if (ed.numberGrade === 0)
          failureReason = failureReason.length
            ? `${failureReason} | Target Not On Screen`
            : `Target Not On Screen`;
      } else if (ed.poeType === 'AltitudeAtCompletionPoE') {
        // AltitudeAtCompletionPoE
        narrative = `${
          ed.numberGrade === 0 ? 'Fail' : 'Success'
        } | Target Altitude: ${ed.targetValue} | Result Altitude: ${
          ed.resultValue
        }`;

        if (ed.numberGrade === 0)
          failureReason = failureReason.length
            ? `${failureReason} | Incorrect Altitude`
            : `Incorrect Altitude`;
      } else if (ed.poeType === 'TargetPositionComboPoE') {
        // Target Position Combo
        narrative = `${
          ed.numberGrade === 0 ? 'Fail' : 'Success'
        } | Target Captured`;

        if (ed.numberGrade === 0)
          failureReason = failureReason.length
            ? `${failureReason} | Target Not Captured`
            : `Target Not Captured`;
      }

      if (ed.numberGrade === 0) isSuccess = false;

      ed.narrative = narrative;
    });

    od.isSuccess = isSuccess;
    od.failureReason = failureReason.length ? failureReason : null;
  }

  return od;
};
