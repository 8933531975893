var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    {
      directives: [
        {
          name: "b-tooltip",
          rawName: "v-b-tooltip.top",
          value: _vm.text,
          expression: "text",
          modifiers: { top: true },
        },
      ],
      class: ["info-circle", _vm.variant ? `info-circle-${_vm.variant}` : ""],
    },
    [
      _c(_setup.Icon, {
        staticClass: "icon-regular",
        attrs: { icon: _setup.faInfoRegular },
      }),
      _vm._v(" "),
      _c(_setup.Icon, {
        staticClass: "icon-solid",
        attrs: { icon: _setup.faInfoSolid },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }