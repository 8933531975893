export const DMA = {
  name: {
    common: 'Dominica',
    official: 'Commonwealth of Dominica',
    native: {
      eng: { official: 'Commonwealth of Dominica', common: 'Dominica' },
    },
  },
  cities: ['Berekua', 'Roseau', 'Marigot'],
  tld: ['.dm'],
  cca2: 'DM',
  ccn3: '212',
  cca3: 'DMA',
  cioc: 'DMA',
  currency: ['XCD'],
  callingCode: ['1767'],
  capital: 'Roseau',
  altSpellings: [
    'DM',
    'Dominique',
    'Wai‘tu kubuli',
    'Commonwealth of Dominica',
  ],
  region: 'Americas',
  subregion: 'Caribbean',
  languages: { eng: 'English' },
  translations: {
    cym: { official: 'Commonwealth of Dominica', common: 'Dominica' },
    deu: { official: 'Commonwealth von Dominica', common: 'Dominica' },
    fra: { official: 'Commonwealth de la Dominique', common: 'Dominique' },
    hrv: { official: 'Zajednica Dominika', common: 'Dominika' },
    ita: { official: 'Commonwealth di Dominica', common: 'Dominica' },
    jpn: { official: 'ドミニカ国', common: 'ドミニカ国' },
    nld: { official: 'Gemenebest Dominica', common: 'Dominica' },
    por: { official: 'Comunidade da Dominica', common: 'Dominica' },
    rus: { official: 'Содружество Доминики', common: 'Доминика' },
    spa: { official: 'Mancomunidad de Dominica', common: 'Dominica' },
    fin: { official: 'Dominican liittovaltio', common: 'Dominica' },
  },
  latlng: [15.41666666, -61.33333333],
  demonym: 'Dominican',
  landlocked: false,
  borders: [],
  area: 751,
} as const;
