export const KGZ = {
  name: {
    common: 'Kyrgyzstan',
    official: 'Kyrgyz Republic',
    native: {
      kir: { official: 'Кыргыз Республикасы', common: 'Кыргызстан' },
      rus: { official: 'Кыргызская Республика', common: 'Киргизия' },
    },
  },
  cities: ['Bishkek'],
  tld: ['.kg'],
  cca2: 'KG',
  ccn3: '417',
  cca3: 'KGZ',
  cioc: 'KGZ',
  currency: ['KGS'],
  callingCode: ['996'],
  capital: 'Bishkek',
  altSpellings: [
    'KG',
    'Киргизия',
    'Kyrgyz Republic',
    'Кыргыз Республикасы',
    'Kyrgyz Respublikasy',
  ],
  region: 'Asia',
  subregion: 'Central Asia',
  languages: { kir: 'Kyrgyz', rus: 'Russian' },
  translations: {
    deu: { official: 'Kirgisische Republik', common: 'Kirgisistan' },
    fra: { official: 'République kirghize', common: 'Kirghizistan' },
    hrv: { official: 'Kirgistanu', common: 'Kirgistan' },
    ita: { official: 'Kirghizistan', common: 'Kirghizistan' },
    jpn: { official: 'キルギス共和国', common: 'キルギス' },
    nld: { official: 'Kirgizische Republiek', common: 'Kirgizië' },
    por: { official: 'República do Quirguistão', common: 'Quirguistão' },
    rus: { official: 'Кыргызская Республика', common: 'Киргизия' },
    spa: { official: 'República Kirguisa', common: 'Kirguizistán' },
    fin: { official: 'Kirgisian tasavalta', common: 'Kirgisia' },
  },
  latlng: [41, 75],
  demonym: 'Kirghiz',
  landlocked: true,
  borders: ['CHN', 'KAZ', 'TJK', 'UZB'],
  area: 199951,
  provinces: [
    'Batken Oblasty',
    'Bishkek Shaary',
    'Chuy Oblasty (Bishkek)',
    'Jalal-Abad Oblasty',
    'Naryn Oblasty',
    'Osh Oblasty',
    'Talas Oblasty',
    'Ysyk-Kol Oblasty (Karakol)',
  ],
  nativeName: 'Кыргызстан',
  timezones: ['UTC+06:00'],
} as const;
