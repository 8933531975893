import { Module, Action } from '@vuex/decorators';
import { TablePanel } from 'table-panel';

import { faEye } from '@icons/solid/faEye';
import { faEdit } from '@icons/solid/faEdit';
import { faCopy } from '@icons/solid/faCopy';

import { Announcement } from '@models';
import { modals } from '@modals';
import { RoleId } from '@values/roles';

const columns: TablePanel.Column<Announcement>[] = [
  {
    key: 'title',
    label: 'Title',
    value: 'title',
  },
  {
    key: 'createdAt',
    label: 'Created',
    type: 'date',
    value: 'createdAt',
  },
  {
    key: 'institution',
    label: 'Institution',
    value: ({ organization: account }) => account?.name,
  },
  {
    key: 'course',
    label: 'Course',
    value: ({ course }) => course?.name,
  },
];

const operations: TablePanel.Row.Operation<Announcement>[] = [
  {
    label: 'View',
    icon: faEye,
    fn: (announcement) => {
      void modals.announcement.view({ announcement });
    },
  },
  {
    label: 'Edit',
    icon: faEdit,
    fn: (announcement) => {
      void modals.announcement.edit({ announcement });
    },
    hidden: (announcement, { selectedRole }) => {
      if (
        selectedRole?.roleId !== RoleId.Instructor &&
        selectedRole?.roleId !== RoleId.InstitutionAdmin &&
        selectedRole?.roleId !== RoleId.LasAdmin
      )
        return true;

      if (
        selectedRole?.roleId === RoleId.Instructor ||
        selectedRole?.roleId === RoleId.InstitutionAdmin
      ) {
        return !(
          announcement.organization?.id === selectedRole?.organization?.id
        );
      }

      return false;
    },
  },
  {
    label: 'Create Copy',
    icon: faCopy,
    fn: (announcement) => {
      void modals.announcement.create({ announcement });
    },
    hidden: (announcement, { selectedRole }) => {
      if (
        selectedRole?.roleId !== RoleId.Instructor &&
        selectedRole?.roleId !== RoleId.InstitutionAdmin &&
        selectedRole?.roleId !== RoleId.LasAdmin
      )
        return true;

      if (
        selectedRole?.roleId === RoleId.Instructor ||
        selectedRole?.roleId === RoleId.InstitutionAdmin
      ) {
        return !(
          announcement.organization?.id === selectedRole?.organization?.id
        );
      }

      return false;
    },
  },
];

@Module({ namespaced: true })
export class CourseAnnouncementsTablePanel
  extends TablePanel<Announcement>
  implements TablePanel.Props<Announcement>
{
  readonly module = 'announcements';
  // readonly loadAction = 'listByCourse';
  readonly loadAction = 'list';
  readonly pk = 'id';
  readonly label = 'ANNOUNCEMENTS';
  readonly columns = columns;
  readonly operations = operations;
  readonly createAction = 'createAnnouncement';
  readonly deleteAction = 'deleteAnnouncement';
  readonly tableSortType = 'createdAt';
  readonly tableSortReverse = true;

  readonly enabled = true;

  @Action
  async createAnnouncement() {
    await modals.announcement.create();
  }

  @Action
  async deleteAnnouncement({
    items,
  }: CourseAnnouncementsTablePanel.DeleteItemOptions) {
    await modals.confirm.deleteItems({ itemType: 'announcements', items });
  }
}

export namespace CourseAnnouncementsTablePanel {
  /** ... */
  export interface DeleteItemOptions {
    items: Announcement[];
  }
}
