export const BRB = {
  name: {
    common: 'Barbados',
    official: 'Barbados',
    native: { eng: { official: 'Barbados', common: 'Barbados' } },
  },
  cities: [
    'Warrens',
    'Bridgetown',
    'Pine Housing Estate',
    'Atlantic Shores',
    'Hastings',
    'Wildey',
    'Rendezvous',
    'Bloomsbury',
    'The Garden',
    'Oistins',
    'Rockley',
    'Holetown',
    'Wanstead',
    'Warners',
    'Bruce Vale',
    'Bagatelle',
    'Worthing',
    'Cave Hill',
    'Clapham',
    'White Hill',
    'Porters',
    'Husbands',
    'Jackmans',
  ],
  tld: ['.bb'],
  cca2: 'BB',
  ccn3: '052',
  cca3: 'BRB',
  cioc: 'BAR',
  currency: ['BBD'],
  callingCode: ['1246'],
  capital: 'Bridgetown',
  altSpellings: ['BB'],
  region: 'Americas',
  subregion: 'Caribbean',
  languages: { eng: 'English' },
  translations: {
    cym: { official: 'Barbados', common: 'Barbados' },
    deu: { official: 'Barbados', common: 'Barbados' },
    fra: { official: 'Barbade', common: 'Barbade' },
    hrv: { official: 'Barbados', common: 'Barbados' },
    ita: { official: 'Barbados', common: 'Barbados' },
    jpn: { official: 'バルバドス', common: 'バルバドス' },
    nld: { official: 'Barbados', common: 'Barbados' },
    por: { official: 'Barbados', common: 'Barbados' },
    rus: { official: 'Барбадос', common: 'Барбадос' },
    spa: { official: 'Barbados', common: 'Barbados' },
    fin: { official: 'Barbados', common: 'Barbados' },
  },
  latlng: [13.16666666, -59.53333333],
  demonym: 'Barbadian',
  landlocked: false,
  borders: [],
  area: 430,
} as const;
