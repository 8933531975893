import { load, type ReCaptchaInstance } from 'recaptcha-v3';

import { GOOGLE_RECAPTCHA_KEY } from '@values';

let recaptchaClient: ReCaptchaInstance | null = null;

async function getRecaptchaClient() {
  return (
    recaptchaClient ?? (recaptchaClient = await load(GOOGLE_RECAPTCHA_KEY))
  );
}

/**
 * Get a Google reCAPTCHA response token.
 *
 * @param action The action to be performed.
 * @returns A Google reCAPTCHA response token.
 */
export async function getToken(action?: string) {
  return await (await getRecaptchaClient()).execute(action);
}
