export const VUT = {
  name: {
    common: 'Vanuatu',
    official: 'Republic of Vanuatu',
    native: {
      bis: { official: 'Ripablik blong Vanuatu', common: 'Vanuatu' },
      eng: { official: 'Republic of Vanuatu', common: 'Vanuatu' },
      fra: { official: 'République de Vanuatu', common: 'Vanuatu' },
    },
  },
  provinces: ['Malampa', 'Penama', 'Sanma', 'Shefa', 'Tafea', 'Torba'],
  nativeName: 'Vanuatu',
  cities: ['Port Vila', 'Level', 'Loltong'],
  tld: ['.vu'],
  cca2: 'VU',
  ccn3: '548',
  cca3: 'VUT',
  cioc: 'VAN',
  currency: ['VUV'],
  callingCode: ['678'],
  capital: 'Port Vila',
  altSpellings: [
    'VU',
    'Republic of Vanuatu',
    'Ripablik blong Vanuatu',
    'République de Vanuatu',
  ],
  region: 'Oceania',
  subregion: 'Melanesia',
  languages: { bis: 'Bislama', eng: 'English', fra: 'French' },
  translations: {
    deu: { official: 'Vanuatu', common: 'Vanuatu' },
    fra: { official: 'République de Vanuatu', common: 'Vanuatu' },
    hrv: { official: 'Republika Vanuatu', common: 'Vanuatu' },
    ita: { official: 'Repubblica di Vanuatu', common: 'Vanuatu' },
    jpn: { official: 'バヌアツ共和国', common: 'バヌアツ' },
    nld: { official: 'Republiek Vanuatu', common: 'Vanuatu' },
    por: { official: 'República de Vanuatu', common: 'Vanuatu' },
    rus: { official: 'Республика Вануату', common: 'Вануату' },
    spa: { official: 'República de Vanuatu', common: 'Vanuatu' },
    fin: { official: 'Vanuatun tasavalta', common: 'Vanuatu' },
  },
  latlng: [-16, 167],
  demonym: 'Ni-Vanuatu',
  landlocked: false,
  borders: [],
  area: 12189,
  timezones: ['UTC+11:00'],
} as const;
