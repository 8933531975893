export const URY = {
  name: {
    common: 'Uruguay',
    official: 'Oriental Republic of Uruguay',
    native: {
      spa: { official: 'República Oriental del Uruguay', common: 'Uruguay' },
    },
  },
  nativeName: 'Uruguay',
  provinces: [
    'Artigas',
    'Canelones',
    'Cerro Largo',
    'Colonia',
    'Durazno',
    'Flores',
    'Florida',
    'Lavalleja',
    'Maldonado',
    'Montevideo',
    'Paysandu',
    'Rio Negro',
    'Rivera',
    'Rocha',
    'Salto',
    'San Jose',
    'Soriano',
    'Tacuarembo',
    'Treinta y Tres',
  ],
  cities: [
    'Montevideo',
    'La Floresta',
    'Barra de Carrasco',
    'PaysandÃº',
    'Salto',
    'Las Piedras',
    'TacuarembÃ³',
    'Toledo',
    'Colonia del Sacramento',
    'Mercedes',
    'Union',
    'Florida',
    'Maldonado',
    'Canelones',
    'La Paz',
    'San Carlos',
    'Durazno',
    'Punta del Este',
  ],
  tld: ['.uy'],
  cca2: 'UY',
  ccn3: '858',
  cca3: 'URY',
  cioc: 'URU',
  currency: ['UYI', 'UYU'],
  callingCode: ['598'],
  capital: 'Montevideo',
  altSpellings: [
    'UY',
    'Oriental Republic of Uruguay',
    'República Oriental del Uruguay',
  ],
  region: 'Americas',
  subregion: 'South America',
  languages: { spa: 'Spanish' },
  translations: {
    deu: { official: 'Republik Östlich des Uruguay', common: 'Uruguay' },
    fra: { official: "République orientale de l'Uruguay", common: 'Uruguay' },
    hrv: { official: 'Orijentalna Republika Urugvaj', common: 'Urugvaj' },
    ita: { official: "Repubblica Orientale dell'Uruguay", common: 'Uruguay' },
    jpn: { official: 'ウルグアイ東方共和国', common: 'ウルグアイ' },
    nld: { official: 'Oosterse Republiek Uruguay', common: 'Uruguay' },
    por: { official: 'República Oriental do Uruguai', common: 'Uruguai' },
    rus: { official: 'Восточной Республики Уругвай', common: 'Уругвай' },
    spa: { official: 'República Oriental del Uruguay', common: 'Uruguay' },
    fin: { official: 'Uruguayn itäinen tasavalta', common: 'Uruguay' },
  },
  latlng: [-33, -56],
  demonym: 'Uruguayan',
  landlocked: false,
  borders: ['ARG', 'BRA'],
  area: 181034,
  timezones: ['UTC−03:00'],
} as const;
