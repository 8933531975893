var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    _vm._l(_setup.props.value, function (dlc) {
      return _c("div", { key: dlc.id, staticClass: "mx-1" }, [
        _c("span", [_vm._v(_vm._s(dlc.name) + " | " + _vm._s(dlc.version))]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }