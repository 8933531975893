export const GIN = {
  name: {
    common: 'Guinea',
    official: 'Republic of Guinea',
    native: { fra: { official: 'République de Guinée', common: 'Guinée' } },
  },
  cities: [
    'Conakry',
    'ForÃ©cariah',
    'NzÃ©rÃ©korÃ©',
    'LabÃ©',
    'Kankan',
    'Port Kamsar',
    'BokÃ©',
    'Mamou',
    'Dabola',
    'Lola',
    'Sangaredi',
    'Kalia',
  ],
  tld: ['.gn'],
  cca2: 'GN',
  ccn3: '324',
  cca3: 'GIN',
  cioc: 'GUI',
  currency: ['GNF'],
  callingCode: ['224'],
  capital: 'Conakry',
  altSpellings: ['GN', 'Republic of Guinea', 'République de Guinée'],
  region: 'Africa',
  subregion: 'Western Africa',
  languages: { fra: 'French' },
  translations: {
    deu: { official: 'Republik Guinea', common: 'Guinea' },
    fra: { official: 'République de Guinée', common: 'Guinée' },
    hrv: { official: 'Republika Gvineja', common: 'Gvineja' },
    ita: { official: 'Repubblica di Guinea', common: 'Guinea' },
    jpn: { official: 'ギニア共和国', common: 'ギニア' },
    nld: { official: 'Republiek Guinee', common: 'Guinee' },
    por: { official: 'República da Guiné', common: 'Guiné' },
    rus: { official: 'Республика Гвинея', common: 'Гвинея' },
    spa: { official: 'República de Guinea', common: 'Guinea' },
    fin: { official: 'Guinean tasavalta', common: 'Guinea' },
  },
  latlng: [11, -10],
  demonym: 'Guinean',
  landlocked: false,
  borders: ['CIV', 'GNB', 'LBR', 'MLI', 'SEN', 'SLE'],
  area: 245857,
  provinces: [
    'Beyla',
    'Boffa',
    'Boke',
    'Conakry',
    'Coyah',
    'Dabola',
    'Dalaba',
    'Dinguiraye',
    'Dubreka',
    'Faranah',
    'Forecariah',
    'Fria',
    'Gaoual',
    'Gueckedou',
    'Kankan',
    'Kerouane',
    'Kindia',
    'Kissidougou',
    'Koubia',
    'Koundara',
    'Kouroussa',
    'Labe',
    'Lelouma',
    'Lola',
    'Macenta',
    'Mali',
    'Mamou',
    'Mandiana',
    'Nzerekore',
    'Pita',
    'Siguiri',
    'Telimele',
    'Tougue',
    'Yomou',
  ],
  nativeName: 'Guinée',
  timezones: ['UTC'],
} as const;
