export const CHN = {
  name: {
    common: 'China',
    official: "People's Republic of China",
    native: { cmn: { official: '中华人民共和国', common: '中国' } },
  },
  cities: [
    'Guangzhou',
    'Fuzhou',
    'Beijing',
    'Baotou',
    'Hohhot',
    'Guiyang',
    'Yinchuan',
    'Nanjing',
    'Changzhou',
    'Chuzhou',
    'Hefei',
    'Jinan',
    'Qingdao',
    'Harbin',
    'Zhaodong',
    'Taiyuan',
    "Xi'an",
    'Xianyang',
    'Shenzhen',
    'Nanning',
    'Zhengzhou',
    'Xinxiang',
    'Luohe',
    'Luoyang',
    'Chaoyang',
    'Xingyi',
    'Foshan',
    'Haikou',
    'Chengdu',
    'Dongguan',
    'Mingzhou',
    'Chongqing',
    'Zhuhai',
    'Kunming',
    'Wuhan',
    'Xiling',
    'Huizhou',
    'Jiangmen',
    'Shantou',
    'Changxiacun',
    'Zhongshan',
    'Lhasa',
    'Nanchang',
    'Tianjin',
    'Shanghai',
    'Hebei',
    'Shijiazhuang',
    'Quanzhou',
    'Putian',
    'Xiamen',
    'Chengyang',
    'Zhangzhou',
    'Sanming',
    'Nanping',
    'Baoding',
    'Langfang',
    'Yantai',
    'Binzhou',
    'Lanzhou',
    'Yueqing',
    'Zhongxin',
    'Zhoushan',
    'Hangzhou',
    'Ningbo',
    'Wenzhou',
    'Changchun',
    'Fuyang',
    'Jieshou',
    'Anqing',
    'Wuhu',
    'Shishi',
    'Shishi',
    'Weitang',
    'Shenyang',
    'Changsha',
    'Yongjiawan',
    'Lengshuijiang',
    'Shijiazhuang',
    'Xuchang',
    'Suzhou',
    'Xuzhou',
    'Taizhou',
    'Nanyang',
    'Xinhua',
    'ÃœrÃ¼mqi',
    "Yan'an Beilu",
    'Baotao',
    'Macao',
    'Wuxi',
    'Yangzhou',
    'Baiyin',
    'Tongren',
    'Kunshan',
    'Zhangjiagang',
    'Jiangyin',
    'Zhenjiang',
    'Zhoukou',
    'Anyang',
    'Dalian',
    'Tongcun',
    'Shihezi',
    'Xining',
    'Zhangye',
    'Qingyang',
    'Wangsu',
    'Anshun',
    'Kaili',
    'Bijie',
    'Shuigang',
    'Jianyuan',
    'Jinlong',
    'Jingdezhen',
    'Yichang',
    'Xiangfan',
    'Jiayuan',
    'Shashi',
    'Yingjie',
    'Huangshi',
    'Jinhua',
    'Zhengyuan',
    'Langli',
    'Hengyang',
    'Jianning',
    'Xiangtan',
    'Rongxing',
    'Xincao',
    'Jinda',
    'Nanlong',
    'Xiangcheng',
    'Nanma',
    'Zhongxiang',
    'Pudong',
    'Shuitou',
    'Zhenxing',
    'Jinjiang',
    'Longxun',
    'Guanqiao',
    'Jingfeng',
    'Shijing',
    'Tangbian',
    'Jiangchuanlu',
    'Jiaoyun',
    'Guizhoumanzuxiang',
    'Qingzhen',
    'Changde',
    'Xianning',
    'Jiaozhou',
    'Weifang',
    "Tai'an",
    'Luoxi',
    'Guoji',
    'Guangdong',
    'Sijiqing',
    'Huzhou',
    'Panjin Shi',
    'Daqing',
    'Jilin City',
    'Lianyungang',
    'Yancheng',
    'Yuehu',
    'Kunshan',
    'Taicang',
    'Lianshui',
    'Nantong',
    'Jiaotong',
    'Changshu City',
    'Xingxiangcun',
    'Jinsha',
    'Jiangyan',
    'Chaigoubu',
    "Ma'anshan",
    'Huainan',
    'Haibei',
    'Shenlong',
    'Nangxian',
    'Rongsheng',
    'Changfeng',
    'Chengqiao',
    'Jiafu',
    'Shenzhou',
    'Shantou',
    'Qingyuan',
    'Gyari',
    'Xinshijie',
    'Zhaoqing',
    'Zhanjiang',
    'Kuicheng',
    'Taoyuan',
    'Jincheng',
    'Caishen',
    'Shiyan',
    'Liaoyang',
    'Xingtai',
    'Wenchang',
    'Wanning',
    'Qionghai',
    'Huilongba',
    'Dingcheng',
    'Baodian',
    'Wuzhishan',
    'Chengmai',
    'Yinggen',
    'Ledong',
    'Lincheng',
    'Baisha',
    'Changjiang',
    'Dongfang',
    'Changjian',
    'Jinmao',
    'Yangpu',
    'Baipo',
    'Jiefang',
    'Danzhou',
    'Lingshui',
    'Haidian',
    'Sanya',
    'Rongjiang',
    'Longyan',
    'Jinghe',
    'Zhangjiakou',
    'Renqiu',
    'Yaocheng',
    'Kaifeng',
    'Hebi',
    'Jiaozuo',
    'Pingdingshan',
    'Anshan',
    'Dandong',
    'Haitang',
    'Tongchuan',
    'Ankang',
    'Guozhen',
    'Shangluo',
    'Xijing',
    'Weinan',
    'Yulin',
    'Yining',
    'Dingxi',
    'Wuwei',
    'Dawukou',
    'Lishui',
    'Quzhou',
    'Hejiang',
    'Handan',
    'Qinhuangdao',
    'Hengshui',
    'Longxin',
    "Wen'an",
    'Yichun',
    'Heihe',
    'Jiaxing',
    'Korla',
    'Kuytun',
    "Di'an",
    "Yu'an",
    'Mengzhou',
    'Hulu',
    'Yizhou',
    'Shuliang',
    'Shancheng',
    'Fushun',
    'Dashiqiao',
    'Laonian',
    'Shengli',
    'Wenquan',
    'Zhiye',
    'Lingzhi',
    'Zhongtang',
    'Gucheng',
    'Xinhua',
    'Ninghe',
    'Dangyang',
    'Yizhong',
    'Jizhou',
    'Tianbao',
    'Jinghai',
    'Julong',
    'Jiaqin',
    'Jiayue',
    'Dabaizhuang',
    'Juchuan',
    'Hexi',
    'Jinnan',
    'Hangu',
    'Nankai',
    'Hedong',
    'Yanglou',
    'Huawei',
    'Hanting',
    'Tianshi',
    'Baiyu',
    'Bohai',
    'Rujia',
    'Tongwang',
    'Meijiang',
    'Dagang',
    'Baodi',
    'Daqiuzhuang',
    'Yuxi',
    'Zicheng',
    'Shaoxing',
    'Zhoushan',
    'Xiaoshan',
    'Linhai',
    'Cixi',
    'Jinchuan',
    'Zhaobaoshan',
    'Tiangang',
    'Beilun',
    'Zhangqi',
    'Zhenghai',
    'Cicheng',
    'Lishu',
    'Chengbei',
    'Heyi',
    'Xikou',
    'Jiangkou',
    'Shunshui',
    'Simen',
    'Yuyao',
    'Lanjiang',
    'Jiangdong',
    'Gaotang',
    'Xiangshan',
    'Shipu',
    'Jinyi',
    'Chengzhong',
    'Yinzhou',
    'Luoyang',
    'Mapai',
    'Cangnan',
    'Jinxiangzhen',
    'Yingjia',
    'Pingyang',
    "Rui'an",
    'Tianfu',
    'Shangtang',
    'Yongjia',
    'Tiancheng',
    'Hongqiao',
    'Furong',
    'Wenxing',
    'Mingxi',
    'Jinshan',
    'Changtu',
    'Anzi',
    'Xianren',
    'Zhongxing',
    'Guanli',
    'Yucai',
    'Xianjiang',
    'Aojiang',
    'Dongtou',
    'Rongjiang',
    'Jinmen',
    'Qiantang',
    'Baojiang',
    'Huling',
    'Liushi',
    'Yuecheng',
    'Hongyun',
    'Longhua',
    'Yajin',
    'Simcun',
    'Longgang',
    'Yingdu',
    'Wansong',
    'Yuele',
    'Nanjiang',
    'Longhu',
    'Ningyi',
    'Fengling',
    'Wuzhou',
    'Xinchen',
    'Jinghu',
    'Fangzhuang',
    'Yinfang',
    'Cili',
    'Angu',
    'Feiyun',
    'Wanquan',
    'Kunyang',
    'Shibei',
    'Jiangnan',
    'Yujing',
    'Yishan',
    'Xuefeng',
    'Feilong',
    'Shangrao',
    'Xuexiao',
    'Yuzhen',
    'Huangbao',
    'Longquan',
    'Pizhou',
    'Songyang',
    'Qingtian',
    'Chenguang',
    'Kaiyuan',
    'Dongsheng',
    'Jinyun',
    'Zhongshan',
    'Miaogao',
    'Yuanli',
    'Shinian',
    'Qingfeng Chengguanzhen',
    'Liu`an',
    'Yulong',
    'Haixing',
    'Sanjiaocheng',
    'Pinghu',
    'Jinling',
    'Fengming',
    'Tongxiang',
    'Puyuan',
    'Dingqiao',
    'Yanjiang',
    'Wutong',
    'Pingchuan',
    'Dushu',
    'Nanxun',
    'Wuxing',
    'Yangzhou',
    'Hongyuan',
    'Anji',
    'Shangying',
    'Deqing',
    'Digang',
    'Sanguan',
    'Yuantong',
    'Changxin',
    'Huating',
    'Putuoshan',
    'Jinyuan',
    'Dinghai',
    'Xiangnan',
    'Putuo',
    'Xintian',
    'Donghuxu',
    'Zhuji',
    'Jingcheng',
    'Jiangtian',
    'Xingchang',
    'Jindou',
    'Xinchang',
    'Baiyun',
    'Qianqing',
    'Tianchang',
    'Tianchi',
    'Luzhou',
    'Qinjiang',
    'Tianzhu',
    'Chengguan',
    'Jinhong',
    'Huaqiao',
    'Maotai',
    'Hezhu',
    'Dahai',
    'Shanhu',
    'Changle',
    'Guoshang',
    'Dongshen',
    'Shangbu',
    'Zhedong',
    'Boxing',
    'Tianyuan',
    'Guodian',
    'Linping',
    'Meicheng',
    'Jiyang',
    'Tonglu',
    'Fuchunjiang',
    'Qiandaohu',
    'Yuhang',
    'Changsheng',
    'Honglin',
    'Xiaoheshan',
    'Binjiang',
    'Yijin',
    'Xunxian',
    'Qianshan',
    'Zhongzhou',
    'Chongxian',
    'Gongchang',
    'Huangyan',
    'Jiaojiang',
    'Wenling',
    'Xindu',
    'Sili',
    'Luqiao',
    'Baoshan',
    'Yanjing',
    'Jinqingzhen',
    'Gamlung',
    'Yiwu',
    'Dongyang',
    'Yongkang',
    'Lanxi',
    'Wuyi',
    'Wanjia',
    'Fotang',
    'Yuhai',
    'Yiting',
    'Puyang',
    'Longfeng',
    'Yueliangwan',
    'Renhe',
    'Yangfen',
    'Youjia',
    'Hanshang',
    'Jindu',
    'Junping',
    'Aoma',
    'Yinliang',
    'Lijing',
    'Renhou',
    'Wangshang',
    "Pan'an",
    'Longchuan',
    'Hengzhou',
    'Lianyuan',
    'Jinlun',
    'Qiaodou',
    'Shizhu',
    'Huajie',
    'Xixi',
    'Hengdian',
    'Dongcheng',
    'Dongdu',
    'Fusheng',
    'Yongjin',
    'Youyi',
    'Yuchi',
    'Haiyang',
    'Tashi',
    'Jiya',
    'Zhangqiu',
    'Shangdong',
    'Zoucheng',
    'Jining',
    'Linyi',
    'Feixian',
    'Yishui',
    'Zaozhuang',
    'Zibo',
    'Laiwu',
    'Jiyang',
    'Yayu',
    'Zhenzhuquan',
    'Changzhi',
    'Changping',
    'Daxing',
    'Fuling',
    'Xiangyuan',
    'Shiji',
    'Changshan',
    'Shangzhou',
    'Kaihua',
    'Jiangshan',
    'Longzhou',
    'Citai',
    'Jinyang',
    'Yanhai',
    'Xintai',
    'Yinjiang',
    'Guxiang',
    'Yindian',
    'Yiwu',
    'Qujiang',
    'Juhua',
    'Zhicheng',
    'Ningde',
    'Meizhou',
    'Shaowu',
    'Zhanghou',
    "Nan'an Qu",
    'Xuexi',
    'Hanjiang',
    'Huaihe',
    'Bengbu',
    'Dangshan',
    'Shecheng',
    'Huangshan',
    'Huaibei',
    'Suixi',
    'Tongling',
    'Xuancheng',
    'Huoshan',
    'Chizhou',
    'Bozhou',
    'Boshan',
    'Weihai',
    'Quanzhou',
    "Qi'ao",
    'Lingcheng',
    'Xinhuang',
    'Gaiyu',
    'Hanzhong',
    "Yan'an",
    'Fugu',
    'Longnan',
    'Xiushui',
    'Puyang',
    'Xiaogan',
    'Qiqihar',
    'Lung',
    'Sichuan',
    'Leshan',
    'Bazhou',
    'Mianyang',
    'Meishan',
    'Suining',
    'Youshi',
    'Baoxing',
    'Rongshan',
    'Tangtou',
    'Nanhai',
    'Nanzhuang',
    'Danzao',
    'Chaozhou',
    'Nanfang',
    'Jinli',
    'Heping',
    'Lantang',
    'Lianping',
    'Puning',
    'Puning',
    'Jiexi',
    'Huicheng',
    'Fengshun',
    'Pingyuan',
    'Shaoguan',
    'Jiangwan',
    'Licheng',
    'Xindi',
    'Xiangxi',
    'Luohu',
    'Renmin',
    'Dawang',
    'Luofang',
    'Liannan',
    'Jieyang',
    'Xunyi Chengguanzhen',
    'Shunde',
    'Yangjiang',
    'Nanzhao',
    'Chaohu',
    'Zijin',
    'Heyuan',
    'Gaozhou',
    'Maoming',
    'Gaoji',
    'Suqian',
    "Sha'an",
    'Xiaojiang',
    'Ruili',
    'Tangxia',
    'Baixiang',
    'Huangdu',
    'Daishan',
    'Daidong',
    'Daju',
    'Maoba',
    'Qushan',
    'Caiyuan',
    "Zhang'ancun",
    'Cezi',
    'Zhanmao',
    'Luomen',
    'Luhua',
    'Huanghua',
    'Baixin',
    'Ximeng',
    'Suzhuang',
    'Jiguang',
    'Zhenhua',
    'Jingning Chengguanzhen',
    'Jinhe',
    'Lake',
    'Huafeng',
    'Haijing',
    'Ninghai',
    'Xianrenqiao',
    'Junpu',
    'Nanxi',
    'Dongli',
    'Zhili',
    'Yalan',
    'Sanmen',
    'Tiantai Chengguanzhen',
    'Yuhuan',
    'Qingma',
    'Gucheng',
    'Puqing',
    'Shashan',
    'Donghai',
    'Xiasha',
    'Beichan',
    'Wanbao',
    'Shangye',
    'Penglai',
    'Gaoting',
    'Fudao',
    'Daixi',
    'Pengshan',
    'Shilou',
    'Xinjian',
    'Panxi',
    'Xiandu',
    'Qingquan',
    'Huimin',
    'Zhapu',
    'Maodun',
    'Qiaoting',
    'Ziyang',
    'Qingshan',
    'Guangming',
    'Fangxian Chengguanzhen',
    'Gushi',
    'Huzhen',
    'Shuhong',
    'Wuyang',
    'Hushan',
    'Deyang',
    'Neijiang',
    'Nanchong',
    'Yucheng',
    'Dazhou',
    'Xichang',
    'Taoshan',
    'Xiabancheng',
    'Dazu',
    'Gunan',
    'Heliang',
    'Futian',
    'Liangping',
    'Daotou',
    'Wuxia',
    'Wushan',
    'Houxiangcheng',
    'Xihu',
    'Lushang',
    'Xiaogang',
    'Weibo',
    'Daqiao',
    'Yinxin',
    'Kangda',
    'Andong',
    'Guancheng',
    'Moushan',
    'Qitai',
    'Jinghua',
    'Huayuan',
    'Hongtang',
    'Qiaoxia',
    'Shangwu',
    'Shengping',
    'Jinhu',
    'Shangwang',
    'Taoshan',
    'Baotian',
    "Hai'an",
    'Xinqiao',
    'Yongqiang',
    'Mayu',
    'Wenhou',
    'Tengjiao',
    'Xiaozheng',
    'Meiyu',
    'Yingqian',
    'Shangma',
    'Qiaozhen',
    'Hongshun',
    'Ruifeng',
    'Xianqiao',
    'Dungdo',
    'Gushan',
    'Changqing',
    'Oujiang',
    'Baoyu',
    'Guantian',
    'Caozhai',
    'Xiaoshun',
    'Tongbai',
    'Lijiang',
    'Yuxi',
    'Chengjian',
    'Chuxiong',
    'Lanping',
    'Zhaotong',
    'Gejiu',
    'Yisa',
    'Simao',
    'Jingdong',
    'Wenshang',
    'Wenshan',
    'Dali',
    'Qujing',
    'Xinning',
    'Dujiacun',
    'Yanta',
    'Tianhai',
    'Zhixing',
    'Henghe',
    'Ruixing',
    'Meixi',
    'Gongli',
    'Tongyu',
    'Sanjiang',
    'Tingpang',
    'Sanyou',
    'Aizhou',
    'Jundu',
    'Dipu',
    'Shuangtang',
    'Taimen',
    'Jiaotou',
    'Xiazhi',
    'Zhujiajian',
    'Taohua',
    'Liuheng',
    'Mingdu',
    'Shenjiamen',
    'Longshan',
    'Dengbu',
    'Xiushan',
    'Daizhong',
    'Longteng',
    'Beiyuan',
    'Taili',
    'Choucheng',
    'Lucheng',
    'Chengxiang',
    'Dongying',
    'Gangcun',
    'Sailing',
    'Shifan',
    'Yaohua',
    'Jingyuan',
    'Fengtai',
    'Gaocheng',
    "Gu'an",
    'Shuangshipu',
    "Li'ao",
    "Xi'ao",
    'Tang Xian',
    'Tara',
    "Li'an",
    "Hu'an",
    'Datang',
    'Tumu',
    'Shuyi',
    'Gaoxin',
    'Boâ€™ao',
    'Baocheng',
    'Haifu',
    'Xiuying',
    'Xuanzhou',
    'Gongjiang',
    'Huayuji',
    "Qu'an",
    'Fuhao',
    'Weiyang',
    'Laohekou',
    'Nanshan',
    'Chen Xian',
    'Siting',
    'Wuhou',
    'Jiâ€™an',
    'Fuxin',
    'Xiqu',
    'Wangluo',
    'Erqi',
    'Donghong',
    'Gaobeidian',
    'Lianhua',
    'Chenzhou',
    'Dajie',
    'Chenghua',
    'Dongfeng',
    "Zhu'ang",
    'Maji',
    'Songling',
    'Chum',
    'Cenyang',
    'Hanshan',
    'Xiaomi',
    'Luohu',
    'Hainan',
    'Guangxi',
    'Changle',
    'Linfen',
    'Yuanping',
    'Jiazhuang',
    'Heibei',
    'Liaocheng',
    'Huaihua',
    'Rizhao',
    'Fuzhou',
    'Duyun',
    'Tianshui',
    'Guli',
    'Jinhai',
    'Chaoyang',
    'Taixing',
    'Fengxiang',
    'Jinpan',
    'Yizheng',
    'Yunfu',
    'Xinhui',
    'Wenlan',
    'Tieli',
    'Taihe',
    'Liuzhou',
    'Hechi',
    'Yunyang',
    'Zhuxi Chengguanzhen',
    'Heze',
    'Shiqiaopu',
    'Jiangbei',
    'Shanzhuang',
    'Danyang',
    "Huai'an",
    'Yantan',
    'Guangchang',
    'Xiguan',
    'Xigu',
    'Anying',
    'Xiangtong',
    'Guixi',
    'Xidian',
    'Pingliang',
    'Pinghu',
    'Changhe',
    'Yangshan',
    'Suji',
    'Baizhang',
    'Shuangxi',
    'Yanming',
    'Yiqiao',
    'Xinxing',
    'Dabao',
    'Sanyuanpu',
    'Gaobu',
    'Linghu',
    'Qingtong',
    'Shuanglin',
    'Dampu',
    "Qi'an",
    'Gouzhuang',
    'Changsheng',
    'Qianyu',
    'Datong',
    'Sandu',
    'Anjiang',
    'Huangjue',
    'Name',
    'Linqi',
    'Zhanghe',
    'Zheshang',
    'Huadian',
    'Kangcun',
    'Zhangshan',
    'Shouchang',
    'Qiantan',
    'Hongpan',
    "Lin'an",
    'Dingzhou',
    'Jiuzong',
    'Yuting',
    'Guali',
    'Jianci',
    'Kaiyun',
    'Chunhua',
    'Hongwei',
    'Shuguang',
    'Diankou',
    'Lidong',
    'Fengqing',
    'Fengqiao',
    'Fengqi',
    'Rongle',
    'Huangzhai',
    'Wuning',
    'Yahao',
    'Jifeng',
    'Ancheng',
    'Shangfang',
    'Jiahu',
    'Jinpu',
    'Potang',
    'Shangjiang',
    'Xingqiao',
    'Doujiang',
    'Nyingchi County',
    'Yangxunqiao',
    'Tongmin',
    'Lianghu',
    'Sundun',
    'Santiaolou',
    'Guilin',
    'Guigang',
    'Qinzhou',
    'Laibin',
    'Shenwan',
    'Zhenzhou',
    'Zhongdian',
    'Yidong',
    'Minhang',
    'Longmao',
    'Linjiang',
    'Taiping',
    'Mingde',
    'Xingchen',
    'Jiading',
    'Zhujiajiao',
    'Zhima',
    'Zhongnan',
    'Tongji',
    'Xinzha',
    'Enshi',
    'Taxi',
    'Zhengda',
    'Xuhui',
    'Xiangru',
    'Longjin',
    'Changzhong',
    'Ejia',
    'Huadong',
    'Donghe',
    'Dazhong',
    'Taipingyan',
    'Jintan',
    'Licheng',
    'Miyun',
    'Lingcheng',
    'Jingmen',
    'Kashgar',
    'Tianzhen',
    'Huquan',
    'Wuling',
    'Chengdong',
    'Xinrong',
    'Yungang',
    'Pingwang',
    'Niunaichang',
    'Shouyang',
    'Xiyang',
    'Heshun',
    'Liaoyang',
    'Jicheng',
    'Nandu',
    'Jiexiu',
    'Zhaoyu',
    'Pingyao County',
    'Mingxing',
    'Lingshi',
    'Linjiang',
    'Gaoping',
    'Bagong',
    'Chongwen',
    'Gongyi',
    'Fangshan',
    'Dongcun',
    'Xingxian',
    'Zhongyang',
    'Jiaokou',
    'Liulin',
    'Linzhou',
    'Fenyang',
    'Fengcheng',
    'Xiezhou',
    'Hejin',
    'Pinglu',
    'Ruicheng',
    'Xiedian',
    'Wenxi',
    'Yaofeng',
    'Kouqian',
    'Wutai',
    'Yangquan',
    'Xiaoqu',
    'Yijing',
    'Xinghua',
    'Haozhuang',
    'Loufan',
    'Yangqu',
    'Gujiao',
    'Qingyuan',
    'Luzhou',
    'Laiyi',
    'Jiamusi',
    'Qinghai',
    'Tongliao',
    'Sanhe',
    'Tangdukou',
    "Wu'an",
    'Xianlin',
    'Jingzhong',
    'Baishan',
    'Qingnian',
    'Yintian',
    'Huangshi',
    'Huludao',
    'Fenggang',
    "Sui'an",
    'Putian',
    'Shima',
    'Jiangyou',
    'Yangling',
    'Xinjiang',
    'Zhumadian',
    'Meizhou',
    'Pingxiang',
    'Suzhou',
    'Xucheng',
    'Linbei',
    'Jiedong',
    'Hangong',
    'Jiehe',
    'Ansu',
    'Huge',
    'Gujiang',
    "Lin'e",
    'Mingzhu',
    'Youjiang',
    'Tianli',
    'Baise City',
    'Longlin',
    'Jingxi',
    'Napo',
    'Tianyang',
    'Tiandong',
    'Tieshan',
    'Beihai',
    'Qianling',
    "Guang'an",
    'Yibin',
    'Lincang',
    'Zunyi',
    'Zhongxiang',
    'Yanjiao',
    'Yanji',
    'Qike',
    'Nenjiang',
    'Huanggang',
    'Hupi',
    'Xinyang',
    'Minjiang',
    'Sanmao',
    'Yanchang',
    'Jiahe Chengguanzhen',
    'Baile',
    'Xibu',
    'Doumen',
    'Huangze',
    'Keqiao',
    'Linnan',
    'Xingwang',
    'Liangzhu',
    'Yunting',
    'Meiyuan',
    'Lishang',
    'Shangsi',
    'Lida',
    'Jiangnan',
    'Xingguang',
    'Jingdu',
    'Nanyuan',
    'Shuozhou',
    'Xieqiao',
    "Chang'an",
    'Xucun',
    'Zhouwangmiao',
    'Youchegang',
    'Chengdong',
    'Donglin',
    'Weitang',
    'Fengnan',
    'Lize',
    'Xitang',
    'Xiadianmiao',
    'Dashun',
    'Ganyao',
    'Fanjing',
    'Huimin',
    'Yaozhuang',
    'Taozhuang',
    'Yifu',
    'Yuhui',
    'Tianning',
    'Yangmiao',
    'Hongxi',
    'Dayun',
    'Dingzha',
    'Sizhou',
    'Yucheng',
    'Gaozhao',
    'Xinao',
    'Lanhai',
    'Jinde',
    'Ruiting',
    'Anke',
    'Haizhou',
    'Guangtai',
    'Jielong',
    'Hualong',
    'Huayang',
    'Xiashi',
    'Ziyu',
    'Jinlu',
    'Tianhong',
    'Mingjia',
    'Jingtian',
    'Yunling',
    'Xiangsheng',
    'Cengang',
    'Dongchang',
    'Yungcun',
    'Zhongtian',
    'Longhua',
    'Jiadi',
    'Haicheng',
    'Fengshang',
    'Huangxuan',
    'Hexun',
    'Baziqiang',
    'Baiyi',
    'Yuehua',
    'Fengjiang',
    'Zhiyuan',
    'Jinma',
    'Chengfeng',
    'Anzhou',
    'Shangzhang',
    'Aishang',
    'Huangjia',
    'Oucheng',
    'Wangfu',
    'Pengyu',
    'Zhengjiang',
    'Guangtong',
    'Dengzhou',
    'Dehui',
    'Yinhai',
    'Libang',
    'Anbang',
    'Jinwo',
    'Weidun',
    "Shi'er",
    'Lincheng',
    'Yuanheng',
    'Defeng',
    'Jielong',
    'Tianma',
    'Guangyuan',
    'Caiyuan',
    'Suxi',
    'Yuyuan',
    'Yongchuan',
    'Mingjing',
    'Zhongqiao',
    'Xiangyi',
    'Shuangcheng',
    'Deyuan',
    'Maodian',
    'Bangke',
    'Jinding',
    'Yifeng',
    'Jianyang',
    'Shengdu',
    'Lingdong',
    "Shu'an",
    'Boli',
    'Yutou',
    'Xiakou',
    'Qinghu',
    'Tanshi',
    'Fenglin',
    'Siduxu',
    'Dachen',
    "Bao'an",
    'Xiangjiang',
    'Bole',
    'Qingxin',
    'Longtai',
    'Cuntou',
    'Majin',
    'Xintangbian',
    'Hecun',
    'Kecheng',
    'Jiangwang',
    'Xiaosi',
    'Qingshuiwan',
    'Zhonggongmiao',
    'Jinshadi',
    'Chunjiang',
    'Baidu',
    'Fangqiao',
    'Dongxin',
    'Qijiashan',
    'Yuanyang',
    'Zhouxiang',
    'Shengshan',
    'Xingfeng',
    'Jiuba',
    'Lianshan',
    'Xinzhou',
    'Banxi',
    'Dayan',
    'Daxie',
    'Xizhou',
    'Dancheng',
    'Shiqi',
    'Kandun',
    'Kuangyan',
    'Lingxi',
    'Baishi',
    'Feihong',
    'Yangda',
    'Jiahao',
    'Daxing',
    'Huatan',
    'Fuyang',
    'Xushan',
    'Kang Xian',
    'Qiaoyuan',
    'Zhongyin',
    'Yuetai',
    'Qingtian',
    'Kuishan',
    'Mudanjiang',
    'XigazÃª',
    'Shangnan',
    'Chamdo',
    'Nagqu',
    'Dawa',
    'Baoluo',
    'Shanghu',
    'Yanggu',
    'Kumul',
    'Turpan Diqu',
    'Yili',
    'Bole',
    'Aksu',
    'Hetian',
    'Bamao',
    'Jimo',
    'Beijie',
    'Jindi',
    'Zhizhong',
    'Zhongwei',
    'Wuzhong',
    'Guyuan',
    'Chendu',
    'Xiangfeng',
    'Bailicun',
    'Huazhou',
    'Ruihu',
    'Qiaolian',
    'Huiyang',
    'Hechi',
    'Shennan',
    'Shuangqiao',
    'Shangqiu',
    'Sanmenxia',
    'Xiantao',
    'Jingzhou',
    'Ezhou',
    'Jinzhou',
    'Suihua',
    'Danjiang',
    'Santai',
    'Taiping',
    'Yongzhou',
    'Taocheng',
    'Sanpailou',
    'Yangzhong',
    'Yicheng',
    'Fengxian',
    'Yuhuayuan',
    "Jing'an",
    'Zhengdi',
    'Haihong',
    'Luwan',
    'Huashan',
    'Zhongtu',
    'Dongxi',
    'Dongcheng',
    'Lidu',
    'Shanrui',
    'Yuanpu',
    'Huajiashan',
    'Tianyi',
    'Tianchang',
    'Beiying',
    'Nanhan',
    'Jinci',
    'Chaicun',
    'Huagong',
    'Dahuaishu',
    'Huozhou',
    'Xincheng',
    'Yicheng',
    'Changning',
    'Qingyang',
    'Pucheng',
    'Kouquan',
    'Beixiao',
    'Yuanqu',
    'Yuncheng',
    'Siyang',
    'Benxi',
    'Wuzhen',
    'Tieling',
    'Langzhong',
    'Yuzi',
    'Jishu',
    'Nanda',
    'Huamei',
    'Yanta',
    'Tangxi',
    'Huaxing',
    'Tancheng',
    'Xiazhuang',
    "Liu'an",
    'Liucheng',
    'Guigang',
    'Bobai',
    'Yongning',
    'Hezhou',
    'Hecheng',
    'Zhongzhou',
    'Jinyin',
    'Niulanshan',
    'Huairou',
    'Qiandao',
    'Huangyuan Chengguanzhen',
    'Xishan',
    'Beiyuan',
    'Beixi',
    'Xingcheng',
    'Zhongyuan',
    'Pingyi',
    'Shijingxiang',
    'Guili',
    "Huai'an",
    'Shenyan',
    'Dianzi',
    'Lintong',
    'Hebei',
    'Huangdao',
    'Baotou',
    'Hailar',
    'Fendou',
    'Edong',
    'Xingye',
    'Hezuo',
    'Yingchuan',
    'Zhangshu',
    'Nanjin',
    'Qiongshan',
    'Jiaying',
    'Mengzi',
    'Panzhihua',
    'Zigong',
    'Danjiangkou',
    'Anda',
    'Hulan',
    'Hekou',
    'Xuanwu',
    'Shengfang',
    'Huabei',
    'Huangzhou',
    'Zhangjiagang',
    'Wanxi',
    'Cangzhou',
    'Zhengding',
    'Luocheng',
    'Yunnan',
    'Baliqiao',
    'Jiaxin',
    'Shangxi',
    'Xiuyu',
    'Chengxiang',
    'Xianyou',
    'Guyong',
    'Qidu',
    'Shuangcheng',
    'Xiapu',
    'Fuding',
    "Ping'an",
    'Jin Jiang',
    'Luqiao',
    'Anxi',
    'Longmen',
    'Hongshan',
    'Taoxi',
    'Changting',
    'Lianjiang',
    'Yanshi',
    'Dayang',
    'Dongxiao',
    'Xinmin',
    'Zhangjiajie',
    'Side',
    'Liaoyuan',
    'Dongliu',
    'Shifang',
    'Caoxi',
    "Xin'an",
    'Longdong',
    'Shangbao',
    'Gaoqiao',
    'Hongyangshu',
    'Lugouqiao',
    'Tianshan',
    'Jinqiao',
    'Yongjiu',
    'Huaxia',
    'Xingyuan',
    'Kunlun',
    'Shangzhi',
    'Keshan',
    'Tailai',
    'Longjiang',
    'Gannan',
    'Hailin',
    'Dongning',
    'Muling',
    'Linkou',
    "Ning'an",
    'Pengcheng',
    'Tangyuan',
    'Huanan',
    'Daqing',
    'Qinggang',
    'Mishan',
    'Huixin',
    'Shuangyashan',
    'Baoqing',
    'Hegang',
    'Mingshui',
    'Beishan',
    'Liuhe',
    'Jinshi',
    'Xinyi',
    'Fengzhou',
    'Chengnan',
    'Renrong',
    'Heyuan',
    'Longgong',
    'Shancheng',
    'Buxin',
    'Jingbei',
    'Bibo',
    'Jintian',
    'Huali',
    'Luoling',
    'Cuiyuan',
    'Beidou',
    'Cuizhu',
    'Cuibei',
    'Shuitian',
    'Shuiku',
    'Sungang',
    'Liyuan',
    'Caopu',
    'Yuanling',
    'Hongling',
    'Baihua',
    'Huaxin',
    'Guiyuan',
    'Binhe',
    'Nanhu',
    'Xingang',
    'Shangbu',
    'Tongyuan',
    'Fumin',
    'Shixia',
    'Xinsha',
    'Shangsha',
    'Zhongfu',
    'Yitian',
    'Fuqiang',
    'Jinglong',
    'Huaqiang',
    'Meilian',
    'Jinglian',
    'Shiling',
    'Meilin',
    'Yaoluoping',
    'Sanjiu',
    'Zhuxiang',
    'Zhulin',
    'Zhuyuan',
    'Yunfeng',
    'Shizheng',
    'Jinzhong',
    'Shuiwu',
    'Zongxoi',
    'Dianzhong',
    'Shizhong',
    'Dezhou',
    'Baiyashi',
    'Minzhu',
    'Xiping',
    'Danfeng',
    'Ganzhou',
    'Yangcun',
    'Minzu',
    'Junlin',
    'Xinguang',
    'Jinxing',
    'Ganjiang',
    'Liudao',
    'Shamen',
    'Huanzhen',
    'Shuanglong',
    'Wenlin',
    'Biyang',
    'Nantang',
    'Lecheng',
    'Yandang',
    "Xin'anjiang",
    'Huanghu',
    'Jiuguan',
    'Deyue',
    'Yingtai',
    'Chengxi',
    'Luobu',
    'Houzhai',
    'Eshan',
    'Huayue',
    'Xiyue',
    'Nanmen',
    'Fenshang',
    'Jishou',
    'Xiatangyunxiang',
    'Yalongwan',
    'Yangce',
    'Longxing',
    'Liujian',
    'Tangchang',
    'Gaiwei',
    'Xinyou',
    'Bantou',
    'Daji',
    'Duwei',
    'Jiaowei',
    'Chenyang',
    'Bangtou',
    'Fengshan',
    'Baitang',
    'Sanjiangkou',
    'Lihou',
    'Fengting',
    'Fengcheng',
    'Rongjin',
    'Luoyuan',
    'Minjie',
    'Tangcheng',
    'Jinying',
    "Su'ao",
    'Tancheng',
    'Changzheng',
    'Shule',
    'Qianjiang',
    'Xinzheng',
    'Daye',
    'Jiaoyuan',
    'Jiangjin Qu',
    "Du'an",
    'Tengzhou',
    'Karamay',
    'Wuyishan',
    'Shejiazhuang',
    'Shaoguang',
    'Jinbi',
    'Shanwei',
    'Jingshan',
    'Zhancheng',
    'Anbu',
    'Huigang',
    'Tianle',
    'Xibei',
    'Beilin',
    'Lingnga',
    'Jixi',
    'Caijing',
    'Boji',
    'Baicheng',
    'Wuhai',
    'Niaojiang',
    'Taishan',
    'Yuzhong Chengguanzhen',
    'Dachuan',
    'Zhongfang',
    'Nanning',
    'Haocheng',
    'Goushan',
    'Lujiazhi',
    'Fuchun',
    'Jiangjia',
    'Yangqitan',
    'Mingzhou',
    'Daoxu',
    'Jielong',
    'Qianjin',
    'Changchao',
    'Hengjie',
    'Mayao',
    'Sanzhang',
    'Jinming',
    'Lianshi',
    'Sanlian',
    'Hualin',
    'Zhenxi',
    'Yuxiang',
    'Hechun',
    'Ruiying',
    'Xianchang',
    'Zhongji',
    'Fanzhong',
    'Longxiang',
    'Yanguan',
    'Zhijiang',
    'Yiyang',
    'Huazhong',
    'Yinshan',
    'Cangshang',
    'Banyou',
    'Jindian',
    'Mawei',
    'Anqiu',
    'Shouguang',
    'Linqu',
    'Kuiju',
    'Dingtao',
    'Xiaoxi',
    'Luozhuang',
    'Xuezhuang',
    'Mengyin',
    'Mengshan',
    'Zhangzhuang',
    "Yin'an",
    'Bianzhuang',
    'Zhangzhai',
    'Jiangguantun',
    'Linqing',
    "Dong'e",
    'Chiping',
    'Nanguo',
    'Baying',
    'Linxia Chengguanzhen',
    'Luorong',
    'Liujiang',
    'Daojiang',
    'Liudong',
    'Jinguang',
    'Anhu',
    'Lingang',
    'Jiaoyu',
    'Zuxi',
    'Xiangsi',
    'Jianzhong',
    'Jinzao',
    "Nan'an",
    'Xizhang',
    'Jondag',
    'Xiaojiahe',
    'Yandao',
    'Chadianzi',
    'Zhongyicun',
    'Jialing',
    'Jianxing',
    'Xichong',
    'Sanyi',
    'Hongyan',
    'Base',
    'Yongxing',
    'Nanhe',
    'Naxi Yaozuxiang',
    'Dongxing',
    'Rongxian',
    'Nanying',
    'Huaying',
    'Huagongchang',
    'Gongjiao',
    'Huanghou',
    'Zhongjian',
    'Xingxing',
    'Shanhe',
    'Liwu',
    'Shanshui',
    "Shizhu'an",
    'Anguo',
    'Yanjiao',
    'Linze',
    'Duoba',
    'Yingcai',
    'Hongchang',
    'Jingjiang',
    'Taikang',
    'Zhaozhou',
    'Lindian',
    'Zhonglin',
    'Pingyin',
    'Nanxianglong',
    'Chengxian Chengguanzhen',
    'Yuhong',
    'Yuhan',
    'Xiayuan',
    'Dongjiaying',
    'Songyuan',
    'Dasha',
    'Guohe',
    'Yuanchang',
    'Hangbu',
    'Zhangjiang',
    'Longjian',
    'Zaoyang',
    'Shensha',
    'Xijiao',
    'Xijiao',
    'Chuansha',
    'Gongyuan',
    'Fendou',
    'Dongshi',
    'Nongkeng',
    'Huacheng',
    'Qingzhou',
    'Fangzi',
    'Taishan',
    'Haiyu',
    'Shouchun',
    'Wuhai',
    'Chifeng',
    'Hetao',
    'Shekou',
    'Qianhai',
    "Lu'an",
    'Yancheng',
    'Jinghong',
    "Ning'er",
    'Zhuozhou',
    'Luquan',
    "Li'an",
    'Suqian',
    'Dabu',
    'Luoyang',
    'Feiyun',
  ],
  tld: ['.cn', '.中国', '.中國', '.公司', '.网络'],
  cca2: 'CN',
  ccn3: '156',
  cca3: 'CHN',
  cioc: 'CHN',
  currency: ['CNY'],
  callingCode: ['86'],
  capital: 'Beijing',
  altSpellings: [
    'CN',
    'Zhōngguó',
    'Zhongguo',
    'Zhonghua',
    "People's Republic of China",
    '中华人民共和国',
    'Zhōnghuá Rénmín Gònghéguó',
  ],
  region: 'Asia',
  subregion: 'Eastern Asia',
  languages: { cmn: 'Mandarin' },
  translations: {
    cym: { official: "People's Republic of China", common: 'Tsieina' },
    deu: { official: 'Volksrepublik China', common: 'China' },
    fra: { official: 'République populaire de Chine', common: 'Chine' },
    hrv: { official: 'Narodna Republika Kina', common: 'Kina' },
    ita: { official: 'Repubblica popolare cinese', common: 'Cina' },
    jpn: { official: '中華人民共和国', common: '中国' },
    nld: { official: 'Volksrepubliek China', common: 'China' },
    por: { official: 'República Popular da China', common: 'China' },
    rus: { official: 'Народная Республика Китай', common: 'Китай' },
    spa: { official: 'República Popular de China', common: 'China' },
    fin: { official: 'Kiinan kansantasavalta', common: 'Kiina' },
  },
  latlng: [35, 105],
  demonym: 'Chinese',
  landlocked: false,
  borders: [
    'AFG',
    'BTN',
    'MMR',
    'HKG',
    'IND',
    'KAZ',
    'PRK',
    'KGZ',
    'LAO',
    'MAC',
    'MNG',
    'PAK',
    'RUS',
    'TJK',
    'VNM',
  ],
  area: 9706961,
  provinces: [
    'Anhui',
    'Beijing',
    'Chongqing',
    'Fujian',
    'Gansu',
    'Guangdong',
    'Guangxi',
    'Guizhou',
    'Hainan',
    'Hebei',
    'Heilongjiang',
    'Henan',
    'Hubei',
    'Hunan',
    'Jiangsu',
    'Jiangxi',
    'Jilin',
    'Liaoning',
    'Nei Mongol',
    'Ningxia',
    'Qinghai',
    'Shaanxi',
    'Shandong',
    'Shanghai',
    'Shanxi',
    'Sichuan',
    'Tianjin',
    'Xinjiang',
    'Xizang (Tibet)',
    'Yunnan',
    'Zhejiang',
  ],
  nativeName: '中国',
  timezones: ['UTC+08:00'],
} as const;
