export const JEY = {
  name: {
    common: 'Jersey',
    official: 'Bailiwick of Jersey',
    native: {
      eng: { official: 'Bailiwick of Jersey', common: 'Jersey' },
      fra: { official: 'Bailliage de Jersey', common: 'Jersey' },
      nrf: { official: 'Bailliage dé Jèrri', common: 'Jèrri' },
    },
  },
  cities: ['Saint Helier', 'Saint Peter', 'Saint Johnâ€™s Church'],
  tld: ['.je'],
  cca2: 'JE',
  ccn3: '832',
  cca3: 'JEY',
  cioc: '',
  currency: ['GBP'],
  callingCode: ['44'],
  capital: 'Saint Helier',
  altSpellings: [
    'JE',
    'Bailiwick of Jersey',
    'Bailliage de Jersey',
    'Bailliage dé Jèrri',
  ],
  region: 'Europe',
  subregion: 'Northern Europe',
  languages: { eng: 'English', fra: 'French', nrf: 'Jèrriais' },
  translations: {
    deu: { official: 'Vogtei Jersey', common: 'Jersey' },
    fra: { official: 'Bailliage de Jersey', common: 'Jersey' },
    hrv: { official: 'Struka od Jersey', common: 'Jersey' },
    ita: { official: 'Baliato di Jersey', common: 'Isola di Jersey' },
    jpn: { official: 'ジャージの得意分野', common: 'ジャージー' },
    nld: { official: 'Baljuwschap Jersey', common: 'Jersey' },
    por: { official: 'Bailiado de Jersey', common: 'Jersey' },
    rus: { official: 'Коронное владение Джерси', common: 'Джерси' },
    spa: { official: 'Bailía de Jersey', common: 'Jersey' },
    fin: { official: 'Jersey', common: 'Jersey' },
  },
  latlng: [49.25, -2.16666666],
  demonym: 'Channel Islander',
  landlocked: false,
  borders: [],
  area: 116,
} as const;
