export const PRY = {
  name: {
    common: 'Paraguay',
    official: 'Republic of Paraguay',
    native: {
      grn: { official: 'Tetã Paraguái', common: 'Paraguái' },
      spa: { official: 'República de Paraguay', common: 'Paraguay' },
    },
  },
  cities: [
    'Coronel Oviedo',
    'Yataity',
    'AsunciÃ³n',
    'Boqueron',
    'San Juan Bautista',
    'Ayolas',
    'Fernando de la Mora',
    'Nemby',
    'Santa Rosa',
    'LambarÃ©',
    'Hernandarias',
    'Ciudad del Este',
    'Salto del Guaira',
    'San Alberto',
    'San Lorenzo',
    'Luque',
    'CapiatÃ¡',
    'Villarrica',
    'Villa Elisa',
    'Villa Hayes',
    'Colonia Mariano Roque Alonso',
    'EncarnaciÃ³n',
    'Chore',
    'La Paz',
    'Fuerte Olimpo',
    'Santa Rita',
    'Presidente Franco',
    'Loma Plata',
    'Limpio',
    'Hohenau',
    'Independencia',
  ],
  tld: ['.py'],
  cca2: 'PY',
  ccn3: '600',
  cca3: 'PRY',
  cioc: 'PAR',
  currency: ['PYG'],
  callingCode: ['595'],
  capital: 'Asunción',
  altSpellings: [
    'PY',
    'Republic of Paraguay',
    'República del Paraguay',
    'Tetã Paraguái',
  ],
  region: 'Americas',
  subregion: 'South America',
  languages: { grn: 'Guaraní', spa: 'Spanish' },
  translations: {
    deu: { official: 'Republik Paraguay', common: 'Paraguay' },
    fra: { official: 'République du Paraguay', common: 'Paraguay' },
    hrv: { official: 'Republika Paragvaj', common: 'Paragvaj' },
    ita: { official: 'Repubblica del Paraguay', common: 'Paraguay' },
    jpn: { official: 'パラグアイ共和国', common: 'パラグアイ' },
    nld: { official: 'Republiek Paraguay', common: 'Paraguay' },
    por: { official: 'República do Paraguai', common: 'Paraguai' },
    rus: { official: 'Республика Парагвай', common: 'Парагвай' },
    spa: { official: 'República de Paraguay', common: 'Paraguay' },
    fin: { official: 'Paraguayn tasavalta', common: 'Paraguay' },
  },
  latlng: [-23, -58],
  demonym: 'Paraguayan',
  landlocked: true,
  borders: ['ARG', 'BOL', 'BRA'],
  area: 406752,
  provinces: [
    'Alto Paraguay',
    'Alto Parana',
    'Amambay',
    'Asuncion (city)',
    'Boqueron',
    'Caaguazu',
    'Caazapa',
    'Canindeyu',
    'Central',
    'Concepcion',
    'Cordillera',
    'Guaira',
    'Itapua',
    'Misiones',
    'Neembucu',
    'Paraguari',
    'Presidente Hayes',
    'San Pedro',
  ],
  nativeName: 'Paraguay',
  timezones: ['UTC−04:00'],
} as const;
