export const CYP = {
  name: {
    common: 'Cyprus',
    official: 'Republic of Cyprus',
    native: {
      ell: { official: 'Δημοκρατία της Κύπρος', common: 'Κύπρος' },
      tur: { official: 'Kıbrıs Cumhuriyeti', common: 'Kıbrıs' },
    },
  },
  cities: [
    'Limassol',
    'Nicosia',
    'Larnaca',
    'Paphos',
    'Geroskipou',
    'Ayia Marina',
    'Livadia',
    'Kato Polemidia',
    'Chlorakas',
    'Paralimni',
    'Parekklisha',
    'Morfou',
    'Strovolos',
    'Kyrenia',
    'Laxia',
    'Aradippou',
    'Mouttagiaka',
    'Kiti',
    'Prodromi',
    'Agia Anna',
    'Kato Lakatamia',
    'Famagusta',
    'Deryneia',
    'Sotira',
    'Tera',
  ],
  tld: ['.cy'],
  cca2: 'CY',
  ccn3: '196',
  cca3: 'CYP',
  cioc: 'CYP',
  currency: ['EUR'],
  callingCode: ['357'],
  capital: 'Nicosia',
  altSpellings: [
    'CY',
    'Kýpros',
    'Kıbrıs',
    'Republic of Cyprus',
    'Κυπριακή Δημοκρατία',
    'Kıbrıs Cumhuriyeti',
  ],
  region: 'Europe',
  subregion: 'Eastern Europe',
  languages: { ell: 'Greek', tur: 'Turkish' },
  translations: {
    cym: { official: 'Republic of Cyprus', common: 'Cyprus' },
    deu: { official: 'Republik Zypern', common: 'Zypern' },
    fra: { official: 'République de Chypre', common: 'Chypre' },
    hrv: { official: 'Republika Cipar', common: 'Cipar' },
    ita: { official: 'Repubblica di Cipro', common: 'Cipro' },
    jpn: { official: 'キプロス共和国', common: 'キプロス' },
    nld: { official: 'Republiek Cyprus', common: 'Cyprus' },
    por: { official: 'República de Chipre', common: 'Chipre' },
    rus: { official: 'Республика Кипр', common: 'Кипр' },
    spa: { official: 'República de Chipre', common: 'Chipre' },
    fin: { official: 'Kyproksen tasavalta', common: 'Kypros' },
  },
  latlng: [35, 33],
  demonym: 'Cypriot',
  landlocked: false,
  borders: ['GBR'],
  area: 9251,
  provinces: [
    'Famagusta',
    'Kyrenia',
    'Larnaca',
    'Limassol',
    'Nicosia',
    'Paphos',
  ],
  nativeName: 'Κύπρος',
  timezones: ['UTC+02:00'],
} as const;
