import { server } from '@api/request';
import * as models from '@models';
import * as typeGaurds from '@tools/type-guards';

/**
 * List all existing password resets.
 *
 * @returns ...
 */
export async function list() {
  return await server.list(
    'v2/admin/forgot-password',
    processForgotPasswordData,
  );
}

/**
 * ...
 */
export interface GetOptions {
  resetKey: string;
}

/**
 * Get Password Reset to validate.
 *
 * @param options ...
 * @returns ...
 */
export async function get(options: GetOptions) {
  return await server.get(
    `v2/forgot-password/${options.resetKey} `,
    processForgotPasswordData,
  );
}

/**
 * ...
 */
export interface ResetOptions {
  email: string;
}

/**
 * ...
 *
 * @param options ...
 * @return ...
 */
export async function resetPassword(options: ResetOptions) {
  await server.post('v2/forgot-password', options);
}

/**
 * ...
 */
export interface DeleteOptions {
  forgotPasswordId: models.ForgotPassword['id'];
}

/**
 * Delete a specified password reset.
 *
 * @param options Request options bag.
 */
export async function del(options: DeleteOptions) {
  await server.delete(
    `v2/admin/forgot-password/${options.forgotPasswordId}`,
  );
}

//#region Helper Functions

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
function isValidForgotPasswordData(
  value: unknown,
): value is models.ForgotPassword {
  if (!typeGaurds.isObject(value)) return false;

  return true;
}

/**
 * ...
 *
 * @param data ...
 * @return ...
 */
function processForgotPasswordData(data: unknown) {
  if (!isValidForgotPasswordData(data)) {
    throw new Error('Invalid forgot password data item.');
  }

  return data;
}

//#endregion Helper Functions
