/** ... */
type LocalStorageEntry = [key: string, value: string];

declare module 'vue/types/vue' {
  interface Vue {
    $ls: LocalStorageService;
  }
}

/**
 * ...
 */
export class LocalStorageService {
  /** ... */
  get entries() {
    const entries: LocalStorageEntry[] = [];

    for (let n = 0; n < localStorage.length; n++) {
      const key = localStorage.key(n) as string;
      const value = localStorage.getItem(key) as string;

      entries.push([key, value]);
    }

    return entries;
  }

  /**
   * Returns the current value associated with the given key, or `null` if the
   * given key does not exist.
   *
   * @param key Id to use for lookup.
   * @return Raw value.
   */
  get(key: string) {
    return localStorage.getItem(key);
  }

  /**
   * Returns the deserialized value associated with the given key, or `null` if
   * the given key does not exist.
   *
   * @param key Id to use for lookup.
   * @return Deserialized value.
   */
  getObject(key: string) {
    const value = this.get(key);

    return value === null ? value : (JSON.parse(value) as unknown);
  }

  /**
   * Sets the value of the pair identified by key to value, creating a new
   * key/value pair if none existed for key previously.
   *
   * @param key Id for the value.
   * @param value Raw value to be stored.
   */
  set(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  /**
   * Serializes and sets the value of the pair identified by key to value,
   * creating a new key/value pair if none existed for key previously.
   *
   * @param key Id for the value.
   * @param value Value to be stored.
   */
  setObject(key: string, value: unknown) {
    this.set(key, JSON.stringify(value));
  }

  /**
   * Removes the key/value pair with the given key, if a key/value pair with the
   * given key exists.
   *
   * @param key Id of the key-value pair to delete.
   * @param options Options object.
   */
  remove(key: string) {
    localStorage.removeItem(key);
  }

  /**
   * Removes all key/value pairs, if there are any.
   */
  clear() {
    localStorage.clear();
  }

  /**
   * ...
   *
   * @param predicate ...
   * @return ...
   */
  find(predicate: (entry: LocalStorageEntry) => unknown) {
    return this.entries.find(predicate) || undefined;
  }
}

/** ... */
export const ls = new LocalStorageService();

export default ls;
