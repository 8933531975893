var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "header",
    { ref: "rootEl", class: _setup.classList, attrs: { id: "app-header" } },
    [
      !_setup.isHidden
        ? _c("div", { staticClass: "navbar" }, [
            _c(
              "div",
              { staticClass: "navbar-inner" },
              [
                _c(
                  "div",
                  { staticClass: "navbar__header" },
                  [
                    _c(
                      "RouterLink",
                      { staticClass: "navbar-brand", attrs: { to: "/" } },
                      [
                        _c("ZephyrLogo", {
                          attrs: { width: 165, colors: "currentColor" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _setup.isMobile
                      ? _c(_setup.MobileMenuButton, {
                          attrs: { collapsed: _setup.collapsed },
                          on: {
                            click: function ($event) {
                              _setup.collapsed = !_setup.collapsed
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("Transition", [
                  !_setup.isMobile || !_setup.collapsed
                    ? _c(
                        "div",
                        {
                          class: [
                            "navbar-main",
                            {
                              "navbar-mobile": _setup.isMobile,
                              hidden: !_setup.renderNavItems,
                            },
                          ],
                        },
                        [
                          _c(
                            "nav",
                            [
                              _vm._l(_setup.menu, function (item) {
                                return [
                                  "route" in item
                                    ? _c(
                                        "RouterLink",
                                        {
                                          key: item.id,
                                          staticClass: "nav-item nav-item-link",
                                          attrs: {
                                            to: { name: item.route },
                                            "active-class": "active",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.title) +
                                              "\n              "
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "a",
                                        {
                                          key: item.id,
                                          staticClass: "nav-item nav-item-link",
                                          attrs: {
                                            href: item.url,
                                            target: "_blank",
                                            rel: "noopener noreferrer",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.title) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                ]
                              }),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _setup.isMobile
                            ? _c("div", { style: { width: "100%" } }, [
                                _c("div", { staticClass: "nav-divider" }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "sidebar-mobile-nav" },
                                  [_c("SidebarMobile")],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "nav-divider" }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "navbar-side" },
                            [
                              _setup.auth.user
                                ? _c(_setup.NavItemIcon, {
                                    attrs: {
                                      title: "Dashboard",
                                      icon: _setup.faTachometerFast,
                                    },
                                    on: { click: _setup.goToDashboard },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _setup.canChangeRoles
                                ? _c(_setup.NavItemIcon, {
                                    attrs: {
                                      title: "Change Role",
                                      icon: _setup.faUsers,
                                    },
                                    on: { click: _setup.openRoleSelect },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(_setup.NavItemIcon, {
                                attrs: {
                                  title: "Cart",
                                  icon: _setup.faShoppingCart,
                                  badge: _setup.cartItemCount,
                                },
                                on: { click: _setup.goToCart },
                              }),
                              _vm._v(" "),
                              _setup.auth.user
                                ? _c(
                                    "div",
                                    { staticClass: "position-relative" },
                                    [
                                      _c(_setup.NavItemIcon, {
                                        attrs: {
                                          title: "Announcements",
                                          icon: _setup.faBell,
                                          badge:
                                            _setup.unreadAnnouncementsCount,
                                        },
                                        on: {
                                          click:
                                            _setup.toggleAnnouncementsDropdown,
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        _setup.AnnouncementsDropdown,
                                        _vm._b(
                                          { ref: "announcementsDropdown" },
                                          "AnnouncementsDropdown",
                                          {
                                            announcements: _setup.announcements,
                                          },
                                          false
                                        )
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _setup.auth.user
                                ? _c(_setup.NavItemIcon, {
                                    attrs: {
                                      title: "Settings",
                                      icon: _setup.faCog,
                                    },
                                    on: { click: _setup.openUserSettingsModal },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.$env.isDevelopment
                                ? _c(_setup.NavItemIcon, {
                                    attrs: {
                                      title: "Dev Test Page",
                                      icon: _setup.faVial,
                                    },
                                    on: { click: _setup.goToTesting },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", { staticClass: "nav-divider" }),
                              _vm._v(" "),
                              !_setup.auth.user
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "nav-item nav-item-action",
                                      on: { click: _setup.login },
                                    },
                                    [
                                      _vm._v(
                                        "\n              Log In\n            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !_setup.auth.user
                                ? _c(
                                    "a",
                                    {
                                      staticClass:
                                        "nav-item nav-item-action primary",
                                      on: { click: _setup.goToIndividuals },
                                    },
                                    [
                                      _vm._v(
                                        "\n              Purchase\n            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _setup.showDownloadButton
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "nav-item nav-item-button",
                                      attrs: {
                                        id: "download-zephyr-button",
                                        disabled: !_setup.launcherLink,
                                        href: _setup.launcherLink,
                                      },
                                    },
                                    [
                                      _c("Icon", {
                                        attrs: {
                                          icon: _setup.faArrowCircleDown,
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "button-text" },
                                        [_vm._v("Get Zephyr")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _setup.auth.user
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "nav-item nav-item-action",
                                      attrs: { href: "javascript:void(0)" },
                                      on: { click: _setup.logout },
                                    },
                                    [_c("span", [_vm._v("Logout")])]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _setup.route.name !== "preCheckout"
                  ? _c(_setup.CartWidget)
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _setup.siteAnnouncement
        ? _c("SiteAnnouncement", {
            attrs: { announcement: _setup.siteAnnouncement },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }