export const TTO = {
  name: {
    common: 'Trinidad and Tobago',
    official: 'Republic of Trinidad and Tobago',
    native: {
      eng: {
        official: 'Republic of Trinidad and Tobago',
        common: 'Trinidad and Tobago',
      },
    },
  },
  cities: [
    'Arima',
    'San Fernando',
    'Princes Town',
    'Piarco',
    'Rio Claro',
    'Port of Spain',
    'Victoria',
    'Maraval',
    'Fyzabad',
    'Debe',
    'Couva',
    'Diego Martin',
    'Chaguanas',
    'Penal',
    'Cunupia',
    'Curepe',
    'Roxborough',
    'San Juan',
    'Arouca',
    'Saint Joseph',
    'California',
    'Marabella',
    'Siparia',
    'Gasparillo',
    'Morvant',
    'Barataria',
    'Saint Clair',
    'Laventille',
    'Carenage',
    'Ward of Tacarigua',
    'Caroni',
    'Lopinot',
    'Tunapuna',
    'Santa Cruz',
    'Saint Augustine',
    'Golden Lane',
    'Scarborough',
    'Moriah',
    'Saint James',
    'Carapichaima',
    'Valsayn',
    'Freeport',
    'Claxton Bay',
    'Sangre Grande',
    'Cumuto',
    'Woodbrook',
    'Petit Valley',
    'El Dorado',
    'Phoenix Park',
  ],
  tld: ['.tt'],
  cca2: 'TT',
  ccn3: '780',
  cca3: 'TTO',
  cioc: 'TTO',
  currency: ['TTD'],
  callingCode: ['1868'],
  capital: 'Port of Spain',
  altSpellings: ['TT', 'Republic of Trinidad and Tobago'],
  region: 'Americas',
  subregion: 'Caribbean',
  languages: { eng: 'English' },
  translations: {
    deu: {
      official: 'Republik Trinidad und Tobago',
      common: 'Trinidad und Tobago',
    },
    fra: {
      official: 'République de Trinité-et-Tobago',
      common: 'Trinité-et-Tobago',
    },
    hrv: {
      official: 'Republika Trinidad i Tobago',
      common: 'Trinidad i Tobago',
    },
    ita: {
      official: 'Repubblica di Trinidad e Tobago',
      common: 'Trinidad e Tobago',
    },
    jpn: {
      official: 'トリニダード·トバゴ共和国',
      common: 'トリニダード・トバゴ',
    },
    nld: {
      official: 'Republiek Trinidad en Tobago',
      common: 'Trinidad en Tobago',
    },
    por: {
      official: 'República de Trinidad e Tobago',
      common: 'Trinidade e Tobago',
    },
    rus: {
      official: 'Республика Тринидад и Тобаго',
      common: 'Тринидад и Тобаго',
    },
    spa: {
      official: 'República de Trinidad y Tobago',
      common: 'Trinidad y Tobago',
    },
    fin: {
      official: 'Trinidadin ja Tobagon tasavalta',
      common: 'Trinidad ja Tobago',
    },
  },
  latlng: [11, -61],
  demonym: 'Trinidadian',
  landlocked: false,
  borders: [],
  area: 5130,
  provinces: [
    'Arima',
    'Caroni',
    'Mayaro',
    'Nariva',
    'Port-of-Spain',
    'Saint Andrew',
    'Saint David',
    'Saint George',
    'Saint Patrick',
    'San Fernando',
    'Victoria',
  ],
  nativeName: 'Trinidad and Tobago',
  timezones: ['UTC−04:00'],
} as const;
