export const WSM = {
  name: {
    common: 'Samoa',
    official: 'Independent State of Samoa',
    native: {
      eng: { official: 'Independent State of Samoa', common: 'Samoa' },
      smo: { official: 'Malo Saʻoloto Tutoʻatasi o Sāmoa', common: 'Sāmoa' },
    },
  },
  cities: ['Apia', 'Safotulafai'],
  tld: ['.ws'],
  cca2: 'WS',
  ccn3: '882',
  cca3: 'WSM',
  cioc: 'SAM',
  currency: ['WST'],
  callingCode: ['685'],
  capital: 'Apia',
  altSpellings: [
    'WS',
    'Independent State of Samoa',
    'Malo Saʻoloto Tutoʻatasi o Sāmoa',
  ],
  region: 'Oceania',
  subregion: 'Polynesia',
  languages: { eng: 'English', smo: 'Samoan' },
  translations: {
    deu: { official: 'Unabhängige Staat Samoa', common: 'Samoa' },
    fra: { official: 'Samoa', common: 'Samoa' },
    hrv: { official: 'Nezavisna Država Samoa', common: 'Samoa' },
    ita: { official: 'Stato indipendente di Samoa', common: 'Samoa' },
    jpn: { official: 'サモア独立国', common: 'サモア' },
    nld: { official: 'Onafhankelijke Staat Samoa', common: 'Samoa' },
    por: { official: 'Estado Independente de Samoa', common: 'Samoa' },
    rus: { official: 'Независимое Государство Самоа', common: 'Самоа' },
    spa: { official: 'Estado Independiente de Samoa', common: 'Samoa' },
    fin: { official: 'Samoan itsenäinen valtio', common: 'Samoa' },
  },
  latlng: [-13.58333333, -172.33333333],
  demonym: 'Samoan',
  landlocked: false,
  borders: [],
  area: 2842,
} as const;
