/**
 * ...
 */
export interface CountryInfo {
  name: string;
  code: string;
  dialCode: string;
  flag: string;
  addressInfo: AddressInfo;
}

/**
 * Country address configuration info.
 *
 * Stats from {@link http://statoids.com/}
 */
export interface AddressInfo {
  /**
   * Address thoroughfare (e.g. `Street`, etc.) field label.
   */
  thoroughfare: string;
  /**
   * Address premise (e.g. `Apartment`, `Suite`, `Box number`, etc.) field
   * label.
   */
  premise: string;
  /**
   * Address locality (e.g. `City`, `Town`, etc.) field label.
   */
  localityName?: string;
  /**
   * Address administrative area (e.g. `State`, `Province`, etc.) field info.
   */
  administrativeArea?: AddressInfo.AdministrativeArea;
  /**
   * Address postal code (e.g. `Postal code`, `ZIP code`, etc.) field info.
   */
  postalCode?: AddressInfo.PostalCode;
  /**
   * Address dependent locality (e.g. `Neighborhood`, `District`, etc.) field
   * label.
   */
  dependentLocalityName?: AddressInfo.DependentLocalityName;
  /**
   * Address administrative area override. Forces this value to be used
   * (e.g. Singapore is a city-state, so "Singapore" should be used)
   */
  administrativeAreaOverride?: string;
}

export namespace AddressInfo {
  export interface AdministrativeAreaOption {
    name: string;
    code: string;
  }

  export interface AdministrativeArea {
    /** Locality's administrative area field label. */
    label: string;
    /** Locality's administrative area options. */
    options?: AdministrativeAreaOption[];
  }

  export interface PostalCode {
    label: string;
    format?: RegExp;
    eg?: string;
  }

  export interface DependentLocalityName {
    label: string;
  }
}

/** ... */
/** ... */
export const countryInfo: Record<string, CountryInfo> = {
  US: {
    name: 'United States',
    code: 'US',
    dialCode: '+1',
    flag: '🇺🇸',
    addressInfo: {
      thoroughfare: 'Address',
      // premise: 'Address 2',
      premise: 'Apt, Suite, Building',
      localityName: 'City',
      administrativeArea: {
        label: 'State',
        options: [
          { name: 'Alabama', code: 'AL' },
          { name: 'Alaska', code: 'AK' },
          { name: 'Arizona', code: 'AZ' },
          { name: 'Arkansas', code: 'AR' },
          { name: 'California', code: 'CA' },
          { name: 'Colorado', code: 'CO' },
          { name: 'Connecticut', code: 'CT' },
          { name: 'Delaware', code: 'DE' },
          { name: 'District Of Columbia', code: 'DC' },
          { name: 'Florida', code: 'FL' },
          { name: 'Georgia', code: 'GA' },
          { name: 'Hawaii', code: 'HI' },
          { name: 'Idaho', code: 'ID' },
          { name: 'Illinois', code: 'IL' },
          { name: 'Indiana', code: 'IN' },
          { name: 'Iowa', code: 'IA' },
          { name: 'Kansas', code: 'KS' },
          { name: 'Kentucky', code: 'KY' },
          { name: 'Louisiana', code: 'LA' },
          { name: 'Maine', code: 'ME' },
          { name: 'Maryland', code: 'MD' },
          { name: 'Massachusetts', code: 'MA' },
          { name: 'Michigan', code: 'MI' },
          { name: 'Minnesota', code: 'MN' },
          { name: 'Mississippi', code: 'MS' },
          { name: 'Missouri', code: 'MO' },
          { name: 'Montana', code: 'MT' },
          { name: 'Nebraska', code: 'NE' },
          { name: 'Nevada', code: 'NV' },
          { name: 'New Hampshire', code: 'NH' },
          { name: 'New Jersey', code: 'NJ' },
          { name: 'New Mexico', code: 'NM' },
          { name: 'New York', code: 'NY' },
          { name: 'North Carolina', code: 'NC' },
          { name: 'North Dakota', code: 'ND' },
          { name: 'Ohio', code: 'OH' },
          { name: 'Oklahoma', code: 'OK' },
          { name: 'Oregon', code: 'OR' },
          { name: 'Pennsylvania', code: 'PA' },
          { name: 'Rhode Island', code: 'RI' },
          { name: 'South Carolina', code: 'SC' },
          { name: 'South Dakota', code: 'SD' },
          { name: 'Tennessee', code: 'TN' },
          { name: 'Texas', code: 'TX' },
          { name: 'Utah', code: 'UT' },
          { name: 'Vermont', code: 'VT' },
          { name: 'Virginia', code: 'VA' },
          { name: 'Washington', code: 'WA' },
          { name: 'West Virginia', code: 'WV' },
          { name: 'Wisconsin', code: 'WI' },
          { name: 'Wyoming', code: 'WY' },
          { name: 'Armed Forces (Americas)', code: 'AA' },
          {
            name: 'Armed Forces (Europe, Canada, Middle East, Africa)',
            code: 'AE',
          },
          { name: 'Armed Forces (Pacific)', code: 'AP' },
          { name: 'American Samoa', code: 'AS' },
          { name: 'Federated States of Micronesia', code: 'FM' },
          { name: 'Guam', code: 'GU' },
          { name: 'Marshall Islands', code: 'MH' },
          { name: 'Northern Mariana Islands', code: 'MP' },
          { name: 'Palau', code: 'PW' },
          { name: 'Puerto Rico', code: 'PR' },
          { name: 'Virgin Islands', code: 'VI' },
        ],
      },
      postalCode: {
        label: 'ZIP Code',
        format: /^\d{5}(?:[-\s]\d{4})?$/,
        eg: '98103',
      },
    },
  },
  CA: {
    name: 'Canada',
    code: 'CA',
    dialCode: '+1',
    flag: '🇨🇦',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      administrativeArea: {
        label: 'Province',
        options: [
          { name: 'Alberta', code: 'AB' },
          { name: 'British Columbia', code: 'BC' },
          { name: 'Manitoba', code: 'MB' },
          { name: 'New Brunswick', code: 'NB' },
          { name: 'Newfoundland', code: 'NL' },
          { name: 'Northwest Territories', code: 'NT' },
          { name: 'Nova Scotia', code: 'NS' },
          { name: 'Nunavut', code: 'NU' },
          { name: 'Ontario', code: 'ON' },
          { name: 'Prince Edward Island', code: 'PE' },
          { name: 'Quebec', code: 'QC' },
          { name: 'Saskatchewan', code: 'SK' },
          { name: 'Yukon Territory', code: 'YT' },
        ],
      },
      postalCode: {
        label: 'Postal Code',
        format:
          /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d$/,
        eg: 'K1A 0B1',
      },
    },
  },
  AF: {
    name: 'Afghanistan',
    code: 'AF',
    dialCode: '+93',
    flag: '🇦🇫',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      postalCode: { label: 'Postal Code', format: /^\d{4}$/, eg: '1001' },
    },
  },
  AX: {
    name: 'Åland Islands',
    code: 'AX',
    dialCode: '+358',
    flag: '🇦🇽',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      postalCode: { label: 'Postal Code', format: /^22\d{3}$/, eg: '22150' },
    },
  },
  AL: {
    name: 'Albania',
    code: 'AL',
    dialCode: '+355',
    flag: '🇦🇱',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  DZ: {
    name: 'Algeria',
    code: 'DZ',
    dialCode: '+213',
    flag: '🇩🇿',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  AS: {
    name: 'American Samoa',
    code: 'AS',
    dialCode: '+1684',
    flag: '🇦🇸',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      administrativeArea: { label: 'State' },
      postalCode: { label: 'ZIP Code' },
    },
  },
  AD: {
    name: 'Andorra',
    code: 'AD',
    dialCode: '+376',
    flag: '🇦🇩',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  AO: {
    name: 'Angola',
    code: 'AO',
    dialCode: '+244',
    flag: '🇦🇴',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  AI: {
    name: 'Anguilla',
    code: 'AI',
    dialCode: '+1264',
    flag: '🇦🇮',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  AQ: {
    name: 'Antarctica',
    code: 'AQ',
    dialCode: '+672',
    flag: '🇳🇫',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  AG: {
    name: 'Antigua & Barbuda',
    code: 'AG',
    dialCode: '+1268',
    flag: '🇦🇬',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  AR: {
    name: 'Argentina',
    code: 'AR',
    dialCode: '+54',
    flag: '🇦🇷',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: {
        label: 'Postal Code',
        format: /^((?:[A-HJ-NP-Z])?\d{4})([A-Z]{3})?$/,
        eg: 'B1921',
      },
      localityName: 'City',
      administrativeArea: {
        label: 'State',
        options: [
          { name: 'Buenos Aires', code: 'B' },
          { name: 'Catamarca', code: 'K' },
          { name: 'Chaco', code: 'H' },
          { name: 'Chubut', code: 'U' },
          { name: 'Ciudad de Buenos Aires', code: 'C' },
          { name: 'Córdoba', code: 'X' },
          { name: 'Corrientes', code: 'W' },
          { name: 'Entre Ríos', code: 'E' },
          { name: 'Formosa', code: 'P' },
          { name: 'Jujuy', code: 'Y' },
          { name: 'La Pampa', code: 'L' },
          { name: 'La Rioja', code: 'F' },
          { name: 'Mendoza', code: 'M' },
          { name: 'Misiones', code: 'N' },
          { name: 'Neuquén', code: 'Q' },
          { name: 'Río Negro', code: 'R' },
          { name: 'Salta', code: 'A' },
          { name: 'San Juan', code: 'J' },
          { name: 'San Luis', code: 'D' },
          { name: 'Santa Cruz', code: 'Z' },
          { name: 'Santa Fe', code: 'S' },
          { name: 'Santiago del Estero', code: 'G' },
          { name: 'Tierra del Fuego', code: 'V' },
          { name: 'Tucumán', code: 'T' },
        ],
      },
    },
  },
  AM: {
    name: 'Armenia',
    code: 'AM',
    dialCode: '+374',
    flag: '🇦🇲',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
      administrativeArea: { label: 'Province' },
    },
  },
  AW: {
    name: 'Aruba',
    code: 'AW',
    dialCode: '+297',
    flag: '🇦🇼',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  AU: {
    name: 'Australia',
    code: 'AU',
    dialCode: '+61',
    flag: '🇦🇺',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City/suburb',
      administrativeArea: {
        label: 'State',
        options: [
          { name: 'Australian Capital Territory', code: 'ACT' },
          { name: 'New South Wales', code: 'NSW' },
          { name: 'Northern Territory', code: 'NT' },
          { name: 'Queensland', code: 'QLD' },
          { name: 'South Australia', code: 'SA' },
          { name: 'Tasmania', code: 'TAS' },
          { name: 'Victoria', code: 'VIC' },
          { name: 'Western Australia', code: 'WA' },
        ],
      },
      postalCode: { label: 'Postcode', format: /^\d{4}$/, eg: '2000' },
    },
  },
  AT: {
    name: 'Austria',
    code: 'AT',
    dialCode: '+43',
    flag: '🇦🇹',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code', format: /^\d{4}$/, eg: '3741' },
      localityName: 'City',
      administrativeArea: {
        label: 'State',
        options: [
          { name: 'Burgenland', code: 'Burgenland' },
          { name: 'Carinthia', code: 'Carinthia' },
          { name: 'Lower Austria', code: 'Lower Austria' },
          { name: 'Salzburg', code: 'Salzburg' },
          { name: 'Styria', code: 'Styria' },
          { name: 'Tyrol', code: 'Tyrol' },
          { name: 'Upper Austria', code: 'Upper Austria' },
          { name: 'Vienna', code: 'Vienna' },
          { name: 'Vorarlberg', code: 'Vorarlberg' },
        ],
      },
    },
  },
  AZ: {
    name: 'Azerbaijan',
    code: 'AZ',
    dialCode: '+994',
    flag: '🇦🇿',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  BS: {
    name: 'Bahamas',
    code: 'BS',
    dialCode: '+1242',
    flag: '🇧🇸',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      administrativeArea: { label: 'Island' },
    },
  },
  BH: {
    name: 'Bahrain',
    code: 'BH',
    dialCode: '+973',
    flag: '🇧🇭',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      postalCode: { label: 'Postal Code' },
    },
  },
  BD: {
    name: 'Bangladesh',
    code: 'BD',
    dialCode: '+880',
    flag: '🇧🇩',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      postalCode: { label: 'Postal Code' },
    },
  },
  BB: {
    name: 'Barbados',
    code: 'BB',
    dialCode: '+1246',
    flag: '🇧🇧',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  BY: {
    name: 'Belarus',
    code: 'BY',
    dialCode: '+375',
    flag: '🇧🇾',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
      administrativeArea: { label: 'Province' },
    },
  },
  BE: {
    name: 'Belgium',
    code: 'BE',
    dialCode: '+32',
    flag: '🇧🇪',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code', format: /^\d{4}$/, eg: '4000' },
      localityName: 'City',
      administrativeArea: { label: 'State/Province' },
    },
  },
  BZ: {
    name: 'Belize',
    code: 'BZ',
    dialCode: '+501',
    flag: '🇧🇿',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      administrativeArea: { label: 'State' },
    },
  },
  BJ: {
    name: 'Benin',
    code: 'BJ',
    dialCode: '+229',
    flag: '🇧🇯',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  BM: {
    name: 'Bermuda',
    code: 'BM',
    dialCode: '+1441',
    flag: '🇧🇲',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      postalCode: { label: 'Postal Code' },
    },
  },
  BT: {
    name: 'Bhutan',
    code: 'BT',
    dialCode: '+975',
    flag: '🇧🇹',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      postalCode: { label: 'Postal Code' },
    },
  },
  BO: {
    name: 'Bolivia',
    code: 'BO',
    dialCode: '+591',
    flag: '🇧🇴',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  BA: {
    name: 'Bosnia & Herzegovina',
    code: 'BA',
    dialCode: '+387',
    flag: '🇧🇦',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  BW: {
    name: 'Botswana',
    code: 'BW',
    dialCode: '+267',
    flag: '🇧🇼',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  BR: {
    name: 'Brazil',
    code: 'BR',
    dialCode: '+55',
    flag: '🇧🇷',
    addressInfo: {
      thoroughfare: 'Thoroughfare',
      premise: 'Complement',
      dependentLocalityName: { label: 'Neighborhood' },
      localityName: 'City',
      administrativeArea: {
        label: 'State',
        options: [
          { name: 'Acre', code: 'AC' },
          { name: 'Alagoas', code: 'AL' },
          { name: 'Amazonas', code: 'AM' },
          { name: 'Amapa', code: 'AP' },
          { name: 'Bahia', code: 'BA' },
          { name: 'Ceara', code: 'CE' },
          { name: 'Distrito Federal', code: 'DF' },
          { name: 'Espirito Santo', code: 'ES' },
          { name: 'Goias', code: 'GO' },
          { name: 'Maranhao', code: 'MA' },
          { name: 'Minas Gerais', code: 'MG' },
          { name: 'Mato Grosso do Sul', code: 'MS' },
          { name: 'Mato Grosso', code: 'MT' },
          { name: 'Para', code: 'PA' },
          { name: 'Paraiba', code: 'PB' },
          { name: 'Pernambuco', code: 'PE' },
          { name: 'Piaui', code: 'PI' },
          { name: 'Parana', code: 'PR' },
          { name: 'Rio de Janeiro', code: 'RJ' },
          { name: 'Rio Grande do Norte', code: 'RN' },
          { name: 'Rondonia', code: 'RO' },
          { name: 'Roraima', code: 'RR' },
          { name: 'Rio Grande do Sul', code: 'RS' },
          { name: 'Santa Catarina', code: 'SC' },
          { name: 'Sergipe', code: 'SE' },
          { name: 'Sao Paulo', code: 'SP' },
          { name: 'Tocantins', code: 'TO' },
        ],
      },
      postalCode: {
        label: 'Postal Code',
        format: /^\d{5}[-]?\d{3}$/,
        eg: '10025-345',
      },
    },
  },
  IO: {
    name: 'British Indian Ocean Territory',
    code: 'IO',
    dialCode: '+246',
    flag: '🇮🇳',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  BN: {
    name: 'Brunei',
    code: 'BN',
    dialCode: '+673',
    flag: '🇧🇳',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      postalCode: { label: 'Postal Code' },
    },
  },
  BG: {
    name: 'Bulgaria',
    code: 'BG',
    dialCode: '+359',
    flag: '🇧🇬',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
      administrativeArea: { label: 'State/Region' },
    },
  },
  BF: {
    name: 'Burkina Faso',
    code: 'BF',
    dialCode: '+226',
    flag: '🇧🇫',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      postalCode: { label: 'Postal Code' },
    },
  },
  BI: {
    name: 'Burundi',
    code: 'BI',
    dialCode: '+257',
    flag: '🇧🇮',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  KH: {
    name: 'Cambodia',
    code: 'KH',
    dialCode: '+855',
    flag: '🇰🇭',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      postalCode: { label: 'Postal Code' },
    },
  },
  CM: {
    name: 'Cameroon',
    code: 'CM',
    dialCode: '+237',
    flag: '🇨🇲',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  CV: {
    name: 'Cape Verde',
    code: 'CV',
    dialCode: '+238',
    flag: '🇨🇻',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
      administrativeArea: { label: 'Island' },
    },
  },
  KY: {
    name: 'Cayman Islands',
    code: 'KY',
    dialCode: '+ 345',
    flag: '🇰🇾',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      administrativeArea: { label: 'Island' },
    },
  },
  CF: {
    name: 'Central African Republic',
    code: 'CF',
    dialCode: '+236',
    flag: '🇨🇫',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  TD: {
    name: 'Chad',
    code: 'TD',
    dialCode: '+235',
    flag: '🇹🇩',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  CL: {
    name: 'Chile',
    code: 'CL',
    dialCode: '+56',
    flag: '🇨🇱',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
      administrativeArea: {
        label: 'State',
        options: [
          { name: 'Aysén del General Carlos Ibáñez del Campo', code: 'AI' },
          { name: 'Antofagasta', code: 'AN' },
          { name: 'Araucanía', code: 'AR' },
          { name: 'Arica y Parinacota', code: 'AP' },
          { name: 'Atacama', code: 'AT' },
          { name: 'Biobío', code: 'BI' },
          { name: 'Coquimbo', code: 'CO' },
          {
            code: 'LI',
            name: "Libertador General Bernardo O'Higgins",
          },
          { name: 'Los Lagos', code: 'LL' },
          { name: 'Los Ríos', code: 'LR' },
          { name: 'Magallanes y de la Antártica Chilena', code: 'MA' },
          { name: 'Maule', code: 'ML' },
          { name: 'Metropolitana de Santiago', code: 'RM' },
          { name: 'Tarapacá', code: 'TA' },
          { name: 'Valparaíso', code: 'VS' },
        ],
      },
    },
  },
  CN: {
    name: 'China',
    code: 'CN',
    dialCode: '+86',
    flag: '🇨🇳',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      dependentLocalityName: { label: 'District' },
      localityName: 'City',
      administrativeArea: {
        label: 'Province',
        options: [
          { name: 'Beijing', code: '11' },
          { name: 'Shanghai', code: '31' },
          { name: 'Guangdong', code: '44' },
          { name: 'Tianjin', code: '12' },
          { name: 'Hebei', code: '13' },
          { name: 'Shanxi', code: '14' },
          { name: 'Inner Mongolia', code: '15' },
          { name: 'Liaoning', code: '21' },
          { name: 'Jilin', code: '22' },
          { name: 'Heilongjiang', code: '23' },
          { name: 'Jiangsu', code: '32' },
          { name: 'Zhejiang', code: '33' },
          { name: 'Anhui', code: '34' },
          { name: 'Fujian', code: '35' },
          { name: 'Jiangxi', code: '36' },
          { name: 'Shandong', code: '37' },
          { name: 'Henan', code: '41' },
          { name: 'Hubei', code: '42' },
          { name: 'Hunan', code: '43' },
          { name: 'Guangxi', code: '45' },
          { name: 'Hainan', code: '46' },
          { name: 'Chongqing', code: '50' },
          { name: 'Sichuan', code: '51' },
          { name: 'Guizhou', code: '52' },
          { name: 'Yunnan', code: '53' },
          { name: 'Tibet', code: '54' },
          { name: 'Shaanxi', code: '61' },
          { name: 'Gansu', code: '62' },
          { name: 'Qinghai', code: '63' },
          { name: 'Ningxia Hui', code: '64' },
          { name: 'Xinjiang Uyghur', code: '65' },
        ],
      },
      postalCode: { label: 'Postal Code', format: /^\d{6}$/, eg: '101200' },
    },
  },
  CX: {
    name: 'Christmas Island',
    code: 'CX',
    dialCode: '+61',
    flag: '🇨🇽',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      administrativeArea: { label: 'Province' },
      postalCode: { label: 'Postal Code' },
    },
  },
  CC: {
    name: 'Cocos (Keeling) Islands',
    code: 'CC',
    dialCode: '+61',
    flag: '🇨🇨',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      administrativeArea: { label: 'Province' },
      postalCode: { label: 'Postal Code' },
    },
  },
  CO: {
    name: 'Colombia',
    code: 'CO',
    dialCode: '+57',
    flag: '🇨🇴',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      administrativeArea: { label: 'State/Province' },
    },
  },
  KM: {
    name: 'Comoros',
    code: 'KM',
    dialCode: '+269',
    flag: '🇰🇲',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  CG: {
    name: 'Congo - Brazzaville',
    code: 'CG',
    dialCode: '+242',
    flag: '🇨🇬',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  CD: {
    name: 'Congo - Kinshasa',
    code: 'CD',
    dialCode: '+243',
    flag: '🇨🇩',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  CK: {
    name: 'Cook Islands',
    code: 'CK',
    dialCode: '+682',
    flag: '🇨🇰',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  CR: {
    name: 'Costa Rica',
    code: 'CR',
    dialCode: '+506',
    flag: '🇨🇷',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  CI: {
    name: 'Côte d’Ivoire',
    code: 'CI',
    dialCode: '+225',
    flag: '🇨🇮',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  HR: {
    name: 'Croatia',
    code: 'HR',
    dialCode: '+385',
    flag: '🇭🇷',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
      administrativeArea: { label: 'State/Region' },
    },
  },
  CU: {
    name: 'Cuba',
    code: 'CU',
    dialCode: '+53',
    flag: '🇨🇺',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  CY: {
    name: 'Cyprus',
    code: 'CY',
    dialCode: '+357',
    flag: '🇨🇾',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
      administrativeArea: { label: 'State/Region' },
    },
  },
  CZ: {
    name: 'Czechia',
    code: 'CZ',
    dialCode: '+420',
    flag: '🇨🇿',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
      administrativeArea: { label: 'State/Region' },
    },
  },
  DK: {
    name: 'Denmark',
    code: 'DK',
    dialCode: '+45',
    flag: '🇩🇰',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code', format: /^\d{4}$/, eg: '8660' },
      localityName: 'City',
      administrativeArea: { label: 'State/Region' },
    },
  },
  DJ: {
    name: 'Djibouti',
    code: 'DJ',
    dialCode: '+253',
    flag: '🇩🇯',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  DM: {
    name: 'Dominica',
    code: 'DM',
    dialCode: '+1767',
    flag: '🇩🇲',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  DO: {
    name: 'Dominican Republic',
    code: 'DO',
    dialCode: '+1849',
    flag: '🇩🇴',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
      administrativeArea: { label: 'State' },
    },
  },
  EC: {
    name: 'Ecuador',
    code: 'EC',
    dialCode: '+593',
    flag: '🇪🇨',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  EG: {
    name: 'Egypt',
    code: 'EG',
    dialCode: '+20',
    flag: '🇪🇬',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      administrativeArea: {
        label: 'Governorate',
        options: [
          { name: 'Alexandria', code: 'ALX' },
          { name: 'Aswan', code: 'ASN' },
          { name: 'Asyut', code: 'AST' },
          { name: 'Beheira', code: 'BH' },
          { name: 'Beni Suef', code: 'BNS' },
          { name: 'Cairo', code: 'C' },
          { name: 'Dakahlia', code: 'DK' },
          { name: 'Damietta', code: 'DT' },
          { name: 'Faiyum', code: 'FYM' },
          { name: 'Gharbia', code: 'GH' },
          { name: 'Giza', code: 'GZ' },
          { name: 'Ismailia', code: 'IS' },
          { name: 'Kafr el-Sheikh', code: 'KFS' },
          { name: 'Matruh', code: 'MT' },
          { name: 'Minya', code: 'MN' },
          { name: 'Monufia', code: 'MNF' },
          { name: 'New Valley', code: 'WAD' },
          { name: 'North Sinai', code: 'SIN' },
          { name: 'Port Said', code: 'PTS' },
          { name: 'Qalyubia', code: 'KB' },
          { name: 'Qena', code: 'KN' },
          { name: 'Red Sea', code: 'BA' },
          { name: 'Sharqia', code: 'SHR' },
          { name: 'Sohag', code: 'SHG' },
          { name: 'South Sinai', code: 'JS' },
          { name: 'Suez', code: 'SUZ' },
          { name: 'Luxor', code: 'LX' },
        ],
      },
      postalCode: { label: 'Postal Code' },
    },
  },
  SV: {
    name: 'El Salvador',
    code: 'SV',
    dialCode: '+503',
    flag: '🇸🇻',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
      administrativeArea: { label: 'Province' },
    },
  },
  GQ: {
    name: 'Equatorial Guinea',
    code: 'GQ',
    dialCode: '+240',
    flag: '🇬🇳',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  ER: {
    name: 'Eritrea',
    code: 'ER',
    dialCode: '+291',
    flag: '🇪🇷',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  EE: {
    name: 'Estonia',
    code: 'EE',
    dialCode: '+372',
    flag: '🇪🇪',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
      administrativeArea: { label: 'State/Region' },
    },
  },
  ET: {
    name: 'Ethiopia',
    code: 'ET',
    dialCode: '+251',
    flag: '🇪🇹',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  FK: {
    name: 'Falkland Islands (Islas Malvinas)',
    code: 'FK',
    dialCode: '+500',
    flag: '🇫🇰',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  FO: {
    name: 'Faroe Islands',
    code: 'FO',
    dialCode: '+298',
    flag: '🇫🇴',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  FJ: {
    name: 'Fiji',
    code: 'FJ',
    dialCode: '+679',
    flag: '🇫🇯',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      administrativeArea: { label: 'State' },
    },
  },
  FI: {
    name: 'Finland',
    code: 'FI',
    dialCode: '+358',
    flag: '🇫🇮',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code', format: /^\d{5}$/, eg: '00550' },
      localityName: 'City',
      administrativeArea: { label: 'State/Region' },
    },
  },
  FR: {
    name: 'France',
    code: 'FR',
    dialCode: '+33',
    flag: '🇫🇷',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      administrativeArea: { label: 'Province' },
      postalCode: {
        label: 'Postal Code',
        format: /^\d{2}[ ]?\d{3}$/,
        eg: '75002',
      },
      localityName: 'City',
    },
  },
  GF: {
    name: 'French Guiana',
    code: 'GF',
    dialCode: '+594',
    flag: '🇬🇫',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: {
        label: 'Postal Code',
        format: /^9[78]3\d{2}$/,
        eg: '97300',
      },
      localityName: 'City',
    },
  },
  PF: {
    name: 'French Polynesia',
    code: 'PF',
    dialCode: '+689',
    flag: '🇵🇫',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
      administrativeArea: { label: 'Island' },
    },
  },
  GA: {
    name: 'Gabon',
    code: 'GA',
    dialCode: '+241',
    flag: '🇬🇦',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  GM: {
    name: 'Gambia',
    code: 'GM',
    dialCode: '+220',
    flag: '🇬🇲',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  GE: {
    name: 'Georgia',
    code: 'GE',
    dialCode: '+995',
    flag: '🇬🇪',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  DE: {
    name: 'Germany',
    code: 'DE',
    dialCode: '+49',
    flag: '🇩🇪',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code', format: /^\d{5}$/, eg: '60322' },
      localityName: 'City',
      administrativeArea: { label: 'State/Province' },
    },
  },
  GH: {
    name: 'Ghana',
    code: 'GH',
    dialCode: '+233',
    flag: '🇬🇭',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  GI: {
    name: 'Gibraltar',
    code: 'GI',
    dialCode: '+350',
    flag: '🇬🇮',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
    },
  },
  GR: {
    name: 'Greece',
    code: 'GR',
    dialCode: '+30',
    flag: '🇬🇷',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: {
        label: 'Postal Code',
        format: /^\d{3} ?\d{2}$/,
        eg: '151 24',
      },
      localityName: 'City',
      administrativeArea: { label: 'State/Province' },
    },
  },
  GL: {
    name: 'Greenland',
    code: 'GL',
    dialCode: '+299',
    flag: '🇬🇱',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code', format: /^39\d{2}$/, eg: '3911' },
      localityName: 'City',
    },
  },
  GD: {
    name: 'Grenada',
    code: 'GD',
    dialCode: '+1473',
    flag: '🇬🇩',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  GP: {
    name: 'Guadeloupe',
    code: 'GP',
    dialCode: '+590',
    flag: '🇬🇵',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: {
        label: 'Postal Code',
        format: /^9[78][01]\d{2}$/,
        eg: '97100',
      },
      localityName: 'City',
    },
  },
  GU: {
    name: 'Guam',
    code: 'GU',
    dialCode: '+1671',
    flag: '🇬🇺',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      administrativeArea: { label: 'State' },
      postalCode: { label: 'ZIP Code' },
    },
  },
  GT: {
    name: 'Guatemala',
    code: 'GT',
    dialCode: '+502',
    flag: '🇬🇹',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  GG: {
    name: 'Guernsey',
    code: 'GG',
    dialCode: '+44',
    flag: '🇬🇬',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: {
        label: 'Postal Code',
        format: /^GY\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}$/,
        eg: 'GY1 1AA',
      },
      localityName: 'City',
    },
  },
  GN: {
    name: 'Guinea',
    code: 'GN',
    dialCode: '+224',
    flag: '🇬🇳',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  GW: {
    name: 'Guinea-Bissau',
    code: 'GW',
    dialCode: '+245',
    flag: '🇬🇳',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  GY: {
    name: 'Guyana',
    code: 'GY',
    dialCode: '+592',
    flag: '🇬🇾',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  HT: {
    name: 'Haiti',
    code: 'HT',
    dialCode: '+509',
    flag: '🇭🇹',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  VA: {
    name: 'Vatican City',
    code: 'VA',
    dialCode: '+379',
    flag: '🇻🇦',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  HN: {
    name: 'Honduras',
    code: 'HN',
    dialCode: '+504',
    flag: '🇭🇳',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      administrativeArea: { label: 'Province' },
      postalCode: { label: 'Postal Code' },
    },
  },
  HK: {
    name: 'Hong Kong',
    code: 'HK',
    dialCode: '+852',
    flag: '🇭🇰',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'District',
      administrativeArea: {
        label: 'Area',
        options: [
          { name: 'Kowloon', code: 'Kowloon' },
          { name: 'Hong Kong Island', code: 'Hong Kong Island' },
          { name: 'New Territories', code: 'New Territories' },
        ],
      },
    },
  },
  HU: {
    name: 'Hungary',
    code: 'HU',
    dialCode: '+36',
    flag: '🇭🇺',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
      administrativeArea: { label: 'State/Province' },
    },
  },
  IS: {
    name: 'Iceland',
    code: 'IS',
    dialCode: '+354',
    flag: '🇮🇸',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  IN: {
    name: 'India',
    code: 'IN',
    dialCode: '+91',
    flag: '🇮🇳',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      administrativeArea: {
        label: 'State',
        options: [
          { name: 'Andhra Pradesh', code: 'AP' },
          { name: 'Arunachal Pradesh', code: 'AR' },
          { name: 'Assam', code: 'AS' },
          { name: 'Bihar', code: 'BR' },
          { name: 'Chhattisgarh', code: 'CT' },
          { name: 'Goa', code: 'GA' },
          { name: 'Gujarat', code: 'GJ' },
          { name: 'Haryana', code: 'HR' },
          { name: 'Himachal Pradesh', code: 'HP' },
          { name: 'Jammu and Kashmir', code: 'JK' },
          { name: 'Jharkhand', code: 'JH' },
          { name: 'Karnataka', code: 'KA' },
          { name: 'Kerala', code: 'KL' },
          { name: 'Madhya Pradesh', code: 'MP' },
          { name: 'Maharashtra', code: 'MH' },
          { name: 'Manipur', code: 'MN' },
          { name: 'Meghalaya', code: 'ML' },
          { name: 'Mizoram', code: 'MZ' },
          { name: 'Nagaland', code: 'NL' },
          { name: 'Odisha', code: 'OR' },
          { name: 'Punjab', code: 'PB' },
          { name: 'Rajasthan', code: 'RJ' },
          { name: 'Sikkim', code: 'SK' },
          { name: 'Tamil Nadu', code: 'TN' },
          { name: 'Tripura', code: 'TR' },
          { name: 'Uttar Pradesh', code: 'UP' },
          { name: 'Uttarakhand', code: 'UT' },
          { name: 'West Bengal', code: 'WB' },
          { name: 'Andaman and Nicobar Islands', code: 'AN' },
          { name: 'Chandigarh', code: 'CH' },
          { name: 'Dadra and Nagar Haveli', code: 'DN' },
          { name: 'Daman and Diu', code: 'DD' },
          { name: 'Lakshadweep', code: 'LD' },
          { name: 'National Capital Territory of Delhi', code: 'DL' },
          { name: 'Puducherry', code: 'PY' },
        ],
      },
      postalCode: { label: 'PIN code', format: /^\d{6}$/, eg: '110005' },
    },
  },
  ID: {
    name: 'Indonesia',
    code: 'ID',
    dialCode: '+62',
    flag: '🇮🇩',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City/Regency',
      postalCode: { label: 'Postal Code' },
      administrativeArea: {
        label: 'Province',
        options: [
          { name: 'Aceh', code: 'AC' },
          { name: 'Bali', code: 'BA' },
          { name: 'Bangka Belitung', code: 'BB' },
          { name: 'Banten', code: 'BT' },
          { name: 'Bengkulu', code: 'BE' },
          { name: 'DKI Jakarta', code: 'JK' },
          { name: 'D.I. Yogyakarta', code: 'YO' },
          { name: 'Gorontalo', code: 'GO' },
          { name: 'Jambi', code: 'JA' },
          { name: 'Jawa Barat', code: 'JB' },
          { name: 'Jawa Tengah', code: 'JT' },
          { name: 'Jawa Timur', code: 'JI' },
          { name: 'Kalimantan Barat', code: 'KB' },
          { name: 'Kalimantan Selatan', code: 'KS' },
          { name: 'Kalimantan Tengah', code: 'KT' },
          { name: 'Kalimantan Timur', code: 'KI' },
          { name: 'Kepulauan Riau', code: 'KR' },
          { name: 'Lampung', code: 'LA' },
          { name: 'Maluku', code: 'MA' },
          { name: 'Maluku Utara', code: 'MU' },
          { name: 'Nusa Tenggara Barat', code: 'NB' },
          { name: 'Nusa Tenggara Timur', code: 'NT' },
          { name: 'Papua', code: 'PA' },
          { name: 'Papua Barat', code: 'PB' },
          { name: 'Riau', code: 'RI' },
          { name: 'Sulawesi Barat', code: 'SR' },
          { name: 'Sulawesi Selatan', code: 'SN' },
          { name: 'Sulawesi Tengah', code: 'ST' },
          { name: 'Sulawesi Tenggara', code: 'SG' },
          { name: 'Sulawesi Utara', code: 'SA' },
          { name: 'Sumatera Barat', code: 'SB' },
          { name: 'Sumatera Selatan', code: 'SS' },
          { name: 'Sumatera Utara', code: 'SU' },
        ],
      },
    },
  },
  IR: {
    name: 'Iran',
    code: 'IR',
    dialCode: '+98',
    flag: '🇮🇷',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      dependentLocalityName: { label: 'Neighborhood' },
      localityName: 'City',
      administrativeArea: { label: 'Province' },
      postalCode: { label: 'Postal Code' },
    },
  },
  IQ: {
    name: 'Iraq',
    code: 'IQ',
    dialCode: '+964',
    flag: '🇮🇶',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      administrativeArea: { label: 'Province' },
      postalCode: { label: 'Postal Code' },
    },
  },
  IE: {
    name: 'Ireland',
    code: 'IE',
    dialCode: '+353',
    flag: '🇮🇪',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'Town/City',
      administrativeArea: {
        label: 'County',
        options: [
          { name: 'Co Carlow', code: 'CW' },
          { name: 'Co Cavan', code: 'CN' },
          { name: 'Co Clare', code: 'CE' },
          { name: 'Co Cork', code: 'CO' },
          { name: 'Co Donegal', code: 'DL' },
          { name: 'Co Dublin', code: 'D' },
          { name: 'Dublin 1', code: 'D1' },
          { name: 'Dublin 2', code: 'D2' },
          { name: 'Dublin 3', code: 'D3' },
          { name: 'Dublin 4', code: 'D4' },
          { name: 'Dublin 5', code: 'D5' },
          { name: 'Dublin 6', code: 'D6' },
          { name: 'Dublin 6w', code: 'D6W' },
          { name: 'Dublin 7', code: 'D7' },
          { name: 'Dublin 8', code: 'D8' },
          { name: 'Dublin 9', code: 'D9' },
          { name: 'Dublin 10', code: 'D10' },
          { name: 'Dublin 11', code: 'D11' },
          { name: 'Dublin 12', code: 'D12' },
          { name: 'Dublin 13', code: 'D13' },
          { name: 'Dublin 14', code: 'D14' },
          { name: 'Dublin 15', code: 'D15' },
          { name: 'Dublin 16', code: 'D16' },
          { name: 'Dublin 17', code: 'D17' },
          { name: 'Dublin 18', code: 'D18' },
          { name: 'Dublin 19', code: 'D19' },
          { name: 'Dublin 20', code: 'D20' },
          { name: 'Dublin 21', code: 'D21' },
          { name: 'Dublin 22', code: 'D22' },
          { name: 'Dublin 23', code: 'D23' },
          { name: 'Dublin 24', code: 'D24' },
          { name: 'Co Galway', code: 'G' },
          { name: 'Co Kerry', code: 'KY' },
          { name: 'Co Kildare', code: 'KE' },
          { name: 'Co Kilkenny', code: 'KK' },
          { name: 'Co Laois', code: 'LS' },
          { name: 'Co Leitrim', code: 'LM' },
          { name: 'Co Limerick', code: 'LK' },
          { name: 'Co Longford', code: 'LD' },
          { name: 'Co Louth', code: 'LH' },
          { name: 'Co Mayo', code: 'MO' },
          { name: 'Co Meath', code: 'MH' },
          { name: 'Co Monaghan', code: 'MN' },
          { name: 'Co Offaly', code: 'OY' },
          { name: 'Co Roscommon', code: 'RN' },
          { name: 'Co Sligo', code: 'SO' },
          { name: 'Co Tipperary', code: 'TA' },
          { name: 'Co Waterford', code: 'WD' },
          { name: 'Co Westmeath', code: 'WH' },
          { name: 'Co Wexford', code: 'WX' },
          { name: 'Co Wicklow', code: 'WW' },
        ],
      },
      postalCode: { label: 'Postal Code' },
    },
  },
  IM: {
    name: 'Isle of Man',
    code: 'IM',
    dialCode: '+44',
    flag: '🇮🇲',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: {
        label: 'Postal Code',
        format: /^IM\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}$/,
        eg: 'IM99 1PS',
      },
      localityName: 'City',
    },
  },
  IL: {
    name: 'Israel',
    code: 'IL',
    dialCode: '+972',
    flag: '🇮🇱',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  IT: {
    name: 'Italy',
    code: 'IT',
    dialCode: '+39',
    flag: '🇮🇹',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
      administrativeArea: {
        label: 'Province',
        options: [
          { name: 'Agrigento', code: 'AG' },
          { name: 'Alessandria', code: 'AL' },
          { name: 'Ancona', code: 'AN' },
          {
            code: 'AO',
            name: "Valle d'Aosta/VallÈe d'Aoste",
          },
          { name: 'Ascoli Piceno', code: 'AP' },
          {
            code: 'AQ',
            name: "L'Aquila",
          },
          { name: 'Arezzo', code: 'AR' },
          { name: 'Asti', code: 'AT' },
          { name: 'Avellino', code: 'AV' },
          { name: 'Bari', code: 'BA' },
          { name: 'Bergamo', code: 'BG' },
          { name: 'Biella', code: 'BI' },
          { name: 'Belluno', code: 'BL' },
          { name: 'Benevento', code: 'BN' },
          { name: 'Bologna', code: 'BO' },
          { name: 'Brindisi', code: 'BR' },
          { name: 'Brescia', code: 'BS' },
          { name: 'Barletta-Andria-Trani', code: 'BT' },
          { name: 'Bolzano/Bozen', code: 'BZ' },
          { name: 'Cagliari', code: 'CA' },
          { name: 'Campobasso', code: 'CB' },
          { name: 'Caserta', code: 'CE' },
          { name: 'Chieti', code: 'CH' },
          { name: 'Carbonia-Iglesias', code: 'CI' },
          { name: 'Caltanissetta', code: 'CL' },
          { name: 'Cuneo', code: 'CN' },
          { name: 'Como', code: 'CO' },
          { name: 'Cremona', code: 'CR' },
          { name: 'Cosenza', code: 'CS' },
          { name: 'Catania', code: 'CT' },
          { name: 'Catanzaro', code: 'CZ' },
          { name: 'Enna', code: 'EN' },
          { name: 'ForlÏ-Cesena', code: 'FC' },
          { name: 'Ferrara', code: 'FE' },
          { name: 'Foggia', code: 'FG' },
          { name: 'Firenze', code: 'FI' },
          { name: 'Fermo', code: 'FM' },
          { name: 'Frosinone', code: 'FR' },
          { name: 'Genova', code: 'GE' },
          { name: 'Gorizia', code: 'GO' },
          { name: 'Grosseto', code: 'GR' },
          { name: 'Imperia', code: 'IM' },
          { name: 'Isernia', code: 'IS' },
          { name: 'Crotone', code: 'KR' },
          { name: 'Lecco', code: 'LC' },
          { name: 'Lecce', code: 'LE' },
          { name: 'Livorno', code: 'LI' },
          { name: 'Lodi', code: 'LO' },
          { name: 'Latina', code: 'LT' },
          { name: 'Lucca', code: 'LU' },
          { name: 'Monza e Brianza', code: 'MB' },
          { name: 'Macerata', code: 'MC' },
          { name: 'Messina', code: 'ME' },
          { name: 'Milano', code: 'MI' },
          { name: 'Mantova', code: 'MN' },
          { name: 'Modena', code: 'MO' },
          { name: 'Massa-Carrara', code: 'MS' },
          { name: 'Matera', code: 'MT' },
          { name: 'Napoli', code: 'NA' },
          { name: 'Novara', code: 'NO' },
          { name: 'Nuoro', code: 'NU' },
          { name: 'Ogliastra', code: 'OG' },
          { name: 'Oristano', code: 'OR' },
          { name: 'Olbia-Tempio', code: 'OT' },
          { name: 'Palermo', code: 'PA' },
          { name: 'Piacenza', code: 'PC' },
          { name: 'Padova', code: 'PD' },
          { name: 'Pescara', code: 'PE' },
          { name: 'Perugia', code: 'PG' },
          { name: 'Pisa', code: 'PI' },
          { name: 'Pordenone', code: 'PN' },
          { name: 'Prato', code: 'PO' },
          { name: 'Parma', code: 'PR' },
          { name: 'Pistoia', code: 'PT' },
          { name: 'Pesaro e Urbino', code: 'PU' },
          { name: 'Pavia', code: 'PV' },
          { name: 'Potenza', code: 'PZ' },
          { name: 'Ravenna', code: 'RA' },
          { name: 'Reggio di Calabria', code: 'RC' },
          {
            code: 'RE',
            name: "Reggio nell'Emilia",
          },
          { name: 'Ragusa', code: 'RG' },
          { name: 'Rieti', code: 'RI' },
          { name: 'Roma', code: 'RM' },
          { name: 'Rimini', code: 'RN' },
          { name: 'Rovigo', code: 'RO' },
          { name: 'Salerno', code: 'SA' },
          { name: 'Siena', code: 'SI' },
          { name: 'Sondrio', code: 'SO' },
          { name: 'La Spezia', code: 'SP' },
          { name: 'Siracusa', code: 'SR' },
          { name: 'Sassari', code: 'SS' },
          { name: 'Savona', code: 'SV' },
          { name: 'Taranto', code: 'TA' },
          { name: 'Teramo', code: 'TE' },
          { name: 'Trento', code: 'TN' },
          { name: 'Torino', code: 'TO' },
          { name: 'Trapani', code: 'TP' },
          { name: 'Terni', code: 'TR' },
          { name: 'Trieste', code: 'TS' },
          { name: 'Treviso', code: 'TV' },
          { name: 'Udine', code: 'UD' },
          { name: 'Varese', code: 'VA' },
          { name: 'Verbano-Cusio-Ossola', code: 'VB' },
          { name: 'Vercelli', code: 'VC' },
          { name: 'Venezia', code: 'VE' },
          { name: 'Vicenza', code: 'VI' },
          { name: 'Verona', code: 'VR' },
          { name: 'Medio Campidano', code: 'VS' },
          { name: 'Viterbo', code: 'VT' },
          { name: 'Vibo Valentia', code: 'VV' },
        ],
      },
    },
  },
  JM: {
    name: 'Jamaica',
    code: 'JM',
    dialCode: '+1876',
    flag: '🇯🇲',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      administrativeArea: {
        label: 'Parish',
        options: [
          { name: 'Clarendon', code: 'Clarendon' },
          { name: 'Hanover', code: 'Hanover' },
          { name: 'Kingston', code: 'Kingston' },
          { name: 'Manchester', code: 'Manchester' },
          { name: 'Portland', code: 'Portland' },
          { name: 'St. Andrew', code: 'St. Andrew' },
          { name: 'St. Ann', code: 'St. Ann' },
          { name: 'St. Catherine', code: 'St. Catherine' },
          { name: 'St. Elizabeth', code: 'St. Elizabeth' },
          { name: 'St. James', code: 'St. James' },
          { name: 'St. Mary', code: 'St. Mary' },
          { name: 'St. Thomas', code: 'St. Thomas' },
          { name: 'Trelawny', code: 'Trelawny' },
          { name: 'Westmoreland', code: 'Westmoreland' },
        ],
      },
    },
  },
  JP: {
    name: 'Japan',
    code: 'JP',
    dialCode: '+81',
    flag: '🇯🇵',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: {
        label: 'Postal Code',
        format: /^\d{3}-?\d{4}$/,
        eg: '142-0062',
      },
      localityName: 'City',
      administrativeArea: {
        label: 'Prefecture',
        options: [
          { name: 'Hokkaido', code: '01' },
          { name: 'Aomori', code: '02' },
          { name: 'Iwate', code: '03' },
          { name: 'Miyagi', code: '04' },
          { name: 'Akita', code: '05' },
          { name: 'Yamagata', code: '06' },
          { name: 'Fukushima', code: '07' },
          { name: 'Ibaraki', code: '08' },
          { name: 'Tochigi', code: '09' },
          { name: 'Gunma', code: '10' },
          { name: 'Saitama', code: '11' },
          { name: 'Chiba', code: '12' },
          { name: 'Tokyo', code: '13' },
          { name: 'Kanagawa', code: '14' },
          { name: 'Niigata', code: '15' },
          { name: 'Toyama', code: '16' },
          { name: 'Ishikawa', code: '17' },
          { name: 'Fukui', code: '18' },
          { name: 'Yamanashi', code: '19' },
          { name: 'Nagano', code: '20' },
          { name: 'Gifu', code: '21' },
          { name: 'Shizuoka', code: '22' },
          { name: 'Aichi', code: '23' },
          { name: 'Mie', code: '24' },
          { name: 'Shiga', code: '25' },
          { name: 'Kyoto', code: '26' },
          { name: 'Osaka', code: '27' },
          { name: 'Hyogo', code: '28' },
          { name: 'Nara', code: '29' },
          { name: 'Wakayama', code: '30' },
          { name: 'Tottori', code: '31' },
          { name: 'Shimane', code: '32' },
          { name: 'Okayama', code: '33' },
          { name: 'Hiroshima', code: '34' },
          { name: 'Yamaguchi', code: '35' },
          { name: 'Tokushima', code: '36' },
          { name: 'Kagawa', code: '37' },
          { name: 'Ehime', code: '38' },
          { name: 'Kochi', code: '39' },
          { name: 'Fukuoka', code: '40' },
          { name: 'Saga', code: '41' },
          { name: 'Nagasaki', code: '42' },
          { name: 'Kumamoto', code: '43' },
          { name: 'Oita', code: '44' },
          { name: 'Miyazaki', code: '45' },
          { name: 'Kagoshima', code: '46' },
          { name: 'Okinawa', code: '47' },
        ],
      },
    },
  },
  JE: {
    name: 'Jersey',
    code: 'JE',
    dialCode: '+44',
    flag: '🇯🇪',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: {
        label: 'Postal Code',
        format: /^JE\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}$/,
        eg: 'JE2 2BT',
      },
      localityName: 'City',
    },
  },
  JO: {
    name: 'Jordan',
    code: 'JO',
    dialCode: '+962',
    flag: '🇯🇴',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      administrativeArea: { label: 'State' },
      postalCode: { label: 'Postal Code' },
    },
  },
  KZ: {
    name: 'Kazakhstan',
    code: 'KZ',
    dialCode: '+7',
    flag: '🇰🇿',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
      administrativeArea: { label: 'Province' },
    },
  },
  KE: {
    name: 'Kenya',
    code: 'KE',
    dialCode: '+254',
    flag: '🇰🇪',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  KI: {
    name: 'Kiribati',
    code: 'KI',
    dialCode: '+686',
    flag: '🇰🇮',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      administrativeArea: { label: 'Island' },
    },
  },
  KP: {
    name: 'North Korea',
    code: 'KP',
    dialCode: '+850',
    flag: '🇰🇵',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  KR: {
    name: 'South Korea',
    code: 'KR',
    dialCode: '+82',
    flag: '🇰🇷',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      dependentLocalityName: { label: 'District' },
      localityName: 'City',
      administrativeArea: {
        label: 'Province',
        options: [
          { name: 'Seoul', code: 'Seoul' },
          { name: 'Busan', code: 'Busan' },
          { name: 'Daegu', code: 'Daegu' },
          { name: 'Daejeon', code: 'Daejeon' },
          { name: 'Gwangju', code: 'Gwangju' },
          { name: 'Incheon', code: 'Incheon' },
          { name: 'Ulsan', code: 'Ulsan' },
          { name: 'Chungcheongbuk-do', code: 'Chungcheongbuk-do' },
          { name: 'Chungcheongnam-do', code: 'Chungcheongnam-do' },
          { name: 'Gangwon-do', code: 'Gangwon-do' },
          { name: 'Gyeonggi-do', code: 'Gyeonggi-do' },
          { name: 'Gyeongsangbuk-do', code: 'Gyeongsangbuk-do' },
          { name: 'Gyeongsangnam-do', code: 'Gyeongsangnam-do' },
          { name: 'Jeju-do', code: 'Jeju-do' },
          { name: 'Jeollabuk-do', code: 'Jeollabuk-do' },
          { name: 'Jeollanam-do', code: 'Jeollanam-do' },
          { name: 'Sejong', code: 'Sejong' },
        ],
      },
      postalCode: { label: 'Postal Code' },
    },
  },
  KW: {
    name: 'Kuwait',
    code: 'KW',
    dialCode: '+965',
    flag: '🇰🇼',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
      administrativeArea: { label: 'State' },
    },
  },
  KG: {
    name: 'Kyrgyzstan',
    code: 'KG',
    dialCode: '+996',
    flag: '🇰🇬',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  LA: {
    name: 'Laos',
    code: 'LA',
    dialCode: '+856',
    flag: '🇱🇦',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  LV: {
    name: 'Latvia',
    code: 'LV',
    dialCode: '+371',
    flag: '🇱🇻',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      postalCode: { label: 'Postal Code' },
      administrativeArea: { label: 'State/Region' },
    },
  },
  LB: {
    name: 'Lebanon',
    code: 'LB',
    dialCode: '+961',
    flag: '🇱🇧',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      postalCode: { label: 'Postal Code' },
    },
  },
  LS: {
    name: 'Lesotho',
    code: 'LS',
    dialCode: '+266',
    flag: '🇱🇸',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      postalCode: { label: 'Postal Code' },
    },
  },
  LR: {
    name: 'Liberia',
    code: 'LR',
    dialCode: '+231',
    flag: '🇱🇷',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  LY: {
    name: 'Libya',
    code: 'LY',
    dialCode: '+218',
    flag: '🇱🇾',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  LI: {
    name: 'Liechtenstein',
    code: 'LI',
    dialCode: '+423',
    flag: '🇱🇮',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: {
        label: 'Postal Code',
        format: /^(948[5-9])|(949[0-7])$/,
        eg: '9496',
      },
      localityName: 'City',
    },
  },
  LT: {
    name: 'Lithuania',
    code: 'LT',
    dialCode: '+370',
    flag: '🇱🇹',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
      administrativeArea: { label: 'State/Region' },
    },
  },
  LU: {
    name: 'Luxembourg',
    code: 'LU',
    dialCode: '+352',
    flag: '🇱🇺',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code', format: /^\d{4}$/, eg: '4750' },
      localityName: 'City',
      administrativeArea: { label: 'State/Province' },
    },
  },
  MO: {
    name: 'Macau',
    code: 'MO',
    dialCode: '+853',
    flag: '🇲🇴',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  MK: {
    name: 'North Macedonia',
    code: 'MK',
    dialCode: '+389',
    flag: '🇲🇰',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  MG: {
    name: 'Madagascar',
    code: 'MG',
    dialCode: '+261',
    flag: '🇲🇬',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  MW: {
    name: 'Malawi',
    code: 'MW',
    dialCode: '+265',
    flag: '🇲🇼',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      postalCode: { label: 'Postal Code' },
    },
  },
  MY: {
    name: 'Malaysia',
    code: 'MY',
    dialCode: '+60',
    flag: '🇲🇾',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      dependentLocalityName: { label: 'Village/Township' },
      localityName: 'City',
      administrativeArea: {
        label: 'State',
        options: [
          { name: 'Johor', code: 'Johor' },
          { name: 'Kedah', code: 'Kedah' },
          { name: 'Kelantan', code: 'Kelantan' },
          { name: 'Kuala Lumpur', code: 'Kuala Lumpur' },
          { name: 'Labuan', code: 'Labuan' },
          { name: 'Melaka', code: 'Melaka' },
          { name: 'Negeri Sembilan', code: 'Negeri Sembilan' },
          { name: 'Pahang', code: 'Pahang' },
          { name: 'Perak', code: 'Perak' },
          { name: 'Perlis', code: 'Perlis' },
          { name: 'Pulau Pinang', code: 'Pulau Pinang' },
          { name: 'Putrajaya', code: 'Putrajaya' },
          { name: 'Sabah', code: 'Sabah' },
          { name: 'Sarawak', code: 'Sarawak' },
          { name: 'Selangor', code: 'Selangor' },
          { name: 'Terengganu', code: 'Terengganu' },
        ],
      },
      postalCode: { label: 'Postal Code' },
    },
  },
  MV: {
    name: 'Maldives',
    code: 'MV',
    dialCode: '+960',
    flag: '🇲🇻',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      postalCode: { label: 'Postal Code' },
    },
  },
  ML: {
    name: 'Mali',
    code: 'ML',
    dialCode: '+223',
    flag: '🇲🇱',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  MT: {
    name: 'Malta',
    code: 'MT',
    dialCode: '+356',
    flag: '🇲🇹',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
      administrativeArea: { label: 'State/Region' },
    },
  },
  MH: {
    name: 'Marshall Islands',
    code: 'MH',
    dialCode: '+692',
    flag: '🇲🇭',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      administrativeArea: { label: 'State' },
      postalCode: { label: 'ZIP Code' },
    },
  },
  MQ: {
    name: 'Martinique',
    code: 'MQ',
    dialCode: '+596',
    flag: '🇲🇶',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: {
        label: 'Postal Code',
        format: /^9[78]2\d{2}$/,
        eg: '97220',
      },
      localityName: 'City',
    },
  },
  MR: {
    name: 'Mauritania',
    code: 'MR',
    dialCode: '+222',
    flag: '🇲🇷',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  MU: {
    name: 'Mauritius',
    code: 'MU',
    dialCode: '+230',
    flag: '🇲🇺',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  YT: {
    name: 'Mayotte',
    code: 'YT',
    dialCode: '+262',
    flag: '🇾🇹',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code', format: /^976\d{2}$/, eg: '97600' },
      localityName: 'City',
    },
  },
  MX: {
    name: 'Mexico',
    code: 'MX',
    dialCode: '+52',
    flag: '🇲🇽',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      dependentLocalityName: { label: 'Neighborhood' },
      localityName: 'City',
      administrativeArea: {
        label: 'State',
        options: [
          { name: 'Aguascalientes', code: 'AGU' },
          { name: 'Baja California', code: 'BCN' },
          { name: 'Baja California Sur', code: 'BCS' },
          { name: 'Campeche', code: 'CAM' },
          { name: 'Coahuila', code: 'COA' },
          { name: 'Colima', code: 'COL' },
          { name: 'Chiapas', code: 'CHP' },
          { name: 'Chihuahua', code: 'CHH' },
          { name: 'Distrito Federal', code: 'DIF' },
          { name: 'Durango', code: 'DUG' },
          { name: 'Estado de México', code: 'MEX' },
          { name: 'Guanajuato', code: 'GUA' },
          { name: 'Guerrero', code: 'GRO' },
          { name: 'Hidalgo', code: 'HID' },
          { name: 'Jalisco', code: 'JAL' },
          { name: 'Michoacán', code: 'MIC' },
          { name: 'Morelos', code: 'MOR' },
          { name: 'Nayarit', code: 'NAY' },
          { name: 'Nuevo León', code: 'NLE' },
          { name: 'Oaxaca', code: 'OAX' },
          { name: 'Puebla', code: 'PUE' },
          { name: 'Queretaro', code: 'QUE' },
          { name: 'Quintana Roo', code: 'ROO' },
          { name: 'San Luis Potosí', code: 'SLP' },
          { name: 'Sinaloa', code: 'SIN' },
          { name: 'Sonora', code: 'SON' },
          { name: 'Tabasco', code: 'TAB' },
          { name: 'Tamaulipas', code: 'TAM' },
          { name: 'Tlaxcala', code: 'TLA' },
          { name: 'Veracruz', code: 'VER' },
          { name: 'Yucatán', code: 'YUC' },
          { name: 'Zacatecas', code: 'ZAC' },
        ],
      },
      postalCode: { label: 'Postal Code', format: /^\d{5}$/, eg: '03400' },
    },
  },
  FM: {
    name: 'Micronesia',
    code: 'FM',
    dialCode: '+691',
    flag: '🇫🇲',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      administrativeArea: { label: 'State' },
      postalCode: { label: 'ZIP Code' },
    },
  },
  MD: {
    name: 'Moldova',
    code: 'MD',
    dialCode: '+373',
    flag: '🇲🇩',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      administrativeArea: { label: 'District' },
      localityName: 'City',
    },
  },
  MC: {
    name: 'Monaco',
    code: 'MC',
    dialCode: '+377',
    flag: '🇲🇨',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  MN: {
    name: 'Mongolia',
    code: 'MN',
    dialCode: '+976',
    flag: '🇲🇳',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      administrativeArea: { label: 'Province' },
      postalCode: { label: 'Postal Code' },
    },
  },
  ME: {
    name: 'Montenegro',
    code: 'ME',
    dialCode: '+382',
    flag: '🇲🇪',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  MS: {
    name: 'Montserrat',
    code: 'MS',
    dialCode: '+1664',
    flag: '🇲🇸',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  MA: {
    name: 'Morocco',
    code: 'MA',
    dialCode: '+212',
    flag: '🇲🇦',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  MZ: {
    name: 'Mozambique',
    code: 'MZ',
    dialCode: '+258',
    flag: '🇲🇿',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
      administrativeArea: { label: 'Province' },
    },
  },
  MM: {
    name: 'Myanmar (Burma)',
    code: 'MM',
    dialCode: '+95',
    flag: '🇲🇲',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      postalCode: { label: 'Postal Code' },
    },
  },
  NA: {
    name: 'Namibia',
    code: 'NA',
    dialCode: '+264',
    flag: '🇳🇦',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  NR: {
    name: 'Nauru',
    code: 'NR',
    dialCode: '+674',
    flag: '🇳🇷',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      administrativeArea: { label: 'District' },
    },
  },
  NP: {
    name: 'Nepal',
    code: 'NP',
    dialCode: '+977',
    flag: '🇳🇵',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      postalCode: { label: 'Postal Code' },
    },
  },
  NL: {
    name: 'Netherlands',
    code: 'NL',
    dialCode: '+31',
    flag: '🇳🇱',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: {
        label: 'Postal Code',
        format: /^\d{4}[ ]?[A-Z]{2}$/,
        eg: '2585 GJ',
      },
      localityName: 'City',
      administrativeArea: { label: 'State/Province' },
    },
  },
  AN: {
    name: 'Curaçao',
    code: 'AN',
    dialCode: '+599',
    flag: '🇳🇱',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  NC: {
    name: 'New Caledonia',
    code: 'NC',
    dialCode: '+687',
    flag: '🇳🇨',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code', format: /^988\d{2}$/, eg: '98814' },
      localityName: 'City',
    },
  },
  NZ: {
    name: 'New Zealand',
    code: 'NZ',
    dialCode: '+64',
    flag: '🇳🇿',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'Town/City',
      administrativeArea: {
        label: 'Region',
        options: [
          { name: 'Auckland', code: 'AUK' },
          { name: 'Bay of Plenty', code: 'BOP' },
          { name: 'Canterbury', code: 'CAN' },
          {
            code: 'HKB',
            name: "Hawke's Bay",
          },
          { name: 'Manawatu-Wanganui', code: 'MWT' },
          { name: 'Northland', code: 'NTL' },
          { name: 'Otago', code: 'OTA' },
          { name: 'Southland', code: 'STL' },
          { name: 'Taranaki', code: 'TKI' },
          { name: 'Waikato', code: 'WKO' },
          { name: 'Wellington', code: 'WGN' },
          { name: 'West Coast', code: 'WTC' },
          { name: 'Gisborne District', code: 'GIS' },
          { name: 'Marlborough District', code: 'MBH' },
          { name: 'Nelson', code: 'NSN' },
          { name: 'Tasman District', code: 'TAS' },
          { name: 'Chatham Islands Territory', code: 'CIT' },
        ],
      },
      postalCode: { label: 'Postcode' },
    },
  },
  NI: {
    name: 'Nicaragua',
    code: 'NI',
    dialCode: '+505',
    flag: '🇳🇮',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
      administrativeArea: { label: 'Department' },
    },
  },
  NE: {
    name: 'Niger',
    code: 'NE',
    dialCode: '+227',
    flag: '🇳🇪',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  NG: {
    name: 'Nigeria',
    code: 'NG',
    dialCode: '+234',
    flag: '🇳🇪',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      administrativeArea: { label: 'State' },
      postalCode: { label: 'Postal Code' },
    },
  },
  NU: {
    name: 'Niue',
    code: 'NU',
    dialCode: '+683',
    flag: '🇳🇺',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  NF: {
    name: 'Norfolk Island',
    code: 'NF',
    dialCode: '+672',
    flag: '🇳🇫',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      administrativeArea: { label: 'Province' },
      postalCode: { label: 'Postal Code' },
    },
  },
  MP: {
    name: 'Northern Mariana Islands',
    code: 'MP',
    dialCode: '+1670',
    flag: '🇲🇵',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      administrativeArea: { label: 'State' },
      postalCode: { label: 'ZIP Code' },
    },
  },
  NO: {
    name: 'Norway',
    code: 'NO',
    dialCode: '+47',
    flag: '🇳🇴',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code', format: /^\d{4}$/, eg: '0025' },
      localityName: 'City',
      administrativeArea: { label: 'Province' },
    },
  },
  OM: {
    name: 'Oman',
    code: 'OM',
    dialCode: '+968',
    flag: '🇴🇲',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      postalCode: { label: 'Postal Code' },
      administrativeArea: { label: 'State' },
    },
  },
  PK: {
    name: 'Pakistan',
    code: 'PK',
    dialCode: '+92',
    flag: '🇵🇰',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      postalCode: { label: 'Postal Code' },
    },
  },
  PW: {
    name: 'Palau',
    code: 'PW',
    dialCode: '+680',
    flag: '🇵🇼',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      administrativeArea: { label: 'State' },
      postalCode: { label: 'ZIP Code' },
    },
  },
  PS: {
    name: 'Palestine',
    code: 'PS',
    dialCode: '+970',
    flag: '🇵🇸',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  PA: {
    name: 'Panama',
    code: 'PA',
    dialCode: '+507',
    flag: '🇵🇦',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      administrativeArea: { label: 'Province' },
    },
  },
  PG: {
    name: 'Papua New Guinea',
    code: 'PG',
    dialCode: '+675',
    flag: '🇬🇳',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      administrativeArea: { label: 'Province' },
      postalCode: { label: 'Postal Code' },
    },
  },
  PY: {
    name: 'Paraguay',
    code: 'PY',
    dialCode: '+595',
    flag: '🇵🇾',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  PE: {
    name: 'Peru',
    code: 'PE',
    dialCode: '+51',
    flag: '🇵🇪',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      administrativeArea: {
        label: 'Region',
        options: [
          { name: 'Amazonas', code: 'AMA' },
          { name: 'Ancash', code: 'ANC' },
          { name: 'Apurimac', code: 'APU' },
          { name: 'Arequipa', code: 'ARE' },
          { name: 'Ayacucho', code: 'AYA' },
          { name: 'Cajamarca', code: 'CAJ' },
          { name: 'Callao', code: 'CAL' },
          { name: 'Cusco', code: 'CUS' },
          { name: 'Huancavelica', code: 'HUV' },
          { name: 'Huanuco', code: 'HUC' },
          { name: 'Ica', code: 'ICA' },
          { name: 'Junin', code: 'JUN' },
          { name: 'La Libertad', code: 'LAL' },
          { name: 'Lambayeque', code: 'LAM' },
          { name: 'Lima', code: 'LIM' },
          { name: 'Loreto', code: 'LOR' },
          { name: 'Madre de Dios', code: 'MDD' },
          { name: 'Moquegua', code: 'MOQ' },
          { name: 'Pasco', code: 'PAS' },
          { name: 'Piura', code: 'PIU' },
          { name: 'Puno', code: 'PUN' },
          { name: 'San Martin', code: 'SAM' },
          { name: 'Tacna', code: 'TAC' },
          { name: 'Tumbes', code: 'TUM' },
          { name: 'Ucayali', code: 'UCA' },
        ],
      },
      localityName: 'District',
    },
  },
  PH: {
    name: 'Philippines',
    code: 'PH',
    dialCode: '+63',
    flag: '🇵🇭',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      dependentLocalityName: { label: 'Suburb' },
      localityName: 'City',
      administrativeArea: { label: 'Province' },
      postalCode: { label: 'Postal Code' },
      administrativeAreaOverride: 'Philippines',
    },
  },
  PN: {
    name: 'Pitcairn Islands',
    code: 'PN',
    dialCode: '+64',
    flag: '🇳🇿',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  PL: {
    name: 'Poland',
    code: 'PL',
    dialCode: '+48',
    flag: '🇵🇱',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: {
        label: 'Postal Code',
        format: /^\d{2}-?\d{3}$/,
        eg: '00-950',
      },
      localityName: 'City',
      administrativeArea: { label: 'State/Province' },
    },
  },
  PT: {
    name: 'Portugal',
    code: 'PT',
    dialCode: '+351',
    flag: '🇵🇹',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: {
        label: 'Postal Code',
        format: /^\d{4}-?\d{3}$/,
        eg: '2725-079',
      },
      localityName: 'City',
      administrativeArea: { label: 'State/Province' },
    },
  },
  PR: {
    name: 'Puerto Rico',
    code: 'PR',
    dialCode: '+1939',
    flag: '🇵🇷',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      postalCode: { label: 'ZIP Code' },
      administrativeArea: { label: 'State/Province' },
    },
  },
  QA: {
    name: 'Qatar',
    code: 'QA',
    dialCode: '+974',
    flag: '🇶🇦',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  RO: {
    name: 'Romania',
    code: 'RO',
    dialCode: '+40',
    flag: '🇷🇴',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
      administrativeArea: { label: 'State/Region' },
    },
  },
  RU: {
    name: 'Russia',
    code: 'RU',
    dialCode: '+7',
    flag: '🇷🇺',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      administrativeArea: {
        label: 'Province',
        options: [
          { name: 'Moskva', code: 'MOW' },
          { name: 'Sankt-Peterburg', code: 'SPE' },
          { name: 'Adygeya, Respublika', code: 'AD' },
          { name: 'Altay, Respublika', code: 'AL' },
          { name: 'Bashkortostan, Respublika', code: 'BA' },
          { name: 'Buryatiya, Respublika', code: 'BU' },
          { name: 'Chechenskaya Respublika', code: 'CE' },
          { name: 'Chuvashskaya Respublika', code: 'CU' },
          { name: 'Dagestan, Respublika', code: 'DA' },
          { name: 'Ingushetiya, Respublika', code: 'IN' },
          { name: 'Kabardino-Balkarskaya Respublika', code: 'KB' },
          { name: 'Kalmykiya, Respublika', code: 'KL' },
          { name: 'Karachayevo-Cherkesskaya Respublika', code: 'KC' },
          { name: 'Kareliya, Respublika', code: 'KR' },
          { name: 'Khakasiya, Respublika', code: 'KK' },
          { name: 'Komi, Respublika', code: 'KO' },
          { name: 'Mariy El, Respublika', code: 'ME' },
          { name: 'Mordoviya, Respublika', code: 'MO' },
          { name: 'Sakha, Respublika [Yakutiya]', code: 'SA' },
          { name: 'Severnaya Osetiya-Alaniya, Respublika', code: 'SE' },
          { name: 'Tatarstan, Respublika', code: 'TA' },
          { name: 'Tyva, Respublika [Tuva]', code: 'TY' },
          { name: 'Udmurtskaya Respublika', code: 'UD' },
          { name: 'Altayskiy kray', code: 'ALT' },
          { name: 'Kamchatskiy kray', code: 'KAM' },
          { name: 'Khabarovskiy kray', code: 'KHA' },
          { name: 'Krasnodarskiy kray', code: 'KDA' },
          { name: 'Krasnoyarskiy kray', code: 'KYA' },
          { name: 'Permskiy kray', code: 'PER' },
          { name: 'Primorskiy kray', code: 'PRI' },
          { name: 'Stavropolskiy kray', code: 'STA' },
          { name: 'Zabaykalskiy kray', code: 'ZAB' },
          { name: 'Amurskaya oblast', code: 'AMU' },
          { name: 'Arkhangelskaya oblast', code: 'ARK' },
          { name: 'Astrakhanskaya oblast', code: 'AST' },
          { name: 'Belgorodskaya oblast', code: 'BEL' },
          { name: 'Bryanskaya oblast', code: 'BRY' },
          { name: 'Chelyabinskaya oblast', code: 'CHE' },
          { name: 'Irkutskaya oblast', code: 'IRK' },
          { name: 'Ivanovskaya oblast', code: 'IVA' },
          { name: 'Kaliningradskaya oblast', code: 'KGD' },
          { name: 'Kaluzhskaya oblast', code: 'KLU' },
          { name: 'Kemerovskaya oblast', code: 'KEM' },
          { name: 'Kirovskaya oblast', code: 'KIR' },
          { name: 'Kostromskaya oblast', code: 'KOS' },
          { name: 'Kurganskaya oblast', code: 'KGN' },
          { name: 'Kurskaya oblast', code: 'KRS' },
          { name: 'Leningradskaya oblast', code: 'LEN' },
          { name: 'Lipetskaya oblast', code: 'LIP' },
          { name: 'Magadanskaya oblast', code: 'MAG' },
          { name: 'Moskovskaya oblast', code: 'MOS' },
          { name: 'Murmanskaya oblast', code: 'MUR' },
          { name: 'Nizhegorodskaya oblast', code: 'NIZ' },
          { name: 'Novgorodskaya oblast', code: 'NGR' },
          { name: 'Novosibirskaya oblast', code: 'NVS' },
          { name: 'Omskaya oblast', code: 'OMS' },
          { name: 'Orenburgskaya oblast', code: 'ORE' },
          { name: 'Orlovskaya oblast', code: 'ORL' },
          { name: 'Penzenskaya oblast', code: 'PNZ' },
          { name: 'Pskovskaya oblast', code: 'PSK' },
          { name: 'Rostovskaya oblast', code: 'ROS' },
          { name: 'Ryazanskaya oblast', code: 'RYA' },
          { name: 'Sakhalinskaya oblast', code: 'SAK' },
          { name: 'Samarskaya oblast', code: 'SAM' },
          { name: 'Saratovskaya oblast', code: 'SAR' },
          { name: 'Smolenskaya oblast', code: 'SMO' },
          { name: 'Sverdlovskaya oblast', code: 'SVE' },
          { name: 'Tambovskaya oblast', code: 'TAM' },
          { name: 'Tomskaya oblast', code: 'TOM' },
          { name: 'Tulskaya oblast', code: 'TUL' },
          { name: 'Tverskaya oblast', code: 'TVE' },
          { name: 'Tyumenskaya oblast', code: 'TYU' },
          { name: 'Ulyanovskaya oblast', code: 'ULY' },
          { name: 'Vladimirskaya oblast', code: 'VLA' },
          { name: 'Volgogradskaya oblast', code: 'VGG' },
          { name: 'Vologodskaya oblast', code: 'VLG' },
          { name: 'Voronezhskaya oblast', code: 'VOR' },
          { name: 'Yaroslavskaya oblast', code: 'YAR' },
          { name: 'Yevreyskaya avtonomnaya oblast', code: 'YEV' },
          { name: 'Chukotskiy avtonomnyy okrug', code: 'CHU' },
          { name: 'Khanty-Mansiyskiy avtonomnyy okrug-Yugra', code: 'KHM' },
          { name: 'Nenetskiy avtonomnyy okrug', code: 'NEN' },
          { name: 'Yamalo-Nenetskiy avtonomnyy okrug', code: 'YAN' },
        ],
      },
      postalCode: { label: 'Postal Code' },
    },
  },
  RW: {
    name: 'Rwanda',
    code: 'RW',
    dialCode: '+250',
    flag: '🇷🇼',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  RE: {
    name: 'Réunion',
    code: 'RE',
    dialCode: '+262',
    flag: '🇷🇪',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: {
        label: 'Postal Code',
        format: /^9[78]4\d{2}$/,
        eg: '97400',
      },
      localityName: 'City',
    },
  },
  BL: {
    name: 'St. Barthélemy',
    code: 'BL',
    dialCode: '+590',
    flag: '🇧🇱',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: {
        label: 'Postal Code',
        format: /^9[78][01]\d{2}$/,
        eg: '97100',
      },
      localityName: 'City',
    },
  },
  SH: {
    name: 'St. Helena',
    code: 'SH',
    dialCode: '+290',
    flag: '🇸🇭',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: {
        label: 'Postal Code',
        format: /^(ASCN|STHL)[ ]?1ZZ$/,
        eg: 'STHL 1ZZ',
      },
      localityName: 'City',
    },
  },
  KN: {
    name: 'St. Kitts & Nevis',
    code: 'KN',
    dialCode: '+1869',
    flag: '🇰🇳',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      administrativeArea: { label: 'Island' },
    },
  },
  LC: {
    name: 'St. Lucia',
    code: 'LC',
    dialCode: '+1758',
    flag: '🇱🇨',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  MF: {
    name: 'St. Martin',
    code: 'MF',
    dialCode: '+590',
    flag: '🇲🇫',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: {
        label: 'Postal Code',
        format: /^9[78][01]\d{2}$/,
        eg: '97100',
      },
      localityName: 'City',
    },
  },
  PM: {
    name: 'St. Pierre & Miquelon',
    code: 'PM',
    dialCode: '+508',
    flag: '🇵🇲',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: {
        label: 'Postal Code',
        format: /^9[78]5\d{2}$/,
        eg: '97500',
      },
      localityName: 'City',
    },
  },
  VC: {
    name: 'St. Vincent & Grenadines',
    code: 'VC',
    dialCode: '+1784',
    flag: '🇻🇨',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  WS: {
    name: 'Samoa',
    code: 'WS',
    dialCode: '+685',
    flag: '🇦🇸',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  SM: {
    name: 'San Marino',
    code: 'SM',
    dialCode: '+378',
    flag: '🇸🇲',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  ST: {
    name: 'São Tomé & Príncipe',
    code: 'ST',
    dialCode: '+239',
    flag: '🇸🇹',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  SA: {
    name: 'Saudi Arabia',
    code: 'SA',
    dialCode: '+966',
    flag: '🇸🇦',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      postalCode: { label: 'Postal Code' },
    },
  },
  SN: {
    name: 'Senegal',
    code: 'SN',
    dialCode: '+221',
    flag: '🇸🇳',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  RS: {
    name: 'Serbia',
    code: 'RS',
    dialCode: '+381',
    flag: '🇷🇸',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
      administrativeArea: { label: 'City' },
    },
  },
  SC: {
    name: 'Seychelles',
    code: 'SC',
    dialCode: '+248',
    flag: '🇸🇨',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      administrativeArea: { label: 'Island' },
    },
  },
  SL: {
    name: 'Sierra Leone',
    code: 'SL',
    dialCode: '+232',
    flag: '🇸🇱',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  SG: {
    name: 'Singapore',
    code: 'SG',
    dialCode: '+65',
    flag: '🇸🇬',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code', format: /^\d{6}$/, eg: '238880' },
      administrativeAreaOverride: 'Singapore',
    },
  },
  SK: {
    name: 'Slovakia',
    code: 'SK',
    dialCode: '+421',
    flag: '🇸🇰',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
      administrativeArea: { label: 'State/Region' },
    },
  },
  SI: {
    name: 'Slovenia',
    code: 'SI',
    dialCode: '+386',
    flag: '🇸🇮',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
      administrativeArea: { label: 'State/Region' },
    },
  },
  SB: {
    name: 'Solomon Islands',
    code: 'SB',
    dialCode: '+677',
    flag: '🇸🇧',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  SO: {
    name: 'Somalia',
    code: 'SO',
    dialCode: '+252',
    flag: '🇸🇴',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      administrativeArea: { label: 'Province' },
      postalCode: { label: 'Postal Code' },
    },
  },
  ZA: {
    name: 'South Africa',
    code: 'ZA',
    dialCode: '+27',
    flag: '🇿🇦',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
      administrativeArea: {
        label: 'Province',
        options: [
          { name: 'Eastern Cape', code: 'Eastern Cape' },
          { name: 'Free State', code: 'Free State' },
          { name: 'Gauteng', code: 'Gauteng' },
          { name: 'KwaZulu-Natal', code: 'KwaZulu-Natal' },
          { name: 'Limpopo', code: 'Limpopo' },
          { name: 'Mpumalanga', code: 'Mpumalanga' },
          { name: 'North West', code: 'North West' },
          { name: 'Northern Cape', code: 'Northern Cape' },
          { name: 'Western Cape', code: 'Western Cape' },
        ],
      },
    },
  },
  SS: {
    name: 'South Sudan',
    code: 'SS',
    dialCode: '+211',
    flag: '🇸🇩',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  GS: {
    name: 'South Georgia & South Sandwich Islands',
    code: 'GS',
    dialCode: '+500',
    flag: '🇫🇰',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  ES: {
    name: 'Spain',
    code: 'ES',
    dialCode: '+34',
    flag: '🇪🇸',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
      administrativeArea: {
        label: 'Province',
        options: [
          { name: 'A Coruña', code: 'C' },
          { name: 'Alava', code: 'VI' },
          { name: 'Albacete', code: 'AB' },
          { name: 'Alicante', code: 'A' },
          { name: 'Almería', code: 'AL' },
          { name: 'Asturias', code: 'O' },
          { name: 'Ávila', code: 'AV' },
          { name: 'Badajoz', code: 'BA' },
          { name: 'Baleares', code: 'PM' },
          { name: 'Barcelona', code: 'B' },
          { name: 'Burgos', code: 'BU' },
          { name: 'Cáceres', code: 'CC' },
          { name: 'Cádiz', code: 'CA' },
          { name: 'Cantabria', code: 'S' },
          { name: 'Castellón', code: 'CS' },
          { name: 'Ceuta', code: 'CE' },
          { name: 'Ciudad Real', code: 'CR' },
          { name: 'Córdoba', code: 'CO' },
          { name: 'Cuenca', code: 'CU' },
          { name: 'Gerona', code: 'GI' },
          { name: 'Granada', code: 'GR' },
          { name: 'Guadalajara', code: 'GU' },
          { name: 'Guipúzcoa', code: 'SS' },
          { name: 'Huelva', code: 'H' },
          { name: 'Huesca', code: 'HU' },
          { name: 'Jaén', code: 'J' },
          { name: 'La Rioja', code: 'LO' },
          { name: 'Las Palmas', code: 'GC' },
          { name: 'León', code: 'LE' },
          { name: 'Lérida', code: 'L' },
          { name: 'Lugo', code: 'LU' },
          { name: 'Madrid', code: 'M' },
          { name: 'Málaga', code: 'MA' },
          { name: 'Melilla', code: 'ML' },
          { name: 'Murcia', code: 'MU' },
          { name: 'Navarra', code: 'NA' },
          { name: 'Ourense', code: 'OR' },
          { name: 'Palencia', code: 'P' },
          { name: 'Pontevedra', code: 'PO' },
          { name: 'Salamanca', code: 'SA' },
          { name: 'Santa Cruz de Tenerife', code: 'TF' },
          { name: 'Segovia', code: 'SG' },
          { name: 'Sevilla', code: 'SE' },
          { name: 'Soria', code: 'SO' },
          { name: 'Tarragona', code: 'T' },
          { name: 'Teruel', code: 'TE' },
          { name: 'Toledo', code: 'TO' },
          { name: 'Valencia', code: 'V' },
          { name: 'Valladolid', code: 'VA' },
          { name: 'Vizcaya', code: 'BI' },
          { name: 'Zamora', code: 'ZA' },
          { name: 'Zaragoza', code: 'Z' },
        ],
      },
    },
  },
  LK: {
    name: 'Sri Lanka',
    code: 'LK',
    dialCode: '+94',
    flag: '🇱🇰',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  SD: {
    name: 'Sudan',
    code: 'SD',
    dialCode: '+249',
    flag: '🇸🇩',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  SR: {
    name: 'Suriname',
    code: 'SR',
    dialCode: '+597',
    flag: '🇸🇷',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      administrativeArea: { label: 'Province' },
    },
  },
  SJ: {
    name: 'Svalbard & Jan Mayen',
    code: 'SJ',
    dialCode: '+47',
    flag: '🇸🇯',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code', format: /^\d{4}$/, eg: '9170' },
      localityName: 'City',
    },
  },
  SZ: {
    name: 'Eswatini',
    code: 'SZ',
    dialCode: '+268',
    flag: '🇸🇿',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  SE: {
    name: 'Sweden',
    code: 'SE',
    dialCode: '+46',
    flag: '🇸🇪',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: {
        label: 'Postal Code',
        format: /^\d{3}[ ]?\d{2}$/,
        eg: '11455',
      },
      localityName: 'City',
      administrativeArea: { label: 'State/Province' },
    },
  },
  CH: {
    name: 'Switzerland',
    code: 'CH',
    dialCode: '+41',
    flag: '🇨🇭',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code', format: /^\d{4}$/, eg: '2544' },
      localityName: 'City',
      administrativeArea: { label: 'State/Province' },
    },
  },
  SY: {
    name: 'Syria',
    code: 'SY',
    dialCode: '+963',
    flag: '🇸🇾',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  TW: {
    name: 'Taiwan',
    code: 'TW',
    dialCode: '+886',
    flag: '🇹🇼',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: {
        label: 'Postal Code',
        format: /^\d{3}(\d{2})?$/,
        eg: '100',
      },
      localityName: 'City',
      administrativeArea: {
        label: 'State',
        options: [
          { name: 'Taichung City', code: 'TXG' },
          { name: 'Taipei City', code: 'TPE' },
          { name: 'Taitung County', code: 'TTT' },
          { name: 'Tainan City', code: 'TNN' },
          { name: 'Yilan County', code: 'ILA' },
          { name: 'Hualien County', code: 'HUA' },
          { name: 'Kinmen County', code: 'Kinmen County' },
          { name: 'Nantou County', code: 'NAN' },
          { name: 'Pingtung County', code: 'PIF' },
          { name: 'Miaoli County', code: 'MIA' },
          { name: 'Taoyuan County', code: 'TAO' },
          { name: 'Kaohsiung City', code: 'KHH' },
          { name: 'Keelung City', code: 'KEE' },
          { name: 'Lienchiang County', code: 'Lienchiang County' },
          { name: 'Yunlin County', code: 'YUN' },
          { name: 'New Taipei City', code: 'TPQ' },
          { name: 'Hsinchu City', code: 'HSZ' },
          { name: 'Hsinchu County', code: 'HSQ' },
          { name: 'Chiayi City', code: 'CYI' },
          { name: 'Chiayi County', code: 'CYQ' },
          { name: 'Changhua County', code: 'CHA' },
          { name: 'Penghu County', code: 'PEN' },
        ],
      },
    },
  },
  TJ: {
    name: 'Tajikistan',
    code: 'TJ',
    dialCode: '+992',
    flag: '🇹🇯',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  TZ: {
    name: 'Tanzania',
    code: 'TZ',
    dialCode: '+255',
    flag: '🇹🇿',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  TH: {
    name: 'Thailand',
    code: 'TH',
    dialCode: '+66',
    flag: '🇹🇭',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      dependentLocalityName: { label: 'Suburb' },
      localityName: 'City',
      administrativeArea: { label: 'Province' },
      postalCode: { label: 'Postal Code' },
    },
  },
  TL: {
    name: 'Timor-Leste',
    code: 'TL',
    dialCode: '+670',
    flag: '🇹🇱',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  TG: {
    name: 'Togo',
    code: 'TG',
    dialCode: '+228',
    flag: '🇹🇬',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  TK: {
    name: 'Tokelau',
    code: 'TK',
    dialCode: '+690',
    flag: '🇹🇰',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  TO: {
    name: 'Tonga',
    code: 'TO',
    dialCode: '+676',
    flag: '🇹🇴',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  TT: {
    name: 'Trinidad & Tobago',
    code: 'TT',
    dialCode: '+1868',
    flag: '🇹🇹',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  TN: {
    name: 'Tunisia',
    code: 'TN',
    dialCode: '+216',
    flag: '🇹🇳',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  TR: {
    name: 'Turkey',
    code: 'TR',
    dialCode: '+90',
    flag: '🇹🇷',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      administrativeArea: {
        label: 'Province',
        options: [
          { name: 'Adana', code: 'Adana' },
          { name: 'Adıyaman', code: 'Adıyaman' },
          { name: 'Afyon', code: 'Afyon' },
          { name: 'Ağrı', code: 'Ağrı' },
          { name: 'Aksaray', code: 'Aksaray' },
          { name: 'Amasya', code: 'Amasya' },
          { name: 'Ankara', code: 'Ankara' },
          { name: 'Antalya', code: 'Antalya' },
          { name: 'Ardahan', code: 'Ardahan' },
          { name: 'Artvin', code: 'Artvin' },
          { name: 'Aydın', code: 'Aydın' },
          { name: 'Balıkesir', code: 'Balıkesir' },
          { name: 'Bartın', code: 'Bartın' },
          { name: 'Batman', code: 'Batman' },
          { name: 'Bayburt', code: 'Bayburt' },
          { name: 'Bilecik', code: 'Bilecik' },
          { name: 'Bingöl', code: 'Bingöl' },
          { name: 'Bitlis', code: 'Bitlis' },
          { name: 'Bolu', code: 'Bolu' },
          { name: 'Burdur', code: 'Burdur' },
          { name: 'Bursa', code: 'Bursa' },
          { name: 'Çanakkale', code: 'Çanakkale' },
          { name: 'Çankırı', code: 'Çankırı' },
          { name: 'Çorum', code: 'Çorum' },
          { name: 'Denizli', code: 'Denizli' },
          { name: 'Diyarbakır', code: 'Diyarbakır' },
          { name: 'Düzce', code: 'Düzce' },
          { name: 'Edirne', code: 'Edirne' },
          { name: 'Elazığ', code: 'Elazığ' },
          { name: 'Erzincan', code: 'Erzincan' },
          { name: 'Erzurum', code: 'Erzurum' },
          { name: 'Eskişehir', code: 'Eskişehir' },
          { name: 'Gaziantep', code: 'Gaziantep' },
          { name: 'Giresun', code: 'Giresun' },
          { name: 'Gümüşhane', code: 'Gümüşhane' },
          { name: 'Hakkari', code: 'Hakkari' },
          { name: 'Hatay', code: 'Hatay' },
          { name: 'Iğdır', code: 'Iğdır' },
          { name: 'Isparta', code: 'Isparta' },
          { name: 'İstanbul', code: 'İstanbul' },
          { name: 'İzmir', code: 'İzmir' },
          { name: 'Kahramanmaraş', code: 'Kahramanmaraş' },
          { name: 'Karabük', code: 'Karabük' },
          { name: 'Karaman', code: 'Karaman' },
          { name: 'Kars', code: 'Kars' },
          { name: 'Kastamonu', code: 'Kastamonu' },
          { name: 'Kayseri', code: 'Kayseri' },
          { name: 'Kırıkkale', code: 'Kırıkkale' },
          { name: 'Kırklareli', code: 'Kırklareli' },
          { name: 'Kırşehir', code: 'Kırşehir' },
          { name: 'Kilis', code: 'Kilis' },
          { name: 'Kocaeli', code: 'Kocaeli' },
          { name: 'Konya', code: 'Konya' },
          { name: 'Kütahya', code: 'Kütahya' },
          { name: 'Malatya', code: 'Malatya' },
          { name: 'Manisa', code: 'Manisa' },
          { name: 'Mardin', code: 'Mardin' },
          { name: 'Mersin', code: 'Mersin' },
          { name: 'Muğla', code: 'Muğla' },
          { name: 'Muş', code: 'Muş' },
          { name: 'Nevşehir', code: 'Nevşehir' },
          { name: 'Niğde', code: 'Niğde' },
          { name: 'Ordu', code: 'Ordu' },
          { name: 'Osmaniye', code: 'Osmaniye' },
          { name: 'Rize', code: 'Rize' },
          { name: 'Sakarya', code: 'Sakarya' },
          { name: 'Samsun', code: 'Samsun' },
          { name: 'Siirt', code: 'Siirt' },
          { name: 'Sinop', code: 'Sinop' },
          { name: 'Sivas', code: 'Sivas' },
          { name: 'Şanlıurfa', code: 'Şanlıurfa' },
          { name: 'Şırnak', code: 'Şırnak' },
          { name: 'Tekirdağ', code: 'Tekirdağ' },
          { name: 'Tokat', code: 'Tokat' },
          { name: 'Trabzon', code: 'Trabzon' },
          { name: 'Tunceli', code: 'Tunceli' },
          { name: 'Uşak', code: 'Uşak' },
          { name: 'Van', code: 'Van' },
          { name: 'Yalova', code: 'Yalova' },
          { name: 'Yozgat', code: 'Yozgat' },
          { name: 'Zonguldak', code: 'Zonguldak' },
        ],
      },
      localityName: 'City',
    },
  },
  TM: {
    name: 'Turkmenistan',
    code: 'TM',
    dialCode: '+993',
    flag: '🇹🇲',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  TC: {
    name: 'Turks & Caicos Islands',
    code: 'TC',
    dialCode: '+1649',
    flag: '🇹🇨',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  TV: {
    name: 'Tuvalu',
    code: 'TV',
    dialCode: '+688',
    flag: '🇹🇻',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      administrativeArea: {
        label: 'Island',
        options: [
          { name: 'Funafuti', code: 'Funafuti' },
          { name: 'Funafuti Centre', code: 'Funafuti Centre' },
          { name: 'Nanumaga', code: 'Nanumaga' },
          { name: 'Nanumea', code: 'Nanumea' },
          { name: 'Niulakita', code: 'Niulakita' },
          { name: 'Niutao', code: 'Niutao' },
          { name: 'Nui', code: 'Nui' },
          { name: 'Nukulaelae', code: 'Nukulaelae' },
          { name: 'Vaitupu', code: 'Vaitupu' },
        ],
      },
    },
  },
  UG: {
    name: 'Uganda',
    code: 'UG',
    dialCode: '+256',
    flag: '🇺🇬',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  UA: {
    name: 'Ukraine',
    code: 'UA',
    dialCode: '+380',
    flag: '🇺🇦',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      administrativeArea: { label: 'Region' },
      postalCode: { label: 'Postal Code' },
    },
  },
  AE: {
    name: 'United Arab Emirates',
    code: 'AE',
    dialCode: '+971',
    flag: '🇦🇪',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      administrativeArea: {
        label: 'Emirate',
        options: [
          { name: 'Abu Dhabi', code: 'AZ' },
          { name: 'Fujairah', code: 'FU' },
          { name: 'Umm al-Quwain', code: 'UQ' },
          { name: 'Sharjah', code: 'SH' },
          { name: 'Dubai', code: 'DU' },
          { name: 'Ras al-Khaimah', code: 'RK' },
          { name: 'Ajmān', code: 'AJ' },
        ],
      },
    },
  },
  GB: {
    name: 'United Kingdom',
    code: 'GB',
    dialCode: '+44',
    flag: '🇬🇧',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'Town/City',
      administrativeArea: { label: 'County' },
      postalCode: {
        label: 'Postcode',
        format:
          /^GIR[ ]?0AA|((AB|AL|B|BA|BB|BD|BH|BL|BN|BR|BS|BT|BX|CA|CB|CF|CH|CM|CO|CR|CT|CV|CW|DA|DD|DE|DG|DH|DL|DN|DT|DY|E|EC|EH|EN|EX|FK|FY|G|GL|GY|GU|HA|HD|HG|HP|HR|HS|HU|HX|IG|IM|IP|IV|JE|KA|KT|KW|KY|L|LA|LD|LE|LL|LN|LS|LU|M|ME|MK|ML|N|NE|NG|NN|NP|NR|NW|OL|OX|PA|PE|PH|PL|PO|PR|RG|RH|RM|S|SA|SE|SG|SK|SL|SM|SN|SO|SP|SR|SS|ST|SW|SY|TA|TD|TF|TN|TQ|TR|TS|TW|UB|W|WA|WC|WD|WF|WN|WR|WS|WV|YO|ZE)(\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}))|BFPO[ ]?\d{1,4}$/,
        eg: 'SE1 0SU',
      },
    },
  },
  UY: {
    name: 'Uruguay',
    code: 'UY',
    dialCode: '+598',
    flag: '🇺🇾',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
      administrativeArea: { label: 'State' },
    },
  },
  UZ: {
    name: 'Uzbekistan',
    code: 'UZ',
    dialCode: '+998',
    flag: '🇺🇿',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      postalCode: { label: 'Postal Code' },
    },
  },
  VU: {
    name: 'Vanuatu',
    code: 'VU',
    dialCode: '+678',
    flag: '🇻🇺',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
  VE: {
    name: 'Venezuela',
    code: 'VE',
    dialCode: '+58',
    flag: '🇻🇪',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
      administrativeArea: {
        label: 'State',
        options: [
          { name: 'Amazonas', code: 'Z' },
          { name: 'Anzoátegui', code: 'B' },
          { name: 'Apure', code: 'C' },
          { name: 'Aragua', code: 'D' },
          { name: 'Barinas', code: 'E' },
          { name: 'Bolívar', code: 'F' },
          { name: 'Carabobo', code: 'G' },
          { name: 'Cojedes', code: 'H' },
          { name: 'Delta Amacuro', code: 'Y' },
          { name: 'Dependencias Federales', code: 'W' },
          { name: 'Distrito Federal', code: 'A' },
          { name: 'Falcón', code: 'I' },
          { name: 'Guárico', code: 'J' },
          { name: 'Lara', code: 'K' },
          { name: 'Mérida', code: 'L' },
          { name: 'Miranda', code: 'M' },
          { name: 'Monagas', code: 'N' },
          { name: 'Nueva Esparta', code: 'O' },
          { name: 'Portuguesa', code: 'P' },
          { name: 'Sucre', code: 'R' },
          { name: 'Táchira', code: 'S' },
          { name: 'Trujillo', code: 'T' },
          { name: 'Vargas', code: 'X' },
          { name: 'Yaracuy', code: 'U' },
          { name: 'Zulia', code: 'V' },
        ],
      },
    },
  },
  VN: {
    name: 'Vietnam',
    code: 'VN',
    dialCode: '+84',
    flag: '🇻🇳',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      administrativeArea: { label: 'State' },
      postalCode: { label: 'Postal Code' },
    },
  },
  VG: {
    name: 'British Virgin Islands',
    code: 'VG',
    dialCode: '+1284',
    flag: '🇻🇬',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      postalCode: { label: 'Postal Code' },
    },
  },
  VI: {
    name: 'U.S. Virgin Islands',
    code: 'VI',
    dialCode: '+1340',
    flag: '🇻🇮',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
      administrativeArea: { label: 'State' },
      postalCode: { label: 'Postal Code' },
    },
  },
  WF: {
    name: 'Wallis & Futuna',
    code: 'WF',
    dialCode: '+681',
    flag: '🇼🇫',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code', format: /^986\d{2}$/, eg: '98600' },
      localityName: 'City',
    },
  },
  YE: {
    name: 'Yemen',
    code: 'YE',
    dialCode: '+967',
    flag: '🇾🇪',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  ZM: {
    name: 'Zambia',
    code: 'ZM',
    dialCode: '+260',
    flag: '🇿🇲',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      postalCode: { label: 'Postal Code' },
      localityName: 'City',
    },
  },
  ZW: {
    name: 'Zimbabwe',
    code: 'ZW',
    dialCode: '+263',
    flag: '🇿🇼',
    addressInfo: {
      thoroughfare: 'Address',
      premise: 'Address 2',
      localityName: 'City',
    },
  },
};

export interface CountryAddressInfo extends AddressInfo {
  /** Name of country to which the address format info pertains. */
  name: string;
}

/** ... */
export const countryAddressInfo: Record<string, CountryAddressInfo> = {};

for (const key in countryInfo) {
  const info = countryInfo[key] as CountryInfo;

  countryAddressInfo[key] = { name: info.name, ...info.addressInfo };
}
