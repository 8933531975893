import { server } from '@api/request';
import { Product, Organization, DLC } from '@models';
import { isObject, isArray } from '@tools/type-guards';

export type DependencyList = Product.DependencyInfo['id'][];
export type SceneList = Product.SceneInfo['id'][];
export type DroneList = Product.DroneInfo['id'][];
export type DlcList = Product.DlcInfo['id'][];
export type OrganizationList = Product.OrganizationInfo['id'][];
export type BundleList = Product['id'][];

/** `Product` certification information submission options.  */
export type CertificationOptions = Overwrite<
  Product.CertificationInfo,
  { modules: Product.ModuleInfo['id'][] }
>;

/**
 * {@link list List products} API request options.
 */
export interface ListOptions {
  type: 'basic' | 'certification';
}

/**
 * List all existing products.
 *
 * @param options Request options bag.
 * @returns A list of products.
 *
 * @see {@link ZephyrApi.Products.listAllProducts `ListAllProducts`} API route in `zephyr-serverless`
 */
export async function list(options: ListOptions = { type: 'basic' }) {
  const response = await server.get('v2/products');

  if (!isArray(response)) {
    throw new Error('[] invalid response data.');
  }

  let products = response.map(processProductData);

  if (options.type === 'certification') {
    products = products.filter(({ type }) => type === 'CERTIFICATION');
  }

  return products;
}

/**
 * List all existing "live" products.
 *
 * @returns A promise that resolves to a list of products.
 *
 * @see {@link ZephyrApi.Products.listPromotionCodes `ListPromotionCodes`} API route in `zephyr-serverless`
 */
export async function listLive() {
  return await server.list('v2/products/live', processProductData);
}

/**
 * {@link list List products by organization} API request options.
 */
export interface ListByOrganizationOptions {
  organizationId: Organization['id'];
}

/**
 * List all products accessible to a specified organization.
 *
 * NOTE: TEMPORARY
 *
 * @param options Request options bag.
 * @returns A list of products.
 *
 * @see {@link ZephyrApi.Organizations.listProducts `ListOrganizationProducts`} API route in `zephyr-serverless`
 */
export async function listByOrganization(options: ListByOrganizationOptions) {
  // eslint-disable-next-line no-console
  console.warn(
    "[products.listByOrganization] is a temporary call. Should be able to retrieve objects directly from an organization's data object.",
  );

  return await server.list(
    `v2/organizations/${options.organizationId}/products`,
    processProductData,
  );
}

/**
 * {@link get Get product} API request options.
 */
export interface GetOptions {
  productId: Product['id'];
}

/**
 * Get a specified product.
 *
 * @param options Request options bag.
 * @returns The specified product.
 *
 * @see {@link ZephyrApi.Products.getProduct `GetProduct`} API route in `zephyr-serverless`
 */
export async function get(options: GetOptions) {
  return await server.get(
    `v2/products/${options.productId}`,
    processProductData,
  );
}

/**
 * {@link getAsAdmin Get product as admin} API request options.
 */
export interface GetAsAdminOptions {
  productId: Product['id'];
}

/**
 * Get a specified product as an admin.
 *
 * @param options Request options bag.
 * @returns The specified product.
 *
 * @see {@link ZephyrApi.Admin.Products.adminGetProduct `AdminGetProduct`} API route in `zephyr-serverless`
 */
export async function getAsAdmin(options: GetOptions) {
  return await server.get(
    `v2/admin/products/${options.productId}`,
    processProductData,
  );
}

/**
 * {@link create Create product} API request options.
 */
export interface CreateOptions {
  id: Product['id'];
  type: Product.Type;
  name: string;
  taxCode: string;
  tags?: string[];
  prices?: CreateOptions.PriceOptions[];
  price?: number;
  salePrice?: number;
  upSell?: number;
  downSell?: number;
  useGeneric?: boolean;
  hideShop?: boolean;
  limit?: number;
  description?: string | null;
  allowBackorder?: boolean;
  stock: number | null;
  weight?: number | null;
  instructionalVideo?: boolean;
  active?: boolean;
  dependencies?: DependencyList;
  scenes?: SceneList;
  drones?: DroneList;
  organizations?: OrganizationList;
  bundle?: BundleList;
  certification?: CertificationOptions;
  images?: ZephyrWeb.ImageInfo[];
  // ...
  checkoutPinned?: number | null;
  shippingService?: Product.ShippingService | null;
  shippingTable?: Product.ShippingTableEntry[] | null;
  phoneRequired: boolean;
  dlc?: DlcList;
}

export namespace CreateOptions {
  export type PriceOptions = Omit<Product.PriceInfo, 'id'>;
}

/**
 * Create a product.
 *
 * @param options Request options bag.
 * @returns The new product.
 *
 * @see {@link ZephyrApi.Admin.Products.createProduct `AdminCreateProduct`} API route in `zephyr-serverless`
 */
export async function create(options: CreateOptions) {
  return await server.post('v2/admin/products', options, processProductData);
}

/**
 * {@link createSiteLicense Create site license product} API request options.
 */
export interface CreateSiteLicenseOptions {
  id: Product['id'];
  name: string;
  description?: string | null;
  priceId?: string | null;
  priceData?: CreateSiteLicenseOptions.PriceData;
  useGeneric: boolean;
  scenes: SceneList;
  drones: DroneList;
}

export namespace CreateSiteLicenseOptions {
  export type PriceData = Pick<
    Product.PriceInfo,
    'amount' | 'currency' | 'description'
  >;
}

/**
 * Create a site (enterprise) license product.
 *
 * @param options Request options bag.
 * @returns The new product.
 *
 * @see {@link ZephyrApi.Admin.Products.createEnterpriseLicense `AdminCreateSiteLicense`} API route in `zephyr-serverless`
 */
export async function createSiteLicense(options: CreateSiteLicenseOptions) {
  return await server.post(
    'v2/admin/products/enterprise-license',
    options,
    processProductData,
  );
}

/**
 * {@link update Update product} API request options.
 */
export interface UpdateOptions {
  id: Product['id'];
  type?: Product.Type;
  name?: string;
  taxCode?: string;
  tags?: string[] | null;
  price?: number;
  salePrice?: number;
  upSell?: number;
  downSell?: number;
  useGeneric?: boolean;
  hideShop?: boolean;
  limit?: number;
  description?: string | null;
  allowBackorder?: boolean;
  stock?: number | null;
  weight?: number | null;
  instructionalVideo?: boolean;
  active?: boolean;
  dependencies?: DependencyList | null;
  scenes?: SceneList | null;
  drones?: DroneList | null;
  organizations?: OrganizationList | null;
  bundle?: BundleList | null;
  certification?: CertificationOptions | null;
  addedImages?: ZephyrWeb.ImageInfo[];
  removedImages?: string[];
  licenseExpiration?: number | null;
  // ...
  checkoutPinned?: number | null;
  shippingService?: Product.ShippingService | null;
  shippingTable?: Product.ShippingTableEntry[] | null;
  phoneRequired?: boolean;
  dlc?: DlcList | null;
}

/**
 * Get a specified product as an admin.
 *
 * @param options Request options bag.
 * @returns The specified product.
 *
 * @see {@link ZephyrApi.Admin.Products.update `AdminUpdateProduct`} API route in `zephyr-serverless`
 */
export async function update(options: UpdateOptions) {
  const { id, ...data } = options;

  return await server.post(`v2/admin/products/${id}`, data, processProductData);
}

/**
 * {@link updateSiteLicense Update site license product} API request options.
 */
export interface UpdateSiteLicenseOptions {
  productId: Product['id'];
  name?: string;
  description?: string | null;
  priceId?: string | null;
  priceData?: CreateSiteLicenseOptions.PriceData;
  scenes?: SceneList;
  drones?: DroneList;
}

export namespace UpdateSiteLicenseOptions {
  export type PriceData = Pick<
    Product.PriceInfo,
    'amount' | 'currency' | 'description'
  >;
}

/**
 * Update a site (enterprise) license product.
 *
 * @param options Request options bag.
 * @returns The new product.
 *
 * @see {@link ZephyrApi.Admin.Products.updateEnterpriseLicense `AdminUpdateSiteLicense`} API route in `zephyr-serverless`
 */
export async function updateSiteLicense(options: UpdateSiteLicenseOptions) {
  const { productId, ...data } = options;

  return await server.post(
    `v2/admin/products/enterprise-license/${productId}`,
    data,
    processProductData,
  );
}

/**
 * {@link addPrice Add price to product} API request options.
 */
export interface AddPriceOptions {
  /**
   * The product ID.
   */
  productId: Product['id'];
  /**
   * The price ID from Stripe (if it already exists). All other properties will
   * be ignored if provided.
   */
  priceId?: Product.PriceInfo['id'];
  /**
   * The price amount (in cents).
   */
  amount: number;
  /**
   * The price currency type.
   */
  currency: string;
  /**
   * An optional price description.
   */
  description?: string;
  /**
   * Boolean flag representing whether the price should be active or not.
   */
  active?: boolean;
}

/**
 * Add price to specified product.
 *
 * @param options Request options bag.
 *
 * @see {@link ZephyrApi.Admin.Products.addPrice `AdminAddPriceToProduct`} API route in `zephyr-serverless`
 */
export async function addPrice(options: AddPriceOptions) {
  return await server.post(
    `v2/admin/products/add-price`,
    options,
    processPriceInfoData,
  );
}

/**
 * {@link addDLC Add DLC to product} API request options.
 */
export interface AddDLCOptions {
  productId: Product['id'];
  dlcId: DLC['id'];
  private: boolean;
  os: 'win' | 'mac';
  name: string;
}

/**
 * Add DLC to specified product.
 *
 * @param options Request options bag.
 *
 * @see {@link ZephyrApi.Admin.Products.addDLC `AdminAddDlcToProduct`} API route in `zephyr-serverless`
 */
export async function addDLC(options: AddDLCOptions) {
  await server.post(`v2/admin/products/add-dlc`, options);
}

/**
 * {@link removeDLC Remove DLC from product} API request options.
 */
export interface RemoveDLCOptions {
  productId: Product['id'];
  dlcId: DLC['id'];
  os: 'win' | 'mac';
}

/**
 * Remove DLC from specified product.
 *
 * @param options Request options bag.
 *
 * @see {@link ZephyrApi.Admin.Products.removeDLC `AdminRemoveDlcFromProduct`} API route in `zephyr-serverless`
 */
export async function removeDLC(options: RemoveDLCOptions) {
  await server.post(`v2/admin/products/remove-dlc`, options);
}

/**
 * {@link del Delete product} API request options.
 */
export interface DeleteOptions {
  productId: Product['id'];
}

/**
 * Delete a specified product.
 *
 * @param options Request options bag.
 *
 * @see {@link ZephyrApi.Admin.Products.adminDeleteProduct `AdminDeleteProduct`} API route in `zephyr-serverless`
 */
export async function del(options: DeleteOptions) {
  await server.delete(`v2/admin/products/${options.productId}`);
}

//#region Helper Functions

/**
 * Determine if a value is a valid {@link Product}.
 *
 * @param value The value to check.
 * @returns `true` if the value is a valid {@link Product}, otherwise `false`.
 */
function isValidProductData(value: unknown): value is Product {
  if (!isObject(value)) return false;

  // TEMP: Use a more strict validation.

  return true;
}

/**
 * Process data received from a request expected to be a {@link Product}.
 *
 * @param data Data received from the request.
 * @returns The processed data value.
 */
function processProductData(data: unknown) {
  if (!isValidProductData(data)) {
    throw new Error('Invalid product data item.');
  }

  if (DEVELOPMENT) {
    if (!data.prices) {
      const tempPriceData: Product.PriceInfo = {
        id: $devUtils.uid(),
        amount: data.price * 100,
        currency: 'usd',
        active: true,
      };

      data.prices = [tempPriceData];

      // console.warn(
      //   `Product "${data.name}" had its "prices" array created from the depreciated "price" property due to its non-existence.`,
      //   { oldPrice: data.price, newPrice: tempPriceData },
      // );
    }

    data.price = NaN;

    // TEMP: some products have been found to not have a value for `behaviors`.
    // Should be removed once effected products are fixed or removed.
    if (!data.behaviors) {
      data.behaviors = [];
    }

    // TEMP: make sure all price currency values are lowercase.
    data.prices.forEach((price) => {
      price.currency = price.currency.toLowerCase() as Product.PriceCurrency;
    });
  }

  return { ...data } as Product;
}

/**
 * Determine if a value is a valid {@link Product.PriceInfo}.
 *
 * @param value The value to check.
 * @returns `true` if the value is a valid {@link Product.PriceInfo}, otherwise
 * `false`.
 */
function isValidPriceInfoData(value: unknown): value is Product.PriceInfo {
  if (!isObject(value)) return false;

  // TEMP: Use a more strict validation.

  return true;
}

/**
 * Process data received from a request expected to be
 * {@link Product.PriceInfo}.
 *
 * @param data Data received from the request.
 * @returns The processed data value.
 */
function processPriceInfoData(data: unknown) {
  if (!isValidPriceInfoData(data)) {
    throw new Error('Invalid price info data item.');
  }

  return { ...data } as Product.PriceInfo;
}

//#endregion Helper Functions
