export const HUN = {
  name: {
    common: 'Hungary',
    official: 'Hungary',
    native: { hun: { official: 'Magyarország', common: 'Magyarország' } },
  },
  cities: [
    'Dunaharaszti',
    'Budapest',
    'Gyal',
    'Nyirmartonfalva',
    'Csorvas',
    'Esztergom',
    'Kunhegyes',
    'Alsopahok',
    'Sarmellek',
    'Edeleny',
    'Balatonfured',
    'Koszeg',
    'Szombathely',
    'Tiszafured',
    'Ullo',
    'Ã‰rd',
    'Gyula',
    'Kistelek',
    'Szeged',
    'Sarszentmihaly',
    'Rakocziujfalu',
    'Kiskunfelegyhaza',
    'Ajak',
    'Ujszilvas',
    'KecskemÃ©t',
    'PÃ©cs',
    'Debrecen',
    'Hegyhatmaroc',
    'Szolnok',
    'Mako',
    'Apatfalva',
    'Klarafalva',
    'BÃ©kÃ©scsaba',
    'Posfa',
    'Tiszaujvaros',
    'Keszu',
    'Tata',
    'Kisujszallas',
    'Kerekegyhaza',
    'Miskolc',
    'Kunszentmarton',
    'VisegrÃ¡d',
    'Solymar',
    'Piliscsaba',
    'God',
    'Budajeno',
    'Budakalasz',
    'Karcag',
    'Nagykoros',
    'Salfold',
    'Kapuvar',
    'Vitnyed',
    'Repceszemere',
    'Szajol',
    'Tapioszele',
    'Almasfuzito',
    'Szecseny',
    'Nogradsipek',
    'Kacsota',
    'Pakod',
    'Zalaegerszeg',
    'Szentkatalin',
    'Beloiannisz',
    'Fot',
    'Erdokertes',
    'Kerepes',
    'Csecse',
    'Taplanszentkereszt',
    'Szazhalombatta',
    'Zahony',
    'Kisvarda',
    'Kistarcsa',
    'Mateszalka',
    'Boly',
    'Szajk',
    'Oroshaza',
    'Medgyesegyhaza',
    'Vegegyhaza',
    'Hodmezovasarhely',
    'Totkomlos',
    'Nyiregyhaza',
    'Pomaz',
    'Kismaros',
    'Veresegyhaz',
    'Kisgyor',
    'SiÃ³fok',
    'GyÅ‘r',
    'Pusztavam',
    'Szemely',
    'Hajdusamson',
    'Tiszalok',
    'Ajka',
    'SzÃ©kesfehÃ©rvÃ¡r',
    'Szerencs',
    'Beled',
    'Nagynyarad',
    'Aparhant',
    'Komlo',
    'KomÃ¡rom',
    'Szabadbattyan',
    'Soskut',
    'Eperjeske',
    'Tiszaszalka',
    'Somogysard',
    'KaposvÃ¡r',
    'Diosd',
    'Pusztazamor',
    'Mende',
    'MosonmagyarÃ³vÃ¡r',
    'Bonyhad',
    'Sasd',
    'Nagymanyok',
    'SzekszÃ¡rd',
    'Tolna',
    'Paks',
    'Seregelyes',
    'Kisvaszar',
    'Dunafoldvar',
    'Tevel',
    'Nemetker',
    'Kurd',
    'Kulcs',
    'Alsoszentivan',
    'Sarbogard',
    'Sajoszentpeter',
    'Mucsony',
    'Sajokapolna',
    'Kazincbarcika',
    'Balatonszabadi',
    'Balatonszarszo',
    'Per',
    'Kadarkut',
    'Lebeny',
    'Fertoszentmiklos',
    'Dunakeszi',
    'Ivancsa',
    'Telep',
    'Lajoskomarom',
    'Roszke',
    'GÃ¶dÃ¶llÅ‘',
    'Csogle',
    'Csomor',
    'TatabÃ¡nya',
    'DunaÃºjvÃ¡ros',
    'Tompa',
    'Batonyterenye',
    'VeszprÃ©m',
    'Tura',
    'ParÃ¡dfÃ¼rdÅ‘',
    'Gyomro',
    'BÃ©kÃ©s',
    'Csepa',
    'Nagyhalasz',
    'Isaszeg',
    'Gorgeteg',
    'Somogyudvarhely',
    'Labod',
    'Nagyatad',
    'Sandorfalva',
    'Lenti',
    'Zalalovo',
    'Nagypali',
    'Boncodfolde',
    'Szigliget',
    'Tapolca',
    'Balloszog',
    'Kiskunhalas',
    'Gyongyos',
    'Nagycserkesz',
    'Gencsapati',
    'Pecol',
    'Nemesbod',
    'Vep',
    'Sarvar',
    'Szentendre',
    'Szirmabesenyo',
    'Helvecia',
    'Bukkaranyos',
    'Hernadkak',
    'Almosd',
    'Gyorsag',
    'Fertod',
    'Ikreny',
    'Telki',
    'Nyirtass',
    'Hont',
    'Saska',
    'Bataszek',
    'Pellerd',
    'MohÃ¡cs',
    'Gyenesdias',
    'Bicske',
    'Szomod',
    'Alsogalla',
    'Lovas',
    'Kisber',
    'Polgardi',
    'Cegled',
    'Pilisvorosvar',
    'Nagyszenas',
    'Domaszek',
    'Nagyszekeres',
    'Asotthalom',
    'Hercegszanto',
    'Egyek',
    'Bohonye',
    'Zsambek',
    'Sopron',
    'Kormend',
    'Tiszaluc',
    'Arnot',
    'Inancs',
    'Taktaszada',
    'Csobad',
    'Sajokeresztur',
    'Dorog',
    'Hatvan',
    'Hosszuheteny',
    'Mogyorod',
    'Urom',
    'Aszod',
    'Balatonalmadi',
    'Szodliget',
    'Galgamacsa',
    'Ozd',
    'Nagydobsza',
    'Rabapatona',
    'Csorna',
    'Kony',
    'Szilsarkany',
    'Pilismarot',
    'Ercsi',
    'Visznek',
    'Biharkeresztes',
    'Hajduboszormeny',
    'Nagytotfalu',
    'Nyirbator',
    'Nemesbuek',
    'Budaors',
    'Teglas',
    'Vamosszabadi',
    'Otteveny',
    'Tab',
    'Balatonlelle',
    'Kalocsa',
    'Simontornya',
    'Mezoszilas',
    'Deg',
    'Nyul',
    'Gyorujbarat',
    'Csanadpalota',
    'Galambok',
    'Badacsonytomaj',
    'Nagykanizsa',
    'Ujfeherto',
    'Boldog',
    'Eger',
    'Salgotarjan',
    'Nagykallo',
    'Aszalo',
    'Onga',
    'Budakeszi',
    'Toeroekbalint',
    'Balatonfuzfo',
    'Berhida',
    'Mor',
    'Szikszo',
    'Gyongyossolymos',
    'Maklar',
    'Szigetszentmiklos',
    'Farmos',
    'Varpalota',
    'Magyaregres',
    'Kaposmero',
    'Nagyberki',
    'Taszar',
    'Cserenfa',
    'Juta',
    'Kartal',
    'Nagyvenyim',
    'Marokpapi',
    'Baja',
    'Kubekhaza',
    'Tiszafoldvar',
    'Szentgotthard',
    'Hegyeshalom',
    'Bag',
    'Hevizgyoerk',
    'Janossomorja',
    'Kistokaj',
    'Delegyhaza',
    'Agard',
    'Alsozsolca',
    'Gardony',
    'Bokod',
    'Homrogd',
    'Csobanka',
    'Mezotur',
    'Paty',
    'Hajdunanas',
    'Szalaszend',
    'Hernadnemeti',
    'Nagykoru',
    'Biatorbagy',
    'Marcali',
    'Hajduszoboszlo',
    'Mesztegnyo',
    'Danszentmiklos',
    'Encs',
    'Verseg',
    'Tet',
    'Halaszi',
    'Gyorladamer',
    'Pilisszentkereszt',
    'Tarnok',
    'Abony',
    'Sajovamos',
    'Vamospercs',
    'Varosfoeld',
    'Cserszegtomaj',
    'Berettyoujfalu',
    'Keszthely',
    'Lajosmizse',
    'Zalakomar',
    'Martonvasar',
    'Balkany',
    'Toekoel',
    'Bakonyszentlaszlo',
    'Egyhazashetye',
    'Papa',
    'Zsambok',
    'Repcelak',
    'Vacszentlaszlo',
    'Bajna',
    'VÃ¡c',
    'Csovar',
    'Magy',
    'Nagyhegyes',
    'Ladbesenyo',
    'Korosladany',
    'Garab',
    'Rabapaty',
    'Adony',
    'Felsoszentivan',
    'Puspokladany',
    'Zebecke',
    'Lovaszi',
    'Paka',
    'Gyar',
    'Mosonszentmiklos',
    'Leanyfalu',
    'Szany',
    'Velence',
    'Tarjan',
    'Pakozd',
    'Borcs',
    'Oroszlany',
    'Fuzesabony',
    'Bakonyszombathely',
    'Szigetvar',
    'Kornye',
    'Malyi',
    'Selyeb',
    'Kiskinizs',
    'Sored',
    'Bakonycsernye',
    'Nagykereki',
    'Sarospatak',
    'Torokszentmiklos',
    'Balatonboglar',
    'Nezsa',
    'Pilisszanto',
    'Vasvar',
    'Dunabogdany',
    'Retsag',
    'Pilis',
    'Tarpa',
    'Mariapocs',
    'Tetetlen',
    'Petohaza',
    'Tiszavasvari',
    'Komlod',
    'Abda',
    'Sumeg',
    'Siklos',
    'Berkesd',
    'Bodajk',
    'Nemesvita',
    'Ujszentmargita',
    'Kotaj',
    'Hajduhadhaz',
    'Magyarhertelend',
    'Maglod',
    'Gelse',
    'Gorbehaza',
    'Kincsesbanya',
    'Rakoczifalva',
    'Abaujszanto',
    'Turkeve',
    'Martfu',
    'Harta',
    'Nyiradony',
    'Badacsonytordemic',
    'Fegyvernek',
    'Kisdorog',
    'Szentes',
    'Magyarnandor',
    'Vaja',
    'Zebegeny',
    'Szokolya',
    'Almaskamaras',
    'Kevermes',
    'Mindszent',
    'Deszk',
    'Ujlengyel',
    'Dabas',
    'Monor',
    'Ecser',
    'Peteri',
    'Csakvar',
    'Vecses',
    'Soltszentimre',
    'Pilisszentivan',
    'Tiszacsege',
    'Barand',
    'Mezokovesd',
    'Csemo',
    'Balassagyarmat',
    'Sajooeroes',
    'Heves',
    'Tapiogyorgye',
    'Bacsalmas',
    'Patvarc',
    'Polgar',
    'Ofeherto',
    'Janoshalma',
    'Toszeg',
    'Zalaszentgrot',
    'Szentlorinc',
    'Barcs',
    'Gyulahaza',
    'Levelek',
    'HÃ©vÃ­z',
    'Kiskoros',
    'Kesznyeten',
    'Rackeresztur',
    'Bekasmegyer',
    'Felsopakony',
    'Zagyvaszanto',
    'Demjen',
    'Pecel',
    'Szendehely',
    'Tordas',
    'Ostoros',
    'Egyhazasdengeleg',
    'Vertesszolos',
    'Felsozsolca',
    'Alsotold',
    'Jaszfelsoszentgyorgy',
    'Csoeroeg',
    'Veroce',
    'Jaszapati',
    'Szarvas',
    'Csabacsud',
    'Bekesszentandras',
    'Totszerdahely',
    'Becsehely',
    'Cered',
    'Pazmand',
    'Besnyo',
    'Sukoro',
    'Nemesvid',
    'Doroghaza',
    'Tabajd',
    'Letenye',
    'Szepetnek',
    'Valkonya',
    'Ujudvar',
    'Kistapolca',
    'Nagykeresztur',
    'Halasztelek',
    'Makkoshotyka',
    'Jaszbereny',
    'Szigethalom',
    'Janoshida',
    'Bocs',
    'Jaszfenyszaru',
    'Nyekladhaza',
    'Ujszasz',
    'Gyongyostarjan',
    'Nagytarcsa',
    'Emod',
    'Ecs',
    'Szuhakallo',
    'Kurityan',
    'Kophaza',
    'Hegyfalu',
    'Nagycenk',
    'Tarcal',
    'Taksony',
    'Vertestolna',
    'Labatlan',
    'Dunavarsany',
    'Majoshaza',
    'Domsod',
    'Balatonkenese',
    'SÃ¡toraljaÃºjhely',
    'Revfueloep',
    'Vertessomlo',
    'Bazsi',
    'Szeremle',
    'Hercegkut',
    'Koka',
    'Nagykata',
    'Alsonemedi',
    'Inarcs',
    'Ocsa',
    'Szentmartonkata',
    'Monorierdo',
    'Uri',
    'Benye',
    'Tapioszecso',
    'Kerecsend',
    'Vizslas',
    'Koszarhegy',
    'Apc',
    'Tiszaadony',
    'Sirok',
    'Vajszlo',
    'Taborfalva',
    'Bugyi',
    'Pusztavacs',
    'Atkar',
    'Nagykozar',
    'Nyergesujfalu',
    'Se',
    'Paszto',
    'Ibrany',
    'Felsotarkany',
    'Egerszolat',
    'Markaz',
    'Hajmasker',
    'Szentkiralyszabadja',
    'Herend',
    'Kallo',
    'Hered',
    'Hehalom',
    'Csomad',
    'Tapiobicske',
    'Tapiosag',
    'Part',
    'Domoszlo',
    'Karancssag',
    'Celldomolk',
    'Mezofalva',
    'Zirc',
    'Aszar',
    'Nagyigmand',
    'Acs',
    'Babolna',
    'Rackeve',
    'Kerekharaszt',
    'Nagyvazsony',
    'Jaszkiser',
    'Kemence',
    'Devavanya',
    'Ketegyhaza',
    'Valko',
    'Morahalom',
    'Fuzesgyarmat',
    'Dany',
    'Karancslapujto',
    'Karancskeszi',
    'Martely',
    'Neszmely',
    'Cirak',
    'Senyo',
    'Sarosd',
    'Csanytelek',
    'Terem',
    'Rad',
    'Szar',
    'Nagybarca',
    'Csongrad',
    'Melykut',
    'Tass',
    'Dunavecse',
    'Nagymagocs',
    'Pusztaszemes',
    'Tomorkeny',
    'Kunbaja',
    'Szegvar',
    'Kunszentmiklos',
    'Apostag',
    'Mezobereny',
    'Hernad',
    'Varbalog',
    'Balmazujvaros',
    'Gonyu',
    'Ocseny',
    'Bogyiszlo',
    'Mecseknadasd',
    'Attala',
    'Dombovar',
    'Vardomb',
    'Varalja',
    'Iregszemcse',
    'Dunaszentgyorgy',
    'Decs',
    'Racalmas',
    'Ciko',
    'Nagyszokoly',
    'Tamasi',
    'Izmeny',
    'Alap',
    'Bikal',
    'Hidas',
    'Sarisap',
    'Tat',
    'Banhorvati',
    'Herceghalom',
    'Vadna',
    'Varbo',
    'Zichyujfalu',
    'Szogliget',
    'Dedestapolcsany',
    'Many',
    'Bodaszolo',
    'Kislang',
    'Albertirsa',
    'Kulsobarand',
    'Zamoly',
    'Szod',
    'Szecsenyfelfalu',
    'Baracs',
    'Tiszavalk',
    'Kispest',
    'Naszaly',
    'Rudabanya',
    'Borsodszirak',
    'Golle',
    'Vasarosdombo',
    'Mernye',
    'Tokodaltaro',
    'Szabolcs',
    'Derecske',
    'Buzsak',
    'Gerjen',
    'Palotabozsok',
    'Dobrokoz',
    'Mezonyarad',
    'Szentistvan',
    'Dunaszeg',
    'Solt',
    'Szabadszallas',
    'Kecel',
    'Hajdudorog',
    'Dombrad',
    'Szabolcsveresmart',
    'Mandok',
    'Patroha',
    'Gemzse',
    'Ujkenez',
    'Devecser',
    'Dudar',
    'Bakonybel',
    'Kunsziget',
    'Kemecse',
    'Kunagota',
    'Kismanyok',
    'Balatonfokajar',
    'Sarpentele',
    'Jaszladany',
    'Tar',
    'Matraszolos',
    'Vacratot',
    'Vacduka',
    'Ocsod',
    'Adacs',
    'Belapatfalva',
    'Hevesaranyos',
    'Kotelek',
    'Cserepfalu',
    'Acsa',
    'Kisnemedi',
    'Makad',
    'Rimoc',
    'Berekfurdo',
    'Kajaszo',
    'Nagysap',
    'Gyomaendrod',
    'Szigetcsep',
    'Csopak',
    'Petfuerdo',
    'Szigetszentmarton',
    'Kiskunlachaza',
    'Petofibanya',
    'Sarkad',
    'Orbottyan',
    'Baracska',
    'Szeghalom',
    'Nagykapornak',
    'Sajopalfala',
    'Matraballa',
    'Bugac',
    'Tinnye',
    'Bagyogszovat',
    'Sopronkovesd',
    'Nagykovacsi',
    'Rajka',
    'Perbal',
    'Boldva',
    'Torony',
    'Hegyhatszentmarton',
    'Tiszapalkonya',
    'Cegledbercel',
    'Hort',
    'Nogradsap',
    'Dad',
    'Donat',
    'Center',
    'Kapolnasnyek',
    'Kolontar',
    'Bodrogkisfalud',
    'Balaton',
    'Kompolt',
    'Lazi',
    'Halimba',
    'Ujkigyos',
    'Pusztaszabolcs',
    'Mezohegyes',
    'Kisar',
    'Fehergyarmat',
    'Gavavencsello',
    'Tepe',
    'Harkany',
    'Perenye',
    'Dusnok',
    'Szabadszentkiraly',
    'Tiszabo',
    'Csajag',
    'Buk',
    'Madocsa',
    'Sarszentagota',
    'Sarpilis',
    'Kistormas',
    'Gyorkony',
    'Sarszentlorinc',
    'Uraiujfalu',
    'Szomor',
    'Gyorzamoly',
    'Ebes',
    'Kaloz',
    'Vezseny',
    'Mosonszolnok',
    'Csolyospalos',
    'Zsombo',
    'Misefa',
    'Telekes',
    'Gasztony',
    'Suelysap',
  ],
  tld: ['.hu'],
  cca2: 'HU',
  ccn3: '348',
  cca3: 'HUN',
  cioc: 'HUN',
  currency: ['HUF'],
  callingCode: ['36'],
  capital: 'Budapest',
  altSpellings: ['HU'],
  region: 'Europe',
  subregion: 'Eastern Europe',
  languages: { hun: 'Hungarian' },
  translations: {
    deu: { official: 'Ungarn', common: 'Ungarn' },
    fra: { official: 'Hongrie', common: 'Hongrie' },
    hrv: { official: 'Madžarska', common: 'Mađarska' },
    ita: { official: 'Ungheria', common: 'Ungheria' },
    jpn: { official: 'ハンガリー', common: 'ハンガリー' },
    nld: { official: 'Hongarije', common: 'Hongarije' },
    por: { official: 'Hungria', common: 'Hungria' },
    rus: { official: 'Венгрия', common: 'Венгрия' },
    spa: { official: 'Hungría', common: 'Hungría' },
    fin: { official: 'Unkari', common: 'Unkari' },
  },
  latlng: [47, 20],
  demonym: 'Hungarian',
  landlocked: true,
  borders: ['AUT', 'HRV', 'ROU', 'SRB', 'SVK', 'SVN', 'UKR'],
  area: 93028,
  provinces: ['Howland Island'],
  nativeName: 'Magyarország',
  timezones: ['UTC+01:00'],
} as const;
