import { server } from '@api/request';
import { Reseller, Organization, Invite, Product } from '@models';
import * as typeGuards from '@tools/type-guards';

/**
 * List all existing resellers.
 *
 * @returns A list of resellers.
 */
export async function list() {
  return await server.list('resellers', processResellerData);
}

/**
 * ...
 */
export interface GetResellerOptions {
  id: number;
}

/**
 * Get a specified reseller.
 *
 * @param options Request options bag.
 * @returns The specified reseller.
 */
export async function get(options: GetResellerOptions) {
  return await server.get(`resellers/${options.id}`, processResellerData);
}

/**
 * ...
 */
export interface CreateOptions {
  // id: number;
  id: string;
  name: string;
  website: string;
  contactEmail: string;
  cut: string;
  products?: Product[];
  // institutions?: Institution[];
  accounts?: Organization[];
  invites?: Invite[];
}

/**
 * Create a reseller.
 *
 * @param options Request options bag.
 * @returns The specified reseller.
 */
export async function create(options: CreateOptions) {
  return await server.post(
    `resellers/${options.id}`,
    options,
    processResellerData,
  );
}

/**
 * ...
 */
export interface AddProductsOptions {
  id: number;
  productIds: string[];
}

/**
 * Add products to a reseller.
 *
 * @param options Request options bag.
 * @returns ...
 */
export async function addProducts(options: AddProductsOptions) {
  return await server.post(
    `resellers/${options.id}/add-product`,
    options,
    processResellerData,
  );
}

/**
 * ...
 */
export interface RemoveProductsOptions {
  id: number;
  productIds: string[];
}

/**
 * Remove products from a reseller.
 *
 * @param options Request options bag.
 * @returns ...
 */
export async function removeProducts(options: RemoveProductsOptions) {
  return await server.post(
    `resellers/${options.id}/remove-product`,
    options,
    processResellerData,
  );
}

/**
 * ...
 */
export interface InviteOptions {
  id: number;
  emails: string[];
}

/**
 * Invite reseller users.
 *
 * @param options Request options bag.
 * @returns ...
 */
export async function inviteUsers(options: InviteOptions) {
  await server.post(
    `resellers/${options.id}/invite`,
    options,
    processResellerData,
  );
}

//region Helper Functions

/**
 * ...
 *
 * @param value ...
 * @returns ...
 */
function isValidResellerData(value: unknown): value is Reseller {
  if (!typeGuards.isObject(value)) return false;

  return (
    typeGuards.isString(value.id) && typeGuards.isString(value.announcementId)
  );
}

/**
 * ...
 *
 * @param data ...
 * @returns ...
 */
function processResellerData(data: unknown) {
  if (!isValidResellerData(data)) {
    throw new Error('Invalid reseller announcement data.');
  }

  return { ...data } as Reseller;
}

//#endregion Helper Functions
