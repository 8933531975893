var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-container",
    { staticClass: "pack-option-cards" },
    [
      _c(
        "b-row",
        _vm._l(_setup.PACKS, function (pack, index) {
          return _c(
            "b-col",
            { key: pack.key, attrs: { lg: "4" } },
            [
              _c(
                _setup.PackOptionCard,
                _vm._b(
                  { on: { selected: _setup.onSelection } },
                  "PackOptionCard",
                  { index, pack },
                  false
                )
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }