export const BWA = {
  name: {
    common: 'Botswana',
    official: 'Republic of Botswana',
    native: {
      eng: { official: 'Republic of Botswana', common: 'Botswana' },
      tsn: { official: 'Lefatshe la Botswana', common: 'Botswana' },
    },
  },
  cities: ['Francistown', 'Gaborone', 'Orapa', 'Serowe', 'Village'],
  tld: ['.bw'],
  cca2: 'BW',
  ccn3: '072',
  cca3: 'BWA',
  cioc: 'BOT',
  currency: ['BWP'],
  callingCode: ['267'],
  capital: 'Gaborone',
  altSpellings: ['BW', 'Republic of Botswana', 'Lefatshe la Botswana'],
  region: 'Africa',
  subregion: 'Southern Africa',
  languages: { eng: 'English', tsn: 'Tswana' },
  translations: {
    deu: { official: 'Republik Botsuana', common: 'Botswana' },
    fra: { official: 'République du Botswana', common: 'Botswana' },
    hrv: { official: 'Republika Bocvana', common: 'Bocvana' },
    ita: { official: 'Repubblica del Botswana', common: 'Botswana' },
    jpn: { official: 'ボツワナ共和国', common: 'ボツワナ' },
    nld: { official: 'Republiek Botswana', common: 'Botswana' },
    por: { official: 'República do Botswana', common: 'Botswana' },
    rus: { official: 'Республика Ботсвана', common: 'Ботсвана' },
    spa: { official: 'República de Botswana', common: 'Botswana' },
    fin: { official: 'Botswanan tasavalta', common: 'Botswana' },
  },
  latlng: [-22, 24],
  demonym: 'Motswana',
  landlocked: true,
  borders: ['NAM', 'ZAF', 'ZMB', 'ZWE'],
  area: 582000,
  provinces: [
    'Central',
    'Chobe',
    'Francistown',
    'Gaborone',
    'Ghanzi',
    'Kgalagadi',
    'Kgatleng',
    'Kweneng',
    'Lobatse',
    'Ngamiland',
    'North-East',
    'Selebi-Pikwe',
    'South-East',
    'Southern',
  ],
  nativeName: 'Botswana',
  timezones: ['UTC+02:00'],
} as const;
