var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "a",
    _vm._b(
      {
        ref: "el",
        class: _setup.classList,
        on: { click: _setup.onClick, mouseleave: _setup.onMouseLeave },
      },
      "a",
      { title: _vm.title },
      false
    ),
    [
      _c("Icon", _vm._b({}, "Icon", { icon: _vm.icon }, false)),
      _vm._v(" "),
      _setup.isNumber(_vm.badge) && _vm.badge > 0
        ? [_c("BadgeCount", { key: _vm.badge, attrs: { value: _vm.badge } })]
        : _vm._e(),
      _vm._v(" "),
      _setup.isMobile
        ? _c("p", { staticClass: "m-0" }, [_vm._v(_vm._s(_setup.tooltip))])
        : _vm._e(),
      _vm._v(" "),
      _setup.el && !_setup.disableTooltip
        ? _c("b-tooltip", { attrs: { target: _setup.el, triggers: "hover" } }, [
            _vm._v("\n    " + _vm._s(_setup.tooltip) + "\n  "),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }