import { ensureArray } from '@tools/ensure-array';
import { ensureDate } from '@tools/ensure-date';
import { ensureError } from '@tools/ensure-error';

declare module 'vue/types/vue' {
  interface Vue {
    /**
     * Service with utilities for ensuring a value of a certain type by attempting
     * to cast the value to the required type if necessary.
     */
    $ensure: EnsureService;
  }
}

class EnsureService {
  /** Ensure the provided value is an `Array`. */
  readonly array = ensureArray;
  /** Ensure the provided value is an `Date`. */
  readonly date = ensureDate;
  /** Ensure the provided value is an `Error`. */
  readonly error = ensureError;
}

/**
 * Service with utilities for ensuring a value of a certain type by attempting
 * to cast the value to the required type if necessary.
 */
export const ensure = new EnsureService();

export default ensure;
