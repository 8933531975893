import { Vue } from '@vue';

import { randomId } from '@tools/random-id';

import FlyoutMenuComponent from './FlyoutMenu.vue';

type FlyoutMenuInstance = InstanceType<typeof FlyoutMenuComponent>;

/** Flyout menu instance. */
export interface FlyoutMenu {
  /**
   * Unique ID of the flyout.
   */
  readonly uid: string;
  /**
   * Manually close the flyout menu.
   */
  close(): void;
  /**
   * Registers a callback to be called after the flyout menu has been closed.
   *
   * @param callback The callback function to be called.
   */
  onClosed(callback: FlyoutMenu.OnClosedCallback): void;
}

export namespace FlyoutMenu {
  /** Flyout menu creation options. */
  export type Options = import('./FlyoutMenu.vue').Props;

  /** Flyout menu item object. */
  export type Item = import('./FlyoutMenu.vue').Item;

  /** Flyout menu `onClosed` lifecycle hook callback function. */
  export type OnClosedCallback = () => void;
}

let currentFlyout: FlyoutMenuWrapper | null = null;

class FlyoutMenuWrapper implements FlyoutMenu {
  readonly uid = randomId();

  #instance: FlyoutMenuInstance;
  #onClosedCallback: FlyoutMenu.OnClosedCallback | null = null;
  #closed = false;

  constructor(options: FlyoutMenu.Options) {
    const el = document.createElement('div');
    document.body.append(el);

    const instanceOptions = Object.assign({}, FlyoutMenuComponent, {
      el,
      propsData: options,
    }) as unknown as ConstructorParameters<Vue.Constructor>[0];

    this.#instance = new Vue(instanceOptions) as unknown as FlyoutMenuInstance;

    this.#instance.$once('before-remove', () => this.#onBeforeRemove());
    this.#instance.$once('after-remove', () => this.#onAfterRemove());

    if (currentFlyout) {
      currentFlyout.close();
    }

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    currentFlyout = this;
  }

  close() {
    if (this.#closed) {
      // eslint-disable-next-line no-console
      return console.warn(
        '[FlyoutMenu.close] this flyout menu has already been closed.',
      );
    }

    this.#instance.close();
  }

  onClosed(fn: FlyoutMenu.OnClosedCallback) {
    this.#onClosedCallback = fn;
  }

  #onBeforeRemove() {
    this.#closed = true;

    this.#onClosedCallback?.();
  }

  #onAfterRemove() {
    this.#instance.$destroy();

    if (currentFlyout?.uid === this.uid) {
      currentFlyout = null;
    }
  }
}

/**
 * Create a flyout menu instance.
 *
 * @param options flyout menu options object.
 * @returns A flyout menu component instance.
 */
export function createFlyoutMenu(options: FlyoutMenu.Options) {
  return new FlyoutMenuWrapper(options) as FlyoutMenu;
}
