/**
 * Name info object for use with {@link nameFormat}.
 */
export interface NameInfo {
  firstName?: Nullable<string>;
  lastName?: Nullable<string>;
  email: string;
}

export interface NameFormatOptions {
  lastNameFirst?: boolean;
  ignoreEmail?: boolean;
}

/**
 * Format name for display purposes.
 *
 * @param info The value to check.
 * @param options Options data object for effecting output format.
 * @returns String featuring full name as specified.
 */
export function nameFormat(info: NameInfo, options?: NameFormatOptions) {
  const parts: string[] = [];

  if (info.firstName) {
    parts.push(info.firstName);
  }

  if (info.lastName) {
    if (options?.lastNameFirst && parts.length) {
      parts.unshift(`${info.lastName},`);
    } else {
      parts.push(info.lastName);
    }
  }

  if (!parts.length) {
    parts.push('no name');
  }

  if (!options?.ignoreEmail) {
    parts.push('|', info.email);
  }

  return parts.join(' ');
}
