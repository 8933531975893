export const SMR = {
  name: {
    common: 'San Marino',
    official: 'Most Serene Republic of San Marino',
    native: {
      ita: {
        official: 'Serenissima Repubblica di San Marino',
        common: 'San Marino',
      },
    },
  },
  cities: ['Falciano', 'Fiorentino', 'San Marino', 'Serravalle', 'Acquaviva'],
  tld: ['.sm'],
  cca2: 'SM',
  ccn3: '674',
  cca3: 'SMR',
  cioc: 'SMR',
  currency: ['EUR'],
  callingCode: ['378'],
  capital: 'City of San Marino',
  altSpellings: ['SM', 'Republic of San Marino', 'Repubblica di San Marino'],
  region: 'Europe',
  subregion: 'Southern Europe',
  languages: { ita: 'Italian' },
  translations: {
    deu: { official: 'Republik San Marino', common: 'San Marino' },
    fra: { official: 'République de Saint-Marin', common: 'Saint-Marin' },
    hrv: {
      official: 'Većina Serene Republika San Marino',
      common: 'San Marino',
    },
    ita: {
      official: 'Serenissima Repubblica di San Marino',
      common: 'San Marino',
    },
    jpn: {
      official: 'サンマリノのほとんどセリーヌ共和国',
      common: 'サンマリノ',
    },
    nld: {
      official: 'Meest Serene Republiek San Marino',
      common: 'San Marino',
    },
    por: {
      official: 'Sereníssima República de San Marino',
      common: 'San Marino',
    },
    rus: {
      official: 'Большинство Serene Республика Сан-Марино',
      common: 'Сан-Марино',
    },
    spa: {
      official: 'Serenísima República de San Marino',
      common: 'San Marino',
    },
    fin: { official: 'San Marinon seesteinen tasavalta', common: 'San Marino' },
  },
  latlng: [43.76666666, 12.41666666],
  demonym: 'Sammarinese',
  landlocked: true,
  borders: ['ITA'],
  area: 61,
} as const;
