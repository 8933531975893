import { isObject } from './type-guards';

/**
 * An alias for {@link JSON.parse} that falls back to providing an empty object
 * if any issue with the parsing process occurs.
 *
 * @param text String value to (attempt to) parse.
 * @returns The parsed JSON object value, or an empty object `{}` if the parsing
 * failed.
 */
export function parse<T = GenericObject>(text: string) {
  let value: unknown = null;

  try {
    value = JSON.parse(text);
  } catch {
    value = {};
  }

  return (isObject(value) ? value : {}) as T;
}
