var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "Transition",
    {
      attrs: { appear: "" },
      on: {
        "before-leave": _setup.beforeLeave,
        "after-leave": _setup.afterLeave,
      },
    },
    [
      _setup.active
        ? _c(
            "div",
            { ref: "el", staticClass: "flyout-menu", style: _setup.style },
            [
              _c(
                "ul",
                _vm._l(
                  _setup.menuItems,
                  function ({ key, icon, label, variant, fn }) {
                    return _c(
                      "li",
                      {
                        key: key,
                        class: {
                          danger: variant === "danger",
                          info: variant === "info",
                          success: variant === "success",
                          warning: variant === "warning",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            on: {
                              click: function ($event) {
                                return _setup.execAction(fn)
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "icon-wrapper" },
                              [
                                _c(
                                  _setup.Icon,
                                  _vm._b({}, "Icon", { icon }, false)
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "text-wrapper" }, [
                              _vm._v(_vm._s(label)),
                            ]),
                          ]
                        ),
                      ]
                    )
                  }
                ),
                0
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }