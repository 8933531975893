import { WatchCallback, WatchOptions, WatchStopHandle } from 'vue';

import { computed, watch, Vue } from '@vue';
import { Vuex, Getters, CommitMap, DispatchMap } from '@vuex';

import type { Me } from './modules';
import { Root } from './root';

declare module '@vue' {
  interface ExtendedVue {
    /** Vuex store. */
    $store: typeof store;
    /** Reference to active site user, if one is logged in. */
    $me: Me.UserSessionInfo | null;
  }
}

Vue.use(Vuex);

/** App store instance. */
export const store =
  getCachedStore() ?? new Vuex.Store<Root.State>({ strict: false, ...Root });

if (import.meta.hot) {
  import.meta.hot.on('vite:beforeUpdate', () => {
    if (import.meta.hot?.data) {
      import.meta.hot.data.store = store;
    }
  });
}

// Set up current user (me) functionality.

Vue.prototype.$me = null;

store.watch(
  (_, getters) => getters.user,
  (newValue) => Vue.set(Vue.prototype, '$me', newValue),
  { immediate: true },
);

/**
 * Composable that returns the app's `vuex` store instance.
 *
 * @returns App store instance.
 */
export function useStore() {
  return store;
}

/**
 * Composable that returns a ref of the specified store getter.
 *
 * @returns App store getter.
 */
export function useGetter<K extends keyof Getters>(key: K) {
  return computed(() => store.getters[key] as Getters[K]);
}

/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * ...
 *
 * @param type ...
 * @returns ...
 */
export function useCommit<T extends keyof CommitMap>(type: T) {
  return ((...args: any[]) => store.commit(type, ...args)) as CommitMap[T];
}

/**
 * ...
 *
 * @param type ...
 * @returns ...
 */
export function useDispatch<T extends keyof DispatchMap>(type: T) {
  return ((...args: any[]) => store.dispatch(type, ...args)) as DispatchMap[T];
}

export type WatchSource<T = any> = (state: Root.State, getters: Getters) => T;

/**
 * Create a watcher bound to the current state of the store.
 */
export function watchStore<K extends keyof Root.State>(
  source: K,
  cb: WatchCallback<Root.State[K]>,
  options?: WatchOptions,
): WatchStopHandle;
export function watchStore<T>(
  source: WatchSource<T>,
  cb: WatchCallback<T>,
  options?: WatchOptions,
): WatchStopHandle;
export function watchStore(
  source: keyof Root.State | WatchSource<unknown>,
  cb: WatchCallback<unknown>,
  options?: WatchOptions,
) {
  let fn;

  if (typeof source === 'string') {
    fn = () => store.state[source];
  } else {
    fn = () => source(store.state, store.getters);
  }

  return watch(fn, cb, options ?? {});
}

/* eslint-enable @typescript-eslint/no-explicit-any */

function getCachedStore() {
  return (import.meta.hot?.data.store ?? null) as unknown as
    | import('@vuex').Store<Root.State>
    | null;
}
