var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("Transition", { attrs: { appear: "" } }, [
    _setup.display
      ? _c("div", { attrs: { id: "app-cookies-notice" } }, [
          _c(
            "div",
            { staticClass: "notice-content" },
            [
              _c(
                "p",
                [
                  _vm._v(
                    "\n        Notice. Zephyr-Sim.com uses cookies to provide necessary website\n        functionality, improve your experience and analyze our traffic. By\n        using our website, you agree to our\n        "
                  ),
                  _c("RouterLink", { attrs: { to: "/privacy-policy" } }, [
                    _vm._v("Privacy Statement"),
                  ]),
                  _vm._v(" and\n        our "),
                  _c("RouterLink", { attrs: { to: "/cookies" } }, [
                    _vm._v("Cookies Policy"),
                  ]),
                  _vm._v(".\n      "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { variant: "primary" },
                  on: { click: _setup.dismiss },
                },
                [_vm._v("Dismiss")]
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }