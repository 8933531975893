export const MLI = {
  name: {
    common: 'Mali',
    official: 'Republic of Mali',
    native: { fra: { official: 'République du Mali', common: 'Mali' } },
  },
  cities: ['Bamako'],
  tld: ['.ml'],
  cca2: 'ML',
  ccn3: '466',
  cca3: 'MLI',
  cioc: 'MLI',
  currency: ['XOF'],
  callingCode: ['223'],
  capital: 'Bamako',
  altSpellings: ['ML', 'Republic of Mali', 'République du Mali'],
  region: 'Africa',
  subregion: 'Western Africa',
  languages: { fra: 'French' },
  translations: {
    deu: { official: 'Republik Mali', common: 'Mali' },
    fra: { official: 'République du Mali', common: 'Mali' },
    hrv: { official: 'Republika Mali', common: 'Mali' },
    ita: { official: 'Repubblica del Mali', common: 'Mali' },
    jpn: { official: 'マリ共和国', common: 'マリ' },
    nld: { official: 'Republiek Mali', common: 'Mali' },
    por: { official: 'República do Mali', common: 'Mali' },
    rus: { official: 'Республика Мали', common: 'Мали' },
    spa: { official: 'República de Malí', common: 'Mali' },
    fin: { official: 'Malin tasavalta', common: 'Mali' },
  },
  latlng: [17, -4],
  demonym: 'Malian',
  landlocked: true,
  borders: ['DZA', 'BFA', 'GIN', 'CIV', 'MRT', 'NER', 'SEN'],
  area: 1240192,
  provinces: [
    'Gao',
    'Kayes',
    'Kidal',
    'Koulikoro',
    'Mopti',
    'Segou',
    'Sikasso',
    'Tombouctou',
  ],
  nativeName: 'Mali',
  timezones: ['UTC'],
} as const;
