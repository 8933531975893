import { ref, computed, reactive, Ref } from 'vue';

import { Deferred } from '@tools/defer';

import { UibModals } from './uib-modals';
import * as utils from './utils';
import type * as ModalWindow from './ModalWindow.vue';

type ModalOpenedListener = (
  modalInstance: ModalStack.ModalInstance,
  modal: ModalStack.ModalData,
) => void;
type ModalClosedListener = (
  modalInstance: ModalStack.ModalInstance,
  result?: unknown,
) => void;
type ModalDismissedListener = (
  modalInstance: ModalStack.ModalInstance,
  reason?: string,
) => void;

type ModalEventListener =
  | ModalOpenedListener
  | ModalClosedListener
  | ModalDismissedListener;

/**
 * ...
 */
export interface ModalStack {
  entries: ModalStack.StackItem[];
  keys: ModalStack.ModalInstance[];
  length: number;
  top: ModalStack.StackItem | undefined;
  add(key: ModalStack.ModalInstance, value: ModalStack.WindowData): void;
  get(key: ModalStack.ModalInstance): ModalStack.StackItem | undefined;
  remove(key: ModalStack.ModalInstance): ModalStack.StackItem | undefined;
  removeTop(): ModalStack.StackItem | undefined;
  clear(): void;

  emit(
    event: 'open',
    modalInstance: ModalStack.ModalInstance,
    modal: ModalStack.ModalData,
  ): void;
  emit(
    event: 'close',
    modalInstance: ModalStack.ModalInstance,
    result?: unknown,
  ): void;
  emit(
    event: 'dismiss',
    modalInstance: ModalStack.ModalInstance,
    reason?: string,
  ): void;

  on(event: 'open', callback: ModalOpenedListener): void;
  on(event: 'close', callback: ModalClosedListener): void;
  on(event: 'dismiss', callback: ModalDismissedListener): void;

  off(event: 'open', callback: ModalOpenedListener): void;
  off(event: 'close', callback: ModalClosedListener): void;
  off(event: 'dismiss', callback: ModalDismissedListener): void;
}

export namespace ModalStack {
  /** ... */
  interface ComponentData {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    deferred: Deferred<any>;
    renderDeferred: Deferred;
    closedDeferred: Deferred;
    backdrop: boolean | 'static' | undefined;
    keyboard: boolean | undefined;
    openedClass: string | undefined;
    animation: boolean | undefined;
  }

  /** ... */
  export type Component = Vue.Component | Vue.AsyncComponent;

  /** ... */
  export type ComponentLoader =
    import('vue/types/v3-define-async-component').AsyncComponentLoader;

  /** ... */
  export type ComponentValue = Component | ComponentLoader;

  /** ... */
  export interface WindowData extends ComponentData {
    modalId: string;
    modalOpener: HTMLElement;
    modalProps: ModalWindow.Props;
  }

  /** ... */
  export interface ModalData extends ComponentData {
    props: unknown;
    component: ComponentValue;
    backdropClass: string | string[] | null;
    windowClass: string | string[] | undefined;
    ariaLabelledBy: string | undefined;
    ariaDescribedBy: string | undefined;
    size: string | undefined;
  }

  /** ... */
  export type ModalInstance = UibModals.Modal<unknown>;

  /** ... */
  export type StackItem = utils.StackMapItem<ModalInstance, WindowData>;
}

export const stack = useModalStack();

function useModalStack() {
  const entries = ref([]) as Ref<ModalStack.StackItem[]>;

  const keys = computed(() => entries.value.map(({ key }) => key));
  const length = computed(() => entries.value.length);
  const top = computed(() => entries.value.at(-1));

  const add = (key: ModalStack.ModalInstance, value: ModalStack.WindowData) => {
    entries.value = [...entries.value, { key, value }];
  };

  const get = (key: ModalStack.ModalInstance) => {
    return entries.value.find((item) => key === item.key);
  };

  const remove = (key: ModalStack.ModalInstance) => {
    const items = [...entries.value];

    let idx = -1;

    for (let i = 0; i < items.length; i++) {
      if (key === items[i]?.key) {
        idx = i;

        break;
      }
    }

    const [win] = items.splice(idx, 1);

    entries.value = items;

    return win;
  };

  const removeTop = () => {
    const items = [...entries.value];

    const win = items.pop();

    entries.value = items;

    return win;
  };

  const clear = () => {
    entries.value = [];
  };

  //#region Stack Event Management

  const eventListeners = {
    onOpen: [] as ModalOpenedListener[],
    onClose: [] as ModalClosedListener[],
    onDismiss: [] as ModalDismissedListener[],
  };

  const emit = (
    event: string,
    modalInstance: ModalStack.ModalInstance,
    value?: unknown,
  ) => {
    let listeners: ModalEventListener[];

    if (event === 'open') {
      listeners = eventListeners.onOpen;
    } else if (event === 'close') {
      listeners = eventListeners.onClose;
    } else if (event === 'dismiss') {
      listeners = eventListeners.onDismiss;
    } else {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const args: unknown[] = [modalInstance];

    if (value !== undefined) {
      args.push(value);
    }

    for (const listener of listeners) {
      try {
        (listener as (...args: unknown[]) => void)(...args);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    }
  };

  const on = (event: string, callback: ModalEventListener) => {
    let listeners: ModalEventListener[];

    if (event === 'open') {
      listeners = eventListeners.onOpen;
    } else if (event === 'close') {
      listeners = eventListeners.onClose;
    } else if (event === 'dismiss') {
      listeners = eventListeners.onDismiss;
    } else {
      return;
    }

    listeners.push(callback);
  };

  const off = (event: string, callback: ModalEventListener) => {
    let listeners: ModalEventListener[];

    if (event === 'open') {
      listeners = eventListeners.onOpen;
    } else if (event === 'close') {
      listeners = eventListeners.onClose;
    } else if (event === 'dismiss') {
      listeners = eventListeners.onDismiss;
    } else {
      return;
    }

    const i = listeners.findIndex((listener) => listener === callback);

    if (i !== -1) listeners.splice(i, 1);
  };

  //#endregion Stack Event Management

  return reactive({
    entries,
    keys,
    length,
    top,
    add,
    get,
    remove,
    removeTop,
    clear,
    emit,
    on,
    off,
  }) as ModalStack;
}
