import { Getters } from '@vuex';
import { Module, VuexModule, Mutation, Action } from '@vuex/decorators';
import filter from 'lodash/filter';
import find from 'lodash/find';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';

import { api } from '@api';
import { Product } from '@models';
import { alert } from '@services/alert';
import { auth } from '@auth';
// import { moveArrayItem } from '@services/utils';
import { Root } from '@store';
import { isNumber } from '@tools/type-guards';

declare module '@vuex/core' {
  export interface Getters {
    'shop/productList': Shop['productList'];
    'shop/getProduct': Shop['getProduct'];
    'shop/newCustomerProducts': Shop['newCustomerProducts'];
    'shop/addOnProducts': Shop['addOnProducts'];
    'shop/sectorSpecificProducts': Shop['sectorSpecificProducts'];
    'shop/biteSizedProducts': Shop['biteSizedProducts'];
    'shop/certificationProducts': Shop['certificationProducts'];
    'shop/existingUserOfferings': Shop['existingUserOfferings'];
    'shop/shopList': Shop['shopList'];
    'shop/checkoutAddonsList': Shop['checkoutAddonsList'];
  }

  export interface CommitMap {
    'shop/SET_PRODUCTS': Shop['SET_PRODUCTS'];
    'shop/ADD_PRODUCT': Shop['ADD_PRODUCT'];
    'shop/SET_ADDER_QUANTITY': Shop['SET_ADDER_QUANTITY'];
  }

  export interface DispatchMap {
    'shop/load': Shop['load'];
    'shop/increaseAddQuantity': Shop['increaseAddQuantity'];
    'shop/decreaseAddQuantity': Shop['decreaseAddQuantity'];
  }
}

/**
 * ...
 */
type ShopItem = Product & {
  quantity: number;
  owned: boolean;
  recommended: boolean;
};

const NEW_CUSTOMER_PRODUCT_IDS = [
  'FREE-TRIAL',
  'BEGINNER-PACK',
  'ADVANCED-PACK',
];

const ADD_ON_PRODUCT_IDS = [
  'WAREHOUSE',
  'THE-ISLAND',
  'SKIP-FRED',
  'NIST',
  'BRIDGE',
  'kidswithdrones',
  'TOW-INSP',
  'ORQA-CONTROLLER',
];

const SECTOR_SPECIFIC_PRODUCT_IDS = [
  'WAREHOUSE',
  'NIST',
  'TOW-INSP',
  'BRIDGE',
  'SKIP-FRED',
];

const BITE_SIZED_PRODUCT_IDS = [
  'THE-ISLAND',
  'OBSTACLE',
  'BASIC-TRAINING',
  'FLIGHT-PATH-PRACTICE',
  'kidswithdrones',
];

const RECOMMENDED_PRODUCT_IDS = [
  'WAREHOUSE',
  'NIST',
  'THE-ISLAND',
  'BRIDGE',
  'FlySky FS-i6s',
];

/**
 * To filter certifications, Products can be filtered by either:
 * - type = "CERTIFICATION"
 * - certification != null
 */

/**
 * ...
 */
@Module({ namespaced: true })
export class Shop
  extends VuexModule<Shop.State, Root.State>
  implements Shop.State
{
  products: Record<string, ShopItem> = {};

  /** ... */
  get productList() {
    return Object.values(this.products);
  }

  /** ... */
  get shopList() {
    // filter out particular lists
    return filter(this.products, ({ id, behaviors }) => {
      return (
        !NEW_CUSTOMER_PRODUCT_IDS.includes(id) &&
        !BITE_SIZED_PRODUCT_IDS.includes(id) &&
        !behaviors.some(
          (behavior) => behavior.toLowerCase() === 'certification',
        )
      );
    });
  }

  /** ... */
  get getProduct() {
    return (id: Product['id']) => this.products[id] ?? null;
  }

  /** Filtered product list of "new customer" products. */
  get newCustomerProducts() {
    return orderBy(
      filter(this.products, ({ id }) => NEW_CUSTOMER_PRODUCT_IDS.includes(id)),
      'createdAt',
      'desc',
    );
  }

  /** Filtered product list of "add-on" products. */
  get addOnProducts() {
    return orderBy(
      filter(this.products, ({ id }) => ADD_ON_PRODUCT_IDS.includes(id)),
      'createdAt',
      'desc',
    );
  }

  /** Filtered product list of "sector-specific" products. */
  get sectorSpecificProducts() {
    return orderBy(
      filter(this.products, ({ id }) =>
        SECTOR_SPECIFIC_PRODUCT_IDS.includes(id),
      ),
      'createdAt',
      'desc',
    );
  }

  /** Filtered product list of "bite-sized" products. */
  get biteSizedProducts() {
    return orderBy(
      filter(this.products, ({ id }) => BITE_SIZED_PRODUCT_IDS.includes(id)),
      'createdAt',
      'desc',
    );
  }

  /** Filtered product list of certification products. */
  get certificationProducts() {
    return this.productList.filter(({ behaviors }) => {
      return behaviors.some(
        (behavior) => behavior.toLowerCase() === 'certification',
      );
    });
  }

  /** Get list of product offerings for existing user. */
  get existingUserOfferings() {
    const { me } = this.context.rootState;

    if (!me.id) return;

    const ownedScenes = [
      ...new Map(
        me.licenses?.flatMap((l) => {
          return (l.scenes ?? []).map(
            (scene) => [scene.id, scene] as [string, Product.SceneInfo],
          );
        }),
      ).values(),
    ];

    // filter by existing in cart
    let products = this.productList.filter(
      (p) => !(this.context.rootGetters as Getters)['cart/get'](p.id),
    );

    products = orderBy(products, ['recommended', 'createdAt'], ['asc', 'desc']);

    return products.filter(({ owned, scenes }) => {
      if (owned || !scenes?.length) return false;

      return scenes.some((scene) => {
        return ownedScenes.some(({ id }) => {
          return scene.id.toString() === id.toString();
        });
      });
    });
  }

  /** Get list of checkout addons and upsells */
  get checkoutAddonsList() {
    const { cart } = this.context.rootState;

    let items = Object.values(this.products);

    items = items.filter(({ id, checkoutPinned, weight, stock }) => {
      if (isNumber(weight) && isNumber(stock) && stock <= 0) return false;
      return isNumber(checkoutPinned) && !find(cart.items, { id });
    });

    items = sortBy(items, ['checkoutPinned']);

    return items;
  }

  //#region Mutations

  /**
   * ...
   */
  @Mutation
  SET_PRODUCTS(options: Shop.SetProductsMutationOptions) {
    this.products = Object.fromEntries(
      options.products.map((item) => [
        item.id,
        {
          ...item,
          recommended: item.recommended ?? false,
          owned: item.owned ?? false,
        },
      ]),
    );
  }

  /**
   * ...
   */
  @Mutation
  ADD_PRODUCT(options: Shop.AddProductMutationOptions) {
    const id = options.product.id;

    const product: ShopItem = {
      ...options.product,
      recommended: options.product.recommended ?? false,
      owned: options.product.owned ?? false,
    };

    this.products = { ...this.products, [id]: product };
  }

  /**
   * ...
   */
  @Mutation
  SET_ADDER_QUANTITY(options: Shop.SetAdderQuantityMutationOptions) {
    const product = this.products[options.productId];

    if (!product) return;

    product.quantity = options.quantity;

    this.products = { ...this.products, [options.productId]: product };
  }

  //#endregion Mutations

  //#region Actions

  /**
   * Load all available products. If an active user exists, mark any products
   * they've already purchased as `owned`.
   */
  @Action
  async load() {
    const products = await api.products.listLive();

    const shopItems: ShopItem[] = [];

    for (const product of products) {
      // TEMP: Filter out products from shop items that don't currently have
      // prices (from Stripe) declared.
      // if (!product.prices?.[0]) continue;
      // if (!isNaN(parseInt(product.prices?.[0]?.id ?? 'NaN'))) continue;

      const recommended = RECOMMENDED_PRODUCT_IDS.includes(product.id);
      const owned = false;
      const quantity = 1;

      shopItems.push({
        ...product,
        recommended,
        owned,
        quantity,
      });
    }

    const ownedProductIds = await getOwnedProductIds();

    const verifyItemOwnership: (
      itemRef: string | Product,
    ) => Promise<boolean> = async (itemRef) => {
      let item: Product | null = null;

      if (typeof itemRef === 'string') {
        item = await api.products.get({ productId: itemRef });
      } else {
        item = itemRef;
      }

      if (!item) return false;

      if (item.behaviors.includes('bundle') && item.bundle) {
        const promises = item.bundle.map(verifyItemOwnership);

        return (await Promise.all(promises)).every((result) => result);
      }

      return ownedProductIds.includes(item.id);
    };

    for (const item of shopItems) {
      item.owned = await verifyItemOwnership(item);
    }

    this.context.commit('SET_PRODUCTS', { products: shopItems });

    return shopItems;
  }

  /**
   * ...
   */
  @Action
  increaseAddQuantity(options: Shop.IncreaseAddQuantityActionOptions) {
    // ...
    const product = this.products[options.productId];

    if (!product) {
      /* eslint-disable-next-line no-console */
      return console.warn(
        `[shop.increaseQuantity] product with ID "${options.productId}" could not be found.`,
      );
    }

    // ...
    const item = (this.context.rootGetters as Getters)['cart/get'](
      options.productId,
    );

    let quantity = -1;

    if (!item) {
      quantity = Math.min(product.quantity + 1, product.limit);
    } else if (
      product.limit === 0 ||
      item.quantity + product.quantity < product.limit
    ) {
      quantity = product.quantity + 1;
    }

    if (quantity < 0) {
      return alert.warning(`Per Cart Item Limit Reached: ${product.limit}`);
    }

    this.context.commit('SET_ADDER_QUANTITY', {
      productId: options.productId,
      quantity,
    });
  }

  /**
   * ...
   */
  @Action
  decreaseAddQuantity(options: Shop.DecreaseAddQuantityActionOptions) {
    // ...
    const product = this.products[options.productId];

    if (!product) {
      /* eslint-disable-next-line no-console */
      return console.warn(
        `[shop.increaseQuantity] product with ID "${options.productId}" could not be found.`,
      );
    }

    const quantity = Math.max(1, product.quantity - 1);

    this.context.commit('SET_ADDER_QUANTITY', {
      productId: options.productId,
      quantity,
    });
  }

  //#endregion Actions
}

export namespace Shop {
  /** ... */
  export type Item = ShopItem;

  export interface State {
    products: Record<string, ShopItem>;
  }

  export interface SetProductsMutationOptions {
    products: ShopItem[];
  }

  export interface AddProductMutationOptions {
    product: ShopItem;
  }

  export interface SetAdderQuantityMutationOptions {
    productId: ShopItem['id'];
    quantity: number;
  }

  export interface IncreaseAddQuantityActionOptions {
    productId: ShopItem['id'];
  }

  export interface DecreaseAddQuantityActionOptions {
    productId: ShopItem['id'];
  }
}

export default Shop;

//#region Helper Functions

async function getOwnedProductIds() {
  const productIds: string[] = [];

  const user = await auth.getUser();

  if (user?.id) {
    for (const { productId } of user.licenses ?? []) {
      productIds.push(productId);
    }

    const certifications = await api.certifications.listForUser({
      userId: user.id,
    });

    for (const { product } of certifications ?? []) {
      productIds.push(product.id);
    }
  }

  return productIds;
}

//#endregion Helper Functions
