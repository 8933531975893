export const VCT = {
  name: {
    common: 'Saint Vincent and the Grenadines',
    official: 'Saint Vincent and the Grenadines',
    native: {
      eng: {
        official: 'Saint Vincent and the Grenadines',
        common: 'Saint Vincent and the Grenadines',
      },
    },
  },
  cities: ['Kingstown', 'Georgetown', 'Union'],
  tld: ['.vc'],
  cca2: 'VC',
  ccn3: '670',
  cca3: 'VCT',
  cioc: 'VIN',
  currency: ['XCD'],
  callingCode: ['1784'],
  capital: 'Kingstown',
  altSpellings: ['VC'],
  region: 'Americas',
  subregion: 'Caribbean',
  languages: { eng: 'English' },
  translations: {
    deu: {
      official: 'St. Vincent und die Grenadinen',
      common: 'Saint Vincent und die Grenadinen',
    },
    fra: {
      official: 'Saint-Vincent-et-les Grenadines',
      common: 'Saint-Vincent-et-les-Grenadines',
    },
    hrv: {
      official: 'Sveti Vincent i Grenadini',
      common: 'Sveti Vincent i Grenadini',
    },
    ita: {
      official: 'Saint Vincent e Grenadine',
      common: 'Saint Vincent e Grenadine',
    },
    jpn: {
      official: 'セントビンセントおよびグレナディーン諸島',
      common: 'セントビンセントおよびグレナディーン諸島',
    },
    nld: {
      official: 'Saint Vincent en de Grenadines',
      common: 'Saint Vincent en de Grenadines',
    },
    por: {
      official: 'São Vicente e Granadinas',
      common: 'São Vincente e Granadinas',
    },
    rus: {
      official: 'Сент-Винсент и Гренадины',
      common: 'Сент-Винсент и Гренадины',
    },
    spa: {
      official: 'San Vicente y las Granadinas',
      common: 'San Vicente y Granadinas',
    },
    fin: {
      official: 'Saint Vincent ja Grenadiinit',
      common: 'Saint Vincent ja Grenadiinit',
    },
  },
  latlng: [13.25, -61.2],
  demonym: 'Saint Vincentian',
  landlocked: false,
  borders: [],
  area: 389,
} as const;
