import type { Organization } from '@models';

type BillingTypeOption = ZephyrWeb.OptionItem<Organization.BillingType>;

/**
 * Billing type selection options.
 */
export const billingTypeOptions: BillingTypeOption[] = [
  { text: 'Point of Transaction', value: 'POT' },
  { text: 'Billed', value: 'BillMeLater' },
  { text: 'Student Pays', value: 'StudentPays' },
  { text: 'Site License', value: 'SiteLicense' },
  // { text: 'Reseller', value: 'Reseller' },
];
