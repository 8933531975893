// export const jrotcInstIds: number[] = [
//   482, 481, 480, 479, 478, 477, 476, 475, 474, 473, 472, 471, 470, 469, 468,
//   467, 466, 465, 464, 463, 462, 461, 460, 459, 458, 457, 456, 455, 454, 453,
//   452, 451, 450, 449, 448, 447, 446, 445, 444, 443, 442, 441, 440, 439, 438,
//   437, 436, 435, 434, 433, 432, 431, 430, 429, 428, 427, 426, 425,
// ];

export const jrotcInstIds: string[] = [
  '355882343494',
  '071057532392',
  '546047283924',
  '212852025557',
  '015949888756',
  '839094428578',
  '673295909543',
  '204814279572',
  '450826253842',
  '914592701955',
  '813181605027',
  '798883066041',
  '876918770759',
  '329775540750',
  '459291970582',
  '748747901548',
  '257836014349',
  '183359413856',
  '647245170674',
  '529566191695',
  '649144997782',
  '201604095878',
  '332304314626',
  '753832351503',
  '582223311933',
  '164758525355',
  '328691381045',
  '654208838477',
  '437311204310',
  '324654460546',
  '830606803335',
  '754848568006',
  '609778570235',
  '853287602860',
  '640330746667',
  '038171499786',
  '962588588296',
  '947409776137',
  '392416124470',
  '297003591049',
  '147692871731',
  '525982306873',
  '840244583534',
  '377553351587',
  '863216964855',
  '340068305806',
  '600686608053',
  '552406352839',
  '917705900838',
  '650397248763',
  '114768707616',
  '655699092014',
  '569344211790',
  '081886783031',
  '746274159785',
  '755203157540',
  '166710440398',
];

export default jrotcInstIds;
