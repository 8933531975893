var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    _vm._b(
      { class: ["zephyr-logo", { animate: _vm.animate }] },
      "div",
      { style: _setup.style },
      false
    ),
    [
      _c(
        "svg",
        {
          style: _setup.svgStyle,
          attrs: {
            id: "logo",
            version: "1.1",
            x: "0px",
            y: "0px",
            viewBox: "0 0 295.4 80.8",
            "xml:space": "preserve",
          },
        },
        [
          _c(
            "defs",
            [
              _c(
                "linearGradient",
                {
                  attrs: {
                    id: _setup.gradientId,
                    x1: "0%",
                    y1: "0%",
                    x2: "0%",
                    y2: "100%",
                    gradientUnits: "userSpaceOnUse",
                  },
                },
                _vm._l(_setup.gradient, function ({ key, color, offset }) {
                  return _c("stop", {
                    key: key,
                    attrs: { offset: `${offset}%`, "stop-color": color },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "g",
            { attrs: { id: "logo-body", fill: `url(#${_setup.gradientId})` } },
            [
              _c("g", { staticClass: "logo-text" }, [
                _c("path", {
                  attrs: {
                    id: "logo-r",
                    d: "M273.4,32.6c0.3-1.2,1-2.3,1.9-3.1c1.1-0.9,2.4-1.4,3.8-1.7c2.1-0.4,4.2-0.6,6.3-0.5h5.4l-2,4h-3.1\n  c-1.3,0-2.7,0.1-4,0.2c-0.9,0.1-1.7,0.4-2.5,0.9c-0.6,0.5-1.1,1.1-1.2,1.9c-0.3,1-0.4,2.1-0.3,3.1v20.4h-4.8V37.5\n  C272.9,35.9,273.1,34.2,273.4,32.6z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    id: "logo-y",
                    d: "M264,27.3h5.7l-11.4,32.3c-0.5,1.5-1.1,3-1.6,4.3c-0.5,1.2-1.2,2.4-2,3.4s-1.7,1.7-2.9,2.3\n  c-1.4,0.6-2.9,0.9-4.4,0.8v-3.7c1,0,2-0.3,2.8-0.9c0.7-0.6,1.3-1.4,1.8-2.3c0.5-0.9,0.8-1.9,1.1-2.9s0.5-2,0.7-2.9l-10.6-30.4h5.8\n  l7.3,24.2l2.7-9.2L264,27.3z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    id: "logo-h",
                    d: "M240.8,57.9H236v-21c0-0.9-0.1-1.9-0.4-2.8c-0.3-0.8-0.7-1.4-1.4-1.9c-0.8-0.6-1.7-1-2.6-1.1\n  c-1.4-0.3-2.7-0.4-4.1-0.4h-4.3v27.2h-4.8v-43h4.8v11.8h4.4c2.2,0,4.3,0.2,6.4,0.6c1.5,0.3,2.9,1,4.1,1.9c1,0.8,1.7,1.9,2.1,3.1\n  c0.4,1.4,0.6,2.9,0.6,4.4V57.9z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    id: "logo-p",
                    d: "M194.2,28.5c3-1.1,6.1-1.7,9.3-1.8c3.3,0,5.9,0.9,7.8,2.6c1.8,1.8,2.8,4.4,2.8,7.8v8.3c0,1.7-0.1,3.4-0.3,5.2\n  c-0.2,1.5-0.7,2.9-1.5,4.1c-0.8,1.2-2,2.2-3.4,2.7c-1.9,0.7-4,1.1-6.1,1c-1.2,0-2.5-0.1-3.7-0.3c-1-0.2-1.9-0.5-2.8-0.9v13h-4.8\n  V29.5L194.2,28.5z M202.7,54.5c1.3,0.1,2.5-0.2,3.7-0.7c0.8-0.4,1.5-1.1,1.9-1.9C208.7,51,209,50,209,49c0.1-1.1,0.1-2.3,0.1-3.6\n  v-8.2c0.1-1.8-0.5-3.5-1.6-4.9c-1.1-1.2-2.7-1.9-5-1.9c-2.1,0.1-4.2,0.5-6.2,1.2v15.5c0,1,0.1,1.9,0.2,2.9c0.1,0.8,0.4,1.6,0.8,2.3\n  c0.5,0.7,1.2,1.3,2,1.6C200.4,54.3,201.6,54.5,202.7,54.5L202.7,54.5z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    id: "logo-e",
                    d: "M169.8,43.8v1.5c0,1.3,0,2.5,0.1,3.6c0.1,1,0.3,2,0.7,2.8c0.4,0.9,1,1.6,1.9,2c1.2,0.5,2.5,0.7,3.7,0.7\n  c2.4,0,4.1-0.5,5-1.5c1-1.2,1.5-2.7,1.4-4.2h1h1.3c0.4,0,0.9,0,1.4,0h1.2c0,1.3-0.2,2.7-0.5,4c-0.3,1.2-0.9,2.2-1.8,3.1\n  c-1,0.9-2.2,1.6-3.4,2c-1.8,0.5-3.6,0.8-5.5,0.7c-2.1,0.1-4.1-0.3-6.1-1c-1.4-0.6-2.5-1.5-3.4-2.7c-0.8-1.2-1.3-2.6-1.5-4.1\n  c-0.2-1.7-0.3-3.5-0.3-5.2v-5.7c0-1.7,0.1-3.5,0.3-5.2c0.2-1.5,0.7-2.9,1.5-4.2c0.8-1.2,2-2.2,3.4-2.8c1.9-0.7,4-1.1,6.1-1\n  c2-0.1,3.9,0.3,5.8,1c1.4,0.6,2.6,1.5,3.4,2.7c0.8,1.3,1.4,2.7,1.6,4.2c0.3,1.8,0.4,3.6,0.4,5.5v3.8\n  C187.5,43.8,169.8,43.8,169.8,43.8z M176.2,30.6c-1.3-0.1-2.6,0.2-3.7,0.7c-0.8,0.4-1.5,1.1-1.9,1.9c-0.4,0.9-0.7,1.8-0.7,2.8\n  c-0.1,1.1-0.1,2.2-0.1,3.4V40h12.9c0-1.4-0.1-2.8-0.2-4.1c-0.1-1-0.4-2-0.9-2.9c-0.4-0.8-1.1-1.4-1.9-1.7\n  C178.6,30.8,177.4,30.6,176.2,30.6L176.2,30.6z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    id: "logo-z",
                    d: "M152.5,14.9h3.9c1.2-0.1,2.5,0.3,3.5,1c0.9,0.7,1.3,1.8,1.3,2.9c0,1-0.3,2.1-0.7,3c-0.6,1.3-1.3,2.6-2.2,3.8\n  L139,52.5h20.5l2.2,5.4h-23.9c-1.2,0.1-2.3-0.4-3.1-1.2c-0.8-0.9-1.1-2-1.1-3.2c0-1.1,0.2-2.1,0.6-3.1c0.5-1.2,1.2-2.4,2.1-3.4\n  l18.9-26.6h-19.5l-2.2-5.4h19V14.9z",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("g", { staticClass: "logo-symbol" }, [
                _c("path", {
                  attrs: {
                    id: "logo-base",
                    d: "M66.7,10.7c-17.6,0-31.9,14.3-31.9,31.9s14.3,31.9,31.9,31.9s31.9-14.3,31.9-31.9l0,0\n  C98.6,25,84.3,10.7,66.7,10.7L66.7,10.7z M75.9,46.7L75.9,46.7c0.3-0.1,0.5-0.2,0.8-0.2l0.2-0.1l0.6-0.2l0.3-0.1\n  c0.2,0,0.4-0.1,0.6-0.1h0.5h0.3h0.4c5.1,0,9.3,4.2,9.3,9.3c0,3.1-1.5,5.9-4.1,7.7c-1.5,1-3.3,1.6-5.2,1.6c-5.1,0-9.3-4.2-9.3-9.3\n  c0-0.8,0.1-1.5,0.3-2.2V53c0.1-0.3,0.2-0.6,0.3-0.8c0-0.1,0-0.1,0.1-0.2c0-0.1,0.1-0.2,0.1-0.3c-1.6-1.2-3.5-2.3-4.1-2.3l0,0\n  c-0.6,0-2.4,1.1-3.8,2.3c1.7,4.1,0.3,8.8-3.3,11.3c-4.3,2.9-10,1.8-12.9-2.5s-1.8-10,2.5-12.9c1.5-1,3.4-1.6,5.2-1.6H55h0.2\n  c0.6,0,1.1,0.1,1.6,0.3l0,0c0.5,0.1,1,0.3,1.4,0.5c1.2-1.6,2.3-3.5,2.3-4.1l0,0c0-0.6-1.1-2.4-2.3-3.9C53.5,40.9,48,38.7,45.9,34\n  s0.1-10.2,4.8-12.3S61,21.6,63,26.4c1,2.4,1,5.1,0,7.4c1.5,1.2,3.2,2.3,3.8,2.3l0,0c0.7,0,2.5-1.1,4.1-2.3v-0.2l-0.1-0.3\n  c-0.1-0.2-0.2-0.5-0.2-0.7l-0.1-0.2c-0.2-0.7-0.3-1.5-0.3-2.2c0-5.1,4.2-9.3,9.3-9.3c5.1,0,9.3,4.2,9.3,9.3s-4.2,9.3-9.3,9.3\n  c-0.7,0-1.5-0.1-2.2-0.3l-0.2-0.1l-0.7-0.2l-0.3-0.1l-0.2-0.1c-1.2,1.5-2.3,3.2-2.3,3.9l0,0C73.6,43.2,74.7,45.1,75.9,46.7\n  L75.9,46.7z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    id: "logo-rotor-br",
                    d: "M79.6,62.6c4.1,0,7.5-3.3,7.5-7.5s-3.3-7.5-7.5-7.5c-4.1,0-7.5,3.3-7.5,7.5l0,0\n  C72.1,59.3,75.4,62.6,79.6,62.6C79.5,62.6,79.6,62.6,79.6,62.6z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    id: "logo-rotor-bl",
                    d: "M54.5,47.7c-4.1,0-7.4,3.4-7.4,7.5s3.4,7.4,7.5,7.4s7.4-3.4,7.4-7.5S58.7,47.7,54.5,47.7\n  C54.6,47.7,54.5,47.7,54.5,47.7z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    id: "logo-rotor-tr",
                    d: "M79.6,37.6c4.1,0,7.4-3.3,7.4-7.4s-3.3-7.4-7.4-7.4s-7.4,3.3-7.4,7.4c0,2,0.8,3.9,2.2,5.3\n  C75.7,36.8,77.6,37.6,79.6,37.6z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    id: "logo-rotor-tl",
                    d: "M59.8,24.8c-2.9-2.9-7.6-2.9-10.5,0.1s-2.9,7.6,0.1,10.5s7.6,2.9,10.5-0.1\n  c1.4-1.4,2.2-3.3,2.2-5.3C62,28.1,61.2,26.2,59.8,24.8z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    id: "logo-drone-body",
                    d: "M74.3,37.7c-0.3-0.2-0.5-0.4-0.8-0.6L73.4,37l-0.2-0.2L73,36.7l0,0c-0.4-0.4-0.7-0.8-1-1.2\n  c-1.4,1-3.7,2.5-5,2.5l0,0c-1.3,0-3.4-1.5-4.7-2.5c-0.6,0.9-1.4,1.7-2.3,2.3c1,1.3,2.5,3.4,2.5,4.8l0,0c0,1.4-1.5,3.7-2.5,5\n  c0.9,0.6,1.7,1.4,2.3,2.3c1.3-1,3.4-2.5,4.7-2.5l0,0c1.4,0,3.7,1.5,5,2.5c0.6-0.9,1.4-1.7,2.3-2.3c-1-1.4-2.5-3.7-2.5-5l0,0\n  C71.8,41.2,73.3,39,74.3,37.7z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    id: "logo-outer-ring",
                    d: "M66.7,78.4c19.8,0,35.9-16.1,35.9-35.9S86.5,6.6,66.7,6.6S30.8,22.7,30.8,42.5l0,0\n  C30.9,62.4,46.9,78.4,66.7,78.4z M66.7,8.4c18.9,0,34.2,15.3,34.2,34.2S85.6,76.8,66.7,76.8S32.5,61.5,32.5,42.6l0,0\n  C32.5,23.7,47.8,8.4,66.7,8.4z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    id: "logo-right-wing",
                    d: "M133.4,0l-30.9,18.4l0,0l-3.9,2.4l0.5,0.8l0.6,1l0.7,1.2c0.5,0.9,1.1,2.1,1.7,3.5c0.3,0.7,0.6,1.4,0.9,2.2\n  s0.6,1.6,0.8,2.5c0.5,1.9,0.9,3.9,1.1,5.9c1.3,12.4-3.4,24.6-12.7,32.9c-1.7,1.6-3.6,2.9-5.6,4.2c-1.8,1.1-3.6,2-5.6,2.9\n  c-2.9,1.2-5.9,2.1-9,2.6c-1.1,0.2-2,0.3-2.6,0.3l-0.9,0.1h0.9c0.6,0,1.5-0.1,2.7-0.2c13.1-1.4,24.7-9.1,31.1-20.6\n  c2.3-4.1,3.8-8.5,4.6-13.2h5.9l3.5-8.4h-8.8c0-0.3,0-0.5,0-0.8c0-0.5-0.1-1-0.1-1.4l10.4-1.3l5.4-12.8l-17.3,6.3c0-0.1,0-0.1,0-0.2\n  c-0.2-0.8-0.5-1.6-0.8-2.3l20-8.5L133.4,0z",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    id: "logo-left-wing",
                    d: "M0,0l30.9,18.4l0,0l4,2.4l-0.5,0.8l-0.6,1L33,23.7c-0.6,1.1-1.2,2.3-1.7,3.5c-0.3,0.7-0.6,1.4-0.9,2.2\n  s-0.6,1.6-0.8,2.5c-0.5,1.9-0.9,3.9-1.1,5.9c-0.2,2.2-0.3,4.5-0.1,6.8c0.7,10,5.3,19.4,12.8,26.1c1.7,1.6,3.6,2.9,5.6,4.2\n  c1.8,1.1,3.6,2,5.5,2.9c2.9,1.2,5.9,2.1,9,2.6c1.1,0.2,2,0.3,2.6,0.3l0.9,0.1h-0.9c-0.6,0-1.5-0.1-2.7-0.2\n  c-5.3-0.5-10.4-2.1-15.1-4.7c-2.1-1.1-4.1-2.5-6-4c-2-1.6-3.9-3.4-5.5-5.4c-4.8-5.6-7.9-12.4-9.2-19.7h-5.9L16,38.4h9\n  c0-0.3,0-0.5,0-0.8c0-0.5,0.1-1,0.1-1.4l-10.5-1.3L9.3,22.1l17.3,6.3c0-0.1,0-0.1,0-0.2c0.3-0.8,0.5-1.6,0.8-2.3l-20-8.5L0,0z",
                  },
                }),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }