var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.Overlay,
    _vm._b({ staticClass: "overlay-loading" }, "Overlay", _vm.$props, false),
    [
      _c(_setup.Spinner),
      _vm._v(" "),
      _vm.message
        ? _c("span", { staticClass: "ml-4" }, [_vm._v(_vm._s(_vm.message))])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }