var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "modal-root" },
    [
      _setup.backdropProps
        ? _c(
            _setup.ModalBackdrop,
            _vm._b(
              { ref: "backdrop" },
              "ModalBackdrop",
              _setup.backdropProps,
              false
            )
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_setup.openWindows, function ({ modalId, modalProps }) {
        return _c(
          _setup.ModalWindow,
          _vm._b(
            { key: modalId, ref: "modalWindows", refInFor: true },
            "ModalWindow",
            modalProps,
            false
          )
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }