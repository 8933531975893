import { kebabCase } from 'lodash';

/** ... */
export type StyledLog = [string, string | Record<string, string>];

/**
 * ...
 *
 * @param style ...
 * @return ...
 */
function stringifyStyles(style: Record<string, string>) {
  return Object.keys(style)
    .map((key) => `${kebabCase(key)}: ${style[key]};`)
    .join('');
}

/**
 * ...
 *
 * @return ...
 */
function createStyledLog(...args: Array<string | StyledLog>) {
  let str = '';

  const colors: string[] = [];

  for (const arg of args) {
    let [text, style] = Array.isArray(arg) ? arg : [arg, { color: '#e4e4e4' }];

    str += `%c${text}`;

    style = typeof style === 'string' ? { color: style } : style;

    colors.push(stringifyStyles(style));
  }

  return [str, ...colors];
}

class StyledLogger {
  /**
   * ...
   *
   * @param args ...
   */
  log(...args: Array<string | StyledLog>) {
    /* eslint-disable-next-line no-console */
    console.log(...createStyledLog(...args));
  }

  /**
   * ...
   *
   * @param args ...
   */
  group(...args: Array<string | StyledLog>) {
    /* eslint-disable-next-line no-console */
    console.groupCollapsed(...createStyledLog(...args));
  }

  /**
   * ...
   */
  groupEnd() {
    /* eslint-disable-next-line no-console */
    console.groupEnd();
  }
}

/**
 * ...
 */
export const logger = new StyledLogger();
