export const REU = {
  name: {
    common: 'Réunion',
    official: 'Réunion Island',
    native: { fra: { official: 'Ile de la Réunion', common: 'La Réunion' } },
  },
  cities: [
    'Saint-Joseph',
    'Le Tampon',
    'Saint-Andre',
    'Saint-Piorre',
    'Saint-Paul',
    'La Possession',
    'Le Port',
    'Le Petit Tampon',
    'Sainte-Suzanne',
    'Ravine des Cabris',
    'Saint-Gilles-les Bains',
    'Sainte-Clotilde',
    'Saint-Denis',
    'Sainte-Marie',
    'Petite-Ile',
    'La Riviere',
    'Les Avirons',
    'La Saline',
    'Etang-Sale les Bains',
    'Saint-Louis',
    'La Chaloupe Saint-Leu',
    'Entre-Deux',
    'Piton',
    'Saint-Benoit',
  ],
  tld: ['.re'],
  cca2: 'RE',
  ccn3: '638',
  cca3: 'REU',
  cioc: '',
  currency: ['EUR'],
  callingCode: ['262'],
  capital: 'Saint-Denis',
  altSpellings: ['RE', 'Reunion'],
  region: 'Africa',
  subregion: 'Eastern Africa',
  languages: { fra: 'French' },
  translations: {
    deu: { official: 'Réunion', common: 'Réunion' },
    fra: { official: 'Ile de la Réunion', common: 'Réunion' },
    hrv: { official: 'Réunion Island', common: 'Réunion' },
    ita: { official: 'Réunion', common: 'Riunione' },
    jpn: { official: 'レユニオン島', common: 'レユニオン' },
    nld: { official: 'Réunion', common: 'Réunion' },
    por: { official: 'Ilha da Reunião', common: 'Reunião' },
    rus: { official: 'Реюньон', common: 'Реюньон' },
    spa: { official: 'Isla de la Reunión', common: 'Reunión' },
    fin: { official: 'Réunion', common: 'Réunion' },
  },
  latlng: [-21.15, 55.5],
  demonym: 'French',
  landlocked: false,
  borders: [],
  area: 2511,
} as const;
