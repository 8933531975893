import { DLC } from '@models';

import { openModal } from '../open-modal';

import { store } from '@store';

/**
 * ...
 */
export interface ManageDLCModalOptions {
  dlcId?: DLC['id'];
  dlcName?: DLC['name'];
  osSelection: 'win' | 'mac';
}
/**
 * Open ManageDLC modal
 *
 * @return Modal promise;
 */
export async function manage(options: ManageDLCModalOptions) {
  const dlc = {
    id: options.dlcId ? options.dlcId : '',
    name: options.dlcName ? options.dlcName : '',
    mac: [],
    win: [],
  };

  if (options.dlcId) {
    const dlcSubmissions = await store.dispatch('dlc/get', options);

    dlc.mac = dlcSubmissions.mac;
    dlc.win = dlcSubmissions.win;
  }

  const modalOptions = {
    title: options.dlcId ? `UPDATE` : `CREATE` + `DLC`,
    props: {
      dlc,
      osSelection: options.osSelection,
    },
    size: 'xl',
    component: () => import('./ManageDLC.vue'),
  };

  await openModal.safe(modalOptions);
}
