import { server } from '@api/request';
import { Module, Scene } from '@models';
import { isObject } from '@tools/type-guards';

/**
 * List all existing modules.
 *
 * @returns A list of modules.
 */
export async function list() {
  return await server.list('v2/admin/modules', processModuleData);
}

/**
 * List all active modules.
 *
 * @returns A list of modules.
 */
export async function listActive() {
  return await server.list('v2/modules/active', processModuleData);
}

/**
 * ...
 */
export interface GetOptions {
  moduleId: Module['id'];
}

/**
 * Get a specified module.
 *
 * @param options Request options bag.
 * @returns The specified module.
 */
export async function get(options: GetOptions) {
  return await server.get(`v2/modules/${options.moduleId}`, processModuleData);
}

/**
 * ...
 */
export interface CreateOptions {
  id: Module['id'];
  sceneId: Scene['id'];
  name: string;
  description: string;
  maxXP: number;
  possibleScore: number;
}

/**
 * Create a module.
 *
 * @param options Request options bag.
 */
export async function create(options: CreateOptions) {
  return await server.post(`v2/admin/modules`, options, processModuleData);
}

/**
 * ...
 */
export interface UpdateOptions {
  moduleId: Module['id'];
  possibleScore?: Module['possibleScore'];
  sceneId?: Scene['id'];
  name?: string;
  description?: string;
  maxXP?: number;
}

/**
 * Update a specified module.
 *
 * @param options Request options bag.
 */
export async function update(options: UpdateOptions) {
  const { moduleId, ...data } = options;

  return await server.post(
    `v2/admin/modules/${moduleId}`,
    data,
    processModuleData,
  );
}

/**
 * ...
 */
export interface DeleteOptions {
  moduleId: Module['id'];
}

/**
 * Delete a specified module.
 *
 * @param options Request options bag.
 */
export async function del(options: DeleteOptions) {
  await server.delete(`v2/admin/modules/${options.moduleId}`);
}

// region Helper Functions

/**
 * ...
 *
 * @param value ...
 * @returns ...
 */
function isValidModuleData(value: unknown): value is Module {
  if (!isObject(value)) return false;

  return true;
}

/**
 * ...
 *
 * @param data ...
 * @returns ...
 */
function processModuleData(data: unknown) {
  if (!isValidModuleData(data)) {
    throw new Error('Invalid module data item.');
  }

  return { ...data } as Module;
}

// endregion Helper Functions
