export const ZAF = {
  name: {
    common: 'South Africa',
    official: 'Republic of South Africa',
    native: {
      afr: { official: 'Republiek van Suid-Afrika', common: 'South Africa' },
      eng: { official: 'Republic of South Africa', common: 'South Africa' },
      nbl: { official: 'IRiphabliki yeSewula Afrika', common: 'Sewula Afrika' },
      nso: { official: 'Rephaboliki ya Afrika-Borwa ', common: 'Afrika-Borwa' },
      sot: { official: 'Rephaboliki ya Afrika Borwa', common: 'Afrika Borwa' },
      ssw: {
        official: 'IRiphabhulikhi yeNingizimu Afrika',
        common: 'Ningizimu Afrika',
      },
      tsn: {
        official: 'Rephaboliki ya Aforika Borwa',
        common: 'Aforika Borwa',
      },
      tso: { official: 'Riphabliki ra Afrika Dzonga', common: 'Afrika Dzonga' },
      ven: {
        official: 'Riphabuḽiki ya Afurika Tshipembe',
        common: 'Afurika Tshipembe',
      },
      xho: {
        official: 'IRiphabliki yaseMzantsi Afrika',
        common: 'Mzantsi Afrika',
      },
      zul: {
        official: 'IRiphabliki yaseNingizimu Afrika',
        common: 'Ningizimu Afrika',
      },
    },
  },
  cities: [
    'Johannesburg',
    'Sandton',
    'Midrand',
    'Pretoria',
    'Malmesbury',
    'Kempton Park',
    'Despatch',
    'Durban',
    'Port Elizabeth',
    'Meyerton',
    'Bloemfontein',
    'Delmas',
    'Benoni',
    'Centurion',
    'Newcastle',
    'Cape Town',
    'Stellenbosch',
    'Mthatha',
    'Gansbaai',
    'Bellville',
    'Paarl',
    'Panorama',
    'George',
    'Wellington',
    'Winterton',
    'Strand',
    'East London',
    'Somerset West',
    'Hartenbos',
    'Sun Valley',
    'Kraaifontein',
    'Port Alfred',
    'Sedgefield',
    'London',
    'Pietermaritzburg',
    'Margate',
    'Kloof',
    'Greytown',
    'Krugersdorp',
    'Westonaria',
    'Pongola',
    'Boksburg',
    'Scottburgh',
    'Port Shepstone',
    'Germiston',
    'Lephalale',
    'Groblersdal',
    'Randburg',
    'Roodepoort',
    'Kimberley',
    'Edenvale',
    'Carletonville',
    'Brits',
    'Rivonia',
    'Springs',
    'Heidelberg',
    'Vanderbijlpark',
    'Randfontein',
    'Vereeniging',
    'Sasolburg',
    'Brakpan',
    'Isando',
    'Florida Glen',
    'Alberton',
    'Olifantsfontein',
    'Walkerville',
    'Franschhoek',
    'Rustenburg',
    'Lombardy East',
    'Sebokeng',
    'Morningside Manor',
    'Bethal',
    'Amanzimtoti',
    'Lyttelton',
    'Witbank',
    'Sinoville',
    'Nigel',
    'Hartbeespoort',
    'Tokai',
    'Fochville',
    'Witpoortjie',
    'Polokwane',
    'Klerksdorp',
    'Nelspruit',
    'Pinelands',
    'Uitenhage',
    'Hermanus',
    'Kleinmond',
    'Mooi River',
    'Mookgophong',
    'Louis Trichardt',
    'Upington',
    'Dube Location',
    'Saint James',
    'Walmer Heights',
    'Kingsley',
    'Durbanville',
    'Pinetown',
    'Middelburg',
    'Vrededorp',
    'Brackenfell',
    'Ermelo',
    'Kranskop',
    'Richards Bay',
    'Oranjezicht',
    'Elarduspark',
    'Bronkhorstspruit',
    'Soshanguve',
    'Tongaat',
    'Katlehong',
    'Cullinan',
    'Bedfordview',
    'Weltevreedenpark',
    'Modderfontein',
    'Ogies',
    'Hekpoort',
    'Potchefstroom',
    'Hoedspruit',
    'Kingsmead',
    'Himeville',
    'Vryheid',
    'Emnambithi-Ladysmith',
    'Bethlehem',
    'Hluhluwe',
    'Kroonstad',
    'Philipstown',
    'Oudtshoorn',
    'Rawsonville',
    'Pringle Bay',
    'Villiersdorp',
    'Rooigrond',
    'Stilfontein',
    'Hartswater',
    'Bryanston',
    'Eloff',
    'Coega',
    'Waverley',
    'Beaufort West',
    'White River',
    'Secunda',
    'Duiwelskloof',
    'Newlands',
    'Alrode',
    'Umdloti',
    'Lynnwood',
    'Tembisa',
    'Northcliff',
    'Hilton',
    'Hout Bay',
    'Queensburgh',
    'Botshabelo',
    'Salt Rock',
    'Warner Beach',
    'Stanford',
    'KwaDukuza',
    'Worcester',
    'Shelly Beach',
    'Melkbosstrand',
    'Rosettenville',
    'Umhlanga',
    'Welkom',
    'De Aar',
    'Knysna',
    'Ixopo',
    'Lyndhurst',
    'Riebeek-Kasteel',
    'Lutzville',
    'Mafikeng',
    'Richmond',
    'Virginia',
    'Odendaalsrus',
    'Bredasdorp',
    'Plettenberg Bay',
    'New Germany',
    'Ottosdal',
    'Ladybrand',
    'Constantia',
    'Hazyview',
    'Lichtenburg',
    'Maidstone',
    'Hillcrest',
    'Montagu',
    'Howick',
    'Dullstroom',
    'Phalaborwa',
    'Welkom',
    'Senekal',
    'Verulam',
    'Standerton',
    'Umkomaas',
    'Eshowe',
    'Tzaneen',
    'Onverwacht',
    'Kriel',
    'Napier',
    'Meadowridge',
    'Empangeni',
    'Humansdorp',
    'Soweto',
    'Phoenix',
    'Atlantis',
    'Mossel Bay',
    'Welgemoed',
    'City of Cape Town',
    'Buffalo',
    'Merrivale',
    'Vredenburg',
    'Evander',
    'Umtentweni',
    'Vaalpark',
    'Three Rivers',
    'Hawston',
    'Parow',
    'Houghton Estate',
    'Muizenberg',
    "Gordon's Bay",
    'Claremont',
    'Hennenman',
    'Kuils River',
    'Overport',
    'Milnerton',
    'Deneysville',
    'Kinross',
    'Lydenburg',
    'Temba',
    'Prieska',
    'Henley on Klip',
    'Coligny',
    'Wartburg',
    'Zeerust',
    'Thabazimbi',
    'Harrismith',
    "King William's Town",
    'Kingsborough',
    'Bergvliet',
    'Westville',
    'Mmabatho',
    'Goodwood',
    'La Lucia',
    'Wilderness',
    'Phokeng',
    'Haenertsburg',
    'Balfour',
    'Grabouw',
    'Graaff Reinet',
    'Mount Edgecombe',
    'Rhodes',
    'Grahamstown',
    'La Motte',
    'Saldanha',
    'Parkview',
    'Rooihuiskraal',
    'Wynberg',
    'Middelburg',
    'Rosslyn',
    'Silverton',
    'Roggebaai',
    'Ganspan',
    'Ashton',
    'Erasmia',
    'Dundee',
    'Belhar',
    'Marshalltown',
    'Braamfontein',
    'Hogsback',
    'Alice',
    'Elsenburg',
    'Onderstepoort',
    'Monument Park',
    'Emerald Hill',
    'Elandsfontein',
    'Maraisburg',
    'Fort Cox',
    'Butterworth',
    'Thohoyandou',
    'Medunsa',
    'Schweizer-Reneke',
    'Moorreesburg',
    'Queenstown',
    'Peninsula',
    'Faure',
    'Doornfontein',
    'Waverley',
    'Mobeni',
    'Hatfield',
    'Capital Park',
    'Parktown',
    'Sandown',
    'Bathurst',
    'Vosloorus',
    'Bothasig',
    'The Rest',
    'Majuba',
    'Queenswood',
    'Mokopane',
    'Hyde Park',
    'Kalkbaai',
    'Craighall',
    'Clanwilliam',
    'Brooklyn',
    'Wonderboom',
    'Irene',
    'Kokstad',
    'Welgelegen',
    'Philadelphia',
    'Primrose',
    'Plumstead',
    'Lansdowne',
    'Edenburg',
    'Gingindlovu',
    'Malvern',
    'Elsburg',
    'Fordsburg',
    'Three Anchor Bay',
    'Saint Helena Bay',
    'Gillitts',
    'Messina',
    'Weltevreden',
    'Vaalbank',
    'Machadodorp',
    'Eersterivier',
    'Sabie',
    'Laudium',
    'Gezina',
    'Glen Austin',
    'Umbogintwini',
    'Oranjeville',
    'Carolina',
    'Swartkops',
    'Rosebank',
  ],
  tld: ['.za'],
  cca2: 'ZA',
  ccn3: '710',
  cca3: 'ZAF',
  cioc: 'RSA',
  currency: ['ZAR'],
  callingCode: ['27'],
  capital: 'Pretoria',
  altSpellings: ['ZA', 'RSA', 'Suid-Afrika', 'Republic of South Africa'],
  region: 'Africa',
  subregion: 'Southern Africa',
  languages: {
    afr: 'Afrikaans',
    eng: 'English',
    nbl: 'Southern Ndebele',
    nso: 'Northern Sotho',
    sot: 'Southern Sotho',
    ssw: 'Swazi',
    tsn: 'Tswana',
    tso: 'Tsonga',
    ven: 'Venda',
    xho: 'Xhosa',
    zul: 'Zulu',
  },
  translations: {
    deu: { official: 'Republik Südafrika', common: 'Republik Südafrika' },
    fra: { official: "République d'Afrique du Sud", common: 'Afrique du Sud' },
    hrv: {
      official: 'Južnoafrička Republika',
      common: 'Južnoafrička Republika',
    },
    ita: { official: 'Repubblica del Sud Africa', common: 'Sud Africa' },
    jpn: { official: '南アフリカ共和国', common: '南アフリカ' },
    nld: { official: 'Zuid -Afrika', common: 'Zuid-Afrika' },
    por: { official: 'República da África do Sul', common: 'África do Sul' },
    rus: {
      official: 'Южно-Африканская Республика',
      common: 'Южно-Африканская Республика',
    },
    spa: {
      official: 'República de Sudáfrica',
      common: 'República de Sudáfrica',
    },
    fin: { official: 'Etelä-Afrikan tasavalta', common: 'Etelä-Afrikka' },
  },
  latlng: [-29, 24],
  demonym: 'South African',
  landlocked: false,
  borders: ['BWA', 'LSO', 'MOZ', 'NAM', 'SWZ', 'ZWE'],
  area: 1221037,
  provinces: [
    'Eastern Cape',
    'Free State',
    'Gauteng',
    'KwaZulu-Natal',
    'Mpumalanga',
    'North-West',
    'Northern Cape',
    'Northern Province',
    'Western Cape',
  ],
  nativeName: 'South Africa',
  timezones: ['UTC+02:00'],
} as const;
