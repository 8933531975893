export const GLP = {
  name: {
    common: 'Guadeloupe',
    official: 'Guadeloupe',
    native: { fra: { official: 'Guadeloupe', common: 'Guadeloupe' } },
  },
  cities: [
    'Les Abymes',
    'Basse-Terre',
    'Pointe-Ã -Pitre',
    'Sainte-Anne',
    "Morne-a-l'Eau",
    'Saint-Francois',
    'Lamentin',
    'Saint-Claude',
    'Baie-Mahault',
    'Petit-Bourg',
    'Le Moule',
    'Gourbeyre',
    'Port-Louis',
    'Capesterre-Belle-Eau',
    'Baie Mahault',
    'Le Gosier',
    'Sainte-Rose',
    'Terre-de-Bas',
    'Grand-Bourg',
    'Anse-Bertrand',
    'Petit-Canal',
    'Baillif',
    'Goyave',
    'Capesterre-de-Marie-Galante',
    'Deshaies',
    'Terre-de-Haut',
    'Trois-Rivieres',
    'Pointe-Noire',
    'Saint-Louis',
    'Village',
  ],
  tld: ['.gp'],
  cca2: 'GP',
  ccn3: '312',
  cca3: 'GLP',
  cioc: '',
  currency: ['EUR'],
  callingCode: ['590'],
  capital: 'Basse-Terre',
  altSpellings: ['GP', 'Gwadloup'],
  region: 'Americas',
  subregion: 'Caribbean',
  languages: { fra: 'French' },
  translations: {
    deu: { official: 'Guadeloupe', common: 'Guadeloupe' },
    fra: { official: 'Guadeloupe', common: 'Guadeloupe' },
    hrv: { official: 'Gvadalupa', common: 'Gvadalupa' },
    ita: { official: 'Guadeloupe', common: 'Guadeloupa' },
    jpn: { official: 'グアドループ島', common: 'グアドループ' },
    nld: { official: 'Guadeloupe', common: 'Guadeloupe' },
    por: { official: 'Guadalupe', common: 'Guadalupe' },
    rus: { official: 'Гваделупа', common: 'Гваделупа' },
    spa: { official: 'Guadalupe', common: 'Guadalupe' },
    fin: { official: 'Guadeloupen departmentti', common: 'Guadeloupe' },
  },
  latlng: [16.25, -61.583333],
  demonym: 'Guadeloupian',
  landlocked: false,
  borders: [],
  area: 1628,
} as const;
