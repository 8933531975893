import sortBy from 'lodash/sortBy';

import * as models from '@models';
import { isString } from '@tools/type-guards';
import { nameFormat, type NameFormatOptions } from '@utils/name-format';
import { countries, countryInfo } from '@values/countries';
import { RoleId, ZEPHYR_ROLE_LIST } from '@values/roles';

import { billingCycleOptions } from './billing-cycle-options';
import { billingDayOptions } from './billing-day-options';
import { billingTypeOptions } from './billing-type-options';
import { paymentCycleOptions } from './payment-cycle-options';
import { usStateOptions } from './us-state-options';

/** ... */
type ValueCallback<T, R = unknown> = (item: T) => R;
/** ... */
type TextCallback<T> = (item: T) => string;
/** ... */
type DisabledHandler<T> = (item: T) => boolean;

export interface OptionsCreatorBaseConfig {
  /** ... */
  includeLetterIndicators?: boolean;
}

export namespace CourseOptions {
  export type Model = Pick<
    models.Course,
    'id' | 'name' | 'organization' | 'endDate'
  >;

  /**
   * Course option generation configuration object.
   */
  export type Config = OptionsCreatorBaseConfig & {
    /**
     * If provided, will only include courses belonging to that account.
     */
    organizationId?: Nullable<models.Organization['id']>;
    /**
     * If `true`, will disable options who's corresponding course has since
     * concluded.
     */
    disableConcluded?: boolean;
  };
}

export namespace UserOptions {
  // export type Model = Pick<
  //   models.User,
  //   'id' | 'firstName' | 'lastName' | 'email' | 'roles'
  // >;
  export type Model = Pick<
    models.User,
    'id' | 'firstName' | 'lastName' | 'email'
  >;

  /**
   * User option generation configuration object.
   */
  export type Config = OptionsCreatorBaseConfig &
    NameFormatOptions & {
      /**
       * If `true`, will disable the corresponding option for user's who are
       * missing either a first or last name.
       */
      disableWithMissingName?: boolean;
    };
}

/**
 * ...
 */
class SelectOptionsCreator {
  /**
   * Create select options for Zephyr roles.
   *
   * @returns List of Zephyr role options.
   */
  roles(...roleIds: RoleId[]) {
    let roleItems = ZEPHYR_ROLE_LIST;

    if (roleIds.length) {
      roleItems = roleItems.filter(({ id }) =>
        (roleIds as number[]).includes(id),
      );
    }

    return roleItems.map(
      ({ id, name }) =>
        ({ text: name, value: id } as ZephyrWeb.OptionItem<number>),
    );
  }

  /**
   * Create select options for billing cycles.
   *
   * @returns List of billing cycle options.
   */
  billingCycles() {
    return billingCycleOptions;
  }

  /**
   * Create select options for billing days.
   *
   * @returns List of billing day options.
   */
  billingDays() {
    return billingDayOptions;
  }

  /**
   * Create select options for billing types.
   *
   * @returns List of billing type options.
   */
  billingTypes() {
    return billingTypeOptions;
  }

  /**
   * Create select options for payment cycles.
   *
   * @returns List of payment cycle options.
   */
  paymentCycles() {
    return paymentCycleOptions;
  }

  /**
   * Create select options for `CountryInfo`.
   *
   * @returns List of `CountryInfo` options.
   */
  countries(config?: OptionsCreatorBaseConfig) {
    const options = createOptionsFromProps({
      items: countries,
      textProp: 'name',
      valueProp: 'value',
      ...config,
    });

    if (process.env.NODE_ENV === 'development') {
      setPriorityOptionByText(options, 'United States', 'Canada');
    }

    return options;
  }

  /**
   * ...
   */
  countryDialCodes(config?: OptionsCreatorBaseConfig) {
    const items = Object.values(countryInfo).map((o) => ({
      ...o,
      name: `${o.code} (${o.dialCode})`,
    }));

    const options = createOptionsFromProps({
      items,
      textProp: 'name',
      valueProp: 'dialCode',
      ...config,
    });

    if (process.env.NODE_ENV === 'development') {
      setPriorityOptionByText(options, 'United States', 'Canada');
    }

    return options;
  }

  /**
   * Create select options for a country's states/provinces.
   *
   * @param countryCode Code of country to create options for.
   * @returns List of state/province options.
   */
  localities(countryCode: string, config?: OptionsCreatorBaseConfig) {
    const items =
      countries.find((item) => item.value === countryCode)?.provinces ?? [];

    return createOptionsFromProps({
      items,
      textProp: (item) => item,
      valueProp: (item) => item,
      ...config,
    });
  }

  /**
   * Create select options for a US states.
   *
   * @returns List of US state options.
   */
  usStates() {
    return usStateOptions;
  }

  /**
   * Create select options for `Drone`.
   *
   * @param drones List of drones.
   * @returns List of `Drone` options.
   */
  drones(
    drones: Pick<models.Drone, 'id' | 'name'>[],
    config?: OptionsCreatorBaseConfig,
  ) {
    return createOptionsFromProps({
      items: drones,
      textProp: 'name',
      valueProp: 'id',
      ...config,
    });
  }

  /**
   * Create select options for `CourseData`.
   *
   * @param courses List of courses.
   * @param config Optional options output configuration.
   * @returns List of `CourseData` options.
   */
  courses(courses: CourseOptions.Model[], config?: CourseOptions.Config) {
    const organizationId = config?.organizationId?.toString() ?? null;

    if (isString(organizationId)) {
      courses = courses.filter(
        (course) => course.organization.id === organizationId,
      );
    }

    const creationOptions = {
      items: courses,
      textProp: 'name',
      valueProp: 'id',
    } as CreateOptionsFromPropsConfig<models.Course, 'id'>;

    if (config && 'includeLetterIndicators' in config) {
      creationOptions.includeLetterIndicators = config.includeLetterIndicators;
    }

    if (config?.disableConcluded) {
      creationOptions.disabledHandler = ({ endDate }) => {
        return new Date(endDate).getTime() <= Date.now();
      };
    }

    return createOptionsFromProps(creationOptions);
  }

  /**
   * Create select options for `Organization`.
   *
   * @param organizations List of organizations.
   * @returns List of `Organization` options.
   */
  organizations(
    organizations: Pick<models.Organization, 'id' | 'name'>[],
    config?: OptionsCreatorBaseConfig,
  ) {
    const options = createOptionsFromProps({
      items: organizations,
      textProp: 'name',
      valueProp: 'id',
      ...config,
    });

    if (process.env.NODE_ENV === 'development') {
      setPriorityOptionByText(
        options,
        'Dev Test Institution',
        'Little Arms Studios',
      );
    }

    return options;
  }

  /**
   * Create select options for `Product`.
   *
   * @param products List of products.
   * @returns List of `Product` options.
   */
  products(
    products: Pick<models.Product, 'id' | 'name'>[],
    config?: OptionsCreatorBaseConfig,
  ) {
    return createOptionsFromProps({
      items: products,
      textProp: 'name',
      valueProp: 'id',
      ...config,
    });
  }

  /**
   * Create select options for `Module`.
   *
   * @param modules List of modules.
   * @returns List of `Module` options.
   */
  modules(
    modules: Pick<models.Module, 'id' | 'name'>[],
    config?: OptionsCreatorBaseConfig,
  ) {
    const options = createOptionsFromProps({
      items: modules,
      textProp: 'name',
      valueProp: 'id',
      ...config,
    });

    if (process.env.NODE_ENV === 'development') {
      setPriorityOptionByText(options, 'Dev Test Module');
    }

    return options;
  }

  /**
   * Create select options for `Scene`.
   *
   * @param scenes List of scenes.
   * @returns List of `Scene` options.
   */
  scenes(
    scenes: Pick<models.Scene, 'id' | 'name' | 'isPrivate'>[],
    config?: OptionsCreatorBaseConfig,
  ) {
    let options = createOptionsFromProps({
      items: scenes,
      textProp: (item) => `${item.name} ${item.isPrivate ? `[PRIVATE]` : ''}`,
      valueProp: 'id',
      ...config,
    });

    // reorganize [PRIVATE] scenarios to end of list
    const privateOptions = [];
    const publicOptions = [];

    for (const option of options) {
      if (option.text.includes('[PRIVATE')) {
        privateOptions.push(option);
      } else {
        publicOptions.push(option);
      }
    }

    options = [...publicOptions, ...privateOptions];

    if (process.env.NODE_ENV === 'development') {
      setPriorityOptionByText(options, 'Dev Test Scene');
    }

    return options;
  }

  /**
   * Create select options for `DLC`.
   *
   * @param dlc List of dlc.
   * @returns List of `DLC` options.
   */
  dlc(
    dlc: Pick<models.DLC, 'id' | 'name' | 'os'>[],
    config?: OptionsCreatorBaseConfig,
  ) {
    // since we have os options within each DLC option,
    // we need to create a selection item for each os option

    const options = createOptionsFromProps({
      items: dlc.flatMap((d) => {
        return d.os.map((os) => {
          return {
            id: `${d.id}+${os}`,
            name: `${d.name ? d.name : d.id} [${os}]`,
          };
        });
      }),
      textProp: 'name',
      valueProp: 'id',
      ...config,
    });

    if (process.env.NODE_ENV === 'development') {
      setPriorityOptionByText(options, 'Dev Test DLC');
    }

    return options;
  }

  /**
   * Create select options for teachers.
   *
   * @param users List of teachers.
   * @returns List of `UserData` options.
   */
  teachers(
    users: Pick<
      models.User,
      'id' | 'firstName' | 'lastName' | 'email' | 'roles'
    >[],
    config?: OptionsCreatorBaseConfig,
  ) {
    return createOptionsFromProps({
      items: users,
      textProp: (item) => `${nameFormat(item)}`,
      valueProp: 'id',
      ...config,
    });
  }

  /**
   * Create select options for `User`.
   *
   * @param users List of users.
   * @returns List of `UserData` options.
   */
  users(users: UserOptions.Model[], config: UserOptions.Config = {}) {
    const { lastNameFirst = false, ignoreEmail = false, ...options } = config;

    const creationOptions = {
      items: users,
      textProp: (item) => `${nameFormat(item, { lastNameFirst, ignoreEmail })}`,
      valueProp: 'id',
    } as CreateOptionsFromPropsConfig<models.User, 'id'>;

    if (config?.disableWithMissingName) {
      creationOptions.disabledHandler = ({ firstName, lastName }) => {
        console.log({ firstName, lastName });

        return !firstName || !lastName;
      };
    }

    return createOptionsFromProps(creationOptions);
  }
}

/** ... */
export const createOptions = new SelectOptionsCreator();

export namespace createOptions {
  /** ... */
  export namespace course {
    /** Course option generation configuration object. */
    export type Config = CourseOptions.Config;
  }
}

export interface CreateAlphabetizedOptionsListOptions<T, V = unknown> {
  items: T[];
  callback: (item: T) => ZephyrWeb.OptionItem<V>;
  includeLetterIndicators?: boolean;
}

/**
 * ...
 *
 * @param items ...
 * @param callback ...
 * @returns ...
 */
export function createAlphabetizedOptionsList<T, V = unknown>(
  items: T[],
  callback: (item: T) => ZephyrWeb.OptionItem<V>,
): ZephyrWeb.OptionItem<V | null>[];
export function createAlphabetizedOptionsList<T, V = unknown>(
  options: CreateAlphabetizedOptionsListOptions<T, V>,
): ZephyrWeb.OptionItem<V | null>[];
export function createAlphabetizedOptionsList<T, V = unknown>(
  arg1: unknown,
  arg2?: unknown,
) {
  let items: T[];
  let callback: (item: T) => ZephyrWeb.OptionItem<V>;
  let includeLetterIndicators = true;

  if (arg2) {
    items = arg1 as T[];
    callback = arg2 as (item: T) => ZephyrWeb.OptionItem<V>;
  } else {
    ({
      items,
      callback,
      includeLetterIndicators = true,
    } = arg1 as CreateAlphabetizedOptionsListOptions<T, V>);
  }

  const options: ZephyrWeb.OptionItem<V | null>[] = [];

  mapSortedOptions(items, callback).forEach((item, index, array) => {
    if (includeLetterIndicators) {
      const prevItem = array[index - 1];

      if (item.letter !== prevItem?.letter) {
        options.push({ value: null, text: item.letter, disabled: true });
      }
    }

    options.push(item.option);
  });

  return options;
}

type ValueProp<T> = keyof T | ValueCallback<T>;

export interface CreateOptionsFromPropsConfig<T, V extends ValueProp<T>> {
  items: T[];
  valueProp: V;
  textProp: keyof T | TextCallback<T>;
  disabledHandler?: DisabledHandler<T>;
  includeLetterIndicators?: boolean;
}

/**
 * ...
 *
 * @param config ...
 * @returns ...
 */
export function createOptionsFromProps<T, V extends keyof T>(
  config: CreateOptionsFromPropsConfig<T, V>,
): ZephyrWeb.OptionItem<T[V]>[];
export function createOptionsFromProps<T, V extends ValueCallback<T>>(
  config: CreateOptionsFromPropsConfig<T, V>,
): ZephyrWeb.OptionItem<ReturnType<V>>[];
export function createOptionsFromProps<T>(
  config: CreateOptionsFromPropsConfig<T, ValueProp<T>>,
) {
  return createAlphabetizedOptionsList({
    items: config.items,
    includeLetterIndicators: config.includeLetterIndicators ?? false,
    callback: (item) => {
      let text;

      if (typeof config.textProp === 'function') {
        text = config.textProp(item);
      } else {
        text = item[config.textProp] as unknown;
      }

      if (!isString(text)) {
        throw new Error(
          '[createOptionsFromProps] Corresponding value for "textProp" must be a string.',
        );
      }

      let value;

      if (typeof config.valueProp === 'function') {
        value = config.valueProp(item);
      } else {
        value = item[config.valueProp];
      }

      const disabled = config.disabledHandler?.(item) ?? false;

      return { value, text, disabled };
    },
  });
}

//#region Helper Functions

/**
 * ...
 *
 * @param items ...
 * @param callback ...
 * @returns ...
 */
function mapSortedOptions<T, V = unknown>(
  items: T[],
  callback: (item: T) => ZephyrWeb.OptionItem<V>,
) {
  const mappedOptions = items.map((item) => {
    const option = callback(item);
    const value = option.text.toLowerCase();
    const letter = option.text.charAt(0).toUpperCase();

    return { option, value, letter };
  });

  return sortBy(mappedOptions, ['value']);
}

/**
 * ...
 *
 * @param options ...
 * @param optionTexts ...
 */
function setPriorityOptionByText(
  options: ZephyrWeb.OptionItem[],
  ...optionTexts: string[]
) {
  const matchedOptions: ZephyrWeb.OptionItem[] = [];

  for (const text of optionTexts) {
    // ...
    const i = options.findIndex((option) => option.text === text);

    if (i === -1) continue;

    matchedOptions.push(
      options.splice(i, 1).at(0) as unknown as ZephyrWeb.OptionItem,
    );
  }

  if (matchedOptions.length) {
    // options.unshift({ text: '', value: '', disabled: true });
    // options.unshift({ text: '--', value: '', disabled: true });

    options.unshift(...matchedOptions);
    options.unshift({ text: '★', value: Symbol(), disabled: true });
  }
}

//#endregion Helper Functions
