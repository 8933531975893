var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.BCard,
    _vm._b({ class: _setup.classList }, "b-card", _vm.$attrs, false),
    [
      _vm._t("default"),
      _vm._v(" "),
      _setup.loadingStateActive
        ? _c(
            _setup.Overlay,
            {
              staticClass: "card-loading-overlay",
              attrs: { background: "transparent", transition: "1" },
            },
            [_c(_setup.Spinner)],
            1
          )
        : _vm.error
        ? _c(_setup.OverlayRenderIssue, { staticClass: "card-error-overlay" })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }