var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "cart-widget-wrapper" },
    [
      _c("Transition", { attrs: { appear: "" } }, [
        _setup.showCartButton
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.left",
                    value: "Show Cart",
                    expression: "'Show Cart'",
                    modifiers: { left: true },
                  },
                ],
                staticClass: "cart-button",
                on: {
                  click: function ($event) {
                    _setup.showCartWidget = !_setup.showCartWidget
                  },
                },
              },
              [
                _c("Icon", { attrs: { icon: _setup.faShoppingCart } }),
                _vm._v(" "),
                _setup.showBadge
                  ? _c("BadgeCount", {
                      key: _setup.cartLength,
                      attrs: { value: _setup.cartLength },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("Transition", { attrs: { appear: "" } }, [
        _setup.showCartWidget
          ? _c(
              "div",
              {
                class: { "has-new-item": _setup.newItemAdded },
                attrs: { id: "cart-widget-view" },
              },
              [
                _c(
                  "h3",
                  {
                    staticClass:
                      "h5 d-flex justify-content-between align-items-center mb-4",
                  },
                  [
                    _c(
                      "span",
                      [
                        _c("Icon", {
                          staticClass: "mr-1",
                          attrs: { icon: _setup.faShoppingCart },
                        }),
                        _vm._v(" "),
                        _c("b", [_vm._v("CART")]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        staticClass: "dismiss-button",
                        attrs: { variant: "secondary" },
                        on: {
                          click: function ($event) {
                            _setup.showCartWidget = false
                          },
                        },
                      },
                      [_c("Icon", { attrs: { icon: _setup.faTimes } })],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "cart-items-container mb-4" },
                  [
                    _c(
                      "b-container",
                      { staticClass: "flex-column align-items-stretch px-0" },
                      _vm._l(_setup.cart.items, function (item) {
                        return _c(
                          "b-row",
                          { key: item.id, staticClass: "cart-item py-2" },
                          [
                            _c(
                              "b-col",
                              {
                                staticClass:
                                  "d-flex justify-content-between align-items-center",
                                attrs: { col: "" },
                              },
                              [
                                _c("small", [_vm._v(_vm._s(item.name))]),
                                _vm._v(" "),
                                _c(_setup.DisplayTextCurrency, {
                                  staticClass: "font-weight-bold",
                                  attrs: { value: item.total },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-col",
                              {
                                staticClass: "d-inline-flex align-items-center",
                                attrs: { cols: "auto" },
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      title: "Remove Item",
                                      variant: "outline-danger",
                                      size: "sm",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _setup.removeFromCart(item.id)
                                      },
                                    },
                                  },
                                  [
                                    _c("Icon", {
                                      attrs: { icon: _setup.faTrashCan },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-button",
                  {
                    attrs: { block: "", variant: "primary" },
                    on: { click: _setup.goToCheckout },
                  },
                  [_vm._v("\n        Proceed to Checkout\n      ")]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }