var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("Transition", { attrs: { appear: "" } }, [
    _c("div", { staticClass: "sidebar-mobile" }, [
      _c(
        "div",
        { staticClass: "sidebar-main" },
        [_c(_setup.SidebarNav), _vm._v(" "), _c("hr")],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }