export const ZMB = {
  name: {
    common: 'Zambia',
    official: 'Republic of Zambia',
    native: { eng: { official: 'Republic of Zambia', common: 'Zambia' } },
  },
  cities: [
    'Lusaka',
    'Mumbwa',
    'Kitwe',
    'Ndola',
    'Macha',
    'Kalomo',
    'Solwezi',
    'Siavonga',
    'Livingstone',
  ],
  provinces: [
    'Central',
    'Copperbelt',
    'Eastern',
    'Luapula',
    'Lusaka',
    'North-Western',
    'Northern',
    'Southern',
    'Western',
  ],
  nativeName: 'Zambia',
  tld: ['.zm'],
  cca2: 'ZM',
  ccn3: '894',
  cca3: 'ZMB',
  cioc: 'ZAM',
  currency: ['ZMW'],
  callingCode: ['260'],
  capital: 'Lusaka',
  altSpellings: ['ZM', 'Republic of Zambia'],
  region: 'Africa',
  subregion: 'Eastern Africa',
  languages: { eng: 'English' },
  translations: {
    deu: { official: 'Republik Sambia', common: 'Sambia' },
    fra: { official: 'République de Zambie', common: 'Zambie' },
    hrv: { official: 'Republika Zambija', common: 'Zambija' },
    ita: { official: 'Repubblica di Zambia', common: 'Zambia' },
    jpn: { official: 'ザンビア共和国', common: 'ザンビア' },
    nld: { official: 'Republiek Zambia', common: 'Zambia' },
    por: { official: 'República da Zâmbia', common: 'Zâmbia' },
    rus: { official: 'Республика Замбия', common: 'Замбия' },
    spa: { official: 'República de Zambia', common: 'Zambia' },
    fin: { official: 'Sambian tasavalta', common: 'Sambia' },
  },
  latlng: [-15, 30],
  demonym: 'Zambian',
  landlocked: true,
  borders: ['AGO', 'BWA', 'COD', 'MWI', 'MOZ', 'NAM', 'TZA', 'ZWE'],
  area: 752612,
} as const;
