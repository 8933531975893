var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", {
    class: ["panel-pointer", _setup.info.className],
    style: _setup.info.style,
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }