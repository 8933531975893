export const YEM = {
  name: {
    common: 'Yemen',
    official: 'Republic of Yemen',
    native: { ara: { official: 'الجمهورية اليمنية', common: 'اليَمَن' } },
  },
  cities: ["Sana'", 'Sanaa', 'Aden'],
  provinces: [
    "'Adan",
    "'Ataq",
    'Abyan',
    "Al Bayda'",
    'Al Hudaydah',
    'Al Jawf',
    'Al Mahrah',
    'Al Mahwit',
    'Dhamar',
    'Hadhramawt',
    'Hajjah',
    'Ibb',
    'Lahij',
    "Ma'rib",
    "Sa'dah",
    "San'a'",
    "Ta'izz",
  ],
  nativeName: 'اليَمَن',
  tld: ['.ye'],
  cca2: 'YE',
  ccn3: '887',
  cca3: 'YEM',
  cioc: 'YEM',
  currency: ['YER'],
  callingCode: ['967'],
  capital: "Sana'a",
  altSpellings: ['YE', 'Yemeni Republic', 'al-Jumhūriyyah al-Yamaniyyah'],
  region: 'Asia',
  subregion: 'Western Asia',
  languages: { ara: 'Arabic' },
  translations: {
    deu: { official: 'Republik Jemen', common: 'Jemen' },
    fra: { official: 'République du Yémen', common: 'Yémen' },
    hrv: { official: 'Republika Jemen', common: 'Jemen' },
    ita: { official: 'Repubblica dello Yemen', common: 'Yemen' },
    jpn: { official: 'イエメン共和国', common: 'イエメン' },
    nld: { official: 'Republiek Jemen', common: 'Jemen' },
    por: { official: 'República do Iêmen', common: 'Iémen' },
    rus: { official: 'Йеменская Республика', common: 'Йемен' },
    spa: { official: 'República de Yemen', common: 'Yemen' },
    fin: { official: 'Jemenin tasavalta', common: 'Jemen' },
  },
  latlng: [15, 48],
  demonym: 'Yemeni',
  landlocked: false,
  borders: ['OMN', 'SAU'],
  area: 527968,
} as const;
