var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { class: _setup.classList },
    [
      _c(
        "b-button-toolbar",
        { staticClass: "table-toolbar p-4", attrs: { justify: "" } },
        [
          _c(
            "b-input-group",
            { staticClass: "flex-grow-1 mr-2", attrs: { prepend: "User" } },
            [
              _c(_setup.FormQuerySelect, {
                attrs: {
                  placeholder: "Select a user",
                  querier: _setup.queryUsers,
                },
                model: {
                  value: _setup.queryParams.userId,
                  callback: function ($$v) {
                    _vm.$set(_setup.queryParams, "userId", $$v)
                  },
                  expression: "queryParams.userId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-button-group",
            { staticClass: "mr-2" },
            [
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip",
                      value: "Load More",
                      expression: "'Load More'",
                    },
                  ],
                  attrs: {
                    disabled: _setup.loadingPage || _setup.allResultsLoaded,
                  },
                  on: { click: _setup.loadMore },
                },
                [_vm._v("\n        Load More\n      ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-button-group",
            [
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip",
                      value: "Refresh",
                      expression: "'Refresh'",
                    },
                  ],
                  on: { click: _setup.refreshItems },
                },
                [_c(_setup.Icon, { attrs: { icon: _setup.faSync } })],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.infoMessage
            ? _c(
                "b-button-group",
                { staticClass: "ml-2" },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.left",
                          value: _vm.infoMessage,
                          expression: "infoMessage",
                          modifiers: { left: true },
                        },
                      ],
                    },
                    [_c(_setup.Icon, { attrs: { icon: _setup.faInfo } })],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table-container" },
        [
          _c(
            "Transition",
            { attrs: { appear: "" } },
            [
              _setup.tableOverlay
                ? _c(
                    _setup.TableOverlay,
                    _vm._b({}, "TableOverlay", _setup.tableOverlay, false)
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "table-wrapper" },
            [
              _c("b-table", {
                class: ["mb-0", { "is-busy": _setup.loadingPage }],
                attrs: {
                  busy: _setup.loadingPage,
                  items: _setup.items,
                  fields: [..._setup.fields, "show"],
                  "per-page": 0,
                  striped: "",
                  "sticky-header": "auto",
                },
                on: {
                  "update:busy": function ($event) {
                    _setup.loadingPage = $event
                  },
                },
                nativeOn: {
                  scroll: function ($event) {
                    return _setup.onScroll.apply(null, arguments)
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "head(selector)",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "table-cell-checkbox" }, [
                            _c(
                              "div",
                              [
                                _c("InputCheckbox", {
                                  attrs: { size: "sm" },
                                  model: {
                                    value: _setup.allRowsSelected,
                                    callback: function ($$v) {
                                      _setup.allRowsSelected = $$v
                                    },
                                    expression: "allRowsSelected",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "head(operations)",
                      fn: function (column) {
                        return [
                          _c("div", { staticClass: "text-center" }, [
                            _vm._v(_vm._s(column.label)),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "head(show)",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "text-center" }, [
                            _vm._v("Full Log"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "cell(selector)",
                      fn: function (cell) {
                        return [
                          _c("div", { staticClass: "table-cell-checkbox" }, [
                            _c(
                              "div",
                              [
                                _setup.page[cell.index]
                                  ? _c("InputCheckbox", {
                                      attrs: { size: "sm" },
                                      model: {
                                        value: _setup.page[cell.index].selected,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _setup.page[cell.index],
                                            "selected",
                                            $$v
                                          )
                                        },
                                        expression: "page[cell.index].selected",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                        ]
                      },
                    },
                    _vm._l(_setup.customCells, function (cell) {
                      return {
                        key: `cell(${cell.key})`,
                        fn: function (data) {
                          return [
                            _c(
                              cell.component,
                              _vm._b(
                                { key: cell.key, tag: "component" },
                                "component",
                                data,
                                false
                              )
                            ),
                          ]
                        },
                      }
                    }),
                    {
                      key: "cell(operations)",
                      fn: function (cell) {
                        return [
                          _vm.operations && cell.item
                            ? _c(
                                _setup.CellOperations,
                                _vm._b(
                                  {},
                                  "CellOperations",
                                  {
                                    item: cell.item,
                                    operations: _vm.operations,
                                  },
                                  false
                                )
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "cell(show)",
                      fn: function (cell) {
                        return [
                          _c(
                            "div",
                            { staticClass: "text-center" },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "view-log-btn",
                                  attrs: { variant: "link" },
                                  on: {
                                    click: function ($event) {
                                      return _setup.viewFullLog(cell.item)
                                    },
                                  },
                                },
                                [_vm._v("\n              View\n            ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
                model: {
                  value: _setup.displayedItems,
                  callback: function ($$v) {
                    _setup.displayedItems = $$v
                  },
                  expression: "displayedItems",
                },
              }),
              _vm._v(" "),
              _c("Transition", [
                _setup.loadingPage
                  ? _c("div", { staticClass: "table-progress-bar" }, [
                      _c("span", {
                        style: { right: `${100 - _setup.loadProgress}%` },
                        on: { transitionend: _setup.onAnimationEnd },
                      }),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table-footer p-4" },
        [
          _c(
            "b-row",
            { staticClass: "justify-content-between align-items-center" },
            [
              _c("b-col", { staticClass: "col-auto" }, [
                _vm._v(" " + _vm._s(_setup.items.length) + " result(s) "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }