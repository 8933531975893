var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "form-list-select" },
    [
      _c(
        "div",
        { staticClass: "field-wrapper" },
        [
          _c(
            "div",
            { staticClass: "input-wrapper", attrs: { id: _setup.fieldId } },
            [
              _c(
                _setup.BFormInput,
                _vm._g(
                  {
                    class: { focused: _setup.popoverShow },
                    attrs: { value: _setup.displayValue },
                  },
                  _vm.$listeners
                )
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.clearable
            ? _c("b-button", { on: { click: _setup.clearValue } }, [
                _vm._v(" Clear "),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-popover",
        {
          attrs: {
            "data-form-list-select-id": _setup.fieldId,
            target: _setup.fieldId,
            triggers: "click",
            show: _setup.popoverShow,
            placement: "bottom",
          },
          on: {
            "update:show": function ($event) {
              _setup.popoverShow = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "popover-search-form" },
            [
              _c(
                "b-input-group",
                {
                  staticClass: "mr-2",
                  attrs: { size: "sm", prepend: "Search" },
                },
                [
                  _c(_setup.BFormInput, {
                    ref: "queryField",
                    staticClass: "m-0",
                    attrs: { size: "sm", spellcheck: "false" },
                    model: {
                      value: _setup.queryText,
                      callback: function ($$v) {
                        _setup.queryText = $$v
                      },
                      expression: "queryText",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "b-input-group-append",
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "secondary" },
                          on: { click: _setup.clearQueryText },
                        },
                        [_vm._v("\n            Clear\n          ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { variant: "danger", size: "sm" },
                  on: { click: _setup.close },
                },
                [_vm._v(" Close ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "popover-list-items" },
            [
              !_setup.filteredItems.length && !_setup.queryText
                ? [
                    _c("div", { staticClass: "popover-overlay" }, [
                      _c("span", { staticClass: "h6 m-0 text-muted" }, [
                        _vm._v(" No items "),
                      ]),
                    ]),
                  ]
                : !_setup.filteredItems.length && _setup.queryText
                ? [
                    _c("div", { staticClass: "popover-overlay" }, [
                      _c("span", { staticClass: "h6 m-0 text-muted" }, [
                        _vm._v(" No results "),
                      ]),
                    ]),
                  ]
                : _c(
                    "div",
                    {
                      staticClass: "popover-list-items-inner",
                      style: { maxHeight: _setup.listInnerHeight() },
                    },
                    _vm._l(_setup.filteredItems, function (item) {
                      return _c(
                        "div",
                        {
                          key: item.value,
                          class: [
                            "list-item",
                            { selected: item.value === _vm.value },
                          ],
                          attrs: { disabled: item.disabled },
                          on: {
                            click: function ($event) {
                              return _setup.setValue(item.value)
                            },
                          },
                        },
                        [_c("span", [_vm._v(" " + _vm._s(item.text))])]
                      )
                    }),
                    0
                  ),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }