export const DZA = {
  name: {
    common: 'Algeria',
    official: "People's Democratic Republic of Algeria",
    native: {
      ara: {
        official: 'الجمهورية الديمقراطية الشعبية الجزائرية',
        common: 'الجزائر',
      },
    },
  },
  tld: ['.dz', 'الجزائر.'],
  cca2: 'DZ',
  ccn3: '012',
  cca3: 'DZA',
  cioc: 'ALG',
  currency: ['DZD'],
  callingCode: ['213'],
  capital: 'Algiers',
  altSpellings: ['DZ', 'Dzayer', 'Algérie'],
  region: 'Africa',
  subregion: 'Northern Africa',
  languages: { ara: 'Arabic' },
  translations: {
    cym: {
      official: "People's Democratic Republic of Algeria",
      common: 'Algeria',
    },
    deu: {
      official: 'Demokratische Volksrepublik Algerien',
      common: 'Algerien',
    },
    fra: {
      official: "République démocratique et populaire d'Algérie",
      common: 'Algérie',
    },
    hrv: { official: 'Narodna Demokratska Republika Alžir', common: 'Alžir' },
    ita: {
      official: 'Repubblica popolare democratica di Algeria',
      common: 'Algeria',
    },
    jpn: { official: 'アルジェリア人民民主共和国', common: 'アルジェリア' },
    nld: {
      official: 'Democratische Volksrepubliek Algerije',
      common: 'Algerije',
    },
    por: {
      official: 'República Argelina Democrática e Popular',
      common: 'Argélia',
    },
    rus: {
      official: 'Народно-Демократическая Республика Алжир',
      common: 'Алжир',
    },
    spa: {
      official: 'República Argelina Democrática y Popular',
      common: 'Argelia',
    },
    fin: {
      official: 'Algerian demokraattinen kansantasavalta',
      common: 'Algeria',
    },
  },
  latlng: [28, 3],
  demonym: 'Algerian',
  landlocked: false,
  borders: ['TUN', 'LBY', 'NER', 'ESH', 'MRT', 'MLI', 'MAR'],
  area: 2381741,
  provinces: [
    'Adrar',
    'Ain Defla',
    'Ain Temouchent',
    'Alger',
    'Annaba',
    'Batna',
    'Bechar',
    'Bejaia',
    'Biskra',
    'Blida',
    'Bordj Bou Arreridj',
    'Bouira',
    'Boumerdes',
    'Chlef',
    'Constantine',
    'Djelfa',
    'El Bayadh',
    'El Oued',
    'El Tarf',
    'Ghardaia',
    'Guelma',
    'Illizi',
    'Jijel',
    'Khenchela',
    'Laghouat',
    "M'Sila",
    'Mascara',
    'Medea',
    'Mila',
    'Mostaganem',
    'Naama',
    'Oran',
    'Ouargla',
    'Oum el Bouaghi',
    'Relizane',
    'Saida',
    'Setif',
    'Sidi Bel Abbes',
    'Skikda',
    'Souk Ahras',
    'Tamanghasset',
    'Tebessa',
    'Tiaret',
    'Tindouf',
    'Tipaza',
    'Tissemsilt',
    'Tizi Ouzou',
    'Tlemcen',
  ],
  nativeName: 'الجزائر',
  timezones: ['UTC+01:00'],
} as const;
