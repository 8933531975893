export const SRB = {
  name: {
    common: 'Serbia',
    official: 'Republic of Serbia',
    native: { srp: { official: 'Република Србија', common: 'Србија' } },
  },
  cities: [
    'Belgrade',
    'Zemun Polje',
    'Kragujevac',
    'Novi Sad',
    'Subotica',
    'NiÅ¡',
    'Vajska',
    'Futog',
    'PanÄevo',
    'Backa Topola',
    'Cantavir',
    'Becej',
    'Backo Gradiste',
    'Kraljevo',
    'Basaid',
    'Bocar',
    'Kovin',
    'Novi Belgrade',
    'Savski Venac',
    'Sremska Kamenica',
    'Aleksinac',
    'Surcin',
    'Grocka',
    'Umcari',
    'Nova Pazova',
    'Sombor',
    'Smederevo',
    'Sremski Karlovci',
    'Jagodina',
    'Novi Becej',
    'Stara Pazova',
    'Apatin',
    'PoÅ¾arevac',
    'Dolovo',
    'Coka',
    'Gornji Milanovac',
    'Veternik',
    'Sibac',
    'Tornjos',
    'Zlatibor',
    'ÄŒaÄak',
    'Ugrinovci',
    'KruÅ¡evac',
    'Paracin',
    'Priboj',
    'Despotovac',
    'Prokuplje',
    'Titel',
    'Rumenka',
    'Temerin',
    'Backi Petrovac',
    'Backi Jarak',
    'Kanjiza',
    'Srbobran',
    'Golubinci',
    'Petrovac',
    'Barajevo',
    'Bor',
    'Zrenjanin',
    'Melenci',
    'Novi Banovci',
    'Ingija',
    'Odzaci',
    'Drenovac',
    'Glozan',
    'Ratkovo',
    'Banatsko Novo Selo',
    'Ruma',
    'Svilajnac',
    'Avala',
    'Cuprija',
    'Popovac',
    'Svrljig',
    'Simanovci',
    'Sremcica',
    'Leskovac',
    'Selo Mladenovac',
    'UÅ¾ice',
    'Valjevo',
    'Pirot',
    'Arilje',
    'Novi Pazar',
    'Kacarevo',
    'Loznica',
    'Petrovaradin',
    'Zajecar',
    'Debeljaca',
    'Senta',
    'Vrbas',
    'VrÅ¡ac',
    'Stari Banovci',
    'Batajnica',
    'Umka',
    'Izvor',
    'Nikinci',
    'Sirig',
    'Sremska Mitrovica',
    'Palic',
    'Cukarica',
    'Ada',
  ],
  tld: ['.rs', '.срб'],
  cca2: 'RS',
  ccn3: '688',
  cca3: 'SRB',
  cioc: 'SRB',
  currency: ['RSD'],
  callingCode: ['381'],
  capital: 'Belgrade',
  altSpellings: [
    'RS',
    'Srbija',
    'Republic of Serbia',
    'Република Србија',
    'Republika Srbija',
  ],
  region: 'Europe',
  subregion: 'Southern Europe',
  languages: { srp: 'Serbian' },
  translations: {
    deu: { official: 'Republik Serbien', common: 'Serbien' },
    fra: { official: 'République de Serbie', common: 'Serbie' },
    hrv: { official: 'Republika Srbija', common: 'Srbija' },
    ita: { official: 'Repubblica di Serbia', common: 'Serbia' },
    jpn: { official: 'セルビア共和国', common: 'セルビア' },
    nld: { official: 'Republiek Servië', common: 'Servië' },
    por: { official: 'República da Sérvia', common: 'Sérvia' },
    rus: { official: 'Республика Сербия', common: 'Сербия' },
    spa: { official: 'República de Serbia', common: 'Serbia' },
    fin: { official: 'Serbian tasavalta', common: 'Serbia' },
  },
  latlng: [44, 21],
  demonym: 'Serbian',
  landlocked: true,
  borders: ['BIH', 'BGR', 'HRV', 'HUN', 'KOS', 'MKD', 'MNE', 'ROU'],
  area: 88361,
} as const;
