var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "selector-group" }, [
    _c("h3", { staticClass: "text-center" }, [_vm._v(_vm._s(_vm.label))]),
    _vm._v(" "),
    _c("div", { staticClass: "list-container" }, [
      !_setup.itemsLength
        ? _c("ul", [_vm._m(0)])
        : _c(
            "ul",
            _vm._l(_setup.groupItems, function (item) {
              return _c(
                "li",
                {
                  key: item.id,
                  on: {
                    click: function ($event) {
                      return _vm.$emit("removed", item.id)
                    },
                  },
                },
                [
                  _c("div", [
                    _c("div", { staticClass: "item-text" }, [
                      _vm._v(_vm._s(item.text)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-subtext" }, [
                      _vm._v(_vm._s(item.subtext)),
                    ]),
                  ]),
                ]
              )
            }),
            0
          ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("li", [
      _c("h4", { staticClass: "text-center" }, [_vm._v("No Items")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }