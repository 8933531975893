export const GUF = {
  name: {
    common: 'French Guiana',
    official: 'Guiana',
    native: { fra: { official: 'Guyanes', common: 'Guyane française' } },
  },
  cities: ['Petit Cayenne', 'Kourou', 'Remire-Montjoly', 'Matoury'],
  tld: ['.gf'],
  cca2: 'GF',
  ccn3: '254',
  cca3: 'GUF',
  cioc: '',
  currency: ['EUR'],
  callingCode: ['594'],
  capital: 'Cayenne',
  altSpellings: ['GF', 'Guiana', 'Guyane'],
  region: 'Americas',
  subregion: 'South America',
  languages: { fra: 'French' },
  translations: {
    deu: { official: 'Guayana', common: 'Französisch Guyana' },
    fra: { official: 'Guyane', common: 'Guyane' },
    hrv: { official: 'Gijana', common: 'Francuska Gvajana' },
    ita: { official: 'Guiana', common: 'Guyana francese' },
    jpn: { official: 'ギアナ', common: 'フランス領ギアナ' },
    nld: { official: 'Guyana', common: 'Frans-Guyana' },
    por: { official: 'Guiana', common: 'Guiana Francesa' },
    rus: { official: 'Гвиана', common: 'Французская Гвиана' },
    spa: { official: 'Guayana', common: 'Guayana Francesa' },
    fin: { official: 'Ranskan Guayana', common: 'Ranskan Guayana' },
  },
  latlng: [4, -53],
  demonym: '',
  landlocked: false,
  borders: ['BRA', 'SUR'],
  area: 83534,
  provinces: ['French Guiana'],
  nativeName: 'Guyane française',
} as const;
