import { BvTableField, BvTableFormatterCallback } from 'bootstrap-vue';
import { TablePanel } from 'table-panel';

import { dateFilter } from '@filters/date';
import { store } from '@store';
import { ensureError } from '@tools/ensure-error';
import { isBoolean, isString, isNumber, isDateish } from '@tools/type-guards';
import * as currencies from '@utils/currencies';

/**
 * Create a BootstrapVue table field config from legacy custom table column
 * config.
 *
 * @param column Legacy column config.
 * @return BootstrapVue table field config.
 */
export function createField<T>(column: TablePanel.Column<T>) {
  if (column.hidden?.(store.state.me)) return;

  const field: BvTableField = { label: column.label };

  let formatter: BvTableFormatterCallback | null = null;

  if (typeof column.value === 'function') {
    formatter = (_value, _key, item) => (column.value as GenericFunction)(item);
  } else if (column.type) {
    formatter = createColumnTypeFormatter(column.type);
  }

  field.formatter = wrapFormatter(formatter ?? ((value) => value));

  if (column.filterByFormatted) field.filterByFormatted = true;
  if (column.sortByFormatted) field.sortByFormatted = true;

  return { key: column.key, sortable: true, ...field };
}

/**
 * Wraps a provided table column formatter function with silent error handling
 * (encountered during the formatting process) as well as fallback output value
 * of `--` if the format results in a nullish value.
 */
function wrapFormatter(formatter: BvTableFormatterCallback) {
  return ((value, key, item) => {
    let output: unknown = null;

    try {
      output = formatter(value, key, item);
    } catch (err) {
      let warningMessage = 'Failed to format cell';

      if (process.env.NODE_ENV === 'development') {
        warningMessage += `\n${ensureError(err).message}`;
      } else {
        warningMessage += '.';
      }

      /* eslint-disable-next-line no-console */
      console.warn(warningMessage, { value, key, item });
    }

    return output ?? '--';
  }) as BvTableFormatterCallback;
}

/**
 * Creates a table column formatter function based off a provided cell value
 * input type.
 */
function createColumnTypeFormatter(type: TablePanel.Column.Type) {
  return ((value: unknown) => {
    if (type === 'currency') return formatAsCurrency(value);
    if (type === 'date') return formatAsDate(value);
    if (type === 'dateTime') return formatAsDateTime(value);
    if (type === 'boolean') return formatAsBoolean(value);
    if (type === 'phone') return formatAsPhone(value);

    return value;
  }) as BvTableFormatterCallback;
}

//#region Cell Value Formatters

function formatAsCurrency(value: unknown) {
  value = isString(value) ? parseFloat(value) : value;

  return isNumber(value) ? currencies.format(value) : null;
}

function formatAsDate(value: unknown) {
  return isDateish(value) ? dateFilter(value, 'MM/dd/yyyy') : null;
}

function formatAsDateTime(value: unknown) {
  return isDateish(value) ? dateFilter(value, 'MM/dd/yyyy hh:mm:ss a') : null;
}

function formatAsBoolean(value: unknown) {
  return isBoolean(value) ? (value ? 'Yes' : 'No') : null;
}

function formatAsPhone(value: unknown) {
  if (!isString(value) && !isNumber(value)) return null;

  const chars = value.toString().split('');
  let countryCode = '';

  if (chars.length > 10) {
    countryCode = '+' + chars.splice(0, chars.length - 10).join('') + ' ';
  }

  chars.splice(0, 0, '(');
  chars.splice(4, 0, ') ');
  chars.splice(8, 0, '-');

  return countryCode + chars.join('');
}

//#endregion Cell Value Formatters
