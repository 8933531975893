var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-popover",
    _vm._b(
      { attrs: { triggers: "hover", placement: "bottom" } },
      "b-popover",
      { target: _vm.target },
      false
    ),
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex justify-content-center align-items-center flex-column p-3",
        },
        [
          _c(
            "div",
            { staticClass: "h5 mb-3 d-flex align-items-center" },
            [
              _c("Icon", {
                style: { color: _setup.info.color },
                attrs: { icon: _setup.info.icon },
              }),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_setup.info.text) + " Certificate")]),
            ],
            1
          ),
          _vm._v(" "),
          _c("p", { staticClass: "m-0" }, [
            _vm._v("by Zephyr Verification Service"),
            _c("br"),
          ]),
          _vm._v(" "),
          _c(
            "b-button",
            {
              attrs: {
                to: { name: "certifications" },
                variant: "link",
                target: "_blank",
              },
            },
            [_vm._v("\n      Learn more\n    ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }