var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { class: ["separator", ..._setup.classList] },
    [
      _vm.text
        ? [_c("span"), _vm._v(_vm._s(_vm.text)), _c("span")]
        : _c("span"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }