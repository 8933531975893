export const NPL = {
  name: {
    common: 'Nepal',
    official: 'Federal Democratic Republic of Nepal',
    native: {
      nep: { official: 'नेपाल संघीय लोकतान्त्रिक गणतन्त्र', common: 'नपल' },
    },
  },
  cities: ['Patan', 'Jawlakhel', 'Kathmandu', 'Bharatpur', 'Palpa', 'Lumbini'],
  tld: ['.np'],
  cca2: 'NP',
  ccn3: '524',
  cca3: 'NPL',
  cioc: 'NEP',
  currency: ['NPR'],
  callingCode: ['977'],
  capital: 'Kathmandu',
  altSpellings: [
    'NP',
    'Federal Democratic Republic of Nepal',
    'Loktāntrik Ganatantra Nepāl',
  ],
  region: 'Asia',
  subregion: 'Southern Asia',
  languages: { nep: 'Nepali' },
  translations: {
    deu: { official: 'Demokratischen Bundesrepublik Nepal', common: 'Népal' },
    fra: { official: 'République du Népal', common: 'Népal' },
    hrv: { official: 'Savezna Demokratska Republika Nepal', common: 'Nepal' },
    ita: {
      official: 'Repubblica federale democratica del Nepal',
      common: 'Nepal',
    },
    jpn: { official: 'ネパール連邦民主共和国', common: 'ネパール' },
    nld: {
      official: 'Federale Democratische Republiek Nepal',
      common: 'Nepal',
    },
    por: {
      official: 'República Democrática Federal do Nepal',
      common: 'Nepal',
    },
    rus: {
      official: 'Федеративная Демократическая Республика Непал',
      common: 'Непал',
    },
    spa: {
      official: 'República Democrática Federal de Nepal',
      common: 'Nepal',
    },
    fin: {
      official: 'Nepalin demokraattinen liittotasavalta',
      common: 'Nepal',
    },
  },
  latlng: [28, 84],
  demonym: 'Nepalese',
  landlocked: true,
  borders: ['CHN', 'IND'],
  area: 147181,
  provinces: [
    'Bagmati',
    'Bheri',
    'Dhawalagiri',
    'Gandaki',
    'Janakpur',
    'Karnali',
    'Kosi',
    'Lumbini',
    'Mahakali',
    'Mechi',
    'Narayani',
    'Rapti',
    'Sagarmatha',
    'Seti',
  ],
  nativeName: 'नपल',
  timezones: ['UTC+05:45'],
} as const;
