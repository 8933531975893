import { server } from '@api/request';
import { ApiKey } from '@models';
import { isObject } from '@tools/type-guards';

// List ApiKeys
/**
 * List all Api Keys
 *
 * @return A list of ApiKeys
 */
export async function apiKeysList() {
  return await server.list('v2/admin/developer/api-keys', processApiKeyData);
}

export interface CreateApiKeyOptions {
  name: string;
  permissions: string[];
  userId?: string;
  organizationId?: string;
}

export async function createApiKey(options: CreateApiKeyOptions) {
  return await server.post('v2/admin/developer/api-keys', options);
}

export interface UpdateApiKeyOptions {
  apiKey: string;
  name: string;
  permissions: string[];
}

export async function updateApiKey(options: UpdateApiKeyOptions) {
  return await server.post('v2/admin/developer/api-keys/update', options);
}

//#region Helper Functions
/**
 *
 */
function isValidApiKeyData(value: unknown): value is ApiKey {
  if (!isObject(value)) return false;

  // TEMP: Use a more strict validation.

  return true;
}

/**
 *
 */
function processApiKeyData(data: unknown) {
  if (!isValidApiKeyData(data)) {
    throw new Error('Invalid drone data item.');
  }

  return { ...data } as ApiKey;
}
