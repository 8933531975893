import { Module, VuexModule, Mutation, Action } from '@vuex/decorators';
import { findIndex } from 'lodash';
import { api } from '@api';
import { Root } from '@store';
import { ApiKey } from '@models';
import { findById, addById, updateById } from '@utils/array';

declare module '@vuex/core' {
  export interface Getters {
    'apiKeys/findById': ApiKeys['findById'];
  }

  export interface CommitMap {
    'apiKeys/SET': ApiKeys['SET'];
    'apiKeys/ADD': ApiKeys['ADD'];
    'apiKeys/UPDATE': ApiKeys['UPDATE'];
    'apiKeys/CLEAR': ApiKeys['CLEAR'];
  }

  export interface DispatchMap {
    'apiKeys/list': ApiKeys['list'];
    'apiKeys/create': ApiKeys['create'];
    'apiKeys/update': ApiKeys['update'];
  }
}

@Module({ namespaced: true })
export class ApiKeys
  extends VuexModule<ApiKeys.State, Root.State>
  implements ApiKeys.State
{
  items: ApiKey[] = [];

  /** Fetch a specific ApiKey data object by ID */
  get findById() {
    return (id: string) => findById(this.items, id);
  }
  /**
   * ...
   */
  @Mutation
  SET(options: ApiKeys.SetMutationOptions) {
    this.items = options.data;
  }

  /**
   * ...
   */
  @Mutation
  ADD(options: ApiKeys.AddMutationOptions) {
    this.items = addById(this.items, options);
  }

  /**
   * ...
   */
  @Mutation
  UPDATE(options: ApiKeys.UpdateMutationOptions) {
    const items = [...this.items];

    const i = findIndex(items, { id: options.apiKey });

    if (i <= -1) {
      throw new Error(`ApiKey with ID ${options.apiKey} not found.`);
    }

    items[i] = Object.assign({}, items[i], options);

    this.items = items;
  }

  /**
   * ...
   */
  @Mutation
  CLEAR() {
    this.items = [];
  }

  //#endregion Mutations

  //#region Actions

  /**
   * ...
   */
  @Action
  async list() {
    const data = await api.developer.apiKeysList();

    this.context.commit('SET', { data });

    return this.items;
  }

  /**
   * ...
   */
  @Action
  async create(options: ApiKeys.CreateActionOptions) {
    const data = await api.developer.createApiKey(options);

    this.context.commit('ADD', data);

    return data;
  }

  /**
   * ...
   */
  @Action
  async update(options: ApiKeys.UpdateActionOptions) {
    await api.developer.updateApiKey(options);

    this.context.commit('UPDATE', options);

    return options;
  }
}

export namespace ApiKeys {
  export interface State {
    items: ApiKey[];
  }

  export interface SetMutationOptions {
    data: ApiKey[];
  }

  /** ... */
  export type AddMutationOptions = ApiKey;

  /** ... */
  export type CreateActionOptions = api.developer.CreateApiKeyOptions;

  /** ... */
  export type UpdateMutationOptions = api.developer.UpdateApiKeyOptions;

  /** ... */
  export type UpdateActionOptions = api.developer.UpdateApiKeyOptions;
}

export default ApiKeys;
