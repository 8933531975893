import { AxiosError } from 'axios';

import { ensureError } from '@tools/ensure-error';
import { isString } from '@tools/type-guards';

import { isServerlessResponse } from './validators';

/**
 * Error representing a failed API request as dictated by the API server.
 */
export class ApiRequestError extends Error {
  name = 'ApiRequestError';

  readonly status: number | null;
  readonly code: string;

  constructor(reason: string | AxiosError) {
    let message: string | null = null;
    let status: number | null = null;
    let code: string | null = null;

    if (isString(reason)) {
      message = reason;
    } else {
      status = reason.response?.status ?? null;
      code = reason.code ?? null;

      // ...
      const responseData = reason.response?.data;

      if (isServerlessResponse(responseData)) {
        message = responseData.message ?? null;
      } else {
        message = reason.message;
      }
    }

    super(message ?? 'An unknown error occurred.');

    this.status = status;
    this.code = code ?? 'UNKNOWN_ERROR';

    this.stack = (this.stack ?? '')
      .split('\n')
      .filter((_, i) => i !== 1)
      .join('\n');
  }
}

/**
 * Error representing an issue that occurred within a request response data
 * transform function.
 */
export class TransformError extends Error {
  name = 'TransformError';

  dataItem: unknown;
  description: string;

  constructor(dataItem: unknown, reason: unknown) {
    const description = ensureError(reason).message;

    super(`Failed to transform data item: ${description}`);

    this.dataItem = dataItem;
    this.description = description;
  }
}

/**
 * Error representing an issue that occurred within one of the functions of a
 * request response data transform function group.
 */
export class TransformGroupError extends Error {
  name = 'TransformGroupError';
  dataItems: unknown[];

  constructor(errors: TransformError[]) {
    const dataItems: unknown[] = [];
    const descriptions: string[] = [];

    for (const [index, error] of errors.entries()) {
      dataItems.push(error.dataItem);
      descriptions.push(`   - ${index}: ${error.description}`);
    }

    super(
      `Failed to transform ${errors.length} data items:\n\n` +
        descriptions.join('\n'),
    );

    this.dataItems = dataItems;
  }
}
