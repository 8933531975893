var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.CardStat, {
    attrs: {
      title: "Modules Completed",
      value: _setup.modulesCompleted,
      loading: _vm.loading,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }