var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "TransitionGroup",
    { attrs: { tag: "div", appear: "" } },
    _vm._l(_setup.instances, function (inst) {
      return _c(
        "b-card",
        {
          key: inst.key,
          staticClass: "card-load-placeholder",
          style: { transitionDelay: inst.transitionDelay },
        },
        _vm._l(inst.lines, function (line) {
          return _c("b-skeleton", {
            key: line.key,
            attrs: { width: line.width },
          })
        }),
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }