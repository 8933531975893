import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

import siteNavLogo from '@assets/images/las-site-nav-logo.png';

type ImgType = 'PNG' | 'JPEG';

type ImgAlignment = 'center' | 'left' | 'right';

type TextAlignment = 'center' | 'left' | 'right' | 'justify';

interface AddTextOptions {
  align?: TextAlignment;
  size?: number;
  color?: Color;
}

type FormatArray = [number, number];

type TableRow = string[][];

interface TableOptions {
  showHead?: 'everyPage' | 'firstPage' | 'never' | boolean;
  showFoot?: 'everyPage' | 'lastPage' | 'never' | boolean;
  theme?: 'striped' | 'grid' | 'plain' | null;
  margin?: number;
  pageBreak?: 'auto' | 'avoid' | 'always';
  rowPageBreak?: 'auto' | 'avoid';
}

interface Color {
  r: number;
  g: number;
  b: number;
}

export interface DocProperties {
  format: 'letter' | 'legal' | FormatArray;
  orientation: 'portrait' | 'landscape';
  pageWidth: number;
  pageHeight: number;
  margin: number;
  // space between lines and elements
  lineSpacing: number;
  lineHeight: number;
  // computed from above properties
  pageWidthInner: number;
  pageHeightInner: number;
  pageCenter: number;
  // Y Position for tracking "cursor"
  yPos: number;
}

export interface Base64ImageObject {
  img: HTMLImageElement;
  width: number;
  height: number;
}

const DefaultTextProperties = {
  color: {
    r: 40,
    g: 40,
    b: 40,
  },
  size: 12,
  family: 'Helvetica',
};

export class PDFGeneratorService {
  /** Initializes a doc */
  create(docProps: DocProperties) {
    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      format: 'letter',
    });

    this.setDefaultTextProperties(doc);

    docProps.pageWidth = doc.internal.pageSize.getWidth();
    docProps.pageHeight = doc.internal.pageSize.getHeight();

    docProps.pageWidthInner = docProps.pageWidth - docProps.margin * 2;
    docProps.pageHeightInner = docProps.pageHeight - docProps.margin * 2;
    docProps.pageCenter = docProps.pageWidth / 2;

    docProps.yPos = docProps.margin;

    this.addFooter(doc, docProps);

    return doc;
  }

  /** ... */
  addImage(
    doc: jsPDF,
    img: HTMLImageElement,
    imgType: ImgType,
    alignment: ImgAlignment,
    width: number,
    height: number,
    docProps: DocProperties,
  ) {
    // https://raw.githack.com/MrRio/jsPDF/master/docs/module-addImage.html
    if (width > docProps.pageWidthInner)
      return console.error(
        `[PDF Generator] Image Width (${width}) is greater than Inner Doc Width (${docProps.pageWidthInner})`,
      );

    this.checkEndOfPage(height, docProps, doc);

    let x = 0;

    if (alignment === 'left') x = docProps.margin;
    if (alignment === 'right')
      x = docProps.margin + docProps.pageWidthInner - width;
    if (alignment === 'center') x = docProps.pageCenter - width / 2;

    doc.addImage(img, imgType, x, docProps.yPos, width, height);

    // set new Y position after placing image
    docProps.yPos += height + docProps.margin + docProps.lineSpacing;
  }

  /** 
 * jsPDF Standard font options are:
    Courier
    Courier-Bold
    Courier-BoldOblique
    Courier-Oblique
    Helvetica
    Helvetica-Bold
    Helvetica-BoldOblique
    Helvetica-Oblique
    Symbol
    Times-Roman
    Times-Bold
    Time-Italic
    Time-BoldItalic
 */
  addText(
    doc: jsPDF,
    docProps: DocProperties,
    text: string,
    options?: AddTextOptions,
  ) {
    if (options?.size) doc.setFontSize(options.size);
    if (options?.color)
      doc.setTextColor(options.color.r, options.color.g, options.color.b);

    const lines = doc.splitTextToSize(text, docProps.pageWidthInner);

    let x = docProps.margin;

    if (options?.align === 'center') x = docProps.pageWidth / 2;

    if (options?.align === 'right') x = docProps.pageWidth - docProps.margin;

    const textOptions = {
      align: options?.align ? options.align : 'left',
      x,
    };

    doc.text(
      lines,
      textOptions?.x ? textOptions.x : docProps.margin,
      docProps.yPos,
      textOptions,
    );

    // set yPos
    lines.forEach(() => this.newLine(doc, docProps));

    this.setDefaultTextProperties(doc);
  }

  /** ... */
  newLine(doc: jsPDF, docProps: DocProperties) {
    if (
      docProps.yPos + docProps.lineHeight >
      docProps.pageHeight - docProps.margin * 2
    )
      return this.addPage(doc, docProps);

    docProps.yPos += docProps.lineHeight;

    return docProps;
  }

  /** Adds new page */
  addPage(doc: jsPDF, docProps: DocProperties) {
    doc.addPage(docProps?.format, docProps?.orientation);

    // reset Y position
    docProps.yPos = docProps.margin;

    this.addFooter(doc, docProps);
  }

  /** adds LAS footer */
  addFooter(doc: jsPDF, docProps: DocProperties) {
    // las-site-nav-logo image ration is width = height * 5.53
    const width = 110.6;
    const height = 20;

    const lasImage: Base64ImageObject = this.getImageElem(
      siteNavLogo,
      width,
      height,
    );

    const x = docProps.pageWidth - width - 10;
    const y = docProps.pageHeight - height - 10;

    doc.addImage(lasImage.img, 'PNG', x, y, width, height);

    // add text
    doc.setFontSize(10);
    doc.setTextColor(90, 90, 90);
    doc.text(
      'Little Arms Studios, LLC\n2210 S Grace St Apt 111\nLombard, IL 60148 USA',
      docProps.margin,
      y,
      {
        align: 'left',
      },
    );
    doc.text(
      '(571) 719 - 6031\nsupport@littlearms.com',
      docProps.margin + 100,
      y,
      {
        align: 'left',
      },
    );
    // reset defaults
    doc.setFontSize(12);
    doc.setTextColor(25, 25, 25);
  }

  /** checks to see if we need to automatically create a new page */
  checkEndOfPage(elemHeight: number, docProps: DocProperties, doc: jsPDF) {
    // check current docProps.yPos relative to elemHeight and end of page
    // (minus margin)
    if (docProps.yPos + elemHeight > docProps.pageHeight - docProps.margin)
      this.addPage(doc, docProps);
  }

  /** ... */
  getImageElem(imageUrl: string, width: number, height: number) {
    const img = new Image(width, height);
    img.src = imageUrl;

    return {
      img,
      width: img.width,
      height: img.height,
    };
  }

  /** ... */
  setDefaultTextProperties(doc: jsPDF) {
    doc.setFont(DefaultTextProperties.family);
    doc.setTextColor(
      DefaultTextProperties.color.r,
      DefaultTextProperties.color.g,
      DefaultTextProperties.color.b,
    );
    doc.setFontSize(DefaultTextProperties.size);
  }

  /** ... */
  createTable(
    head: TableRow,
    body: TableRow,
    doc: jsPDF,
    docProps: DocProperties,
    tableOptions?: TableOptions,
  ) {
    if (!head[0])
      return console.error(`[PDF Generator - createTable] - Head[0] undefined`);

    if (!body[0])
      return console.error(`[PDF Generator - createTable] - Body[0] undefined`);

    autoTable(doc, {
      head,
      body,
      startY: docProps.yPos,
      theme: tableOptions?.theme ? tableOptions.theme : 'grid',
      rowPageBreak: tableOptions?.rowPageBreak
        ? tableOptions.rowPageBreak
        : 'avoid',
      pageBreak: tableOptions?.pageBreak ? tableOptions.pageBreak : 'avoid',
      showHead: tableOptions?.showHead ? tableOptions.showHead : 'firstPage',
      showFoot: tableOptions?.showFoot ? tableOptions.showFoot : 'lastPage',
      margin: tableOptions?.margin ? tableOptions.margin : docProps.margin,
      didDrawPage: (hookData) => {
        if (hookData.pageNumber > 1) this.addPage(doc, docProps);
        if (!hookData.cursor?.y) return;
        docProps.yPos = hookData.cursor.y + docProps.margin;
      },
    });
  }
}

export const pdfGenerator = new PDFGeneratorService();

export default pdfGenerator;
