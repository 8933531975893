export const FJI = {
  name: {
    common: 'Fiji',
    official: 'Republic of Fiji',
    native: {
      eng: { official: 'Republic of Fiji', common: 'Fiji' },
      fij: { official: 'Matanitu Tugalala o Viti', common: 'Viti' },
      hif: { official: 'रिपब्लिक ऑफ फीजी', common: 'फिजी' },
    },
  },
  cities: ['Suva', 'Lautoka', 'Nadi', 'Tamavua Heights'],
  tld: ['.fj'],
  cca2: 'FJ',
  ccn3: '242',
  cca3: 'FJI',
  cioc: 'FIJ',
  currency: ['FJD'],
  callingCode: ['679'],
  capital: 'Suva',
  altSpellings: [
    'FJ',
    'Viti',
    'Republic of Fiji',
    'Matanitu ko Viti',
    'Fijī Gaṇarājya',
  ],
  region: 'Oceania',
  subregion: 'Melanesia',
  languages: { eng: 'English', fij: 'Fijian', hif: 'Fiji Hindi' },
  translations: {
    deu: { official: 'Republik Fidschi', common: 'Fidschi' },
    fra: { official: 'République des Fidji', common: 'Fidji' },
    hrv: { official: 'Republika Fidži', common: 'Fiđi' },
    ita: { official: 'Repubblica di Figi', common: 'Figi' },
    jpn: { official: 'フィジー共和国', common: 'フィジー' },
    nld: { official: 'Republiek Fiji', common: 'Fiji' },
    por: { official: 'República de Fiji', common: 'Fiji' },
    rus: { official: 'Республика Фиджи', common: 'Фиджи' },
    spa: { official: 'República de Fiji', common: 'Fiyi' },
    fin: { official: 'Fidžin tasavalta', common: 'Fidži' },
  },
  latlng: [-18, 175],
  demonym: 'Fijian',
  landlocked: false,
  borders: [],
  area: 18272,
  provinces: ['Central', 'Eastern', 'Northern', 'Rotuma', 'Western'],
  nativeName: 'Fiji',
  timezones: ['UTC+12:00'],
} as const;
