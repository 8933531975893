export const NER = {
  name: {
    common: 'Niger',
    official: 'Republic of Niger',
    native: { fra: { official: 'République du Niger', common: 'Niger' } },
  },
  cities: ['Niamey'],
  tld: ['.ne'],
  cca2: 'NE',
  ccn3: '562',
  cca3: 'NER',
  cioc: 'NIG',
  currency: ['XOF'],
  callingCode: ['227'],
  capital: 'Niamey',
  altSpellings: ['NE', 'Nijar'],
  region: 'Africa',
  subregion: 'Western Africa',
  languages: { fra: 'French' },
  translations: {
    deu: { official: 'Republik Niger', common: 'Niger' },
    fra: { official: 'République du Niger', common: 'Niger' },
    hrv: { official: 'Republika Niger', common: 'Niger' },
    ita: { official: 'Repubblica del Niger', common: 'Niger' },
    jpn: { official: 'ニジェール共和国', common: 'ニジェール' },
    nld: { official: 'Republiek Niger', common: 'Niger' },
    por: { official: 'República do Níger', common: 'Níger' },
    rus: { official: 'Республика Нигер', common: 'Нигер' },
    spa: { official: 'República de Níger', common: 'Níger' },
    fin: { official: 'Nigerin tasavalta', common: 'Niger' },
  },
  latlng: [16, 8],
  demonym: 'Nigerien',
  landlocked: true,
  borders: ['DZA', 'BEN', 'BFA', 'TCD', 'LBY', 'MLI', 'NGA'],
  area: 1267000,
  provinces: [
    'Agadez',
    'Diffa',
    'Dosso',
    'Maradi',
    'Niamey',
    'Tahoua',
    'Tillaberi',
    'Zinder',
  ],
  nativeName: 'Niger',
  timezones: ['UTC+01:00'],
} as const;
