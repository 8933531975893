export const SGS = {
  name: {
    common: 'South Georgia',
    official: 'South Georgia and the South Sandwich Islands',
    native: {
      eng: {
        official: 'South Georgia and the South Sandwich Islands',
        common: 'South Georgia',
      },
    },
  },
  cities: ['Grytviken'],
  tld: ['.gs'],
  cca2: 'GS',
  ccn3: '239',
  cca3: 'SGS',
  cioc: '',
  currency: ['GBP'],
  callingCode: ['500'],
  capital: 'King Edward Point',
  altSpellings: ['GS', 'South Georgia and the South Sandwich Islands'],
  region: 'Americas',
  subregion: 'South America',
  languages: { eng: 'English' },
  translations: {
    deu: {
      official: 'Südgeorgien und die Südlichen Sandwichinseln',
      common: 'Südgeorgien und die Südlichen Sandwichinseln',
    },
    fra: {
      official: 'Géorgie du Sud et les îles Sandwich du Sud',
      common: 'Géorgie du Sud-et-les Îles Sandwich du Sud',
    },
    hrv: {
      official: 'Južna Džordžija i Otoci Južni Sendvič',
      common: 'Južna Georgija i otočje Južni Sandwich',
    },
    ita: {
      official: 'Georgia del Sud e isole Sandwich del Sud',
      common: 'Georgia del Sud e Isole Sandwich Meridionali',
    },
    jpn: {
      official: 'サウスジョージア·サウスサンドウィッチ諸島',
      common: 'サウスジョージア・サウスサンドウィッチ諸島',
    },
    nld: {
      official: 'Zuid-Georgië en de Zuidelijke Sandwich-eilanden',
      common: 'Zuid-Georgia en Zuidelijke Sandwicheilanden',
    },
    por: {
      official: 'Geórgia do Sul e Sandwich do Sul',
      common: 'Ilhas Geórgia do Sul e Sandwich do Sul',
    },
    rus: {
      official: 'Южная Георгия и Южные Сандвичевы острова',
      common: 'Южная Георгия и Южные Сандвичевы острова',
    },
    spa: {
      official: 'Georgia del Sur y las Islas Sandwich del Sur',
      common: 'Islas Georgias del Sur y Sandwich del Sur',
    },
    fin: {
      official: 'Etelä-Georgia ja Eteläiset Sandwichsaaret',
      common: 'Etelä-Georgia ja Eteläiset Sandwichsaaret',
    },
  },
  latlng: [-54.5, -37],
  demonym: 'South Georgian South Sandwich Islander',
  landlocked: false,
  borders: [],
  area: 3903,
} as const;
