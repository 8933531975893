export const FLK = {
  name: {
    common: 'Falkland Islands',
    official: 'Falkland Islands',
    native: {
      eng: { official: 'Falkland Islands', common: 'Falkland Islands' },
    },
  },
  cities: ['Stanley'],
  tld: ['.fk'],
  cca2: 'FK',
  ccn3: '238',
  cca3: 'FLK',
  cioc: '',
  currency: ['FKP'],
  callingCode: ['500'],
  capital: 'Stanley',
  altSpellings: ['FK', 'Islas Malvinas', 'Falkland Islands (Malvinas)'],
  region: 'Americas',
  subregion: 'South America',
  languages: { eng: 'English' },
  translations: {
    deu: { official: 'Falkland-Inseln', common: 'Falklandinseln' },
    fra: { official: 'Îles Malouines', common: 'Îles Malouines' },
    hrv: { official: 'Falklandski otoci', common: 'Falklandski Otoci' },
    ita: {
      official: 'Isole Falkland',
      common: 'Isole Falkland o Isole Malvine',
    },
    jpn: {
      official: 'フォークランド',
      common: 'フォークランド（マルビナス）諸島',
    },
    nld: { official: 'Falkland eilanden', common: 'Falklandeilanden' },
    por: { official: 'Ilhas Malvinas', common: 'Ilhas Malvinas' },
    rus: { official: 'Фолклендские острова', common: 'Фолклендские острова' },
    spa: { official: 'islas Malvinas', common: 'Islas Malvinas' },
    fin: { official: 'Falkandinsaaret', common: 'Falkandinsaaret' },
  },
  latlng: [-51.75, -59],
  demonym: 'Falkland Islander',
  landlocked: false,
  borders: [],
  area: 12173,
  provinces: ['Falkland Islands (Islas Malvinas)'],
  nativeName: 'Falkland Islands',
} as const;
