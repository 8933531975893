export const NRU = {
  name: {
    common: 'Nauru',
    official: 'Republic of Nauru',
    native: {
      eng: { official: 'Republic of Nauru', common: 'Nauru' },
      nau: { official: 'Republic of Nauru', common: 'Nauru' },
    },
  },
  cities: ['Anabar'],
  tld: ['.nr'],
  cca2: 'NR',
  ccn3: '520',
  cca3: 'NRU',
  cioc: 'NRU',
  currency: ['AUD'],
  callingCode: ['674'],
  capital: 'Yaren',
  altSpellings: [
    'NR',
    'Naoero',
    'Pleasant Island',
    'Republic of Nauru',
    'Ripublik Naoero',
  ],
  region: 'Oceania',
  subregion: 'Micronesia',
  languages: { eng: 'English', nau: 'Nauru' },
  translations: {
    deu: { official: 'Republik Nauru', common: 'Nauru' },
    fra: { official: 'République de Nauru', common: 'Nauru' },
    hrv: { official: 'Republika Nauru', common: 'Nauru' },
    ita: { official: 'Repubblica di Nauru', common: 'Nauru' },
    jpn: { official: 'ナウル共和国', common: 'ナウル' },
    nld: { official: 'Republiek Nauru', common: 'Nauru' },
    por: { official: 'República de Nauru', common: 'Nauru' },
    rus: { official: 'Республика Науру', common: 'Науру' },
    spa: { official: 'República de Nauru', common: 'Nauru' },
    fin: { official: 'Naurun tasavalta', common: 'Nauru' },
  },
  latlng: [-0.53333333, 166.91666666],
  demonym: 'Nauruan',
  landlocked: false,
  borders: [],
  area: 21,
} as const;
