export const MSR = {
  name: {
    common: 'Montserrat',
    official: 'Montserrat',
    native: { eng: { official: 'Montserrat', common: 'Montserrat' } },
  },
  cities: ['Davy Hill', 'Salem'],
  tld: ['.ms'],
  cca2: 'MS',
  ccn3: '500',
  cca3: 'MSR',
  cioc: '',
  currency: ['XCD'],
  callingCode: ['1664'],
  capital: 'Plymouth',
  altSpellings: ['MS'],
  region: 'Americas',
  subregion: 'Caribbean',
  languages: { eng: 'English' },
  translations: {
    deu: { official: 'Montserrat', common: 'Montserrat' },
    fra: { official: 'Montserrat', common: 'Montserrat' },
    hrv: { official: 'Montserrat', common: 'Montserrat' },
    ita: { official: 'Montserrat', common: 'Montserrat' },
    jpn: { official: 'モントセラト', common: 'モントセラト' },
    nld: { official: 'Montserrat', common: 'Montserrat' },
    por: { official: 'Montserrat', common: 'Montserrat' },
    rus: { official: 'Монтсеррат', common: 'Монтсеррат' },
    spa: { official: 'Montserrat', common: 'Montserrat' },
    fin: { official: 'Montserrat', common: 'Montserrat' },
  },
  latlng: [16.75, -62.2],
  demonym: 'Montserratian',
  landlocked: false,
  borders: [],
  area: 102,
} as const;
