export const BRN = {
  name: {
    common: 'Brunei',
    official: 'Nation of Brunei, Abode of Peace',
    native: {
      msa: {
        official: 'Nation of Brunei, Abode Damai',
        common: 'Negara Brunei Darussalam',
      },
    },
  },
  cities: [
    'Bandar Seri Begawan',
    'Seria',
    'Tutong',
    'Kuala Belait',
    'Kampong Jerudong',
    'Kuala Balai',
    'Panaga',
    'Bangar',
  ],
  tld: ['.bn'],
  cca2: 'BN',
  ccn3: '096',
  cca3: 'BRN',
  cioc: 'BRU',
  currency: ['BND'],
  callingCode: ['673'],
  capital: 'Bandar Seri Begawan',
  altSpellings: [
    'BN',
    'Brunei Darussalam',
    'Nation of Brunei',
    'the Abode of Peace',
  ],
  region: 'Asia',
  subregion: 'South-Eastern Asia',
  languages: { msa: 'Malay' },
  translations: {
    cym: { official: 'Nation of Brunei, Abode of Peace', common: 'Brunei' },
    deu: {
      official: 'Nation von Brunei, Wohnung des Friedens',
      common: 'Brunei',
    },
    fra: { official: 'État de Brunei Darussalam', common: 'Brunei' },
    hrv: { official: 'Nacija od Bruneja, Kuću Mira', common: 'Brunej' },
    ita: { official: 'Nazione di Brunei, Dimora della Pace', common: 'Brunei' },
    jpn: {
      official: 'ブルネイ、平和の精舎の国家',
      common: 'ブルネイ・ダルサラーム',
    },
    nld: {
      official: 'Natie van Brunei, de verblijfplaats van de Vrede',
      common: 'Brunei',
    },
    por: { official: 'Nação do Brunei, Morada da Paz', common: 'Brunei' },
    rus: { official: 'Нация Бруней, обитель мира', common: 'Бруней' },
    spa: { official: 'Nación de Brunei, Morada de la Paz', common: 'Brunei' },
    fin: { official: 'Brunei Darussalamin valtio', common: 'Brunei' },
  },
  latlng: [4.5, 114.66666666],
  demonym: 'Bruneian',
  landlocked: false,
  borders: ['MYS'],
  area: 5765,
  provinces: ['Belait', 'Brunei and Muara', 'Temburong', 'Tutong'],
  nativeName: 'Negara Brunei Darussalam',
  timezones: ['UTC+08:00'],
} as const;
