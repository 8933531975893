var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("Transition", [
    _setup.hints
      ? _c(
          "div",
          { staticClass: "keyboard-shortcut-helper" },
          [
            _c("div", { staticClass: "helper-header" }, [
              _vm._v("AVAILABLE KEYBOARD SHORTCUTS"),
            ]),
            _vm._v(" "),
            _vm._l(_setup.hints, function ({ id, label, keys }) {
              return _c(
                "div",
                { key: id, staticClass: "helper-item" },
                [
                  _c(
                    "span",
                    { staticClass: "key-group" },
                    _vm._l(keys, function ({ key, pressed }) {
                      return _c("span", {
                        key: key,
                        class: { pressed },
                        domProps: { textContent: _vm._s(key) },
                      })
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c("Spacer"),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(label))]),
                ],
                1
              )
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }