export const TCA = {
  name: {
    common: 'Turks and Caicos Islands',
    official: 'Turks and Caicos Islands',
    native: {
      eng: {
        official: 'Turks and Caicos Islands',
        common: 'Turks and Caicos Islands',
      },
    },
  },
  cities: ['Cockburn Harbour', 'Over Back The Damp', 'Balfour Town'],
  tld: ['.tc'],
  cca2: 'TC',
  ccn3: '796',
  cca3: 'TCA',
  cioc: '',
  currency: ['USD'],
  callingCode: ['1649'],
  capital: 'Cockburn Town',
  altSpellings: ['TC'],
  region: 'Americas',
  subregion: 'Caribbean',
  languages: { eng: 'English' },
  translations: {
    deu: {
      official: 'Turks und Caicos Inseln',
      common: 'Turks-und Caicosinseln',
    },
    fra: {
      official: 'Îles Turques et Caïques',
      common: 'Îles Turques-et-Caïques',
    },
    hrv: { official: 'Otoci Turks i Caicos', common: 'Otoci Turks i Caicos' },
    ita: { official: 'Turks e Caicos', common: 'Isole Turks e Caicos' },
    jpn: {
      official: 'タークス·カイコス諸島',
      common: 'タークス・カイコス諸島',
    },
    nld: {
      official: 'Turks-en Caicoseilanden',
      common: 'Turks-en Caicoseilanden',
    },
    por: { official: 'Ilhas Turks e Caicos', common: 'Ilhas Turks e Caicos' },
    rus: { official: 'Теркс и Кайкос острова', common: 'Теркс и Кайкос' },
    spa: { official: 'Islas Turcas y Caicos', common: 'Islas Turks y Caicos' },
    fin: { official: 'Turks-ja Caicossaaret', common: 'Turks-ja Caicossaaret' },
  },
  latlng: [21.75, -71.58333333],
  demonym: 'Turks and Caicos Islander',
  landlocked: false,
  borders: [],
  area: 948,
} as const;
