export const ISR = {
  name: {
    common: 'Israel',
    official: 'State of Israel',
    native: {
      ara: { official: 'دولة إسرائيل', common: 'إسرائيل' },
      heb: { official: 'מדינת ישראל', common: 'ישראל' },
    },
  },
  cities: [
    'Lod',
    'Tel Aviv',
    'Jerusalem',
    'Kfar Saba',
    'Or Yehuda',
    'Azor',
    'Bat Yam',
    'Ramat Gan',
    'Holon',
    'Ashdod',
    'Beersheba',
    'Yehud',
    'Rishon LeZion',
    'Hazav',
    'Ness Ziona',
    'Eilat',
    'Rehovot',
    'Herzliya',
    'Nahariya',
    'Haifa',
    'Shave Ziyyon',
    'Ramat HaSharon',
    'Ramat Poleg',
    'Kadima',
    "Karmi'el",
    'Pardesiyya',
    'Netanya',
    'Gan Yavne',
    'Petah Tikwah',
    'Ashqelon',
    'Savyon',
    'Naham',
    'Mazkeret Batya',
    '`Arugot',
    'Ofaqim',
    'Shetulim',
    'Hadera',
    'Qiryat Bialik',
    'Tel Mond',
    'Hod HaSharon',
    'Qiryat Yam',
    'Shelomi',
    'Binyamina',
    'Liman',
    '`Alma',
    'Ramla',
    'Nazareth',
    'Et Taiyiba',
    'Rinnatya',
    'Yavne',
    'Acre',
    'Nazerat `Illit',
    'Baraq',
    'Even Yehuda',
    'Nesher',
    'Pardes Hanna Karkur',
    'Netivot',
    'Tirat Karmel',
    'Or `Aqiva',
    'Kefar Yona',
    'Bet Oren',
    'Haluz',
    'Biriyya',
    'Gan Hayyim',
    'Qiryat Tiv`on',
    'Qiryat Gat',
    "Be'er Toviyya",
    '`En Ayyala',
    'Misgav Regional Council',
    'Safed',
    'Gedera',
    'Dimona',
    'Bet Shemesh',
    '`En HaShelosha',
    'Herut',
    'Mazor',
    'Shamir',
    "Ra'anana",
    'Bat Hadar',
    "Rosh Ha'Ayin",
    'Zoran',
    'Tirat Yehuda',
    'Bareqet',
    'Elyakhin',
    'Hadid',
    'Hadar Ramatayim',
    'Modiin',
    'Hazor Ashdod',
    'Kefar Daniyyel',
    'Newe Efrayim',
    'Talme Menashe',
    'Kefar Netter',
    'Mazliah',
    'Moran',
    'Shoval',
    'Ramat Aviv',
    'Afiqim',
    'Ramat Dawid',
    'Sde Warburg',
    'Magen',
    'Karkur',
    'Qiryat Ata',
    'Maghar',
    'Bet Alfa',
    'Timrat',
    'Elyaqim',
    'Bet Dagan',
    'Ginnosar',
    "Qiryat Mal'akhi",
    'Hadar `Am',
    'maalot Tarshiha',
    'Ahituv',
    'Qiryat Motzkin',
    'Migdal',
    'Bat Hefer',
    'Ben Shemen-Kefar Hano`ar',
    'Emunim',
    'Tiberias',
    'Mikhmoret',
    'Hever',
    'Ramat Yishay',
    'Sderot',
    'Gimzo',
    'Mesillat Ziyyon',
    'Pasuta',
    "Bet She'an",
    'Newe Yamin',
    'Nordiyya',
    'HaKarmel',
    'Gibbeton',
    'Qiryat Ono',
    'Sarid',
    'Nir Zevi',
    'Ramat Ef`al',
    'Bene Ziyyon',
    'Hurfeish',
    'Shefayim',
    'Dabburiya',
    'Rishpon',
    'Mizpe Netofa',
    'Sde Boker',
    'Yaqum',
    'Bahan',
    'Jaffa',
    'Allonim',
    '`Evron',
    'Hazor HaGelilit',
    'Ramot Naftali',
    'Giv`at Hayyim',
    'Urim',
    'Sedot Yam',
    '`Aseret',
    'Nirim',
    '`Amir',
    'Ganne Tiqwa',
    "Ma`agan Mikha'el",
    'Qiryat Hayyim',
    'Kefar Witqin',
  ],
  tld: ['.il'],
  cca2: 'IL',
  ccn3: '376',
  cca3: 'ISR',
  cioc: 'ISR',
  currency: ['ILS'],
  callingCode: ['972'],
  capital: 'Jerusalem',
  altSpellings: ['IL', 'State of Israel', "Medīnat Yisrā'el"],
  region: 'Asia',
  subregion: 'Western Asia',
  languages: { ara: 'Arabic', heb: 'Hebrew' },
  translations: {
    deu: { official: 'Staat Israel', common: 'Israel' },
    fra: { official: "État d'Israël", common: 'Israël' },
    hrv: { official: 'Država Izrael', common: 'Izrael' },
    ita: { official: 'Stato di Israele', common: 'Israele' },
    jpn: { official: 'イスラエル国', common: 'イスラエル' },
    nld: { official: 'Staat Israël', common: 'Israël' },
    por: { official: 'Estado de Israel', common: 'Israel' },
    rus: { official: 'Государство Израиль', common: 'Израиль' },
    spa: { official: 'Estado de Israel', common: 'Israel' },
    fin: { official: 'Israelin valtio', common: 'Israel' },
  },
  latlng: [31.47, 35.13],
  demonym: 'Israeli',
  landlocked: false,
  borders: ['EGY', 'JOR', 'LBN', 'SYR'],
  area: 20770,
  provinces: [
    'Central',
    'Haifa',
    'Jerusalem',
    'Northern',
    'Southern',
    'Tel Aviv',
  ],
  nativeName: 'יִשְׂרָאֵל',
  timezones: ['UTC+02:00'],
} as const;
