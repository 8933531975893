import { Module, VuexModule, Mutation, Action } from '@vuex/decorators';
import { find } from 'lodash';

import { api } from '@api';
import { Announcement, Faq } from '@models';
import { Root } from '@store';

declare module '@vuex/core' {
  export interface Getters {
    'faqs/get': Faqs['get'];
  }

  export interface CommitMap {
    'faqs/SET': Faqs['SET'];
    'faqs/CLEAR': Faqs['CLEAR'];
  }

  export interface DispatchMap {
    'faqs/list': Faqs['list'];
    'faqs/update': Faqs['update'];
  }
}

@Module({ namespaced: true })
export class Faqs
  extends VuexModule<Faqs.State, Root.State>
  implements Faqs.State
{
  items: Faq[] = [];

  // region Mutations

  /** ... */
  get get() {
    return (announcementId: Announcement['id']) => {
      return find(this.items, { id: announcementId });
    };
  }

  /**
   * Set state items.
   *
   * @param options
   */
  @Mutation
  SET(options: Faqs.SetMutationOptions): void {
    this.items = options.data;
  }

  /**
   * Clear state.
   */
  @Mutation
  CLEAR() {
    this.items = [];
  }

  // endregion Mutations

  // region Actions

  /**
   * List FAQs.
   *
   * @return ...
   */
  @Action
  async list() {
    let data: Faq[] = [];

    data = await api.faqs.list();

    this.context.commit('SET', { data });

    return this.items;
  }

  /**
   * Update FAQs.
   *
   * @param options ...
   * @return ...
   */
  @Action
  async update(options: Faqs.UpdateActionOptions) {
    await api.faqs.update(options);

    this.context.commit('SET', options);

    return this.items;
  }

  // endregion Actions
}

export namespace Faqs {
  /**
   * ...
   */
  export interface State {
    items: Faq[];
  }

  /**
   * ...
   */
  export interface SetMutationOptions {
    data: Faq[];
  }

  /** ... */
  export type UpdateActionOptions = api.faqs.UpdateOptions;
}

export default Faqs;
