import { Module, Action } from '@vuex/decorators';
import { TablePanel } from 'table-panel';

import { faEdit } from '@icons/solid/faEdit';
import { faEye } from '@icons/solid/faEye';
import { ApiKey } from '@models';
import { modals } from '@modals';
import { alert } from '@services/alert';

/** ... */
const columns: TablePanel.Column<ApiKey>[] = [
  {
    key: 'name',
    label: 'Name',
    value: 'name',
  },
  { key: 'id', label: 'ID', value: 'id' },
  {
    key: 'permissions',
    label: 'Permissions',
    value: 'permissions',
  },
];

const operations: TablePanel.Row.Operation<ApiKey>[] = [
  {
    label: 'View',
    icon: faEye,
    fn: (apiKey) => {
      void modals.developer.viewApiKey({ apiKey });
    },
  },
  {
    label: 'Edit',
    icon: faEdit,
    fn: (apiKey) => {},
  },
];

@Module({ namespaced: true })
export class ApiKeyTablePanel
  extends TablePanel<ApiKey>
  implements TablePanel.Props<ApiKey>
{
  readonly module = 'apiKeys';
  readonly loadAction = 'list';
  readonly pk = 'id';
  readonly label = 'API KEYS';
  readonly columns = columns;
  readonly operations = operations;
  readonly createAction = 'createApiKey';
  //   readonly deleteAction = 'deleteDLC';
  readonly enabled = true;
  readonly tableSortType = 'name';
  readonly tablePageSize = 50;

  @Action
  async createApiKey() {
    await modals.developer.createApiKey();
  }
}

// /**
//    * ...
//    */
// @Action
// async createDLC() {

// }

export namespace ApiKeyTablePanel {
  /** ... */
  export interface DeleteItemOptions {
    items: ApiKey[];
  }
}
