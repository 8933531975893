import { render, staticRenderFns } from "./TableCellInvoiceNumber.vue?vue&type=template&id=10e206fe&scoped=true"
import script from "./TableCellInvoiceNumber.vue?vue&type=script&setup=true&lang=ts"
export * from "./TableCellInvoiceNumber.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./TableCellInvoiceNumber.vue?vue&type=style&index=0&id=10e206fe&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10e206fe",
  null
  
)

export default component.exports