var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "FloatingLabel",
    _vm._b(
      { staticClass: "form-select" },
      "FloatingLabel",
      { label: _vm.label },
      false
    ),
    [
      _c(
        "b-form-select",
        _vm._g(
          _vm._b(
            {
              attrs: { placeholder: _vm.label },
              scopedSlots: _vm._u(
                [
                  _vm._l(_vm.$scopedSlots, function (_, slot) {
                    return {
                      key: slot,
                      fn: function (scope) {
                        return [_vm._t(slot, null, null, scope)]
                      },
                    }
                  }),
                ],
                null,
                true
              ),
            },
            "b-form-select",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }