export const COL = {
  name: {
    common: 'Colombia',
    official: 'Republic of Colombia',
    native: { spa: { official: 'República de Colombia', common: 'Colombia' } },
  },
  cities: [
    'MedellÃ­n',
    'BogotÃ¡',
    'Barranquilla',
    'Miami',
    'Cundinamarca',
    'Antioquia',
    'Magdalena',
    'Palmira',
    'Villavicencio',
    'Bermudez',
    'Acevedo',
    'Cartagena',
    'Bucaramanga',
    'San AndrÃ©s',
    'Neiva',
    'Santiago de Cali',
    'Ibague',
    'Buenaventura',
    'CÃºcuta',
    'Chocho',
    'Valledupar',
    'Tarapaca',
    'Engativa',
    'Inirida',
    'Duitama',
    'Fusagasuga',
    'Envigado',
    'Itagui',
    'Pereira',
    'Armenia',
    'Manizales',
    'Santa Rosa de Cabal',
    'Floridablanca',
    'Santa Marta',
    'Riohacha',
    'Sincelejo',
    'Pasto',
    'TuluÃ¡',
    'Tunja',
    'Yopal',
    'Acacias',
    'Bello',
    'Rionegro',
    'Popayan',
    'Chia',
    'Municipio de Copacabana',
    'La Estrella',
    'CalarcÃ¡',
    'Barrancabermeja',
    'San Diego',
    'San Clemente',
    'Piedecuesta',
    'Tauramena',
    'Giron',
    'Calamar',
    'San Martin',
    'Urumita',
    'Dosquebradas',
    'Fundacion',
    'Soacha',
    'Turbaco',
    'Villamaria',
    'Soledad',
    'Tocancipa',
    'Tenjo',
    'Cajica',
    'Buga',
    'MonterÃ­a',
    'Villa del Rosario',
    'Silvania',
    'Florencia',
    'Candelaria',
    'Montenegro',
    'Sogamoso',
    'Espinal',
    'Sabaneta',
    'Planadas',
    'FacatativÃ¡',
    'Madrid',
    'Puerto CarreÃ±o',
    'El Colegio',
    'Ipiales',
    'Galapa',
    'La Mesa',
    'Ginebra',
    'Yotoco',
    'Obando',
    'ZipaquirÃ¡',
    'Sachica',
    'Corozal',
    'San Gil',
    'Pamplona',
    'Purificacion',
    'Boyaca',
    'Chiquinquira',
    'Caldas',
    'Ubaque',
    'Arauca',
    'Sabana de Torres',
    'Los Patios',
    'Cauca',
    'Aguachica',
    'Santa Rosa del Sur',
    'Mitu',
    'Fonseca',
    'Velez',
    'Pitalito',
    'San JosÃ© del Guaviare',
    'Sopo',
    'Cartago',
    'La Union',
    'Guarne',
    'La Ceja',
    'Yumbo',
    'Columbia',
    'Atlantico',
    'Mosquera',
    'Cumaribo',
    'Puerto Tejada',
    'Cota',
    'Puerta Roja',
    'Risaralda',
  ],
  tld: ['.co'],
  cca2: 'CO',
  ccn3: '170',
  cca3: 'COL',
  cioc: 'COL',
  currency: ['COP'],
  callingCode: ['57'],
  capital: 'Bogotá',
  altSpellings: ['CO', 'Republic of Colombia', 'República de Colombia'],
  region: 'Americas',
  subregion: 'South America',
  languages: { spa: 'Spanish' },
  translations: {
    cym: { official: 'Republic of Colombia', common: 'Colombia' },
    deu: { official: 'Republik Kolumbien', common: 'Kolumbien' },
    fra: { official: 'République de Colombie', common: 'Colombie' },
    hrv: { official: 'Republika Kolumbija', common: 'Kolumbija' },
    ita: { official: 'Repubblica di Colombia', common: 'Colombia' },
    jpn: { official: 'コロンビア共和国', common: 'コロンビア' },
    nld: { official: 'Republiek Colombia', common: 'Colombia' },
    por: { official: 'República da Colômbia', common: 'Colômbia' },
    rus: { official: 'Республика Колумбия', common: 'Колумбия' },
    spa: { official: 'República de Colombia', common: 'Colombia' },
    fin: { official: 'Kolumbian tasavalta', common: 'Kolumbia' },
  },
  latlng: [4, -72],
  demonym: 'Colombian',
  landlocked: false,
  borders: ['BRA', 'ECU', 'PAN', 'PER', 'VEN'],
  area: 1141748,
  provinces: [
    'Amazonas',
    'Antioquia',
    'Arauca',
    'Atlantico',
    'Bolivar',
    'Boyaca',
    'Caldas',
    'Caqueta',
    'Casanare',
    'Cauca',
    'Cesar',
    'Choco',
    'Cordoba',
    'Cundinamarca',
    'Distrito Capital de Santa Fe de Bogota',
    'Guainia',
    'Guaviare',
    'Huila',
    'La Guajira',
    'Magdalena',
    'Meta',
    'Narino',
    'Norte de Santander',
    'Putumayo',
    'Quindio',
    'Risaralda',
    'San Andres y Providencia',
    'Santander',
    'Sucre',
    'Tolima',
    'Valle del Cauca',
    'Vaupes',
    'Vichada',
  ],
  nativeName: 'Colombia',
  timezones: ['UTC−05:00'],
} as const;
