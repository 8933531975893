import { Module, Action } from '@vuex/decorators';
import { TablePanel } from 'table-panel';

import { faEdit } from '@icons/solid/faEdit';
import { faUser } from '@icons/solid/faUser';
import { faEye } from '@icons/solid/faEye';

import { Reseller } from '@models';
import { modals } from '@modals';

/** ... */
const columns: TablePanel.Column<Reseller>[] = [
  {
    key: 'name',
    label: 'Name',
    value: 'name',
  },
];

/** ... */
const operations: TablePanel.Row.Operation<Reseller>[] = [
  {
    label: 'Edit',
    icon: faEdit,
    fn: (reseller) => {
      void modals.reseller.edit({ reseller });
    },
  },
  {
    label: 'Invite Reseller Users',
    icon: faUser,
    fn: (reseller) => {
      void modals.user.inviteReseller({ resellerId: reseller.id });
    },
  },
  {
    label: 'View Reseller Logs',
    icon: faEye,
    fn: (reseller) => {
      void modals.reseller.view({ reseller });
    },
  },
];

@Module({ namespaced: true })
export class ResellerTablePanel
  extends TablePanel<Reseller>
  implements TablePanel.Props<Reseller>
{
  readonly module = 'resellers';
  readonly loadAction = 'list';
  readonly pk = 'id';
  readonly label = 'RESELLERS';
  readonly columns = columns;
  readonly operations = operations;
  readonly createAction = 'createReseller';
  readonly deleteAction = 'deleteReseller';

  get enabled() {
    return this.isActiveRole(9);
  }

  @Action
  async createReseller() {
    await modals.reseller.create();
  }
}

export namespace ResellerTablePanel {
  /** ... */
  export interface DeleteItemOptions {
    items: Reseller[];
  }
}
