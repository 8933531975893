export const BEL = {
  name: {
    common: 'Belgium',
    official: 'Kingdom of Belgium',
    native: {
      deu: { official: 'Königreich Belgien', common: 'Belgien' },
      fra: { official: 'Royaume de Belgique', common: 'Belgique' },
      nld: { official: 'Koninkrijk België', common: 'België' },
    },
  },
  cities: [
    'Mortsel',
    'Wilrijk',
    'Kontich',
    'Vise',
    'Brussels',
    'Wetteren',
    'LiÃ¨ge',
    'Antwerp',
    'Mechelen',
    'Puurs',
    'Braine-le-Chateau',
    'Namur',
    'Sint-Michiels',
    'Brasmenil',
    'Bruges',
    'Lives-sur-Meuse',
    'Seraing',
    'Pepinster',
    'Beaufays',
    'Hannut',
    'Argenteau',
    'Angleur',
    'Hollogne',
    'Hognoul',
    'Crisnee',
    'Fexhe-Slins',
    'Lixhe',
    'Marche-en-Famenne',
    'Ougree',
    'Awans',
    'Mons',
    'Huy',
    'Ans',
    'Modave',
    'Wanze',
    'Flobecq',
    'Beyne-Heusay',
    'Magnee',
    'Oupeye',
    'Herstal',
    'Grez-Doiceau',
    'Holland',
    'Turnhout',
    'Ghent',
    'Waregem',
    'Heverlee',
    'Kortrijk',
    'Zwijndrecht',
    'Merksem',
    'Sint-Genesius-Rode',
    'Hasselt',
    'Eupen',
    'Wemmel',
    'Vilvoorde',
    'Strombeek-Bever',
    'Kraainem',
    'Wilsele',
    'Duffel',
    'Dworp',
    'Linkebeek',
    'Jemeppe-sur-Meuse',
    'Franiere',
    'Chenee',
    'Jette',
    'Ixelles-Elsene',
    'La Hulpe',
    'Rixensart',
    'Evere',
    'Overijse',
    'Hoeilaart',
    'Sint-Niklaas',
    'Zaventem',
    'Denderleeuw',
    'Destelbergen',
    'Lochristi',
    'Wachtebeke',
    'Kalken',
    'Zaffelare',
    'Gentbrugge',
    'Merelbeke',
    'Appelterre-Eichem',
    'Haaltert',
    'Ninove',
    'Nederhasselt',
    'Outer',
    'Gijzegem',
    'Viane',
    'Idegem',
    'Geraardsbergen',
    'Grimminge',
    'Opbrakel',
    'Nieuwenhove',
    'Nederbrakel',
    'Everbeek',
    'Waasmunster',
    'Temse',
    'Beveren',
    'Melsele',
    'Zottegem',
    'Sint-Amandsberg',
    'Herzele',
    'Erpe',
    'Hamme',
    'Buggenhout',
    'Bornem',
    'Londerzeel',
    'Malderen',
    'Essene',
    'Aalst',
    'Opwijk',
    'Dendermonde',
    'Sint-Gillis-bij-Dendermonde',
    'Baasrode',
    'Lokeren',
    'Eksaarde',
    'De Pinte',
    'Meldert',
    'Moerzeke',
    'Lebbeke',
    'Belsele',
    'Sint-Martens-Latem',
    'Kruibeke',
    'De Klinge',
    'Wondelgem',
    'Oostakker',
    'Zelzate',
    'Stekene',
    'Bazel',
    'Steendorp',
    'Sint-Gillis-Waas',
    'Schendelbeke',
    'Ressegem',
    'Deftinge',
    'Schellebelle',
    'Berlare',
    'Zele',
    'Burcht',
    'Mespelare',
    'Sint-Amands',
    'Heusden',
    'Laarne',
    'Erembodegem',
    'Iddergem',
    'Sint-Lievens-Houtem',
    'Welle',
    'Nieuwerkerken',
    'Herdersem',
    'Asse',
    'Hofstade',
    'Moerbeke',
    'Vlekkem',
    'Overmere',
    'Meerbeke',
    'Pollare',
    'Denderhoutem',
    'Moorsel',
    'Mariakerke',
    'Drongen',
    'Kapellen',
    'Sint-Pauwels',
    'Kemzeke',
    'Eeklo',
    'Evergem',
    'Zwijnaarde',
    'Deurle',
    'Oppuurs',
    'Oudegem',
    'Appels',
    'Schoonaarde',
    'Hingene',
    'Hekelgem',
    'Wichelen',
    'Sint-Denijs-Westrem',
    'Afsnee',
    'Erondegem',
    'Verrebroek',
    'Teralfene',
    'Borsbeke',
    'Elversele',
    'Zeveneken',
    'Beervelde',
    'Merendree',
    'Berchem',
    'Grembergen',
    'Drogenbos',
    'Anderlecht',
    'Perwez',
    'Landelies',
    'Villers-la-Ville',
    'Fleurus',
    'Thuin',
    'Montignies-sur-Sambre',
    'Gembloux',
    'Wavre',
    'Chapelle-lez-Herlaimont',
    'Marcinelle',
    'Presles',
    'Charleroi',
    'Gilly',
    'Lodelinsart',
    'Roux',
    'Labuissiere',
    'Le Tilleul',
    'Cour-sur-Heure',
    'Ham-sur-Heure',
    'Nalinnes',
    'Bierges',
    'Tamines',
    'Chatelineau',
    'Rochefort',
    'Jemelle',
    'Chatelet',
    'Godarville',
    'Forchies-la-Marche',
    'Falisolle',
    'Mont-sur-Marchienne',
    'Obaix',
    'Fontaine-Valmont',
    'Ransart',
    'Trazegnies',
    'Marchienne-au-Pont',
    'Courcelles',
    'Jumet',
    'Haine-Saint-Paul',
    'Peronnes',
    'Leval-Trahegnies',
    'Hyon',
    'Gosselies',
    'Ruiselede',
    'Lede',
    'Mouscron',
    'Tervuren',
    'Dilbeek',
    'Ohain',
    'Waterloo',
    'Groot-Bijgaarden',
    'Sint-Agatha-Berchem',
    'Uccle',
    'Schepdaal',
    'Sint-Pieters-Leeuw',
    'Erps-Kwerps',
    'Beersel',
    'Huizingen',
    'Meise',
    'Grimbergen',
    'Ganshoren',
    'Itterbeek',
    'Zellik',
    'Alsemberg',
    'Boutersem',
    'Tubize',
    'Arlon',
    'Melle',
    'Quievrain',
    "Meslin-l'Eveque",
    'Loupoigne',
    'Frasnes-lez-Buissenal',
    'Erbaut',
    'Barry',
    'Montignies-lez-Lens',
    'Ellezelles',
    'Dison',
    'Soumagne',
    'Lontzen',
    'Beez',
    'Jambes',
    'Etalle',
    'Gesves',
    'Profondeville',
    'Dave',
    'Verviers',
    'Andenne',
    'Jauche',
    'Sprimont',
    'Thimister',
    'Saint-Georges-sur-Meuse',
    'Vielsalm',
    'Zeebrugge',
    'Romsee',
    'Wezembeek-Oppem',
    'Loncin',
    'Manhay',
    'Hamoir',
    'Erpent',
    'Gerpinnes',
    'Transinne',
    'Steenokkerzeel',
    'Woluwe-Saint-Pierre - Sint-Pieters-Woluwe',
    'Brecht',
    'Liedekerke',
    'Wezemaal',
    'Roeselare',
    'Vorselaar',
    'Denderbelle',
    'Deurne',
    'Berchem',
    'Borsbeek',
    'Wuustwezel',
    'Havre',
    'Geel',
    'Mol',
    'Dessel',
    'Balen',
    'Wijnegem',
    'Bonheiden',
    'Aartselaar',
    'Nijlen',
    'Zemst',
    'Sint-Katelijne-Waver',
    'Onze-Lieve-Vrouw-Waver',
    'Keerbergen',
    'Elewijt',
    'Willebroek',
    'Tremelo',
    'Brasschaat',
    'Westerlo',
    'Kalmthout',
    'Stabroek',
    'Veerle',
    'Hoevenen',
    'Wommelgem',
    'Itegem',
    'Booischot',
    'Heist-op-den-Berg',
    'Wiekevorst',
    'Schoten',
    'Schilde',
    'Ekeren',
    'Zoersel',
    'Arendonk',
    'Poppel',
    'Meerle',
    'Vlezenbeek',
    'Kortenberg',
    'Izegem',
    'Leuven',
    'Kampenhout',
    'Gavere',
    'Scherpenheuvel',
    'Paal',
    'Lanaken',
    'Eksel',
    'Zonhoven',
    'Alken',
    'Maasmechelen',
    'Overpelt',
    'Sint-Truiden',
    'Bilzen',
    'Bunsbeek',
    'Zandhoven',
    'Aalter',
    'Diksmuide',
    'Kuurne',
    'Oudenaarde',
    'Deinze',
    'Gistel',
    'Deerlijk',
    'Zedelgem',
    'Hever',
    'Ranst',
    'Oosterzele',
    'Grobbendonk',
    'Pulderbos',
    'Ravels',
    'Borgerhout',
    'Vosselaar',
    'Beerse',
    'Bouwel',
    'Westmalle',
    'Aarschot',
    'Hoboken',
    'Tisselt',
    'Weelde',
    'Kapelle-op-den-Bos',
    'Haacht',
    'Essen',
    'Oud-Turnhout',
    'Boechout',
    'Rumst',
    'Reet',
    'Muizen',
    'Herenthout',
    'Berlaar',
    'Hulshout',
    'Rotselaar',
    'Putte',
    'Herentals',
    'Morkhoven',
    'Retie',
    'Wechelderzande',
    'Beerzel',
    'Halle',
    'Lembeek',
    'Wolvertem',
    'Lennik',
    'Sterrebeek',
    'Humbeek',
    'Beigem',
    'Ternat',
    'Lot',
    'Merchtem',
    'Etterbeek',
    'Herne',
    'Vollezele',
    'Tienen',
    'Hoegaarden',
    'Woluwe-Saint-Lambert',
    'Buizingen',
    'Forest',
    'Nossegem',
    'Schaarbeek',
    'Gooik',
    'Galmaarden',
    'Machelen',
    'Werchter',
    'Herent',
    'Olen',
    'Lille',
    'Gierle',
    'Kasterlee',
    'Waarloos',
    'Tongerlo',
    'Rijmenam',
    'Hombeek',
    'Leest',
    'Boortmeerbeek',
    'Muizen',
    'Lint',
    'Edegem',
    'Hove',
    'Koningshooikt',
    'Anzegem',
    'Ingooigem',
    'Tielt',
    'Meulebeke',
    'Ingelmunster',
    'Dentergem',
    'Veldegem',
    'Middelkerke',
    'Westende',
    'Ostend',
    'Oostrozebeke',
    'Ledegem',
    'Assenede',
    'Jabbeke',
    'Oudenburg',
    'Nevele',
    'Kaprijke',
    'Lovendegem',
    'Rumbeke',
    'Moorslede',
    'Zonnebeke',
    'Dadizele',
    'Beveren',
    'Maldegem',
    'Adegem',
    'Wingene',
    'Sint-Laureins',
    'Knesselare',
    'Waarschoot',
    'Park',
    'Nameche',
    'Yvoir',
    'Molenbeek-Saint-Jean',
    'Anseroeul',
    'Ittre',
    'Chievres',
    'Manage',
    'Arsimont',
    'La LouviÃ¨re',
    'Sirault',
    'Kain',
    'Dampremy',
    'Corroy-le-Grand',
    'Jemeppe-sur-Sambre',
    'Tournai',
    'Bonlez',
    'Chercq',
    'Wasmes',
    'Chaumont-Gistoux',
    'Court-Saint-Etienne',
    'Nodebais',
    'Nethen',
    'Wanfercee-Baulet',
    'Bousval',
    'Mont-Saint-Guibert',
    'Lommel',
    'Niel',
    'Boom',
    'Hofstade',
    'Ciney',
    'Wepion',
    'Celles',
    'Vremde',
    'Goor',
    'Jupille-sur-Meuse',
    'Waremme',
    'Rocourt',
    'Montegnee',
    'Grivegnee',
    'Saint-Leger',
    'Faimes',
    'Loppem',
    'Ronse',
    'Assebroek',
    'Warneton',
    'Fleron',
    'Massemen',
    'Herseaux',
    'Landegem',
    'Aartrijke',
    'Esneux',
    'Bastogne',
    'Genval',
    'Rosieres',
    'Schelle',
    'Helchteren',
    'Strepy-Bracquegnies',
    'Oud-Heverlee',
    'Hollain',
    'Heusden',
    'Bissegem',
    'Engis',
    'Incourt',
    'Frameries',
    'Burst',
    'Couvin',
    'Sint-Stevens-Woluwe',
    'Nazareth',
    'Estaimpuis',
    'Auderghem',
    'Fayt-lez-Manage',
    'Lambermont',
    'Saint-Symphorien',
    'Houdeng-Goegnies',
    'Cuesmes',
    'Nil Saint-Vincent',
    'Binche',
    'Pont-a-Celles',
    'Haut-Ittre',
    'Relegem',
    'Andrimont',
    'Butgenbach',
    'Wierde',
    'Clavier',
    'Erezee',
    'Ferrieres',
    'Wanne',
    'Torhout',
    'Bellem',
    'Ohey',
    'Marchin',
    'Villers-sur-Semois',
    'Theux',
    'Bavikhove',
    'Tessenderlo',
    'Moha',
    'Henri-Chapelle',
    'Alleur',
    'Haccourt',
    'Richelle',
    'Horion-Hozemont',
    'Trooz',
    'Kessel-Lo',
    'Peer',
    'Marais',
    'Anderlues',
    'Tertre',
    'Sankt Vith',
    'Morlanwelz-Mariemont',
    'Bocholt',
    'Hamont',
    'Achel',
    'Genk',
    'As',
    'Bree',
    'Opglabbeek',
    'Kinrooi',
    'Maaseik',
    'Begijnendijk',
    'Ham',
    'Dilsen',
    'Stokkem',
    'Elen',
    'Opgrimbie',
    'Hoeselt',
    'Riemst',
    'Tongeren',
    'Kaulille',
    'Baal',
    'Tielt',
    'Lubbeek',
    'Nieuwrode',
    'Kortrijk-Dutsel',
    'Gingelom',
    'Borgloon',
    'Nieuwerkerken',
    'Klein-Gelmen',
    'Heers',
    'Zutendaal',
    'Rosmeer',
    'Munsterbilzen',
    'Halen',
    'Leopoldsburg',
    'Zolder',
    'Lummen',
    'Heppen',
    'Neerpelt',
    'Hechtel',
    'Geetbets',
    'Zoutleeuw',
    'Attenhoven',
    'Landen',
    'Budingen',
    'Kersbeek-Miskom',
    'Langdorp',
    'Ramsel',
    'Rillaar',
    'Herselt',
    'Diest',
    'Houthalen',
    'Sint-Lambrechts-Herk',
    'Boorsem',
    'Gruitrode',
    'Neerlinter',
    'Hoeleden',
    'Blanden',
    'Molenstede',
    'Averbode',
    'Zichem',
    'Kuringen',
    'Diepenbeek',
    'Opoeteren',
    'Opitter',
    'Meeuwen',
    'Herk-de-Stad',
    'Melkwezer',
    'Bekkevoort',
    'Haasrode',
    'Meensel-Kiezegem',
    'Wellen',
    'Velm',
    'Ulbeek',
    'Kozen',
    'Holsbeek',
    'Betekom',
    'Pellenberg',
    'Linden',
    'Testelt',
    'Stevoort',
    'Kortessem',
    'Beringen',
    'Beverlo',
    'Koersel',
    'Schulen',
    'Meerlaar',
    'Kwaadmechelen',
    'Ham',
    'Oostham',
    'Rekem',
    'Eigenbilzen',
    'Vliermaalroot',
    'Kermt',
    'Vliermaal',
    'Wolfsdonk',
    'Assent',
    'Glabbeek',
    'Baarle-Hertog',
    'Poederlee',
    'Lichtaart',
    'Hemiksem',
    'Sinaai',
    'Minderhout',
    'Merksplas',
    'Hoogstraten',
    'Meersel',
    'Oelegem',
    'Kessel',
    'Rijkevorsel',
    'Pulle',
    'Heindonk',
    'Eppegem',
    'Schriek',
    'Hallaar',
    'Loenhout',
    'Berendrecht',
    'Terhagen',
    'Wespelaar',
    'Tildonk',
    'Blankenberge',
    'Buvrinnes',
    'Bierset',
    'Saive',
    'Remicourt',
    'Hodeige',
    'Braives',
    'Flemalle-Grande',
    'Ramet',
    'Vivegnis',
    'Flemalle-Haute',
    'Neuville-en-Condroz',
    'Boncelles',
    'Saint-Nicolas',
    'Vottem',
    'Juprelle',
    'Bassenge',
    'Melen',
    'Saint-Remy',
    'Fexhe-le-Haut-Clocher',
    'Amay',
    'Comblain-au-Pont',
    'Thulin',
    'Aubel',
    'Goutroux',
    'Herlaimont',
    'Chastre',
    'Viesville',
    'Loverval',
    'Couillet',
    'Luttre',
    'Velaine-sur-Sambre',
    'Thimeon',
    'Auvelais',
    'Pont-de-Loup',
    'Farciennes',
    'Fontaine',
    "Fontaine-l'Eveque",
    'Isnes',
    'Wagnelee',
    'Louvain-la-Neuve',
    "Braine-l'Alleud",
    'Lillois-Witterzee',
    'Herve',
    'Ath',
    'Casteau',
    'Bernissart',
    'Quevaucamps',
    'Floreffe',
    'Bovesse',
    'Malonne',
    'Saint-Servais',
    'Membach',
    'Spa',
    'Lambermont',
    'Aubange',
    'Mettet',
    'Hotton',
    'Hampteau',
    'Gourdinne',
    'Xhoris',
    'Filot',
    'Ampsin',
    'Naast',
    'Nivelles',
    'Houtain-le-Val',
    'Remersdaal',
    'AnhÃ©e',
    'Messancy',
    'Bressoux',
    'Vedrin',
    'Roeulx',
    'Aywaille',
    'Sclayn',
    'Bilstain',
    'Grand-Rechain',
    'Eghezee',
    'Ecaussinnes-Lalaing',
    'Braine-le-Comte',
    'Baisy-Thy',
    'Stembert',
    'Rhisnes',
    'Battice',
    'Blegny',
    'Donceel',
    'Chaudfontaine',
    'Limbourg',
    'Embourg',
    'Haneffe',
    'Bredene',
    'Oostkamp',
    'Wevelgem',
    'Marke',
    'Staden',
    'Knokke',
    'Oordegem',
    'Vance',
    'Emptinne',
    'Gelrode',
    'Borchtlombeek',
    'Eke',
    'Koksijde',
    'Wolkrange',
    'Duisburg',
    'Vaux-sous-Chevremont',
    'Olne',
    'Verlaine',
    'Flawinne',
    'Florenville',
    'Kettenis',
    'Walhorn',
    'Malmedy',
    'Montzen',
    'Izel',
    'Tilff',
    'Astenet',
    'Lisogne',
    'Natoye',
    'Cerexhe-Heuseux',
    'Nandrin',
    'Belle Fontaine',
    'Jemappes',
    'Saint-Ghislain',
    'Jodoigne',
    'Baudour',
    'Villers-le-Bouillet',
    'Coret',
    'Saint-Gilles',
    'Quaregnon',
    'Lessines',
    'Flenu',
    'Boussu',
    'Hermee',
    'Plainevaux',
    'Houffalize',
    'Gellik',
    'Leerbeek',
    'Neerharen',
    'Ghlin',
    'Limal',
    'Pipaix',
    'Ottignies',
    'Limelette',
    'Hornu',
    'Dour',
    'Leuze',
    'Hautrage',
    'Jurbise',
    'Leernes',
    'Sart Melin',
    'Watermael-Boitsfort',
    'Xhendremael',
    'Huldenberg',
    'Warsage',
    'Fallais',
    'Baelen',
    'Othee',
    'Stavelot',
    'Ressaix',
    'Estinnes-au-Val',
    'Nimy',
    'Mont-Sainte-Aldegonde',
    'Obourg',
    'Maisieres',
    'Waudrez',
    'Warquignies',
    'Awirs',
    'Bouillon',
    'Avelgem',
    'De Panne',
    'Zulte',
    'Wortegem',
    'Eernegem',
    'Ruisbroek',
    'Humbeek',
    'Sart-lez-Spa',
    'Ciplet',
    'Damme',
    'Ertvelde',
    'Agimont',
    'Barchon',
    'Virton',
    'Heusy',
    'Nokere',
    'Vichte',
    'Vlierzele',
    'Laken',
    'Ways',
    'Plancenoit',
    'Charneux',
    'Walhain',
    'Hooglede',
    'Beernem',
    'Colfontaine',
    'Sart-Dames-Avelines',
    'Ledeberg',
    'Diegem',
    'Tohogne',
    'Bierbeek',
    'Sainte-Ode',
    'Chiny',
    'Dorinne',
    'Brugelette',
    'Dottignies',
    'Oostende',
    'Hamme-Mille',
    'Pamel',
    'Peutie',
    'Vossem',
    'Veltem-Beisem',
    'Everberg',
    'Leefdaal',
    'Bertem',
    'Neerijse',
    'Wijgmaal',
    'Saint-Jean-Geest',
    'Winksele',
    'Korbeek-Dijle',
    'Sint-Agatha-Rode',
    'Ruisbroek',
    'Elingen',
    'Brussegem',
    'Korbeek-Lo',
    'Oostmalle',
    'Meerhout',
    'Broechem',
    'Emblem',
    'Ramsdonk',
    'Blaasveld',
    'Bevel',
    "'s-Gravenwezel",
    'Recht',
    'Latinne',
    'Hermalle-sous-Argenteau',
    'Waleffe Saint-Georges',
    'Enghien',
    'Thisnes',
    'Heure-le-Romain',
    'Housse',
    'Beauvechain',
    'Glimes',
    'Souvret',
    'Monceau-sur-Sambre',
    'Wangenies',
    'Welkenraedt',
    'Geer',
    'Goe',
    'Assesse',
    'Voroux-Goreux',
    'Momalle',
    'Florennes',
    'Morialme',
    "Ecaussinnes-d'Enghien",
    'Belgrade',
    'Ligny',
    'Nouvelles',
    'Dalhem',
    'Dinant',
    'Ham-sur-Sambre',
    'Carnieres',
    'Ophain',
    'Bouge',
    'Basecles',
    'Stoumont',
    'Lincent',
    'Ensival',
    'Dongelberg',
    'Louveigne',
    'Orp-le-Grand',
    'Soignies',
    'Saint-Josse-ten-Noode',
    'Haren',
    'Saint-Sauveur',
    'Gages',
    'Hensies',
    'Gobard',
    'Herinnes',
    'Sombreffe',
    'Chaussee-Notre-Dame-Louvignies',
    'Tongre-Notre-Dame',
    'Rode',
    'Mariekerke',
    'Maurage',
    'Sleidinge',
    'Mariembourg',
    'La Hestre',
    'Rance',
    "Bois-d'Haine",
    'Velaines',
    'Renlies',
    'Knokke-Heist',
    'Lippelo',
    'Bertrix',
    'Houtaing',
    'Rebaix',
    'Sart-en-Fagne',
    'Havay',
    'Attre',
    'Saint-Mard',
    'Retinne',
    'Oedelem',
    'Wieze',
    'Parike',
    'Zandbergen',
    'Mere',
    'Kruishoutem',
    'Zingem',
    'Schorisse',
    'Etikhove',
    'Klemskerke',
    'Zuienkerke',
    'Menen',
    'Westouter',
    'Poperinge',
    'Kortemark',
    'Astene',
    'Heist',
    'Dudzele',
    'Kanegem',
    'Oeselgem',
    'Harelbeke',
    'Kortenaken',
    'Rummen',
    'Letterhoutem',
    'Toernich',
    'Jamioulx',
    'Fosse des Loups',
    'Ster',
    'Tilleur',
    'Ellignies-Sainte-Anne',
    'Couthuin',
    'Seilles',
    'Anthisnes',
    'Varsenare',
    'De Haan',
    'Ruien',
    'Zwartenbroek',
    'Petegem',
    'Stene',
    'Sijsele',
    'Vlissegem',
    'Ursel',
    'Zomergem',
    'Hansbeke',
    'Vinderhoute',
    'Bassevelde',
    'Watervliet',
    'Oosteeklo',
    'Nukerke',
    'Werken',
    'Bovekerke',
    'Koekelare',
    'Bavegem',
    'Scheldewindeke',
    'Sint-Andries',
    'Ypres',
    'Elverdinge',
    'Langemark',
    'Zwevegem',
    'Aalbeke',
    'Heule',
    'Markegem',
    'Ooigem',
    'Houthulst',
    'Boezinge',
    'Woesten',
    'Reninge',
    'Alveringem',
    'Lauwe',
    'Wervik',
    'Comines',
    'Houthem',
    'Rekkem',
    'Ploegsteert',
    'Lichtervelde',
    'Vladslo',
    'Ruddervoorde',
    'Meigem',
    'Wakken',
    'Sint-Baafs-Vijve',
    'Wielsbeke',
    'Aarsele',
    'Oostvleteren',
    'Passendale',
    'Geluwe',
    'Ichtegem',
    'Nieuwpoort',
    'Nieuwpoort',
    'Zwevezele',
    'Kachtem',
    'Pervijze',
    'Desselgem',
    'Loker',
    'Voormezele',
    'Moen',
    'Tiegem',
    'Otegem',
    'Heestert',
    'Ramskapelle',
    'Sint-Maria-Latem',
    'Middelburg',
    'Outrijve',
    'Lendelede',
    'Gullegem',
    'Pittem',
    'Zarren',
    'Overheide',
    'Molenbeek',
    'Grand-Reng',
    'Erquelinnes',
    'Stree',
    'Chimay',
    'Feluy',
    'Sablon',
    'Ghislenghien',
    'Post',
    'Melsbroek',
    'Sint-Pieters op de Dijk',
    'Grand',
    'Libramont',
    'Veurne',
    'Vaux',
    'Gentinnes',
    'Orbais',
    'Grand-Leez',
    'Biercee',
    'Seneffe',
    'Thynes',
    'Warchin',
    'Okegem',
    'Ardooie',
    'Leffinge',
    'Avekapelle',
    'Leisele',
    'Adinkerke',
    'Lissewege',
    'Serskamp',
    'Sint-Kornelis-Horebeke',
    'Impe',
    'Lotenhulle',
    'Haasdonk',
    'Lanklaar',
    'Schalkhoven',
    'Thy-le-Chateau',
    'Lobbes',
    'Beloeil',
    'Gouy-lez-Pieton',
    'La Calamine',
    'Chevron',
    'Saintes',
    'Neder-Over-Heembeek',
    'Oppem',
    'Arquennes',
    'Banneux',
    'Burdinne',
    'Marche-lez-Ecaussinnes',
    'Heffen',
    'Houtvenne',
    'Lier',
    'Schaffen',
    'Kerkhove',
    'Gits',
    'Wenduine',
    'Dranouter',
    'Poelkapelle',
    'Kerksken',
    'Quenast',
    'Arbrefontaine',
    'Forrieres',
    'Freylange',
  ],
  tld: ['.be'],
  cca2: 'BE',
  ccn3: '056',
  cca3: 'BEL',
  cioc: 'BEL',
  currency: ['EUR'],
  callingCode: ['32'],
  capital: 'Brussels',
  altSpellings: [
    'BE',
    'België',
    'Belgie',
    'Belgien',
    'Belgique',
    'Kingdom of Belgium',
    'Koninkrijk België',
    'Royaume de Belgique',
    'Königreich Belgien',
  ],
  region: 'Europe',
  subregion: 'Western Europe',
  languages: { deu: 'German', fra: 'French', nld: 'Dutch' },
  translations: {
    cym: { official: 'Kingdom of Belgium', common: 'Gwlad Belg' },
    deu: { official: 'Königreich Belgien', common: 'Belgien' },
    fra: { official: 'Royaume de Belgique', common: 'Belgique' },
    hrv: { official: 'Kraljevina Belgija', common: 'Belgija' },
    ita: { official: 'Regno del Belgio', common: 'Belgio' },
    jpn: { official: 'ベルギー王国', common: 'ベルギー' },
    nld: { official: 'Koninkrijk België', common: 'België' },
    por: { official: 'Reino da Bélgica', common: 'Bélgica' },
    rus: { official: 'Королевство Бельгия', common: 'Бельгия' },
    spa: { official: 'Reino de Bélgica', common: 'Bélgica' },
    fin: { official: 'Belgian kuningaskunta', common: 'Belgia' },
  },
  latlng: [50.83333333, 4],
  demonym: 'Belgian',
  landlocked: false,
  borders: ['FRA', 'DEU', 'LUX', 'NLD'],
  area: 30528,
  provinces: [
    'Antwerpen',
    'Brabant Wallon',
    'Brussels Capitol Region',
    'Hainaut',
    'Liege',
    'Limburg',
    'Luxembourg',
    'Namur',
    'Oost-Vlaanderen',
    'Vlaams Brabant',
    'West-Vlaanderen',
  ],
  nativeName: 'België',
  timezones: ['UTC+01:00'],
} as const;
