export const ABW = {
  name: {
    common: 'Aruba',
    official: 'Aruba',
    native: {
      nld: { official: 'Aruba', common: 'Aruba' },
      pap: { official: 'Aruba', common: 'Aruba' },
    },
  },
  cities: [
    'Palm Beach',
    'Oranjestad',
    'Sero Blanco',
    'Tanki Lender',
    'Santa Cruz',
    'Noord',
    'Paradera',
    'Sabaneta',
    'San Barbola',
    'Sint Nicolaas',
    'Ponton',
  ],
  nativeName: 'Aruba',
  provinces: ['Aruba'],
  tld: ['.aw'],
  cca2: 'AW',
  ccn3: '533',
  cca3: 'ABW',
  cioc: 'ARU',
  currency: ['AWG'],
  callingCode: ['297'],
  capital: 'Oranjestad',
  altSpellings: ['AW'],
  region: 'Americas',
  subregion: 'Caribbean',
  languages: { nld: 'Dutch', pap: 'Papiamento' },
  translations: {
    deu: { official: 'Aruba', common: 'Aruba' },
    fra: { official: 'Aruba', common: 'Aruba' },
    hrv: { official: 'Aruba', common: 'Aruba' },
    ita: { official: 'Aruba', common: 'Aruba' },
    jpn: { official: 'アルバ', common: 'アルバ' },
    nld: { official: 'Aruba', common: 'Aruba' },
    por: { official: 'Aruba', common: 'Aruba' },
    rus: { official: 'Аруба', common: 'Аруба' },
    spa: { official: 'Aruba', common: 'Aruba' },
    fin: { official: 'Aruba', common: 'Aruba' },
  },
  latlng: [12.5, -69.96666666],
  demonym: 'Aruban',
  landlocked: false,
  borders: [],
  area: 180,
} as const;
