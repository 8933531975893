/**
 * Generate a data URL from an input element's `File` data container.
 *
 * @param file Input element `File` data container.
 * @return A data URL if one was generated, otherwise `null`.
 */
export async function generateDataUrl(file: File) {
  const reader = new FileReader();
  reader.readAsDataURL(file);

  const url = await new Promise<FileReader['result']>((resolve) => {
    reader.onload = ({ target }) => resolve(target?.result ?? null);
  });

  return typeof url === 'string' ? url : null;
}
