import { openModal } from '../open-modal';

/**
 * Open App Settings modal.
 */
export async function appSettings() {
  await openModal.safe({
    title: 'APP SETTINGS',
    component: () => import('./AppSettings.vue'),
  });
}
