/* eslint-disable no-console */

import { AxiosResponse } from 'axios';

import { roundTo } from '@tools/math';
import { isObject } from '@tools/type-guards';
import { logger as _logger, StyledLog } from '@utils/styled-logger';

/** ... */
const API_LOG_TAG: StyledLog = [
  ' API ',
  { color: '#000000', background: '#E6DB74', borderRadius: '6px' },
];

/** ... */
const SUCCESS_STATUS_CODES = [200, 201, 202, 203, 204, 205, 206, 207, 208, 226];

/**
 * ...
 *
 * @param res ...
 */
export function logger(res: AxiosResponse, duration: number) {
  // ...
  const wasSuccess = SUCCESS_STATUS_CODES.includes(res.status);

  // ...
  const logStatusMarkConfig: StyledLog = [
    (wasSuccess ? ' ✔ ' : ' ✘ ') + res.status.toString() + ' ',
    {
      color: '#000000',
      background: wasSuccess ? '#12a757' : ' #ff0047',
      fontSize: '12px',
      borderRadius: '6px',
    },
  ];

  // ...
  const logEndpointConfig = createLogEndpointConfig(res.config.url);

  _logger.group(
    API_LOG_TAG,
    '  ',
    logStatusMarkConfig,
    '  ',
    // Method Tag
    generateMethodTagConfig(res.config.method ?? '--'),
    [' --> ', '#6d6d6d'],
    // [res.config.url, '#ff6e7a']
    ...logEndpointConfig,
    '  ',
    [roundTo(0.001 * duration, 2).toString() + ' s', '#ffb167'],
    '  ',
    [generateCurrentDateTimeTag(), '#ffb167'],
  );

  console.log('url: ', res.config.url);
  console.log('status: ', res.status);
  console.log('statusText: ', res.statusText);

  const payload: unknown =
    typeof res.config.data === 'string'
      ? attemptParse(res.config.data)
      : res.config.data;

  console.log('payload: ', payload);

  let data: unknown = undefined;
  let error: unknown = undefined;
  let feedback = '--';

  if (!isObject(res.data)) {
    data = res.data;
  } else {
    const resData = Object.assign({}, res.data ?? {});

    data = resData.data ?? resData;
    error = resData.error;
    feedback = typeof resData.feedback === 'string' ? resData.feedback : '';
  }

  console.log('data: ', data);
  console.log('error: ', error);
  _logger.log('feedback: ', [feedback, '#E6DB74']);
  console.log('fullResponce: ', res);
  console.log(generateStacktrace());

  _logger.groupEnd();
}

//#region Helper Functions

/**
 * ...
 *
 * @param value ...
 * @returns ...
 */
function createLogEndpointConfig(value: Nullable<string>) {
  value ||= '';

  let host = '';
  let pathname = '';

  try {
    ({ host, pathname } = new URL(value));
  } catch {
    // host = location.host;
    host = '{local}';
    pathname = value;
  }

  pathname = pathname.replace(/^\//, '');

  const configs: StyledLog[] = [[host, '#ff6e7a']];

  for (const seg of pathname.split('/')) {
    if (seg) configs.push(['/', '#e06c75ad'], [seg, '#ff6e7a']);
  }

  return configs;
}

/**
 * ...
 *
 * @param data ...
 * @return ...
 */
function attemptParse(data: string) {
  try {
    return JSON.parse(data) as unknown;
  } catch {
    return data;
  }
}

/**
 * ...
 *
 * @returns ...
 */
function generateMethodTagConfig(method: string) {
  method = method.toLocaleUpperCase();

  let color: string;

  if (method === 'GET') {
    color = '#ffb167';
  } else if (method === 'POST') {
    color = '#67b1ff';
  } else if (method === 'PUT') {
    color = '#67ff84';
  } else if (method === 'DELETE') {
    color = '#ff67a3';
  } else if (method === 'HEAD') {
    color = '#ab67ff';
  } else {
    color = 'gray';
  }

  return [method, color] as StyledLog;
}

/**
 * ...
 *
 * @returns ...
 */
function generateCurrentDateTimeTag() {
  return new Date().toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
}

/**
 * ...
 *
 * @return ...
 */
function generateStacktrace() {
  let stacktrace = new Error().stack ?? '';

  // ...
  stacktrace = stacktrace.replace(
    /^[\s\S]+ {4}at requestWrapper.*\n/,
    'Error \n',
  );

  // ...
  // stacktrace = stacktrace.replace(/^ {4}/gm, '');

  return stacktrace;
}

//#endregion Helper Functions
