/**
 * Represents a task which will finish in the future.
 */
export interface Deferred<T = unknown> {
  /** Promise object associated with this deferred. */
  promise: Promise<T>;

  /**
   * Resolves the derived promise with the `value`.
   */
  resolve(value?: T): void;

  /**
   * Rejects the derived promise with the `reason`.
   */
  reject(reason?: unknown): void;
}

/**
 * Creates a {@link Deferred} object which represents a task which will finish
 * in the future.
 */
export function defer<T = unknown>() {
  // return new DeferredObject<T>() as Deferred<T>;

  const deferred = {} as Deferred<T>;

  deferred.promise = new Promise<T>((resolve, reject) => {
    deferred.resolve = resolve as (value?: T) => void;
    deferred.reject = reject;
  });

  return deferred;
}
