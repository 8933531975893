import { reactive, inject } from 'vue';

import type { ModalState } from './ModalDialog.vue';

export { ModalState };

/**
 * Retrieve modal state (injected by `ModalDialog` component).
 */
export function useModal() {
  const modal = inject<ModalState>('modal');

  if (!modal) {
    throw new Error(
      '[useModal] the "modal" state has not yet been provided by the Modal Dialog component.',
    );
  }

  // TODO: Dane - providing the modal state object as already reactive prevents
  // consumers of this composable from being able to destructure it and
  // maintain reactivity on any reactive properties (such as `fullscreen1).
  // Consider removing the reactive wrapper in the future.
  return reactive(modal);
}
