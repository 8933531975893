export const MYS = {
  name: {
    common: 'Malaysia',
    official: 'Malaysia',
    native: {
      eng: { official: 'Malaysia', common: 'Malaysia' },
      msa: { official: 'مليسيا', common: 'مليسيا' },
    },
  },
  cities: [
    'Pantai',
    'Kuala Lumpur',
    'Petaling Jaya',
    'Shah Alam',
    'Kajang',
    'Kuching',
    'Setapak',
    'Ipoh',
    'Bukit Kayu Hitam',
    'Bayan Lepas',
    'Taiping',
    'Kuala Terengganu',
    'Kuantan',
    'Johor Bahru',
    'Malacca',
    'Seremban',
    'Kota Kinabalu',
    'Bukit Mertajam',
    'Klang',
    'Alor Gajah',
    'Cheras',
    'Puchong Batu Dua Belas',
    'Taman Sri Ampang',
    'Muadzam Shah',
    'Kuala Pahang',
    'Simpang Empat',
    'Sungai Petani',
    'Taman Senai',
    'Batu Caves',
    'Banting',
    'Kampar',
    'Sungai Buluh',
    'Seri Kembangan',
    'Kampong Pasir Segambut',
    'Kampung Likas',
    'Penampang',
    'Simpang Tiga',
    'Bandar',
    'Lintang',
    'Putrajaya',
    'Damansara',
    'Batu Pahat',
    'Kota',
    'Sepang',
    'Kuala Selangor',
    'Taman Bukit Skudai',
    'Batang Kali',
    'Ayer Itam',
    'Bandar Tun Razak',
    'Tanah Merah New Village Site C',
    'Kuah',
    'Alor Star',
    'George Town',
    'Bandar Baru Bangi',
    'Kerling',
    'Bentung',
    'Victoria',
    'Kampung Tanjung Aru',
    'Taman Prai',
    'Sabak Bernam',
    'Kangar',
    'Itam',
    'Rawang',
    'Kulai',
    'Muar town',
    'Teluk Intan',
    'Masai',
    'Ulu Tiram',
    'Senggarang',
    'Kuala Kangsar',
    'Sitiawan',
    'Kepong',
    'Perai',
    'Butterworth',
    'Teluk Panglima Garang',
    'Lenggeng',
    'Merlimau',
    'Kluang',
    'Port Klang',
    'Kota Tinggi',
    'Pontian Kechil',
    'Miri',
    'Sibu',
    'Pengkalan Chepa',
    'Kampung Pekan Kechil Beranang',
    'Balik Pulau',
    'Tanjung Bunga',
    'Kota Bharu',
    'Karak',
    'Mentekab',
    'Raub',
    'Dungun',
    'Temerluh',
    'Kampong Kemaman',
    'Tanah Merah',
    'Papar',
    'Keningau',
    'Tawau',
    'Kampung Baharu Nilai',
    'Lahad Datu',
    'Kuala Lipis',
    'Bintulu',
    'Tampin',
    'Pasir Gudang',
    'Tumpat',
    'Seberang Jaya',
    'Lunas',
    'Benut',
    'Rengam',
    'Chemor',
    'Pedas',
    'Ranau',
    'Tanjong Karang',
    'Kulim',
    'Taman Kota Jaya',
    'Kepala Batas',
    'Kuala Penyu',
    'Jenjarum',
    'Kuala Pilah',
    'Kota Baharu',
    'Dengkil',
    'Tanjong',
    'Parit Raja',
    'Teronoh',
    'Kampung Batu Uban',
    'Hulu Langat',
    'Sungai Puyu',
    'Cameron Highlands',
    'Durian Tunggal',
    'Teriang',
    'Kampung Ayer Keroh',
    'Port Dickson',
    'Sandakan',
    'Membakut',
    'Kampung Teluk Baru',
    'Pasir Mas',
    'Changloon',
    'Batu Gajah',
    'Keramat Pulai',
    'Baru',
    'Ampang',
    'Kampung Teriang',
    'Bachok',
    'Tanjung',
    'Cyberjaya',
    'Serdang',
    'Pudu',
    'Pandan',
    'Simunjan',
    'Jertih',
    'Semenyih',
    'Kampong Changkat',
    'Segamat',
    'Bukit Gambir',
    'Kamunting',
    'Batu Arang',
    'Jerantut',
    'Nibong Tebal',
    'Jeram',
    'Tasek Glugor',
    'Lumut',
    'Slim River',
    'Ketereh',
    'Jitra',
    'Mantin',
    'Kampong Kahang Batu Twenty-eight',
    'Tamparuli',
    'Jasin',
    'Lukut',
    'Gemas',
    'Tangkak',
    'Gopeng',
    'Bedong',
    'Tapah Road',
    'Ayer Tawar',
    'Gurun',
    'Kuala Nerang',
    'Pengkalan Kempas',
    'Kompleks Sukan Tenom',
    'Padang Rengas',
    'Labis',
    'Jelutong',
    'Beaufort',
    'Sarikei',
    'Bidur',
    'Seri Manjung',
    'Masjid Tanah',
    'Bukit Asahan',
    'Melaka Tengah',
    'Padang Serai',
    'Serdang',
    'Bongawan',
    'Bagan Serai',
    'Kuala Ketil',
    'Simpang Pertang',
    'Tatau',
    'Gelang Patah',
    'Sungai Pelek',
    'Rembau',
    'Layang Layang',
    'Parit Buntar',
    'Rengit',
    'Gerik',
    'Pulau Pinang',
    'Sungai Besar',
    'Kampung Paya Simpang Empat Genting',
    'Kapar',
    'Bandar Tenggara',
    'Kampung Batu Kikir',
    'Pudu Ulu',
    'Sentul',
    'Serendah',
    'Permatang Pauh',
    'Kuala Berang',
    'Melor',
    'Kota Belud',
    'Kertih',
    'Cukai',
    'Hutan Melintang',
    'Batang Berjuntai',
    'Kuala Kubu Baharu',
    'Pantai Remis',
    'Juasseh',
    'Bahau',
    'Gua Musang',
    'Tanjong Malim',
    'Pusing',
    'Pekan',
    'Merbok',
    'Lahat',
    'Pasir Puteh',
    'Kubang Kerian',
    'Langat',
    'Selama',
    'Menglembu',
    'Taman Seremban Jaya',
    'Sungai Bakap',
    'Bau',
    'Kepala Batas',
    'Pendang',
    'Simpang Renggam',
    'Pengerang',
    'Bukit Pasir',
    'Kampung Bota Kiri',
    'Temoh',
    'Taman Megah Ria',
    'Taman Senawang Indah',
    'Sungai Besi',
    'Menggatal',
    'Tambunan',
    'Batu Berendam',
    'Penaga',
    'Batu Feringgi',
    'Marang',
    'Semerah',
    'Parit',
    'Batu',
    'Inanam',
    'Taman Desa',
    'Tampoi',
    'Bangi',
    'Genting',
    'Kuala Klawang',
    'Sungai Udang',
    'Gebeng',
    'Jawi',
    'Broga',
    'Kuang',
    'Kampung Tasek',
    'Labu',
    'Tanjung Sepat',
    'Kuala',
    'Bukit Rotan',
    'Jinjang',
    'Machang',
    'Mukah',
  ],
  tld: ['.my'],
  cca2: 'MY',
  ccn3: '458',
  cca3: 'MYS',
  cioc: 'MAS',
  currency: ['MYR'],
  callingCode: ['60'],
  capital: 'Kuala Lumpur',
  altSpellings: ['MY'],
  region: 'Asia',
  subregion: 'South-Eastern Asia',
  languages: { eng: 'English', msa: 'Malay' },
  translations: {
    deu: { official: 'Malaysia', common: 'Malaysia' },
    fra: { official: 'Fédération de Malaisie', common: 'Malaisie' },
    hrv: { official: 'Malezija', common: 'Malezija' },
    ita: { official: 'Malaysia', common: 'Malesia' },
    jpn: { official: 'マレーシア', common: 'マレーシア' },
    nld: { official: 'Maleisië', common: 'Maleisië' },
    por: { official: 'Malásia', common: 'Malásia' },
    rus: { official: 'Малайзия', common: 'Малайзия' },
    spa: { official: 'Malasia', common: 'Malasia' },
    fin: { official: 'Malesia', common: 'Malesia' },
  },
  latlng: [2.5, 112.5],
  demonym: 'Malaysian',
  landlocked: false,
  borders: ['BRN', 'IDN', 'THA'],
  area: 330803,
  provinces: [
    'Johor',
    'Kedah',
    'Kelantan',
    'Labuan',
    'Melaka',
    'Negeri Sembilan',
    'Pahang',
    'Perak',
    'Perlis',
    'Pulau Pinang',
    'Sabah',
    'Sarawak',
    'Selangor',
    'Terengganu',
    'Wilayah Persekutuan',
  ],
  nativeName: 'Malaysia',
  timezones: ['UTC+08:00'],
} as const;
