export const EGY = {
  name: {
    common: 'Egypt',
    official: 'Arab Republic of Egypt',
    native: { ara: { official: 'جمهورية مصر العربية', common: 'مصر' } },
  },
  cities: [
    'Cairo',
    'New Cairo',
    'Alexandria',
    'Giza',
    'Helwan',
    'Shubra',
    'Damietta',
    'Suez',
    'Madinat an Nasr',
    'Al Mansurah',
    'Bani Suwayf',
    'Sohag',
    'Tanta',
    'Zagazig',
    'Ramsis',
    'Ar Rawdah',
    'Al Mahallah al Kubra',
    'Heliopolis',
    'Hurghada',
    'Az Zamalik',
    'Rafah',
    'Madinat as Sadis min Uktubar',
    'Ismailia',
    'Faraskur',
    'Shirbin',
    'Toukh',
    'Banha',
    'Port Said',
    'Luxor',
    'Minya',
    'Sadat',
    'Almazah',
    'Assiut',
    'Al Marj',
    'Abu Hammad',
    'Nasr',
    'Flaminj',
    'Kafr ash Shaykh',
    'Badr',
  ],
  tld: ['.eg', '.مصر'],
  cca2: 'EG',
  ccn3: '818',
  cca3: 'EGY',
  cioc: 'EGY',
  currency: ['EGP'],
  callingCode: ['20'],
  capital: 'Cairo',
  altSpellings: ['EG', 'Arab Republic of Egypt'],
  region: 'Africa',
  subregion: 'Northern Africa',
  languages: { ara: 'Arabic' },
  translations: {
    cym: { official: 'Arab Republic of Egypt', common: 'Yr Aifft' },
    deu: { official: 'Arabische Republik Ägypten', common: 'Ägypten' },
    fra: { official: "République arabe d'Égypte", common: 'Égypte' },
    hrv: { official: 'Arapska Republika Egipat', common: 'Egipat' },
    ita: { official: "Repubblica araba d'Egitto", common: 'Egitto' },
    jpn: { official: 'エジプト·アラブ共和国', common: 'エジプト' },
    nld: { official: 'Arabische Republiek Egypte', common: 'Egypte' },
    por: { official: 'República Árabe do Egipto', common: 'Egito' },
    rus: { official: 'Арабская Республика Египет', common: 'Египет' },
    spa: { official: 'República Árabe de Egipto', common: 'Egipto' },
    fin: { official: 'Egyptin arabitasavalta', common: 'Egypti' },
  },
  latlng: [27, 30],
  demonym: 'Egyptian',
  landlocked: false,
  borders: ['ISR', 'LBY', 'SDN'],
  area: 1002450,
  provinces: [
    'Ad Daqahliyah',
    'Al Bahr al Ahmar',
    'Al Buhayrah',
    'Al Fayyum',
    'Al Gharbiyah',
    'Al Iskandariyah',
    "Al Isma'iliyah",
    'Al Jizah',
    'Al Minufiyah',
    'Al Minya',
    'Al Qahirah',
    'Al Qalyubiyah',
    'Al Wadi al Jadid',
    'As Suways',
    'Ash Sharqiyah',
    'Aswan',
    'Asyut',
    'Bani Suwayf',
    "Bur Sa'id",
    'Dumyat',
    "Janub Sina'",
    'Kafr ash Shaykh',
    'Matruh',
    'Qina',
    "Shamal Sina'",
    'Suhaj',
  ],
  nativeName: 'مصر‎',
  timezones: ['UTC+02:00'],
} as const;
