import { server } from '@api/request';
import { isObject, isString, isNull } from '@tools/type-guards';

/**
 * {@link createIntent Create payment intent} API request options.
 */
export interface CreatePaymentIntentOptions {
  cart: CreatePaymentIntentOptions.CartItem[];
  address: CreatePaymentIntentOptions.AddressInfo;
}

export namespace CreatePaymentIntentOptions {
  export interface CartItem {
    productId: string;
    quantity: number;
    price: number;
  }

  export interface AddressInfo {
    city?: string;
    country?: string;
    line1?: string;
    line2?: string;
    postal_code?: string;
    state?: string;
  }
}

/**
 * {@link createIntent Create payment intent} API request response.
 */
export interface CreatePaymentIntentResponse {
  clientSecret: string;
}

/**
 * Create a payment intent for a user who's ready to make a purchase at
 * checkout.
 *
 * @param options Request options bag.
 * @returns A promise that resolves to a generic Braintree token.
 *
 * @see {@link ZephyrApi.Payments.createIntent `CreatePaymentIntent`} API route in `zephyr-serverless`
 */
export async function createIntent(options: CreatePaymentIntentOptions) {
  return await server.post<string>(
    'v2/payments/create-payment-intent',
    options,
    processCreateIntentResponse,
  );
}

/**
 * {@link getSessionStatus Get checkout session status} API request payload options.
 */
export interface GetSessionStatusOptions {
  sessionId: string;
}

export interface SessionStatusInfo {
  status: 'complete' | 'expired' | 'open';
  customer_email: string;
}

/**
 * Get status info of an existing Stripe checkout session.
 *
 * @param options Request options payload.
 * @returns A promise that resolves to a Stripe checkout session info object.
 */
export async function getSessionStatus(options: GetSessionStatusOptions) {
  return await server.get<SessionStatusInfo>(
    `v2/payments/session-status?session_id=${options.sessionId}`,
  );
}

/**
 * {@link createSession Create checkout session} API request response.
 */
export interface CreateCheckoutSessionOptions {
  /**
   * List of pos-specific cart item objects.
   */
  cart: CreateCheckoutSessionOptions.CartItem[];
  /**
   * Google reCAPTCHA token.
   */
  reCaptcha: string;
  /**
   * If included, the purchase will be attempted to be made on behalf of the
   * specified organization. Note that only an organization admin of the
   * specified organization permitted to do this.
   *
   */
  organizationId?: string;
  /**
   * If included, the email will be (statically) set on checkout page.
   */
  guestEmail?: string;
  /**
   * If included, purchase will be applied to listed users instead of the
   * purchaser.
   */
  targetUsers?: string[];
  /**
   * If included, will associate the code (and by extension, the referer) to
   * the completed order.
   */
  referralCode?: string;
  /**
   * Context of the site in which the checkout process is taking place.
   */
  context?: string;
}

export namespace CreateCheckoutSessionOptions {
  export interface CartItem {
    productId: string;
    priceId: string;
    quantity: number;
  }
}

/**
 * {@link createSession Create checkout session} API request response.
 */
export interface CreateCheckoutSessionResponse {
  sessionUrl: string | null;
  clientSecret: string | null;
}

/**
 * Creates a checkout session on Stripe.
 *
 * @param options Request options bag.
 * @returns Object housing either a `sessionUrl` or and `clientSecret` (based on
 * the value of `options.uIMode`).
 *
 * @see {@link ZephyrApi.Payments.createCheckoutSession `CreateCheckoutSession`} API route in `zephyr-serverless`
 */
export async function createSession(options: CreateCheckoutSessionOptions) {
  return await server.post(
    'v2/payments/create-checkout-session',
    options,
    processCreateSessionResponse,
  );
}

export async function createCustomerPortalSession() {
  return await server.post('v2/payments/create-customer-portal-session');
}

//#region Helper Functions

/**
 * Determine if a value is a valid {@link CreatePaymentIntentResponse}.
 *
 * @param value The value to check.
 * @returns `true` if the value is a valid {@link CreatePaymentIntentResponse},
 * otherwise `false`.
 */
function isValidCreateIntentResponse(
  value: unknown,
): value is CreatePaymentIntentResponse {
  return isObject(value) && isString(value['clientSecret']);
}

/**
 * Process data received from a request expected to be a
 * {@link CreatePaymentIntentResponse}.
 *
 * @param data Data received from the request.
 * @returns The processed data value.
 */
function processCreateIntentResponse(data: unknown) {
  if (!isValidCreateIntentResponse(data)) {
    throw new Error('Invalid create intent data.');
  }

  return data.clientSecret;
}

/**
 * Determine if a value is a valid {@link CreateCheckoutSessionResponse}.
 *
 * @param value The value to check.
 * @returns `true` if the value is a valid {@link CreateCheckoutSessionResponse},
 * otherwise `false`.
 */
function isValidCreateSessionResponse(
  value: unknown,
): value is CreateCheckoutSessionResponse {
  return (
    isObject(value) &&
    (isString(value['sessionUrl']) || isNull(value['sessionUrl'])) &&
    (isString(value['clientSecret']) || isNull(value['clientSecret']))
  );
}

/**
 * Process data received from a request expected to be a
 * {@link CreateCheckoutSessionResponse}.
 *
 * @param data Data received from the request.
 * @returns The processed data value.
 */
function processCreateSessionResponse(data: unknown) {
  if (!isValidCreateSessionResponse(data)) {
    throw new Error('Invalid create session data.');
  }

  return data;
}

//#endregion Helper Functions
