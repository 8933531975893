export const TKL = {
  name: {
    common: 'Tokelau',
    official: 'Tokelau',
    native: {
      eng: { official: 'Tokelau', common: 'Tokelau' },
      smo: { official: 'Tokelau', common: 'Tokelau' },
      tkl: { official: 'Tokelau', common: 'Tokelau' },
    },
  },
  cities: ['Atafu Village'],
  tld: ['.tk'],
  cca2: 'TK',
  ccn3: '772',
  cca3: 'TKL',
  cioc: '',
  currency: ['NZD'],
  callingCode: ['690'],
  capital: 'Fakaofo',
  altSpellings: ['TK'],
  region: 'Oceania',
  subregion: 'Polynesia',
  languages: { eng: 'English', smo: 'Samoan', tkl: 'Tokelauan' },
  translations: {
    deu: { official: 'Tokelau', common: 'Tokelau' },
    fra: { official: 'Îles Tokelau', common: 'Tokelau' },
    hrv: { official: 'Tokelau', common: 'Tokelau' },
    ita: { official: 'Tokelau', common: 'Isole Tokelau' },
    jpn: { official: 'トケラウ諸島', common: 'トケラウ' },
    nld: { official: 'Tokelau', common: 'Tokelau' },
    por: { official: 'Tokelau', common: 'Tokelau' },
    rus: { official: 'Токелау', common: 'Токелау' },
    spa: { official: 'Tokelau', common: 'Islas Tokelau' },
    fin: { official: 'Tokelau', common: 'Tokelau' },
  },
  latlng: [-9, -172],
  demonym: 'Tokelauan',
  landlocked: false,
  borders: [],
  area: 12,
} as const;
