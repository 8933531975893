var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("Transition", [
    _setup.enabled
      ? _c(
          "footer",
          [
            _c(
              "b-container",
              { staticClass: "px-5 footer-container" },
              [
                _c(
                  "b-row",
                  { staticClass: "py-4 justify-content-between" },
                  [
                    _c(
                      "b-col",
                      { staticClass: "flex-wrap", attrs: { cols: "auto" } },
                      [
                        _c(
                          "div",
                          { staticStyle: { "margin-bottom": "20px" } },
                          [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "http://www.littlearmsstudios.com",
                                  target: "_blank",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "little-arms-logo-wrapper" },
                                  [
                                    _c(_setup.Toothy),
                                    _vm._v(" "),
                                    _c(_setup.LittleArmsStudios),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _c("div", [
                            _c("h6", { staticClass: "text-muted" }, [
                              _vm._v("Little Arms, Big Ideas!"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticStyle: { "max-width": "200px" } }, [
                            _c(
                              "p",
                              {
                                staticClass: "text-muted",
                                staticStyle: { "text-align": "justify" },
                              },
                              [
                                _vm._v(
                                  "\n                Fly without risking hardware, having to charge drone\n                batteries, and worrying about weather. Users can train\n                wherever and whenever they want.\n              "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      { staticClass: "flex-wrap", attrs: { cols: "auto" } },
                      [
                        _c("h4", [_vm._v("Company")]),
                        _vm._v(" "),
                        _c(
                          "ul",
                          { staticClass: "no-bullets" },
                          _vm._l(_setup.COMPANY_LINKS, function (link) {
                            return _c(
                              "li",
                              { key: link.key, staticClass: "py-1" },
                              [
                                _c(
                                  "RouterLink",
                                  { attrs: { to: { name: link.route } } },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(link.label) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      { staticClass: "flex-wrap", attrs: { cols: "auto" } },
                      [
                        _c("h4", [_vm._v("Platform")]),
                        _vm._v(" "),
                        _c(
                          "ul",
                          { staticClass: "no-bullets" },
                          [
                            _c(
                              "li",
                              { staticClass: "py-1" },
                              [
                                _c(
                                  "RouterLink",
                                  { attrs: { to: { name: "individuals" } } },
                                  [
                                    _vm._v(
                                      "\n                Individual Pricing\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              { staticClass: "py-1" },
                              [
                                _c(
                                  "RouterLink",
                                  { attrs: { to: { name: "enterprise" } } },
                                  [
                                    _vm._v(
                                      "\n                Enterprise Pricing\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm._l(_setup.PLATFORM_LINKS, function (link) {
                              return _c(
                                "li",
                                { key: link.id, staticClass: "py-1" },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: link.url,
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v(_vm._s(link.title))]
                                  ),
                                ]
                              )
                            }),
                          ],
                          2
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      { staticClass: "flex-wrap", attrs: { cols: "auto" } },
                      [
                        _c("h4", [_vm._v("Resources")]),
                        _vm._v(" "),
                        _c(
                          "ul",
                          { staticClass: "no-bullets" },
                          [
                            _c(
                              "li",
                              { staticClass: "py-1" },
                              [
                                _c(
                                  "RouterLink",
                                  { attrs: { to: { name: "contact" } } },
                                  [_vm._v("Contact")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm._l(_setup.RESOURCE_LINKS, function (link) {
                              return _c(
                                "li",
                                { key: link.id, staticClass: "py-1" },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: link.url,
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v(_vm._s(link.title))]
                                  ),
                                ]
                              )
                            }),
                          ],
                          2
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      { staticClass: "flex-wrap", attrs: { cols: "auto" } },
                      [
                        _c(
                          "RouterLink",
                          {
                            staticClass: "mb-2",
                            attrs: { to: { name: "systemRequirements" } },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "platform-logo-container-footer" },
                              [
                                _c("h6", { staticClass: "pt-3 pl-3 pr-3" }, [
                                  _vm._v("Requirements"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "platform-logo-image-wrapper-footer",
                                  },
                                  [
                                    _c("Icon", {
                                      staticClass: "fa-3x",
                                      attrs: { icon: _setup.faApple },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "platform-logo-image-wrapper-footer",
                                  },
                                  [
                                    _c("Icon", {
                                      staticClass: "fa-3x",
                                      attrs: { icon: _setup.faWindows },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "RouterLink",
                          { attrs: { to: { name: "countryAvailability" } } },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "platform-availability-container-footer",
                              },
                              [
                                _c("h6", { staticClass: "pt-3 pl-3 pr-3" }, [
                                  _vm._v("Availability"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "platform-logo-image-wrapper-footer",
                                  },
                                  [
                                    _c("Icon", {
                                      staticClass: "fa-3x",
                                      attrs: { icon: _setup.faEarthAmericas },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-row",
                  { staticClass: "pt-1 pb-4 justify-content-between" },
                  [
                    _c("b-col", { attrs: { cols: "auto" } }, [
                      _c("p", { staticClass: "text-muted m-0" }, [
                        _c("span", [_vm._v("© Zephyr " + _vm._s(_setup.year))]),
                        _vm._v(" "),
                        _c("span", { staticClass: "m-2" }, [_vm._v("|")]),
                        _vm._v(" "),
                        _c("span", [_vm._v("v" + _vm._s(_setup.VERSION))]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      { attrs: { cols: "auto" } },
                      _vm._l(_setup.SOCIAL_LINKS, function (link) {
                        return _c(
                          "a",
                          {
                            key: link.id,
                            staticClass: "social-link mx-2",
                            attrs: { href: link.url, target: "_blank" },
                          },
                          [_c("Icon", { attrs: { icon: link.icon } })],
                          1
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }