var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_setup.dismissed
    ? _c("div", { staticClass: "site-announcement" }, [
        _c(
          "div",
          { staticClass: "site-announcement-inner" },
          [
            _vm._v("\n    " + _vm._s(_setup.title) + "\n\n    "),
            _setup.details
              ? _c("b-button", { on: { click: _setup.showDetails } }, [
                  _vm._v(" Details "),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "b-button",
              {
                on: {
                  click: function ($event) {
                    _setup.dismissed = true
                  },
                },
              },
              [_vm._v(" Dismiss ")]
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }