export const IRQ = {
  name: {
    common: 'Iraq',
    official: 'Republic of Iraq',
    native: {
      ara: { official: 'جمهورية العراق', common: 'العراق' },
      arc: { official: 'ܩܘܼܛܢܵܐ ܐܝܼܪܲܩ', common: 'ܩܘܼܛܢܵܐ' },
      ckb: { official: 'کۆماری عێراق', common: 'کۆماری' },
    },
  },
  cities: [
    'Baghdad',
    'Karkh',
    'Sulaymaniyah',
    'Kirkuk',
    'Erbil',
    'Basra',
    'Bahr',
    'Tikrit',
    'Najaf',
    'Al Hillah',
    'Mosul',
    'Haji Hasan',
    'Al `Amarah',
    'Basere',
    'Manawi',
    'Hayat',
  ],
  tld: ['.iq'],
  cca2: 'IQ',
  ccn3: '368',
  cca3: 'IRQ',
  cioc: 'IRQ',
  currency: ['IQD'],
  callingCode: ['964'],
  capital: 'Baghdad',
  altSpellings: ['IQ', 'Republic of Iraq', 'Jumhūriyyat al-‘Irāq'],
  region: 'Asia',
  subregion: 'Western Asia',
  languages: { ara: 'Arabic', arc: 'Aramaic', ckb: 'Sorani' },
  translations: {
    deu: { official: 'Republik Irak', common: 'Irak' },
    fra: { official: "République d'Irak", common: 'Irak' },
    hrv: { official: 'Republika Irak', common: 'Irak' },
    ita: { official: "Repubblica dell'Iraq", common: 'Iraq' },
    jpn: { official: 'イラク共和国', common: 'イラク' },
    nld: { official: 'Republiek Irak', common: 'Irak' },
    por: { official: 'República do Iraque', common: 'Iraque' },
    rus: { official: 'Республика Ирак', common: 'Ирак' },
    spa: { official: 'República de Irak', common: 'Irak' },
    fin: { official: 'Irakin tasavalta', common: 'Irak' },
  },
  latlng: [33, 44],
  demonym: 'Iraqi',
  landlocked: false,
  borders: ['IRN', 'JOR', 'KWT', 'SAU', 'SYR', 'TUR'],
  area: 438317,
  provinces: [
    'Al Anbar',
    'Al Basrah',
    'Al Muthanna',
    'Al Qadisiyah',
    'An Najaf',
    'Arbil',
    'As Sulaymaniyah',
    "At Ta'mim",
    'Babil',
    'Baghdad',
    'Dahuk',
    'Dhi Qar',
    'Diyala',
    "Karbala'",
    'Maysan',
    'Ninawa',
    'Salah ad Din',
    'Wasit',
  ],
  nativeName: 'العراق',
  timezones: ['UTC+03:00'],
} as const;
