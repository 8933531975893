export const MAR = {
  name: {
    common: 'Morocco',
    official: 'Kingdom of Morocco',
    native: {
      ara: { official: 'المملكة المغربية', common: 'المغرب' },
      ber: { official: 'ⵜⴰⴳⵍⴷⵉⵜ ⵏ ⵍⵎⵖⵔⵉⴱ', common: 'ⵍⵎⴰⵖⵔⵉⴱ' },
    },
  },
  cities: [
    'Marrakesh',
    'Tangier',
    'Temara',
    'Skhirat',
    'Casablanca',
    'Rabat',
    'Kenitra',
    "Sidi Yahia az Za'er",
    'Fes',
    'Tarfaya',
    'Meknes',
    'Agadir',
    'Khouribga',
    'SalÃ©',
    'TÃ©touan',
    'Touissite',
    'Oujda',
    'Nador',
    'Erfoud',
    'Mehediyah',
    'Khemisset',
    'Oulad Teima',
    'Mohammedia',
    'El Gara',
    'Berrechid',
    'Safi',
    'Benguerir',
    'Tiznit',
    'Sidi Slimane',
    'Sefrou',
    "Ifrane de l'Anti-Atlas",
    'El Hajeb',
    'Larache',
    'Ksar el Kebir',
    "M'dik",
    'Tan-Tan',
    'Al Hoceima',
    'Ouazzane',
    'Settat',
    'Tiflet',
    'Tahala',
    'El Jadida',
    'Sidi Bennour',
    'Afourer',
    'Beni Mellal',
    'Fkih Ben Salah',
    'Oued Zem',
    'Deroua',
    'Assa',
    'Ait Melloul',
    'Taza',
    'Mansour',
  ],
  tld: ['.ma', 'المغرب.'],
  cca2: 'MA',
  ccn3: '504',
  cca3: 'MAR',
  cioc: 'MAR',
  currency: ['MAD'],
  callingCode: ['212'],
  capital: 'Rabat',
  altSpellings: ['MA', 'Kingdom of Morocco', 'Al-Mamlakah al-Maġribiyah'],
  region: 'Africa',
  subregion: 'Northern Africa',
  languages: { ara: 'Arabic', ber: 'Berber' },
  translations: {
    deu: { official: 'Königreich Marokko', common: 'Marokko' },
    fra: { official: 'Royaume du Maroc', common: 'Maroc' },
    hrv: { official: 'Kraljevina Maroko', common: 'Maroko' },
    ita: { official: 'Regno del Marocco', common: 'Marocco' },
    jpn: { official: 'モロッコ王国', common: 'モロッコ' },
    nld: { official: 'Koninkrijk Marokko', common: 'Marokko' },
    por: { official: 'Reino de Marrocos', common: 'Marrocos' },
    rus: { official: 'Королевство Марокко', common: 'Марокко' },
    spa: { official: 'Reino de Marruecos', common: 'Marruecos' },
    fin: { official: 'Marokon kuningaskunta', common: 'Marokko' },
  },
  latlng: [32, -5],
  demonym: 'Moroccan',
  landlocked: false,
  borders: ['DZA', 'ESH', 'ESP'],
  area: 446550,
  provinces: [
    'Agadir',
    'Al Hoceima',
    'Azilal',
    'Ben Slimane',
    'Beni Mellal',
    'Boulemane',
    'Casablanca',
    'Chaouen',
    'El Jadida',
    'El Kelaa des Srarhna',
    'Er Rachidia',
    'Essaouira',
    'Fes',
    'Figuig',
    'Guelmim',
    'Ifrane',
    'Kenitra',
    'Khemisset',
    'Khenifra',
    'Khouribga',
    'Laayoune',
    'Larache',
    'Marrakech',
    'Meknes',
    'Nador',
    'Ouarzazate',
    'Oujda',
    'Rabat-Sale',
    'Safi',
    'Settat',
    'Sidi Kacem',
    'Tan-Tan',
    'Tanger',
    'Taounate',
    'Taroudannt',
    'Tata',
    'Taza',
    'Tetouan',
    'Tiznit',
  ],
  nativeName: 'المغرب',
  timezones: ['UTC'],
} as const;
