export const DEU = {
  name: {
    common: 'Germany',
    official: 'Federal Republic of Germany',
    native: {
      deu: { official: 'Bundesrepublik Deutschland', common: 'Deutschland' },
    },
  },
  cities: [
    'Langgons',
    'Holle',
    'Tespe',
    'Walsrode',
    'Salzgitter',
    'Bad Sassendorf',
    'Vienenburg',
    'Rosdorf',
    'Einbeck',
    'Markersbach',
    'Westensee',
    'Lauterbach',
    'Marburg',
    'Dornburg',
    'Mainz',
    'Weinbach',
    'Cologne',
    'Epfenbach',
    'Essen',
    'Huttenberg',
    'Borgentreich',
    'Mettmann',
    'Schutzbach',
    'Florstadt',
    'Markdorf',
    'Sponheim',
    'Warstein',
    'Gelsenkirchen',
    'Sprockhovel',
    'Miellen',
    'Grebenau',
    'Pohl',
    'Sohren',
    'Grosslittgen',
    'Dillenburg',
    'Frankfurt am Main',
    'Tholey',
    'Runkel',
    'Aerzen',
    'Chemnitz',
    'Worrstadt',
    'Windischleuba',
    'Unnau',
    'Waldbrol',
    'Gebhardshain',
    'Berg',
    'Rheinbreitbach',
    'Wandlitz',
    'Euskirchen',
    'Gau-Bickelheim',
    'Leitzkau',
    'Pfreimd',
    'Biederitz',
    'Langenwetzendorf',
    'Donndorf',
    'Stadtkyll',
    'Pressath',
    'Bockau',
    'Nossen',
    'Homberg',
    'Borken',
    'Tobertitz',
    'Haren',
    'Zossen',
    'Grundau',
    'Schlotfeld',
    'Apolda',
    'Limbach-Oberfrohna',
    'Konigs Wusterhausen',
    'Netphen',
    'Altkirchen',
    'Steyerberg',
    'Hohndorf',
    'Dortmund',
    'Spenge',
    'Selm',
    'Espenau',
    'Lemgo',
    'Bochum',
    'Halver',
    'Bergkamen',
    'Erndtebruck',
    'Rudersdorf',
    'LÃ¼beck',
    'Berlin',
    'Gluckstadt',
    'Bernau bei Berlin',
    'Moor',
    'Dresden',
    'Groditz',
    'Nitz',
    'Sebnitz',
    'Colmnitz',
    'Dobeln',
    'Lauchhammer',
    'Olbernhau',
    'Frankenberg',
    'Rabenau',
    'Finsterwalde',
    'Ebersbach',
    'Hockendorf',
    'Friedersdorf',
    'Coswig',
    'Radebeul',
    'Weinbohla',
    'Klettwitz',
    'Rammenau',
    'Freital',
    'Weisswasser',
    'Thiendorf',
    'Brand-Erbisdorf',
    'Senftenberg',
    'Lunzenau',
    'Halsbrucke',
    'Zwickau',
    'Dippoldiswalde',
    'Ottendorf-Okrilla',
    'Pirna',
    'Niederwiesa',
    'Frauendorf',
    'Wittgensdorf',
    'Falkenberg',
    'Geringswalde',
    'Burkhardtsdorf',
    'Bannewitz',
    'Lichtenberg',
    'Freiberg',
    'Lichtenstein',
    'Niesky',
    'Penig',
    'Calau',
    'Frauenstein',
    'Bischofswerda',
    'GÃ¶rlitz',
    'Halle',
    'Marienberg',
    'Rudolstadt',
    'Neukirchen',
    'Riesa',
    'Heidenau',
    'Priestewitz',
    'Zittau',
    'Oelsnitz',
    'Grossrohrsdorf',
    'Fraureuth',
    'Altenburg',
    'Ruckersdorf',
    'Leipzig',
    'Annaberg-Buchholz',
    'Sohland',
    'Schwarzenberg',
    'Mittweida',
    'Nunchritz',
    'Goldberg',
    'Schenkenhorst',
    'Woltersdorf',
    'Malchow',
    'Velten',
    'Havelberg',
    'Brandenburg',
    'Mullrose',
    'Magdeburg',
    'Lubtheen',
    'Waren',
    'Gransee',
    'Templin',
    'Genthin',
    'Klein Rogahn',
    'Vellahn',
    'Wefensleben',
    'Potsdam',
    'Luckenwalde',
    'Werneuchen',
    'Werder',
    'Brodhagen',
    'EisenhÃ¼ttenstadt',
    'Schermen',
    'Teltow',
    'Schollene',
    'Plate',
    'Hamburg',
    'Ganderkesee',
    'Seth',
    'Laatzen',
    'Stadthagen',
    'Hanover',
    'Varel',
    'Winsen',
    'Isenbuttel',
    'Jevenstedt',
    'Achim',
    'Uetze',
    'Bargfeld-Stegen',
    'Kiel',
    'Oldenburg',
    'Bremen',
    'Bad Zwischenahn',
    'Ratekau',
    'Heist',
    'Elmshorn',
    'Aurich',
    'Padelugge',
    'Buchholz',
    'Seevetal',
    'Meldorf',
    'Bonnigheim',
    'Uberlingen',
    'Geislingen',
    'Sandhausen',
    'Rottenburg',
    'Hessheim',
    'Ittlingen',
    'Mannheim',
    'Niefern-Oschelbronn',
    'Friedenweiler',
    'Bietigheim-Bissingen',
    'Elmstein',
    'Backnang',
    'Zwiefalten',
    'Rheinau',
    'Eigeltingen',
    'Freudenweiler',
    'Bad Liebenzell',
    'Landau',
    'Stuttgart',
    'Worms',
    'Ludwigshafen am Rhein',
    'Karlsruhe',
    'Pforzheim',
    'Bad Duerkheim',
    'Mersheim',
    'Ostfildern',
    'Hamm',
    'Bielefeld',
    'Bad Oeynhausen',
    'MÃ¼nster',
    'Dusseldorf',
    'Lubbecke',
    'Point',
    'Lohfelden',
    'Nordkirchen',
    'Kassel',
    'Hagen',
    'Soest',
    'Recklinghausen',
    'Paderborn',
    'Buende',
    'Geseke',
    'Neuenrade',
    'Pinneberg',
    'Neustadt-Glewe',
    'Gingst',
    'Calbe',
    'Ploen',
    'Seefeld',
    'Wesenberg',
    'Falkensee',
    'Barleben',
    'Neuruppin',
    'Zehdenick',
    'Weddingstedt',
    'Flensburg',
    'Heide',
    'Bargteheide',
    'Kaltenkirchen',
    'Geesthacht',
    'Ahrensburg',
    'Norderstedt',
    'Einhaus',
    'Alveslohe',
    'Itzehoe',
    'Reinbek',
    'Wedel',
    'Bad Segeberg',
    'Grosshansdorf',
    'Glinde',
    'Bad Bramstedt',
    'Barmstedt',
    'Schenefeld',
    'Borstel-Hohenraden',
    'Henstedt-Ulzburg',
    'Tornesch',
    'Ratzeburg',
    'Wentorf bei Hamburg',
    'Neu Wulmstorf',
    'Kroppelshagen-Fahrendorf',
    'Molln',
    'Husum',
    'Fahrendorf',
    'Halstenbek',
    'Wilhelmshaven',
    'Diepholz',
    'Cloppenburg',
    'Schortens',
    'Jever',
    'Lohne',
    'Damme',
    'Vechta',
    'Wittmund',
    'Delmenhorst',
    'Nuremberg',
    'Stein',
    'Gunzenhausen',
    'Erlangen',
    'Oberasbach',
    'Neumarkt-Sankt Veit',
    'Burgthann',
    'Herzogenaurach',
    'Feucht',
    'Ansbach',
    'Bamberg',
    'Lauf an der Pegnitz',
    'Gnotzheim',
    'Amberg',
    'FÃ¼rth',
    'Katzwang',
    'Schwarzenbruck',
    'Roth',
    'Schwabach',
    'Sulzbach-Rosenberg',
    'Heroldsberg',
    'Kummersbruck',
    'Rosstal',
    'Neunkirchen am Sand',
    'Baiersdorf',
    'Zirndorf',
    'Pegnitz',
    'Coburg',
    'Schwerte',
    'Unna',
    'Werl',
    'Waltrop',
    'Munich',
    'Ahlen',
    'LÃ¼denscheid',
    'Arnsberg',
    'Werne',
    'Kierspe',
    'Iserlohn',
    'Marienheide',
    'Beckum',
    'Warendorf',
    'Olsberg',
    'Duisburg',
    'Sundern',
    'LÃ¼nen',
    'Dorsten',
    'Frondenberg',
    'Wickede',
    'Brilon',
    'Herdecke',
    'Menden',
    'Holzwickede',
    'Hemer',
    'Deutsch',
    'Kamen',
    'Bingen am Rhein',
    'Bobenheim-Roxheim',
    'Pirmasens',
    'Eisenberg',
    'Speyer',
    'Landstuhl',
    'Viernheim',
    'Bad Kreuznach',
    'Hettenleidelheim',
    'Frankenthal',
    'Homburg',
    'Weinheim',
    'Dudenhofen',
    'Mutterstadt',
    'Grunstadt',
    'Munster-Sarmsheim',
    'Rodalben',
    'ZweibrÃ¼cken',
    'Neustadt/Westerwald',
    'Birkenau',
    'Germersheim',
    'Altrip',
    'Schriesheim',
    'Hordt',
    'Ramstein-Miesenbach',
    'Mehlbach',
    'Langen',
    'Neustadt',
    'Heidelberg',
    'Neckargemund',
    'Leimen',
    'Dannstadt-Schauernheim',
    'Schifferstadt',
    'Limburgerhof',
    'Eppelheim',
    'Bad Mergentheim',
    'Schwetzingen',
    'Lampertheim',
    'Dossenheim',
    'Nussloch',
    'Ellerstadt',
    'Neuhofen',
    'Kahl am Main',
    'Ketsch',
    'Rodersheim-Gronau',
    'Rubke',
    'Naila',
    'Steinwiesen',
    'Selbitz',
    'Radolfzell',
    'Konstanz',
    'Rastatt',
    'Rulzheim',
    'SchwÃ¤bisch Hall',
    'Gaildorf',
    'Giengen an der Brenz',
    'Lauchheim',
    'Wasser',
    'Freiburg',
    'Bad Krozingen',
    'Schopfheim',
    'LÃ¶rrach',
    'Bad Sackingen',
    'Leinfelden-Echterdingen',
    'Echterdingen',
    'Wuppertal',
    'Krefeld',
    'Alfter',
    'Hilchenbach',
    'Kaarst',
    'Winterberg',
    'Steinbach am Taunus',
    'Remscheid',
    'Rheinbach',
    'Preussisch Oldendorf',
    'Huellhorst',
    'Grossbottwar',
    'Erftstadt',
    'Burgkunstadt',
    'Langfurth',
    'Gleissenberg',
    'Viechtach',
    'Nittenau',
    'Chamerau',
    'Jena',
    'Bad Durrenberg',
    'Oranienburg',
    'Bernburg',
    'Wernigerode',
    'Cottbus',
    'Lubbenau',
    'Schonefeld',
    'Plauen',
    'Bodenfelde',
    'Wolfhagen',
    'Host',
    'Kirchheim unter Teck',
    'Kurnbach',
    'Weiden',
    'Hemmingen',
    'Igensdorf',
    'Volkach',
    'Bergtheim',
    'Sulzheim',
    'Baar-Ebenhausen',
    'Beilngries',
    'Altmannstein',
    'Rottweil',
    'Blumberg',
    'Dauchingen',
    'Lauda-Konigshofen',
    'Tauberbischofsheim',
    'Wertheim am Main',
    'Crailsheim',
    'Neckarsulm',
    'Bretzfeld',
    'Heilbronn',
    'Rheinfelden',
    'Tuttlingen',
    'Fridingen an der Donau',
    'Owingen',
    'Berghausen',
    'Appenweier',
    'Bruchsal',
    'Gondelsheim',
    'Friolzheim',
    'Bad Wildbad im Schwarzwald',
    'Olbronn-Durrn',
    'Wildberg',
    'Althengstett',
    'Ludwigsburg',
    'Tamm',
    'Sindelfingen',
    'Herrenberg',
    'Ehningen',
    'Renningen',
    'Gosheim',
    'Bad Durrheim',
    'Wehingen',
    'Villingen-Schwenningen',
    'Esslingen am Neckar',
    'Ellwangen',
    'Wiesloch',
    'Friedrichshafen',
    'Tettnang Castle',
    'Epfendorf',
    'Bohringen',
    'Horb am Neckar',
    'Bad Uberkingen',
    'GÃ¶ppingen',
    'Gladbeck',
    'Herten',
    'Gera',
    'Stahl',
    'Wintrich',
    'Hermersberg',
    'Thallichtenberg',
    'Merzig',
    'Saarlouis',
    'Engen',
    'Kaufungen',
    'Aachen',
    'Herzogenrath',
    'Frechen',
    'Wetzlar',
    'Herne',
    'Babenhausen',
    'Darmstadt',
    'Morfelden-Walldorf',
    'Taunusstein',
    'Offenbach',
    'Oberhausen',
    'Castrop-Rauxel',
    'Oberursel',
    'Bad Soden am Taunus',
    'Jestadt',
    'Minden',
    'Wesseling',
    'Inden',
    'Hattingen',
    'Kelkheim (Taunus)',
    'Wiesbaden',
    'Kall',
    'Mechernich',
    'Sankt Augustin',
    'Bonn',
    'Leichlingen',
    'Leverkusen',
    'MÃ¼lheim',
    'Bad Vilbel',
    'Kempen',
    'Florsheim',
    'Ginsheim-Gustavsburg',
    'Heusenstamm',
    'Hofheim am Taunus',
    'Obertshausen',
    'Nauheim',
    'RÃ¼sselsheim',
    'Hochheim am Main',
    'Kelsterbach',
    'Bischofsheim',
    'Assenheim',
    'Kleve',
    'Willich',
    'Bedburg',
    'Senden',
    'Friedberg',
    'Eriskirch',
    'Ravensburg',
    'Immenstaad am Bodensee',
    'Hockenheim',
    'Albstadt',
    'Wurmlingen',
    'Rielasingen-Worblingen',
    'Singen',
    'Pfullendorf',
    'Waiblingen',
    'Kuchen',
    'Salach',
    'Lorch',
    'Gerlingen',
    'Kressbronn am Bodensee',
    'Oftersheim',
    'Geisingen',
    'Wolfsburg',
    'Wahrenholz',
    'Braunschweig',
    'Meine',
    'Lentfohrden',
    'Holm',
    'Bonningstedt',
    'Hasloh',
    'Moritz',
    'Ulm',
    'Neu-Ulm',
    'Rellingen',
    'Erkner',
    'Neuenhagen',
    'Uelzen',
    'Wustermark',
    'Spremberg',
    'Bestensee',
    'Woltersdorf',
    'Mittenwalde',
    'Furstenwalde',
    'Cuxhaven',
    'Grevesmuhlen',
    'Wismar',
    'OsnabrÃ¼ck',
    'Rostock',
    'Bredstedt',
    'Niebull',
    'Eckernforde',
    'Georgsmarienhutte',
    'Bad Iburg',
    'Hildesheim',
    'Hamelin',
    'Hinte',
    'Emden',
    'Goslar',
    'Peine',
    'Helmstedt',
    'Konigslutter am Elm',
    'GÃ¶ttingen',
    'Stuhr',
    'Langen',
    'Nordenham',
    'Bremerhaven',
    'Lehrte',
    'WolfenbÃ¼ttel',
    'Ahstedt',
    'Nienburg',
    'Rotenburg',
    'Herzberg am Harz',
    'Hemelingen',
    'Hohenkirchen-Siegertsbrunn',
    'Deisenhofen',
    'Halfing',
    'Weilheim',
    'Lindenberg im Allgau',
    'Oberstaufen',
    'Scheidegg',
    'Bayreuth',
    'Burghausen',
    'Schweinfurt',
    'Markt Wald',
    'Weissenburg',
    'Treuchtlingen',
    'Emmering',
    'FÃ¼rstenfeldbruck',
    'Gilching',
    'Schwabhausen',
    'Grobenzell',
    'Planegg',
    'Kempten (Allgaeu)',
    'Olching',
    'Maisach',
    'Murnau am Staffelsee',
    'Oberau',
    'FÃ¼ssen',
    'Waldkraiburg',
    'Passau',
    'Guenzburg',
    'Burgau',
    'Krumbach',
    'Werningshausen',
    'Ilmenau',
    'Handewitt',
    'Harrislee',
    'Kappeln',
    'Bremervorde',
    'Kuddeworde',
    'Greifswald',
    'Preetz',
    'Bordesholm',
    'Jesteburg',
    'Buchholz in der Nordheide',
    'LÃ¼neburg',
    'Winsen',
    'Bad Schwartau',
    'Herrnburg',
    'Raisdorf',
    'Parchim',
    'Ueckermunde',
    'Melsdorf',
    'Stralsund',
    'Simonsberg',
    'Scharbeutz',
    'Heikendorf',
    'Schwerin',
    'Bruel',
    'Wittenforden',
    'Neustrelitz',
    'Robel',
    'Loitz',
    'Ostseebad Kuhlungsborn',
    'Langwedel',
    'Hassel',
    'Mildstedt',
    'Stade',
    'Deutsch Evern',
    'Pasewalk',
    'Neubrandenburg',
    'Schneverdingen',
    'Soltau',
    'Fahrenkrug',
    'Dorverden',
    'Schonberg',
    'NeumÃ¼nster',
    'Wahlstedt',
    'Rendsburg',
    'Borgstedt',
    'Westerronfeld',
    'Osterronfeld',
    'Kremperheide',
    'Oelixdorf',
    'Oststeinbek',
    'Schleswig',
    'Appen',
    'Maschen',
    'Dassendorf',
    'Schwarzenbek',
    'Wohltorf',
    'Wees',
    'Verden an der Aller',
    'Schonkirchen',
    'Sarkwitz',
    'Hamberge',
    'Kronshagen',
    'Ebstorf',
    'Molfsee',
    'Mielkendorf',
    'Ellerau',
    'Timmendorf Beach',
    'Buedelsdorf',
    'Bendestorf',
    'Uetersen',
    'Lauenburg',
    'Neustadt in Holstein',
    'Heiligenhafen',
    'Lutjenburg',
    'Quickborn',
    'Tangstedt',
    'Tangstedt',
    'Negast',
    'Langendorf',
    'Stockelsdorf',
    'Prangendorf',
    'Lagerdorf',
    'Jersbek',
    'Lutjensee',
    'Hoisdorf',
    'Tremsbuttel',
    'Nahe',
    'Itzstedt',
    'Dabelow',
    'Monkeberg',
    'Altenholz',
    'Buxtehude',
    'Escheburg',
    'Barsbuettel',
    'Ludwigslust',
    'Elmenhorst',
    'Grabau',
    'Sassnitz',
    'Sagard',
    'Kisdorf',
    'Selmsdorf',
    'Kummerfeld',
    'Krempe',
    'Hanstedt',
    'Demmin',
    'Prisdorf',
    'Schwalingen',
    'Sprengel',
    'Neuenkirchen',
    'Munster',
    'Uelvesbull',
    'Neu Duvenstedt',
    'Wendisch Evern',
    'Adendorf',
    'Marne',
    'Celle',
    'Jork',
    'Reppenstedt',
    'Bad Bevensen',
    'Wrestedt',
    'Vogelsen',
    'Bardowick',
    'Apensen',
    'Hemmoor',
    'Rosengarten',
    'Eggingen',
    'Kadelburg',
    'Wiernsheim',
    'Mittelstenweiler',
    'Nagold',
    'Leonberg',
    'SchwÃ¤bisch GmÃ¼nd',
    'Hochdorf',
    'Ingoldingen',
    'Mutlangen',
    'Mittelbiberach',
    'Biberach an der Riss',
    'Loxstedt',
    'Stinstedt',
    'Wanna',
    'Brake (Unterweser)',
    'Hechthausen',
    'Hambergen',
    'Schiffdorf',
    'Lamstedt',
    'Hagen',
    'Himmelpforten',
    'Vollersode',
    'Nordholz',
    'Bulkau',
    'Drochtersen',
    'Bockhorn',
    'Sande',
    'Zetel',
    'Friedeburg',
    'Wiesmoor',
    'Schweiburg',
    'Sottrum',
    'Dohren',
    'Tarmstedt',
    'Tostedt',
    'Hemsbunde',
    'Zeven',
    'Helvesiek',
    'Otter',
    'Worpswede',
    'Schwanewede',
    'Gnarrenburg',
    'Mittelnkirchen',
    'Lilienthal',
    'Syke',
    'Rastede',
    'Westerstede',
    'Hatten',
    'Garrel',
    'Friesoythe',
    'Norden',
    'Blomberg',
    'Hage',
    'Mittegrossefehn',
    'Eversmeer',
    'Esens',
    'Upgant-Schott',
    'Hesel',
    'Ihlow',
    'Westerholt',
    'Wirdum',
    'Berumbur',
    'Dornum',
    'Grossheide',
    'Remels',
    'Norderney',
    'Herford',
    'Melle',
    'Hiddenhausen',
    'Espelkamp',
    'Lohne',
    'Lauenhagen',
    'Enger',
    'Adorf',
    'Trostberg an der Alz',
    'Insingen',
    'Dietramszell',
    'Rabenholz',
    'Dassow',
    'Weinsfeld',
    'Barkow',
    'Wermelskirchen',
    'Poing',
    'Riedering',
    'Maintal',
    'Rothenstein',
    'Erfurt',
    'Auma',
    'Heidenburg',
    'Laufach',
    'Freilassing',
    'Bad Reichenhall',
    'Piding',
    'Saaldorf',
    'Weissenhorn',
    'Gauting',
    'Grafelfing',
    'Senden',
    'Pfaffenhofen an der Roth',
    'Poppenricht',
    'Ebermannsdorf',
    'Geretsried',
    'Starnberg',
    'Wolfratshausen',
    'Pocking',
    'Freising',
    'Mindelheim',
    'Altusried',
    'Neufahrn bei Freising',
    'Hallbergmoos',
    'Konigsbrunn',
    'Buchloe',
    'Regensburg',
    'Weiden',
    'Marktredwitz',
    'Arzberg',
    'Bad Alexandersbad',
    'Hallstadt',
    'Viereth-Trunstadt',
    'Pressig',
    'Stockstadt am Main',
    'Aschaffenburg',
    'Amorbach',
    'Friedrichsdorf',
    'Kleinostheim',
    'Hosbach',
    'Niedernberg',
    'Emtmannsberg',
    'Speichersdorf',
    'Bindlach',
    'Weidenberg',
    'Osterode',
    'Andreasberg',
    'Bad Pyrmont',
    'Bad Gandersheim',
    'Dassel',
    'Kreiensen',
    'Rinteln',
    'Belm',
    'Meppen',
    'Osterholz-Scharmbeck',
    'Papenburg',
    'Langenhagen',
    'Wangerooge',
    'Gifhorn',
    'Calberlah',
    'Northeim',
    'Uslar',
    'Langelsheim',
    'Springe',
    'Stolzenau',
    'Sulingen',
    'Schoneiche',
    'Marklohe',
    'Liebenau',
    'Landesbergen',
    'Wriezen',
    'Strausberg',
    'Wennigsen',
    'Hagenow',
    'Boizenburg',
    'Bad Rothenfelde',
    'Trappenkamp',
    'Kalefeld',
    'Erding',
    'Pliening',
    'Markt Schwaben',
    'Oberding',
    'Diedorf',
    'Neusass',
    'Burgkirchen an der Alz',
    'Altoetting',
    'Haiming',
    'Neuotting',
    'Oberhaching',
    'Burglengenfeld',
    'Lappersdorf',
    'Polling',
    'Augsburg',
    'Penzberg',
    'Wielenbach',
    'Peissenberg',
    'Miesbach',
    'Kaufbeuren',
    'Gundelfingen',
    'Sauerlach',
    'Mehring',
    'Oberkotzau',
    'Hof',
    'Otterloh',
    'Landshut',
    'Glattbach',
    'Johannesberg',
    'Unterschleissheim',
    'Grafing bei Munchen',
    'Kirchseeon',
    'Haar',
    'Kaiserslautern',
    'Enkenbach-Alsenborn',
    'Reich',
    'Umkirch',
    'Baden-Baden',
    'Efringen-Kirchen',
    'Eschweiler',
    'Alsdorf',
    'Bad Homburg',
    'Niederkruchten',
    'Meerbusch',
    'Marl',
    'Bad Wildungen',
    'Erkelenz',
    'Haltern',
    'Ratingen',
    'Bocholt',
    'Bergisch Gladbach',
    'Gelnhausen',
    'Seligenstadt',
    'Hanau',
    'Bruhl',
    'Lohmar',
    'MÃ¶nchengladbach',
    'Neuss',
    'Troisdorf',
    'Geldern',
    'Egenhausen',
    'Altensteig',
    'Grombach',
    'Calw',
    'Inzigkofen',
    'Muhlacker',
    'Ostrach',
    'Flein',
    'Buhl',
    'Wolfschlugen',
    'Wendlingen am Neckar',
    'Volkertshausen',
    'Teningen',
    'Albershausen',
    'Zell unter Aichelberg',
    'Nurtingen',
    'Kirchentellinsfurt',
    'Wannweil',
    'Schiltach',
    'Unterkirnach',
    'Monchweiler',
    'Spaichingen',
    'Weinstadt-Endersbach',
    'Urbach',
    'Vogt',
    'Aulendorf',
    'Reutlingen',
    'Balingen',
    'Konigsbronn',
    'Aalen',
    'Pluderhausen',
    'Neuhausen auf den Fildern',
    'Dietenheim',
    'Ummendorf',
    'Mengen',
    'Saulgau',
    'Unlingen',
    'Metzingen',
    'Sinsheim',
    'Obrigheim',
    'Oedheim',
    'Ditzingen',
    'Fellbach',
    'Langenau',
    'Laupheim',
    'Warthausen',
    'Dornstadt',
    'Winnenden',
    'Schwieberdingen',
    'Maulburg',
    'Lauchringen',
    'Inzlingen',
    'Philippsburg',
    'Waghausel',
    'Ettlingen',
    'Bietigheim',
    'Kehl',
    'Berghaupten',
    'Altlussheim',
    'Frickingen',
    'Freudenstadt',
    'SaarbrÃ¼cken',
    'Kemnath',
    'Lindau',
    'Wangen',
    'Rodental',
    'Burgrieden',
    'Schuttorf',
    'Emlichheim',
    'Borger',
    'Neuenhaus',
    'Osterwald',
    'Visselhovede',
    'Scheessel',
    'Harsefeld',
    'Vierhofen',
    'Salzhausen',
    'Hollenstedt',
    'Garlstorf',
    'Egestorf',
    'Asendorf',
    'Hemslingen',
    'Sittensen',
    'Ruspel',
    'Hetzwege',
    'Vierden',
    'Selsingen',
    'Grasberg',
    'Hammah',
    'Edewecht',
    'Harpstedt',
    'Bassum',
    'Holtinghausen',
    'Grossenkneten',
    'Beckstedt',
    'Bosel',
    'Dingstede',
    'Mehr',
    'Bank',
    'Memmingerberg',
    'Hetzlinshofen',
    'Lachen',
    'Zusmarshausen',
    'Stolberg',
    'GÃ¼tersloh',
    'Vellmar',
    'Ennepetal',
    'Neuwerk',
    'Viersen',
    'Korschenbroich',
    'Nidda',
    'Erkrath',
    'Hilden',
    'Isenburg',
    'Biedenkopf',
    'Langenfeld',
    'Rosrath',
    'Monheim am Rhein',
    'Frankenberg',
    'Dreieich',
    'Fulda',
    'DÃ¼ren',
    'Kerpen',
    'Julich',
    'Kreuzau',
    'Espelkamp-Mittwald',
    'Sigmaringen',
    'Schlaitdorf',
    'TÃ¼bingen',
    'Ettenheim',
    'Offenburg',
    'Staufen im Breisgau',
    'Nufringen',
    'BÃ¶blingen',
    'Hildrizhausen',
    'Heidenheim',
    'Rosengarten',
    'Leutkirch im Allgau',
    'Sankt Leon-Rot',
    'Heddesheim',
    'Reichenbach',
    'Talheim',
    'Steinheim am der Murr',
    'Lochgau',
    'Sipplingen',
    'Sulz am Neckar',
    'Ilsfeld',
    'Mosbach',
    'Friesenheim',
    'Durbach',
    'Welzheim',
    'Donzdorf',
    'Dettingen unter Teck',
    'Filderstadt',
    'Ohringen',
    'Rietheim',
    'Tuningen',
    'Karben',
    'Heusweiler',
    'Sankt Ingbert',
    'Illingen',
    'Volklingen',
    'Grossrosseln',
    'Sankt Wendel',
    'Riegelsberg',
    'Quierschied',
    'Saarwellingen',
    'Remmesweiler',
    'Dessau',
    'Bernsdorf',
    'Grimma',
    'Saalfeld',
    'Neumark',
    'Erlenmoos',
    'Vaihingen an der Enz',
    'Erdmannhausen',
    'Weitnau',
    'Bad Waldsee',
    'Kornwestheim',
    'Bad Herrenalb',
    'Loffenau',
    'Weingarten',
    'Wangen im Allgau',
    'Herrischried',
    'Denkingen',
    'Loffingen',
    'Schorndorf',
    'Denzlingen',
    'Eschenbach',
    'Bissingen an der Teck',
    'Eislingen',
    'Unterensingen',
    'Bad Ditzenbach',
    'Neuenburg am Rhein',
    'Walldorf',
    'Laudenbach',
    'Durmersheim',
    'Untergruppenbach',
    'Eberstadt',
    'Bad Rappenau',
    'Raibach',
    'Braunsbach',
    'Heubach',
    'Ladenburg',
    'Bruhl',
    'Plankstadt',
    'Schwaikheim',
    'Kuppenheim',
    'Hirrlingen',
    'Rangendingen',
    'Weikersheim',
    'Pliezhausen',
    'Erbach',
    'Mietingen',
    'Huttisheim',
    'Rottenacker',
    'Zaberfeld',
    'Eisingen',
    'Weil der Stadt',
    'Schlat',
    'Kongen',
    'Walldurn',
    'Niederstetten',
    'Bad Wimpfen',
    'Zweiflingen',
    'Siegelsbach',
    'Brackenheim',
    'Asperg',
    'Aspach',
    'Dusslingen',
    'Hechingen',
    'Grossbettlingen',
    'Herdwangen-Schonach',
    'Schallstadt',
    'Schliengen',
    'Gernsbach',
    'Kuenzelsau',
    'Lautenbach',
    'Kippenheim',
    'Niederstotzingen',
    'Baltringen',
    'Rehburg-Loccum',
    'Stockse',
    'Neustadt am Rubenberge',
    'Husum',
    'Uchte',
    'Dornheim',
    'Premnitz',
    'Ingolstadt',
    'Dachau',
    'Pullhausen',
    'Sochtenau',
    'Rosenheim',
    'Radevormwald',
    'Boostedt',
    'Gross Kummerfeld',
    'Bonebuttel',
    'Ehndorf',
    'Grossenaspe',
    'Bruckberg',
    'Krugzell',
    'Oesterdeichstrich',
    'Kolpien',
    'Numbrecht',
    'Eppingen',
    'Oppenweiler',
    'Wald-Michelbach',
    'Trebur',
    'Tellingstedt',
    'Proseken',
    'Inzell',
    'Aichwald',
    'Hude',
    'Pleckhausen',
    'Velbert',
    'Pulheim',
    'Delitzsch',
    'Westerburg',
    'HÃ¼rth',
    'Solingen',
    'Elz',
    'Quedlinburg',
    'Frankfurt (Oder)',
    'Unterfoehring',
    'Fohren',
    'Ottobrunn',
    'Weil am Rhein',
    'Heimstetten',
    'Schacht-Audorf',
    'Bohmstedt',
    'Rumohr',
    'Probsteierhagen',
    'Schellhorn',
    'Laboe',
    'Lebrade',
    'Schonberg',
    'Flintbek',
    'Wisch',
    'Quarnbek',
    'Felde',
    'Schoenberg',
    'Drelsdorf',
    'Langwieden',
    'Sippersfeld',
    'Morschheim',
    'Fohren-Linden',
    'Altenglan',
    'Perl',
    'Kirchheimbolanden',
    'Schellweiler',
    'Losheim',
    'Saalstadt',
    'Hoppstadten-Weiersbach',
    'Hoheinod',
    'Schiffweiler',
    'Hefersweiler',
    'Battenberg',
    'Lotzbeuren',
    'Pfeffelbach',
    'Gollheim',
    'Hohenollen',
    'Nonnweiler',
    'Herschweiler-Pettersheim',
    'Weingarten',
    'Etschberg',
    'Ayl',
    'Wattenheim',
    'Klingenmunster',
    'Ulmet',
    'Kirchheim an der Weinstrasse',
    'Dillingen',
    'Weitersweiler',
    'Bolanden',
    'Bubenheim',
    'Molsheim',
    'Esselborn',
    'Brucken',
    'Bernstadt',
    'DinkelsbÃ¼hl',
    'Zipplingen',
    'Neresheim',
    'Rammingen',
    'Donaueschingen',
    'Villingendorf',
    'Bad Griesbach',
    'Lahr',
    'Achern',
    'Hambrucken',
    'Eggenstein-Leopoldshafen',
    'Dielheim',
    'Ilvesheim',
    'Guglingen',
    'Abstatt',
    'Grenzach-Wyhlen',
    'Auggen',
    'Wehr',
    'Deizisau',
    'Steinenbronn',
    'Aldingen',
    'Weissach',
    'Bisingen',
    'Vellberg',
    'Schechingen',
    'Eschach',
    'Gartringen',
    'Freudental',
    'Breisach',
    'Freiberg am Neckar',
    'Murrhardt',
    'Weinsberg',
    'Ehingen',
    'Weil im Schonbuch',
    'Offenau',
    'Ortenberg',
    'Hohberg',
    'Gemmrigheim',
    'Baiersbronn',
    'Jettingen-Scheppach',
    'Bosingen',
    'Bad Urach',
    'Dogern',
    'Hilzingen',
    'Oberkirch',
    'Schutterwald',
    'Thalheim',
    'Oberndorf',
    'Langenargen',
    'Weilheim',
    'Hirschberg',
    'Ebersbach an der Fils',
    'Beuren',
    'Deggingen',
    'Notzingen',
    'Neuffen',
    'Karlsdorf-Neuthard',
    'Otigheim',
    'Blaubeuren Abbey',
    'Schnurpflingen',
    'Stockach',
    'Amstetten',
    'Riedlingen',
    'Teublitz',
    'Straubing',
    'Weilmunster',
    'Wehrheim',
    'Hungen',
    'Floha',
    'Klaus',
    'Holzgerlingen',
    'Wurmberg',
    'Treffelhausen',
    'Dettingen an der Erms',
    'MÃ¼llheim',
    'Uhingen',
    'Ohmden',
    'Riedhausen',
    'Denkendorf',
    'Kreuzwertheim',
    'Mossingen',
    'Sindringen',
    'Bad Konig',
    'Heppenheim an der Bergstrasse',
    'Michelstadt',
    'Hochst im Odenwald',
    'Finkenbach',
    'Beerfelden',
    'Langerringen',
    'Unterhaching',
    'Holzheim',
    'Denklingen',
    'Landsberg am Lech',
    'Dillingen an der Donau',
    'Meitingen',
    'Langweid',
    'Schwifting',
    'Rednitzhembach',
    'Schrobenhausen',
    'Schwaig',
    'Seukendorf',
    'Hamminkeln',
    'Ahaus',
    'Heiden',
    'Bad Berka',
    'Ramerberg',
    'Seeon-Seebruck',
    'Pfaffing',
    'Prien am Chiemsee',
    'Artlenburg',
    'Amelinghausen',
    'Oldendorf',
    'Prebberede',
    'Michendorf',
    'Badow',
    'Linow',
    'Gorschendorf',
    'Tieplitz',
    'Fincken',
    'Stolzenhagen',
    'Protzen',
    'Siedenbrunzow',
    'Warin',
    'Neutrebbin',
    'Klotze',
    'Ahrensfelde',
    'Beelitz',
    'Guestrow',
    'Warrenzin',
    'Rovershagen',
    'Ankershagen',
    'Burg bei Magdeburg',
    'Schwaneberg',
    'Zerbst',
    'Steinhagen',
    'Kirchlengern',
    'Vohl',
    'Lugde',
    'Rollshausen',
    'Lengerich',
    'Niederaula',
    'Immenhausen',
    'Blomberg',
    'Grebenstein',
    'Emstal',
    'Rotenburg an der Fulda',
    'Meinerzhagen',
    'Attendorn',
    'Dorentrup',
    'Neuenstein',
    'Eslohe',
    'Warburg',
    'Horn-Bad Meinberg',
    'Medebach',
    'Dulmen',
    'Oelde',
    'Halle',
    'Werdohl',
    'Felsberg',
    'Siegen',
    'Alheim',
    'Beverungen',
    'Petershagen',
    'Kreuztal',
    'Bad Hersfeld',
    'Glandorf',
    'Bramsche',
    'Baunatal',
    'Stelle',
    'Brokdorf',
    'Sulfeld',
    'Bomlitz',
    'Osterrade',
    'Bad Oldesloe',
    'Bosau',
    'Sommerland',
    'Triptis',
    'Wundersleben',
    'Limbach',
    'Mannichswalde',
    'Fuchshain',
    'Wetterzeube',
    'Osterhausen',
    'Mechelroda',
    'Bornitz',
    'Schkolen',
    'Friedrichroda',
    'Meuselwitz',
    'Saara',
    'Sangerhausen',
    'Plaue',
    'Ilshofen',
    'Konigsfeld im Schwarzwald',
    'Bopfingen',
    'Otisheim',
    'Grevenbroich',
    'Radefeld',
    'Domsdorf',
    'Weischlitz',
    'Luebben',
    'Berka',
    'Hohenmolsen',
    'Kodersdorf',
    'Zeithain',
    'Holzhausen',
    'Hohen',
    'Nohra',
    'Hundhaupten',
    'Deiningen',
    'Siegenburg',
    'Mettenheim',
    'Mainburg',
    'Parsdorf',
    'Antdorf',
    'Puchheim',
    'Wolfgang',
    'Taufkirchen',
    'Petersdorf',
    'Neufarn',
    'Pottmes',
    'Weichering',
    'Saal',
    'Steinhoring',
    'Soyen',
    'Hagelstadt',
    'Westendorf',
    'Gersthofen',
    'Mitwitz',
    'Kirchberg',
    'Haselbach',
    'Oberschneiding',
    'Hadamar',
    'Wittlich',
    'Dietzenbach',
    'Sinn',
    'Plein',
    'Asslar',
    'Eschborn',
    'Holzhausen an der Haide',
    'Bad Soden-Salmunster',
    'Berg',
    'Idstein',
    'Dexheim',
    'Butzbach',
    'Koblenz',
    'Massbach',
    'Reiskirchen',
    'Montabaur',
    'Becheln',
    'Obermeilingen',
    'Diefflen',
    'Hundsangen',
    'Glashutten',
    'Neuwied',
    'Udenheim',
    'Waldbrunn',
    'Wolfersheim',
    'Hunfeld',
    'Hunzel',
    'Mandel',
    'Zeitlofs',
    'Mehlingen',
    'Grunberg',
    'Altenstadt',
    'Rodenbach',
    'Sterzhausen',
    'Bad Camberg',
    'Landsberg',
    'Pockau',
    'Ettersburg',
    'Zeitz',
    'Kelbra',
    'Riethnordhausen',
    'Schwerstedt',
    'Naumburg',
    'Oranienbaum',
    'Rechenberg-Bienenmuhle',
    'Berga',
    'Glauchau',
    'Waltershausen',
    'Arensdorf',
    'Waldau',
    'Wilhelmsdorf',
    'Isseroda',
    'Lugau',
    'Eberstadt',
    'Callenberg',
    'Fambach',
    'Erlau',
    'Freyburg',
    'Tanna',
    'Kleinschwabhausen',
    'Lederhose',
    'Unterwellenborn',
    'Grossposna',
    'Kirchhasel',
    'Schleid',
    'Neunheilingen',
    'Goch',
    'Elsdorf',
    'Blankenheim',
    'Kevelaer',
    'Dormagen',
    'Titz',
    'Dinslaken',
    'Bergheim',
    'Nettersheim',
    'Neunkirchen',
    'Heimbach',
    'Zulpich',
    'Wesel',
    'Konigswinter',
    'Gummersbach',
    'Kirchlinteln',
    'Bienenbuttel',
    'Schuby',
    'Blender',
    'Friedrichsholm',
    'Schwienau',
    'Anderlingen',
    'Gommern',
    'Kritzow',
    'Mieste',
    'Ringenwalde',
    'Boldebuck',
    'Gulzow',
    'Nauen',
    'Bastorf',
    'Rehfelde',
    'Rossau',
    'Bautzen',
    'Luckau',
    'Welzow',
    'Pinnow',
    'Grossschirma',
    'Kamenz',
    'Berggiesshubel',
    'Kesselsdorf',
    'Merschwitz',
    'Hoyerswerda',
    'Malschwitz',
    'Pfaffroda',
    'Kurort Hartha',
    'Anzing',
    'Ottenhofen',
    'Irschenberg',
    'Riegsee',
    'Selb',
    'Bolsterlang',
    'Rottenburg an der Laaber',
    'Cham',
    'Wessobrunn Abbey',
    'Moschenfeld',
    'Dietmannsried',
    'Dollern',
    'Berne',
    'Breuna',
    'Eppstein',
    'Girod',
    'Schwalbach',
    'Chiemsee',
    'Pattensen',
    'Gaishofen',
    'Sarstedt',
    'Bad Salzungen',
    'Gerstetten',
    'Laichingen',
    'Essingen',
    'Bobingen',
    'Machtolsheim',
    'Abtsgmuend',
    'Hauzenberg',
    'Wegscheid',
    'Hunxe',
    'Greven',
    'Borken',
    'Linz am Rhein',
    'Hattersheim',
    'Poppenhausen',
    'Eiterfeld',
    'Ranstadt',
    'Kleinkahl',
    'Happurg',
    'Hannover',
    'Streich',
    'Schutzenhof',
    'Markt',
    'Alexander',
    'Breckerfeld',
    'Dolgesheim',
    'Reinhartshausen',
    'Monschau',
    'Simmerath',
    'Hartenstein',
    'Massenheim',
    'Herzfeld',
    'Volkmarsen',
    'Kamp-Lintfort',
    'Mohnesee',
    'Witten',
    'Georgensgmund',
    'Westhausen',
    'Weiltingen',
    'Sontheim an der Brenz',
    'NÃ¶rdlingen',
    'Huttlingen',
    'Tannhausen',
    'Steinweiler',
    'Nachrodt-Wiblingwerde',
    'Herscheid',
    'Grenzau',
    'Dobler',
    'Mulheim-Karlich',
    'Karlstein',
    'Gehrden',
    'Wurselen',
    'Detmold',
    'Altena',
    'Schmolln',
    'Kanzlei',
    'Erika',
    'Ergersheim',
    'Ebermannstadt',
    'Kranenburg',
    'Freund',
    'Hohenhaus',
    'Hirschau',
    'Bruckmuhl',
    'Worfelden',
    'Gronau',
    'Heiligenroth',
    'Roding',
    'Wohlsborn',
    'Butzow',
    'Neu Sanitz',
    'Gremersdorf',
    'Neubukow',
    'Stabelow',
    'Glasewitz',
    'Dettmannsdorf',
    'Wattmannshagen',
    'Tutow',
    'Splietsdorf',
    'Gravenwiesbach',
    'Usingen',
    'Konigstein im Taunus',
    'Urspringen',
    'Eichenbuhl',
    'Konigsberg in Bayern',
    'Theilheim',
    'Schwanfeld',
    'Wurzburg',
    'Knetzgau',
    'Wiesentheid',
    'Dombuhl',
    'Munchaurach',
    'Neunkirchen',
    'Weisendorf',
    'Bubenreuth',
    'Telgte',
    'Limburg an der Lahn',
    'Marbach am Neckar',
    'Kitzingen',
    'Gettorf',
    'Wesselburen',
    'Gross Kreutz',
    'Ochsenhausen',
    'Bad Wurzach',
    'Bissendorf',
    'Berg',
    'Werdau',
    'Berching',
    'Anklam',
    'Much',
    'Hauser',
    'Huglfing',
    'Penzing',
    'Garmisch-Partenkirchen',
    'Rohrmoos',
    'Forstern',
    'Bad Aibling',
    'Krailling',
    'Hohenkammer',
    'Feldkirchen-Westerham',
    'Hohenpeissenberg',
    'Klais',
    'Supplingen',
    'Clausthal-Zellerfeld',
    'Altenau',
    'Sassenburg',
    'Korntal',
    'Offenbach an der Queich',
    'Rheurdt',
    'Moers',
    'Hasloch',
    'Friedberg',
    'Buttenwiesen',
    'Munster',
    'Marxheim',
    'Ziemetshausen',
    'Althegnenberg',
    'Wessiszell',
    'Schmiechen',
    'Langenneufnach',
    'Nordendorf',
    'Leipheim',
    'Fahrenzhausen',
    'Apfeldorf',
    'Peiting',
    'Gaimersheim',
    'Loiching',
    'Rhinow',
    'Wiesenburg',
    'Verl',
    'WipperfÃ¼rth',
    'Geiselbach',
    'Ingelheim am Rhein',
    'Barsinghausen',
    'Oestrich-Winkel',
    'Seebad Bansin',
    'Bayerisch Gmain',
    'Ismaning',
    'Garbsen',
    'Stellshagen',
    'Usedom',
    'Wilhelmsthal',
    'Dornstetten',
    'Dieburg',
    'Mombris',
    'Forsthaus',
    'Albertshausen',
    'Osloss',
    'Tappenbeck',
    'Raubling',
    'Waldfeucht',
    'Dockingen',
    'DonauwÃ¶rth',
    'Monheim',
    'Niederschonenfeld',
    'Ellzee',
    'Aletshausen',
    'Ebershausen',
    'Kettershausen',
    'Oberndorf',
    'Grassau',
    'Maximilian',
    'Bonen',
    'Aschheim',
    'Margertshausen',
    'Memmingen',
    'Beiersdorf',
    'Bingen',
    'Hausen ob Verena',
    'Messkirch',
    'Immendingen',
    'Bondorf',
    'Muhlheim an der Donau',
    'Schwenningen',
    'Bietingen',
    'Leibertingen',
    'Veringenstadt',
    'Emmingen-Liptingen',
    'Grunheide',
    'Jacobsdorf',
    'Hennickendorf',
    'Rauen',
    'Angermunde',
    'Pfedelbach',
    'Kupferzell',
    'Schnelldorf',
    'Untereisesheim',
    'Igersheim',
    'Grosserlach',
    'Wustenrot',
    'Erlenbach',
    'Sulzbach',
    'Katzenstein',
    'Wiesau',
    'Ostseebad Boltenhagen',
    'Eden',
    'Oschersleben',
    'Timmendorf',
    'Lage',
    'Oberboihingen',
    'Lennestadt',
    'Niederzier',
    'Bemerode',
    'Gerbrunn',
    'Mainaschaff',
    'Randersacker',
    'Kleinwallstadt',
    'Zell am Main',
    'Thungersheim',
    'Weibersbrunn',
    'Schollkrippen',
    'Krombach',
    'Haibach',
    'Kohren-Sahlis',
    'Rheine',
    'Frohburg',
    'Thallwitz',
    'Voerde',
    'Raesfeld',
    'Sonsbeck',
    'Schermbeck',
    'Weeze',
    'Rees',
    'Affing',
    'Obergriesbach',
    'Thierhaupten',
    'Wertingen',
    'Finningen',
    'Bissingen',
    'Rain',
    'Mering',
    'Mertingen',
    'Zoschingen',
    'Untermeitingen',
    'Welden',
    'Hainsfarth',
    'Bad Laasphe',
    'Freudenberg',
    'Bad Berleburg',
    'Bergneustadt',
    'Wenden',
    'Schnega',
    'Bodenteich',
    'Ruhen',
    'Warberg',
    'Zernien',
    'Neu Darchau',
    'Meinersen',
    'Vechelde',
    'Kissenbruck',
    'Neetze',
    'Wagenhoff',
    'Bottrop',
    'Neukirchen-Vluyn',
    'Weener',
    'Spetzerfehn',
    'Leezdorf',
    'Detern',
    'Borkum',
    'Leer',
    'Holtland',
    'Firrel',
    'Aschersleben',
    'Jessnitz',
    'Lutzen',
    'Nauendorf',
    'Mansfeld',
    'Abberode',
    'Petersberg',
    'Jessen',
    'Weissenfels',
    'Bad Lauchstadt',
    'Raguhn',
    'Schkopau',
    'KÃ¶then',
    'Wengelsdorf',
    'Bad Bibra',
    'Sandersleben',
    'Wolfen',
    'Allstedt',
    'Grossrinderfeld',
    'Schwegenheim',
    'Billigheim-Ingenheim',
    'Hemsbach',
    'Albersweiler',
    'Steinweiler',
    'Buchen',
    'Kuhardt',
    'Gerolsheim',
    'Sulzfeld',
    'Oberviechtach',
    'Schwarzenbach an der Saale',
    'Tannesberg',
    'Dohlau',
    'Helmbrechts',
    'Fichtelberg',
    'Mehlmeisel',
    'Hohenberg',
    'Thierstein',
    'Marsberg',
    'Anrochte',
    'Schotten',
    'Bad Harzburg',
    'Liebenburg',
    'Steinlah',
    'Ebersberg',
    'Vaterstetten',
    'Garching bei Munchen',
    'Schondorf am Ammersee',
    'Grasbrunn',
    'Feldkirchen',
    'Brunnthal',
    'Oberschleissheim',
    'Giessen',
    'Lich',
    'Hessen',
    'Seeheim-Jugenheim',
    'Pfungstadt',
    'Erzhausen',
    'Griesheim',
    'Bendorf',
    'Lahnstein',
    'Hohr-Grenzhausen',
    'Emmelshausen',
    'Heuchelheim',
    'Lollar',
    'Bad Nauheim',
    'Staufenberg',
    'Marktheidenfeld',
    'Bad Neustadt an der Saale',
    'Niederwerrn',
    'Sennfeld',
    'Zellingen',
    'Hochberg',
    'Bad Kissingen',
    'Fuchsstadt',
    'Leinach',
    'Geldersheim',
    'Schwalbach am Taunus',
    'Kronberg',
    'Raunheim',
    'Ostheim',
    'Vallendar',
    'Albig',
    'Bruchkobel',
    'Veitshochheim',
    'Neu-Anspach',
    'Grossostheim',
    'Nidderau',
    'Sulzbach am Main',
    'Kriftel',
    'Erlensee',
    'Momlingen',
    'Gross-Umstadt',
    'Lorsch',
    'Ober-Ramstadt',
    'Grosswallstadt',
    'Reinheim',
    'Morlenbach',
    'Gross-Bieberau',
    'Rodgau',
    'Bensheim',
    'Zwingenberg',
    'Gross-Gerau',
    'Richen',
    'Sulzbach',
    'Lautersheim',
    'Staudt',
    'Schoneck',
    'Niederzeuzheim',
    'Andernach',
    'Langenselbold',
    'Buhlertal',
    'Rauenberg',
    'Gaggenau',
    'Ostringen',
    'Ubstadt-Weiher',
    'Eningen unter Achalm',
    'Neckartailfingen',
    'Dagersheim',
    'Meckenbeuren',
    'Gottmadingen',
    'Cleebronn',
    'Lauffen am Neckar',
    'Schonborn',
    'Gundelfingen',
    'Waldenbuch',
    'Odenheim',
    'Sinzheim',
    'Bretten',
    'Muhlhausen',
    'Bischweier',
    'Rechberghausen',
    'Ispringen',
    'Waldkirch',
    'Irsee',
    'Schwabmunchen',
    'Traunreut',
    'Traunstein',
    'Sulzberg',
    'Sonthofen',
    'Durach',
    'Oberstdorf',
    'Prutting',
    'Germering',
    'Eching',
    'Karlsfeld',
    'Toging am Inn',
    'Bergkirchen',
    'Grafenau',
    'Aichach',
    'Bad Worishofen',
    'Immenstadt im Allgau',
    'Gessertshausen',
    'Marktoberdorf',
    'Zorneding',
    'Bad Toelz',
    'Schechen',
    'Ergolding',
    'Aresing',
    'Kirchreit',
    'Kissing',
    'Arnstadt',
    'Suhl',
    'Weimar',
    'Eisenach',
    'Schmalkalden',
    'Oschatz',
    'Markranstadt',
    'Markkleeberg',
    'Taucha',
    'Wurzen',
    'Crimmitschau',
    'Wiedemar',
    'Schkeuditz',
    'Meerane',
    'Wilkau-Hasslau',
    'Radeberg',
    'Meissen',
    'Schneeberg',
    'Bad Muskau',
    'GlÃ¼cksburg',
    'Eutin',
    'Falkendorf',
    'Busdorf',
    'Ammerndorf',
    'Rothenbach an der Pegnitz',
    'Kulmbach',
    'Mohrendorf',
    'Bischberg',
    'Veitsbronn',
    'Heisede',
    'Ronnenberg',
    'Nordstemmen',
    'Vieselbach',
    'Nordhausen',
    'Gotha',
    'Greiz',
    'Possneck',
    'Bucha',
    'Soemmerda',
    'Bad Langensalza',
    'Sonneberg',
    'MÃ¼hlhausen',
    'Leinefelde',
    'Mettingen',
    'Quakenbruck',
    'Harsewinkel',
    'Korbach',
    'Buckeburg',
    'Rheda-Wiedenbruck',
    'Ochtrup',
    'Nordhorn',
    'Lotte',
    'Altenbeken',
    'Delbruck',
    'Bad Lippspringe',
    'HÃ¶xter',
    'Bad Salzuflen',
    'Rietberg',
    'Oerlinghausen',
    'Versmold',
    'Rahden',
    'Calden',
    'Hannoversch MÃ¼nden',
    'Hofgeismar',
    'Ibbenbueren',
    'Lippstadt',
    'Borgholzhausen',
    'Neuenkirchen',
    'Hovelhof',
    'Bad Driburg',
    'Duderstadt',
    'Lingen',
    'Salzkotten',
    'Bovenden',
    'Nettetal',
    'Wilnsdorf',
    'Pesch',
    'Kalkar',
    'Mudersbach',
    'Scheuerfeld',
    'Olpe',
    'Betzdorf',
    'Tonisvorst',
    'Gevelsberg',
    'Heiligenhaus',
    'Wuelfrath',
    'Wetter (Ruhr)',
    'Emmerich',
    'Styrum',
    'Oer-Erkenschwick',
    'Eitorf',
    'Rheinberg',
    'Schwalmtal',
    'Wildeshausen',
    'Oyten',
    'Ritterhude',
    'Siegburg',
    'Overath',
    'Bad Honnef',
    'Rommerskirchen',
    'Ubach-Palenberg',
    'Wegberg',
    'Baesweiler',
    'Bornheim',
    'Linnich',
    'Hirzlei',
    'Neunkirchen',
    'Ottweiler',
    'Trier',
    'Gillenfeld',
    'Wasserliesch',
    'Konz',
    'Leeheim',
    'Rossdorf',
    'Eppertshausen',
    'Schaafheim',
    'Birkenwerder',
    'Fredersdorf',
    'Eberswalde',
    'Hennigsdorf',
    'Hohen Neuendorf',
    'Eichwalde',
    'Schwedt (Oder)',
    'Finowfurt',
    'Buckow',
    'Ludwigsfelde',
    'Waldsieversdorf',
    'Rathenow',
    'Halberstadt',
    'Obernburg am Main',
    'Gross-Zimmern',
    'Burgstadt',
    'Grunwald',
    'Bad Neuenahr-Ahrweiler',
    'Hennef',
    'Nassau',
    'Remagen',
    'Grossheubach',
    'Wunstorf',
    'Hagenburg',
    'Alfeld',
    'Lindhorst',
    'Ilsede',
    'Luhden',
    'Obernkirchen',
    'Heuerssen',
    'Bad Salzdetfurth',
    'Seelze',
    'Seesen',
    'Salzgitter-Bad',
    'Sandkrug',
    'Burgdorf, Hanover',
    'Wallenhorst',
    'Hasbergen',
    'Norten-Hardenberg',
    'Bad Lauterberg im Harz',
    'Diekholzen',
    'Bad Munder am Deister',
    'Walkenried',
    'Bueddenstedt',
    'Grunenplan',
    'Visbek',
    'Lengede',
    'Vorden',
    'Bad Grund',
    'Burgwedel',
    'Bad Nenndorf',
    'Hemmingen',
    'Lehre',
    'Schoningen',
    'Loheide',
    'Walle',
    'Osterode am Harz',
    'Dinklage',
    'Wardenburg',
    'Weyhe',
    'Bodenwerder',
    'Holzminden',
    'Stadtoldendorf',
    'Steinfeld',
    'Minsen',
    'Klausheide',
    'Wietmarschen',
    'Lemwerder',
    'Nudlingen',
    'Ostrhauderfehn',
    'Dissen',
    'Rattelsdorf',
    'Neubiberg',
    'Assling',
    'Obertrubach',
    'Schnabelwaid',
    'Putzbrunn',
    'Gmund am Tegernsee',
    'Isen',
    'Wittislingen',
    'Aindling',
    'Langenmosen',
    'Konigsdorf',
    'Eckersdorf',
    'Glonn',
    'Schonbrunn',
    'Hassmersheim',
    'Winden',
    'Neufra',
    'Erlabrunn',
    'Himmelstadt',
    'Guntersleben',
    'Leidersbach',
    'Barth',
    'Poppenlauer',
    'Munnerstadt',
    'Bitterfeld-Wolfen',
    'Friedrichsthal',
    'Stassfurt',
    'Malsch',
    'Dettighofen',
    'Stendal',
    'Besitz',
    'Werther',
    'Dettelbach',
    'Untereuerheim',
    'Werneck',
    'Atteln',
    'Halle',
    'Billerbeck',
    'Stadtlohn',
    'Steinfurt',
    'Coesfeld',
    'Issum',
    'Vreden',
    'Fritzlar',
    'Hessisch Lichtenau',
    'Morshausen',
    'Porta Westfalica',
    'Havixbeck',
    'Westerkappeln',
    'Menslage',
    'Emsdetten',
    'Gescher',
    'Rhede',
    'Eltville',
    'Saint Egidien',
    'Dohna',
    'Forst',
    'Gornsdorf',
    'Eppendorf',
    'Hohenstein-Ernstthal',
    'Grossenhain',
    'Eilenburg',
    'Oberhermsdorf',
    'Bernsdorf',
    'Stolpen',
    'Winkwitz',
    'Meckenheim',
    'Niederkassel',
    'Weilerswist',
    'Burscheid',
    'Sinzig',
    'Bruggen',
    'Langerwehe',
    'Xanten',
    'Luchem',
    'Haan',
    'Barssel',
    'Samern',
    'Niedernhausen',
    'Sondershausen',
    'Mylau',
    'Reichenbach/Vogtland',
    'Puttlingen',
    'Eppelborn',
    'Wittenberg',
    'Merseburg',
    'Leuna',
    'Zeuthen',
    'Mahlow',
    'Bergfelde',
    'Thyrow',
    'Leisnig',
    'Schonebeck',
    'Gnadau',
    'Blankenburg',
    'Wittstock',
    'Kremmen',
    'Furstenwald',
    'Wusterhausen',
    'Flothe',
    'Sehnde',
    'Borgsdorf',
    'Schulzendorf',
    'Horst',
    'Krauschwitz',
    'Burkau',
    'Wachau',
    'Oberlungwitz',
    'Lobau',
    'Cannewitz',
    'Lichtentanne',
    'Uttenreuth',
    'Kosching',
    'Rohrenfels',
    'Neunburg vorm Wald',
    'Langensendelbach',
    'Kronach',
    'Mainleus',
    'Johanngeorgenstadt',
    'Oberwiesenthal',
    'Grunhainichen',
    'Aue',
    'Stollberg',
    'Bach',
    'Wallerfangen',
    'Kaisersesch',
    'Eichenzell',
    'Ehrenfeld',
    'Zollstock',
    'Odenthal',
    'Kendenich',
    'Wachtberg',
    'Merzenich',
    'Ensen',
    'Bayenthal',
    'Wiehl',
    'Mogglingen',
    'Untermunkheim',
    'Magstadt',
    'Birenbach',
    'Pleidelsheim',
    'Kirchberg',
    'Hessigheim',
    'Besigheim',
    'Rust',
    'Zell am Harmersbach',
    'Eberbach',
    'Neulussheim',
    'Kronau',
    'Malsch',
    'Dietingen',
    'Trossingen',
    'Vogtsburg',
    'Trochtelfingen',
    'Meersburg',
    'Orsingen-Nenzingen',
    'Allensbach',
    'Deidesheim',
    'Edingen-Neckarhausen',
    'Herbrechtingen',
    'Sachsenheim',
    'Affalterbach',
    'Neubrunn',
    'Schramberg',
    'Durnau',
    'Hirschhorn',
    'Waldshut-Tiengen',
    'Schworstadt',
    'Botzingen',
    'Oberteuringen',
    'Hopfingen',
    'Mulfingen',
    'Riederich',
    'Grosselfingen',
    'Schonaich',
    'Waschenbeuren',
    'Aichelberg',
    'Titisee-Neustadt',
    'Beimerstetten',
    'Westerstetten',
    'Maselheim',
    'Schwendi',
    'Dellmensingen',
    'Illerrieden',
    'Buch',
    'Moglingen',
    'Birkenlohe',
    'Oberkochen',
    'Forchtenberg',
    'Markgroningen',
    'Erligheim',
    'Pfullingen',
    'Neckartenzlingen',
    'Ofterdingen',
    'Hulben',
    'Munsingen',
    'Niedernhall',
    'Straelen',
    'Herzebrock',
    'Ludinghausen',
    'Sendenhorst',
    'Grefrath',
    'Beesten',
    'Huckelhoven',
    'Bilk',
    'Altenberge',
    'Schlangen',
    'Ahrensbok',
    'Nottuln',
    'Herborn',
    'Vlotho',
    'Schwelm',
    'Schonberg',
    'Wendelstein',
    'Lain',
    'Gemmingen',
    'Gau-Bischofsheim',
    'Nierstein',
    'Waibstadt',
    'Grosskrotzenburg',
    'Buchen',
    'Ebhausen',
    'Liederbach',
    'Waldaschaff',
    'Blankenfelde',
    'Neu Isenburg',
    'Hermsdorf',
    'Zella-Mehlis',
    'Hoppegarten',
    'Kahla',
    'Reilingen',
    'Reinfeld',
    'Hemdingen',
    'Bentwisch',
    'Klein Lichtenhagen',
    'Zingst',
    'Schenefeld',
    'Hittbergen',
    'Doberlug-Kirchhain',
    'Hohenwarthe',
    'Lietzen',
    'Burg Stargard',
    'Treuenbrietzen',
    'Wolmirstedt',
    'Linden',
    'Weiterstadt',
    'Holte',
    'Green',
    'Gendorf',
    'Winnen',
    'Wenzenbach',
    'Geiselhoring',
    'Bad Abbach',
    'Pentling',
    'Pfatter',
    'Bruck in der Oberpfalz',
    'Regenstauf',
    'Neutraubling',
    'Sunching',
    'Stamsried',
    'Wiesenfelden',
    'Wackersdorf',
    'Falkenstein',
    'Zwiesel',
    'Schwandorf in Bayern',
    'Donaustauf',
    'Schrozberg',
    'Eggesin',
    'Altentreptow',
    'Prenzlau',
    'Friedland',
    'Rangsdorf',
    'Fahrland',
    'Pritzwalk',
    'Freckenhorst',
    'Ascheberg',
    'Salzbergen',
    'Worthsee',
    'Strasslach-Dingharting',
    'Raisting',
    'Windach',
    'Diessen am Ammersee',
    'Juterbog',
    'Kleinmachnow',
    'Baruth',
    'Gittelde',
    'Hehlen',
    'Badenhausen',
    'Grossalmerode',
    'Fuldatal',
    'Melsungen',
    'Guxhagen',
    'Klosterhauser',
    'Belzig',
    'Ziesar',
    'Biesenthal',
    'Zechlin Dorf',
    'Leegebruch',
    'Schildow',
    'Glienicke',
    'Fehrbellin',
    'Alt Ruppin',
    'Kaisersbach',
    'Rudersberg',
    'Feldberg',
    'Wredenhagen',
    'Viecheln',
    'Ribnitz-Damgarten',
    'Malchin',
    'Teterow',
    'Leizen',
    'Gnoien',
    'Brussow',
    'Leopoldshagen',
    'Ribnitz',
    'Woldegk',
    'Freden',
    'Eime',
    'Everode',
    'Warmsen',
    'Kemberg',
    'Zahna',
    'Krauschwitz',
    'Coswig',
    'Labejum',
    'Ockenfels',
    'Ransbach-Baumbach',
    'Scharnhorst',
    'Puderbach',
    'Burstadt',
    'Herxheim',
    'Lochau',
    'Erdeborn',
    'Hoya',
    'Wachtendonk',
    'Velen',
    'Sudlohn',
    'Dirmingen',
    'Urweiler',
    'Kleinblittersdorf',
    'Reimsbach',
    'Beckingen',
    'Mettlach',
    'Wadgassen',
    'Merchweiler',
    'Sulzbach',
    'Lebach',
    'Bexbach',
    'Hohenfurch',
    'Rottach-Egern',
    'Gerzen',
    'Willebadessen',
    'Buren',
    'Schloss Holte-Stukenbrock',
    'Steinheim',
    'Brakel',
    'Peckelsheim',
    'Barntrup',
    'Oldendorf',
    'Bad Essen',
    'Hille',
    'Pullach im Isartal',
    'Lingerhahn',
    'Morsfeld',
    'Klein-Winternheim',
    'Bechtolsheim',
    'Eich',
    'Seifhennersdorf',
    'Stukenbrock',
    'Oberderdingen',
    'Monsheim',
    'Illingen',
    'Maulbronn',
    'Wernau',
    'Hankensbuttel',
    'Langeln',
    'Dedelstorf',
    'Lautenthal',
    'Vordorf',
    'Parsau',
    'Winnigstedt',
    'Schladen',
    'Remlingen',
    'Naunheim',
    'Altenkirchen',
    'Brensbach',
    'Messel',
    'Gerau',
    'Gernsheim',
    'Schoppenstedt',
    'Woltersdorf',
    'Evessen',
    'Brome',
    'Destedt',
    'Wittingen',
    'Knesebeck',
    'Cremlingen',
    'Hofbieber',
    'Schwalmstadt',
    'Grebenhain',
    'Schluchtern',
    'Herbstein',
    'Neuhof',
    'Steinau an der Strasse',
    'Oberaula',
    'Alsfeld',
    'Birstein',
    'Bermuthshain',
    'Metzlos-Gehag',
    'Gehaus',
    'Frielendorf',
    'Bad Salzschlirf',
    'Kunzell',
    'Odendorf',
    'Neunkirchen',
    'Bad Munstereifel',
    'Villmar',
    'Gladenbach',
    'Bad Endbach',
    'Niederweidbach',
    'Breidenbach',
    'Niederfischbach',
    'Berod bei Wallmerod',
    'Schoeffengrund',
    'Ewersbach',
    'Kirschhofen',
    'Katzenelnbogen',
    'Rettert',
    'Treffelstein',
    'Stammbach',
    'Munchberg',
    'Nagel',
    'Weissdorf',
    'Erbendorf',
    'Kirchenlamitz',
    'Waldershof',
    'Gefrees',
    'Neustadt an der Aisch',
    'Konigstein',
    'Thurnau',
    'Schonwald',
    'Kastl',
    'Konradsreuth',
    'Hollfeld',
    'Neustadt an der Waldnaab',
    'Weiherhammer',
    'Plossberg',
    'Witzenhausen',
    'Gieboldehausen',
    'Braunlage',
    'Gleichen',
    'Hattorf',
    'Luerdissen',
    'Eschershausen',
    'Moringen',
    'Hardegsen',
    'Ulbersdorf',
    'Moritzburg',
    'Spechtshausen',
    'Neustadt in Sachsen',
    'Schwarmstedt',
    'Steimbke',
    'Oldendorf',
    'Elze',
    'Essel',
    'Hessisch Oldendorf',
    'Horgau',
    'Ichenhausen',
    'Rehling',
    'Fischach',
    'Mottingen',
    'Welden',
    'Klosterlechfeld',
    'Hollenbach',
    'Offingen',
    'Gablingen',
    'Lauingen',
    'Inchenhofen',
    'Nienhagen',
    'Eicklingen',
    'Rodenberg',
    'Coppengrave',
    'Wathlingen',
    'Hohnhorst',
    'Delligsen',
    'Giesen',
    'Rheden',
    'Nienhagen',
    'Marienhagen',
    'Schoneberg',
    'Seelow',
    'Lieberose',
    'Vorland',
    'Alt Buchhorst',
    'Barendorf',
    'Radbruch',
    'Wendhausen',
    'Kolln-Reisiek',
    'Aschendorf',
    'Heede',
    'Marienhafe',
    'Juist',
    'Baltrum',
    'Alttechau',
    'Hohenaspe',
    'Klein Ammensleben',
    'Ilsenburg',
    'Wanzleben',
    'Heringsdorf',
    'Hemmingstedt',
    'Hohenschaftlarn',
    'Manching',
    'Geisenfeld',
    'Wasserburg am Inn',
    'Wessling',
    'Egling',
    'Eurasburg',
    'Pforring',
    'Wilsum',
    'Niederlangen',
    'Sogel',
    'Twist',
    'Engden',
    'Bad Bentheim',
    'Getelo',
    'Solms',
    'Gemunden',
    'Kirchhain',
    'Friedensdorf',
    'Lohnberg',
    'Dreikirchen',
    'Braunfels',
    'Gemunden an der Wohra',
    'Wetter',
    'Nordwalde',
    'Leer',
    'Spelle',
    'Beelen',
    'Sassenberg',
    'Horstmar',
    'Metelen',
    'Geltendorf',
    'Reichertshausen',
    'Greifenberg',
    'Vierkirchen',
    'Walleshausen',
    'Welshofen',
    'Ostbevern',
    'Tecklenburg',
    'Rhade',
    'Beverstedt',
    'Wietzen',
    'Kirchseelte',
    'Rhadereistedt',
    'Horstedt',
    'Scharrel',
    'Ovelgoenne',
    'Carolinensiel',
    'Ovelgonne',
    'Bakum',
    'Dotlingen',
    'Ramsloh',
    'Kirn',
    'Furtwangen im Schwarzwald',
    'Hufingen',
    'Niedereschach',
    'Gaienhofen',
    'Uhldingen-Muhlhofen',
    'Triberg im Schwarzwald',
    'Dahlewitz',
    'Putlitz',
    'Bad Boll',
    'Nattheim',
    'Sievershutten',
    'Seedorf',
    'Hilter',
    'Alfhausen',
    'Neuenkirchen',
    'Bersenbruck',
    'Gehrde',
    'Trittau',
    'Gronwohld',
    'Todesfelde',
    'Todendorf',
    'Wunnenberg',
    'Lichtenau',
    'Weiberg',
    'Etteln',
    'Kruden',
    'Haldensleben I',
    'Hecklingen',
    'Lostau',
    'Biere',
    'Atzendorf',
    'Mahndorf',
    'Igel',
    'Neroth',
    'Urzig',
    'Mastershausen',
    'Sensweiler',
    'Enkirch',
    'Tawern',
    'Herrstein',
    'Idar-Oberstein',
    'Geisfeld',
    'Morschied',
    'Pellingen',
    'Bausendorf',
    'Thomm',
    'Newel',
    'Horbruch',
    'Ralingen',
    'Sonnschied',
    'Pelm',
    'Kenn',
    'Olzheim',
    'Serrig',
    'Steineberg',
    'Pluwig',
    'Feusdorf',
    'Lautzenhausen',
    'Helfant',
    'Iffezheim',
    'Hugelsheim',
    'Gengenbach',
    'Neuried',
    'Lichtenau',
    'Ringsheim',
    'Ottenhofen',
    'Kappelrodeck',
    'Forbach',
    'Meissenheim',
    'Lauf',
    'Dasing',
    'Affaltern',
    'Ahlhorn',
    'Pfaffenhausen',
    'Weiler-Simmerberg',
    'Wertach',
    'Oberrieden',
    'Markt Rettenbach',
    'Buchenberg',
    'Eppishausen',
    'Eggenthal',
    'Waal',
    'Zaisertshofen',
    'Heimenkirch',
    'Waltenhofen',
    'Ruckholz',
    'Sigmarszell',
    'Turkheim',
    'Ascha',
    'Parnsberg',
    'Bodenmais',
    'Kelheim',
    'Strasskirchen',
    'Wolfsegg',
    'Hemau',
    'Mariaposching',
    'Thalmassing',
    'Eichendorf',
    'Pemfling',
    'Bischofsmais',
    'Beratzhausen',
    'Breitenbrunn',
    'Rastenberg',
    'Worbis',
    'Weilar',
    'Blankenhain',
    'Brotterode',
    'Rausdorf',
    'Oechsen',
    'Singenbach',
    'Dorfen',
    'Amerang',
    'Gerolsbach',
    'Ernsgaden',
    'Lenggries',
    'Vohburg an der Donau',
    'Brunsbuttel',
    'Burg-Grambke',
    'Olderup',
    'Pansdorf',
    'Rantzau',
    'Gersheim',
    'Namborn',
    'Weiskirchen',
    'Blieskastel',
    'Marpingen',
    'Kollerbach',
    'Freisen',
    'Siesbach',
    'Rodach',
    'Baunach',
    'Dentlein am Forst',
    'Rothenburg',
    'Uffenheim',
    'Langenzenn',
    'Weismain',
    'Wirsberg',
    'Kasendorf',
    'Vilseck',
    'Topen',
    'Kodnitz',
    'Stockheim',
    'Grafenwohr',
    'Nordhalben',
    'Tirschenreuth',
    'Gossweinstein',
    'Goldkronach',
    'Obernzell',
    'Unterdietfurt',
    'Dingolfing',
    'Untergriesbach',
    'Furstenzell',
    'Spiegelau',
    'Tittling',
    'Stubenberg',
    'Ruderting',
    'Tann',
    'Vilshofen',
    'Fursteneck',
    'Hutthurm',
    'Perlesreut',
    'Ergoldsbach',
    'Frontenhausen',
    'Lindlar',
    'Engelskirchen',
    'Pilsting',
    'Landau an der Isar',
    'Obertraubling',
    'Schnaittenbach',
    'Brunn',
    'Seubersdorf',
    'Maxhutte-Haidhof',
    'Nittendorf',
    'Ringsberg',
    'Teising',
    'Muehldorf',
    'Bischofswiesen',
    'Winhoring',
    'Berchtesgaden',
    'Pleiskirchen',
    'Tittmoning',
    'Kleinrinderfeld',
    'Bastheim',
    'Rottendorf',
    'Gerolzhofen',
    'Rollbach',
    'Schonau',
    'Rothenfels',
    'Karlstadt am Main',
    'Kleinheubach',
    'Zell',
    'Rothhausen',
    'Lutzelbach',
    'Wunsiedel',
    'Trabitz',
    'Hochstadt an der Aisch',
    'Auerbach',
    'Freihung',
    'Wernberg-Koblitz',
    'Stornstein',
    'Pullenreuth',
    'Kirchenthumbach',
    'Rehau',
    'Eilenburg, Berg',
    'Pleystein',
    'Mantel',
    'Mitterteich',
    'Pottenstein',
    'Eslarn',
    'Windischeschenbach',
    'Timmenrode',
    'Budenheim',
    'Gensingen',
    'Schlangenbad',
    'Hausen uber Aar',
    'Langenlonsheim',
    'Heidesheim',
    'Grolsheim',
    'Oppenheim',
    'Ober-Hilbersheim',
    'Ellgau',
    'Thannhausen',
    'Altomunster',
    'Wemding',
    'Dinkelscherben',
    'Eichstaett',
    'Muhr am See',
    'Freystadt',
    'Hirschaid',
    'Oberreichenbach',
    'Oberreichenbach',
    'Waldbockelheim',
    'Merenberg',
    'Schwabenheim',
    'Nastatten',
    'Offstein',
    'Boppard',
    'Hergenfeld',
    'Auen',
    'Gau-Algesheim',
    'Thalheim',
    'Schlema',
    'Eibenstock',
    'Burgstadt',
    'Ehrenfriedersdorf',
    'Zwonitz',
    'Rochlitz',
    'Lengenfeld',
    'Hilbersdorf',
    'Drebach',
    'Crottendorf',
    'Schoenheide',
    'Augustusburg',
    'Stutzengrun',
    'Filsen',
    'Oberhausen',
    'Meckenbach',
    'Kaub',
    'Hahnheim',
    'Kirchzell',
    'Worth',
    'Worth am Rhein',
    'Grettstadt',
    'Obernbreit',
    'Goldbach',
    'Kirchlauter',
    'Elsenfeld',
    'Hammelburg',
    'Miltenberg',
    'Pfarrweisach',
    'Eibelstadt',
    'Hassfurt',
    'Klingenberg am Main',
    'Ettleben',
    'Lohr a. Main',
    'Erlenbach am Main',
    'Wachtersbach',
    'Budingen',
    'Gedern',
    'Ober-Morlen',
    'Bad Orb',
    'Westergellersen',
    'Moisburg',
    'Cadenberge',
    'Wingst',
    'Burweg',
    'Kirchgellersen',
    'Kollmar',
    'Untereglfing',
    'Eichenau',
    'Bruchweiler',
    'Zemmer',
    'Zeltingen-Rachtig',
    'Baumholder',
    'Hermeskeil',
    'Monzelfeld',
    'Nordrach',
    'Aldenhoven',
    'Hellenthal',
    'Nideggen',
    'Heinsberg',
    'Wassenberg',
    'Pahl',
    'Markt Indersdorf',
    'Marienstein',
    'Rottenbuch',
    'Schongau',
    'Bad Feilnbach',
    'Baiern',
    'Holzkirchen',
    'Eschenlohe',
    'Winterbach',
    'Murr',
    'Heringen',
    'Barchfeld',
    'Windehausen',
    'Gerstungen',
    'Stadtilm',
    'Breitungen',
    'Dingelstadt',
    'Ernstroda',
    'Stadtroda',
    'Bischofferode',
    'Ilfeld',
    'Bad Sulza',
    'Mihla',
    'Neuhaus am Rennweg',
    'Kindelbruck',
    'Seligenthal',
    'Urbach',
    'Zottelstedt',
    'Oberhof',
    'Lichte',
    'Mellenbach-Glasbach',
    'Olbersleben',
    'Thale',
    'Meiningen',
    'Heilbad Heiligenstadt',
    'Hohenstein',
    'Wernrode',
    'Walldorf',
    'Crossen',
    'Weida',
    'Bad Lobenstein',
    'Kamsdorf',
    'Eisenberg',
    'Schleiz',
    'Gossnitz',
    'Bocka',
    'Blankenstein',
    'Bad Blankenburg',
    'Zeulenroda',
    'Muhltroff',
    'Remptendorf',
    'Zuhlsdorf',
    'Vogelsdorf',
    'Trebbin',
    'Am Mellensee',
    'Bad Kostritz',
    'Neustadt (Orla)',
    'Munchenbernsdorf',
    'Wadern',
    'Rehlingen-Siersburg',
    'Muehlheim am Main',
    'Zainingen',
    'Grethem',
    'Haste',
    'Salzhemmendorf',
    'Burg',
    'Burladingen',
    'Haigerloch',
    'Lossburg',
    'Grunenberg',
    'Zimmern',
    'Rosenfeld',
    'Rheinsberg',
    'Krummensee',
    'Wildau',
    'Muncheberg',
    'Altlandsberg',
    'Asbach',
    'Alsbach-Hahnlein',
    'Buttelborn',
    'Lindenfels',
    'Einhausen',
    'Kefenrod',
    'Leingarten',
    'Massenbachhausen',
    'Nordheim',
    'Neuenstadt am Kocher',
    'Laubach',
    'Aull',
    'Mittenaar',
    'Ehringshausen',
    'Diez',
    'Wiesenbach',
    'Hassloch',
    'Rieseby',
    'Reher',
    'Tonning',
    'Burg auf Fehmarn',
    'Lensahn',
    'Fehmarn',
    'Kellinghusen',
    'Hohenwestedt',
    'Ahrensboek',
    'Unterschneidheim',
    'Satteldorf',
    'Buhlerzell',
    'Wolpertshausen',
    'Obersontheim',
    'Mainhardt',
    'Twiste',
    'Gudensberg',
    'Schwarzenborn',
    'Wabern',
    'Helsa',
    'Zierenberg',
    'Nieste',
    'Korle',
    'Fuldabruck',
    'Sachsenhagen',
    'Fassberg',
    'Konigheim',
    'Schonfeld',
    'Gernrode',
    'Magdala',
    'Kolleda',
    'Arenshausen',
    'Steinach',
    'Kallstadt',
    'Gundelsheim',
    'Neunkirchen',
    'Wilhelmsfeld',
    'Oberotterbach',
    'Huffenhardt',
    'Bohl-Iggelheim',
    'Jockgrim',
    'Rhein',
    'Aglasterhausen',
    'Beindersheim',
    'Lambrecht',
    'Leinsweiler',
    'Waldhambach',
    'Gonnheim',
    'Hatzenbuhl',
    'Niederkirchen bei Deidesheim',
    'Heiligkreuzsteinach',
    'Neupotz',
    'Graben-Neudorf',
    'Funfstetten',
    'Altenmunster',
    'Forheim',
    'Hirzenhain',
    'Ortenberg',
    'Rosbach vor der Hohe',
    'Schlitz',
    'Mindelstetten',
    'Icking',
    'Tutzing',
    'Krautheim',
    'Osterburken',
    'Glauburg',
    'Neuhausen',
    'Birkenfeld',
    'Kieselbronn',
    'Steinegg',
    'Konigsbach-Stein',
    'Rath',
    'Vetschau',
    'Grossraschen',
    'Neuhausen',
    'Borna',
    'Hartha',
    'Bad Lausick',
    'Schnaittach',
    'Hillersleben',
    'Gross Santersleben',
    'Wedringen',
    'Tangermunde',
    'Samswegen',
    'Elend',
    'Gusten',
    'Moser',
    'Osterwieck',
    'Zielitz',
    'Eilsleben',
    'Tangerhutte',
    'Weferlingen',
    'Flechtingen',
    'Obing',
    'Pittenhart',
    'Rimsting',
    'Waging am See',
    'Flinsbach',
    'Gimbsheim',
    'Mockmuhl',
    'Plochingen',
    'Benningen am Neckar',
    'Illertissen',
    'Holzgunz',
    'Aitrang',
    'Ottobeuren',
    'Gronenbach',
    'Altenstadt',
    'Friesenried',
    'Rieden',
    'Rosshaupten',
    'Unterthingau',
    'Altenstadt',
    'Leopoldshohe',
    'Muhlen',
    'Augustdorf',
    'Rieden',
    'Lemforde',
    'Essen',
    'Lienen',
    'Rehden',
    'Bohmte',
    'Unkel',
    'Rettigheim',
    'Bockenem',
    'Bleckede',
    'Astfeld',
    'Unterluss',
    'Tulau',
    'Lehmke',
    'Eschwege',
    'Bad Arolsen',
    'Waldkappel',
    'Trendelburg',
    'Wanfried',
    'Westerdeichstrich',
    'Schwabstedt',
    'Rodewisch',
    'Geilenkirchen',
    'Hurtgenwald',
    'Juchen',
    'Schleiden',
    'Gangelt',
    'Rutesheim',
    'Kastellaun',
    'Simmern',
    'Eppelsheim',
    'Gau-Odernheim',
    'Miehlen',
    'Rheinbollen',
    'Ustersbach',
    'Waidhaus',
    'Schirnding',
    'Pechbrunn',
    'Vohenstrauss',
    'Marktleugast',
    'Parkstein',
    'Kueps Oberfranken',
    'Gestungshausen',
    'Bad Berneck im Fichtelgebirge',
    'Etzenricht',
    'Gleiritsch',
    'Schauenstein',
    'Leonberg',
    'Marktleuthen',
    'Buchbach',
    'Niederneuching',
    'Haag',
    'Reithofen',
    'Gross Gronau',
    'Hasenmoor',
    'Rethwisch',
    'Stahnsdorf',
    'Schonfliess',
    'Borkheide',
    'Borkwalde',
    'Nennhausen',
    'Gorenzen',
    'Nebra',
    'Wulfen',
    'Bennungen',
    'Bad Schmiedeberg',
    'Goseck',
    'Eckartsberga',
    'Apen',
    'Voslapp',
    'Emstek',
    'Linkenheim-Hochstetten',
    'Forst',
    'Marschacht',
    'Kothel',
    'Weddelbrook',
    'Grossensee',
    'Rausdorf',
    'Krummesse',
    'Niendorf',
    'Ellhofen',
    'Waldenburg',
    'Hohenlinden',
    'Buch am Buchrain',
    'Oberornau',
    'Edling',
    'Mittbach',
    'Gars',
    'Schliersee',
    'Andechs',
    'Velden',
    'Egmating',
    'Herrsching am Ammersee',
    'Seefeld',
    'Bernried',
    'Baldham',
    'Babensham',
    'Hausham',
    'Schwaig',
    'Kirchen',
    'Tabarz',
    'Gorkwitz',
    'Schwabhausen',
    'Grainau',
    'Forstinning',
    'Benediktbeuern',
    'Farchant',
    'Odelzhausen',
    'Hodenhagen',
    'Hoheneggelsen',
    'Kirchhundem',
    'Horgertshausen',
    'Tiefenbach',
    'Berglern',
    'Wartenberg',
    'Winzeln',
    'Schomberg',
    'Durchhausen',
    'Empfingen',
    'Dornhan',
    'Frasdorf',
    'Unterneukirchen',
    'Ruhpolding',
    'Hoslwang',
    'Bad Endorf',
    'Eggstatt',
    'Engelsberg',
    'Kirchweidach',
    'Neuberg',
    'Neuberg',
    'Leimbach',
    'Breitenbach',
    'Homberg',
    'Nentershausen',
    'Jesberg',
    'Bergen',
    'Coppenbrugge',
    'Hofer',
    'Hademstorf',
    'Niedernwohren',
    'Hespe',
    'Dankmarshausen',
    'Tann',
    'Schwalmtal',
    'Sterbfritz',
    'Kirchheim',
    'Obersuhl',
    'Bebra',
    'Heringen',
    'Gilserberg',
    'Hilders',
    'Petersberg',
    'Mottgers',
    'Schenklengsfeld',
    'Philippsthal',
    'Wippershain',
    'Meuro',
    'Plessa',
    'Brauweiler',
    'Vettweiss',
    'Winkelhaid',
    'Uehlfeld',
    'Cadolzburg',
    'Strullendorf',
    'Meeder',
    'Bad Staffelstein',
    'Altdorf',
    'Winkelhaid',
    'Memmelsdorf',
    'Jagstzell',
    'Obermarchtal',
    'Spraitbach',
    'Ingelfingen',
    'Alfdorf',
    'Fichtenberg',
    'Kaldenkirchen',
    'Aach',
    'Bodman-Ludwigshafen',
    'Willstatt',
    'Rheinau',
    'Seelbach',
    'Hornberg',
    'Oppenau',
    'Wolfach',
    'Balve',
    'Saerbeck',
    'Lichtenstein',
    'Bohmenkirch',
    'Schelklingen',
    'Gingen an der Fils',
    'Durlangen',
    'Isny im Allgau',
    'Hattenhofen',
    'Erolzheim',
    'Nellingen',
    'Bad Buchau',
    'Gruibingen',
    'Drensteinfurt',
    'Heek',
    'Ennigerloh',
    'Rattenkirchen',
    'Ubersee',
    'Kraiburg am Inn',
    'Reit im Winkl',
    'Taching am See',
    'Ampfing',
    'Tacherting',
    'Marktl',
    'Siegsdorf',
    'Anger',
    'Ohningen',
    'Moos',
    'Schonach im Schwarzwald',
    'Aftholderberg',
    'Neufrach',
    'Gailingen',
    'Muhlhausen-Ehingen',
    'Ronsberg',
    'Pforzen',
    'Niederrieden',
    'Pfronten',
    'Boos',
    'Jengen',
    'Lautrach',
    'Missen-Wilhams',
    'Westerheim',
    'Kirchdorf',
    'Mulsen',
    'Neuhausen',
    'Klingenthal',
    'Muhlau',
    'Oberschona',
    'Oederan',
    'Hartmannsdorf',
    'Auerbach',
    'Lauter',
    'Taura',
    'Gelenau',
    'Zschopau',
    'Werda',
    'Neckarsteinach',
    'Meckesheim',
    'Helmstadt',
    'Schieder-Schwalenberg',
    'Kurort Steinbach-Hallenberg',
    'Bad Liebenstein',
    'Hattstedt',
    'Friedrichstadt',
    'Dahme',
    'Brokstedt',
    'Erfde',
    'Behrendorf',
    'Suderstapel',
    'Buchwalde',
    'Langenberg',
    'Ehrenburg',
    'Suhlendorf',
    'Driedorf',
    'Haiger',
    'Colbe',
    'Wolframs-Eschenbach',
    'Lichtenfels',
    'Lauterhofen',
    'Petershagen',
    'Arnstorf',
    'Postmunster',
    'Neuhaus',
    'Eggenfelden',
    'Simbach am Inn',
    'Neukirchen am Teisenberg',
    'Pfarrkirchen',
    'Gottfrieding',
    'Neuhaus',
    'Aldersbach',
    'Mallersdorf-Pfaffenberg',
    'Ortenburg',
    'Chostlarn',
    'Falkenberg',
    'Wietze',
    'Eschede',
    'Haenigsen',
    'Laubenheim',
    'Biebelsheim',
    'Norheim',
    'Simmertal',
    'Sommerloch',
    'Windesheim',
    'Welgesheim',
    'Boos',
    'Odernheim',
    'Pfaffen-Schwabenheim',
    'Rudesheim',
    'Altenberg',
    'Bahratal',
    'Klipphausen',
    'Merkendorf',
    'Faulbach',
    'Kutenholz',
    'Bederkesa',
    'Nordholz',
    'Ruhland',
    'Lohsa',
    'Tschernitz',
    'Kolkwitz',
    'Broeckel',
    'Eldingen',
    'Steinhorst',
    'Adelheidsdorf',
    'Bad Fallingbostel',
    'Algermissen',
    'Harsum',
    'Edemissen',
    'Hambuhren',
    'Unteruhldingen',
    'Villingen',
    'Salem',
    'Wiesensteig',
    'Genkingen',
    'Munderkingen',
    'Neukirch',
    'Mochenwangen',
    'Schlier',
    'Eberhardzell',
    'Aitrach',
    'Amtzell',
    'Waldburg',
    'Hettstadt',
    'Eltmann',
    'Donnersdorf',
    'Bad Konigshofen im Grabfeld',
    'Gochsheim',
    'Ochsenfurt',
    'Oberickelsheim',
    'Kirschfurt',
    'Gollhofen',
    'Ebelsbach',
    'Frammersbach',
    'Estenfeld',
    'Munster',
    'Muhltal',
    'Dransfeld',
    'Golmbach',
    'Hardenberg',
    'Duderode',
    'Neuendorf',
    'Brieskow-Finkenheerd',
    'Mixdorf',
    'Steintoch',
    'Bad Freienwalde',
    'Spree',
    'Beeskow',
    'Wendisch Rietz',
    'Gross Lindow',
    'Mark',
    'Bad Saarow',
    'Waldstetten',
    'Westerheim',
    'Geislingen an der Steige',
    'Iggingen',
    'Merklingen',
    'Grabenstetten',
    'Lonsee',
    'Alpen',
    'Lauenau',
    'Muden',
    'Schoneworde',
    'Luechow',
    'Hitzacker',
    'Elbe',
    'Gielde',
    'Baddeckenstedt',
    'Everswinkel',
    'Olfen',
    'Wadersloh',
    'Schoppingen',
    'Kiedrich',
    'Geisenheim',
    'Uhler',
    'Kirchberg',
    'Reichelsheim',
    'Westkirchen',
    'Pflummern',
    'Wain',
    'Lauterach',
    'Jungingen',
    'Kisslegg',
    'Schwarzenfeld',
    'Rosendahl',
    'Arnschwang',
    'Kakenstorf',
    'Wittorf',
    'Gardelegen',
    'Garbek',
    'Siebenbaumen',
    'Berkenthin',
    'Gronau',
    'Schmilau',
    'Rondeshagen',
    'Lucka',
    'Langenbernsdorf',
    'Wurzbach',
    'Leutenberg',
    'Hartmannsdorf',
    'Dobitschen',
    'Kaulsdorf',
    'Pausa',
    'Mohlsdorf',
    'Dahn',
    'Hutschenhausen',
    'Thaleischweiler-Froschen',
    'Weilerbach',
    'Bruchmuhlbach-Miesau',
    'Rammelsbach',
    'Imsbach',
    'Winnweiler',
    'Glan-Munchweiler',
    'Waldfischbach-Burgalben',
    'Rudelzhausen',
    'Tauberfeld',
    'Au in der Hallertau',
    'Wolfersdorf',
    'Fraunberg',
    'Schweitenkirchen',
    'Reitmehring',
    'Allershausen',
    'Ohren',
    'Weilburg',
    'Mengerskirchen',
    'Fleisbach',
    'Weimar',
    'Leun',
    'Seeburg',
    'Adelebsen',
    'Wendeburg',
    'Barwedel',
    'Ruppichteroth',
    'Hausern',
    'Weisweil',
    'Heitersheim',
    'Zell',
    'Gorwihl',
    'Endingen',
    'Eberdingen',
    'Fintel',
    'Neuenkirchen',
    'Affinghausen',
    'Alpirsbach',
    'Winterlingen',
    'Zimmern ob Rottweil',
    'Hohentengen am Hochrhein',
    'Gammertingen',
    'Bitz',
    'Schafflund',
    'Satrup',
    'Jubek',
    'Struxdorf',
    'Bargum',
    'Dorpstedt',
    'Brodersby',
    'Bollingstedt',
    'Schaalby',
    'Kampen',
    'Leutershausen',
    'Burk',
    'Petersaurach',
    'Ornbau',
    'Diebach',
    'Megesheim',
    'Ederheim',
    'Sielenbach',
    'Kaisheim',
    'Ernstthal',
    'Ronneburg',
    'Buttenheim',
    'Neundorf',
    'Haiterbach',
    'Sigmaringendorf',
    'Oschingen',
    'Arnstein',
    'Uettingen',
    'Alzenau in Unterfranken',
    'Kurnach',
    'Giebelstadt',
    'Weisen',
    'Galenbeck',
    'Perleberg',
    'Zerrenthin',
    'Reisbach',
    'Neureichenau',
    'Hinterschmiding',
    'Am See',
    'Waldkirchen',
    'Querfurt',
    'Karsdorf',
    'Mucheln',
    'Nienburg/Saale',
    'Steigra',
    'Elster',
    'Markt Einersheim',
    'Stettfeld',
    'Karbach',
    'Zeil',
    'Iphofen',
    'Roetgen',
    'Lahden',
    'Grossruckerswalde',
    'Schwarzheide',
    'Hainichen',
    'Elterlein',
    'Plettenberg',
    'Osthofen',
    'Geinsheim',
    'Biblis',
    'Dienheim',
    'Bermersheim',
    'Westhofen',
    'Bad Elster',
    'Bernsbach',
    'Kirchberg',
    'Zaulsdorf',
    'Zschorlau',
    'Windsbach',
    'Muhlhausen',
    'Dietersheim',
    'Puschendorf',
    'Litzendorf',
    'Stegaurach',
    'Podeldorf',
    'Gutenstetten',
    'Wiefelstede',
    'Wagenfeld',
    'Wetschen',
    'Egelsbach',
    'Niederdorfelden',
    'Wehlheiden',
    'Willingen',
    'Naumburg',
    'Spangenberg',
    'Mehmels',
    'Romhild',
    'Catterfeld',
    'Linden',
    'Bad Breisig',
    'Ruppach-Goldhausen',
    'Nauort',
    'Mendig',
    'Plaidt',
    'Wassenach',
    'Heinz',
    'Heinzenberg',
    'Langenbach',
    'Neustadt bei Coburg',
    'Pessenburgheim',
    'Ruthnick',
    'Bismark',
    'Kyritz',
    'Langewiesen',
    'Grossbreitenbach',
    'Wallersdorf',
    'Irlbach',
    'Taufkirchen',
    'Grafschaft',
    'Wohnbach',
    'Waldems',
    'Hammersbach',
    'Mengkofen',
    'Rohr',
    'Mauth',
    'Marklkofen',
    'Hohenau',
    'Bad Fussing',
    'Hofkirchen',
    'Kirchham',
    'Rotthalmunster',
    'Deggendorf',
    'Aidenbach',
    'Haidmuhle',
    'Massing',
    'Egglham',
    'Ruhstorf',
    'Neuhaus am Inn',
    'Legden',
    'Burgbernheim',
    'Neuzelle',
    'Fischbach',
    'Mittelneufnach',
    'Kirkel',
    'Horst',
    'Burbach',
    'Bullay',
    'Traben-Trarbach',
    'Klausen',
    'Gondenbrett',
    'Zell',
    'Hallschlag',
    'Prum',
    'Schweich',
    'Schwirzheim',
    'Bernkastel-Kues',
    'Badem',
    'Irmenach',
    'Lieser',
    'Dreis',
    'Rhaunen',
    'Losheim',
    'Seffern',
    'Morbach',
    'Leiwen',
    'Malberg',
    'Daun',
    'Hochscheid',
    'Kordel',
    'Hillesheim',
    'Sehlem',
    'Eschfeld',
    'Osterburg',
    'Schwanebeck',
    'Irxleben',
    'Hohenerxleben',
    'Kroppenstedt',
    'Bismark',
    'Welsleben',
    'Wegeleben',
    'Huckeswagen',
    'Uberfeld',
    'Zorbig',
    'Braunsbedra',
    'Wimmelburg',
    'Pouch',
    'Teutschenthal',
    'Annaburg',
    'Norvenich',
    'Drolshagen',
    'Lehrberg',
    'Burgoberbach',
    'Kottenheim',
    'Hinterzarten',
    'Haimhausen',
    'Wurmsham',
    'Petershausen',
    'Elstra',
    'Radeburg',
    'Konigswartha',
    'Prietitz',
    'Langebruck',
    'Leutenbach',
    'Burgstall',
    'Beilstein',
    'Lowenstein',
    'Calvorde',
    'Stadtallendorf',
    'Friedland',
    'Heltersberg',
    'Horbach',
    'Nalbach',
    'Gerlfangen',
    'Queidersbach',
    'Kallmunz',
    'Leiblfing',
    'Iggensbach',
    'Kirchroth',
    'Abensberg',
    'Haidhof',
    'Morsbach',
    'Nohfelden',
    'Grafenroda',
    'Dippach',
    'Ruhla',
    'Schleusingen',
    'Vacha',
    'Schonwald im Schwarzwald',
    'Lampertswalde',
    'Kreischa',
    'Ostseebad Nienhagen',
    'Seeheilbad Graal-Muritz',
    'Marlow',
    'Glashagen',
    'Vietgest',
    'Schwaan',
    'Blankenhagen',
    'Weitenhagen',
    'Hebertshausen',
    'Gaissach',
    'Greiling',
    'Kirspenich',
    'Heimerzheim',
    'Nortrup',
    'Voltlage',
    'Loningen',
    'Bad Laer',
    'Bad Teinach-Zavelstein',
    'Meschede',
    'Kammerforst',
    'Hohenleimbach',
    'Leubsdorf',
    'Helferskirchen',
    'Kempenich',
    'Bell',
    'Rheinbrohl',
    'Eitelborn',
    'Schmitten',
    'Hepberg',
    'Jetzendorf',
    'Bad Heilbrunn',
    'Singhofen',
    'Freckenfeld',
    'Burgstetten',
    'Thyrnau',
    'Kirchdorf',
    'Triftern',
    'Pfeffenhausen',
    'Freyung',
    'Bad Schwalbach',
    'Nackenheim',
    'Rudesheim am Rhein',
    'Kiefersfelden',
    'Teisendorf',
    'Isselburg',
    'Billigheim',
    'Zwingenberg',
    'Weisenheim am Berg',
    'Krauchenwies',
    'Bevern',
    'Ravenstein',
    'Gaiberg',
    'Seckach',
    'Bammental',
    'Adelsheim',
    'Burbach',
    'Derschen',
    'Erwitte',
    'Mastholte',
    'Arnbach',
    'Ampermoching',
    'Vilsbiburg',
    'Wettstetten',
    'Moorenweis',
    'Datteln',
    'Schonstett',
    'Chieming',
    'Tuszshing',
    'Aschau',
    'Oberneukirchen',
    'Laufen',
    'Ramsau',
    'Schleching',
    'Petting',
    'Vachendorf',
    'Grabenstatt',
    'Breest',
    'Grambin',
    'Carmzow',
    'Torgelow',
    'Wilhelmsburg',
    'Thurkow',
    'Horstel',
    'Dahlem',
    'Dunningen',
    'Oberopfingen',
    'Oberkammlach',
    'Egg an der Gunz',
    'Blaichach',
    'Seeg',
    'Buhlertann',
    'Bonndorf im Schwarzwald',
    'Buchenbach',
    'Schluchsee',
    'Sexau',
    'Bahlingen',
    'Merdingen',
    'Solden',
    'Blankenrath',
    'Fell',
    'Trierweiler',
    'Arenrath',
    'Saarburg',
    'Irrel',
    'Beuren',
    'Longuich',
    'Gerolstein',
    'Osterspai',
    'Bad Honningen',
    'Willroth',
    'Hausten',
    'Hachenburg',
    'Mertloch',
    'Wissen',
    'Cochem',
    'Kruft',
    'Faid',
    'Bad Marienberg',
    'Dernau',
    'Oberelbert',
    'Alpenrod',
    'Welver',
    'Reichertshofen',
    'Karlskron',
    'Bestwig',
    'Schmallenberg',
    'Ruthen',
    'Kervenheim',
    'Treudelberg',
    'Winden',
    'Albbruck',
    'Neuhausen ob Eck',
    'Bad Steben',
    'Seebad Ahlbeck',
    'Hallenberg',
    'Finnentrop',
    'Orbis',
    'Hofen an der Enz',
    'Darstein',
    'Moyland',
    'Borsfleth',
    'Rackwitz',
    'Maierhofen',
    'Waldschlosschen',
    'Kirburg',
    'Mulda',
    'Vollkofen',
    'Spessart',
    'Grosskugel',
    'Weiding',
    'Woltingerode',
    'Eibsee',
    'Schlierbach',
    'Hermannsburg',
    'Wehnrath',
    'Neuental',
    'Kurten',
    'Purgen',
    'Hahn',
    'Festenburg',
    'Stockhausen',
    'Thoma',
    'Teschow',
    'Ballendorf',
    'Hohne',
    'Wentorf',
    'Vettelschoss',
    'Lubmin',
    'Stapelfeld',
    'Klein Offenseth-Sparrieshoop',
    'Hinterm Horn',
    'Altenmedingen',
    'Spalt',
    'Hilpoltstein',
    'Kettenkamp',
    'Haselunne',
    'Jaderberg',
    'Metjendorf',
    'Recke',
    'Salzburg',
    'Basel',
    'Bulow',
    'Buchwaldchen',
    'Wadelsdorf',
    'Beilrode',
    'Schwarz',
    'Grove',
    'Sanssouci',
    'Tegernsee',
    'Anker',
    'Seehof',
    'Kettig',
    'Marschall',
    'Pixel',
    'Flieden',
    'Watzenborn',
    'Delingsdorf',
    'Dorpen',
    'Hennstedt',
    'Drage',
    'Deinste',
    'Melbeck',
    'Estorf',
    'Wankendorf',
    'Heidehof',
    'Horhausen',
    'Dernbach',
    'Selters',
    'Kurtscheid',
    'Kaltenengers',
    'Rengsdorf',
    'Waldesch',
    'Spay',
    'Bassenheim',
    'Breitscheid',
    'Linkenbach',
    'Dierdorf',
    'Mellingen',
    'Komp',
    'Rhein',
    'Ostercappeln',
    'Dorf Doberitz',
    'Gutzkow',
    'Staig',
    'Achstetten',
    'Kirchzarten',
    'Gunningen',
    'Kapellenberg',
    'Bogen',
    'Ehekirchen',
    'Hitzhofen',
    'Kirchweg',
    'Netze',
    'Lohra',
    'Merching',
    'Herringen',
    'Birkenhof',
    'Badenweiler',
    'Bodensee',
    'Bensberg',
    'Stromberg',
    'Ohlsdorf',
    'Walpertshofen',
    'Rothenburg',
    'Gravenbruch',
    'Osch',
    'Wall',
    'Munich',
    'Bad Schachen',
    'Vorbach',
    'Garden',
    'Im Loh',
    'Teistungenburg',
    'Nobitz',
    'Brandl',
    'Heideck',
    'Postbauer-Heng',
    'Berngau',
    'Timmaspe',
    'Gnutz',
    'Wattenbek',
    'Kayhude',
    'Bimohlen',
    'Stephanskirchen',
    'Martinsried',
    'Geisenhausen',
    'Forchheim',
    'Adelsdorf',
    'Hausen',
    'Ihringen',
    'Kenzingen',
    'Altdorf',
    'Rechberg',
    'Erzingen',
    'Altbach',
    'Althutte',
    'Eitensheim',
    'Kutzenhausen',
    'Osburg',
    'Bickenbach',
    'Erbach im Odenwald',
    'Furth',
    'Alzey',
    'Biebesheim',
    'Wallmerod',
    'Partenheim',
    'Harxheim',
    'Laurenburg',
    'Sprendlingen',
    'Friesenhagen',
    'Macken',
    'Bardenberg',
    'Berlin',
    'Uelsen',
    'Suddendorf',
    'Bawinkel',
    'Hoogstede',
    'Georgsdorf',
    'Lage',
    'Furstenau',
    'Freren',
    'Renchen',
    'Hofstetten',
    'Fischerbach',
    'Oberharmersbach',
    'Haslach im Kinzigtal',
    'Schwaibach',
    'Hausach',
    'Hesedorf',
    'Peitz',
    'Bad Liebenwerda',
    'Munchweiler an der Alsenz',
    'Dietrichingen',
    'Eppenbrunn',
    'Rosenkopf',
    'Rockenhausen',
    'Krickenbach',
    'Walshausen',
    'Oberndorf',
    'Alfstedt',
    'Sontra',
    'Bahrenfleth',
    'Neuenbrook',
    'Steinburg',
    'Neuwittenbek',
    'Strande',
    'Danischenhagen',
    'Gleschendorf',
    'Colnrade',
    'Cappeln',
    'Buchlberg',
    'Hebertsfelden',
    'Haarbach',
    'Roehrnbach',
    'Hamb',
    'Gronau',
    'Bruggen',
    'Haina',
    'Breitscheid',
    'Schonecken',
    'Bitburg',
    'Kinheim',
    'Mehring',
    'Neuhutten',
    'Wolsfeld',
    'Berglicht',
    'Binsfeld',
    'Rittersdorf',
    'Murlenbach',
    'Neuerburg',
    'Dettenhausen',
    'Drestedt',
    'Halsenbach',
    'Welterod',
    'Duchroth',
    'Beckedorf',
    'Schellerten',
    'Wollstein',
    'Ober-Olm',
    'Bad Munster am Stein-Ebernburg',
    'Trollenhagen',
    'Hilwartshausen',
    'Lachendorf',
    'Bad Sooden-Allendorf',
    'Malterdingen',
    'Katzenmoos',
    'Oldersum',
    'Langeoog',
    'Neuborger',
    'Nieder-Olm',
    'Bad Sachsa',
    'Harste',
    'Wulften',
    'Lottstetten',
    'Brachbach',
    'Daaden',
    'Dorstadt',
    'Hoitlingen',
    'Brandis',
    'Borsdorf',
    'Roklum',
    'Jelmstorf',
    'Suderburg',
    'Leiferde',
    'Vohringen',
    'Oggelshausen',
    'Reichenbach im Tale',
    'Aichstetten',
    'Frickenhausen',
    'Sehlde',
    'Bieber',
    'Wiggensbach',
    'Buxheim',
    'Halblech',
    'Konigsbruck',
    'Hohnstein',
    'Glashutte',
    'Diepenau',
    'Mauer',
    'Hardheim',
    'Kulsheim',
    'Hopsten',
    'Lembruch',
    'Windhagen',
    'Braubach',
    'Oberrod',
    'Woldert',
    'Selbach',
    'Steimel',
    'Buchholz',
    'Steuden',
    'Britz',
    'Twistringen',
    'Bexhovede',
    'Binzen',
    'Schweringen',
    'Hassel',
    'Schwarme',
    'Stadtprozelten',
    'Weilbach',
    'Sailauf',
    'Riedenberg',
    'Kalbach',
    'Rothlein',
    'Zwesten',
    'Liebenau',
    'Berkheim',
    'Winterrieden',
    'Lauben',
    'Eichelgarten',
    'Waldeck',
    'Herleshausen',
    'Cornberg',
    'Weissenbrunn',
    'Ludwigsstadt',
    'Stadtsteinach',
    'Eystrup',
    'Martfeld',
    'Mudau',
    'Schwarzach',
    'Tessin',
    'Suderholz',
    'Loddin',
    'Hohen Schwarfs',
    'Broderstorf',
    'Ducherow',
    'Horst',
    'Steinhagen',
    'Kritzmow',
    'Volkenshagen',
    'Gribow',
    'Bad Doberan',
    'Brandshagen',
    'Zempin',
    'Dummerstorf',
    'Kassow',
    'Kramerhof',
    'Grammow',
    'Birkheim',
    'Weinsheim',
    'Bacharach',
    'Suckow',
    'Rehna',
    'Crivitz',
    'Banzkow',
    'Cambs',
    'Wardow',
    'Zarrentin',
    'Spornitz',
    'Mecklenburg',
    'Gadebusch',
    'Laage',
    'Lubz',
    'Krakow am See',
    'Eldena',
    'Neu Vitense',
    'Camin',
    'Gross Bengerstorf',
    'Niepars',
    'Wittenberge',
    'Brusewitz',
    'Leezen',
    'Rehhorst',
    'Rettin',
    'Brunstorf',
    'Huttblek',
    'Spangdahlem',
    'Bettingen',
    'Landscheid',
    'Thalfang',
    'Ebenweiler',
    'Wolpertswende',
    'Todenbuttel',
    'Bargenstedt',
    'Altwittenbek',
    'Hanerau-Hademarschen',
    'Warnau',
    'Sankt Peter-Ording',
    'Tetenbull',
    'Witzwort',
    'Oldenswort',
    'Ascheberg',
    'Quarnstedt',
    'Bornhoved',
    'Kasseedorf',
    'Elsdorf-Westermuhlen',
    'Ascheffel',
    'Pellworm',
    'Arlewatt',
    'Bistensee',
    'Hochdonn',
    'Brickeln',
    'Sarlhusen',
    'Schulldorf',
    'Breitenburg',
    'Elisabeth-Sophien-Koog',
    'Schonwalde',
    'Witzhave',
    'Lichtenberg',
    'Warmensteinach',
    'Grafengehaig',
    'Wittenburg',
    'Wenzendorf',
    'Vastorf',
    'Bad Schussenried',
    'Hosskirch',
    'Dieblich',
    'Asbach',
    'Kotterichen',
    'Arzbach',
    'Rennerod',
    'Flammersfeld',
    'Kobern-Gondorf',
    'Niederzissen',
    'Assel',
    'Helmste',
    'Kennenburg',
    'Korb',
    'Freiburg',
    'Brackel',
    'Stadelhofen',
    'Wallenfels',
    'Wonsees',
    'Wagersrott',
    'Taarstedt',
    'Langstedt',
    'Hasselberg',
    'Suderbrarup',
    'Keitum',
    'Borgsum',
    'Rantum',
    'Stolk',
    'Boel',
    'Burgsinn',
    'Mellrichstadt',
    'Pettstadt',
    'Wipfeld',
    'Grossbardorf',
    'Egenhausen',
    'Burgwindheim',
    'Heustreu',
    'Bergrheinfeld',
    'Monchsroth',
    'Gillersdorf',
    'Beinerstadt',
    'Palling',
    'Kienberg',
    'Bernau am Chiemsee',
    'Surheim',
    'Halsbach',
    'Ering',
    'Geratskirchen',
    'Rimbach',
    'Wolfegg',
    'Nersingen',
    'Uttenweiler',
    'Bachingen an der Brenz',
    'Bodenwohr',
    'Kirchdorf im Wald',
    'Schierling',
    'Nabburg',
    'Bernhardswald',
    'Geisling',
    'Tegernheim',
    'Riedenburg',
    'Wiesent',
    'Painten',
    'Metten',
    'Wildenberg',
    'Offenberg',
    'Bayern',
    'Brenz',
    'Altenriet',
    'Blaibach',
    'Eschlkam',
    'Rinchnach',
    'Hunderdorf',
    'Bayerisch Eisenstein',
    'Gotteszell',
    'Plattling',
    'Leinburg',
    'Oettersdorf',
    'Tengen',
    'Eschenbach',
    'Kohlberg',
    'Teuschnitz',
    'Creussen',
    'Roslau',
    'Regnitzlosau',
    'Rimbach',
    'Pfalzgrafenweiler',
    'Hersbruck',
    'Hallerndorf',
    'Durrbrunn',
    'Grafenberg',
    'Hemhofen',
    'Rottenbach',
    'Kirchehrenbach',
    'Finsing',
    'Warngau',
    'Waakirchen',
    'Seeshaupt',
    'Otterfing',
    'Kochel',
    'Kipfenberg',
    'Diepersdorf',
    'Muggendorf',
    'Spardorf',
    'Greding',
    'Leutenbach',
    'Buckenhof',
    'Ottensoos',
    'Grossenseebach',
    'Rechtenbach',
    'Rothenbuch',
    'Hollstadt',
    'Hergolshausen',
    'Bischofsheim an der Rhon',
    'Eussenheim',
    'Stockheim',
    'Oberelsbach',
    'Schillingsfurst',
    'Neuhof',
    'Sonnefeld',
    'Neuhof',
    'Gundelsheim',
    'Adelschlag',
    'Markt Erlbach',
    'Adelshofen',
    'Sesslach',
    'Ahorn',
    'Bellershausen',
    'Dollnstein',
    'Wicklesgreuth',
    'Langenbach',
    'Lenting',
    'Essenbach',
    'Riemerling',
    'Nassenfels',
    'Herrieden',
    'Fatschenbrunn',
    'Lichtenau',
    'Lichtenau',
    'Wilburgstetten',
    'Pleinfeld',
    'Weitramsdorf',
    'Feuchtwangen',
    'Leibelbach',
    'Gerolfingen',
    'Ehingen',
    'Deining',
    'Allersberg',
    'Heimbuchenthal',
    'Gaukonigshofen',
    'Wiesthal',
    'Poppenhausen',
    'Unterpleichfeld',
    'GemÃ¼nden am Main',
    'Frensdorf',
    'Flachslanden',
    'Kammerstein',
    'Kemmern',
    'Obermichelbach',
    'Retzstadt',
    'Rottingen',
    'Butthard',
    'Schonungen',
    'Schollbrunn',
    'Umpfenbach',
    'Bad Bocklet',
    'Schondra',
    'Hafenlohr',
    'Seligenstadt',
    'Mainstockheim',
    'Riedmoos',
    'Tuntenhausen',
    'Emmering',
    'Weyarn',
    'Inning am Ammersee',
    'Uffing',
    'Valley',
    'Rott am Inn',
    'Bad Wiessee',
    'Utting',
    'Groitzsch',
    'Husby',
    'Wyk auf Fohr',
    'Grafrath',
    'Kolbermoor',
    'Kuhbach',
    'Iffeldorf',
    'Oberammergau',
    'Eydelstedt',
    'Barnstorf',
    'Bippen',
    'Badbergen',
    'Berge',
    'Pentenried',
    'Wildenfels',
    'Oberdolling',
    'Rohrbach',
    'Gammelsdorf',
    'Feldafing',
    'Albaching',
    'Wolnzach',
    'Neukeferloh',
    'Rieden',
    'Batzhausen',
    'Aholming',
    'Parkstetten',
    'Schmidmuhlen',
    'Laaber',
    'Moosburg',
    'Wang',
    'Lutau',
    'Grossbeeren',
    'Pfaffenhofen an der Ilm',
    'Munsing',
    'Moosach',
    'Hohenwart',
    'Neuburg an der Donau',
    'Kottgeisering',
    'Attenkirchen',
    'Sulzemoos',
    'Langenpreising',
    'Rachelsbach',
    'Zuchering',
    'Zolling',
    'Tuerkenfeld',
    'Walpertskirchen',
    'Allendorf',
    'Wieseck',
    'Gutmadingen',
    'Stuhlingen',
    'Niederstaufenbach',
    'Kusel',
    'Trippstadt',
    'Rimschweiler',
    'Haschbach an der Glan',
    'Hochspeyer',
    'Bundenthal',
    'Stelzenberg',
    'Otterberg',
    'Nanzweiler',
    'Sembach',
    'Allmannshofen',
    'Genderkingen',
    'Leitershofen',
    'Graben',
    'Villenbach',
    'Fremdingen',
    'Schwaben',
    'Stadtbergen',
    'Monchsdeggingen',
    'Mickhausen',
    'Dielingen',
    'Mahlberg',
    'Rugland',
    'Ehingen',
    'Wallerstein',
    'Bachhagel',
    'Zusamaltheim',
    'Schiltberg',
    'Oettingen in Bayern',
    'Mechelgrun',
    'Beierfeld',
    'Lossnitz',
    'Markneukirchen',
    'Breitenbrunn',
    'Herfatz',
    'Opfenbach',
    'Betzigau',
    'Rettenberg',
    'Schwangau',
    'Untrasried',
    'Eisenberg',
    'Bad Hindelang',
    'Herzlake',
    'Lunne',
    'Hohenstein',
    'Heiningen',
    'Baienfurt',
    'Bunde',
    'Konigseggwald',
    'Bartholoma',
    'Attenweiler',
    'Buhlen',
    'Niedenstein',
    'Emerkingen',
    'Griesingen',
    'Altshausen',
    'Dettingen an der Iller',
    'Owen',
    'Bierlingen',
    'Motzingen',
    'Aichhalden',
    'Bad Rippoldsau-Schapbach',
    'Deisslingen',
    'Sauldorf',
    'Pulsnitz',
    'Olbersdorf',
    'Ostritz',
    'Rohrsdorf',
    'Bad Duben',
    'Nerchau',
    'Belgern',
    'Torgau',
    'Pegau',
    'Oeversee',
    'Leck',
    'Westerland',
    'Kropp',
    'Sollerup',
    'Grossenwiehe',
    'Tetenhusen',
    'Brebel',
    'Heudorf',
    'Altheim',
    'Wyhl',
    'Breitnau',
    'Steinen',
    'Kandern',
    'Ibach',
    'Herbolzheim',
    'Laufenburg',
    'Todtmoos',
    'Ebringen',
    'Eschbach',
    'Sasbach',
    'Uhlingen-Birkendorf',
    'Ballrechten',
    'Utzenfeld',
    'Schalksmuhle',
    'Schomberg',
    'Engelsbrand',
    'Ostelsheim',
    'Gomaringen',
    'Raubach',
    'Munstermaifeld',
    'Stockhausen-Illfurth',
    'Mundersbach',
    'Alsbach',
    'Ulmen',
    'Kleinmaischeid',
    'Neuweiler',
    'Sternenfels',
    'Neugersdorf',
    'Oppach',
    'Goda',
    'Mistelbach',
    'Himmelkron',
    'Luhe-Wildenau',
    'Altenstadt',
    'Speinshart',
    'Partenstein',
    'Heigenbrucken',
    'Bodenkirchen',
    'Hohenbrunn',
    'Dorum',
    'Seefeld',
    'Bichl',
    'Rottleberode',
    'Neukieritzsch',
    'Colditz',
    'Rosswein',
    'Mutzschen',
    'Ottersweier',
    'Oberwolfach',
    'Muggensturm',
    'Kappel-Grafenhausen',
    'Greifenstein',
    'Selters',
    'Niederquembach',
    'Rodheim',
    'Schornsheim',
    'Sulzheim',
    'Mengerschied',
    'Oberhausen-Rheinhausen',
    'Unterliezheim',
    'Asbach',
    'Lutzingen',
    'Gerabronn',
    'Jagsthausen',
    'Burtenbach',
    'Gachenbach',
    'Oberrot',
    'Allmersbach im Tal',
    'Scheuerhalden',
    'Fischbach',
    'Kirtorf',
    'Rasdorf',
    'Hosenfeld',
    'Nennig',
    'Ensdorf',
    'Hirstein',
    'Gusterath',
    'Burgen',
    'Auw',
    'Maring-Noviand',
    'Mandern',
    'Baasem',
    'Eckfeld',
    'Korperich',
    'Sefferweich',
    'Schleid',
    'Platten',
    'Prumzurlay',
    'Lambertsberg',
    'Hilscheid',
    'Altenkirchen',
    'Niederkirchen',
    'Schindhard',
    'Clausen',
    'Heinzenhausen',
    'Konken',
    'Hornbach',
    'Contwig',
    'Medard',
    'Offenbach-Hundheim',
    'Waldmohr',
    'Olsbrucken',
    'Einselthum',
    'Breitenbach',
    'Dittweiler',
    'Wilgartswiesen',
    'Papendorf',
    'Rullstorf',
    'Scharnebeck',
    'Klein-Gerau',
    'Rieneck',
    'Ippesheim',
    'Lautertal',
    'Klein-Zimmern',
    'Billings',
    'Oberstenfeld',
    'Dorzbach',
    'Stimpfach',
    'Schmidthachenbach',
    'Hohen',
    'Oberdiebach',
    'Sankt Georgen im Schwarzwald',
    'Norsingen',
    'Vohrenbach',
    'Waldsassen',
    'Waldalgesheim',
    'Kesselbach',
    'Sargenroth',
    'Pfalzfeld',
    'Pleizenhausen',
    'Engelstadt',
    'Erbes-Budesheim',
    'Abenberg',
    'Walsdorf',
    'Heroldsbach',
    'Burgebrach',
    'Buchenbach',
    'Kinding',
    'Oberwesel',
    'Hochstetten-Dhaun',
    'Kasdorf',
    'Bergen',
    'Geisig',
    'Bornich',
    'Merxheim',
    'Monsheim',
    'Seesbach',
    'Breitscheid',
    'Neubeckum',
    'Mommenheim',
    'Flonheim',
    'Pyrbaum',
    'Priesendorf',
    'Euerdorf',
    'Rimpar',
    'Stadtlauringen',
    'Kolitzheim',
    'Stammheim',
    'Waldbuttelbrunn',
    'Dittelbrunn',
    'Tauberrettersheim',
    'Unterbaldingen',
    'Malborn',
    'Speicher',
    'Oberbillig',
    'Mechenried',
    'Burkardroth',
    'Schlusselfeld',
    'Grafenrheinfeld',
    'Heppdiel',
    'Hofheim',
    'Possenheim',
    'Heinrichsthal',
    'Sand',
    'Neukirchen',
    'Ulrichstein',
    'Kandel',
    'Schmiechen',
    'Berghulen',
    'Steinheim am Albuch',
    'Roigheim',
    'Dirmstein',
    'Minfeld',
    'Eichtersheim',
    'Edenkoben',
    'Lambsheim',
    'Gerlachsheim',
    'Jebenhausen',
    'Laumersheim',
    'Schonau im Schwarzwald',
    'Ruchheim',
    'Neubulach',
    'Aidlingen',
    'Leimersheim',
    'Neckargerach',
    'Hagenbach',
    'Karlshuld',
    'Brannenburg',
    'Oberpframmern',
    'Eicherloh',
    'Eitting',
    'Maikammer',
    'Maxdorf',
    'Roschbach',
    'Neuleiningen',
    'Putbus',
    'Lassentin',
    'Gagern',
    'Prohn',
    'Ostseebad Zinnowitz',
    'Wiek',
    'Obrigheim',
    'Harthausen',
    'Thum',
    'Niederdorf',
    'Neudorf',
    'Sehma',
    'Gersdorf',
    'Erda',
    'Annerod',
    'Rauschenberg',
    'Fronhausen',
    'Eschau',
    'Roden',
    'Monchberg',
    'Rentweinsdorf',
    'Beuern',
    'Herschbach',
    'Allendorf',
    'Altendiez',
    'Hausen',
    'Rummingen',
    'Oberried',
    'Lehmen',
    'Melsbach',
    'Mayen',
    'Gieleroth',
    'Willmenrod',
    'Steinen',
    'Uxheim',
    'Werlau',
    'Kelberg',
    'Oberraden',
    'Bad Gottleuba',
    'Bodenheim',
    'Haide',
    'Neusitz',
    'Ohorn',
    'Hirschfelde',
    'Oberseifersdorf',
    'Dittelsdorf',
    'Spitzkunnersdorf',
    'Grossschonau',
    'Burgsponheim',
    'Schmittweiler',
    'Monzingen',
    'Lauschied',
    'Lindewitt',
    'Goldelund',
    'Suderlugum',
    'Humptrup',
    'Langenweddingen',
    'Oebisfelde',
    'Dardesheim',
    'Stapelburg',
    'Colbitz',
    'Harzgerode',
    'Pfaffenhausen',
    'Uedem',
    'Kagsdorf',
    'Pfaffenhofen',
    'Schneizlreuth',
    'Fridolfing',
    'Hohenbergham',
    'Reichelsheim',
    'Echzell',
    'Lauta',
    'Bad Schandau',
    'Arnsdorf',
    'Klingenberg',
    'Hegge',
    'Haldenwang',
    'Burgberg',
    'Obergunzburg',
    'Harsleben',
    'Wilsdruff',
    'Langenwolmsdorf',
    'Geising',
    'Aukrug',
    'Norderstapel',
    'Hohenlockstedt',
    'Schlettau',
    'Wechselburg',
    'Mildenau',
    'Jahnsdorf',
    'Reinsdorf',
    'Syrau',
    'Lichtenau',
    'Leubsdorf',
    'Dahlenburg',
    'Herrnhut',
    'Doberschau',
    'Wilthen',
    'Mittelherwigsdorf',
    'Bernstadt',
    'Cunewalde',
    'Eibau',
    'Rennersdorf',
    'Obergurig',
    'Holdorf',
    'Unterwossen',
    'Perach',
    'Bergen',
    'Seebruck',
    'Breitbrunn am Chiemsee',
    'Feilitzsch',
    'Neuenmarkt',
    'Trostau',
    'Sandesneben',
    'Wakendorf',
    'Zarpen',
    'Breitengussbach',
    'Thalmassing',
    'Gremsdorf',
    'Neustadt an der Orla',
    'Konigsee',
    'Niederpollnitz',
    'Lehndorf',
    'Salzwedel',
    'Gatersleben',
    'Jerichow',
    'Buden',
    'Luftkurort Arendsee',
    'Neenstetten',
    'Gerbstedt',
    'Dieskau',
    'Aken',
    'Baasdorf',
    'Weissandt-Golzau',
    'Kretzschau',
    'Bobenheim am Berg',
    'Ilbesheim',
    'Neckarbischofsheim',
    'Kirchardt',
    'Lingenfeld',
    'Bad Bergzabern',
    'Wettin',
    'Milzau',
    'Zschornewitz',
    'Rosslau',
    'Dederstedt',
    'Horstdorf',
    'Grafenhainichen',
    'Frose',
    'Helbra',
    'Nachterstedt',
    'Droyssig',
    'Konnern',
    'Lieskau',
    'Langendorf',
    'Niemberg',
    'Seeburg',
    'Helmershausen',
    'Altengottern',
    'Herbsleben',
    'Niederspier',
    'Grossengottern',
    'Georgenthal',
    'Vesser',
    'Bad Frankenhausen',
    'Bleicherode',
    'Bad Tennstedt',
    'Geraberg',
    'Schmiedefeld am Rennsteig',
    'Uder',
    'Laucha',
    'Sollstedt',
    'Greussen',
    'Thalwenden',
    'Wachenroth',
    'Gebesee',
    'Bosleben-Wullersleben',
    'Oberdorla',
    'Hildburghausen',
    'Buttstedt',
    'Wechmar',
    'Elxleben',
    'Niederdorla',
    'Horselgau',
    'Stotternheim',
    'Noda',
    'Kaltenwestheim',
    'Geschwenda',
    'Buch',
    'Essenheim',
    'Waldheim',
    'Grethen',
    'Krostitz',
    'Regis-Breitingen',
    'Mochau',
    'Dommitzsch',
    'Lauta',
    'Heide',
    'Straupitz',
    'Burg',
    'Altdobern',
    'Spremberg',
    'Guben',
    'Schonewalde',
    'Neupetershain',
    'Hellstein',
    'Stegen',
    'Grafenhausen',
    'Wutoschingen',
    'Waldshut',
    'Istein',
    'Feldberg-Ort',
    'Cappel',
    'Gross-Rohrheim',
    'Otzing',
    'Wald',
    'Langquaid',
    'Laer',
    'Birnbach',
    'Heringsdorf',
    'Jarmen',
    'Karlshagen',
    'Gramzow',
    'Mirow',
    'Mallin',
    'Grambow',
    'Saal',
    'Grossenluder',
    'Altengronau',
    'Banteln',
    'Vehlow',
    'Walsleben',
    'Zerf',
    'Dienstweiler',
    'Waxweiler',
    'Dabergotz',
    'Flecken Zechlin',
    'Dreetz',
    'Hohendodeleben',
    'Zerben',
    'Hundisburg',
    'Derben',
    'Egeln',
    'Parey',
    'Eggersdorf',
    'Dedeleben',
    'Bruck',
    'Betzenstein',
    'Mistelgau',
    'Glien',
    'Oberirsen',
    'Friedewald',
    'Wolferlingen',
    'Lonnig',
    'Streithausen',
    'Fachbach',
    'Herdorf',
    'Muschenbach',
    'Halbs',
    'Schonwalde',
    'Beim Kronsberg',
    'Haus',
    'Ankum',
    'Icker',
    'Lathen',
    'Goldenstedt',
    'Lastrup',
    'Toppenstedt',
    'Kuhstedt',
    'Siedenburg',
    'Wischhafen',
    'Osten',
    'Storkow',
    'Rechtenfleth',
    'Elsfleth',
    'Butjadingen',
    'Ohrensen',
    'Elstorf',
    'Hamwarde',
    'Ottersberg',
    'Brieselang',
    'Herzberg',
    'Bad Windsheim',
    'Lychen',
    'Gromitz',
    'Bergen auf Ruegen',
    'Lynow',
    'Dallgow-Doeberitz',
    'Alt Golm',
    'Tutzpatz',
    'Krugsdorf',
    'Paradies',
    'Walheim',
    'Frittlingen',
    'Nusplingen',
    'Kusterdingen',
    'Entringen',
    'Ruppertshofen',
    'Deilingen',
    'Riegel',
    'Silling',
    'Karsbach',
    'Bogenhausen',
    'Gadegast',
    'Blumenthal',
    'Bornstein',
    'Marienburg',
    'Fockbek',
    'Boklund',
    'Tarp',
    'Borgdorf-Seedorf',
    'Wasbek',
    'Fleckeby',
    'Owschlag',
    'Rickert',
    'Padenstedt',
    'Nubbel',
    'Kirchhellen',
    'Neuendettelsau',
    'Stulln',
    'Geithain',
    'Tettau',
    'Konigsfeld',
    'Etterschlag',
    'Spatzenhausen',
    'Leezen',
    'Nakensdorf',
    'Cramonshagen',
    'Domsuhl',
    'Retgendorf',
    'Witzin',
    'Bornsen',
    'Trent',
    'Rambin',
    'Verchen',
    'Neuenkirchen',
    'Grimmen',
    'Neuenkirchen',
    'Dipbach',
    'Benshausen',
    'Weissensee',
    'Bregenstedt',
    'Barby',
    'Dahlen',
    'Mugeln',
    'Ursensollen',
    'Freudenberg',
    'Claussnitz',
    'Klutz',
    'Strohkirchen',
    'Lauenbruck',
    'Wirges',
    'Gershasen',
    'Luckenbach',
    'Burgbrohl',
    'Idenheim',
    'Werlte',
    'Lindern',
    'Oberthulba',
    'Weissenthurm',
    'Ohrdruf',
    'Kranichfeld',
    'Burghaun',
    'Ainring',
    'Lehesten',
    'Gefell',
    'Lonnerstadt',
    'Ellingen',
    'Wellheim',
    'Vahlbruch',
    'Obernfeld',
    'Ostseebad Binz',
    'Wolgast',
    'Erzenhausen',
    'Busenberg',
    'Leimen',
    'Lauterecken',
    'Linden',
    'Bernterode',
    'Schwallungen',
    'Munchhausen',
    'Battenberg',
    'Kirberg',
    'Ballersbach',
    'Teuchern',
    'Jestetten',
    'Bollschweil',
    'Linsburg',
    'Westfeld',
    'Werlaburgdorf',
    'Treuen',
    'Gomadingen',
    'Winzer',
    'Weiler',
    'Bromskirchen',
    'Sickte',
    'Fallersleben',
    'Dannenberg',
    'Sachsenkam',
    'Fischbachau',
    'Neubeuern',
    'Obermoschel',
    'Hirschhorn',
    'Grosskarolinenfeld',
    'Buggingen',
    'Quaal',
    'Pohnstorf',
    'Heilsbronn',
    'Pommelsbrunn',
    'Velburg',
    'Breitenberg',
    'Strohn',
    'Hobeck',
    'Atting',
    'Traitsching',
    'Hohenlimburg',
    'Bellheim',
    'Hochstadten',
    'Wolfmannshausen',
    'Steisslingen',
    'Elsterberg',
    'Kranzberg',
    'Oberaudorf',
    'Sosa',
    'Bruchhausen-Vilsen',
    'Ahausen',
    'Boddenstedt',
    'Bankewitz',
    'Muhl Rosin',
    'Alling',
    'Hofen',
    'Egenhofen',
    'Sotterhausen',
    'Merzalben',
    'Schmalenberg',
    'Bunsoh',
    'Schmalensee',
    'Hundsdorf',
    'Grossmaischeid',
    'Brey',
    'Pesterwitz',
    'Arnbruck',
    'Bayrischzell',
    'Haag an der Amper',
    'Hasselbach',
    'Riede',
    'Hamdorf',
    'Aurach',
    'Domitz',
    'Dreveskirchen',
    'Oelerse',
    'Regen',
    'Walderbach',
    'Morslingen',
    'Munzingen',
    'Grossaitingen',
    'Landensberg',
    'Rogling',
    'Elbingerode',
    'Benneckenstein',
    'Friedrichswerth',
    'Hasselfelde',
    'Nandlstadt',
    'Dingolshausen',
    'Nieder-Beerbach',
    'Gundernhausen',
    'Erlbach',
    'Eschelbronn',
    'Hergisdorf',
    'Hengersberg',
    'Runding',
    'Kollnburg',
    'Lam',
    'Waldsee',
    'Birkenheide',
    'Frankenstein',
    'Rohrbach',
    'Wachenheim an der Weinstrasse',
    'Carlsberg',
    'Hochdorf-Assenheim',
    'Heudeber',
    'Engelthal',
    'Hartenstein',
    'Hausen',
    'Simmelsdorf',
    'Rottenstuben',
    'Offenhausen',
    'Alfeld',
    'Adelsdorf',
    'Hessdorf',
    'Vestenbergsgreuth',
    'Oberhundem',
    'Hohe',
    'Wallertheim',
    'Horrweiler',
    'Friesenheim',
    'Alsheim',
    'Selzen',
    'Horperath',
    'Bretthausen',
    'Marienhausen',
    'Schuld',
    'Pracht',
    'Horressen',
    'Moosinning',
    'Bayersoien',
    'Scheuring',
    'Buch',
    'Steingaden',
    'Griesstatt',
    'Spiesen-Elversberg',
    'Mitterfels',
    'Sankt Englmar',
    'Bad Karlshafen',
    'Soller',
    'Hastenrath',
    'Niederdorf',
    'Bofingen',
    'Hoym',
    'Hettstedt',
    'Neutz-Lettewitz',
    'Neustadt',
    'Altleiningen',
    'Schweigen-Rechtenbach',
    'Prestewitz',
    'Leuthen',
    'Elsterwerda',
    'Otterbach',
    'Ruppertsweiler',
    'Kleinbundenbach',
    'Dellfeld',
    'Guntersblum',
    'Gundersheim',
    'Hochborn',
    'Walluf',
    'Meisenheim',
    'Eimsheim',
    'Laudert',
    'Kisselbach',
    'Bockenau',
    'Fischerhude',
    'Thedinghausen',
    'Fernthal',
    'Oberzissen',
    'Urbar',
    'Steineroth',
    'Lahrbach',
    'Jembke',
    'Wesendorf',
    'Beierstedt',
    'Lommatzsch',
    'Weissenborn',
    'Stromberg',
    'Hatzfeld',
    'Eppenrod',
    'Thomasburg',
    'Schnackenburg',
    'Gohrde',
    'Soltendieck',
    'Schirgiswalde',
    'Waltersdorf',
    'Crostau',
    'Demitz-Thumitz',
    'Hainewalde',
    'Neusalza-Spremberg',
    'Neukirch/Lausitz',
    'Kubschutz',
    'Barenstein',
    'Reinsberg',
    'Oelsnitz',
    'Thermalbad Wiesenbad',
    'Herbertingen',
    'Dirlewang',
    'Hinterhermsdorf',
    'Neumarkt in der Oberpfalz',
    'Dorndorf',
    'Oberweissbach',
    'Effelder',
    'Obergebra',
    'Aspach',
    'Tiefenort',
    'Hohenleuben',
    'Berga',
    'Teichwolframsdorf',
    'Spora',
    'Solkwitz',
    'Dobra',
    'Gossersweiler-Stein',
    'Au am Rhein',
    'Sternberg',
    'Spielberg',
    'Treben',
    'Seeland',
    'Holzweissig',
    'Sandersdorf',
    'Wallhausen',
    'Zwenkau',
    'Kriebstein',
    'Gruna',
    'Belgershain',
    'Altenhasslau',
    'Salm',
    'Garz',
    'Wenddorf',
    'Kladen',
    'Jersleben',
    'Schwarzholz',
    'Gerwisch',
    'Eichstedt',
    'Eichenbarleben',
    'Apenburg',
    'Niederndodeleben',
    'Seehausen',
    'Lenzkirch',
    'Elzach',
    'Heimertingen',
    'Legau',
    'Babenhausen',
    'Erkheim',
    'Fischen',
    'Ottersheim',
    'Lengdorf',
    'Altfraunhofen',
    'Volkenschwand',
    'Sankt Wolfgang',
    'Inning am Holz',
    'Gunthersleben',
    'Wumbach',
    'Wiehe',
    'Rauenstein',
    'Rottach',
    'Kaufering',
    'Rohrdorf',
    'Ettenstatt',
    'Kunreuth',
    'Oberscheinfeld',
    'Munchsteinach',
    'Hiltrup',
    'Ladbergen',
    'Wettringen',
    'Untersiemau',
    'Nennslingen',
    'Ebensfeld',
    'Wettelsheim',
    'Obernzenn',
    'Dorfprozelten',
    'Schneppenbach',
    'Oerlenbach',
    'Falls',
    'Weilbach',
    'Billingshausen',
    'Ermershausen',
    'Erdweg',
    'Neuburg',
    'Bubesheim',
    'Rofingen',
    'Biberbach',
    'Todtnauberg',
    'Hochenschwand',
    'Bad Bellingen',
    'Eichstetten',
    'Todtnau',
    'Gutach im Breisgau',
    'Bamlach',
    'Morscheid',
    'Nittel',
    'Lissendorf',
    'Bous',
    'Rodenbach',
    'Mackenbach',
    'Rutsweiler an der Lauter',
    'Schwedelbach',
    'Gehrweiler',
    'Steinwenden',
    'Obermohr',
    'Herschberg',
    'Niederwuerschnitz',
    'Geyer',
    'Wachtum',
    'Brockum',
    'Ellrich',
    'Schnaitsee',
    'Jesendorf',
    'Marzling',
    'Freimersheim',
    'Mauer',
    'Schonau',
    'Sasbach',
    'Sasbachwalden',
    'Tiddische',
    'Lahm',
    'Oberleichtersbach',
    'Erlenbach bei Marktheidenfeld',
    'Kolbingen',
    'Scheer',
    'Marksuhl',
    'Stutzerbach',
    'Ohlstadt',
    'Burgheim',
    'Elm',
    'Donsieders',
    'Schmelz',
    'Uberherrn',
    'Walhausen',
    'Wiedergeltingen',
    'Ruderatshofen',
    'Auenstein',
    'Weissbach',
    'Michelbach an der Bilz',
    'Wimsheim',
    'Ottrau',
    'Wallhausen',
    'Schwaigern',
    'Baindt',
    'Gutenzell-Hurbel',
    'Deckenpfronn',
    'Bempflingen',
    'Bahrdorf',
    'Oldenburg in Holstein',
    'Reutlingendorf',
    'Oberdischingen',
    'Ertingen',
    'Schaulings',
    'Neuravensburg',
    'Bortlingen',
    'Ersingen',
    'Bellamont',
    'Veltheim',
    'Sohlde',
    'Ramsla',
    'Oldisleben',
    'Wustheuterode',
    'Frankenheim',
    'Kaltennordheim',
    'Grosswechsungen',
    'Sattelstadt',
    'Dermbach',
    'Empfertshausen',
    'Lengerich',
    'Geeste',
    'Obristfeld',
    'Oberhaid',
    'Wassermungenau',
    'Schesslitz',
    'Pappenheim',
    'Scheinfeld',
    'Michelau',
    'Marktgraitz',
    'Ebersdorf',
    'Bockelwitz',
    'Alterhofen',
    'Mamming',
    'Kunzing',
    'Baltmannsweiler',
    'Bedburdyck',
    'Gladbach',
    'Hohn',
    'Eddelak',
    'Herforst',
    'Langweiler',
    'Nattenheim',
    'Bollendorf',
    'Welschbillig',
    'Karlshausen',
    'Piesport',
    'Breit',
    'Wiltingen',
    'Hetzerath',
    'Metterich',
    'Krov',
    'Wistedt',
    'Geversdorf',
    'Handeloh',
    'Arlaching',
    'Reischach',
    'Aindorf',
    'Halbe',
    'Senzig',
    'Gersfeld',
    'Hochkirch',
    'Gelting',
    'Bockhorn',
    'Kloster',
    'Ferchesar',
    'Ketzin',
    'Heinsdorf',
    'Ermke',
    'Strucklingen',
    'Gross Koris',
    'Oberkirchen',
    'Plech',
    'Schonsee',
    'Marktschorgast',
    'Kupferberg',
    'Lubstorf',
    'Passow',
    'Dabel',
    'Carlow',
    'Pinnow',
    'Aumuhle',
    'Wusterhusen',
    'Kropelin',
    'Ostseebad Prerow',
    'Ziesendorf',
    'Gelbensande',
    'Breesen',
    'Rhumspringe',
    'Muldenstein',
    'Farnroda',
    'Breitenbach',
    'Eisfeld',
    'Willmersdorf',
    'Schwansee',
    'Stepfershausen',
    'Wilhelmsthal',
    'Altstadt',
    'Weissen',
    'Spiegelsberge',
    'Rosengarten',
    'Hofgarten',
    'Deutsches Haus',
    'Tonbach',
    'Diana',
    'Dunnwald',
    'Ernst',
    'Linde',
    'Haverkamp',
    'Bad Godesberg',
    'Lausitz',
    'Oberwinter',
    'Wittekindshof',
    'Lehen',
    'Bredeney',
    'Allee',
    'Konigsdorf',
    'Schaumburg',
    'Rodinghausen',
    'Eimeldingen',
    'Nesselwang',
    'Sussen',
    'Mittenwald',
    'Mammendorf',
    'Wustenriet',
    'Rudersdorf',
    'Munchsmunster',
    'Traunwalchen',
    'Weichs',
    'Wangen',
    'Wasserlosen',
    'Hohenberg',
    'Rosenthal',
    'Ellerbek',
    'Velpke',
    'Rosendahl',
    'Hohenhameln',
    'Oppurg',
    'Pauscha',
    'Lalendorf',
    'Rastow',
    'Funfeichen',
    'Harra',
    'Grossharthau',
    'Rauda',
    'Plau am See',
    'Goldewin',
    'Neuhausel',
    'Weiden',
    'Kirchsahr',
    'Altenahr',
    'Gross Twulpstedt',
    'Weiden',
    'Altheim',
    'Unterreit',
    'Buch am Erlbach',
    'Saldenburg',
    'Innernzell',
    'Thannberg',
    'Ruckeroth',
    'Breitenau',
    'Vielbach',
    'Nordhofen',
    'Rentrisch',
    'Otterstadt',
    'Polch',
    'Monreal',
    'Wackernheim',
    'Marnheim',
    'Freirachdorf',
    'Oberthal',
    'Hanhofen',
    'Wittgert',
    'Stadecken-Elsheim',
    'Fusternberg',
    'Holthausen',
    'Niederau',
    'Roxel',
    'Konigsbrunn',
    'Holzheim',
    'Ruckersdorf',
    'Langwasser',
    'Hurlach',
    'Aislingen',
    'Oy-Mittelberg',
    'Vier',
    'Uckendorf',
    'Sindorf',
    'Illschwang',
    'Wartenberg-Rohrbach',
    'Womrath',
    'Wallhausen',
    'Blatzheim',
    'Sachsen',
    'Eggersdorf',
    'Venne',
    'Adlum',
    'Heiningen',
    'Otze',
    'Stadt',
    'Gerichshain',
    'Weede',
    'Ellerdorf',
    'Merzen',
    'Heiligenstedtenerkamp',
    'Nienborstel',
    'Oersdorf',
    'Klein Nordende',
    'Huje',
    'Nindorf',
    'Niederfrohna',
    'Pfaffenweiler',
    'Nienstadt',
    'Deutzen',
    'Naunhof',
    'Ranis',
    'Rieder',
    'Welbsleben',
    'Abtsbessingen',
    'Bad Kosen',
    'Altbelgern',
    'Klostermansfeld',
    'Gangloff',
    'Lauscha',
    'Schwarzhausen',
    'Niederau',
    'Moosthenning',
    'Julbach',
    'Wormstedt',
    'Artern',
    'Kirchworbis',
    'Ichtershausen',
    'Heldrungen',
    'Kleinfurra',
    'Schalkau',
    'Niederorschel',
    'Seebergen',
    'Steinach',
    'Sinzing',
    'Hohenfels',
    'Malgersdorf',
    'Hahnbach',
    'Weyhausen',
    'Stoetze',
    'Wasbuttel',
    'Gross Oesingen',
    'Emmendorf',
    'Mariental',
    'Osingen',
    'Lessien',
    'Nahrendorf',
    'Messstetten',
    'Heinade',
    'Eichenberg',
    'Willensen',
    'Morenhoven',
    'Heinkenborstel',
    'Habersdorf',
    'Gschwend',
    'Lindwedel',
    'Betheln',
    'Helpsen',
    'Almstedt',
    'Beedenbostel',
    'Sibbesse',
    'Furholzen',
    'Dingelstedt',
    'Groeningen',
    'Luttgenrode',
    'Mockern',
    'Ditfurt',
    'Ballenstedt',
    'Schopsdorf',
    'Harmstorf',
    'Seester',
    'Sorup',
    'Niesgrau',
    'Mohrkirch',
    'Wrixum',
    'Wittdun',
    'Gross Rheide',
    'Pommerby',
    'Linden',
    'Friedrichskoog',
    'Damp',
    'Barkenholm',
    'Bockhorst',
    'Neermoor',
    'Furth im Wald',
    'Borne',
    'Eresing',
    'Lengenfeld',
    'Bad Kohlgrub',
    'Rennertshofen',
    'Schwindegg',
    'Au',
    'Todtenweis',
    'Wechingen',
    'Asbach-Baumenheim',
    'Hilgertshausen-Tandern',
    'Marktschellenberg',
    'Gmain bei Weidach',
    'Schonberg',
    'Weissenohe',
    'Vorra',
    'Bredenbek',
    'Kaaks',
    'Wilhelm',
    'Thaden',
    'Bendfeld',
    'Wendtorf',
    'Puls',
    'Oldenburg',
    'Damlos',
    'Waischenfeld',
    'Hiltpoltstein',
    'Buchbach',
    'Furstenberg',
    'Langsur',
    'Koxhausen',
    'Strickscheid',
    'Birkenfeld',
    'Ochtendung',
    'Ruschberg',
    'Herborn',
    'Rinzenberg',
    'Bispingen',
    'Pollhagen',
    'Machern',
    'Posing',
    'Kofering',
    'Altenthann',
    'Gartow',
    'Jameln',
    'Weisenheim am Sand',
    'Limbach',
    'Wilhermsdorf',
    'Sugenheim',
    'Oberkail',
    'Bescheid',
    'Kempfeld',
    'Osann-Monzel',
    'Neumagen-Dhron',
    'Naurath',
    'Graach',
    'Junkerath',
    'Veldenz',
    'Oberstadtfeld',
    'Schwollen',
    'Freudenburg',
    'Longkamp',
    'Bekond',
    'Horath',
    'Alsdorf',
    'Kell',
    'Reuth',
    'Brietlingen',
    'Appel',
    'Neuendorf',
    'Bad Kleinen',
    'Neukloster',
    'Grabow',
    'Sternberg',
    'Oberhof',
    'Gustavel',
    'Herren Steinfeld',
    'Uelitz',
    'Birkenfeld',
    'Wonfurt',
    'Winterhausen',
    'Sulzthal',
    'Marktbreit',
    'Westheim',
    'Weidhausen',
    'Oberneuses',
    'Mitteleschenbach',
    'Emskirchen',
    'Buchberg',
    'Theilenhofen',
    'Sengenthal',
    'Tiefenbronn',
    'Vestenberg',
    'Alesheim',
    'Wassertrudingen',
    'Pollenfeld',
    'Rothenbach',
    'Karweiler',
    'Ahrbruck',
    'Weitersburg',
    'Bad Ems',
    'Insul',
    'Adenau',
    'Bonefeld',
    'Brucktal',
    'Waldbreitbach',
    'Westerhorn',
    'Haseldorf',
    'Leimbach',
    'Hartenfels',
    'Sondheim',
    'Willmars',
    'Weihenzell',
    'Dietfurt',
    'Wittelshofen',
    'Burghaslach',
    'Denkendorf',
    'Zapfendorf',
    'Belecke',
    'Wittighausen',
    'Abtswind',
    'Schneeberg',
    'Sulzfeld',
    'Untergimpern',
    'Knoringen',
    'Fahrenbach',
    'Oberschefflenz',
    'Lehrensteinsfeld',
    'Rot am See',
    'Adelmannsfelden',
    'Ellenberg',
    'Spiegelberg',
    'Unterreichenbach',
    'Zaisenhausen',
    'Reute',
    'Trebendorf',
    'Laubusch',
    'Gehofen',
    'Niendorf',
    'Roseburg',
    'Haffkrug',
    'Oberweimar',
    'Oberndorf',
    'Wolkenstein',
    'Weyerbusch',
    'Harschbach',
    'Elkenroth',
    'Niederwambach',
    'Waldorf',
    'Katzwinkel',
    'Uess',
    'Treis-Karden',
    'Eichen',
    'Hillscheid',
    'Uersfeld',
    'Nickenich',
    'Dietlingen',
    'Radegast',
    'Kuhndorf',
    'Kleinfahner',
    'Dachwig',
    'Gierstadt',
    'Badra',
    'Woffleben',
    'Schnellmannshausen',
    'Wiesenfeld',
    'Kefferhausen',
    'Rohr',
    'Halle Neustadt',
    'Grosslobichau',
    'Vollersroda',
    'Buchfart',
    'Pohl',
    'Mittelbach',
    'Hormersdorf',
    'Annweiler am Trifels',
    'Friedelsheim',
    'Bischheim',
    'Freinsheim',
    'Albisheim',
    'Salgen',
    'Nieder Ohmen',
    'Boxberg',
    'Himbergen',
    'Rummer',
    'Weste',
    'Grafhorst',
    'Danndorf',
    'Barum',
    'Mitterstetten',
    'Pornbach',
    'Elsendorf',
    'Hergolding',
    'Kumhausen',
    'Treia',
    'Sieverstedt',
    'Fahrdorf',
    'Jorl',
    'Lurschau',
    'Grosssolt',
    'Karlum',
    'Klein Wittensee',
    'Langballig',
    'Schlotheim',
    'Mattstedt',
    'Themar',
    'Sundhausen',
    'Possendorf',
    'Furthen',
    'Bannberscheid',
    'Winningen',
    'Mannebach',
    'Mogendorf',
    'Weibern',
    'Fehl-Ritzhausen',
    'Welkenbach',
    'Niederalsen',
    'Marienrachdorf',
    'Vohringen',
    'Warza',
    'Neuhaus-Schierschnitz',
    'Reinstadt',
    'Trusetal',
    'Neudietendorf',
    'Kirchheim',
    'Goritz',
    'Veilsdorf',
    'Ingersleben',
    'Seck',
    'Thur',
    'Weitefeld',
    'Ebernhahn',
    'Dreisbach',
    'Meinsdorf',
    'Kleinbeeren',
    'Diedorf',
    'Albrechts',
    'Othenstorf',
    'Altenkrempe',
    'Malente',
    'Klein Ronnau',
    'Talkau',
    'Hartenholm',
    'Politz',
    'Merzhausen',
    'Schwarzwald',
    'March',
    'Neu Zauche',
    'Schacksdorf',
    'Kasel-Golzig',
    'Drebkau',
    'Axstedt',
    'Bohlen',
    'Schildau',
    'Hattenhofen',
    'Vilsheim',
    'Niederviehbach',
    'Adlkofen',
    'Weihmichl',
    'Neufraunhofen',
    'Trebsen',
    'Bucha',
    'Doberschutz',
    'Rockenberg',
    'Munzenberg',
    'Schwarzach',
    'Tettenwang',
    'Bartow',
    'Medow',
    'Klein Kedingshagen',
    'Ernsthof',
    'Patzig',
    'Vitte',
    'Gross Miltzow',
    'Murchin',
    'Meesiger',
    'Samtens',
    'Sellin',
    'Kluis',
    'Ostseebad Baabe',
    'Hiddensee',
    'Wackerow',
    'Aspisheim',
    'Oelsberg',
    'Beltheim',
    'Schnellbach',
    'Barstadt',
    'Stauchitz',
    'Panschwitz-Kuckau',
    'Groditz',
    'Hirschstein',
    'Hof',
    'Muhlhausen',
    'Ecklingerode',
    'Seega',
    'Bendeleben',
    'Isserstedt',
    'Tonndorf',
    'Bienstadt',
    'Bleckenrode',
    'Neundorf',
    'Mengersgereuth-Hammern',
    'Hauteroda',
    'Wolfis',
    'Grobzig',
    'Wansleben',
    'Bodelshausen',
    'Bermatingen',
    'Merkers',
    'Westhausen',
    'Viernau',
    'Springstille',
    'Seebach',
    'Rotterode',
    'Heyerode',
    'Hetschburg',
    'Hildebrandshausen',
    'Ammern',
    'Elgersburg',
    'Wohnrod',
    'Heidenau',
    'Hilgermissen',
    'Luttum',
    'Wurmannsquick',
    'Rickenbach',
    'Erfelden',
    'Heiligenhagen',
    'Pruchten',
    'Wittichenau',
    'Rietschen',
    'Tettau',
    'Ortrand',
    'Loch',
    'Reichenbach-Steegen',
    'Becherbach',
    'Schneckenhausen',
    'Ransweiler',
    'Reipoltskirchen',
    'Kindsbach',
    'Hettenrodt',
    'Weidenbach',
    'Niederstadtfeld',
    'Wilferdingen',
    'Schmidgaden',
    'Prackenbach',
    'Alteglofsheim',
    'Hardt',
    'Eutingen',
    'Hermaringen',
    'Nehren',
    'Renquishausen',
    'Unterroth',
    'Heimsheim',
    'Knittlingen',
    'Johstadt',
    'Gechingen',
    'Ramsen',
    'Reichartshausen',
    'Neckarzimmern',
    'Neidenstein',
    'Opfingen',
    'Rechtenstein',
    'Durmentingen',
    'Alleshausen',
    'Ebnat',
    'Steinhausen',
    'Lauterburg',
    'Niederbreitbach',
    'Braunlingen',
    'Rappenau',
    'Frankweiler',
    'Erpolzheim',
    'Botersen',
    'Michelfeld',
    'Emtinghausen',
    'Sandbostel',
    'Schonenberg-Kubelberg',
    'Bad Peterstal-Griesbach',
    'Wittgendorf',
    'Steinigtwolmsdorf',
    'Blievenstorf',
    'Lamspringe',
    'Bad Eilsen',
    'Salzstetten',
    'Stetten',
    'Pastetten',
    'Baierbrunn',
    'Ilmmunster',
    'Jemgum',
    'Terborg',
    'Rechtsupweg',
    'Rhede',
    'Esterwegen',
    'Osterbrock',
    'Emsburen',
    'Graveneck',
    'Frankenau',
    'Linden',
    'Bischoffen',
    'Lahr',
    'Ebeleben',
    'Wildbergerhutte',
    'Weddel',
    'Veltheim',
    'Wriedel',
    'Gerdau',
    'Ribbesbuttel',
    'Vogtareuth',
    'Einsbach',
    'Samerberg',
    'Golzow',
    'Wusterwitz',
    'Kiez',
    'Raben',
    'Wenzlow',
    'Niemegk',
    'Milow',
    'Hausen am Tann',
    'Barenthal',
    'Boich',
    'Mutzenich',
    'Osterhofen',
    'Salzweg',
    'Eggebek',
    'Langenhorn',
    'Gammellund',
    'Ulsnis',
    'Bordelum',
    'Klanxbull',
    'Fargau-Pratjau',
    'Delve',
    'Landrecht',
    'Dietersburg',
    'Hohenthann',
    'Greussenheim',
    'Margetshochheim',
    'Euerbach',
    'Vluyn',
    'Suderau',
    'Volkersweiler',
    'Esthal',
    'Bockenheim',
    'Kirchtimke',
    'Neverin',
    'Driftsethe',
    'Wremen',
    'Ueterlande',
    'Bramstedt',
    'Midlum',
    'Mulsum',
    'Tapfheim',
    'Obermeitingen',
    'Ahrensmoor',
    'Albersdorf',
    'Martensrade',
    'Riepsdorf',
    'Selent',
    'Kellenhusen',
    'Loose',
    'Gross Wittensee',
    'Nordhastedt',
    'Wester-Ohrstedt',
    'Sehlen',
    'Tating',
    'Breiholz',
    'Susel',
    'Wesseln',
    'Krumstedt',
    'Satjendorf',
    'Barkelsby',
    'Lohe-Rickelshof',
    'Otterndorf',
    'Belum',
    'Schopfloch',
    'Glatten',
    'Strassberg',
    'Bischofsgrun',
    'Schlammersdorf',
    'Barnau',
    'Glashutten',
    'Fichtenwalde',
    'Ribbeck',
    'Babow',
    'Brieske',
    'Hennweiler',
    'Flacht',
    'Birlenbach',
    'Bergweiler',
    'Dausenau',
    'Liebenscheid',
    'Bach',
    'Hattert',
    'Wieselbach',
    'Lochum',
    'Hochstenbach',
    'Sankt Katharinen',
    'Niederburg',
    'Erpel',
    'Dattenberg',
    'Wincheringen',
    'Riol',
    'Hof',
    'Hohn',
    'Norken',
    'Hellenhahn-Schellenberg',
    'Gutenacker',
    'Forst',
    'Steinmauern',
    'Oberriexingen',
    'Rohrdorf',
    'Borstel',
    'Beckeln',
    'Jeddeloh Eins',
    'Marxen',
    'Tangendorf',
    'Bargstedt',
    'Wilstedt',
    'Elmlohe',
    'Gyhum',
    'Nortmoor',
    'Augustfehn',
    'Molbergen',
    'Neukamperfehn',
    'Westrhauderfehn',
    'Filsum',
    'Heinbockel',
    'Horneburg',
    'Hepstedt',
    'Heeslingen',
    'Lorup',
    'Hasel',
    'Breitenfelde',
    'Nusse',
    'Schiphorst',
    'Labenz',
    'Klein Wesenberg',
    'Bliesdorf',
    'Schulendorf',
    'Kastorf',
    'Duchelsdorf',
    'Lasbek',
    'Kirchheim am Ries',
    'Dischingen',
    'Eging',
    'Hilgert',
    'Helmern',
    'Markt',
    'Norddeich',
    'Juliusburg',
    'Wilster',
    'Schenefeld',
    'Brande-Hornerkirchen',
    'Tharandt',
    'Netzschkau',
    'Marwitz',
    'Arft',
    'Pomster',
    'Herresbach',
    'Ebertsheim',
    'Niederding',
    'Neustadt',
    'Schloss',
    'Weidenhof',
    'Karin',
    'Kreuz',
    'Vegesack',
    'Beaumarais',
    'Klostersee',
    'Ruit',
    'Balgheim',
    'Degerloch',
    'Westeregeln',
    'Plattenburg',
    'Ziegendorf',
    'Osternienburg',
    'Possendorf',
    'Kitzscher',
    'Freiwalde',
    'Dahme',
    'Lindenau',
    'Mittelsaida',
    'Ritschenhausen',
    'Buttelstedt',
    'Rettersen',
    'Blomesche Wildnis',
    'Nortorf',
    'Hollingstedt',
    'Duingen',
    'Barghorst',
    'Simmersfeld',
    'Tennenbronn',
    'Moos',
    'Ober-Erlenbach',
    'Hohenfelde',
    'Kirchweyhe',
    'Poggensee',
    'Wanne-Eickel',
    'Neuhardenberg',
    'Wettenberg',
    'Hemmelzen',
    'Lochhausen',
    'Milbertshofen',
    'Laussnitz',
    'Osede',
    'Rieste',
    'Rugheim',
    'Weil',
    'Weissenfeld',
    'Hopferau',
    'Stetten',
    'Lutzerath',
    'Filzen',
    'Oberporlitz',
    'Lindigshof',
    'Kammerforst',
    'In der Meer',
    'Giesenkirchen',
    'Sulzgries',
    'Hochdorf',
    'Altingen',
    'Barbing',
    'Scheyern',
    'Waffenbrunn',
    'Ihrlerstein',
    'Krumhermersdorf',
    'Hellweg',
    'Felm',
    'Marienfeld',
    'Hohenhorn',
    'Osdorf',
    'Holtsee',
    'Nonnenhorn',
    'Oberpleichfeld',
    'Pleisweiler-Oberhofen',
    'Anrath',
    'Wienhausen',
    'Altersbach',
    'Salmtal',
    'Wolsendorf',
    'Roetz',
    'Bad Gogging',
    'Frauenau',
    'Waldmunchen',
    'Lupburg',
    'Kleinwolmsdorf',
    'Stadt Wehlen',
    'Wisper',
    'Niederneisen',
    'Mudershausen',
    'Niederbrechen',
    'Hahnstatten',
    'Zorge',
    'Sankt Andreasberg',
    'Betzendorf',
    'Hohnstorf',
    'Prebitz',
    'Gattendorf',
    'Eschenbach',
    'Caputh',
    'Ellefeld',
    'Haaren',
    'Golssen',
    'Kraupa',
    'Hohenleipisch',
    'Schlieben',
    'Lebusa',
    'Crinitz',
    'Breddorf',
    'Puchersreuth',
    'Edelsfeld',
    'Meerbeck',
    'Zarrendorf',
    'Pantelitz',
    'Bannemin',
    'Gahlkow',
    'Molschow',
    'Greppin',
    'Schmerz',
    'Niederselters',
    'Wohra',
    'Amoneburg',
    'Eibelshausen',
    'Scherenbostel',
    'Gangkofen',
    'Bayerbach',
    'Windorf',
    'Furstenstein',
    'Niederalteich',
    'Brennberg',
    'Niederwinkling',
    'Kinsau',
    'Anzing',
    'Berzdorf',
    'Paunzhausen',
    'Hohenbocka',
    'Benningen',
    'Kraftisried',
    'Neckarwestheim',
    'Hohenfelde',
    'Wohrden',
    'Windeby',
    'Petersdorf',
    'Langwitz',
    'Mollenhagen',
    'Peetsch',
    'Schossin',
    'Fischingen',
    'Heuweiler',
    'Schackendorf',
    'Neversdorf',
    'Kukels',
    'Wittenborn',
    'Hobbersdorf',
    'Lindhorst',
    'Drubeck',
    'Derenburg',
    'Loderburg',
    'Wienrode',
    'Harmsdorf',
    'Klein-Schlamin',
    'Wotersen',
    'Bentfeld',
    'Unterammergau',
    'Wiesenbach',
    'Pickliessem',
    'Krettnach',
    'Densborn',
    'Neuschonau',
    'Lemberg',
    'Neuried',
    'Dabendorf',
    'Stocksee',
    'Grosshabersdorf',
    'Eggolsheim',
    'Oberpleis',
    'Effeltrich',
    'Poxdorf',
    'Frankisch-Crumbach',
    'Konzell',
    'Simbach',
    'Bobrach',
    'Rattenberg',
    'Geiersthal',
    'Wittenbeck',
    'Roggentin',
    'Reddelich',
    'Neu Kaliss',
    'Boldela',
    'Lubesse',
    'Katlenburg-Lindau',
    'Mielenhausen',
    'Scheden',
    'Fredelsloh',
    'Neckarrems',
    'Kleinsteinhausen',
    'Wahnwegen',
    'Niederalben',
    'Perkam',
    'Aholfing',
    'Deggenhausen',
    'Hagnau',
    'Wollbrandshausen',
    'Kirchdorf am Inn',
    'Aicha vorm Wald',
    'Schuttertal',
    'Weisenbach',
    'Fronhofen',
    'Kestert',
    'Zornheim',
    'Weitersborn',
    'Hungenroth',
    'Lipporn',
    'Wiebelsheim',
    'Ruchsen',
    'Habach',
    'Hemmersdorf',
    'Trulben',
    'Rohrbach',
    'Wustweiler',
    'Warnow',
    'Franzenheim',
    'Sellerich',
    'Dackscheid',
    'Klotten',
    'Aach',
    'Oberpierscheid',
    'Oberweis',
    'Kalenborn-Scheuern',
    'Bleialf',
    'Harburg',
    'Laugna',
    'NuÃŸdorf (Chiemgau)',
    'Oberndorf',
    'Halvesbostel',
    'Embsen',
    'Oberstreu',
    'Neuhutten',
    'Ettringen',
    'Stotten',
    'Lamerdingen',
    'Bad Wilsnack',
    'Hohen Wangelin',
    'Vollrathsruhe',
    'Alt Schwerin',
    'Liepgarten',
    'Satzvey',
    'Tinnum',
    'Rabenkirchen-Fauluck',
    'Grundhof',
    'Meyn',
    'Medelby',
    'Lindenthal',
    'Ipsheim',
    'Wittnau',
    'Wasenweiler',
    'Bernau',
    'Zell im Wiesental',
    'Kirchheilingen',
    'Creuzburg',
    'Schwarzbach',
    'Oelzschau',
    'Wermsdorf',
    'Espenhain',
    'Kossa',
    'Otterwisch',
    'Osterberg',
    'Sontheim',
    'Bodolz',
    'Wolpinghausen',
    'Schlepzig',
    'Zerre',
    'Fichtenberg',
    'Paulinenaue',
    'Friesack',
    'Dessow',
    'Kirschau',
    'Gnaschwitz',
    'Schlegel',
    'Aldekerk',
    'Emmerzhausen',
    'Dungenheim',
    'Siershahn',
    'Oberlahr',
    'Sorth',
    'Oberfell',
    'Bell',
    'Neitersen',
    'Freisbach',
    'Binau',
    'Bornheim',
    'Mertendorf',
    'Reichardtswerben',
    'Wolferode',
    'Farnstadt',
    'Riedbach',
    'Unterschleichach',
    'Riedbach',
    'Uchtelhausen',
    'Prichsenstadt',
    'Allmendingen',
    'Tomerdingen',
    'Titting',
    'Teugn',
    'Hornburg',
    'Melchow',
    'Ivenack',
    'Stockstadt am Rhein',
    'Burgschwalbach',
    'Immenreuth',
    'Neudrossenfeld',
    'Romrod',
    'Breitenbach am Herzberg',
    'Heubach',
    'Furstenberg',
    'Sauensiek',
    'Twielenfleth',
    'Hollern',
    'Beckdorf',
    'Oring',
    'Unterhausen',
    'Oberhausen',
    'Alten',
    'Raschau',
    'Brekendorf',
    'Schwesing',
    'Gutach (Schwarzwaldbahn)',
    'Zuzenhausen',
    'Rechberg',
    'Argenthal',
    'Vendersheim',
    'Lorscheid',
    'Nachtsheim',
    'Auerbach',
    'Angern',
    'Karstadt',
    'Neukirchen',
    'Redefin',
    'Zapel',
    'Bollstein',
    'Romsthal',
    'Dibbersen',
    'Jheringsfehn',
    'Thuine',
    'Dersum',
    'Breddenberg',
    'Walchum',
    'Klein Meckelsen',
    'Habenhausen',
    'Wallendorf',
    'Huffelsheim',
    'Biesingen',
    'Feilbingert',
    'Traisen',
    'Meudt',
    'Wiesbach',
    'Armsheim',
    'Keidelheim',
    'Mutterschied',
    'Theley',
    'Bad Klosterlausnitz',
    'Bann',
    'Gries',
    'Petersberg',
    'Kerzenheim',
    'Merkelbach',
    'Rotenhain',
    'Guckheim',
    'Urexweiler',
    'Bliesmengen-Bolchen',
    'Barbelroth',
    'Obernheim-Kirchenarnbach',
    'Hahnenbach',
    'Munchweiler am Klingbach',
    'Rheinzabern',
    'Gommersheim',
    'Geinsheim',
    'Hargesheim',
    'Kaden',
    'Kerlingen',
    'Jugenheim',
    'Hackenheim',
    'Pretzfeld',
    'Arberg',
    'Flomborn',
    'Eyendorf',
    'Frommern',
    'Reimlingen',
    'Rimhorn',
    'Sereetz',
    'Schongeising',
    'Schenkenzell',
    'Gammelshausen',
    'Holzmaden',
    'Mittelrot',
    'Gurtweil',
    'Lichtenbroich',
    'Deutz',
    'Durbheim',
    'Wilhelmsdorf',
    'Koditz',
    'Berg',
    'Hetzenhausen',
    'Salching',
    'Vilshoven',
    'Altstadt',
    'Gutow',
    'Ebendorf',
    'Insel',
    'Redekin',
    'Born',
    'Boddin',
    'Dahlenwarsleben',
    'Grebbin',
    'Boden',
    'Zimmern',
    'Bothkamp',
    'Grossmonra',
    'Ostramondra',
    'Blankenhain',
    'Graitschen',
    'Kalkreuth',
    'Blankenloch',
    'Dormettingen',
    'Lauterbach/Schwarzwald',
    'Dobel',
    'Weilheim an der Teck',
    'Neidlingen',
    'Teisnach',
    'Gebenbach',
    'Schonthal',
    'Knittelsheim',
    'Platkow',
    'Falkenfels',
    'Birlinghoven',
    'Luft',
    'Seehof',
    'Dotternhausen',
    'Ottenbach',
    'Altdorf',
    'Eisenbach',
    'Ohlsbach',
    'Apfelstadt',
    'Clausthal',
    'Kottweiler-Schwanden',
    'Temmels',
    'Friemar',
    'Schonenberg',
    'Radibor',
    'Zschortau',
    'Grafenthal',
    'Wulfen',
    'Goggingen',
    'Dipperz',
    'Golm',
    'Langenbach',
    'Schadeleben',
    'Alperstedt',
    'Gossel',
    'Flurstedt',
    'Behringen',
    'Liebenstein',
    'Prittriching',
    'Hard',
    'Malsfeld',
    'Bermbach',
    'Wald',
    'Lippe',
    'Joachimsthal',
    'Oderberg',
    'Wiederau',
    'Kayna',
    'Weidenhahn',
    'Niederfell',
    'Eiselfing',
    'Mintraching',
    'Kotzting',
    'Ensdorf',
    'Heiligenthal',
    'Friedrichsort',
    'Langlingen',
    'Dorf',
    'Niedersachswerfen',
    'Einhausen',
    'Neehausen',
    'Holzengel',
    'Kasbach-Ohlenberg',
    'Henfenfeld',
    'Bobbau',
    'Muldenhammer',
    'Murg',
    'Wintersdorf',
    'Bechhofen',
    'Harlachen',
    'Rugendorf',
    'Wacken',
    'Rabke',
    'Ballstadt',
    'Wernersberg',
    'Inzmuhlen',
    'Wilhelmsheim',
    'Krolpa',
    'Wehrbleck',
    'Ellerhoop',
    'Herzogsweiler',
    'Somborn',
    'Schmidt',
    'Oelsen',
    'Petriroda',
    'Hohenroth',
    'Ramsthal',
    'Salz',
    'Mittelberg',
    'Altenmarkt',
    'Stein an der Traun',
    'Weilersbach',
    'Flintsbach',
    'Nussdorf am Inn',
    'Krun',
    'Jettenbach',
    'Reichersbeuern',
    'Trunkelsberg',
    'Hawangen',
    'Reichertshausen',
    'Mitterfelden',
    'Werpeloh',
    'Klein Berssen',
    'Fresenburg',
    'Rastdorf',
    'Lintig',
    'Keller',
    'Gulzowshof',
    'Malsburg',
    'Buborn',
    'Lollbach',
    'Herren-Sulzbach',
    'Abtweiler',
    'Beetzendorf',
    'Vorstetten',
    'Ludersdorf',
    'Dittelsheim-Hessloch',
    'Preith',
    'Jagel',
    'Hartheim',
    'Eisenbach',
    'Schuller',
    'Heilingen',
    'Neuhemsbach',
    'Sack',
    'Plan',
    'Sudheide',
    'Ludersfeld',
    'Neuengors',
    'Volxheim',
    'Sersheim',
    'Oberstadion',
    'Korchow',
    'Traubing',
    'Reichenschwand',
    'Krautheim',
    'Waldenburg',
    'Steudnitz',
    'Seelitz',
    'Kastel',
    'Lutter am Barenberge',
    'Heidekamp',
    'Trimbs',
    'Schwarzenbach am Wald',
    'Schwarzenbach',
    'Runthe',
    'Oberottmarshausen',
    'Mittelstetten',
    'Gundremmingen',
    'Schwabniederhofen',
    'Tussenhausen',
    'Dittenheim',
    'Oberlaindern',
    'Arzbach',
    'Truchtlaching',
    'Garching an der Alz',
    'Biessenhofen',
    'Mauerstetten',
    'Westheim',
    'Elfershausen',
    'Gerach',
    'Untersteinach',
    'Marktrodach',
    'Hesselbach',
    'Altenkunstadt',
    'Schney',
    'Schwebheim',
    'Ebern',
    'Dorfles',
    'Grossheirath',
    'Kirchendemenreuth',
    'Schirmitz',
    'Pirk',
    'Eisingen',
    'Heinersreuth',
    'Bellenberg',
    'Grossmehring',
    'Lautertal',
    'Wildpoldsried',
    'Marxgrun',
    'Schnee',
    'Stahlhofen',
    'Grunenthal',
    'Pressen',
    'Isenburg',
    'Vorbach',
    'Heeg',
    'Vorwerk',
    'Bellenberg',
    'Wachsenburg',
    'Rechtmehring',
    'Jagerhof',
    'Dierkow',
    'Weissenstadt',
    'Sierksdorf',
    'Seehaus',
    'Berlingerode',
    'Linden',
    'Welle',
    'Theissen',
    'Kuhn',
    'Blechhammer',
    'Wasser',
    'Weisel',
    'Gutenberg',
    'Bergbau',
    'Halberg',
    'Gossen',
    'Konigstein',
    'Karlsbad',
    'Dohle',
    'Gehlenbeck',
    'Blume',
    'Kraft',
    'Hohenroda',
    'Rasselstein',
    'Wichte',
    'Kleinsendelbach',
    'Klink',
    'Ostseebad Sellin',
    'Benz',
    'Klinge',
    'Cranz',
    'Ostseebad Ahrenshoop',
    'Autenzell',
    'Schutz',
    'Waschenbach',
    'Buhler',
    'Schillingshof',
    'Lerbach',
    'Ludwigshafen',
    'Wieske',
    'Feldkirchen',
    'Roes',
    'Wiesbach',
    'Buschhausen',
    'Karl',
    'Ostseebad Dierhagen',
    'Seelscheid',
    'Blitzenreute',
    'Langenenslingen',
    'Thulba',
    'Diedersdorf',
    'Oberberg',
    'Todtenried',
    'Lawalde',
    'Erlach',
    'Hallnberg',
    'Eisenbach',
    'Schlossberg',
    'Buhlerhohe',
    'Gleisweiler',
    'Reichenbach',
    'Burgerhof',
    'Zirkow',
    'Gevensleben',
    'Wolsdorf',
    'Hochstrasse',
    'Albeck',
    'Heimbach',
    'Neuburg',
    'Feldrennach',
    'Oberreichenbach',
    'Engelsburg',
    'Grunkraut',
    'Lichtenwald',
    'Otterbach',
    'Oppendorf',
    'Mohren',
    'Werste',
    'Zeiskam',
    'Bach an der Donau',
    'Steinfeld',
    'Blankensee',
    'Dorth',
    'Friedensau',
    'Buschbell',
    'Mailing',
    'Huhnerhof',
    'Munchhausen',
    'Lutz',
    'Maasholm',
    'Laasdorf',
    'Redwitz an der Rodach',
    'Eckartsberg',
    'Osterfeld',
    'Rackith',
    'Lebus',
    'Tauche',
    'Tantow',
    'Werben',
    'Bruch',
    'Abbensen',
    'Weenzen',
    'Ostenfeld',
    'Osterstedt',
    'Landscheide',
    'Jahnshof',
    'Margarethen',
    'Saint Michaelisdonn',
    'Tarbek',
    'Schafstedt',
    'Massenhausen',
    'Aying',
    'Maitenbeth',
    'Giggenhausen',
    'Kapellen-Drusweiler',
    'Lagesbuttel',
    'Geiselwind',
    'Ebrach',
    'Burglauer',
    'Thungen',
    'Rumeltshausen',
    'Esgrus',
    'Sterup',
    'Neckarweihingen',
    'Tailfingen',
    'Schopfloch',
    'Walting',
    'Gerhardshofen',
    'Trautskirchen',
    'Lettenreuth',
    'Scherstetten',
    'Lohnsfeld',
    'Schauerberg',
    'Zuffenhausen',
    'Liebenwalde',
    'Tarmow',
    'Waldlaubersheim',
    'Niederheimbach',
    'Holzbach',
    'Kamp-Bornhofen',
    'Niederlindhart',
    'Elmpt',
    'Windberg',
    'Listerfehrda',
    'Wasungen',
    'Insheim',
    'Hochstadt',
    'Boffzen',
    'Wolfsheim',
    'Lautern',
    'Nebringen',
    'Egesheim',
    'Woltershausen',
    'Wietzendorf',
    'Wenzingen',
    'Pilsach',
    'Kirchhaslach',
    'Lengthal',
    'Adelsried',
    'Ehrenberg',
    'Floss',
    'Trogen',
    'Etzelwang',
    'Issigau',
    'Neusorg',
    'Reitsch',
    'Kulz',
    'Brand',
    'Tiefenbach',
    'Gablenz',
    'Uebigau',
    'Schleife',
    'Schweizerhof',
    'Aschau',
    'Wittenberg',
    'Lechbruck',
    'Querum',
    'Riding',
    'Rothof',
    'Wassersleben',
    'Steglitz Bezirk',
    'Grunhof',
    'Ober-Roden',
    'Birnbach',
    'Stiege',
    'Schlanstedt',
    'Freudenberg',
    'Weidenthal',
    'Meckenheim',
    'Ruppertsberg',
    'Edesheim',
    'Urmitz',
    'Saffig',
    'Helmenzen',
    'Breitscheidt',
    'Ehlscheid',
    'Weinahr',
    'Wilhelmsdorf',
    'Egloffstein',
    'Ochtmersleben',
    'Rosenburg',
    'Zeppernick',
    'Gernrode',
    'Sandberg',
    'Worth am Main',
    'Neuendorf',
    'Benzingerode',
    'Morsleben',
    'Vahldorf',
    'Hotensleben',
    'Arrach',
    'Leukersdorf',
    'Heiligengrabe',
    'Klausdorf',
    'Honebach',
    'Plodda',
    'Krumpa',
    'Stangerode',
    'Tilleda',
    'Tiefenthal',
    'Rorichum',
    'Pewsum',
    'Dalum',
    'Heiligenstadt',
    'Mainbernheim',
    'Riedenheim',
    'Kist',
    'Mittelstreu',
    'Aub',
    'Dietenhofen',
    'Langenfeld',
    'Baudenbach',
    'Spechtsbrunn',
    'Niedereisenhausen',
    'Dauborn',
    'Kirrweiler',
    'Glindenberg',
    'Veckenstedt',
    'Wasserleben',
    'Garz',
    'Behrenhoff',
    'Katzow',
    'Ostseebad Gohren',
    'Kroslin',
    'Gohren',
    'Seebad Heringsdorf',
    'Dranske',
    'Hinrichshagen',
    'Altenkirchen',
    'Gross Kiesow',
    'Lassan',
    'Zussow',
    'Hochwang',
    'Duggendorf',
    'Weigendorf',
    'Joehlingen',
    'Ittersbach',
    'Rosdorf',
    'Wrist',
    'Grosskorbetha',
    'Ruscheid',
    'Stein-Neukirch',
    'Rieden',
    'Buchel',
    'Thalhausen',
    'Oberhonnefeld-Gierend',
    'Weitzschen',
    'Kohra',
    'Hohenfinow',
    'Spreenhagen',
    'Freudenberg',
    'Ziltendorf',
    'Arnsdorf',
    'Dintesheim',
    'Ludwigshohe',
    'Dahnsdorf',
    'Oberporing',
    'Drachselsried',
    'Aufhausen',
    'Niedermurach',
    'Roggenburg',
    'Veringendorf',
    'Konigsheim',
    'Marquartstein',
    'Marsdorf',
    'Muhle',
    'Beucha',
    'Mockrehna',
    'Knobelsdorf',
    'Rotha',
    'Fahrenbach',
    'Strahlungen',
    'Dambeck',
    'Quitzow',
    'Adenbuettel',
    'Schollnach',
    'Gulzow',
    'Parum',
    'Ahrenshagen',
    'Biburg',
    'Oberschweinbach',
    'Hayingen',
    'Bulowerburg',
    'Lohberg',
    'Kapsweyer',
    'Rositz',
    'Schlehdorf',
    'Tieringen',
    'Weixerau',
    'Kriebitzsch',
    'Straussfurt',
    'Gerbershausen',
    'Kieselbach',
    'Geisenhain',
    'Oberbodnitz',
    'Callbach',
    'Dankenfeld',
    'Schammelsdorf',
    'Nieheim',
    'Bruchhausen',
    'Eichen',
    'Haswede',
    'Bonstetten',
    'Beetzsee',
    'Hoppegarten',
    'Breitenworbis',
    'Unterdiessen',
    'Stottwang',
    'Obertaufkirchen',
    'Niederaichbach',
    'Aichtal',
    'Dunzweiler',
    'Weselberg',
    'Holler',
    'Heuchlingen',
    'Lonsingen',
    'Munsterdorf',
    'Alt Bukow',
    'Winsen',
    'Trassem',
    'Engelshof',
    'Winterbach',
    'Neidenfels',
    'Kemerting',
    'Aurachtal',
    'Frankenwinheim',
    'Armstorf',
  ],
  tld: ['.de'],
  cca2: 'DE',
  ccn3: '276',
  cca3: 'DEU',
  cioc: 'GER',
  currency: ['EUR'],
  callingCode: ['49'],
  capital: 'Berlin',
  altSpellings: [
    'DE',
    'Federal Republic of Germany',
    'Bundesrepublik Deutschland',
  ],
  region: 'Europe',
  subregion: 'Western Europe',
  languages: { deu: 'German' },
  translations: {
    deu: { official: 'Bundesrepublik Deutschland', common: 'Deutschland' },
    fra: { official: "République fédérale d'Allemagne", common: 'Allemagne' },
    hrv: { official: 'Njemačka Federativna Republika', common: 'Njemačka' },
    ita: { official: 'Repubblica federale di Germania', common: 'Germania' },
    jpn: { official: 'ドイツ連邦共和国', common: 'ドイツ' },
    nld: { official: 'Bondsrepubliek Duitsland', common: 'Duitsland' },
    por: { official: 'República Federal da Alemanha', common: 'Alemanha' },
    rus: { official: 'Федеративная Республика Германия', common: 'Германия' },
    spa: { official: 'República Federal de Alemania', common: 'Alemania' },
    fin: { official: 'Saksan liittotasavalta', common: 'Saksa' },
  },
  latlng: [51, 9],
  demonym: 'German',
  landlocked: false,
  borders: ['AUT', 'BEL', 'CZE', 'DNK', 'FRA', 'LUX', 'NLD', 'POL', 'CHE'],
  area: 357114,
  provinces: [
    'Baden-Wuerttemberg',
    'Bayern',
    'Berlin',
    'Brandenburg',
    'Bremen',
    'Hamburg',
    'Hessen',
    'Mecklenburg-Vorpommern',
    'Niedersachsen',
    'Nordrhein-Westfalen',
    'Rheinland-Pfalz',
    'Saarland',
    'Sachsen',
    'Sachsen-Anhalt',
    'Schleswig-Holstein',
    'Thueringen',
  ],
  nativeName: 'Deutschland',
  timezones: ['UTC+01:00'],
} as const;
