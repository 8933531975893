import { Report } from '@models';
import { evaluateNistTest, parseObjectiveData } from '@utils/nist-grading';
import { nistGradingCriteria } from '@values/nist-grading-criteria';
import { store } from '@store';
import { Getter } from '@vuex/class';

import {
  formatReportDistance,
  formatReportSpeed,
  formatReportDate,
} from './format';

/** ... */
const NIST_TEST_MODULES = nistGradingCriteria.NIST_TEST_MODULES;

/**
 * Parse a full report.
 *
 * @param report ...
 * @return ...
 */
export function parseFullReport(report: Report) {
  const averageAltitude = formatReportDistance(report.averageAltitude);
  const averageSpeed = formatReportSpeed(report.averageSpeed);
  const maxAltitude = formatReportDistance(report.maxAltitude);
  const topSpeed = formatReportSpeed(report.topSpeed);

  // grab user's email. we no longer get it natively with reports
  const userEmail = store.state.users.items.find(
    ({ id }) => id === report.completedBy.id,
  )?.email;

  const user = {
    firstName: report.completedBy?.firstName
      ? report.completedBy.firstName
      : '--',
    lastName: report.completedBy?.lastName ? report.completedBy.lastName : '--',
    email: userEmail ? userEmail : '--',
  };

  const violations = {
    altitude: report.altitudeViolations,
    lineOfSight: report.lostLineOfSightViolations,
  };

  const scorePercent =
    ((report.score / report.possibleScore) * 100).toString() + '%';

  const date = formatReportDate(report.createdAt);
  const startTime = formatReportDate(report.moduleStartTime);
  const endTime = formatReportDate(report.moduleCompleted);

  const objectiveData = report.module.objectiveData?.length
    ? report.module.objectiveData.map((od) => parseObjectiveData(od))
    : null;

  /** ... */
  const moduleId = report.module.id;

  /** ... */
  const isNistTest =
    moduleId && moduleId in NIST_TEST_MODULES
      ? NIST_TEST_MODULES[
          moduleId as unknown as nistGradingCriteria.Modules.Key
        ]
      : null;

  const nistTestResults =
    isNistTest && objectiveData
      ? evaluateNistTest(
          objectiveData,
          isNistTest.type,
          isNistTest.feet,
          report.id,
        )
      : null;

  const { requiresHumanGrading } = report.module;

  const droneName = `${report.drone.manufacturer} | ${report.drone.model}`;

  const reportDisplayInfo: ZephyrWeb.ReportDisplayInfo = {
    user,
    courseName: report.course?.name ?? '--',
    organizationName: report.organization?.name ?? '--',
    droneName,
    date,
    sceneName: report.scene.name,
    sceneId: report.scene.id,
    moduleName: report.module.name,
    moduleId: report.module.id,
    score: report.score,
    possibleScore: report.possibleScore,
    scorePercent: requiresHumanGrading ? 'N/A' : scorePercent,
    grade: requiresHumanGrading ? 'N/A' : report.grade,
    resetCount: report.resetCount,
    crashCount: report.crashCount,
    completed: report.completed,
    startTime,
    endTime,
    totalTime: report.totalTime,
    // droneName: report.droneName,
    averageAltitude,
    averageSpeed,
    maxAltitude,
    topSpeed,
    violations,
    objectiveData: objectiveData ?? null,
    requiresHumanGrading,
    nistTestResults,
  };

  return reportDisplayInfo;
}
