var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.info
    ? _c(
        "span",
        {
          class: [
            "verification-status-indicator",
            { compact: _vm.compact, hoverable: _vm.hoverable },
          ],
          style: { backgroundColor: _setup.info.color },
          attrs: { id: _setup.target },
        },
        [
          _c("Icon", { attrs: { icon: _setup.info.icon } }),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_setup.info.text))]),
          _vm._v(" "),
          _c(
            _setup.PopoverVerificationInfo,
            _vm._b(
              {},
              "PopoverVerificationInfo",
              { target: _setup.target, status: _vm.status },
              false
            )
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }