import { Vue } from '@vue';
import { camelCase } from 'change-case';

import { isObject } from '@tools/type-guards';
import { importContext } from '@utils/import-context';

/** Required context for local Vue service modules. */
// const context = require.context('.', true, /\.ts$/);
const context = require.context('.', true, /\.\/[\w-]+(\.ts|\/index\.ts)$/);

importContext(context, ({ name, value }) => {
  if (name === './index.ts') return;

  const serviceConfig = !isObject(value) ? value : value.default ?? value;
  const serviceName = getServiceName(name);

  Object.assign(Vue.prototype, { [serviceName]: serviceConfig });
});

//#region Helper Functions

/**
 * ...
 *
 * @param fileName ...
 * @return ...
 */
function getServiceName(fileName: string) {
  const name = /index.ts$/.test(fileName)
    ? /\/(.*?)\//.exec(fileName)?.[1]
    : fileName.replace(/(\.\/|\.ts$)/g, '');

  if (!name) {
    throw new Error(
      `[getServiceName] the parsed name for file ${fileName} was invalid.`,
    );
  }

  return ('$' + camelCase(name)) as keyof Vue;
}

//#endregion Helper Functions
