import { Module } from '@vuex/decorators';
import { TablePanel } from 'table-panel';

// import { faEye } from '@icons/solid/faEye';
import { faHand } from '@icons/solid/faHand';

import { modals } from '@modals';
import { Certification } from '@models';
import { computeDisplayName } from '@utils/user';
import { RoleId } from '@values/roles';

/** ... */
const columns: TablePanel.Column<Certification>[] = [
  {
    key: 'productName',
    label: 'Product Name',
    value: ({ product }) => product.name,
  },
  {
    key: 'createdAt',
    label: 'Created',
    value: 'createdAt',
    type: 'date',
  },
  {
    key: 'status',
    label: 'Status',
    value: 'status',
    component: 'TableCellCertificationStatus',
  },
  {
    key: 'lastAction',
    label: 'Last Action',
    component: 'TableCellCertificationLastAction',
    value: (item) => {
      return item.approvedAt ?? item.reattemptPromptAt ?? item.deniedAt ?? null;
    },
  },
  {
    key: 'user',
    label: 'User',
    value: ({ user }) => computeDisplayName(user),
  },
];

/** ... */
const operations: TablePanel.Row.Operation<Certification>[] = [
  {
    label: 'Claim',
    icon: faHand,
    fn: ({ id }) => {
      void modals.certifications.reviewAttempt({ certificationId: id });
    },
    hidden: ({ status }) => {
      return status !== 'AWAITING_REVIEW';
    },
  },
];

@Module({ namespaced: true })
export class ReviewableCertificationsTablePanel
  extends TablePanel<Certification>
  implements TablePanel.Props<Certification>
{
  readonly module = 'certifications';
  readonly loadAction = 'listReviewable';
  readonly pk = 'id';
  readonly label = 'REVIEWABLE CERTIFICATIONS';
  readonly columns = columns;
  readonly operations = operations;
  readonly tableSortType = 'createdAt';
  readonly tableSortReverse = true;

  get enabled() {
    return this.isActiveRole(RoleId.Reviewer, RoleId.LasAdmin);
  }

  get items() {
    // Override base `items` getter to only include certifications from store
    // that are reviewable.
    return this.storeModule.items.filter(({ reviewer, status }) => {
      return (
        reviewer?.id !== this.activeUser.id && status === 'AWAITING_REVIEW'
      );
    });
  }
}
