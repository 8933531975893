export const BHR = {
  name: {
    common: 'Bahrain',
    official: 'Kingdom of Bahrain',
    native: { ara: { official: 'مملكة البحرين', common: '‏البحرين' } },
  },
  cities: [
    'Manama',
    'Barbar',
    'Al Muharraq',
    'Madinat Hamad',
    'Al Janabiyah',
    'Al Markh',
    'Al Budayyi`',
    'Bani Jamrah',
    'Sanad',
    'Madinat `Isa',
    'Al Hadd',
    'Al Hamalah',
    'Sitrah',
    'Tubli',
    'Jurdab',
    'Oil City',
    'Sanabis',
  ],
  tld: ['.bh'],
  cca2: 'BH',
  ccn3: '048',
  cca3: 'BHR',
  cioc: 'BRN',
  currency: ['BHD'],
  callingCode: ['973'],
  capital: 'Manama',
  altSpellings: ['BH', 'Kingdom of Bahrain', 'Mamlakat al-Baḥrayn'],
  region: 'Asia',
  subregion: 'Western Asia',
  languages: { ara: 'Arabic' },
  translations: {
    cym: { official: 'Kingdom of Bahrain', common: 'Bahrain' },
    deu: { official: 'Königreich Bahrain', common: 'Bahrain' },
    fra: { official: 'Royaume de Bahreïn', common: 'Bahreïn' },
    hrv: { official: 'Kraljevina Bahrein', common: 'Bahrein' },
    ita: { official: 'Regno del Bahrain', common: 'Bahrein' },
    jpn: { official: 'バーレーン王国', common: 'バーレーン' },
    nld: { official: 'Koninkrijk Bahrein', common: 'Bahrein' },
    por: { official: 'Reino do Bahrein', common: 'Bahrein' },
    rus: { official: 'Королевство Бахрейн', common: 'Бахрейн' },
    spa: { official: 'Reino de Bahrein', common: 'Bahrein' },
    fin: { official: 'Bahrainin kuningaskunta', common: 'Bahrain' },
  },
  latlng: [26, 50.55],
  demonym: 'Bahraini',
  landlocked: false,
  borders: [],
  area: 765,
} as const;
