export const CPV = {
  name: {
    common: 'Cape Verde',
    official: 'Republic of Cabo Verde',
    native: {
      por: { official: 'República de Cabo Verde', common: 'Cabo Verde' },
    },
  },
  cities: ['Praia'],
  tld: ['.cv'],
  cca2: 'CV',
  ccn3: '132',
  cca3: 'CPV',
  cioc: 'CPV',
  currency: ['CVE'],
  callingCode: ['238'],
  capital: 'Praia',
  altSpellings: ['CV', 'Republic of Cabo Verde', 'República de Cabo Verde'],
  region: 'Africa',
  subregion: 'Western Africa',
  languages: { por: 'Portuguese' },
  translations: {
    cym: { official: 'Republic of Cabo Verde', common: 'Cape Verde' },
    deu: { official: 'Republik Cabo Verde', common: 'Kap Verde' },
    fra: { official: 'République du Cap-Vert', common: 'Îles du Cap-Vert' },
    hrv: { official: 'Republika Cabo Verde', common: 'Zelenortska Republika' },
    ita: { official: 'Repubblica di Capo Verde', common: 'Capo Verde' },
    jpn: { official: 'カーボベルデ共和国', common: 'カーボベルデ' },
    nld: { official: 'Republiek van Cabo Verde', common: 'Kaapverdië' },
    por: { official: 'República de Cabo Verde', common: 'Cabo Verde' },
    rus: { official: 'Республика Кабо -Верде', common: 'Кабо-Верде' },
    spa: { official: 'República de Cabo Verde', common: 'Cabo Verde' },
    fin: { official: 'Kap Verden tasavalta', common: 'Kap Verde' },
  },
  latlng: [16, -24],
  demonym: 'Cape Verdian',
  landlocked: false,
  borders: [],
  area: 4033,
} as const;
