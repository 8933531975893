var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "badge-count" }, [
    _c("div", [_vm._v(_vm._s(_setup.displayCount))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }