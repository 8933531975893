var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "graphic-chevrons", style: _setup.graphicStyle },
    [
      _c(
        "div",
        { staticClass: "d-flex" },
        _vm._l(_setup.elementItems, function (item) {
          return _c(
            "div",
            { key: item.index, staticClass: "graphic-element" },
            [
              _c("div", { staticClass: "chevron-piece chevron-top" }),
              _vm._v(" "),
              _c("div", { staticClass: "chevron-piece chevron-bottom" }),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }