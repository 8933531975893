export const AZE = {
  name: {
    common: 'Azerbaijan',
    official: 'Republic of Azerbaijan',
    native: {
      aze: { official: 'Azərbaycan Respublikası', common: 'Azərbaycan' },
      rus: { official: 'Азербайджанская Республика', common: 'Азербайджан' },
    },
  },
  cities: [
    'Baku',
    'Sumqayit',
    'Xirdalan',
    'Quba',
    'Zurges',
    'ÅžÇki',
    'Qusar',
    'Nakhchivan',
    'Sulutapa',
    'XaÃ§maz',
  ],
  tld: ['.az'],
  cca2: 'AZ',
  ccn3: '031',
  cca3: 'AZE',
  cioc: 'AZE',
  currency: ['AZN'],
  callingCode: ['994'],
  capital: 'Baku',
  altSpellings: ['AZ', 'Republic of Azerbaijan', 'Azərbaycan Respublikası'],
  region: 'Asia',
  subregion: 'Western Asia',
  languages: { aze: 'Azerbaijani', rus: 'Russian' },
  translations: {
    cym: { official: 'Republic of Azerbaijan', common: 'Aserbaijan' },
    deu: { official: 'Republik Aserbaidschan', common: 'Aserbaidschan' },
    fra: { official: "République d'Azerbaïdjan", common: 'Azerbaïdjan' },
    hrv: { official: 'Republika Azerbajdžan', common: 'Azerbajdžan' },
    ita: { official: "Repubblica dell'Azerbaigian", common: 'Azerbaijan' },
    jpn: { official: 'アゼルバイジャン共和国', common: 'アゼルバイジャン' },
    nld: { official: 'Republiek Azerbeidzjan', common: 'Azerbeidzjan' },
    por: { official: 'República do Azerbaijão', common: 'Azerbeijão' },
    rus: { official: 'Азербайджанская Республика', common: 'Азербайджан' },
    spa: { official: 'República de Azerbaiyán', common: 'Azerbaiyán' },
    fin: { official: 'Azerbaidzanin tasavalta', common: 'Azerbaidzan' },
  },
  latlng: [40.5, 47.5],
  demonym: 'Azerbaijani',
  landlocked: true,
  borders: ['ARM', 'GEO', 'IRN', 'RUS', 'TUR'],
  area: 86600,
  provinces: [
    'Abseron Rayonu',
    'Agcabadi Rayonu',
    'Agdam Rayonu',
    'Agdas Rayonu',
    'Agstafa Rayonu',
    'Agsu Rayonu',
    'Ali Bayramli Sahari',
    'Astara Rayonu',
    'Baki Sahari',
    'Balakan Rayonu',
    'Barda Rayonu',
    'Beylaqan Rayonu',
    'Bilasuvar Rayonu',
    'Cabrayil Rayonu',
    'Calilabad Rayonu',
    'Daskasan Rayonu',
    'Davaci Rayonu',
    'Fuzuli Rayonu',
    'Gadabay Rayonu',
    'Ganca Sahari',
    'Goranboy Rayonu',
    'Goycay Rayonu',
    'Haciqabul Rayonu',
    'Imisli Rayonu',
    'Ismayilli Rayonu',
    'Kalbacar Rayonu',
    'Kurdamir Rayonu',
    'Lacin Rayonu',
    'Lankaran Rayonu',
    'Lankaran Sahari',
    'Lerik Rayonu',
    'Masalli Rayonu',
    'Mingacevir Sahari',
    'Naftalan Sahari',
    'Naxcivan Muxtar Respublikasi',
    'Neftcala Rayonu',
    'Oguz Rayonu',
    'Qabala Rayonu',
    'Qax Rayonu',
    'Qazax Rayonu',
    'Qobustan Rayonu',
    'Quba Rayonu',
    'Qubadli Rayonu',
    'Qusar Rayonu',
    'Saatli Rayonu',
    'Sabirabad Rayonu',
    'Saki Rayonu',
    'Saki Sahari',
    'Salyan Rayonu',
    'Samaxi Rayonu',
    'Samkir Rayonu',
    'Samux Rayonu',
    'Siyazan Rayonu',
    'Sumqayit Sahari',
    'Susa Rayonu',
    'Susa Sahari',
    'Tartar Rayonu',
    'Tovuz Rayonu',
    'Ucar Rayonu',
    'Xacmaz Rayonu',
    'Xankandi Sahari',
    'Xanlar Rayonu',
    'Xizi Rayonu',
    'Xocali Rayonu',
    'Xocavand Rayonu',
    'Yardimli Rayonu',
    'Yevlax Rayonu',
    'Yevlax Sahari',
    'Zangilan Rayonu',
    'Zaqatala Rayonu',
    'Zardab Rayonu',
  ],
  nativeName: 'Azərbaycan',
  timezones: ['UTC+04:00'],
} as const;
