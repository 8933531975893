var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-collapse",
    _vm._b(
      { attrs: { accordion: "data-table-accordion", role: "tabpanel" } },
      "b-collapse",
      { visible: _setup.visible },
      false
    ),
    [
      _c(
        "b-card-body",
        { staticClass: "p-0" },
        [
          _c(
            _setup.tableComponent,
            _vm._b(
              { tag: "component", attrs: { table: _vm.tableKey } },
              "component",
              _setup.tablePanel,
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }