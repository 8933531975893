import { Module, Action } from '@vuex/decorators';
import { TablePanel } from 'table-panel';

import { faEdit } from '@icons/solid/faEdit';

import { Drone } from '@models';
import { modals } from '@modals';

/** ... */
const columns: TablePanel.Column<Drone>[] = [
  { key: 'id', label: 'ID', value: 'id' },
  {
    key: 'name',
    label: 'Name',
    value: 'name',
  },
  {
    key: 'manufacturer',
    label: 'Manufacturer',
    value: 'manufacturer',
  },
  {
    key: 'model',
    label: 'Model',
    value: 'model',
  },
  {
    key: 'active',
    label: 'Active',
    value: 'active',
  },
];

/** ... */
const operations: TablePanel.Row.Operation<Drone>[] = [
  {
    label: 'Edit',
    icon: faEdit,
    fn: ({ id }) => {
      void modals.drone.edit({ droneId: id });
    },
  },
];

@Module({ namespaced: true })
export class DroneTablePanel
  extends TablePanel<Drone>
  implements TablePanel.Props<Drone>
{
  readonly module = 'drones';
  readonly loadAction = 'list';
  readonly pk = 'id';
  readonly label = 'DRONES';
  readonly columns = columns;
  readonly operations = operations;
  readonly createAction = 'createDrone';
  readonly deleteAction = 'deleteDrone';
  readonly enabled = true;
  readonly tableSortType = 'id';
  readonly tablePageSize = 50;

  /**
   * ...
   */
  @Action
  async createDrone() {
    await modals.drone.create();

    await this.context.dispatch('load');
  }

  /**
   * ...
   */
  @Action
  async deleteDrone({ items }: DroneTablePanel.DeleteItemOptions) {
    await modals.confirm.deleteItems({ itemType: 'drones', items });
  }
}

export namespace DroneTablePanel {
  /** ... */
  export interface DeleteItemOptions {
    items: Drone[];
  }
}
