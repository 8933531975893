export const PNG = {
  name: {
    common: 'Papua New Guinea',
    official: 'Independent State of Papua New Guinea',
    native: {
      eng: {
        official: 'Independent State of Papua New Guinea',
        common: 'Papua New Guinea',
      },
      hmo: {
        official: 'Independen Stet bilong Papua Niugini',
        common: 'Papua Niu Gini',
      },
      tpi: {
        official: 'Independen Stet bilong Papua Niugini',
        common: 'Papua Niugini',
      },
    },
  },
  cities: [
    'Waigani',
    'Madang',
    'Port Moresby',
    'Namatanai',
    'Arawa',
    'Pongani',
    'Lae',
    'Goroka',
    'Wewak',
    'Kimbe',
    'Daru',
    'Kokopo',
    'Kerema',
    'Lorengau',
    'Popondetta',
    'Wabag',
    'Kundiawa',
    'Mount Hagen',
    'Nambaga',
    'Kikori',
    'Aitape',
    'Kiunga',
    'Vanimo',
    'Mendi',
    'Paivara',
    'Kavieng',
    'Kupano',
  ],
  tld: ['.pg'],
  cca2: 'PG',
  ccn3: '598',
  cca3: 'PNG',
  cioc: 'PNG',
  currency: ['PGK'],
  callingCode: ['675'],
  capital: 'Port Moresby',
  altSpellings: [
    'PG',
    'Independent State of Papua New Guinea',
    'Independen Stet bilong Papua Niugini',
  ],
  region: 'Oceania',
  subregion: 'Melanesia',
  languages: { eng: 'English', hmo: 'Hiri Motu', tpi: 'Tok Pisin' },
  translations: {
    deu: {
      official: 'Unabhängige Staat Papua-Neuguinea',
      common: 'Papua-Neuguinea',
    },
    fra: {
      official: 'État indépendant de Papouasie-Nouvelle-Guinée',
      common: 'Papouasie-Nouvelle-Guinée',
    },
    hrv: {
      official: 'Nezavisna Država Papui Novoj Gvineji',
      common: 'Papua Nova Gvineja',
    },
    ita: {
      official: 'Stato indipendente di Papua Nuova Guinea',
      common: 'Papua Nuova Guinea',
    },
    jpn: { official: 'パプアニューギニア独立国', common: 'パプアニューギニア' },
    nld: {
      official: 'Onafhankelijke Staat Papoea -Nieuw-Guinea',
      common: 'Papoea-Nieuw-Guinea',
    },
    por: {
      official: 'Estado Independente da Papua Nova Guiné',
      common: 'Papua Nova Guiné',
    },
    rus: {
      official: 'Независимое Государство Папуа-Новой Гвинеи',
      common: 'Папуа — Новая Гвинея',
    },
    spa: {
      official: 'Estado Independiente de Papúa Nueva Guinea',
      common: 'Papúa Nueva Guinea',
    },
    fin: {
      official: 'Papua-Uuden-Guinean Itsenäinen valtio',
      common: 'Papua-Uusi-Guinea',
    },
  },
  latlng: [-6, 147],
  demonym: 'Papua New Guinean',
  landlocked: false,
  borders: ['IDN'],
  area: 462840,
  provinces: [
    'Bougainville',
    'Central',
    'Chimbu',
    'East New Britain',
    'East Sepik',
    'Eastern Highlands',
    'Enga',
    'Gulf',
    'Madang',
    'Manus',
    'Milne Bay',
    'Morobe',
    'National Capital',
    'New Ireland',
    'Northern',
    'Sandaun',
    'Southern Highlands',
    'West New Britain',
    'Western',
    'Western Highlands',
  ],
  nativeName: 'Papua Niugini',
  timezones: ['UTC+10:00'],
} as const;
