export const NFK = {
  name: {
    common: 'Norfolk Island',
    official: 'Territory of Norfolk Island',
    native: {
      eng: {
        official: 'Territory of Norfolk Island',
        common: 'Norfolk Island',
      },
      pih: { official: "Teratri of Norf'k Ailen", common: "Norf'k Ailen" },
    },
  },
  cities: ['Kingston'],
  tld: ['.nf'],
  cca2: 'NF',
  ccn3: '574',
  cca3: 'NFK',
  cioc: '',
  currency: ['AUD'],
  callingCode: ['672'],
  capital: 'Kingston',
  altSpellings: [
    'NF',
    'Territory of Norfolk Island',
    "Teratri of Norf'k Ailen",
  ],
  region: 'Oceania',
  subregion: 'Australia and New Zealand',
  languages: { eng: 'English', pih: 'Norfuk' },
  translations: {
    deu: { official: 'Gebiet der Norfolk-Insel', common: 'Norfolkinsel' },
    fra: { official: "Territoire de l'île Norfolk", common: 'Île Norfolk' },
    hrv: { official: 'Teritorij Norfolk Island', common: 'Otok Norfolk' },
    ita: { official: 'Territorio di Norfolk Island', common: 'Isola Norfolk' },
    jpn: { official: 'ノーフォーク島の領土', common: 'ノーフォーク島' },
    nld: {
      official: 'Grondgebied van Norfolk Island',
      common: 'Norfolkeiland',
    },
    por: { official: 'Território da Ilha Norfolk', common: 'Ilha Norfolk' },
    rus: { official: 'Территория острова Норфолк', common: 'Норфолк' },
    spa: {
      official: 'Territorio de la Isla Norfolk',
      common: 'Isla de Norfolk',
    },
    fin: { official: 'Norfolkinsaaren territorio', common: 'Norfolkinsaari' },
  },
  latlng: [-29.03333333, 167.95],
  demonym: 'Norfolk Islander',
  landlocked: false,
  borders: [],
  area: 36,
} as const;
