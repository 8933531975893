export const LAO = {
  name: {
    common: 'Laos',
    official: "Lao People's Democratic Republic",
    native: {
      lao: { official: 'ສາທາລະນະ ຊາທິປະໄຕ ຄົນລາວ ຂອງ', common: 'ສປປລາວ' },
    },
  },
  cities: ['Vientiane'],
  tld: ['.la'],
  cca2: 'LA',
  ccn3: '418',
  cca3: 'LAO',
  cioc: 'LAO',
  currency: ['LAK'],
  callingCode: ['856'],
  capital: 'Vientiane',
  altSpellings: [
    'LA',
    'Lao',
    "Lao People's Democratic Republic",
    'Sathalanalat Paxathipatai Paxaxon Lao',
  ],
  region: 'Asia',
  subregion: 'South-Eastern Asia',
  languages: { lao: 'Lao' },
  translations: {
    deu: { official: 'Laos, Demokratische Volksrepublik', common: 'Laos' },
    fra: { official: 'République démocratique populaire lao', common: 'Laos' },
    hrv: { official: 'Narodna Demokratska Republika', common: 'Laos' },
    ita: {
      official: 'Repubblica democratica popolare del Laos',
      common: 'Laos',
    },
    jpn: { official: 'ラオス人民民主共和国', common: 'ラオス人民民主共和国' },
    nld: { official: 'Lao Democratische Volksrepubliek', common: 'Laos' },
    por: { official: 'Laos, República Democrática', common: 'Laos' },
    rus: {
      official: 'Лаосская Народно-Демократическая Республика',
      common: 'Лаос',
    },
    spa: { official: 'República Democrática Popular Lao', common: 'Laos' },
    fin: { official: 'Laosin demokraattinen kansantasavalta', common: 'Laos' },
  },
  latlng: [18, 105],
  demonym: 'Laotian',
  landlocked: true,
  borders: ['MMR', 'KHM', 'CHN', 'THA', 'VNM'],
  area: 236800,
  provinces: [
    'Attapu',
    'Bokeo',
    'Bolikhamxai',
    'Champasak',
    'Houaphan',
    'Khammouan',
    'Louangnamtha',
    'Louangphabang',
    'Oudomxai',
    'Phongsali',
    'Salavan',
    'Savannakhet',
    'Viangchan',
    'Viangchan',
    'Xaignabouli',
    'Xaisomboun',
    'Xekong',
    'Xiangkhoang',
  ],
  nativeName: 'ສປປລາວ',
  timezones: ['UTC+07:00'],
} as const;
