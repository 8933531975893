export const VGB = {
  name: {
    common: 'British Virgin Islands',
    official: 'Virgin Islands',
    native: {
      eng: { official: 'Virgin Islands', common: 'British Virgin Islands' },
    },
  },
  cities: ['Road Town', 'Tortola'],
  provinces: ['Saint Croix', 'Saint John', 'Saint Thomas'],
  tld: ['.vg'],
  cca2: 'VG',
  ccn3: '092',
  cca3: 'VGB',
  cioc: 'IVB',
  currency: ['USD'],
  callingCode: ['1284'],
  capital: 'Road Town',
  altSpellings: ['VG', 'Virgin Islands, British'],
  region: 'Americas',
  subregion: 'Caribbean',
  languages: { eng: 'English' },
  translations: {
    deu: { official: 'Jungferninseln', common: 'Britische Jungferninseln' },
    fra: { official: 'îles Vierges', common: 'Îles Vierges britanniques' },
    hrv: {
      official: 'Djevičanski Otoci',
      common: 'Britanski Djevičanski Otoci',
    },
    ita: { official: 'Isole Vergini', common: 'Isole Vergini Britanniche' },
    jpn: { official: 'バージン諸島', common: 'イギリス領ヴァージン諸島' },
    nld: { official: 'Maagdeneilanden', common: 'Britse Maagdeneilanden' },
    por: { official: 'Ilhas Virgens', common: 'Ilhas Virgens' },
    rus: {
      official: 'Виргинские острова',
      common: 'Британские Виргинские острова',
    },
    spa: {
      official: 'Islas Vírgenes',
      common: 'Islas Vírgenes del Reino Unido',
    },
    fin: { official: 'Brittiläiset Neitsytsaaret', common: 'Neitsytsaaret' },
  },
  latlng: [18.431383, -64.62305],
  demonym: 'Virgin Islander',
  landlocked: false,
  borders: [],
  area: 151,
} as const;
