export const PYF = {
  name: {
    common: 'French Polynesia',
    official: 'French Polynesia',
    native: {
      fra: { official: 'Polynésie française', common: 'Polynésie française' },
    },
  },
  cities: ['Papeete', 'Mahina', 'Faaa', 'Pirae', 'Punaauia', 'Arue', 'Paeau'],
  tld: ['.pf'],
  cca2: 'PF',
  ccn3: '258',
  cca3: 'PYF',
  cioc: '',
  currency: ['XPF'],
  callingCode: ['689'],
  capital: 'Papeetē',
  altSpellings: [
    'PF',
    'Polynésie française',
    'French Polynesia',
    'Pōrīnetia Farāni',
  ],
  region: 'Oceania',
  subregion: 'Polynesia',
  languages: { fra: 'French' },
  translations: {
    deu: {
      official: 'Französisch-Polynesien',
      common: 'Französisch-Polynesien',
    },
    fra: { official: 'Polynésie française', common: 'Polynésie française' },
    hrv: { official: 'Francuska Polinezija', common: 'Francuska Polinezija' },
    ita: { official: 'Polinesia Francese', common: 'Polinesia Francese' },
    jpn: { official: 'フランス領ポリネシア', common: 'フランス領ポリネシア' },
    nld: { official: 'Frans-Polynesië', common: 'Frans-Polynesië' },
    por: { official: 'Polinésia Francesa', common: 'Polinésia Francesa' },
    rus: { official: 'Французская Полинезия', common: 'Французская Полинезия' },
    spa: { official: 'Polinesia francés', common: 'Polinesia Francesa' },
    fin: { official: 'Ranskan Polynesia', common: 'Ranskan Polynesia' },
  },
  latlng: [-15, -140],
  demonym: 'French Polynesian',
  landlocked: false,
  borders: [],
  area: 4167,
} as const;
