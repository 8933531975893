export const MLT = {
  name: {
    common: 'Malta',
    official: 'Republic of Malta',
    native: {
      eng: { official: 'Republic of Malta', common: 'Malta' },
      mlt: { official: "Repubblika ta ' Malta", common: 'Malta' },
    },
  },
  cities: [
    'Mellieha',
    'Hamrun',
    'Siggiewi',
    'Saint John',
    'Zurrieq',
    'Paola',
    'Tarxien',
    'Fgura',
    'Marsa',
    'Kirkop',
    'BirÅ¼ebbuÄ¡a',
    'Gharb',
    'Mosta',
    'Munxar',
    'Rabat',
    'Attard',
    'Xaghra',
    'Naxxar',
    'Lija',
    'Imsida',
    'Birkirkara',
    'Zejtun',
    'Safi',
    'Zabbar',
    'Haz-Zebbug',
    'Victoria',
    'Sannat',
    'Valletta',
    'Cospicua',
    'San Pawl il-Bahar',
    'Sliema',
    'Qormi',
    'Mdina',
    'Marsaxlokk',
    'Floriana',
    'Luqa',
    'Marsaskala',
    'PietÃ ',
    'Fontana',
    'Il-Pergla',
    'Saint Venera',
    'Pembroke',
    'Mqabba',
    'Marsalforn',
    'Gzira',
    "St. Julian's",
    'Imtarfa',
    'Ghasri',
    'Hal Gharghur',
    'Saint Lucia',
    'Il-Madliena',
    'Senglea',
    'Bingemma',
    'Dingli',
    'L-Iklin',
    'Gudja',
    'Saint Lawrence',
    'Ghajnsielem',
    'Xewkija',
    'Bugibba',
    'Nadur',
    'Balzan',
    'Birgu',
    'Qrendi',
    'Ghaxaq',
    'L-Iskorvit',
    'Manikata',
    'Iz-Zebbiegh',
    "Ta' Xbiex",
    'Swieqi',
  ],
  tld: ['.mt'],
  cca2: 'MT',
  ccn3: '470',
  cca3: 'MLT',
  cioc: 'MLT',
  currency: ['EUR'],
  callingCode: ['356'],
  capital: 'Valletta',
  altSpellings: ['MT', 'Republic of Malta', "Repubblika ta' Malta"],
  region: 'Europe',
  subregion: 'Southern Europe',
  languages: { eng: 'English', mlt: 'Maltese' },
  translations: {
    deu: { official: 'Republik Malta', common: 'Malta' },
    fra: { official: 'République de Malte', common: 'Malte' },
    hrv: { official: 'Republika Malta', common: 'Malta' },
    ita: { official: 'Repubblica di Malta', common: 'Malta' },
    jpn: { official: 'マルタ共和国', common: 'マルタ' },
    nld: { official: 'Republiek Malta', common: 'Malta' },
    por: { official: 'República de Malta', common: 'Malta' },
    rus: { official: 'Республика Мальта', common: 'Мальта' },
    spa: { official: 'República de Malta', common: 'Malta' },
    fin: { official: 'Maltan tasavalta', common: 'Malta' },
  },
  latlng: [35.83333333, 14.58333333],
  demonym: 'Maltese',
  landlocked: false,
  borders: [],
  area: 316,
  provinces: ['Valletta'],
  nativeName: 'Malta',
  timezones: ['UTC+01:00'],
} as const;
