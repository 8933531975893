import { Vue } from '@vue';

import * as modules from './modules';

type ApiService = {
  [P in keyof typeof modules]: (typeof modules)[P];
};

/** Series of Zephyr database API request-based services. */
export const api = Vue.observable({ ...modules }) as ApiService;

// if (module.hot) {
//   module.hot.accept('./modules', () => Object.assign(api, modules));
// }

if (import.meta.hot) {
  import.meta.hot.accept('./modules', () => Object.assign(api, modules));
}
