import { render, staticRenderFns } from "./TableCellSuccess.vue?vue&type=template&id=670ed4d0&scoped=true"
import script from "./TableCellSuccess.vue?vue&type=script&setup=true&lang=ts"
export * from "./TableCellSuccess.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./TableCellSuccess.vue?vue&type=style&index=0&id=670ed4d0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "670ed4d0",
  null
  
)

export default component.exports