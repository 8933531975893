export const BLR = {
  name: {
    common: 'Belarus',
    official: 'Republic of Belarus',
    native: {
      bel: { official: 'Рэспубліка Беларусь', common: 'Белару́сь' },
      rus: { official: 'Республика Беларусь', common: 'Белоруссия' },
    },
  },
  cities: [
    'Minsk',
    'Mogilev',
    'Brest',
    'Vitebsk',
    "Dvor-Gomel'",
    'Hrodna',
    'Navapolatsk',
    'Lida',
    'Horki',
    'Borisov',
    'Lyakhovichi',
    'Radoshkovichi',
    'Polatsk',
    'Orsha',
    "Valer'yanovo",
    'Baranovichi',
    'Mazyr',
    'Lyaskavichy',
    'Zhodzina',
    'Zaslawye',
    'Lahoysk',
    'Shklow',
    "Mozyr'",
    'Dzyarzhynsk',
    'Pinsk',
  ],
  tld: ['.by'],
  cca2: 'BY',
  ccn3: '112',
  cca3: 'BLR',
  cioc: 'BLR',
  currency: ['BYR'],
  callingCode: ['375'],
  capital: 'Minsk',
  altSpellings: [
    'BY',
    'Bielaruś',
    'Republic of Belarus',
    'Белоруссия',
    'Республика Беларусь',
    'Belorussiya',
    'Respublika Belarus’',
  ],
  region: 'Europe',
  subregion: 'Eastern Europe',
  languages: { bel: 'Belarusian', rus: 'Russian' },
  translations: {
    cym: { official: 'Republic of Belarus', common: 'Belarws' },
    deu: { official: 'Republik Belarus', common: 'Weißrussland' },
    fra: { official: 'République de Biélorussie', common: 'Biélorussie' },
    hrv: { official: 'Republika Bjelorusija', common: 'Bjelorusija' },
    ita: { official: 'Repubblica di Belarus', common: 'Bielorussia' },
    jpn: { official: 'ベラルーシ共和国', common: 'ベラルーシ' },
    nld: { official: 'Republiek Belarus', common: 'Wit-Rusland' },
    por: { official: 'República da Bielorrússia', common: 'Bielorússia' },
    rus: { official: 'Республика Беларусь', common: 'Белоруссия' },
    spa: { official: 'República de Belarús', common: 'Bielorrusia' },
    fin: { official: 'Valko-Venäjän tasavalta', common: 'Valko-Venäjä' },
  },
  latlng: [53, 28],
  demonym: 'Belarusian',
  landlocked: true,
  borders: ['LVA', 'LTU', 'POL', 'RUS', 'UKR'],
  area: 207600,
  provinces: [
    'Brestskaya (Brest)',
    "Homyel'skaya (Homyel')",
    'Horad Minsk',
    'Hrodzyenskaya (Hrodna)',
    'Mahilyowskaya (Mahilyow)',
    'Minskaya',
    'Vitsyebskaya (Vitsyebsk)',
  ],
  nativeName: 'Белару́сь',
  timezones: ['UTC+03:00'],
} as const;
