var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { class: ["table-placeholder", { transparent: _vm.transparent }] },
    [
      !_vm.transparent
        ? _c("Spinner", { attrs: { offset: "left" } })
        : _vm._e(),
      _vm._v(" "),
      _c("h2", [_vm._v(_vm._s(_vm.text))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }