export const GRC = {
  name: {
    common: 'Greece',
    official: 'Hellenic Republic',
    native: { ell: { official: 'Ελληνική Δημοκρατία', common: 'Ελλάδα' } },
  },
  cities: [
    'Athens',
    'Ekali',
    'Thessaloniki',
    'Chalcis',
    'Aegina',
    'Rhodes',
    'Kos',
    'Rafina',
    'Voula',
    'Vouliagmeni',
    'Alimos',
    'Glyfada',
    'Corinth',
    'Servia',
    'Kozani',
    'Serres',
    'Panorama',
    'Katerini',
    'Larisa',
    'Elassona',
    'Komotini',
    'Alexandroupoli',
    'Metaxades',
    'Marathon',
    'Filothei',
    'Paiania',
    'Tinos',
    'Psychiko',
    'Megalochari',
    'Kallithea',
    'Pallini',
    'Nikaia',
    'Ioannina',
    'Loutraki',
    'Aliartos',
    'Argos',
    'Kilkis',
    'Lamia',
    'Heraklion',
    'Cholargos',
    'Agrinio',
    'Markopoulo Mesogaias',
    'Piraeus',
    'Keratsini',
    'Aigaleo',
    'PÃ¡trai',
    'Aigio',
    'Messolonghi',
    'Karditsa',
    'Corfu',
    'Lefkada',
    'Chania',
    'Veroia',
    'Giannitsa',
    'Vergina',
    'Ptolemaida',
    'Xanthi',
    'Kavala',
    'Sparta',
    'Moires',
    'Kalamata',
    'Nea Peramos',
    'Chios',
    'Perama',
    'Attica',
    'Magoula',
    'Elliniko',
    'Nafplion',
    'Salamina',
    'Ayios Stefanos',
    'Galatsi',
    'Kymi',
    'Elefsina',
    'Aspropyrgos',
    'Nea Ionia',
    'Kifissia',
    'Farsala',
    'Amaliada',
    'Pefki',
    'Nea Makri',
    'Alexandreia',
    'Melissia',
    'Volos',
    'Sindos',
    'Kalamaria',
    'Goumenissa',
    'Kastoria',
    'Florina',
    'Vari',
    'Skala',
    'Chania',
    'Kaisariani',
    'Igoumenitsa',
    'Ierapetra',
    'Ialysos',
    'Patmos',
    'Kassiopi',
    'Anavyssos',
    'Koropi',
    'Trikala',
    'Varkiza',
    'Andravida',
    'Kranidi',
    'Hydra',
    'Xylokastro',
    'Nea Kios',
    'Tripoli',
    'Ano Liosia',
    'Peristeri',
    'Orestiada',
    'Keratea',
    'Kouvaras',
    'Porto Rafti',
    'Paloukia',
    'Dafni',
    'Eretria',
    'Thebes',
    'Pyrgos',
    'Rio',
    'Mandra',
    'Megara',
    'Peristeri',
    'Argyroupoli',
    'Thermi',
    'Arta',
    'Lefkimmi',
    'Parga',
    'Preveza',
    'Litochoro',
    'Naxos',
    'Spata',
    'Nea Smyrni',
    'Mytilene',
    'Zakynthos',
    'Argostoli',
    'Lixouri',
    'Nafpaktos',
    'Oropos',
    'Drama',
    'Kalymnos',
    'Kalamos',
    'Mesagros',
    'Skiathos',
    'Skala Oropou',
    'Makrygialos',
    'Samothraki',
    'Ormylia',
    'Pella',
    'Pylaia',
    'Rethymno',
    'Filippiada',
    'Nea Alikarnassos',
    'Lykovrysi',
    'Nea Filadelfeia',
    'Pikermi',
    'Kamatero',
    'Gonnoi',
    'Agioi Anargyroi',
    'Archaia Nemea',
    'Polichni',
    'Platy',
    'Dionysos',
    'Kryonerion',
    'Symi',
    'Agios Nikolaos',
    'Tavros',
    'Leontario',
    'Naousa',
  ],
  tld: ['.gr'],
  cca2: 'GR',
  ccn3: '300',
  cca3: 'GRC',
  cioc: 'GRE',
  currency: ['EUR'],
  callingCode: ['30'],
  capital: 'Athens',
  altSpellings: ['GR', 'Elláda', 'Hellenic Republic', 'Ελληνική Δημοκρατία'],
  region: 'Europe',
  subregion: 'Southern Europe',
  languages: { ell: 'Greek' },
  translations: {
    deu: { official: 'Hellenische Republik', common: 'Griechenland' },
    fra: { official: 'République hellénique', common: 'Grèce' },
    hrv: { official: 'Helenska Republika', common: 'Grčka' },
    ita: { official: 'Repubblica ellenica', common: 'Grecia' },
    jpn: { official: 'ギリシャ共和国', common: 'ギリシャ' },
    nld: { official: 'Helleense Republiek', common: 'Griekenland' },
    por: { official: 'República Helénica', common: 'Grécia' },
    rus: { official: 'Греческая Республика', common: 'Греция' },
    spa: { official: 'República Helénica', common: 'Grecia' },
    fin: { official: 'Helleenien tasavalta', common: 'Kreikka' },
  },
  latlng: [39, 22],
  demonym: 'Greek',
  landlocked: false,
  borders: ['ALB', 'BGR', 'TUR', 'MKD'],
  area: 131990,
  provinces: [
    'Aitolia kai Akarnania',
    'Akhaia',
    'Argolis',
    'Arkadhia',
    'Arta',
    'Attiki',
    'Ayion Oros (Mt. Athos)',
    'Dhodhekanisos',
    'Drama',
    'Evritania',
    'Evros',
    'Evvoia',
    'Florina',
    'Fokis',
    'Fthiotis',
    'Grevena',
    'Ilia',
    'Imathia',
    'Ioannina',
    'Irakleion',
    'Kardhitsa',
    'Kastoria',
    'Kavala',
    'Kefallinia',
    'Kerkyra',
    'Khalkidhiki',
    'Khania',
    'Khios',
    'Kikladhes',
    'Kilkis',
    'Korinthia',
    'Kozani',
    'Lakonia',
    'Larisa',
    'Lasithi',
    'Lesvos',
    'Levkas',
    'Magnisia',
    'Messinia',
    'Pella',
    'Pieria',
    'Preveza',
    'Rethimni',
    'Rodhopi',
    'Samos',
    'Serrai',
    'Thesprotia',
    'Thessaloniki',
    'Trikala',
    'Voiotia',
    'Xanthi',
    'Zakinthos',
  ],
  nativeName: 'Ελλάδα',
  timezones: ['UTC+02:00'],
} as const;
