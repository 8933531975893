
import { defineComponent } from 'vue';
import { BFormSelect } from 'bootstrap-vue';

declare module 'vue/types/vue' {
  export interface Vue {
    FormSelect: ComponentWithProps<Props>;
  }
}

/**
 * `FormSelect` component properties.
 */
export interface Props extends BFormSelect.Props {
  label: string;
}

export default defineComponent({
  name: 'FormSelect',
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      required: true,
    },
  },
});
