export const CMR = {
  name: {
    common: 'Cameroon',
    official: 'Republic of Cameroon',
    native: {
      eng: { official: 'Republic of Cameroon', common: 'Cameroon' },
      fra: { official: 'République du Cameroun', common: 'Cameroun' },
    },
  },
  cities: [
    'Douala',
    'YaoundÃ©',
    'Bafoussam',
    'Ringo',
    'Bamenda',
    'NgaoundÃ©rÃ©',
    'Tibati',
    'Kumba',
    'Buea',
    'Bafia',
    'Kribi',
  ],
  tld: ['.cm'],
  cca2: 'CM',
  ccn3: '120',
  cca3: 'CMR',
  cioc: 'CMR',
  currency: ['XAF'],
  callingCode: ['237'],
  capital: 'Yaoundé',
  altSpellings: ['CM', 'Republic of Cameroon', 'République du Cameroun'],
  region: 'Africa',
  subregion: 'Middle Africa',
  languages: { eng: 'English', fra: 'French' },
  translations: {
    cym: { official: 'Republic of Cameroon', common: 'Camerŵn' },
    deu: { official: 'Republik Kamerun', common: 'Kamerun' },
    fra: { official: 'République du Cameroun', common: 'Cameroun' },
    hrv: { official: 'Republika Kamerun', common: 'Kamerun' },
    ita: { official: 'Repubblica del Camerun', common: 'Camerun' },
    jpn: { official: 'カメルーン共和国', common: 'カメルーン' },
    nld: { official: 'Republiek Kameroen', common: 'Kameroen' },
    por: { official: 'República dos Camarões', common: 'Camarões' },
    rus: { official: 'Республика Камерун', common: 'Камерун' },
    spa: { official: 'República de Camerún', common: 'Camerún' },
    fin: { official: 'Kamerunin tasavalta', common: 'Kamerun' },
  },
  latlng: [6, 12],
  demonym: 'Cameroonian',
  landlocked: false,
  borders: ['CAF', 'TCD', 'COG', 'GNQ', 'GAB', 'NGA'],
  area: 475442,
  provinces: [
    'Adamaoua',
    'Centre',
    'Est',
    'Extreme-Nord',
    'Littoral',
    'Nord',
    'Nord-Ouest',
    'Ouest',
    'Sud',
    'Sud-Ouest',
  ],
  nativeName: 'Cameroon',
  timezones: ['UTC+01:00'],
} as const;
