import * as models from '@models';

import { openModal } from '../open-modal';

/**
 * ...
 */
export type DeleteItemsModalOptions =
  | { itemType: 'announcements'; items: models.Announcement[] }
  | { itemType: 'assignments'; items: models.Assignment[] }
  | { itemType: 'courses'; items: models.Course[] }
  | { itemType: 'drones'; items: models.Drone[] }
  | { itemType: 'extensionCodes'; items: models.ExtensionCode[] }
  | { itemType: 'organizations'; items: models.Organization[] }
  | { itemType: 'invites'; items: models.Invite[] }
  | { itemType: 'modules'; items: models.Module[] }
  | { itemType: 'products'; items: models.Product[] }
  | { itemType: 'scenes'; items: models.Scene[] };

/**
 * Open Delete Items modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function deleteItems(options?: DeleteItemsModalOptions) {
  await openModal.safe({
    title: 'CONFIRM DELETION',
    props: { ...(options ?? {}) },
    component: () => import('./DeleteItems/index.vue'),
  });
}

/**
 * ...
 */
export interface ExternalLinkNoticeModalOptions {
  link: string;
}

/**
 * Open External Link Notice modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function externalLinkNotice(
  options: ExternalLinkNoticeModalOptions,
) {
  await openModal.safe({
    title: 'EXTERNAL LINK NOTICE',
    props: { ...options },
    component: () => import('./ExternalLinkNotice.vue'),
  });
}

/**
 * ...
 */
export interface YesNoModalOptions {
  message?: string;
}

/**
 * Open Yes No modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function yesNo(options: YesNoModalOptions) {
  const modalOptions = {
    title: 'ARE YOU SURE?',
    props: { ...options },
    component: () => import('./YesNo.vue'),
  };

  try {
    return await openModal<boolean>(modalOptions);
  } catch {
    return false;
  }
}
