function createInfo(str: string) {
  return (
    "High level metrics displayed are now based on the last 90 days of flight reports. Don't worry, all of your past reports are still viewable!\n\n" +
    str +
    ' Zephyr is a learning platform and mistakes are expected as the pilot becomes more competent prior to handling a live aircraft. A well performing pilot should expect this score to improve as they spend more time using Zephyr.'
  );
}

const completedInfo =
  'Zephyr tracks the score of each training module attempted within the last 90 days. This value shows the percentage of each module that received a score of 80% or better out of all modules completed excluding modules with a score of 0. A score 80% or above is a high score for a module requiring precise manipulation of the controls, a skill that is not expected of beginner pilots.\n\n';

const violationInfo =
  'Zephyr tracks the total number of FAA violations committed by the pilot during each module and calculates an average from modules attempted within the last 90 days. This value shows the percentage of modules completed where the pilot violated an FAA Pt. 107 regulation. The goal of each pilot should be to have zero FAA violations during each module. A score of zero is attributed to a highly compliant pilot, beginner pilots are expected to violate regulations in the initial stages of their training.\n\n';

const crashInfo =
  'Zephyr tracks the total number of crashes caused by the pilot during each module and calculates an average from modules attempted the last 90 days. This value shows the percentage of training modules completed where the pilot crashed at least once. The goal of each pilot should be to have zero crashes during each module, but this may be challenging for a beginner.\n\n';

export default {
  completed: createInfo(completedInfo),
  violations: createInfo(violationInfo),
  crashes: createInfo(crashInfo),
};
