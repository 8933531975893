export const GUY = {
  name: {
    common: 'Guyana',
    official: 'Co-operative Republic of Guyana',
    native: {
      eng: { official: 'Co-operative Republic of Guyana', common: 'Guyana' },
    },
  },
  cities: ['Georgetown', 'New Amsterdam', 'Linden'],
  tld: ['.gy'],
  cca2: 'GY',
  ccn3: '328',
  cca3: 'GUY',
  cioc: 'GUY',
  currency: ['GYD'],
  callingCode: ['592'],
  capital: 'Georgetown',
  altSpellings: ['GY', 'Co-operative Republic of Guyana'],
  region: 'Americas',
  subregion: 'South America',
  languages: { eng: 'English' },
  translations: {
    deu: { official: 'Kooperative Republik Guyana', common: 'Guyana' },
    fra: { official: 'République coopérative de Guyana', common: 'Guyana' },
    hrv: { official: 'Zadruga Republika Gvajana', common: 'Gvajana' },
    ita: { official: 'Co -operative Republic of Guyana', common: 'Guyana' },
    jpn: { official: 'ガイアナの協同共和国', common: 'ガイアナ' },
    nld: { official: 'Coöperatieve Republiek Guyana', common: 'Guyana' },
    por: { official: 'Co -operative República da Guiana', common: 'Guiana' },
    rus: { official: 'Кооперативная Республика Гайана', common: 'Гайана' },
    spa: { official: 'República Cooperativa de Guyana', common: 'Guyana' },
    fin: {
      official: 'Guayanan osuustoiminnallinen tasavalta',
      common: 'Guayana',
    },
  },
  latlng: [5, -59],
  demonym: 'Guyanese',
  landlocked: false,
  borders: ['BRA', 'SUR', 'VEN'],
  area: 214969,
  provinces: [
    'Barima-Waini',
    'Cuyuni-Mazaruni',
    'Demerara-Mahaica',
    'East Berbice-Corentyne',
    'Essequibo Islands-West Demerara',
    'Mahaica-Berbice',
    'Pomeroon-Supenaam',
    'Potaro-Siparuni',
    'Upper Demerara-Berbice',
    'Upper Takutu-Upper Essequibo',
  ],
  nativeName: 'Guyana',
  timezones: ['UTC−04:00'],
} as const;
