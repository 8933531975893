import { computed, type Component } from 'vue';

import { useStore, type TablePanels } from '@store';

import {
  TablePanelRegular,
  TablePanelProgressive,
  TablePanelLogs,
} from '@components/TablePanel';

export interface UseTableOptions {
  tableKey: TablePanels.ModuleKey;
  loaderOptions?: Nullable<GenericObject>;
  collapsable?: boolean;
}

/**
 * Get `CardTable` configuration data.
 */
export function useTableItem(props: UseTableOptions) {
  const store = useStore();

  const tablePanel = computed(() => store.state.tablePanels[props.tableKey]);

  /**
   * `true` if the table uses a progressive load paginated format.
   */
  const progressive = computed(() => {
    return !!(store.getters as unknown as GenericObject)[
      `tablePanels/${props.tableKey}/isProgressive`
    ];
  });

  /**
   * Reference to the table component that should be used to render the table.
   */
  const tableComponent = computed(() => {
    let value: Component;

    if (props.tableKey === 'outputLogEvents') {
      value = TablePanelLogs;
    } else if (progressive.value) {
      value = TablePanelProgressive;
    } else {
      value = TablePanelRegular;
    }

    return value;
  });

  const makeDispatch = (type: string, options?: Nullable<GenericObject>) => {
    const args: [string, unknown?] = [`tablePanels/${props.tableKey}/${type}`];

    if (options) {
      args.push(options);
    }

    void store.dispatch(...args);
  };

  /**
   * Boolean representing whether the table panel is set to be "open" or not.
   *
   * Note that it is up to the component implementing this composable to
   * use the state of this value to represent expanding functionally and
   * visibly on the DOM.
   */
  const expanded = computed({
    get: () => {
      return tablePanel.value.tableOpen;
    },
    set: (value) => {
      let type: 'open' | 'close';

      if (value) {
        if (tablePanel.value.tableOpen) return;

        type = 'open';
      } else {
        if (!tablePanel.value.tableOpen) return;

        type = 'close';
      }

      makeDispatch(type, props.loaderOptions);
    },
  });

  const visible = computed(() => !props.collapsable || expanded.value);

  // If the table is open and is configured to load when opened, initiate
  // a load here.
  if (visible.value && tablePanel.value.loadOnOpen) {
    if (props.loaderOptions) {
      makeDispatch('load', props.loaderOptions);
    } else {
      makeDispatch('load');
    }
  }

  return {
    expanded,
    tablePanel,
    tableComponent,
    visible,
  };
}
