export const COM = {
  name: {
    common: 'Comoros',
    official: 'Union of the Comoros',
    native: {
      ara: { official: 'الاتحاد القمري', common: 'القمر‎' },
      fra: { official: 'Union des Comores', common: 'Comores' },
      zdj: { official: 'Udzima wa Komori', common: 'Komori' },
    },
  },
  cities: ['Moutsamoudou', 'Moroni'],
  tld: ['.km'],
  cca2: 'KM',
  ccn3: '174',
  cca3: 'COM',
  cioc: 'COM',
  currency: ['KMF'],
  callingCode: ['269'],
  capital: 'Moroni',
  altSpellings: [
    'KM',
    'Union of the Comoros',
    'Union des Comores',
    'Udzima wa Komori',
    'al-Ittiḥād al-Qumurī',
  ],
  region: 'Africa',
  subregion: 'Eastern Africa',
  languages: { ara: 'Arabic', fra: 'French', zdj: 'Comorian' },
  translations: {
    cym: { official: 'Union of the Comoros', common: 'Comoros' },
    deu: { official: 'Union der Komoren', common: 'Union der Komoren' },
    fra: { official: 'Union des Comores', common: 'Comores' },
    hrv: { official: 'Savez Komori', common: 'Komori' },
    ita: { official: 'Unione delle Comore', common: 'Comore' },
    jpn: { official: 'コモロ連合', common: 'コモロ' },
    nld: { official: 'Unie van de Comoren', common: 'Comoren' },
    por: { official: 'União das Comores', common: 'Comores' },
    rus: { official: 'Союз Коморских Островов', common: 'Коморы' },
    spa: { official: 'Unión de las Comoras', common: 'Comoras' },
    fin: { official: 'Komorien liitto', common: 'Komorit' },
  },
  latlng: [-12.16666666, 44.25],
  demonym: 'Comoran',
  landlocked: false,
  borders: [],
  area: 1862,
} as const;
