import axios, {
  type InternalAxiosRequestConfig,
  type AxiosStatic,
} from 'axios';

import { auth } from '@auth';

declare module 'vue/types/vue' {
  interface Vue {
    /** {@link AxiosStatic} http instance used by the application. */
    $http: AxiosStatic;
  }
}

/**
 * Request interceptor that sets the "Authorization" header using the access
 * token belonging to the current Cognito user session, if one exists.
 */
async function onRequest(config: InternalAxiosRequestConfig) {
  // Ensure the header value is an object.
  config.headers = config.headers ?? {};

  // Attempt tp get the access token from the current Cognito user session.
  // Obviously, this will result in `null` if no user session is active.
  const token = await auth.getAccessToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}

export * from 'axios';

/**
 * App http service for making authenticated requests to the server.
 */
export const http = axios.create();

http.interceptors.request.use(onRequest);

export default axios;
