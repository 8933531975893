export const LBR = {
  name: {
    common: 'Liberia',
    official: 'Republic of Liberia',
    native: { eng: { official: 'Republic of Liberia', common: 'Liberia' } },
  },
  cities: ['Monrovia', 'Fish Town', 'Paynesville'],
  tld: ['.lr'],
  cca2: 'LR',
  ccn3: '430',
  cca3: 'LBR',
  cioc: 'LBR',
  currency: ['LRD'],
  callingCode: ['231'],
  capital: 'Monrovia',
  altSpellings: ['LR', 'Republic of Liberia'],
  region: 'Africa',
  subregion: 'Western Africa',
  languages: { eng: 'English' },
  translations: {
    deu: { official: 'Republik Liberia', common: 'Liberia' },
    fra: { official: 'République du Libéria', common: 'Liberia' },
    hrv: { official: 'Republika Liberija', common: 'Liberija' },
    ita: { official: 'Repubblica di Liberia', common: 'Liberia' },
    jpn: { official: 'リベリア共和国', common: 'リベリア' },
    nld: { official: 'Republiek Liberia', common: 'Liberia' },
    por: { official: 'República da Libéria', common: 'Libéria' },
    rus: { official: 'Республика Либерия', common: 'Либерия' },
    spa: { official: 'República de Liberia', common: 'Liberia' },
    fin: { official: 'Liberian tasavalta', common: 'Liberia' },
  },
  latlng: [6.5, -9.5],
  demonym: 'Liberian',
  landlocked: false,
  borders: ['GIN', 'CIV', 'SLE'],
  area: 111369,
  provinces: [
    'Bomi',
    'Bong',
    'Grand Bassa',
    'Grand Cape Mount',
    'Grand Gedeh',
    'Grand Kru',
    'Lofa',
    'Margibi',
    'Maryland',
    'Montserrado',
    'Nimba',
    'River Cess',
    'Sinoe',
  ],
  nativeName: 'Liberia',
  timezones: ['UTC'],
} as const;
