import { Course, Organization } from '@models';
import { store } from '@store';

import { openModal } from '../open-modal';

/**
 * {@link create Create Course} modal configuration options.
 */
export interface CreateCourseModalOptions {
  courseId?: Course['id'];
  organizationId?: Organization['id'];
}

/**
 * Open Create Course modal.
 *
 * @param options Modal {@link CreateCourseModalOptions configuration options}.
 * @returns Modal promise.
 */
export async function create(options?: CreateCourseModalOptions) {
  const props: { course?: Course } = {};

  if (options?.courseId) {
    const course = await store.dispatch('courses/get', options);

    props.course = course;
  }

  await openModal.safe({
    title: 'CREATE COURSE',
    component: () => import('./CreateCourse.vue'),
    props,
  });
}

/**
 * {@link edit Edit Course} modal configuration options.
 */
export interface EditCourseModalOptions {
  courseId: Course['id'];
  organizationId: Organization['id'];
}

/**
 * Open Edit Course modal.
 *
 * @param options Modal {@link EditCourseModalOptions configuration options}.
 * @returns Modal promise.
 */
export async function edit(options: EditCourseModalOptions) {
  const course = await store.dispatch('courses/get', options);

  await openModal.safe({
    title: 'EDIT COURSE',
    component: () => import('./EditCourse.vue'),
    props: { course },
  });
}

/**
 * {@link manageStudents Manage Students} modal configuration options.
 */
export interface ManageStudentsModalOptions {
  courseId: Course['id'];
  organizationId: Organization['id'];
}

/**
 * Open Manage Students modal.
 *
 * @param options Modal
 * {@link ManageStudentsModalOptions configuration options}.
 * @returns Modal promise.
 */
export async function manageStudents(options: ManageStudentsModalOptions) {
  const course = await store.dispatch('courses/get', options);

  await openModal.safe({
    title: `MANAGE STUDENTS: ${course.name}`,
    size: 'xl',
    props: { course },
    component: () => import('./ManageStudents.vue'),
  });
}
