export const HTI = {
  name: {
    common: 'Haiti',
    official: 'Republic of Haiti',
    native: {
      fra: { official: "République d'Haïti", common: 'Haïti' },
      hat: { official: 'Repiblik Ayiti', common: 'Ayiti' },
    },
  },
  cities: [
    'Port-au-Prince',
    'Cap-HaÃ¯tien',
    'Delmar',
    'Carrefour',
    'Petionville',
    'Moise',
    'Jacmel',
    'Masseau',
    'Prince',
    'Turgeau',
    'Duverger',
  ],
  tld: ['.ht'],
  cca2: 'HT',
  ccn3: '332',
  cca3: 'HTI',
  cioc: 'HAI',
  currency: ['HTG', 'USD'],
  callingCode: ['509'],
  capital: 'Port-au-Prince',
  altSpellings: [
    'HT',
    'Republic of Haiti',
    "République d'Haïti",
    'Repiblik Ayiti',
  ],
  region: 'Americas',
  subregion: 'Caribbean',
  languages: { fra: 'French', hat: 'Haitian Creole' },
  translations: {
    deu: { official: 'Republik Haiti', common: 'Haiti' },
    fra: { official: "République d'Haïti", common: 'Haïti' },
    hrv: { official: 'Republika Haiti', common: 'Haiti' },
    ita: { official: 'Repubblica di Haiti', common: 'Haiti' },
    jpn: { official: 'ハイチ共和国', common: 'ハイチ' },
    nld: { official: 'Republiek Haïti', common: 'Haïti' },
    por: { official: 'República do Haiti', common: 'Haiti' },
    rus: { official: 'Республика Гаити', common: 'Гаити' },
    spa: { official: 'República de Haití', common: 'Haiti' },
    fin: { official: 'Haitin tasavalta', common: 'Haiti' },
  },
  latlng: [19, -72.41666666],
  demonym: 'Haitian',
  landlocked: false,
  borders: ['DOM'],
  area: 27750,
  provinces: [
    'Artibonite',
    'Centre',
    "Grand'Anse",
    'Nord',
    'Nord-Est',
    'Nord-Ouest',
    'Ouest',
    'Sud',
    'Sud-Est',
  ],
  nativeName: 'Haïti',
  timezones: ['UTC−05:00'],
} as const;
