var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "organization-seat-type-table-cell" }, [
    _setup.billingType !== "SiteLicense"
      ? _c("span", [
          _vm._v(
            "\n    " +
              _vm._s(_setup.usedSeats) +
              "/" +
              _vm._s(_setup.maxSeats) +
              " (" +
              _vm._s(_setup.maxSeats - _setup.usedSeats) +
              " available)\n  "
          ),
        ])
      : _c("span", [_vm._v("N/A")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }