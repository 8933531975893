var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { class: _setup.rootClassList, attrs: { id: "app" } },
    [
      _c(_setup.AppHeader),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content", on: { click: _setup.onAppClicked } },
        [
          _c(
            "Transition",
            [
              _c(_setup.RouterView, {
                class: ["view-wrapper", ..._setup.pageClassList],
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(_setup.AppFooter),
      _vm._v(" "),
      _c(_setup.ModalRoot),
      _vm._v(" "),
      _c(_setup.NotificationsRoot),
      _vm._v(" "),
      _c(_setup.PrivacyPolicyUpdateNotice),
      _vm._v(" "),
      _c(_setup.AppCookiesNotice),
      _vm._v(" "),
      _setup.busyOverlay
        ? _c(
            _setup.Overlay,
            { staticClass: "busy-overlay", attrs: { fullscreen: "" } },
            [
              _c("Spinner", { staticClass: "mr-4", attrs: { size: "3" } }),
              _vm._v(" "),
              _c("span", { staticClass: "h2" }, [
                _vm._v(_vm._s(_setup.busyOverlay.message)),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(_setup.KeyboardShortcutsUtil),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }