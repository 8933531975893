/**
 * Base structure for an ID'ed map entry object.
 */
interface HasId {
  id: string;
}

/**
 * Create an map object from a list of ID'ed object values. An entry's ID will
 * be used as the lookup key for that same entry in the generated map.
 *
 * @param item A list of ID'ed objects.
 * @returns An object map who's lookup keys correspond to the IDs of their
 * entries.
 */
export function createIdentifierMap<T extends HasId, K extends T['id']>(
  items: T[],
) {
  return Object.fromEntries(createEntries(items)) as Record<K, T>;
}

//#region Helper Functions

function createEntries(items: HasId[]) {
  return items.map((item) => [item.id, item]);
}

//#endregion Helper Functions
