import {
  ref,
  computed,
  watch,
  isRef,
  onMounted,
  onUnmounted,
  type Ref,
} from 'vue';

import { useStore } from '@store';
import type { Me } from '@store/modules/me';
import { clamp } from '@tools/math';

/**
 * Get current user data from the store. An error is thrown if there is none.
 *
 * @returns Store user data.
 */
export function useMe() {
  const store = useStore();

  const me = store.state.me;

  if (!me.id) {
    throw new Error(
      '[useMe] no user data to fetch. Be sure this composable is only used in a context where user data is certain to present,',
    );
  }

  return me as Me.UserSessionInfo;
}

/**
 * Register using addEventListener on mounted, and removeEventListener
 * automatically on unmounted.
 *
 * @param event
 * @param listener
 * @returns ...
 */
export function useEventListener<E extends keyof WindowEventMap>(
  event: E,
  listener: (this: Window, event: WindowEventMap[E]) => void,
  options?: boolean | AddEventListenerOptions,
) {
  const add = () => {
    if (options !== undefined) {
      window.addEventListener(event, listener, options);
    } else {
      window.addEventListener(event, listener);
    }
  };

  const remove = () => {
    if (options !== undefined) {
      window.removeEventListener(event, listener, options);
    } else {
      window.removeEventListener(event, listener);
    }
  };

  onMounted(add);
  onUnmounted(remove);

  return remove;
}

/**
 * ...
 *
 * @param handler
 * @param timeout
 * @returns ...
 */
export function useInterval(handler: () => void, timeout?: number) {
  let intervalId: number;

  const set = () => {
    intervalId = window.setInterval(handler, timeout);
  };

  const clear = () => {
    window.clearInterval(intervalId);
  };

  onMounted(set);
  onUnmounted(clear);

  return clear;
}

/**
 * Set an attribute on the DOM root node.
 *
 * @param name Attribute name.
 * @param callback ...
 * @returns ...
 */
export function useSetRootAttribute(
  name: string,
  callback: () => string | null | undefined,
) {
  const attrName = `data-${name}`;

  return watch(
    callback,
    (value) => {
      if (value) {
        document.documentElement.setAttribute(attrName, value);
      } else {
        document.documentElement.removeAttribute(attrName);
      }
    },
    { immediate: true },
  );
}

/**
 * Cycle through a list of items.
 *
 * @param list The list of items to cycle through.
 * @returns An object with the current state and functions to cycle through the
 * list.
 */
export function useCycleList<T>(list: T[] | Ref<T[]>) {
  const reactiveList = (isRef(list) ? list : ref(list)) as Ref<T[]>;

  const activeIndex = ref(0);

  const state = computed({
    get: () => {
      return reactiveList.value[activeIndex.value] as T;
    },
    set: (value) => {
      reactiveList.value[activeIndex.value] = value;
    },
  });

  const go = (index: number) => {
    activeIndex.value = clamp(index, 0, reactiveList.value.length - 1);
  };

  const prev = () => {
    if (activeIndex.value === 0) {
      activeIndex.value = reactiveList.value.length - 1;
    } else {
      activeIndex.value -= 1;
    }
  };

  const next = () => {
    if (activeIndex.value === reactiveList.value.length - 1) {
      activeIndex.value = 0;
    } else {
      activeIndex.value += 1;
    }
  };

  watch(reactiveList, () => {
    if (activeIndex.value >= reactiveList.value.length) {
      activeIndex.value = 0;
    }
  });

  return {
    /**
     * The current item in the list.
     */
    state,
    /**
     * Move to the previous item in the list.
     */
    prev,
    /**
     * Move to the next item in the list.
     */
    next,
    /**
     * Move to a specific item in the list corresponding to the provided index.
     */
    go,
  };
}

/**
 * Cycle through a list of items automatically at a given interval. Selecting
 * a specific item will pause the cycle.
 *
 * @param list The list of items to cycle through.
 * @param interval The interval in milliseconds between each item.
 * @returns An object with the current state and functions to control the cycle.
 */
export function useAutoCycleList<T>(list: T[] | Ref<T[]>, interval: number) {
  const { state, next, go } = useCycleList(list);

  const intervalId = ref<number | null>(null);

  const paused = computed(() => intervalId.value === null);

  const start = () => {
    if (intervalId.value !== null) return;

    intervalId.value = window.setInterval(next, interval);
  };

  const stop = () => {
    if (intervalId.value === null) return;

    window.clearInterval(intervalId.value);

    intervalId.value = null;
  };

  const select = (index: number) => {
    stop();

    go(index);
  };

  onMounted(start);
  onUnmounted(stop);

  return {
    /**
     * The current item in the list.
     */
    state,
    /**
     * Whether the cycle is paused.
     */
    paused,
    /**
     * Start the cycle.
     */
    start,
    /**
     * Stop the cycle.
     */
    stop,
    /**
     * Move to a specific item in the list corresponding to the provided index.
     * This will also pause the cycle.
     */
    select,
  };
}
