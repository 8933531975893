var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.CardLoadable,
    { attrs: { loading: !!_vm.loading, "no-body": "" } },
    [
      _c("b-card-header", [
        _c("h5", { staticClass: "mb-0" }, [_vm._v("Assignments")]),
      ]),
      _vm._v(" "),
      _c(
        "b-card-body",
        { staticClass: "p-0" },
        [
          _c("b-table", {
            staticClass: "m-0",
            attrs: {
              items: _vm.assignments,
              fields: _setup.FIELDS,
              "per-page": _setup.perPage,
              "current-page": _setup.currentPage,
              "show-empty": "",
              "empty-text": "No Assignments",
              "empty-filtered-text": "No Assignments Found",
            },
          }),
          _vm._v(" "),
          _c(
            "b-container",
            [
              _c(
                "b-row",
                { staticClass: "p-3" },
                [
                  _c(
                    "b-col",
                    { attrs: { col: "" } },
                    [
                      _c("b-pagination", {
                        staticClass: "m-0",
                        attrs: {
                          size: "sm",
                          "total-rows": _setup.rows,
                          "per-page": _setup.perPage,
                        },
                        model: {
                          value: _setup.currentPage,
                          callback: function ($$v) {
                            _setup.currentPage = $$v
                          },
                          expression: "currentPage",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }