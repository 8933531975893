export const SLV = {
  name: {
    common: 'El Salvador',
    official: 'Republic of El Salvador',
    native: {
      spa: { official: 'República de El Salvador', common: 'El Salvador' },
    },
  },
  cities: [
    'San Salvador',
    'San Miguel',
    'La Libertad',
    'Ilopango',
    'Antiguo Cuscatlan',
    'Soyapango',
    'Santa Tecla',
    'Botoncillal El Botoncillo',
    'Mejicanos',
    'Nuevo Cuscatlan',
    'San Jose Villanueva',
    'Zaragoza',
    'Santa Ana',
    'Gigante',
    'Colonia Escalon',
    'Santiago Texacuangos',
    'Ayutuxtepeque',
    'Apopa',
    'Coatepeque',
    'San Marcos',
    'Usulutan',
    'Ozatlan',
    'Delgado',
    'Guazapa',
    'Apaneca',
    'Ahuachapan',
    'Sonsonate',
    'Lourdes',
    'Colon',
    'Cuscatancingo',
  ],
  tld: ['.sv'],
  cca2: 'SV',
  ccn3: '222',
  cca3: 'SLV',
  cioc: 'ESA',
  currency: ['SVC', 'USD'],
  callingCode: ['503'],
  capital: 'San Salvador',
  altSpellings: ['SV', 'Republic of El Salvador', 'República de El Salvador'],
  region: 'Americas',
  subregion: 'Central America',
  languages: { spa: 'Spanish' },
  translations: {
    cym: { official: 'Republic of El Salvador', common: 'El Salvador' },
    deu: { official: 'Republik El Salvador', common: 'El Salvador' },
    fra: { official: 'République du Salvador', common: 'Salvador' },
    hrv: { official: 'Republika El Salvador', common: 'Salvador' },
    ita: { official: 'Repubblica di El Salvador', common: 'El Salvador' },
    jpn: { official: 'エルサルバドル共和国', common: 'エルサルバドル' },
    nld: { official: 'Republiek El Salvador', common: 'El Salvador' },
    por: { official: 'República de El Salvador', common: 'El Salvador' },
    rus: { official: 'Республика Эль-Сальвадор', common: 'Сальвадор' },
    spa: { official: 'República de El Salvador', common: 'El Salvador' },
    fin: { official: 'El Salvadorin tasavalta', common: 'El Salvador' },
  },
  latlng: [13.83333333, -88.91666666],
  demonym: 'Salvadoran',
  landlocked: false,
  borders: ['GTM', 'HND'],
  area: 21041,
  provinces: [
    'Ahuachapan',
    'Cabanas',
    'Chalatenango',
    'Cuscatlan',
    'La Libertad',
    'La Paz',
    'La Union',
    'Morazan',
    'San Miguel',
    'San Salvador',
    'San Vicente',
    'Santa Ana',
    'Sonsonate',
    'Usulutan',
  ],
  nativeName: 'El Salvador',
  timezones: ['UTC−06:00'],
} as const;
