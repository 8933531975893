export const TUV = {
  name: {
    common: 'Tuvalu',
    official: 'Tuvalu',
    native: {
      eng: { official: 'Tuvalu', common: 'Tuvalu' },
      tvl: { official: 'Tuvalu', common: 'Tuvalu' },
    },
  },
  timezones: ['UTC+12:00'],
  provinces: ['Tuvalu'],
  nativeName: 'Tuvalu',
  cities: ['Funafuti'],
  tld: ['.tv'],
  cca2: 'TV',
  ccn3: '798',
  cca3: 'TUV',
  cioc: 'TUV',
  currency: ['AUD'],
  callingCode: ['688'],
  capital: 'Funafuti',
  altSpellings: ['TV'],
  region: 'Oceania',
  subregion: 'Polynesia',
  languages: { eng: 'English', tvl: 'Tuvaluan' },
  translations: {
    deu: { official: 'Tuvalu', common: 'Tuvalu' },
    fra: { official: 'Tuvalu', common: 'Tuvalu' },
    hrv: { official: 'Tuvalu', common: 'Tuvalu' },
    ita: { official: 'Tuvalu', common: 'Tuvalu' },
    jpn: { official: 'ツバル', common: 'ツバル' },
    nld: { official: 'Tuvalu', common: 'Tuvalu' },
    por: { official: 'Tuvalu', common: 'Tuvalu' },
    rus: { official: 'Тувалу', common: 'Тувалу' },
    spa: { official: 'Tuvalu', common: 'Tuvalu' },
    fin: { official: 'Tuvalu', common: 'Tuvalu' },
  },
  latlng: [-8, 178],
  demonym: 'Tuvaluan',
  landlocked: false,
  borders: [],
  area: 26,
} as const;
