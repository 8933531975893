import { render, staticRenderFns } from "./TableCellOrganizationSiteLicenses.vue?vue&type=template&id=164f7573&scoped=true"
import script from "./TableCellOrganizationSiteLicenses.vue?vue&type=script&setup=true&lang=ts"
export * from "./TableCellOrganizationSiteLicenses.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./TableCellOrganizationSiteLicenses.vue?vue&type=style&index=0&id=164f7573&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "164f7573",
  null
  
)

export default component.exports