export const DNK = {
  name: {
    common: 'Denmark',
    official: 'Kingdom of Denmark',
    native: { dan: { official: 'Kongeriget Danmark', common: 'Danmark' } },
  },
  cities: [
    'Copenhagen',
    'Frederiksberg',
    'Bronshoj',
    'Albertslund',
    'HillerÃ¸d',
    'FrederiksvÃ¦rk',
    'Vasby',
    'Elsinore',
    'Roskilde',
    'Brande',
    'Vejle',
    'Viborg',
    'KÃ¸ge',
    'Herlev',
    'Aarhus',
    'Randers',
    'Uldum',
    'Egtved',
    'Galten',
    'Hammel',
    'HÃ¸rsholm',
    'BirkerÃ¸d',
    'Hvidovre',
    'Ganlose',
    'Hedehusene',
    'RÃ¸dovre',
    'IshÃ¸j',
    'Frederikssund',
    'Tarm',
    'Haderslev',
    'Soborg',
    'RÃ¸nne',
    'Vordingborg',
    'Fakse',
    'RingkÃ¸bing',
    'Assens',
    'Odense',
    'Niva',
    'Olstykke',
    'Lemvig',
    'Holstebro',
    'Fensmark',
    'JÃ¦gerspris',
    'Jyllinge',
    'KorsÃ¸r',
    'Naestved',
    'Holbaek',
    'Karup',
    'Karlstrup',
    'Kongens Lyngby',
    'Hundested',
    'Farum',
    'Snekkersten',
    'Allerod',
    'Skaering',
    'Ega',
    'Hornslet',
    'Hjortshoj',
    'Skodstrup',
    'Lystrup',
    'Hinnerup',
    'DragÃ¸r',
    'Gentofte',
    'Ronde',
    'VallensbÃ¦k',
    'Risskov',
    'Kokkedal',
    'Kirke Hvalso',
    'Ballerup',
    'Fredericia',
    'Humlebaek',
    'Nakskov',
    'NÃ¸rre Alslev',
    'Glostrup',
    'Ikast',
    'Malov',
    'Fredensborg',
    'Stenlose',
    'Skovlunde',
    'Kirke-Hyllinge',
    'Kirke Saby',
    'Lejre',
    'Tune',
    'Havdrup',
    'Solrod',
    'Viby',
    'Sonderso',
    'Faaborg',
    'Marstal',
    'Langeskov',
    'Kerteminde',
    'Middelfart',
    'Ejby',
    'Arslev',
    'RudkÃ¸bing',
    'Svendborg',
    'Tommerup',
    'Silkeborg',
    'Ans',
    'Herning',
    'Aabenraa',
    'Ribe',
    'Christiansfeld',
    'Karlslunde',
    'SÃ¸nderborg',
    'Augustenborg',
    'Vojens',
    'Skanderborg',
    'Braedstrup',
    'Horsens',
    'Odder',
    'Kolding',
    'Vamdrup',
    'Stenderup',
    'Juelsminde',
    'Bredballe',
    'Lunderskov',
    'Hampen',
    'Ejstrup',
    'Gedved',
    'Brejning',
    'Ostbirk',
    'Ry',
    'Snedsted',
    'Nykobing Mors',
    'Hurup',
    'Baekmarksbro',
    'Struer',
    'Roslev',
    'Thisted',
    'Vinderup',
    'Skive',
    'Hellerup',
    'Charlottenlund',
    'Klampenborg',
    'Greve',
    'Slagelse',
    'Dalmose',
    'Bagsvaerd',
    'Ringsted',
    'Gilleleje',
    'Helsinge',
    'Graested',
    'Slangerup',
    'Tollose',
    'Kalundborg',
    'Vig',
    'Hadsten',
    'Viby',
    'Marslet',
    'Hojbjerg',
    'Ledoje',
    'Soro',
    'Haslev',
    'Borup',
    'Harlev',
    'Tureby',
    'Ruds-Vedby',
    'Olsted',
    'Vejby',
    'HjÃ¸rring',
    'Vadum',
    'BrÃ¸nderslev',
    'Frederikshavn',
    'Vra',
    'Sindal',
    'Strandby',
    'Norre Bindslev',
    'Gandrup',
    'Hirtshals',
    'Kastrup',
    'Liseleje',
    'Virum',
    'Hornbaek',
    'Espergaerde',
    'Holte',
    'Gislinge',
    'Ugerlose',
    'Farevejle',
    'Follenslev',
    'Taastrup',
    'PrÃ¦stÃ¸',
    'Lundby Stationsby',
    'Knebel',
    'Havndal',
    'GrenÃ¥',
    'Morke',
    'Virring',
    'Hasselager',
    'Tilst',
    'Malling',
    'Abyhoj',
    'Vaerlose',
    'Stege',
    'Aalborg',
    'Stovring',
    'Ringe',
    'SakskÃ¸bing',
    'VidebÃ¦k',
    'Nykobing Falster',
    'Idestrup',
    'Brovst',
    'Aabybro',
    'Morkov',
    'Svinninge',
    'Dianalund',
    'Hals',
    'Lasby',
    'Skjern',
    'Bjerringbro',
    'Vedbaek',
    'Rungsted',
    'Naerum',
    'LÃ¸gstÃ¸r',
    'FarsÃ¸',
    'Gistrup',
    'Norresundby',
    'Storvorde',
    'Hadsund',
    'Hobro',
    'Terndrup',
    'Haderup',
    'Oster Assels',
    'Erslev',
    'Frostrup',
    'Svaneke',
    'Hojby',
    'Herfolge',
    'Lille Skensved',
    'RÃ¸nnede',
    'Vissenbjerg',
    'Svanninge',
    'Ã†rÃ¸skÃ¸bing',
    'Nyborg',
    'Asnaes',
    'Jelling',
    'Brabrand',
    'Gadstrup',
    'Askeby',
    'Tappernoje',
    'Maribo',
    'Nysted',
    'StubbekÃ¸bing',
    'Eskilstrup',
    'RÃ¸dby',
    'Vemb',
    'SkÃ¸rping',
    'Norager',
    'Lynge',
    'Gadbjerg',
    'Them',
    'Vejen',
    'Aars',
    'Hedensted',
    'Harpelunde',
    'Rodvig',
    'Frederiksberg',
    'Brondby Strand',
    'Tagerup',
    'Skibby',
    'Ejby',
    'Vivild',
    'Esbjerg',
    'Norre Snede',
    'Skaelskor',
    'Horve',
    'Regstrup',
    'Jyderup',
    'HÃ¸ng',
    'Stroby',
    'Store Heddinge',
    'Bjaeverskov',
    'Stenlille',
    'Gammel Svebolle',
    'Snertinge',
    'Vemmelev',
    'Grevinge',
    'Munke Bjergby',
    'Stokkemarke',
    'Sollested',
    'Guldborg',
    'Mern',
    'Karrebaeksminde',
    'Bogo By',
    'Kettinge',
    'RÃ¸dekro',
    'Spentrup',
    'Gudbjerg',
    'TÃ¸nder',
    'Randbol',
    'Bramming',
    'LÃ¸gumkloster',
    'Varde',
    'Ã˜lgod',
    'Vorbasse',
    'Krusa',
    'Nordborg',
    'Oksbol',
    'Rodding',
    'Billund',
    'Hemmet',
    'Aller',
    'Ullerslev',
    'Gislev',
    'Mariager',
    'Baelum',
    'Ranum',
    'Alestrup',
    'Nibe',
    'Tranebjerg',
    'Ulstrup',
    'Orbaek',
    'Torring',
    'Allingabro',
    'Tjaereborg',
    'Henne',
    'Sherrebek',
    'Holsted',
    'Gram',
    'Tistrup',
    'Brorup',
    'Nordenskov',
    'Padborg',
    'Agerskov',
    'Bredebro',
    'Vandel',
    'Broager',
    'Kjargaard',
    'Daugard',
    'Losning',
    'Vestbjerg',
    'Tylstrup',
    'Suldrup',
    'Vodskov',
    'Sjolund',
    'Almind',
    'Glumso',
    'Hovedgard',
    'Ebeltoft',
    'Hjallerup',
    'Dronninglund',
    'Tars',
    'Give',
    'Arre',
    'Brenderup',
    'Auning',
    'Jerslev',
    'Tisvildeleje',
    'Kvistgard',
    'Alsgarde',
    'Bredsten',
    'Gording',
    'Otterup',
    'Lokken',
    'Alslev',
    'Fuglebjerg',
    'Store Merlose',
    'Ferritslev',
    'Tranbjerg',
    'Kirke Eskilstrup',
    'Hundslund',
    'Sandager',
    'Saltum',
    'Jerslev',
    'Asa',
    'Fjerritslev',
    'Gjol',
    'Saeby',
    'Dybvad',
    'Ostervra',
    'Skagen',
    'Pandrup',
    'Akirkeby',
    'Vekso',
    'Jorlunde',
    'Svenstrup',
    'Avlum',
    'Gorlose',
    'Harlev',
    'Gudme',
    'Harby',
    'Kibaek',
    'Klarup',
    'Munkebo',
    'Norre Aby',
    'SkÃ¦vinge',
    'Solbjerg',
    'Vildbjerg',
    'Vipperod',
    'Arup',
    'Gelsted',
    'Glamsbjerg',
    'Ryslinge',
    'Stensved',
    'Strib',
    'Bovrup',
    'Tornby',
    'Morud',
    'Oure',
    'Klemensker',
    'Gudhjem',
    'Bogense',
    'Frorup',
    'Trige',
    'Logstrup',
    'Ryomgard',
    'Kejlstrup',
    'Kolind',
    'Engesvang',
    'Hojslev',
    'Kjellerup',
    'Tinglev',
    'Gredstedbro',
    'Egernsund',
    'Blokhus',
    'Ulfborg',
    'Grindsted',
    'GÃ¸rlev',
    'Orsted',
    'Farup',
    'Langa',
    'Klovborg',
    'Ale',
    'Bryrup',
    'Nykobing Sjaelland',
    'Sandved',
    'Agedrup',
    'Vejstrup',
    'Sabro',
    'Tarbaek',
    'Vaeggerlose',
    'Kastrup',
    'Hanstholm',
    'Hornsyld',
    'Norre Nebel',
    'Jerup',
    'Sunds',
    'Thorso',
    'Hellebaek',
    'Moldrup',
    'Hosterkob',
    'Skamby',
    'Lyngby',
    'Nodebo',
    'Skarup',
    'Ebberup',
    'Fovling',
    'Beder',
    'Arden',
    'Ostermarie',
    'GrÃ¥sten',
    'Sulsted',
    'Sonder Omme',
    'Fjenneslev',
    'Harndrup',
    'Borkop',
    'Albaek',
    'Ornhoj',
    'Gladsaxe',
    'Horning',
    'Karise',
    'Toftlund',
    'Hejnsvig',
    'Hesselager',
    'Vester-Skerninge',
    'Stenstrup',
    'Hoje Tastrup',
    'Agerbaek',
    'Alslev',
    'Herlufmagle',
    'Koldby',
    'Laurbjerg',
    'Dannemare',
    'Ansager',
    'Refsvindinge',
    'Sorring',
    'Orum',
    'Blommenslyst',
    'Slet',
    'Norre Asmindrup',
    'Lambjerg',
    'Hoptrup',
    'Stakroge',
    'Bandholm',
    'Bovlingbjerg',
    'Harboore',
    'Spottrup',
    'Farvang',
    'Gjern',
    'Hvide Sande',
    'Rodkaersbro',
    'Lemming',
    'Lem',
    'Bording Stationsby',
    'Vester Hassing',
    'Hou',
    'Trustrup',
    'Gjerlev',
    'Balle',
    'Kongerslev',
    'Vebbestrup',
    'Nexo',
    'Allinge',
    'Soby',
    'Humble',
    'Marslev',
    'Rude',
    'LangebÃ¦k',
    'Borre',
    'Store Fuglede',
    'Oddesund Syd',
    'Hasle',
    'Blavand',
    'Omme',
    'Kolsen',
    'Bedsted',
    'Skodsborg',
    'Himmelev',
    'Sundby',
    'Jordrup',
    'Baekke',
    'Gesten',
    'Viuf',
    'Stouby',
    'Sonder Stenderup',
    'Barrit',
    'Hejls',
    'Bjaert',
    'Trelde',
    'Millinge',
    'Nordby',
    'Odsted',
    'Veflinge',
    'Brobyvaerk',
    'Baring',
    'Verninge',
    'Osby',
    'Glesborg',
    'Frederiksdal',
    'Rynkeby',
    'Asperup',
    'Kvaerndrup',
    'Skals',
    'Astrup',
    'Holme-Olstrup',
    'Holeby',
    'Flemming',
    'Salten',
    'Stoholm',
    'Sanderum',
    'Vantinge',
    'Toreby',
    'Horbelev',
    'Vesterborg',
    'Glyngore',
    'Tjebberup',
    'Eskebjerg',
    'Nygard',
    'Nimtofte',
    'Melby',
    'Boeslunde',
    'Klippinge',
    'Tim',
    'Hammershoj',
    'Nim',
    'Greve Strand',
    'Ramlose',
    'Ferslev',
    'Soesmarke',
    'Tullebolle',
    'Tranekaer',
    'Klokkerholm',
    'Laeso',
    'Spjald',
    'Sorvad',
    'Hodsager',
    'Dronningmolle',
    'Bylderup-Bov',
    'Norreballe',
    'Sommersted',
    'Glejbjerg',
    'Janderup',
    'Branderup',
    'TRUE',
    'TÃ¥rnby',
    'Vinkel',
    'Bolderslev',
    'Torring',
    'Borum',
    'Arslev',
    'Bruunshab',
    'Kvols',
    'Tikob',
    'No',
    'Hyllinge',
    'Kalvehave',
    'BrÃ¸ndby',
    'Horslunde',
    'Torrig',
    'Flauenskjold',
    'Anholt',
    'Tejn',
    'Vestervig',
    'Hjerm',
    'Vederso',
    'Vils',
    'Kaldred',
    'Dong',
    'Holme',
    'Henne Strand',
    'Jystrup',
    'Rebaek',
    'Bevtoft',
    'Martofte',
    'Naesby',
    'Errindlev',
    '',
  ],
  tld: ['.dk'],
  cca2: 'DK',
  ccn3: '208',
  cca3: 'DNK',
  cioc: 'DEN',
  currency: ['DKK'],
  callingCode: ['45'],
  capital: 'Copenhagen',
  altSpellings: ['DK', 'Danmark', 'Kingdom of Denmark', 'Kongeriget Danmark'],
  region: 'Europe',
  subregion: 'Northern Europe',
  languages: { dan: 'Danish' },
  translations: {
    cym: { official: 'Kingdom of Denmark', common: 'Denmarc' },
    deu: { official: 'Königreich Dänemark', common: 'Dänemark' },
    fra: { official: 'Royaume du Danemark', common: 'Danemark' },
    hrv: { official: 'Kraljevina Danska', common: 'Danska' },
    ita: { official: 'Regno di Danimarca', common: 'Danimarca' },
    jpn: { official: 'デンマーク王国', common: 'デンマーク' },
    nld: { official: 'Koninkrijk Denemarken', common: 'Denemarken' },
    por: { official: 'Reino da Dinamarca', common: 'Dinamarca' },
    rus: { official: 'Королевство Дания', common: 'Дания' },
    spa: { official: 'Reino de Dinamarca', common: 'Dinamarca' },
    fin: { official: 'Tanskan kuningaskunta', common: 'Tanska' },
  },
  latlng: [56, 10],
  demonym: 'Danish',
  landlocked: false,
  borders: ['DEU'],
  area: 43094,
  provinces: [
    'Arhus',
    'Bornholm',
    'Fredericksberg',
    'Frederiksborg',
    'Fyn',
    'Kobenhavn',
    'Kobenhavns',
    'Nordjylland',
    'Ribe',
    'Ringkobing',
    'Roskilde',
    'Sonderjylland',
    'Storstrom',
    'Vejle',
    'Vestsjalland',
    'Viborg',
  ],
  nativeName: 'Danmark',
  timezones: ['UTC−04:00', 'UTC−03:00', 'UTC−01:00', 'UTC', 'UTC+01:00'],
} as const;
