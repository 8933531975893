export const MDG = {
  name: {
    common: 'Madagascar',
    official: 'Republic of Madagascar',
    native: {
      fra: { official: 'République de Madagascar', common: 'Madagascar' },
      mlg: { official: "Repoblikan'i Madagasikara", common: 'Madagasikara' },
    },
  },
  cities: [
    'Toamasina',
    'Antananarivo',
    'Antsirabe',
    'Ambanja',
    'Toliara',
    'Fianarantsoa',
    'Antsiranana',
  ],
  tld: ['.mg'],
  cca2: 'MG',
  ccn3: '450',
  cca3: 'MDG',
  cioc: 'MAD',
  currency: ['MGA'],
  callingCode: ['261'],
  capital: 'Antananarivo',
  altSpellings: [
    'MG',
    'Republic of Madagascar',
    "Repoblikan'i Madagasikara",
    'République de Madagascar',
  ],
  region: 'Africa',
  subregion: 'Eastern Africa',
  languages: { fra: 'French', mlg: 'Malagasy' },
  translations: {
    deu: { official: 'Republik Madagaskar', common: 'Madagaskar' },
    fra: { official: 'République de Madagascar', common: 'Madagascar' },
    hrv: { official: 'Republika Madagaskar', common: 'Madagaskar' },
    ita: { official: 'Repubblica del Madagascar', common: 'Madagascar' },
    jpn: { official: 'マダガスカル共和国', common: 'マダガスカル' },
    nld: { official: 'Republiek Madagaskar', common: 'Madagaskar' },
    por: { official: 'República de Madagáscar', common: 'Madagáscar' },
    rus: { official: 'Республика Мадагаскар', common: 'Мадагаскар' },
    spa: { official: 'República de Madagascar', common: 'Madagascar' },
    fin: { official: 'Madagaskarin tasavalta', common: 'Madagaskar' },
  },
  latlng: [-20, 47],
  demonym: 'Malagasy',
  landlocked: false,
  borders: [],
  area: 587041,
  provinces: [
    'Antananarivo',
    'Antsiranana',
    'Fianarantsoa',
    'Mahajanga',
    'Toamasina',
    'Toliara',
  ],
  nativeName: 'Madagasikara',
  timezones: ['UTC+03:00'],
} as const;
