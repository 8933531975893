export const GAB = {
  name: {
    common: 'Gabon',
    official: 'Gabonese Republic',
    native: { fra: { official: 'République gabonaise', common: 'Gabon' } },
  },
  cities: [
    'Libreville',
    'Moanda',
    'Mamagnia',
    'LambarÃ©nÃ©',
    'Gamba',
    'Port-Gentil',
  ],
  tld: ['.ga'],
  cca2: 'GA',
  ccn3: '266',
  cca3: 'GAB',
  cioc: 'GAB',
  currency: ['XAF'],
  callingCode: ['241'],
  capital: 'Libreville',
  altSpellings: ['GA', 'Gabonese Republic', 'République Gabonaise'],
  region: 'Africa',
  subregion: 'Middle Africa',
  languages: { fra: 'French' },
  translations: {
    deu: { official: 'Gabunische Republik', common: 'Gabun' },
    fra: { official: 'République gabonaise', common: 'Gabon' },
    hrv: { official: 'Gabon Republika', common: 'Gabon' },
    ita: { official: 'Repubblica gabonese', common: 'Gabon' },
    jpn: { official: 'ガボン共和国', common: 'ガボン' },
    nld: { official: 'Republiek Gabon', common: 'Gabon' },
    por: { official: 'República do Gabão', common: 'Gabão' },
    rus: { official: 'Габона Республика', common: 'Габон' },
    spa: { official: 'República de Gabón', common: 'Gabón' },
    fin: { official: 'Gabonin tasavalta', common: 'Gabon' },
  },
  latlng: [-1, 11.75],
  demonym: 'Gabonese',
  landlocked: false,
  borders: ['CMR', 'COG', 'GNQ'],
  area: 267668,
  provinces: [
    'Estuaire',
    'Haut-Ogooue',
    'Moyen-Ogooue',
    'Ngounie',
    'Nyanga',
    'Ogooue-Ivindo',
    'Ogooue-Lolo',
    'Ogooue-Maritime',
    'Woleu-Ntem',
  ],
  nativeName: 'Gabon',
  timezones: ['UTC+01:00'],
} as const;
