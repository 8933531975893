import { Module, VuexModule, Mutation, Action } from '@vuex/decorators';
import { find, findIndex } from 'lodash';

import { api } from '@api';
import { Announcement } from '@models';
import { auth, RoleId } from '@auth';
import { alert } from '@services/alert';
import { Root } from '@store';

declare module '@vuex/core' {
  export interface Getters {
    'announcements/findById': Announcements['findById'];
  }

  export interface CommitMap {
    'announcements/SET': Announcements['SET'];
    'announcements/ADD': Announcements['ADD'];
    'announcements/UPDATE': Announcements['UPDATE'];
    'announcements/MARK_READ': Announcements['MARK_READ'];
    'announcements/DELETE': Announcements['DELETE'];
    'announcements/CLEAR': Announcements['CLEAR'];
  }

  export interface DispatchMap {
    'announcements/list': Announcements['list'];
    'announcements/get': Announcements['get'];
    'announcements/create': Announcements['create'];
    'announcements/update': Announcements['update'];
    'announcements/viewed': Announcements['viewed'];
    'announcements/del': Announcements['del'];
  }
}

@Module({ namespaced: true })
export class Announcements
  extends VuexModule<Announcements.State, Root.State>
  implements Announcements.State
{
  items: Announcements.UserAnnouncement[] = [];

  /** ... */
  get findById() {
    return (id: string) => find(this.items, { id }) ?? null;
  }

  //#region Mutations

  /**
   * ...
   */
  @Mutation
  SET(options: Announcements.SetMutationOptions) {
    this.items = options.data;
  }

  /**
   * ...
   */
  @Mutation
  ADD(options: Announcements.AddMutationOptions) {
    const items = [...this.items];

    const i = findIndex(items, { id: options.id });

    if (i !== -1) {
      items[i] = options;
    } else {
      items.push(options);
    }

    this.items = items;
  }

  /**
   * ...
   */
  @Mutation
  UPDATE(options: Announcements.UpdateMutationOptions) {
    const items = [...this.items];

    const i = findIndex(items, { id: options.announcementId });

    if (i <= -1) {
      throw new Error(
        `Announcement with ID ${options.announcementId} not found.`,
      );
    }

    items[i] = Object.assign({}, items[i], options);

    this.items = items;
  }

  /**
   * ...
   */
  @Mutation
  MARK_READ(options: Announcements.MarkAsReadMutationOptions) {
    const announcement = find(this.items, { id: options.announcementId });

    if (!announcement) {
      throw new Error(
        `Announcement with ID ${options.announcementId} not found.`,
      );
    }

    announcement.read = true;
  }

  /**
   * ...
   */
  @Mutation
  DELETE(options: Announcements.DeleteMutationOptions) {
    const index = findIndex(this.items, { id: options.announcementId });

    if (index === -1) {
      throw new Error(
        `Announcement with ID ${options.announcementId} not found.`,
      );
    }

    this.items = this.items.filter((_, i) => i !== index);
  }

  /**
   * ...
   */
  @Mutation
  CLEAR() {
    this.items = [];
  }

  //#endregion Mutations

  //#region Actions

  /**
   * ...
   */
  @Action
  async list() {
    const { me } = this.context.rootState;

    if (!me.id) return [];

    const promises: Promise<Announcement[]>[] = [];

    promises.push(api.announcements.listMyAnnouncements());

    // if (auth.isActiveRole(RoleId.LasAdmin)) {
    //   promises.push(api.announcements.list());
    // } else if (auth.isActiveRole(RoleId.Instructor, RoleId.InstitutionAdmin)) {
    //   promises.push(api.announcements.listPublic());

    //   if (me.selectedRole?.organization?.id) {
    //     promises.push(
    //       api.announcements.listByOrganization({
    //         organizationId: me.selectedRole.organization.id,
    //       }),
    //     );
    //   }
    // }

    // ...
    const announcements = (await Promise.all(promises)).flatMap((list) => list);
    // ...
    const viewedAnnouncements = await api.announcements.listMyViewed();

    const data: Announcements.UserAnnouncement[] = announcements.map(
      (item) => ({
        ...item,
        read: !!find(viewedAnnouncements, { announcementId: item.id }),
      }),
    );

    this.context.commit('SET', { data });

    return this.items;
  }

  /**
   * ...
   */
  @Action
  async get(options: Announcements.GetActionOptions) {
    const data = await api.announcements.get(options);

    this.context.commit('ADD', data);

    return data;
  }

  /**
   * ...
   */
  @Action
  async create(options: Announcements.CreateActionOptions) {
    const { me } = this.context.rootState;

    if (me.organization?.id && !options.organizationId) {
      options.organizationId = me.organization.id;
    }

    const data = await api.announcements.create(options);

    this.context.commit('ADD', data);

    return data;
  }

  /**
   * ...
   */
  @Action
  async update(options: Announcements.UpdateActionOptions) {
    await api.announcements.update(options);

    this.context.commit('UPDATE', options);

    return options;

    // currently returning the updated object until we have the updated object
    // being returned on the Update API call
    //
    // return (await this.context.dispatch('get', options)) as Announcement;
  }

  /**
   * ...
   */
  @Action
  async viewed(options: Announcements.SetReadStateActionOptions) {
    await api.announcements.markAsViewed(options);

    this.context.commit('MARK_READ', options);
  }

  /**
   * ...
   */
  @Action
  async del(options: Announcements.DeleteActionOptions) {
    const announcement = find(this.items, { id: options.announcementId });

    if (
      !announcement?.organization?.id &&
      !auth.isActiveRole(RoleId.LasAdmin)
    ) {
      alert.error('Unauthorized');

      throw new Error('Unauthorized');
    }

    if (
      announcement?.organization?.id &&
      !auth.isActiveRole(
        RoleId.LasAdmin,
        RoleId.Instructor,
        RoleId.InstitutionAdmin,
      )
    ) {
      alert.error('Unauthorized');

      throw new Error('Unauthorized');
    }

    await api.announcements.del(options);

    this.context.commit('DELETE', options);
  }

  //#endregion Actions
}

export namespace Announcements {
  /** ... */
  export interface UserAnnouncement extends Announcement {
    read: boolean;
  }

  /** ... */
  export interface State {
    items: UserAnnouncement[];
  }

  /** ... */
  export interface SetMutationOptions {
    data: UserAnnouncement[];
  }

  /** ... */
  export type AddMutationOptions = UserAnnouncement;
  /** ... */
  export type UpdateMutationOptions = api.announcements.UpdateOptions;
  /** ... */
  export type MarkAsReadMutationOptions = api.announcements.MarkAsViewedOptions;
  /** ... */
  export type DeleteMutationOptions = api.announcements.DeleteOptions;
  /** ... */
  export type GetActionOptions = api.announcements.GetOptions;
  /** ... */
  export type CreateActionOptions = api.announcements.CreateOptions;
  /** ... */
  export type UpdateActionOptions = api.announcements.UpdateOptions;
  /** ... */
  export type SetReadStateActionOptions = api.announcements.MarkAsViewedOptions;
  /** ... */
  export type DeleteActionOptions = api.announcements.DeleteOptions;
}

export default Announcements;
