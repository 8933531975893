import { api } from '@api';
import { Invoice } from '@models';

import { openModal } from '../open-modal';

/**
 * Open Create Invoice modal.
 *
 * @returns Modal promise.
 */
export async function create() {
  await openModal.safe({
    title: 'CREATE INVOICE',
    component: () => import('./CreateInvoice.vue'),
  });
}

/**
 * ...
 */
export interface EditInvoiceModalOptions {
  invoiceId: Invoice['id'];
}

/**
 * Open Edit Invoice modal.
 *
 * @returns Modal promise.
 */
export async function edit(options: ViewInvoiceModalOptions) {
  const invoice = await api.invoices.get(options);

  await openModal.safe({
    title: 'EDIT INVOICE',
    props: { invoice },
    component: () => import('./EditInvoice.vue'),
  });
}

/**
 * ...
 */
export interface ViewInvoiceModalOptions {
  invoiceId: Invoice['id'];
}

/**
 * Open View Invoice modal.
 *
 * @returns Modal promise.
 */
export async function view(options: ViewInvoiceModalOptions) {
  const invoice = await api.invoices.get(options);

  await openModal.safe({
    title: 'VIEW INVOICE',
    props: { invoice },
    component: () => import('./ViewInvoice.vue'),
  });
}
