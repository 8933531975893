export const KAZ = {
  name: {
    common: 'Kazakhstan',
    official: 'Republic of Kazakhstan',
    native: {
      kaz: { official: 'Қазақстан Республикасы', common: 'Қазақстан' },
      rus: { official: 'Республика Казахстан', common: 'Казахстан' },
    },
  },
  cities: [
    'Almaty',
    'Oral',
    'Kostanay',
    'Qaskeleng',
    'Karagandy',
    'Temirtau',
    'Astana',
    'Taldykorgan',
    'Talghar',
    'Pavlodar',
    'Ekibastuz',
    'Atyrau',
    'KÃ¶kshetaÅ«',
    'Aksay',
    'Taraz',
    'Petropavl',
    'Shymkent',
    'Serebryansk',
    'Ridder',
    'ShemonaÄ«kha',
    'Semey',
    'AqsÅ«',
    'Kyzylorda',
    'AqtÃ¶be',
    'KhromtaÅ«',
    'Aqtau',
    'Taldyk',
    'ZhangaÃ¶zen',
    'Karagandy',
    'Dzhezkazgan',
    'Baikonur',
    'Kyzyl',
    'Koktem',
    'Dostyk',
    'Sonaly',
    'Kentau',
    'Rudnyy',
    "Turgen'",
    'Esil',
    'Vostok',
    'Qaraghandy',
    'Uritskiy',
    'Kazakh',
    'Stepnogorsk',
    'Ust-Kamenogorsk',
    'LÄ«sakovsk',
    'Aksoran',
    'Zyryanovsk',
    'Aqtas',
    'BÃ¶rili',
    'Lomonosovka',
    'Turkestan',
  ],
  tld: ['.kz', '.қаз'],
  cca2: 'KZ',
  ccn3: '398',
  cca3: 'KAZ',
  cioc: 'KAZ',
  currency: ['KZT'],
  callingCode: ['76', '77'],
  capital: 'Astana',
  altSpellings: [
    'KZ',
    'Qazaqstan',
    'Казахстан',
    'Republic of Kazakhstan',
    'Қазақстан Республикасы',
    'Qazaqstan Respublïkası',
    'Республика Казахстан',
    'Respublika Kazakhstan',
  ],
  region: 'Asia',
  subregion: 'Central Asia',
  languages: { kaz: 'Kazakh', rus: 'Russian' },
  translations: {
    deu: { official: 'Republik Kasachstan', common: 'Kasachstan' },
    fra: { official: 'République du Kazakhstan', common: 'Kazakhstan' },
    hrv: { official: 'Republika Kazahstan', common: 'Kazahstan' },
    ita: { official: 'Repubblica del Kazakhstan', common: 'Kazakistan' },
    jpn: { official: 'カザフスタン共和国', common: 'カザフスタン' },
    nld: { official: 'Republiek Kazachstan', common: 'Kazachstan' },
    por: { official: 'República do Cazaquistão', common: 'Cazaquistão' },
    rus: { official: 'Республика Казахстан', common: 'Казахстан' },
    spa: { official: 'República de Kazajstán', common: 'Kazajistán' },
    fin: { official: 'Kazakstanin tasavalta', common: 'Kazakstan' },
  },
  latlng: [48, 68],
  demonym: 'Kazakhstani',
  landlocked: true,
  borders: ['CHN', 'KGZ', 'RUS', 'TKM', 'UZB'],
  area: 2724900,
  provinces: [
    'Almaty',
    'Aqmola',
    'Aqtobe',
    'Astana',
    'Atyrau',
    'Batys Qazaqstan',
    'Bayqongyr',
    'Mangghystau',
    'Ongtustik Qazaqstan',
    'Pavlodar',
    'Qaraghandy',
    'Qostanay',
    'Qyzylorda',
    'Shyghys Qazaqstan',
    'Soltustik Qazaqstan',
    'Zhambyl',
  ],
  nativeName: 'Қазақстан',
  timezones: ['UTC+05:00', 'UTC+06:00'],
} as const;
