var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { class: _setup.classList }, [
    _c(
      "div",
      { staticClass: "input-wrapper" },
      [
        _c("div", {
          staticClass: "form-control hosted-field-wrapper",
          attrs: { id: _vm.uid },
        }),
        _vm._v(" "),
        _c("Transition", [
          _c("label", { staticClass: "input-label", attrs: { for: _vm.uid } }, [
            _vm._v(_vm._s(_vm.label)),
          ]),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _vm.error
      ? _c("p", { staticClass: "error-message" }, [
          _vm._v(_vm._s(_vm.error.message)),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }