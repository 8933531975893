/** Alias tag parameter type. */
export type AliasTag = string | number | ((tag: number) => string | number);

/**
 * Generate an alias for a given email. If no alias tag is provided, a random
 * one will be generated.
 *
 * @param email Email to generate alias for.
 * @param aliasTag Tag to use as part of the alias. If a function is provided,
 * it will be called with a random number as it's sole parameter to generate
 * the tag.
 * @returns the email alias.
 */
export function createEmailAlias(email: string, aliasTag?: AliasTag) {
  let tag: string;

  if (!aliasTag) {
    tag = generateRandomNumber().toString();
  } else if (typeof aliasTag === 'function') {
    tag = aliasTag(generateRandomNumber()).toString();
  } else {
    tag = aliasTag.toString();
  }

  return email.replace(/@/, `+${tag}@`);
}

//#region Helper Functions

function generateRandomNumber() {
  return Math.round(10_000 * Math.random());
}

//#endregion Helper Functions
