var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "input-price" },
    [
      _c(
        "b-form-group",
        { attrs: { label: "Amount*" } },
        [
          _c(
            "b-input-group",
            {
              scopedSlots: _vm._u(
                [
                  _setup.model.currency
                    ? {
                        key: "prepend",
                        fn: function () {
                          return [
                            _c(
                              "b-input-group-text",
                              { staticClass: "currency-symbol" },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_setup.currencySymbol) +
                                    "\n        "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      }
                    : null,
                ],
                null,
                true
              ),
            },
            [
              _vm._v(" "),
              _c("b-form-input", {
                attrs: {
                  placeholder: "0.00",
                  readonly: _setup.props.readonly,
                  min: 0,
                  type: "number",
                  number: "",
                },
                on: { change: _setup.sanitizeAmount },
                model: {
                  value: _setup.model.amount,
                  callback: function ($$v) {
                    _vm.$set(_setup.model, "amount", $$v)
                  },
                  expression: "model.amount",
                },
              }),
              _vm._v(" "),
              _c("b-form-select", {
                staticClass: "currency-select",
                attrs: {
                  readonly: _setup.props.readonly,
                  options: _setup.CURRENCY_OPTIONS,
                },
                model: {
                  value: _setup.model.currency,
                  callback: function ($$v) {
                    _vm.$set(_setup.model, "currency", $$v)
                  },
                  expression: "model.currency",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { label: "Description" } },
        [
          _c("b-form-input", {
            attrs: {
              placeholder: _setup.props.placeholder,
              readonly: _setup.props.readonly,
            },
            model: {
              value: _setup.model.description,
              callback: function ($$v) {
                _vm.$set(_setup.model, "description", $$v)
              },
              expression: "model.description",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }