export const GNQ = {
  name: {
    common: 'Equatorial Guinea',
    official: 'Republic of Equatorial Guinea',
    native: {
      fra: {
        official: 'République de la Guinée Équatoriale',
        common: 'Guinée équatoriale',
      },
      por: {
        official: 'República da Guiné Equatorial',
        common: 'Guiné Equatorial',
      },
      spa: {
        official: 'República de Guinea Ecuatorial',
        common: 'Guinea Ecuatorial',
      },
    },
  },
  cities: ['Malabo'],
  tld: ['.gq'],
  cca2: 'GQ',
  ccn3: '226',
  cca3: 'GNQ',
  cioc: 'GEQ',
  currency: ['XAF'],
  callingCode: ['240'],
  capital: 'Malabo',
  altSpellings: [
    'GQ',
    'Republic of Equatorial Guinea',
    'República de Guinea Ecuatorial',
    'République de Guinée équatoriale',
    'República da Guiné Equatorial',
  ],
  region: 'Africa',
  subregion: 'Middle Africa',
  languages: { fra: 'French', por: 'Portuguese', spa: 'Spanish' },
  translations: {
    cym: {
      official: 'Republic of Equatorial Guinea',
      common: 'Gini Gyhydeddol',
    },
    deu: { official: 'Republik Äquatorialguinea', common: 'Äquatorialguinea' },
    fra: {
      official: 'République de Guinée équatoriale',
      common: 'Guinée équatoriale',
    },
    hrv: {
      official: 'Republika Ekvatorska Gvineja',
      common: 'Ekvatorijalna Gvineja',
    },
    ita: {
      official: 'Repubblica della Guinea Equatoriale',
      common: 'Guinea Equatoriale',
    },
    jpn: { official: '赤道ギニア共和国', common: '赤道ギニア' },
    nld: {
      official: 'Republiek Equatoriaal-Guinea',
      common: 'Equatoriaal-Guinea',
    },
    por: {
      official: 'República da Guiné Equatorial',
      common: 'Guiné Equatorial',
    },
    rus: {
      official: 'Республика Экваториальная Гвинея',
      common: 'Экваториальная Гвинея',
    },
    spa: {
      official: 'República de Guinea Ecuatorial',
      common: 'Guinea Ecuatorial',
    },
    fin: {
      official: 'Päiväntasaajan Guinean tasavalta',
      common: 'Päiväntasaajan Guinea',
    },
  },
  latlng: [2, 10],
  demonym: 'Equatorial Guinean',
  landlocked: false,
  borders: ['CMR', 'GAB'],
  area: 28051,
  provinces: [
    'Annobon',
    'Bioko Norte',
    'Bioko Sur',
    'Centro Sur',
    'Kie-Ntem',
    'Litoral',
    'Wele-Nzas',
  ],
  nativeName: 'Guinea Ecuatorial',
  timezones: ['UTC+01:00'],
} as const;
