export const VNM = {
  name: {
    common: 'Vietnam',
    official: 'Socialist Republic of Vietnam',
    native: {
      vie: {
        official: 'Cộng hòa xã hội chủ nghĩa Việt Nam',
        common: 'Việt Nam',
      },
    },
  },
  provinces: [
    'An Giang',
    'Ba Ria-Vung Tau',
    'Bac Giang',
    'Bac Kan',
    'Bac Lieu',
    'Bac Ninh',
    'Ben Tre',
    'Binh Dinh',
    'Binh Duong',
    'Binh Phuoc',
    'Binh Thuan',
    'Ca Mau',
    'Can Tho',
    'Cao Bang',
    'Da Nang',
    'Dac Lak',
    'Dong Nai',
    'Dong Thap',
    'Gia Lai',
    'Ha Giang',
    'Ha Nam',
    'Ha Noi',
    'Ha Tay',
    'Ha Tinh',
    'Hai Duong',
    'Hai Phong',
    'Ho Chi Minh',
    'Hoa Binh',
    'Hung Yen',
    'Khanh Hoa',
    'Kien Giang',
    'Kon Tum',
    'Lai Chau',
    'Lam Dong',
    'Lang Son',
    'Lao Cai',
    'Long An',
    'Nam Dinh',
    'Nghe An',
    'Ninh Binh',
    'Ninh Thuan',
    'Phu Tho',
    'Phu Yen',
    'Quang Binh',
    'Quang Nam',
    'Quang Ngai',
    'Quang Ninh',
    'Quang Tri',
    'Soc Trang',
    'Son La',
    'Tay Ninh',
    'Thai Binh',
    'Thai Nguyen',
    'Thanh Hoa',
    'Thua Thien-Hue',
    'Tien Giang',
    'Tra Vinh',
    'Tuyen Quang',
    'Vinh Long',
    'Vinh Phuc',
    'Yen Bai',
  ],
  nativeName: 'Việt Nam',
  cities: [
    'Hanoi',
    'Ho Chi Minh City',
    'Can Tho',
    'Thanh Nguyen',
    'Dinh Long',
    'Vinh',
    'Nghi Loc',
    'Phu Xuyen',
    'Tan Thuan Dong',
    'Nam Äá»‹nh',
    'Thanh Pho Thai Nguyen',
    'Haiphong',
    'Huáº¿',
    'Da Nang',
    'Da Lat',
    'Khanh Hoa',
    'Quang Trung',
    'An Thanh',
    'Long An',
    'Thanh Pho Ninh Binh',
    'Thanh Pho Hoa Binh',
    'Tan Binh',
    'Xom Truong Dinh',
    'Le Loi',
    'Phuong Tan',
    'Ap Trung',
    'Binh Thanh',
    'Xom Thang',
    'Thu Dau Mot',
    'Bach Ma',
    'Dien Bien Phu',
    'Long Van',
    'Ben Nghe',
    'Phu Nhuan',
    'Ap Sai Gon',
    'Go Vap',
    'Truong Son',
    'Thong Nhat',
    'Thanh Pho Thai Binh',
    'Van Tan',
    'An Tam',
    'Co Giang',
    'Quan Ngoc',
    'Nha Be',
    'Thu Duc',
    'Bac Giang',
    'Tang',
    'Ha Long',
    'Thanh Pho Hai Duong',
    'Bac Ninh',
    'Nha Trang',
    'Pleiku',
    'VÅ©ng TÃ u',
    'Thuan An',
    'Hung Yen',
    'Thanh Pho Tuyen Quang',
    'Ha Nam',
    'Bien Hoa',
    'Di An',
    'Vinh Yen',
    'Luong Hoi',
    'Phan Thiáº¿t',
    'Quáº£ng NgÃ£i',
    'Qui Nhon',
    'Buon Ma Thuot',
    'Yen Thanh',
    'Bac Kan',
    'Lao Cai',
    'Lang Son',
    'Phu Tho',
    'Yen Bai',
    'SÆ¡n La',
    'Thanh Pho Ha Giang',
    'An Dinh',
    'Quan Trang',
    'Thanh HÃ³a',
    'Huong Tinh',
    'Quang Tri',
    'Quang Nam',
    'Giong Ngai',
    'An Nhon',
    'Dac Loc',
    'Phu Yen',
    'Kon Tum',
    'Dong',
    'Binh Thoai',
    'Thon Bao An',
    'Binh Phuoc',
    'Long Tan',
    'Báº¿n Tre',
    'Dong Thap',
    'An Giang',
    'Ca Mau',
    'Soc Trang',
    'Tay Ninh',
    'Má»¹ Tho',
    'Dong Nai',
    'Ha Tinh',
    'Lang Dung',
    'Ha Dong',
    'Hoang Mai',
    'Cho Ha',
    'Tra Vinh',
    'Thach That',
    'Bang',
    'Xom Phuoc My',
    'Cam Pha',
    'Vinh Long',
    'Vi Thanh',
    'Phan Thon',
    'Chuong Duong',
    'Minh Khai',
    'Giong Truong',
    'Ben Than',
    'Thuan Cong',
    'Duc Thang',
    'Lang Hong',
    'Van Hai',
    'Tam Ky',
    'Truong Lam',
    'Trao',
  ],
  tld: ['.vn'],
  cca2: 'VN',
  ccn3: '704',
  cca3: 'VNM',
  cioc: 'VIE',
  currency: ['VND'],
  callingCode: ['84'],
  capital: 'Hanoi',
  altSpellings: [
    'VN',
    'Socialist Republic of Vietnam',
    'Cộng hòa Xã hội chủ nghĩa Việt Nam',
    'Viet Nam',
  ],
  region: 'Asia',
  subregion: 'South-Eastern Asia',
  languages: { vie: 'Vietnamese' },
  translations: {
    deu: { official: 'Sozialistische Republik Vietnam', common: 'Vietnam' },
    fra: { official: 'République socialiste du Viêt Nam', common: 'Viêt Nam' },
    hrv: { official: 'Socijalistička Republika Vijetnam', common: 'Vijetnam' },
    ita: { official: 'Repubblica socialista del Vietnam', common: 'Vietnam' },
    jpn: { official: 'ベトナム社会主義共和国', common: 'ベトナム' },
    nld: { official: 'Socialistische Republiek Vietnam', common: 'Vietnam' },
    por: { official: 'República Socialista do Vietname', common: 'Vietname' },
    rus: { official: 'Социалистическая Республика Вьетнам', common: 'Вьетнам' },
    spa: { official: 'República Socialista de Vietnam', common: 'Vietnam' },
    fin: { official: 'Vietnamin sosialistinen tasavalta', common: 'Vietnam' },
  },
  latlng: [16.16666666, 107.83333333],
  demonym: 'Vietnamese',
  landlocked: false,
  borders: ['KHM', 'CHN', 'LAO'],
  area: 331212,
  timezones: ['UTC+07:00'],
} as const;
