export const PLW = {
  name: {
    common: 'Palau',
    official: 'Republic of Palau',
    native: {
      eng: { official: 'Republic of Palau', common: 'Palau' },
      pau: { official: 'Beluu er a Belau', common: 'Belau' },
    },
  },
  tld: ['.pw'],
  cca2: 'PW',
  ccn3: '585',
  cca3: 'PLW',
  cioc: 'PLW',
  currency: ['USD'],
  callingCode: ['680'],
  capital: 'Ngerulmud',
  altSpellings: ['PW', 'Republic of Palau', 'Beluu er a Belau'],
  region: 'Oceania',
  subregion: 'Micronesia',
  languages: { eng: 'English', pau: 'Palauan' },
  translations: {
    deu: { official: 'Palau', common: 'Palau' },
    fra: {
      official: 'République des Palaos (Palau)',
      common: 'Palaos (Palau)',
    },
    hrv: { official: 'Republika Palau', common: 'Palau' },
    ita: { official: 'Repubblica di Palau', common: 'Palau' },
    jpn: { official: 'パラオ共和国', common: 'パラオ' },
    nld: { official: 'Republiek van Palau', common: 'Palau' },
    por: { official: 'República de Palau', common: 'Palau' },
    rus: { official: 'Республика Палау', common: 'Палау' },
    spa: { official: 'República de Palau', common: 'Palau' },
    fin: { official: 'Palaun tasavalta', common: 'Palau' },
  },
  latlng: [7.5, 134.5],
  demonym: 'Palauan',
  landlocked: false,
  borders: [],
  area: 459,
} as const;
