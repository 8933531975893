
import { Vue, Component, Prop, Watch } from '@vue';
import { defaults } from 'chart.js';
import { Line, mixins } from 'vue-chartjs';

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    ChartLine: ComponentWithProps<Props>;
  }
}

/**
 * `ChartLine` component properties.
 */
export interface Props {
  /** ... */
  chartData: Chart.ChartData | null;
  /** ... */
  options?: Chart.ChartOptions;
  /** ... */
  height?: number;
}

const { elements } = defaults.global;

if (elements?.line) {
  Object.assign(elements.line, {
    tension: 0.4,
    backgroundColor: '#007bff33',
    borderWidth: 3,
    borderColor: '#007bff',
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    capBezierPoints: true,
    fill: true,
  });
}

export interface ChartLine {
  $data: {
    _chart: Chart;
  };
  addPlugin: Line['addPlugin'];
  renderChart: Line['renderChart'];
}

@Component({
  extends: Line,
  mixins: [mixins.reactiveProp],
})
export class ChartLine extends Vue {
  @Prop(Object) readonly chartData!: Chart.ChartData | null;
  @Prop(Object) readonly options?: Chart.ChartOptions;
  @Prop({ type: Number, default: 270 }) readonly height?: number;

  @Watch('chartdata')
  onChartdataUpdated() {
    this.$data._chart.update();
  }

  @Watch('$store.state.theme')
  onThemeChanged() {
    this.makeChart();
  }

  mounted() {
    this.makeChart();
  }

  /**
   * ...
   */
  private makeChart() {
    // ...
    const isDarkTheme = this.$store.state.theme === 'dark';

    const tickFontColor = isDarkTheme ? '#c8cad2' : '#667381';
    const gridLinesColor = isDarkTheme ? '#c8cad229' : '#0000003d';

    const axesOptions = {
      display: true,
      ticks: {
        fontColor: tickFontColor,
      },
      gridLines: {
        display: true,
        color: gridLinesColor,
        borderDash: [2, 2],
      },
    };

    const options: Chart.ChartOptions = {
      scales: {
        xAxes: [axesOptions],
        yAxes: [axesOptions],
      },
      responsive: true,
      maintainAspectRatio: false,
    };

    this.renderChart(this.chartData ?? {}, options);
  }
}

export default ChartLine;
