import { orderBy, uniqBy } from 'lodash';

import { COUNTRY_DATA, CCA2 } from './data';
import { countryInfo } from './country-info';

/**
 * ...
 */
export interface CountryInfo {
  name: string;
  value: CCA2;
  provinces: string[];
}

/** List of countries not supported by Zephyr. */
export const BANNED_COUNTRIES = [
  'BH', // Bahrain
  'CN', // China
  'EG', // Egypt
  'IR', // Iran
  'IQ', // Iraq
  'IL', // Israel
  'JO', // Jordan
  'KP', // North Korea
  'KW', // Kuwait
  'LB', // Lebanon
  'LY', // Libya
  'MO', // Macau
  'OM', // Oman
  'PK', // Pakistan
  'QA', // Qatar
  'SA', // Saudi Arabia
  'SY', // Syria
  'AE', // United Arab Emirates
  'YE', // Yemen
];

/**
 * List of countries that (currently) are disallowed from placing orders that
 * require shipping.
 */
export const NO_SHIPMENT_COUNTRIES = [
  'IN', // India
];

const dataItems: CountryInfo[] = [];

for (const { name, cca2, provinces } of orderBy(
  Object.values(COUNTRY_DATA),
  ['name'],
  ['asc'],
)) {
  if (BANNED_COUNTRIES.includes(cca2) || cca2 === 'UK') continue;

  const data = {
    name: name.common,
    value: cca2 as CCA2,
    provinces: provinces ?? [],
  };

  if (cca2 === 'US') {
    dataItems.unshift(data);
  } else {
    dataItems.push(data);
  }
}

/** ... */
export const countries = uniqBy(dataItems, 'value');

export * from './country-info';

/** All countries that the Zephyr shop supports. */
export const billableCountries = orderBy(
  countryInfo,
  [({ code }) => code === 'US', ({ code }) => code === 'CA', 'name'],
  ['desc', 'desc', 'asc'],
).filter(({ code, addressInfo }) => {
  // ...
  if (BANNED_COUNTRIES.includes(code)) return false;

  // ...
  return (
    (!!addressInfo.administrativeArea ||
      !!addressInfo.administrativeAreaOverride) &&
    !!addressInfo.postalCode
  );
});
