var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("Transition", { attrs: { appear: "" } }, [
    _c("div", {
      class: ["uib-modal-backdrop", ..._vm.classList],
      style: {
        transitionDuration: _setup.transitionDuration,
        zIndex: _setup.zIndex,
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }