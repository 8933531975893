import { Module, Action } from '@vuex/decorators';
import { TablePanel } from 'table-panel';

import { faEdit } from '@icons/solid/faEdit';
import { faArrowUp } from '@icons/solid/faArrowUp';
import { faArrowDown } from '@icons/solid/faArrowDown';

import { Scene } from '@models';
import { modals } from '@modals';
import { alert } from '@services/alert';
import { store } from '@store';

const columns: TablePanel.Column<Scene>[] = [
  {
    key: 'id',
    label: 'ID',
    value: 'id',
  },
  {
    key: 'name',
    label: 'Name',
    value: 'name',
  },
  {
    key: 'isPrivate',
    label: 'Private',
    value: 'isPrivate',
    component: 'TableCellPrivateStatus',
  },
  {
    key: 'active',
    label: 'Active',
    value: ({ active }) => (active ? 'Yes' : 'No'),
  },
];

const operations: TablePanel.Row.Operation<Scene>[] = [
  {
    label: 'Edit',
    icon: faEdit,
    fn: ({ id }) => {
      void modals.scene.edit({ sceneId: id });
    },
  },
  {
    label: 'Set to Active',
    icon: faArrowUp,
    fn: ({ id }) => {
      void setSceneState(id, true);
    },
    hidden: ({ active }) => active,
  },
  {
    label: 'Set to Inactive',
    icon: faArrowDown,
    fn: ({ id }) => {
      void setSceneState(id, false);
    },
    hidden: ({ active }) => !active,
  },
];

@Module({ namespaced: true })
export class SceneTablePanel
  extends TablePanel<Scene>
  implements TablePanel.Props<Scene>
{
  readonly module = 'scenes';
  readonly loadAction = 'list';
  readonly pk = 'id';
  readonly label = 'SCENES';
  readonly columns = columns;
  readonly operations = operations;
  readonly createAction = 'createScene';
  readonly deleteAction = 'deleteScene';
  readonly enabled = true;

  @Action
  async createScene() {
    await modals.scene.create();
  }

  @Action
  async deleteScene({ items }: SceneTablePanel.DeleteItemOptions) {
    await modals.confirm.deleteItems({ itemType: 'scenes', items });
  }
}

export namespace SceneTablePanel {
  /** ... */
  export interface DeleteItemOptions {
    items: Scene[];
  }
}

//#region Helper Functions

/**
 * ...
 *
 * @param sceneId ...
 * @param active ...
 */
async function setSceneState(sceneId: Scene['id'], active: boolean) {
  try {
    await store.dispatch('scenes/update', { sceneId, active });
  } catch (err) {
    return alert.error(err);
  }

  if (active) {
    alert.success('Scene set to "Active".');
  } else {
    alert.success('Scene set to "Inactive".');
  }
}

//#endregion Helper Functions
