import { server } from '@api/request';
import { License, Organization } from '@models';
import { isObject } from '@tools/type-guards';
import * as typeGuards from '@tools/type-guards';
/**
 * List all existing licenses.
 *
 * @returns  A list of licenses.
 */
export async function list() {
  return await server.list('v2/admin/licenses', processLicenseData);
}

type PaginatedLicenseResults = ZephyrWeb.Pagination.Results<License>;

export namespace LicenseSearch {
  /**
   * {@link search Search licenses}
   *
   */
  export interface Options {
    licenseKey?: string;
    productId?: string;
    userId?: string;
    limit?: number;
    startKey?: ZephyrWeb.Pagination.PlacementKey;
  }
  /**
   * {@link search Search users} API request response.
   */
  export type Results = PaginatedLicenseResults;
}

/**
 * Paginated License Call
 *
 */
export async function search(options: LicenseSearch.Options) {
  return await server.post(
    'v2/admin/licenses/search',
    options,
    processSearchResults,
  );
}

/**
 * ...
 */
export interface ListByOrganizationOptions {
  organizationId: Organization['id'];
}

/**
 * ...
 *
 * @return A list of licenses.
 */
export async function listByOrganization(options: ListByOrganizationOptions) {
  return server.list(
    `v2/organizations/${options.organizationId}/licenses`,
    processLicenseData,
  );
}

/**
 * ...
 */
export interface CreateOptions {
  productId: License['productId'];
  amount: number;
  emails: string[];
  expiration?: License['expiration'];
}

/**
 * Create a new license.
 *
 * @returns ...
 */
export async function create(options: CreateOptions) {
  await server.post('v2/admin/licenses', options);
}

/**
 * ...
 */
export interface SetStateOptions {
  id: License['id'];
  active: boolean;
}

/**
 * ...
 *
 * @param options ...
 * @returns  ...
 */
export async function setState(options: SetStateOptions) {
  await server.post(`v2/admin/licenses/${options.id}`, {
    active: options.active,
  });
}

/**
 * ...
 */
export interface RedeemOptions {
  key: string;
}

/**
 * Redeem a license using a valid license key.
 *
 * @param options ...
 * @returns  ...
 */
export async function redeem(options: RedeemOptions) {
  await server.post('v2/licenses/redeem', options);
}

/**
 * ...
 */
export interface AssignOptions {
  organizationId: string;
  userId: string;
  licenseKey: string;
}

/**
 * Unassign a license.
 *
 * @param options ...
 * @returns  ...
 */
export async function assign(options: AssignOptions) {
  const { organizationId, ...data } = options;

  await server.post(`v2/organizations/${organizationId}/assign-license`, data);
}

/**
 * ...
 */
export interface UnassignOptions {
  organizationId: string;
  licenseIds: string[];
}

/**
 * Unassign a license.
 *
 * @param options ...
 * @returns  ...
 */
export async function unassign(options: UnassignOptions) {
  await server.post(
    `v2/organizations/${options.organizationId}/unassign-licenses`,
    { licenses: options.licenseIds },
  );
}

//#region Helper Functions

/**
 * ...
 *
 * @param value ...
 * @returns ...
 */
function isValidLicenseData(value: unknown): value is License {
  if (!isObject(value)) return false;

  // TEMP: Use a more strict validation.

  return true;
}
/**
 * ...
 *
 * @param data ...
 * @returns ...
 */
function processSearchResults(data: unknown) {
  if (!isValidSearchResults(data)) {
    throw new Error('Invalid user search result data.');
  }

  return data;
}

/**
 * ...
 *
 * @param value ...
 * @returns ...
 */
function isValidSearchResults(value: unknown): value is LicenseSearch.Results {
  return (
    typeGuards.isObject(value) &&
    typeGuards.isArray(value.items) &&
    (typeGuards.isObject(value.lastEvaluatedKey) ||
      typeGuards.isNull(value.lastEvaluatedKey))
  );
}
/**
 * ...
 *
 * @param data ...
 * @returns s ...
 */
function processLicenseData(data: unknown) {
  if (!isValidLicenseData(data)) {
    throw new Error('Invalid license data item.');
  }

  return { ...data } as License;
}

//#endregion Helper Functions
