var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("b-card", { staticClass: "text-white" }, [
    _c("div", { class: [_setup.hasExpired ? "danger" : "success"] }, [
      _c("span", [_vm._v("Student Account Expires: ")]),
      _vm._v(" "),
      _c("strong", [_vm._v(_vm._s(_setup.displayExpirationDate))]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "pb-2" },
      [
        !_setup.hasExpired
          ? [
              _c("span", [_vm._v("Days Left: ")]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_setup.daysRemaining ?? "--"))]),
            ]
          : [
              _c("Icon", { attrs: { icon: _setup.icon } }),
              _vm._v(" "),
              _c("span", [_vm._v("Your Student Account Has Expired")]),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }