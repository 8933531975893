export const EST = {
  name: {
    common: 'Estonia',
    official: 'Republic of Estonia',
    native: { est: { official: 'Eesti Vabariik', common: 'Eesti' } },
  },
  cities: [
    'Maardu',
    'Kuressaare',
    'Tallinn',
    'Laagri',
    'PÃµlva',
    'VÃµru',
    'Viimsi',
    'Tabasalu',
    'Keila',
    'Rakvere',
    'SillamÃ¤e',
    'Kohtla-JÃ¤rve',
    'Tartu',
    'JÃµhvi',
    'Viljandi',
    'PÃ¤rnu',
    'Paide',
    'TÃ¼ri',
    'Harku',
    'Saku',
    'Vara',
    'Alliku',
    'Kulna',
    'Kohtla',
    'Narva',
    'Tamsalu',
    'Aasmae',
    'KiviÃµli',
    'Ahtma',
    'Toila',
    'Voka',
    'Kaarma',
    'Paldiski',
    'Loo',
    'Sindi',
    'JÃ¼ri',
    'Emmaste',
    'Aespa',
    'Peetri',
    'JÃµgeva',
    'Haapsalu',
    'Lagedi',
    'Haabneeme',
    'Lubja',
    'Venevere',
    'PÃµltsamaa',
    'Vaida',
    'Ardu',
    'Puhja',
    'Otepaeae',
    'Antsla',
    'Palivere',
    'Aaviku',
    'Lepna',
    'Kehra',
    'Kuusalu',
    'Loksa',
    'Elva',
    'Valga',
    'Tabivere',
    'Saue',
    'Rapla',
    'Kohila',
    'Kiili',
    'Lahtse',
    'Muraste',
    'Somerpalu',
    'Tapa',
    'Kose',
    'Haage',
    'JÃµelÃ¤htme Parish',
    'Ambla',
    'Kadrina',
    'Syare',
    'Harju',
    'Rakke',
    'Avinurme',
    'Halinga',
    'Kilingi-NÃµmme',
    'KÃ¤rdla',
    'Paikuse',
  ],
  tld: ['.ee'],
  cca2: 'EE',
  ccn3: '233',
  cca3: 'EST',
  cioc: 'EST',
  currency: ['EUR'],
  callingCode: ['372'],
  capital: 'Tallinn',
  altSpellings: ['EE', 'Eesti', 'Republic of Estonia', 'Eesti Vabariik'],
  region: 'Europe',
  subregion: 'Northern Europe',
  languages: { est: 'Estonian' },
  translations: {
    cym: { official: 'Republic of Estonia', common: 'Estonia' },
    deu: { official: 'Republik Estland', common: 'Estland' },
    fra: { official: "République d'Estonie", common: 'Estonie' },
    hrv: { official: 'Republika Estonija', common: 'Estonija' },
    ita: { official: 'Repubblica di Estonia', common: 'Estonia' },
    jpn: { official: 'エストニア共和国', common: 'エストニア' },
    nld: { official: 'Republiek Estland', common: 'Estland' },
    por: { official: 'República da Estónia', common: 'Estónia' },
    rus: { official: 'Эстонская Республика', common: 'Эстония' },
    spa: { official: 'República de Estonia', common: 'Estonia' },
    fin: { official: 'Viron tasavalta', common: 'Viro' },
  },
  latlng: [59, 26],
  demonym: 'Estonian',
  landlocked: false,
  borders: ['LVA', 'RUS'],
  area: 45227,
  provinces: [
    'Harjumaa (Tallinn)',
    'Hiiumaa (Kardla)',
    'Ida-Virumaa (Johvi)',
    'Jarvamaa (Paide)',
    'Jogevamaa (Jogeva)',
    'Laane-Virumaa (Rakvere)',
    'Laanemaa (Haapsalu)',
    'Parnumaa (Parnu)',
    'Polvamaa (Polva)',
    'Raplamaa (Rapla)',
    'Saaremaa (Kuessaare)',
    'Tartumaa (Tartu)',
    'Valgamaa (Valga)',
    'Viljandimaa (Viljandi)',
    'Vorumaa (Voru)',
  ],
  nativeName: 'Eesti',
  timezones: ['UTC+02:00'],
} as const;
