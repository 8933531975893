var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    _vm._b({ staticClass: "fa-icon" }, "span", { style: _setup.style }, false),
    [
      _c(
        _setup.FontAwesomeIcon,
        _vm._b({}, "FontAwesomeIcon", _vm.$attrs, false)
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }