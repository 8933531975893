export const GRL = {
  name: {
    common: 'Greenland',
    official: 'Greenland',
    native: {
      kal: { official: 'Kalaallit Nunaat', common: 'Kalaallit Nunaat' },
    },
  },
  cities: [
    'Nuussuaq',
    'Kapisillit',
    'Nuuk',
    'Maniitsoq',
    'Qeqertarsuaq',
    'Qaqortoq',
    'Ilulissat',
    'Uummannaq Kommune',
    'Qasigiannguit',
    'Paamiut',
    'Narsaq',
    'Upernavik',
    'Tasiilaq',
    'Sisimiut',
    'Aasiaat',
    'Qeqertat',
    'Narsarsuaq',
  ],
  tld: ['.gl'],
  cca2: 'GL',
  ccn3: '304',
  cca3: 'GRL',
  cioc: '',
  currency: ['DKK'],
  callingCode: ['299'],
  capital: 'Nuuk',
  altSpellings: ['GL', 'Grønland'],
  region: 'Americas',
  subregion: 'Northern America',
  languages: { kal: 'Greenlandic' },
  translations: {
    deu: { official: 'Grönland', common: 'Grönland' },
    fra: { official: 'Groenland', common: 'Groenland' },
    hrv: { official: 'Grenland', common: 'Grenland' },
    ita: { official: 'Groenlandia', common: 'Groenlandia' },
    jpn: { official: 'グリーンランド', common: 'グリーンランド' },
    nld: { official: 'Groenland', common: 'Groenland' },
    por: { official: 'Groenlândia', common: 'Gronelândia' },
    rus: { official: 'Гренландия', common: 'Гренландия' },
    spa: { official: 'Groenlandia', common: 'Groenlandia' },
    fin: { official: 'Groönlanti', common: 'Groönlanti' },
  },
  latlng: [72, -40],
  demonym: 'Greenlandic',
  landlocked: false,
  borders: [],
  area: 2166086,
  provinces: [
    'Avannaa (Nordgronland)',
    'Kitaa (Vestgronland)',
    'Tunu (Ostgronland)',
  ],
  nativeName: 'Kalaallit Nunaat',
} as const;
