import { Action, Module, Mutation, VuexModule } from '@vuex/decorators';
import find from 'lodash/find';
import type { TablePanel } from 'table-panel';

import { api } from '@api';
import type { UserAuditLogsSearch } from '@api/modules/users';
import { OutputLogEvent } from '@models';
import { Root } from '@store';
import { isString } from '@tools/type-guards';

declare module '@vuex/core' {
  export interface Getters {
    'outputLogEvents/findById': OutputLogEvents['findById'];
  }

  export interface CommitMap {
    'outputLogEvents/SET': OutputLogEvents['SET'];
    'outputLogEvents/SET_LAST_EVALUATED_KEY': OutputLogEvents['SET_LAST_EVALUATED_KEY'];
    'outputLogEvents/SET_NEXT_FORWARD_TOKEN': OutputLogEvents['SET_NEXT_FORWARD_TOKEN'];
    'outputLogEvents/SET_NEXT_BACKWARD_TOKEN': OutputLogEvents['SET_NEXT_BACKWARD_TOKEN'];
    'outputLogEvents/CLEAR': OutputLogEvents['CLEAR'];
  }
}

@Module({ namespaced: true })
export class OutputLogEvents
  extends VuexModule<OutputLogEvents.State, Root.State>
  implements OutputLogEvents.State
{
  items: OutputLogEvent[] = [];
  lastEvaluatedKey: string | null = null;
  nextForwardToken: string | null = null;
  nextBackwardToken: string | null = null;
  allResultsLoaded = false;

  /** ... */
  get findById() {
    return (id: string) => find(this.items, { id });
  }

  //#region Mutations

  /**
   * ...
   */
  @Mutation
  SET(options: OutputLogEvents.SetMutationOptions) {
    this.items = options.data;
  }

  /**
   * ...
   */
  @Mutation
  SET_LAST_EVALUATED_KEY(options: { lastEvaluatedKey: string | null }) {
    this.lastEvaluatedKey = options.lastEvaluatedKey;
  }

  /**
   * ...
   */
  @Mutation
  SET_NEXT_FORWARD_TOKEN(options: { token: string | null }) {
    this.nextForwardToken = options.token;
  }

  /**
   * ...
   */
  @Mutation
  SET_NEXT_BACKWARD_TOKEN(options: { token: string | null }) {
    this.nextBackwardToken = options.token;
  }

  /**
   * ...
   */
  @Mutation
  CLEAR() {
    this.items = [];
    this.lastEvaluatedKey = null;
    this.allResultsLoaded = false;
  }

  //#endregion Mutations

  //#region Actions

  /**
   * ...
   */
  @Action
  async loadPage(options: TablePanel.LoadPageOptions<OutputLogEvent>) {
    let items: OutputLogEvent[] = [];
    let data: OutputLogEvent[] = [];
    let lastEvaluatedKey: string | null = null;

    const userId = options.params?.['userId'];

    if (isString(userId)) {
      const searchOptions = {
        userId,
      } as UserAuditLogsSearch.Options;

      if (this.lastEvaluatedKey && !options.clearPrevious) {
        searchOptions.nextToken = this.lastEvaluatedKey;
        data = [...this.items];
      }

      const res = await api.users.searchAuditLogs(searchOptions);

      items = res.events ?? [];
      lastEvaluatedKey = res.nextBackwardToken ?? null;
    }

    data.push(...items);

    this.context.commit('SET', { data });
    this.context.commit('SET_LAST_EVALUATED_KEY', { lastEvaluatedKey });

    return { items, lastEvaluatedKey };
  }

  //#endregion Actions
}

export namespace OutputLogEvents {
  /**
   * ...
   */
  export interface State {
    items: OutputLogEvent[];
    lastEvaluatedKey: string | null;
    nextForwardToken: string | null;
    nextBackwardToken: string | null;
    allResultsLoaded: boolean;
  }

  /**
   * ...
   */
  export interface SetMutationOptions {
    data: OutputLogEvent[];
  }
}

export default OutputLogEvents;
