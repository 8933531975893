import { server } from '@api/request';
import * as models from '@models';
import { isObject } from '@tools/type-guards';
import { creditCards } from '@values/credit-cards';

/**
 * ...
 *
 * @return ...
 */
export async function listMine() {
  return await server.list(
    'v2/users/payment-methods',
    processPaymentMethodData,
  );
}

/**
 * ...
 */
export interface GetOptions {
  paymentMethodId: models.PaymentMethod['id'];
}

/**
 * ...
 *
 * @param options ...
 * @return ...
 */
export async function get(options: GetOptions) {
  return await server.get(
    `v2/payment-methods/${options.paymentMethodId}`,
    processPaymentMethodData,
  );
}

/**
 * ...
 */
export interface GetForUserOptions {
  userId: models.User['id'];
}

/**
 * ...
 *
 * @param options ...
 * @return ...
 */
export async function getForUser(options: GetForUserOptions) {
  return await server.list(
    `v2/users/${options.userId}/payment-methods`,
    processPaymentMethodData,
  );
}

/**
 * ...
 */
export type CreateOptions = (
  | { userId: models.User['id'] }
  | { organizationId: models.Organization['id'] }
) & {
  paymentMethodNonce: string;
  firstName: string;
  lastName: string;
  address1: string;
  address2: string | null;
  address3: string | null;
  city: string;
  stateProvince: string;
  postalCode: string | number;
  country: string;
};

/**
 * ...
 *
 * @param options ...
 * @return ...
 */
export async function create(options: CreateOptions) {
  const url =
    'organizationId' in options
      ? `v2/organizations/${options.organizationId}/payment-methods`
      : `v2/users/payment-methods`;

  const payload = {
    paymentMethodNonce: options.paymentMethodNonce,
    firstName: options.firstName,
    lastName: options.lastName,
    address1: options.address1,
    address2: options.address2,
    address3: options.address3,
    city: options.city,
    stateProvince: options.stateProvince,
    postalCode: options.postalCode,
    country: options.country,
  };

  return server.post(url, payload, processPaymentMethodData);
}

/**
 * ...
 */
export interface UpdateOptions {
  paymentMethodId: models.PaymentMethod['id'];
  paymentMethodNonce: string;
  // token?: string;
  firstName?: string;
  lastName?: string;
  company?: string | null;
  address1?: string;
  address2?: string | null;
  address3?: string | null;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
}

/**
 * ...
 *
 * @param options ...
 * @return ...
 */
export async function update(options: UpdateOptions) {
  return await server.post(
    `v2/payment-methods/${options.paymentMethodId}`,
    processPaymentMethodData,
  );
}

/**
 * ...
 */
export interface DeleteOptions {
  paymentMethodId: models.PaymentMethod['id'];
}

/**
 * ...
 *
 * @param options ...
 * @return ...
 */
export async function del(options: DeleteOptions) {
  await server.delete(
    `v2/users/payment-methods/${options.paymentMethodId}`,
  );
}

//#region Helper Functions

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
function isValidPaymentMethodItem(
  value: unknown,
): value is models.PaymentMethod {
  if (!isObject(value)) return false;

  // TEMP: Use a more strict validation.

  return true;
}

/**
 * ...
 *
 * @param data ...
 * @return ...
 */
function processPaymentMethodData(data: unknown) {
  if (!isValidPaymentMethodItem(data)) {
    throw new Error('Invalid payment method data item.');
  }

  // ...
  const cardProfile =
    data.cardType === 'American Express'
      ? creditCards.amex
      : data.cardType === 'Diners'
      ? creditCards.diners
      : data.cardType === 'Discover'
      ? creditCards.discover
      : data.cardType === 'Jcb'
      ? creditCards.jcb
      : data.cardType === 'Maestro'
      ? creditCards.maestro
      : data.cardType === 'MasterCard'
      ? creditCards.mastercard
      : data.cardType === 'UnionPay'
      ? creditCards.unionpay
      : data.cardType === 'Visa'
      ? creditCards.visa
      : null;

  if (!cardProfile) {
    throw new Error(
      'The credit card associated with the provided payment method data was not recognized.',
    );
  }

  const paymentMethod = {
    // ...data,
    id: data.id,
    // id: data.uniqueNumberIdentifier,
    digits: parseInt(data.last4),
    cardType: data.cardType,
    expiration: data.expirationDate,
    token: data.token,
    userId: data.userId ?? null,
    image: cardProfile.image,
    billing: data.billingAddress,
    customerId: data.customerId,
    firstName: data.firstName ?? data.billingAddress.firstName,
    lastName: data.lastName ?? data.billingAddress.lastName,
    company: data.company,
    address1: data.address1,
    address2: data.address2,
    address3: data.address3,
    city: data.city,
    state: data.state,
    zip: data.zip,
    country: data.country,
  };

  return paymentMethod as models.PaymentMethod;
}

//#endregion Helper Functions
