import { server } from '@api/request';
import * as models from '@models';
import { isObject } from '@tools/type-guards';

/**
 * List all existing courses.
 *
 * @return A list of courses.
 */
export async function list() {
  return await server.list('v2/admin/courses', processCourseData);
}

/**
 * ...
 */
export interface ListByOrganizationOptions {
  organizationId: models.Organization['id'];
}

/**
 * ...
 *
 * @return A list of courses.
 */
export async function listByOrganization(options: ListByOrganizationOptions) {
  return server.list(
    `v2/organizations/${options.organizationId}/courses`,
    processCourseData,
  );
}

/**
 * ...
 */
export interface GetOptions {
  courseId: models.Course['id'];
  organizationId: models.Organization['id'];
}

/**
 * Get a specified course.
 *
 * @param options Request options bag.
 * @return The specified course.
 */
export async function get(options: GetOptions) {
  return await server.get(
    `v2/organizations/${options.organizationId}/courses/${options.courseId}`,
    processCourseData,
  );
}

/**
 * ...
 */
export interface CreateOptions {
  organizationId: models.Organization['id'];
  name: string;
  startDate: string;
  endDate: string;
  teacherId: models.User['id'];
  teacherAssistantId?: models.User['id'];
}

/**
 * Create a course.
 *
 * @param options Request options bag.
 * @return The specified course.
 */
export async function create(options: CreateOptions) {
  const { organizationId, ...data } = options;

  return await server.post(
    `v2/organizations/${organizationId}/courses`,
    data,
    processCourseData,
  );
}

/**
 * ...
 */
export interface UpdateOptions {
  courseId: models.Course['id'];
  organizationId: models.Organization['id'];
  name?: string;
  startDate?: string;
  endDate?: string;
  teacherId?: models.User['id'];
  teacherAssistantId?: models.User['id'];
}

/**
 * Update a course.
 *
 * @param options Request options bag.
 * @return The specified course.
 */
export async function update(options: UpdateOptions) {
  const { courseId, organizationId, ...data } = options;

  return await server.post(
    `v2/organizations/${organizationId}/courses/${courseId}`,
    data,
  );
}

/**
 * ...
 */
export interface AddStudentsOptions {
  courseId: models.Course['id'];
  organizationId: models.Organization['id'];
  userIds?: models.User['id'][];
}

/**
 * Add students to a specified course.
 *
 * @param options Request options bag.
 * @return ...
 */
export async function addStudents(options: AddStudentsOptions) {
  const { organizationId, courseId, userIds: users } = options;

  return await server.post(
    `v2/organizations/${organizationId}/courses/${courseId}/add-students`,
    { users },
  );
}

/**
 * ...
 */
export interface RemoveStudentsOptions {
  courseId: models.Course['id'];
  organizationId: models.Organization['id'];
  userIds?: models.User['id'][];
}

/**
 * Remove students from a specified course.
 *
 * @param options Request options bag.
 * @return ...
 */
export async function removeStudents(options: RemoveStudentsOptions) {
  const { organizationId, courseId, userIds: users } = options;

  return await server.post(
    `v2/organizations/${organizationId}/courses/${courseId}/remove-students`,
    { users },
  );
}

/**
 * ...
 */
export interface InviteParticipantsOptions {
  courseId: models.Course['id'];
  organizationId: models.Organization['id'];
  emails: string[];
  roleId: number;
  autoCreateAccounts?: boolean;
}

/**
 * Send invites for a specific course to a list of emails.
 *
 * @param options Request options bag.
 * @return ...
 */
export async function inviteParticipants(options: InviteParticipantsOptions) {
  const { organizationId, ...data } = options;

  const url = options.autoCreateAccounts
    ? `v2/organizations/${organizationId}/bulk-create-users`
    : `v2/organizations/${organizationId}/invites`;

  return await server.post(url, data);
}

/**
 * ...
 */
export interface ExtendLicenseOptions {
  courseId: models.Course['id'];
  organizationId: models.Organization['id'];
  code: string;
  paymentMethodNonce?: string;
  paymentMethodId?: models.PaymentMethod['id'];
}

/**
 * ...
 *
 * @param options Request options bag.
 * @return ...
 */
export async function extendLicense(options: ExtendLicenseOptions) {
  const { courseId, organizationId, ...data } = options;

  return await server.post(
    `v2/organizations/${organizationId}/courses/${courseId}/extend-license`,
    data,
  );
}

/**
 * ...
 */
export interface SendExtensionCodesOptions {
  courseId: models.Course['id'];
  organizationId: models.Organization['id'];
  userIds?: models.User['id'][];
}

/**
 * Send extension code emails to students in a specified course. If a list of
 * user IDs is not provided, an email will be sent to all users of that course
 * that have a license with an expiration date that comes before the course's
 * end date.
 *
 * @param options Options bag for
 */
export async function sendExtensionCodes(options: SendExtensionCodesOptions) {
  const { courseId, organizationId, ...data } = options;

  await server.post(
    `v2/organizations/${organizationId}/courses/${courseId}/send-extension-codes`,
    data,
  );
}

/**
 * ...
 */
export type PurchaseSeatOptions = {
  courseId: models.Course['id'];
  organizationId: models.Organization['id'];
  service: 'Standard' | 'Paypal';
  total: number;
  billingCountry?: string | null;
  billingStateProvince?: string | null;
  billingPostalCode?: string | null;
  paymentMethodNonce?: string;
  paymentMethodId?: models.PaymentMethod['id'];
} & ({ nonce: string } | { method: string });

/**
 * ...
 *
 * @param options Request options bag.
 * @return ...
 */
export async function purchaseSeat(options: PurchaseSeatOptions) {
  const { organizationId, courseId, ...props } = options;

  const data: Record<string, unknown> = {
    service: props.service,
    total: props.total,
  };

  if (props.billingCountry) {
    data['billingCountry'] = props.billingCountry;
  }

  if (props.billingStateProvince) {
    data['billingStateProvince'] = props.billingStateProvince;
  }

  if (props.billingPostalCode) {
    data['billingPostalCode'] = props.billingPostalCode;
  }

  if ('nonce' in props) {
    Object.assign(data, { paymentMethodNonce: props.nonce });
  } else {
    Object.assign(data, { paymentMethodId: props.method });
  }

  return await server.post(
    `v2/organizations/${organizationId}/courses/${courseId}/purchase-seat`,
    data,
  );
}

/**
 * ...
 */
export interface DeleteOptions {
  courseId: models.Course['id'];
  organizationId: models.Organization['id'];
}

/**
 * Delete a specified product.
 *
 * @param options Request options bag.
 */
export async function del(options: DeleteOptions) {
  return await server.delete(
    `v2/organizations/${options.organizationId}/courses/${options.courseId}`,
  );
}

//#region Helper Functions

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
function isValidCourseData(value: unknown): value is models.Course {
  if (!isObject(value)) return false;

  // TEMP: Use a more strict validation.

  return true;
}

/**
 * ...
 *
 * @param data ...
 * @return ...
 */
function processCourseData(data: unknown) {
  if (!isValidCourseData(data)) {
    throw new Error('Invalid course data item.');
  }

  return { ...data } as models.Course;
}

//#endregion Helper Functions
