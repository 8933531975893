export const CXR = {
  name: {
    common: 'Christmas Island',
    official: 'Territory of Christmas Island',
    native: {
      eng: {
        official: 'Territory of Christmas Island',
        common: 'Christmas Island',
      },
    },
  },
  cities: ['Flying Fish Cove'],
  tld: ['.cx'],
  cca2: 'CX',
  ccn3: '162',
  cca3: 'CXR',
  cioc: '',
  currency: ['AUD'],
  callingCode: ['61'],
  capital: 'Flying Fish Cove',
  altSpellings: ['CX', 'Territory of Christmas Island'],
  region: 'Oceania',
  subregion: 'Australia and New Zealand',
  languages: { eng: 'English' },
  translations: {
    cym: {
      official: 'Territory of Christmas Island',
      common: 'Ynys y Nadolig',
    },
    deu: { official: 'Gebiet der Weihnachtsinsel', common: 'Weihnachtsinsel' },
    fra: { official: "Territoire de l'île Christmas", common: 'Île Christmas' },
    hrv: { official: 'Teritorij Božićni otok', common: 'Božićni otok' },
    ita: {
      official: 'Territorio di Christmas Island',
      common: 'Isola di Natale',
    },
    jpn: { official: 'クリスマス島の領土', common: 'クリスマス島' },
    nld: {
      official: 'Grondgebied van Christmas Island',
      common: 'Christmaseiland',
    },
    por: { official: 'Território da Ilha Christmas', common: 'Ilha do Natal' },
    rus: {
      official: 'Территория острова Рождества',
      common: 'Остров Рождества',
    },
    spa: {
      official: 'Territorio de la Isla de Navidad',
      common: 'Isla de Navidad',
    },
    fin: { official: 'Joulusaaren alue', common: 'Joulusaari' },
  },
  latlng: [-10.5, 105.66666666],
  demonym: 'Christmas Island',
  landlocked: false,
  borders: [],
  area: 135,
} as const;
