export const NGA = {
  name: {
    common: 'Nigeria',
    official: 'Federal Republic of Nigeria',
    native: {
      eng: { official: 'Federal Republic of Nigeria', common: 'Nigeria' },
    },
  },
  cities: [
    'Ikeja',
    'Lagos',
    'Maryland',
    'Isolo',
    'Yaba',
    'Iseri-Oke',
    'Shomolu',
    'Ojo',
    'Lekki',
    'Ifako',
    'Ayobo',
    'Suleja',
    'Ipaja',
    'Aganga',
    'Bwari',
    'Uyo',
    'Mushin',
    'Port Harcourt',
    'Aba',
    'Forum',
    'Ibadan',
    'Kano',
    'Benin City',
    'Mowe',
    'Apapa',
    'Ojota',
    'Suru-Lere',
    'Ajuwon',
    'Ikorodu',
    'Bonny',
    'Agege',
    'Birnin Kebbi',
    'Zaria',
    'Warri',
    'Calabar',
    'Orile Oshodi',
    'Ota',
    'Osogbo',
    'Ekpoma',
    'Odau',
    'Damaturu',
    'Yola',
    'Egbeda',
    'Magodo',
    'Ilesa',
    'Onigbongbo',
    'Ughelli',
    'Kaduna',
    'Owerri',
    'Enugu',
    'Ilorin',
    'Jos',
    'Warri',
    'Anambra',
    'Abraka',
    'Abraka',
    'Abuja',
    'Abakaliki',
    'Ado-Ekiti',
    'Jalingo',
    'Ile-Ife',
    'Burutu',
    'Lokoja',
    'Ido',
    'Minna',
    'Obudu',
    'Abeokuta',
    'Oworonsoki',
    'Alimosho',
    'Akure',
    'Makurdi',
    'Funtua',
    'Obafemi',
    'Tara',
    'Nsukka',
    'Adodo',
    'Ondo',
    'Ungwan Madaki',
    'Igueben',
    'Oshodi',
    'Igando',
    'Agidingbi',
    'Ikotun',
    'Ajegunle',
    'Chafe',
    'Mogho',
    'Kebbi',
  ],
  tld: ['.ng'],
  cca2: 'NG',
  ccn3: '566',
  cca3: 'NGA',
  cioc: 'NGR',
  currency: ['NGN'],
  callingCode: ['234'],
  capital: 'Abuja',
  altSpellings: ['NG', 'Nijeriya', 'Naíjíríà', 'Federal Republic of Nigeria'],
  region: 'Africa',
  subregion: 'Western Africa',
  languages: { eng: 'English' },
  translations: {
    deu: { official: 'Bundesrepublik Nigeria', common: 'Nigeria' },
    fra: { official: 'République fédérale du Nigeria', common: 'Nigéria' },
    hrv: { official: 'Savezna Republika Nigerija', common: 'Nigerija' },
    ita: { official: 'Repubblica federale di Nigeria', common: 'Nigeria' },
    jpn: { official: 'ナイジェリア連邦共和国', common: 'ナイジェリア' },
    nld: { official: 'Federale Republiek Nigeria', common: 'Nigeria' },
    por: { official: 'República Federal da Nigéria', common: 'Nigéria' },
    rus: { official: 'Федеративная Республика Нигерия', common: 'Нигерия' },
    spa: { official: 'República Federal de Nigeria', common: 'Nigeria' },
    fin: { official: 'Nigerian liittotasavalta', common: 'Nigeria' },
  },
  latlng: [10, 8],
  demonym: 'Nigerian',
  landlocked: false,
  borders: ['BEN', 'CMR', 'TCD', 'NER'],
  area: 923768,
  provinces: [
    'Abia',
    'Abuja Federal Capital Territory',
    'Adamawa',
    'Akwa Ibom',
    'Anambra',
    'Bauchi',
    'Bayelsa',
    'Benue',
    'Borno',
    'Cross River',
    'Delta',
    'Ebonyi',
    'Edo',
    'Ekiti',
    'Enugu',
    'Gombe',
    'Imo',
    'Jigawa',
    'Kaduna',
    'Kano',
    'Katsina',
    'Kebbi',
    'Kogi',
    'Kwara',
    'Lagos',
    'Nassarawa',
    'Niger',
    'Ogun',
    'Ondo',
    'Osun',
    'Oyo',
    'Plateau',
    'Rivers',
    'Sokoto',
    'Taraba',
    'Yobe',
    'Zamfara',
  ],
  nativeName: 'Nigeria',
  timezones: ['UTC+01:00'],
} as const;
