import { computed, type PropType } from '@vue';
import type { BaseValidation } from '@vuelidate/core';

import { isString, isNotNullish } from '@tools/type-guards';

/** ... */
export type ValidationRuleName = 'required' | 'email';

/** ... */
// export type Field = BaseValidation & Record<ValidationRuleName, boolean>;
export type Field = BaseValidation<string | number | null>;

/**
 * `FormField` base component properties.
 */
export interface FormFieldProps {
  field: Field;
  label: string;
  validate?: Nullable<boolean | 'valid-only'>;
}

/**
 * ...
 */
interface FieldError {
  key: string;
  message: string;
}

/** ... */
type FieldPropType = PropType<FormFieldProps['field']>;
/** ... */
type ValidatePropType = PropType<
  Exclude<FormFieldProps['validate'], undefined>
>;

/** ... */
export const PROP_OPTIONS = {
  field: {
    type: Object as FieldPropType,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  validate: {
    type: [Boolean, String] as ValidatePropType,
    default: () => 'valid-only',
  },
} as const;

/**
 * ...
 *
 * @param props ...
 * @returns ...
 */
export function useFormField(props: FormFieldProps) {
  /** ... */
  const hasValue = computed(() => isNotNullish(props.field.$model));

  /** ... */
  const state = computed(() => {
    // ...
    if (!props.validate || (!hasValue.value && !props.field.$dirty)) {
      return null;
    }

    if (!props.field.$invalid) return true;

    return props.validate === 'valid-only' ? null : false;
  });

  /** ... */
  const errors = computed(() => {
    return props.field.$errors.map(({ $validator, $message }) => ({
      key: $validator,
      message: isString($message) ? $message : $message.value,
    })) as FieldError[];
  });

  /** ... */
  const errorMessage = computed(() => {
    return errors.value[0]?.message ?? null;
  });

  /** ... */
  const showErrorMessage = computed(() => {
    return props.validate === true && !!errorMessage.value;
  });

  return {
    hasValue,
    state,
    errors,
    errorMessage,
    showErrorMessage,
  };
}
