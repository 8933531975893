import { Module } from '@vuex/decorators';
import { TablePanel } from 'table-panel';

import { faEye } from '@icons/solid/faEye';
import { faStickyNote } from '@icons/solid/faStickyNote';

import { modals } from '@modals';
import { StudentAssignmentData } from '@models';
import dateFilter from '@filters/date';
import secToHmsFilter from '@filters/sec-to-hms';

const columns: TablePanel.Column<StudentAssignmentData>[] = [
  {
    key: 'name',
    label: 'Name',
    value: ({ lastName, firstName, email }) => {
      if (lastName && firstName) return `${lastName}, ${firstName} | ${email}`;
      return `${email}`;
    },
  },
  {
    key: 'droneName',
    label: 'Drone',
    value: ({ assignment }) => assignment.report?.drone?.name,
  },
  {
    key: 'completed',
    label: 'Completed',
    value: ({ assignment }) => (assignment.completed ? 'Yes' : 'No'),
  },
  {
    key: 'onTime',
    label: 'On Time',
    value: ({ assignment }) => (assignment.onTime ? 'Yes' : 'No'),
  },
  {
    key: 'score',
    label: 'Score',
    value: ({ assignment }) => assignment.score,
  },
  {
    key: 'totalTime',
    label: 'Total Time (hh:mm:ss)',
    value: ({ assignment }) =>
      assignment.totalTime ? `${secToHmsFilter(assignment.totalTime)}` : 'N/A',
  },
  {
    key: 'completedDate',
    label: 'Completed Date',
    type: 'date',
    value: ({ assignment }) =>
      assignment.completedDate
        ? dateFilter(assignment.completedDate, 'MM/dd/yyyy')
        : 'N/A',
  },
];

const operations: TablePanel.Row.Operation<StudentAssignmentData>[] = [
  {
    label: 'View Single Report',
    icon: faEye,
    fn: ({ assignment }) => {
      void modals.report.viewReport({ report: assignment.report });
    },
  },
  {
    label: 'View All Attempts',
    icon: faStickyNote,
    fn: (student) => {
      void modals.report.viewReports({ user: student });
    },
  },
];

@Module({ namespaced: true })
export class CourseAssignmentTablePanel
  extends TablePanel<StudentAssignmentData>
  implements TablePanel.Props<StudentAssignmentData>
{
  readonly module = 'assignments';
  readonly loadAction = 'listByCourse';
  readonly pk = 'id';
  readonly label = 'ASSIGNMENT DETAILS';
  readonly columns = columns;
  readonly operations = operations;

  readonly enabled = true;
}
