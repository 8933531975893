import { modals } from '@modals';
import { alert } from '@services/alert';
import { ls } from '@services/ls';

/**
 * Amount of time in milliseconds the site should remain idle before warning the
 * user (15 minutes).
 */
const IDLE_WARNING_TRIGGER_TIME = 900_000;

const TIMESTAMP_CACHE_ID = 'zephyr.lastActiveAt';

const USER_INPUT_EVENTS: (keyof WindowEventMap)[] = [
  'mousemove',
  'mousedown',
  'keypress',
  'scroll',
];

export interface IdleMonitorConfigOptions {
  timeLimit?: number;
  /** Callback function to be executed if the idle time limit is reached. */
  limitReachedCallback: () => void;
}

export interface IdleMonitor {
  /** Start the idle monitor. */
  start(): void;
  /** End the idle monitor. */
  end(): void;
}

/**
 * Create an idle monitor to track site user's idle time and respond if the
 * limit is reached.
 */
export function useIdleMonitor(options: IdleMonitorConfigOptions) {
  const { timeLimit = IDLE_WARNING_TRIGGER_TIME, limitReachedCallback } =
    options;

  let idleMonitorIntervalId: number | null = null;

  const start = () => {
    if (idleMonitorIntervalId) return;

    for (const event of USER_INPUT_EVENTS) {
      window.addEventListener(event, setLastActivityTimestamp);
    }

    setLastActivityTimestamp();

    idleMonitorIntervalId = window.setInterval(() => {
      void tick();
    }, 1_000);
  };

  const end = () => {
    if (!idleMonitorIntervalId) return;

    for (const event of USER_INPUT_EVENTS) {
      window.removeEventListener(event, setLastActivityTimestamp);
    }

    window.clearInterval(idleMonitorIntervalId);

    idleMonitorIntervalId = null;
  };

  const tick = async () => {
    const timestamp = getLastActivityTimestamp();

    if (!timestamp) return;

    const diff = Date.now() - timestamp.getTime();

    if (diff <= timeLimit) return;

    end();

    if (DEVELOPMENT) {
      return alert.info(
        'Auto logout was prevented due to the site being in a development environment.',
        true,
      );
    }

    // Prompt user with a final warning and countdown. Reset monitor if they
    // elect to continue their session, and fire the limit-reached callback if
    // they do not.
    if (await modals.account.idleLogoutWarning()) {
      start();
    } else {
      limitReachedCallback();
    }
  };

  return { start, end } as IdleMonitor;
}

function parseTimestampValue(value: string | null) {
  return value ? new Date(value) : null;
}

function getLastActivityTimestamp() {
  return parseTimestampValue(ls.get(TIMESTAMP_CACHE_ID));
}

function setLastActivityTimestamp() {
  ls.set(TIMESTAMP_CACHE_ID, new Date().toISOString());
}
