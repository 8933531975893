import { api } from '@api';
import * as models from '@models';
import { auth } from '@auth';

import { openModal } from '../open-modal';

/**
 * Purchase Cert for Student
 */
export interface PurchaseCertificationForStudentsModalOptions {
  organizationId: models.Organization['id'];
}

/**
 * Purchase Certification on Behalf of Students
 *
 * @param options ...
 * @returns Modal promise.
 */
export async function purchaseCertificationForStudents(
  options: PurchaseCertificationForStudentsModalOptions,
) {
  const { organizationId } = options;

  const organization = await api.organizations.get({
    organizationId,
    admin: auth.isActiveRole(9),
  });

  const modalOptions = {
    title: 'PURCHASE CERTIFICATION FOR STUDENTS',
    props: { organization },
    component: () => import('./PurchaseCertificationForStudents.vue'),
  };

  return openModal.safe(modalOptions);
}
