export const MTQ = {
  name: {
    common: 'Martinique',
    official: 'Martinique',
    native: { fra: { official: 'Martinique', common: 'Martinique' } },
  },
  cities: [
    'Riviere-Salee',
    'Fort-de-France',
    'Le Gros-Morne',
    'Saint-Joseph',
    'Ducos',
    'Le Lamentin',
    'Case-Pilote',
    'Schoelcher',
    'Le Robert',
    'Le Carbet',
    'Sainte-Luce',
    'Les Trois-Ilets',
    "Les Anses-d'Arlet",
    'Le Francois',
    'Sainte-Anne',
    'Le Diamant',
    'Le Morne-Rouge',
    'Sainte-Marie',
    'Saint-Esprit',
  ],
  tld: ['.mq'],
  cca2: 'MQ',
  ccn3: '474',
  cca3: 'MTQ',
  cioc: '',
  currency: ['EUR'],
  callingCode: ['596'],
  capital: 'Fort-de-France',
  altSpellings: ['MQ'],
  region: 'Americas',
  subregion: 'Caribbean',
  languages: { fra: 'French' },
  translations: {
    deu: { official: 'Martinique', common: 'Martinique' },
    fra: { official: 'Martinique', common: 'Martinique' },
    hrv: { official: 'Martinique', common: 'Martinique' },
    ita: { official: 'Martinique', common: 'Martinica' },
    jpn: { official: 'マルティニーク島', common: 'マルティニーク' },
    nld: { official: 'Martinique', common: 'Martinique' },
    por: { official: 'Martinique', common: 'Martinica' },
    rus: { official: 'Мартиника', common: 'Мартиника' },
    spa: { official: 'Martinica', common: 'Martinica' },
    fin: { official: 'Martinique', common: 'Martinique' },
  },
  latlng: [14.666667, -61],
  demonym: 'French',
  landlocked: false,
  borders: [],
  area: 1128,
} as const;
