export const NCL = {
  name: {
    common: 'New Caledonia',
    official: 'New Caledonia',
    native: {
      fra: { official: 'Nouvelle-Calédonie', common: 'Nouvelle-Calédonie' },
    },
  },
  cities: ['Nouville', 'Noumea', 'Mont-Dore', 'Dumbea', 'Paita'],
  tld: ['.nc'],
  cca2: 'NC',
  ccn3: '540',
  cca3: 'NCL',
  cioc: '',
  currency: ['XPF'],
  callingCode: ['687'],
  capital: 'Nouméa',
  altSpellings: ['NC'],
  region: 'Oceania',
  subregion: 'Melanesia',
  languages: { fra: 'French' },
  translations: {
    deu: { official: 'Neukaledonien', common: 'Neukaledonien' },
    fra: { official: 'Nouvelle-Calédonie', common: 'Nouvelle-Calédonie' },
    hrv: { official: 'Nova Kaledonija', common: 'Nova Kaledonija' },
    ita: { official: 'Nuova Caledonia', common: 'Nuova Caledonia' },
    jpn: { official: 'ニューカレドニア', common: 'ニューカレドニア' },
    nld: { official: 'nieuw -Caledonië', common: 'Nieuw-Caledonië' },
    por: { official: 'New Caledonia', common: 'Nova Caledónia' },
    rus: { official: 'Новая Каледония', common: 'Новая Каледония' },
    spa: { official: 'nueva Caledonia', common: 'Nueva Caledonia' },
    fin: { official: 'Uusi-Kaledonia', common: 'Uusi-Kaledonia' },
  },
  latlng: [-21.5, 165.5],
  demonym: 'New Caledonian',
  landlocked: false,
  borders: [],
  area: 18575,
  provinces: ['Iles Loyaute', 'Nord', 'Sud'],
  nativeName: 'Nouvelle-Calédonie',
} as const;
