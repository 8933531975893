var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "ul",
    { staticClass: "sidebar-list" },
    _vm._l(_setup.items, function (item) {
      return _c(
        "li",
        {
          directives: [
            {
              name: "b-tooltip",
              rawName: "v-b-tooltip.left",
              value: _setup.navItemTooltip(item),
              expression: "navItemTooltip(item)",
              modifiers: { left: true },
            },
          ],
          key: item.key,
          staticClass: "sidebar-list-item",
          class: [{ "coming-soon": item.comingSoon }],
        },
        [
          _c(
            "Transition",
            { attrs: { appear: "" } },
            [
              _c(
                "RouterLink",
                _vm._b(
                  {
                    staticClass: "sidebar-link",
                    class: [{ disabled: item.comingSoon }],
                    attrs: { "exact-active-class": "active" },
                  },
                  "RouterLink",
                  item.link,
                  false
                ),
                [
                  _c(
                    "span",
                    { staticClass: "icon-wrapper" },
                    [_c("Icon", { attrs: { icon: item.icon } })],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(item.label))]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }