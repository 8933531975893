import { api } from '@api';
import { Module } from '@models';

import { openModal } from '../open-modal';

/**
 * Open Create Module modal.
 *
 * @return Modal promise.
 */
export async function create() {
  const modalOptions = {
    title: 'CREATE MODULE',
    component: () => import('./CreateModule.vue'),
  };

  await openModal.safe(modalOptions);
}

export interface EditModuleModalOptions {
  moduleId: Module['id'];
}

/**
 * Open Edit Module modal.
 *
 * @return Modal promise.
 */
export async function edit(options: EditModuleModalOptions) {
  const data = await api.modules.get(options);

  const modalOptions = {
    title: 'EDIT MODULE',
    props: { module: data },
    component: () => import('./EditModule.vue'),
  };

  await openModal.safe(modalOptions);
}
