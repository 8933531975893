var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("Transition", { attrs: { appear: "" } }, [
    _setup.display
      ? _c("div", { attrs: { id: "privacy-policy-update-notice" } }, [
          _c(
            "div",
            { staticClass: "notice-content" },
            [
              _c(
                "p",
                [
                  _vm._v(
                    "\n        Hey! Just letting you know we updated our Privacy Policy. You can\n        check them out here\n        "
                  ),
                  _c("RouterLink", { attrs: { to: "/privacy-policy" } }, [
                    _vm._v("Privacy Policy"),
                  ]),
                  _vm._v(
                    ". If you\n        keep using zephyr-sim.com after September 13, 2024, you're letting us\n        know that you've read and agreed to these updates.\n      "
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { variant: "primary" },
                  on: { click: _setup.dismiss },
                },
                [_vm._v("Dismiss")]
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }