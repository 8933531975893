export const GNB = {
  name: {
    common: 'Guinea-Bissau',
    official: 'Republic of Guinea-Bissau',
    native: {
      por: { official: 'República da Guiné-Bissau', common: 'Guiné-Bissau' },
    },
  },
  cities: ['Cacheu', 'Bissau'],
  tld: ['.gw'],
  cca2: 'GW',
  ccn3: '624',
  cca3: 'GNB',
  cioc: 'GBS',
  currency: ['XOF'],
  callingCode: ['245'],
  capital: 'Bissau',
  altSpellings: [
    'GW',
    'Republic of Guinea-Bissau',
    'República da Guiné-Bissau',
  ],
  region: 'Africa',
  subregion: 'Western Africa',
  languages: { por: 'Portuguese' },
  translations: {
    deu: { official: 'Republik Guinea-Bissau', common: 'Guinea-Bissau' },
    fra: { official: 'République de Guinée-Bissau', common: 'Guinée-Bissau' },
    hrv: { official: 'Republika Gvineja Bisau', common: 'Gvineja Bisau' },
    ita: { official: 'Repubblica di Guinea-Bissau', common: 'Guinea-Bissau' },
    jpn: { official: 'ギニアビサウ共和国', common: 'ギニアビサウ' },
    nld: { official: 'Republiek Guinee-Bissau', common: 'Guinee-Bissau' },
    por: { official: 'República da Guiné-Bissau', common: 'Guiné-Bissau' },
    rus: { official: 'Республика Гвинея -Бисау', common: 'Гвинея-Бисау' },
    spa: { official: 'República de Guinea-Bissau', common: 'Guinea-Bisáu' },
    fin: { official: 'Guinea-Bissaun tasavalta', common: 'Guinea-Bissau' },
  },
  latlng: [12, -15],
  demonym: 'Guinea-Bissauan',
  landlocked: false,
  borders: ['GIN', 'SEN'],
  area: 36125,
  provinces: [
    'Bafata',
    'Biombo',
    'Bissau',
    'Bolama-Bijagos',
    'Cacheu',
    'Gabu',
    'Oio',
    'Quinara',
    'Tombali',
  ],
  nativeName: 'Guiné-Bissau',
  timezones: ['UTC'],
} as const;
