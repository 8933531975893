import { server } from '@api/request';
import * as models from '@models';
import * as typeGuards from '@tools/type-guards';

/**
 * List all existing announcements.
 *
 * @return A list of announcements.
 */
export async function list() {
  return await server.list('v2/admin/announcements', processAnnouncementData);
}

/**
 * List My Announcements
 */
export async function listMyAnnouncements() {
  return await server.list('v2/users/announcements', processAnnouncementData);
}

/**
 * List all existing public announcements.
 *
 * @return A list of announcements.
 */
export async function listPublic() {
  return await server.list('v2/announcements/public', processAnnouncementData);
}

/**
 * ...
 */
export interface ListByOrganizationOptions {
  organizationId: models.Organization['id'];
}

/**
 * ...
 *
 * @return A list of announcements.
 */
export async function listByOrganization(options: ListByOrganizationOptions) {
  return await server.list(
    `v2/organizations/${options.organizationId}/announcements`,
    processAnnouncementData,
  );
}

/**
 * ...
 */
export interface ListUserViewedOptions {
  userId: models.User['id'];
}

/**
 * ...
 *
 * @return A list of announcements.
 */
export async function listMyViewed() {
  return await server.list(
    `v2/users/viewed-announcements`,
    processViewedAnnouncementData,
  );
}

/**
 * ...
 */
export interface GetOptions {
  announcementId: models.Announcement['id'];
  organizationId: models.Organization['id'];
}

/**
 * ...
 *
 * @return The specified announcement.
 */
export async function get(options: GetOptions) {
  return await server.get(
    `v2/organizations/${options.organizationId}/announcements/${options.announcementId}`,
    processAnnouncementData,
  );
}

/**
 * ...
 */
export interface CreateOptions {
  organizationId?: models.Organization['id'];
  courseId?: models.Course['id'];
  title: string;
  message: string;
}

/**
 * ...
 *
 * @params options ...
 * @return ...
 */
export async function create(options: CreateOptions) {
  const { organizationId, ...data } = options;

  const route = organizationId
    ? `v2/organizations/${organizationId}/announcements`
    : 'v2/admin/announcements';

  return await server.post(route, data, processAnnouncementData);
}

/**
 * ...
 */
export interface MarkAsViewedOptions {
  announcementId: models.Announcement['id'];
}

/**
 * ...
 *
 * @params options ...
 * @return ...
 */
export async function markAsViewed(options: MarkAsViewedOptions) {
  return await server.post(
    `v2/users/viewed-announcements`,
    options,
    processViewedAnnouncementData,
  );
}

/**
 * ...
 */
export interface UpdateOptions {
  announcementId: models.Announcement['id'];
  organizationId?: models.Organization['id'];
  title?: string;
  message?: string;
}

/**
 * ...
 *
 * @params options ...
 * @return ...
 */
export async function update(options: UpdateOptions) {
  const { organizationId, announcementId, ...data } = options;

  const route = organizationId
    ? `v2/organizations/${organizationId}/announcements/${announcementId}`
    : `v2/admin/announcements/${announcementId}`;

  // TODO: Have Eric return updated announcement in response.
  // return await server.post(route, data, createAnnouncementFromData);
  await server.post(route, data);
}

/**
 * ...
 */
export interface DeleteOptions {
  announcementId: models.Announcement['id'];
  organizationId?: models.Organization['id'];
}

/**
 * Delete a specified announcement.
 *
 * @param options Request options bag.
 */
export async function del(options: DeleteOptions) {
  const { organizationId, announcementId } = options;

  const route = organizationId
    ? `v2/organizations/${organizationId}/announcements/${announcementId}`
    : `v2/admin/announcements/${announcementId}`;

  await server.delete(route);
}

//#region Helper Functions

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
function isValidCreatorInfo(
  value: unknown,
): value is models.Announcement.CreatorInfo {
  if (!typeGuards.isObject(value)) return false;

  return (
    typeGuards.isString(value.id) &&
    typeGuards.isString(value.firstName) &&
    typeGuards.isString(value.lastName) &&
    typeGuards.isString(value.email)
  );
}

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
function isValidOrganizationInfo(
  value: unknown,
): value is models.Announcement.CreatorInfo {
  if (!typeGuards.isObject(value)) return false;

  return typeGuards.isString(value.id) && typeGuards.isString(value.name);
}

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
function isValidCourseInfo(
  value: unknown,
): value is models.Announcement.CreatorInfo {
  if (!typeGuards.isObject(value)) return false;

  return typeGuards.isString(value.id) && typeGuards.isString(value.name);
}

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
function isValidAnnouncementData(value: unknown): value is models.Announcement {
  if (!typeGuards.isObject(value)) return false;

  return (
    typeGuards.isString(value.id) &&
    typeGuards.isString(value.title) &&
    typeGuards.isString(value.message) &&
    isValidCreatorInfo(value.creator) &&
    typeGuards.isOneOf(
      value.organization,
      typeGuards.isNull,
      isValidOrganizationInfo,
    ) &&
    typeGuards.isOneOf(value.course, typeGuards.isNull, isValidCourseInfo)
  );
}

/**
 * ...
 *
 * @param data ...
 * @return ...
 */
function processAnnouncementData(data: unknown) {
  if (!isValidAnnouncementData(data)) {
    throw new Error('Invalid announcement data item.');
  }

  return { ...data, read: false } as models.Announcement;
}

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
function isValidViewedAnnouncementData(
  value: unknown,
): value is models.ViewedAnnouncement {
  if (!typeGuards.isObject(value)) return false;

  return (
    typeGuards.isString(value.id) && typeGuards.isString(value.announcementId)
  );
}

/**
 * ...
 *
 * @param data ...
 * @return ...
 */
function processViewedAnnouncementData(data: unknown) {
  if (!isValidViewedAnnouncementData(data)) {
    throw new Error('Invalid viewed announcement data.');
  }

  return { ...data } as models.ViewedAnnouncement;
}

//#endregion Helper Functions
