var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.CardLoadable,
    _vm._b(
      { attrs: { header: "Reviews Completed", "no-body": "" } },
      "CardLoadable",
      { loading: _setup.loading },
      false
    ),
    [
      _c(
        "b-card-body",
        [
          _c(_setup.ChartLine, {
            attrs: { "chart-data": _setup.certificationChartData },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }