export const ARG = {
  name: {
    common: 'Argentina',
    official: 'Argentine Republic',
    native: {
      grn: { official: 'Argentine Republic', common: 'Argentina' },
      spa: { official: 'República Argentina', common: 'Argentina' },
    },
  },
  cities: [
    'Ingeniero Beaugey',
    'San Isidro',
    'Buenos Aires',
    'Paternal',
    'CÃ³rdoba',
    'Olivos',
    'Florencio Varela',
    'Pilar',
    'Vicente Lopez',
    'Rosario',
    'Federal',
    'San Luis',
    'Puerto Madryn',
    'GualeguaychÃº',
    'Gualeguay',
    'Leones',
    'Corrientes',
    'Mendoza',
    'Rivadavia',
    'Santa Fe',
    'La Pampa',
    'Colon',
    'Cipolletti',
    'Libertad',
    'San Jorge',
    'Trelew',
    'Villaguay',
    'Santiago del Estero',
    'Tortuguitas',
    'San Miguel',
    'Pilar',
    'Paso del Rey',
    'San Telmo',
    'Del Viso',
    'La Plata',
    'Quilmes',
    'Hipolito Yrigoyen',
    'Ituzaingo',
    'San Juan',
    'Villa Elisa',
    'Santos Lugares',
    'Los Polvorines',
    'Jose Marmol',
    'Temperley',
    'Banfield',
    'Ezeiza',
    'Canning',
    'Llavallol',
    'Esteban Echeverria',
    'Gonzalez Moreno',
    'Pico Truncado',
    'Casilda',
    'San Rafael',
    'San Andres de Giles',
    'Luis Guillon',
    'Burzaco',
    'Eduardo Castex',
    'Martinez',
    'Bragado',
    'Mar de Ajo',
    'BahÃ­a Blanca',
    'Villa Regina',
    'Gobernador Galvez',
    'El Talar',
    'Fatima',
    'Lanus',
    'MalargÃ¼e',
    'Alvear',
    'Mariano Acosta',
    'Villa Carlos Paz',
    'Mariano Moreno',
    'San Miguel de TucumÃ¡n',
    'Villa Ballester',
    'Don Torcuato',
    'Matheu',
    'San Miguel',
    'General San Martin',
    'Yerba Buena',
    'Tafi Viejo',
    'ZÃ¡rate',
    'Belen de Escobar',
    'Jose Leon Suarez',
    'RÃ­o Cuarto',
    'La Banda',
    'General Juan Madariaga',
    'San Andres',
    'Tigre',
    'Nordelta',
    'Villa Urquiza',
    'Campana',
    'Villa de Mayo',
    'San Lorenzo',
    'San Fernando',
    'General Pico',
    'Caseros',
    'Garin',
    'Bell Ville',
    'San NicolÃ¡s de los Arroyos',
    'Salta',
    'De Mayo',
    'Thames',
    'Chorroarin',
    'San Martin',
    'Esperanza',
    'Posadas',
    'Frias',
    'Munro',
    'Uriburu',
    'Santa Rita',
    'San Jose',
    'La Rioja',
    'Pueyrredon',
    'Castillo',
    'San Salvador de Jujuy',
    'Concordia',
    'Bariloche',
    'Cervantes',
    'San Fernando del Valle de Catamarca',
    'Rocamora',
    'Apostoles',
    'Beccar',
    'San Miguel',
    'Mercedes',
    'El Palomar',
    'Grand Bourg',
    'Cruz del Eje',
    'Villa MarÃ­a',
    'Villa Huidobro',
    'Margarita',
    'Viamonte',
    'Capilla del Monte',
    'La Para',
    'Huanchillas',
    'Cosquin',
    'Villa de Maria',
    'San Jose de la Esquina',
    'Sacanta',
    'Las Varillas',
    'Ramos Mejia',
    'Godoy Cruz',
    'Ushuaia',
    'Rio Grande',
    'City Bell',
    'Mar del Plata',
    'Merlo',
    'Las Heras',
    'Rafael Calzada',
    'Claypole',
    'Avellaneda',
    'Lomas de Zamora',
    'Tristan Suarez',
    'Remedios de Escalada',
    'Longchamps',
    'La Union',
    'Dock Sud',
    'Punta Alta',
    'Castelar',
    'Moron',
    'Hurlingham',
    'Don Bosco',
    'Bernal',
    'Balcarce',
    'Moreno',
    'Miramar',
    'Batan',
    'Lomas del Mirador',
    'Villa Madero',
    'Benavidez',
    'San Justo',
    'General Pacheco',
    'Mariano J. Haedo',
    'Palermo',
    'Almagro',
    'Florida',
    'Humahuaca',
    'Mendiolaza',
    'Oncativo',
    'Rio Segundo',
    'Victoria',
    'Rio Ceballos',
    'Francisco Alvarez',
    'La Boca',
    'Berazategui',
    'Obera',
    'Resistencia',
    'San Benito',
    'Puerto IguazÃº',
    'Santa Rosa',
    'Ramallo',
    'Intendente Alvear',
    'Ingeniero Luiggi',
    'Bernardo Larroude',
    'Winifreda',
    'Rancul',
    'Capitan Bermudez',
    'Reconquista',
    'Tandil',
    'Caleta Olivia',
    'Caballito',
    'Wilde',
    'General Roca',
    'Berisso',
    'Boulogne',
    'Belgrano',
    'Plottier',
    'NeuquÃ©n',
    'Guatrache',
    'Goya',
    'San Salvador',
    'Palpala',
    'Las Rosas',
    'Carlos Pellegrini',
    'Rafaela',
    'Villa Gesell',
    'San Bernardo',
    'Tres Arroyos',
    'Darregueira',
    'San Clemente',
    'Castelli',
    'Comodoro Rivadavia',
    'RÃ­o Gallegos',
    'ParanÃ¡',
    'Laborde',
    'JunÃ­n',
    'El Calafate',
    'Curuzu Cuatia',
    'Mercedes',
    'Minacar',
    'Bosques',
    'Piamonte',
    'San Antonio de Padua',
    'Marcos Paz',
    'Tostado',
    'Huinca Renanco',
    'General Deheza',
    'General Cabrera',
    'Villa Alsina',
    'Ciudadela',
    'Tablada',
    'Villa Luzuriaga',
    'Chacarita',
    'Villa Dominico',
    'General Las Heras',
    'Flores',
    'Sarandi',
    'Montserrat',
    'Aldo Bonzi',
    'La Tablada',
    'Lynch',
    'Oliva',
    'Perito Moreno',
    'Navarro',
    'Tornquist',
    'RÃ­o Turbio',
    'El BolsÃ³n',
    'Rawson',
    'Vera',
    'Coronel Martinez de Hoz',
    'Pigue',
    'Villa Rosa',
    'Catriel',
    'San Gregorio',
    'Viedma',
    'Presidente Derqui',
    'Villa Nueva',
    'Alberdi',
    'El Hoyo',
    'General Acha',
    'Coy Aike',
    'San Cayetano',
    'San Miguel del Monte',
    'Leandro N. Alem',
    'General Alvear',
    '28 de Noviembre',
    'Abasto',
    'OlavarrÃ­a',
    'General Mansilla',
    'Monte Hermoso',
    'Brandsen',
    'Villa Ocampo',
    'Manuel J. Cobo',
    'General San Martin',
    'Salto',
    'San Manuel',
    'Bordenave',
    'Suipacha',
    'San Antonio de Obligado',
    'Fernandez',
    'Partido de Jose C. Paz',
    'Gonzalez Catan',
    'Pontevedra',
    'Capilla del Senor',
    'Chivilcoy',
    'LujÃ¡n',
    'Isidro Casanova',
    'Laferrere',
    'Rafael Castillo',
    'Bella Vista',
    'Ciudad General Belgrano',
    'Tapiales',
    'Ezpeleta',
    'Platanos',
    'Santa Lucia',
    'Villa Mercedes',
    'Maipu',
    'Tunuyan',
    'Corralitos',
    'Lujan de Cuyo',
    'Magdalena',
    'Ensenada',
    'San Antonio Oeste',
    'Alejandro Korn',
    'Roque Perez',
    'Guernica',
    'Glew',
    'San Vicente',
    'Navarro',
    'Lobos',
    'San Francisco Solano',
    'Almirante Brown',
    'Ranelagh',
    'San Francisco de Santa Fe',
    'Devoto',
    'Pico de Salamanca',
    'Azul',
    'Pedro Luro',
    'Villa Adelina',
    'Las Talitas',
    'Formosa',
    'San Salvador',
    'Villa Allende',
    'Valle Hermoso',
    'ConcepciÃ³n del Uruguay',
    'Canada de Gomez',
    'Banda del Rio Sali',
    'Villa Bosch',
    'Victoria',
    'Chateaubriand',
    'Oliveros',
    'Despenaderos',
    'Bandera',
    'Chacabuco',
    'Viale',
    'Maquinista Savio',
    'Necochea',
    'Adelia Maria',
    'Monje',
    'Coronel Dorrego',
    'Crespo',
    'Ceres',
    'Baradero',
    'Villa Trinidad',
    'San Guillermo',
    'Canada del Ucle',
    'La Leonesa',
    'Tupungato',
    'Palmira',
    'Allen',
    'Villa Insuperable',
    'Open Door',
    'Cinco Saltos',
    'El Palomar',
    'General Rodriguez',
    'La Lucila',
    'Villa del Parque',
    'Turdera',
    'Funes',
    'Avellaneda',
    'Canada Rosquin',
    'Villa La Angostura',
    'Rufino',
    'Salguero',
    'Rojas',
    'Barrio Fisherton',
    'Rosas',
    'General Paz',
    'Villa Media Agua',
    'Alicia',
    'Ranchos',
    'Puan',
    'Pueblo San Jose',
    'Huanguelen',
    'La Calera',
    'Santa Teresita',
    'Villa Lugano',
    'Granadero Baigorria',
    'Sunchales',
    'Angelica',
    'Bombal',
    'Villa Amelia',
    'Hersilia',
    'Maria Juana',
    'Villa Mercedes',
    'La Paz',
    'Alberti',
    'Antonio Carboni',
    'Pedernales',
    'Belgrano',
    'Venado Tuerto',
    'Rio Tercero',
    'Justiniano Posse',
    'Roldan',
    'Mar del Tuyu',
    'General Viamonte',
    'Pergamino',
    'San Francisco',
    'Salsipuedes',
    'Pilar',
    'San Pedro',
    'Cavanagh',
    'Pavon',
    'La Cumbre',
    'Azara',
    'Chilecito',
    'Alta Gracia',
    'General Roca',
    'Recoleta',
    'La Falda',
    'Saladillo',
    'Trenque Lauquen',
    'Esquel',
    'Sierra de los Padres',
    'Santa Clara de Saguier',
    'Arroyito',
    'Arroyito',
    'El Trebol',
    'Empalme Lobos',
    'Nunez',
    'Villa Maria Grande',
    'Santa Elena',
    'Arrecifes',
    'Todd',
    'Coronel Pringles',
    'Chajari',
    'Freyre',
    'Tapalque',
    'Totoras',
    'Henderson',
    'Carhue',
    'Colonia San Miguel Arcangel',
    'Choele Choel',
    'Colon',
    'Cutral-Co',
    'Jauregui',
    'Muniz',
    'Bernasconi',
    'Pasco',
    'James Craik',
    'Rafael Obligado',
    'La Madrid',
    'Catrilo',
    'Caleufu',
    'Alta Italia',
    'Colonia Baron',
    'Realico',
    'Miramar',
    'Balnearia',
    'Centenario',
    'Canuelas',
    'Alvarez',
    'Franck',
    'Plaza Huincul',
    'Sarmiento',
    'Luque',
    'Colonia Caroya',
    'Jesus Maria',
    'Ordonez',
    'Fray Luis A. Beltran',
    'Hipatia',
    'Zenon Pereyra',
    'Villa Giardino',
    'Lavalle',
    'San Isidro',
    'San Isidro de Lules',
    'San Martin de las Escobas',
    'Armstrong',
    'Eldorado',
    'Bustinza',
    'Vicuna Mackenna',
    'Lima',
    'Floresta',
    'Rawson',
    'General Lavalle',
    'Gaiman',
    'Dolores',
    'Trevelin',
    'Luzuriaga',
    'Ibicuy',
    'San Cristobal',
    'Concepcion',
    'San Javier',
    'Segui',
    'Veinticinco de Mayo',
    'Villa Canas',
    'Nogoya',
    'Larroque',
    'Veronica',
    'Liniers',
    'Rio Colorado',
    'Arequito',
    'Los Molinos',
    'Arteaga',
    'Canals',
    'Godoy',
    'Ascension',
    'Sampacho',
    'Constituyentes',
    'Acevedo',
    'Vuelta de Obligado',
    'Albardon',
    'Marcos Juarez',
    'Independencia',
    'Ibarlucea',
    'Perez',
    'Rodriguez Pena',
    'Santa Lucia',
    'Villa Constitucion',
    'Bigand',
    'Maipu',
    'Manantial',
    'San Genaro',
    'General Villegas',
    'Arroyo Seco',
    'Firmat',
    'Merlo',
    'Acassuso',
    'La Violeta',
    'San Pedro',
    'Charata',
    'Bella Vista',
    'Saenz Pena',
    'Monteros',
    'Santo Tome',
    'Garupa',
    'Sierra de la Ventana',
    'General Lagos',
    'Juan Pujol',
    'Rio Piedras',
    'Ayacucho',
    'Bosch',
    'Diaz',
    'San Jeronimo Norte',
    'Jeppener',
    'Rivadavia',
    'Tio Pujio',
    'La Paz',
    'Almafuerte',
    'Tacuari',
    'Rivadavia',
    'Sanchez',
    'Concepcion',
    'Maggiolo',
    'Villa Dolores',
    'El Dorado',
    'Perico',
    'Villa Las Rosas',
    'Embalse',
    'Diego de Alvear',
    'San Antonio de Arredondo',
    'Bovril',
    'Anatuya',
    'Pozo del Molle',
    'Magdalena',
    'Las Flores',
    'Pellegrini',
    'San Carlos de Bolivar',
    'Salto Grande',
    'General Guido',
    'Teodelina',
    'Vedia',
    'Las Flores',
    'Arroyito Challaco',
    'Villa Paranacito',
    'Morteros',
    'Las Perdices',
    'Corral de Bustos',
    'Asamblea',
    'Albarellos',
    'Gobernador Crespo',
    'Villa Nueva',
    'Camilo Aldao',
    'Retiro',
    'Hernando',
    'Galvez',
    'Brinkmann',
    'Agua de Oro',
    'Rada Tilly',
    'Famailla',
    'Villa Aberastain',
    'Hilario Ascasubi',
    'Manfredi',
    'Drabble',
    'Hilario',
    'Piquete Cabado',
    'Los Antiguos',
    'Laboulaye',
    'Sinsacate',
    'Ucacha',
    'General Martin Miguel de Guemes',
    'San Nicolas',
    'Los Cardales',
    'Doblas',
    'Pinamar',
    'Villa General Belgrano',
    'San MartÃ­n de los Andes',
    'La Punta',
    'Las Catitas',
    'Florentino Ameghino',
    'Portena',
    'Carlos Casares',
    'Wheelwright',
    'Mayor Buratovich',
    'Germania',
    'Saladillo',
    'Sarmiento',
    'Carlos Tejedor',
    'Melincue',
    'Clorinda',
    'PehuajÃ³',
    'Veinticinco de Mayo',
    'Warnes',
    'Maria Ignacia',
    'Villa Robles',
    'Macachin',
    'Laguna Alsina',
    'Puerto Rico',
    'Acebal',
    'Del Campillo',
    'Villa Angela',
    'Ingeniero Maschwitz',
    'San Antonio de Areco',
    'Cramer',
    'Calderon',
  ],
  tld: ['.ar'],
  cca2: 'AR',
  ccn3: '032',
  cca3: 'ARG',
  cioc: 'ARG',
  currency: ['ARS'],
  callingCode: ['54'],
  capital: 'Buenos Aires',
  altSpellings: ['AR', 'Argentine Republic', 'República Argentina'],
  region: 'Americas',
  subregion: 'South America',
  languages: { grn: 'Guaraní', spa: 'Spanish' },
  translations: {
    cym: { official: 'Argentine Republic', common: 'Ariannin' },
    deu: { official: 'Argentinische Republik', common: 'Argentinien' },
    fra: { official: 'République argentine', common: 'Argentine' },
    hrv: { official: 'Argentinski Republika', common: 'Argentina' },
    ita: { official: 'Repubblica Argentina', common: 'Argentina' },
    jpn: { official: 'アルゼンチン共和国', common: 'アルゼンチン' },
    nld: { official: 'Argentijnse Republiek', common: 'Argentinië' },
    por: { official: 'República Argentina', common: 'Argentina' },
    rus: { official: 'Аргентинская Республика', common: 'Аргентина' },
    spa: { official: 'República Argentina', common: 'Argentina' },
    fin: { official: 'Argentiinan tasavalta', common: 'Argentiina' },
  },
  latlng: [-34, -64],
  demonym: 'Argentinean',
  landlocked: false,
  borders: ['BOL', 'BRA', 'CHL', 'PRY', 'URY'],
  area: 2780400,
  provinces: [
    'Antartica e Islas del Atlantico Sur',
    'Buenos Aires',
    'Buenos Aires Capital Federal',
    'Catamarca',
    'Chaco',
    'Chubut',
    'Cordoba',
    'Corrientes',
    'Entre Rios',
    'Formosa',
    'Jujuy',
    'La Pampa',
    'La Rioja',
    'Mendoza',
    'Misiones',
    'Neuquen',
    'Rio Negro',
    'Salta',
    'San Juan',
    'San Luis',
    'Santa Cruz',
    'Santa Fe',
    'Santiago del Estero',
    'Tierra del Fuego',
    'Tucuman',
  ],
  nativeName: 'Argentina',
  timezones: ['UTC−03:00'],
} as const;
