export const MDA = {
  name: {
    common: 'Moldova',
    official: 'Republic of Moldova',
    native: { ron: { official: 'Republica Moldova', common: 'Moldova' } },
  },
  cities: [
    'ChiÅŸinÄƒu',
    'Tiraspol',
    'Comrat',
    'EdineÅ£',
    'Tighina',
    'Anenii Noi',
    'BÄƒlÅ£i',
    'Orhei',
    'Ialoveni',
    'RÃ®bniÅ£a',
    'Soroca',
    'Nisporeni',
    'Cahul',
    'Briceni',
    'Magdacesti',
    'Straseni',
    'Ciorescu',
    'Durlesti',
    'Cojusna',
    'Vulcanesti',
    'Drochia',
    'Calarasi',
    'Voinescu',
  ],
  tld: ['.md'],
  cca2: 'MD',
  ccn3: '498',
  cca3: 'MDA',
  cioc: 'MDA',
  currency: ['MDL'],
  callingCode: ['373'],
  capital: 'Chișinău',
  altSpellings: [
    'MD',
    'Moldova, Republic of',
    'Republic of Moldova',
    'Republica Moldova',
  ],
  region: 'Europe',
  subregion: 'Eastern Europe',
  languages: { ron: 'Moldavian' },
  translations: {
    deu: { official: 'Republik Moldau', common: 'Moldawie' },
    fra: { official: 'République de Moldavie', common: 'Moldavie' },
    hrv: { official: 'Moldavija', common: 'Moldova' },
    ita: { official: 'Repubblica di Moldova', common: 'Moldavia' },
    jpn: { official: 'モルドバ共和国', common: 'モルドバ共和国' },
    nld: { official: 'Republiek Moldavië', common: 'Moldavië' },
    por: { official: 'República da Moldávia', common: 'Moldávia' },
    rus: { official: 'Молдова', common: 'Молдавия' },
    spa: { official: 'República de Moldova', common: 'Moldavia' },
    fin: { official: 'Moldovan tasavalta', common: 'Moldova' },
  },
  latlng: [47, 29],
  demonym: 'Moldovan',
  landlocked: true,
  borders: ['ROU', 'UKR'],
  area: 33846,
  provinces: [
    'Balti',
    'Cahul',
    'Chisinau',
    'Chisinau',
    'Dubasari',
    'Edinet',
    'Gagauzia',
    'Lapusna',
    'Orhei',
    'Soroca',
    'Tighina',
    'Ungheni',
  ],
  nativeName: 'Moldova',
  timezones: ['UTC+02:00'],
} as const;
