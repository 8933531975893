export const NIU = {
  name: {
    common: 'Niue',
    official: 'Niue',
    native: {
      eng: { official: 'Niue', common: 'Niue' },
      niu: { official: 'Niuē', common: 'Niuē' },
    },
  },
  cities: ['Alofi'],
  tld: ['.nu'],
  cca2: 'NU',
  ccn3: '570',
  cca3: 'NIU',
  cioc: '',
  currency: ['NZD'],
  callingCode: ['683'],
  capital: 'Alofi',
  altSpellings: ['NU'],
  region: 'Oceania',
  subregion: 'Polynesia',
  languages: { eng: 'English', niu: 'Niuean' },
  translations: {
    deu: { official: 'Niue', common: 'Niue' },
    fra: { official: 'Niue', common: 'Niue' },
    hrv: { official: 'Niue', common: 'Niue' },
    ita: { official: 'Niue', common: 'Niue' },
    jpn: { official: 'ニウエ', common: 'ニウエ' },
    nld: { official: 'Niue', common: 'Niue' },
    por: { official: 'Niue', common: 'Niue' },
    rus: { official: 'Ниуэ', common: 'Ниуэ' },
    spa: { official: 'Niue', common: 'Niue' },
    fin: { official: 'Niue', common: 'Niue' },
  },
  latlng: [-19.03333333, -169.86666666],
  demonym: 'Niuean',
  landlocked: false,
  borders: [],
  area: 260,
} as const;
