import Vue from 'vue';

import { isNumber, isString } from '@tools/type-guards';

declare global {
  interface HTMLElement {
    /** ... */
    _vue_scroll_trigger_handler?: (event: Event) => unknown;
  }
}

/** ... */
type Element = Parameters<Vue.DirectiveFunction>[0];
/** ... */
type Binding = Parameters<Vue.DirectiveFunction>[1];
/** ... */
type VNode = Parameters<Vue.DirectiveFunction>[2];

/**
 * Directive `bind` function.
 */
function bind(el: Element, binding: Binding, vnode: VNode) {
  // If the source element has a `_vue_clickaway_handler` function, remove it
  // and it's corresponding event listener from the node and page, respectively.
  unbind(el);

  const vm = vnode.context;

  // If the binding value is not a function, produce a warning and abort
  // the bind.
  if (!isNumber(binding.value) && !isString(binding.value)) {
    return invalidParameterWarning(binding);
  }

  // ...
  const range = isNumber(binding.value)
    ? binding.value
    : parseFloat(binding.value);

  // if (value < -1 || value > 1) {
  //   throw new Error(
  //     `[ScrollTrigger] provided value "${value}" for "range" was invalid -- must be between -1 and 1.`,
  //   );
  // }

  let triggered = false;

  // ...
  const handler = () => {
    if (triggered || !el) return;

    const winCenter = 0.5 * window.innerHeight;

    // ...
    const { top, height } = el.getBoundingClientRect();

    // ...
    const center = top + 0.5 * height;
    // ...
    const threshold = Math.min(
      1,
      Math.max(-1, (winCenter - center) / winCenter),
    );

    if (threshold <= range) return;

    triggered = true;

    el.classList.add('triggered');
  };

  // Add the handler to the source node so it can later be used to remove
  // the event listner should the directive be unbound.
  el._vue_scroll_trigger_handler = handler;

  window.addEventListener('scroll', handler, false);
}

/**
 * Directive `unbind` function.
 */
function unbind(el: Element) {
  // ...
  if (!el._vue_scroll_trigger_handler) return;

  window.removeEventListener('scroll', el._vue_scroll_trigger_handler, false);

  delete el._vue_scroll_trigger_handler;
}

/**
 * Directive `update` function.
 */
function update(el: Element, binding: Binding, vnode: VNode) {
  if (binding.value !== binding.oldValue) bind(el, binding, vnode);
}

/**
 * `scrollTrigger` directive options.
 */
export const scrollTrigger: Vue.DirectiveOptions = { bind, unbind, update };

export default scrollTrigger;

// region Helper Functions

/**
 * ...
 */
function invalidParameterWarning({ name, expression }: Binding) {
  Vue.util.warn(`v-${name}="${expression ?? ''}" expects a number value.`);
}

// endregion Helper Functions
