export const UGA = {
  name: {
    common: 'Uganda',
    official: 'Republic of Uganda',
    native: {
      eng: { official: 'Republic of Uganda', common: 'Uganda' },
      swa: { official: 'Republic of Uganda', common: 'Uganda' },
    },
  },
  timezones: ['UTC+03:00'],
  nativeName: 'Uganda',
  provinces: [
    'Adjumani',
    'Apac',
    'Arua',
    'Bugiri',
    'Bundibugyo',
    'Bushenyi',
    'Busia',
    'Gulu',
    'Hoima',
    'Iganga',
    'Jinja',
    'Kabale',
    'Kabarole',
    'Kalangala',
    'Kampala',
    'Kamuli',
    'Kapchorwa',
    'Kasese',
    'Katakwi',
    'Kibale',
    'Kiboga',
    'Kisoro',
    'Kitgum',
    'Kotido',
    'Kumi',
    'Lira',
    'Luwero',
    'Masaka',
    'Masindi',
    'Mbale',
    'Mbarara',
    'Moroto',
    'Moyo',
    'Mpigi',
    'Mubende',
    'Mukono',
    'Nakasongola',
    'Nebbi',
    'Ntungamo',
    'Pallisa',
    'Rakai',
    'Rukungiri',
    'Sembabule',
    'Soroti',
    'Tororo',
  ],
  cities: ['Kampala'],
  tld: ['.ug'],
  cca2: 'UG',
  ccn3: '800',
  cca3: 'UGA',
  cioc: 'UGA',
  currency: ['UGX'],
  callingCode: ['256'],
  capital: 'Kampala',
  altSpellings: ['UG', 'Republic of Uganda', 'Jamhuri ya Uganda'],
  region: 'Africa',
  subregion: 'Eastern Africa',
  languages: { eng: 'English', swa: 'Swahili' },
  translations: {
    deu: { official: 'Republik Uganda', common: 'Uganda' },
    fra: { official: "République de l'Ouganda", common: 'Ouganda' },
    hrv: { official: 'Republika Uganda', common: 'Uganda' },
    ita: { official: 'Repubblica di Uganda', common: 'Uganda' },
    jpn: { official: 'ウガンダ共和国', common: 'ウガンダ' },
    nld: { official: 'Republiek Uganda', common: 'Oeganda' },
    por: { official: 'República do Uganda', common: 'Uganda' },
    rus: { official: 'Республика Уганда', common: 'Уганда' },
    spa: { official: 'República de Uganda', common: 'Uganda' },
    fin: { official: 'Ugandan tasavalta', common: 'Uganda' },
  },
  latlng: [1, 32],
  demonym: 'Ugandan',
  landlocked: true,
  borders: ['COD', 'KEN', 'RWA', 'SSD', 'TZA'],
  area: 241550,
} as const;
