var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.Notifications, {
    attrs: {
      group: "reg",
      classes: "app-notification",
      width: "480px",
      position: "top center",
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ item, close }) {
          return [
            _c(
              "div",
              {
                class: ["regular-notification", `type-${item.type}`],
                on: { click: close },
              },
              [
                _c("div", { staticClass: "notification-panel" }, [
                  _c(
                    "button",
                    { staticClass: "btn-dismiss" },
                    [_c(_setup.Icon, { attrs: { icon: _setup.faTimes } })],
                    1
                  ),
                  _vm._v(" "),
                  item.title
                    ? _c("h3", { domProps: { innerHTML: _vm._s(item.title) } })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("pre", {
                    staticClass: "message",
                    domProps: { innerHTML: _vm._s(item.text) },
                  }),
                ]),
              ]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }