export const PRI = {
  name: {
    common: 'Puerto Rico',
    official: 'Commonwealth of Puerto Rico',
    native: {
      eng: { official: 'Commonwealth of Puerto Rico', common: 'Puerto Rico' },
      spa: {
        official: 'Estado Libre Asociado de Puerto Rico',
        common: 'Puerto Rico',
      },
    },
  },
  cities: [
    'BayamÃ³n',
    'Dorado',
    'Carolina',
    'Rio Grande',
    'Guaynabo',
    'San Juan',
    'Caguas',
    'Salinas',
    'Vega Baja',
    'Morovis',
    'Arecibo',
    'Catano',
    'Toa Baja',
    'Las Piedras',
    'Corozal',
    'Aguas Buenas',
    'Punta Santiago',
    'Luquillo',
    'Fajardo',
    'Ciales',
    'Humacao',
    'Juncos',
    'Barceloneta',
    'Hatillo',
    'Manati',
    'Vega Alta',
    'Naguabo',
    'Saint Just',
    'Trujillo Alto',
    'Lares',
    'San Sebastian',
    'Camuy',
    'Bajadero',
    'Gurabo',
    'San Lorenzo',
    'Aibonito',
    'Cayey',
    'Santa Isabel',
    'Ponce',
    'Guayanilla',
    'Villalba',
    'Aguadilla',
    'Isabela',
    'Moca',
    'Mayaguez',
    'Hormigueros',
    'Guayama',
    'Maunabo',
    'Patillas',
    'Ensenada',
    'Sabana Grande',
    'Barranquitas',
    'Utuado',
    'Garrochales',
    'Cidra',
    'Loiza',
    'Canovanas',
    'Yabucoa',
    'Arroyo',
    'Coamo',
    'Cabo Rojo',
    'Aguada',
    'Juana Diaz',
    'Rincon',
    'Anasco',
    'Guanica',
    'Boqueron',
    'Quebradillas',
    'Toa Alta',
    'Ceiba',
    'Yauco',
    'Palmer',
    'Florida',
    'Penuelas',
    'Lajas',
    'San German',
    'Residencial Puerto Real',
    'Orocovis',
    'Maricao',
    'Naranjito',
    'Adjuntas',
    'Sabana Seca',
    'Vieques',
    'San Antonio',
    'Jayuya',
    'Culebra',
    'Coto Laurel',
    'Mercedita',
  ],
  tld: ['.pr'],
  cca2: 'PR',
  ccn3: '630',
  cca3: 'PRI',
  cioc: 'PUR',
  currency: ['USD'],
  callingCode: ['1787', '1939'],
  capital: 'San Juan',
  altSpellings: [
    'PR',
    'Commonwealth of Puerto Rico',
    'Estado Libre Asociado de Puerto Rico',
  ],
  region: 'Americas',
  subregion: 'Caribbean',
  languages: { eng: 'English', spa: 'Spanish' },
  translations: {
    deu: { official: 'Commonwealth von Puerto Rico', common: 'Puerto Rico' },
    fra: { official: 'Porto Rico', common: 'Porto Rico' },
    hrv: { official: 'Zajednica Puerto Rico', common: 'Portoriko' },
    ita: { official: 'Commonwealth di Porto Rico', common: 'Porto Rico' },
    jpn: { official: 'プエルトリコのコモンウェルス', common: 'プエルトリコ' },
    nld: { official: 'Gemenebest van Puerto Rico', common: 'Puerto Rico' },
    por: { official: 'Commonwealth of Puerto Rico', common: 'Porto Rico' },
    rus: { official: 'Содружество Пуэрто-Рико', common: 'Пуэрто-Рико' },
    spa: { official: 'Asociado de Puerto Rico', common: 'Puerto Rico' },
    fin: { official: 'Puerto Rico', common: 'Puerto Rico' },
  },
  latlng: [18.25, -66.5],
  demonym: 'Puerto Rican',
  landlocked: false,
  borders: [],
  area: 8870,
  provinces: [
    'Adjuntas',
    'Aguada',
    'Aguadilla',
    'Aguas Buenas',
    'Aibonito',
    'Anasco',
    'Arecibo',
    'Arroyo',
    'Barceloneta',
    'Barranquitas',
    'Bayamon',
    'Cabo Rojo',
    'Caguas',
    'Camuy',
    'Canovanas',
    'Carolina',
    'Catano',
    'Cayey',
    'Ceiba',
    'Ciales',
    'Cidra',
    'Coamo',
    'Comerio',
    'Corozal',
    'Culebra',
    'Dorado',
    'Fajardo',
    'Florida',
    'Guanica',
    'Guayama',
    'Guayanilla',
    'Guaynabo',
    'Gurabo',
    'Hatillo',
    'Hormigueros',
    'Humacao',
    'Isabela',
    'Jayuya',
    'Juana Diaz',
    'Juncos',
    'Lajas',
    'Lares',
    'Las Marias',
    'Las Piedras',
    'Loiza',
    'Luquillo',
    'Manati',
    'Maricao',
    'Maunabo',
    'Mayaguez',
    'Moca',
    'Morovis',
    'Naguabo',
    'Naranjito',
    'Orocovis',
    'Patillas',
    'Penuelas',
    'Ponce',
    'Quebradillas',
    'Rincon',
    'Rio Grande',
    'Sabana Grande',
    'Salinas',
    'San German',
    'San Juan',
    'San Lorenzo',
    'San Sebastian',
    'Santa Isabel',
    'Toa Alta',
    'Toa Baja',
    'Trujillo Alto',
    'Utuado',
    'Vega Alta',
    'Vega Baja',
    'Vieques',
    'Villalba',
    'Yabucoa',
    'Yauco',
  ],
  nativeName: 'Puerto Rico',
} as const;
