export const KOR = {
  name: {
    common: 'South Korea',
    official: 'Republic of Korea',
    native: { kor: { official: '한국', common: '대한민국' } },
  },
  cities: [
    'Seoul',
    'Incheon',
    'Paju',
    'Cheonan',
    'Yongin',
    'Kwanghui-dong',
    'Pon-dong',
    'Gwangju',
    'Gwangmyeong',
    'Tang-ni',
    'Busan',
    'Seongnam-si',
    'Suwon-si',
    'Namyang',
    'Namyangju',
    'Jeju-si',
    'Ulsan',
    'Osan',
    'Hanam',
    'Pyong-gol',
    'Anyang-si',
    'Yangsan',
    'Daejeon',
    'Nonsan',
    'Seocho',
    'Wonju',
    'Kisa',
    'Daegu',
    'Ansan-si',
    'Gongju',
    'Haeundae',
    'Sasang',
    'Bucheon-si',
    'Chuncheon',
    'Ilsan-dong',
    'Naju',
    'Jinju',
    'Uiwang',
    'Gangneung',
    'Yongsan-dong',
    'Pohang',
    'Changwon',
    'Jeonju',
    'Yeosu',
    'Songnim',
    'Gimhae',
    'Songjeong',
    'Hyoja-dong',
    'Icheon-si',
    'Kimso',
    'Iksan',
    'Deokjin',
    'Koyang-dong',
    'Samsung',
    'Anseong',
    'Samjung-ni',
    'Mapo-dong',
    'Gunnae',
    'Nae-ri',
    'Suncheon',
    'Okpo-dong',
    'Moppo',
    'Sangdo-dong',
    'Cheongju-si',
    "Ch'aeun",
    'Taebuk',
    'Yeoju',
    'Seong-dong',
    'Duchon',
    'Gyeongju',
    'Andong',
    'Seosan City',
    'Asan',
    'Miryang',
    'Wonmi-gu',
    'Janghowon',
    'Chungnim',
    'Songam',
    'Tongan',
    "Ap'o",
    'Jecheon',
    'Se-ri',
    'Ka-ri',
    'Hansol',
    'Songang',
    'Hyangyang',
    'Gyeongsan-si',
    'Gumi',
    'Unpo',
    'Ulchin',
    'Namhyang-dong',
    "T'aebaek",
    'Hadong',
    'Haesan',
    'Chungju',
    'Chilgok',
  ],
  tld: ['.kr', '.한국'],
  cca2: 'KR',
  ccn3: '410',
  cca3: 'KOR',
  cioc: 'KOR',
  currency: ['KRW'],
  callingCode: ['82'],
  capital: 'Seoul',
  altSpellings: ['KR', 'Korea, Republic of', 'Republic of Korea'],
  region: 'Asia',
  subregion: 'Eastern Asia',
  languages: { kor: 'Korean' },
  translations: {
    deu: { official: 'Republik Korea', common: 'Südkorea' },
    fra: { official: 'République de Corée', common: 'Corée du Sud' },
    hrv: { official: 'Republika Koreja', common: 'Južna Koreja' },
    ita: { official: 'Repubblica di Corea', common: 'Corea del Sud' },
    jpn: { official: '大韓民国', common: '大韓民国' },
    nld: { official: 'Republiek Korea', common: 'Zuid-Korea' },
    por: { official: 'República da Coreia', common: 'Coreia do Sul' },
    rus: { official: 'Республика Корея', common: 'Южная Корея' },
    spa: { official: 'República de Corea', common: 'Corea del Sur' },
    fin: { official: 'Korean tasavalta', common: 'Etelä-Korea' },
  },
  latlng: [37, 127.5],
  demonym: 'South Korean',
  landlocked: false,
  borders: ['PRK'],
  area: 100210,
  provinces: [
    "Ch'ungch'ong-bukto",
    "Ch'ungch'ong-namdo",
    'Cheju-do',
    'Cholla-bukto',
    'Cholla-namdo',
    "Inch'on-gwangyoksi",
    'Kangwon-do',
    'Kwangju-gwangyoksi',
    'Kyonggi-do',
    'Kyongsang-bukto',
    'Kyongsang-namdo',
    'Pusan-gwangyoksi',
    "Soul-t'ukpyolsi",
    'Taegu-gwangyoksi',
    'Taejon-gwangyoksi',
    'Ulsan-gwangyoksi',
  ],
  nativeName: '대한민국',
  timezones: ['UTC+09:00'],
} as const;
