var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "site-documentV2" }, [
    _setup.loading
      ? _c("div", [_c("Spinner")], 1)
      : _c(
          "div",
          _vm._l(
            _setup.items,
            function (
              {
                key,
                type,
                text,
                raw,
                classList,
                depth,
                style,
                htmlParsed,
                tabs,
              },
              index
            ) {
              return _c(
                "div",
                { key: key },
                [
                  type === "heading"
                    ? _c(
                        "b-row",
                        { class: classList },
                        [
                          _c("b-col", [
                            depth === 1
                              ? _c(
                                  "h1",
                                  {
                                    class: classList ?? [
                                      "doc-section-title",
                                      "h1",
                                      "mt-3",
                                    ],
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(text) +
                                        "\n          "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            depth === 2
                              ? _c(
                                  "h2",
                                  {
                                    class: classList ?? [
                                      "doc-section-title",
                                      "h2",
                                      "mt-3",
                                    ],
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(text) +
                                        "\n          "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            depth === 3
                              ? _c(
                                  "h3",
                                  {
                                    class: classList ?? [
                                      "doc-section-title",
                                      "h3",
                                      "mt-3",
                                    ],
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(text) +
                                        "\n          "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            depth === 4
                              ? _c(
                                  "h4",
                                  {
                                    class: classList ?? [
                                      "doc-section-title",
                                      "h4",
                                      "mt-3",
                                    ],
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(text) +
                                        "\n          "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    {
                      style: {
                        textAlign: type === "paragraph" ? "justify" : "unset",
                      },
                      attrs: { "align-h": "between" },
                    },
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "12" } },
                        [
                          type === "list"
                            ? _c(
                                "div",
                                { staticClass: "site-document-list-container" },
                                [
                                  type === "list"
                                    ? [
                                        _c("ul", {
                                          domProps: { innerHTML: _vm._s(text) },
                                        }),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          type === "paragraph"
                            ? [
                                _c("p", {
                                  class: [classList ?? ["m-1"]],
                                  domProps: { innerHTML: _vm._s(text) },
                                }),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  type === "tabs"
                    ? _c("b-row", [
                        _c(
                          "div",
                          {
                            class: [
                              classList ?? [
                                "site-document-tabs-container",
                                "mt-3",
                                "w-full",
                              ],
                            ],
                          },
                          [
                            _c(
                              "b-tabs",
                              {
                                attrs: {
                                  "active-nav-item-class":
                                    "font-weight-bold site-document-tabs",
                                  "content-class": " w-full mx-auto",
                                },
                              },
                              _vm._l(tabs, function ({ title, tabParsed }) {
                                return _c(
                                  "b-tab",
                                  {
                                    key: key + title,
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "title",
                                          fn: function () {
                                            return [
                                              title.includes("🪟")
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c("Icon", {
                                                        staticStyle: {
                                                          "font-size": "2em",
                                                          color:
                                                            "var(--primary)",
                                                        },
                                                        attrs: {
                                                          icon: _setup.faWindows,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : title.includes("🍎")
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c("Icon", {
                                                        staticStyle: {
                                                          "font-size": "2em",
                                                          color: "lightgray",
                                                        },
                                                        attrs: {
                                                          icon: _setup.faApple,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _c("div", [
                                                    _vm._v(_vm._s(title)),
                                                  ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _vm._v(" "),
                                    _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(tabParsed),
                                      },
                                    }),
                                  ]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  type === "table"
                    ? _c("table", {
                        class: [classList ?? ["table"]],
                        domProps: { innerHTML: _vm._s(htmlParsed) },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  type === "hint-info"
                    ? _c(
                        "b-row",
                        [
                          _c("b-col", [
                            _c(
                              "div",
                              { staticClass: "hint-info-card mb-3 mt-3" },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { display: "flex" } },
                                  [
                                    _c("Icon", {
                                      staticStyle: { "padding-right": "10px" },
                                      attrs: { icon: _setup.faCircleInfo },
                                    }),
                                    _vm._v(" "),
                                    _c("div", {
                                      class: classList,
                                      domProps: { innerHTML: _vm._s(text) },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  type === "hint-warning"
                    ? _c(
                        "b-row",
                        [
                          _c("b-col", [
                            _c(
                              "div",
                              { staticClass: "hint-warning-card mb-3 mt-3" },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { display: "flex" } },
                                  [
                                    _c("Icon", {
                                      staticStyle: { "padding-right": "10px" },
                                      attrs: { icon: _setup.faLightbulbOn },
                                    }),
                                    _vm._v(" "),
                                    _c("div", {
                                      class: classList,
                                      domProps: { innerHTML: _vm._s(text) },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  type === "hint-danger"
                    ? _c(
                        "b-row",
                        [
                          _c("b-col", [
                            _c(
                              "div",
                              { staticClass: "hint-danger-card mb-3 mt-3" },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { display: "flex" } },
                                  [
                                    _c("Icon", {
                                      staticStyle: { "padding-right": "10px" },
                                      attrs: {
                                        icon: _setup.faTriangleExclamation,
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("div", {
                                      class: classList,
                                      domProps: { innerHTML: _vm._s(text) },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            }
          ),
          0
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }