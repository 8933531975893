export const BEN = {
  name: {
    common: 'Benin',
    official: 'Republic of Benin',
    native: { fra: { official: 'République du Bénin', common: 'Bénin' } },
  },
  cities: ['Cotonou', 'Abomey-Calavi'],
  tld: ['.bj'],
  cca2: 'BJ',
  ccn3: '204',
  cca3: 'BEN',
  cioc: 'BEN',
  currency: ['XOF'],
  callingCode: ['229'],
  capital: 'Porto-Novo',
  altSpellings: ['BJ', 'Republic of Benin', 'République du Bénin'],
  region: 'Africa',
  subregion: 'Western Africa',
  languages: { fra: 'French' },
  translations: {
    cym: { official: 'Republic of Benin', common: 'Benin' },
    deu: { official: 'Republik Benin', common: 'Benin' },
    fra: { official: 'République du Bénin', common: 'Bénin' },
    hrv: { official: 'Republika Benin', common: 'Benin' },
    ita: { official: 'Repubblica del Benin', common: 'Benin' },
    jpn: { official: 'ベナン共和国', common: 'ベナン' },
    nld: { official: 'Republiek Benin', common: 'Benin' },
    por: { official: 'República do Benin', common: 'Benin' },
    rus: { official: 'Республика Бенин', common: 'Бенин' },
    spa: { official: 'República de Benin', common: 'Benín' },
    fin: { official: 'Beninin tasavalta', common: 'Benin' },
  },
  latlng: [9.5, 2.25],
  demonym: 'Beninese',
  landlocked: false,
  borders: ['BFA', 'NER', 'NGA', 'TGO'],
  area: 112622,
  provinces: [
    'Alibori',
    'Atakora',
    'Atlantique',
    'Borgou',
    'Collines',
    'Couffo',
    'Donga',
    'Littoral',
    'Mono',
    'Oueme',
    'Plateau',
    'Zou',
  ],
  nativeName: 'Bénin',
  timezones: ['UTC+01:00'],
} as const;
