const CHARACTERS = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
];

/**
 * Generate a random alphanumeric ID (string) of an optional specified length.
 */
export function randomId(length = 6) {
  let id = '';

  for (let i = 0; i < length; i++) {
    id += CHARACTERS[Math.floor(Math.random() * CHARACTERS.length)] as string;
  }

  return id;
}
