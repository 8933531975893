var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    _vm._b({ staticClass: "faq-card my-4" }, "div", { id: _vm.id }, false),
    [
      _c(
        "div",
        { staticClass: "faq-title", on: { click: _setup.toggle } },
        [
          _c("span", [
            _c(
              "span",
              { staticClass: "faq-marker-container" },
              [
                _vm.topFaq
                  ? _c("Icon", { attrs: { icon: _setup.faStar } })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
          ]),
          _vm._v(" "),
          _c("Icon", {
            attrs: { icon: _setup.open ? _setup.faMinus : _setup.faPlus },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "faq-tags" },
        [
          _c(
            "div",
            { staticClass: "tag" },
            [_c("Icon", { attrs: { icon: _setup.faTag } })],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.tags, function (tag) {
            return _c("div", { key: tag, staticClass: "tag" }, [
              _vm._v(_vm._s(tag)),
            ])
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        {
          attrs: { role: "tabpanel" },
          model: {
            value: _setup.open,
            callback: function ($$v) {
              _setup.open = $$v
            },
            expression: "open",
          },
        },
        [
          _c("div", { staticClass: "faq-body-container" }, [
            _vm.subjects && _vm.subjects.length
              ? _c(
                  "div",
                  { staticClass: "faq-subjects" },
                  _vm._l(_vm.subjects, function (subject) {
                    return _c("div", { key: subject, staticClass: "subject" }, [
                      _vm._v("\n          " + _vm._s(subject) + "\n        "),
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "faq-body" },
              [
                _vm._l(_setup.elements, function ({ type, content }, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "faq-body-content" },
                    [
                      type === "text"
                        ? _c("div", {
                            ref: "text",
                            refInFor: true,
                            domProps: { innerHTML: _vm._s(content) },
                          })
                        : type === "video"
                        ? _c("div", [
                            _c("iframe", {
                              attrs: {
                                width: "560",
                                height: "315",
                                src: content,
                                frameborder: "0",
                                allow:
                                  "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
                                allowfullscreen: "",
                              },
                            }),
                          ])
                        : type === "image"
                        ? _c("div", [_c("img", { attrs: { src: content } })])
                        : _vm._e(),
                    ]
                  )
                }),
                _vm._v(" "),
                _vm.showChat
                  ? _c(
                      "div",
                      {
                        staticClass: "contact-support-prompt",
                        on: { click: _setup.contactSupport },
                      },
                      [
                        _vm._v(
                          "\n          Still not finding what you are looking for? Contact Us\n        "
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              2
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }