export const VEN = {
  name: {
    common: 'Venezuela',
    official: 'Bolivarian Republic of Venezuela',
    native: {
      spa: {
        official: 'República Bolivariana de Venezuela',
        common: 'Venezuela',
      },
    },
  },
  provinces: [
    'Amazonas',
    'Anzoategui',
    'Apure',
    'Aragua',
    'Barinas',
    'Bolivar',
    'Carabobo',
    'Cojedes',
    'Delta Amacuro',
    'Dependencias Federales',
    'Distrito Federal',
    'Falcon',
    'Guarico',
    'Lara',
    'Merida',
    'Miranda',
    'Monagas',
    'Nueva Esparta',
    'Portuguesa',
    'Sucre',
    'Tachira',
    'Trujillo',
    'Vargas',
    'Yaracuy',
    'Zulia',
  ],
  nativeName: 'Venezuela',
  cities: [
    'Puerto La Cruz',
    'Caracas',
    'Ocumare',
    'El Roque',
    'Guachara',
    'Santa Fe',
    'Torre',
    'Maracaibo',
    'Valencia',
    'MÃ©rida',
    'Puerto Ordaz and San Felix',
    'Guayana',
    'Ciudad Guayana',
    'San Felix',
    'Maracay',
    'Barcelona',
    'Cabimas',
    'San Jose de Guanipa',
    'Guarenas',
    'Mariguitar',
    'Macuto',
    'Ciudad BolÃ­var',
    'Punto Fijo',
    'Barquisimeto',
    'San Diego',
    'Carrizal',
    'San Antonio de Los Altos',
    'Los Teques',
    'Cabudare',
    'Acarigua',
    'Araure',
    'Valera',
    'Guatire',
    'La Guaira',
    'Caraballeda',
    'Catia La Mar',
    'MaturÃ­n',
    'Santa Cruz del Zulia',
    'Bachaquero',
    'Maiquetia',
    'Valle',
    'Naguanagua',
    'La Cruz',
    'Porlamar',
    'Tucupita',
    'Calabozo',
    'Campo de Carabobo',
    'Charallave',
    'La Victoria',
    'Santa Teresa',
    'Gueiria',
    'Vargas',
    'La Villa del Rosario',
    'Ciudad Ojeda',
    'Turmero',
    'Cagua',
    'San Felipe',
    'Trujillo',
    'Anaco',
    'San CristÃ³bal',
    'El Tigre',
    'CumanÃ¡',
    'Miranda',
    'San Antonio',
    'Pampatar',
    'El Recreo',
    'Ciudad Piar',
    'Chivacoa',
    'Londres',
    'Coro',
    'Caripe',
    'Bejuma',
    'Matanzas',
    'El Rosal',
    'La Verdad',
    'Monte Cristo',
    'El Chorro',
    'Solano',
    'Maria',
    'San Joaquin',
    'La Urbina',
    'Los Chorros',
    'La Castellana',
    'Curazaito',
    'Guanare',
    'Caja de Agua',
    'Barinas',
    'Bolivar',
    'Puerto Cabello',
    'San Francisco',
    'Cua',
    'Colonia Tovar',
    'Tariba',
    'El Vigia',
    'Sabaneta',
  ],
  tld: ['.ve'],
  cca2: 'VE',
  ccn3: '862',
  cca3: 'VEN',
  cioc: 'VEN',
  currency: ['VEF'],
  callingCode: ['58'],
  capital: 'Caracas',
  altSpellings: [
    'VE',
    'Bolivarian Republic of Venezuela',
    'Venezuela, Bolivarian Republic of',
    'República Bolivariana de Venezuela',
  ],
  region: 'Americas',
  subregion: 'South America',
  languages: { spa: 'Spanish' },
  translations: {
    deu: { official: 'Bolivarische Republik Venezuela', common: 'Venezuela' },
    fra: {
      official: 'République bolivarienne du Venezuela',
      common: 'Venezuela',
    },
    hrv: {
      official: 'BOLIVARIJANSKA Republika Venezuela',
      common: 'Venezuela',
    },
    ita: {
      official: 'Repubblica Bolivariana del Venezuela',
      common: 'Venezuela',
    },
    jpn: {
      official: 'ベネズエラ·ボリバル共和国',
      common: 'ベネズエラ・ボリバル共和国',
    },
    nld: { official: 'Bolivariaanse Republiek Venezuela', common: 'Venezuela' },
    por: {
      official: 'República Bolivariana da Venezuela',
      common: 'Venezuela',
    },
    rus: {
      official: 'Боливарианская Республика Венесуэла',
      common: 'Венесуэла',
    },
    spa: {
      official: 'República Bolivariana de Venezuela',
      common: 'Venezuela',
    },
    fin: {
      official: 'Venezuelan bolivariaainen tasavalta',
      common: 'Venezuela',
    },
  },
  latlng: [8, -66],
  demonym: 'Venezuelan',
  landlocked: false,
  borders: ['BRA', 'COL', 'GUY'],
  area: 916445,
  timezones: ['UTC−04:30'],
} as const;
