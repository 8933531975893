import { Module, VuexModule, Mutation, Action } from '@vuex/decorators';
import { find, findIndex } from 'lodash';

import { api } from '@api';
import { Transaction } from '@models';
import { Root } from '@store';

declare module '@vuex/core' {
  export interface Getters {
    'transactions/findById': Transactions['findById'];
  }

  export interface CommitMap {
    'transactions/SET': Transactions['SET'];
    'transactions/ADD': Transactions['ADD'];
    'transactions/CLEAR': Transactions['CLEAR'];
  }

  export interface DispatchMap {
    'transactions/get': Transactions['get'];
  }
}

@Module({ namespaced: true })
export class Transactions
  extends VuexModule<Transactions.State, Root.State>
  implements Transactions.State
{
  items: Transaction[] = [];

  /** ... */
  get findById() {
    return (id: string) => find(this.items, { id });
  }

  // region Mutations

  /**
   * ...
   */
  @Mutation
  SET(options: Transactions.SetMutationOptions) {
    this.items = options.data;
  }

  /**
   * ...
   */
  @Mutation
  ADD(options: Transactions.AddMutationOptions) {
    const items = [...this.items];

    const i = findIndex(items, { id: options.id });

    if (i !== -1) {
      items[i] = options;
    } else {
      items.push(options);
    }

    this.items = items;
  }

  /**
   * ...
   */
  @Mutation
  CLEAR() {
    this.items = [];
  }

  // endregion Mutations

  // region Actions

  /**
   * ...
   */
  @Action
  async get(options: Transactions.GetActionOptions) {
    const data = await api.transactions.get(options);

    this.context.commit('ADD', data);

    return data;
  }

  // endregion Actions
}

export namespace Transactions {
  /** ... */
  export interface State {
    items: Transaction[];
  }

  /** ... */
  export interface SetMutationOptions {
    data: Transaction[];
  }

  /** ... */
  export type AddMutationOptions = Transaction;
  /** ... */
  export type GetActionOptions = api.transactions.GetOptions;
}

export default Transactions;
