export const SLB = {
  name: {
    common: 'Solomon Islands',
    official: 'Solomon Islands',
    native: { eng: { official: 'Solomon Islands', common: 'Solomon Islands' } },
  },
  cities: ['Honiara'],
  tld: ['.sb'],
  cca2: 'SB',
  ccn3: '090',
  cca3: 'SLB',
  cioc: 'SOL',
  currency: ['SBD'],
  callingCode: ['677'],
  capital: 'Honiara',
  altSpellings: ['SB'],
  region: 'Oceania',
  subregion: 'Melanesia',
  languages: { eng: 'English' },
  translations: {
    deu: { official: 'Salomon-Inseln', common: 'Salomonen' },
    fra: { official: 'Îles Salomon', common: 'Îles Salomon' },
    hrv: { official: 'Solomonski Otoci', common: 'Solomonski Otoci' },
    ita: { official: 'Isole Salomone', common: 'Isole Salomone' },
    jpn: { official: 'ソロモン諸島', common: 'ソロモン諸島' },
    nld: { official: 'Solomon eilanden', common: 'Salomonseilanden' },
    por: { official: 'Ilhas Salomão', common: 'Ilhas Salomão' },
    rus: { official: 'Соломоновы острова', common: 'Соломоновы Острова' },
    spa: { official: 'islas Salomón', common: 'Islas Salomón' },
    fin: { official: 'Salomonsaaret', common: 'Salomonsaaret' },
  },
  latlng: [-8, 159],
  demonym: 'Solomon Islander',
  landlocked: false,
  borders: [],
  area: 28896,
  provinces: [
    'Bellona',
    'Central',
    'Choiseul (Lauru)',
    'Guadalcanal',
    'Honiara',
    'Isabel',
    'Makira',
    'Malaita',
    'Rennell',
    'Temotu',
    'Western',
  ],
  nativeName: 'Solomon Islands',
  timezones: ['UTC+11:00'],
} as const;
