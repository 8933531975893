var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    _vm._l(_setup.items, function ({ key, item }) {
      return _c("b-badge", { key: key, staticClass: "mx-1" }, [
        _vm._v(_vm._s(item)),
      ])
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }