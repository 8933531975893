export const UZB = {
  name: {
    common: 'Uzbekistan',
    official: 'Republic of Uzbekistan',
    native: {
      rus: { official: 'Республика Узбекистан', common: 'Узбекистан' },
      uzb: { official: "O'zbekiston Respublikasi", common: 'O‘zbekiston' },
    },
  },
  provinces: [
    'Andijon Wiloyati',
    'Bukhoro Wiloyati',
    'Farghona Wiloyati',
    'Jizzakh Wiloyati',
    'Khorazm Wiloyati (Urganch)',
    'Namangan Wiloyati',
    'Nawoiy Wiloyati',
    'Qashqadaryo Wiloyati (Qarshi)',
    'Qoraqalpoghiston (Nukus)',
    'Samarqand Wiloyati',
    'Sirdaryo Wiloyati (Guliston)',
    'Surkhondaryo Wiloyati (Termiz)',
    'Toshkent Shahri',
    'Toshkent Wiloyati',
  ],
  nativeName: 'O‘zbekiston',
  cities: ['Tashkent', 'Termez', 'Samarkand', 'Turon'],
  tld: ['.uz'],
  cca2: 'UZ',
  ccn3: '860',
  cca3: 'UZB',
  cioc: 'UZB',
  currency: ['UZS'],
  callingCode: ['998'],
  capital: 'Tashkent',
  altSpellings: [
    'UZ',
    'Republic of Uzbekistan',
    'O‘zbekiston Respublikasi',
    'Ўзбекистон Республикаси',
  ],
  region: 'Asia',
  subregion: 'Central Asia',
  languages: { rus: 'Russian', uzb: 'Uzbek' },
  translations: {
    deu: { official: 'Republik Usbekistan', common: 'Usbekistan' },
    fra: { official: "République d'Ouzbékistan", common: 'Ouzbékistan' },
    hrv: { official: 'Republika Uzbekistan', common: 'Uzbekistan' },
    ita: { official: 'Repubblica di Uzbekistan', common: 'Uzbekistan' },
    jpn: { official: 'ウズベキスタン共和国', common: 'ウズベキスタン' },
    nld: { official: 'Republiek Oezbekistan', common: 'Oezbekistan' },
    por: { official: 'República do Usbequistão', common: 'Uzbequistão' },
    rus: { official: 'Республика Узбекистан', common: 'Узбекистан' },
    spa: { official: 'República de Uzbekistán', common: 'Uzbekistán' },
    fin: { official: 'Uzbekistanin tasavalta', common: 'Uzbekistan' },
  },
  latlng: [41, 64],
  demonym: 'Uzbekistani',
  landlocked: true,
  borders: ['AFG', 'KAZ', 'KGZ', 'TJK', 'TKM'],
  area: 447400,
  timezones: ['UTC+05:00'],
} as const;
