import { Module } from '@vuex/decorators';
import { TablePanel } from 'table-panel';

import { modals } from '@modals';
import { OutputLogEvent } from '@models';
import { isString } from '@tools/type-guards';

import TableCellSuccess from '@components/TableCells/TableCellSuccess.vue';
import TableCellListItems from '@components/TableCells/TableCellListItems.vue';

import { faCopy } from '@icons/solid/faCopy';

const columns: TablePanel.Column<OutputLogEvent>[] = [
  {
    key: 'timestamp',
    label: 'Timestamp',
    sortByFormatted: true,
    value: ({ timestamp }) => {
      return new Date(timestamp).toLocaleString('en-US', {
        timeZoneName: 'short',
      });
    },
  },
  {
    key: 'success',
    label: 'Success',
    type: 'boolean',
    component: TableCellSuccess,
    value: ({ message }) => {
      return isString(message) ? null : message.message.success === 'Success';
    },
  },
  {
    key: 'action',
    label: 'Action',
    value: ({ message }) => {
      return isString(message) ? null : message.message.action;
    },
  },
  {
    key: 'targets',
    label: 'Targets',
    component: TableCellListItems,
    value: ({ message }) => {
      return isString(message)
        ? []
        : (message.message.targets ?? []).map((o) => o.name);
    },
  },
  {
    key: 'requester',
    label: 'Requester',
    value: ({ message }) => {
      return isString(message) ? null : message.message.requester.email;
    },
  },
];

const operations: TablePanel.Row.Operation<OutputLogEvent>[] = [
  {
    label: 'View',
    icon: faCopy,
    fn: viewFullLog,
  },
];

@Module({ namespaced: true })
export class OutputLogEventsTablePanel
  extends TablePanel<OutputLogEvent>
  implements TablePanel.Props<OutputLogEvent>
{
  onPageChanged?: TablePanel.OnPageChangedCallback<OutputLogEvent>;
  readonly module = 'outputLogEvents';
  readonly pk = 'timestamp';
  readonly loadPageAction = 'loadPage';
  readonly label = 'OUTPUT EVENT LOGS';
  readonly columns = columns;
  readonly operations = operations;
  readonly loadOnOpen = false;
  readonly tableSortType = 'timestamp';
  readonly tableSortReverse = true;
  readonly infoMessage =
    'Currently, only the following routes are logged: GetMe, SubmitReport, OrgRemoveUser, OrgDeleteInvite';

  get enabled() {
    return this.isActiveRole(9);
  }

  readonly progressive = true;
}

/**
 * View the full contents of a log via a modal.
 *
 * @param event ...
 */
export function viewFullLog(event: OutputLogEvent) {
  let value: unknown;
  let requester = 'Unknown';

  if (!isString(event.message)) {
    value = event.message.message;
    requester = event.message.message.requester.email;
  } else {
    value = event.message;
  }

  const time = new Date(event.timestamp).toUTCString();

  void modals.util.viewValue({
    value,
    title: `LOG EVENT (${requester}) - ${time}`,
  });
}
