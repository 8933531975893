export const USA = {
  name: {
    common: 'United States',
    official: 'United States of America',
    native: {
      eng: { official: 'United States of America', common: 'United States' },
    },
  },
  nativeName: 'United States',
  provinces: [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'District of Columbia',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
  ],
  cities: [
    'Mukilteo',
    'Fairfield',
    'Chicago',
    'Hernando',
    'Irving',
    'Baltimore',
    'Kingston',
    'Burlington',
    'Harrison',
    'Newton Center',
    'Littleton',
    'Bloomington',
    'San Ramon',
    'Downers Grove',
    'Circleville',
    'Oxnard',
    'Gulfport',
    'Portland',
    'Houston',
    'Greenbank',
    'Somerset',
    'Sidman',
    'Minneapolis',
    'Manchester',
    'Canton',
    'The Bronx',
    'San Francisco',
    'Saluda',
    'San Jose',
    'Santa Clara',
    'Sunnyvale',
    'Washington',
    'Harrisburg',
    'Stafford',
    'Newington',
    'Vienna',
    'Cambridge',
    'Boston',
    'South Harwich',
    'Worcester',
    'Tampa',
    'Lake Wales',
    'Saint Petersburg',
    'Kyle',
    'Pomona',
    'Covina',
    'Edmonds',
    'Kirkland',
    'White Plains',
    'Waynesboro',
    'Buffalo',
    'New York',
    'Ridgewood',
    'Utica',
    'Port Washington',
    'Brecksville',
    'Streetsboro',
    'Hartford',
    'Nashville',
    'Chester',
    'Richmond',
    'Jacksonville',
    'Kissimmee',
    'Clinton',
    'Denver',
    'Fort Worth',
    'Brandon',
    'Dover',
    'Union Township',
    'Nazareth',
    'Pine Brook',
    'Edison',
    'Redwood City',
    'Arvada',
    'Pineville',
    'Ponte Vedra Beach',
    'Sarasota',
    'Jackson',
    'Nacogdoches',
    'Tomball',
    'McKinney',
    'Little Rock',
    'Dallas',
    'Halethorpe',
    'Crofton',
    'Northbrook',
    'Palatine',
    'New Hyde Park',
    'Phoenix',
    'Northville',
    'St Louis',
    'Lafayette',
    'Castle Rock',
    'Windsor',
    'Laurel',
    'Collegeville',
    'Twinsburg',
    'Getzville',
    'Richfield',
    'Rochester',
    'Lorton',
    'Manassas',
    'Virginia Beach',
    'Waldorf',
    'Ashburn',
    'Seattle',
    'Woodbridge',
    'Verona',
    'Bakersfield',
    'Scranton',
    'Riverside',
    'Gardena',
    'Bakersfield',
    'Fontana',
    'Los Angeles',
    'Sacramento',
    'Bonham',
    'Keller',
    'Orlando',
    'Largo',
    'Austin',
    'Atlanta',
    'Salem',
    'Stockbridge',
    'Monroe Township',
    'Lakewood',
    'San Diego',
    'Spring Valley',
    'Reseda',
    'Oak Brook',
    'Novi',
    'Miami',
    'Fort Lauderdale',
    'Littleton',
    'Southern Pines',
    'Grantville',
    'Honesdale',
    'Philadelphia',
    'Coatesville',
    'Kennesaw',
    'Irvine',
    'Newbury Park',
    'Ontario',
    'Manhattan Beach',
    'San Mateo',
    'Westlake Village',
    'Covington',
    'Wichita',
    'Canton',
    'Euless',
    'New Orleans',
    'Garland',
    'Poughkeepsie',
    'Mechanicsville',
    'Sterling',
    'Temple Hills',
    'Glen Allen',
    'Hopkins',
    'Jersey City',
    'Parsippany',
    'Wanaque',
    'Whippany',
    'Bothell',
    'Mill Valley',
    'Oakland',
    'Canonsburg',
    'Naperville',
    'Columbus',
    'Monmouth Junction',
    'King of Prussia',
    'Tipton',
    'Trenton',
    'Upland',
    'Santa Monica',
    'Hacienda Heights',
    'Lewis Center',
    'Alexandria',
    'Fairfax',
    'Frederick',
    'Dumfries',
    'Charlotte',
    'Bradenton',
    'Winchester',
    'Anniston',
    'Round Rock',
    'Humble',
    'Katy',
    'Golden',
    'Colorado Springs',
    'Merrick',
    'Northfield',
    'Ridgefield Park',
    'North Palm Beach',
    'Boca Raton',
    'High Bridge',
    'Piscataway',
    'Hillsborough',
    'Huntington Beach',
    'Tempe',
    'Yorba Linda',
    'Duarte',
    'Oceanside',
    'Redding',
    'Fountain Valley',
    'Clinton',
    'Durham',
    'Marysville',
    'Long Island City',
    'Haverford',
    'League City',
    'Jackson',
    'Berkley',
    'Plano',
    'Lexington',
    'Atlanta',
    'Brighton',
    'Wakefield',
    'East Falmouth',
    'Louisa',
    'Gaithersburg',
    'Ashland',
    'Columbia',
    'Upper Marlboro',
    'Oviedo',
    'Birmingham',
    'Tallahassee',
    'Tustin',
    'Suwanee',
    'Pompano Beach',
    'Grapevine',
    'Pittsburgh',
    'Butler',
    'South Jordan',
    'Elk Grove Village',
    'Milwaukee',
    'Bradford',
    'Villa Park',
    'Springfield Township',
    'South Amboy',
    'Signal Hill',
    'Wilmington',
    'Newport Beach',
    'Las Vegas',
    'Portsmouth',
    'Midlothian',
    'Rockville',
    'Newport News',
    'Edison',
    'Paul Smiths',
    'Copenhagen',
    'North Richland Hills',
    'Cedar Hill',
    'Moorestown Township',
    'Huntsville',
    'Silver Spring',
    'Fayetteville',
    'Raleigh',
    'Chesapeake',
    'Arlington',
    'Rougemont',
    'Annandale',
    'Norcross',
    'Hendersonville',
    'Dalton',
    'Brooklyn',
    'Elmhurst',
    'Terre Haute',
    'Ingleside',
    'Detroit',
    'Baton Rouge',
    'West Hollywood',
    'New Albany',
    'Louisville',
    'Destrehan',
    'Normal',
    'Ludington',
    'Zolfo Springs',
    'Zephyrhills',
    'Bowling Green',
    'Coppell',
    'Chestnut Hill',
    'Dedham',
    'Westborough',
    'Andover',
    'Jasper',
    'Allentown',
    'Greensburg',
    'South Burlington',
    'Warrendale',
    'Reston',
    'New Kensington',
    'Pitcairn',
    'Hanover',
    'Duncansville',
    'Englewood',
    'Boulder',
    'Broomfield',
    'Cheyenne',
    'Modesto',
    'American Fork',
    'Cocoa Beach',
    'Lake Worth',
    'Brookline',
    'New Gloucester',
    'Lawrence',
    'Whitman',
    'North Attleboro',
    'Lake Orion',
    'Livonia',
    'Bloomfield Hills',
    'Toledo',
    'Mesa',
    'Grand Canyon',
    'Vista',
    'Escondido',
    'Ramona',
    'San Lorenzo',
    'Renton',
    'Vashon',
    'Redmond',
    'Auburn',
    'Fenton',
    'New Port Richey',
    'Winter Haven',
    'Pauma Valley',
    'Cleveland',
    'Rocky River',
    'Folsom',
    'Yerington',
    'Reno',
    'Franklin',
    'Chantilly',
    'Orangevale',
    'Chalfont',
    'Columbus',
    'Woodstock',
    'Columbus',
    'Stamford',
    'Raritan',
    'Buffalo Grove',
    'Des Plaines',
    'Baytown',
    'Cincinnati',
    'Rosemead',
    'Pasadena',
    'Sun City',
    'Lawndale',
    'Saint Paul',
    'Onalaska',
    'Herriman',
    'Hollywood',
    'Cocoa',
    'Myakka City',
    'Rowlett',
    'Cleveland',
    'Haysville',
    'Montgomery',
    'Milford',
    'Bethlehem',
    'Centreville',
    'Butler',
    'Amarillo',
    'Bedford',
    'Castro Valley',
    'Palo Alto',
    'Rosedale',
    'Hagerstown',
    'Avenel',
    'Palm Beach Gardens',
    'Babson Park',
    'Mission Viejo',
    'Cupertino',
    'Joliet',
    'Adams Run',
    'Baldwin Park',
    'Long Beach',
    'Sylmar',
    'Tulsa',
    'Nutley',
    'Seagoville',
    'Astoria',
    'North Bergen',
    'Duluth',
    'Kemah',
    'Quakertown',
    'Royersford',
    'Bensalem',
    'Mountain View',
    'Rockford',
    'Troy',
    'Annapolis',
    'Hyattsville',
    'Anza',
    'Roanoke',
    'Somerville',
    'Lexington',
    'Tyler',
    'London',
    'Ladonia',
    'Vernon',
    'Tappahannock',
    'Suffolk',
    'Providence Forge',
    'Ashville',
    'Groveport',
    'Mansfield',
    'Alvarado',
    'San Antonio',
    'Portland',
    'Westminster',
    'Verona',
    'Elma',
    'Henderson',
    'Goldsboro',
    'Dickson',
    'Bennettsville',
    'Waynesboro',
    'Pembroke',
    'Shelby',
    'Wilmer',
    'Quitman',
    'Register',
    'Lawrenceville',
    'Muskegon',
    'West Mifflin',
    'North Rose',
    'Webster',
    'Cedar Springs',
    'Arlington Heights',
    'Elgin',
    'West Lafayette',
    'Justin',
    'Horn Lake',
    'Sedalia',
    'Roscommon',
    'Mt. Dora',
    'Albuquerque',
    'Sierra Vista',
    'Hamden',
    'Hackensack',
    'Sheridan',
    'Mosinee',
    'Lafayette',
    'Peoria',
    'Farmington Hills',
    'Kingsbury',
    'Muncie',
    'Charlestown',
    'Aurora',
    'New Richmond',
    'Stout',
    'Marshall',
    'Aurora',
    'Lyons',
    'Elizabeth',
    'Canton',
    'Akron',
    'Alliance',
    'Freeport',
    'Lincoln',
    'Montpelier',
    'Rozet',
    'Leeds',
    'Union Lake',
    'Dundee',
    'Capac',
    'Massillon',
    'Missouri City',
    'Buda',
    'Meridian',
    'Richmond Hill',
    'Interlachen',
    'Cranston',
    'Pennsville Township',
    'Glendale',
    'Placitas',
    'Tucson',
    'Las Cruces',
    'El Paso',
    'Thoreau',
    'Tacoma',
    'Olympia',
    'Camdenton',
    'Kansas City',
    'Fredericktown',
    'Independence',
    'Greenwich',
    'Oklahoma City',
    'House Springs',
    'Barnhart',
    'Belleville',
    'Catawissa',
    'Woodstock',
    'Fresno',
    'Pahrump',
    'Grass Valley',
    'Cobb',
    'East Haddam',
    'Radford',
    'Cleveland',
    'Perth Amboy',
    'Irvington',
    'Vesuvius',
    'Amherst',
    'Dayton',
    'Spencerville',
    'Manchester',
    'Brunswick',
    'West Union',
    'Lima',
    'Madison',
    'Lexington',
    'Rochelle',
    'Aniwa',
    'Rushmore',
    'Genoa',
    'Greenville',
    'Monticello',
    'Aberdeen',
    'River Falls',
    'Eagle River',
    'Morrill',
    'Vancouver',
    'Fort Gibson',
    'Salem',
    'Wellford',
    'Anderson',
    'Greenville',
    'Clifton',
    'Institute',
    'Jefferson City',
    'Yuma',
    'Madison',
    'Matawan',
    'Orange',
    'Livingston',
    'Scottsdale',
    'Beverly Hills',
    'Palm Desert',
    'Torrance',
    'El Segundo',
    'Rio Rico',
    'Rancho Cucamonga',
    'Chula Vista',
    'Fallbrook',
    'Costa Mesa',
    'St. Augustine',
    'Hopewell',
    'Edgewater',
    'Morristown',
    'New Rochelle',
    'Garden City',
    'Centereach',
    'Encino',
    'Alton',
    'Long Pond',
    'East Elmhurst',
    'Emeryville',
    'Pennington',
    'Montoursville',
    'East Brunswick',
    'Waltham',
    'Coleville',
    'Mammoth Lakes',
    'Shrewsbury',
    'Eatontown',
    'Flushing',
    'Princeton',
    'Apex',
    'Santa Ana',
    'Norristown',
    'Morrisville',
    'Leesburg',
    'Wilson',
    'Franklin',
    'West Palm Beach',
    'Opa-locka',
    'Naples',
    'Nolensville',
    'Antioch',
    'Saint Clair Shores',
    'Lafayette',
    'Maryville',
    'Falls Church',
    'Cathedral City',
    'Warrenville',
    'Longmont',
    'Kinston',
    'Milpitas',
    'Winnemucca',
    'Berkeley',
    'San Leandro',
    'Somerville',
    'Kingman',
    'Desert Hot Springs',
    'Coachella',
    'Crete',
    'Yulee',
    'Palm Bay',
    'Clermont',
    'Lyman',
    'Mountain View',
    'White Cloud',
    'Hallettsville',
    'Yoakum',
    'Pocono Summit',
    'York',
    'Newtown',
    'Wilmington',
    'Drexel Hill',
    'Sherwood',
    'DuPont',
    'Westfield',
    'Wendell',
    'Byers',
    'Nixon',
    'Odessa',
    'Lexington',
    'Foster',
    'California',
    'Foster City',
    'Belle Chasse',
    'Metairie',
    'Cordova',
    'Herndon',
    'Woods Cross',
    'Brigham City',
    'Orem',
    'Flowood',
    'Jamaica',
    'Newhall',
    'Southington',
    'Seneca',
    'Chino Valley',
    'Prescott Valley',
    'Prescott',
    'Paulden',
    'Cornville',
    'Idaho Falls',
    'Provo',
    'Burbank',
    'Thousand Oaks',
    'Syracuse',
    'Minoa',
    'Lady Lake',
    'Winona',
    'Red Wing',
    'Utica',
    'Spring Grove',
    'Lake City',
    'Eyota',
    'Plainview',
    'Bloomfield',
    'Brenham',
    'Portland',
    'Mathis',
    'Robstown',
    'Taft',
    'Sandia',
    'Odem',
    'Skidmore',
    'El Campo',
    'Charlottesville',
    'Poway',
    'San Marcos',
    'Schaumburg',
    'Williamsport',
    'Elmont',
    'Bethel',
    'Reading',
    'Greenbrae',
    'Salisbury',
    'Strasburg',
    'Bennett',
    'Deer Trail',
    'Ft. Pierce',
    'Lake Elsinore',
    'Wildomar',
    'Murrieta',
    'Marina del Rey',
    'Mims',
    'Dunnellon',
    'Tavares',
    'Leesburg',
    'Melbourne',
    'Altamonte Springs',
    'Hialeah',
    'Haines City',
    'Stone Mountain',
    'Blountstown',
    'Apalachicola',
    'Wewahitchka',
    'Panacea',
    'Bristol',
    'Port Saint Joe',
    'Perry',
    'North Miami Beach',
    'Miami Beach',
    'Queen Creek',
    'Gilbert',
    'Laveen',
    'Clarksville',
    'New Philadelphia',
    'Uniontown',
    'Fort Morgan',
    'Durango',
    'Oldsmar',
    'Clarendon',
    'Canadian',
    'Morse',
    'Evanston',
    'Burgettstown',
    'Wellsburg',
    'Dry Run',
    'Midlothian',
    'Yukon',
    'Newalla',
    'Drumright',
    'Perry',
    'Tuttle',
    'Guthrie',
    'Flagstaff',
    'Blue Earth',
    'New Prague',
    'Bricelyn',
    'Wells',
    'Easton',
    'Minnesota Lake',
    'Winnebago',
    'Sugar Land',
    'Duncan',
    'West Chester',
    'Bloomington',
    'Sonora',
    'Wallace',
    'Pleasantville',
    'Weston',
    'Seymour',
    'Essex Junction',
    'Northfield Falls',
    'Somerset',
    'Colts Neck',
    'McLean',
    'Westminster',
    'Fort Collins',
    'Bullhead City',
    'Topock',
    'North Brunswick',
    'Palisades Park',
    'Paoli',
    'Orleans',
    'Mitchell',
    'Prince George',
    'Washougal',
    'Mount Juliet',
    'El Monte',
    'North Wales',
    'Spring',
    'Narberth',
    'Pearland',
    'Overland Park',
    'Tipton',
    'Gravois Mills',
    'California',
    'Shawnee',
    'Kansas City',
    'Martindale',
    'Doylestown',
    'Harrington',
    'Newark',
    'Hazel Park',
    'Norwalk',
    'Fort Myers',
    'Dundee',
    'South Royalton',
    'Knoxville',
    'Mansfield',
    'Drums',
    'Socorro',
    'Marion',
    'Darby',
    'State College',
    'New Castle',
    'Compton',
    'Russellville',
    'Lemont Furnace',
    'Rocky Hill',
    'Louisville',
    'Artesia',
    'Nellysford',
    'Buffalo',
    'Hiawatha',
    'Elwood',
    'Sabetha',
    'Seneca',
    'Horton',
    'Ocala',
    'Sugar Grove',
    'West Jefferson',
    'Todd',
    'Banner Elk',
    'Crumpler',
    'Vilas',
    'Fleetwood',
    'Sparta',
    'Lansing',
    'Piney Creek',
    'Zionville',
    'Riverton',
    'Augusta',
    'Stockton',
    'Russell',
    'Great Bend',
    'Agra',
    'Norton',
    'Hays',
    'Lenora',
    'Versailles',
    'Tinley Park',
    'Lone Pine',
    'Studio City',
    'Carlsbad',
    'Buford',
    'Parker',
    'Jamaica',
    'Newfane',
    'Middle Village',
    'Claremont',
    'Hogansburg',
    'Lynchburg',
    'Jackson',
    'Cape Girardeau',
    'Whitewater',
    'Rush Center',
    'Quinter',
    'Ellis',
    'Ness City',
    'Ogallah',
    'Shawnee',
    'Animas',
    'Townshend',
    'Brattleboro',
    'West Dummerston',
    'South Newfane',
    'Putney',
    'Rensselaer',
    'Lewiston',
    'North Tonawanda',
    'Wellsboro',
    'Lock Haven',
    'Bethania',
    'Venice',
    'Johnson City',
    'Montpelier',
    'Clifton Park',
    'Langeloth',
    'Gibsonia',
    'Bethel Park',
    'Monroeville',
    'Homestead',
    'Clairton',
    'Clarence',
    'Karthaus',
    'Drifting',
    'Frenchville',
    'Houtzdale',
    'Morrisdale',
    'Grassflat',
    'Bridgeton',
    'Westbury',
    'Everett',
    'Groveland',
    'Three Rivers',
    'Scotts Valley',
    'Mount Laurel',
    'Glendale',
    'Lakewood',
    'Coral Gables',
    'Hollister',
    'Glen Burnie',
    'Montebello',
    'La Puente',
    'Los Alamitos',
    'Genoa',
    'Montevideo',
    'Hoboken',
    'Casselberry',
    'Maitland',
    'Auburn Hills',
    'Sandusky',
    'Grand Rapids',
    'Kankakee',
    'Crystal Lake',
    'Libertyville',
    'Sikeston',
    'Oran',
    'Ridgeland',
    'Ellisville',
    'Long Beach',
    'Saltillo',
    'Biloxi',
    'Yazoo City',
    'Ocean Springs',
    'La Porte',
    'Kountze',
    'Franklin',
    'Berwick',
    'Jeanerette',
    'Saraland',
    'Lafayette',
    'Mobile',
    'Picayune',
    'Batavia',
    'Bedford',
    'Frostburg',
    'Salem',
    'Coraopolis',
    'Chapel Hill',
    'Fountain Inn',
    'Depere',
    'Brookfield',
    'Lake Forest',
    'Harwood Heights',
    'Indianapolis',
    'Frisco',
    'Bismarck',
    'Vicksburg',
    'Cadillac',
    'Kalamazoo',
    'Newaygo',
    'Traverse City',
    'Aurora',
    'Peoria',
    'Darien',
    'Iowa City',
    'Napa',
    'Brisbane',
    'Taylor',
    'Warren',
    'Oxford',
    'Saginaw',
    'Southfield',
    'Livermore',
    'Danville',
    'Toluca',
    'Rutland',
    'Wayne',
    'Easton',
    'Melville',
    'Williston',
    'Bala-Cynwyd',
    'East Lansing',
    'Maumee',
    'Mahwah',
    'Rowland Heights',
    'Carson',
    'Scotch Plains',
    'Lexington',
    'West Jefferson',
    'Oakland',
    'Truckee',
    'Shingletown',
    'Woodland',
    'Tracy',
    'Oxon Hill',
    'Beachwood',
    'Elizabeth City',
    'Lanham',
    'Harrisonburg',
    'Frazier Park',
    'Lewisville',
    'Deerfield',
    'Winnfield',
    'Millry',
    'Ponca City',
    'Broken Arrow',
    'Chetopa',
    'Jenks',
    'McPherson',
    'Aberdeen',
    'Severna Park',
    'Hanover',
    'Rohnert Park',
    'Claysville',
    'Clarksburg',
    'Bloomingdale',
    'Broussard',
    'Waller',
    'Lake Jackson',
    'Smyrna',
    'Grand Prairie',
    'Richardson',
    'Arlington',
    'Gainesville',
    'Langhorne',
    'Walterboro',
    'Huntsville',
    'Sylacauga',
    'Pike Road',
    'Boligee',
    'Temple',
    'Georgetown',
    'Pottsboro',
    'Fairview Heights',
    'Denison',
    'Celina',
    'Galveston',
    'Alva',
    'Skiatook',
    'Collinsville',
    'Yale',
    'Woodward',
    'Kaufman',
    'Waikoloa',
    'Hickory',
    'Concord',
    'Bristol',
    'Gaffney',
    'Florence',
    'Columbus',
    'Streamwood',
    'Hinsdale',
    'Woodland Hills',
    'Oak Park',
    'Greensburg',
    'Shreveport',
    'Sorrento',
    'Marrero',
    'Oak Ridge',
    'Louisville',
    'Westwego',
    'New Iberia',
    'Itta Bena',
    'Pearl',
    'Starkville',
    'Vicksburg',
    'Tuscaloosa',
    'Northport',
    'Grayson',
    'Warner Robins',
    'Sparks',
    'Paramus',
    'Weare',
    'Sonoma',
    'Bentonville',
    'Midland',
    'Pleasanton',
    'Charleston',
    'Tupelo',
    'Lucedale',
    'De Kalb',
    'Daly City',
    'Pleasanton',
    'Bandera',
    'McAllen',
    'Downey',
    'Junction City',
    'Concord',
    'Laredo',
    'Tylertown',
    'Bozeman',
    'Palmdale',
    'Winterville',
    'Hoosick Falls',
    'Fremont',
    'Auburn',
    'Knoxville',
    'Abbeville',
    'Marietta',
    'Marbury',
    'Campbell',
    'Lutz',
    'Delray Beach',
    'Gainesville',
    'Clementon',
    'Titusville',
    'Savannah',
    'Summerfield',
    'Hesperia',
    'Irons',
    'Union City',
    'Bridgeport',
    'Eatonton',
    'Key Largo',
    'Palatka',
    'DeLand',
    'Ormond Beach',
    'Brownwood',
    'Newberry',
    'Bonita Springs',
    'Corona',
    'West Hills',
    'Chatsworth',
    'La Canada Flintridge',
    'San Pedro',
    'Buena Park',
    'Van Nuys',
    'Valencia',
    'Buellton',
    'Diamond Bar',
    'Memphis',
    'Laurel',
    'Tutwiler',
    'Philipp',
    'Ennis',
    'Danbury',
    'Norwalk',
    'Shrewsbury',
    'Pacific',
    'Jonesboro',
    'Mandeville',
    'Chandler',
    'Panorama City',
    'South El Monte',
    'Milford',
    'Cheshire',
    'East Hartford',
    'Meriden',
    'Palm Springs',
    'Orange',
    'Fullerton',
    'San Jacinto',
    'Northridge',
    'Poplar Bluff',
    'Pearl River',
    'Eldon',
    'Linn Creek',
    'Edwardsville',
    'North Sioux City',
    'Lake Saint Louis',
    'Quincy',
    'Joplin',
    'Hayti',
    'Fort Smith',
    'Monroe',
    'North Little Rock',
    'Bellevue',
    'Springfield',
    'Fair Grove',
    'Columbus',
    'Wood River',
    'Maryland Heights',
    'Caseyville',
    'Glen Allan',
    'Pine Bluffs',
    'Salt Lake City',
    'Winchester',
    'Peyton',
    'Platteville',
    'Bosque Farms',
    'Santa Fe',
    'Santa Rosa',
    'Beaverton',
    'Gillette',
    'Aspen',
    'Loveland',
    'Pasadena',
    'Needham Heights',
    'Norwood',
    'Londonderry',
    'Watertown',
    'Oconomowoc',
    'Gurnee',
    'Kokomo',
    'Bargersville',
    'Middletown',
    'Anchorage',
    'Akhiok',
    'Mountain Village',
    'Valdez',
    'Sitka',
    'Juneau',
    'Kake',
    'Fairbanks',
    'Stockton',
    'Ketchikan',
    'Carol Stream',
    'Catoosa',
    'Wilburton',
    'Hayward',
    'San Luis Obispo',
    'Petaluma',
    'Ross',
    'San Bernardino',
    'Chino',
    'Seal Beach',
    'Jupiter',
    'Alhambra',
    'Culver City',
    'Azusa',
    'Canoga Park',
    'Clanton',
    'Corsicana',
    'Longview',
    'Little Elm',
    'Lake Charles',
    'Houma',
    'Odessa',
    'Victoria',
    'Kodak',
    'Alpharetta',
    'La Quinta',
    'Macon',
    'Montgomery',
    'North Hollywood',
    'Topeka',
    'El Macero',
    'North Las Vegas',
    'Castaic',
    'Brea',
    'Forest Park',
    'Madera',
    'Richmond',
    'Tucker',
    'Wakefield',
    'Saint Francisville',
    'Zephyr Cove',
    'Waco',
    'Flower Mound',
    'Abilene',
    'Dulles',
    'Parlin',
    'Ronkonkoma',
    'Belton',
    'Seabrook',
    'Fairfield',
    'Magnolia',
    'Lake Zurich',
    'Manteno',
    'Carrollton',
    'Port Chester',
    "O'Fallon",
    'Moberly',
    'Florissant',
    'Portageville',
    'Weaverville',
    'Douglas City',
    'Chicago Heights',
    'Rochester',
    'Port Orange',
    'Hamel',
    'Shenandoah',
    'Bayonne',
    'Woonsocket',
    'West Warwick',
    'Standish',
    'South Weymouth',
    'Roslindale',
    'Osceola',
    'Bridgewater',
    'Oshkosh',
    'Grafton',
    'Hartland',
    'Franksville',
    'Riverside',
    'Lake Mary',
    'Midland',
    'Framingham',
    'Hartsville',
    'Beaumont',
    'Dublin',
    'Pelham',
    'Decatur',
    'Rolling Meadows',
    'La Grange',
    'Glen Ellyn',
    'Bolingbrook',
    'Elmwood Park',
    'Crest Hill',
    'Westmont',
    'Albany',
    'Schiller Park',
    'Woodridge',
    'Round Lake',
    'Ukiah',
    'Royal',
    'Barrington',
    'Rutherfordton',
    'Charleston',
    'Columbia',
    'Garner',
    'La Plata',
    'Wilmington',
    'Leland',
    'Clarksville',
    'Burke',
    'Henderson',
    'Lubbock',
    'Bourbonnais',
    'Bellingham',
    'Diamond Springs',
    'Lapwai',
    'Pierce',
    'Missoula',
    'Casper',
    'Lakewood',
    'Cottage Grove',
    'Racine',
    'Plainsboro',
    'Yakima',
    'Albany',
    'Pryor',
    'Oxford',
    'Bowling Green',
    'Milton-Freewater',
    'Rantoul',
    'Marcus Hook',
    'Fishers',
    'Champaign',
    'Axis',
    'Holliston',
    'Allston',
    'Locust Grove',
    'Galax',
    'Wetumka',
    'Liberal',
    'Stigler',
    'Stillwater',
    'Cushing',
    'Chavies',
    'Barboursville',
    'Lancaster',
    'Brownsville',
    'Pharr',
    'Schertz',
    'Mercer Island',
    'Grand Forks',
    'Orchard Park',
    'Anaheim',
    'Simi Valley',
    'Lynwood',
    'East Meadow',
    'Lisbon',
    'Kenosha',
    'Hilliard',
    'Saint Catharine',
    'Belpre',
    'Springfield',
    'Frankfort',
    'Mendenhall',
    'Waveland',
    'Bay Shore',
    'Charleston',
    'Key Biscayne',
    'Plantation',
    'Cibolo',
    'Waukesha',
    'Redondo Beach',
    'Social Circle',
    'Seekonk',
    'Chipley',
    'Greer',
    'Hattiesburg',
    'North Charleston',
    'Donalds',
    'West Columbia',
    'Loris',
    'Saint Matthews',
    'Brierfield',
    'Valdosta',
    'Fletcher',
    'McComb',
    'Vina',
    'Ely',
    'Rancho Cordova',
    'Warrior',
    'Inglewood',
    'Lenexa',
    'Neligh',
    'Omaha',
    'Dothan',
    'Brookfield',
    'Sturgeon',
    'Venice',
    'Mira Loma',
    'West Covina',
    'Arcadia',
    'Bel Air',
    'Ellicott City',
    'Pensacola',
    'Santa Barbara',
    'New Haven',
    'Santa Rosa',
    'New Lenox',
    'Washington',
    'Paradise Valley',
    'City of Saint Peters',
    'Schulter',
    'Seneca',
    'Wood Dale',
    'Veedersburg',
    'Guin',
    'Calera',
    'Homestead',
    'West Chicago',
    'Mundelein',
    'Lumberton',
    'Goshen',
    'Princeton',
    'Stonewall',
    'Quitman',
    'Crowley',
    'Alexandria',
    'Old Hickory',
    'Lewisburg',
    'Decatur',
    'La Vergne',
    'Hermitage',
    'Madisonville',
    'Manchester',
    'Glasgow',
    'Inyokern',
    'Shingle Springs',
    'Highlands',
    'Painted Post',
    'Palm Coast',
    'Hobart',
    'Mount Kisco',
    'Dickinson',
    'Plainfield',
    'Mandan',
    'Arcola',
    'Sugar Grove',
    'Germantown',
    'Saint Michael',
    'Peabody',
    'Warwick',
    'Saugus',
    'Blytheville',
    'Bremen',
    'Manchester',
    'Marlborough',
    'Dayton',
    'Farmington',
    'Mount Vernon',
    'Kilgore',
    'Arkansas City',
    'Durant',
    'Duncan',
    'Kirksville',
    'Vallejo',
    'Antioch',
    'Jacksonville',
    'Havana',
    'Virginia',
    'Secaucus',
    'Keams Canyon',
    'Polacca',
    'Second Mesa',
    'Hampden',
    'Newark',
    'Colonial Heights',
    'Dowell',
    'Bowie',
    'Wilton',
    'East Granby',
    'Dyersburg',
    'Ann Arbor',
    'Lawrenceburg',
    'West Monroe',
    'Wyandotte',
    'South Pasadena',
    'Dearborn',
    'Perrysburg',
    'New Hudson',
    'Owingsville',
    'West Hartland',
    'Willimantic',
    'Middletown',
    'Seminole',
    'Middletown',
    'Prospect Heights',
    'South Webster',
    'Gallipolis',
    'Rahway',
    'Eau Claire',
    'Rochester',
    'Williamsburg',
    'Waynesburg',
    'Stanford',
    'Xenia',
    'San Bruno',
    'Nanuet',
    'Cypress',
    'Stafford',
    'Centreville',
    'Boyers',
    'Paducah',
    'Fort Stewart',
    'Jacksonville Beach',
    'Roanoke',
    'Bristol',
    'Walpole',
    'Portland',
    'Rockland',
    'Wiscasset',
    'Mio',
    'Taft',
    'Lyman',
    'Fort Mill',
    'Tonganoxie',
    'St. Joseph',
    "Lee's Summit",
    'Pleasant Hill',
    'Blue Springs',
    'Clarendon Hills',
    'Bridgeview',
    'Sealy',
    'Porter',
    'New Caney',
    'Rattan',
    'Edmond',
    'Caledonia',
    'West Fargo',
    'Adrian',
    'Lyons',
    'Foothill Ranch',
    'Port Carbon',
    'Pottsville',
    'Gladstone',
    'Hasbrouck Heights',
    'Garfield',
    'Caldwell',
    'Hammond',
    'Oak Creek',
    'Hauppauge',
    'Fort Valley',
    'Sharpsburg',
    'Miamisburg',
    'Catron',
    'Dexter',
    'Bloomfield',
    'El Dorado',
    'Fredericksburg',
    'Greenbelt',
    'Woodmere',
    'Lynn',
    'Quincy',
    'Arlington',
    'Fremont',
    'Westford',
    'Jeffersonville',
    'Radcliff',
    'Danville',
    'Fairborn',
    'Egg Harbor',
    'Linden',
    'Newark',
    'Matamoras',
    'Madison',
    'DuBois',
    'Notre Dame',
    'Buchanan',
    'Sycamore',
    'Mount Prospect',
    'Russell Springs',
    'Mount Washington',
    'Homer',
    'Ruston',
    'Saint George',
    'Alden',
    'Ridgefield',
    'Camillus',
    'Montrose',
    'Holland',
    'Bonita',
    'Easley',
    'Rome',
    'Fort Wayne',
    'Appleton',
    'Green Bay',
    'Brooklyn',
    'Colgate',
    'Beaver Dam',
    'Fayette',
    'Plain',
    'Los Banos',
    'Santa Clarita',
    'Aliso Viejo',
    'Perris',
    'Locust Grove',
    'Hillside',
    'Saint Charles',
    'Canton',
    'Grenloch',
    'Weyers Cave',
    'Seeley Lake',
    'Belleville',
    'Spokane',
    'Harrison',
    'Springfield',
    'Rockford',
    'Lake Bluff',
    'Parker City',
    'Bitely',
    'Janesville',
    'Madison',
    'Guilford',
    'Bridgeport',
    'Boonton',
    'Westlake',
    'Corcoran',
    'Round Mountain',
    'South Lake Tahoe',
    'Bandon',
    'Oakdale',
    'Union Springs',
    'East Rutherford',
    'East Syracuse',
    'Elk River',
    'Chebanse',
    'Tuscola',
    'Noblesville',
    'University Park',
    'Martinsville',
    'Canal Winchester',
    'Mason',
    'Novato',
    'East Chicago',
    'Highland Park',
    'Weehawken',
    'Bethesda',
    'Benton Harbor',
    'Royal Oak',
    'Lansing',
    'Franklin',
    'Saukville',
    'Fond du Lac',
    'West Bend',
    'Allen Park',
    'Madison Heights',
    'Ajo',
    'Eloy',
    'Rancho Palos Verdes',
    'Tiburon',
    'Orinda',
    'Watsonville',
    'Seymour',
    'Columbia',
    'Valley Park',
    'St. Charles',
    'Hershey',
    'Newton',
    'Neptune City',
    'Asbury Park',
    'Avon',
    'Stony Brook',
    'Sweetwater',
    'Burleson',
    'Middlefield',
    'Newnan',
    'Covington',
    'Douglasville',
    'Duncans Mills',
    'Pulaski',
    'Saratoga',
    'Sussex',
    'Flippin',
    'Panama City',
    'Ridgeville',
    'Laguna Niguel',
    'Rancho Santa Fe',
    'Ventura',
    'Temecula',
    'Loma Linda',
    'Wenonah',
    'Fishkill',
    'Totowa',
    'Green River',
    'Rawlins',
    'Rock Springs',
    'Carmichael',
    'McHenry',
    'Wixom',
    'Georgetown',
    'Lihue',
    'Coalinga',
    'Kingsburg',
    'Jolon',
    'McDonough',
    'Dublin',
    'Conneaut',
    'Winder',
    'Rocky Mount',
    'Berea',
    'Nottingham',
    'Bloomingdale',
    'Graniteville',
    'Barnwell',
    'Greendale',
    'Menomonee Falls',
    'Burlington',
    'Michigan City',
    'Lindale',
    'Princeton',
    'Hurst',
    'Carmel',
    'Florence',
    'Belleville',
    'Piqua',
    'Muir',
    'Montrose',
    'Norco',
    'Bell',
    'Owings',
    'Oxford',
    'Enterprise',
    'Union Springs',
    'Moultrie',
    'Sea Island',
    'Conway',
    'Imperial',
    'Springfield',
    'Germantown',
    'Harlan',
    'Ruther Glen',
    'Yorktown',
    'Franklin',
    'Canton',
    'Sandston',
    'Cary',
    'Orange',
    'Bell',
    'San Dimas',
    'Saint Clairsville',
    'Oakland Gardens',
    'Camp Verde',
    'Laurinburg',
    'LaFollette',
    'South Bend',
    'Sumner',
    'Ravenna',
    'Greensboro',
    'Orland Park',
    'Morris',
    'Zeeland',
    'Sevierville',
    'Orange',
    'Liverpool',
    'Paris',
    'Lancaster',
    'Bartlesville',
    'Owasso',
    'Fredonia',
    'Deer Park',
    'Ballinger',
    'Bastrop',
    'Greenville',
    'Staunton',
    'Suisun',
    'Richland',
    'Laurel',
    'Bainbridge',
    'Horseheads',
    'West Haven',
    'Addison',
    'Helmetta',
    'Mendham',
    'Ottawa',
    'Olathe',
    'Savannah',
    'Billerica',
    'Plainville',
    'Dundas',
    'Lexington',
    'Plymouth',
    'Bensenville',
    'Warsaw',
    'Walkerton',
    'Champlin',
    'Rosemount',
    'Davis',
    'Plymouth',
    'Danville',
    'Hollis',
    'Norwich',
    'Vacaville',
    'Waterford',
    'Horsham',
    'Ambler',
    'Bartlett',
    'Itasca',
    'Duxbury',
    'Haverhill',
    'Springfield',
    'Woodville',
    'Gloster',
    'Eunice',
    'Lordsburg',
    'Hobbs',
    'La Junta',
    'Jefferson',
    'Grant',
    'Cabot',
    'Spring Hill',
    'Port Saint Lucie',
    'Hilton Head Island',
    'Ellenville',
    'Woodbridge',
    'Fairfield',
    'Avon',
    'Vail',
    'Eureka',
    'Medford',
    'Tarzana',
    'Carpinteria',
    'Lehighton',
    'Bella Vista',
    'Independence',
    'Rockwall',
    'Big Spring',
    'La Marque',
    'Troy',
    'Pleasant Hill',
    'Dublin',
    'Hull',
    'Madison',
    'Decatur',
    'Brighton',
    'South Sioux City',
    'Sioux City',
    'Hinton',
    'Westfield',
    'Flowery Branch',
    'Royston',
    'Glen Lyon',
    'Kingston',
    'Hubbard',
    'Medina',
    'Beckley',
    'Greensboro',
    'Center',
    'Corpus Christi',
    'San Marcos',
    'Eagle Pass',
    'Three Rivers',
    'Simpsonville',
    'Melfa',
    'Fishersville',
    'Brookeland',
    'Calabasas',
    'Maywood',
    'Montvale',
    'Conshohocken',
    'Mountain Home',
    'Sedona',
    'Dinuba',
    'Pacific Grove',
    'Evansville',
    'Henderson',
    'Oxford',
    'Randolph Township',
    'Rockaway',
    'Moosic',
    'Warren',
    'Erie',
    'North East',
    'Mayville',
    'Ripley',
    'Rifle',
    'Laramie',
    'Glenwood Springs',
    'Sheridan',
    'Hawkins',
    'Jewett',
    'Wylie',
    'The Colony',
    'Marysville',
    'Carthage',
    'Conroe',
    'Texas City',
    'Niles',
    'Blue Island',
    'Florien',
    'Riverview',
    'Raymond',
    'New Cuyama',
    'Collierville',
    'Broadview Heights',
    'East Liverpool',
    'Cleburne',
    'Lynnwood',
    'Oak Harbor',
    'Solana Beach',
    'Marysville',
    'Cedar Hill',
    'Lizella',
    'Kennewick',
    'Providence',
    'Orleans',
    'Fairfield',
    'Belmont',
    'Bloomingdale',
    'Solon',
    'Independence',
    'Strongsville',
    'Schererville',
    'Saint John',
    'Rising Sun',
    'Townsend',
    'Lake Lynn',
    'Cambridge',
    'Seaford',
    'Luzerne',
    'Mishawaka',
    'Owatonna',
    'Haskins',
    'Albion',
    'Port Huron',
    'Memphis',
    'Addyston',
    'Culpeper',
    'Elkwood',
    'Chillicothe',
    'Salinas',
    'Boulder Creek',
    'Norman',
    'Williams',
    'Westfield',
    'Martinsville',
    'Sherman',
    'Ector',
    'Lilburn',
    'Whittier',
    'Canyon Country',
    'Arab',
    'Cullman',
    'Skokie',
    'Ballwin',
    'Godfrey',
    'Corning',
    'Romeoville',
    'Wheeling',
    'Iron Mountain',
    'McMillan',
    'Thiensville',
    'Summerville',
    'Wallace',
    'Broseley',
    "O'Fallon",
    'Rowesville',
    'Acworth',
    'Navarre',
    'Pitkin',
    'Richland',
    'Hebron',
    'Elburn',
    'Placentia',
    'Burlingame',
    'Sausalito',
    'Menlo Park',
    'Geyserville',
    'Healdsburg',
    'Eddy',
    'Kentwood',
    'Cantonment',
    'Atwater',
    'Homerville',
    'La Habra',
    'Newark',
    'Milford',
    'Paris',
    'Jackson',
    'Manteca',
    'Lahaina',
    'Marina',
    'Castroville',
    'Sunol',
    'King City',
    'Greenfield',
    'Teague',
    'Mohave Valley',
    'Warren',
    'Iuka',
    'Winnsboro',
    'Caddo Mills',
    'Forney',
    'Denton',
    'Grove City',
    'Steubenville',
    'Seaside',
    'Monterey',
    'Morgan Hill',
    'Golden Valley',
    'Tolleson',
    'Narragansett',
    'Dacula',
    'Tyrone',
    'Springville',
    'Pasco',
    'Athens',
    'Braselton',
    'Lithia Springs',
    'Commerce',
    'Dawsonville',
    'Vale',
    'Waynesville',
    'Cleveland',
    'Fincastle',
    'Six Mile',
    'Ypsilanti',
    'Flint',
    'Saline',
    'Highland',
    'La Crescenta',
    'Lemoore',
    'Clovis',
    'Mount Pleasant',
    'Prague',
    'Waxahachie',
    'Roseville',
    'Vandalia',
    'Leipsic',
    'Jacobsburg',
    'Clarence',
    'Painesville',
    'San Elizario',
    'Union City',
    'Malibu',
    'Des Moines',
    'Los Gatos',
    'Idalou',
    'Southlake',
    'Longwood',
    'Quincy',
    'Beatrice',
    'Spartanburg',
    'Corolla',
    'Clayton',
    'Alcoa',
    'Granada Hills',
    'Santa Fe Springs',
    'Heber',
    'East Saint Louis',
    'Chesterfield',
    'Hartford',
    'Taylor',
    'Batesville',
    'Ankeny',
    'Marianna',
    'Wichita Falls',
    'Mesquite',
    'Gardner',
    'Leawood',
    'Belton',
    'Paola',
    'Cole Camp',
    'Newtown Square',
    'Chino Hills',
    'Sherman Oaks',
    'Moreno Valley',
    'Temple City',
    'Edinburgh',
    'Urbana',
    'Camby',
    'Bellaire',
    'Chester Springs',
    'Adairsville',
    'Cumming',
    'Winchester',
    'Rosenberg',
    'East Bernard',
    'Plaquemine',
    'Dodge',
    'Columbus',
    'Huntersville',
    'Asheville',
    'Mebane',
    'Gwynn Oak',
    'Church Hill',
    'Suitland',
    'Yorkville',
    'Waukegan',
    'Elkhart',
    'Riverdale',
    'Belmont',
    'Stow',
    'Mentor',
    'Canal Fulton',
    'Winnebago',
    'New London',
    'Springfield',
    'Pickerington',
    'Cynthiana',
    'Merchantville',
    'Huntley',
    'Huntingdon Valley',
    'Herculaneum',
    'Garden Grove',
    'Sandwich',
    'Hanford',
    'Martinez',
    'Beaver Falls',
    'Cameron',
    'Mechanicsburg',
    'Lansdale',
    'Mechanicsville',
    'Chevy Chase',
    'Clarkston',
    'Juneau',
    'Plymouth',
    'Stuart',
    'Key West',
    'Burgaw',
    'Afton',
    'Bedford',
    'Franklin Park',
    'Maple Grove',
    'Whitestone',
    'Woburn',
    'Johnston',
    'Ellenboro',
    'Morganton',
    'Mitchell',
    'Clearwater',
    'La Mirada',
    'Ames',
    'Zionsville',
    'Westland',
    'Salyersville',
    'Woodsfield',
    'Clarington',
    'Uhrichsville',
    'Meadville',
    'Pinole',
    'Avon',
    'Mooresville',
    'Los Altos',
    'Goliad',
    'Red Oak',
    'Palos Heights',
    'Midlothian',
    'Colton',
    'Gustine',
    'Alexander City',
    'Columbia',
    'Shelbyville',
    'Lebanon',
    'Burnet',
    'Cedar Park',
    'Bogalusa',
    'Fair Oaks',
    'Quincy',
    'Gastonia',
    'Gilberts',
    'Republic',
    'Porterville',
    'Middletown',
    'Antelope',
    'Placerville',
    'Camino',
    'Marengo',
    'West Dundee',
    'Willowbrook',
    'Webster',
    'Bay City',
    'Brookshire',
    'Salem',
    'West Hartford',
    'Sumter',
    'North Augusta',
    'Ridgeway',
    'Aiken',
    'Lugoff',
    'Rockwell City',
    'Gans',
    'Sallisaw',
    'Vian',
    'Palmyra',
    'Greencastle',
    'Lake Forest',
    'Lebec',
    'Lakeside',
    'Oneida',
    'Helenwood',
    'Carlyle',
    'Spring Creek',
    'Blythe',
    'Los Fresnos',
    'New Bedford',
    'Laguna Beach',
    'Santee',
    'Woodside',
    'Katonah',
    'Putnam',
    'Marne',
    'Smiths Creek',
    'Stevensville',
    'Broadview',
    'McCamey',
    'Sebree',
    'Murfreesboro',
    'Shelbyville',
    'Stanton',
    'Lamesa',
    'Green Cove Springs',
    'Okatie',
    'Corning',
    'Warsaw',
    'Norfolk',
    'Dakota City',
    'Wethersfield',
    'Scarborough',
    'Dodgewood',
    'Glastonbury',
    'Altadena',
    'Rixeyville',
    'Von Ormy',
    'Pawhuska',
    'Newkirk',
    'Springfield',
    'New Liberty',
    'Owenton',
    'Romulus',
    'La Grange',
    'Myrtle Beach',
    'Somerset',
    'Cloverdale',
    'Deer Park',
    'Veradale',
    'Eugene',
    'Woodland',
    'Philadelphia',
    'Honey Creek',
    'Rockford',
    'Afton',
    'Grand Junction',
    'Carthage',
    'Grady',
    'Muldrow',
    'Conway',
    'Newark',
    'Waupaca',
    'Crawfordsville',
    'Shelbyville',
    'Princeton',
    'Farmington',
    'Hollow Rock',
    'Nebo',
    'Harrah',
    'Jasper',
    'Ninnekah',
    'Columbiana',
    'Trafford',
    'Dora',
    'Mount Olive',
    'Boaz',
    'Fountain',
    'Riesel',
    'Quitman',
    'Mineola',
    'Weatherford',
    'Wortham',
    'Pontiac',
    'River Rouge',
    'Oak Park',
    'Roseville',
    'Grosse Pointe',
    'Holly',
    'Burton',
    'Zanesville',
    'Niles',
    'Hillsboro',
    'Potsdam',
    'Masury',
    'Newark Valley',
    'Villa Rica',
    'Henrietta',
    'Queens Village',
    'Levittown',
    'Yorkville',
    'Saint Albans',
    'Berwyn',
    'Manville',
    'East Palestine',
    'Lebanon',
    'East Hampton',
    'Stonington',
    'Gloucester',
    'Mooresboro',
    'Lawndale',
    'Fort Bragg',
    'Snow Camp',
    'Pelion',
    'Prospect',
    'Stewart',
    'Hohenwald',
    'Hampton',
    'Millen',
    'Riceville',
    'Newport',
    'Mullins',
    'Marion',
    'Charleston',
    'East Palatka',
    'Hartselle',
    'Magnolia',
    'Blythewood',
    'Iva',
    'Union',
    'Cameron',
    'Old Town',
    'Brooksville',
    'Eufaula',
    'Jackson',
    'Selma',
    'Waterbury',
    'Geneva',
    'Warner Springs',
    'Surgoinsville',
    'Fred',
    'Morrow',
    'Valparaiso',
    'Tulare',
    'Mission',
    'Gladstone',
    'Darien',
    'Waynesboro',
    "Kane'ohe",
    'Chattanooga',
    'Bonfield',
    'Equality',
    'Seminole',
    'Blue Bell',
    'Utica',
    'Leander',
    'Lake in the Hills',
    'Maywood',
    'Lombard',
    'Brunswick',
    'Granbury',
    'Olivehurst',
    'Greater Capitol Heights',
    'Currie',
    'Collinsville',
    'Death Valley Junction',
    'Cache',
    'Bowling Green',
    'Bishop',
    'West Point',
    'Kingsville',
    'Hudsonville',
    'Hermosa Beach',
    'Jackson',
    'Newton',
    'Bamberg',
    'Morganfield',
    'Gulf Breeze',
    'Schoolcraft',
    'Muskogee',
    'Tremont',
    'Liberty',
    'Vinton',
    'Scott',
    'Pass Christian',
    'Hammond',
    'Kent',
    'Sebring',
    'Colfax',
    'Georgetown',
    'North Reading',
    'Belmont',
    'Harrodsburg',
    'La Grange',
    'Mount Vernon',
    'Santa Paula',
    'Hawthorne',
    'Empire',
    'Forest Home',
    'Sayre',
    'Chesterfield',
    'Norfolk',
    'San Rafael',
    'Opelika',
    'Bayside',
    'Harlingen',
    'Alexandria',
    'Wharton',
    'Evans',
    'Wadley',
    'Battle Creek',
    'Independence',
    'Gautier',
    'Slidell',
    'Curtis Bay',
    'Braintree',
    'Telford',
    'Berwyn',
    'Blackwood',
    'Emmaus',
    'Patterson',
    'Beattyville',
    'West Des Moines',
    'Marble Hill',
    'Byhalia',
    'Paragould',
    'Honolulu',
    'Turtle Lake',
    'New Britain',
    'Metuchen',
    'Amesbury',
    'Bow Center',
    'Festus',
    'Granite City',
    'Trenton',
    'Rolla',
    'Arnold',
    'Bourbon',
    'Paris',
    'Salisbury',
    'Manchester',
    'El Cajon',
    'Warroad',
    'Plainfield',
    'Fernandina Beach',
    'Plant City',
    'Hinesville',
    'Oregon',
    'Hockley',
    'Dowagiac',
    'Berrien Springs',
    'Lisle',
    'Bradley',
    'Edwardsburg',
    'Hoffman Estates',
    'Enid',
    'Royse City',
    'Pekin',
    'Ottawa',
    'Spring Valley',
    'Cabery',
    'Cicero',
    'Cary',
    'Surry',
    'Pipe Creek',
    'Cherry Hill',
    'New Brunswick',
    'Issaquah',
    'Albany',
    'Victor',
    'Tanner',
    'East Greenbush',
    'Ashkum',
    'Maple Hill',
    'Graham',
    'Murphy',
    'Christiana',
    'Franklin',
    'Summertown',
    'Ashland City',
    'Murray',
    'Spring Hill',
    'Smithville',
    'Bethpage',
    'Hillsboro',
    'Indiantown',
    'Okeechobee',
    'Lakeland',
    'Napoleonville',
    'Natchez',
    'Poplarville',
    'Donaldsonville',
    'Johnstown',
    'Sterling Heights',
    'Lancaster',
    'Sardis',
    'Monaca',
    'Blackwater',
    'Oak Grove',
    'Louisburg',
    'Spring Hill',
    'Emporia',
    'Millbrae',
    'Jennings',
    'Buna',
    'Purcellville',
    'Crothersville',
    'Philo',
    'Arcadia',
    'Buckingham',
    'Monticello',
    'Parkersburg',
    'Ashland',
    'Carlisle',
    'Findlay',
    'Lignum',
    'Searcy',
    'White Hall',
    'Bauxite',
    'Dry Prong',
    'Coushatta',
    'Sterlington',
    'Huttig',
    'Marion',
    'Mena',
    'Roselle',
    'Boise',
    'Henderson',
    'Quincy',
    'Dundalk',
    'Ocean City',
    'Owings Mills',
    'Barrington',
    'Lowell',
    'Northborough',
    'Pflugerville',
    'Harker Heights',
    'East Alton',
    'Sunflower',
    'Galesville',
    'Chesterton',
    'Staten Island',
    'Englewood',
    'Madison',
    'Shelton',
    'Wallingford',
    'Chester',
    'South Windsor',
    'Colchester',
    'Groton',
    'Webster',
    'Friant',
    'Excelsior Springs',
    'Bristow',
    'Lawrence',
    'Clackamas',
    'Federal Way',
    'Gettysburg',
    'Cranbury',
    'Litchfield',
    'Sylvania',
    'Moro',
    'Shorewood',
    'Greenwood',
    'Highland',
    'Boyce',
    'Pomfret',
    'Carrollton',
    'Delmont',
    'Allen',
    'Lillington',
    'Clinton',
    'Wewoka',
    'Watertown',
    'Windsor',
    'Lancaster',
    'Bay Village',
    'Hartville',
    'Coopersville',
    'Laddonia',
    'Maricopa',
    'Linden',
    'Winsted',
    'Aromas',
    'Harvest',
    'Kathleen',
    'Coffeen',
    'Elgin',
    'Kaweah',
    'Salisbury',
    'Tyler',
    'Thomson',
    'Cudahy',
    'Brentwood',
    'Rincon',
    'Weldon',
    'Philpot',
    'Danville',
    'Eureka',
    'Sutter Creek',
    'Elmsford',
    'Powder Springs',
    'Davidson',
    'Barbourville',
    'Pelham',
    'Malden',
    'Dana Point',
    'Monroe',
    'San Carlos',
    'Liberty',
    'Brentwood',
    'Angie',
    'Holt',
    'Franklin',
    'Cordele',
    'Inwood',
    'Friendswood',
    'Crystal City',
    'Sheridan',
    'Childress',
    'Bessemer',
    'Deatsville',
    'Little Rock Air Force Base',
    'Arkadelphia',
    'Guymon',
    'Ada',
    'Clinton',
    'Pauls Valley',
    'Walkertown',
    'Boone',
    'Dansville',
    'Union City',
    'Moulton',
    'Orange',
    'Silsbee',
    'Cypress',
    'Saint Joseph',
    'Benicia',
    'Brewton',
    'Lovelady',
    'Belding',
    'Delafield',
    'Slinger',
    'Staatsburg',
    'Old Greenwich',
    'Akron',
    'White Cloud',
    'Grand Blanc',
    'Bethel',
    'Greenwood',
    'Boyle',
    'Raleigh',
    'Jonesboro',
    'Chaparral',
    'Roswell',
    'Zuni',
    'Alpine',
    'Beeville',
    'Marfa',
    'Hobe Sound',
    'Wadesboro',
    'Jackson',
    'Ethel',
    'Uvalde',
    'New Braunfels',
    'Sparta',
    'Cockeysville',
    'Roby',
    'Glen Carbon',
    'Atwood',
    'Richmond',
    'Highland',
    'Holt',
    'Maumelle',
    'Lynn Haven',
    'Prattville',
    'Beltsville',
    'Glencoe',
    'Stuart',
    'Okmulgee',
    'Bidwell',
    'New Berlin',
    'Kelseyville',
    'Farmington',
    'Brackettville',
    'Monroe',
    'Penfield',
    'Davenport',
    'Villa Park',
    'Theodore',
    'Richmond',
    'Williston',
    'Nuevo',
    'Whitley City',
    'Corbin',
    'Hamburg',
    'Fordyce',
    'Crossett',
    'Arbuckle',
    'Lathrop',
    'Goddard',
    'Fisk',
    'Seville',
    'Carson City',
    'Chico',
    'Dearborn Heights',
    'Milford',
    'Mount Clemens',
    'Montgomery',
    'Fort Davis',
    'Magee',
    'Madison',
    'Santa Maria',
    'Vails Gate',
    'Bronxville',
    'Newburgh',
    'Elizabeth',
    'Kendall Park',
    'Hope',
    'Damariscotta',
    'South Bristol',
    'Alna',
    'Lincolnville',
    'Belfast',
    'Pearsall',
    'Great Lakes',
    'Park Ridge',
    'Yates City',
    'Hamilton',
    'Edinburg',
    'Dagsboro',
    'Matthews',
    'Kernersville',
    'High Point',
    'Arden',
    'Burlington',
    'Elon',
    'Huntington Park',
    'Pine Plains',
    'Avalon',
    'Cedarburg',
    'Ironwood',
    'Pleasant Prairie',
    'Blue Hill',
    'Grand Island',
    'East Aurora',
    'Irmo',
    'Utica',
    'Tiffin',
    'Beverly',
    'West Sacramento',
    'Tahoe City',
    'Soda Springs',
    'Williams',
    'Girdwood',
    'Wilmette',
    'Berwick',
    'Parlier',
    'Dudley',
    'Havelock',
    'Sneads Ferry',
    'Moncks Corner',
    'Jenkinsville',
    'Greenwood',
    'Trenton',
    'Deerfield Beach',
    'Earth City',
    'Hazlehurst',
    'Westminster',
    'Wildorado',
    'Sonora',
    'Ripon',
    'Oakton',
    'Greenway Downs',
    'Gary',
    'Hopwood',
    'Johnstown',
    'Hermitage',
    'Rillton',
    'Maysville',
    'Mystic',
    'Newtown',
    'Portland',
    'Clawson',
    'Port Isabel',
    'Millican',
    'Benton Ridge',
    'Naubinway',
    'Cedar Rapids',
    'Machesney Park',
    'East Troy',
    'Whitewater',
    'Levittown',
    'East Boothbay',
    'Natick',
    'Bridgewater',
    'Nashua',
    'Jackson Township',
    'Iselin',
    'Walnut Creek',
    'Pittsburg',
    'Alameda',
    'Okemah',
    'Gardiner',
    'Revere',
    'Vineland',
    'Sanborn',
    'Clinton',
    'Byron Center',
    'Comstock Park',
    'Sturgis',
    'Alto',
    'Superior',
    'Rogersville',
    'Friendsville',
    'Powell',
    'Waleska',
    'Statesville',
    'Shepherdstown',
    'Graceville',
    'Creola',
    'Trinity',
    'Town Creek',
    'McVeytown',
    'Newville',
    'Mount Joy',
    'New Enterprise',
    'Imler',
    'Santa Cruz',
    'West Wareham',
    'Bedford',
    'Lewisville',
    'Smithville',
    'Pocahontas',
    'Cleveland',
    'Toone',
    'Millington',
    'Dresden',
    'Patterson',
    'Lake Geneva',
    'Melrose Park',
    'Madison',
    'Merrillville',
    'Mingo Junction',
    'Toronto',
    'Freeland',
    'Vero Beach',
    'Melbourne Beach',
    'Boynton Beach',
    'Amite',
    'Clinton',
    'Thibodaux',
    'Delhi',
    'Draper',
    'Clearfield',
    'Norwalk',
    'Mason City',
    'Plattsburgh',
    'Colchester',
    'Saratoga Springs',
    'Kingston',
    'Cumberland City',
    'Smyrna',
    'Hopkinsville',
    'Winchester',
    'Lexington Park',
    'Germantown',
    'Thurmont',
    'Takoma Park',
    'Lovettsville',
    'Towson',
    'Absecon',
    'Port Charlotte',
    'Estero',
    'North Port',
    'Calistoga',
    'Erin',
    'Roanoke',
    'Henry',
    'Rutherford',
    'Cherokee',
    'Alamo',
    'Schurz',
    'Lodi',
    'El Dorado Hills',
    'Visalia',
    'Momence',
    'Roanoke',
    'River Forest',
    'Port Jefferson Station',
    'Medford',
    'Central Islip',
    'Bay City',
    'Keego Harbor',
    'Garden City',
    'Acushnet',
    'Riverside',
    'Newport',
    'Alice',
    'Manor',
    'Glen Cove',
    'Oakley',
    'Williamsburg',
    'Farmville',
    'Camarillo',
    'Agoura Hills',
    'Dalton',
    'Stevens Point',
    'Waterford',
    'Roseville',
    'Denham Springs',
    'Glenmora',
    'North Olmsted',
    'Kalispell',
    'Darlington',
    'Johnsonville',
    'Elgin',
    'Russellville',
    'Frankfort',
    'Port Lavaca',
    'Lake City',
    'Cantrall',
    'Baldwin',
    'South Lyon',
    'Salina',
    'Brewster',
    'South Orange',
    'Saint Helena',
    'Bristol',
    'Hartford',
    'Thompsonville',
    'Lemon Grove',
    'Berlin Center',
    'Abbeville',
    'Port Gibson',
    'Lumberton',
    'Oak Grove',
    'Hampton',
    'Princeton',
    'Grants Pass',
    'Dime Box',
    'Big Rock',
    'Portage',
    'Watseka',
    'Bellwood',
    'Washington Court House',
    'Sedalia',
    'Manhattan',
    'Boonville',
    'Bird City',
    'Buckner',
    'Owens Cross Roads',
    'Plymouth',
    'Alexandria',
    'South Gate',
    'Kemp',
    'Emory',
    'Pittsburg',
    'Perryville',
    'New Madrid',
    'Town of Boxborough',
    'Franklin',
    'East New Market',
    'Stevensville',
    'Seymour',
    'Crown Point',
    'Zion',
    'La Grange Park',
    'Barberton',
    'Kilmarnock',
    'Ozark',
    'Rogers',
    'Lamar',
    'Fayetteville',
    'Republic',
    'Kimberling City',
    'Fayette',
    'Carterville',
    'Red Bank',
    'Lebanon',
    'Farmingdale',
    'Wakefield',
    'Ellsworth',
    'Milford',
    'Crescent Valley',
    'Rutherford',
    'Marlton',
    'Atco',
    'Lemont',
    'Glenwood',
    'Dale',
    'Richmond',
    'Covington',
    'Shadyside',
    'Mineral Wells',
    'Rosemont',
    'La Porte',
    'Chalmette',
    'DeRidder',
    'Presto',
    'Altoona',
    'Moundsville',
    'Wheeling',
    'Follansbee',
    'Teaneck',
    'New Carlisle',
    'New Haven',
    'Hillsdale',
    'Oktaha',
    'Wagoner',
    'Syracuse',
    'Little Falls',
    'Lockhart',
    'Dripping Springs',
    'Minnie',
    'West Liberty',
    'Sandy Hook',
    'Wellington',
    'Eolia',
    'Printer',
    'Jenkins',
    'La Jolla',
    'Fargo',
    'Sterling',
    'Clinton',
    'Needville',
    'Marietta',
    'Louisa',
    'Pikeville',
    'Macomb',
    'Niles',
    'Chadds Ford',
    'Midway',
    'Sweetwater',
    'Ashland',
    'Thomas',
    'Belhaven',
    'Youngstown',
    'Newport',
    'Fisherville',
    'Cumberland',
    'Sandy City',
    'Glenview',
    'Ecru',
    'Sidell',
    'Ridge Farm',
    'Basking Ridge',
    'Mableton',
    'Montevallo',
    'Harpersville',
    'Hamilton',
    'Calexico',
    'Fillmore',
    'Encinitas',
    'Cedar Creek',
    'New Albany',
    'Demorest',
    'Cedartown',
    'Loganville',
    'Junction City',
    'Trenton',
    'Cameron',
    'Eagle Lake',
    'Monkton',
    'Hampstead',
    'Easton',
    'Parkville',
    'Grayslake',
    'Holcombe',
    'Austin',
    'Huffman',
    'Jasper',
    'Atchison',
    'Evergreen Park',
    'Westfield Center',
    'Columbiana',
    'Newcomerstown',
    'Highland Park',
    'Orrville',
    'Austell',
    'Millboro',
    'Vernon Hills',
    'Forrest City',
    'Beebe',
    'Olive Branch',
    'Trenton',
    'Cuyahoga Falls',
    'Albert City',
    'Metamora',
    'Erlanger',
    'Pigeon Forge',
    'Newport',
    'South Deerfield',
    'North Windham',
    'Essex',
    'Wolcott',
    'Woodville',
    'Sandy',
    'Aliquippa',
    'Cromwell',
    'Uncasville',
    'Windsor Locks',
    'Nampa',
    'Clover',
    'Chatham',
    'Black Mountain',
    'Richmond',
    'Owensboro',
    'Hartford',
    'Loomis',
    'Bardstown',
    'Taylorsville',
    'Higginsville',
    'Van Buren',
    'McAlester',
    'Atascadero',
    'Holly Springs',
    'Columbia',
    'Orangeburg',
    'Kings Park',
    'Waterloo',
    'Sullivan',
    'Vandalia',
    'Iuka',
    'West Orange',
    'Williamsville',
    'Diamond',
    'Sterrett',
    'Almont',
    'Riverdale',
    'Clarksburg',
    'Haltom City',
    'Comanche',
    'Apollo Beach',
    'Anguilla',
    'Stanton',
    'Glassboro',
    'Mount Holly',
    'Washington',
    'College Park',
    'Rapidan',
    'Lincolnshire',
    'Williamsport',
    'Colusa',
    'Incline Village',
    'Yreka',
    'Mount Shasta',
    'Tifton',
    'Dunlap',
    'Milton',
    'Ripley',
    'Baker',
    'East Orange',
    'Berlin',
    'Clarks Summit',
    'Keystone Heights',
    'Temperance',
    'Charter Township of Clinton',
    'Lexington',
    'Woodhaven',
    'Accokeek',
    'Rhoadesville',
    'Maple Heights',
    'Raymore',
    'Kosciusko',
    'McCool',
    'Mountain Pine',
    'French Village',
    'Bernie',
    'Cape Coral',
    'Fort Myers Beach',
    'Youngtown',
    'Glasford',
    'Roswell',
    'Madison',
    'Cottondale',
    'Glendale Heights',
    'Springfield Township',
    'Fogelsville',
    'Malvern',
    'Schwenksville',
    'Wheaton',
    'Lomita',
    'Magna',
    'Zapata',
    'Dumas',
    'El Reno',
    'Piedmont',
    'Neffs',
    'Coshocton',
    'Middlesboro',
    'Dublin',
    'Winston-Salem',
    'Tazewell',
    'Booneville',
    'Ravenswood',
    'Satellite Beach',
    'Deltona',
    'Tehachapi',
    'Acton',
    'Sun Valley',
    'Monrovia',
    'Portola Valley',
    'Bristol',
    'Magnolia',
    'Sulphur',
    'Bloomingdale',
    'Bedford',
    'San Juan Bautista',
    'Aptos',
    'Reynoldsville',
    'Camp Hill',
    'New Bloomfield',
    'Temple',
    'Fayetteville',
    'Laguna Hills',
    'Terrell',
    'Long Lane',
    'Hillsboro',
    'South Beloit',
    'Noble',
    'Algonquin',
    'Pampa',
    'Turpin',
    'Geneva',
    'Collingswood',
    'Orange Park',
    'Dandridge',
    'Newman',
    'Stevinson',
    'West New York',
    'Enfield',
    'Branford',
    'Naugatuck',
    'Hopewell Junction',
    'Saint Johnsbury',
    'Gales Ferry',
    'Copperopolis',
    'Cotati',
    'Carterville',
    'Branson',
    'Logan',
    'Chugwater',
    'Hanover Park',
    'Haddonfield',
    'Rockledge',
    'Graham',
    'Fairview',
    'Silvis',
    'Taylors',
    'Pittsboro',
    'Royal',
    'Tillar',
    'Farmerville',
    'Boerne',
    'Kingsland',
    'Saint Simons Island',
    'La Grange',
    'Pewaukee',
    'Sandpoint',
    'Meridian',
    'Clinton',
    'Ruskin',
    'Suwannee',
    'Trussville',
    'Lawrenceburg',
    'Glenshaw',
    'Harrison',
    'West Babylon',
    'Imperial',
    'Rancho Mirage',
    'Kimberly',
    'Manitowoc',
    'Munster',
    'Manistee',
    'Winnetka',
    'Crossville',
    'Moundville',
    'Albertville',
    'Midway',
    'Wetumpka',
    'Washington',
    'Topanga',
    'Berlin',
    'Dunkirk',
    'Lecompte',
    'Vacherie',
    'Brawley',
    'Port Washington',
    'Danville',
    'Hammonton',
    'Burlington',
    'Salem',
    'Commack',
    'Shepherdsville',
    'Rock Hill',
    'Milan',
    'Blacklick',
    'Bayport',
    'Vernon',
    'Rockport',
    'Jellico',
    'Pine Knot',
    'Alsip',
    'Hernando',
    'Arlington',
    'Nevada City',
    'Titusville',
    'Hamburg',
    'Cookeville',
    'Plains',
    'Seagraves',
    'Denver City',
    'Maxwell',
    'Hot Springs National Park',
    'Dermott',
    'Dumas',
    'Pine Bluff',
    'Ashdown',
    'De Queen',
    'McGehee',
    'Arkansas City',
    'Harvey',
    'Moss Point',
    'Flomaton',
    'Seguin',
    'Chatom',
    'Ludowici',
    'Powderly',
    'Sumner',
    'Hoschton',
    'Clinton',
    'Fayetteville',
    'Haslet',
    'Flanders',
    'La Mesa',
    'Crow Agency',
    'Oregon City',
    'Platte City',
    'Carlsbad',
    'Kendallville',
    'Marion',
    'Live Oak',
    'Pico Rivera',
    'Pinehill',
    'Vina',
    'Salina',
    'Quinlan',
    'Wills Point',
    'Larned',
    'Ridgecrest',
    'Rocklin',
    'Turlock',
    'Gustine',
    'Converse',
    'Alvin',
    'Benton',
    'Ellinwood',
    'Harbor City',
    'Banning',
    'Cabazon',
    'New London',
    'Wathena',
    'Chillicothe',
    'Lockwood',
    'Billings',
    'Mount Vernon',
    'Sandwich',
    'Union',
    'Nobleboro',
    'Winnetka',
    'Lincoln',
    'Urbandale',
    'Wickliffe',
    'Clarkston',
    'Waupun',
    'Park Forest',
    'Neenah',
    'Lansing',
    'Middlebury',
    'Woodbury',
    'Trumbull',
    'Port Washington',
    'Pinehurst',
    'Northampton',
    'Snyder',
    'Tewksbury',
    'North Andover',
    'Plainville',
    'Upland',
    'Inez',
    'Moline',
    'Niota',
    'Canton',
    'Wilton',
    'Archer',
    'Homewood',
    'Mooresville',
    'Fort Lee',
    'Jericho',
    'Wabash',
    'Dry Ridge',
    'Pecos',
    'Newton Lower Falls',
    'Boxford',
    'Morrisville',
    'Mattapan',
    'Attleboro',
    'Eads',
    'Whitehouse',
    'Big Sandy',
    'Salem',
    'Redlands',
    'Bossier City',
    'Cottontown',
    'Mendota',
    'Clayton',
    'Paris',
    'Hoopeston',
    'Toledo',
    'West Union',
    'Ashmore',
    'Holden',
    'Eclectic',
    'Gadsden',
    'Anna',
    'Stratford',
    'Menasha',
    'Newton Highlands',
    'Cuero',
    'Fairmont',
    'DeSoto',
    'Sanger',
    'Whitney',
    'Lindon',
    'Monroe',
    'Southport',
    'Springville',
    'Bostic',
    'Stanley',
    'Church Hill',
    'Fairmont',
    'Kerrville',
    'Oroville',
    'Goodwater',
    'West Louisville',
    'New Haven',
    'Sellersburg',
    'Marion',
    'Elizabethtown',
    'Columbia City',
    'Newington',
    'Bryans Road',
    'Hempstead',
    'Benkelman',
    'Newton',
    'Valhermoso Springs',
    'Shelby',
    'Elkview',
    'Leesville',
    'Deming',
    'Batesville',
    'Opolis',
    'Hinckley',
    'Hebron',
    'Somerset',
    'Rodeo',
    'Alpine',
    'Torrington',
    'Allendale',
    'Cheraw',
    'Westbrook',
    'Hampton',
    'Cotulla',
    'Jourdanton',
    'Sabinal',
    'Natchitoches',
    'Pleasant Hill',
    'Campti',
    'Converse',
    'Pelican',
    'Marthaville',
    'Calumet City',
    'Wisconsin Dells',
    'Daphne',
    'Lake Village',
    'Redfield',
    'Fruitland',
    'Ogden',
    'Watertown',
    'Grove',
    'Playa del Rey',
    'South Portland',
    'Gainesville',
    'Moodus',
    'Heber Springs',
    'Cape May',
    'Silverdale',
    'Mountain Home',
    'Atlantic Beach',
    'Callahan',
    'Loxahatchee Groves',
    'South Ozone Park',
    'Corning',
    'Lake Crystal',
    'Sioux Falls',
    'Seward',
    'Milford',
    'Bunceton',
    'New Baltimore',
    'Whitehouse',
    'Brownsville',
    'Ramsey',
    'Attalla',
    'Bristol',
    'Wilbraham',
    'Mansfield Center',
    'Tucumcari',
    'Edison',
    'Hogansville',
    'Kingston',
    'Milledgeville',
    'Chelsea',
    'Bloomington',
    'Plainville',
    'Cartersville',
    'Mary Esther',
    'Sherwood',
    'Winfield',
    'Chase',
    'Alpine',
    'Chaska',
    'Jean',
    'Mineral Wells',
    'Springtown',
    'Aledo',
    'Lake Oswego',
    'Pacifica',
    'San Angelo',
    'East Peoria',
    'Qulin',
    'Kahoka',
    'Hondo',
    'Benton',
    'Tullahoma',
    'Grand Rivers',
    'Clark',
    'Hendersonville',
    'Prairieville',
    'San Juan Capistrano',
    'Senoia',
    'Cadiz',
    'Jerusalem',
    'Cramerton',
    'Southmayd',
    'Esparto',
    'Amargosa Valley',
    'Tybo',
    'Howard City',
    'Gaylord',
    'Dudley',
    'Park City',
    'Plymouth Meeting',
    'Chester',
    'Pearcy',
    'Scott',
    'Mansfield',
    'Mer Rouge',
    'Mound Bayou',
    'Camden',
    'Savannah',
    'Houston',
    'Taylor',
    'Forest Park',
    'Helena',
    'Athens',
    'Centreville',
    'Patton',
    'Yucca Valley',
    'Eaton Rapids',
    'Cropseyville',
    'Lexington',
    'West',
    'Rosser',
    'Clifton',
    'Mart',
    'Henderson',
    'Culleoka',
    'Westminster',
    'Laughlin',
    'West Plains',
    'La Vernia',
    'Wallis',
    'Fulshear',
    'Winona',
    'Center',
    'Hannibal',
    'Monroe City',
    'New London',
    'Jonesboro',
    'Monterey Park',
    'Presidio',
    'Bogota',
    'Strunk',
    'Benham',
    'Lostant',
    'Granby',
    'Kearney',
    'Cleveland',
    'Lincoln',
    'Hummelstown',
    'Carrollton',
    'Destin',
    'Brandenburg',
    'Woodstock',
    'Yanceyville',
    'Holdenville',
    'Erwin',
    'Stanford',
    'Interlochen',
    'Merritt Island',
    'Valrico',
    'Brandon',
    'Venus',
    'Star City',
    'Waterproof',
    'New Buffalo',
    'Lewiston',
    'Petoskey',
    'Olivet',
    'Millersburg',
    'Walled Lake',
    'Opelousas',
    'New Milford',
    'Agawam',
    'Lamar',
    'Yountville',
    'Auburn',
    'Elmore',
    'Brookline',
    'Talladega',
    'Weslaco',
    'Pontotoc',
    'Sanford',
    'Marquette',
    'Central City',
    'Effingham',
    'Smithton',
    'Prairie Village',
    'Morristown',
    'Bean Station',
    'White Pine',
    'Talbott',
    'Browns Summit',
    'Walnut Grove',
    'West Memphis',
    'Logansport',
    'Mountain Lakes',
    'Matteson',
    'Albany',
    'Anderson',
    'Savoy',
    'Fowler',
    'Arcata',
    'Eureka',
    'Gonzales',
    'Fairhope',
    'Rehoboth Beach',
    'Warrington',
    'Los Molinos',
    'Imperial Beach',
    'Crestwood',
    'Ellsworth',
    'Wyandotte',
    'Fenton',
    'Central Village',
    'Poteet',
    'Marathon',
    'Brighton',
    'Hamilton',
    'Sulligent',
    'Dallas',
    'Onaway',
    'Atoka',
    'Rogers',
    'Whitmore Lake',
    'San Juan',
    'Grenada',
    'Ruleville',
    'Terry',
    'Grand Bay',
    'Macon',
    'Steens',
    'Clemson',
    'Forkland',
    'Logan',
    'Marion Junction',
    'Bowie',
    'Paso Robles',
    'Waters',
    'Granger',
    'Robards',
    'Williamsville',
    'Rankin',
    'Middleville',
    'Elko',
    'Creedmoor',
    'Winston',
    'Catheys Valley',
    'Kennett',
    'Devine',
    'Waterford',
    'Lakewood',
    'Elyria',
    'Valley Center',
    'Westchester',
    'Harvard',
    'Spring Grove',
    'San Simeon',
    'Hemet',
    'Conyers',
    'Cedar City',
    'Waxhaw',
    'Luxora',
    'Descanso',
    'Perkinston',
    'Delcambre',
    'Raceland',
    'North Haven',
    'North Dartmouth',
    'Griffin',
    'Simonton',
    'Montara',
    'Chowchilla',
    'Hickory Hills',
    'California City',
    'Highland',
    'High Ridge',
    'Alton',
    'Hercules',
    'Burlington',
    'Windsor',
    'Fort Stockton',
    'Travelers Rest',
    'Braidwood',
    'Beaumont',
    'Port Hueneme',
    'Sweeny',
    'Carrizo Springs',
    'East Hanover',
    'Poplar Grove',
    'Fairfax',
    'Middleburg',
    'Chesapeake Beach',
    'Clarksdale',
    'Rockwood',
    'Hitchcock',
    'Camp H M Smith',
    'Grand Terrace',
    'East Sparta',
    'Union City',
    'Summerdale',
    'Fort Polk',
    'South Boardman',
    'Bristol',
    'Elwood',
    'Wautoma',
    'Seatonville',
    'Alvaton',
    'Huntsville',
    'South Pittsburg',
    'Greene',
    'Mequon',
    'Hampshire',
    'Carpentersville',
    'Miami',
    'Stamford',
    'Jerome',
    'Anson',
    'Cisco',
    'Geismar',
    'Hudson',
    'Clare',
    'Rialto',
    'Cerritos',
    'Mojave',
    'Florence',
    'Warrensburg',
    'Hillsborough',
    'Carbondale',
    'Campbell',
    'Bay Minette',
    'Miramar Beach',
    'New Roads',
    'Bellville',
    'Bedford',
    'Washington',
    'Tallassee',
    'Equality',
    'Heidelberg',
    'Oak Forest',
    'Northfield',
    'Hutto',
    'Burbank',
    'Hillsdale',
    'Nunica',
    'Milan',
    'College Station',
    'Big Rapids',
    'Frankfort',
    'Logansport',
    'Lockport',
    'Keasbey',
    'Ada',
    'Snellville',
    'Hahira',
    'Sebastopol',
    'Malaga',
    'Calvert',
    'Olmito',
    'Port Arthur',
    'Pinckneyville',
    'Lake Ozark',
    'Aberdeen',
    'Troy',
    'Elgin',
    'Mission Hills',
    'Oglesby',
    'Hamtramck',
    'Magnolia',
    'McKeesport',
    'Armonk',
    'Bloomfield',
    'Passaic',
    'Marion',
    'Council Grove',
    'Dane',
    'Glencoe',
    'Hallwood',
    'Hesperia',
    'Del Mar',
    'Millsap',
    'Three Rivers',
    'Armington',
    'Union Hill',
    'Adamsville',
    'Greensboro',
    'Churchville',
    'Gloucester',
    'Lompoc',
    'Bellflower',
    'Salida',
    'Stephenville',
    'Azle',
    'Springdale',
    'Siloam Springs',
    'Leavenworth',
    'Parsons',
    'Lacombe',
    'Fox River Grove',
    'Hope',
    'Delhi',
    'McKenzie',
    'Melissa',
    'Charleston',
    'De Forest',
    'Paintsville',
    'Bodega Bay',
    'Lowell',
    'Godley',
    'Rainbow',
    'Delta',
    'Macedonia',
    'Seward',
    'Oxford',
    'Corona',
    'South Wilmington',
    'Smithland',
    'Morgantown',
    'Pittsview',
    'Kingstree',
    'Loretto',
    'Duncanville',
    'Pomfret Center',
    'Brooklyn',
    'La Palma',
    'Hidalgo',
    'Daytona Beach',
    'Carteret',
    'Northvale',
    'Gilroy',
    'Hughson',
    'Portsmouth',
    'Middletown',
    'Mulberry',
    'Colleyville',
    'Spicewood',
    'Tuskegee',
    'Tavernier',
    'Marion',
    'Stanton',
    'Brownsburg',
    'Pembroke',
    'Westminster',
    'Cross Plains',
    'Kiel',
    'Colorado City',
    'Tolar',
    'Billings',
    'Yonkers',
    'Leeds',
    'Citrus Heights',
    'Mauckport',
    'Nicholasville',
    'Covington',
    'Greenville',
    'Fraser',
    'Calhoun',
    'Pioneer',
    'Orangeburg',
    'Addison',
    'Union Grove',
    'Redwood Valley',
    'New Paltz',
    'Gardiner',
    'Pala',
    'Clark Fork',
    'Fort Belvoir',
    'Morrill',
    'Grandview',
    'Stilwell',
    'Fort Scott',
    'Oxford',
    'Bonsall',
    'Redford',
    'La Verne',
    'Pacoima',
    'Woodway',
    'Clio',
    'Bradley Beach',
    'West Simsbury',
    'La Pryor',
    'Millbrook',
    'Whitinsville',
    'Glenmont',
    'Chagrin Falls',
    'East Walpole',
    'Honea Path',
    'Hensley',
    'Centerville',
    'Wayland',
    'Brookfield',
    'Sunland',
    'Ottumwa',
    'Lake Dallas',
    'Rayville',
    'Gilbert',
    'Walnut Ridge',
    'Lancaster',
    'Ionia',
    'Stevenson Ranch',
    'Clayton',
    'Toomsuba',
    'Grove City',
    'Johnston',
    'Easton',
    'Acampo',
    'Winters',
    'Fairdale',
    'Prosper',
    'Dillon',
    'Jamaica Plain',
    'Brook Park',
    'Capitola',
    'Troy',
    'Wareham',
    'Bono',
    'Franklinton',
    'Wellston',
    'Corvallis',
    'Dayton',
    'Souderton',
    'Paterson',
    'New Cumberland',
    'Garrison',
    'Slater',
    'Elk Grove',
    'Conover',
    'Potomac',
    'Calumet',
    'Montgomeryville',
    'Middletown',
    'Fort Washington',
    'Devon',
    'Ridley Park',
    'Warminster',
    'Brockton',
    'Pottstown',
    'Villanova',
    'Phoenixville',
    'Glen Mills',
    'West Chester',
    'Gilbertsville',
    'Broomall',
    'Hatboro',
    'Perkasie',
    'Furlong',
    'Downingtown',
    'Barto',
    'Exton',
    'West Grove',
    'Upper Darby',
    'Ocean View',
    'Pennsauken Township',
    'Wallington',
    'South Plainfield',
    'Woodbury',
    'Marmora',
    'Atlantic Highlands',
    'Riverside',
    'Camden',
    'Hillside',
    'Toms River',
    'Princeton Junction',
    'Cranford',
    'Friendship',
    'Ridgely',
    'Salisbury',
    'Abingdon',
    'New Windsor',
    'Burtonsville',
    'Steamboat Springs',
    'Monument',
    'Berthoud',
    'Erie',
    'Brighton',
    'Carbondale',
    'Dacono',
    'Wellington',
    'Fort Bragg',
    'Soquel',
    'Glade Spring',
    'Caryville',
    'Oak Grove',
    'Harrisburg',
    'Happy Valley',
    'Kelso',
    'McMinnville',
    'Camas',
    'Battle Ground',
    'Coloma',
    'Avilla',
    'Chatham',
    'Cerro Gordo',
    'Winfield',
    'Maple Park',
    'Lincolnwood',
    'Pendleton',
    'Justice',
    'Lake Villa',
    'Harvey',
    'Export',
    'Morgantown',
    'New Middletown',
    'Jeannette',
    'Windber',
    'Tarentum',
    'Mars',
    'Irwin',
    'Belle Vernon',
    'Prosperity',
    'Bridgeville',
    'Wilmerding',
    'Latrobe',
    'North Versailles',
    'Meyersdale',
    'Boydton',
    'Axton',
    'Huntington',
    'Scottsville',
    'Fort Lee',
    'Bentonville',
    'Sabot',
    'Rockville',
    'Pulaski',
    'Palmyra',
    'Blacksburg',
    'Kent',
    'Puyallup',
    'Marysville',
    'Poulsbo',
    'Enumclaw',
    'Snohomish',
    'Minnetonka',
    'Andover',
    'Greeneville',
    'Eden Prairie',
    'Madison',
    'Sherman',
    'Canton',
    'Marianna',
    'Dover',
    'Suncook',
    'Wayland',
    'Old Saybrook',
    'Concord',
    'Epping',
    'Hingham',
    'Canaan',
    'Medford',
    'Hooksett',
    'Hudson',
    'Bellingham',
    'Shelburne',
    'Randolph',
    'Lakeville',
    'Middlebury',
    'South Easton',
    'Hopedale',
    'North Berwick',
    'Newtonville',
    'Middlefield',
    'Derry',
    'White River Junction',
    'Stratham',
    'Hampstead',
    'Salem',
    'Derby',
    'Cobalt',
    'Haydenville',
    'East Weymouth',
    'Mason',
    'Williamston',
    'Charlotte',
    'Dimondale',
    'Laingsburg',
    'Bath',
    'Pleasant Lake',
    'Saranac',
    'Marshall',
    'Fowlerville',
    'Lake Odessa',
    'Perry',
    'Hastings',
    'Homer',
    'Horton',
    'Grass Lake',
    'Union City',
    'Avon Lake',
    "O'Neals",
    'North Fork',
    'Auberry',
    'Prather',
    'Tollhouse',
    'Mission',
    'Kittery',
    'Hemingford',
    'Alliance',
    'Chadron',
    'Scottsville',
    'Monticello',
    'Lebanon',
    'Central City',
    'Oakland',
    'Waipahu',
    'Jessup',
    'Middle River',
    'Parkton',
    'Riverdale Hills',
    'Georgetown',
    'Millersville',
    'Odenton',
    'Bristow',
    'Fort George Meade',
    'Severn',
    'Ceres',
    'Altaville',
    'Willows',
    'Murphys',
    'Farmington',
    'Springville',
    'Taylorsville',
    'Kaysville',
    'Channelview',
    'Lyndhurst',
    'Riverton',
    'Maplewood',
    'Bound Brook',
    'Tenafly',
    'Bellmawr',
    'Bedminster',
    'Long Branch',
    'Orange',
    'Somerdale',
    'Freehold Borough',
    'Hackettstown',
    'Plainfield',
    'Saddle Brook',
    'Lenni',
    'Chester',
    'Manahawkin',
    'Maple Shade',
    'Williamstown',
    'Alloway',
    'Millville',
    'Vauxhall',
    'Danvers',
    'West Springfield',
    'Lunenburg',
    'Ashburnham',
    'Shirley',
    'Dover',
    'Chester',
    'South Yarmouth',
    'Gardner',
    'Newburyport',
    'Medfield',
    'Ayer',
    'Durham',
    'Conway',
    'Baldwinville',
    'Hanover',
    'Centerbrook',
    'Peterborough',
    'Hyannis',
    'Poughquag',
    'North Billerica',
    'Lebanon',
    'Stoneham',
    'Amherst',
    'Mendon',
    'Concord',
    'Medway',
    'Sudbury',
    'Yarmouth Port',
    'Greenfield',
    'Wellesley',
    'Danville',
    'Nantucket',
    'Rainbow City',
    'Carlisle',
    'East Earl',
    'Beaver Springs',
    'Reedsville',
    'Pittston',
    'Millersburg',
    'Stillwater',
    'Cottage Grove',
    'Anoka',
    'Inver Grove Heights',
    'Monticello',
    'Jensen Beach',
    'Winter Park',
    'Commerce City',
    'Shenandoah',
    'Amelia Court House',
    'Crozet',
    'Gordonsville',
    'Bluefield',
    'Waynesboro',
    'Bridgewater',
    'Petersburg',
    'Newberry',
    'Jasper',
    'Schenectady',
    'Peachtree City',
    'Bishop',
    'Jacksboro',
    'Belknap',
    'Metropolis',
    'Harriman',
    'Gallatin',
    'Abingdon',
    'North Fort Myers',
    'Gainesville',
    'Elkton',
    'Martinsburg',
    'Finksburg',
    'Prince Frederick',
    'Essex',
    'Joppa',
    'Sykesville',
    'Olney',
    'Mount Airy',
    'Swedesboro',
    'Sewell',
    'Egg Harbor City',
    'Millburn',
    'Yaphank',
    'Mays Landing',
    'Selinsgrove',
    'Strasburg',
    'Quarryville',
    'Leola',
    'Bellefonte',
    'Lititz',
    'Chambersburg',
    'Bainbridge',
    'Saint Clair',
    'Walnut',
    'Medford',
    'Novelty',
    'Lenoir',
    'Cambria Heights',
    'Burnsville',
    'Rienzi',
    'Romeo',
    'Adrian',
    'Plantsville',
    'Burlington',
    'Oak Lawn',
    'Muscle Shoals',
    'Tuscumbia',
    'Valley Village',
    'Glenpool',
    'Jonesboro',
    'Wasco',
    'Arvin',
    'Half Moon Bay',
    'Yuba City',
    'Vidor',
    'Cedar Hill',
    'Highland',
    'Lyons',
    'Paradise',
    'Fulton',
    'Hazelwood',
    'Freeport',
    'Marlin',
    'San Marino',
    'Euclid',
    'San Pablo',
    'Pinckney',
    'El Centro',
    'Jacumba Hot Springs',
    'Bremen',
    'Wilmington',
    'Hereford',
    'Terra Bella',
    'Dixon',
    'Ansonia',
    'Wendell',
    'Mt. Pleasant',
    'Johns Island',
    'Hazlehurst',
    'Scott City',
    'Hutchinson',
    'Rockville',
    'Havana',
    'Herington',
    'Fairfield',
    'Fort Payne',
    'Fort Knox',
    'Peoria Heights',
    'Lawton',
    'Minooka',
    'Rock Island',
    'Kennedale',
    'Baldwyn',
    'Verona',
    'Rockingham',
    'Lumberton',
    'Vandalia',
    'Dyer',
    'Newberry',
    'Struthers',
    'Orland',
    'Dayton',
    'North Lima',
    'Sylvester',
    'Huntland',
    'Silver Springs',
    'Hazel Green',
    'Toney',
    'Hazel Crest',
    'De Soto',
    'Dodge City',
    'McCalla',
    'Arroyo Grande',
    'Los Osos',
    'Templeton',
    'South Milwaukee',
    'Kingston',
    'Ellenwood',
    'Cayce',
    'Commerce',
    'Durant',
    'Sturtevant',
    'Grant',
    'Minden',
    'Keene',
    'Medway',
    'Earle',
    'Lockport',
    'Eufaula',
    'Warren',
    'Claxton',
    'Maywood',
    'South Elgin',
    'Leslie',
    'Selma',
    'Bay Saint Louis',
    'Edgewater',
    'New Smyrna Beach',
    'Tawas City',
    'Burton',
    'Newton',
    'Bethany',
    'Lexington',
    'Harlem',
    'Warrenton',
    'Ojai',
    'Fort Gratiot Township',
    'Tallulah',
    'Washington',
    'Homer Glen',
    'Sturgis',
    'Dawson Springs',
    'Rancho Santa Margarita',
    'Dayville',
    'Simsbury',
    'Wauconda',
    'Phenix City',
    'Wyoming',
    'West Lafayette',
    'Thornville',
    'Goose Creek',
    'Wake Forest',
    'Bainbridge',
    'Waycross',
    'Monticello',
    'Helotes',
    'Ferndale',
    'Denver',
    'Lincolnton',
    'South Houston',
    'Zachary',
    'Greenwell Springs',
    'Chippewa Falls',
    'Utica',
    'Smartt',
    'Ardmore',
    'Weatherford',
    'Rosamond',
    'Penney Farms',
    'Westerville',
    'Hamlin',
    'Eastman',
    'Camden',
    'Hixson',
    'Van Alstyne',
    'Sanford',
    'Kenmore',
    'Mesquite',
    'Binghamton',
    'Owego',
    'Oregon',
    'McKee',
    'Tyner',
    'Annville',
    'Forsyth',
    'Feasterville-Trevose',
    'Pikesville',
    'Forest City',
    'New Milford',
    'Union Dale',
    'Pleasant Mount',
    'Factoryville',
    'Thompson',
    'Waymart',
    'Kingsley',
    'Prompton',
    'Wasilla',
    'Palmer',
    'Dodd City',
    'Ivanhoe',
    'Ravenna',
    'Telephone',
    'Honey Grove',
    'Goleta',
    'Yucaipa',
    'Moorpark',
    'Menifee',
    'Pacific Palisades',
    'North Hills',
    'Tujunga',
    'Sierra Madre',
    'Monticello',
    'Morton',
    'Pocatello',
    'Aberdeen',
    'Machias',
    'East Machias',
    'Princeton',
    'Addison',
    'Steuben',
    'Chardon',
    'Florence',
    'Signal Mountain',
    'Ringgold',
    'Ooltewah',
    'Harrison',
    'Montclair',
    'Pittsford',
    'Plainview',
    'Sloughhouse',
    'Newcastle',
    'Penryn',
    'Pleasant Grove',
    'Saint Ansgar',
    'Riceville',
    'Greene',
    'Nora Springs',
    'Plymouth',
    'Floyd',
    'Rudd',
    'Frenchburg',
    'Elkfork',
    'Salt Lick',
    'Campton',
    'South River',
    'Colonia',
    'Bayville',
    'Beachwood',
    'Browns Mills',
    'Paulsboro',
    'Sicklerville',
    'Barnegat',
    'Forked River',
    'Long Valley',
    'Island Heights',
    'Somers Point',
    'Montclair',
    'Fanwood',
    'Brigantine',
    'Ventnor City',
    'Tuckerton',
    'Waretown',
    'Atlantic City',
    'Leonardo',
    'Mickleton',
    'Manchester Township',
    'Little Silver',
    'Deal',
    'Holmdel',
    'Lincroft',
    'Fair Haven',
    'West Long Branch',
    'New Egypt',
    'Wrightstown',
    'Newfield',
    'Elmer',
    'Franklinville',
    'Skillman',
    'Linwood',
    'North Arlington',
    'Lanoka Harbor',
    'Glendora',
    'Gloucester City',
    'Vincentown',
    'Flemington',
    'Oceanport',
    'Rumson',
    'Hightstown',
    'Spotswood',
    'Three Oaks',
    'Antioch',
    'Channahon',
    'Andrews',
    'Oswego',
    'Plano',
    'Bridgman',
    'Griffith',
    'Dolton',
    'Monticello',
    'Monon',
    'Berkeley',
    'Huntertown',
    'Ellettsville',
    'Spencer',
    'Chicago Ridge',
    'Princeton',
    'LaSalle',
    'Lake Station',
    'Peru',
    'Thornton',
    'Flossmoor',
    'Worth',
    'Palos Hills',
    'Grain Valley',
    'Odessa',
    'Sibley',
    'North Highlands',
    'Sanger',
    'West Jordan',
    'Midvale',
    'Hyrum',
    'Smithfield',
    'Layton',
    'Willard',
    'Oakdale',
    'Beaver',
    'Wellsville',
    'Edinburg',
    'Lisbon',
    'East Freetown',
    'Assonet',
    'Fall River',
    'Arlington',
    'Milton',
    'Milton',
    'Holyoke',
    'South Hadley',
    'Groveland',
    'Pawling',
    'Old Lyme',
    'Cotuit',
    'Marstons Mills',
    'Bellows Falls',
    'Charlestown',
    'Alstead',
    'North Branford',
    'Holmes',
    'Taunton',
    'Swansea',
    'East Berlin',
    'East Canaan',
    'Ivoryton',
    'Durham',
    'Fremont',
    'Exeter',
    'Armagh',
    'Ligonier',
    'Alverda',
    'Hooversville',
    'Listie',
    'Gifford',
    'Smethport',
    'Kane',
    'Indiana',
    'Barnesville',
    'Vandergrift',
    'Apollo',
    'Brilliant',
    'Richmond',
    'East Pittsburgh',
    'Youngwood',
    'New Stanton',
    'Hunker',
    'Bovard',
    'Ruffs Dale',
    'Madison',
    'New Alexandria',
    'Herminie',
    'West Newton',
    'Crabtree',
    'Ambridge',
    'Baden',
    'Conway',
    'Sewickley',
    'Freedom',
    'Carnegie',
    'Cairnbrook',
    'Oil City',
    'Brookville',
    'Clarion',
    'Maidsville',
    'South Park Township',
    'Wexford',
    'Allison Park',
    'Springdale',
    'Natrona Heights',
    'McKees Rocks',
    'Duquesne',
    'Seneca',
    'Stoystown',
    'Berlin',
    'Shanksville',
    'New Eagle',
    'Connellsville',
    'Donora',
    'Fairmont',
    'Mannington',
    'Bridgeport',
    'Tiltonsville',
    'Martins Ferry',
    'Friedens',
    'New Paris',
    'Elizabeth',
    'Monongahela',
    'Monessen',
    'Charleroi',
    'Bairdford',
    'Punxsutawney',
    'Oakmont',
    'Dillonvale',
    'Discovery Bay',
    'El Cerrito',
    'Clayton',
    'Moraga',
    'San Anselmo',
    'Guerneville',
    'Corte Madera',
    'Lagunitas',
    'Ben Lomond',
    'El Sobrante',
    'Pollock Pines',
    'North Aurora',
    'Saint Joseph',
    'Middletown',
    'Morrison',
    'Evergreen',
    'Indian Hills',
    'Dillon',
    'Manitou Springs',
    'Wheat Ridge',
    'Silverthorne',
    'Breckenridge',
    'Granby',
    'Greeley',
    'Parachute',
    'Fraser',
    'Pueblo',
    'Snowmass',
    'Conifer',
    'Snowmass Village',
    'Basalt',
    'Cedar Ridge',
    'Rio Linda',
    'Mather Field',
    'Elverta',
    'Galt',
    'Magalia',
    'Mi-Wuk Village',
    'Millville',
    'Corinne',
    'Garland',
    'Riverside',
    'Tremonton',
    'Pleasant Grove',
    'Tooele',
    'Roy',
    'Twin Lake',
    'Spring Lake',
    'Willis',
    'Mt. Morris',
    'Washington',
    'Inkster',
    'Swartz Creek',
    'South Haven',
    'Saugatuck',
    'Howell',
    'Eastpointe',
    'Tecumseh',
    'Paw Paw',
    'Waterford',
    'Flushing',
    'Jonesville',
    'Dexter',
    'West Bloomfield',
    'Birmingham',
    'Lawton',
    'Portage',
    'St. Helena',
    'Hampton',
    'DeWitt',
    'Genesee',
    'Clio',
    'Okemos',
    'Hartland',
    'Davisburg',
    'Peru',
    'Ladd',
    'Utica',
    'Osceola',
    'Whiting',
    'DeMotte',
    'Hebron',
    'Wheatfield',
    'Westville',
    'Morton Grove',
    'McCordsville',
    'Sawyer',
    'Beecher',
    'Country Club Hills',
    'Monee',
    'Cedar Lake',
    'River Grove',
    'Reynolds',
    'Western Springs',
    'Lawrenceburg',
    'West Harrison',
    'DeKalb',
    'Fountaintown',
    'Oakwood',
    'Mokena',
    'Saint Anne',
    'Steger',
    'Macomb',
    'Wadsworth',
    'North Bend',
    'Preston',
    'Snoqualmie',
    'Gig Harbor',
    'Woodinville',
    'Lake Stevens',
    'Sammamish',
    'Everett',
    'Shelton',
    'Rochester',
    'Lacey',
    'Chehalis',
    'Bremerton',
    'Kingston',
    'Spanaway',
    'Bainbridge Island',
    'Buckley',
    'Black Diamond',
    'Maple Valley',
    'Anacortes',
    'Lakewood',
    'Centralia',
    'South Prairie',
    'Seahurst',
    'Darrington',
    'Aberdeen',
    'Ferndale',
    'Sedro-Woolley',
    'Mountlake Terrace',
    'Granite Falls',
    'Bonney Lake',
    'Blaine',
    'Graham',
    'Burlington',
    'Ravensdale',
    'Yelm',
    'Tenino',
    'Lynden',
    'University Place',
    'Camano',
    'Fox Island',
    'Sumner',
    'Fall City',
    'Mount Vernon',
    'Roy',
    'Olalla',
    'Colbert',
    'Fairchild Air Force Base',
    'Springfield',
    'Forest Grove',
    'Gresham',
    'Tualatin',
    'Wilsonville',
    'Keizer',
    'West Linn',
    'Brush Prairie',
    'Longview',
    'Ridgefield',
    'North Plains',
    'Cornelius',
    'Fairview',
    'Boring',
    'Yamhill',
    'Newberg',
    'Lebanon',
    'Sweet Home',
    'Lafayette',
    'Damascus',
    'Ariel',
    'Gladstone',
    'Kalama',
    'Scappoose',
    'Saint Helens',
    'Hoquiam',
    'Montesano',
    'Westport',
    'Oakville',
    'Monroe',
    'South Bend',
    'McCleary',
    'Elma',
    'Rough and Ready',
    'Penn Valley',
    'Clarklake',
    'Brooklyn',
    'Hephzibah',
    'Wildwood',
    'Fombell',
    'Metz',
    'Santa Ynez',
    'Alamo',
    'Carmel',
    'Ithaca',
    'Deer Park',
    'San Fernando',
    'Idyllwild',
    'Elizabethtown',
    'Southport',
    'Whiteville',
    'Gloversville',
    'Adams',
    'Cohoes',
    'Troy',
    'North Adams',
    'Gansevoort',
    'North Conway',
    'Lewiston',
    'Lisbon',
    'Lisbon Falls',
    'Coronado',
    'Makawao',
    'Wahiawa',
    'Versailles',
    'London',
    'Hudson',
    'Corydon',
    'Galloway',
    'Van Wert',
    'Marengo',
    'Malta',
    'Little Hocking',
    'Waterford',
    'Killeen',
    'Liberty Hill',
    'Commerce',
    'Loveland',
    'Peebles',
    'Wimberley',
    'Union City',
    'South Charleston',
    'Middletown',
    'Greenville',
    'Germantown',
    'Castleton-on-Hudson',
    'Valatie',
    'Kinderhook',
    'Ticonderoga',
    'Queensbury',
    'Northville',
    'Delmar',
    'Eaton',
    'North Royalton',
    'Willoughby',
    'Lindale',
    'Lithonia',
    'Hiram',
    'Pendergrass',
    'Twentynine Palms',
    'Indio',
    'Greenwich',
    'Campbell Hall',
    'Poestenkill',
    'Auburn',
    'Lake Lure',
    'Forest City',
    'Liberty',
    'Bullard',
    'Flint',
    'Groesbeck',
    'Mexia',
    'Statesboro',
    'Crockett',
    'Central',
    'Pendleton',
    'Laurens',
    'Caribou',
    'Bangor',
    'Biddeford',
    'Old Town',
    'Fort Kent',
    'Saint Agatha',
    'Waverly',
    'New Lexington',
    'Logan',
    'Napoleon',
    'Bryan',
    'Sunbury',
    'Andrews',
    'Aubrey',
    'Colfax',
    'Anna',
    'Fort Sill Indian School Reservation (historical)',
    'Many',
    'San Augustine',
    'Electra',
    'Big Lake',
    'Breckenridge',
    'Brady',
    'Iowa Park',
    'Burkburnett',
    'Ville Platte',
    'Charleston',
    'De Witt',
    'Detroit',
    'Clarksville',
    'Blossom',
    'Healdton',
    'Wilson',
    'Heavener',
    'Ingram',
    'Junction',
    'Kermit',
    'Lampasas',
    'Monahans',
    'Nashville',
    'Rockdale',
    'Rusk',
    'Cottonport',
    'Bunkie',
    'Argenta',
    'Buckhannon',
    'Maryville',
    'Crane',
    'Kellogg',
    'Foresthill',
    'Stuttgart',
    'Pleasant City',
    'Carlisle',
    'Hazen',
    'Ringling',
    'Post Falls',
    'Mount Ida',
    'Osawatomie',
    'Wellington',
    'Russellville',
    'Osburn',
    'Anadarko',
    'Urbana',
    'Springboro',
    'East Bridgewater',
    'Stoughton',
    'West Bridgewater',
    'Hanson',
    'Scituate',
    'Fitchburg',
    'Townsend',
    'Leominster',
    'Amherst',
    'Weymouth',
    'North Weymouth',
    'Hull',
    'Fair Haven',
    'Somersworth',
    'Terryville',
    'Ipswich',
    'Byfield',
    'Rowley',
    'Monson',
    'Palmer',
    'Needham',
    'Newton Upper Falls',
    'Plaistow',
    'Sandown',
    'Foxborough',
    'Mansfield',
    'Sharon',
    'Reading',
    'Falmouth',
    'Hampton',
    'Newfields',
    'Berwick',
    'Shelburne Falls',
    'Deerfield',
    'Brandon',
    'Killington',
    'Pawlet',
    'Castleton',
    'West Pawlet',
    'Wells',
    'Rutland',
    'Middleton',
    'Marblehead',
    'Topsfield',
    'Ware',
    'Warren',
    'Gilbertville',
    'Voluntown',
    'Westerly',
    'Ledyard Center',
    'Cohasset',
    'Fairhaven',
    'South Dartmouth',
    'Rockland',
    'Middleboro',
    'Westwood',
    'Bolton',
    'Newmarket',
    'Nottingham',
    'Wellesley Hills',
    'Unionville',
    'Rehoboth',
    'North Dighton',
    'Linthicum Heights',
    'Arnold',
    'Brooklyn',
    'Pasadena',
    'Lothian',
    'Mabelvale',
    'Alexander',
    'Ward',
    'Harrisonville',
    'Nevada',
    'Queen City',
    'West Point',
    'Ozark',
    'Andalusia',
    'Jack',
    'Elba',
    'Bryant',
    'Cherokee Village',
    'Hardy',
    'Hazlet',
    'Caldwell',
    'Smithtown',
    'Belmar',
    'Beacon',
    'Manhasset',
    'Englishtown',
    'Huntington Station',
    'Great Neck',
    'East Northport',
    'Florham Park',
    'Huntington',
    'Southold',
    'Carle Place',
    'Bohemia',
    'Oceanside',
    'Valhalla',
    'Peekskill',
    'Nyack',
    'Closter',
    'Watchung',
    'South Salem',
    'Lodi',
    'Oak Ridge',
    'Oradell',
    'Uniondale',
    'Rockville Centre',
    'Salem',
    'Fort Mitchell',
    'Warren',
    'Barrington',
    'Bristol',
    'Tarrytown',
    'Ossining',
    'Crompond',
    'Wayne',
    'Monsey',
    'Scarsdale',
    'Croton-on-Hudson',
    'Thornwood',
    'Howell',
    'Easton',
    'Farmingdale',
    'North Babylon',
    'Lindenhurst',
    'Kents Hill',
    'North Bridgton',
    'Augusta',
    'Yarmouth',
    'Westbrook',
    'Falmouth',
    'Fairfield',
    'South Sanford',
    'Skowhegan',
    'New Sharon',
    'South Casco',
    'Keene',
    'Dublin',
    'Corning',
    'Clay',
    'Norwood',
    'Cortland',
    'Rome',
    'Ilion',
    'West Henrietta',
    'Palmyra',
    'Albion',
    'Rhinebeck',
    'Slate Hill',
    'Stone Ridge',
    'Englewood Cliffs',
    'New Windsor',
    'Latham',
    'Vestal',
    'Endicott',
    'Macedon',
    'Oneida',
    'East Rochester',
    'Corfu',
    'Lee',
    'South Glens Falls',
    'Pittsfield',
    'Ballston Spa',
    'Johnsonville',
    'Waterford',
    'Oneonta',
    'Dundee',
    'Athens',
    'Elmira',
    'Hall',
    'Oswego',
    'Cicero',
    'Canton',
    'New Hartford',
    'Ruidoso',
    'Holloman Air Force Base',
    'Alamogordo',
    'Carrizozo',
    'Washington',
    'Jackson',
    'Youngsville',
    'Fuquay-Varina',
    'Holly Springs',
    'Knightdale',
    'Selma',
    'Bahama',
    'Hillsborough',
    'Louisburg',
    'Hope Mills',
    'Raeford',
    'Pinehurst',
    'Kings Mountain',
    'Mount Holly',
    'Indian Trail',
    'Bluffton',
    'Stedman',
    'Lakeview',
    'Ventress',
    'Jarreau',
    'Seabrook',
    'Charlestown',
    'Auburn',
    'Waban',
    'Eliot',
    'Carlisle',
    'Hansville',
    'Winthrop',
    'Wrentham',
    'Springfield Gardens',
    'College Point',
    'Bellerose',
    'Jackson Heights',
    'Elmhurst',
    'Fresh Meadows',
    'Lanett',
    'Panama City Beach',
    'Marshall',
    'Paramount',
    'Mission Hills',
    'Joshua Tree',
    "'Ewa Beach",
    'Pahoa',
    "'Aiea",
    'Kapaau',
    'Kapolei',
    'New Canaan',
    'New Milford',
    'Hawthorne',
    'Northport',
    'Selden',
    'Middlesex',
    'Mineola',
    'Babylon',
    'Tuckahoe',
    'Wharton',
    'Lake Hopatcong',
    'Succasunna',
    'Brentwood',
    'Dobbs Ferry',
    'Westport',
    'Litchfield',
    'Thomaston',
    'Cornwall Bridge',
    'Westwood',
    'Midland Park',
    'Waldwick',
    'Emerson',
    'Washington Township',
    'Glen Rock',
    'Saddle River',
    'East Setauket',
    'Farmingville',
    'Copiague',
    'Mamaroneck',
    'Eastchester',
    'Towaco',
    'Coram',
    'Hartsdale',
    'Jefferson Valley',
    'Rocky Point',
    'Sound Beach',
    'Ridge',
    'Sag Harbor',
    'Water Mill',
    'Southampton',
    'Sagaponack',
    'Bridgehampton',
    'Manorville',
    'Center Moriches',
    'Shirley',
    'East Moriches',
    'Eastport',
    'Yorktown Heights',
    'Bedford Hills',
    'Shrub Oak',
    'Mohegan Lake',
    'Bedford',
    'Spring Valley',
    'New City',
    'Larchmont',
    'Rye',
    'Amityville',
    'Lincolndale',
    'Putnam Valley',
    'North Salem',
    'Mahopac',
    'Cross River',
    'East Islip',
    'Islandia',
    'Haledon',
    'Franklin Lakes',
    'Pompton Lakes',
    'Ledgewood',
    'Hopatcong',
    'Monroe',
    'Harriman',
    'Wyandanch',
    'Montville',
    'Lake Hiawatha',
    'Marlboro',
    'Patchogue',
    'Lake Grove',
    'Roslyn',
    'Holtsville',
    'Oakdale',
    'Islip',
    'Hawthorne',
    'Holbrook',
    'West Islip',
    'Bellmore',
    'Hempstead',
    'Glenwood Landing',
    'Denville',
    'Mount Arlington',
    'Budd Lake',
    'West Milford',
    'Hewitt',
    'Westtown',
    'Johnson',
    'Lincoln Park',
    'Mattituck',
    'Freeport',
    'Island Park',
    'Long Beach',
    'Atlantic Beach',
    'Dumont',
    'Zellwood',
    'Eustis',
    'Pierson',
    'Astor',
    'Apopka',
    'Astatula',
    'Citra',
    'Schulenburg',
    'La Grange',
    'Springhill',
    'Leland',
    'Hardy',
    'Bedford',
    'Wirtz',
    'Gladehill',
    'Moneta',
    'Christiansburg',
    'Forest',
    'Rustburg',
    'Appomattox',
    'Rock View',
    'Bradshaw',
    'Welch',
    'Oceana',
    'Elbert',
    'Roderfield',
    'Pineville',
    'War',
    'Northfork',
    'Avondale',
    'Gary',
    'Edinburg',
    'Charlotte Court House',
    'Williamsport',
    'Clear Spring',
    'Big Pool',
    'Coudersport',
    'Palestine',
    'Friday Harbor',
    'Susanville',
    'Burney',
    'Wabasha',
    'Elgin',
    'Saint Joseph',
    'Rice',
    'Holdingford',
    'Saint Cloud',
    'Cold Spring',
    'Becker',
    'La Luz',
    'Estes Park',
    'Hurricane',
    'Pell City',
    'Lincoln',
    'Vincent',
    'Odenville',
    'Ivins',
    'LaVerkin',
    'Dover',
    'Highlands',
    'Ailey',
    'Vidalia',
    'Aliceville',
    'Oakhurst',
    'Mabank',
    'Trinidad',
    'Marble Falls',
    'McCloud',
    'Othello',
    'Chandler',
    "Coeur d'Alene",
    'Fairfield',
    'Dunsmuir',
    'Marshall',
    'Chaffee',
    'Canalou',
    'Advance',
    'Matthews',
    'Gillett',
    'Cecil',
    'Iron River',
    'Lomira',
    'Butternut',
    'Pelican Lake',
    'Crandon',
    'Fall Creek',
    'Oconto Falls',
    'Greenwood',
    'Franklin',
    'Red Cloud',
    'Central City',
    'Schuyler',
    'Columbus',
    'Paxton',
    'Albion',
    'Alma',
    'Burlington',
    'Colstrip',
    'Forsyth',
    'Pagosa Springs',
    'Bayfield',
    'Black Hawk',
    'Saguache',
    'Port Allegany',
    'Emporium',
    'Genesee',
    'Harrison Valley',
    'Roulette',
    'Saint Marys',
    'Johnsonburg',
    'Bryson City',
    'Robbinsville',
    'Wana',
    'Canton',
    'Haleyville',
    'Baileyton',
    'Rogersville',
    'Huntsburg',
    'Rock Creek',
    'Thompson',
    'Trout Run',
    'Jal',
    'Forest Lake',
    'Rockville',
    'Woodland Park',
    'Johnstown',
    'Rapid City',
    'Wyoming',
    'Holland',
    'Northwood',
    'Rossford',
    'Woodville',
    'Swanton',
    'Oak Harbor',
    'Beardstown',
    'Manito',
    'Rushville',
    'Bath',
    'Versailles',
    'Sherman',
    'Jerseyville',
    'Pleasant Plains',
    'Alexander',
    'Pittsfield',
    'Petersburg',
    'Bluffs',
    'Easton',
    'Chandlerville',
    'White Hall',
    'Carrollton',
    'Elsah',
    'Arenzville',
    'Sea Ranch',
    'Gualala',
    'Cazadero',
    'Evington',
    'Rural Retreat',
    'Lynch Station',
    'Moses Lake',
    'Milliken',
    'Severance',
    'Laporte',
    'Hazleton',
    'New Berlin',
    'Mohnton',
    'Birdsboro',
    'Andover',
    'Palmerton',
    'Litchfield Park',
    'Surprise',
    'New River',
    'Avondale',
    'Bowling Green',
    'Bluffton',
    'Minster',
    'Ottawa',
    'Fostoria',
    'Aurora',
    'Crewe',
    'Wytheville',
    'Concrete',
    'Port Ludlow',
    'Duvall',
    'Carnation',
    'Port Hadlock',
    'Coupeville',
    'Otis',
    'Baldwinsville',
    'West Monroe',
    'Constantia',
    'Watertown',
    'Camden',
    'Blossvale',
    'Lowville',
    'Weedsport',
    'Jordan',
    'Fulton',
    'Massena',
    'Herkimer',
    'Boonville',
    'Hamilton',
    'Little Falls',
    'Lake Placid',
    'Elbridge',
    'Homer',
    'Pitcher',
    'Chazy',
    'Champlain',
    'Ellenburg Center',
    'Rouses Point',
    'Ogdensburg',
    'Canastota',
    'Saranac Lake',
    'Sackets Harbor',
    'Sylvan Beach',
    'Malone',
    'Tupper Lake',
    'Glenfield',
    'Vernon',
    'Hebron',
    'Oak Bluffs',
    'Longmeadow',
    'Carver',
    'East Haven',
    'North Carver',
    'Hopkinton',
    'Berkley',
    'East Wareham',
    'Mattapoisett',
    'Hudson',
    'Milford',
    'Weston',
    'Babson Park',
    'Hyde Park',
    'Brimfield',
    'Goffstown',
    'Turners Falls',
    'Avon',
    'Newton',
    'Walpole',
    'Norfolk',
    'Canton',
    'Windham',
    'South Dennis',
    'Harwich',
    'Brewster',
    'Manchester Center',
    'Swampscott',
    'Lynnfield',
    'Chelsea',
    'Melrose',
    'Nahant',
    'Dracut',
    'North Hampton',
    'Orleans',
    'Clinton',
    'South Hamilton',
    'Chichester',
    'Lee',
    'South Berwick',
    'Wilmington',
    'Chelmsford',
    'North Chelmsford',
    'Jaffrey',
    'Jericho',
    'Blackstone',
    'Dover',
    'Ashland',
    'Millis',
    'Tolland',
    'Ellington',
    'Abington',
    'Lincoln',
    'Maynard',
    'Merrimack',
    'Contoocook',
    'Collinsville',
    'Marion',
    'Candia',
    'East Candia',
    'Acton',
    'Norton',
    'Somerset',
    'Pawtucket',
    'Loudon',
    'Atkinson',
    'Kingston',
    'Holden',
    'Methuen',
    'Templeton',
    'Saint Albans',
    'Sterling',
    'West Rutland',
    'Devens',
    'Athol',
    'Winchendon',
    'Newton',
    'Tyngsboro',
    'Southwick',
    'Holbrook',
    'Raynham',
    'Rockport',
    'Morrisville',
    'West Roxbury',
    'New London',
    'Harvard',
    'Newport',
    'Marlborough',
    'Bolton',
    'Amston',
    'Sour Lake',
    'Weimar',
    'Angleton',
    'Sarepta',
    'Laurel',
    'Hardin',
    'Stromsburg',
    'Genoa',
    'Fullerton',
    'Osceola',
    'Bellwood',
    'Palmetto',
    'Palm Harbor',
    'Tarpon Springs',
    'Lecanto',
    'Holiday',
    'Inverness',
    'Port Richey',
    'Wesley Chapel',
    'Winter Park',
    'Windermere',
    'Seminole',
    'Homosassa',
    'Parrish',
    "Land O' Lakes",
    'Cherryville',
    'Midland',
    'Norwood',
    'Albemarle',
    'Mount Gilead',
    'New Bern',
    'Niagara Falls',
    'Lockport',
    'Crosby',
    'Splendora',
    'Sagle',
    'Ball Ground',
    'Dalzell',
    'Manning',
    'Georgetown',
    'Red Springs',
    'Parkton',
    'Dunn',
    'Zebulon',
    'Boonsboro',
    'Knoxville',
    'Funkstown',
    'Colt',
    'Hallsville',
    'Tahlequah',
    'Stony Point',
    'Dunellen',
    'Morganville',
    'Chester',
    'Riverhead',
    'Massapequa Park',
    'Roosevelt',
    'Syosset',
    'Bergenfield',
    'Warren Township',
    'Suffern',
    'Valley Cottage',
    'Elmwood Park',
    'Ozone Park',
    'Howard Beach',
    'Rockaway Park',
    'Forest Hills',
    'Rego Park',
    'Far Rockaway',
    'Springfield',
    'Bennington',
    'Shaftsbury',
    'Rollinsford',
    'Lancaster',
    'Centerville',
    'Barnstable',
    'Erving',
    'West Yarmouth',
    'Hardwick',
    'Johnson',
    'Henniker',
    'Swanton',
    'Wellfleet',
    'Provincetown',
    'North Truro',
    'North Pembroke',
    'Montgomery Center',
    'Newport Center',
    'Richford',
    'Rutland',
    'Sun City Center',
    'Glens Falls',
    'Hadley',
    'Cobleskill',
    'Lake George',
    'Averill Park',
    'Hudson Falls',
    'New Albany',
    'Davenport',
    'Dade City',
    'Mount Vernon',
    'Marion',
    'Plymouth',
    'Cherryfield',
    'Rumney',
    'Alfred',
    'Kennebunkport',
    'Kennebunk',
    'Lorain',
    'Greenville',
    'Vermilion',
    'Waynesburg',
    'Mantua',
    'Shelby',
    'Jamestown',
    'Walton',
    'Norwich',
    'Dansville',
    'Bath',
    "Kapa'a",
    "Wai'anae",
    'Hilo',
    'Hudson',
    'Midland City',
    'Fort Rucker',
    'Bucyrus',
    'Cambridge',
    'London',
    'Safety Harbor',
    'Dunedin',
    'Grovetown',
    'Gurley',
    'Valley',
    'Watervliet',
    'Rexford',
    'Berlin',
    'Waterville',
    'Fly Creek',
    'Edmeston',
    'Aurora',
    'Chittenango',
    'Phoenix',
    'Woodstock',
    'Stillwater',
    'Wynantskill',
    'Fort Ann',
    'Middle Grove',
    'Fort Edward',
    'Lenox',
    'Brewer',
    'Littleton',
    'Cleveland',
    'Pine Bush',
    'Fairport',
    'Cassadaga',
    'Farmington',
    'Williamson',
    'Hornell',
    'Johnson City',
    'Rumford',
    'Gray',
    'Casco',
    'Moody',
    'Buxton',
    'Fairburn',
    'Lula',
    'Riverdale',
    'Union City',
    'Auburn',
    'Conley',
    'Clarkston',
    'Avondale Estates',
    'Emerson',
    'Rex',
    'Bethlehem',
    'Pine Lake',
    'Watkinsville',
    'Brownsville',
    'Vincennes',
    'Litchfield',
    'Sparta',
    'Mount Vernon',
    'Ripley',
    'Mayfield',
    'Calvert City',
    'Fulton',
    'Olney',
    'Grayville',
    'Norris City',
    'Fairfield',
    'Mount Carmel',
    'Brazil',
    'Farmersville',
    'Carlinville',
    'Owaneco',
    'Hillsboro',
    'Westville',
    'Willow Hill',
    'Pineville',
    'Kettle Island',
    'Nineveh',
    'McLeansboro',
    'Sesser',
    'Girard',
    'Pana',
    'Anna',
    'Greenville',
    'Piggott',
    'Virden',
    'Macon',
    'Bethany',
    'Malden',
    'Saint Francis',
    'Clarkton',
    'Carmi',
    'Dale',
    'Taylorville',
    'Mount Pleasant',
    'Ashland',
    'Wellington',
    'West Salem',
    'Sullivan',
    'Girard',
    'Hartstown',
    'Conneaut Lake',
    'Linesville',
    'Kenova',
    'Chicora',
    'Port Deposit',
    'Cochranville',
    'Canfield',
    'North Jackson',
    'Catlettsburg',
    'Greenup',
    'Oxford',
    'Salem',
    'North East',
    'South Point',
    'Karns City',
    'Conowingo',
    'Harmony',
    'Zelienople',
    'Evans City',
    'Stewartstown',
    'Macfarlan',
    'Harrisville',
    'Cranberry Township',
    'Valencia',
    'Ellwood City',
    'New Springfield',
    'McDonald',
    'Campbell',
    'Sarver',
    'Delta',
    'Nottingham',
    'New Brighton',
    'Slippery Rock',
    'West Sunbury',
    'Hamlin',
    'Mercer',
    'Dalton',
    'Marshallville',
    'Litchfield',
    'Valley City',
    'Street',
    'New Freedom',
    'Tionesta',
    'Guys Mills',
    'Scottdale',
    'Daisytown',
    'California',
    'Coal Center',
    'Perryopolis',
    'Saxonburg',
    'Dunbar',
    'Pullman',
    'Sunnyside',
    'Whitehall Township',
    'Phillipsburg',
    'Stewartsville',
    'Center Valley',
    'Lewisburg',
    'Milford',
    'Hawley',
    'Dingmans Ferry',
    'Aquashicola',
    'Jim Thorpe',
    'New Ringgold',
    'Albrightsville',
    'Blakeslee',
    'Kunkletown',
    'Nesquehoning',
    'Lansford',
    'Coaldale',
    'Manheim',
    'Ephrata',
    'Reinholds',
    'Denver',
    'Akron',
    'Temple',
    'New Tripoli',
    'Orefield',
    'Kempton',
    'Hellertown',
    'Macungie',
    'East Greenville',
    'Liberty',
    'Saugerties',
    'Goshen',
    'Rosedale',
    'Monticello',
    'Cuddebackville',
    'Gorham',
    'Spencerport',
    'Bergen',
    'Clinton',
    'Central Square',
    'Newcastle',
    'Buckfield',
    'Rockport',
    'Moultonborough',
    'Rushford',
    'Dallastown',
    'Riverton',
    'Hobart',
    'Elkins Park',
    'Randallstown',
    'Livingston',
    'Punta Gorda',
    'Dixon',
    'North Grafton',
    'Mapleton',
    'Angwin',
    'Hurleyville',
    'Red Hook',
    'Marlboro',
    'Swanzey',
    'Jefferson',
    'Kilkenny Township',
    'Seneca Falls',
    'Batavia',
    'Sherburne',
    'Coxsackie',
    'Catskill',
    'Hudson',
    'Leeds',
    'Ravena',
    'Greenville',
    'Stuyvesant',
    'Earlton',
    'Windham',
    'Westerlo',
    'East Durham',
    'Athens',
    'Climax',
    'Claverack',
    'Great Barrington',
    'Purling',
    'Cornwallville',
    'Preston Hollow',
    'Cairo',
    'Round Top',
    'Freehold',
    'Copake',
    'Kaukauna',
    'Maineville',
    'Tipp City',
    'Arcanum',
    'Troy',
    'Jeffersonville',
    'Marysville',
    'Tallmadge',
    'Mansfield',
    'Plain City',
    'Heath',
    'Wadsworth',
    'Fort Thomas',
    'Seville',
    'Carthage',
    'Richlands',
    'Jacksonville',
    'Reynoldsburg',
    'Cortland',
    'West Milton',
    'Glenford',
    'Saint Ann',
    'Maryville',
    'Bethalto',
    'Glencoe',
    'Eureka',
    'Allenton',
    'Grover',
    'Villa Ridge',
    'Gray Summit',
    'West Boylston',
    'Spencer',
    'Chicopee',
    'Cleveland',
    'Elizabethton',
    'Jasper',
    'Saint Cloud',
    'Orange City',
    'Indialantic',
    'Ocoee',
    'Winter Garden',
    'Winter Springs',
    'Manvel',
    'Williston',
    'Watertown',
    'Aberdeen',
    'Moorhead',
    'Casselton',
    'North Branch',
    'Baltic',
    'New Rockford',
    'Page',
    'Grafton',
    'Yankton',
    'Vermillion',
    'Devils Lake',
    'Spearfish',
    'Lead',
    'Sturgis',
    'Cooperstown',
    'Mobridge',
    'Mitchell',
    'Minot',
    'Belfield',
    'Wahpeton',
    'Fairmount',
    'Breckenridge',
    'Colbert',
    'Cartwright',
    'Coalgate',
    'Tupelo',
    'Bokchito',
    'Achille',
    'Kingston',
    'Tishomingo',
    'Ravia',
    'Mead',
    'Lake Arthur',
    'Kinder',
    'Westlake',
    'Oberlin',
    'Elton',
    'Gueydan',
    'Welsh',
    'DeQuincy',
    'Tazewell',
    'Harrogate',
    'Cumberland Gap',
    'New Tazewell',
    'Airville',
    'Jarrettsville',
    'Forest Hill',
    'Fawn Grove',
    'Fallston',
    'Darlington',
    'Pylesville',
    'Proctorville',
    'Chesapeake',
    'Crown City',
    'Whiteford',
    'Felton',
    'West Hamlin',
    'Milton',
    'Pennsboro',
    'West Union',
    'Harts',
    'Big Creek',
    'Ranger',
    'Salt Rock',
    'Jackson Center',
    'Portersville',
    'New Castle',
    'Stanwood',
    'La Conner',
    'Port Angeles',
    'Port Orchard',
    'Seabeck',
    'Allyn',
    'Belfair',
    'Bow',
    'Sandy',
    'Brightwood',
    'Keyport',
    'Sequim',
    'Port Townsend',
    'Blandon',
    'Fleetwood',
    'Kutztown',
    'Coplay',
    'New Columbia',
    'Milton',
    'Watsontown',
    'Morgantown',
    'Honey Brook',
    'Elverson',
    'Sparta',
    'Newton',
    'McAdoo',
    'Tresckow',
    'Lafayette',
    'Walnutport',
    'Slatington',
    'Alburtis',
    'Sugarloaf Township',
    'Stockholm',
    'Sussex',
    'Hamburg',
    'Stanhope',
    'Mertztown',
    'Hamburg',
    'Duncannon',
    'Branchville',
    'Augusta',
    'Tobyhanna',
    'Gouldsboro',
    'Greentown',
    'Newfoundland',
    'South Sterling',
    'Canadensis',
    'Coopersburg',
    'Troy',
    'Stroudsburg',
    'Saylorsburg',
    'East Stroudsburg',
    'Effort',
    'Tannersville',
    'Wilkes Barre',
    'Breinigsville',
    'Ottsville',
    'Zionsville',
    'Holcomb',
    'Lewiston',
    'Aransas Pass',
    'Elk City',
    'McCall',
    'Show Low',
    'Bisbee',
    'Texarkana',
    'Filer',
    'Rupert',
    'Heyburn',
    'Twin Falls',
    'Texarkana',
    'Ingleside',
    'Clarkston',
    'Eagle',
    'Cottonwood',
    'Winslow',
    'Gooding',
    'Altus',
    'Page',
    'Jacksonville',
    'Borger',
    'Brookhaven',
    'Globe',
    'Blackfoot',
    'Pascagoula',
    'Chanute',
    'American Falls',
    'Safford',
    'Burley',
    'Buhl',
    'Rigby',
    'Taylor',
    'McNary',
    'Holbrook',
    'Garden City',
    'Pinetop-Lakeside',
    'Dewey',
    'Vaughan',
    'Star',
    'Hugo',
    'South Saint Paul',
    'Hudson',
    'Hastings',
    'Burnsville',
    'Middleton',
    'Alexandria',
    'Weaver',
    'Boulder City',
    'Lake Havasu City',
    'Fountain Hills',
    'Parker',
    'Payson',
    'Peculiar',
    'Garden City',
    'Pine',
    'Wilmington',
    'Cleves',
    'Galveston',
    'Newton Falls',
    'Bristolville',
    'Lake City',
    'Pataskala',
    'Mechanicsburg',
    'Sheboygan',
    'Suamico',
    'Englewood',
    'Galion',
    'Overpeck',
    'Batavia',
    'China Grove',
    'New Carlisle',
    'Gibsonburg',
    'New Lebanon',
    'Rittman',
    'Basehor',
    'Linwood',
    'Eudora',
    'Dinwiddie',
    'Warrenton',
    'Hanover',
    'Powhatan',
    'Draper',
    'Bealeton',
    'Catlett',
    'Bluefield',
    'Broadway',
    'Stephens City',
    'Middleburg',
    'Bassett',
    'Hamilton',
    'Round Hill',
    'Maidens',
    'Sandy Hook',
    'Madison Heights',
    'Stuarts Draft',
    'Aldie',
    'Poca',
    'Winfield',
    'Front Royal',
    'Sperryville',
    'Grottoes',
    'Madison',
    'Collinsville',
    'Falling Waters',
    'Hedgesville',
    'Kearneysville',
    'Bunker Hill',
    'Montgomery Village',
    'Shenandoah Junction',
    'Ranson',
    'Charles Town',
    'Harpers Ferry',
    'Brunswick',
    'New Market',
    'Lusby',
    'Berkeley Springs',
    'Windsor Mill',
    'District Heights',
    'Ft. Washington',
    'Maysville',
    'Jeffersonton',
    'Amissville',
    'Daleville',
    'Cloverdale',
    'Troutville',
    'Sutherland',
    'Cross Junction',
    'Berryville',
    'Media',
    'Crum Lynne',
    'Kennett Square',
    'Avondale',
    'Sharon Hill',
    'Prospect Park',
    'Norwood',
    'Clifton Heights',
    'Wallingford',
    'Holmes',
    'Lafayette',
    'Hallandale',
    'Satsuma',
    'Hanover',
    'Harwich Port',
    'Dennis',
    'Dennis Port',
    'West Newbury',
    'Three Rivers',
    'Bondsville',
    'Osterville',
    'Florence',
    'Canterbury',
    'Indian Orchard',
    'Hampton Falls',
    'Winchester',
    'Plymouth',
    'Canaan',
    'Quechee',
    'Waterbury Center',
    'East Montpelier',
    'Waterbury',
    'Worcester',
    'Hinesburg',
    'Ferrisburgh',
    'Live Oak',
    'City of Saint Marys',
    'Ponte Vedra',
    'Macclenny',
    'Glen Saint Mary',
    'Pinellas Park',
    'Carmel Valley',
    'Camp Meeker',
    'Occidental',
    'Dayton',
    'Disputanta',
    'Ridgeway',
    'Harrison City',
    'Mount Jewett',
    'Grafton',
    'Petersburg',
    'New Waterford',
    'Murrysville',
    'NuMine',
    'Kittanning',
    'Rural Valley',
    'Turtle Creek',
    'Blairsville',
    'Ebensburg',
    'Elmora',
    'Tyrone',
    'Flushing',
    'Adena',
    'Rayland',
    'Jennerstown',
    'Glen Easton',
    'Clinton',
    'Bayport',
    'Lakeland',
    'Shakopee',
    'Zimmerman',
    'Newport',
    'Darlington',
    'McDonald',
    'Crescent Hills',
    'Trafford',
    'Hawaiian Gardens',
    'Littlerock',
    'Moonachie',
    'Richfield',
    'Stoneville',
    'Eden',
    'Mount Airy',
    'East Bend',
    'Wallburg',
    'Reidsville',
    'Supply',
    'Hubert',
    'Carrboro',
    'West End',
    'Linden',
    'Willow Springs',
    'Saint Robert',
    'Fort Leonard Wood',
    'Waynesville',
    'Clever',
    'Willow Springs',
    'Wooster',
    'Apple Creek',
    'Creston',
    'Brewster',
    'Navarre',
    'Shreve',
    'N. Lawrence',
    'Mineral City',
    'Smithville',
    'Mount Eaton',
    'Montpelier',
    'Millbury',
    'Athens',
    'White Plains',
    'Liberty',
    'Lockbourne',
    'Asheboro',
    'Rural Hall',
    'Clemmons',
    'Torrington',
    'Wheatland',
    'Decatur',
    'Jacksboro',
    'Bridgeport',
    'Graford',
    'Alvord',
    'Bryson',
    'Chelan',
    'Lakebay',
    'Stevenson',
    'Carson',
    'Randle',
    'Morton',
    'Longbranch',
    'South Beach',
    'Everson',
    'Cle Elum',
    'Hamilton',
    'Depoe Bay',
    'Arverne',
    'Springfield',
    'Enola',
    'Center Ridge',
    'Beverly Hills',
    'Harrisville',
    'Clintonville',
    'New Wilmington',
    'Shiloh',
    'Harmonsburg',
    'Wattsburg',
    'Saegertown',
    'Prospect',
    'Petrolia',
    'Sterling',
    'Cumberland',
    'East Freedom',
    'Hollsopple',
    'Boswell',
    'Vanderbilt',
    'Uniontown',
    'Brownsville',
    'Masontown',
    'Carmichaels',
    'Fayette City',
    'Nemacolin',
    'Fredericktown',
    'Marianna',
    'Crucible',
    'Clarksville',
    'Ellsworth',
    'Creighton',
    'Warrenville',
    'Bradford',
    'Lewis Run',
    'Chester',
    'Grasonville',
    'Sudlersville',
    'Chestertown',
    'Salamanca',
    'Delaware',
    'Orient',
    'Powell',
    'Plymouth',
    'Delphos',
    'Monroe',
    'Granville',
    'Paulding',
    'Baldwin Place',
    'Pound Ridge',
    'Westminster',
    'Walpole',
    'Hyde Park',
    'North Stonington',
    'Hinsdale',
    'Salisbury',
    'Dorset',
    'Rye',
    'Ghent',
    'Germantown',
    'Chatham',
    'Mellenville',
    'Hannacroix',
    'Bearsville',
    'Coeymans Hollow',
    'Pfafftown',
    'Pinnacle',
    'Whitsett',
    'Tobaccoville',
    'Dobson',
    'Trinity',
    'Jonesville',
    'Thomasville',
    'Hopkins',
    'Bessemer City',
    'Smithfield',
    'Summerfield',
    'Advance',
    'Gibsonville',
    'Cedar Falls',
    'Brockport',
    'Scottsville',
    'Conesus',
    'Ballston Lake',
    'Porter Corners',
    'Johnstown',
    'Amsterdam',
    'Boones Mill',
    'Penhook',
    'Lebanon',
    'Castlewood',
    'Honaker',
    'Dante',
    'Blackstone',
    'Lewisburg',
    'Wyoming',
    'Ravencliff',
    'Hensley',
    'Elkhorn',
    'Glen Fork',
    'Bud',
    'Clear Fork',
    'Pageton',
    'Saulsville',
    'New Hartford',
    'Bethlehem',
    'Auburn',
    'North Brookfield',
    'Sandy Hook',
    'Southbury',
    'New Fairfield',
    'Brookfield',
    'Coventry',
    'Washington',
    'New Preston',
    'Barkhamsted',
    'Harwinton',
    'Kent',
    'Sherman',
    'Uxbridge',
    'Sturbridge',
    'Southbridge',
    'Dudley',
    'Ludlow',
    'Belchertown',
    'East Longmeadow',
    'Gatlinburg',
    'Madisonville',
    'Piney Flats',
    'Kingsport',
    'Blountville',
    'Loudon',
    'Louisville',
    'Tunnel Hill',
    'Resaca',
    'Joelton',
    'Adams',
    'Humboldt',
    'Mount Pleasant',
    'Milan',
    'Jefferson City',
    'Thousand Palms',
    'Brownstown',
    'Stevens',
    'Cresco',
    'Douglassville',
    'Mountain Top',
    'Tunkhannock',
    'Noxen',
    'Le Raysville',
    'Saint Peters',
    'Colonial Beach',
    'King George',
    'Bloomsburg',
    'Montross',
    'California',
    'Hollywood',
    'Leonardtown',
    'Diggs',
    'Heathsville',
    'Weems',
    'Lancaster',
    'Dutton',
    'Bohannon',
    'Hartfield',
    'Topping',
    'Wake',
    'Smithsburg',
    'Keedysville',
    'Canyon Lake',
    'Gonzales',
    'Del Rio',
    'Bertram',
    'Hadley',
    'Acme',
    'Bruin',
    'Emlenton',
    'Fenelton',
    'Colora',
    'Alverton',
    'Jamestown',
    'Kinsman',
    'Sandy Lake',
    'Stoneboro',
    'Roscoe',
    'Cabot',
    'Cooperstown',
    'Cochranton',
    'Franklin',
    'Finleyville',
    'Spencer',
    'Lowellville',
    'Lorena',
    'Hewitt',
    'Argyle',
    'Duncanville',
    'Gardendale',
    'June Lake',
    'Needles',
    'Hicksville',
    'Irvington',
    'Allendale',
    'Purchase',
    'Lawrence',
    'Richland Center',
    'Roscoe',
    'Sun Prairie',
    'Fort Atkinson',
    'Ripon',
    'Horicon',
    'Sturgeon Bay',
    'Fox Lake',
    'Duluth',
    'Superior',
    'Melrose',
    'Connelly Springs',
    'Valdese',
    'Drexel',
    'Belton',
    'Piedmont',
    'Hampstead',
    'Roan Mountain',
    'Woodruff',
    'Inman',
    'Boiling Springs',
    'Chesnee',
    'Dayton',
    'Evensville',
    'Trezevant',
    'Huntingdon',
    'Bluff City',
    'Lenoir City',
    'Bristol',
    'McMinnville',
    'Paris',
    'Chatsworth',
    'Summerville',
    'Trion',
    'Newbern',
    'Folsom',
    'Ponchatoula',
    'Tickfaw',
    'Springfield',
    'Russellville',
    'Sparta',
    'Monroe',
    'Altamont',
    'Selmer',
    'Bells',
    'Trenton',
    'Ider',
    'Whitwell',
    'Bell Buckle',
    'Franklin',
    'Oakwood',
    'Kill Devil Hills',
    'Walland',
    'Stewartville',
    'Northfield',
    'Pine Island',
    'Albert Lea',
    'Lakeville',
    'Faribault',
    'Mondovi',
    'Fairfax',
    'Mankato',
    'Byron',
    'Brownsdale',
    'Beloit',
    'Palmyra',
    'Genoa City',
    'Fontana',
    'Darien',
    'Waterloo',
    'Clinton',
    'New Holstein',
    'Stoughton',
    'Lodi',
    'Baraboo',
    'Deerfield',
    'Oregon',
    'Rhinelander',
    'Lake Mills',
    'Evansville',
    'Edgerton',
    'Elkhorn',
    'Delavan',
    'Bulverde',
    'McConnelsville',
    'Coolville',
    'Jackson',
    'Wellston',
    'West Point',
    'Port Jervis',
    'Cottekill',
    'Warrenton',
    'Pikeville',
    'Brookville',
    'Seymour',
    'Kailua',
    'Kahului',
    'Lebanon',
    'Chesterland',
    'Ashtabula',
    'Millersburg',
    'Loudonville',
    'Bellville',
    'Girard',
    'Fairview',
    'Portsmouth',
    'San Benito',
    'La Blanca',
    'Denton',
    'Kansasville',
    'Pleasant Valley',
    'North Myrtle Beach',
    'Little River',
    'Pawleys Island',
    'Redford',
    'Bushnell',
    'Belleview',
    'Ocklawaha',
    'Fairplay',
    'Sabillasville',
    'Sharpsburg',
    'Columbia',
    'Millstadt',
    'Dupo',
    'Mt. Vernon',
    'Centralia',
    'Nashville',
    'Addieville',
    'Salem',
    'Warrenton',
    'Troy',
    'Moscow Mills',
    'Wright City',
    'Foristell',
    'Winfield',
    'Morehead City',
    'Rowland',
    'Wrightsville Beach',
    'Sidney',
    'Bethel',
    'Bellevue',
    'Beach City',
    'North Ridgeville',
    'Aynor',
    'Chapin',
    'Groves',
    'Floresville',
    'Hidalgo',
    'Scottville',
    'Benzonia',
    'Sherwood',
    'Claremont',
    'Taylorsville',
    'Joanna',
    'Teachey',
    'Weaverville',
    'Marshall',
    'Wilkesboro',
    'Hildebran',
    'Beech Bluff',
    'Medon',
    'Crump',
    'Parsons',
    'Morris Chapel',
    'Counce',
    'Hays',
    'North Wilkesboro',
    'Canton',
    'Maggie Valley',
    'West Chazy',
    'Keeseville',
    'Cohutta',
    'Rocky Face',
    'Nipomo',
    'Oceano',
    'Grover Beach',
    'Red Bluff',
    'Pismo Beach',
    'Morro Bay',
    'Hilmar',
    'Fernley',
    'Minden',
    'Windham',
    'Chaplin',
    'Mansfield Depot',
    'Easthampton',
    'Eastford',
    'Storrs',
    'Canterbury',
    'Ashford',
    'Fairview',
    'Estill Springs',
    'Leicester',
    'Oxford',
    'Brookfield',
    'Sutton',
    'Millers Creek',
    'Startex',
    'Cowpens',
    'Moore',
    'Peru',
    'Middleton',
    'Sauk City',
    'Wisconsin Rapids',
    'Portage',
    'Randolph',
    'Birchwood',
    'Southampton',
    'Ishpeming',
    'Gwinn',
    'Negaunee',
    'Champion',
    'Palmer',
    'Skandia',
    'Mount Olive',
    'Lineville',
    'Chelsea',
    'Gardendale',
    'Reedsburg',
    'Verona',
    'Mount Horeb',
    'La Crosse',
    'Saint Croix Falls',
    'Osceola',
    'Barnardsville',
    'Columbia',
    'Hampton',
    'Twin Lakes',
    'Walworth',
    'McFarland',
    'St. Genevieve',
    'Wheeler',
    'Manteo',
    'Roebuck',
    'Rockmart',
    'Joppa',
    'Pinson',
    'Guntersville',
    'Moulton',
    'Thomaston',
    'Monroe',
    'Tarawa Terrace I',
    'Williamston',
    'Pelzer',
    'Candler',
    'Leicester',
    'Locust Fork',
    'Roxana',
    'Saint Jacob',
    'Elkmont',
    'Arley',
    'Coker',
    'Pickens',
    'Cordova',
    'Jacksons Gap',
    'Carriere',
    'Alexandria',
    'Morrisonville',
    'Hadley',
    'Farmington',
    'Tracy',
    'Sartell',
    'Little Falls',
    'Waite Park',
    'Long Prairie',
    'Fergus Falls',
    'Buffalo',
    'Wadena',
    'Willmar',
    'Delano',
    'Adelanto',
    'Dayton',
    'Victorville',
    'Grayling',
    'Mount Pleasant',
    'Linden',
    'Davison',
    'Essexville',
    'Otisville',
    'Oscoda',
    'Frankenmuth',
    'Birch Run',
    'Newport',
    'La Salle',
    'New Boston',
    'Kalkaska',
    'Sault Ste. Marie',
    'Greenville',
    'Grandville',
    'Rogers City',
    'Alpena',
    'Chesaning',
    'Owosso',
    'Gowen',
    'Stanton',
    'Shelbyville',
    'Caledonia',
    'Harrison',
    'Kingston',
    'Cheboygan',
    'Vassar',
    'Lapeer',
    'Elk Rapids',
    'Saint Ignace',
    'Pierson',
    'Reed City',
    'Saint Louis',
    'Millington',
    'Auburn',
    'Ithaca',
    'Merrill',
    'Gladwin',
    'Rose City',
    'Ortonville',
    'Durand',
    'Imlay City',
    'Shepherd',
    'East Dublin',
    'Fort Benning',
    'Sherrills Ford',
    'Buffalo',
    'Moody',
    'Long Beach',
    'Ocean Park',
    'Sauk Rapids',
    'Pennock',
    'Cokato',
    'Big Lake',
    'Jefferson',
    'Menominee',
    'Altoona',
    'Cambridge',
    'Clyde',
    'Granite Falls',
    'Marion',
    'Charlton',
    'Pepperell',
    'Hollis',
    'Groton',
    'Brookline',
    'North Haverhill',
    'Barre',
    'Lyndonville',
    'Lyme',
    'Phelan',
    'Lucerne Valley',
    'Tahoe Valley',
    'Roaring River',
    'Moravian Falls',
    'Elkin',
    'Ronda',
    'Mountain City',
    'Wentzville',
    'Joshua',
    'East Prairie',
    'Sheboygan Falls',
    'Columbus',
    'Two Rivers',
    'Algoma',
    'Rice Lake',
    'Dodgeville',
    'Mineral Point',
    'Ashland',
    'Lone Rock',
    'Black Earth',
    'Prairie du Sac',
    'Minocqua',
    'Wausau',
    'Schofield',
    'Hubertus',
    'Hartford',
    'Hubbardston',
    'Paxton',
    'Jefferson',
    'Cadyville',
    'Elizabethtown',
    'Fiskdale',
    'Douglas',
    'Saltillo',
    'Adamsville',
    'Saint James',
    'Nesconset',
    'Moriches',
    'Westhampton Beach',
    'Hampton Bays',
    'Oakland',
    'Ringwood',
    'Pompton Plains',
    'Warwick',
    'Florida',
    'Glen Head',
    'Greenlawn',
    'Far Hills',
    'Morris Plains',
    'Riverside',
    'Massapequa',
    'Locust Valley',
    'Highland Mills',
    'West Haverstraw',
    'Garnerville',
    'Oyster Bay',
    'South Hackensack',
    'Malverne',
    'Dover Plains',
    'Verbank',
    'Millbrook',
    'Old Bridge',
    'Miller Place',
    'Wantagh',
    'Cedar Knolls',
    'Mastic',
    'Mastic Beach',
    'Oakville',
    'Goshen',
    'Baldwin',
    'Valley Stream',
    'Williston Park',
    'Kenvil',
    'Whitehouse',
    'Netcong',
    'Lynbrook',
    'Fair Lawn',
    'Sayreville',
    'Cliffwood',
    'Keansburg',
    'Keyport',
    'East Hampton',
    'Montauk',
    'Mine Hill',
    'Milltown',
    'Park Ridge',
    'Ramsey',
    'Centerport',
    'Maspeth',
    'Cresskill',
    'Roslyn Heights',
    'West Hempstead',
    'Woodbury',
    'Seaford',
    'East Rockaway',
    'Hillsdale',
    'West Nyack',
    'Liberty Corner',
    'Manasquan',
    'South Jamesport',
    'Landing',
    'Shoreham',
    'Islip Terrace',
    'Sayville',
    'Brick Township',
    'Port Monmouth',
    'Bethpage',
    'Roselle',
    'Calverton',
    'Franklin Square',
    'Amagansett',
    'Cedarhurst',
    'Wappingers Falls',
    'Stanfordville',
    'Clinton Corners',
    'Congers',
    'West Sayville',
    'Wingdale',
    'Middle Island',
    'Mount Sinai',
    'Lagrangeville',
    'Martinsville',
    'Bellport',
    'Brookhaven',
    'Pelham',
    'Cedar Grove',
    'Oakhurst',
    'Amenia',
    'Millerton',
    'Sloatsburg',
    'Cream Ridge',
    'Stormville',
    'Canton',
    'Trenton',
    'Wayne',
    'Harrison',
    'Little Neck',
    'Fairview',
    'Little Ferry',
    'Ridgefield',
    'West Shokan',
    'Kew Gardens',
    'Alabaster',
    'Vinemont',
    'Glenwood',
    'Paynesville',
    'Loves Park',
    'Brodhead',
    'Milton',
    'Ridgeway',
    'Cadott',
    'Williams Bay',
    'Mazomanie',
    'Princeton',
    'Saint Cloud',
    'Berlin',
    'Maiden',
    'Terrell',
    'Hiddenite',
    'Glendale',
    'Mauldin',
    'Morgan City',
    'Gibson',
    'West McLean',
    'Palmerdale',
    'Fayette',
    'Lexington',
    'Fultondale',
    'Hanceville',
    'Dadeville',
    'Altoona',
    'Scottsboro',
    'Pleasant Grove',
    'Blountsville',
    'Carbon Hill',
    'Parrish',
    'Marietta',
    'Bogart',
    'Dayton',
    'Rothschild',
    'King',
    'Holmen',
    'Marshfield',
    'Arcadia',
    'Indian Wells',
    'Elmhurst',
    'Floral Park',
    'Newport',
    'Beaufort',
    'Naples',
    'Diana',
    'San Gabriel',
    'Stateline',
    'Running Springs',
    'Big Bear Lake',
    'Kings Beach',
    'Sun Valley',
    'Copperas Cove',
    'Bryan',
    'Hughes',
    'Paige',
    'Widener',
    'Oreana',
    'Mamou',
    'Hillsboro',
    'Hematite',
    'Osage Beach',
    'Madison',
    'Little Chute',
    'Oconto',
    'Galena',
    'Kenton',
    'Ada',
    'North Lewisburg',
    'Sharon',
    'Lakeview',
    'Muskego',
    'Blanchester',
    'Saint Paris',
    'Bloomingburg',
    'Sabina',
    'Union City',
    'Amelia',
    'West Alexandria',
    'Louisville',
    'Centenary',
    'Murrells Inlet',
    'Pamplico',
    'Baraga',
    'Manistique',
    'Escanaba',
    'Gladstone',
    'Houghton',
    'Kingsford',
    'Rapid River',
    'Waunakee',
    'Belmont',
    'Waterford',
    'Amory',
    'Millport',
    'Malakoff',
    'Cashiers',
    'Wyoming',
    'Newark',
    'Auburn',
    'Fort Drum',
    'Keuka Park',
    'Canandaigua',
    'Silver Lake',
    'Geneseo',
    'Heuvelton',
    'Brushton',
    'Moravia',
    'North Chili',
    'Romulus',
    'Beans Purchase',
    'Berne',
    'Sidney',
    'Waverly Hall',
    'Irene',
    'Kitty Hawk',
    'Clermont',
    'Saint Albans',
    'Cowiche',
    'Walla Walla',
    'West Richland',
    'Florence',
    'Klamath Falls',
    'Ashland',
    'White Salmon',
    'Roseburg',
    'Sullivan',
    'Pevely',
    'Ashland',
    'Robertsville',
    'Saint James',
    'Norwell',
    'North Eastham',
    'Hartland',
    'Marshfield',
    'Lakeville',
    'Jewett City',
    'East Providence',
    'Stow',
    'Mont Vernon',
    'Killingworth',
    'Haddam',
    'North Easton',
    'Cavour',
    'Milbank',
    'East Grand Forks',
    'Beulah',
    'Langdon',
    'Tioga',
    'Rugby',
    'Saint John',
    'Pierre',
    'Harrisburg',
    'Ely',
    'Jonestown',
    'Gering',
    'Oshkosh',
    'Scottsbluff',
    'Hebbronville',
    'Worden',
    'Story',
    'Sundance',
    'Swainsboro',
    'Toccoa',
    'Gordo',
    'Reform',
    'Cozad',
    'Thermal',
    'Calipatria',
    'Julian',
    'Borrego Springs',
    'Bountiful',
    'North Platte',
    'Shepherd',
    'Pittsboro',
    'Wauseon',
    'Yellow Springs',
    'Rockford',
    'Salem',
    'Denmark',
    'Menominee',
    'Hortonville',
    'Falls City',
    'Ulysses',
    'Sharon Springs',
    'Leoti',
    'Satanta',
    'Sublette',
    'Deerfield',
    'Lakin',
    'Holcomb',
    'Hugoton',
    'Johnson',
    'Syracuse',
    'Cornelius',
    'Kirwin',
    'Liebenthal',
    'La Crosse',
    'Hoxie',
    'Osborne',
    'Plainville',
    'Roseau',
    'Thief River Falls',
    'DeFuniak Springs',
    'Greenville',
    'Port Neches',
    'Norwood',
    'Demarest',
    'Woodcliff Lake',
    'Bloomingdale',
    'Pequannock',
    'Frenchtown',
    'Elysburg',
    'Columbia',
    'Glenmoore',
    'Sunbury',
    'Kintnersville',
    'Wellsville',
    'Barnesville',
    'Mount Carmel',
    "Coryell's Ferry",
    'Adamstown',
    'Mount Pocono',
    'Vernon',
    'Bath',
    'Wind Gap',
    'Catasauqua',
    'Upper Black Eddy',
    'Erwinna',
    'Springtown',
    'Milford',
    'Bloomsbury',
    'Bangor',
    'Mount Bethel',
    'Marshalls Creek',
    'Monroeton',
    'Schnecksville',
    'Laceyville',
    'Wyalusing',
    'York Haven',
    'Dillsburg',
    'Valley Green',
    'Lewisberry',
    'Dover',
    'Palm',
    'Trexlertown',
    'Huron',
    'Hazen',
    'Cambridge',
    'Fort Pierre',
    'Volga',
    'Ripley',
    'Sorrento',
    'Altoona',
    'Quinnesec',
    'Stanwood',
    'Sand Lake',
    'Whitehall',
    'Grand Haven',
    'Sanford',
    'Freeland',
    'Columbiaville',
    'Au Gres',
    'Fowler',
    'Cass City',
    'Gaines',
    'Coleman',
    'Goodrich',
    'Prudenville',
    'Plainwell',
    'Otsego',
    'Dorr',
    'Harbor Springs',
    'Manton',
    'Bellaire',
    'Montague',
    'Martin',
    'Shelby',
    'Allegan',
    'Allendale',
    'Orange Grove',
    'Rochester',
    'Strafford',
    'Belmont',
    'Alton',
    'Deerfield',
    'Tilton',
    'Sanbornton',
    'Wolfeboro',
    'Bristol',
    'New Hampton',
    'Northwood',
    'Gilmanton',
    'Epsom',
    'Laconia',
    'Center Harbor',
    'Springvale',
    'Meredith',
    'Wolfeboro Falls',
    'Shapleigh',
    'Gilford',
    'Alton Bay',
    'Farmington',
    'McColl',
    'Louisville',
    'Carthage',
    'Morton',
    'West',
    'Forest',
    'Ludlow',
    'Pittsboro',
    'Booneville',
    'Burnt Hills',
    'Verona',
    'Ontario',
    'Holley',
    'Pine City',
    'Stacy',
    'Lindstrom',
    'Corunna',
    'Caro',
    'Coldwater',
    'Hamilton',
    'Bridgeport',
    'Flat Rock',
    'Erie',
    'Dryden',
    'Vernon',
    'Ossineke',
    'Orleans',
    'Beulah',
    'East Tawas',
    'Standish',
    'Bethel',
    'Bell Island Hot Springs',
    'Eagle River',
    'Barrow',
    'Angoon',
    'Fort Greely',
    'Seward',
    'Togiak',
    'Stebbins',
    'Homer',
    'North Pole',
    'Ambler',
    'Fairbanks North Star Borough',
    'Dillingham',
    'Deltana',
    'Kodiak',
    'Koyuk',
    'Soldotna',
    'Kenai',
    'Blairstown',
    'Franklin',
    'Sciota',
    'Farmersville',
    'Sunnyvale',
    'Blue Ridge',
    'McCaysville',
    'Ellijay',
    'Copperhill',
    'Mineral Bluff',
    'Renfrew',
    'Allenport',
    'Mineral Ridge',
    'Wampum',
    'West Pittsburg',
    'Koppel',
    'Tarrs',
    'Richmond',
    'Shawano',
    'Marathon',
    'Cabot',
    'Williamstown',
    'Blowing Rock',
    'New Baden',
    'Chincoteague Island',
    'Cape Charles',
    'Crisfield',
    'Angier',
    'Siler City',
    'Mascoutah',
    'Defiance',
    'Hudson',
    'Enoree',
    'Spencer',
    'Ogema',
    'Neillsville',
    'Pine Mountain',
    'Holly Pond',
    'Kimberly',
    'Piedmont',
    'Brookwood',
    'Marshall',
    'Bridge City',
    'Nederland',
    'Camden',
    'Circle Pines',
    'Montrose',
    'Hanover',
    'Riverton',
    'Athens',
    'White Haven',
    'Hunlock Creek',
    'Nanticoke',
    'Shickshinny',
    'Shavertown',
    'Orangeville',
    'Hardyville',
    'Dunnsville',
    'Guntown',
    'Haynesville',
    'Maysville',
    'Augusta',
    'Dover',
    'Brooksville',
    'Germantown',
    'Carlstadt',
    'Chatham',
    'Cullowhee',
    'East Flat Rock',
    'Franklin',
    'Sylva',
    'Jefferson',
    'Flat Rock',
    'Diller',
    'Dorchester',
    'Friend',
    'Hebron',
    'Fairbury',
    'Endicott',
    'Wilber',
    'Bruning',
    'Utica',
    'Blue Springs',
    'Hickman',
    'Martell',
    'Sterling',
    'Adams',
    'Wymore',
    'Filley',
    'Plymouth',
    'Odell',
    'Glenwood',
    'Sweet Valley',
    'West Olive',
    'Saint Johns',
    'Carleton',
    'Rapid City',
    'Grawn',
    'Williamsburg',
    'Fruitport',
    'Oshtemo',
    'Barbeau',
    'Charlevoix',
    'Bear Lake',
    'Kewadin',
    'Reading',
    'Colon',
    'Grosse Ile',
    'Melvin',
    'Pinconning',
    'Sheridan',
    'Ida',
    'Tekonsha',
    'Luling',
    'North Smithfield',
    'North Kingstown',
    'East Greenwich',
    'San Clemente',
    'Asbury',
    'Spivey',
    'Derby',
    'Kingman',
    'Sedgwick',
    'Hiwasse',
    'Eureka Springs',
    'Harrison',
    'North Providence',
    'Greene',
    'Burrton',
    'Frontenac',
    'Valley Center',
    'South Hutchinson',
    'Coffeyville',
    'Garden City',
    'Crestview',
    'Concordia',
    'Pratt',
    'Augusta',
    'Moran',
    'Hahnville',
    'Benson',
    'Coventry',
    'Chickasha',
    'Eglin',
    'Chepachet',
    'Harrisville',
    'North Scituate',
    'Foster',
    'Rumford',
    'Central Falls',
    'Saunderstown',
    'Pride',
    'Anthony',
    'Buckeye',
    'Goodyear',
    'Casa Grande',
    'Sahuarita',
    'Cashion',
    'Wickenburg',
    'El Mirage',
    'Sun City',
    'Bennington',
    'Elkhorn',
    'Council Bluffs',
    'Bellevue',
    'Gretna',
    'Bonaire',
    'Centerville',
    'Kenner',
    'Olmsted Falls',
    'Vinton',
    'Addis',
    'White Castle',
    'Port Allen',
    'Brusly',
    'Sun City West',
    'Lindsborg',
    'El Dorado',
    'Maize',
    'Silver Lake',
    'Newton',
    'Caney',
    'Berryton',
    'Gas City',
    'Lufkin',
    'Bloomfield',
    'Hardeeville',
    'Mullica Hill',
    'Columbia',
    'Middlebourne',
    'Poolville',
    'West Helena',
    'Clinton',
    'Bay Springs',
    'Gretna',
    'Pearce',
    'Scottown',
    'Okanogan',
    'Sheldon',
    'Sneads',
    'Halifax',
    'Holton',
    'Luverne',
    'Jenkinsburg',
    'Flora Vista',
    'Farmington',
    'Prospect',
    'South Glastonbury',
    'Bethany',
    'Oakdale',
    'Niantic',
    'Quaker Hill',
    'Broad Brook',
    'Taftville',
    'Falls Village',
    'Grosvenor Dale',
    'Moosup',
    'Redding Ridge',
    'Beacon Falls',
    'Andover',
    'Versailles',
    'Woodbridge',
    'Somers',
    'Plainfield',
    'Deep River',
    'Sterling',
    'Bridgewater',
    'Winchester Center',
    'Higganum',
    'South Woodstock',
    'Danielson',
    'Redding',
    'Contact',
    'Glendora',
    'Belle',
    'Lonedell',
    'Custer',
    'Walhalla',
    'Branch Township',
    'Thomas',
    'Watonga',
    'Fredericksburg',
    'Manhattan',
    'Elwood',
    'Gardner',
    'Mount Rainier',
    'Cornish',
    'Syracuse',
    'Lehi',
    'Apple Valley',
    'Helendale',
    'Glenrock',
    'Yuma',
    'Knights Landing',
    'Pilot Hill',
    'Ione',
    'Bath',
    'Washington',
    'Blue Ridge',
    'Mead',
    'Henderson',
    'San Carlos',
    'Ellsworth',
    'El Paso',
    'Forrest',
    'Goodland',
    'Colby',
    'Oakley',
    'Dighton',
    'Detroit Lakes',
    'Ferris',
    'Nevada',
    'Crandall',
    'Scurry',
    'San Ysidro',
    'Attleboro Falls',
    'Georgetown',
    'West Newton',
    'Hatfield',
    'Glenside',
    'Ardmore',
    'Fresno',
    'Bellaire',
    'Glasco',
    'Bonner Springs',
    'De Soto',
    'Upper Sandusky',
    'Pleasant Ridge',
    'North Salt Lake',
    'Payson',
    'Leesburg',
    'Kensington',
    'Waterford',
    'Colton',
    'Dufur',
    'The Dalles',
    'Elmendorf',
    'Adkins',
    'White Hall',
    'Hunt Valley',
    'Fairfax Station',
    'St. Rose',
    'Pendleton',
    'Gladwyne',
    'Voorhees Township',
    'Atherton',
    'Shobonier',
    'Underwood',
    'North Chicago',
    'Bacliff',
    'Wilmer',
    'Lavon',
    'Dubuque',
    'Altoona',
    'Coralville',
    'Lone Tree',
    'Chappaqua',
    'Savage',
    'Jordan',
    'Elko',
    'Danville',
    'Ranger',
    'Fairmount',
    'Sugar Valley',
    'Statham',
    'Pope Valley',
    'Rutherford',
    'Lakeport',
    'Garrison',
    'Timpson',
    'Tenaha',
    'Gary',
    'Cassopolis',
    'Willow Springs',
    'International Falls',
    'Westfield',
    'Artesia',
    'Breese',
    'Red Bud',
    'Saint Libory',
    'Farina',
    'Mulberry Grove',
    'Celeste',
    'Martinsville',
    'Corrigan',
    'Winterthur',
    'Great Falls',
    'Derwood',
    'Mercedes',
    'La Feria',
    'Rio Hondo',
    'Donna',
    'Laguna Woods',
    'McClelland',
    'Neola',
    'Brightwaters',
    'Belen',
    'Brentwood',
    'Hewlett',
    'Morse',
    'Rayne',
    'Stonewall',
    'Rosedale',
    'Hagerstown',
    'Economy',
    'Lebanon',
    'Daleville',
    'Yorktown',
    'Culver',
    'Bremen',
    'Churubusco',
    'Claypool',
    'Dresher',
    'Gibson City',
    'Lutherville-Timonium',
    'Wattsville',
    'Onancock',
    'Capeville',
    'Upperco',
    'Tasley',
    'Exmore',
    'Spencerville',
    'Chinle',
    'Pinon',
    'Fort Defiance',
    'Tuba City',
    'Wyckoff',
    'Janesville',
    'Hayes',
    'Bolivia',
    'Port Reading',
    'Alma',
    'Teutopolis',
    'Grafton',
    'Lebanon',
    'Byron',
    'Grand River',
    'Jefferson',
    'Clayton',
    'Dahlonega',
    'Blairsville',
    'Cornelia',
    'Crawford',
    'Alto',
    'Lake City',
    'Lonoke',
    'Perryville',
    'Vilonia',
    'Crocker',
    'Lindsay',
    'Dawson',
    'Normangee',
    'Groveton',
    'Andrews',
    'Saronville',
    'Nebraska City',
    'Santa Claus',
    'Lancaster',
    'Nokomis',
    'Purcell',
    'Grand Tower',
    'Hastings',
    'Doniphan',
    'Sparkman',
    'Sheridan',
    'Grapevine',
    'Fairdealing',
    'Delta',
    'Jefferson',
    'Vanceburg',
    'Flemingsburg',
    'Argillite',
    'America',
    'Quantico',
    'Cisco',
    'Millington',
    'Port Jefferson',
    'Blue Point',
    'Allentown',
    'Wading River',
    'Lavallette',
    'Quogue',
    'Ardsley',
    'Pine Beach',
    'Pomona',
    'Haverstraw',
    'Inwood',
    'Roseland',
    'Sea Cliff',
    'Albertson',
    'Highland Park',
    'Highland Lakes',
    'River Edge',
    'Kenilworth',
    'Glen Oaks',
    'Cos Cob',
    'Old Bethpage',
    'Old Westbury',
    'Point Pleasant Beach',
    'Glen Ridge',
    'Beverly',
    'Aroostook Band of Micmac Trust Land',
    'Comptche',
    'Twain Harte',
    'Mendocino',
    'Point Arena',
    'Harper Woods',
    'Newburgh',
    'Southgate',
    'Covington',
    'Latonia',
    'Rosharon',
    'Midway',
    'Merced',
    'Lindsay',
    'Riverbank',
    'Delhi',
    'Linden',
    'Exeter',
    'Ballico',
    'Lockeford',
    'Woodlake',
    'Dos Palos',
    'Atwater',
    'Leesburg',
    'Ivanhoe',
    'French Camp',
    'Farmersville',
    'Richton Park',
    'White Bluff',
    'Neptune Beach',
    'Highlands',
    'Goldenrod',
    'Clearwater Beach',
    'Southampton',
    'Willingboro',
    'Havertown',
    'Lansdowne',
    'Westville',
    'Bryn Mawr',
    'Milaca',
    'Stone Park',
    'Winton',
    'Livingston',
    'Kearny',
    'Belleville',
    'San Luis Rey Heights',
    'Willis',
    'Davidsonville',
    'Burley',
    'Union Grove',
    'Somerville',
    'Oneonta',
    'Santa Clara',
    'Cortez',
    'Elkhart',
    'Canyon',
    'Littlefield',
    'Clyde',
    'Browning',
    'Lake Peekskill',
    'Pocono Pines',
    'Scotrun',
    'Shamokin Dam',
    'Northumberland',
    'Winfield',
    'Middleburg',
    'Coal Township',
    'Shamokin',
    'Kulpmont',
    'Locust Gap',
    'Ashland',
    'Trevorton',
    'Paxinos',
    'Wilburton',
    'Marion Heights',
    'Montrose',
    'Flagler Beach',
    'Raymondville',
    'Falfurrias',
    'Charlotte',
    'George West',
    'Rio Grande City',
    'Oilton',
    'Dilley',
    'Burneyville',
    'Davis',
    'Wynnewood',
    'Marietta',
    'Overbrook',
    'Thackerville',
    'Cheney',
    'Hearne',
    'Pine Grove',
    'Kirkwood',
    'New Holland',
    'Atglen',
    'Tower City',
    'Parkesburg',
    'Bird in Hand',
    'Halifax',
    'Valley View',
    'Elizabethville',
    'Middletown',
    'Gap',
    'Pequea',
    'Holtwood',
    'Williamstown',
    'Drumore',
    'Peach Bottom',
    'New Providence',
    'Christiana',
    'Gratz',
    'Leesport',
    'Intercourse',
    'Shoemakersville',
    'Lykens',
    'Friendsville',
    'Towanda',
    'Meshoppen',
    'Dushore',
    'Rome',
    'Taylor',
    'Wapwallopen',
    'Montrose',
    'Waverly',
    'Benton',
    'Dallas',
    'Huntington Mills',
    'Hop Bottom',
    'Susquehanna',
    'Hallstead',
    'Stillwater',
    'Harveys Lake',
    'Nicholson',
    'Falls',
    'Conyngham',
    'Paradise',
    'Pen Argyl',
    'Mohrsville',
    'Kinzers',
    'Blue Ball',
    'Terre Hill',
    'Canby',
    'Fountain',
    'Cannon Falls',
    'Finlayson',
    'Chisago City',
    'Moose Lake',
    'Sturgeon Lake',
    'Center City',
    'Willow River',
    'Taylors Falls',
    'McGregor',
    'Bethel',
    'Scandia',
    'Askov',
    'Decatur',
    'Lawrence',
    'Centreville',
    'Jeddo',
    'Hudson',
    'Rives Junction',
    'Rutledge',
    'Washburn',
    'Thorn Hill',
    'Metamora',
    'Yale',
    'Potterville',
    'Goodells',
    'Bannister',
    'Ashley',
    'Maple Rapids',
    'Alma',
    'Elwell',
    'Avoca',
    'Lexington',
    'Parma',
    'Ovid',
    'White Lake',
    'Maybee',
    'Grand Ledge',
    'Posen',
    'North Branch',
    'Hillman',
    'Decker',
    'Mattawan',
    'Pullman',
    'Grand Junction',
    'Gregory',
    'Bancroft',
    'Weidman',
    'Six Lakes',
    'Vestaburg',
    'Barryton',
    'East Wenatchee',
    'Ephrata',
    'Silvana',
    'Clinton',
    'Lyman',
    'Brewster',
    'Sumas',
    'Benton City',
    'Santee',
    'Elloree',
    'Hemingway',
    'Yemassee',
    'Lake City',
    'Edisto Island',
    'Conklin',
    'Hart',
    'Gobles',
    'New Era',
    'Fennville',
    'Union',
    'Marcellus',
    'Vandalia',
    'Covert',
    'Middleton',
    'Quincy',
    'Alger',
    'Attica',
    'Monroeville',
    'Frisco City',
    'Camden',
    'Magnolia',
    'Ethelsville',
    'Atmore',
    'Repton',
    'Kennedy',
    'Ravenna',
    'Mendon',
    'Holstein',
    'Fort Dodge',
    'Denison',
    'Britton',
    'Clinton',
    'White Pigeon',
    'Markleeville',
    'Gardnerville',
    'Hayden',
    'Priest River',
    'Greenacres',
    'Orofino',
    'Pullman',
    'Moscow',
    'Genesee',
    'Palouse',
    'Weippe',
    'Potlatch',
    'Newport',
    'Spirit Lake',
    'Rosalia',
    'Rathdrum',
    'Ponderay',
    'Fairfield',
    'Oakesdale',
    'Garfield',
    'Tekoa',
    'Viola',
    'Parma',
    'Wilder',
    'Coos Bay',
    'La Grande',
    'Joseph',
    'Enterprise',
    'Wallowa',
    'Crescent City',
    'North Bend',
    'Brookings',
    'Coquille',
    'Lakeside',
    'Myrtle Point',
    'Port Orford',
    'Ferndale',
    'Silverton',
    'Gaston',
    'Mill City',
    'Dayton',
    'Banks',
    'Dundee',
    'Amity',
    'Aumsville',
    'Ganado',
    'Alpine',
    'Eagar',
    'Apache Junction',
    'Pinedale',
    'Snowflake',
    'Whiteriver',
    'Concho',
    'Nutrioso',
    'Saint Johns',
    'Fort Apache',
    'Chester',
    'Klamath',
    'Canby',
    'Castle Rock',
    'Clatskanie',
    'Troutdale',
    'Vernonia',
    'Welches',
    'Industry',
    'Pontiac',
    'Danvers',
    'Clinton',
    'Saybrook',
    'Cissna Park',
    'Bement',
    'Moweaqua',
    'Mahomet',
    'Tremont',
    'Assumption',
    'Bushnell',
    'Malta',
    'Kewanee',
    'Harmon',
    'Monroe Center',
    'Du Bois',
    'Woodlawn',
    'Bartelso',
    'Irvington',
    'Waltonville',
    'Richview',
    'Pocahontas',
    'Ashley',
    'Hoffman',
    'Ina',
    'Hoyleton',
    'Armstrong',
    'Gifford',
    'Lovington',
    'Mansfield',
    'Fisher',
    'Eureka',
    'Newman',
    'Collison',
    'Roanoke',
    'Tolono',
    'Pawnee',
    'Omaha',
    'Eldorado',
    'New Burnside',
    'Herrin',
    'Marion',
    'Zeigler',
    'Brookport',
    'Mulkeytown',
    'Roseville',
    'Knoxville',
    'Abingdon',
    'Kirkwood',
    'Carman',
    'Carthage',
    'Monmouth',
    'Oakdale',
    'West Frankfort',
    'Harrisburg',
    'Carrier Mills',
    'Shawneetown',
    'Marissa',
    'Energy',
    'Ewing',
    'Benton',
    'Murphysboro',
    'Creal Springs',
    'Ullin',
    'Makanda',
    'Raleigh',
    'Simpson',
    'Christopher',
    'Villa Ridge',
    'Buckner',
    'Ellis Grove',
    'Thompsonville',
    'Royalton',
    'Percy',
    'Galatia',
    'Ava',
    'Steeleville',
    'Willisville',
    'De Soto',
    'Cypress',
    'Pittsburg',
    'Evansville',
    'Vienna',
    'Lucasville',
    'Sardinia',
    'Pomeroy',
    'Piketon',
    'Middleport',
    'Albany',
    'Nelsonville',
    'Ostrander',
    'Beverly',
    'Guysville',
    'Langsville',
    'Baltimore',
    'Vinton',
    'New Marshfield',
    'Sugar Grove',
    'Portland',
    'Rockbridge',
    'Otway',
    'Laurelville',
    'Amesville',
    'Waldo',
    'Chauncey',
    'Somerville',
    'Georgetown',
    'Seaman',
    'Winchester',
    'Bremen',
    'Williamsport',
    'Midland',
    'McArthur',
    'Zaleski',
    'Beaver',
    'Oak Hill',
    'Greenfield',
    'Cardington',
    'Amanda',
    'Mount Orab',
    'Wharton',
    'Russellville',
    'Ashley',
    'Leesburg',
    'Hamersville',
    'Richwood',
    'Lowell',
    'Byesville',
    'Cheshire',
    'Prospect',
    'New Vienna',
    'Senecaville',
    'Winesburg',
    'Norwalk',
    'Powder Springs',
    'Stockbridge',
    'Henderson',
    'Lyons',
    'Emmett',
    'West Portsmouth',
    'Lambertville',
    'Mears',
    'Fremont',
    'Oberlin',
    'Garrettsville',
    'Polk',
    'Columbia Station',
    'Wakeman',
    'East Claridon',
    'Sugarcreek',
    'Felicity',
    'Clarksville',
    'Genoa',
    'New London',
    'Willard',
    'Port Clinton',
    'Dover',
    'Farmer City',
    'Freeport',
    'Byron',
    'Belvidere',
    'Rockton',
    'Kingston',
    'Stillman Valley',
    'Cortland',
    'Hinckley',
    'Elizabeth',
    'Durand',
    'La Moille',
    'Serena',
    'Somonauk',
    'Goodfield',
    'Wonder Lake',
    'Waterman',
    'Minonk',
    'Earlville',
    'Sparland',
    'Princeville',
    'Dunlap',
    'Granville',
    'Wyoming',
    'Cedar Point',
    'Toulon',
    'Henry',
    'Chillicothe',
    'Minier',
    'Atlanta',
    'Chenoa',
    'Fairbury',
    'McLean',
    'Chatsworth',
    'Elkhart',
    'Wapella',
    'Gridley',
    'Colfax',
    'Clayton',
    'New Canton',
    'Heyworth',
    'Streator',
    'Piper City',
    'Hudson',
    'Robinson',
    'Paxton',
    'Williamsfield',
    'Chester',
    'Sailor Springs',
    'Johnston City',
    'Louisville',
    'Jonesboro',
    'Goreville',
    'Flora',
    'Du Quoin',
    'Cobden',
    'Dongola',
    'Kempton',
    'Flanagan',
    'Cullom',
    'Saunemin',
    'Rossville',
    'Winnebago',
    'Mount Pulaski',
    'Chestnut',
    'Leland',
    'Tiskilwa',
    'Brimfield',
    'Cove',
    'Dallas',
    'Elgin',
    'Union',
    'Lostine',
    'Columbia City',
    'Willamina',
    'Carlton',
    'Hermiston',
    'Leavenworth',
    'Gold Bar',
    'Maple Falls',
    'Washingtonville',
    'Narrowsburg',
    'Glen Spey',
    'Bloomingburg',
    'Wurtsboro',
    'Eldred',
    'Otisville',
    'Cochecton',
    'Barryville',
    'New Berlin',
    'East Pharsalia',
    'Willet',
    'West Edmeston',
    'Masonville',
    'Unadilla',
    'Waterville',
    'Brookfield',
    'Hubbardsville',
    'Chenango Forks',
    'McDonough',
    'Franklin',
    'North Brookfield',
    'New Woodstock',
    'Earlville',
    'Sidney Center',
    'Hemlock',
    'Mount Morris',
    'Perry',
    'Springwater',
    'Linwood',
    'Le Roy',
    'Livonia',
    'Honeoye Falls',
    'Nunda',
    'Jasper',
    "Northrup's of West Bloomfield Mobile Home Park",
    'Port Leyden',
    'Woodgate',
    'Remsen',
    'Hilton',
    'East Branch',
    'Port Crane',
    'Roscoe',
    'Lisle',
    'Dryden',
    'Cincinnatus',
    'Downsville',
    'South Plymouth',
    'Lac du Flambeau',
    'New Richmond',
    'Spring Green',
    'Star Prairie',
    'Arkdale',
    'Pardeeville',
    'Lake Delton',
    'Johnson Creek',
    'Belleville',
    'Westfield',
    'Mauston',
    'Hazelhurst',
    'Merrill',
    'Abbotsford',
    'Hatley',
    'Mayville',
    'Adams',
    'Friendship',
    'Rock Springs',
    'Random Lake',
    'Campbellsport',
    'Clyman',
    'Cascade',
    'Neosho',
    'Kewaskum',
    'Elkhart Lake',
    'Waldo',
    'Mount Calvary',
    'Fredonia',
    'Stratford',
    'Tomahawk',
    'Owen',
    'Woodruff',
    'Athens',
    'Fayette',
    'Celina',
    'Helena',
    'Antwerp',
    'McComb',
    'Fort Recovery',
    'North Fairfield',
    'Hicksville',
    'Scott',
    'Put-in-Bay',
    'Strasburg',
    'Minerva',
    'Bloomville',
    'Clyde',
    'Payne',
    'Huron',
    'Weston',
    'Pioneer',
    'Carrollton',
    'Greenwich',
    'Lodi',
    'Edon',
    'Dundee',
    'Burbank',
    'Bolivar',
    'Malvern',
    'New Washington',
    'New Plymouth',
    'Wallace',
    'Bonners Ferry',
    'Athol',
    'Oldtown',
    'Moyie Springs',
    'Mullan',
    'Bayview',
    'Blanchard',
    'Pageland',
    'Flag Pond',
    'Stem',
    'Butner',
    'Hayesville',
    'Warne',
    'Whittier',
    'Scotts Mills',
    'Berlin Heights',
    'Haviland',
    'Hanoverton',
    'Collins',
    'Monroeville',
    'Baltic',
    'Grand Rapids',
    'Pemberville',
    'Nooksack',
    'Colby',
    'Nebo',
    'Burnsville',
    'Old Fort',
    'Mars Hill',
    'Bakersville',
    'Hot Springs',
    'Glenwood',
    'Andrews',
    'Marble',
    'Brasstown',
    'Young Harris',
    'Topton',
    'Dillsboro',
    'Tuckasegee',
    'Sapphire',
    'Del Valle',
    'Lancaster',
    'Egypt',
    'Anthony',
    'Iota',
    'Hungerford',
    'Franklin',
    'Conklin',
    'Ladera Ranch',
    'Liverpool',
    'Richmond Hill',
    'Pooler',
    'McSherrystown',
    'Marysville',
    'Landisville',
    'Philipsburg',
    'Boyertown',
    'Centerville',
    'Lewiston',
    'Jackson',
    'Palm City',
    'Palm Beach',
    'Oakland',
    'Wrightsville',
    'Summerville',
    'Bridgeton',
    'Wyncote',
    'Florence',
    'South Bound Brook',
    'Penns Park',
    'Toughkenamon',
    'Northfield',
    'Lumberton',
    'DeWitt',
    'South Boston',
    'Washington',
    'Wallkill',
    'Rock Tavern',
    'Granville',
    'Williamstown',
    'Lubec',
    'Hampden',
    'Poland',
    'Cape Elizabeth',
    'Waterville Valley',
    'East Amherst',
    'Clayton',
    'Ellenburg Depot',
    'Oriskany',
    'Skaneateles Falls',
    'Wellsville',
    'Grand Isle',
    'Cumberland Center',
    'Saco',
    'Bridgton',
    'York Corner',
    'Cornish',
    'Mountainville',
    'North Branch',
    'Riderwood',
    'Tracys Landing',
    'Myersville',
    'Sunderland',
    'Solomons',
    'Walnut',
    'Malden',
    'Hopedale',
    'Alexandria',
    'Elizabethtown',
    'Archbald',
    'Robesonia',
    'New Oxford',
    'Holden',
    'Florence',
    'Goodlettsville',
    'Hillsville',
    'Isle of Palms',
    'Spring Grove',
    'Fayetteville',
    'Osceola Mills',
    'Islamorada',
    'Jesup',
    'Midway',
    'Corinth',
    'Ocean City',
    'West Berlin',
    'New Providence',
    'Adair',
    'Kirtland',
    'Heber',
    'Middletown',
    'Folsom',
    'Oreland',
    'Lewistown',
    'Newmanstown',
    'Bentleyville',
    'Enola',
    'Vevay',
    'Island Lake',
    'Jenison',
    'Marine City',
    'Huntington Woods',
    'Highland',
    'Wildwood',
    'Pitman',
    'Asbury',
    'Edgewood',
    'Marriottsville',
    'Dayton',
    'Marietta',
    'East Petersburg',
    'Columbus',
    'Worcester',
    'Cheltenham',
    'Gibbsboro',
    'Eagle',
    'Trinidad',
    'Captiva',
    'New Ulm',
    'Auburn',
    'Chase City',
    'Sherborn',
    'Upton',
    'Damascus',
    'American Canyon',
    'South Holland',
    'Kenilworth',
    'New Castle',
    'Alpine',
    'El Dorado',
    'East Calais',
    'New Castle',
    'Greenland',
    'Centerville',
    'Moore Haven',
    'Bartow',
    'Gallup',
    'Mound',
    'Wayzata',
    'Long Lake',
    'Maple Plain',
    'Bondurant',
    'Sparks',
    'Naylor',
    'Geneseo',
    'Galva',
    'Cambridge',
    'Port Byron',
    'Carrollton',
    'Brunswick',
    'Caruthersville',
    'Delavan',
    'Dawson',
    'Cataula',
    'Waterloo',
    'Traer',
    'Aplington',
    'Eldora',
    'La Porte City',
    'Millsboro',
    'Lewes',
    'Fortson',
    'Silver Bay',
    'Two Harbors',
    'Cassville',
    'Goodman',
    'Dawson',
    'Lacon',
    'North Webster',
    'Chanhassen',
    'Belle Plaine',
    'Saint Peter',
    'Loami',
    'Thomasville',
    'Burlington',
    'Galena',
    'Marshalltown',
    'Grinnell',
    'Worthington',
    'Fulda',
    'Lena',
    'Darlington',
    'Pearl City',
    'Cuba City',
    'Pecatonica',
    'Chadwick',
    'Lanark',
    'Warren',
    'Hazel Green',
    'Shannon',
    'Milledgeville',
    'Davis',
    'Hanover',
    'Willacoochee',
    'Doerun',
    'Mount Carroll',
    'Benton',
    'Irvington',
    'Prior Lake',
    'Atkinson',
    'Marion',
    'Excelsior',
    'Iron Junction',
    'Chisholm',
    'Virginia',
    'Carl Junction',
    'Sarcoxie',
    'Santa Rosa Beach',
    'Angola',
    'Tama',
    'Manchester',
    'Parkersburg',
    'Granite Falls',
    'Cloquet',
    'Bettendorf',
    'Kernville',
    'Bodfish',
    'Wofford Heights',
    'Adel',
    'Clinton',
    'Darwin',
    'Hutchinson',
    'Butler',
    'Marceline',
    'Norborne',
    'Dixons Mills',
    'Hayfield',
    'Cuba',
    'Vinton',
    'Newton',
    'Brookings',
    'Hermann',
    'Lillian',
    'Robertsdale',
    'Effingham',
    'Chatfield',
    'Saint Charles',
    'Gaylord',
    'Hector',
    'Nevada',
    'Burlington',
    'Danville',
    'Nappanee',
    'Ardmore',
    'Boone',
    'Leitchfield',
    'Smiths Grove',
    'Lake Wilson',
    'Luverne',
    'Livingston',
    'Midland',
    'Manson',
    'Coon Valley',
    'Spirit Lake',
    'Lake Park',
    'Morrison',
    'De Witt',
    'Golden City',
    'Fairfield',
    'Prophetstown',
    'Camanche',
    'Grimes',
    'Maquoketa',
    'Cairo',
    'Savanna',
    'Fulton',
    'Viroqua',
    'Westby',
    'New Lisbon',
    'Gulf Shores',
    'Americus',
    'Nogales',
    'McDaniels',
    'Archie',
    'Albia',
    'Oskaloosa',
    'Burkesville',
    'Albany',
    'Marseilles',
    'Baldwin City',
    'Mattoon',
    'West Branch',
    'Belle Plaine',
    'Towanda',
    'Eldridge',
    'Edenton',
    'Knox',
    'Birmingham',
    'LeClaire',
    'Albion',
    'Hanna City',
    'Egan',
    'Hampton',
    'Creston',
    'Prairie du Chien',
    'Atlantic',
    'Glenwood',
    'Camden',
    'Ventura',
    'East Moline',
    'Durant',
    'Grabill',
    'Preston',
    'Orange Beach',
    'Centerville',
    'Bloomfield',
    'Wiggins',
    'Decatur',
    'Winsted',
    'Franklin',
    'Colona',
    'Fairfax',
    'Hennepin',
    'Carroll',
    'Charleston',
    'Moyock',
    'Milan',
    'Andalusia',
    'Alpha',
    'Waconia',
    'Lanesboro',
    'Galesburg',
    'Arlington',
    'Ivanhoe',
    'Bonifay',
    'Avon',
    'Farmington',
    'Silverhill',
    'North Liberty',
    'Frankford',
    'Hiawatha',
    'Williamsburg',
    'Ocean View',
    'Bishopville',
    'Conway',
    'Forsyth',
    'Kirbyville',
    'Marshfield',
    'Seymour',
    'Nixa',
    'Mantorville',
    'Stanford',
    'Solon',
    'Morris',
    'Belgrade',
    'Appleton',
    'Odessa',
    'Kensington',
    'Wheaton',
    'Spring Branch',
    'Salado',
    'Bethel Springs',
    'Mathiston',
    'Hickory Valley',
    'DeBary',
    'De Leon Springs',
    'Cape Canaveral',
    'Gotha',
    'Oakland',
    'Sebastian',
    'Whitestown',
    'Sinton',
    'Gregory',
    'Bishop',
    'Medina',
    'Mercer',
    'Waldo',
    'Long Island',
    'Luray',
    'Smith Center',
    'Phillipsburg',
    'Kensington',
    'Scandia',
    'Hoisington',
    'Helen',
    'Athens',
    'Lake Park',
    'Horse Cave',
    'Seffner',
    'Plover',
    'Clarksville',
    'York',
    'Williamston',
    'Roanoke Rapids',
    'Smithfield',
    'Great Falls',
    'Babb',
    'Ellensburg',
    'Atlantic Beach',
    'Kuttawa',
    'Southaven',
    'Clinton',
    'Rocky Mount',
    'Niceville',
    'CaÃ±on City',
    'Rio Rancho',
    'Mansura',
    'Forsyth',
    'Claremont',
    'Salida',
    'South Padre Island',
    'Sulphur Springs',
    'Yutan',
    'Geneva',
    'Nicholson',
    'Lexington',
    'Cleveland',
    'Carnesville',
    'Purdy',
    'Stockton',
    'Bolivar',
    'Union Star',
    'Altamont',
    'Gallatin',
    'Bellflower',
    'Holliday',
    'Curryville',
    'Silex',
    'Sandoval County',
    'Savanna',
    'De Kalb',
    'Comer',
    'Tiger',
    'Danielsville',
    'Baldwin',
    'Mount Airy',
    'Perry',
    'Homerville',
    'Fitzgerald',
    'Marshallville',
    'Nacoochee',
    'Eastanollee',
    'Lavonia',
    'Clarkesville',
    'Sinclairville',
    'Blakely',
    'Leary',
    'Marcellus',
    'Dillard',
    'Hull',
    'Chickamauga',
    'LaFayette',
    'Vega',
    'Panama',
    'Berryville',
    'Alpena',
    'Huntsville',
    'Green Forest',
    'Foss',
    'Wayne',
    'Hammon',
    'Wilmore',
    'Peru',
    'Firth',
    'Unadilla',
    'Oglethorpe',
    'Montezuma',
    'Wray',
    'Morgan',
    'Uvalda',
    'Lakeland',
    'Buena Vista',
    'Rebecca',
    'Hodgenville',
    'Campbellsville',
    'Cecilia',
    'Greensburg',
    'Clarkson',
    'Princeton',
    'Corydon',
    'Versailles',
    'Leavenworth',
    'North Vernon',
    'Winchester',
    'Elizabeth',
    'Brownstown',
    'Owensville',
    'Oakland City',
    'Milltown',
    'Westport',
    'Shoals',
    'Henryville',
    'Francisco',
    'Worthington',
    'Depauw',
    'Saint Anthony',
    'Georgetown',
    'Dubois',
    'Lewisburg',
    'Greensburg',
    'Osgood',
    'Farmersville',
    'Phillipsburg',
    'Casstown',
    'Connersville',
    'Lanesville',
    'College Corner',
    'Crandall',
    'Freetown',
    'Lynn',
    'Salem',
    'Milan',
    'Loogootee',
    'New Salisbury',
    'Patoka',
    'Memphis',
    'Springport',
    'Butlerville',
    'Ohio City',
    'Crane',
    'Scottsburg',
    'Batesville',
    'Etna Green',
    'Mentone',
    'Middlebury',
    'Bourbon',
    'Rolling Prairie',
    'Forreston',
    'Kirkland',
    'Creston',
    'Cherry Valley',
    'Leaf River',
    'Capron',
    'Mount Morris',
    'West Salem',
    'Palestine',
    'Lawrenceville',
    'Albion',
    'Casey',
    'Clay City',
    'Oblong',
    'Wayne City',
    'Greenup',
    'Sainte Marie',
    'Bridgeport',
    'Noble',
    'Carlock',
    'Roodhouse',
    'New Athens',
    'Shirley',
    'Franklin',
    'Homer',
    'Kouts',
    'Union Mills',
    'La Crosse',
    'Hanna',
    'West Terre Haute',
    'Akron',
    'Wheatland',
    'Farmersburg',
    'Hartford City',
    'Sullivan',
    'Prairieton',
    'Cory',
    'Lewis',
    'Marshall',
    'Center Point',
    'New Goshen',
    'Chrisman',
    'Clay City',
    'Bronson',
    'Eau Claire',
    'Constantine',
    'Oostburg',
    'Brillion',
    'Oakfield',
    'Chilton',
    'Theresa',
    'Belgium',
    'Antigo',
    'Wyocena',
    'Montello',
    'Conover',
    'Wenatchee',
    'Entiat',
    'Laurel',
    'Butler',
    'Liberty',
    'Huntington',
    'Williamsburg',
    'Laconia',
    'Pleasant Lake',
    'Zanesville',
    'Rushville',
    'Mooreland',
    'Lost Creek',
    'Parsons',
    'Flemington',
    'Marlinton',
    'Valley Head',
    'Delbarton',
    'Oak Hill',
    'Chapmanville',
    'Lesage',
    'Tornado',
    'Eleanor',
    'Belle',
    'White Sulphur Springs',
    'East Lynn',
    'Fort Gay',
    'Ronceverte',
    'Danville',
    'Ona',
    'Hurricane',
    'Prichard',
    'Meadow Bridge',
    'Richwood',
    'Dunbar',
    'Pleasant Hill',
    'Odell',
    'Le Roy',
    'Wenona',
    'Mount Sterling',
    'Onamia',
    'Isle',
    'Mora',
    'Brainerd',
    'Needmore',
    'Breezewood',
    'Roaring Branch',
    'Munith',
    'Onondaga',
    'Jermyn',
    'Wyoming',
    'Ulster',
    'Lake Winola',
    'Sugar Run',
    'Houghton Lake',
    'Atlanta',
    'Richmond',
    'Brown City',
    'Harbor Beach',
    'Carson City',
    'Tivoli',
    'Hyde Park',
    'Martin',
    'Palmersville',
    'Sharon',
    'Villa Grove',
    'Seymour',
    'Thomasboro',
    'Sidney',
    'Ancona',
    'Blanding',
    'Moab',
    'Monticello',
    'Almond',
    'Boardman',
    'Kailua',
    'Barstow',
    'Beaumont',
    'Tompkinsville',
    'Edmonton',
    'Providence',
    'Sterling',
    'La Harpe',
    'Olpe',
    'Matfield Green',
    'Cassoday',
    'Marquette',
    'Armona',
    'Kewaunee',
    'Pound',
    'Pearson',
    'Gleason',
    'Birnamwood',
    'Lena',
    'Greenleaf',
    'Luxemburg',
    'Marion',
    'Leopolis',
    'Coleman',
    'Casco',
    'Crivitz',
    'Wallace',
    'Westwood',
    'Rio Vista',
    'Greenville',
    'Janesville',
    'Milford',
    'Herlong',
    'Crescent Mills',
    'Cascade',
    'Wheelersburg',
    'The Plains',
    'Blue Creek',
    'Morrow',
    'Summerfield',
    'Greenfield',
    'Apalachin',
    'Plymouth',
    'Ringtown',
    'Jasonville',
    'Wanatah',
    'Fountain City',
    'Losantville',
    'Thorntown',
    'Monroeville',
    'Cutler',
    'North Manchester',
    'Carbon',
    'Dennison',
    'Bicknell',
    'Point Pleasant',
    'Scott Depot',
    'Culloden',
    'Nellis',
    'Hanover',
    'Crum',
    'Lynco',
    'Fraziers Bottom',
    'Gilbert',
    'Verdunville',
    'Princeton',
    'Jane Lew',
    'Madison',
    'Alderson',
    'Proctor',
    'Green Bank',
    'Webster Springs',
    'Rock',
    'Philippi',
    'Junior',
    'Center Point',
    'Elkins',
    'Bruceton Mills',
    'Frankford',
    'Nitro',
    'Sandyville',
    'Hico',
    'Craigsville',
    'Mount Hope',
    'Walton',
    'Crawley',
    'Reedy',
    'North Matewan',
    'Gap Mills',
    'Mount Gay',
    'Weston',
    'Shinnston',
    'Saint Albans',
    'Berwind',
    'Cedar Bluff',
    'Bomont',
    'Gay',
    'Spencer',
    'LeRoy',
    'Spurlockville',
    'Paw Paw',
    'Watson',
    'Altamont',
    'Dallas City',
    'Winchester',
    'Greenfield',
    'Griggsville',
    'Maroa',
    'Strawn',
    'Herrick',
    'Patoka',
    'Warfordsburg',
    'Greencastle',
    'Springville',
    'Zion Grove',
    'Covington',
    'Middlesex',
    'Whitney Point',
    'Etna',
    'Sandy Creek',
    'North Creek',
    'Altmar',
    'Lake Pleasant',
    'Adams',
    'Pulaski',
    'Lacona',
    'Indian Lake',
    'Saint Johnsville',
    'Williamstown',
    'Brant Lake',
    'Old Forge',
    'Tribes Hill',
    'Almond',
    'Fillmore',
    'Hunt',
    'Honeoye',
    'Dalton',
    'Walden',
    'York',
    'Lima',
    'Ava',
    'Berkshire',
    'Heber',
    'Overgaard',
    'Window Rock',
    'Manson',
    'Cashmere',
    'Jackson',
    'Hollywood',
    'Saint Germain',
    'Barneveld',
    'Westboro',
    'Arena',
    'Grand Marsh',
    'Ringle',
    'Sister Bay',
    'Eagle',
    'Perrinton',
    'Riga',
    'Loyal',
    'Irma',
    'Three Lakes',
    'Glenville',
    'Le Roy',
    'Clarks Grove',
    'Adams',
    'Kenyon',
    'Blooming Prairie',
    'Pease',
    'Clear Lake',
    'Watervliet',
    'Saint Clair',
    'McDavid',
    'Thomaston',
    'Catherine',
    'Century',
    'Peterman',
    'Mount Gilead',
    'Amsterdam',
    'Bowerston',
    'Scio',
    'Homeworth',
    'Carey',
    'Stryker',
    'Jewett',
    'Dexter City',
    'Carbon Hill',
    'Commercial Point',
    'Wilton',
    'Isleton',
    'Caldwell',
    'Rutland',
    'Chippewa Lake',
    'Farmland',
    'Kansas',
    'Clarks Hill',
    'Plymouth',
    'Marengo',
    'Birdseye',
    'Red Jacket',
    'Matewan',
    'Evans',
    'Mount Nebo',
    'Alkol',
    'Kenna',
    'Clendenin',
    'Kincaid',
    'Logan',
    'Powellton',
    'Union',
    'Pecks Mill',
    'Belington',
    'Bridgeport',
    'Gosport',
    'Rensselaer',
    'Cambridge City',
    'Pershing',
    'Grant Park',
    'Clifton',
    'Rochester',
    'Vernon',
    'Coal City',
    'Meridianville',
    'Petal',
    'Byram',
    'Laplace',
    'Grand Junction',
    'Los Lunas',
    'White Sands',
    'Ranchos de Taos',
    'Bloomfield',
    'Solvang',
    'Forestville',
    'Felton',
    'Otis Orchards',
    'Tell City',
    'Carthage',
    'Lafayette',
    'Osseo',
    'Lincoln Park',
    'Pittsford',
    'Harpswell',
    'Orrs Island',
    'Freeport',
    'Bath',
    'West Durham',
    'Elkton',
    'Highland Springs',
    'Moseley',
    'The Plains',
    'Edwards',
    'Mead',
    'Rainier',
    'Plympton',
    'Halifax',
    'Bryantville',
    'Pembroke',
    'Manchester-by-the-Sea',
    'Granby',
    'Pownal',
    'Northampton',
    'Chatham',
    'South Londonderry',
    'Lake Elmo',
    'Belle Glade',
    'Summerland Key',
    'Dania Beach',
    'Chittenden',
    'Poultney',
    'Underhill',
    'Richmond',
    'Montague',
    'Bernardston',
    'Northfield',
    'South Lancaster',
    'Portsmouth',
    'North Hero',
    'Beaufort',
    'Ladson',
    'Clewiston',
    'Greenfield',
    'Kentfield',
    'Palos Park',
    'Lakeside',
    'Mount Zion',
    'Jonesborough',
    'Unicoi',
    'Big Stone Gap',
    'Corryton',
    'Maynardville',
    'Chilhowie',
    'Saltville',
    'Marion',
    'Luttrell',
    'Bulls Gap',
    'Hampton',
    'Pennington Gap',
    'Chuckey',
    'Limestone',
    'Wise',
    'Atkins',
    'Pound',
    'Coeburn',
    'Heiskell',
    'Norton',
    'East Stone Gap',
    'Appalachia',
    'Strawberry Plains',
    'Pocasset',
    'Morton',
    'Shiloh',
    'Port Republic',
    'Bordentown',
    'Penns Grove',
    'National Park',
    'Harleysville',
    'Rockford',
    'Dryden',
    'Jonesville',
    'Meadowview',
    'Damascus',
    'Duff',
    'Oakdale',
    'Woodlawn',
    'Fries',
    'Austinville',
    'Sharpsville',
    'Attica',
    'Dayton',
    'Kimmell',
    'Bluffton',
    'Albany',
    'Saint Paul Park',
    'Madelia',
    'Columbia',
    'Hughesville',
    'Muncy',
    'Montgomery',
    'Shippensburg',
    'Myerstown',
    'Annville',
    'Wiconisco',
    'Berrysburg',
    'Afton',
    'Cosby',
    'Lake City',
    'Norris',
    'Briceville',
    'Andersonville',
    'Whitesburg',
    'Greenback',
    'Clintwood',
    'Midway',
    'Dungannon',
    'Clinchco',
    'Mosheim',
    'Mohawk',
    'Pahokee',
    'Mazon',
    'Markham',
    'Fortville',
    'Larkspur',
    'Evans',
    'Minersville',
    'Old Forge',
    'Dickson City',
    'Campbelltown',
    'Palmyra',
    'Manchester',
    'Newport',
    'Red Lion',
    'Lenhartsville',
    'Huntingdon',
    'Mount Union',
    'Shrewsbury',
    'Glen Rock',
    'Jamison',
    'Richboro',
    'Palmyra',
    'Berlin',
    'Rexmont',
    'Bernville',
    'Bridgeville',
    'Saint James City',
    'Okahumpka',
    'Weirsdale',
    'Lehigh Acres',
    'Immokalee',
    'Marco Island',
    'LaBelle',
    'South Bay',
    'Silver Creek',
    'Byron',
    'Madison',
    'Crawfordville',
    'Fall Branch',
    'Mascot',
    'Marion',
    'Brunswick',
    'Topsham',
    'Leeds',
    'Divernon',
    'Beech Grove',
    'Hartsville',
    'Greentown',
    'Rock Falls',
    'Grayland',
    'Milton',
    'Taylorsville',
    'Valley Springs',
    'Reedley',
    'Mendota',
    'San Joaquin',
    'Kerman',
    'Clute',
    'Edgartown',
    'West Dennis',
    'Sagamore Beach',
    'Buzzards Bay',
    'Williamsburg',
    'New Ipswich',
    'Feeding Hills',
    'Englewood',
    'Rotonda',
    'Fort Meade',
    'Frostproof',
    'South Fork',
    'Accident',
    'Venetia',
    'Leechburg',
    'Spring Church',
    'Hawthorn',
    'Peckville',
    'Womelsdorf',
    'Schuylkill Haven',
    'Port Matilda',
    'Jessup',
    'Olyphant',
    'Dauphin',
    'Mill Hall',
    'Jersey Shore',
    'Marion',
    'Duryea',
    'Lemoyne',
    'Dunstable',
    'Merrimac',
    'East Sandwich',
    'Forestdale',
    'East Taunton',
    'Mashpee',
    'Rochester',
    'West Harwich',
    'Burlington',
    'Bloomfield',
    'Peotone',
    'Paoli',
    'Califon',
    'Oxford',
    'Woodbine',
    'Salem',
    'Cape May Court House',
    'Villas',
    'Port Murray',
    'Mount Royal',
    'Gibbstown',
    'Audubon',
    'Oaklyn',
    'Runnemede',
    'Clarksboro',
    'Pemberton',
    'Belford',
    'Beach Haven',
    'Monmouth Beach',
    'Fords',
    'Woodstown',
    'Dividing Creek',
    'Thorofare',
    'Roebling',
    'Bernardsville',
    'Woodbury Heights',
    'Mantua',
    'Belle Mead',
    'Roselle Park',
    'Palmetto',
    'Larkspur',
    'Hooper',
    'Carmel',
    'Somers',
    'Wilton',
    'West Townsend',
    'Cataumet',
    'Litchfield',
    'Head of Westport',
    'North Franklin',
    'Franklin',
    'Suquamish',
    'Orting',
    'Sultan',
    'Eastlake',
    'New Castle',
    'Kersey',
    'Green Mountain Falls',
    'Galena Park',
    'Santa Fe',
    'Cedar',
    'Fox Lake',
    'Bedford Park',
    'Creve Coeur',
    'Vallecito',
    'Sheridan',
    'Knightsen',
    'Fulton',
    'Penngrove',
    'Hathorne',
    'Windsor',
    'Sunapee',
    'Essex',
    'Northford',
    'Auburndale',
    'Irasburg',
    'Weatogue',
    'Woolwich',
    'Chester',
    'Greenville',
    'Centerville',
    'Polo',
    'Rochester',
    'Linton',
    'Fairmount',
    'Gillsville',
    'Talmo',
    'Havre de Grace',
    'Ijamsville',
    'Taneytown',
    'Bladensburg',
    'Mayo',
    'Brandywine',
    'Emmitsburg',
    'Riverdale',
    'Triangle',
    'Jobstown',
    'Mount Ephraim',
    'Whitehouse Station',
    'Ewan',
    'Lambertville',
    'Landisville',
    'Cookstown',
    'Stratford',
    'Annandale',
    'Port Norris',
    'Fort Lupton',
    'Woodacre',
    'Salem',
    'Saratoga Springs',
    'East Berkshire',
    'New Boston',
    'Francestown',
    'Kingston',
    'Plainfield',
    'Enfield',
    'Ludlow',
    'South Hero',
    'Philomath',
    'Sanibel',
    'Bokeelia',
    'Alva',
    'Walkersville',
    'Smyrna',
    'Indian Head',
    'Adamstown',
    'Wyoming',
    'Middletown',
    'Reisterstown',
    'Huntingtown',
    'Catonsville',
    'Phoenix',
    'Point of Rocks',
    'Lincoln',
    'Jenkintown',
    'Claymont',
    'Croydon',
    'Wynnewood',
    'Landenberg',
    'Glenolden',
    'Mont Clare',
    'Hockessin',
    'Folcroft',
    'Essington',
    'Brookhaven',
    'Spring Mills',
    'Ronks',
    'Loganton',
    'Lowell',
    'Buena',
    'Quakertown',
    'Lascassas',
    'New Market',
    'Jacksonville',
    'Davisboro',
    'Beaverdam',
    'Bastrop',
    'Wallingford',
    'Crownsville',
    'Glenwood',
    'Teterboro',
    'Cicero',
    'Jamestown',
    'Garrett',
    'Windfall',
    'Stayton',
    'Minotola',
    'Aston Arms',
    'Edgemont',
    'Oaks',
    'Winona Lake',
    'Boyds',
    'Ridgway',
    'Lawrence',
    'Weirton',
    'Marblemount',
    'New Lothrop',
    'Delton',
    'San Geronimo',
    'Sunderland',
    'Linden',
    'Hampton',
    'Garnet Valley',
    'Dublin',
    'Highspire',
    'Eighty Four',
    'Shelocta',
    'Patterson',
    'East Berlin',
    'Fruitland',
    'Taholah',
    'Burr Hill',
    'Centre',
    'Belcamp',
    'Kents Store',
    'Elkridge',
    'Pittstown',
    'Willow Street',
    'Fort Campbell North',
    'Cresson',
    'Woodberry Forest',
    'Federalsburg',
    'Suffield',
    'Rockwood',
    'Saint Leonard',
    'Valleyford',
    'Caledonia',
    'Abington Township',
    'West Falmouth',
    'Granite Bay',
    'Spotsylvania',
    'Goldvein',
    'Pebble Beach',
    'Bumpass',
    'Carson',
    'Haymarket',
    'Strasburg',
    'Hurlock',
    'Whites Creek',
    "Thompson's Station",
    'Ringoes',
    'Berkeley Heights',
    'Mineral',
    'Argyle',
    'Fairfield',
    'Butler',
    'Pomfret',
    'Woodbine',
    'Cedar Crest',
    'White House',
    'Orwigsburg',
    'Greenwood',
    'Haddon Heights',
    'Norma',
    'Mountainside',
    'Gambrills',
    'Claridge',
    'Avalon',
    'Glen Arm',
    'Lyles',
    'Tigard',
    'Bennington',
    'Baxter',
    'Primus',
    'Ireland',
    'Holland',
    'Bolt',
    'Myrtle Creek',
    'Emmett',
    'Kimball',
    'Emporia',
    'Yale',
    'Petersburg',
    'Randolph',
    'Willow Grove',
    'Livingston',
    'Dahlgren',
    'Swanton',
    'Clifton',
    'Defiance',
    'Midland',
    'Tamaqua',
    'Harlem',
    'Aurora',
    'Huntington',
    'Home',
    'Clearwater',
    'Wales',
    'Ephraim',
    'Eureka',
    'Fairview',
    'Mona',
    'Santaquin',
    'Garberville',
    'Fallon',
    'Willow Creek',
    'Cambridge Springs',
    'Merrifield',
    'Washington',
    'Soulsbyville',
    'Jamestown',
    'Sequoia',
    'Hyampom',
    'Freeport',
    'Timberlake',
    'Ferdinand',
    'Hamburg',
    'Clive',
    'Prescott',
    'Lafferty',
    'New Knoxville',
    'Linden',
    'Lonsdale',
    'New Concord',
    'Russellville',
    'Sawyer',
    'Redmond',
    'North Newton',
    'Pierce City',
    'Santa Anna',
    'Caldwell',
    'De Leon',
    'Round Mountain',
    'Dalhart',
    'Stockdale',
    'Buchanan Dam',
    'Krum',
    'Poth',
    'Point Comfort',
    'Mustang',
    'Muleshoe',
    'Clovis',
    'Dublin',
    'Hartwell',
    'Grant',
    'Arnaudville',
    'Aguanga',
    'Sterling Forest',
    'Astoria',
    'Anamosa',
    'Tuxedo Park',
    'Arrington',
    'Watkins Glen',
    'Summit',
    'Colliers',
    'Monrovia',
    'Sandersville',
    'Tonawanda',
    'Freeport',
    'Ford City',
    'Sanbornville',
    'Sabattus',
    'Presque Isle',
    'Tonawanda',
    'Fairfax',
    'Noonan',
    'Ray',
    'Crosby',
    'Alamo',
    'Epping',
    'Flaxton',
    'Columbus',
    'Powers Lake',
    'Chattaroy',
    'Liberty Lake',
    'Foxburg',
    'Brockway',
    'Strattanville',
    'Knox',
    'New Bethlehem',
    'Rimersburg',
    'Shippenville',
    'Cheswick',
    'Homer City',
    'Derry',
    'Dugger',
    'Carlisle',
    'Shelburn',
    'Pimento',
    'Clinton',
    'Soap Lake',
    'Greenville',
    'Lakewood',
    'Mountain',
    'Gainesboro',
    'Byrdstown',
    'Livingston',
    'Jamestown',
    'Grimsley',
    'Hilham',
    'Bend',
    'Fulton',
    'Brookeville',
    'Sauquoit',
    'Elk',
    'Nikiski',
    'Max Meadows',
    'Summersville',
    'Central Valley',
    'Wells',
    'Buffalo',
    'Helena',
    'Thomaston',
    'Ellendale',
    'Auburn',
    'Westview',
    'Roundhill',
    'Magnolia',
    'Buffalo',
    'Upton',
    'Cave City',
    'Ferguson',
    'Sonora',
    'Jones',
    'Raymond',
    'Auburn',
    'Manchester',
    'Rector',
    'French Lick',
    'Beaver Dam',
    'Nashville',
    'Vergennes',
    'Tilton',
    'Cherokee',
    'Cathlamet',
    'Rainier',
    'Osakis',
    'Clarissa',
    'Perham',
    'Bigfork',
    'Omak',
    'Oroville',
    'Tonasket',
    'Twisp',
    'Winthrop',
    'Walnut Grove',
    'Ryde',
    'Tipton',
    'Sand Creek',
    'Blissfield',
    'East Windsor Hill',
    'Woodward',
    'Valley View',
    'Lexington',
    'Wakarusa',
    'Wellington',
    'Chesterfield',
    'Wellton',
    'La Vista',
    'Hurley',
    'Fairfax',
    'Auburndale',
    'Carroll',
    'Calhan',
    'Canon City',
    'Kamiah',
    'Kearney',
    'Oak Creek',
    'Yampa',
    'Hayden',
    'Denver',
    'Mount Vernon',
    'Avon',
    'Bird Island',
    'Flensburg',
    'Litchfield',
    'Foley',
    'Belgrade',
    'Red Lodge',
    'Lolo',
    'Butte',
    'Victor',
    'Potomac',
    'Anaconda',
    'Hamilton',
    'Stevensville',
    'Whitehall',
    'Conrad',
    'Corvallis',
    'Dillon',
    'McCammon',
    'Wilson',
    'Evanston',
    'Jackson',
    'Riverton',
    'Wright',
    'Veneta',
    'Cottage Grove',
    'Holts Summit',
    'Teton Village',
    'Castroville',
    'Gila Bend',
    'Coolidge',
    'Albertville',
    'Carlisle',
    'Douglas',
    'Rose Hill',
    'Ranger',
    'Yadkinville',
    'Muenster',
    'Saint Pauls',
    'Magnolia',
    'Troy',
    'Keokuk',
    'Wellington',
    'Halsey',
    'Kemmerer',
    'Autryville',
    'Whitefish',
    'Brownell',
    'Carbondale',
    'Atwood',
    'Merino',
    'Sterling',
    'Great Mills',
    'Beggs',
    'Stanardsville',
    'Buena Vista',
    'Maxton',
    'Petrolia',
    'Byers',
    'Fiddletown',
    'Baxter Springs',
    'Floyd',
    'Etna',
    'Happy Camp',
    'Fort Jones',
    'New Richmond',
    'Mossyrock',
    'West Point',
    'Pine Grove',
    'Tornillo',
    'Evant',
    'Goldthwaite',
    'Menard',
    'Ozona',
    'Tarboro',
    'Utopia',
    'Rocksprings',
    'Camp Wood',
    'Bangs',
    'Meadow',
    'Robert Lee',
    'Mannford',
    'Hancock',
    'Davis',
    'Mount Wilson',
    'Delano',
    'National City',
    'Forest',
    'Earlimart',
    'Verdi',
    'Willits',
    'Big Sur',
    'Brookfield',
    'Tumwater',
    'Ault',
    'Rockville',
    "O'Neill",
    'Atkinson',
    'Inman',
    'Clear Lake',
    'Rogue River',
    'Pleasant Hill',
    'Gold Hill',
    'Jacksonville',
    'College Place',
    'Seaside',
    'Corrales',
    'Silver City',
    'Tahuya',
    'Wright',
    'Rollingbay',
    'Moriarty',
    'Soda Springs',
    'Cut Bank',
    'East Helena',
    'Donald',
    'Cascade Locks',
    'Glorieta',
    'Las Vegas',
    'Kuna',
    'Lusk',
    'Rexburg',
    'Pacific',
    'Muscatine',
    'Hastings',
    'Eagle Grove',
    'Cass Lake',
    'Cottonwood',
    'Lennox',
    'Deer Island',
    'San Luis',
    'Franktown',
    'Isanti',
    'Brewster',
    'Romney',
    'Quemado',
    'Bagley',
    'Cave Creek',
    'Stone Lake',
    'McDermitt',
    'Cattaraugus',
    'Papillion',
    'Hibbing',
    'Augusta',
    'Cerrillos',
    'Grand Island',
    'Dillard',
    'Frisco',
    'Independence',
    'Zarephath',
    'Springdale',
    'Crested Butte',
    'Tubac',
    'Hawthorne',
    'Seligman',
    'Tipton',
    'Pewee Valley',
    'Elgin',
    'Craig',
    'San Fidel',
    'Wappapello',
    'Van Buren',
    'Oakland',
    'Fort Irwin',
    'Forest City',
    'Gardiner',
    'Aberdeen Proving Ground',
    'Carefree',
    'Saint Michael',
    'La Fox',
    'Doswell',
    'Antioch',
    'Bushton',
    'Holyrood',
    'Miltonvale',
    'Moundridge',
    'Goessel',
    'Little River',
    'Inman',
    'Dorrance',
    'Lyons',
    'Clyde',
    'Minneapolis',
    'Hesston',
    'Wolfe City',
    'Park Hills',
    'Luling',
    'Holderness',
    'Akron',
    'Ben Wheeler',
    'Van',
    'Grand Saline',
    'Alba',
    'Del Rio',
    'Rudolph',
    'Nekoosa',
    'Short Hills',
    'Wellsville',
    'Mendon',
    'Clarkston',
    'Bear River City',
    'Preston',
    'Honeyville',
    'Hyde Park',
    'Dayton',
    'Weston',
    'Paradise',
    'Mantua',
    'Spanish Fork',
    'Morgan',
    'Grantsville',
    'Stockton',
    'Marsing',
    'Eden',
    'Huntsville',
    'Eagle Mountain',
    'Wallsburg',
    'Howe',
    'Weston',
    'Whitesboro',
    'Gunter',
    'Whiteland',
    'Christmas',
    'Palisade',
    'Gunnison',
    'Farmingdale',
    'Nederland',
    'Frederick',
    'Munroe Falls',
    'Mount Olive',
    'Jefferson',
    'Whatley',
    'Lithia',
    'Clifton Forge',
    'Covington',
    'Crimora',
    'Sutton',
    'Talkeetna',
    'Whiting',
    'Onawa',
    'Ida Grove',
    'Missouri Valley',
    'Sloan',
    'Mapleton',
    'Coon Rapids',
    'Sergeant Bluff',
    'Orange City',
    'Windsor',
    'Grandview',
    'Gatesville',
    'Amana',
    'Saint George',
    'Wamego',
    'Saint Marys',
    'Belvue',
    'Denison',
    'Rochelle Park',
    'Nicasio',
    'Waynesboro',
    'Linden',
    'Decaturville',
    'Lebanon Junction',
    'Eden Valley',
    'Glencoe',
    'Milan',
    'Grand Rapids',
    'Ogilvie',
    'Hinckley',
    'Aitkin',
    'Saint Francis',
    'Howard Lake',
    'Pierz',
    'Bellwood',
    'Central Point',
    'Azalea',
    "Pu'unene",
    'Madras',
    'Redmond',
    'Prineville',
    'Eagle Point',
    'Phoenix',
    'Owensville',
    'New Haven',
    'Cave Creek',
    'Folly Beach',
    'Belmont',
    'Prosperity',
    'Aztec',
    'Telluride',
    'Norwood',
    'Mancos',
    'Ruidoso Downs',
    'Winooski',
    'Stowe',
    'Wilmington',
    'Thompson',
    'Rootstown',
    'Bakewell',
    'Tulare County',
    'Clayton',
    'Moriah',
    'Redwood',
    'Alexandria Bay',
    'Dexter',
    'Castorland',
    'Evans Mills',
    'Hammond',
    'Brier Hill',
    'Earlsboro',
    'Vici',
    'Taloga',
    'Binger',
    'Crowley',
    'Huger',
    'Ossian',
    'Craigville',
    'Climax',
    'Fort Totten',
    'Fessenden',
    'Wimbledon',
    'Ypsilanti',
    'Gackle',
    'Saint Michael',
    'Cold Brook',
    'Crown Point',
    'Claryville',
    'Coeymans',
    'Addison',
    'Woodhull',
    'Whitesville',
    'Troupsburg',
    'Rexville',
    'West Liberty',
    'Humansville',
    'Cerro Gordo',
    'Fountain',
    'Stratford',
    'Chaffee',
    'Irving',
    'Delevan',
    'Cato',
    'Cuba',
    'Pima County',
    'Alberton',
    'Turner',
    'Crystal',
    'Wheeler',
    'Sumner',
    'Edmore',
    'Vergennes',
    'Palmer Lake',
    'Princess Anne',
    'Jefferson',
    'Stockton',
    'Warwick',
    'Denton',
    'Greensboro',
    'Worton',
    'Belmont',
    'Crossville',
    'Elizabethtown',
    'Rosiclare',
    'Golconda',
    'Magnolia Springs',
    'Spanish Fort',
    'Kihei',
    'Cambridge',
    'Lyndon Center',
    'West Burke',
    'Brooks',
    'McClellan Park Census Designated Place',
    'Palos Verdes Peninsula',
    'Sunset Beach',
    'Rhome',
    'Daisetta',
    'Hull',
    'Burton',
    'Mont Belvieu',
    'New Waverly',
    'Steele',
    'Linton',
    'Hazelton',
    'Lehr',
    'Zeeland',
    'Wilton',
    'Wing',
    'Diablo',
    'Clayton',
    'Amery',
    'Bloomer',
    'Osseo',
    'Somerset',
    'Baldwin',
    'Strum',
    'Eleva',
    'Whitehall',
    'Clear Lake',
    'Durand',
    'Phillips',
    'New Auburn',
    'Luck',
    'La Pointe',
    'Hixton',
    'Grantsburg',
    'Rosholt',
    'Amherst',
    'Siren',
    'Webster',
    'Independence',
    'Chetek',
    'Park Falls',
    'Alma Center',
    'Hammond',
    'Balsam Lake',
    'Tomah',
    'Clark',
    'Hendricks',
    'Webster',
    'Hazel',
    'Wagner',
    'Lake Andes',
    'Keystone',
    'Chamberlain',
    'Tyndall',
    'Viborg',
    'Warner',
    'Bradford',
    'North Sutton',
    'Virgin',
    'Enterprise',
    'Nunn',
    'Carr',
    'Joes',
    'Idalia',
    'Vona',
    'Kirk',
    'Hugo',
    'Karval',
    'Flagler',
    'Eads',
    'Roggen',
    'Keenesburg',
    'Madison',
    'Wilton',
    'Farmington',
    'Millinocket',
    'East Millinocket',
    'Camilla',
    'Colquitt',
    'Benton',
    'Garden Plain',
    'Mulvane',
    'Halstead',
    'Valparaiso',
    'Rotan',
    'Clyde',
    'Van Horn',
    'Merkel',
    'Albany',
    'Orono',
    'Calais',
    'Blue Hill',
    'Groveland',
    'Fort Walton Beach',
    'Wildwood',
    'Crystal River',
    'McGregor',
    'Crittenden',
    'Manvel',
    'Blue Mountain',
    'Water Valley',
    'Onalaska',
    'Jetersville',
    'Sandborn',
    'Granville',
    'Cottageville',
    'Rock Island',
    'Bethune',
    'Mount Croghan',
    'Swansea',
    'Ware Shoals',
    'Franklin Springs',
    'McCormick',
    'Colchester',
    'Winfield',
    'Round O',
    'Chester',
    'Gilbert',
    'Wagener',
    'Starr',
    'Parkers Lake',
    'Great Falls',
    'Winnsboro',
    'Abbeville',
    'Ruffin',
    'Stearns',
    'Smoaks',
    'Calhoun Falls',
    'Pilot Mountain',
    'Wartburg',
    'Saint Amant',
    'Trenton',
    'Webster',
    'Irvington',
    'Ekron',
    'Guston',
    'Harned',
    'Rhodelia',
    'Hardinsburg',
    'Freeville',
    'Earleville',
    'Queen Anne',
    'Kennedyville',
    'Florence',
    'Stanfield',
    'Arizona City',
    'Valley Farms',
    'Ward',
    'Cave City',
    'Tupelo',
    'Newark',
    'Salem',
    'Judsonia',
    'Ravenden',
    'Twin Peaks',
    'Marshall',
    'Cliffside Park',
    'Wolcott',
    'Zumbrota',
    'Roberts',
    'Jamesville',
    'Croton',
    'Fredonia',
    'Ashville',
    'Denair',
    'Weimar',
    'Hebron',
    'Glen Ullin',
    'McClusky',
    'Flasher',
    'Solen',
    'Goodrich',
    'Fort Yates',
    'New Salem',
    'Saint Anthony',
    'Golden Valley',
    'Groton',
    'Faulkton',
    'Frederick',
    'Tombstone',
    'Huachuca City',
    'Brownfield',
    'Floydada',
    'Marana',
    'Pima',
    'Elgin',
    'Willcox',
    'Mountain City',
    'Levelland',
    'Livermore',
    'Bode',
    'Belle Plaine',
    'Severy',
    'Burden',
    'Cedar Vale',
    'Leon',
    'Dexter',
    'Howard',
    'Moline',
    'Longton',
    'Argonia',
    'Conway Springs',
    'Norwich',
    'Milton',
    'Milan',
    'Haviland',
    'Greensburg',
    'Eastland',
    'Lexington',
    'Pilot Point',
    'Neosho',
    'Olney',
    'Coatesville',
    'Redwood Falls',
    'Morton',
    'Vesta',
    'Walnut Grove',
    'Wabasso',
    'Belview',
    'Lamberton',
    'Wood Lake',
    'Morgan',
    'Combined Locks',
    'Glasco',
    'Kauneonga Lake',
    'Callicoon',
    "Ho'olehua",
    "Ka'a'awa",
    'Hana',
    'Keauhou',
    "Honoka'a",
    'Wailuku',
    'Kaunakakai',
    'Kalaheo',
    "Kea'au",
    'Blackshear',
    'Jamestown',
    'Granby',
    'Moroni',
    'Salina',
    'Fountain Green',
    'Mount Pleasant',
    'Spring City',
    'Loretto',
    'Bluemont',
    'Alexander',
    'Leicester',
    'Churchville',
    'Hopewell',
    'Gouverneur',
    'Waterville',
    'Marquand',
    'Winslow Township',
    'Edinboro',
    'Corry',
    'Waterford',
    'Wingate',
    'Valders',
    'Troy',
    'Fostoria',
    'Munger',
    'Dassel',
    'Oak Park',
    'Sauk Centre',
    'Sugar Grove',
    'Luray',
    'Point Harbor',
    'Roxboro',
    'Orrstown',
    'Fort Loudon',
    'Saint Thomas',
    'State Line',
    'Spring Run',
    'Kettle Falls',
    'Bloomingdale',
    'Hemlock',
    'Lennon',
    'Farber',
    'Centertown',
    'Bonnots Mill',
    'Winona',
    'Saint Clair',
    'Allenton',
    'Center Line',
    'Casco',
    'Columbus',
    'Progreso',
    'Alamo',
    'Plattsmouth',
    'Woodville',
    'Miles City',
    'Lewistown',
    'Sidney',
    'Glendive',
    'Houlton',
    'Monticello',
    'Oakfield',
    'Patten',
    'Brookton',
    'Howland',
    'Perry',
    'Abita Springs',
    'Ironton',
    'Merrifield',
    'Outing',
    'Staples',
    'Nisswa',
    'Pillager',
    'Randall',
    'Cushing',
    'Algonac',
    'Croswell',
    'Dexter',
    'Lake Arthur',
    'Akron',
    'Clifford',
    'Peck',
    'Pigeon',
    'Marlette',
    'Sebewaing',
    'Minden City',
    'Kinde',
    'Otter Lake',
    'Unionville',
    'Clarksville',
    'Las Animas',
    'Dresser',
    'Bixby',
    'Bear Creek',
    'Coffee Springs',
    'Ariton',
    'Newville',
    'Avon',
    'Markesan',
    'Amherst',
    'Valley',
    'Beasley',
    'Diboll',
    'Wells',
    'Pollok',
    'Fruita',
    'Silt',
    'Delta',
    'Paonia',
    'Crawford',
    'Cedaredge',
    'Hotchkiss',
    'Olathe',
    'Alma',
    'Florissant',
    'Broadlands',
    'Elmore',
    'Cedarville',
    'Coin',
    'Auxvasse',
    'Tarkio',
    'Oregon',
    'Lewistown',
    'Maywood',
    'La Belle',
    'Rock Port',
    'Rhineland',
    'Williamstown',
    'Taylor',
    'Bethel',
    'Polo',
    'Forksville',
    'Martins Creek',
    'Warner',
    'Canadian',
    'Wynona',
    'Gore',
    'Keota',
    'McCurtain',
    'Kinta',
    'Quinton',
    'Porum',
    'Deerfield',
    'Newport',
    'Bryn Athyn',
    'West Brookfield',
    'Underwood',
    'Ashby',
    'Erhard',
    'Mariposa',
    'Coarsegold',
    'Eastsound',
    'Lachine',
    'Elkton',
    'Linwood',
    'Hubbard Lake',
    'Presque Isle',
    'Tatamy',
    'Woodlyn',
    'Royalton',
    'Seadrift',
    'Placedo',
    'Shiner',
    'Neodesha',
    'Andover',
    'Brazoria',
    'Harrisburg',
    'Elk River',
    'Craigmont',
    'Vienna',
    'Alum Creek',
    'Glenville',
    'Dahlgren',
    'Mill Shoals',
    'Pearl City, Manana',
    'Llano',
    'Saint Maries',
    'Medical Lake',
    'Penn Yan',
    'Lovington',
    'Portales',
    'Texico',
    'Dozier',
    'Brundidge',
    'Ramer',
    'Glenwood',
    'Opp',
    'Bonnieville',
    'Hardyville',
    'Munfordville',
    'Cub Run',
    'Summer Shade',
    'Canmer',
    'Knob Lick',
    'Center',
    'Sublimity',
    'Lyons',
    'Scio',
    'Colfax',
    'Shirley',
    'Stokesdale',
    'Robinsonville',
    'Boomer',
    'Hebron',
    'Lexington',
    'Holdrege',
    'Broken Bow',
    'Baker City',
    'Pipestone',
    'Cherokee',
    'Estherville',
    'Charles City',
    'Idaho Springs',
    'Iowa Falls',
    'Deer Lodge',
    'Oelwein',
    'Raton',
    'Hampton',
    'Independence',
    'Valentine',
    'Colfax',
    'Hartsville',
    'Troutman',
    'Providence',
    'West Wendover',
    'Pittsfield',
    'Norway',
    'Hollis Center',
    'Horner',
    'Perryton',
    'Briscoe',
    'Shamrock',
    'Lefors',
    'Skellytown',
    'Riva',
    'Longs',
    'Galivants Ferry',
    'Fosters',
    'Goochland',
    'Carlton',
    'Malott',
    'Riverside',
    'Frankston',
    'Edgefield',
    'Dillon Beach',
    'Ocean Shores',
    'Warden',
    'Naselle',
    'Prescott',
    'Dayton',
    'Burbank',
    'Ritzville',
    'Grand Coulee',
    'Nordland',
    'Arapahoe',
    'Bertrand',
    'Eustis',
    'Cambridge',
    'Ogallala',
    'Brule',
    'Elwood',
    'Loomis',
    'Bar Harbor',
    'Swannanoa',
    'Enka',
    'Hadlyme',
    'East Marion',
    'Canton',
    'Cherryvale',
    'Whitewater',
    'Sapulpa',
    'Sperry',
    'Nichols',
    'Los Altos Hills',
    'Moss Landing',
    'Oak View',
    'Alpaugh',
    'Lake Isabella',
    'Weldon',
    'Mifflinburg',
    'Montandon',
    'Millmont',
    'Belleair Beach',
    'Drummonds',
    'Munford',
    'Atoka',
    'Mason',
    'Crossville',
    'Bryant',
    'Dutton',
    'Newport',
    'Rainsville',
    'Fyffe',
    'Old Kotzebue',
    'Flat Rock',
    'Hollywood',
    'Henagar',
    'Reynolds',
    'Talbotton',
    'Butler',
    'Cameron',
    'Laotto',
    'Fremont',
    'Parker',
    'Storm Lake',
    'Wakonda',
    'Tea',
    'Belle Fourche',
    'Blackhawk',
    'Canon',
    'Martin',
    'Rockport',
    'Napier',
    'Canvas',
    'Iaeger',
    'Red House',
    'Fort Ashby',
    'Great Cacapon',
    'Morehead',
    'Pioneertown',
    'Grundy',
    'Mount Sterling',
    'Delphi',
    'Poneto',
    'Warren',
    'Wilkinson',
    'Markleville',
    'Francesville',
    'Swayzee',
    'Odon',
    'Topeka',
    'Ligonier',
    'Wolcottville',
    'Lagrange',
    'Sweetser',
    'Denver',
    'Cloverdale',
    'Atlanta',
    'Bowling Green',
    'Old Pekin',
    'Montgomery',
    'Leo',
    'New Lisbon',
    'Rome City',
    'Brownsville',
    'Buffalo',
    'Star City',
    'Winamac',
    'Republic',
    'Selah',
    'Glendo',
    'Fremont',
    'Plainview',
    'Wolfforth',
    'Walters',
    'Tahoka',
    'Slaton',
    'Prosser',
    'Camden',
    'Freedom',
    'Greene',
    'Interlaken',
    'Waverly',
    'Trumansburg',
    'Center Tuftonboro',
    'Metlakatla',
    'Tok',
    'Thorne Bay',
    'Klawock',
    'Craig',
    'Haines',
    'Petersburg',
    'Northway',
    'Chicken',
    'Skaguay',
    'Iliff',
    'Lyman',
    'Bayard',
    'Bridgeport',
    'Havre',
    'Big Timber',
    'Inverness',
    'Turner',
    'Sadieville',
    'McLoud',
    'Wheatland',
    'Whitethorn',
    'Redway',
    'Carlotta',
    'Fortuna',
    'Riverdale',
    'McKinleyville',
    'Meridian',
    'Philo',
    'Stockton',
    'Knoxville',
    'Silver Creek',
    'Glenn Dale',
    'Lake Arrowhead',
    'Alviso',
    'Winthrop Harbor',
    'Iron Ridge',
    'Eastlake',
    'Edwards',
    'Polk City',
    'Monett',
    'Stahlstown',
    'Everson',
    'Melcroft',
    'Indian Head',
    'Normalville',
    'Point',
    'Princeville',
    'Shafter',
    'Firebaugh',
    'Pixley',
    'Concord',
    'Remington',
    'Montvale',
    'Eagle Rock',
    'Hot Springs',
    'Verona',
    'Goshen',
    'Fort Defiance',
    'Ridgeland',
    'Guilderland',
    'Sudan',
    'Crosbyton',
    'Ransom Canyon',
    'New Deal',
    'Bixby',
    'Sand Springs',
    'Mounds',
    'Claremore',
    'Webster',
    'Warwick',
    'Stilwell',
    'Medicine Lodge',
    'New London',
    'West Swanzey',
    'Belvidere Center',
    'Tunbridge',
    'Newport',
    'West Lebanon',
    'Wolcott',
    'Passumpsic',
    'Brownsville',
    'Sharon',
    'Barton',
    'Rindge',
    'Hancock',
    'Sheldon',
    'North Bennington',
    'Moretown',
    'Antrim',
    'East Middlebury',
    'Jay',
    'Milford',
    'Franconia',
    'Bath',
    'Fitzwilliam',
    'Orford',
    'Old Orchard Beach',
    'Newbury',
    'Phippsburg',
    'Union',
    'Woodsville',
    'Parsonsfield',
    'Richmond',
    'Georgetown',
    'Frankfort',
    'Hiram',
    'Jonesport',
    'Tiverton',
    'Jeffersonville',
    'Ripton',
    'Brookfield',
    'Vernon',
    'Eden',
    'Randolph',
    'Florence',
    'East Burke',
    'Ossipee',
    'Woodstock',
    'Greenfield',
    'Cavendish',
    'West Newbury',
    'Salisbury',
    'Charleston',
    'Pittsfield',
    'Deer Isle',
    'East Blue Hill',
    'Harrison',
    'Lancaster',
    'Ervings Location',
    'Machiasport',
    'Aurora',
    'Milan',
    'North Haven',
    'Milo',
    'Madawaska',
    'Trumann',
    'Tyronza',
    'Wynne',
    'Harrisburg',
    'Manila',
    'Walhalla',
    'Kingsland',
    'Hico',
    'Madisonville',
    'Bellvue',
    'Eaton',
    'La Salle',
    'Hudson',
    'Red Lake Falls',
    'Erskine',
    'Winger',
    'Gatzke',
    'Mentor',
    'Lengby',
    'Grygla',
    'Beltrami',
    'Bemidji',
    'Crookston',
    'Saint Hilaire',
    'Goodridge',
    'Plummer',
    'Brooks',
    'Oklee',
    'Gonvick',
    'Clearbrook',
    'Shevlin',
    'Gully',
    'Trail',
    'Doniphan',
    'Maurepas',
    'Hillsboro',
    'East Norwich',
    'Millington',
    'Hope Hull',
    'Ferriday',
    'Saint Joseph',
    'Columbia',
    'Jonesville',
    'Vidalia',
    'Athens',
    'Sicily Island',
    'Ringgold',
    'Sikes',
    'Aurora',
    'Gunnison',
    'Curtice',
    'Graytown',
    'Bloomdale',
    'Walbridge',
    'Cygnet',
    'Cooperstown',
    'Hancock',
    'Middleburgh',
    'Stamford',
    'Pattersonville',
    'Howes Cave',
    'Cleveland',
    'Newton',
    'Reedsville',
    'Greenbrier',
    'Plumerville',
    'Morrilton',
    'Marshall',
    'Bee Branch',
    'Shirley',
    'La Cygne',
    'Udall',
    'Jamestown',
    'Kingfisher',
    'Blanchard',
    'Hennessey',
    'Longdale',
    'Hollis',
    'Apache',
    'Putnam',
    'Shattuck',
    'Okeene',
    'Mooreland',
    'Cheyenne',
    'Seiling',
    'Geary',
    'Baker',
    'Reserve',
    'Paulina',
    'Laquey',
    'Pomona',
    'Wall',
    'Hot Springs',
    'Philip',
    'Winner',
    'Custer',
    'Mission',
    'Manderson',
    'Reliance',
    'Faith',
    'Freeman',
    'Pine Ridge',
    'Kyle',
    'Martin',
    'Plankinton',
    'Gilman',
    'Industry',
    'New Ulm',
    'Carmine',
    'Ledbetter',
    'Carrollton',
    'Valley',
    'Dowelltown',
    'Woodbury',
    'Milton',
    'Watertown',
    'Liberty',
    'Gordonsville',
    'Hickman',
    'Altoona',
    'Lebo',
    'Westphalia',
    'LeRoy',
    'Waverly',
    'Washington',
    'New Church',
    'Cleveland',
    'Waseca',
    'Mapleton',
    'Parowan',
    'Carrington',
    'Cando',
    'Willapa',
    'Lake Providence',
    'Okolona',
    'McHenry',
    'Etta',
    'Mooreville',
    'Hickory Flat',
    'Blue Springs',
    'Belden',
    'Foxworth',
    'Greenwood Springs',
    'Sallis',
    'Rolling Fork',
    'Sarah',
    'Senatobia',
    'Schlater',
    'Indianola',
    'Pope',
    'Como',
    'Kilmichael',
    'Benoit',
    'Duck Hill',
    'Richton',
    'Purvis',
    'Buckatunna',
    'Sumrall',
    'Collins',
    'Brooklyn',
    'Vance',
    'Diamondhead',
    'Oak Island',
    'Princeton',
    'Morriston',
    'Hawthorne',
    'Mount Hermon',
    'Youngsville',
    'Waverly',
    'Tybee Island',
    'Lynch',
    'Cerulean',
    'Greenville',
    'Bush',
    'Braithwaite',
    'Arabi',
    'Concord',
    'Iron Station',
    'Fairview',
    'Benton',
    'Ashland',
    'Van Vleet',
    'Dennis',
    'Scottdale',
    'Brooks',
    'Morris',
    'Pegram',
    'Bon Aqua',
    'Dallas',
    'York',
    'Meansville',
    'Williamson',
    'Waco',
    'Zirconia',
    'Westville',
    'Leesville',
    'Rossville',
    'Semmes',
    'Montrose',
    'Juliette',
    'Pearl River',
    'Holden',
    'Slaughter',
    'Robert',
    'Rosepine',
    'Plains',
    'Orrville',
    'Violet',
    'Newborn',
    'Dolomite',
    'Ohatchee',
    'Moscow',
    'Somerville',
    'Nesbit',
    'Grayson',
    'Wade',
    'Pauline',
    'Campobello',
    'Gray',
    'Aquilla',
    'Virgie',
    'Crystal City',
    'Ottoville',
    'Lincolnville Center',
    'Santa Cruz',
    'Los Alamos',
    'Espanola',
    'Medanales',
    'Waitsfield',
    'Franklin',
    'Bristol',
    'Huntington',
    'Fairfield',
    'Browning',
    'Big Sky',
    'Glasgow',
    'Wolf Point',
    'Eureka',
    'Trego',
    'Fortine',
    'Malta',
    'Fishtail',
    'White Sulphur Springs',
    'Geraldine',
    'Moore',
    'Rayville',
    'Fullerton',
    'Cayuga',
    'Lisbon',
    'LaMoure',
    'Wishek',
    'Forman',
    'Lidgerwood',
    'Milnor',
    'Oakes',
    'Gwinner',
    'Ashley',
    'Forbes',
    'Havana',
    'Kulm',
    'Edgeley',
    'Dresden',
    'North Baltimore',
    'Dousman',
    'Mukwonago',
    'Hales Corners',
    'Marinette',
    'Miamiville',
    'South Vienna',
    'Wales',
    'North Prairie',
    'Cedar Grove',
    'Floral City',
    'Irvine',
    'Geneva',
    'Hartford',
    'Bradenton Beach',
    'Avon',
    'Warsaw',
    'Wayland',
    'Naples',
    'Atlanta',
    'Dale',
    'Pavilion',
    'Portage',
    'Lakeside',
    'Superior',
    'Wauchula',
    'Fruitland Park',
    'Poplar Branch',
    'Kenly',
    'Spring Lake',
    'Hamilton',
    'Columbia',
    'Farmville',
    'Howe',
    'Alum Bank',
    'Vienna',
    'Snow Hill',
    'Blanch',
    'Wapato',
    'Goldendale',
    'Tillamook',
    'Roseboro',
    'Salem',
    'Holly Hill',
    'Nashville',
    'Bayboro',
    'Lapel',
    'Freeport',
    'Erwin',
    'Henrico',
    'Pinetown',
    'Buffalo Lake',
    'South Whitley',
    'Norlina',
    'Bad Axe',
    'Somerset',
    'Springport',
    'Trabuco Canyon',
    'New Haven',
    'Williamstown',
    'Mokelumne Hill',
    'Niland',
    'Salton City',
    'Winterhaven',
    'Somerton',
    'Roll',
    'Franklin Park',
    'Greenvale',
    'Goldens Bridge',
    'Big Bear',
    'Eltopia',
    'Halstad',
    'Fisher',
    'Hillsboro',
    'Chokio',
    'Shelly',
    'Oak Ridge',
    'Ellsworth',
    'Girvin',
    'King City',
    'Hugo',
    'Idabel',
    'Gruver',
    'Carrier',
    'Henryetta',
    'Hillister',
    'Chandler',
    'Bellevue',
    'Findlay',
    'Rockport',
    'Lake Tomahawk',
    'Elcho',
    'Lake Lillian',
    'Custer',
    'Watertown',
    'Saint Bonifacius',
    'Mackinaw',
    'Sibley',
    'Meadview',
    'Narvon',
    'Springerville',
    'Houlka',
    'Akutan',
    'Kotlik',
    'Kiana',
    'Noorvik',
    'Eielson Air Force Base',
    'Woodburn',
    'Weiser',
    'West Liberty',
    'Cable',
    'Bellefontaine',
    'Newcastle',
    'Minco',
    'Luther',
    'Crescent',
    'Hugheston',
    'New Martinsville',
    'Sistersville',
    'Hooker',
    'Tyrone',
    'Booker',
    'Beaver',
    'Spearman',
    'Boise City',
    'Forgan',
    'Woodstock',
    'Richfield',
    'Mifflintown',
    'Millerstown',
    'Mount Pleasant Mills',
    'McClure',
    'Thompsontown',
    'Pleasant View',
    'Burns',
    'Kingston Springs',
    'Delco',
    'Caledonia',
    'Taylorsville',
    'Brighton',
    'Norco',
    'Raymond',
    'Lynchburg',
    'Calhoun',
    'Denton',
    'Gaston',
    'Boutte',
    'Madisonville',
    'Duson',
    'Culloden',
    'Fults',
    'Modoc',
    'Valmeyer',
    'Summerton',
    'Fort Bridger',
    'Bostwick',
    'Bowdoinham',
    'Chartley',
    'Ascutney',
    'North Springfield',
    'Orange',
    'North San Juan',
    'Evansdale',
    'Lander',
    'Tumacacori',
    'Tijeras',
    'Pine Grove',
    'Rimrock',
    'Barnesville',
    'Medina',
    'Ignacio',
    'Hubbard',
    'Hailey',
    'Carlsborg',
    'Firestone',
    'West Burlington',
    'Tesuque',
    'Grants',
    'Royal',
    'Sidney',
    'Edgewood',
    'Eagle Nest',
    'Kimberly',
    'Clarendon',
    'Tiona',
    'Sheffield',
    'Ludlow',
    'West Sand Lake',
    'South Paris',
    'Manlius',
    'Rogers',
    'Thorndale',
    'Buckholts',
    'Moody',
    'Gause',
    'Sterling',
    'Fairview',
    'West Branch',
    'San Martin',
    'Ocean Grove',
    'Wamsutter',
    'Mountain View',
    'West Point',
    'Malin',
    'Haslett',
    'Tignall',
    'Exeter',
    'Lake City',
    'Chimacum',
    'Townsend',
    'Ashton',
    'Dickinson',
    'Albion',
    'Lincoln',
    'Concordville',
    'Alden',
    'Bevington',
    'Giddings',
    'Kevil',
    'Little Valley',
    'Southborough',
    'Coal Valley',
    'Guernsey',
    'Moran',
    'Colville',
    'Columbus',
    'Angel Fire',
    'Ocate',
    'Saint Charles',
    'Perry',
    'Tunica',
    'Howard',
    'Sparta',
    'Santa Teresa',
    'Sanders',
    'Riley',
    'Victor',
    'Jackson',
    'Cimarron',
    'Milan',
    'Piedmont',
    'Manquin',
    'Melvindale',
    'Tonopah',
    'Bernalillo',
    'Douglas',
    'West Yellowstone',
    'Keller',
    'Vermillion',
    'Adel',
    'Mimbres',
    'Panaca',
    'Packwood',
    'Clyo',
    'Gaston',
    'Greenview',
    'Grove Hill',
    'Arlington',
    'Butler',
    'Oakwood',
    'Volcano',
    'Mitchell',
    'Gerlach',
    'New Hope',
    'Overton',
    'Mount Enterprise',
    'Warsaw',
    'Archbold',
    'Spring Hope',
    'Cumberland',
    'Prinsburg',
    'Roaring Spring',
    'Pioneer',
    'Buckingham',
    'Gibbon',
    'Burwell',
    'Arcadia',
    'Scotia',
    'Ravenna',
    'Lakewood',
    'Hill City',
    'Ransom',
    'Ord',
    'Laguna',
    'Vinita',
    'Elk Park',
    'Rotterdam Junction',
    'Coleman',
    'Corona del Mar',
    'Augusta',
    'Washington',
    'Lyons',
    'New Paris',
    'North Bangor',
    'Saint Regis Falls',
    'Trafalgar',
    'Blair',
    'Ganado',
    'Boardman',
    'Valle',
    'Center Barnstead',
    'Barnstead',
    'Lebanon',
    'Milton',
    'Smyer',
    'Lincolnton',
    'Saint Paul',
    'Maury City',
    'Crystal Springs',
    'Tennessee Ridge',
    'Richland',
    'Nashville',
    'Chamberino',
    'Rothsay',
    'New York Mills',
    'Pelican Rapids',
    'Locust',
    'Stanfield',
    'Arapahoe',
    'Oriental',
    'Bladenboro',
    'Rocky Point',
    'Swansboro',
    'Chadbourn',
    'Pink Hill',
    'Grantsboro',
    'Warsaw',
    'West Mansfield',
    'Jamestown',
    'Sophia',
    'Aberdeen',
    'Confluence',
    'Hainesport',
    'Brightwood',
    'Pleasantville',
    'Avella',
    'Gum Spring',
    'Garyville',
    'Warsaw',
    'Faber',
    'Port Trevorton',
    'Mount Holly',
    'Nephi',
    'Coldspring',
    'Grand Lake',
    'Burnham',
    'Bremo Bluff',
    'Belzoni',
    'Malvern',
    'Unity',
    'West Groton',
    'Loveville',
    'West Middlesex',
    'Leonia',
    'Kellerton',
    'Luxor',
    'New Johnsonville',
    'Venus',
    'Cambridge',
    'Youngstown',
    'Center Point',
    'Voorheesville',
    'Polk City',
    'Cardale',
    'Sybertsville',
    'Baldwin',
    'Fryburg',
    'Friars Point',
    'Glenville',
    'Charles City',
    'Aquasco',
    'Berlin',
    'Clyde Park',
    'Gallatin Gateway',
    'Emigrant',
    'West Tisbury',
    'Vansant',
    'Poquoson',
    'Woodland',
    'Ruckersville',
    'Elsa',
    'Crestline',
    'Biggs',
    'Pennsburg',
    'Broad Run',
    'Kingwood',
    'Fairless Hills',
    'Lincoln',
    'Stirling',
    'Mappsville',
    'Roseland',
    'Edgewater',
    'Pineville',
    'Buttonwillow',
    'Richlands',
    'Bucklin',
    'Washington Crossing',
    'Cumberland',
    'Alton',
    'Kenbridge',
    'Spout Spring',
    'Lawrenceville',
    'Halifax',
    'Enfield',
    'Oakwood',
    'Canton',
    'Buffalo',
    'Calumet',
    'Frederick',
    'Custer City',
    'Waynoka',
    'Ringwood',
    'Nash',
    'Comanche',
    'Halls',
    'Tiptonville',
    'Kenton',
    'Obion',
    'Mound City',
    'Luray',
    'Macon',
    'Forest City',
    'Green Castle',
    'Green City',
    'Ethel',
    'Hale',
    'New Cambria',
    'Excello',
    'Callao',
    'Huntsville',
    'Bosworth',
    'Shiloh',
    'Wilsonville',
    'Maylene',
    'Smiths',
    'Kremmling',
    'Point Reyes Station',
    'Wentworth',
    'Toronto',
    'Lake Benton',
    'Bradley',
    'Clear Lake',
    'Chester',
    'Castlewood',
    'Estelline',
    'Mililani Town',
    'Koloa',
    "Ha'iku",
    'Pikeville',
    'Spring City',
    'Mayer',
    'Palmer',
    'Collegedale',
    'Cloudcroft',
    'New Lebanon',
    'Dyer',
    'Blenheim',
    'Lovejoy',
    'Lattimore',
    'Mount Olive',
    'Lanai',
    'Sparkill',
    'Hamilton',
    'Sharon',
    'Norfolk',
    'Kittery Point',
    'Norwich',
    'Greenbush',
    'North Chatham',
    'East Orleans',
    'Wysox',
    'New Albany',
    'Lyle',
    'Connell',
    'Sunnyside',
    'Plummer',
    'Worley',
    'Tensed',
    'Stratford',
    'Doland',
    'Redfield',
    'Cresbard',
    'Cuthbert',
    'Buena Vista',
    'Barren Springs',
    'Willis',
    'Meadows of Dan',
    'Pilot',
    'Lewisburg',
    'Adairville',
    'Dunmor',
    'Westmoreland',
    'Dunnville',
    'Vine Grove',
    'Red Boiling Springs',
    'Hardin',
    'Alexandria',
    'Independence',
    'Okeana',
    'Union',
    'Walton',
    'Williamstown',
    'Fort Mitchell',
    'Sunman',
    'Steelville',
    'Davisville',
    'Linn',
    'Cuba',
    'Viburnum',
    'Dunkerton',
    'Crawfordsville',
    'Bellevue',
    'Dumont',
    'Allison',
    'Mediapolis',
    'Sioux Center',
    'Scranton',
    'Lansing',
    'Wapello',
    'Woodward',
    'Fontanelle',
    'Moville',
    'Atkins',
    'McGregor',
    'Wallingford',
    'Dysart',
    'Clarence',
    'Elkader',
    'Odebolt',
    'Readlyn',
    'Schaller',
    'Decorah',
    'Martelle',
    'Dexter',
    'Mount Sterling',
    'Rockford',
    'Postville',
    'Spencer',
    'Lawton',
    'Audubon',
    'Walcott',
    'Goodhue',
    'Sleepy Eye',
    'Hanska',
    'Springfield',
    'Exeland',
    'Hager City',
    'Mazeppa',
    'Welch',
    'Radisson',
    'Oronoco',
    'Stewart',
    'Tate',
    'Ducktown',
    'Alplaus',
    'Mocksville',
    'Harmony',
    'Hamptonville',
    'Efland',
    'Hot Springs Village',
    'Altamont',
    'Golden Eagle',
    'Milton',
    'Barry',
    'Killdeer',
    'New England',
    'Mott',
    'Scranton',
    'Rhame',
    'Hettinger',
    'Bigfoot',
    'Eustace',
    'Hickman',
    'Anderson',
    'Tehama',
    'Pipersville',
    'Hackberry',
    'Limestone',
    'Hazard',
    'Beulaville',
    'Nome',
    'Chugiak',
    'Kongiganak',
    'New Salem',
    'Powellsville',
    'Zullinger',
    'Ash Hill',
    'Courtland',
    'Nicollet',
    'Mogadore',
    'Evansville',
    'Culver',
    'Summerville',
    'North Waterboro',
    'Islesford',
    'Northeast Harbor',
    'Mars Hill',
    'Phelps',
    'Combs',
    'Waco',
    'Clay City',
    'Fayetteville',
    'Chappell Hill',
    'Somerville',
    'Medina',
    'Hammondsport',
    'Rush',
    'Marion',
    'Walworth',
    'Hamlin',
    'Smithville',
    'Glen Gardner',
    'University Park',
    'Cynthiana',
    'Fishel',
    'Holualoa',
    'Navasota',
    'Quitman',
    'Athens',
    'Gladewater',
    'Jacksonville',
    'Aurora',
    'Helena',
    'Daingerfield',
    'Blue Lake',
    'Magnolia',
    'New Haven',
    'Fairmont',
    'Foley',
    'Loxley',
    'Pelham',
    'Buchanan',
    'San Andreas',
    'La Grange',
    'Cayucos',
    'Clearlake Oaks',
    'Springville',
    'Piercy',
    'Smith River',
    'Colbert',
    'Julian',
    'Coward',
    'Unionville',
    'Deckerville',
    'Sandusky',
    'Snover',
    'Fairgrove',
    'Carsonville',
    'Deford',
    'Gagetown',
    'Applegate',
    'Rockport',
    'Falls of Rough',
    'Loretto',
    'Rineyville',
    'Alpha',
    'Lookout Mountain',
    'Soddy-Daisy',
    'Rossville',
    'Graysville',
    'Sale Creek',
    'Gypsum',
    'Armada',
    'Hanalei',
    'Hakalau',
    "La'ie",
    'Coupland',
    'Inez',
    'Chewelah',
    'Shaw Island',
    'Vulcan',
    'Alpha',
    'Powers',
    'Beach',
    'Enderlin',
    'Northwood',
    'Stanley',
    'Portal',
    'Lebanon',
    'Buckland',
    'Wapakoneta',
    'Avery',
    'Kalona',
    'Blackwell',
    'Mayer',
    'Mechanicville',
    'Slingerlands',
    'Plains',
    'Bailey',
    'Ashburn',
    'Randleman',
    'Orangeville',
    'Helper',
    'Price',
    'Wellington',
    'East Carbon City',
    'Huntington',
    'Haynesville',
    'Potosi',
    'Choudrant',
    'Jena',
    'Keithville',
    'Center',
    'Port Aransas',
    'Elm Grove',
    'Lamar',
    'Wiley',
    'Smithville',
    'Wray',
    'Black River Falls',
    'Platteville',
    'Pottsville',
    'Genoa',
    'Galena',
    'Deville',
    'Wedowee',
    'Dardanelle',
    'Gould',
    'Wilmar',
    'Mountain View',
    'Hodges',
    'Carrollton',
    'Lincoln',
    'Westby',
    'Sun River',
    'Joplin',
    'Hays',
    'Absarokee',
    'Choteau',
    'Dupuyer',
    'Shelby',
    'Valier',
    'Culbertson',
    'Box Elder',
    'Kevin',
    'Columbus Grove',
    'Fort Jennings',
    'Garden Prairie',
    'Orfordville',
    'Vincent',
    'Columbia Cross Roads',
    'Lawrenceville',
    'Mainesburg',
    'Prattsburgh',
    'Gillett',
    'Millerton',
    'Burdett',
    'Lodi',
    'Platte',
    'Summit',
    'Lester',
    'Beresford',
    'Scotland',
    'Alcester',
    'Rock Valley',
    'Lake Preston',
    'Valley Springs',
    'North Lemmon',
    'Salem',
    'Emery',
    'Stewardson',
    'Bulpitt',
    'Shelbyville',
    'Beecher City',
    'Nokomis',
    'Kincaid',
    'Blue Mound',
    'Irving',
    'Taylor Springs',
    'Dow',
    'Grafton',
    'Verona',
    'Seven Mile',
    'Darien',
    'Townsend',
    'Forest',
    'Dolores',
    'De Beque',
    'Hesperus',
    'Strafford',
    'Mills',
    'Saratoga',
    'Hiawassee',
    'Suches',
    'Morganton',
    'Farmington',
    'Rosburg',
    'Centerview',
    'Parkston',
    'Morristown',
    'Ipswich',
    'Stickney',
    'Doon',
    'Pickstown',
    'Unionville',
    'Eagleville',
    'Chapel Hill',
    'Gill',
    'Cripple Creek',
    'Captain Cook',
    'Columbia Falls',
    'Bigfork',
    'Libby',
    'Strong',
    'Shelby',
    'Jonestown',
    'Edcouch',
    'Hydro',
    'West Columbia',
    'Humboldt',
    'Union',
    'Bushland',
    'Wink',
    'Hindsville',
    'Elkins',
    'Garfield',
    'Cedar Glen',
    'Wrightwood',
    'Clearfield',
    'Juniata',
    'Minden',
    'Kenesaw',
    'Giltner',
    'Axtell',
    'Glenvil',
    'Douglas',
    'Rosemary Beach',
    'Awendaw',
    'Sebring',
    'Adak',
    'Phelps',
    'Clifton Springs',
    'Ovid',
    'Belvidere',
    'Wenham',
    'Caledonia',
    'Warrenton',
    'Urbanna',
    'Mount Angel',
    'Maypearl',
    'China Springs',
    'Declo',
    'Downey',
    'Bancroft',
    'Grace',
    'Montpelier',
    'Paris',
    'Morning View',
    'Knightstown',
    'Greenwich',
    'Selkirk',
    'Humphrey',
    'Medina',
    'Cleveland',
    'Valley City',
    'Grace City',
    'Langley',
    'Point Roberts',
    'Freeland',
    'Shiocton',
    'Clintonville',
    'Weyauwega',
    'Monroe',
    'Van Dyne',
    'Rosendale',
    'Brandon',
    'Wahoo',
    'Kimball',
    'Monteagle',
    'Coalmont',
    'Sewanee',
    'Garysburg',
    'Greenbackville',
    'Laurel Springs',
    'Unorganized Territory of Camp Lejeune',
    'Guadalupe',
    'Port Edwards',
    'Arpin',
    'Laneville',
    'Oakhurst',
    'Rye',
    'Outlook',
    'Plentywood',
    'Poplar',
    'Saint Marie',
    'Medicine Lake',
    'Nashua',
    'Gate City',
    'Chelan Falls',
    'Alpha',
    'Danbury',
    'Laurens',
    'Alta',
    'Blencoe',
    'Le Mars',
    'Hawarden',
    'Salix',
    'Soldier',
    'Jefferson',
    'Annandale',
    'Cologne',
    'Inverness',
    'Ackerman',
    'Roxie',
    'Meadville',
    'Bude',
    'Hermanville',
    'Pinola',
    'Pattison',
    'Summit',
    'Ixonia',
    'Fellsmere',
    'Talent',
    'South Canaan',
    'Lake Ariel',
    'East Waterboro',
    'Waterboro',
    'Sipsey',
    'Paradise',
    'Newark',
    'Portland',
    'Steele',
    'Marion',
    'Brooklet',
    'Granger',
    'Madrid',
    'Neoga',
    'Freeburg',
    'Hebron',
    'Bryant Pond',
    'Sumner',
    'Groveton',
    'Bethel',
    'Thorndike',
    'Vassalboro',
    'Readfield',
    'South China',
    'Turner',
    'Ocean Park',
    'Walker',
    'Brittany',
    'Oxford',
    'Saint Charles',
    'Kent City',
    'Montrose',
    'Oxford',
    'Milner',
    'Poynette',
    'Deerwood',
    'Benson',
    'Montgomery',
    'Saint James',
    'Grafton',
    'Northbridge',
    'Boylston',
    'North Oxford',
    'Rochdale',
    'Barre',
    'Cloverdale',
    'Lakeview',
    'Reedsport',
    'Waterford',
    'Escalon',
    'Toppenish',
    'Sunrise Beach',
    'Whiteman Air Force Base',
    'Linden',
    'La Crescent',
    'Carolina Beach',
    'Hamilton',
    'Chauvin',
    'Livingston',
    'Albany',
    'Keno',
    'Dryden',
    'Medford',
    'Curtiss',
    'Manawa',
    'Edgar',
    'Landrum',
    'Duncan',
    'Clifton',
    'Boling',
    'Van Vleck',
    'Pleasant Garden',
    'Salem',
    'Centerburg',
    'Little River-Academy',
    'Trumbull',
    'Palmer',
    'Clarks',
    'Minturn',
    'Mountain Iron',
    'Hovland',
    'Oakham',
    'Alma',
    'Mulberry',
    'Tucker',
    'Onalaska',
    'Henrietta',
    'Doyline',
    'Kamas',
    'Garden City',
    'Coalville',
    'Laketown',
    'Henefer',
    'Cokeville',
    'Randolph',
    'Oakley',
    'Endeavor',
    'Smithfield',
    'Lyndhurst',
    'Kenansville',
    'Coats',
    'Ivanhoe',
    'Godwin',
    'Council',
    'Sharon',
    'Comfort',
    'Zephyr',
    'Early',
    'Lohn',
    'Paint Rock',
    'Wilseyville',
    'Saint Marys',
    'Cecil',
    'Lorenzo',
    'Abernathy',
    'Ralls',
    'Shallowater',
    'Sun Valley',
    'Goodview',
    'Hopedale',
    'Rowley',
    'Elmira',
    'Ubly',
    'Mancelona',
    'Caseville',
    'Port Austin',
    'Mantachie',
    'Fulton',
    'Golden',
    'Whitefield',
    'Crowder',
    'Checotah',
    'Webbers Falls',
    'Town of East Lyme',
    'Oneco',
    'Andover',
    'Alvordton',
    'Reno',
    'Killbuck',
    'Cloverdale',
    'Mendon',
    'New Straitsville',
    'Abilene',
    'Elmdale',
    'Cambria',
    'Santa Margarita',
    'Avila Beach',
    'Pentress',
    'Fairview',
    'Mountville',
    'Oley',
    'Kirbyville',
    'Richland',
    'Leonard',
    'McIntosh',
    'Farwell',
    'Doylestown',
    'Opdyke',
    'Broughton',
    'Enfield',
    'Sisters',
    'Muldoon',
    'Kensington',
    'Le Sueur',
    'West Corinth',
    'North Pomfret',
    'Grafton',
    'Cuttingsville',
    'Danby',
    'West Topsham',
    'Middletown Springs',
    'Bradford',
    'Belmont',
    'Mount Holly',
    'Strafford',
    'Bridgewater Corners',
    'Lynchburg',
    'Leakesville',
    'Neely',
    'Pequot Lakes',
    'Clearwater',
    'Pleasant Plain',
    'Goshen',
    'Marblehill',
    'Union City',
    'New Castle',
    'Clifton',
    'Collinwood',
    'Granton',
    'Necedah',
    'Cory',
    'Lyndeborough',
    'Port Byron',
    'Montezuma',
    'Savannah',
    'Lancaster',
    'Cypress Inn',
    'Gilbertown',
    'Cordova',
    'Atqasuk',
    'Buckland',
    'Shungnak',
    'Kipnuk',
    'Toksook Bay',
    'Chefornak',
    'Akiachak',
    'Unalakleet',
    'Sutton',
    'Dutch Harbor',
    'Unalaska',
    'Gervais',
    'Colfax',
    'Edwards Air Force Base',
    'Golden',
    'Sutter',
    'La Prairie',
    'Warsaw',
    'Payson',
    'Palmyra',
    'Canton',
    'Timewell',
    'Plainville',
    'Paloma',
    'Liberty',
    'Burt',
    'Alba',
    'Glen Arbor',
    'Frankfort',
    'Camden',
    'Hamburg',
    'Tygh Valley',
    'Arlington',
    'Hood River',
    'Battle Lake',
    'Dalton',
    'Vining',
    'Anchor Point',
    'Crooks',
    'Alexandria',
    'Garretson',
    'Worthing',
    'Park River',
    'Bathgate',
    'Lakota',
    'Neche',
    'Pembina',
    'Cavalier',
    'Edmore',
    'Fairdale',
    'Mayville',
    'Edinburg',
    'Rolla',
    'Kramer',
    'Mexico',
    'Orrington',
    'Surry',
    'Sullivan',
    'Midvale',
    'Cambridge',
    'Council',
    'Challis',
    'Salmon',
    'Hamer',
    'Terreton',
    'Bluford',
    'Dix',
    'WaKeeney',
    'Hillsboro',
    'Peabody',
    'Edson',
    'Saint Francis',
    'Pawcatuck',
    'Braddock',
    'Goshen',
    'Indian Rocks Beach',
    'Garfield',
    'Brandon',
    'Graceville',
    'Madison',
    'Palacios',
    'Blessing',
    'Edna',
    'Creede',
    'Evans',
    'Gary',
    'Fort Calhoun',
    'Wayne',
    'Pender',
    'Laurel',
    'Tekamah',
    'Bassett',
    'Walthill',
    'Carroll',
    'Albany',
    'Keyser',
    'Franklin',
    'Staunton',
    'Worden',
    'Hamel',
    'Livingston',
    'Sorento',
    'Dorsey',
    'Mosca',
    'Crestone',
    'Washington',
    'Liberty',
    'Freedom',
    'Brooks',
    'Palermo',
    'Morrill',
    'Odin',
    'Jetmore',
    'Florala',
    'Laurel Hill',
    'Norman',
    'Weiner',
    'Hymera',
    'Lizton',
    'Glen Ellen',
    'Stonyford',
    'Hawaiian Ocean View',
    'Toccoa Falls',
    'South Pekin',
    'East Galesburg',
    'Montrose',
    'Hudson',
    'Levant',
    'Kenduskeag',
    'Carmel',
    'Plymouth',
    'West Enfield',
    'Portland',
    'Urbana',
    'Lagro',
    'Maxwell',
    'Hyannis',
    'Brewster',
    'Curtis',
    'Brady',
    'Mullen',
    'Whitman',
    'Anselmo',
    'Wallace',
    'Merna',
    'Olton',
    'Morton',
    'Fayetteville',
    'Davis Junction',
    'Steward',
    'Esmond',
    'New Kent',
    'Cochiti Lake',
    'Mesilla',
    'Peralta',
    'Green Valley',
    'Monroe',
    'Richfield',
    'Coleraine',
    'Esko',
    'Springfield',
    'Airway Heights',
    'Fort Madison',
    'Eveleth',
    'Geneva',
    'Dover',
    'Thonotosassa',
    'Universal City',
    'Hermitage',
    'Smackover',
    'Banks',
    'Evergreen',
    'Yorkshire',
    'Snyder',
    'Geronimo',
    'Faxon',
    'Rural Ridge',
    'Russellton',
    'Patterson',
    'Johnson City',
    'Carney',
    'Daggett',
    'Perronville',
    'Foster City',
    'Marenisco',
    'Drummond',
    'Toivola',
    'Rebersburg',
    'Centre Hall',
    'Nashville',
    'Sun City',
    'Isabel',
    'Sawyer',
    'Wilmore',
    'Mullinville',
    'Coldwater',
    'Green River',
    'Cleveland',
    'Elmo',
    'Sunnyside',
    'Sandia Park',
    'Webb',
    'Sanborn',
    'Tulia',
    'Silverton',
    'Claude',
    'Gail',
    'Post',
    'Molalla',
    'Sharon Center',
    'Sunset',
    'Taft',
    'Tigerville',
    'Zebulon',
    'Denmark',
    'Vanleer',
    'Middleton',
    'Richfield',
    'Black Canyon City',
    'Dexter',
    'Hoodsport',
    'Medford',
    'Dundas',
    'Sutherlin',
    'De Soto',
    'Warren',
    'Estacada',
    'Pleasant Unity',
    'Mammoth',
    'Albers',
    'West Alton',
    'Gorham',
    'Smithton',
    'Bonnie',
    'Haxtun',
    'Rosebud',
    'North Tazewell',
    'Kula',
    'Clontarf',
    'Rangely',
    'Peshtigo',
    'Ironton',
    'Honaunau',
    'Rivesville',
    "Na'alehu",
    'Oracle',
    'Louisville',
    'Wibaux',
    'Dimmitt',
    'Lamar',
    'Union',
    'Maben',
    'Stephenson',
    'Coloma',
    'Boyne City',
    'Darlington',
    'Falconer',
    'Leola',
    'Shirleysburg',
    'Orlinda',
    'Ozan',
    'Bivins',
    'Rockfield',
    'Salix',
    'Badger',
    'Clarksville',
    'Terril',
    'North English',
    'Milford',
    'Sioux Rapids',
    'Marathon',
    'Springville',
    'Walford',
    'Indianola',
    'Bryant',
    'Villisca',
    'Elkhart',
    'Elk Horn',
    'Radcliffe',
    'Pacific Junction',
    'Robins',
    'Bronson',
    'Hornick',
    'Baxter',
    'Columbus Junction',
    'Mount Pleasant',
    'Sully',
    'Oakville',
    'Ainsworth',
    'Lake View',
    'Emerson',
    'Imogene',
    'Walnut',
    'Ringsted',
    'Riverside',
    'Stuart',
    'Calamus',
    'Clare',
    'Gowrie',
    'Reinbeck',
    'Woden',
    'Hopkins',
    'Sibley',
    'Long Grove',
    'Mechanicsville',
    'Panora',
    'Dolliver',
    'Yale',
    'Harlan',
    'Monona',
    'Stratford',
    'Guthrie Center',
    'Elgin',
    'Nazan Village',
    'Levelock',
    'New Stuyahok',
    'Tuntutuliak',
    'Mekoryuk',
    'Goodnews Bay',
    'Russian Mission',
    'Nondalton',
    'Kalskag',
    'Alakanuk',
    'Pedro Bay',
    'Hooper Bay',
    'Naknek',
    'Kwethluk',
    'Chevak',
    'Pilot Station',
    'Manokotak',
    'Scammon Bay',
    'Ardmore',
    'Evergreen',
    'Rising Fawn',
    'Penrose',
    'Brevard',
    'Rosman',
    'Sneedville',
    'Arthur',
    'Witt',
    'Edinburg',
    'Strasburg',
    'Oakland',
    'Stonington',
    'Lerna',
    'Afton',
    'Silver Lake',
    'Warm Springs',
    'Powell Butte',
    'Galena',
    'Sand Point',
    'Teller',
    'Sulphur',
    'Payette',
    'Casanova',
    'DeWitt',
    'Harvard',
    'Sutton',
    'Eagle',
    'Puryear',
    'Bardwell',
    'Arlington',
    'Wickliffe',
    'Clinton',
    'La Center',
    'Barlow',
    'Weeping Water',
    'Murray',
    'Cook',
    'Murdock',
    'Hickman',
    'Shickley',
    'Tribune',
    'Bates City',
    'Antlers',
    'Quapaw',
    'City of Picher (historical)',
    'Saint John',
    'Elm City',
    'Holland',
    'Chico',
    'Lebanon',
    'Biscoe',
    'Deep Run',
    'Grandview',
    'Camden',
    'Holmesville',
    'La Crosse',
    'North Garden',
    'Stuart',
    'Monroe',
    'Barboursville',
    'Holt',
    'Richards',
    'Benson',
    'Starke',
    'Shipshewana',
    'Avon Park',
    'Lone Jack',
    'Pantego',
    'Fischer',
    'Sachse',
    'Bantam',
    'Modena',
    'Shelter Island',
    'Orient',
    'Tappan',
    'Greenwood Lake',
    'Cold Spring',
    'Wood-Ridge',
    'Greenport',
    'Shelter Island Heights',
    'Perrineville',
    'Clarksburg',
    'Harrington Park',
    'Thiells',
    'Aquebogue',
    'Briarcliff Manor',
    'White Marsh',
    'Rome',
    'Walker',
    'Llano',
    'Somis',
    'Lenore',
    'Culdesac',
    'Bumpus Mills',
    'Juliaetta',
    'Peck',
    'Cottonwood',
    'Lake Shasta',
    'Le Grand',
    'Lake',
    'Molt',
    'Park City',
    'Hereford',
    'Hudson',
    'Waverly',
    'Kelley',
    'Kennebec',
    'Jordan Valley',
    'Georgetown',
    'Tabernash',
    'Rush Hill',
    'Hay Springs',
    'Bowling Green',
    'Alachua',
    'Creswell',
    'Blanchard',
    'Remus',
    'Penrose',
    'Kykotsmovi Village',
    'Bear',
    'Rociada',
    'Powell',
    'Orchard',
    'Meadow Grove',
    'Clearwater',
    'Watts',
    'Akron',
    'Winston',
    'Madison',
    'Tilden',
    'Delmar',
    'Pirtleville',
    'Vernal',
    'Greensboro',
    'Richeyville',
    'Waynesburg',
    'Holbrook',
    'Mount Morris',
    'Bobtown',
    'Garards Fort',
    'Rockwell',
    'Kannapolis',
    'Turbotville',
    'Adrian',
    'Ringgold',
    'Mayport',
    'East Brady',
    'Randolph',
    'Clymer',
    'Celoron',
    'Frewsburg',
    'Gerry',
    'Bemus Point',
    'Madison',
    'Geneva',
    'New Boston',
    'Simms',
    'Hughes Springs',
    'Redwater',
    'Eastview',
    'Elk Horn',
    'Summersville',
    'Burnside',
    'Science Hill',
    'Eubank',
    'Brodhead',
    'Flat Lick',
    'Bonnyman',
    'East Bernstadt',
    'Plummers Landing',
    'Tollesboro',
    'Grayson',
    'Winterville',
    'Menlo',
    'Granite Quarry',
    'Ray City',
    'Olive Hill',
    'Tiline',
    'South Shore',
    'Milford',
    'Cross Plains',
    'Montague',
    'Baird',
    'Hemphill',
    'Palmyra',
    'Elkhart',
    'Centerville',
    'Lyerly',
    'Talking Rock',
    'Good Hope',
    'Nashville',
    'High Springs',
    'Barnesville',
    'Millsboro',
    'Landis',
    'Gordon',
    'Cochran',
    'Haddock',
    'Irwinton',
    'Cameron',
    'Marshville',
    'Peachland',
    'Nucla',
    'Pine City',
    'Grangeville',
    'Stites',
    'New Laguna',
    'Penasco',
    'El Prado',
    'Hatch',
    'Deadwood',
    'Scenery Hill',
    'Paxton',
    'Wing',
    'Marienthal',
    'East Chatham',
    'Westfield',
    'Limington',
    'Mattawamkeag',
    'West Baldwin',
    'Fryeburg',
    'Danville',
    'Cornell',
    'Ransom',
    'Waitsburg',
    'Carbonado',
    'Flintstone',
    'Fort Oglethorpe',
    'Etowah',
    'Robbins',
    'Posen',
    'Umatilla',
    'Yalaha',
    'Hebron',
    'Snow Hill',
    'Felton',
    'Hartly',
    'Unionville',
    'Colver',
    'Negley',
    'Sycamore',
    'Northern Cambria',
    'Everett',
    'Nanty Glo',
    'Spreckels',
    'Cowansville',
    'Portage',
    'Hyndman',
    'Dravosburg',
    'Keswick',
    'Rohrersville',
    'Hometown',
    'McRae',
    'Lookout Mountain',
    'La Center',
    'Newman Lake',
    'Elmira',
    'Greenock',
    'Friendsville',
    'Washingtonville',
    'Glassport',
    'Medinah',
    'Beech Island',
    'Lotus',
    'West Newton',
    'New Palestine',
    'Fairland',
    'Ray Center',
    'El Granada',
    'Woodbridge',
    'Nine Mile Falls',
    'Plumville',
    'Carrolltown',
    'Lansing',
    'Blairs',
    'Arnold',
    'Hathaway Pines',
    'Derby Center',
    'Heath Springs',
    'Kershaw',
    'Van Wyck',
    'Catawba',
    'Kaiser',
    'Terrebonne',
    'Spavinaw',
    'Jay',
    'Broken Bow',
    'Bethel',
    'Annabella',
    'Elsinore',
    'Dugway',
    'Elberta',
    'Centerfield',
    'Wendover',
    'Valliant',
    'Springer',
    'Calera',
    'Kansas',
    'Ona',
    'Calabash',
    'Shallotte',
    'Sunset Beach',
    'Ocean Isle Beach',
    'Ash',
    'Woodville',
    'Centuria',
    'Milltown',
    'Glenwood City',
    'Logan',
    'McAlister',
    'Des Moines',
    'Branson',
    'Clayton',
    'Farwell',
    'Woodland',
    'Clarksville',
    'Mulliken',
    'Ochelata',
    'Talala',
    'Noel',
    'Miller',
    'South West City',
    'Orlando',
    'Whitewright',
    'Mescalero',
    'Tularosa',
    'Low Moor',
    'Caldwell',
    'Cheney',
    'Attica',
    'Vail',
    'Munising',
    'Ontonagon',
    'Paradise',
    'Celina',
    'Latham',
    'Elk Falls',
    'Rosalia',
    'Piedmont',
    'Boonville',
    'Myton',
    'Roosevelt',
    'Fort Duchesne',
    'Meeker',
    'Duchesne',
    'Topton',
    'Bechtelsville',
    'Banquete',
    'Terlton',
    'Clay Center',
    'Milford',
    'Cody',
    'Frannie',
    'Ten Sleep',
    'Greybull',
    'Basin',
    'Lovell',
    'Richland',
    'Dubois',
    'John Day',
    'Ione',
    'Fish Haven',
    'Arbon',
    'Holland Patent',
    'Gilmer',
    'Harleton',
    'Avinger',
    'Ore City',
    'Winnsboro',
    'Orwell',
    'Benson',
    'Macomb',
    'Gray',
    'Worland',
    'Newcastle',
    'Ashland',
    'Midwest',
    'Thermopolis',
    'Albin',
    'Busby',
    'Grady',
    'Lapine',
    'Highland Home',
    'Ragland',
    'Knightsville',
    'Laverne',
    'Buchanan',
    'Golden',
    'Campbell',
    'Scroggins',
    'Mount Vernon',
    'Craig',
    'Bogard',
    'Hurdland',
    'La Plata',
    'Gorin',
    'Edina',
    'Clearmont',
    'Ellington',
    'Wheatland',
    'Weaubleau',
    'Skidmore',
    'Portland',
    'Kennard',
    'Arlington',
    'Sanderson',
    'Fleming',
    'Mineral Springs',
    'Bradley',
    'Foreman',
    'Tatum',
    'Halltown',
    'Freeman',
    'Rantoul',
    'Ash Grove',
    'Lewisville',
    'Chelsea',
    'Grundy Center',
    'Gladbrook',
    'Malcom',
    'Garwin',
    'Wellsburg',
    'Montour',
    'New Sharon',
    'Edgewood',
    'Central City',
    'Masonville',
    'Greeley',
    'Hopkinton',
    'Arlington',
    'Walker',
    'Ryan',
    'Dundee',
    'Lamont',
    'Sheldahl',
    'Monroe',
    'Washington',
    'Cresco',
    'Lime Springs',
    'New Hampton',
    'Lawler',
    'Peel',
    'Spring Arbor',
    'Gilcrest',
    'Alamosa',
    'Walsenburg',
    'Evansville',
    'Nelson',
    'Villard',
    'Marietta',
    'Bellingham',
    'Holloway',
    'Monette',
    'Marked Tree',
    'Brookland',
    'Waterloo',
    'Gray Court',
    'Powhatan Point',
    'Eagle Springs',
    'Harrah',
    'Zillah',
    'Mabton',
    'Murchison',
    'Overton',
    'North Bonneville',
    'Felda',
    'Henry',
    'Fork Union',
    'Dry Fork',
    'Arvonia',
    'Ferrum',
    'Claudville',
    'Monticello',
    'Mercersburg',
    'Hustontown',
    'Blairs Mills',
    'Landisburg',
    'Loysville',
    'Newburg',
    'Shade Gap',
    'Three Springs',
    'Biglerville',
    'Aspers',
    'Littlestown',
    'Milroy',
    'Mount Holly Springs',
    'Boiling Springs',
    'Gardners',
    'Ickesburg',
    'Ayden',
    'Kentland',
    'Trade',
    'Hesston',
    'Caryville',
    'Shalimar',
    'Watauga',
    'Wolcott',
    'Cromwell',
    'North Judson',
    'Earl Park',
    'Leesburg',
    'Grovertown',
    'Corunna',
    'Kewanna',
    'Walstonburg',
    'Scotland Neck',
    'Grand Ronde',
    'Sheridan',
    'Critz',
    'Stanley',
    'Burkeville',
    'Bland',
    'Victoria',
    'Parrottsville',
    'Telford',
    'Shady Cove',
    'Lincoln City',
    'Quilcene',
    'Lake Placid',
    'Mayodan',
    'Morocco',
    'Berne',
    'Vass',
    'Estill',
    'Bailey',
    'Macclesfield',
    'Four Oaks',
    'Windsor',
    'Chocowinity',
    'Arcadia',
    'Orrtanna',
    'Lemasters',
    'Iredell',
    'Alma',
    'Ozawkie',
    'Eskridge',
    'Fort Riley-Camp Whiteside',
    'Lyndon',
    'Vassar',
    'Osage City',
    'Loose Creek',
    'Wellington',
    'Middleport',
    'Attica',
    'Whitesboro',
    'Preble',
    'Angola',
    'Derby',
    'West Falls',
    'Warners',
    'Nedrow',
    'Mexico',
    'Hartwick',
    'Montour Falls',
    'Angelica',
    'Campbell',
    'Olean',
    'Machias',
    'Ogunquit',
    'Cape Neddick',
    'York Beach',
    'Bolivar',
    'Depew',
    'Dunkirk',
    'Gasport',
    'Waterloo',
    'Arcade',
    'Springville',
    'Lake View',
    'Eden',
    'Boston',
    'Corinth',
    'Lake Luzerne',
    'Canajoharie',
    'Chestertown',
    'Pottersville',
    'Schuylerville',
    'Skaneateles',
    'Brewerton',
    'Fayetteville',
    'Cazenovia',
    'Tully',
    'LaFayette',
    'Montgomery',
    'Fort Montgomery',
    'Highland Falls',
    'Palmyra',
    'Kingfield',
    'Kerhonkson',
    'Woodridge',
    'Shandaken',
    'Woodbourne',
    'Mount Upton',
    'Candor',
    'Livermore Falls',
    'Campton Upper Village',
    'Smithfield',
    'Castile',
    'Cowlesville',
    'Blodgett Mills',
    'Winthrop',
    'Belgrade',
    'Mount Vernon',
    'Conway',
    'Madison',
    'Des Allemands',
    'Paradis',
    'Lafitte',
    'Ama',
    'Higley',
    'Manville',
    'Little Compton',
    'West Suffield',
    'Stafford Springs',
    'West Kingston',
    'Newport Coast',
    'Silverado',
    'Capistrano Beach',
    'Jamul',
    'Lincoln',
    'Slatersville',
    'Pascoag',
    'West Greenwich',
    'Greenville',
    'Mapleville',
    'Oakland',
    'Jamestown',
    'Marion',
    'Quinton',
    'West Point',
    'Lanexa',
    'Lutcher',
    'Gramercy',
    'Achilles',
    'Kiefer',
    'Charlestown',
    'Meraux',
    'Gold Canyon',
    'Treynor',
    'Prospect',
    'Batesburg',
    'Latta',
    'Townville',
    'Haughton',
    'Little Rock',
    'Effingham',
    'Timmonsville',
    'Edwards',
    'Mize',
    'Lake',
    'Collinsville',
    'Kokomo',
    'Iroquois',
    'Bellevue',
    'Thornton',
    'Commerce',
    'Waterford Works',
    'Kingsville',
    'Gunpowder',
    'West River',
    'Libertytown',
    'White Plains',
    'Woodsboro',
    'Sparrows Point',
    'Manchester',
    'Perry Hall',
    'Gerrardstown',
    'Sparks',
    'Selbyville',
    'Fenwick Island',
    'Freeland',
    'North Beach',
    'Placida',
    'Lawnside',
    'Castalian Springs',
    'Port Tobacco',
    'Eight Mile',
    'Cedarville',
    'Crosswicks',
    'Magnolia',
    'Montclair',
    'Mantoloking',
    'Rio Grande',
    'Delmont',
    'Lakehurst',
    'Barrington',
    'Stockton',
    'Titusville',
    'Pedricktown',
    'West Creek',
    'Sewaren',
    'Tennent',
    'Ocean Gate',
    'Margate City',
    'Longport',
    'Monroeville',
    'Newport',
    'Leesburg',
    'Riverview',
    'Oakland Estates Mobile Home Park',
    'Jerome',
    'Bangor',
    'Douglas',
    'Michigan Center',
    'Cement City',
    'East China Township',
    'Garwood',
    'Cape May Point',
    'Great Meadows',
    'South Seaville',
    'Rising Sun',
    'Elberton',
    'Dearing',
    'Appling',
    'Blythe',
    'Rockvale',
    'Waverly',
    'Decherd',
    'Andrews Air Force Base',
    'Cheltenham',
    'Delmar',
    'Nokesville',
    'Bel Alton',
    'Lisbon',
    'Ellabell',
    'Crescent',
    'Springfield',
    'Guyton',
    'Ledbetter',
    'Cross Plains',
    'West Paducah',
    'Chapmansboro',
    'Lynchburg',
    'Greenbrier',
    'Charlotte',
    'Dixon Springs',
    'Cunningham',
    'Benton',
    "Sullivan's Island",
    'Savage',
    'Baldwin',
    'Longboat Key',
    'Boca Grande',
    'Zieglersville',
    'Ringgold',
    'Blue Ridge',
    'McGaheysville',
    'Raphine',
    'Amo',
    'Aroma Park',
    'Headland',
    'Youngstown',
    'Inglis',
    'Vonore',
    'Oldfort',
    'Ocoee',
    'Apison',
    'Delano',
    'Englewood',
    'Rock Spring',
    'South Rockwood',
    'Scotts',
    'Demopolis',
    'Sheffield',
    'Montverde',
    'The Villages',
    'Mount Vernon',
    'Hindman',
    'Hallie',
    'Kite',
    'Carrie',
    'Dema',
    'Pine Top',
    'Wayland',
    'Soldiers Grove',
    'Nelson',
    'Amherst Junction',
    'Humbird',
    'Blair',
    'Frederic',
    'Ettrick',
    'Trempealeau',
    'Bruce',
    'Ladysmith',
    'Saint Henry',
    'Oakwood',
    'Kalida',
    'Kenedy',
    'Mackinac Island',
    'Curtis',
    'Grenville',
    'Trementina',
    'Louin',
    'Soso',
    'Pelahatchie',
    'Pittsburg',
    'Climax',
    'Haysi',
    'Wetmore',
    'Soldier',
    'Netawaka',
    'Fairview',
    'Corning',
    'Holton',
    'Cuba',
    'Narka',
    'Mahaska',
    'Barnes',
    'Haddam',
    'Havensville',
    'Mayetta',
    'Valley Falls',
    'Brent',
    'Duanesburg',
    'Cumby',
    'Big Sandy',
    'Cooper',
    'Hoboken',
    'Garland',
    'Monson',
    'Medora',
    'Eldred',
    'Hillview',
    'New Berlin',
    'Allen',
    'Porcupine',
    'Edgemont',
    'New Underwood',
    'Kadoka',
    'Gregory',
    'Windsor',
    'Great Bend',
    'Brackney',
    'Harpursville',
    'Kirkwood',
    'Montezuma',
    'Ashland',
    'Cimarron',
    'Spearville',
    'Ford',
    'Copeland',
    "L'Anse",
    'Chassell',
    'Crystal Falls',
    'Petersburg',
    'Morenci',
    'Lone Oak',
    'Corinth',
    'Bridgewater',
    'Killen',
    'Leetonia',
    'Russiaville',
    'Green Lane',
    'Richlandtown',
    'Flourtown',
    'Sellersville',
    'Red Hill',
    'Buckingham Township',
    'Swarthmore',
    'Point Pleasant',
    'Odessa',
    'Merion',
    'Clayton',
    'Union Bridge',
    'Silverdale',
    'Mexico',
    'Oswego',
    'Concordia',
    'Weleetka',
    'Danbury',
    'Meade',
    'Choctaw',
    'Erie',
    'Dunn Loring',
    'Meriden',
    'Carbondale',
    'Kechi',
    'Yates Center',
    'Iola',
    'Piqua',
    'Pea Ridge',
    'Auburn',
    'Rose Hill',
    'Buffalo',
    'Ketchum',
    'Bent Mountain',
    'Toano',
    'Hope',
    'Rio Verde',
    'East Hartland',
    'Ashaway',
    'Low and Burbanks Grant',
    'Pine River',
    'New London',
    'Spicer',
    'Park Rapids',
    'Canyonville',
    'Cedar Bluff',
    'Nauvoo',
    'Lewis',
    'Waverly',
    'South Barre',
    'Carrollton',
    'Millbury',
    'Kiln',
    'Church Point',
    'Mishicot',
    'Junction City',
    'Windsor',
    'Pacolet',
    'Gates',
    'Nags Head',
    'Moxee City',
    'Nehalem',
    'Rockaway Beach',
    'Pacific City',
    'Keyes',
    'Riddle',
    'Oakland',
    'Glen Rose',
    'Berlin',
    'Montegut',
    'Bourg',
    'Egg Harbor',
    'Cumberland',
    'Washburn',
    'Hayward',
    'Glidden',
    'Docena',
    'Horton',
    'Holly Ridge',
    'Linville',
    'Arcadia',
    'Williams',
    'Toledo',
    'Yatesville',
    'White City',
    'Peshastin',
    'Granger',
    'Moreland',
    'Wild Rose',
    'Newsoms',
    'Bonne Terre',
    'Walsh',
    'Weed',
    'Walhonding',
    'Red Rock',
    'West Union',
    'Rogersville',
    'Moreauville',
    'Loachapoka',
    'Greenville',
    'Saint George',
    'Tougaloo',
    'Buchanan',
    'New Hebron',
    'Monticello',
    'Marks',
    'Calhoun City',
    'Flora',
    'Sumner',
    'Wesson',
    'Coffeeville',
    'Bentonia',
    'Abbeville',
    'Mills River',
    'Auxier',
    'Andersonville',
    'Spruce Pine',
    'Swiftwater',
    'Allamuchy',
    'Spring City',
    'Riegelsville',
    'Yantis',
    'Eagle Butte',
    'Lantry',
    'Richmond',
    'Sherrill',
    'Holy Cross',
    'Peosta',
    'Epworth',
    'Dyersville',
    'Farley',
    'Cascade',
    'Guttenberg',
    'Strawberry Point',
    'Earlville',
    'Motley',
    'Crosby',
    'Trenton',
    'Windom',
    'Leonard',
    'Sylvania',
    'Sardis',
    'Newington',
    'Kingsville',
    'Jasper',
    'Valley Springs',
    'Ponca',
    'Everton',
    'Compton',
    'Western Grove',
    'Vendor',
    'Deer',
    'Lathrop',
    'Garden Grove',
    'Mercer',
    'Graham',
    'Princeton',
    'Bethany',
    'Leon',
    'Van Wert',
    'Blockton',
    'Accord',
    'Rosendale',
    'Shelley',
    'San Rafael',
    'Alamo',
    'Biola',
    'Bethel Island',
    'Tunas',
    'Falkville',
    'Daviston',
    'Centralia',
    'Gold Beach',
    'Gasquet',
    'Parker',
    'Arlington',
    'Scotland',
    'East Brookfield',
    'Montreal',
    'Labadieville',
    'Aragon',
    'Cherry Valley',
    'Jackson',
    'Roach',
    'Northport',
    'Wellston',
    'Spruce',
    'Hopkins',
    'Lake',
    'Dixie',
    'Whitmire',
    'Blacksburg',
    'Gothenburg',
    'Laceys Spring',
    'Danville',
    'Matthews',
    'New Glarus',
    'Balaton',
    'Spooner',
    'Cameron',
    'Sheldon',
    'Neshkoro',
    'Tahoe Vista',
    'Dannemora',
    'Colebrook',
    'Dulac',
    'Green Lake',
    'Scott City',
    'Ethridge',
    'Millville',
    'Hardwick',
    'Cold Spring Harbor',
    'Point Lookout',
    'Sugar Loaf',
    'Plattekill',
    'Verplanck',
    'Bayville',
    'Mill Neck',
    'Hastings-on-Hudson',
    'Brielle',
    'Millwood',
    'Alpine',
    'Seaside Park',
    'Seaside Heights',
    'East Quogue',
    'Haskell',
    'Peconic',
    'Cutchogue',
    'Clintondale',
    'Jamesport',
    'Blauvelt',
    'Molino',
    'Decatur',
    'Baxley',
    'Gibson',
    'Clarks Hill',
    'Carencro',
    'Ghent',
    'Salvisa',
    'Ruffin',
    'Kure Beach',
    'Citronelle',
    'Bridgeport',
    'Sunset',
    'La Fayette',
    'Almo',
    'Rydal',
    'Wrightsville',
    'Eminence',
    'Convent',
    'Thomasville',
    'San Mateo',
    'Blackville',
    'Sparta',
    'Wrens',
    'Troy',
    'Seven Springs',
    'Middlesex',
    'Sardis',
    'Spencer',
    'McConnells',
    'Midville',
    'Prestonsburg',
    'Boaz',
    'Hawesville',
    'Maurice',
    'Breaux Bridge',
    'Patterson',
    'Milton',
    'Erath',
    'Holland',
    'Milldale',
    'Hope Valley',
    'Bradford',
    'Healy',
    'Eagle Lake',
    'Tatum',
    'Hagerman',
    'Mahaffey',
    'Gaines',
    'Drummond',
    'Cable',
    'Iron River',
    'Mason',
    'Westmoreland',
    'Marysville',
    'Summerfield',
    'Palmer',
    'Vermillion',
    'Oketo',
    'Centralia',
    'Onaga',
    'Axtell',
    'Blue Rapids',
    'Randolph',
    'Beattie',
    'Linn',
    'Frankfort',
    'Laporte',
    'Walker',
    'Solway',
    'Kelliher',
    'Blackduck',
    'Tenstrike',
    'Redby',
    'Northome',
    'Benedict',
    'Alex',
    'McQueeney',
    'Smiley',
    'Nixon',
    'Yorktown',
    'Athol',
    'Chouteau',
    'Inola',
    'Austerlitz',
    'Hillsdale',
    'Craryville',
    'Copake Falls',
    'Randolph',
    'Holden',
    'Warren',
    'Bridgeport',
    'West Point',
    'Clayton',
    'Gordonville',
    'Willisburg',
    'Markham',
    'Keymar',
    'Ripley',
    'Maribel',
    'Corning',
    'Dalzell',
    'Houlton',
    'Yoncalla',
    'Monmouth',
    'Independence',
    'McCook',
    'Oxford',
    'Bartley',
    'Indianola',
    'Edgar',
    'Cotton Valley',
    'Leakey',
    'Medina',
    'Mason',
    'Hunt',
    'Deer Park',
    'Prairie Grove',
    'Lincoln',
    'Summers',
    'Evansville',
    'Franklin',
    'Ontario',
    'White Deer',
    'Panhandle',
    'Fritch',
    'Sunray',
    'Miami',
    'Happy',
    'Darrouzett',
    'Quail',
    'Davenport',
    'Sparks',
    'Carney',
    'Meeker',
    'Stroud',
    'Lone Grove',
    'Duke',
    'Granite',
    'Wright City',
    'Merrimac',
    'Quebeck',
    'Doyle',
    'Morrison',
    'Miller',
    'Timber Lake',
    'Iroquois',
    'Culbertson',
    'Elsie',
    'Palisade',
    'Trenton',
    'Dixon',
    'Eatonville',
    'Napavine',
    'Salome',
    'Quartzsite',
    'Kendrick',
    'Amboy',
    'Salisbury',
    'Morrice',
    'Deposit',
    'Woodbine',
    'Smithville',
    'Graton',
    'Shasta',
    'Sigourney',
    'Pella',
    'White Oak',
    'Oberlin',
    'Wakefield',
    'Gypsum',
    'Chapman',
    'Chinese Camp',
    'Moore',
    'Saint Anthony',
    'Firth',
    'Ashton',
    'Tetonia',
    'Pingree',
    'Driggs',
    'Arco',
    'Menan',
    'Sugar City',
    'Teton',
    'Basalt',
    'Saint Libory',
    'Wood River',
    'Loup City',
    'Goshen',
    'Vardaman',
    'Dornsife',
    'Cochrane',
    'Andover',
    'Grantham',
    'Wenden',
    'Romney',
    'West Point',
    'Fayetteville',
    'Arcadia',
    'Eldridge',
    'Augusta',
    'Monticello',
    'Beaumont',
    'Toledo',
    'Smilax',
    'Tellico Plains',
    'Bonneau',
    'Potosi',
    'Dickeyville',
    'Sherwood',
    'Fair Bluff',
    'Hyden',
    'Wadesville',
    'Hillsboro',
    'Stetson',
    'Idaville',
    'Saint Clair',
    'Kasota',
    'Madison Lake',
    'Peck',
    'Oxford',
    'Harper',
    'Fort Loramie',
    'Mount Victory',
    'New Bremen',
    'Elgin',
    'Carson',
    'Washburn',
    'McLaughlin',
    'Underwood',
    'Bullhead',
    'Campbellsburg',
    'Hardinsburg',
    'Nowata',
    'Buffalo',
    'Urbana',
    'Bronaugh',
    'Lenapah',
    'Pescadero',
    'Glenwood',
    'Memphis',
    'Trimble',
    'Hamburg',
    'Northboro',
    'Worthington',
    'Dawn',
    'Amazonia',
    'Aurora',
    'Lincoln',
    'Delaplaine',
    'Bull Shoals',
    'Melbourne',
    'Perry',
    'Marthasville',
    'Springfield',
    'Westcliffe',
    'Reeds Spring',
    'Caulfield',
    'Pilot Knob',
    'Hollister',
    'Vernon',
    'Mansfield',
    'Booneville',
    'Magazine',
    'Paris',
    'Lowry City',
    'Bald Knob',
    'Winslow',
    'Collins',
    'Lawson',
    'Lafayette',
    'Norwalk',
    'Eagle',
    'Oakwood',
    'Hominy',
    'Cresson',
    'Colerain',
    'Victoria',
    'Oldwick',
    'Sims',
    'Magnolia',
    'Saluda',
    'Albertson',
    'Star',
    'Houston',
    'Deshler',
    'Sunset',
    'North Liberty',
    'Fenwick',
    'Rodney',
    'Walkerville',
    'Dorris',
    'Tulelake',
    'Gazelle',
    'Warrensville',
    'Middle Haddam',
    'Lowell',
    'Fruitland',
    'Winters',
    'Christoval',
    'Mereta',
    'Waldoboro',
    'West Rockport',
    'Islesboro',
    'Edgecomb',
    'Jefferson',
    'Warm Springs',
    'Armuchee',
    'Murrayville',
    'King',
    'Columbus',
    'Tryon',
    'Waverly',
    'South Haven',
    'Centerville',
    'Boynton',
    'Cortland',
    'Windsor',
    'Molena',
    'Saltsburg',
    'Broaddus',
    'Hartley',
    'Newton',
    'Oakboro',
    'Howard',
    'David City',
    'Elsie',
    'Bolivar',
    'Foxhome',
    'Kent',
    'Campbell',
    'Hankinson',
    'Abercrombie',
    'Colfax',
    'Wyndmere',
    'Hawley',
    'Mooreton',
    'Hancock',
    'Westport',
    'Brookston',
    'Maple Lake',
    'Home',
    'Dixmont',
    'Jemez Pueblo',
    'Rule',
    'Crowell',
    'Chillicothe',
    'Seymour',
    'Knox City',
    'Aspermont',
    'Rochester',
    'Quanah',
    'Haskell',
    'Fowler',
    'Plains',
    'Bucklin',
    'Oxford',
    'Island Falls',
    'West Danville',
    'Jefferson City',
    'Pinesdale',
    'Casnovia',
    'East Leroy',
    'Burlington',
    'Harold',
    'New Haven',
    'Charlotte',
    'Shoreham',
    'Graniteville',
    'Warren',
    'State Center',
    'Mulino',
    'Beaver Creek',
    'Houston',
    'Raymondville',
    'Indian River',
    'Big Bay',
    'Texline',
    'Kenmare',
    'Parshall',
    'Max',
    'Plaza',
    'Watford City',
    'Alexander',
    'Halfway',
    'Etna',
    'Buffalo Valley',
    'Clarkrange',
    'Moss',
    'Reeds',
    'Knott',
    'Menlo',
    'Harrington',
    'Winlock',
    'Toutle',
    'Forks',
    'Algona',
    'Whittemore',
    'Jeremiah',
    'Whitesburg',
    'Thornton',
    'Winthrop',
    'Holland',
    'Kaplan',
    'Falls Mills',
    'Santo',
    'Waldport',
    'Yachats',
    'Lipan',
    'Falmouth',
    'Oregonia',
    'Williamsburg',
    'Trenton',
    'North Bend',
    'Mount Saint Joseph',
    'Bovina',
    'Memphis',
    'Oxford',
    'Fairbank',
    'Swisher',
    'Morning Sun',
    'Steamboat Rock',
    'Dayton',
    'Red Oak',
    'Lowden',
    'Pounding Mill',
    'Washington',
    'Merryville',
    'Hessmer',
    'Marksville',
    'New Virginia',
    'Ocheyedan',
    'Protivin',
    'Roland',
    'Agency',
    'Greenfield',
    'Nichols',
    'Montezuma',
    'Deep River',
    'Osceola',
    'Newell',
    'Marengo',
    'Emmetsburg',
    'New Vienna',
    'Persia',
    'Moravia',
    'New London',
    'Rembrandt',
    'Diagonal',
    'Toledo',
    'Paullina',
    'Primghar',
    'Zearing',
    'Maxwell',
    'Story City',
    'Janesville',
    'Prescott',
    'Logan',
    'Portsmouth',
    'Panama',
    'Dunlap',
    'Woodbine',
    'Pisgah',
    'Barnes City',
    'Clarinda',
    'Lake City',
    'Tipton',
    'Elma',
    'Arcadia',
    'Exira',
    'Avoca',
    'Bayard',
    'Manning',
    'Ralston',
    'Conroy',
    'Pomeroy',
    'Pocahontas',
    'Armstrong',
    'Glidden',
    'Ute',
    'Halbur',
    'Sumner',
    'Fredericksburg',
    'Maynard',
    'Hazleton',
    'Leighton',
    'Goodland',
    'Waynesville',
    'Liberty Center',
    'Lewiston Woodville',
    'Lucama',
    'Montpelier',
    'Yankeetown',
    'Appleton City',
    'Littleton',
    'Creston',
    'Shady Valley',
    'Falkland',
    'Lake Waccamaw',
    'Webster',
    'Westphalia',
    'Potwin',
    'Preston',
    'Gore',
    'Greens Farms',
    'Smithville',
    'Oskaloosa',
    'Glade Valley',
    'Hill City',
    'Dell Rapids',
    'Corsica',
    'Trent',
    'Rosebud',
    'Marion',
    'Hartford',
    'Montrose',
    'Lower Brule',
    'Armour',
    'Dallas',
    'Herrick',
    'Springfield',
    'Burke',
    'Humboldt',
    'Harrison',
    'Menno',
    'Witten',
    'Bridgewater',
    'Mayesville',
    'Salters',
    'Raymond',
    'Roca',
    'Alvo',
    'Greenwood',
    'Elmwood',
    'Ashland',
    'Syracuse',
    'Hooper',
    'Nickerson',
    'Sidney',
    'Silver City',
    'Oakland',
    'Malvern',
    'Snelling',
    'Oneida',
    'Bellbrook',
    'De Graff',
    'Burlington',
    'Maxbass',
    'Sterling',
    'Napoleon',
    'Strasburg',
    'Tappen',
    'Driscoll',
    'Franklinville',
    'Crows Landing',
    'Venedocia',
    'Waynesfield',
    'Van Buren',
    'Quincy',
    'Kent',
    'Moro',
    'Condon',
    'Markleysburg',
    'Addison',
    'Farmington',
    'Ohiopyle',
    'McHenry',
    'Ridgeville',
    'Modoc',
    'Geneva',
    'Hoagland',
    'Montpelier',
    'Badger',
    'Argyle',
    'Karlstad',
    'Greenbush',
    'Williams',
    'Wannaska',
    'Stephen',
    'Kennedy',
    'Angle Inlet',
    'Kempner',
    'Webb City',
    'Nash',
    'Winside',
    'Thatcher',
    'Iraan',
    'Weatherly',
    'Crockett',
    'Bolinas',
    'Browns Valley',
    'Elmira',
    'Remsenburg',
    'Palisades',
    'Wainscott',
    'Pine Island',
    'Sea Girt',
    'Wassaic',
    'Westhampton',
    'Hillburn',
    'Salt Point',
    'Northfield',
    'Piermont',
    'West Cornwall',
    'Morris',
    'Great River',
    'Newfoundland',
    'Lake Katrine',
    'Normandy Beach',
    'Montague',
    'Lakeside',
    'Allenhurst',
    'Purdys',
    'Reeseville',
    'Elm Grove',
    'Saint James',
    'Trenary',
    'Trufant',
    'Rison',
    'Blenker',
    'Poseyville',
    'Roachdale',
    'Ladoga',
    'Stilesville',
    'Toxey',
    'Stonewall',
    'Herndon',
    'Dalmatia',
    'Norridgewock',
    'Iola',
    'Needham',
    'Niota',
    'Vernon Center',
    'Oriskany Falls',
    'Salem',
    'Swans Island',
    'Clayton',
    'Lobelville',
    'Williston',
    'Vanlue',
    'Athens',
    'Blanchardville',
    'Backus',
    'Hartland',
    'Wilmot',
    'East Andover',
    'Meriden',
    'Nevis',
    'Stratton',
    'New Vineyard',
    'New Portland',
    'Oakland',
    'Phillips',
    'Anson',
    'Attapulgus',
    'Hickory Corners',
    'Leesburg',
    'Saint Stephen',
    'Sardis',
    'Sugar Tree',
    'Scotts Hill',
    'Darden',
    'Somerset',
    'Alvada',
    'Mount Blanchard',
    'New Ross',
    'Fillmore',
    'Scandinavia',
    'Austin',
    'Roxbury',
    'Philadelphia',
    'New Durham',
    'Etna',
    'Dexter',
    'Saint Albans',
    'Corinna',
    'Newport',
    'Canaan',
    'South Wayne',
    'Argyle',
    'Cambridge',
    'Jonesboro',
    'Kellyville',
    'Chaumont',
    'Bruceton',
    'Fletcher',
    'Neeses',
    'Myrtle',
    'Hosford',
    'Lewisport',
    'Iron City',
    'North',
    'Dorchester',
    'Stetsonville',
    'Withee',
    'Hilbert',
    'Forest Junction',
    'Bloomington',
    'Fennimore',
    'Cassville',
    'Waldron',
    'Flat Rock',
    'Cinebar',
    'Eckert',
    'Wingate',
    'Laura',
    'Ludlow Falls',
    'New Paris',
    'Dunkirk',
    'Ecorse',
    'Hughesville',
    'Churchville',
    'Sandy Spring',
    'Sea Isle City',
    'Gillette',
    'Hancock',
    'Lakeside',
    'Polson',
    'Byron',
    'New Harmony',
    'Manhattan',
    'Ronan',
    'South Fork',
    'Log Lane Village',
    'Brush',
    'Leadville',
    'Clancy',
    'Clinton',
    'Del Norte',
    'Knippa',
    'Ida',
    'Merrillan',
    'Marlow',
    'Fort Cobb',
    'New Franklin',
    'Marionville',
    'Willard',
    'Pangburn',
    'Mayflower',
    'San Felipe',
    'Boyd',
    'Fountain City',
    'Bay City',
    'Ingraham',
    'Mount Erie',
    'Amboy',
    'Converse',
    'Bunker Hill',
    'Young America',
    'Summitville',
    'Waterloo',
    'Frankton',
    'Sterling',
    'Preemption',
    'New Windsor',
    'Viola',
    'Sciota',
    'Good Hope',
    'Woodhull',
    'Gilmanton Ironworks',
    'Farnham',
    'Merry Point',
    'Christchurch',
    'Callao',
    'Cobbs Creek',
    'Deltaville',
    'Mathews',
    'Milton Mills',
    'Jewell',
    'Wilson',
    'Hunter',
    'Belleville',
    'Park',
    'Geneseo',
    'Sylvan Grove',
    'Conger',
    'Elmore',
    'Delavan',
    'Granada',
    'Sisseton',
    'Browns Valley',
    'Houston',
    'Addison',
    'Monrovia',
    'Newberry',
    'Fairfield',
    'Boulder',
    'Florence',
    'Basin',
    'Kendalia',
    'Levan',
    'Pinson',
    'Bowersville',
    'Rickreall',
    'Ponder',
    'Crawford',
    'Red Rock',
    'Bruceville',
    'Runnells',
    'Clifton',
    'Ridgway',
    'Fairplay',
    'Eucha',
    'Agra',
    'Waurika',
    'Nashoba',
    'Haworth',
    'Fowler',
    'Brookston',
    'Jemison',
    'Roann',
    'Cross Lake',
    'Langley',
    'Denniston',
    'Staffordsville',
    'Wittensville',
    'Banner',
    'Ivel',
    'Tutor Key',
    'Oil Springs',
    'Flatgap',
    'Falcon',
    'Hazel Green',
    'Martin',
    'Ezel',
    'Crane Hill',
    'Vancleave',
    'Hayden',
    'Rush City',
    'Fort Richardson',
    'Oriska',
    'Sykeston',
    'Chaseley',
    'Woodworth',
    'Streeter',
    'Minnewaukan',
    'Harvey',
    'Warwick',
    'Esmond',
    'Maddock',
    'Sheyenne',
    'Tolna',
    'Hampden',
    'Starkweather',
    'Leeds',
    'Wolford',
    'Stanley',
    'Glen Flora',
    'Mountain Grove',
    'Plattsburg',
    'El Dorado Springs',
    'Winfield',
    'Berry',
    'Arrington',
    'Stephentown',
    'North Sandwich',
    'Hallowell',
    'Cape Vincent',
    'Shokan',
    'Spencertown',
    'Bunker Hill',
    'Berea',
    'Bremen',
    'Olaton',
    'Ridott',
    'Avalon',
    'Franklin Grove',
    'Amboy',
    'Ohio',
    'Satartia',
    'Florence',
    'Saucier',
    'Warrensburg',
    'Trevett',
    'Sodus',
    'Free Soil',
    'Sheldon',
    'Norway',
    'Bark River',
    'Bessemer',
    'Boyne Falls',
    'Bethesda',
    'Columbia',
    'Holtville',
    'Oak Park',
    'Beatty',
    'Gridley',
    'Greenwood',
    'Varnville',
    'Frederica',
    'Elizabethtown',
    'Taylorsville',
    'Cortaro',
    'Grand Island',
    'Markle',
    'Pittsford',
    'Sopchoppy',
    'Tuckahoe',
    'Cedar Brook',
    'Oceanville',
    'Colmar',
    'Ridge',
    'Port Republic',
    'Poolesville',
    'Dickerson',
    'Newburg',
    'Parsonsburg',
    'Parker Ford',
    'West Friendship',
    'Sacaton',
    'Hartwood',
    'Rhodhiss',
    'Hereford',
    'Baptistown',
    'Blossburg',
    'Wernersville',
    'Bowmansville',
    'Summit Hill',
    'Bowmanstown',
    'Freeburg',
    'Beavertown',
    'Lakeville',
    'Lackawaxen',
    'Tafton',
    'Greeley',
    'Germansville',
    'Danielsville',
    'Guilford',
    'Bucksport',
    'Brooklin',
    'Westfield',
    'Rockwood',
    'Boothbay Harbor',
    'Vinalhaven',
    'Monroe',
    'Burnham',
    'Enosburg Falls',
    'Fairlee',
    'Temple',
    'Stevenson',
    'Lisbon',
    'Troy',
    'North Walpole',
    'Craftsbury',
    'East Hardwick',
    'Drayden',
    'Altavista',
    'Belt',
    'Big Sandy',
    'Ennis',
    'Fort Benton',
    'Chinook',
    'Chester',
    'Wisdom',
    'Scobey',
    'Denton',
    'Fortuna',
    'Harlowton',
    'Divide',
    'Stanford',
    'Talcott',
    'Logandale',
    'Milford',
    'Evart',
    'Byron',
    'Cumming',
    'Ridge Spring',
    'Kasson',
    'Dodge Center',
    'Stratford',
    'Halma',
    'Hallock',
    'Lancaster',
    'Lake Bronson',
    'Warren',
    'Newfolden',
    'Parker',
    'Moss Beach',
    'Bayside',
    'Jackson',
    'Allen',
    'Bartlett',
    'Butte',
    'Coleridge',
    'Craig',
    'Decatur',
    'Concord',
    'Linwood',
    'Hubbard',
    'Long Pine',
    'Newcastle',
    'Maskell',
    'Hartington',
    'Prague',
    'Spencer',
    'Ulysses',
    'Malmo',
    'Morse Bluff',
    'Cedar Bluffs',
    'Stuart',
    'Weston',
    'Thompson',
    'Leland',
    'Britt',
    'Buffalo Center',
    'Scarville',
    'Joice',
    'Garner',
    'Kellogg',
    'Huxley',
    'Duncombe',
    'Klemme',
    'Lynnville',
    'Beacon',
    'Menlo',
    'Wheatland',
    'Shell Rock',
    'Jefferson',
    'Ada',
    'Audubon',
    'Hitterdal',
    'Lake Park',
    'Glyndon',
    'Perley',
    'Felton',
    'Frazee',
    'Niagara',
    'Florence',
    'Ogdensburg',
    'Abrams',
    'Bear Creek',
    'Little Suamico',
    'Bovey',
    'Brook Park',
    'Levering',
    'Stanchfield',
    'Gilbert',
    'Anacoco',
    'Natalia',
    'Benedict',
    'Lorraine',
    'Reevesville',
    'Georgetown',
    'Dayton',
    'Otter',
    'Ismay',
    'Yucca',
    'Happy Jack',
    'Lund',
    'Roy-Winifred Junction',
    'Dell',
    'Kooskia',
    'Reardan',
    'Maplesville',
    'Pisgah Forest',
    'Bushkill',
    'Zionhill',
    'Shohola',
    'Lyons',
    'Beach Lake',
    'Beaver Meadows',
    'Mehoopany',
    'Taos Ski Valley',
    'Walton',
    'Bluewater',
    'Torreon',
    'Lowry',
    'Coggon',
    'Shenandoah',
    'Huntsville',
    'Millersport',
    'Edison',
    'Hamlet',
    'Lakemore',
    'Lambsburg',
    'Fredericktown',
    'Cope',
    'Williston',
    'Chesterhill',
    'Maria Stein',
    'South Solon',
    'Riegelwood',
    'Butler',
    'La Rue',
    'Crooksville',
    'Stockport',
    'Emerald Isle',
    'Grammer',
    'Rosedale',
    'Pembroke',
    'Washington',
    'Fall Creek',
    'Tangent',
    'Rancocas',
    'Silver Lake',
    'Mount Berry',
    'Fairview Village',
    'Osprey',
    'Pocomoke City',
    'Southview',
    'Skippack',
    'Gordonville',
    'Pine Forge',
    'Frederick',
    'Perkiomenville',
    'Grafton',
    'Chesapeake City',
    'East Hampstead',
    'Daleville',
    'Kilauea',
    "Hau'ula",
    'New Market',
    'Lakefield',
    'Hardwick',
    'Edgerton',
    'Bigelow',
    'Chandler',
    'Otho',
    'Comfrey',
    'Marathon',
    'Morrisville',
    'Plymouth',
    'Brooktondale',
    'Truxton',
    'Maysel',
    'Crawford',
    'Walkersville',
    'Grantsville',
    'Folsom',
    'Cameron',
    'Leon',
    'Worthington',
    'Hundred',
    'Hoyt Lakes',
    'Canyon',
    'Brookston',
    'Littlefork',
    'Floodwood',
    'Warba',
    'Lakeville',
    'Slaty Fork',
    'Durbin',
    'Bartow',
    'Hillsboro',
    'Arbovale',
    'Treadwell',
    'South New Berlin',
    'Morris',
    'Fort Plain',
    'Fultonville',
    'Caroga Lake',
    'Dolgeville',
    'Fonda',
    'Kent',
    'Eglon',
    'Dryfork',
    'Masontown',
    'Independence',
    'Hambleton',
    'Donnelly',
    'Horseshoe Bend',
    'Montgomery',
    'New Germany',
    'South Otselic',
    'Danforth',
    'Cheriton',
    'Hyde',
    'Rossiter',
    'Temple',
    'Barnard',
    'West Wardsboro',
    'Barnet',
    'Unionville',
    'Partlow',
    'White Post',
    'Thornton',
    'Chelsea',
    'South Woodstock',
    'Springfield',
    'East Dover',
    'Ashland',
    'South Strafford',
    'Rochester',
    'West Dover',
    'Berkshire',
    'West Stockbridge',
    'Roma',
    'Nolanville',
    'Garciasville',
    'Westville',
    'Highwood',
    'Depew',
    'Mount Hermon',
    'Hidden Valley Lake',
    'Goshen',
    'Kimper',
    'Shelbiana',
    'Elkton',
    'South Fulton',
    'McHenry',
    'Gilbertsville',
    'Chula',
    'Burlison',
    'Hurtsboro',
    'Waverly',
    'Bryceville',
    'Pomona Park',
    'Henning',
    'Oakland',
    'Lake Cormorant',
    'Coldwater',
    'Dana',
    'Perryville',
    'Livermore',
    'Rumsey',
    'Stockton',
    'Autaugaville',
    'Jersey',
    'Cascilla',
    'Oil City',
    'Ralph',
    'Fair Play',
    'Walls',
    'Leighton',
    'Eva',
    'Saint Martinville',
    'Smithfield',
    'Campbellsburg',
    'Cadiz',
    'Fredonia',
    'Cordova',
    "D'Iberville",
    'Lafayette',
    'French Camp',
    'Gallion',
    'Seminary',
    'Saint James',
    'Buhler',
    'Huddleston',
    'Barling',
    'Fort McDowell',
    'Thayer',
    'Sedan',
    'Colwich',
    'Livonia',
    'Fordoche',
    'Maringouin',
    'Lottie',
    'Waddell',
    'Tecumseh',
    'Melville',
    'Andale',
    'Gloucester Point',
    'Greenwood',
    'Coweta',
    'Centerton',
    'Hackett',
    'Gentry',
    'Arkoma',
    'Lowell',
    'Lavaca',
    'Gravette',
    'Farmington',
    'West Fork',
    'Roland',
    'Tontitown',
    'Duplessis',
    'Buras',
    'Wood River Junction',
    'Carolina',
    'Saint Gabriel',
    'Arapaho',
    'Kinsley',
    'Altamont',
    'West Granby',
    'Summerland',
    'Nescopeck',
    'Ashton',
    'Wellington',
    'Luckey',
    'Drexel',
    'Pickton',
    'Mount Carmel',
    'Correctionville',
    'Kingsley',
    'Bishopville',
    'Woodford',
    'Readyville',
    'Lexa',
    'England',
    'Proctor',
    'Oliver Springs',
    'Louisiana',
    'Bismarck',
    'Onarga',
    'Oologah',
    'Fresno',
    'Elsberry',
    'Gates Mills',
    'Morgantown',
    'Verdugo City',
    'Morrison',
    'Midway',
    'Midfield',
    'Midkiff',
    'Edgewood',
    'Shepherd',
    'Coyle',
    'Bullock',
    'Butler',
    'Ramseur',
    'Clarksburg',
    'Plymouth',
    'Intercession City',
    'Howie In The Hills',
    'Dillwyn',
    'Fieldale',
    'Zanesfield',
    'Manti',
    'New Almaden',
    'West Point',
    'Tabor',
    'Columbia',
    'Milo',
    'Eddyville',
    'Lacona',
    'Blakesburg',
    'Mount Ayr',
    'Tingley',
    'Afton',
    'Orient',
    'Redfield',
    'Gravity',
    'Bagley',
    'Salem',
    'Winfield',
    'Montrose',
    'Donnellson',
    'Houghton',
    'Packwood',
    'Conesville',
    'Richland',
    'Atalissa',
    'Moscow',
    'Baldwin',
    'Riverton',
    'Bedford',
    'Sun',
    'Elm Creek',
    'Hartville',
    'Litchfield',
    'Imperial',
    'Cisco',
    'Prescott',
    'Morley',
    'North Grosvenor Dale',
    'Keene',
    'South Ryegate',
    'Lynn',
    'Graysville',
    'Roseland',
    'Rothbury',
    'Wilson',
    'Melrose',
    'Dollar Bay',
    'Lake Linden',
    'South Range',
    'Atlantic Mine',
    'Hubbell',
    'Five Points',
    'Empire',
    'Childersburg',
    'Ashville',
    'Scott Air Force Base',
    'Olivette',
    'Woodland Mills',
    'Atwood',
    'Thornburg',
    'Wallace',
    'Laurel Hill',
    'Gibson',
    'Bennington',
    'Lincoln',
    'Greenleaf',
    'Tescott',
    'New Cambria',
    'Leonardville',
    'Manchaca',
    'Bunn',
    'Eastpoint',
    'Gold Hill',
    'Donalsonville',
    'Helena',
    'Kersey',
    'Wilcox',
    'Knox Dale',
    'Ocilla',
    'Ochlocknee',
    'Boston',
    'Hawkinsville',
    'Hartsfield',
    'Norman Park',
    'Pleasant Hill',
    'Enigma',
    'Whigham',
    'Sycamore',
    'Polkton',
    'Morven',
    'Pinebluff',
    'Perry',
    'Avoca',
    'Pleasant Dale',
    'Homer',
    'Harviell',
    'Neelyville',
    'Grandin',
    'Coxs Creek',
    'Hustonville',
    'Livingston',
    'Garrard',
    'Nancy',
    'Halfway',
    'Rochelle',
    'Waldo',
    'Wellborn',
    'Brooker',
    'Union',
    'Waco',
    'Exeter',
    'Brooks',
    'Burns Flat',
    'Pierpont',
    'Austinburg',
    'Lockesburg',
    'Norman',
    'Murfreesboro',
    'Hatfield',
    'Abbeville',
    'Dry Branch',
    'West Green',
    'Alapaha',
    'Soperton',
    'Kimbolton',
    'Raywick',
    'Alto',
    'Capitan',
    'Nogal',
    'Streetman',
    'Trinity',
    'Poteau',
    'Pavo',
    'Tecumseh',
    'Morris',
    'Mauk',
    'Haskell',
    'Panama',
    'Jeffersonville',
    'Coolidge',
    'Amity',
    'Cove',
    'Kirby',
    'Prentiss',
    'Hooks',
    'Ewing',
    'Evarts',
    'Orlando',
    'Millwood',
    'Bronston',
    'Clearfield',
    'Hillsboro',
    'Brownsville',
    'Gravel Switch',
    'Manchester',
    'Woodland',
    'Brave',
    'Graysville',
    'Mayo',
    'Fort White',
    'Branford',
    'Bedias',
    'Mount Olivet',
    'Yatesboro',
    'Dayton',
    'Penfield',
    'Worthington',
    'Chautauqua',
    'Clay Center',
    'Truth or Consequences',
    'Turnerville',
    'Milan',
    'Bronwood',
    'Barney',
    'Carlton',
    'Nezperce',
    'Blue River',
    'Renville',
    'Sula',
    'Piedmont',
    'Webster City',
    'Earlham',
    'Fairacres',
    'Wesley',
    'Cottondale',
    'Jay',
    'Oxford',
    'Kingsbury',
    'Bear Creek',
    'Danville',
    'Earlysville',
    'San Antonio',
    'Edgerton',
    'Trail',
    'Beaver',
    'Lakeville',
    'Lucas',
    'Whitakers',
    'Stantonsburg',
    'Bunnlevel',
    'Franklinton',
    'Pinetops',
    'Vernon Hill',
    'Cranfills Gap',
    'Russellville',
    'Hurdle Mills',
    'Engelhard',
    'Goldston',
    'Lake Panasoffkee',
    'Plantersville',
    'Elliottsburg',
    'Underwood',
    'Cascade',
    'Ararat',
    'Grifton',
    'Glenmont',
    'Botkins',
    'Belle Center',
    'Milford Center',
    'Windham',
    'Silver Springs',
    'Experiment',
    'South Hill',
    'Hegins',
    'Abbottstown',
    'Lemont',
    'Cornwall',
    'Luthersburg',
    'Stump Creek',
    'Brockport',
    'Bradenville',
    'Burnside',
    'Brackenridge',
    'Summerhill',
    'Rector',
    'Vintondale',
    'Starford',
    'Sutersville',
    'Hickory',
    'Triadelphia',
    'Manor',
    'Bethany',
    'New Cumberland',
    'Chester',
    'Pulaski',
    'Grantsville',
    'Barton',
    'Westmoreland City',
    'Stephenson',
    'Millwood',
    'Timberville',
    'Hinton',
    'Providence',
    'Bainbridge',
    'South Lebanon',
    'Oak Ridge',
    'Wilberforce',
    'Farrell',
    'Sharpsville',
    'Haw River',
    'Thurston',
    'Waverly',
    'Chestnut Mountain',
    'Micro',
    'Tamassee',
    'Newland',
    'Sullivan',
    'Hustisford',
    'Kawkawlin',
    'Stevenson',
    'Seal Rock',
    'Irrigon',
    'Hertford',
    'East Carondelet',
    'Stover',
    'Ironton',
    'Frankford',
    'Cave Spring',
    'Winneconne',
    'Lime Ridge',
    'Auburndale',
    'Vesper',
    'Omro',
    'Hermansville',
    'Sarona',
    'Barronett',
    'Bangor',
    'Wanchese',
    'Woodlawn',
    'Roxbury',
    'South Kent',
    'Colfax',
    'Unity',
    'Keshena',
    'Arcadia',
    'Paris',
    'Mayville',
    'Coral',
    'Barron',
    'White Swan',
    'Sugarloaf',
    'Coyote',
    'Tres Pinos',
    'West Salem',
    'Bells',
    'Collinsville',
    'Wimauma',
    'Ellenton',
    'Laurel',
    'Eagle Lake',
    'Mecca',
    'Morongo Valley',
    'Millville',
    'Mentone',
    'Calimesa',
    'Yermo',
    'Pinon Hills',
    'Winchester',
    'Forest Falls',
    'Imperial',
    'Port Republic',
    'Boissevain',
    'Mount Crawford',
    'Cabin John',
    'Mount Wolf',
    'Bear Lake',
    'Gallitzin',
    'Dysart',
    'Marienville',
    'Olanta',
    'Youngsville',
    'Miles',
    'Clint',
    'Mertzon',
    'McKean Township',
    'Columbus',
    'Homeland',
    'Catharpin',
    'Jenner',
    'Eldorado',
    'Churchton',
    'Lake Alfred',
    'New London',
    'Deale',
    'Newfane',
    'Hookstown',
    'Slovan',
    'Harwick',
    'Savoy',
    'Lee Center',
    'Durhamville',
    'Raymond',
    'Gretna',
    'Hurt',
    'Alburg',
    'Lowell',
    'Tamworth',
    'Effingham',
    'South Pomfret',
    'Town of Mount Desert',
    'Bradford',
    'Starksboro',
    'Otis',
    'Shutesbury',
    'Randolph Center',
    'Harrisville',
    'Sullivan',
    'Proctor',
    'West Chesterfield',
    'Newbury',
    'Catawissa',
    'Midland',
    'Hastings',
    'Julian',
    'Milesburg',
    'Cherry Tree',
    'Millheim',
    'Tidioute',
    'Pine Grove Mills',
    'New Braintree',
    'Dighton',
    'Sadler',
    'Lake Hughes',
    'Highland',
    'Hydes',
    'Brandy Station',
    'Danbury',
    'Bethlehem',
    'North Woodstock',
    'West Cornwall',
    'Ulysses',
    'Hollidaysburg',
    'West Decatur',
    'Curwensville',
    'Sugar Grove',
    'Petersburg',
    'Robertsdale',
    'Lucinda',
    'Wallaceton',
    'Londonderry',
    'East Fairfield',
    'Concord',
    'Sheldon Springs',
    'Grand Isle',
    'Etna',
    'Lincoln',
    'Narrows',
    'Neshanic Station',
    'Frackville',
    'Whitefield',
    'West Paris',
    'Mount Gretna',
    'North Falmouth',
    'Harbeson',
    'Milton',
    'Greenwood',
    'Mechanicsville',
    'Maxatawny',
    'Nassawadox',
    'Painter',
    'Onley',
    'Accomac',
    'Eastville',
    'Wachapreague',
    'Black River',
    'Bridgeport',
    'Lonaconing',
    'Oldtown',
    'Lytle Creek',
    'Nanjemoy',
    'Alturas',
    'Prospect Harbor',
    'Stoddard',
    'Westmoreland',
    'Old Chatham',
    'Canaan',
    'Moscow',
    'Pittsfield',
    'Hancock',
    'Plainfield',
    'Gill',
    'Marshfield',
    'Moscow',
    'Auburn',
    'Lattimer',
    'Shawnee on Delaware',
    'Muse',
    'Hibbs',
    'Rochester',
    'Humarock',
    'Rockland',
    'Gowanda',
    'Holland',
    'West Valley',
    'Allegany',
    'Spring Lake',
    'Otego',
    'Big Flats',
    'Coopers Plains',
    'Hatfield',
    'East Kingston',
    'Sutter',
    'Empire',
    'Loyalhanna',
    'Strongstown',
    'Marion Center',
    'Shippingport',
    'Avonmore',
    'Parker',
    'Ellendale',
    'Woodside',
    'Eden',
    'Rhodesdale',
    'Mardela Springs',
    'Viola',
    'Melrose',
    'Ravenel',
    'Penn Laird',
    'Forbes Road',
    'Schellsburg',
    'Gray',
    'Smoke Run',
    'Madera',
    'Richland',
    'York Springs',
    'Allenspark',
    'Three Forks',
    'Joseph',
    'Bellemont',
    'Darby',
    'Cook',
    'Idledale',
    'Frenchtown',
    'Elbert',
    'La Pine',
    'Umatilla',
    'Unionville',
    'Bally',
    'Gradyville',
    'Depue',
    'Wheeler',
    'Joppa',
    'East Dennis',
    'Granville',
    'Madbury',
    'Ashby',
    'Mashantucket',
    'South Wellfleet',
    'Town of Bozrah',
    'Port Henry',
    'Tariffville',
    'Rawlings',
    'Pittsfield',
    'Atlantic',
    'York Harbor',
    'Hartford',
    'Tenants Harbor',
    'Windham Center',
    'Brooksville',
    'Petersham',
    'Equinunk',
    'Girardville',
    'Rimforest',
    'Harwood',
    'Arkville',
    'Waccabuc',
    'Gibsonton',
    'Burt',
    'Parksley',
    'Harborton',
    'Spartansburg',
    'Keysville',
    'Stony Creek',
    'Clarksville',
    'Dolphin',
    'White Stone',
    'Goode',
    'Waterford Flat',
    'Peaks Island',
    'Natural Bridge',
    'Hebron',
    'Spofford',
    'Tyaskin',
    'Cecilton',
    'Butte des Morts',
    'Pickett',
    'Eldorado',
    'Bowman',
    'Eutawville',
    'North Kingsville',
    'Galien',
    'Baroda',
    'Burbank',
    'Heron Lake',
    'Round Lake',
    'Bingham Lake',
    'Winthrop',
    'Cloverport',
    'Garfield',
    'Roseville',
    'Slaughters',
    'Bloomfield',
    'Crofton',
    'Creighton',
    'Ponca',
    'Scribner',
    'Wisner',
    'Bancroft',
    'Wausa',
    'Dodge',
    'Herman',
    'Stratton',
    'Grant',
    'Amherst',
    'Rushville',
    'Gordon',
    'Ewing',
    'Kilgore',
    'Johnstown',
    'Crookston',
    'Cody',
    'Merriman',
    'Lebanon',
    'Arnold',
    'Callaway',
    'Wilcox',
    'Stapleton',
    'Tryon',
    'Wolbach',
    'Chapman',
    'Snyder',
    'North Bend',
    'Belgrade',
    'Spalding',
    'Saint Edward',
    'Petersburg',
    'New Philadelphia',
    'Carnegie',
    'Tryon',
    'Paoli',
    'Hendrix',
    'Bartow',
    'Twin City',
    'Metter',
    'Nahunta',
    'Chester',
    'Hortense',
    'Schoharie',
    'Fultonham',
    'West Coxsackie',
    'Newport',
    'Poland',
    'Hampden Sydney',
    'Morenci',
    'Saint Joe',
    'Hamler',
    'Malinta',
    'Continental',
    'Mark Center',
    'Custar',
    'Sherwood',
    'Canton',
    'Greenwood',
    'Lovell',
    'Minot',
    'West Minot',
    'Andover',
    'Doss',
    'Mountain Home',
    'Otterbein',
    'Beryl',
    'Beaver',
    'Panguitch',
    'Alton',
    'Hildale',
    'Cannonville',
    'Duck Creek Village',
    'Oronogo',
    'Stella',
    'Bretton Woods',
    'Belmont',
    'Lolita',
    'Whitingham',
    'Nashua',
    'Garden City',
    'Sealevel',
    'Dulce',
    'Latexo',
    'Eunice',
    'Copeville',
    'Odessa',
    'Corbett',
    'Eagle Creek',
    'Kiowa',
    'Hygiene',
    'Fairfield',
    'Halliday',
    'Ludlow',
    'Amidon',
    'Regent',
    'Richardton',
    'Millfield',
    'Glouster',
    'Deaver',
    'Meeteetse',
    'Cowley',
    'Manderson',
    'Rochelle',
    'Annandale-on-Hudson',
    'Oakley',
    'Paul',
    'Hazelton',
    'Bulger',
    'East Butler',
    'Elizabeth',
    'Leeper',
    'Pleasantville',
    'Elco',
    'Smithton',
    'Petroleum',
    'Smithville',
    'Cairo',
    'Branchland',
    'Sayre',
    'Flinton',
    'Reamstown',
    'Warriors Mark',
    'Glen Hope',
    'Etoile',
    'Huntington',
    'Alto',
    'Tilden',
    'Port Mansfield',
    'Hawley',
    'Boody',
    'Warrensburg',
    'Dawson',
    'Kenney',
    'Green Mountain',
    'Hamlin',
    'Jackson',
    'Mount Savage',
    'Barclay',
    'Metaline Falls',
    'Cusick',
    'Section',
    'Wiggins',
    'Pisgah',
    'Pembroke',
    'Oakley',
    'Ridgeway',
    'Gilman City',
    'Galt',
    'Corydon',
    'New Hampton',
    'Lamoni',
    'Barnard',
    'Newtown',
    'Conception Junction',
    'Albany',
    'Hatfield',
    'Chula',
    'Allerton',
    'Martinsville',
    'Murray',
    'Jamesport',
    'Lorimor',
    'Parnell',
    'Ravenwood',
    'Humeston',
    'Gentry',
    'Meadville',
    'Linneus',
    'North Powder',
    'Poyntelle',
    'South Gibson',
    'Shoshoni',
    'Volborg',
    'Broadus',
    'Lame Deer',
    'Hysham',
    'Rosebud',
    'Arvada',
    'Upton',
    'Clearmont',
    'Moorcroft',
    'Kaycee',
    'Hulett',
    'Dubois',
    'Baggs',
    'Crowheart',
    'Fort Washakie',
    'Burns',
    'Central City',
    'Walton',
    'Hinsdale',
    'Marcy',
    'Lindley',
    'Cayuta',
    'Livingston Manor',
    'Stamford',
    'West Winfield',
    'Saint Michaels',
    'Rock Hall',
    'Millington',
    'Perryville',
    'Port Penn',
    'Cordova',
    'Delaware City',
    'Lyndonville',
    'Southport',
    'Bradley',
    'Mechanic Falls',
    'Winterport',
    'Hull',
    'Rock Rapids',
    'Boyden',
    'Big Pine',
    'Pinehurst',
    'Kingston',
    'Lexington',
    'Panama',
    'Meadow Vista',
    'Woodworth',
    'Quaker City',
    'Spiro',
    'Guilford',
    'Burlington',
    'Petersburg',
    'Clayton',
    'Terrace Park',
    'Owensville',
    'Moscow',
    'Tilden',
    'Ridgely',
    'Gideon',
    'Hornbeak',
    'Brownsboro',
    'Bristol',
    'Shabbona',
    'Lee',
    'Saulsbury',
    'Afton',
    'Newry',
    'Orland',
    'Dover-Foxcroft',
    'Greenville',
    'Harborside',
    'Sebec',
    'Greenbush',
    'Eddington',
    'Sangerville',
    'White Water',
    'Feura Bush',
    'Sloansville',
    'Sedgwick',
    'Gouldsboro',
    'Whiting',
    'Wayne',
    'East Wakefield',
    'Indianola',
    'East McKeesport',
    'Pearisburg',
    'Craigsville',
    'Breezy Point',
    'Charlemont',
    'Marlborough',
    'Whitefield',
    'Dixfield',
    'Sligo',
    'Lilly',
    'Clinton',
    'Pembroke',
    'Southwest Harbor',
    'Danforth',
    'Thetford Center',
    'Medway',
    'Eastport',
    'Winter Harbor',
    'Seal Cove',
    'Shirley Mills',
    'Lempster',
    'East Thetford',
    'Goshen',
    'Birmingham',
    'Princeton',
    'Wheatland',
    'Sturgeon',
    'Laurys Station',
    'Stockertown',
    'Brandamore',
    'Middleport',
    'Windsor',
    'Chesterfield',
    'Huntington',
    'Ashfield',
    'Becket',
    'Blandford',
    'Dalton',
    'Leverett',
    'Aylett',
    'King William',
    'Moon',
    'Irvington',
    'Kingston',
    'Goldsboro',
    'Bivalve',
    'Queenstown',
    'Sharptown',
    'Valley',
    'South Wales',
    'Wilson',
    'Plumsteadville',
    'Spring House',
    'Derby Line',
    'Readsboro',
    'Westfield',
    'Gilman',
    'Sugar Hill',
    'Cambridgeport',
    'Harborcreek',
    'Vineyard Haven',
    'Fountainville',
    'Windsor',
    'Fredericksburg',
    'Denmark',
    'Hancock',
    'Cape Porpoise',
    'West Alexander',
    'Penn Run',
    'Houston',
    'Bessemer',
    'Cecil',
    'Torrance',
    'Center Rutland',
    'Chelsea',
    'Quitman',
    'Farmdale',
    'Boyd',
    'Burlington Flats',
    'Pompey',
    'Crescent City',
    'Careywood',
    'Wellpinit',
    'Boyce',
    'Saint Paul',
    'Elba',
    'Jamestown',
    'Tiro',
    'Loda',
    'Lynnville',
    'De Valls Bluff',
    'Niangua',
    'Elkland',
    'West Concord',
    'Lower Lake',
    'Arcadia',
    'Alcalde',
    'Elderton',
    'Buchanan',
    'Gaston',
    'Mount Hope',
    'Maiden Rock',
    'Emerado',
    'Gibbon',
    'Waialua',
    'Hanover',
    'Bowdon',
    'Bowman',
    'Dewy Rose',
    'Farmington',
    'Pineville',
    'Center Point',
    'Clayton',
    'Hosston',
    'Greenwood',
    'Eros',
    'Stonewall',
    'Mooringsport',
    'Calhoun',
    'Downsville',
    'Epps',
    'Keatchie',
    'Echo',
    'New Hill',
    'Little Mountain',
    'Wingo',
    'Satsuma',
    'Shady Dale',
    'Rutledge',
    'Whitesburg',
    'Redan',
    'Luthersville',
    'Elora',
    'Cowan',
    'Kelso',
    'Kilbourne',
    'Dubach',
    'Mulga',
    'Bogue Chitto',
    'Darrow',
    'Bankston',
    'Jonesville',
    'Dorchester',
    'Pinedale',
    'Foster',
    'Skamokawa',
    'Yacolt',
    'Fordland',
    'Cabool',
    'Cambria',
    'Fall River',
    'Jim Falls',
    'Pulaski',
    'Solon Springs',
    'Macomb',
    'Squires',
    'Bruner',
    'Ava',
    'Vanzant',
    'Blue Eye',
    'Horseshoe Bend',
    'Jerico Springs',
    'Gower',
    'Tennille',
    'Dixie',
    'Humphrey',
    'Little America',
    'Nazareth',
    'Harper',
    'Rio Vista',
    'Gerald',
    'Berger',
    'Stanton',
    'East Meredith',
    'Newfield',
    'Brookville',
    'Dateland',
    'Bliss',
    'Rocky Ford',
    'Fowler',
    'Swink',
    'Drummond',
    'Manzanola',
    'Rockvale',
    'Black Eagle',
    'Martindale',
    'Archer City',
    'Hopkinton',
    'North Granby',
    'Cement',
    'Pocola',
    'Mountainburg',
    'Bartlett',
    'French Settlement',
    'Towanda',
    'Douglass',
    'Afton',
    'Ketchum',
    'Langley',
    'Delanson',
    'Central Bridge',
    'Harrington',
    'Waterport',
    'Byron',
    'Piffard',
    'Youngstown',
    'Ransomville',
    'Jefferson',
    'Canisteo',
    'Whitehall',
    'Hanapepe',
    'Kekaha',
    'Kealakekua',
    'Waimanalo',
    "Pa'auilo",
    'Pukalani',
    'Kurtistown',
    "'Ele'ele",
    'Kahuku',
    'Anahola',
    'Raven',
    'Smock',
    'Forestville',
    'Tangerine',
    'Liberty',
    'Omega',
    'Eastaboga',
    'Ekalaka',
    'Baker',
    'Roundup',
    'Lambert',
    'Melstone',
    'East Fairview',
    'Circle',
    'Roy',
    'Custer',
    'Winnett',
    'Savage',
    'Deanville',
    'McCoy',
    'Lower Salem',
    'Valles Mines',
    'Morrisonville',
    'Maunaloa',
    'Ninole',
    "Hale'iwa",
    'Spring Valley',
    'Westland',
    'Summit',
    'Fowler',
    'Millersville',
    'Cosmopolis',
    'The Rock',
    'Spiceland',
    'Hope',
    'Woodburn',
    'Saratoga',
    'Mount Jackson',
    'Buena Vista',
    'Rockton',
    'North Apollo',
    'Yukon',
    'Gastonville',
    'Tuolumne',
    'North Salem',
    'Defiance',
    'Thomasville',
    'New Berlinville',
    'Lumberville',
    'Melrose',
    'Raymond',
    'Center Hill',
    'Esmont',
    'Prospect',
    'Lafayette',
    'Howard',
    'Harrod',
    'Butler',
    'Cummings',
    'Simpsonville',
    'Manchaug',
    'Blaine',
    'Shacklefords',
    'Steamburg',
    'Yulan',
    'Sparrow Bush',
    'Westbrookville',
    'Augusta',
    'Delray',
    'Paw Paw',
    'Slanesville',
    'Petersburg',
    'High View',
    'Rio',
    'Spencer',
    'Lockwood',
    'Van Etten',
    'Himrod',
    'Erin',
    'Harford',
    'Trout Creek',
    'Richford',
    'Chemung',
    'Willseyville',
    'Smithville Flats',
    'Leonardsville',
    'Glendale',
    'Selma',
    'Cave Junction',
    'Days Creek',
    'New Boston',
    'Aledo',
    'Joy',
    'Stamford',
    'Ophiem',
    'Fenton',
    'Oquawka',
    'Cordova',
    'Osco',
    'Hillsdale',
    'Erie',
    'Sherrard',
    'Orion',
    'Dolan Springs',
    'Lynd',
    'Le Center',
    'Cosmos',
    'Elysian',
    'Arlington',
    'Jasper',
    'Henderson',
    'Porter',
    'Minneota',
    'Hanley Falls',
    'Harshaw',
    'Rib Lake',
    'Latimer',
    'Gresham',
    'West Leyden',
    'Forestport',
    'Hampton',
    'Edgewood',
    'Brownstown',
    'Kinmundy',
    'Shumway',
    'Ramsey',
    'Sandoval',
    'Saint Elmo',
    'Tower Hill',
    'Saint Peter',
    'Ivydale',
    'Oakvale',
    'Premier',
    'Clay',
    'Birch River',
    'Homedale',
    'Stanley',
    'Garden Valley',
    'White Bird',
    'Fieldon',
    'Hardin',
    'Hettick',
    'Athens',
    'Newton',
    'Spanishburg',
    'Seaton',
    'Mason City',
    'Randolph',
    'North Henderson',
    'Hamilton',
    'Stronghurst',
    'Cameron',
    'Reynolds',
    'Nauvoo',
    'Quakake',
    'Granville Summit',
    'Capon Bridge',
    'Maysville',
    'Moorefield',
    'Concord',
    'Upper Jay',
    'Hamburg',
    'Batchtown',
    'Loretto',
    'Kandiyohi',
    'Atwater',
    'Vernon',
    'Fillmore',
    'Oak City',
    'Shanks',
    'Saint Marys',
    'Rushville',
    'Kayenta',
    'Oxford',
    'Alturas',
    'Oak Run',
    'Palo Cedro',
    'Millville',
    'Banks',
    'Lynn Center',
    'Slayton',
    'Wilmont',
    'La Sal',
    'Troy',
    'Bullville',
    'Monterey',
    'Lumberport',
    'Brohard',
    'Letart',
    'Charter Oak',
    'New Meadows',
    'Pollock',
    'Erieville',
    'Gilbertsville',
    'Burton',
    'Many Farms',
    'Saint Michaels',
    'Kerrick',
    'Baxter',
    'Ogden',
    'Ava',
    'Crab Orchard',
    'Pleasant Hill',
    'Rosedale',
    'Belmond',
    'Orleans',
    'Green Isle',
    'Excel',
    'Odin',
    'Mountain Lake',
    'Carlin',
    'Hinton',
    'Sheep Springs',
    'Woodruff',
    'Kimball',
    'Tampico',
    'Blue Mountain Lake',
    'Carson',
    'Sac City',
    'Griswold',
    'Arispe',
    'Thornton',
    'Dimock',
    'Benld',
    'Gillespie',
    'Bella Vista',
    'Whitmore',
    'Cassel',
    'Sprakers',
    'Waterville',
    'Wells',
    'Kelayres',
    'Portal',
    'Speculator',
    'Cuba',
    'Navajo',
    'Shiprock',
    'Lindsay',
    'Tsaile',
    'New Hampton',
    'Tishomingo',
    'Cromwell',
    'Alborn',
    'Pierson',
    'Brunsville',
    'Ashton',
    'George',
    'Battle Creek',
    'Welcome',
    'Truman',
    'Trimont',
    'Butterfield',
    'Littleton',
    'Greenview',
    'Maybrook',
    'Gainesville',
    'Westley',
    'Argonne',
    'Rupert',
    'Cowen',
    'Camp Creek',
    'Procious',
    'Grassy Meadows',
    'Perdido',
    'Wanamingo',
    'Humboldt',
    'DeRuyter',
    'Smyrna',
    'Georgetown',
    'Eaton',
    'Plymouth',
    'Constableville',
    'Battle Ground',
    'Guinda',
    'Lehigh',
    'Silver Springs',
    'Teec Nos Pos',
    'Mendon',
    'Blacksville',
    'Adrian',
    'Avoca',
    'Sheffield',
    'Leigh',
    'Newman Grove',
    'Wallback',
    'Diana',
    'Duck',
    'Brooten',
    'Piasa',
    'Kampsville',
    'New Creek',
    'Alden',
    'Kiester',
    'Lyle',
    'Ellendale',
    'Hollandale',
    'Swaledale',
    'Rowan',
    'Collinston',
    'Rodman',
    'Long Lake',
    'Broadalbin',
    'Belleville',
    'Martville',
    'Red Creek',
    'Davy',
    'Erbacon',
    'Bramwell',
    'Jacobson',
    'Meadowlands',
    'Fairfield',
    'Big Falls',
    'Bloomery',
    'Yellow Spring',
    'Springfield',
    'Wardensville',
    'Hanover',
    'Fountain Run',
    'Fort Sumner',
    'Nara Visa',
    'San Jose',
    'San Jon',
    'Corona',
    'Roy',
    'Ribera',
    'Serafina',
    'Yellville',
    'Omaha',
    'Lead Hill',
    'Belfast',
    'Canehill',
    'Morrow',
    'Castleberry',
    'Roseland',
    'Campbell',
    'Inland',
    'Guide Rock',
    'Hildreth',
    'Champion',
    'Donegal',
    'Fifty Lakes',
    'Emily',
    'Glenbrook',
    'Genoa',
    'Phoenicia',
    'Olivebridge',
    'Alfred',
    'Cameron Mills',
    'Maryland',
    'Oakfield',
    'Tioga',
    'Elkland',
    'Osceola',
    'Nassau',
    'Schroon Lake',
    'Limerick',
    'Fort Fairfield',
    'Chocorua',
    'East Berne',
    'Diamond Point',
    'Bolton Landing',
    'Collins',
    'North Collins',
    'Clarence',
    'Peru',
    'Memphis',
    'Arimo',
    'Shinglehouse',
    'Hardin',
    'Collegeport',
    'Mattawa',
    'Orondo',
    'DeMossville',
    'Newtonsville',
    'Camp Dennison',
    'Bellevue',
    'Melbourne',
    'Kings Mills',
    'San Saba',
    'Mullin',
    'Clarion',
    'Lily Dale',
    'Portland',
    'Crawford',
    'Ruth',
    'Lamont',
    'Big Bend',
    'Parma',
    'Bragg City',
    'Steele',
    'Hancocks Bridge',
    'Charlotte Hall',
    'Elk Mills',
    'Saint Marks',
    'New Troy',
    'Mascotte',
    'Waskom',
    'Brunswick',
    'Rosemont',
    'McKenna',
    'Gilsum',
    'Lahaska',
    'Marsteller',
    'Lanse',
    'Falls Creek',
    'Sandy Ridge',
    'Fairhope',
    'Bolivar',
    'Clymer',
    'Green Village',
    'Three Bridges',
    'Cooksville',
    'Albion',
    'New Vernon',
    'Avon-by-the-Sea',
    'Chester',
    'Mill River',
    'Sheffield',
    'Cummington',
    'Carthage',
    'Franklinville',
    'Westernport',
    'Ammon',
    'McGraw',
    'New York Mills',
    'Richfield Springs',
    'Noxapater',
    'Apple River',
    'Scales Mound',
    'Gratiot',
    'Boonville',
    'Crownpoint',
    'Mount Sterling',
    'Medicine Park',
    'West Glover',
    'Reedville',
    'Claflin',
    'Cedarville',
    'Margaretville',
    'Fleischmanns',
    'Roxbury',
    'Goldfield',
    'Farmington',
    'Glenn',
    'Crestline',
    'Braham',
    'Bloomington Springs',
    'Argos',
    'Pierron',
    'Kyles Ford',
    'Duffield',
    'Wauneta',
    'Holliday',
    'Randlett',
    'Oklaunion',
    'Munday',
    'Mapleton',
    'Congerville',
    'Bradford',
    'Triumph',
    'Nerstrand',
    'Williamsville',
    'McDowell',
    'Deerfield',
    'Big Lake',
    'Rawson',
    'Monroe',
    'Minford',
    'Waldron',
    'Londonderry',
    'Kingston',
    'Johnston',
    'Kempton',
    'Ripplemead',
    'Grove Oak',
    'Roberta',
    'Box Springs',
    'Clio',
    'Knoxville',
    'Doyle',
    'Twin Valley',
    'Eagle Bend',
    'Henning',
    'Ponsford',
    'Akeley',
    'Vergas',
    'Waubun',
    'Longville',
    'Marcell',
    'Menahga',
    'Ulen',
    'Dent',
    'Ottertail',
    'Bertha',
    'Cotton',
    'Parkers Prairie',
    'Turners Station',
    'Bedford',
    'Austin',
    'Floyds Knobs',
    'Clermont',
    'New Castle',
    'Borden',
    'Alexandria',
    'Greenville',
    'Fort Branch',
    'Tuscarawas',
    'Dennison',
    'Beloit',
    'Saint Matthews',
    'Haubstadt',
    'West Point',
    'Buckner',
    'Lynnville',
    'Malabar',
    'Grant',
    'Millwood',
    'Pickens',
    'Chunky',
    'Edneyville',
    'Bagdad',
    'Wisner',
    'Black Creek',
    'Freeburn',
    'Stony Point',
    'Jacks Creek',
    'Gadsden',
    'Elkton',
    'Pierre Part',
    'Riddleton',
    'Florahome',
    'Steinhatchee',
    'Remlap',
    'Springfield',
    'Guthrie',
    'Hanson',
    'Sacramento',
    'Centertown',
    'Crab Orchard',
    'Nettleton',
    'Pembroke Pines',
    'Gray',
    'Webb',
    'Orchard Hill',
    'Cedar Grove',
    'Mayo',
    'Norris',
    'McLeansville',
    'Loranger',
    'Natalbany',
    'Cedar Key',
    'Evangeline',
    'Chiefland',
    'Hagan',
    'College Grove',
    'Preston',
    'Hastings',
    'Brownsboro',
    'Primm Springs',
    'Crofton',
    'Woodburn',
    'Denmark',
    'Springville',
    'Enterprise',
    'Bagdad',
    'Wadmalaw Island',
    'Cleveland',
    'Micanopy',
    'Lake Junaluska',
    'Bonlee',
    'Ransom',
    'Raccoon',
    'Sharon',
    'Arnoldsville',
    'Grover',
    'Otis',
    'Clarks',
    'Edgard',
    'Tallapoosa',
    'Seale',
    'Ellerslie',
    'Wellborn',
    'Winona',
    'Jarrell',
    'New Llano',
    'Mason',
    'Gauley Bridge',
    'Stone',
    'Seth',
    'Sophia',
    'Pratt',
    'Williamson',
    'Beauty',
    'Dewar',
    'Grimesland',
    'Robersonville',
    'Kaleva',
    'Harrietta',
    'Table Grove',
    'Gilson',
    'Lomax',
    'Smithshire',
    'Blandinsville',
    'Tennessee',
    'La Harpe',
    'Oneida',
    'Rio',
    'Robbins',
    'New London',
    'Staley',
    'Glenburn',
    'Bottineau',
    'Velva',
    'Sawyer',
    'Granville',
    'Towner',
    'Butte',
    'Lansford',
    'Newburg',
    'Deering',
    'Vienna',
    'Litchfield Plains',
    'Springfield',
    'Naples',
    'Brownfield',
    'East Stoneham',
    'Smyrna Mills',
    'Tonto Basin',
    'Brodheadsville',
    'Lawton',
    'Ferron',
    'Clawson',
    'Sobieski',
    'Sitka',
    'Martha',
    'Hagerhill',
    'Grethel',
    'Betsy Layne',
    'Walhalla',
    'Milton',
    'Belcourt',
    'Emery',
    'Hanksville',
    'Castle Dale',
    'Chilo',
    'Camden',
    'Green Springs',
    'Ellerbe',
    'Dillsboro',
    'Almena',
    'Dallas',
    'Anthon',
    'Mass City',
    'Trout Creek',
    'Shingleton',
    'Westfield',
    'Orient',
    'Sinclair',
    'Bath',
    'Viola',
    'Turbeville',
    'Scranton',
    'Gable',
    'Alcolu',
    'Horse Shoe',
    'Castle Hayne',
    'Lake Toxaway',
    'Yuma',
    'Plantersville',
    'Jewell',
    'Egan',
    'Eupora',
    'Cross City',
    'Hickory',
    'Osyka',
    'Fayette',
    'Mantee',
    'Buckhead',
    'Big Pine Key',
    'Woodleaf',
    'Mount Ulla',
    'Alexis',
    'Casar',
    'Spindale',
    'Mount Vernon',
    'Finchville',
    'Bronson',
    'Williamsport',
    'Shannon',
    'Redwood',
    'Gore Springs',
    'Vandiver',
    'Quinton',
    'Sumiton',
    'Red Banks',
    'Ridgecrest',
    'Pelham',
    'Flintville',
    'Lydia',
    'Estherwood',
    'Baxter',
    'Whitesville',
    'Stapleton',
    'Gibsland',
    'Carlisle',
    'Valley Head',
    'Lakemont',
    'Fouke',
    'Webberville',
    'Atkins',
    'Lemoyne',
    'Clinton',
    'Chireno',
    'Salineville',
    'Lake Village',
    'Lewistown',
    'Foley',
    'Bolckow',
    'Caruthers',
    'Terlingua',
    'New Holland',
    'Yosemite Valley',
    'Reese',
    'Hurley',
    'City of Angels',
    'Woodland',
    'Ellendale',
    'Coahoma',
    'Gloster',
    'Guys',
    'Michie',
    'Sagamore',
    'Hill',
    'Plymouth',
    'Woods Hole',
    'Glover',
    'Perkinsville',
    'East Barre',
    'East Randolph',
    'Bowdoin Center',
    'Wilder',
    'Newbury Old Town',
    'Cascade',
    'Carver',
    'Keezletown',
    'Cusseta',
    'Garvin',
    'Galliano',
    'Golden Meadow',
    'Ridgway',
    'Waverly',
    'Trevor',
    'Saint Joe',
    'Leslie',
    'Bassfield',
    'Pawnee City',
    'Porter',
    'Gresham',
    'Brownville',
    'Milan',
    'Beaver Crossing',
    'Lake Butler',
    'Conneautville',
    'Wallingford',
    'Wickes',
    'Morgan',
    'Nocona',
    'Walnut Springs',
    'Covington',
    'Zavalla',
    'Bronson',
    'Colmesneil',
    'White Springs',
    'Dill City',
    'Anahuac',
    'Allenwood',
    'Picture Rocks',
    'Hastings',
    'Bee Spring',
    'Carlton',
    'Folkston',
    'Glennville',
    'Reidsville',
    'Saint George',
    'Bennet',
    'Broadway',
    'Crandall',
    'Townville',
    'Ambrose',
    'Reddick',
    'Burr',
    'Dunbar',
    'Fairfield',
    'Johnson',
    'Crete',
    'Dwight',
    'Collins',
    'Odum',
    'Caneyville',
    'Russell',
    'Quincy',
    'Garrison',
    'McAlpin',
    'Oran',
    'Delhi',
    'Worthington',
    'Douglass',
    'Wheeler',
    'Coffey',
    'McFall',
    'Pattonsburg',
    'Washington',
    'Blandburg',
    'Amsterdam',
    'Tustin',
    'Andreas',
    'Mahanoy City',
    'Gordon',
    'Damascus',
    'Bartonsville',
    'Richfield',
    'Malta',
    'Vale',
    'Bellevue',
    'Mendon',
    'Plymouth',
    'Ehrenberg',
    'Palo Verde',
    'Cibola',
    'Ogdensburg',
    'Haworth',
    'Allenwood',
    'Pittsville',
    'Milladore',
    'Browntown',
    'Albany',
    'Kerkhoven',
    'Sunburg',
    'Murdock',
    'Grover Hill',
    'Saint Paul',
    'Mount Hope',
    'Nineveh',
    'Hackensack',
    'Miranda',
    'Upperville',
    'Garden City',
    'Willow Lake',
    'Elkton',
    'Hayti',
    'Bryant',
    'White',
    'Gary',
    'Lake Norden',
    'Florence',
    'LaBolt',
    'Revillo',
    'Brandt',
    'Aurora',
    'Poland',
    'Quincy',
    'Volga',
    'Kiowa',
    'La Joya',
    'Fairburn',
    'Isabel',
    'Beulah',
    'Shawnee',
    'Guffey',
    'Boulevard',
    'Boyd',
    'Esperance',
    'Riverside',
    'Jumping Branch',
    'Virgilina',
    'Saint Paul',
    'Galway',
    'River Pines',
    'Pinecrest',
    'Mountain Ranch',
    'Lucas',
    'Winston',
    'Fentress',
    'Ramah',
    'Scipio Center',
    'Marble Rock',
    'Rippey',
    'Buxton',
    'Moro',
    'Wanette',
    'Orange Springs',
    'Princeton',
    'Leopold',
    'Hughesville',
    'Lakewood',
    'Glenallen',
    'De Kalb Junction',
    'Simon',
    'Westford',
    'Sandstone',
    'Abiquiu',
    'Timbo',
    'Hydesville',
    'Rosebud',
    'Milam',
    'Bardwell',
    'Elysian Fields',
    'Kinston',
    'Le Roy',
    'Leland',
    'Cumberland Furnace',
    'Ceresco',
    'Harrisville',
    'Gallipolis Ferry',
    'Mount Vision',
    'Cataldo',
    'Bliss',
    'Downieville',
    'Harper',
    'Schenevus',
    'Reedsville',
    'Grand Marais',
    'Ninety Six',
    'Clopton',
    'Madill',
    'Gracemont',
    'Glennville',
    'Winterset',
    'Thornton',
    'Drain',
    'Cherry Valley',
    'Pendleton',
    'Zalma',
    'Clark',
    'Griffithsville',
    'Sod',
    'Amherstdale',
    'Peach Creek',
    'Lenore',
    'Kermit',
    'Belfry',
    'Rich Creek',
    'East Bank',
    'Julian',
    'Kegley',
    'Peterstown',
    'Wyco',
    'Wayne',
    'Lester',
    'Arnett',
    'Naoma',
    'Crab Orchard',
    'Salesville',
    'Buffalo',
    'Rainelle',
    'Holden',
    'Wheelwright',
    'Accoville',
    'Lovely',
    'Stollings',
    'Newbury Center',
    'Hiram',
    'Dixie',
    'Boomer',
    'Dry Creek',
    'Piney View',
    'Montgomery',
    'Everetts',
    'Harper',
    'Samoa',
    'Rio Dell',
    'Ingalls',
    'Crystal Springs',
    'Steele City',
    'Auburn',
    'Spraggs',
    'Rices Landing',
    'Closplint',
    'Middleburg',
    'Flatwoods',
    'Ravenna',
    'Burkeville',
    'Double Springs',
    'Newton',
    'Ozark',
    'Dover',
    'Colcord',
    'New Blaine',
    'Chancellor',
    'Osceola',
    'Sparta',
    'Lake City',
    'Cadet',
    'Eminence',
    'Warrens',
    'Ontario',
    'Ash Flat',
    'Walnut Shade',
    'Everton',
    'Luna Pier',
    'East Jordan',
    'Carp Lake',
    'Harrisville',
    'Saint Helen',
    'Conway',
    'Onekama',
    'South Roxana',
    'Milligan',
    'Wildwood',
    'Plattenville',
    'Jay',
    'Branch',
    'Catawba',
    'Summerfield',
    'Beckemeyer',
    'Fidelity',
    'Germantown',
    'Beaverton',
    'Arcadia',
    'Leadwood',
    'Knob Noster',
    'Breckenridge',
    'Rosebush',
    'Lake Nebagamon',
    'Highland',
    'Alanson',
    'Avon',
    'Buxton',
    'Rodanthe',
    'Sycamore',
    'Kellyton',
    'Notasulga',
    'Keene Valley',
    'Malaga',
    'Carrsville',
    'Bay City',
    'Winchester',
    'Onsted',
    'Truro',
    'Gladys',
    'Sykesville',
    'Rockland',
    'Lederach',
    'Hydeville',
    'Laytonville',
    'Bolton',
    'Barnum',
    'Pengilly',
    'Kearny',
    'Dorena',
    'Crescent',
    'Blue Grass',
    'Nyssa',
    'Ramona',
    'De Smet',
    'Caputa',
    'Walnut Cove',
    'Aulander',
    'Ahoskie',
    'Murfreesboro',
    'Gratis',
    'Brodnax',
    'Simpsonville',
    'Upper Falls',
    'Ware Neck',
    'King and Queen Court House',
    'Clover',
    'Sumerduck',
    'Center Sandwich',
    'Putnam Station',
    'Otto',
    'Westernville',
    'Stony Creek',
    'Shermans Dale',
    'Methow',
    'Springdale',
    'Paris',
    'Drewryville',
    'Spring Grove',
    'Capron',
    'Clements',
    'Ivor',
    'Blair',
    'Rolesville',
    'Cornersville',
    'Smyrna',
    'Union Mills',
    'Sylvania',
    'Bremen',
    'Lumpkin',
    'White',
    'Metcalf',
    'Cornell',
    'Bethel',
    'Brogue',
    'Mechanicsburg',
    'Portola',
    'Guatay',
    'Santa Ysabel',
    'Lamy',
    'Claypool',
    'Schodack Landing',
    'Mount Cory',
    'Frazeysburg',
    'Mauriceville',
    'Rogers',
    'Utica',
    'Whittemore',
    'Greeley',
    'Alden',
    'Cairo',
    'Lewis',
    'Exeter',
    'Wellsville',
    'Buncombe',
    'Bakersfield',
    'Ozark',
    'Driftwood',
    'Winslow',
    'Berry',
    'Carthage',
    'Brooklyn',
    'Cassatt',
    'Charlotteville',
    'Simsboro',
    'Nickerson',
    'Valier',
    'Ridgedale',
    'Mound City',
    'Stafford',
    'Bynum',
    'Inchelium',
    'Saginaw',
    'Marine on Saint Croix',
    'Waldorf',
    'Stringer',
    'Milroy',
    'Durango',
    'Alexandria',
    'Troup',
    'Mesick',
    'Foster',
    'Loon Lake',
    'Hattieville',
    'Wister',
    'Birchwood',
    'Gadsden',
    'Hopland',
    'Castalia',
    'Belle Valley',
    'Stone Creek',
    'Wiergate',
    'Kilbourne',
    'Aviston',
    'Sharon Springs',
    'Houghton',
    'Redfield',
    'Sand Lake',
    'Barton',
    'Slocomb',
    'Poulan',
    'Battleboro',
    'Milano',
    'Baker',
    'Windsor',
    'Fremont',
    'Rosewood',
    'Florence',
    'Cana',
    'West Manchester',
    'Husum',
    'Russells Point',
    'Chilhowee',
    'Ponce de Leon',
    'Bethel',
    'Mouth of Wilson',
    'Calhoun',
    'Schuyler',
    'Scotland',
    'Martinsburg',
    'Cocolamus',
    'Kerens',
    'Hobbsville',
    'Shannon',
    'Union Hall',
    'Overbrook',
    'Patrick Springs',
    'Florence',
    'Weber City',
    'Harveyville',
    'Pomona',
    'Hoyt',
    'Partridge',
    'Kincaid',
    'Shoshone',
    'Harrells',
    'White Oak',
    'Tar Heel',
    'Kelly',
    'Evergreen',
    'Jacksonville',
    'Cairo',
    'De Witt',
    'Keytesville',
    'Atlanta',
    'Drift',
    'Lowmansville',
    'Waneta',
    'Gunlock',
    'Georgetown',
    'Ahwahnee',
    'Lakeville',
    'Mosier',
    'Haines',
    'Webster',
    'Penn',
    'McVille',
    'Tower City',
    'Hope',
    'Walshville',
    'New Harmony',
    'High Falls',
    'Salisbury Mills',
    'Neversink',
    'South Fallsburg',
    'Pine Hill',
    'Walker Valley',
    'Groveland',
    'Browder',
    'Drakesboro',
    'Eastham',
    'North Clarendon',
    'Somerset Center',
    'Monte Rio',
    'Baltic',
    'Whately',
    'West Chatham',
    'Onset',
    'Soledad',
    'Viper',
    'Happy',
    'Rousseau',
    'Frazer',
    'Opheim',
    'Hobson',
    'Froid',
    'Rudyard',
    'Moccasin',
    'Nye',
    'Sheridan',
    'Twin Bridges',
    'Homestead',
    'Highwood',
    'Antelope',
    'New Bavaria',
    'Holgate',
    'West Unity',
    'Pierce',
    'Loa',
    'Wells',
    'Grouse Creek',
    'Peridot',
    'Bylas',
    'Birchleaf',
    'Sanborn',
    'Aurelia',
    'Robinson',
    'Everest',
    'Denton',
    'Lancaster',
    'Whiting',
    'West Park',
    'Humboldt',
    'Lebanon',
    'Buckeystown',
    'Everglades City',
    'Ruby',
    'Hilliard',
    'Joiner',
    'Marydel',
    'Isleta',
    'Harris',
    'Shady Side',
    'Galesville',
    'Stone Harbor',
    'Pottersville',
    'West Barnstable',
    'North Hatfield',
    'Benwood',
    'Glendale',
    'Bunola',
    'Meadow Lands',
    'Cuddy Hill',
    'Mount Sidney',
    'Townsend',
    'Boston',
    'Roland',
    'Dallas',
    'Hostetter',
    'Forest Knolls',
    'Easton',
    'Preston City',
    'Houston',
    'Guy',
    'Pattison',
    'Morristown',
    'New Market',
    'Westpoint',
    'Leoma',
    'Five Points',
    'Harlan',
    'Loyall',
    'Vivian',
    'Kitts Hill',
    'Gadsden',
    'Livermore',
    'Strong',
    'Boothbay',
    'Mapleton',
    'Washburn',
    'Center Conway',
    'Lincoln',
    'Windsor',
    'Cumberland Foreside',
    'Diamond',
    'Sheffield Lake',
    'Catlin',
    'Somerset',
    'Warren',
    'Woodsboro',
    'Ocotillo',
    'Durham',
    'Maud',
    'Allenton',
    'Stinnett',
    'Freedom',
    'Stanberry',
    'Leonard',
    'Wheatland',
    'Alvin',
    'Garden Valley',
    'Dutch Flat',
    'Vermontville',
    'Crane',
    'Access',
    'Niobe',
    'Nester',
    'Spain',
    'Darmstadt',
    'Italia',
    'Blue',
    'Box Elder',
    'Babbitt',
    'Wessington Springs',
    'Sabin',
    'Leonard',
    'Kindred',
    'Amenia',
    'Huron',
    'Blanco',
    'Bergheim',
    'Waelder',
    'Harwood',
    'Enon',
    'Transfer',
    'Lyon',
    'Midway City',
    'Riverside',
    'Valley Mills',
    'Dragoon',
    'Saint David',
    'McNeal',
    'Merton',
    'Maurice',
    'Ellsworth',
    'Little Rock',
    'Ireton',
    'Glens Fork',
    'Burlington',
    'Ballantine',
    'Shell',
    'Joliet',
    'Phillips',
    'Marquette',
    'Hampton',
    'Alda',
    'Huntingburg',
    'Grandview',
    'Dale',
    'Gentryville',
    'Riverton',
    'South Willington',
    'Quinebaug',
    'Mansfield',
    'Cottage Hills',
    'Climax Springs',
    'Saluda',
    'Midway Park',
    'Isle of Wight',
    'Montezuma',
    'Oakfield',
    'Empire',
    'Willsboro',
    'Fayville',
    'New Memphis',
    'Merrill',
    'Suttons Bay',
    'Honor',
    'Kingsley',
    'Brimley',
    'Proctor',
    'Senath',
    'Arbyrd',
    'Eagle Bridge',
    'Patrick',
    'McBee',
    'Jefferson',
    'Ruby',
    'Bloomburg',
    'Genoa',
    'McClure',
    'Bluejacket',
    'Big Cabin',
    'Henderson',
    'Friona',
    'Windthorst',
    'Newcastle',
    'Woodson',
    'Loving',
    'Marengo',
    'Grand View',
    'Clam Lake',
    'Brule',
    'Southfields',
    'Essex Fells',
    'Croton Falls',
    'Roosevelt',
    'Chili',
    'Elnora',
    'Bonduel',
    'Bainbridge',
    'Norway',
    'Decatur',
    'Cyril',
    'Kings Bay Base',
    'Asotin',
    'Telogia',
    'Stoutland',
    'Collins',
    'Saint Nazianz',
    'Wittenberg',
    'Tigerton',
    'Tom Bean',
    'Gwynedd',
    'Goshen',
    'Monterey',
    'Plainfield',
    'Glen Campbell',
    'Russell',
    'Patton',
    'New Derry',
    'Coburn',
    'New Florence',
    'Belle Haven',
    'Milton',
    'Kiamesha Lake',
    'Tillson',
    'Locke',
    'Groton',
    'Wampsville',
    'Colden',
    'Portville',
    'Thaxton',
    'South Egremont',
    'East Otis',
    'Worthington Center',
    'Appleton',
    'McAlisterville',
    'Henryville',
    'Modena',
    'Amawalk',
    'Sipesville',
    'Tire Hill',
    'Austin',
    'Oliveburg',
    'Boalsburg',
    'Pungoteague',
    'Block Island',
    'Mifflinville',
    'Fort Hall',
    'Macksburg',
    'Clearlake',
    'Gleason',
    'Cobb',
    'Princeton',
    'Hubbard',
    'Bancroft',
    'Waukon',
    'Alexis',
    'New Market',
    'Neponset',
    'Hartford',
    'West Blocton',
    'Batchelor',
    'Cheneyville',
    'Cat Spring',
    'Belle Rose',
    'Donner',
    'Bayfield',
    'Wakefield',
    'Marion',
    'Cedar',
    'Central Lake',
    'Pellston',
    'Pentwater',
    'East Lake',
    'Au Train',
    'Rubicon',
    'Carrollton',
    'Fife Lake',
    'Lake Ann',
    'Montgomery City',
    'Moline',
    'Rollingstone',
    'Kelso',
    'Tuscumbia',
    'Painesdale',
    'Malone',
    'Finger',
    'Prairie Farm',
    'Marine',
    'Ewen',
    'Wakefield',
    'Watersmeet',
    'Waverly',
    'Fish Creek',
    'Baileys Harbor',
    'Richburg',
    'Dalton',
    'Gurley',
    'Endicott',
    'Morristown',
    'Farwell',
    'Ainsworth',
    'Fonda',
    'Randolph',
    'Arenas Valley',
    'Wittmann',
    'Waukee',
    'Prairie City',
    'Goodland',
    'Beaver Bay',
    'Biwabik',
    'Omaha',
    'Mount Tremper',
    'Germanton',
    'Atlantic',
    'McCool Junction',
    'South Bend',
    'Rising City',
    'Tecumseh',
    'Ithaca',
    'Emmet',
    'Brooklyn',
    'Grand Junction',
    'Shellman',
    'Winnie',
    'Cannon',
    'Rush',
    'Jasper',
    'Lowell',
    'Velarde',
    'Dixon',
    'Fairmont',
    'Glendale',
    'Emerson',
    'Jeff',
    'Heidrick',
    'Partridge',
    'Keavy',
    'Karnack',
    'Linden',
    'Avery',
    'Annona',
    'Pattonville',
    'Maud',
    'Joaquin',
    'Canute',
    'Mountain View',
    'Dierks',
    'Roosevelt',
    'Morrisville',
    'Brighton',
    'Fair Play',
    'Delight',
    'Silva',
    'Ellsinore',
    'Rose Bud',
    'Houston',
    'Prattsville',
    'Peach Orchard',
    'Adona',
    'Combs',
    'Bigelow',
    'Perry',
    'Blooming Grove',
    'North Zulch',
    'Bent',
    'Laurelton',
    'Grampian',
    'Distant',
    'Weedville',
    'Fairmont City',
    'Kennedy',
    'Coalport',
    'Corsica',
    'Driftwood',
    'Cranesville',
    'Wellman',
    'Amherst',
    'Whiteface',
    'Cameron',
    'Howe',
    'Saint Elizabeth',
    'Iberia',
    'Barnsdall',
    'Kennerdell',
    'Elmore City',
    'Jameson',
    'Dearborn',
    'Loop',
    'Ropesville',
    'Pettigrew',
    'Saint Louisville',
    'Elko',
    'Gordon',
    'Big Creek',
    'Paint Lick',
    'Trosper',
    'Ary',
    'Throckmorton',
    'Clatonia',
    'Louisville',
    'Pleasant Hope',
    'Hallam',
    'Denton',
    'Truchas',
    'Cordell',
    'Corn',
    'Glenwood',
    'Sandy Ridge',
    'Risingsun',
    'Bradner',
    'Willshire',
    'Russia',
    'Lake Monroe',
    'Jeffersonville',
    'Rockhouse',
    'Eastern',
    'Stanton',
    'Hamer',
    'Harrison',
    'Oakdale',
    'Colfax',
    'Ball',
    'Pollock',
    'Castor',
    'Decatur',
    'Georgetown',
    'Calypso',
    'Pomaria',
    'Reidville',
    'Willard',
    'Shubuta',
    'Spearsville',
    'Sibley',
    'Harrisonburg',
    'Grambling',
    'Hodge',
    'Bolingbroke',
    'Unadilla',
    'Red Bay',
    'Courtland',
    'Spruce Pine',
    'Munford',
    'Cleveland',
    'Mooresburg',
    'Philadelphia',
    'Nunnelly',
    'Wartrace',
    'Lowndesboro',
    'Cecil',
    'Titus',
    'Fulton',
    'Pearlington',
    'Calvert',
    'Chunchula',
    'Leroy',
    'Manitou',
    'Eddyville',
    'Mortons Gap',
    'Eddyville',
    'Symsonia',
    'Saint Charles',
    'Dukedom',
    'Dixon',
    'Stapleton',
    'Bailey',
    'Little Rock',
    'Lauderdale',
    'Washington',
    'Centreville',
    'Silver Creek',
    'Braxton',
    'Potts Camp',
    'Liberty',
    'Jayess',
    'Bellefontaine',
    'Brooksville',
    'Sturgis',
    'Alamance',
    'Society Hill',
    'Caledonia',
    'Sarahsville',
    'Midvale',
    'New Franken',
    'Peapack',
    'Linden',
    'Seven Valleys',
    'Jamestown',
    'Cross',
    'Saxon',
    'Suring',
    'Indianola',
    'Hingham',
    'Lodge Grass',
    'Geneva',
    'Waterville',
    'Grand Meadow',
    'Good Thunder',
    'Wrenshall',
    'Maupin',
    'Dakota',
    'Edwall',
    'Davenport',
    'Viola',
    'La Farge',
    'McFarland',
    'Paia',
    'Caney',
    'Milburn',
    'Coleman',
    'Caddo',
    'Moores Hill',
    'Danville',
    'Plainview',
    'Ola',
    'Havana',
    'Gravelly',
    'Harvey',
    'Williamsport',
    'East Point',
    'West Van Lear',
    'Meally',
    'Van Lear',
    'Roslyn',
    'Britton',
    'Langford',
    'Pierpont',
    'Spencer',
    'Seaford',
    'Pine Valley',
    'Carter Lake',
    'Cave Springs',
    'East Irvine',
    'Waterloo',
    'Cashion',
    'Westover',
    'Madison',
    'Union',
    'Goshen',
    'Burket',
    'Rio Nido',
    'Oregon House',
    'Palermo',
    'Wyanet',
    'Fairmount',
    'Milton',
    'Dublin',
    'Niantic',
    'Coatsburg',
    'Salisbury',
    'Vallonia',
    'Medora',
    'Sulphur Springs',
    'Sergeantsville',
    'Olympia Fields',
    'Stinesville',
    'Fair Haven',
    'Sells',
    'Gwynedd Valley',
    'Pomeroy',
    'Selma',
    'Eaton',
    'Bendersville',
    'Conestoga',
    'Ramey',
    'Schaefferstown',
    'Manns Choice',
    'Yorkville',
    'Industry',
    'Manorville',
    'Newell',
    'Leetsdale',
    'Laughlintown',
    'Saint Boniface',
    'Steilacoom',
    'Glens',
    'Cold Springs',
    'Lowber',
    'Belvidere',
    'Avenal',
    'Port Hope',
    'Troy',
    'Deming',
    'Tumtum',
    'Elliston',
    'Walnut',
    'Linkwood',
    'Henderson',
    'Marydel',
    'Kirklin',
    'Centrahoma',
    'Kohler',
    'Paradise Valley',
    'Powell',
    'Coulterville',
    'Saint Mary',
    'Anthony',
    'Bay Port',
    'Orosi',
    'Spring Valley',
    'Inverness',
    'Lytle',
    'Bruni',
    'Gonzales',
    'Freer',
    'Shelbyville',
    'Altenburg',
    'Georgetown',
    'Chandler',
    'Boswell',
    'Bloomsdale',
    'Versailles',
    'Okay',
    'Wayne',
    'Waveland',
    'Louise',
    'Atascosa',
    'Port Bolivar',
    'Orange Cove',
    'Grantville',
    'Village Mills',
    'McEwen',
    'Richgrove',
    'Hines',
    'Ferrysburg',
    'Green Valley',
    'Iowa',
    'Basile',
    'Olla',
    'Shongaloo',
    'Bland',
    'Norwood',
    'Kissee Mills',
    'Williamsburg',
    'Marshall',
    'Jewell',
    'Clarksdale',
    'Jaroso',
    'Nabb',
    'Huson',
    'Wilsall',
    'Statenville',
    'Buffalo',
    'Patriot',
    'Purdon',
    'San Luis',
    'Berea',
    'Mount Storm',
    'Newberry Springs',
    'Hornbeck',
    'Eutaw',
    'Wingina',
    'West Finley',
    'Marionville',
    'Willis Wharf',
    'Greenbush',
    'Buskirk',
    'Wyoming',
    'Glendale',
    'Loch Sheldrake',
    'Junction City',
    'Everly',
    'Fairfield Bay',
    'Cleveland',
    'Choctaw',
    'Lake Buena Vista',
    'Taos',
    'Anthony',
    'Cranks',
    'Battle Mountain',
    'Tuckerman',
    'Konawa',
    'Lockney',
    'Dover',
    'Cutler',
    'Oilton',
    'Wallins Creek',
    'Maysville',
    'Karnes City',
    'Huron',
    'Tahoma',
    'Cheshire',
    'Collinsville',
    'Air Force Academy',
    'Beechmont',
    'Nageezi',
    'Linville',
    'Rocky Gap',
    'Morral',
    'Rushville',
    'Pleasantville',
    'Nashotah',
    'Lyons',
    'Farragut',
    'Douglassville',
    'Universal City',
    'Dixonville',
    'Madison',
    'Weir',
    'Reading',
    'Hankamer',
    'Alpha',
    'Etna',
    'Georgetown',
    'Acme',
    'Stratford',
    'Gaylordsville',
    'Island',
    'Oakland',
    'Montgomery',
    'Lake Helen',
    'Arrowsmith',
    'Smarr',
    'Burns',
    'Vienna',
    'Glencoe',
    'Alhambra',
    'Nortonville',
    'Allison',
    'Ethel',
    'Refugio',
    'Covington',
    'Montezuma',
    'Higden',
    'Woodbury',
    'Horseshoe Bay',
    'Rosedale',
    'Pittsville',
    'Trappe',
    'Tilghman',
    'North Amherst',
    'Russell',
    'Rowe',
    'Bausman',
    'Parish',
    'Mohawk',
    'Eden',
    'Rowena',
    'Pamplin',
    'Granville',
    'Trumbauersville',
    'Mount Victoria',
    'Monument Beach',
    'Snow Shoe',
    'Estell Manor',
    'Dorothy',
    'Sanford',
    'Adah',
    'Fairchance',
    'New Salem',
    'Spring Creek',
    'Swartswood',
    'Blooming Glen',
    'Kimberton',
    'Ingomar',
    'Glenelg',
    'Los Alamos',
    'Strathmore',
    'Middle Granville',
    'South Cairo',
    'Riner',
    'Spinnerstown',
    'Port Royal',
    'Waterville',
    'Harpers Ferry',
    'Caledonia',
    'Rushford',
    'Brownsville',
    'Houston',
    'Bloomfield',
    'Lindsay',
    'Terry',
    'Vida',
    'Buckley',
    'Roberts',
    'Milford',
    'Rankin',
    'East Lynn',
    'Campus',
    'Potomac',
    'Santa Fe',
    'Brinkley',
    'Spencerville',
    'Seeley',
    'Woodstock',
    'Highlandville',
    'Lansing',
    'Fairland',
    'San Diego',
    'Eastover',
    'North Adams',
    'Talco',
    'Richland',
    'Climax',
    'Sumner',
    'Arlington',
    'Stockton',
    'Shaw',
    'North Carrollton',
    'Lamar',
    'Hollandale',
    'Lena',
    'Polkville',
    'Henrietta',
    'Donie',
    'Maryneal',
    'Newhall',
    'Proctor',
    'Gorman',
    'Hartley',
    'Sutherland',
    'Marcus',
    'Ringwood',
    'White Lake',
    'Brookdale',
    'Green Sea',
    'Edwardsport',
    'Saint Francisville',
    'Clyde',
    'Deansboro',
    'Ludlow',
    'Edelstein',
    'Shellsburg',
    'North Falmouth',
    'Richland',
    'Lisbon',
    'Dwight',
    'Calhoun',
    'Perkins',
    'Campo',
    'Grandfalls',
    'Jasper',
    'Runge',
    'Waukomis',
    'Austin',
    'Eudora',
    'West Lebanon',
    'Dewey',
    'Butler',
    'Laton',
    'York',
    'Port Barre',
    'Leonville',
    'Applegate',
    'Port Sanilac',
    'India',
    'Welcome',
    'Highwood',
    'Perryman',
    'Pennsylvania Furnace',
    'Withams',
    'Horntown',
    'Galena',
    'Ulster Park',
    'Normantown',
    'Institute',
    'Switzer',
    'Munich',
    'Prairie View',
    'University',
    'Cornish Flat',
    'Upton',
    'Italy',
    'Salkum',
    'Chancellor',
    'Westford',
    'Jamestown',
    'Larimore',
    'Pomona',
    'Leonidas',
    'Lamont',
    'Lakeside',
    'Quantico',
    'Deal Island',
    'Baudette',
    'Palisade',
    'New Richland',
    'Cochiti Pueblo',
    'Bloomington',
    'Monterey',
    'Seagrove',
    'Bennett',
    'Purlear',
    'Ferguson',
    'Traphill',
    'Manzanita',
    'Thurmond',
    'Reserve',
    'Hanover',
    'Bracey',
    'Gruetli-Laager',
    'Palmer',
    'Spencer',
    'Beechgrove',
    'Likely',
    'Donnelsville',
    'Riverside County',
    'Gambier',
    'Washington Grove',
    'Richmond',
    'Lester Prairie',
    'Saint Leo',
    'Toronto',
    'Alta Vista',
    'Garnett',
    'Zurich',
    'Lecompton',
    'Ellaville',
    'Linwood',
    'Kirkville',
    'Fabius',
    'Planada',
    'Pratts',
    'Blachly',
    'Hines',
    'Pilot Rock',
    'Coulee Dam',
    'Franklin',
    'Stockton Springs',
    'Harmony',
    'Rock Hill',
    'Spring Glen',
    'Rawlings',
    'Swoope',
    'Renovo',
    'Melba',
    'New Plymouth',
    'Grand View',
    'Black Creek',
    'Athelstane',
    'Brussels',
    'Fort McCoy',
    'Eden',
    'Oak Hill',
    'Timberon',
    'Rosenhayn',
    'State University',
    'Rodeo',
    'Selawik',
    'Coffman Cove',
    'Noatak',
    'Onyx',
    'Point Hope',
    'Saint Bonaventure',
    'Wentworth',
    'Altha',
    'Upatoi',
    'Belleville',
    'Atlasburg',
    'Eton',
    'Newton Grove',
    'Trenton',
    'Buies Creek',
    'Boiling Springs',
    'Collegeville',
    'East Liberty',
    'Castle Point',
    'Perry Point',
    'Mountain Home',
    'Oelrichs',
    'Beaver',
    'Tieton',
    'Plato',
    'Turkey Creek',
    'San Antonio',
    'Krebs',
    'Watkins',
    'Lone Wolf',
    'Tonkawa',
    'Goodwell',
    'Texhoma',
    'Hartshorne',
    'Whiting',
    'Prairie',
    'Chattahoochee',
    'Welcome',
    'Templeton',
    'Jefferson',
    'Harriet',
    'Mount Vernon',
    'Ojo Caliente',
    'North Bloomfield',
    'Kaw City',
    'Paden',
    'Orwell',
    'Damascus',
    'Newhope',
    'Eolia',
    'Penitas',
    'Cusseta',
    'Martin',
    'Red Oak',
    'Sulphur Bluff',
    'Rolfe',
    'Dennison',
    'Mill Spring',
    'Adell',
    'Leeton',
    'Cruger',
    'Marietta',
    'Igo',
    'Woodlawn',
    'Thrall',
    'Christine',
    'Philo',
    'Lansing',
    'Racine',
    'Alford',
    'Still Pond',
    'Cawood',
    'Hobart',
    'Bismarck',
    'Waynetown',
    'Twining',
    'Slater',
    'Boiceville',
    'Lagrange',
    'Amherst',
    'Geff',
    'Cisne',
    'Lefor',
    'Welch',
    'Palmyra',
    'Birdsnest',
    'Echo',
    'Courtland',
    'Osborn',
    'Horse Branch',
    'Rosine',
    'Sonoita',
    'Sublette',
    'Wheatland',
    'Dazey',
    'Buffalo',
    'McBain',
    'Juno Beach',
    'Wrightstown',
    'Beaufort',
    'Village',
    'Riverton',
    'Circleville',
    'Arcadia',
    'Olancha',
    'Squaw Lake',
    'Mesa',
    'Madrid',
    'Morristown',
    'Prudhoe Bay',
    'Siemens',
    'Greenwood',
    'Reydon',
    'Loco',
    'Copan',
    'Bunch',
    'Santa Clara',
    'Heflin',
    'Beloit',
    'Perry',
    'Williamsburg',
    'Fish Camp',
    'Metamora',
    'Straughn',
    'Orland',
    'Eckerty',
    'Cannelton',
    'North Salem',
    'Hillsboro',
    'Hartford',
    'Manning',
    'Norwich',
    'Lowman',
    'Big Piney',
    'Escalante',
    'Klamath River',
    'Macdoel',
    'Hat Creek',
    'Fawnskin',
    'Skykomish',
    'Newtown',
    'Claremont',
    'Hinckley',
    'Circleville',
    'Candor',
    'Mt Pleasant',
    'Badin',
    'Cactus',
    'Broxton',
    'Griffithville',
    'Elephant Butte',
    'Okolona',
    'Tomkins Cove',
    'Flatwoods',
    'Monterey',
    'Johannesburg',
    'Axtell',
    'West Middleton',
    'Whittington',
    'Birch Tree',
    'Babcock',
    'Oak City',
    'Lovilia',
    'Mangum',
    'Boscobel',
    'Alexander',
    'Drakes Branch',
    'Bernard',
    'Indianola',
    'Chariton',
    'Point of Rocks',
    'Encampment',
    'Hanna',
    'Dutch John',
    'Robertson',
    'Manila',
    'La Barge',
    'Rock River',
    'Elroy',
    'Lyndon Station',
    'Alderpoint',
    'Union',
    'Laurel',
    'Liscomb',
    'Lenox',
    'Keystone',
    'Van Horne',
    'Saint Marys',
    'Victor',
    'Oak Hill',
    'Arabi',
    'Toomsboro',
    'Fleming',
    'Sale City',
    'Upper Saddle River',
    'Royal City',
    'Adams Center',
    'Munnsville',
    'Damon',
    'Old Ocean',
    'Axtell',
    'Columbus',
    'Glasgow',
    'Fenwick',
    'Dingess',
    'Lindside',
    'Farmington',
    'Levels',
    'Moatsville',
    'Prewitt',
    'Steep Falls',
    'Albion',
    'Dennysville',
    'Lee',
    'Castine',
    'Kirkwood',
    'Adams',
    'Pennellville',
    'Hannibal',
    'San Bernardino County',
    'Adolphus',
    'Busy',
    'Vicco',
    'Elkhorn City',
    'Pollard',
    'Poyen',
    'Viola',
    'Battle Creek',
    'Bluff',
    'Hilltop',
    'Hutchins',
    'Fordsville',
    'Pawnee',
    'Park Hill',
    'Rockport',
    'Lenox',
    'Tennessee Colony',
    'Prospect',
    'Grapeland',
    'Ionia',
    'Reading',
    'Storden',
    'Plum City',
    'Slagle',
    'Salyer',
    'Gainestown',
    'Greenville',
    'Cohocton',
    'Junction City',
    'Whipple',
    'Acworth',
    'Pewamo',
    'Lovingston',
    'Raymond',
    'Lowndes',
    'Essex',
    'Coulterville',
    'Summerfield',
    'Faulkner',
    'Douglas',
    'Hallieford',
    'Lottsburg',
    'Tangier',
    'Burgess',
    'Meadow Valley',
    'Chualar',
    'Lowell',
    'Los Olivos',
    'Port Salerno',
    'Williston',
    'Monponsett',
    'Bradford',
    'Danube',
    'West Union',
    'Bethany Beach',
    'San Jose',
    'Hartsburg',
    'Westgate',
    'Muscoda',
    'Alsey',
    'Scranton',
    'McLain',
    'Elmwood',
    'Saint David',
    'Bellflower',
    'Melvin',
    'Norwood Young America',
    'Keewatin',
    'Spring Valley',
    'Windsor Heights',
    'Roper',
    'Milford',
    'Downs',
    'Dallas Center',
    'Schneider',
    'London Mills',
    'Currituck',
    'South Mills',
    'Martinsville',
    'Nashwauk',
    'Braceville',
    'Northwood',
    'Pierceton',
    'Keota',
    'Fackler',
    'Aurora',
    'Hokah',
    'Carrabelle',
    'Lohman',
    'Grandy',
    'Coinjock',
    'Big Rock',
    'Fremont',
    'Stoddard',
    'Ackley',
    'Jesup',
    'Gretna',
    'East Dubuque',
    'Alba',
    'Pesotum',
    'Millville',
    'Bureau',
    'Altona',
    'Sheffield',
    'Manly',
    'Trivoli',
    'Lakota',
    'Charlotte',
    'Henderson',
    'Thomson',
    'Melcher-Dallas',
    'Pleasantville',
    'Gilbertville',
    'Andover',
    'Lyndon',
    'Preston',
    'Hurst',
    'Elkville',
    'Mounds',
    'Manlius',
    'Osage',
    'Center Junction',
    'Woodland',
    'Rich Square',
    'Merry Hill',
    'Gilman',
    'Whaleyville',
    'Mitchellville',
    'Spring Park',
    'Warren Center',
    'Valley Forge',
    'Chester Heights',
    'Flintstone',
    'Trona',
    'Blue Jay',
    'Fairton',
    'Cornwall-on-Hudson',
    'Mattawana',
    'Windsor',
    'Bradley Junction',
    'Coal City',
    'Shady Spring',
    'Daniels',
    'White Oak',
    'Bradley',
    'Helen',
    'Rhodell',
    'Josephine',
    'Mount Carbon',
    'Bickmore',
    'Kimberly',
    'Dawes',
    'Whitesville',
    'Miami',
    'Deep Water',
    'Cabin Creek',
    'Racine',
    'Smithers',
    'Drybranch',
    'Ridgeview',
    'Winifrede',
    'Debord',
    'Man',
    'Huddy',
    'Thelma',
    'Yolyn',
    'Whitman',
    'Chattaroy',
    'Tomahawk',
    'Ghent',
    'Quinwood',
    'Paden City',
    'Washington',
    'Belmont',
    'Fleming',
    'Newport',
    'Davisville',
    'Enterprise',
    'Syracuse',
    'Beverly',
    'Leslie',
    'Ashcamp',
    'Lick Creek',
    'Mouthcard',
    'Eldred',
    'Astoria',
    'Riggins',
    'Croghan',
    'Raquette Lake',
    'Thendara',
    'Aurora',
    'Arnoldsburg',
    'Friendly',
    'Millstone',
    'Cottageville',
    'Elvaston',
    'Bruno',
    'Shafer',
    'Tower',
    'Winton',
    'Soudan',
    'Embarrass',
    'Knife River',
    'Shipman',
    'New Douglas',
    'Clarksburg',
    'Greeley',
    'Little Meadows',
    'Ansted',
    'Mannsville',
    'Brantingham',
    'Piseco',
    'Turin',
    'Sinks Grove',
    'Crane Lake',
    'Kerby',
    'Rio',
    'Arco',
    'White Mountain Lake',
    'Mount Zion',
    'Jacksonburg',
    'Big Springs',
    'Uriah',
    'Franklin',
    'Ionia',
    'Deloit',
    'Au Sable Forks',
    'Tilleda',
    'Leavittsburg',
    'Southington',
    'Maurertown',
    'Marbury',
    'Nicoma Park',
    'Jasper',
    'Ely',
    'Sutherland Springs',
    'Flovilla',
    'Boron',
    'Arendtsville',
    'Courtland',
    'Nashport',
    'Gates',
    'Lena',
    'Littlefield',
    'Niwot',
    'Rye',
    'Solebury',
    'Deepwater',
    'Addison',
    'Marshfield Hills',
    'Rheems',
    'Chinchilla',
    'Big Cove Tannery',
    'Beech Creek',
    'Mill Creek',
    'Mifflin',
    'Free Union',
    'Secor',
    'Holly Springs',
    'Bunnell',
    'Glidden',
    'Lott',
    'Barry',
    'Richland',
    'Leona',
    'Iola',
    'Chilton',
    'Blackwell',
    'Evans',
    'Frost',
    'Hubbard',
    'Marquez',
    'Pennington',
    'Beckville',
    'Guy',
    'Woodlake',
    'Kopperl',
    'Utica',
    'Bearden',
    'White Plains',
    'Story',
    'Gillham',
    'Oden',
    'Monticello',
    'Bagwell',
    'Marietta',
    'Harrisville',
    'Fairfax',
    'Stephens',
    'Cambridge',
    'Dows',
    'Denmark',
    'Union Point',
    'Middletown',
    'Whiteside',
    'El Paso',
    'Verona',
    'Hodgen',
    'Ratliff City',
    'Greenland',
    'Lilesville',
    'Rhine',
    'Naylor',
    'Hillsboro',
    'Horatio',
    'Caddo Gap',
    'Tierra Amarilla',
    'Lore City',
    'Kingsville',
    'Faith',
    'Peninsula',
    'Springboro',
    'Fredonia',
    'Midway',
    'Elizaville',
    'Lost Creek',
    'Chama',
    'Embudo',
    'Coyote',
    'Jemez Springs',
    'Otoe',
    'Nemaha',
    'Verdon',
    'Ceresco',
    'Cumberland',
    'Margaret',
    'Bridgewater',
    'Grandfield',
    'Seneca',
    'Hinkley',
    'Kaumakani',
    'Violet Hill',
    'Plain Dealing',
    'Taneyville',
    'Washington',
    'Dunbarton Center',
    'Polk',
    'Errol',
    'Westfield',
    'State Road',
    'Siloam',
    'Lowgap',
    'Ararat',
    'Hedrick',
    'Ollie',
    'Eldon',
    'Delta',
    'What Cheer',
    'Milton',
    'Batavia',
    'Derby',
    'Russell',
    'Mystic',
    'Letts',
    'Brighton',
    'Hillsboro',
    'Sabula',
    'Woodburn',
    'Colesburg',
    'Alburnett',
    'Elliott',
    'Dougherty',
    'Meriden',
    'Dixon',
    'Ogden',
    'Somers',
    'Pilot Mound',
    'Aredale',
    'Yarmouth',
    'Wall Lake',
    'Breda',
    'Wyoming',
    'Hawkeye',
    'Calumet',
    'Hospers',
    'Keosauqua',
    'Wellman',
    'Defiance',
    'Farragut',
    'Woolstock',
    'Clearfield',
    'Gassville',
    'Lakeview',
    'Poplar Grove',
    'Goldonna',
    'Lamar',
    'Rockaway Beach',
    'Hoxie',
    'Waverly',
    'Sidon',
    'Kenney',
    'Petersburg',
    'Broadway',
    'Ho-Ho-Kus',
    'Pierce',
    'Plainview',
    'Brunswick',
    'Stanton',
    'New Effington',
    'Claire City',
    'Wilmot',
    'Veblen',
    'Peever',
    'Corona',
    'Canyon City',
    'Coldwater',
    'Foreston',
    'Sidney',
    'Lakeview',
    'Clarkia',
    'Burton',
    'Pomerene',
    'Garrett',
    'Duke Center',
    'Schenley',
    'Olanta',
    'Pinewood',
    'Canyon',
    'Elkton',
    'Scottsburg',
    'Bluebell',
    'Wynot',
    'Ashley',
    'Edgerton',
    'Convoy',
    'Lyons',
    'Tremont',
    'Bruce',
    'Big Creek',
    'Randolph',
    'Westphalia',
    'Bowman',
    'Glenbeulah',
    'Potter',
    'Payneville',
    'Muldraugh',
    'Frankewing',
    'Silas',
    'Fruitdale',
    'State Line',
    'Herald',
    'Wilson',
    'Elk Mound',
    'Downsville',
    'Wanakena',
    'Star Lake',
    'Winthrop',
    'West Stockholm',
    'Dickinson Center',
    'Rensselaer Falls',
    'Brasher Falls',
    'Ridgeland',
    'Sand Creek',
    'Bryant',
    'Krakow',
    'Lummi Island',
    'Nemo',
    'Brandon',
    'Richmond',
    'Ash Fork',
    'Glasgow',
    'Labadie',
    'Warren',
    'Bringhurst',
    'Weeksbury',
    'Stanville',
    'McConnellsburg',
    'Blue Ridge Summit',
    'Washington Boro',
    'Summit Station',
    'Blanchard',
    'North Point',
    'Saxton',
    'Hopewell',
    'Mesilla Park',
    'Port Elizabeth',
    'McElhattan',
    'North Bend',
    'Muir',
    'Smoketown',
    'Bart Township',
    'Bethel',
    'Shartlesville',
    'East Prospect',
    'Loganville',
    'Manomet',
    'North Troy',
    'Bailey Island',
    'Sunshine',
    'Grosse Tete',
    'Erwinville',
    'Cecilia',
    'Kanopolis',
    'Haven',
    'Mount Hope',
    'Saint Paul',
    'Shawsville',
    'Sulphur Springs',
    'Burgin',
    'Elmwood',
    'Thorp',
    'Shell Lake',
    'Hale',
    'Mecosta',
    'Empire',
    'Brant',
    'Merritt',
    'Arcola',
    'Riverside',
    'Fairchild',
    'Conrath',
    'Wind Ridge',
    'Reklaw',
    'La Crosse',
    'Hampton',
    'Worthington Springs',
    'Fairfield',
    'Parkman',
    'West Farmington',
    'Cushing',
    'Williamsburg',
    'Caliente',
    'Emerson',
    'Taylor',
    'Hondo',
    'Nortonville',
    'McClellandtown',
    'Republic',
    'Jacobs Creek',
    'East Millsboro',
    'Ridgeley',
    'Albright',
    'Williamsburg',
    'Sanders',
    'Sims',
    'Harpster',
    'Gay',
    'Isabella',
    'Attica',
    'Fingerville',
    'Metropolitan',
    'Bill',
    'Medaryville',
    'Moncure',
    'Simpson',
    'Edgerton',
    'Ceres',
    'Maury',
    'Sharpsburg',
    'Kipling',
    'Poynor',
    'Waverly',
    'Howardsville',
    'Saint Thomas',
    'Urich',
    'Walnut Bottom',
    'Cashtown',
    'Fannettsburg',
    'San Pierre',
    'Eugene',
    'Wendel',
    'Moscow',
    'Guilford',
    'Greenwood',
    'Bowler',
    'Justice',
    'Matoaka',
    'Purgitsville',
    'Burlington',
    'Old Fields',
    'Hudson',
    'Redkey',
    'Scipio',
    'Monroe City',
    'Hazleton',
    'Arlington',
    'Chloride',
    'Mayfield',
    'Lorraine',
    'Rowlesburg',
    'Fielding',
    'Meadow',
    'Grand Ridge',
    'Hardy',
    'Genoa',
    'Newcomb',
    'Olmstedville',
    'Meridian',
    'Eagleville',
    'McArthur',
    'Montgomery Creek',
    'Bieber',
    'Round Mountain',
    'Fall River Mills',
    'Adin',
    "O'Brien",
    'McMechen',
    'Tunnelton',
    'Burnsville',
    'Helvetia',
    'Sugar Grove',
    'Terra Alta',
    'Anmoore',
    'Beech Bottom',
    'Carolina',
    'Bigbend',
    'Orma',
    'Dunmore',
    'Mill Creek',
    'Huttonsville',
    'Valley Bend',
    'Monterville',
    'Danese',
    'Coal Mountain',
    'Cyclone',
    'Wharncliffe',
    'Falling Rock',
    'Forest Hill',
    'Turtle Creek',
    'Hernshaw',
    'Lookout',
    'Scarbro',
    'Midway',
    'Mount Lookout',
    'Mallory',
    'Palestine',
    'Glen Daniel',
    'Bim',
    'Jeffrey',
    'Lavalette',
    'Hewett',
    'Princewick',
    'Wharton',
    'Bob White',
    'Glenwood',
    'Millwood',
    'Henderson',
    'Dunlow',
    'Itmann',
    'Morristown',
    'Hamilton',
    'Lucerne',
    'Bippus',
    'Wawaka',
    'Uniondale',
    'Gassaway',
    'Hepzibah',
    'Glengary',
    'Adrian',
    'Sylvester',
    'Wallace',
    'Elmer',
    'Savoy',
    'Peggs',
    'Chester',
    'Kennan',
    'Bonnerdale',
    'Scottsburg',
    'Creola',
    'Pahala',
    'Chandlersville',
    'Juda',
    'Foster',
    'Ranchita',
    'Fruitvale',
    'Sherrodsville',
    'Arp',
    'San Miguel',
    'Makinen',
    'Side Lake',
    'Indian Mound',
    'Riverdale',
    'Mellen',
    'Des Arc',
    'Patton',
    'Reydell',
    'Edwards',
    'Honeydew',
    'Lakehead',
    'Potter Valley',
    'Rembert',
    'Ranburne',
    'Tangipahoa',
    'Fluker',
    'Empire',
    'Pineola',
    'Cade',
    'Centerville',
    'Oak Hill',
    'Totz',
    'Garrett',
    'Hardy',
    'Dwale',
    'Canada',
    'Robinson Creek',
    'Mount Eden',
    'Pleasureville',
    'Westport',
    'Waddy',
    'Wilson',
    'Jekyll Island',
    'Woodbine',
    'Rockholds',
    'Gaylesville',
    'Epworth',
    'Alma',
    'Derma',
    'Oakman',
    'Natural Bridge',
    'Lynn',
    'McClellanville',
    'Hoskinston',
    'Essie',
    'Wooton',
    'Yeaddiss',
    'Gays Creek',
    'Bledsoe',
    'Cherry Log',
    'Matinicus',
    'Edwards',
    'Hermon',
    'Russell',
    'Kieler',
    'Saint Hedwig',
    'Staples',
    'Briggs',
    'Denver',
    'Heath',
    'Dawson',
    'Hulbert',
    'Hartville',
    'Boggstown',
    'Kilkenny',
    'Boyceville',
    'Saint Onge',
    'Black Rock',
    'Drasco',
    'Beldenville',
    'Frohna',
    'Bradford',
    'Trinidad',
    'Shipman',
    'Bernice',
    'Saint Stephens',
    'Lyons',
    'New Harbor',
    'North Anson',
    'Elba',
    'Goldsmith',
    'Tuscola',
    'Buffalo Gap',
    'Boyd',
    'Taunton',
    'Olivia',
    'Clarkfield',
    'Cottonwood',
    'Freeport',
    'Windom',
    'Fairview',
    'Okarche',
    'Leedey',
    'Ames',
    'Aline',
    'Forestville',
    'Ephraim',
    'Ten Mile',
    'Vida',
    'Sherrill',
    'Amberg',
    'Hartman',
    'Coal Hill',
    'Oxford',
    'Calico Rock',
    'Taylor',
    'Wilton',
    'Gotham',
    'Strawberry',
    'Hallsville',
    'Scranton',
    'Somers',
    'Monte Vista',
    'Redwood',
    'Toms Brook',
    'New Market',
    'Sand Fork',
    'Camden',
    'Lupton City',
    'Chataignier',
    'Charenton',
    'Savonburg',
    'Baldwin',
    'Osteen',
    'Pennville',
    'Mico',
    'Cogswell',
    'Cloud',
    'Peak',
    'Ketchum',
    'Park',
    'Seal',
    'Ford',
    'Aventura',
    'Bismarck',
    'Newport',
    'Valentines',
    'Sheridan',
    'Mutual',
    'Rickman',
    'Christmas Valley',
    'Albany',
    'Fertile',
    'Datil',
    'Magdalena',
    'Gila',
    'Freeburg',
    'Grass Valley',
    'Des Arc',
    'Taylor',
    'Belfry',
    'Langston',
    'West Briar',
    'Altura',
    'Lewiston',
    'Conrad',
    'Agate',
    'Arriba',
    'Lopez',
    'Wayne',
    'Middle Bass',
    'Andover',
    'Alfred Station',
    'Vanderbilt',
    'White Heath',
    'Dewey',
    'Vermont',
    'Naples',
    'Warsaw',
    'Estancia',
    'Vaughn',
    'Algodones',
    'Pablo',
    'Sperry',
    'Simms',
    'Sand Coulee',
    'Brady',
    'Geyser',
    'Villanueva',
    'Cheyney',
    'Alfred',
    'Camden Point',
    'Puerto Rico',
    'Local',
    'University',
    'Remote',
    'Gainesville',
    'Channing',
    'Caspian',
    'Bingen',
    'Remer',
    'Bena',
    'Stockton',
    'Lost City',
    'Brandywine',
    'Fisher',
    'Mathias',
    'Baker',
    'Rice',
    'Rhodes',
    'Moorhead',
    'Wylliesburg',
    'Pitman',
    'Wilmot',
    'Albion',
    'Lewiston',
    'Milford',
    'Umpire',
    'Fitzhugh',
    'Arthur City',
    'Medford',
    'Helena',
    'Carmen',
    'City of Industry',
    'Post Mills',
    'McDonald',
    'Rocky',
    'La Crosse',
    'Dendron',
    'Grinnell',
    'Westbrook',
    'Shawboro',
    'Ennice',
    'Logan',
    'Hume',
    'Council Hill',
    'Dustin',
    'Wainwright',
    'Divide',
    'Woodruff',
    'Varna',
    'Washburn',
    'Dana',
    'Buda',
    'Canal Point',
    'Saltillo',
    'Bath',
    'Cedar Grove',
    'Yoder',
    'Poston',
    'Kingdom City',
    'Williamsburg',
    'Tebbetts',
    'Steedman',
    'Fairfax',
    'Yoder',
    'Keystone',
    'Saint Louis Park',
    'Middle River',
    'Wye Mills',
    'Frankfort',
    'Normal',
    'Santa Ana Heights',
    'Murphy',
    'Strawn',
    'Athena',
    'Calpine',
    'Brownsville',
    'Esbon',
    'Downs',
    'Penokee',
    'Tonica',
    'Lithopolis',
    'Marylhurst',
    'Bly',
    'Burns',
    'Goff',
    'Cherokee',
    'Rushville',
    'Easton',
    'Shady Point',
    'Galva',
    'Assaria',
    'Collinston',
    'Bonita',
    'Jones',
    'Pecos',
    'East Corinth',
    'Brownville',
    'Hershey',
    'Simmesport',
    'Lewellen',
    'Hickory',
    'Karnak',
    'Chana',
    'Holcomb',
    'Rock',
    'Copper Harbor',
    'Chester',
    'Upper Abbot',
    'Donaldson',
    'Bogata',
    'Mora',
    'Cleveland',
    'Blackstock',
    'Chambers',
    'Lackey',
    'Leburn',
    'Lutsen',
    'Tofte',
    'Deer Lodge',
    'Hope',
    'Fairview',
    'Freedom',
    'Riviera',
    'Sarita',
    'Hinton',
    'La Honda',
    'Grover',
    'Briggsdale',
    'New Raymer',
    'Orchard',
    'Tipton',
    'Lucas',
    'Brookville',
    'Pembine',
    'Hewitt',
    'Salemburg',
    'Upsala',
    'Peetz',
    'Kettleman City',
    'Omega',
    'Solsberry',
    'Galesburg',
    'Grey Eagle',
    'Burtrum',
    'Brilliant',
    'Cedar Valley',
    'Mason',
    'Malad City',
    'Callaway',
    'Coulee City',
    'Stratford',
    'Odessa',
    'Wonewoc',
    'Granger',
    'Redmon',
    'Petersburg',
    'Garner',
    'Redfox',
    'Bulan',
    'Mousie',
    'Alton',
    'Gas',
    'Girard',
    'Colony',
    'Oneco',
    'Peach Springs',
    'Stark',
    'Fosston',
    'Keswick',
    'Fenton',
    'Irwin',
    'Remsen',
    'Cumberland',
    'Ruthven',
    'Churdan',
    'Templeton',
    'Bonaparte',
    'Graettinger',
    'Palo',
    'Shelby',
    'Hancock',
    'Urbana',
    'Falkner',
    'Ewing',
    'Philadelphia',
    'Emden',
    'Baring',
    'Brashear',
    'Wyaconda',
    'Greentop',
    'Laona',
    'Stoneville',
    'Holly Grove',
    'Stephensport',
    'Arnett',
    'Peace Dale',
    'Kanab',
    'Nova',
    'Angora',
    'Mackinaw City',
    'Claremont',
    'Parkersburg',
    'Alma',
    'Admire',
    'Allen',
    'Madison',
    'Athens',
    'Crawford',
    'Allardt',
    'Long Branch',
    'Hartford',
    'Neosho Rapids',
    'Port Royal',
    'New Point',
    'Pelham',
    'Occoquan',
    'Superior',
    'Otisco',
    'Hoskins',
    'Powhatan',
    'Charlotte',
    'Melrose',
    'Weyerhaeuser',
    'Heppner',
    'Calmar',
    'Shelton',
    'Wilbur',
    'Hartline',
    'Tracy City',
    'Starbuck',
    'Andes',
    'Wykoff',
    'Pioche',
    'Alamo',
    'Hiko',
    'Caliente',
    'Seabrook',
    'Delmar',
    'Mylo',
    'Hannah',
    'Hazel',
    'Fancy Farm',
    'Fossil',
    'Danbury',
    'Haigler',
    'Dittmer',
    'Wilton Manors',
    'Sunrise',
    'Weston',
    'Miramar',
    'Content',
    'Marlboro',
    'Pleasant Shade',
    'San Manuel',
    'Bowlus',
    'Holyoke',
    'Julesburg',
    'Paoli',
    'Ouray',
    'New Concord',
    'Winchester',
    'Josephine',
    'Modale',
    'Green',
    'Mermentau',
    'La Fontaine',
    'Bovina Center',
    'Ronald',
    'Roslyn',
    'Uniontown',
    'Colton',
    'Amelia',
    'Arivaca',
    'Maple City',
    'Clarkson',
    'New Brockton',
    'Fort Deposit',
    'Paeonian Springs',
    'Valparaiso',
    'Westville',
    'Arapahoe',
    'Nielsville',
    'Climax',
    'Gays Mills',
    'Adger',
    'Montreat',
    'Burdett',
    'Schoenchen',
    'Allen',
    'Immaculata',
    'Woodstock',
    'Basye',
    'Clear Brook',
    'Flatwoods',
    'Elk Garden',
    'Tracyton',
    'Robbins',
    'Sunbright',
    'Jacksonboro',
    'Pall Mall',
    'Petros',
    'Grassy Creek',
    'Modoc',
    'Morgan',
    'Bridger',
    'Spring Lake Park',
    'Sweet Water',
    'Pine Hill',
    'La Belle',
    'Oxford',
    'McNabb',
    'Warsaw',
    'Stanfield',
    'Diamondville',
    'Fountain',
    'Van Meter',
    'Harmony',
    'Ridgeway',
    'Mabel',
    'Weskan',
    'Orderville',
    'Jacksonville',
    'Rio Grande',
    'Melvin',
    'Cohasset',
    'Deer River',
    'Monterey County',
    'Stratford',
    'Squaw Valley',
    'Mankato',
    'Glen Elder',
    'Formoso',
    'Ovid',
    'Inkom',
    'Dakota',
    'Ossian',
    'Clermont',
    'Peterson',
    'Fort Atkinson',
    'Canton',
    'Tioga',
    'Fulton',
    'Colorado City',
    'White City',
    'Lost Springs',
    'Burdick',
    'Hope',
    'Dwight',
    'Woodbine',
    'Lincolnville',
    'Ramona',
    'Wilsey',
    'Lake Mills',
    'Cullen',
    'Mayfield',
    'Hardtner',
    'Pond Creek',
    'South Haven',
    'Jet',
    'Bluff City',
    'Braman',
    'Goltry',
    'Manchester',
    'Geuda Springs',
    'Swan',
    'Lehman',
    'Cherry Point Marine Base Mobile Home Park',
    'Clifford',
    'Pollocksville',
    'New Weston',
    'Sargent',
    'Milroy',
    'Cannel City',
    'Saline',
    'New Florence',
    'Wellsville',
    'Pelkie',
    'Skanee',
    'Wauregan',
    'Kinnear',
    'Hudson',
    'Farson',
    'Sugartown',
    'South Williamson',
    'Center Point',
    'Fort Gaines',
    'Okoboji',
    'Brian Head',
    'Limon',
    'Kinderhook',
    'Chambersburg',
    'Ursa',
    'Camp Point',
    'Loraine',
    'Augusta',
    'Melbourne',
    'Garnavillo',
    'Miles',
    'Zwingle',
    'Tripoli',
    'West Bend',
    'Havelock',
    'Ottosen',
    'Gillett Grove',
    'Essex',
    'Edgemoor',
    'Due West',
    'Troy',
    'Point Lay',
    'Kaktovik',
    'Wainwright',
    'Rinard',
    'Xenia',
    'Bone Gap',
    'Young',
    'Rex',
    'Ansley',
    'North Loup',
    'Forreston',
    'Pleasant View',
    'Monroe',
    'Alsea',
    'Blodgett',
    'Stonefort',
    'Cave-in-Rock',
    'Elfrida',
    'Cochise',
    'San Simon',
    'White Bear Lake',
    'Blum',
    'Racine',
    'Verndale',
    'Lucan',
    'Canaan',
    'Dupont',
    'Cross Plains',
    'Commiskey',
    'Deputy',
    'Dunlap',
    'Del Rey',
    'La Jara',
    'Fort Garland',
    'Beulah',
    'Bowie',
    'Douglas',
    'Rush Valley',
    'Hermosa',
    'Woonsocket',
    'Kimball',
    'Tabor',
    'Brooklandville',
    'Elbow Lake',
    'Barrett',
    'Hoffman',
    'Farwell',
    'Wendell',
    'Clinton',
    'Miltona',
    'Talmoon',
    'Congress',
    'Kirkland',
    'Licking',
    'Swisshome',
    'North Greece',
    'Argusville',
    'Standard',
    'Lilliwaup',
    'Union',
    'Clark',
    'Anderson',
    'Kiowa',
    'Sharon',
    'Burlington',
    'Zenda',
    'Penelope',
    'Blain',
    'Moscow',
    'Kendall',
    'Rolla',
    'Prescott',
    'Sherrill',
    'Summerdale',
    'Dublin',
    'Riverdale',
    'Lake Harmony',
    'Oxford',
    'Bronte',
    'Milwaukie',
    'Waucoma',
    'Shannon City',
    'Kensett',
    'Lost Nation',
    'Ellsworth',
    'Dunnell',
    'Paton',
    'Burt',
    'Quimby',
    'Blairsburg',
    'Wiota',
    'Palmer',
    'Cylinder',
    'Rockford',
    'Fernwood',
    'Goodland',
    'Fair Oaks',
    'Brook',
    'Valera',
    'Shedd',
    'Brownsville',
    'Tamms',
    'Cairo',
    'Boelus',
    'Black River',
    'Mikado',
    'Manassas Park',
    'Green Pond',
    'Pearblossom',
    'Westtown',
    'Lake Hamilton',
    'Trenton',
    'Flandreau',
    'Fruitland',
    'Coden',
    'Vader',
    'Jonesburg',
    'Greenfield',
    'Gardner',
    'La Veta',
    'Vershire',
    'Laurens',
    'Keene',
    'Jonesville',
    'Worthville',
    'Royal Center',
    'Plum Branch',
    'Allons',
    'Coalfield',
    'Elmwood',
    'Islandton',
    'Lodge',
    'Flemington',
    'Aldrich',
    'Stotts City',
    'Liberal',
    'Walnut Grove',
    'Stark City',
    'New Galilee',
    'Monroe',
    'Schleswig',
    'Gilbert',
    'Tiffin',
    'Corwith',
    'Adair',
    'Burnettsville',
    'Grapeview',
    'Higdon',
    'Geraldine',
    'Estillfork',
    'Aguila',
    'Bagdad',
    'Grant City',
    'Breckenridge',
    'Burlington Junction',
    'Rich Hill',
    'Massena',
    'Anita',
    'Olin',
    'Moorland',
    'Volga',
    'Onslow',
    'Quasqueton',
    'Ledyard',
    'Hamlin',
    'Plainfield',
    'Stacyville',
    'Ladora',
    'Norway',
    'Leonore',
    'Baring',
    'Alpine',
    'Thayne',
    'Bedford',
    'Auburn',
    'Chaseburg',
    'Hustler',
    'La Valle',
    'Camp Douglas',
    'Blue River',
    'Hillsboro',
    'Cazenovia',
    'Hancock',
    'Cameron',
    'Creole',
    'Osage',
    'Hewitt',
    'Ogema',
    'Unionville',
    'Homewood',
    'Galva',
    'South English',
    'Searsboro',
    'Rutland',
    'Gilmore City',
    'New Providence',
    'Earling',
    'Winthrop',
    'Grand Mound',
    'San Gregorio',
    'Fabens',
    'Church Rock',
    'Lovelock',
    'Montfort',
    'Merit',
    'Avalon',
    'Cedar Lake',
    'West Mineral',
    'Weir',
    'Pleasanton',
    'Arma',
    'Arcadia',
    'McCune',
    'Pointblank',
    'Claysburg',
    'Smithfield',
    'Uledi',
    'Vestaburg',
    'Newell',
    'Grindstone',
    'Chestnut Ridge',
    'Limestone',
    'Clearville',
    'Saint John',
    'Jarvisburg',
    'Kelford',
    'Prairie View',
    'Gilboa',
    'Ferndale',
    'Independence',
    'Bazine',
    'Selden',
    'Almena',
    'Natural Bridge Station',
    'Swartz',
    'Chaplin',
    'Ulm',
    'Emory',
    'Early',
    'Berry Creek',
    'Gerber',
    'Richvale',
    'Oacoma',
    'Pukwana',
    'White Lake',
    'Selby',
    'Harrold',
    'Gettysburg',
    'Hoven',
    'Highmore',
    'Wessington',
    'Holabird',
    'Agar',
    'Akaska',
    'Colton',
    'Ozone',
    'Skyforest',
    'Silverstreet',
    'Piney Woods',
    'Normandy',
    'Mohawk',
    'Kirksey',
    'Comstock',
    'Center Cross',
    'Waverly',
    'Benavides',
    'Blackstone',
    'Newell',
    'Lemmon',
    'Prairie City',
    'Bison',
    'Schaghticoke',
    'Valley Falls',
    'Rives',
    'Minto',
    'Oslo',
    'Euclid',
    'Toddville',
    'Hayfork',
    'Wilderville',
    'Ostrander',
    'New Albin',
    'Moapa',
    'Rowland',
    'Bucyrus',
    'Tannersville',
    'Radnor',
    'Sedalia',
    'Mondamin',
    'Watson',
    'Nilwood',
    'Coolin',
    'Nabesna',
    'Greenwood Village',
    'Russell',
    'Ruthton',
    'Woodstock',
    'Swanville',
    'Garden City',
    'Ackerly',
    'Olmsted',
    'Olive Branch',
    'Elmer City',
    'Underwood',
    'Rose Creek',
    'Dakota City',
    'Renwick',
    'Twelve Mile',
    'Hamlet',
    'Anderson',
    'Kennard',
    'Merlin',
    'Hardin',
    'Cunningham',
    'Bern',
    'Steinauer',
    'Fredonia',
    'Mollusk',
    'Tivoli',
    'Markham',
    'Glendale',
    'Reddick',
    'Rose Hill',
    'Ewing',
    'West Chester',
    'Bapchule',
    'Summit Lake',
    'Gheens',
    'Cut Off',
    'Larose',
    'Jackpot',
    'Rockland',
    'Turner',
    'Epes',
    'Geigertown',
    'Bluff Dale',
    'Brashear',
    'Glenwood',
    'Shandon',
    'Silver Grove',
    'Sparta',
    'Miamitown',
    'Collinsville',
    'Reelsville',
    'Mulberry',
    'Southside',
    'West Springfield',
    'Flat Rock',
    'Silver Bay',
    'South Tamworth',
    'Center Ossipee',
    'South Freeport',
    'Big Stone City',
    'Forest River',
    'Winston',
    'Hayneville',
    'Letohatchee',
    'Georgiana',
    'Revere',
    'Nuremberg',
    'Garber',
    'Drummond',
    'Dacoma',
    'Meno',
    'Wakita',
    'Olney Springs',
    'Wahkon',
    'Bradyville',
    'Hamden',
    'Frankfort',
    'McDermott',
    'Effie',
    'Mahnomen',
    'Vilas',
    'Holly',
    'Hasty',
    'Ordway',
    'Pritchett',
    'Two Buttes',
    'Cheyenne Wells',
    'Annawan',
    'Mineral',
    'Canutillo',
    'Tovey',
    'Hindsboro',
    'Mount Auburn',
    'Windsor',
    'Jewett',
    'Dieterich',
    'Cowden',
    'Clark Mills',
    'Bloomingdale',
    'Argyle',
    'Ellicottville',
    'Westmoreland',
    'Comstock',
    'Albion',
    'Beaman',
    'Farnhamville',
    'Stanhope',
    'Emmons',
    'Wayland',
    'Westside',
    'Greenville',
    'Truro',
    'Lone Rock',
    'Rose Hill',
    'Casey',
    'Titonka',
    'Oxford Junction',
    'La Motte',
    'Pulaski',
    'Kiron',
    'Garrison',
    'Elberon',
    'Minburn',
    'Bernard',
    'Colo',
    'Sherburn',
    'Brayton',
    'Rockwell',
    'Kanawha',
    'Homestead',
    'Bristow',
    'Knapp',
    'Verdigre',
    'Niobrara',
    'Deshler',
    'Byron',
    'Sutherland',
    'Gypsy',
    'Mad River',
    'Asherton',
    'March Air Force Base',
    'Tall Timbers',
    'Lincoln',
    'Samson',
    'Royal Palm Beach',
    'Conway',
    'Lyons',
    'Lancing',
    'Bell City',
    'Nicholville',
    'Waldron',
    'Veyo',
    'Pine Valley',
    'Gunlock',
    'Central',
    'Higgins',
    'Malone',
    'Hart',
    'Rising Star',
    'Follett',
    'Groom',
    'Imperial',
    'McLean',
    'Orchard',
    'Abbott',
    'Stinson Beach',
    'Lynch',
    'Naper',
    'Springview',
    'Millersview',
    'Cherokee',
    'Wellsley',
    'Colman',
    'Beaver Creek',
    'Canton',
    'Mission Hill',
    'Comstock',
    'Panola',
    'Dowling',
    'Thurston',
    'Osmond',
    'Adrian',
    'Waynesville',
    'Plainfield',
    'Elberta',
    'Montgomery',
    'Brocton',
    'Amity',
    'Cokeburg',
    'Ramah',
    'Americus',
    'Cedar Point',
    'Two Rivers',
    'Cooper Landing',
    'King Cove',
    'Massey',
    'Claremont',
    'Coal City',
    'High Island',
    'Kismet',
    'Middle Point',
    'McCutchenville',
    'Stewart',
    'Sycamore',
    'Lorman',
    'Cleghorn',
    'Mount Union',
    'Hanlontown',
    'Kimballton',
    'Westboro',
    'Arbela',
    'Alma',
    'Mooresville',
    'Rockville',
    'De Kalb',
    'Stewartsville',
    'Amity',
    'Harmans',
    'Sweet',
    'Big Horn',
    'May Creek',
    'Copper Center',
    'Pearson',
    'Oakfield',
    'Pine Mountain Club',
    'King Salmon',
    'Akiak',
    'Jamestown',
    'Grelton',
    'Clarksburg',
    'South Salem',
    'Donovan',
    'Moose',
    'Naco',
    'Emerson',
    'Clifton',
    'Olsburg',
    'Delphos',
    'Aurora',
    'Morganville',
    'Green',
    'Delano',
    'Talihina',
    'Tabor City',
    'Jud',
    'Fort Ransom',
    'Arroyo Seco',
    'San Cristobal',
    'Arroyo Hondo',
    'Red River',
    'Ponemah',
    'Nenana',
    'Bondville',
    'Bridgewater',
    'Chesterfield',
    'Paradox',
    'Gateway',
    'Cimarron',
    'Naturita',
    'Protection',
    'Minneola',
    'Almira',
    'Cape Fair',
    'Shell Knob',
    'Cashton',
    'Odanah',
    'Kila',
    'Satsop',
    'Neah Bay',
    'Newellton',
    'Fort Ripley',
    'Nicholls',
    'Mayfield',
    'Soper',
    'Osage',
    'Jennings',
    'Hallett',
    'Sasakwa',
    'New Park',
    'Stillmore',
    'Kite',
    'Dexter',
    'Dudley',
    'Rentz',
    'Baisden',
    'Sarah Ann',
    'Van',
    'Colorado City',
    'Hartsel',
    'Helix',
    'Meacham',
    'Neotsu',
    'Saint Martin',
    'Saint Meinrad',
    'Leopold',
    'Evanston',
    'Berrien Center',
    'Kintyre',
    'Quality',
    'Hoosick',
    'Covelo',
    'Holton',
    'Cullen',
    'Greenville',
    'Arlee',
    'Thompson Falls',
    'Saint Ignatius',
    'De Borgia',
    'Saint Regis',
    'Philipsburg',
    'Dixon',
    'Heron',
    'Trout Creek',
    'Lismore',
    'Monroe',
    'Elk Point',
    'Lesterville',
    'Ghent',
    'Michigantown',
    'Rossville',
    'Biggsville',
    'Mooers',
    'Mooers Forks',
    'Leslie',
    'Hobson',
    'Falls City',
    'Clara City',
    'Sacred Heart',
    'Maynard',
    'Noxon',
    'Ravalli',
    'Gordon',
    'Cottonwood',
    'Pansey',
    'Ashford',
    'Milnesville',
    'Farlington',
    'Amoret',
    'Hepler',
    'Uniontown',
    'Franklin',
    'Prescott',
    'Walnut',
    'Arthur',
    'Buffalo',
    'Moulton',
    'Lu Verne',
    'Minden',
    'Boxholm',
    'Stanton',
    'Gilmanton',
    'Tony',
    'Eau Galle',
    'Winter',
    'Dunbar',
    'Coloma',
    'Skyland',
    'Galena',
    'Saxtons River',
    'Ninilchik',
    'Pavillion',
    'Dulzura',
    'Arnegard',
    'New Town',
    'Garrison',
    'Mandaree',
    'Fallsburg',
    'German Valley',
    'Baileyville',
    'Orangeville',
    'Winslow',
    'Latham',
    'Pilot Grove',
    'Surrency',
    'Red Level',
    'Calpella',
    'Sweet Springs',
    'Renner',
    'Larchwood',
    'Eureka',
    'Westport',
    'Pollock',
    'Glenham',
    'Hosmer',
    'Mount Vernon',
    'Tripp',
    'Artesian',
    'Stella',
    'Salem',
    'Rulo',
    'Phil Campbell',
    'Dauphin Island',
    'Prairie du Rocher',
    'Copalis Beach',
    'Wendell',
    'Hagerman',
    'Dietrich',
    'Eden',
    'Reed Point',
    'Floweree',
    'Judith Gap',
    'Greycliff',
    'Bainville',
    'Mountain View',
    'Onia',
    'Leon',
    'Lineville',
    'Grand River',
    'Spickard',
    'Lucerne',
    'Purdin',
    'Eagleville',
    'Weldon',
    'Thayer',
    'Cainsville',
    'Davis City',
    'Nickelsville',
    'Fort Blackmore',
    'Queen City',
    'Rutledge',
    'Thompson',
    'Novelty',
    'Martinsdale',
    'Flaxville',
    'Sunburst',
    'Garryowen',
    'Ludlow',
    'Wheeling',
    'Novinger',
    'Sheldon',
    'Sharon',
    'Binford',
    'Finley',
    'Palermo',
    'Sentinel Butte',
    'Medora',
    'Paxico',
    'Shidler',
    'Kaneville',
    'Campbell Hill',
    'Rockwood',
    'Questa',
    'Cerro',
    'Caraway',
    'Lepanto',
    'Hay',
    'Treichlers',
    'Jayton',
    'Paducah',
    'Wilson',
    'Carlos',
    'Teasdale',
    'Torrey',
    'Bryce Canyon City',
    'Boulder Town',
    'Marysvale',
    'Bicknell',
    'Goehner',
    'Gunnison',
    'Three Mile Bay',
    'Pineville',
    'Marietta',
    'Redwood Estates',
    'Hill City',
    'Hammond',
    'Dalton City',
    'Williams',
    'Plainville',
    'May',
    'Albion',
    'Mackay',
    'Blue Mounds',
    'Monteview',
    'Picabo',
    'Glenns Ferry',
    'Big Wells',
    'Sebastian',
    'Redfield',
    'Edna',
    'Mulberry',
    'Anthem',
    'Maryknoll',
    'Hartland',
    'Sebeka',
    'Lueders',
    'Lawn',
    'Chebeague Island',
    'Searsmont',
    'Dunseith',
    'Fordville',
    'Adams',
    'Gilby',
    'Wolverton',
    'Hoople',
    'Port Royal',
    'Erick',
    'Durham',
    'Pandora',
    'Ray',
    'Bascom',
    'Exline',
    'Kirkville',
    'Tyringham',
    'Grand Portage',
    'Dayton',
    'Childs',
    'Greeleyville',
    'Cades',
    'Fitzpatrick',
    'Zortman',
    'Loma',
    'Westhope',
    'De Soto',
    'Simla',
    'Saint Francis',
    'Stacyville',
    'North Monmouth',
    'Leeds',
    'Monmouth',
    'Groton',
    'Jordan',
    'Walton',
    'Earth',
    'Raymond',
    'Phenix',
    'Meherrin',
    'Cordesville',
    'Doddridge',
    'Fulton',
    'Washington',
    'Mountainair',
    'Sundown',
    'Junction',
    'Herod',
    'Bryce Canyon',
    'Tropic',
    'Minersville',
    'Sanderson',
    'Sheffield',
    'Alden',
    'Omena',
    'Ellsworth',
    'Blairstown',
    'Clemons',
    'Andrew',
    'Musella',
    'Paragon',
    'Owensburg',
    'Switz City',
    'Inwood',
    'Steen',
    'Alvord',
    'Hudson',
    'Hills',
    'Presho',
    'Howard',
    'Fort Thompson',
    'Jeffers',
    'North Freedom',
    'Keyes',
    'Garland',
    'Turkey',
    'Republic',
    'New Riegel',
    'Fishers Island',
    'Neosho Falls',
    'Bennington',
    'Grand Cane',
    'Windsor',
    'Calder',
    'Mattaponi',
    'Crawfordville',
    'Tyler',
    'Cleveland',
    'Batson',
    'La Mesa',
    'Wakefield',
    'Bethlehem',
    'Custer',
    'Creekside',
    'Clarksville',
    'Stittville',
    'Galesburg',
    'Nicktown',
    'Milmay',
    'Chatham',
    'Marshall',
    'Palmyra',
    'Trenton',
    'Gilman',
    'Berlin',
    'Dana',
    'Welaka',
    'Taberg',
    'Maxwell',
  ],
  tld: ['.us'],
  cca2: 'US',
  ccn3: '840',
  cca3: 'USA',
  cioc: 'USA',
  currency: ['USD', 'USN', 'USS'],
  callingCode: ['1'],
  capital: 'Washington D.C.',
  altSpellings: ['US', 'USA', 'United States of America'],
  region: 'Americas',
  subregion: 'Northern America',
  languages: { eng: 'English' },
  translations: {
    deu: {
      official: 'Vereinigte Staaten von Amerika',
      common: 'Vereinigte Staaten von Amerika',
    },
    fra: { official: "Les états-unis d'Amérique", common: 'États-Unis' },
    hrv: {
      official: 'Sjedinjene Države Amerike',
      common: 'Sjedinjene Američke Države',
    },
    ita: { official: "Stati Uniti d'America", common: "Stati Uniti d'America" },
    jpn: { official: 'アメリカ合衆国', common: 'アメリカ合衆国' },
    nld: {
      official: 'Verenigde Staten van Amerika',
      common: 'Verenigde Staten',
    },
    por: { official: 'Estados Unidos da América', common: 'Estados Unidos' },
    rus: {
      official: 'Соединенные Штаты Америки',
      common: 'Соединённые Штаты Америки',
    },
    spa: { official: 'Estados Unidos de América', common: 'Estados Unidos' },
    fin: { official: 'Amerikan yhdysvallat', common: 'Yhdysvallat' },
  },
  latlng: [38, -97],
  demonym: 'American',
  landlocked: false,
  borders: ['CAN', 'MEX'],
  area: 9372610,
  timezones: [
    'UTC−12:00',
    'UTC−11:00',
    'UTC−10:00',
    'UTC−09:00',
    'UTC−08:00',
    'UTC−07:00',
    'UTC−06:00',
    'UTC−05:00',
    'UTC−04:00',
    'UTC+10:00',
    'UTC+12:00',
  ],
} as const;
