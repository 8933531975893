export const BGR = {
  name: {
    common: 'Bulgaria',
    official: 'Republic of Bulgaria',
    native: { bul: { official: 'Република България', common: 'България' } },
  },
  cities: [
    'Sofia',
    'Plovdiv',
    'Varna',
    'Targovishte',
    'Velingrad',
    'Burgas',
    'Popovo',
    'Karlovo',
    'Pavel Banya',
    'Trustenik',
    'Isperikh',
    'Shumen',
    'Orlovets',
    'Dobrich',
    'Gabrovo',
    'Haskovo',
    'Provadiya',
    'Kharmanli',
    'Sliven',
    'Stara Zagora',
    'Kozloduy',
    'Shabla',
    'Kavarna',
    'Sevlievo',
    'Lovech',
    'Gorna Oryakhovitsa',
    'Blagoevgrad',
    'Petrich',
    'Krumovgrad',
    'Krichim',
    'Smolyan',
    'Radnevo',
    'Muglizh',
    'Troyan',
    'Veliko Tarnovo',
    'Nikopol',
    'Kazanlak',
    'Chakalarovo',
    'Pernik',
    'Ruen',
    'Barutin',
    'Bratsigovo',
    'Isperikhovo',
    'Godech',
    'Chernolik',
    'Razgrad',
    'Dulovo',
    'Yambol',
    'Asenovgrad',
    'Pazardzhik',
    'Chirpan',
    'Rozino',
    'Ravda',
    'Montana',
    'General-Toshevo',
    'Aksakovo',
    'Baltchik',
    'Rodopi',
    'Ikhtiman',
    'Samokov',
    'Svoge',
    'Sopot',
    'Kardzhali',
    'Pleven',
    'Kyustendil',
    'Topoli',
    'Beloslav',
    'Dulgopol',
    'Obzor',
    'Cherven',
    'Vratsa',
    'Borovan',
    'Knezha',
    'Cherven Bryag',
    'Sandanski',
    'Dupnitsa',
    'Lozno',
    'Krupnik',
    'Boboshevo',
    'Rousse',
    'Sandrovo',
    'Zavet',
    'Svishtov',
    'Belene',
    'Pavlikeni',
    'Samovodene',
    'Polski Trumbesh',
    'Kostievo',
    'Akhtopol',
    'Nesebar',
    'Sredets',
    'Pomorie',
    'Simitli',
    'Slivnitsa',
    'Mezdra',
    'Batak',
    'Elkhovo',
    'Belogradchik',
    'Tutrakan',
    'Botevgrad',
    'Moderno Predgradie',
    'Borovo',
    'Topolovo',
    'Bankya',
    'Konush',
    'Dimitrovgrad',
    'Smyadovo',
    'Gulubovo',
    'Simeonovgrad',
    'Panagyurishte',
    'Bansko',
    'Yakoruda',
    'Silistra',
    'Byala',
    'Choba',
    'Iskrets',
    'Vlado Trichkov',
    'Zlatitrap',
    'Nova Zagora',
    'Devin',
    'Gotse Delchev',
    'Dragizhevo',
    'Byala',
    'Momchilgrad',
    'Pravets',
    'Etropole',
    'Malo Konare',
    'Aytos',
    'Bukovo',
    'Banite',
    'Chiprovtsi',
    'Mesta',
    'Tryavna',
    'Berkovitsa',
    'Novi Pazar',
    'Krivodol',
    'Elena',
    'Strazhitsa',
    'Peshtera',
    'Sozopol',
    'Moravka',
    'Kovachite',
    'Omurtag',
    'Vidin',
    'Kostinbrod',
    'Ardino',
    'Alfatar',
    'Rakovski',
    'Poleto',
    'Rila',
    'Draginovo',
    'Oryakhovitsa',
    'Dolna Oryakhovitsa',
    'Kostenets',
    'Raduil',
    'Bukovo',
    'Strelcha',
    'Sokolets',
    'Skravena',
    'Vrachesh',
    'Stamboliyski',
    'Lom',
    'Bistritsa',
    'Khisarya',
    'Shivachevo',
    'Anton',
    'Madan',
    'Elin Pelin',
    'Veliko',
    'Debelets',
    'Oryakhovets',
    'Markovo',
    'Bregare',
    'Byala Slatina',
    'Sofronievo',
    'Kula',
    'Lyulyakovo',
    'Kableshkovo',
    'Antonovo',
    'Belovo',
    'Dospat',
    'Dolna Banya',
    'Septemvri',
    'Bobovdol',
    'Zlatograd',
    'Tervel',
    'Lyaskovets',
    'Mikrevo',
    'Vievo',
    'Chernomorets',
    'Kran',
    'Karnobat',
    'Dryanovo',
    'Ovoshtnik',
    'Koprivets',
    'Vetren',
    'Lukovit',
    'Babovo',
    'Kamenovo',
    'Orizovo',
    'Svilengrad',
    'Rudozem',
    'Aleksandriya',
    'Rakitovo',
    'Ivaylovgrad',
    'Glogovo',
    'Gurkovo',
    'Marikostinovo',
    'Tochilari',
    'Slatina',
    'Razlog',
    'Prespa',
    'Gurmazovo',
    'Novi Iskur',
    'Samuil',
    'Pchelishte',
    'Dragash Voyvoda',
    'Dolna Mitropoliya',
    'Koprivlen',
    'Suedinenie',
    'Kazashka Reka',
    'Ganchovo',
  ],
  tld: ['.bg'],
  cca2: 'BG',
  ccn3: '100',
  cca3: 'BGR',
  cioc: 'BUL',
  currency: ['BGN'],
  callingCode: ['359'],
  capital: 'Sofia',
  altSpellings: ['BG', 'Republic of Bulgaria', 'Република България'],
  region: 'Europe',
  subregion: 'Eastern Europe',
  languages: { bul: 'Bulgarian' },
  translations: {
    cym: { official: 'Republic of Bulgaria', common: 'Bwlgaria' },
    deu: { official: 'Republik Bulgarien', common: 'Bulgarien' },
    fra: { official: 'République de Bulgarie', common: 'Bulgarie' },
    hrv: { official: 'Republika Bugarska', common: 'Bugarska' },
    ita: { official: 'Repubblica di Bulgaria', common: 'Bulgaria' },
    jpn: { official: 'ブルガリア共和国', common: 'ブルガリア' },
    nld: { official: 'Republiek Bulgarije', common: 'Bulgarije' },
    por: { official: 'República da Bulgária', common: 'Bulgária' },
    rus: { official: 'Республика Болгария', common: 'Болгария' },
    spa: { official: 'República de Bulgaria', common: 'Bulgaria' },
    fin: { official: 'Bulgarian tasavalta', common: 'Bulgaria' },
  },
  latlng: [43, 25],
  demonym: 'Bulgarian',
  landlocked: false,
  borders: ['GRC', 'MKD', 'ROU', 'SRB', 'TUR'],
  area: 110879,
  provinces: [
    'Blagoevgrad',
    'Burgas',
    'Dobrich',
    'Gabrovo',
    'Khaskovo',
    'Kurdzhali',
    'Kyustendil',
    'Lovech',
    'Montana',
    'Pazardzhik',
    'Pernik',
    'Pleven',
    'Plovdiv',
    'Razgrad',
    'Ruse',
    'Shumen',
    'Silistra',
    'Sliven',
    'Smolyan',
    'Sofiya',
    'Sofiya-Grad',
    'Stara Zagora',
    'Turgovishte',
    'Varna',
    'Veliko Turnovo',
    'Vidin',
    'Vratsa',
    'Yambol',
  ],
  nativeName: 'България',
  timezones: ['UTC+02:00'],
} as const;
