import { TablePanel } from '@modules/table-panel';
import { stringify } from '@tools/stringify';

/** ... */
const templateInlineStyle = `
@media print {
  @page {
    size: A4 landscape;
  }
}

@media print {
  thead {
    display: table-header-group;
  }
}

body {
  margin: 0;
  font-size: 12px;
  line-height: 14px;
  font-family: Helvetica;
  overlay: hidden;
}

table {
  border: 1px solid #bbb;
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  border-collapse: collapse;
  page-break-after: always;
}

tr {
  background: white;
}

td {
  border: 1px solid #ddd;
  padding: 8px;
  line-height: 1.428571429;
  border-top: 1px solid #ddd;
}

thead > tr {
  font-weight: bold;
}

tbody > tr:nth-of-type(odd) {
  background: #d3d3d3;
}

.table-sub-header {
  border-top: 1px solid #bbb;
}

.table-sub-header > td {
  border: 0;
  color: #e88d20;
  text-align: right;
}

.table-sub-header > td:not(:first-of-type) {
  border-left: 1px solid #bbb !important;
}

label {
  display: block;
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

p {
  margin: 0;
}

.footnote {
  margin-bottom: 20px;
}

.score-table-cell {
  text-align: right;
}

.score-rating-card {
  display: inline-flex;
  justify-content: center;
  min-width: 60px;
}

.percentage-display {
  display: inline-block;
}

.score-good {
  background: #e1f888;
  color: #77c50b !important;
}

.score-average {
  background: #f6ecbc;
  color: #c8ac04 !important;
}

.score-poor {
  background: #ffd1cc;
  color: #c80424 !important;
}
`;

/**
 * ...
 */
export class PrintTableService {
  /**
   * ...
   *
   * @param content ...
   */
  print(content: string) {
    const frameElem = document.createElement('iframe');
    frameElem.width = '100%';
    frameElem.height = '100%';
    frameElem.src = 'about:blank';

    frameElem.classList.add('print-frame');
    document.body.append(frameElem);

    frameElem.contentWindow?.document.open('text/html', 'replace');
    frameElem.contentWindow?.document.write(content);
    frameElem.contentWindow?.document.close();

    // ...
    frameElem.remove();
  }

  /**
   * ...
   *
   * @param columns ...
   * @param rows ...
   */
  fromTableData<C, R>(
    columns: TablePanel.Column<C>[],
    rows: TablePanel.Row<R>[],
  ) {
    const headerContent = columns
      .map((column) => `<td>${column.label}</td>`)
      .join('');

    let tbody = '';

    for (const { values } of rows) {
      let row = '';

      for (const { key } of columns) {
        row += `<td>${stringify(values[key])}</td>`;
      }

      tbody += `<tr>${row}</tr>`;
    }

    const content = `
        <!DOCTYPE html>
        <html>
          <head>
            <title>User Info Table</title>
            <style>${templateInlineStyle}</style>
          </head>
          <body>
            <table>
              <thead>
                <tr>${headerContent}</tr>
              </thead>
              <tbody>${tbody}</tbody>
            </table>
            <script>window.print(); function Kill(cb) {cb();}</script>
          </body>
        </html>
    `;

    this.print(content);
  }
}

/** ... */
export const printTable = new PrintTableService();

export default printTable;
