import { server } from '@api/request';
import * as models from '@models';
import { isObject } from '@tools/type-guards';

/**
 * ...
 */
export interface GetOptions {
  orderId?: models.Order['id'];
  invoiceId: models.Invoice['id'];
  organizationId: models.Organization['id'];
}

/**
 * Get a specified transaction.
 *
 * @param options Request options bag.
 * @return The specified transaction.
 */
export async function get(options: GetOptions) {
  const { organizationId, ...data } = options;

  return await server.post(
    `v2/organizations/${organizationId}/transactions`,
    data,
    processTransactionData,
  );
}

//#region Helper Functions

/**
 * ...
 *
 * @param value ...
 * @returns ...
 */
function isValidTransactionData(value: unknown): value is models.Transaction {
  if (!isObject(value)) return false;

  // TEMP: Use a more strict validation.

  return true;
}

/**
 * ...
 *
 * @param data ...
 * @returns ...
 */
function processTransactionData(data: unknown) {
  if (!isValidTransactionData(data)) {
    throw new Error('Invalid license data item.');
  }

  return { ...data } as models.Transaction;
}

//#endregion Helper Functions
