export const AUT = {
  name: {
    common: 'Austria',
    official: 'Republic of Austria',
    native: { bar: { official: 'Republik Österreich', common: 'Österreich' } },
  },
  cities: [
    'Vienna',
    'Kapfenberg',
    'Kirchham',
    'Hohenzell',
    'Gmunden',
    'Molln',
    'Judendorf',
    'Ebersdorf',
    'Hinterstoder',
    'Sarleinsbach',
    'Sankt Michael im Burgenland',
    'Bad Ischl',
    'Salzburg',
    'Linz',
    'Fieberbrunn',
    'Graz',
    'Seiersberg',
    'Hohenau',
    'Brixen im Thale',
    'Schwaz',
    'Maria Enzersdorf',
    'Tulln',
    'Hartkirchen',
    'Hermagor',
    'Imst',
    'Tarrenz',
    'Jerzens',
    'Plangeross',
    'Wilhelmsburg',
    'Melk',
    'Korneuburg',
    'Giesshubl',
    'Stockerau',
    'MÃ¶dling',
    'Mondsee',
    'Saalfelden am Steinernen Meer',
    'Weiz',
    'Weinburg am Sassbach',
    'Feldbach',
    'Waidhofen an der Ybbs',
    'Seitenstetten Markt',
    'Schweiggers',
    'Weitra',
    'Wallern an der Trattnach',
    'Neumarkt im Hausruckkreis',
    'Sankt Florian',
    'Wartberg an der Krems',
    'Taufkirchen an der Trattnach',
    'Zell an der Ybbs',
    'Kopfing im Innkreis',
    'Pottendorf',
    'Weigelsdorf',
    'Klosterneuburg',
    'Vosendorf',
    'Markgrafneusiedl',
    'Munchendorf',
    'Pillichsdorf',
    'Pichling bei Stainz',
    'Zettling',
    'Stainz',
    'Frohnleiten',
    'Lannach',
    'Deutschlandsberg',
    'Unterrohr',
    'Pischelsdorf in Steiermark',
    'Kindberg',
    'Fehring',
    'Neunkirchen',
    'Oberkohlstaetten',
    'Wiener Neustadt',
    'Grunbach am Schneeberg',
    'Ternitz',
    'Pitten',
    'Marktl',
    'Parndorf',
    'Ollern',
    'Schonkirchen',
    'Strasshof an der Nordbahn',
    'Traiskirchen',
    'Mauerbach',
    'Pettnau',
    'Schwoich',
    'Aschau',
    'Aldrans',
    'Jochberg',
    'Obernberg am Brenner',
    'Wels',
    'Rubring',
    'Enzenkirchen',
    'Lochen',
    'Aschach an der Donau',
    'Klagenfurt',
    'Woelfnitz',
    'Obsteig',
    'Kirchbichl',
    'Innsbruck',
    'Schoenberg im Stubaital',
    'Fulpmes',
    'Freistadt',
    'Walding',
    'Ried im Innkreis',
    'Luftenberg an der Donau',
    'Feldkirch',
    'Bregenz',
    'Hochst',
    'Lustenau',
    'Wallsee',
    'Pochlarn',
    'Wieselburg',
    'Horn',
    'Leoben',
    'Weisskirchen in Steiermark',
    'Obdach',
    'Muerzhofen',
    'Gross',
    'Dietersdorf am Gnasbach',
    'Stein an der Enns',
    'Fohnsdorf',
    'Murfeld',
    'Stainz bei Straden',
    'Gratkorn',
    'Villach',
    'Heimschuh',
    'Wolfsberg',
    'Althofen',
    'Buchkirchen',
    'Neumarkt an der Ybbs',
    'Gunskirchen',
    'Neumarkt im Muhlkreis',
    'Zipf',
    'Eferding',
    'Wernstein am Inn',
    'Gurten',
    'Esternberg',
    'Niederneukirchen',
    'Neuhofen',
    'Attnang-Puchheim',
    'Schardenberg',
    'Kirchschlag bei Linz',
    'Steyr',
    'Aistersheim',
    'Pettenbach',
    'Siezenheim',
    'Neukirchen am Walde',
    'Hochleithen',
    'Ringelsdorf',
    'Baernbach',
    'Koflach',
    'Krems',
    'Amstetten',
    'Weitersfeld',
    'Amaliendorf',
    'Emmersdorf an der Donau',
    'Grafenworth',
    'Kienberg',
    'Neudorf bei Staatz',
    'Waldenstein',
    'Poelten',
    'Kalsdorf bei Graz',
    'Wagna',
    'Muerzzuschlag',
    'Lanzenkirchen',
    'Bad Fischau',
    'Kilb',
    'Wiesmath',
    'Eisenstadt',
    'Kufstein',
    'Hatting',
    'Oberperfuss',
    'Telfs',
    'Bad Goisern',
    'Strobl',
    'Hallein',
    'Hard',
    'Dornbirn',
    'Rankweil',
    'Lauterach',
    'Klosterle',
    'Zirl',
    'Admont',
    'Hartberg',
    'Prabach',
    'Trieben',
    'Grossweikersdorf',
    'Laa an der Thaya',
    'Gaflenz',
    'Dietach',
    'Altenberg bei Linz',
    'Engerwitzdorf',
    'Ulrichsberg',
    'Ried in der Riedmark',
    'Feldkirchen an der Donau',
    'Mehrnbach',
    'Scharnstein',
    'Oehling',
    'Oberrohrbach',
    'Sommerein',
    'Horsching',
    'Werfenweng',
    'Seefeld in Tirol',
    'Bad Gleichenberg',
    'Lech',
    'Serfaus',
    'Muerzsteg',
    'Sieghartskirchen',
    'Harbach',
    'Kaindorf',
    'Gleisdorf',
    'Purkersdorf',
    'Markt Allhau',
    'Deutschfeistritz',
    'Sebersdorf',
    'Straden',
    'Lassnitzhohe',
    'Unterwaltersdorf',
    'Jois',
    'Feldkirchen in Karnten',
    'Feistritz',
    'Stein im Jauntal',
    'Strasswalchen',
    'Anif',
    'Tiefgraben',
    'Perchtoldsdorf',
    'Schwarzenau',
    'Himmelreich',
    'Gaaden',
    'Horbranz',
    'Hopfgarten im Brixental',
    'Tirol',
    'Ernst',
    'Grimmenstein',
    'Bergheim',
    'Ansfelden',
    'Herzogenburg',
    'Schwechat',
    'Hollabrunn',
    'Unterlamm',
    'Gnas',
    'Weibern',
    'Hohnhart',
    'Aurolzmunster',
    'Puch bei Hallein',
    'Fugen',
    'Brunn am Gebirge',
    'Rannersdorf',
    'Ainet',
    'Traun',
    'Hof am Leithaberge',
    'Weikersdorf am Steinfelde',
    'Zillingtal',
    'Zillingdorf',
    'Hall',
    'Absam',
    'Hall in Tirol',
    'Tribuswinkel',
    'Gablitz',
    'Breitenfurt bei Wien',
    'Reith im Alpbachtal',
    'Worgl',
    'Ellmau',
    'Thaur',
    'Kirchberg in Tirol',
    'Rum',
    'Kramsach',
    'Mayrhofen',
    'Stattegg',
    'Bad Voslau',
    'Altach',
    'Gotzis',
    'Altenhof am Hausruck',
    'Gallspach',
    'Bad Schallerbach',
    'Grieskirchen',
    'Schwanenstadt',
    'Ohlsdorf',
    'Pichl bei Wels',
    'Baden bei Wien',
    'Vocklabruck',
    'Leonding',
    'Ampfelwang',
    'Scharding',
    'Perg',
    'Unterjoch',
    'Holzhausen',
    'Natternbach',
    'Bad Gastein',
    'Wals',
    'Saalbach',
    'Anthering',
    'Sankt Johann im Pongau',
    'Bad Hofgastein',
    'Hinterglemm',
    'Obertauern',
    'Zell am See',
    'Stubach',
    'Lavant',
    'Tristach',
    'Sillian',
    'Waidring',
    'Lermoos',
    'Vent',
    'Oberau',
    'Matrei in Osttirol',
    'Rinn',
    'Berwang',
    'Pettneu',
    'OEtztal-Bahnhof',
    'Bruggen',
    'Pertisau',
    'Kuehtai',
    'Finkenberg',
    'Ischgl',
    'Ried im Zillertal',
    'Zell am Ziller',
    'Nauders',
    'Alpbach',
    'Walchsee',
    'Ehrwald',
    'Gschnitz',
    'Riezlern',
    'Wald am Arlberg',
    'Schoppernau',
    'Galtur',
    'Gargellen',
    'Bludenz',
    'Bartholomaberg',
    'Krumpendorf',
    'Mittelberg',
    'Neustift',
    'Bizau',
    'Im Brand',
    'Schrems',
    'Bad Pirawarth',
    'Eggenburg',
    'Sankt Polten',
    'Baumgarten im Burgenland',
    'Wundschuh',
    'Glan',
    'Poertschach am Woerthersee',
    'Oberaichwald',
    'Doebriach',
    'Maria Saal',
    'Millstatt',
    'Kleinkirchheim',
    'Bodensdorf',
    'Velden am Woerthersee',
    'Sirnitz',
    'Grafenstein',
    'Sankt Ulrich bei Steyr',
    'Sipbachzell',
    'Ennsdorf',
    'Pucking',
    'Neusiedl bei Guessing',
    'Raab',
    'Reichendorf',
    'Miesenbach',
    'Ebbs',
    'Wangle',
    'Hofen',
    'Poysdorf',
    'Untersiebenbrunn',
    'Sitzenberg',
    'Obersiebenbrunn',
    'Purgstall',
    'Wurmbrand',
    'Asperhofen',
    'Axams',
    'Steinach am Brenner',
    'Nussdorf',
    'Gallneukirchen',
    'Klein-Harras',
    'Kirchberg am Wagram',
    'Schwadorf',
    'Oberkirchbach',
    'Bisamberg',
    'Wiener Neudorf',
    'Oberneukirchen',
    'Kremsmunster',
    'Seewalchen',
    'Oberkappel',
    'Andorf',
    'Schiefling am See',
    'Voelkermarkt',
    'Landskron',
    'Purgg',
    'Leutschach',
    'Ebenthal',
    'Arriach',
    'Gosdorf',
    'Hohenems',
    'Mader',
    'Schruns',
    'Rohrau',
    'Kierling',
    'Oed',
    'Oggau',
    'Hainburg an der Donau',
    'Reisenberg',
    'Steinhaus am Semmering',
    'Steinabruckl',
    'Donnerskirchen',
    'Bruck an der Leitha',
    'Wulkaprodersdorf',
    'Gols',
    'Aichbach',
    'Lindenberg',
    'Vocklamarkt',
    'Lichtenberg',
    'Micheldorf in Oberoesterreich',
    'Grein',
    'Mairhof',
    'Gross-Enzersdorf',
    'Hinterbruehl',
    'Tattendorf',
    'Traboch',
    'Spielberg bei Knittelfeld',
    'Tauplitz',
    'Ramsau im Zillertal',
    'Grosspesendorf',
    'Passail',
    'Hohenburg',
    'Knittelfeld',
    'Bruck an der Mur',
    'Enns',
    'Ungenach',
    'Asten',
    'Oftering',
    'Laakirchen',
    'Weissenbach am Lech',
    'Pfunds',
    'Graen',
    'Mathon',
    'Haiming',
    'Panzendorf',
    'Scheffau am Wilden Kaiser',
    'Schoenau',
    'Saint Anton',
    'Hochgurgl',
    'Wenns',
    'KitzbÃ¼hel',
    'Hoehe',
    'Maurach',
    'Solden',
    'Leutasch',
    'Vorderlanersbach',
    'Lienz',
    'Niederachen',
    'Hinterthiersee',
    'Achenkirch',
    'Kossen',
    'Bichlbach',
    'Uderns',
    'Sierndorf',
    'Altlengbach',
    'Langenlois',
    'Schongrabern',
    'Litschau',
    'Guntramsdorf',
    'Afritz',
    'Friesach',
    'Faak am See',
    'Latschach',
    'Aifersdorf',
    'Bleiberg ob Villach',
    'Sankt Martin am Techelsberg',
    'Ossiach',
    'Winkl',
    'Seeboden',
    'Annenheim',
    'Ostermiething',
    'Rauris',
    'Bramberg am Wildkogel',
    'Filzmoos',
    'Pichl',
    'Heissingfelding',
    'Plankenau',
    'Karteis',
    'Grossarl',
    'Reitdorf',
    'Grodig',
    'Kremsdorf',
    'Moosburg',
    'Strau',
    'Mutters',
    'Maildorf',
    'Arnoldstein',
    'Viktring',
    'Petersdorf',
    'Markt Neuhodis',
    'Liezen',
    'Mank',
    'Sankt Georgen am Ybbsfelde',
    'Oberndorf an der Melk',
    'Boheimkirchen',
    'Pyhra',
    'Randegg',
    'Karlstetten',
    'Lichtenau',
    'Erlauf',
    'Maiersdorf',
    'Ebergassing',
    'Grafenbach',
    'Neudorfl',
    'Neckenmarkt',
    'Sollenau',
    'Langenzersdorf',
    'Katzelsdorf',
    'Krottendorf bei Ligist',
    'Sankt Lorenzen im Muerztal',
    'Unterpurkla',
    'Falkenburg',
    'Geras',
    'Spitz',
    'Heidenreichstein',
    'Rotenbach',
    'Petronell-Carnuntum',
    'Kirchschlag',
    'Grieselstein',
    'Gattendorf',
    'Kottingbrunn',
    'Asparn',
    'Sankt Wolfgang',
    'Untergrub',
    'Sankt Wolfgang',
    'Schwertberg',
    'Fuerstenfeld',
    'Wettmannstatten',
    'Tschagguns',
    'Warth',
    'Sankt Gallenkirch',
    'Kreuz',
    'Lochau',
    'Gaschurn',
    'Rottenmann',
    'Guglwald',
    'Haag am Hausruck',
    'Burgkirchen',
    'Werndorf',
    'Grossraming',
    'Thalheim bei Wels',
    'Asberg',
    'Pasching',
    'Krenglbach',
    'Haid',
    'Ottensheim',
    'Mitterdorf im Muerztal',
    'Loipersbach',
    'Unterweitersdorf',
    'Pottenbrunn',
    'Hohenberg',
    'Gumpoldskirchen',
    'Wollersdorf',
    'Markt Piesting',
    'Pfaffstatten',
    'Laxenburg',
    'Himberg',
    'Neudorf bei Landsee',
    'Leobendorf',
    'Turnitz',
    'Pressbaum',
    'Biedermannsdorf',
    'Wolfsgraben',
    'Rekawinkel',
    'Tullnerbach-Lawies',
    'Gramatneusiedl',
    'Maria Lanzendorf',
    'Zwolfaxing',
    'Hennersdorf',
    'Trumau',
    'Hofstetten',
    'Unterradlberg',
    'Muckendorf an der Donau',
    'Ganserndorf',
    'Hainfeld',
    'Trautmannsdorf an der Leitha',
    'Bad Radkersburg',
    'Waltersdorf an der March',
    'Lutzmannsburg',
    'Gotzendorf an der Leitha',
    'Westendorf',
    'Oberwart',
    'Weiden am See',
    'Unterpullendorf',
    'Am See',
    'Winden am See',
    'Purbach am Neusiedlersee',
    'Oslip',
    'Mattersburg',
    'Kundl',
    'Lenzing',
    'Stadl-Paura',
    'Abtenau',
    'Radstadt',
    'Eben im Pongau',
    'Leogang',
    'Gallbrunn',
    'Lilienfeld',
    'Maria Taferl',
    'Ybbs an der Donau',
    'Gosing',
    'Bernstein',
    'Rappoltenkirchen',
    'Zuers',
    'Dechantskirchen',
    'Gnadenwald',
    'Mieming',
    'Getzersdorf',
    'Marchtrenk',
    'Fiss',
    'Feuchten',
    'Oberweis',
    'Mauthausen',
    'Reichenau im Muhlkreis',
    'Hagenberg im Muhlkreis',
    'Leibnitz, Styria',
    'Bruck an der Grossglocknerstrasse',
    'Uttendorf',
    'Fusch an der Glocknerstrasse',
    'Kaprun',
    'Altenberg',
    'Piesendorf',
    'Maria Alm am Steinernen Meer',
    'Werfen',
    'Sulzau',
    'Gaisberg',
    'Goldegg',
    'Lend',
    'Schwarzach im Pongau',
    'Tamsweg',
    'Margarethen am Moos',
    'Burmoos',
    'Seeham',
    'Ebensee',
    'Mattsee',
    'Seekirchen am Wallersee',
    'Thalgau',
    'Hof bei Salzburg',
    'Koppl',
    'Eugendorf',
    'Ebenau',
    'Tiefenbach',
    'Elixhausen',
    'Oberndorf bei Salzburg',
    'Kuchl',
    'Golling an der Salzach',
    'Lungotz',
    'Altenmarkt im Pongau',
    'Flachau',
    'Wagrain',
    'Sankt Veit im Pongau',
    'Oberalm',
    'Henndorf am Wallersee',
    'Fuschl am See',
    'Lamprechtshausen',
    'Sausenstein',
    'Deutsch-Wagram',
    'Obergurgl',
    'Zams',
    'Landeck',
    'Neusiedl am See',
    'Rohrbach am Steinfelde',
    'Laab im Walde',
    'Statzendorf',
    'Gerasdorf am Steinfelde',
    'Walbersdorf',
    'Reichenau an der Rax',
    'Felixdorf',
    'Mollersdorf',
    'Hirtenberg',
    'Kennelbach',
    'Parschlug',
    'Hausmannstatten',
    'Wolfurt',
    'Koblach',
    'Klaus',
    'Fussach',
    'Muntlix',
    'Elsbethen',
    'Mariazell',
    'Leobersdorf',
    'Hofstatten an der Raab',
    'Grobming',
    'Innermanzing',
    'Enzesfeld',
    'Hohentauern',
    'Maria Worth',
    'Rosenbach',
    'Freienstein',
    'Wattens',
    'Leitring',
    'Ligist',
    'Stiegl',
    'Kaltenbrunn',
    'Gratwein',
    'Eggersdorf bei Graz',
    'Strassengel',
    'Ferndorf',
    'Wildon',
    'Spittal an der Drau',
    'Sankt Veit an der Glan',
    'Weiler',
    'Raaba',
    'Voitsberg',
    'Lofer',
    'Haidershofen',
    'Unterrohr',
    'Neukirchen an der Vockla',
    'Frankenburg',
    'Oberaich',
    'Sankt Michael in Obersteiermark',
    'Petzenkirchen',
    'Edelschrott',
    'Mannsworth',
    'Garsten',
    'Kirchdorf an der Krems',
    'Sulz',
    'Hadersfeld',
    'Wolkersdorf im Weinviertel',
    'Berndorf',
    'Leisach',
    'Reith bei Seefeld',
    'Bad Hall',
    'Wiesing',
    'Kritzendorf',
    'Riedenthal',
    'Hornstein',
    'Biberbach',
    'Langenstein',
    'Vorchdorf',
    'Puchenau',
    'Bad Kreuzen',
    'Traunkirchen',
    'Neukirchen',
    'Judenburg',
    'Scheifling',
    'Murau',
    'Teufenbach',
    'Apfelberg',
    'Kapfing',
    'Barwies',
    'Tumeltsham',
    'Hinterwaldberg',
    'Passthurn',
    'Sankt Lorenzen',
    'Aigen',
    'Hipping',
    'Eberndorf',
    'Turrach',
    'Sankt Andrae',
    'Augsdorf',
    'Hirschegg',
    'Bezau',
    'Miesenbach',
    'Schwarzau am Steinfelde',
    'Semmering',
    'Pirka',
    'Bad Gams',
    'Steinerkirchen an der Traun',
    'Leonfelden',
    'Soll',
    'Igls',
    'Haimingerberg',
    'Gerlos',
    'Patsch',
    'Kappl',
    'Kematen in Tirol',
    'Oetz',
    'Jungholz',
    'Stein an der Donau',
    'Glinzendorf',
    'Liebenfels',
    'Wieting',
    'Vordernberg',
    'Kobenz',
    'Lieboch',
    'Krieglach',
    'Ludesch',
    'Nuziders',
    'Au',
    'Zistersdorf',
    'Guntersdorf',
    'Ort im Innkreis',
    'Weilbach',
    'Hellmonsodt',
    'Eberschwang',
    'Altheim',
    'Labach',
    'Lembach im Muhlkreis',
    'Sulz im Wienerwald',
    'Zwentendorf',
    'Schleinbach',
    'Gerasdorf bei Wien',
    'Hintersdorf',
    'Mettmach',
    'Ach',
    'Alberndorf in der Riedmark',
    'Dellach',
    'Wilhering',
    'Gaspoltshofen',
    'Scharten',
    'Waxenberg',
    'Alkoven',
    'Meggenhofen',
    'Sankt Pankraz',
    'Freilassing',
    'Desselbrunn',
    'Plescherken',
    'Flattach',
    'Gailitz',
    'Kleinbergl',
    'Gruner Baum',
    'Steyregg',
    'Isper',
    'Huetten',
    'Sernau',
    'Niederwolz',
    'Schwanberg',
    'Haller',
    'Finsing',
    'Sankt Oswald',
    'Kleinstubing',
    'Leiten',
    'Obertrum am See',
    'Stans',
    'Strassen',
    'Moos',
    'Sankt Valentin',
    'Spratzern',
    'Keutschach am See',
    'Rohrmoos',
    'Schladming',
    'Stuhlfelden',
    'Mittersill',
    'Hollersbach im Pinzgau',
    'Vigaun',
    'Dorf',
    'Krispl',
    'Tadten',
    'Drassmarkt',
    'Riedlingsdorf',
    'Wallern im Burgenland',
    'Siegendorf im Burgenland',
    'Pottsching',
    'Sankt Andra am Zicksee',
    'Mannersdorf an der Rabnitz',
    'Reutte',
    'Kappern',
    'Weisskirchen an der Traun',
    'Regau',
    'Sankt Georgen im Attergau',
    'Kefermarkt',
    'Neuhofen an der Krems',
    'Ebreichsdorf',
    'Peuerbach',
    'Bruendl',
    'Ternberg',
    'Innerkrems',
    'Sankt Kanzian',
    'Stadelbach',
    'Kuehnsdorf',
    'Sachendorf',
    'Eisenberg',
    'Sauerbrunn',
    'Payerbach',
    'Hohenwarth',
    'Bad Tatzmannsdorf',
    'Stegersbach',
    'Waidmannsfeld',
    'Roppen',
    'Mosern',
    'Meisten',
    'Schlins',
    'Fischbach',
    'Weissenkirchen in der Wachau',
    'Altwaidhofen',
    'Kaumberg',
    'Hausbrunn',
    'Zwettl Stadt',
    'Laaben',
    'Liesfeld',
    'Sankt Ruprecht an der Raab',
    'Sierning',
    'Neukirchen an der Enknach',
    'Gramastetten',
    'Edlitz',
    'Kittsee',
    'Stoob',
    'Sankt Martin am Ybbsfelde',
    'Michelbach Markt',
    'Watzelsdorf',
    'Dross',
    'Gfohl',
    'Judenau',
    'Konigstetten',
    'Lobenstein',
    'Opponitz',
    'Freinberg',
    'Waizenkirchen',
    'Thalling',
    'Rothis',
    'Schwarzach',
    'Wordern',
    'Frauenkirchen',
    'Purbach',
    'Grafenschlag',
    'Gostling an der Ybbs',
    'Steinakirchen am Forst',
    'Ulmerfeld',
    'Gmund',
    'Redlham',
    'Sankt Agatha',
    'Ernstbrunn',
    'Puchberg am Schneeberg',
    'Arbesthal',
    'Fuernitz',
    'Steindorf am Ossiacher See',
    'Traunstein',
    'Gerersdorf',
    'Niederland',
    'Tweng',
    'Dorfgastein',
    'Hinterburg',
    'Madseit',
    'Motz',
    'Emberg',
    'Mellach',
    'Spital am Pyhrn',
    'Eggendorf',
    'Waltersdorf in Oststeiermark',
    'Schonbuhel an der Donau',
    'Wienerherberg',
    'Tannheim',
    'Grafenweg',
    'Schwaigs',
    'Haldensee',
    'Hintertux',
    'Oepping',
    'Weng im Innkreis',
    'Gurtis',
    'Heiterwang',
    'Siebenhirten',
    'Maria-Anzbach',
    'Knappenberg',
    'Launsdorf',
    'Lavamund',
    'Vols',
    'Brandberg',
    'Braunau am Inn',
    'Hofkirchen im Traunkreis',
    'Feldkirchen bei Mattighofen',
    'Feldkirchen bei Graz',
    'Hollenthon',
    'Pottelsdorf',
    'Nenzing',
    'Egg',
    'Lichtenworth',
    'Theresienfeld',
    'Gunselsdorf',
    'Vorau',
    'Unterpremstatten',
    'Lebring',
    'Weissbriach',
    'Eisbach',
    'Peggau',
    'Zeiselmauer',
    'Kapelln',
    'Frattingsdorf',
    'Neumarkt in Steiermark',
    'Kroatisch Minihof',
    'Enzenreith',
    'Gloggnitz',
    'Furthof',
    'Dalaas',
    'Blindenmarkt',
    'Zellerndorf',
    'Grossebersdorf',
    'Grossrussbach',
    'Orth an der Donau',
    'Ulrichskirchen',
    'Engersdorf',
    'Schoerfling',
    'Neuzeug',
    'Perwang am Grabensee',
    'Attersee',
    'Achensee',
    'Gotzens',
    'Inzing',
    'Ernsthofen',
    'Ebenfurth',
    'Klingfurth',
    'Potzneusiedl',
    'Weppersdorf',
    'Tobaj',
    'Aspang Markt',
    'Wurflach',
    'Hausleiten',
    'Herrnbaumgarten',
    'Fliess',
    'Sistrans',
    'Nassereith',
    'Telfes im Stubai',
    'Pfons',
    'Egg am Faaker See',
    'Eberstein',
    'Rangersdorf',
    'Moellbruecke',
    'Eisentratten',
    'Koestenberg',
    'Gaal',
    'Ardning',
    'Pinkafeld',
    'Anger',
    'Schwarzenberg am Boehmerwald',
    'Buch bei Jenbach',
    'Flinsdorf',
    'Konigswiesen',
    'Schalchen',
    'Mauerkirchen',
    'Neumarkt am Wallersee',
    'Frankenmarkt',
    'Mallnitz',
    'Schutzen am Gebirge',
    'Trausdorf an der Wulka',
    'Deutschkreutz',
    'Breitenbrunn',
    'Edelstal',
    'Mistelbach',
    'Bad Blumau',
    'Pregarten',
    'Rosental',
    'Dobersberg',
    'Neulengbach',
    'Poggstall',
    'Weiten',
    'Pinggau',
    'Purgstall',
    'Rudersdorf',
    'Stuhlsdorf',
    'Grossklein',
    'Diepoltsham',
    'Pinsdorf',
    'Schlierbach',
    'Eggendorf im Thale',
    'Trofaiach',
    'Heissberg',
    'Jennersdorf',
    'Berg',
    'Satteins',
    'Ehrenhausen',
    'Thal',
    'Riegersburg',
    'Kollmitzberg',
    'Gresten',
    'Pernegg an der Mur',
    'Aigen im Muehlkreis',
    'Nesselwangle',
    'Rotholz',
    'Pillberg',
    'Wimpassing im Schwarzatale',
    'Niederalm',
    'Leopoldsdorf',
    'Bach',
    'Erpfendorf',
    'Gries',
    'Lanersbach',
    'Blumau',
    'Kumberg',
    'Neudau',
    'Edt bei Lambach',
    'Gugging',
    'Mannersdorf am Leithagebirge',
    'Wampersdorf',
    'Obervellach',
    'Zemendorf',
    'Guessing',
    'Bruckneudorf',
    'Oberpullendorf',
    'Zurndorf',
    'Going',
    'Mitterndorf an der Fischa',
    'Thoerl',
    'Breitenwang',
    'Lechaschau',
    'Bauer',
    'Prinzersdorf',
    'Nickelsdorf',
    'Neufeld an der Leitha',
    'Enzersdorf an der Fischa',
    'Tschoeran',
    'Toeschling',
    'Durnstein',
    'Eibiswald',
    'Krumbach',
    'Hernstein',
    'Kanzelhoehe',
    'Maglern',
    'Troepolach',
    'Hallstatt',
    'Karl',
    'Maria Buch',
    'Paldau',
    'Birgitz',
    'Hoflein',
    'Gaweinstal',
    'Loimersdorf',
    'Weikendorf',
    'Bosendurnbach',
    'Pischeldorf',
    'Loosdorf',
    'Marbach',
    'Allhartsberg',
    'Waidhofen an der Thaya',
    'Traisen',
    'Ruden',
    'Feistritz an der Drau',
    'Rosegg',
    'Bruckl',
    'Fernitz',
    'Fladnitz im Raabtal',
    'Wies',
    'Polfing',
    'Altendorf',
    'Offenhausen',
    'Herzogsdorf',
    'Taufkirchen an der Pram',
    'Niederdorf',
    'Feld am See',
    'Pattergassen',
    'Taxenbach',
    'Nussdorf am Haunsberg',
    'Zell am Moos',
    'Huttschlag',
    'Vitis',
    'Jagenbach',
    'Altendorf',
    'Hoheneich',
    'Eisgarn',
    'Steinhaus',
    'Ried im Traunkreis',
    'Obernberg',
    'Munzbach',
    'Waldzell',
    'Munzkirchen',
    'Gampern',
    'Haibach ob der Donau',
    'Dorfla',
    'Heiligenkreuz am Waasen',
    'Krumegg',
    'Stubenberg',
    'Marchegg',
    'Aichdorf',
    'Langenrohr',
    'Steinerberg',
    'Jeging',
    'Alland',
    'Sinabelkirchen',
    'Rohrbach am Kulm',
    'Mooskirchen',
    'Unzmarkt',
    'Bernhardsthal',
    'Eckartsau',
    'Bockfliess',
    'Biedermann',
    'Altmunster',
    'Tieschen',
    'Rieding',
    'Grabelsdorf',
    'Mandling',
    'Ottenheim',
    'Strass',
    'Aggsbach',
    'Nestelbach bei Graz',
    'Wimpassing an der Leitha',
    'Pottenstein',
    'Ampass',
    'Biberwier',
    'Gosau',
    'Schneeberg',
    'Sankt Peter',
    'Absdorf',
    'Abtsdorf',
    'Kirchdorf in Tirol',
    'Schwendau',
    'Stadtschlaining',
    'Lichtenegg',
    'Wolfau',
    'Fischerndorf',
    'Unterberg',
    'Aufhausen',
    'Eichgraben',
    'Mollram',
    'Schwarzau im Gebirge',
    'Sankt Margarethen im Burgenland',
    'Riefensberg',
    'Windischgarsten',
    'Kraig',
    'Maria Elend',
    'Haag',
    'Naarn im Machlande',
    'Maria Ellend',
    'Wilfleinsdorf',
    'Bad Deutsch-Altenburg',
    'Raabs an der Thaya',
    'Neidling',
    'Gutau',
    'Pram',
    'Enzersfeld',
    'Altenmarkt an der Triesting',
    'Rauchenwarth',
    'Steinfeld',
    'Sachsenburg',
    'Lendorf',
    'Niederndorf',
    'Gries am Brenner',
    'Schmirn',
    'Matrei am Brenner',
    'Hadres',
    'Maissau',
    'Hochburg',
    'Oberwolbling',
    'Hadersdorf am Kamp',
    'Atzbach',
    'Maria Schmolln',
    'Obergruenburg',
    'Piberbach',
    'Engelhartszell',
    'Rohrbach in Oberosterreich',
    'Ottendorf bei Gleisdorf',
    'Doren',
    'Sibratsgfall',
    'Bad Erlach',
    'Oberwaltersdorf',
    'Staatz',
    'Altenburg',
    'Neutal',
    'Monchhof',
    'Katsdorf',
    'Utzenaich',
    'Poetting',
    'Niederleis',
    'Unterparschenbrunn',
    'Auersthal',
    'Baumkirchen',
    'Thaya',
    'Eggendorf',
    'Ebendorf',
    'Winzendorf',
    'Piringsdorf',
    'Kobersdorf',
    'Klostermarienberg',
    'Poysbrunn',
    'Erpersdorf',
    'Bleiburg',
    'Ybbsitz',
    'Kaltenleutgeben',
    'Reifnitz',
    'Burgschleinitz',
    'Traismauer',
    'Stams',
    'Radfeld',
    'Maria Lankowitz',
    'Wartberg',
    'Buch',
    'Puch',
    'Sankt Stefan im Rosental',
    'Dobersdorf',
    'Landegg',
    'Stumm',
    'Viktorsberg',
    'Gortipohl',
    'Krumbach Markt',
    'Hochneukirchen',
    'Marz',
    'Seckau',
    'Altlichtenwarth',
    'Bergau',
    'Gotzendorf',
    'Pischelsdorf am Engelbach',
    'Pabneukirchen',
    'Rosental',
    'Doblbach',
    'Obritzberg',
    'Gansbach',
    'Schattendorf',
    'Halbturn',
    'Rosenau am Hengstpass',
    'Grambach',
    'Langeck im Burgenland',
    'Waldkirchen am Wesen',
    'Zeltweg',
    'Ober-Grafendorf',
    'Steg',
    'Forst',
    'Studenzen',
    'Kottmannsdorf',
    'Preding',
    'Neuhofen an der Ybbs',
    'Schachendorf',
    'Pernitz',
    'Goedersdorf',
    'Geinberg',
    'Reichraming',
    'Rottenbach',
    'Rutzenmoos',
    'Mitterkirchen im Machland',
    'Lassee',
    'Nochling',
    'Stettenhof',
    'Warth',
    'Hirm',
    'Sankt Kathrein am Hauenstein',
    'Wang',
    'Zeillern',
    'Gutenstein',
    'Adnet',
    'Muhlbach am Manhartsberg',
    'Spillern',
    'Watzendorf',
    'Oberort',
    'Grafendorf bei Stainz',
    'Grafendorf',
    'Zobing',
    'Moosdorf',
    'Bruck am Ziller',
    'Regelsbrunn',
    'Sooss',
    'Wolfpassing',
    'Hautzendorf',
    'Hart',
    'Lambrechten',
    'Volders',
    'Guttaring',
    'Griffen',
    'Foederlach',
    'Koetschach',
    'Haag',
    'Japons',
    'Gofis',
    'Tal',
    'Gollersdorf',
    'Niederkreuzstetten',
    'Toesenssteinach',
    'Schlitters',
    'Meiningen',
    'Bludesch',
    'Thuringen',
    'Sulzberg',
    'Inzersdorf im Kremstal',
    'Semriach',
    'Gaming',
    'Pachfurth',
    'Drassburg',
    'Fischamend Dorf',
    'Kremsbruecke',
    'Niederwaldkirchen',
    'Loretto',
    'Trasdorf',
    'Horersdorf',
    'Friedburg',
    'Grafendorf bei Hartberg',
    'Steinberg bei Ligist',
    'Deutsch Goritz',
    'Kirchbach in Steiermark',
    'Kainach bei Voitsberg',
    'Jenbach',
    'Virgen',
    'Stummerberg',
    'Gross-Siegharts',
    'Ardagger Markt',
    'Gars am Kamp',
    'Haslau an der Donau',
    'Siebenhirten',
    'Gobelsburg',
    'Oberhofen im Inntal',
    'Ziersdorf',
    'Glaubendorf',
    'Baldramsdorf',
    'Reith',
    'Feuersbrunn',
    'Oberschutzen',
    'Goesselsdorf',
    'Jagerberg',
    'Sinnersdorf',
    'Mauterndorf',
    'Eisenerz',
    'Weng bei Admont',
    'Haus',
    'Veitsch',
    'Sankt Marienkirchen am Hausruck',
    'Lohnsburg',
    'Gasthof',
    'Heiligenblut am GroÃŸglockner',
    'Hochsoelden',
    'Hatzendorf',
    'Silz',
    'Gallzein',
    'Gross-Schweinbarth',
    'Klam',
    'Baumgartenberg',
    'Dobldorf',
    'Oberhausen',
    'Obersdorf',
    'Alberschwende',
  ],
  tld: ['.at'],
  cca2: 'AT',
  ccn3: '040',
  cca3: 'AUT',
  cioc: 'AUT',
  currency: ['EUR'],
  callingCode: ['43'],
  capital: 'Vienna',
  altSpellings: ['AT', 'Osterreich', 'Oesterreich'],
  region: 'Europe',
  subregion: 'Western Europe',
  languages: { bar: 'Austro-Bavarian German' },
  translations: {
    cym: { official: 'Republic of Austria', common: 'Awstria' },
    deu: { official: 'Republik Österreich', common: 'Österreich' },
    fra: { official: "République d'Autriche", common: 'Autriche' },
    hrv: { official: 'Republika Austrija', common: 'Austrija' },
    ita: { official: "Repubblica d'Austria", common: 'Austria' },
    jpn: { official: 'オーストリア共和国', common: 'オーストリア' },
    nld: { official: 'Republiek Oostenrijk', common: 'Oostenrijk' },
    por: { official: 'República da Áustria', common: 'Áustria' },
    rus: { official: 'Австрийская Республика', common: 'Австрия' },
    spa: { official: 'República de Austria', common: 'Austria' },
    fin: { official: 'Itävallan tasavalta', common: 'Itävalta' },
  },
  latlng: [47.33333333, 13.33333333],
  demonym: 'Austrian',
  landlocked: true,
  borders: ['CZE', 'DEU', 'HUN', 'ITA', 'LIE', 'SVK', 'SVN', 'CHE'],
  area: 83871,
  provinces: [
    'Burgenland',
    'Kaernten',
    'Niederoesterreich',
    'Oberoesterreich',
    'Salzburg',
    'Steiermark',
    'Tirol',
    'Vorarlberg',
    'Wien',
  ],
  nativeName: 'Österreich',
  timezones: ['UTC+01:00'],
} as const;
