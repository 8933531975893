import { maxBy, sumBy } from 'lodash';

import { Assignment, Module, Report, Scene } from '@models';
import { isNullish } from '@tools/type-guards';
import { dates } from '@utils/dates';

/**
 * ...
 */
export interface ScoredAssignment extends Assignment {
  report: ScoredAssignment.Report;
}

export namespace ScoredAssignment {
  /**
   * ...
   */
  export interface Report {
    /** ... */
    completed: boolean;
    /** ... */
    completedDate: string;
    /** ... */
    score: number;
  }
}

/**
 * Check assignment completion with raw reports and assignments.
 *
 * @param reports ...
 * @param assignments ...
 * @return ...
 */
export function checkReportsWithAssignments(
  reports: Report[],
  assignments: Assignment[],
) {
  let dueDate = new Date();
  let completed = false;
  let completedDate = new Date();
  let moduleCompletedDate = new Date();
  let highestScore = 0;

  return assignments.map((assignment) => {
    completed = false;
    completedDate = new Date();
    moduleCompletedDate = new Date();
    highestScore = 0;
    dueDate = new Date(assignment.dueDate);

    const moduleId = assignment.module.id;

    const assignmentMinimumScore = assignment.minimumScore;
    const assignmentStartDate = assignment.startDate;
    const assignmentEndDate = assignment.endDate;
    const assignmentTotalTime = assignment.totalTime;

    let allAttempts: Report[] = [];
    let singleReport: Report | null = null;
    let totalTime = 0;

    if (!moduleId) {
      throw new Error(
        `[checkReportsWithAssignments] assignment with ID "${assignment.id}" has an invalid "moduleId".`,
      );
    }

    const sceneId = assignment.scene.id;

    if (!sceneId) {
      throw new Error(
        `[checkReportsWithAssignments] assignment with ID "${assignment.id}" has an invalid "sceneId".`,
      );
    }

    const uniqueId = `${moduleId}-${sceneId}`;

    const reportsRelatedToAssignment = reports.filter((r) => {
      // ...
      const scenarioMatch = uniqueId === scenarioUid(r.module?.id, sceneId);

      if (!scenarioMatch) return false;

      if (isNullish(assignmentMinimumScore)) return true;

      return ensureInt(r.score) >= ensureInt(assignmentMinimumScore);
    });

    const reportsOnTime = reportsRelatedToAssignment.filter(({ createdAt }) => {
      if (!dates.isSameOrBefore(createdAt, dueDate)) return false;

      if (
        assignmentStartDate &&
        assignmentEndDate &&
        !dates.isBetween(createdAt, assignmentStartDate, assignmentEndDate)
      ) {
        return false;
      }

      return true;
    });

    if (reportsOnTime.length) {
      allAttempts = reportsOnTime;
      totalTime = ensureInt(sumBy(reportsOnTime, 'totalTime'));
    }

    if (reportsOnTime.length) {
      completed = assignmentTotalTime
        ? ensureInt(totalTime) >= ensureInt(assignmentTotalTime)
        : true;

      singleReport = maxBy(reportsOnTime, 'score') ?? null;
    }

    if (singleReport) {
      highestScore = singleReport.score;
      completedDate = new Date(singleReport.createdAt);
    }

    // for (const report of reports) {
    //   moduleCompletedDate = new Date(report.createdAt);

    //   if (
    //     `${report.module.id}-${report.scene.id}` === uniqueId &&
    //     dates.isSameOrBefore(moduleCompletedDate, dueDate) &&
    //     report.score > highestScore
    //   ) {
    //     highestScore = report.score;
    //     completed = true;
    //     completedDate = cloneDeep(moduleCompletedDate);
    //   }
    // }

    const report: ScoredAssignment.Report = {
      completed,
      completedDate: completedDate ? new Date(completedDate) : '',
      score: highestScore,
    };

    return { ...assignment, report } as ScoredAssignment;
  });
}

function scenarioUid(
  moduleId: Nullable<Module['id']>,
  sceneId?: Nullable<Scene['id']>,
) {
  return `${moduleId ?? ''}-${sceneId ?? ''}`;
}

function ensureInt(value: string | number) {
  return parseInt(value.toString());
}
