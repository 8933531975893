export const UKR = {
  name: {
    common: 'Ukraine',
    official: 'Ukraine',
    native: {
      rus: { official: 'Украина', common: 'Украина' },
      ukr: { official: 'Україна', common: 'Україна' },
    },
  },
  timezones: ['UTC+02:00'],
  nativeName: 'Україна',
  provinces: [
    "Avtonomna Respublika Krym (Simferopol')",
    "Cherkas'ka (Cherkasy)",
    "Chernihivs'ka (Chernihiv)",
    "Chernivets'ka (Chernivtsi)",
    "Dnipropetrovs'ka (Dnipropetrovs'k)",
    "Donets'ka (Donets'k)",
    "Ivano-Frankivs'ka (Ivano-Frankivs'k)",
    "Kharkivs'ka (Kharkiv)",
    "Khersons'ka (Kherson)",
    "Khmel'nyts'ka (Khmel'nyts'kyy)",
    "Kirovohrads'ka (Kirovohrad)",
    'Kyyiv',
    "Kyyivs'ka (Kiev)",
    "L'vivs'ka (L'viv)",
    "Luhans'ka (Luhans'k)",
    "Mykolayivs'ka (Mykolayiv)",
    "Odes'ka (Odesa)",
    "Poltavs'ka (Poltava)",
    "Rivnens'ka (Rivne)",
    "Sevastopol'",
    "Sums'ka (Sumy)",
    "Ternopil's'ka (Ternopil')",
    "Vinnyts'ka (Vinnytsya)",
    "Volyns'ka (Luts'k)",
    "Zakarpats'ka (Uzhhorod)",
    "Zaporiz'ka (Zaporizhzhya)",
    "Zhytomyrs'ka (Zhytomyr)",
  ],
  cities: [
    'Kiev',
    'Vyshhorod',
    'Ternopil',
    'Brody',
    'Donetsk',
    'Mariupol',
    'Rodinskoye',
    'Sloviansk',
    'Yenakiyeve',
    'Kherson',
    'Zaporizhia',
    'Dnipropetrovsk',
    "Berdyans'k",
    'Novomoskovsk',
    'Chervonohrad',
    'Zhytomyr',
    'Odesa',
    'Chernihiv',
    'Boryspil',
    'Kharkiv',
    'Luhansk',
    'Cherkasy',
    'Kerch',
    'Mykolayiv',
    'Teplodar',
    "Alchevs'k",
    'Kirovsk',
    'Petrenky',
    'Kovel',
    'Uzhhorod',
    'Vinnytsya',
    'Desna',
    "Krasnoarmiys'k",
    "Syeverodonets'k",
    'Bila Tserkva',
    'Pavlograd',
    'Makiyivka',
    'Krasnodon',
    'Simferopol',
    'Sumy',
    'Oleh',
    "Obolon'",
    'Kuna',
    "Volodars'k-Volyns'kyy",
    'Kalynivka',
    'Lviv',
    "Volodymyr-Volyns'kyy",
    "Sverdlovs'k",
    'Illintsi',
    "Ivano-Frankivs'k",
    'Lutsk',
    'Novi Sanzhary',
    'Svalyava',
    'Tsyurupinsk',
    'Shpola',
    'Chernivtsi',
    'Buchach',
    'Dimitrov',
    'Mala Tokmachka',
    'Poltava',
    'Korosten',
    'Sevastopol',
    'Smila',
    "Khmel'nyts'kyy",
    'Kryvyy Rih',
    'Drohobych',
    'Rivne',
    "Dniprodzerzhyns'k",
    "Pervomays'k",
    "Volochys'k",
    'Slavuta',
    'Pavlohrad',
    "Ternopol'skiy Rayon",
    'Feodosiya',
    'Yalta',
    'Stakhanov',
    'Brovary',
    'Kremenchuk',
    'Aleksandriya',
    'Zaporozhe',
    "Marhanets'",
    'Kalush',
    'Melitopol',
    'Vorovskiy',
    'Kirovohrad',
    "Dolyns'ka",
    'Nizhyn',
    'Ivanov',
    "Krasnoperekops'k",
    'Zolotonosha',
    'Kolomyia',
    'Belaya',
    'Shostka',
    "Storozhynets'",
    "Anan'yiv",
    'Netishyn',
    'Spas',
    "Vinogradar'",
    'Bucha',
    'Lohvynove',
    'Podol',
    'Boyarka',
    'Rayhorodka',
    'Stat',
    'Rivne',
    'Kramatorsk',
    'Turka',
    'Chop',
    'Irpin',
    'Voznesenka',
    'Bolekhiv',
    'Chervona Hirka',
    'Energodar',
    "Kotovs'k",
    'Vasylkiv',
    'Alekseyevo-Druzhkovka',
    'Kalynivka',
    'Soroki',
    'Zolochiv',
    'Tkachenka',
    'Rubizhne',
    'Malyn',
    'Zhashkiv',
    'Zavod',
    "Vasylets'",
    'Teremky',
    'Lugansk',
    'Mega',
    'Shevchenko',
    'Aleksandrovka',
    'Kurylivka',
    'Poplavy',
    'Zdolbuniv',
    'Kyseliv',
    'Komsomolsk',
    'Romny',
    "Artemivs'k",
    'Lutugino',
    'Makariv',
    'Horlivka',
    'Bryanka',
    'Chernev',
    'Zhmerynka',
    'Yasinovataya',
    'Fastiv',
    'Boryspil Raion',
    "Debal'tseve",
    'Ternovka',
    'Lisichansk',
    'Gorlovka',
    'Ugledar',
    'Sudak',
    'Berehove',
    'Kremenets',
    'Rovenki',
    "Armyans'k",
    'Konotop',
    'Kuznetsovsk',
    'Shevchenka',
    "Bilohirs'k",
    'Alushta',
    'Kozyatyn',
    "Tul'chyn",
    'Alupka',
    'Svessa',
    'Volnovakha',
    'Bila Hora',
    'Zhdanovka',
    'Ukrayinka',
    "Kirovs'ke",
    'Novyy Buh',
    'Mykolayiv',
    'Berdychiv',
    'Maksim',
    'Shepetivka',
    'Dzhankoy',
    "Bobrynets'",
    'Kakhovka',
    'Sokoliv',
    'Korostyshiv',
    'Lanovka',
    'Zabolotiv',
    'Horodenka',
    "Hostomel'",
    'Voronovo',
    "Dykan'ka",
    'Kvasyliv',
    'Zlazne',
    'Obukhov',
    'Trypillia',
    'Kaniv',
    'Saky',
    'Krasnohrad',
    'Sorotskoye',
    "Novovolyns'k",
    'Mylove',
    'Knyazhychi',
    'Nosivka',
    'Bortnychi',
    'Zastava',
    "Uman'",
    'Nadezhda',
    'Stanislav',
    "Khmil'nyk",
    'Ivankov',
    'Darakhov',
    'Semen',
    'Babin',
    'Romaniv',
    'German',
    'Slynkivshchyna',
    'Toporov',
    'Bukova',
    'Davydov',
    'Tsvetkov',
    'Volkov',
    'Pobeda',
    "Stepan'",
    'Frunze',
    'Kurakhovo',
    "Mostys'ka",
    'Lityn',
    'Hoshiv',
    "Sokil'nyky",
    'Grebenyuk',
    'Boryslav',
    'Lubny',
    'Borodyanka',
    'Bozhenka',
    'Zmiyiv',
    'Merefa',
    'Bushtyno',
    'Barashivka',
    'Stryi',
    'Soroka',
    'Dmitrovichi',
    'Osypenko',
    'Osnova',
    'Izmail',
    'Hlukhiv',
    'Serhiyi',
    'Sencha',
    'Baryshivka',
    "Sverdlovs'ke",
    'Markova',
    'Dubno',
    'Luganskoye',
    'Kreminna',
    'Mukacheve',
    "Khotin'",
    'Haysyn',
    'Sokol',
    'Kyyiv',
    'Tyachiv',
    'Druzhkovka',
    'Khust',
    'Pyrohiv',
    'Torez',
    'Petranka',
    'Selidovo',
    'Lenina',
    'Goncharov',
    'Monastyryshche',
    'Fontanka',
    'Tokmak',
    "Uspens'ke",
    'Letychiv',
    'Khyzha',
    "Nikopol'",
    'Oreanda',
    'Vyshneve',
  ],
  tld: ['.ua', '.укр'],
  cca2: 'UA',
  ccn3: '804',
  cca3: 'UKR',
  cioc: 'UKR',
  currency: ['UAH'],
  callingCode: ['380'],
  capital: 'Kiev',
  altSpellings: ['UA', 'Ukrayina'],
  region: 'Europe',
  subregion: 'Eastern Europe',
  languages: { rus: 'Russian', ukr: 'Ukrainian' },
  translations: {
    deu: { official: 'Ukraine', common: 'Ukraine' },
    fra: { official: 'Ukraine', common: 'Ukraine' },
    hrv: { official: 'Ukrajina', common: 'Ukrajina' },
    ita: { official: 'Ucraina', common: 'Ucraina' },
    jpn: { official: 'ウクライナ', common: 'ウクライナ' },
    nld: { official: 'Oekraïne', common: 'Oekraïne' },
    por: { official: 'Ucrânia', common: 'Ucrânia' },
    rus: { official: 'Украина', common: 'Украина' },
    spa: { official: 'Ucrania', common: 'Ucrania' },
    fin: { official: 'Ukraina', common: 'Ukraina' },
  },
  latlng: [49, 32],
  demonym: 'Ukrainian',
  landlocked: false,
  borders: ['BLR', 'HUN', 'MDA', 'POL', 'ROU', 'RUS', 'SVK'],
  area: 603500,
} as const;
