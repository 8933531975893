export const LUX = {
  name: {
    common: 'Luxembourg',
    official: 'Grand Duchy of Luxembourg',
    native: {
      deu: { official: 'Großherzogtum Luxemburg', common: 'Luxemburg' },
      fra: { official: 'Grand-Duché de Luxembourg', common: 'Luxembourg' },
      ltz: { official: 'Groussherzogtum Lëtzebuerg', common: 'Lëtzebuerg' },
    },
  },
  cities: [
    'Steinsel',
    'Bascharage',
    'Itzig',
    'Dudelange',
    'Helmsange',
    'Soleuvre',
    'Belvaux',
    'Bettembourg',
    'Luxembourg',
    'Hunsdorf',
    'Junglinster',
    'Bertrange',
    'Sandweiler',
    'Beggen',
    'Heffingen',
    'Strassen',
    'Contern',
    'Mersch',
    'Bereldange',
    'Alzingen',
    'Holzem',
    'Leudelange',
    'Ernster',
    'Luxembourg-Kirchberg',
    'Tetange',
    'Esch-sur-Alzette',
    'Lamadelaine',
    'Rumelange',
    'Hesperange',
    'Medernach',
    'Goetzingen',
    'Nospelt',
    'Gosseldange',
    'Schifflange',
    'Grosbous',
    'Merl',
    'Gonderange',
    'Ettelbruck',
    'Huncherange',
    'Bridel',
    'Rodange',
    'Hautcharage',
    'Kayl',
    'Berchem',
    'Bofferdange',
    'Mondercange',
    'Dippach',
    'Burmerange',
    'Oberanven',
    'PÃ©tange',
    'Grevenmacher',
    'Differdange',
    'Grevenknapp',
    'Schieren',
    'Pontpierre',
    'Sanem',
    'Crauthem',
    'Beaufort',
    'Syren',
    'Heisdorf-sur-Alzette',
    'Helmdange',
    'Lintgen',
    'Hersberg',
    'Moutfort',
    'Uebersyren',
    'Olm',
    'Tuntange',
    'Bissen',
    'Koetschette',
    'Niedercorn',
    'Mertert',
    'Erpeldange-lez-Bous',
    'Ehnen',
    'Canach',
    'Wasserbillig',
    'Wormeldange',
    'Remerschen',
    'Bigonville',
    'Gostingen',
    'Bourglinster',
    'Bergem',
    'Hobscheid',
    'Schouweiler',
    'Capellen',
    'Ahn',
    'Kleinbettingen',
    'Bonnevoie',
    'Foetz',
    'Walferdange',
    'Steinfort',
    'Colmar',
    'Limpertsberg',
    'Larochette',
    'Hollerich',
    'Hagen',
    'Kehlen',
    'Mamer',
    'Mondorf-les-Bains',
    'Schoos',
    'Beidweiler',
    'Oetrange',
    'Lorentzweiler',
    'Wolpert',
    'Dalheim',
    'Bivange',
    'Godbrange',
    'Roeser',
    'Cap',
    'Roodt-sur-Syre',
    'Frisange',
    'Noerdange',
    'Beckerich',
    'Echternach',
    'Esch-sur-Sure',
    'Betzdorf',
    'Schuttrange',
    'Remich',
  ],
  tld: ['.lu'],
  cca2: 'LU',
  ccn3: '442',
  cca3: 'LUX',
  cioc: 'LUX',
  currency: ['EUR'],
  callingCode: ['352'],
  capital: 'Luxembourg',
  altSpellings: [
    'LU',
    'Grand Duchy of Luxembourg',
    'Grand-Duché de Luxembourg',
    'Großherzogtum Luxemburg',
    'Groussherzogtum Lëtzebuerg',
  ],
  region: 'Europe',
  subregion: 'Western Europe',
  languages: { deu: 'German', fra: 'French', ltz: 'Luxembourgish' },
  translations: {
    deu: { official: 'Großherzogtum Luxemburg,', common: 'Luxemburg' },
    fra: { official: 'Grand-Duché de Luxembourg', common: 'Luxembourg' },
    hrv: { official: 'Veliko Vojvodstvo Luksemburg', common: 'Luksemburg' },
    ita: { official: 'Granducato di Lussemburgo', common: 'Lussemburgo' },
    jpn: { official: 'ルクセンブルク大公国', common: 'ルクセンブルク' },
    nld: { official: 'Groothertogdom Luxemburg', common: 'Luxemburg' },
    por: { official: 'Grão-Ducado do Luxemburgo', common: 'Luxemburgo' },
    rus: { official: 'Великое Герцогство Люксембург', common: 'Люксембург' },
    spa: { official: 'Gran Ducado de Luxemburgo', common: 'Luxemburgo' },
    fin: { official: 'Luxemburgin suurherttuakunta', common: 'Luxemburg' },
  },
  latlng: [49.75, 6.16666666],
  demonym: 'Luxembourger',
  landlocked: true,
  borders: ['BEL', 'FRA', 'DEU'],
  area: 2586,
  provinces: ['Diekirch', 'Grevenmacher', 'Luxembourg'],
  nativeName: 'Luxembourg',
  timezones: ['UTC+01:00'],
} as const;
