export const TKM = {
  name: {
    common: 'Turkmenistan',
    official: 'Turkmenistan',
    native: {
      rus: { official: 'Туркменистан', common: 'Туркмения' },
      tuk: { official: 'Türkmenistan', common: 'Türkmenistan' },
    },
  },
  nativeName: 'Türkmenistan',
  provinces: [
    'Ahal Welayaty',
    'Balkan Welayaty',
    'Dashhowuz Welayaty',
    'Lebap Welayaty',
    'Mary Welayaty',
  ],
  timezones: ['UTC+05:00'],
  cities: ['Darganata', 'Ashgabat'],
  tld: ['.tm'],
  cca2: 'TM',
  ccn3: '795',
  cca3: 'TKM',
  cioc: 'TKM',
  currency: ['TMT'],
  callingCode: ['993'],
  capital: 'Ashgabat',
  altSpellings: ['TM'],
  region: 'Asia',
  subregion: 'Central Asia',
  languages: { rus: 'Russian', tuk: 'Turkmen' },
  translations: {
    deu: { official: 'Turkmenistan', common: 'Turkmenistan' },
    fra: { official: 'Turkménistan', common: 'Turkménistan' },
    hrv: { official: 'Turkmenistan', common: 'Turkmenistan' },
    ita: { official: 'Turkmenistan', common: 'Turkmenistan' },
    jpn: { official: 'トルクメニスタン', common: 'トルクメニスタン' },
    nld: { official: 'Turkmenistan', common: 'Turkmenistan' },
    por: { official: 'Turcomenistão', common: 'Turquemenistão' },
    rus: { official: 'Туркменистан', common: 'Туркмения' },
    spa: { official: 'Turkmenistán', common: 'Turkmenistán' },
    fin: { official: 'Turkmenistan', common: 'Turkmenistan' },
  },
  latlng: [40, 60],
  demonym: 'Turkmen',
  landlocked: true,
  borders: ['AFG', 'IRN', 'KAZ', 'UZB'],
  area: 488100,
} as const;
