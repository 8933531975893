import { Module, Action } from '@vuex/decorators';
import { TablePanel } from 'table-panel';

import { RoleId } from '@auth';
import { Product } from '@models';
import { modals } from '@modals';
import { alert } from '@services/alert';
import { store } from '@store';
import * as currencies from '@utils/currencies';

import TableCellProductSaleNotice from '@components/TableCells/TableCellProductSaleNotice.vue';

import { faEdit } from '@icons/solid/faEdit';
import { faArrowUp } from '@icons/solid/faArrowUp';
import { faArrowDown } from '@icons/solid/faArrowDown';

const columns: TablePanel.Column<Product>[] = [
  {
    key: 'name',
    label: 'Name',
    value: 'name',
  },
  {
    key: 'id',
    label: 'ID',
    value: 'id',
  },
  {
    key: 'type',
    label: 'Type',
    value: ({ type }) => {
      if (type === 'SEAT') return 'Seat';
      if (type === 'LICENSE') return 'License';
      if (type === 'SITE_LICENSE') return 'Site License';
      if (type === 'PHYSICAL') return 'Physical';
      if (type === 'CERTIFICATION') return 'Certification';
      if (type === 'BUNDLE') return 'Bundle';
      if (type === 'DLC') return 'DLC';

      return '--'; // Invalid type
    },
  },
  {
    key: 'prices',
    label: 'Price',
    value: (product) => currencies.formatProductPrice(product),
  },

  {
    key: 'salePrice',
    label: 'Sale Price',
    // type: 'currency',
    component: TableCellProductSaleNotice, // TEMPORARY
    value: () => null,
  },
  {
    key: 'onSale',
    label: 'On Sale',
    // type: 'boolean',
    // value: 'onSale',
    component: TableCellProductSaleNotice, // TEMPORARY
    value: () => null,
  },
  {
    key: 'active',
    label: 'Active',
    type: 'boolean',
    value: 'active',
  },
];

const operations: TablePanel.Row.Operation<Product>[] = [
  {
    label: 'Edit',
    icon: faEdit,
    fn: ({ id: productId }) => {
      void modals.product.edit({ productId });
    },
  },
  {
    label: 'Set to Active',
    icon: faArrowUp,
    fn: async ({ id }) => {
      try {
        await store.dispatch('products/update', { id, active: true });
      } catch (err) {
        return alert.error(err);
      }

      alert.success('Product activated.');
    },
    hidden: ({ active }) => active,
  },
  {
    label: 'Set to Inactive',
    icon: faArrowDown,
    fn: async ({ id: productId }) => {
      try {
        await store.dispatch('products/update', {
          id: productId,
          active: false,
        });
      } catch (err) {
        return alert.error(err);
      }

      alert.success('Product deactivated.');
    },
    hidden: ({ active }) => !active,
  },
];

const specialFilters: TablePanel.SpecialFilter<Product>[] = [
  {
    type: 'dropdown',
    label: 'Type',
    options: [
      { value: 'BUNDLE', text: 'Bundle' },
      { value: 'LICENSE', text: 'License' },
      { value: 'SITE_LICENSE', text: 'Site License' },
      { value: 'CERTIFICATION', text: 'Certification' },
      { value: 'PHYSICAL', text: 'Physical' },
      { value: 'SEAT', text: 'Seat' },
      { value: 'DLC', text: 'DLC' },
    ],
    handler: (item, choice) => {
      return item?.type && choice ? item.type === choice : false;
    },
  },
];

@Module({ namespaced: true })
export class ProductsTablePanel
  extends TablePanel<Product>
  implements TablePanel.Props<Product>
{
  readonly module = 'products';
  readonly loadAction = 'list';
  readonly pk = 'id';
  readonly label = 'PRODUCTS';
  readonly columns = columns;
  readonly operations = operations;
  readonly createAction = 'createProduct';
  readonly deleteAction = 'deleteProduct';
  readonly specialFilters = specialFilters;

  get enabled() {
    return this.isActiveRole(9);
  }

  readonly progressive = false;

  get tableMenu() {
    const context = this.context;
    const roleId = context.rootState.me.selectedRole?.roleId;

    if (roleId !== RoleId.LasAdmin) return [];

    const items: TablePanel.MenuItem[] = [];

    items.push({
      key: 'mass_add_drone',
      label: 'Add Drone to Product(s)',
      click: async (context) => {
        // These are the products they selected
        if (!context?.selection.length) {
          return alert.error('Must select at least one product.');
        }

        const products = context.selection.map((p) => p as Product);

        // select drone modal
        const drones = await store.dispatch('drones/list');

        const selectedDroneId = await modals.util.selectItemFromList({
          title: 'SELECT A DRONE',
          options: drones.map((d) => ({ value: d.id, label: d.name })),
        });

        if (!selectedDroneId || typeof selectedDroneId !== 'string') {
          return alert.error('Must select a drone to add.');
        }

        const droneName = drones.find((d) => d.id === selectedDroneId)?.name;

        // Process each post product API call and check if drone is already on
        // the list and skip
        for (const product of products) {
          alert.info(`Processing ${product.name}`);

          // Product already has drone assigned
          if (product.drones?.find((d) => d.id === selectedDroneId)) {
            alert.warning(
              `${product.name} already has the ${droneName} assigned to it.`,
            );

            continue;
          }

          try {
            const droneIds = (product.drones ?? []).map(({ id }) => id);

            droneIds.push(selectedDroneId);

            const options = {
              productId: product.id,
              drones: droneIds,
            };
            await store.dispatch('products/update', options);

            alert.success(`${droneName} added to ${product.name}`);
          } catch (err) {
            alert.error(err);
          }
        }

        alert.info('Drones added to products successfully.');
      },
    });

    return items;
  }

  @Action
  async createProduct() {
    await modals.product.create();
  }

  @Action
  async deleteProduct({ items }: ProductsTablePanel.DeleteItemOptions) {
    await modals.confirm.deleteItems({ itemType: 'products', items });
  }
}

export namespace ProductsTablePanel {
  export interface DeleteItemOptions {
    items: Product[];
  }
}
