/**
 * Copy a provided value. For objects, the result will be unique.
 *
 * @param value The value to copy.
 * @param safe If provided, an error won't be thrown if one is encounterd while
 * copying the value.
 * @return The copied value, or `null` if the copy was safe and an error
 * occured.
 */
export function copy<T>(value: T): T;
export function copy<T>(value: T, safe: false): T;
export function copy<T>(value: T, safe: true): T | null;
export function copy<T>(value: T, safe = false) {
  let result: T | null = null;
  let error: Error | null = null;

  try {
    result = JSON.parse(JSON.stringify(value)) as T;
  } catch (err) {
    error = err;
  }

  if (error && !safe) {
    throw error;
  }

  return result;
}
