export const OMN = {
  name: {
    common: 'Oman',
    official: 'Sultanate of Oman',
    native: { ara: { official: 'سلطنة عمان', common: 'عمان' } },
  },
  cities: [
    'Muscat',
    'Salalah',
    'Ruwi',
    'Al Sohar',
    'Nizwa',
    'Samad',
    'Saham',
    '',
  ],
  tld: ['.om'],
  cca2: 'OM',
  ccn3: '512',
  cca3: 'OMN',
  cioc: 'OMA',
  currency: ['OMR'],
  callingCode: ['968'],
  capital: 'Muscat',
  altSpellings: ['OM', 'Sultanate of Oman', 'Salṭanat ʻUmān'],
  region: 'Asia',
  subregion: 'Western Asia',
  languages: { ara: 'Arabic' },
  translations: {
    deu: { official: 'Sultanat Oman', common: 'Oman' },
    fra: { official: "Sultanat d'Oman", common: 'Oman' },
    hrv: { official: 'Sultanat Oman', common: 'Oman' },
    ita: { official: "Sultanato dell'Oman", common: 'oman' },
    jpn: { official: 'オマーン·スルタン国', common: 'オマーン' },
    nld: { official: 'Sultanaat van Oman', common: 'Oman' },
    por: { official: 'Sultanato de Omã', common: 'Omã' },
    rus: { official: 'Султанат Оман', common: 'Оман' },
    spa: { official: 'Sultanato de Omán', common: 'Omán' },
    fin: { official: 'Omanin sulttaanikunta', common: 'Oman' },
  },
  latlng: [21, 57],
  demonym: 'Omani',
  landlocked: false,
  borders: ['SAU', 'ARE', 'YEM'],
  area: 309500,
  provinces: [
    'Ad Dakhiliyah',
    'Al Batinah',
    'Al Wusta',
    'Ash Sharqiyah',
    'Az Zahirah',
    'Masqat',
    'Musandam',
    'Zufar',
  ],
  nativeName: 'عمان',
  timezones: ['UTC+04:00'],
} as const;
