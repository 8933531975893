var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "form-field-select" },
    [
      _c(
        "FormSelect",
        _vm._g(
          _vm._b(
            {
              scopedSlots: _vm._u(
                [
                  _vm._l(_vm.$scopedSlots, function (_, slot) {
                    return {
                      key: slot,
                      fn: function (scope) {
                        return [_vm._t(slot, null, null, scope)]
                      },
                    }
                  }),
                ],
                null,
                true
              ),
              model: {
                value: _vm.field.$model,
                callback: function ($$v) {
                  _vm.$set(_vm.field, "$model", $$v)
                },
                expression: "field.$model",
              },
            },
            "FormSelect",
            {
              ..._vm.$attrs,
              state: _vm.state,
              label: _vm.label,
              options: _vm.options,
            },
            false
          ),
          _vm.$listeners
        )
      ),
      _vm._v(" "),
      _vm.showErrorMessage
        ? _c("b-form-invalid-feedback", { attrs: { state: false } }, [
            _vm._v("\n    " + _vm._s(_vm.errorMessage) + "\n  "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }