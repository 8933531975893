export const ECU = {
  name: {
    common: 'Ecuador',
    official: 'Republic of Ecuador',
    native: { spa: { official: 'República del Ecuador', common: 'Ecuador' } },
  },
  cities: [
    'Quito',
    'Pichincha',
    'Guayas',
    'Guayaquil',
    'Ibarra',
    'Riobamba',
    'Ambato',
    'Otavalo',
    'Guamote',
    'Tababela',
    'Guayllabamba',
    'Cayambe',
    'Tena',
    'Tarapoa',
    'Latacunga',
    'Santo Domingo de los Colorados',
    'Cuenca',
    'Esmeraldas',
    'Paccha',
    'Zaruma',
    'El Carmen',
    'La Libertad',
    'Huaquillas',
    'Olmedo',
    'DurÃ¡n',
    'Florida',
    'Machala',
    'Loja',
    'Manta',
    'Nabon',
    'Pelileo',
    'Gualaceo',
    'Macas',
    'Samborondon',
    'Puyo',
    'Zumba',
    'Portoviejo',
    'General Leonidas Plaza Gutierrez',
    'Calderon',
    'Sucre',
    'Jipijapa',
    'Macara',
    'Santa Elena',
    'La Esperanza',
    'Machachi',
    'Atacames',
    'La Troncal',
    'San Rafael',
    'Guabo',
    'Santa Cruz',
    'Milagro',
    'TulcÃ¡n',
    'Atuntaqui',
    'Daule',
    'Pasaje',
    'Playas',
    'San Miguel',
    'Puerto Francisco de Orellana',
    'Loreto',
    'Montecristi',
    'Hacienda Santa Catalina',
    'Zamora',
    'Vinces',
    'Quevedo',
    'Hacienda Duran',
    'Bahia de Caraquez',
    'Salinas',
    'Hacienda Ibarra',
    'Hacienda Bolivia',
    'El Naranjal',
    'Banos',
  ],
  tld: ['.ec'],
  cca2: 'EC',
  ccn3: '218',
  cca3: 'ECU',
  cioc: 'ECU',
  currency: ['USD'],
  callingCode: ['593'],
  capital: 'Quito',
  altSpellings: ['EC', 'Republic of Ecuador', 'República del Ecuador'],
  region: 'Americas',
  subregion: 'South America',
  languages: { spa: 'Spanish' },
  translations: {
    cym: { official: 'Republic of Ecuador', common: 'Ecwador' },
    deu: { official: 'Republik Ecuador', common: 'Ecuador' },
    fra: { official: "République de l'Équateur", common: 'Équateur' },
    hrv: { official: 'Republika Ekvador', common: 'Ekvador' },
    ita: { official: "Repubblica dell'Ecuador", common: 'Ecuador' },
    jpn: { official: 'エクアドル共和国', common: 'エクアドル' },
    nld: { official: 'Republiek Ecuador', common: 'Ecuador' },
    por: { official: 'República do Equador', common: 'Equador' },
    rus: { official: 'Республика Эквадор', common: 'Эквадор' },
    spa: { official: 'República del Ecuador', common: 'Ecuador' },
    fin: { official: 'Ecuadorin tasavalta', common: 'Ecuador' },
  },
  latlng: [-2, -77.5],
  demonym: 'Ecuadorean',
  landlocked: false,
  borders: ['COL', 'PER'],
  area: 276841,
  provinces: [
    'Azuay',
    'Bolivar',
    'Canar',
    'Carchi',
    'Chimborazo',
    'Cotopaxi',
    'El Oro',
    'Esmeraldas',
    'Galapagos',
    'Guayas',
    'Imbabura',
    'Loja',
    'Los Rios',
    'Manabi',
    'Morona-Santiago',
    'Napo',
    'Orellana',
    'Pastaza',
    'Pichincha',
    'Sucumbios',
    'Tungurahua',
    'Zamora-Chinchipe',
  ],
  nativeName: 'Ecuador',
  timezones: ['UTC−06:00', 'UTC−05:00'],
} as const;
