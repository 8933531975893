export const ASM = {
  name: {
    common: 'American Samoa',
    official: 'American Samoa',
    native: {
      eng: { official: 'American Samoa', common: 'American Samoa' },
      smo: { official: 'Sāmoa Amelika', common: 'Sāmoa Amelika' },
    },
  },
  cities: ['American Samoa', 'Pago Pago'],
  tld: ['.as'],
  cca2: 'AS',
  ccn3: '016',
  cca3: 'ASM',
  cioc: 'ASA',
  currency: ['USD'],
  callingCode: ['1684'],
  capital: 'Pago Pago',
  altSpellings: ['AS', 'Amerika Sāmoa', 'Amelika Sāmoa', 'Sāmoa Amelika'],
  region: 'Oceania',
  subregion: 'Polynesia',
  languages: { eng: 'English', smo: 'Samoan' },
  translations: {
    deu: { official: 'Amerikanisch-Samoa', common: 'Amerikanisch-Samoa' },
    fra: { official: 'Samoa américaines', common: 'Samoa américaines' },
    hrv: { official: 'američka Samoa', common: 'Američka Samoa' },
    ita: { official: 'Samoa americane', common: 'Samoa Americane' },
    jpn: { official: '米サモア', common: 'アメリカ領サモア' },
    nld: { official: 'Amerikaans Samoa', common: 'Amerikaans Samoa' },
    por: { official: 'Samoa americana', common: 'Samoa Americana' },
    rus: { official: 'американское Самоа', common: 'Американское Самоа' },
    spa: { official: 'Samoa Americana', common: 'Samoa Americana' },
    fin: { official: 'Amerikan Samoa', common: 'Amerikan Samoa' },
  },
  latlng: [-14.33333333, -170],
  demonym: 'American Samoan',
  landlocked: false,
  borders: [],
  area: 199,
} as const;
