var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "input-toggle",
      class: [`size-${_vm.size}`, { checked: _vm.value }],
    },
    [
      _c("div", [
        _c("input", {
          attrs: { type: "checkbox" },
          domProps: { value: _vm.value },
        }),
        _vm._v(" "),
        _c("div", {
          staticClass: "toggle-slider",
          on: {
            click: function ($event) {
              return _vm.$emit("input", !_vm.value)
            },
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }