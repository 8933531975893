import { Vue } from '@vue';
import { uibModals, UibModals } from 'uib-modals';

Vue.use(UibModals);

export { uibModals };

export { default as ModalHeader } from 'uib-modals/ModalHeader.vue';
export { default as ModalBody } from 'uib-modals/ModalBody.vue';
export { default as ModalFooter } from 'uib-modals/ModalFooter.vue';

export { useModal } from 'uib-modals/modal-state';
