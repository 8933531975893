import { Module } from '@vuex/decorators';
import { TablePanel } from 'table-panel';

import { dateFilter } from '@filters/date';
import { Certification } from '@models';
import { modals } from '@modals';
import { router } from '@router';
import * as certs from '@services/certifications';
import { computeDisplayName } from '@utils/user';

import { faEye } from '@icons/solid/faEye';
import { faCopy } from '@icons/solid/faCopy';
import { faCode } from '@icons/solid/faCode';
import { faArrowUpRightFromSquare } from '@icons/solid/faArrowUpRightFromSquare';

/** ... */
const columns: TablePanel.Column<Certification>[] = [
  {
    key: 'productName',
    label: 'Product Name',
    value: ({ product }) => product.name,
  },
  {
    key: 'status',
    label: 'Status',
    value: 'status',
    component: 'TableCellCertificationStatus',
  },
  {
    key: 'lastAction',
    label: 'Last Action',
    component: 'TableCellCertificationLastAction',
    value: (item) => {
      return item.approvedAt ?? item.reattemptPromptAt ?? item.deniedAt ?? null;
    },
  },
  {
    key: 'user',
    label: 'User',
    value: (item) => computeDisplayName(item.user),
  },
  {
    key: 'reviewer',
    label: 'Reviewer',
    value: ({ reviewer }) => (reviewer ? computeDisplayName(reviewer) : null),
  },
  {
    key: 'expiresAt',
    label: 'Expires',
    value: 'expiresAt',
    type: 'date',
  },
];

const operations: TablePanel.Row.Operation<Certification>[] = [
  {
    label: 'View',
    icon: faEye,
    fn: ({ id }) => {
      void modals.certifications.view({ certificationId: id });
    },
  },
  {
    label: 'Submit',
    icon: faArrowUpRightFromSquare,
    fn: ({ id }) => {
      void modals.certifications.submitAttempt({ certificationId: id });
    },
    hidden: ({ status }) => {
      return (
        status !== 'NOT_STARTED' &&
        status !== 'AWAITING_ATTEMPT' &&
        status !== 'AWAITING_REATTEMPT'
      );

      // return status !== 'AWAITING_ATTEMPT' && status !== 'AWAITING_REATTEMPT';
    },
  },
  {
    label: 'View Review Notes',
    icon: faEye,
    fn: ({ deniedAt, notes }) => {
      const text = `Denied At: ${
        deniedAt
          ? dateFilter(deniedAt, 'MM/dd/yyyy hh:mm:ss a').toString()
          : 'N/A'
      }\n\nNotes: ${notes ? notes : 'Notes Not Found'}`;

      const options = {
        title: 'REVIEW NOTES',
        text,
      };

      void modals.util.generalInfo(options);
    },
    hidden: ({ status }) => {
      return status !== 'DENIED';
    },
  },
  // ...
  {
    label: 'Verify',
    icon: faArrowUpRightFromSquare,
    fn: ({ id }) => {
      // const link = certs.createVerificationLink(id);

      // void router.push({
      //   name: 'verification',
      //   params: { certificationId: id },
      // });

      const routeData = router.resolve({
        name: 'verification',
        params: { certificationId: id },
      });

      window.open(routeData.href, '_blank');
    },
    hidden: ({ status }) => status !== 'CURRENT',
  },
  {
    label: 'Copy',
    icon: faCopy,
    fn: ({ id }) => {
      void certs.copyCertificationLink(id);
    },
    hidden: ({ status }) => status !== 'CURRENT',
  },
  {
    label: 'Embed',
    icon: faCode,
    fn: ({ id, badge }) => {
      void certs.copyEmbedCode(id, badge);
    },
    hidden: ({ status }) => status !== 'CURRENT',
  },
];

@Module({ namespaced: true })
export class MyCertificationsTablePanel
  extends TablePanel<Certification>
  implements TablePanel.Props<Certification>
{
  readonly module = 'certifications';
  readonly loadAction = 'listMine';
  readonly pk = 'id';
  readonly label = 'MY CERTIFICATIONS';
  readonly columns = columns;
  readonly operations = operations;

  /* eslint-disable @typescript-eslint/class-literal-property-style */

  /** ... */
  get enabled() {
    return true;
  }

  /* eslint-enable @typescript-eslint/class-literal-property-style */

  get items() {
    return this.storeModule.items.filter(
      (item) => item.user.id === this.activeUser.id,
    );
  }
}
