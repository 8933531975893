export const GTM = {
  name: {
    common: 'Guatemala',
    official: 'Republic of Guatemala',
    native: {
      spa: { official: 'República de Guatemala', common: 'Guatemala' },
    },
  },
  cities: [
    'Huehuetenango',
    'Guatemala City',
    'Vista Hermosa',
    'El Salvador',
    'Catarina',
    'AmatitlÃ¡n',
    'Mixco',
    'Melchor de Mencos',
    'Chimaltenango',
    'San Pedro Sacatepequez',
    'Antigua Guatemala',
    'Santa Catarina Pinula',
    'Quetzaltenango',
    'Quesada',
    'Santa Cruz Naranjo',
    'Villa Nueva',
    'Zacapa',
    'Ciudad Vieja',
    'Petapa',
    'San Antonio Miramar',
    'Palin',
    'La Reforma',
    'Coban',
    'Mazatenango',
    'Santa Lucia Cotzumalguapa',
    'Retalhuleu',
    'Escuintla',
    'San Marcos',
    'La Reforma',
    'San Juan',
    'Panajachel',
    'Jocotenango',
    'Santa Cruz La Laguna',
    'Esquipulas',
    'Solola',
    'TotonicapÃ¡n',
    'Chiquimula',
    'Puerto Barrios',
    'Central',
    'Flores',
    'Santa Rosa',
    'San Jose Pinula',
    'El Naranjo',
    'El Limon',
    'Cambote',
  ],
  tld: ['.gt'],
  cca2: 'GT',
  ccn3: '320',
  cca3: 'GTM',
  cioc: 'GUA',
  currency: ['GTQ'],
  callingCode: ['502'],
  capital: 'Guatemala City',
  altSpellings: ['GT'],
  region: 'Americas',
  subregion: 'Central America',
  languages: { spa: 'Spanish' },
  translations: {
    deu: { official: 'Republik Guatemala', common: 'Guatemala' },
    fra: { official: 'République du Guatemala', common: 'Guatemala' },
    hrv: { official: 'Republika Gvatemala', common: 'Gvatemala' },
    ita: { official: 'Repubblica del Guatemala', common: 'Guatemala' },
    jpn: { official: 'グアテマラ共和国', common: 'グアテマラ' },
    nld: { official: 'Republiek Guatemala', common: 'Guatemala' },
    por: { official: 'República da Guatemala', common: 'Guatemala' },
    rus: { official: 'Республика Гватемала', common: 'Гватемала' },
    spa: { official: 'República de Guatemala', common: 'Guatemala' },
    fin: { official: 'Guatemalan tasavalta', common: 'Guatemala' },
  },
  latlng: [15.5, -90.25],
  demonym: 'Guatemalan',
  landlocked: false,
  borders: ['BLZ', 'SLV', 'HND', 'MEX'],
  area: 108889,
  provinces: [
    'Alta Verapaz',
    'Baja Verapaz',
    'Chimaltenango',
    'Chiquimula',
    'El Progreso',
    'Escuintla',
    'Guatemala',
    'Huehuetenango',
    'Izabal',
    'Jalapa',
    'Jutiapa',
    'Peten',
    'Quetzaltenango',
    'Quiche',
    'Retalhuleu',
    'Sacatepequez',
    'San Marcos',
    'Santa Rosa',
    'Solola',
    'Suchitepequez',
    'Totonicapan',
    'Zacapa',
  ],
  nativeName: 'Guatemala',
  timezones: ['UTC−06:00'],
} as const;
