import { Vue } from '@vue';
import { Module, VuexModule, Mutation, Action } from '@vuex/decorators';
import { find, some } from 'lodash';

import { api } from '@api';
import * as models from '@models';
import { router } from '@router';
import { alert } from '@services/alert';
import { ls } from '@services/ls';
import { Root } from '@store';
import { isString, isNumber, isObject, isNull } from '@tools/type-guards';
import { RoleId } from '@values/roles';

export interface Auth0Identity {
  connection: string;
  provider: string;
  user_id: string;
  isSocial: boolean;
  profileData: Record<string, unknown>;
}

declare module '@vuex/core' {
  export interface Getters {
    'me/loggedIn': Me['loggedIn'];
    'me/isCurrentRole': Me['isCurrentRole'];
    'me/hasRoleType': Me['hasRoleType'];
    'me/selectableRoles': Me['selectableRoles'];
  }

  export interface CommitMap {
    'me/SET': Me['SET'];
    'me/SET_ROLE': Me['SET_ROLE'];
    'me/SET_LOADING_STATE': Me['SET_LOADING_STATE'];
    'me/SET_INVITES': Me['SET_INVITES'];
    'me/SET_ORGANIZATION': Me['SET_ORGANIZATION'];
    'me/SET_DRONE_LOGBOOK_STATE': Me['SET_DRONE_LOGBOOK_STATE'];
    'me/UPDATE': Me['UPDATE'];
    'me/CLEAR': Me['CLEAR'];
  }

  export interface DispatchMap {
    'me/get': Me['get'];
    'me/getOrganization': Me['getOrganization'];
    'me/getInvites': Me['getInvites'];
    'me/declineInvite': Me['declineInvite'];
    'me/setRole': Me['setRole'];
    'me/update': Me['update'];
    'me/changePassword': Me['changePassword'];
    'me/linkDroneLogbook': Me['linkDroneLogbook'];
    'me/unlinkDroneLogbook': Me['unlinkDroneLogbook'];
  }
}

/**
 * ...
 */
@Module({ namespaced: true })
export class Me extends VuexModule<Me.State, Root.State> implements Me.State {
  id: Me.State['id'] = null;
  username: Me.State['username'] = null;
  firstName: Me.State['firstName'] = null;
  lastName: Me.State['lastName'] = null;
  email: Me.State['email'] = null;
  birthdate: Me.State['birthdate'] = null;
  phone: Me.State['phone'] = null;
  address: Me.State['address'] = null;
  lastSimLogin: Me.State['lastSimLogin'] = null;
  lastWebLogin: Me.State['lastWebLogin'] = null;
  avatar: Me.State['avatar'] = null;
  simulatorTime: Me.State['simulatorTime'] = null;
  realLifeTime: Me.State['realLifeTime'] = null;
  passwordChanged: Me.State['passwordChanged'] = null;
  isDroneLogbookLinked: Me.State['isDroneLogbookLinked'] = null;
  syncingDroneLogbook: Me.State['syncingDroneLogbook'] = null;
  roles: Me.State['roles'] = null;
  licenses: Me.State['licenses'] = null;
  courses: Me.State['courses'] = null;
  privacy: Me.State['privacy'] = null;
  active: Me.State['active'] = null;
  selectedRole: Me.State['selectedRole'] = null;
  resellers: Me.State['resellers'] = null;
  invites: Me.State['invites'] = null;
  organization: Me.State['organization'] = null;
  identities: Me.State['identities'] = [];

  loading = false;

  /** ... */
  get loggedIn() {
    return typeof this.id === 'string';
  }

  /** ... */
  get isCurrentRole() {
    return (...roleIds: RoleId[]) => {
      return roleIds.some((id) => id === this.selectedRole?.roleId);
    };
  }

  /** ... */
  get hasRoleType() {
    return (...ids: RoleId[]) => hasRoleType(this.roles ?? [], ids);
  }

  /** ... */
  get selectableRoles() {
    if (!this.roles) return [];

    return this.roles;

    /**
     * Commented out below code. Was causing issues with students being
     * logged in as their non-organization role and then not being able
     * to switch to their student role
     */

    // Does the user have the independent operator role?
    // const hasIORole = hasRoleType(this.roles, [2]);
    // Does the user have an role associated with an organization?
    // const hasOrganizationRole = hasRoleType(this.roles, [3, 4, 5]);

    // return this.roles.filter((role) => {
    // The "Independent Operator" role should be hidden if the user also has
    // an organization-based role.
    // if (role.roleId === 2 && hasOrganizationRole) return false;

    // The "Subscriber" role should be hidden if the user also has an
    // organization-based role or an "Independent Operator" role.
    // if (role.roleId === 1 && (hasOrganizationRole || hasIORole)) return false;

    // return true;
    // });
  }

  // region Mutations

  @Mutation
  SET(options: Me.SetMutationOptions) {
    // Create convenient reference to user ID.
    const userId = options.id;

    if (!userId) {
      throw new Error(
        'When setting active user, a valid user "id" must be provided.',
      );
    }

    for (const key in this) {
      Vue.set(this, key, options[key] ?? null);
    }

    const selectedRoleData = ls.getObject(`zephyr.selectedRole.${userId}`);

    // check if selected role is still valid/exists in the user's roles array
    if (
      isValidUserRole(selectedRoleData) &&
      this.selectableRoles?.length &&
      !this.selectableRoles?.find((role) => role.id === selectedRoleData?.id)
    ) {
      ls.remove(`zephyr.selectedRole.${userId}`);

      const firstRole: models.Role | undefined = this.selectableRoles[0];
      const roleId: string | null = firstRole ? firstRole.id : null;

      if (!roleId) return;

      this.SET_ROLE({ roleId });

      return;
    }

    this.selectedRole = isValidUserRole(selectedRoleData)
      ? selectedRoleData
      : null;

    if (!this.selectedRole) {
      /* eslint-disable-next-line no-console */
      console.warn(
        '[store.me.SET] selected role data was found, but was malformed, and will therefore be ignored.',
      );
    }
  }

  @Mutation
  SET_ROLE(options?: Me.SetRoleMutationOptions) {
    if (this.id === null) return;

    // ...
    const roleId = options?.roleId ?? null;
    // ...
    let role: models.Role | null = null;

    if (isString(roleId)) {
      role = find(this.roles ?? [], { id: roleId }) ?? null;
    }

    const cachedRoleId = `zephyr.selectedRole.${this.id}`;

    if (!role) {
      return ls.remove(cachedRoleId);
    }

    // empty existing role to prevent duplication
    const selectedRoleData = ls.getObject(cachedRoleId);

    if (selectedRoleData) ls.remove(cachedRoleId);

    this.selectedRole = role;

    ls.setObject(cachedRoleId, role);
  }

  @Mutation
  SET_LOADING_STATE(options: Me.SetLoadingStateOptions) {
    this.loading = options.loading;
  }

  @Mutation
  SET_INVITES(options?: Me.SetInvitesMutationOptions) {
    this.invites = options?.invites ?? null;
  }

  @Mutation
  SET_ORGANIZATION(options: Me.SetOrganizationMutationOptions) {
    this.organization = options.organization;
  }

  // @Mutation
  // SET_INSTITUTION(options: Me.SetInstitutionMutationOptions) {
  //   this.institution = options.institution;
  // }

  @Mutation
  SET_DRONE_LOGBOOK_STATE(options: Me.SetDroneLogbookStateMutationOptions) {
    this.isDroneLogbookLinked = options.isDroneLogbookLinked;
  }

  @Mutation
  UPDATE(options: Me.UpdateMutationOptions) {
    for (const key in options) Vue.set(this, key, options[key]);
  }

  @Mutation
  CLEAR() {
    for (const key in this) Vue.set(this, key, null);
  }

  // endregion Mutations

  // region Actions

  /**
   * Get the user based on the current active session.
   *
   * @return The current user state.
   */
  @Action
  async get() {
    this.context.commit('SET_LOADING_STATE', { loading: true });

    const data = await api.users.getMe();

    this.context.commit('users/ADD', data, { root: true });

    /**
     * Check if currently selected role is still valid. user could have
     * experienced an upgraded or changed role in this session.
     *
     * TODO: We MIGHT want to bake this functionality into the auth
     * service-proper -- review when prudent.
     */
    if (
      this.selectedRole &&
      data.roles &&
      !isRoleStillValid(data.roles, this.selectedRole)
    ) {
      if (this.id) {
        ls.remove(`zephyr.selectedRole.${this.id}`);

        const validRole = isValidUserRole(data.roles[0]) ? data.roles[0] : null;

        if (validRole) {
          this.context.commit('SET_ROLE', { roleId: validRole.id });
        }
      }

      alert.info('Role Change Detected. Auto-Reloading...');

      setTimeout(() => {
        router.go(0);
      }, 5_000);

      this.context.commit('SET_LOADING_STATE', { loading: false });

      return this.context.state;
    }

    if (
      !this.selectedRole &&
      data.roles?.length === 1 &&
      isValidUserRole(data.roles[0])
    ) {
      this.context.commit('SET_ROLE', { roleId: data.roles[0].id });
    }

    if (this.id === data.id) {
      this.context.commit('UPDATE', data);
    } else {
      this.context.commit('SET', data);

      const loaders: Promise<unknown>[] = [this.context.dispatch('getInvites')];

      if (this.selectedRole?.organization?.id) {
        loaders.push(this.context.dispatch('getOrganization'));
      }

      await Promise.all(loaders);
    }

    this.context.commit('SET_LOADING_STATE', { loading: false });

    return this.context.state;
  }

  /**
   * ...
   *
   * @return ...
   */
  @Action
  async getOrganization() {
    if (this.id === null) return this.organization;

    const organizationId = this.selectedRole?.organization?.id;

    if (!organizationId) {
      // eslint-disable-next-line no-console
      return console.warn(
        `The user's current role does not have an associated organization.`,
      );
    }

    const organization = await api.organizations.get({
      organizationId,
      admin: this.selectedRole?.roleId === 9,
    });

    this.context.commit('SET_ORGANIZATION', { organization });

    return this.organization;
  }

  /**
   * ...
   *
   * @return ...
   */
  @Action
  async getInvites() {
    if (this.id === null) return this.invites;

    const invites = await api.invites.getMine();

    this.context.commit('SET_INVITES', { invites });

    return this.invites;
  }

  /**
   * ...
   *
   * @return ...
   */
  @Action
  async acceptInvite(options: api.invites.AcceptOptions) {
    try {
      await api.invites.accept(options);
    } catch (err) {
      alert.error(err);
      throw err;
    }

    await this.context.dispatch('getInvites');
    await this.context.dispatch('get');

    return this.invites;
  }

  /**
   * ...
   *
   * @return ...
   */
  @Action
  async declineInvite(options: api.invites.DeclineOptions) {
    try {
      await api.invites.decline(options);
    } catch (err) {
      alert.error(err);
      throw err;
    }

    await this.context.dispatch('getInvites');
    await this.context.dispatch('get');

    return this.invites;
  }

  /**
   * ...
   *
   * @param options ...
   * @return ...
   */
  @Action
  async setRole(options: Me.SetRoleActionOptions) {
    this.context.commit('SET_LOADING_STATE', { loading: true });
    this.context.commit('SET_ROLE', options);

    if (this.selectedRole?.organization?.id) {
      await this.context.dispatch('getOrganization');
    }

    this.context.commit('SET_LOADING_STATE', { loading: false });
  }

  /**
   * ...
   *
   * @param options ...
   * @return ...
   */
  @Action
  async update(options: Me.UpdateActionOptions) {
    await api.users.updateMe(options);

    return (await this.context.dispatch('get')) as Me.State;
  }

  /**
   * ...
   *
   * @return ...
   */
  @Action
  async changePassword(options: Me.ChangeUserPasswordOptions) {
    if (this.id !== null) {
      await api.users.changeMyPassword(options);
    }
  }

  /**
   * ...
   *
   * @return ...
   */
  @Action
  async linkDroneLogbook(options: Me.LinkDroneLogbookActionOptions) {
    await api.users.linkDroneLogbook(options);

    this.context.commit('SET_DRONE_LOGBOOK_STATE', {
      isDroneLogbookLinked: true,
    });
  }

  /**
   * ...
   *
   * @return ...
   */
  @Action
  async unlinkDroneLogbook() {
    await api.users.unlinkDroneLogbook();

    this.context.commit('SET_DRONE_LOGBOOK_STATE', {
      isDroneLogbookLinked: false,
    });
  }

  // endregion Actions
}

export namespace Me {
  /** Active Active site user's received and sent invites. */
  export interface Invites {
    received: models.Invite[];
    sent: models.Invite[];
  }

  /**
   * User/Session info of a site user.
   */
  export type UserSessionInfo = Pick<
    models.User,
    | 'id'
    | 'username'
    | 'firstName'
    | 'lastName'
    | 'email'
    | 'birthdate'
    | 'phone'
    | 'address'
    | 'lastSimLogin'
    | 'lastWebLogin'
    | 'avatar'
    | 'simulatorTime'
    | 'realLifeTime'
    | 'passwordChanged'
    | 'isDroneLogbookLinked'
    | 'syncingDroneLogbook'
    | 'roles'
    | 'licenses'
    | 'courses'
    | 'privacy'
  > & {
    active: boolean;
    selectedRole: models.Role;
    resellers: models.Reseller[];
    invites: Invites;
    organization: models.Organization;
    identities: Auth0Identity[];
    /** ... */
    loading: boolean;
  };

  /** ... */
  export type State = AssignTypes<UserSessionInfo, null>;

  /** ... */
  export type SetMutationOptions = UserSessionInfo;

  /**
   * ...
   */
  export interface SetRoleMutationOptions {
    /** Unique ID of the role (NOT the role's TYPE ID). */
    roleId?: string;
  }

  /**
   * ...
   */
  export interface SetLoadingStateOptions {
    loading: boolean;
  }

  /**
   * ...
   */
  export interface SetInvitesMutationOptions {
    invites: Invites | null;
  }

  /**
   * ...
   */
  export interface SetOrganizationMutationOptions {
    organization: models.Organization;
  }

  /**
   * ...
   */
  export interface SetDroneLogbookStateMutationOptions {
    isDroneLogbookLinked: boolean;
  }

  /**
   * ...
   */
  export interface SetRoleActionOptions {
    roleId: string;
  }

  /** ... */
  export type UpdateMutationOptions = api.users.UpdateMeOptions;
  /** ... */
  export type UpdateActionOptions = api.users.UpdateMeOptions;
  /** ... */
  export type ChangeUserPasswordOptions = api.users.ChangeMyPasswordOptions;
  /** ... */
  export type LinkDroneLogbookActionOptions = api.users.LinkDroneLogbookOptions;
}

export default Me;

//#region Helper Functions

/**
 * ...
 *
 * @param value ...
 * @returns ...
 */
function isValidUserRole(value: unknown): value is models.Role {
  if (!isObject(value)) return false;

  return (
    isString(value['id']) &&
    isNumber(value['roleId']) &&
    (isString(value['name']) || isNull(value['name'])) &&
    (isObject(value['course']) || isNull(value['course'])) &&
    (isObject(value['organization']) || isNull(value['organization'])) &&
    (isObject(value['reseller']) || isNull(value['reseller'])) &&
    (isString(value['subStart']) || isNull(value['subStart'])) &&
    (isString(value['subEnd']) || isNull(value['subEnd']))
  );
}

/**
 * Check if a user has a specified role type by ID.
 *
 * @param roles A list of role IDs.
 * @param roleIds ...
 * @returns Boolean that represents whether or not the user has any of the
 * specified roles.
 */
function hasRoleType(roles: models.Role[], roleIds: number[]) {
  return some(roles, ({ roleId }) => roleIds.includes(roleId));
}

/**
 * Check if given role still exists on user roles
 */
function isRoleStillValid(roles: models.Role[] | null, role: models.Role) {
  if (!role) return false;
  return find(roles, { id: role.id, roleId: role.roleId });
}

//#endregion Helper Functions
