export const TGO = {
  name: {
    common: 'Togo',
    official: 'Togolese Republic',
    native: { fra: { official: 'République togolaise', common: 'Togo' } },
  },
  cities: ['LomÃ©', 'Sansanne-Mango'],
  tld: ['.tg'],
  cca2: 'TG',
  ccn3: '768',
  cca3: 'TGO',
  cioc: 'TOG',
  currency: ['XOF'],
  callingCode: ['228'],
  capital: 'Lomé',
  altSpellings: ['TG', 'Togolese', 'Togolese Republic', 'République Togolaise'],
  region: 'Africa',
  subregion: 'Western Africa',
  languages: { fra: 'French' },
  translations: {
    deu: { official: 'Republik Togo', common: 'Togo' },
    fra: { official: 'République togolaise', common: 'Togo' },
    hrv: { official: 'Togolese Republika', common: 'Togo' },
    ita: { official: 'Repubblica del Togo', common: 'Togo' },
    jpn: { official: 'トーゴ共和国', common: 'トーゴ' },
    nld: { official: 'Republiek Togo', common: 'Togo' },
    por: { official: 'República do Togo', common: 'Togo' },
    rus: { official: 'Того Республика', common: 'Того' },
    spa: { official: 'República de Togo', common: 'Togo' },
    fin: { official: 'Togon tasavalta', common: 'Togo' },
  },
  latlng: [8, 1.16666666],
  demonym: 'Togolese',
  landlocked: false,
  borders: ['BEN', 'BFA', 'GHA'],
  area: 56785,
  provinces: [
    'De La Kara',
    'Des Plateaux',
    'Des Savanes',
    'Du Centre',
    'Maritime',
  ],
  nativeName: 'Togo',
  timezones: ['UTC'],
} as const;
