var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "FloatingLabel",
    _vm._b(
      { staticClass: "form-input" },
      "FloatingLabel",
      { label: _vm.label },
      false
    ),
    [
      _c(
        "b-form-input",
        _vm._g(
          _vm._b(
            { attrs: { placeholder: _vm.label } },
            "b-form-input",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }