export const ESH = {
  name: {
    common: 'Western Sahara',
    official: 'Sahrawi Arab Democratic Republic',
    native: {
      ber: {
        official: 'Sahrawi Arab Democratic Republic',
        common: 'Western Sahara',
      },
      mey: {
        official: 'الجمهورية العربية الصحراوية الديمقراطية',
        common: 'الصحراء الغربية',
      },
      spa: {
        official: 'República Árabe Saharaui Democrática',
        common: 'Sahara Occidental',
      },
    },
  },
  nativeName: 'الصحراء الغربية',
  cities: [],
  provinces: ['Western Sahara'],
  tld: ['.eh'],
  cca2: 'EH',
  ccn3: '732',
  cca3: 'ESH',
  cioc: '',
  currency: ['MAD', 'DZD', 'MRO'],
  callingCode: ['212'],
  capital: 'El Aaiún',
  altSpellings: ['EH', 'Taneẓroft Tutrimt'],
  region: 'Africa',
  subregion: 'Northern Africa',
  languages: { ber: 'Berber', mey: 'Hassaniya', spa: 'Spanish' },
  translations: {
    deu: {
      official: 'Demokratische Arabische Republik Sahara',
      common: 'Westsahara',
    },
    fra: {
      official: 'République arabe sahraouie démocratique',
      common: 'Sahara Occidental',
    },
    hrv: {
      official: 'Sahrawi Arab Demokratska Republika',
      common: 'Zapadna Sahara',
    },
    ita: {
      official: 'Repubblica Araba Saharawi Democratica',
      common: 'Sahara Occidentale',
    },
    jpn: { official: 'サハラアラブ民主共和国', common: '西サハラ' },
    nld: {
      official: 'Sahrawi Arabische Democratische Republiek',
      common: 'Westelijke Sahara',
    },
    por: {
      official: 'República Árabe Saharaui Democrática',
      common: 'Saara Ocidental',
    },
    rus: {
      official: 'Sahrawi Арабская Демократическая Республика',
      common: 'Западная Сахара',
    },
    spa: {
      official: 'República Árabe Saharaui Democrática',
      common: 'Sahara Occidental',
    },
    fin: { official: 'Länsi-Sahara', common: 'Länsi-Sahara' },
  },
  latlng: [24.5, -13],
  demonym: 'Sahrawi',
  landlocked: false,
  borders: ['DZA', 'MRT', 'MAR'],
  area: 266000,
} as const;
