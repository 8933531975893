import { ref, computed, reactive } from 'vue';
import { TablePanel } from 'table-panel';
import { createFlyoutMenu, FlyoutMenu } from 'flyout-menu';

import { modals } from '@modals';
import { useStore } from '@store';

import { faBracketsCurly } from '@icons/solid/faBracketsCurly';

import type { DataItem } from './table';

export type FlyoutOperations<T extends DataItem = DataItem> =
  TablePanel.Row.Operation<T>[];

/**
 * ...
 */
export function useFlyout<T extends DataItem = DataItem>(
  operations: FlyoutOperations<T>,
) {
  const store = useStore();

  const flyoutMenu = ref<FlyoutMenu | null>(null);

  const isOpen = computed(() => !!flyoutMenu.value);

  /** The list of operations to display. */
  const buildFlyoutItems = (item: T) => {
    if (!operations) return [];

    const items: FlyoutMenu.Item[] = [];

    for (const { fn, ...op } of operations) {
      const shouldHide = op.hidden ? op.hidden(item, store.state.me) : false;

      if (shouldHide) continue;

      const flyoutItem: FlyoutMenu.Item = {
        ...op,
        fn: () => fn(item, store.state.me),
      };

      // TODO: (Dane) devise a better way to handle this.
      if (op.label === 'Delete') {
        flyoutItem.variant = 'danger';
      }

      items.push(flyoutItem);
    }

    // If in a development environment, add a `Log Item` option that will
    // log the item corresponding to this flyout to the console.
    if (DEVELOPMENT) {
      items.push({
        label: 'Log Item',
        icon: faBracketsCurly,
        // eslint-disable-next-line no-console
        // fn: () => console.log(item),
        fn: () => {
          // eslint-disable-next-line no-console
          console.log(item);

          void modals.util.viewValue({ value: item });
        },
      });
    }

    return items;
  };

  /**
   * Open the flyout.
   *
   * @param item The subject of the flyout.
   * @param x The horizontal position (x) to place the flyout.
   * @param y The vertical position (y) to place the flyout.
   */
  const open = (item: T, x: number, y: number) => {
    if (flyoutMenu.value) flyoutMenu.value.close();

    // x += 0.5 * el.value.offsetWidth + 10;
    // y += 0.5 * el.value.offsetHeight;

    const flyoutItems = buildFlyoutItems(item);

    const flyoutInstance = createFlyoutMenu({ x, y, items: flyoutItems });

    flyoutInstance.onClosed(() => {
      if (flyoutInstance.uid === flyoutMenu.value?.uid) {
        flyoutMenu.value = null;
      }
    });

    flyoutMenu.value = flyoutInstance;
  };

  /**
   * Close the flyout.
   */
  const close = () => {
    flyoutMenu.value?.close();
  };

  return reactive({ isOpen, open, close });
}
