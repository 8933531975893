export const BTN = {
  name: {
    common: 'Bhutan',
    official: 'Kingdom of Bhutan',
    native: { dzo: { official: 'འབྲུག་རྒྱལ་ཁབ་', common: 'འབྲུག་ཡུལ་' } },
  },
  cities: ['Thimphu', 'Paro', 'Phuntsholing', 'Mongar'],
  tld: ['.bt'],
  cca2: 'BT',
  ccn3: '064',
  cca3: 'BTN',
  cioc: 'BHU',
  currency: ['BTN', 'INR'],
  callingCode: ['975'],
  capital: 'Thimphu',
  altSpellings: ['BT', 'Kingdom of Bhutan'],
  region: 'Asia',
  subregion: 'Southern Asia',
  languages: { dzo: 'Dzongkha' },
  translations: {
    cym: { official: 'Kingdom of Bhutan', common: 'Bhwtan' },
    deu: { official: 'Königreich Bhutan', common: 'Bhutan' },
    fra: { official: 'Royaume du Bhoutan', common: 'Bhoutan' },
    hrv: { official: 'Kraljevina Butan', common: 'Butan' },
    ita: { official: 'Regno del Bhutan', common: 'Bhutan' },
    jpn: { official: 'ブータン王国', common: 'ブータン' },
    nld: { official: 'Koninkrijk Bhutan', common: 'Bhutan' },
    por: { official: 'Reino do Butão', common: 'Butão' },
    rus: { official: 'Королевство Бутан', common: 'Бутан' },
    spa: { official: 'Reino de Bután', common: 'Bután' },
    fin: { official: 'Bhutanin kuningaskunta', common: 'Bhutan' },
  },
  latlng: [27.5, 90.5],
  demonym: 'Bhutanese',
  landlocked: true,
  borders: ['CHN', 'IND'],
  area: 38394,
  provinces: [
    'Bumthang',
    'Chhukha',
    'Chirang',
    'Daga',
    'Geylegphug',
    'Ha',
    'Lhuntshi',
    'Mongar',
    'Paro',
    'Pemagatsel',
    'Punakha',
    'Samchi',
    'Samdrup Jongkhar',
    'Shemgang',
    'Tashigang',
    'Thimphu',
    'Tongsa',
    'Wangdi Phodrang',
  ],
  nativeName: 'ʼbrug-yul',
  timezones: ['UTC+06:00'],
} as const;
