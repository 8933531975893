import { isFinite } from 'lodash';

/**
 * ...
 */
class ConversionHelper {
  constructor(public value: number) {}

  static exec(value: number) {
    const helper = new ConversionHelper(value);

    return [3600, 60, 1].map((amount) => helper.reduce(amount)).join(':');
  }

  reduce(amount: number) {
    const value = Math.floor(this.value / amount).toString();

    this.value %= amount;

    return value.length === 1 ? '0' + value : value;
  }
}

/**
 * ...
 *
 * @param input ...
 * @return ...
 */
export function secToHmsFilter(input: unknown) {
  let duration = 0;

  if (typeof input === 'string') {
    duration = parseFloat(input);
  } else if (typeof input === 'number') {
    duration = input;
  } else {
    return '--';
  }

  if (!isFinite(duration)) return '--';

  return ConversionHelper.exec(duration);
}

export default secToHmsFilter;
