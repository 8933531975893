import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import { faHomeLg } from '@icons/solid/faHomeLg';
import { faTableRows } from '@icons/solid/faTableRows';
import { faTruck } from '@icons/solid/faTruck';
import { faUser } from '@icons/solid/faUser';
import { faChalkboard } from '@icons/solid/faChalkboard';
import { faSchool } from '@icons/solid/faSchool';
import { faDroneAlt } from '@icons/solid/faDroneAlt';
import { faClipboardListCheck } from '@fortawesome/pro-solid-svg-icons/faClipboardListCheck';
import { faTreasureChest } from '@icons/solid/faTreasureChest';
import { faDiploma } from '@icons/solid/faDiploma';
import { faBracketsCurly } from '@icons/solid/faBracketsCurly';

/**
 * ...
 */
export interface DashboardMenuItem {
  id: number;
  label: string;
  route: string;
  icon: IconDefinition;
  sub?: boolean;
  comingSoon?: boolean;
}

/** ... */
const MenuItemHome: DashboardMenuItem = {
  id: 1,
  label: 'HOME',
  icon: faHomeLg,
  route: 'dashboard',
};

/** ... */
const MenuItemManage: DashboardMenuItem = {
  id: 2,
  label: 'MANAGE',
  route: 'dashboardManage',
  icon: faTableRows,
};

/** ... */
const MenuItemUnityModules: DashboardMenuItem = {
  id: 3,
  label: 'SIMULATOR',
  route: 'dashboardUnityModules',
  icon: faDroneAlt,
};

/** ... */
const MenuItemCourse: DashboardMenuItem = {
  id: 5,
  label: 'COURSE',
  route: 'dashboardCourse',
  icon: faSchool,
  sub: true,
};

/** ... */
const MenuItemStudent: DashboardMenuItem = {
  id: 6,
  label: 'STUDENT',
  route: 'dashboardStudent',
  icon: faUser,
  sub: true,
};

/** ... */
const MenuItemAssignments: DashboardMenuItem = {
  id: 8,
  label: 'ASSIGNMENTS',
  route: 'dashboardAssignments',
  icon: faChalkboard,
  sub: true,
};

/** ... */
const MenuItemAssignment: DashboardMenuItem = {
  id: 9,
  label: 'ASSIGNMENT',
  route: 'dashboardAssignment',
  icon: faChalkboard,
  sub: true,
};

/** ... */
const MenuItemReports: DashboardMenuItem = {
  id: 10,
  label: 'REPORTS',
  route: 'dashboardReports',
  icon: faClipboardListCheck,
};

/** ... */
const MenuItemOrders: DashboardMenuItem = {
  id: 13,
  label: 'ORDERS',
  route: 'dashboardOrders',
  icon: faTruck,
};

/** ... */
const MenuItemCertifications: DashboardMenuItem = {
  id: 14,
  label: 'CERTIFICATIONS',
  route: 'dashboardCertifications',
  icon: faDiploma,
  // comingSoon: true,
};

/** ... */
const MenuItemContent: DashboardMenuItem = {
  id: 15,
  label: 'MY CONTENT',
  route: 'dashboardMyContent',
  icon: faTreasureChest,
};

/** ... */
const MenuItemCourseAssignment: DashboardMenuItem = {
  id: 16,
  label: 'COURSE ASSIGNMENT',
  route: 'dashboardCourseAssignment',
  icon: faChalkboard,
  sub: true,
};

const MenuItemDeveloper: DashboardMenuItem = {
  id: 17,
  label: 'DEVELOPER',
  icon: faBracketsCurly,
  route: 'dashboardDeveloper',
};

/** ... */
const subscriberMenu: DashboardMenuItem[] = [
  MenuItemHome,
  // MenuItemReports,
  MenuItemOrders,
  MenuItemContent,
  MenuItemCertifications,
];

/** ... */
const independentOperatorMenu: DashboardMenuItem[] = [
  MenuItemHome,
  MenuItemManage,
  MenuItemReports,
  MenuItemOrders,
  MenuItemContent,
  MenuItemCertifications,
];

/** ... */
const studentMenu: DashboardMenuItem[] = [
  MenuItemHome,
  MenuItemReports,
  MenuItemOrders,
  MenuItemContent,
  MenuItemCertifications,
];

/** ... */
const instructorMenu: DashboardMenuItem[] = [
  MenuItemHome,
  MenuItemManage,
  MenuItemReports,
  MenuItemCourse,
  MenuItemStudent,
  MenuItemAssignments,
  MenuItemAssignment,
  MenuItemOrders,
  MenuItemContent,
  MenuItemCertifications,
  MenuItemCourseAssignment,
];

/** ... */
const institutionAdminMenu: DashboardMenuItem[] = [
  MenuItemHome,
  MenuItemManage,
  MenuItemReports,
  MenuItemCourse,
  MenuItemStudent,
  MenuItemAssignments,
  MenuItemAssignment,
  MenuItemOrders,
  MenuItemContent,
  MenuItemCertifications,
  MenuItemCourseAssignment,
];

/** ... */
const resellerMenu: DashboardMenuItem[] = [
  MenuItemHome,
  MenuItemManage,
  MenuItemOrders,
  MenuItemContent,
  MenuItemCertifications,
];

/** ... */
const reviewerMenu: DashboardMenuItem[] = [
  MenuItemHome,
  MenuItemManage,
  MenuItemOrders,
  MenuItemContent,
  MenuItemCertifications,
  MenuItemReports,
];

/** ... */
const lasAdminMenu: DashboardMenuItem[] = [
  MenuItemHome,
  MenuItemManage,
  MenuItemUnityModules,
  MenuItemReports,
  MenuItemCourse,
  MenuItemStudent,
  MenuItemAssignments,
  MenuItemAssignment,
  MenuItemOrders,
  MenuItemContent,
  MenuItemCertifications,
  MenuItemCourseAssignment,
  MenuItemDeveloper,
];

/** ... */
export const dashboardMenus = {
  1: subscriberMenu,
  2: independentOperatorMenu,
  3: studentMenu,
  4: instructorMenu,
  5: institutionAdminMenu,
  7: resellerMenu,
  8: reviewerMenu,
  9: lasAdminMenu,
};

export default dashboardMenus;
