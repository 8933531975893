import { Reseller } from '@models';

import { openModal } from '../open-modal';

export interface EditResellerModalOptions {
  reseller: Reseller;
}

export interface ViewResellerModalOptions {
  reseller: Reseller;
}

/**
 * Open Create Reseller modal.
 *
 * @return Modal promise.
 */
export async function create() {
  const modalOptions = {
    title: 'CREATE RESELLER',
    component: () => import('./CreateReseller.vue'),
  };

  try {
    await openModal(modalOptions);
  } catch (_) {
    return;
  }
}

/**
 * Open Edit Reseller modal.
 *
 * @return Modal promise.
 */
export async function edit(options: EditResellerModalOptions) {
  const modalOptions = {
    title: 'EDIT RESELLER',
    props: { ...options },
    component: () => import('./EditReseller.vue'),
  };

  try {
    await openModal(modalOptions);
  } catch (_) {
    return;
  }
}

/**
 * Open View Reseller modal.
 *
 * @return Modal promise.
 */
export async function view(options: ViewResellerModalOptions) {
  const modalOptions = {
    title: 'VIEW RESELLER',
    size: 'lg',
    props: { ...options },
    component: () => import('./ViewReseller.vue'),
  };

  try {
    await openModal(modalOptions);
  } catch (_) {
    return;
  }
}
