var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "radial-progress-container", style: _vm.containerStyle },
    [
      _c(
        "div",
        { staticClass: "radial-progress-inner", style: _vm.innerCircleStyle },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _c(
        "svg",
        {
          staticClass: "radial-progress-bar",
          attrs: {
            width: _vm.diameter,
            height: _vm.diameter,
            version: "1.1",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c(
            "defs",
            [
              _c(
                "radialGradient",
                {
                  attrs: {
                    id: "radial-gradient" + _vm._uid,
                    fx: _vm.gradient.fx,
                    fy: _vm.gradient.fy,
                    cx: _vm.gradient.cx,
                    cy: _vm.gradient.cy,
                    r: _vm.gradient.r,
                  },
                },
                [
                  _c("stop", {
                    attrs: { offset: "30%", "stop-color": _vm.startColor },
                  }),
                  _vm._v(" "),
                  _c("stop", {
                    attrs: { offset: "100%", "stop-color": _vm.stopColor },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("circle", {
            style: _vm.strokeStyle,
            attrs: {
              r: _vm.innerCircleRadius,
              cx: _vm.radius,
              cy: _vm.radius,
              fill: "transparent",
              stroke: _vm.innerStrokeColor,
              "stroke-dasharray": _vm.circumference,
              "stroke-dashoffset": "0",
              "stroke-linecap": _vm.strokeLinecap,
            },
          }),
          _vm._v(" "),
          _c("circle", {
            style: _vm.progressStyle,
            attrs: {
              transform: "rotate(270, " + _vm.radius + "," + _vm.radius + ")",
              r: _vm.innerCircleRadius,
              cx: _vm.radius,
              cy: _vm.radius,
              fill: "transparent",
              stroke: "url(#radial-gradient" + _vm._uid + ")",
              "stroke-dasharray": _vm.circumference,
              "stroke-dashoffset": _vm.circumference,
              "stroke-linecap": _vm.strokeLinecap,
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }