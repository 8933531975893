import { render, staticRenderFns } from "./ModalFooter.vue?vue&type=template&id=bc4cd966&scoped=true"
import script from "./ModalFooter.vue?vue&type=script&setup=true&lang=ts"
export * from "./ModalFooter.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./ModalFooter.vue?vue&type=style&index=0&id=bc4cd966&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc4cd966",
  null
  
)

export default component.exports