import * as utils from './utils';

declare module 'vue/types/vue' {
  interface Vue {
    /** ... */
    $utils: typeof utils;
  }
}

export * from './utils';

export default utils;
