export const COK = {
  name: {
    common: 'Cook Islands',
    official: 'Cook Islands',
    native: {
      eng: { official: 'Cook Islands', common: 'Cook Islands' },
      rar: { official: "Kūki 'Āirani", common: "Kūki 'Āirani" },
    },
  },
  cities: ['Avarua', 'Titikaveka'],
  tld: ['.ck'],
  cca2: 'CK',
  ccn3: '184',
  cca3: 'COK',
  cioc: 'COK',
  currency: ['NZD'],
  callingCode: ['682'],
  capital: 'Avarua',
  altSpellings: ['CK', "Kūki 'Āirani"],
  region: 'Oceania',
  subregion: 'Polynesia',
  languages: { eng: 'English', rar: 'Cook Islands Māori' },
  translations: {
    cym: { official: 'Cook Islands', common: 'Ynysoedd Cook' },
    deu: { official: 'Cook-Inseln', common: 'Cookinseln' },
    fra: { official: 'Îles Cook', common: 'Îles Cook' },
    hrv: { official: 'Cook Islands', common: 'Cookovo Otočje' },
    ita: { official: 'Isole Cook', common: 'Isole Cook' },
    jpn: { official: 'クック諸島', common: 'クック諸島' },
    nld: { official: 'Cook eilanden', common: 'Cookeilanden' },
    por: { official: 'Ilhas Cook', common: 'Ilhas Cook' },
    rus: { official: 'острова Кука', common: 'Острова Кука' },
    spa: { official: 'Islas Cook', common: 'Islas Cook' },
    fin: { official: 'Cookinsaaret', common: 'Cookinsaaret' },
  },
  latlng: [-21.23333333, -159.76666666],
  demonym: 'Cook Islander',
  landlocked: false,
  borders: [],
  area: 236,
} as const;
