import { api } from '@api';
import { Scene } from '@models';

import { openModal } from '../open-modal';

/**
 * Open Create Scene modal.
 *
 * @return Modal promise.
 */
export async function create() {
  const modalOptions = {
    title: 'CREATE SCENE',
    component: () => import('./CreateScene.vue'),
  };

  await openModal.safe(modalOptions);
}

/**
 * ...
 */
export interface EditSceneModalOptions {
  sceneId: Scene['id'];
}

/**
 * Open Edit Scene modal.
 *
 * @return Modal promise.
 */
export async function edit(options: EditSceneModalOptions) {
  const scene = await api.scenes.get(options);

  const modalOptions = {
    title: 'EDIT SCENE',
    props: { scene },
    component: () => import('./EditScene.vue'),
  };

  await openModal.safe(modalOptions);
}
