var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-card",
    { staticClass: "add-on-card", on: { click: _setup.showDetails } },
    [
      _c(
        "b-card-text",
        {
          staticClass:
            "text-center d-flex justify-content-between align-items-center",
        },
        [
          _c(
            _setup.DisplayPrice,
            _vm._b(
              { attrs: { size: "2.5rem" } },
              "DisplayPrice",
              { price: _setup.price },
              false
            )
          ),
          _vm._v(" "),
          _c(
            "span",
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "secondary" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _setup.showDetails.apply(null, arguments)
                    },
                  },
                },
                [_c("Icon", { attrs: { icon: _setup.faInfo } })],
                1
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { variant: "primary", disabled: !_setup.isAddable },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _setup.addToCart.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n        Add\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _setup.price > 0 && !_setup.isCertification
        ? _c("p", { staticClass: "disclaimer" }, [
            _c("i", [_vm._v("One-time fee")]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", {
        staticClass: "add-on-image",
        style: { backgroundImage: `url('${_setup.image}')` },
      }),
      _vm._v(" "),
      _c("b-card-title", { staticClass: "text-center" }, [
        _vm._v("\n    " + _vm._s(_setup.title) + "\n  "),
      ]),
      _vm._v(" "),
      _c("b-card-text", { staticClass: "text-center" }, [
        _c("span", {
          staticClass: "pre-wrap",
          domProps: { innerHTML: _vm._s(_setup.description) },
        }),
      ]),
      _vm._v(" "),
      _setup.productTagsPresent
        ? _c("div", { staticClass: "product-tags-container" }, [
            _vm.product.recommended
              ? _c("span", { staticClass: "recommended-tag" }, [
                  _vm._v("\n      RECOMMENDED\n    "),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.product.onSale
              ? _c("span", { staticClass: "sale-tag" }, [_vm._v(" ON SALE! ")])
              : _vm._e(),
            _vm._v(" "),
            _vm.product.owned
              ? _c("span", { staticClass: "owned-tag" }, [_vm._v(" OWNED ")])
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }