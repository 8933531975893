var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "Transition",
    {
      attrs: { appear: "" },
      on: {
        "before-enter": _setup.onBeforeEnter,
        "before-leave": _setup.onBeforeLeave,
      },
    },
    [
      _c(
        "div",
        _vm._b(
          { staticClass: "overlay" },
          "div",
          { style: _setup.style },
          false
        ),
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }