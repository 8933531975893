export const CUB = {
  name: {
    common: 'Cuba',
    official: 'Republic of Cuba',
    native: { spa: { official: 'República de Cuba', common: 'Cuba' } },
  },
  cities: [
    'Havana',
    'Habana',
    'La Habana',
    'Matanzas',
    'Villa',
    'Bayamo',
    'Cienfuegos',
    'Santiago de Cuba',
    'HolguÃ­n',
    'Ciego de Ãvila',
    'Pinar del RÃ­o',
    'Sancti SpÃ­ritus',
    'CamagÃ¼ey',
    'Las Tunas',
    'GuantÃ¡namo',
    'Varadero',
  ],
  tld: ['.cu'],
  cca2: 'CU',
  ccn3: '192',
  cca3: 'CUB',
  cioc: 'CUB',
  currency: ['CUC', 'CUP'],
  callingCode: ['53'],
  capital: 'Havana',
  altSpellings: ['CU', 'Republic of Cuba', 'República de Cuba'],
  region: 'Americas',
  subregion: 'Caribbean',
  languages: { spa: 'Spanish' },
  translations: {
    cym: { official: 'Republic of Cuba', common: 'Ciwba' },
    deu: { official: 'Republik Kuba', common: 'Kuba' },
    fra: { official: 'République de Cuba', common: 'Cuba' },
    hrv: { official: 'Republika Kuba', common: 'Kuba' },
    ita: { official: 'Repubblica di Cuba', common: 'Cuba' },
    jpn: { official: 'キューバ共和国', common: 'キューバ' },
    nld: { official: 'Republiek Cuba', common: 'Cuba' },
    por: { official: 'República de Cuba', common: 'Cuba' },
    rus: { official: 'Республика Куба', common: 'Куба' },
    spa: { official: 'República de Cuba', common: 'Cuba' },
    fin: { official: 'Kuuban tasavalta', common: 'Kuuba' },
  },
  latlng: [21.5, -80],
  demonym: 'Cuban',
  landlocked: false,
  borders: [],
  area: 109884,
  provinces: [
    'Camaguey',
    'Ciego de Avila',
    'Cienfuegos',
    'Ciudad de La Habana',
    'Granma',
    'Guantanamo',
    'Holguin',
    'Isla de la Juventud',
    'La Habana',
    'Las Tunas',
    'Matanzas',
    'Pinar del Rio',
    'Sancti Spiritus',
    'Santiago de Cuba',
    'Villa Clara',
  ],
  nativeName: 'Cuba',
  timezones: ['UTC−05:00'],
} as const;
