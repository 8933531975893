declare global {
  namespace ZephyrWeb {
    export { RoleInfo, RoleId };
  }
}

/** Zephyr role ID. */
export enum RoleId {
  /** Zephyr `Subscriber` role ID. */
  Subscriber = 1,
  /** Zephyr `Independent Operator` role ID. */
  IndependentOperator = 2,
  /** Zephyr `Student` role ID. */
  Student = 3,
  /** Zephyr `Instructor` role ID. */
  Instructor = 4,
  /** Zephyr `Institution Admin` role ID. */
  InstitutionAdmin = 5,
  /** Zephyr `Reseller` role ID. */
  Reseller = 7,
  /** Zephyr `Reviewer` role ID. */
  Reviewer = 8,
  /** Zephyr `LAS Admin` role ID. */
  LasAdmin = 9,
}

export interface RoleInfo {
  id: number;
  key: string;
  name: string;
}

export const ZEPHYR_ROLE_LIST = [
  {
    id: RoleId.Subscriber,
    key: 'subscriber',
    name: 'Subscriber',
  },
  {
    id: RoleId.IndependentOperator,
    key: 'independentOperator',
    name: 'Independent Operator',
  },
  {
    id: RoleId.Student,
    key: 'student',
    name: 'Student',
  },
  {
    id: RoleId.Instructor,
    key: 'instructor',
    name: 'Instructor',
  },
  {
    id: RoleId.InstitutionAdmin,
    key: 'admin',
    name: 'Institution Admin',
  },
  {
    id: RoleId.Reseller,
    key: 'reseller',
    name: 'Reseller',
  },
  {
    id: RoleId.Reviewer,
    key: 'reviewer',
    name: 'Reviewer',
  },
  {
    id: RoleId.LasAdmin,
    key: 'lasadmin',
    name: 'LAS Admin',
  },
] satisfies ZephyrWeb.RoleInfo[];

/** ... */
export const roles = ZEPHYR_ROLE_LIST;
