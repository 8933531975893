var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "Transition",
    {
      attrs: { appear: "" },
      on: {
        "before-enter": _setup.onBeforeEnter,
        "after-enter": _setup.onAfterEnter,
        "after-leave": _setup.onAfterLeave,
      },
    },
    [
      _c(
        "div",
        {
          ref: "root",
          class: ["uib-modal", ..._vm.classList],
          style: {
            transitionDuration: _setup.transitionDuration,
            zIndex: _setup.zIndex,
          },
          attrs: { role: "dialog", tabindex: "-1" },
          on: { click: _setup.backdropClicked },
        },
        [
          _c(
            _setup.ModalDialog,
            _vm._b(
              { on: { close: _setup.close, dismiss: _setup.dismiss } },
              "ModalDialog",
              _setup.modalDialogProps,
              false
            )
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }