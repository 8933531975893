import { server } from '@api/request';
import * as models from '@models';
import { isObject } from '@tools/type-guards';

/**
 * ...
 */
export interface ListByUserOptions {
  organizationId: models.Organization['id'];
  userId: models.User['id'];
}

/**
 * ...
 *
 * @param options Request options bag.
 * @return A list of user assignments.
 */
export async function listByUser(options: ListByUserOptions) {
  return await server.list(
    `v2/organizations/${options.organizationId}/users/${options.userId}/assignments`,
    processUserAssignmentData,
  );
}

/**
 * ...
 */
export interface ListByOrganizationAssignmentOptions {
  organizationId: models.Organization['id'];
  assignmentId: models.Assignment['id'];
}

/**
 * ...
 *
 * @param options Request options bag.
 * @return A list of user assignments.
 */
export async function listByOrganizationAssignment(
  options: ListByOrganizationAssignmentOptions,
) {
  return await server.list(
    `v2/organizations/${options.organizationId}/assignments/${options.assignmentId}/user-assignments`,
    processUserAssignmentData,
  );
}

//#region Helper Functions

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
function isValidUserAssignmentData(
  value: unknown,
): value is models.UserAssignment {
  if (!isObject(value)) return false;

  // TEMP: Use a more strict validation.

  return true;
}

/**
 * ...
 *
 * @param data ...
 * @return ...
 */
function processUserAssignmentData(data: unknown) {
  if (!isValidUserAssignmentData(data)) {
    throw new Error('Invalid user assignment data item.');
  }

  return { ...data } as models.UserAssignment;
}

//#endregion Helper Functions
