var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("pre", { ref: "preEl", staticClass: "json-view" }, [
    _vm._v("  "),
    _vm._v("\n"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }