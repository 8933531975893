export const QAT = {
  name: {
    common: 'Qatar',
    official: 'State of Qatar',
    native: { ara: { official: 'دولة قطر', common: 'قطر' } },
  },
  cities: ['Doha', 'Al Wakrah', 'Ar Rayyan'],
  tld: ['.qa', 'قطر.'],
  cca2: 'QA',
  ccn3: '634',
  cca3: 'QAT',
  cioc: 'QAT',
  currency: ['QAR'],
  callingCode: ['974'],
  capital: 'Doha',
  altSpellings: ['QA', 'State of Qatar', 'Dawlat Qaṭar'],
  region: 'Asia',
  subregion: 'Western Asia',
  languages: { ara: 'Arabic' },
  translations: {
    deu: { official: 'Staat Katar', common: 'Katar' },
    fra: { official: 'État du Qatar', common: 'Qatar' },
    hrv: { official: 'Država Katar', common: 'Katar' },
    ita: { official: 'Stato del Qatar', common: 'Qatar' },
    jpn: { official: 'カタール国', common: 'カタール' },
    nld: { official: 'Staat Qatar', common: 'Qatar' },
    por: { official: 'Estado do Qatar', common: 'Catar' },
    rus: { official: 'Государство Катар', common: 'Катар' },
    spa: { official: 'Estado de Qatar', common: 'Catar' },
    fin: { official: 'Qatarin valtio', common: 'Qatar' },
  },
  latlng: [25.5, 51.25],
  demonym: 'Qatari',
  landlocked: false,
  borders: ['SAU'],
  area: 11586,
  provinces: [
    'Ad Dawhah',
    'Al Ghuwayriyah',
    'Al Jumayliyah',
    'Al Khawr',
    'Al Wakrah',
    'Ar Rayyan',
    'Jarayan al Batinah',
    'Madinat ash Shamal',
    'Umm Salal',
  ],
  nativeName: 'قطر',
  timezones: ['UTC+03:00'],
} as const;
