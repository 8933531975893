var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("Transition", { attrs: { appear: "" } }, [
    _setup.active
      ? _c(
          "div",
          {
            directives: [
              {
                name: "on-clickaway",
                rawName: "v-on-clickaway",
                value: _setup.close,
                expression: "close",
              },
            ],
            staticClass: "nav-dropdown",
            style: _setup.rootStyle,
          },
          [
            "header" in _setup.slots
              ? _c(
                  "div",
                  { staticClass: "nav-dropdown-header" },
                  [_vm._t("header")],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "nav-dropdown-body", style: _setup.bodyStyle },
              [_vm._t("default")],
              2
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }