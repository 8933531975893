export const MAF = {
  name: {
    common: 'Saint Martin',
    official: 'Saint Martin',
    native: { fra: { official: 'Saint-Martin', common: 'Saint-Martin' } },
  },
  cities: ['Marigot', 'Anse Marcel'],
  tld: ['.fr', '.gp'],
  cca2: 'MF',
  ccn3: '663',
  cca3: 'MAF',
  cioc: '',
  currency: ['EUR'],
  callingCode: ['590'],
  capital: 'Marigot',
  altSpellings: [
    'MF',
    'Collectivity of Saint Martin',
    'Collectivité de Saint-Martin',
    'Saint Martin (French part)',
  ],
  region: 'Americas',
  subregion: 'Caribbean',
  languages: { fra: 'French' },
  translations: {
    deu: { official: 'St. Martin', common: 'Saint Martin' },
    fra: { official: 'Saint-Martin', common: 'Saint-Martin' },
    hrv: { official: 'Saint Martin', common: 'Sveti Martin' },
    ita: { official: 'saint Martin', common: 'Saint Martin' },
    jpn: { official: 'サンマルタン島', common: 'サン・マルタン（フランス領）' },
    nld: { official: 'Saint Martin', common: 'Saint-Martin' },
    por: { official: 'saint Martin', common: 'São Martinho' },
    rus: { official: 'Сен-Мартен', common: 'Сен-Мартен' },
    spa: { official: 'Saint Martin', common: 'Saint Martin' },
    fin: { official: 'Saint-Martin', common: 'Saint-Martin' },
  },
  latlng: [18.08333333, -63.95],
  demonym: 'Saint Martin Islander',
  landlocked: false,
  borders: ['SXM'],
  area: 53,
} as const;
