export const THA = {
  name: {
    common: 'Thailand',
    official: 'Kingdom of Thailand',
    native: { tha: { official: 'ราชอาณาจักรไทย', common: 'ประเทศไทย' } },
  },
  cities: [
    'Bangkok',
    'Chumphon',
    'Kathu',
    'Phang Khon',
    'Sakon Nakhon',
    'Mueang Nonthaburi',
    'Kalasin',
    'Chon Buri',
    'Loei',
    'Khon Kaen',
    'Nong Bua Lamphu',
    'Roi Et',
    'Udon Thani',
    'Kumphawapi',
    'Kanchanaburi',
    'Nong Khai',
    'Ayutthaya',
    'Chiang Mai',
    'Songkhla',
    'Chiang Rai',
    'Surin',
    'Thanyaburi',
    'Wiphawadi',
    'Phuket',
    'Sing Buri',
    'Satun',
    'Prachin Buri',
    'Ubon Ratchathani',
    'Pattaya',
    'Yala',
    'Bang Na',
    'Samut Songkhram',
    'Phetchabun',
    'Min Buri',
    'Ratchaburi',
    'Lampang',
    'Narathiwat',
    'New Sukhothai',
    'Lopburi',
    'Uttaradit',
    'Maha Sarakham',
    'Mae Hong Son',
    'Suphan Buri',
    'Chachoengsao',
    'Samut Sakhon',
    'Phrae',
    'Din Daeng',
    'Pathum Wan',
    'Phayao',
    'Trang',
    'Mukdahan',
    'Phetchaburi',
    'Uthai Thani',
    'Krabi',
    'Phichit',
    'Phitsanulok',
    'Ban Pat Mon',
    'Prachuap Khiri Khan',
    'Ban Khlong Prasong',
    'Yasothon',
    'Ranong',
    'Lamphun',
    'Nong Bua',
    'Amnat Charoen',
    'Ban Phichit',
    'Bang Khae',
    'Thon Buri',
    'Ban Tham',
    'Sam Sen',
    'Ang Thong',
    'Mueang Samut Prakan',
    'Sa Kaeo',
    'Pathum Thani',
    'Chanthaburi',
    'Huai Khwang',
    'Rayong',
    'Sattahip',
    'Phan',
    'Si Racha',
    'Phatthalung',
    'Rawai',
    'Buriram',
    'Dusit',
    'Khlong Luang',
    'Trat',
    'Ban Bueng',
    'Sung Noen',
    'Manorom',
    'Ban Bang Plong',
    'Tak',
    'Ban Tha Duea',
    'Amphawa',
    'Ban Pong Lang',
    'Phaya Thai',
    'Si Sa Ket',
    'Nakhon Ratchasima',
    'Bangkok',
    'Bang Phlat',
    'Ban Bang Phli Nakhon',
    'Salaya',
    'Krathum Baen',
    'Hua Hin',
    'Ban Talat Rangsit',
    'Ban Khlong Ngae',
    'Nong Prue',
    'Wang Thonglang',
    'Samphanthawong',
    'Bang Khun Thian',
    'Chatuchak',
    'Chaiyaphum',
    'Nakhon Pathom',
    'Nan',
    'Bang Kruai',
    'Sathon',
    'Suan Luang',
    'Ban Wang Yai',
    'Ban Khlong Prawet',
    'Khlong San',
    'Watthana',
    'Lat Krabang',
    'Muak Lek',
    'Kosum Phisai',
    'Ban Phlam',
    'Non Thai',
    'Photharam',
    'Thalang',
    'Bang Kapi',
    'Long',
    'Ka Bang',
    'Pattani',
    'Nakhon Si Thammarat',
    'Khlong Toei',
    'Cha-am',
    'Amphoe Aranyaprathet',
    'Phang Nga',
    'Ban Tha Ruea',
    'Chiang Muan',
    'Ban Ang Thong',
    'Ban Khlong Takhian',
    'Khan Na Yao',
    'Bang Sue',
    'Sam Khok',
    'Don Mueang',
    'Phachi',
    'Ban Pratunam Tha Khai',
    'Sena',
    'Prakanong',
    'Ban Tha Pai',
    'Bang Lamung',
    'Nakhon Sawan',
    'San Sai',
    'Kamphaeng Phet',
    'Pak Kret',
    'Hat Yai',
    'Ban Nam Hak',
    'Khlung',
    'Makkasan',
    'Bang Sao Thong',
    'Ban Hua Thale',
    'Klaeng',
    'Chulabhorn',
    'Ban Don Sak',
    'Phanna Nikhom',
    'Ban Na',
    'Ban Ko Pao',
    'Mae Sot',
  ],
  tld: ['.th', '.ไทย'],
  cca2: 'TH',
  ccn3: '764',
  cca3: 'THA',
  cioc: 'THA',
  currency: ['THB'],
  callingCode: ['66'],
  capital: 'Bangkok',
  altSpellings: [
    'TH',
    'Prathet',
    'Thai',
    'Kingdom of Thailand',
    'ราชอาณาจักรไทย',
    'Ratcha Anachak Thai',
  ],
  region: 'Asia',
  subregion: 'South-Eastern Asia',
  languages: { tha: 'Thai' },
  translations: {
    deu: { official: 'Königreich Thailand', common: 'Thailand' },
    fra: { official: 'Royaume de Thaïlande', common: 'Thaïlande' },
    hrv: { official: 'Kraljevina Tajland', common: 'Tajland' },
    ita: { official: 'Regno di Thailandia', common: 'Tailandia' },
    jpn: { official: 'タイ王国', common: 'タイ' },
    nld: { official: 'Koninkrijk Thailand', common: 'Thailand' },
    por: { official: 'Reino da Tailândia', common: 'Tailândia' },
    rus: { official: 'Королевство Таиланд', common: 'Таиланд' },
    spa: { official: 'Reino de Tailandia', common: 'Tailandia' },
    fin: { official: 'Thaimaan kuningaskunta', common: 'Thaimaa' },
  },
  latlng: [15, 100],
  demonym: 'Thai',
  landlocked: false,
  borders: ['MMR', 'KHM', 'LAO', 'MYS'],
  area: 513120,
  provinces: [
    'Amnat Charoen',
    'Ang Thong',
    'Buriram',
    'Chachoengsao',
    'Chai Nat',
    'Chaiyaphum',
    'Chanthaburi',
    'Chiang Mai',
    'Chiang Rai',
    'Chon Buri',
    'Chumphon',
    'Kalasin',
    'Kamphaeng Phet',
    'Kanchanaburi',
    'Khon Kaen',
    'Krabi',
    'Krung Thep Mahanakhon (Bangkok)',
    'Lampang',
    'Lamphun',
    'Loei',
    'Lop Buri',
    'Mae Hong Son',
    'Maha Sarakham',
    'Mukdahan',
    'Nakhon Nayok',
    'Nakhon Pathom',
    'Nakhon Phanom',
    'Nakhon Ratchasima',
    'Nakhon Sawan',
    'Nakhon Si Thammarat',
    'Nan',
    'Narathiwat',
    'Nong Bua Lamphu',
    'Nong Khai',
    'Nonthaburi',
    'Pathum Thani',
    'Pattani',
    'Phangnga',
    'Phatthalung',
    'Phayao',
    'Phetchabun',
    'Phetchaburi',
    'Phichit',
    'Phitsanulok',
    'Phra Nakhon Si Ayutthaya',
    'Phrae',
    'Phuket',
    'Prachin Buri',
    'Prachuap Khiri Khan',
    'Ranong',
    'Ratchaburi',
    'Rayong',
    'Roi Et',
    'Sa Kaeo',
    'Sakon Nakhon',
    'Samut Prakan',
    'Samut Sakhon',
    'Samut Songkhram',
    'Sara Buri',
    'Satun',
    'Sing Buri',
    'Sisaket',
    'Songkhla',
    'Sukhothai',
    'Suphan Buri',
    'Surat Thani',
    'Surin',
    'Tak',
    'Trang',
    'Trat',
    'Ubon Ratchathani',
    'Udon Thani',
    'Uthai Thani',
    'Uttaradit',
    'Yala',
    'Yasothon',
  ],
  nativeName: 'ประเทศไทย',
  timezones: ['UTC+07:00'],
} as const;
