export const KHM = {
  name: {
    common: 'Cambodia',
    official: 'Kingdom of Cambodia',
    native: { khm: { official: 'ព្រះរាជាណាចក្រកម្ពុជា', common: 'Kâmpŭchéa' } },
  },
  cities: [
    'Phnom Penh',
    'Phumi Preah Haoh',
    'Phumi Siem Reab',
    'Phumi Vott Phnum',
    'Sihanoukville',
    'Phumi Boeng (1)',
    'Tuol Kok',
    'Phumi Thnal',
    'Moung Roessei',
    'Phumi Prek Mrinh',
    'Vott Kampong Svay',
    'Phumi Thmei',
    'Phumi Prei',
    'Phumi Chhuk',
  ],
  tld: ['.kh'],
  cca2: 'KH',
  ccn3: '116',
  cca3: 'KHM',
  cioc: 'CAM',
  currency: ['KHR'],
  callingCode: ['855'],
  capital: 'Phnom Penh',
  altSpellings: ['KH', 'Kingdom of Cambodia'],
  region: 'Asia',
  subregion: 'South-Eastern Asia',
  languages: { khm: 'Khmer' },
  translations: {
    cym: { official: 'Kingdom of Cambodia', common: 'Cambodia' },
    deu: { official: 'Königreich Kambodscha', common: 'Kambodscha' },
    fra: { official: 'Royaume du Cambodge', common: 'Cambodge' },
    hrv: { official: 'Kraljevina Kambodža', common: 'Kambodža' },
    ita: { official: 'Regno di Cambogia', common: 'Cambogia' },
    jpn: { official: 'カンボジア王国', common: 'カンボジア' },
    nld: { official: 'Koninkrijk Cambodja', common: 'Cambodja' },
    por: { official: 'Reino do Camboja', common: 'Camboja' },
    rus: { official: 'Королевство Камбоджа', common: 'Камбоджа' },
    spa: { official: 'Reino de Camboya', common: 'Camboya' },
    fin: { official: 'Kambodžan kuningaskunta', common: 'Kambodža' },
  },
  latlng: [13, 105],
  demonym: 'Cambodian',
  landlocked: false,
  borders: ['LAO', 'THA', 'VNM'],
  area: 181035,
  provinces: [
    'Banteay Mean Cheay',
    'Batdambang',
    'Kampong Cham',
    'Kampong Chhnang',
    'Kampong Spoe',
    'Kampong Thum',
    'Kampot',
    'Kandal',
    'Kaoh Kong',
    'Keb',
    'Kracheh',
    'Mondol Kiri',
    'Otdar Mean Cheay',
    'Pailin',
    'Phnum Penh',
    'Pouthisat',
    'Preah Seihanu (Sihanoukville)',
    'Preah Vihear',
    'Prey Veng',
    'Rotanah Kiri',
    'Siem Reab',
    'Stoeng Treng',
    'Svay Rieng',
    'Takev',
  ],
  nativeName: 'Kâmpŭchéa',
  timezones: ['UTC+07:00'],
} as const;
