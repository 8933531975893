export const NIC = {
  name: {
    common: 'Nicaragua',
    official: 'Republic of Nicaragua',
    native: {
      spa: { official: 'República de Nicaragua', common: 'Nicaragua' },
    },
  },
  cities: [
    'Managua',
    'LeÃ³n',
    'Los Arados',
    'Rivas',
    'Ocotal',
    'San Juan del Sur',
    'Granada',
    'Bluefields',
    'Chinandega',
    'Esteli',
    'Masaya',
    'Matagalpa',
    'Jinotega',
    'El Panama',
  ],
  tld: ['.ni'],
  cca2: 'NI',
  ccn3: '558',
  cca3: 'NIC',
  cioc: 'NCA',
  currency: ['NIO'],
  callingCode: ['505'],
  capital: 'Managua',
  altSpellings: ['NI', 'Republic of Nicaragua', 'República de Nicaragua'],
  region: 'Americas',
  subregion: 'Central America',
  languages: { spa: 'Spanish' },
  translations: {
    deu: { official: 'Republik Nicaragua', common: 'Nicaragua' },
    fra: { official: 'République du Nicaragua', common: 'Nicaragua' },
    hrv: { official: 'Republika Nikaragva', common: 'Nikaragva' },
    ita: { official: 'Repubblica del Nicaragua', common: 'Nicaragua' },
    jpn: { official: 'ニカラグア共和国', common: 'ニカラグア' },
    nld: { official: 'Republiek Nicaragua', common: 'Nicaragua' },
    por: { official: 'República da Nicarágua', common: 'Nicarágua' },
    rus: { official: 'Республика Никарагуа', common: 'Никарагуа' },
    spa: { official: 'República de Nicaragua', common: 'Nicaragua' },
    fin: { official: 'Nicaraguan tasavalta', common: 'Nicaragua' },
  },
  latlng: [13, -85],
  demonym: 'Nicaraguan',
  landlocked: false,
  borders: ['CRI', 'HND'],
  area: 130373,
  provinces: [
    'Atlantico Norte',
    'Atlantico Sur',
    'Boaco',
    'Carazo',
    'Chinandega',
    'Chontales',
    'Esteli',
    'Granada',
    'Jinotega',
    'Leon',
    'Madriz',
    'Managua',
    'Masaya',
    'Matagalpa',
    'Nueva Segovia',
    'Rio San Juan',
    'Rivas',
  ],
  nativeName: 'Nicaragua',
  timezones: ['UTC−06:00'],
} as const;
