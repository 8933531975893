var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vue-notification-group", style: _setup.styles },
    [
      _c(
        "TransitionGroup",
        {
          attrs: { name: _vm.animationName },
          on: { "after-leave": _setup.clean },
        },
        _vm._l(_setup.active, function (item) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass: "vue-notification-wrapper",
              style: _setup.notifyWrapperStyle(item),
              attrs: { "data-id": item.id },
              on: {
                mouseenter: _setup.pauseTimeout,
                mouseleave: _setup.resumeTimeout,
              },
            },
            [
              _vm._t(
                "default",
                function () {
                  return [
                    _c(
                      "div",
                      {
                        class: _setup.notifyClass(item),
                        on: {
                          click: function ($event) {
                            return _setup.destroyIfNecessary(item)
                          },
                        },
                      },
                      [
                        item.title
                          ? _c("div", {
                              staticClass: "notification-title",
                              domProps: { innerHTML: _vm._s(item.title) },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "notification-content",
                          domProps: { innerHTML: _vm._s(item.text) },
                        }),
                      ]
                    ),
                  ]
                },
                { item: item, close: () => _setup.destroy(item) }
              ),
            ],
            2
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }