import { DirectiveOptions } from 'vue';

import { isObject, isNumber, isNullish } from '@tools/type-guards';

/**
 * Vue directive for adding transition delay to elements.
 */
const transitionDelay: DirectiveOptions = {
  bind(el, { value, modifiers }) {
    let delay: unknown = 0.5;
    let index: unknown = 0;
    let spacing: unknown = 0.02;

    if (modifiers.group) {
      if (!isObject(value)) throw new Error('Invalid value supplied.');

      delay = value.delay ?? 0;
      index = value.index ?? null;
      spacing = value.spacing ?? spacing;
    } else if (!isNullish(value)) {
      delay = value;
    }

    if (!isNumber(delay)) {
      throw new Error('Invalid value for "delay" supplied.');
    }

    if (!isNumber(index)) {
      throw new Error('Invalid value for "index" supplied.');
    }

    if (!isNumber(spacing)) {
      throw new Error('Invalid value for "spacing" supplied.');
    }

    const result = delay + index * spacing;

    el.style.transitionDelay = `${result}s`;
  },
};

export default transitionDelay;
