export const MCO = {
  name: {
    common: 'Monaco',
    official: 'Principality of Monaco',
    native: { fra: { official: 'Principauté de Monaco', common: 'Monaco' } },
  },
  cities: ['Monaco', 'Monte Carlo'],
  tld: ['.mc'],
  cca2: 'MC',
  ccn3: '492',
  cca3: 'MCO',
  cioc: 'MON',
  currency: ['EUR'],
  callingCode: ['377'],
  capital: 'Monaco',
  altSpellings: ['MC', 'Principality of Monaco', 'Principauté de Monaco'],
  region: 'Europe',
  subregion: 'Western Europe',
  languages: { fra: 'French' },
  translations: {
    deu: { official: 'Fürstentum Monaco', common: 'Monaco' },
    fra: { official: 'Principauté de Monaco', common: 'Monaco' },
    hrv: { official: 'Kneževina Monako', common: 'Monako' },
    ita: { official: 'Principato di Monaco', common: 'Principato di Monaco' },
    jpn: { official: 'モナコ公国', common: 'モナコ' },
    nld: { official: 'Vorstendom Monaco', common: 'Monaco' },
    por: { official: 'Principado do Mónaco', common: 'Mónaco' },
    rus: { official: 'Княжество Монако', common: 'Монако' },
    spa: { official: 'Principado de Mónaco', common: 'Mónaco' },
    fin: { official: 'Monacon ruhtinaskunta', common: 'Monaco' },
  },
  latlng: [43.73333333, 7.4],
  demonym: 'Monegasque',
  landlocked: false,
  borders: ['FRA'],
  area: 2.02,
} as const;
