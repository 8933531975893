import { Module } from '@vuex/decorators';
import { TablePanel } from 'table-panel';

import { auth, RoleId } from '@auth';
import { User, Certification } from '@models';
import { modals } from '@modals';
import { printTable } from '@services/print-table';
import { store } from '@store';
import { nameFormat } from '@utils/name-format';

import { faEye } from '@icons/solid/faEye';
import { faEdit } from '@icons/solid/faEdit';
import { faChalkboard } from '@icons/solid/faChalkboard';
import { faClipboardListCheck } from '@icons/solid/faClipboardListCheck';
import { faBox } from '@icons/solid/faBox';
import { faIdCardAlt } from '@icons/solid/faIdCardAlt';
import { faBadgeCheck } from '@fortawesome/pro-solid-svg-icons/faBadgeCheck';
import { faSealExclamation } from '@icons/solid/faSealExclamation';

const columns: TablePanel.Column<User>[] = [
  {
    key: 'name',
    label: 'Name',
    value: (item) => nameFormat(item, { ignoreEmail: true }),
  },
  {
    key: 'email',
    label: 'Email',
    value: 'email',
    component: 'TableCellUserEmail',
  },
  {
    key: 'certifications',
    label: 'Certifications',
    value: 'certifications',
    component: 'TableCellUserCertifications',
    hidden: ({ selectedRole }) => {
      return (selectedRole?.certifications?.length ?? 0) <= 0;
    },
  },
];

const operations: TablePanel.Row.Operation<User>[] = [
  // {
  //   label: 'Upgrade Student',
  //   icon: faEdit,
  //   fn: (user) => {
  //     // ngModals.user.edit({ user });
  //   },
  //   hidden: ({ studentUpgradable }) => !studentUpgradable,
  // },
  {
    label: 'View',
    icon: faEye,
    fn: (user) => {
      void modals.user.details({ user });
    },
    hidden: () => {
      return auth.isActiveRole(RoleId.LasAdmin);
    },
  },
  {
    label: 'Edit',
    icon: faEdit,
    fn: (user) => {
      void modals.user.edit({ user });
    },
    hidden: () => {
      return !auth.isActiveRole(RoleId.LasAdmin);
    },
  },
  {
    label: 'View Courses',
    icon: faChalkboard,
    fn: ({ id }) => {
      void modals.user.courses({ userId: id });
    },
  },
  {
    label: 'View Reports',
    icon: faClipboardListCheck,
    fn: (user) => {
      void modals.user.reports({ user });
    },
    hidden: () => {
      return auth.isActiveRole(
        RoleId.Instructor,
        RoleId.InstitutionAdmin,
        RoleId.LasAdmin,
      );
    },
  },
  {
    label: 'View Orders',
    icon: faBox,
    fn: ({ id }) => {
      void modals.user.orders({ userId: id });
    },
    hidden: () => {
      return !auth.isActiveRole(RoleId.LasAdmin);
    },
  },
  {
    label: 'View Roles',
    icon: faIdCardAlt,
    fn: ({ id }) => {
      void modals.user.roles({ userId: id });
    },
  },
  {
    label: 'Remove Student from Institution',
    icon: faIdCardAlt,
    fn: ({ id }) => {
      void modals.user.removeStudentFromInstitution({ userId: id });
    },
    // TODO: `User` data objects are no longer guaranteed to have `roles`
    // property -- find another way to check if user is a student.
    hidden: ({ roles }) => {
      return (
        !(roles ?? []).some(({ roleId }) => roleId === RoleId.Student) ||
        auth.isActiveRole(RoleId.InstitutionAdmin, RoleId.LasAdmin)
      );
    },
  },
  {
    label: 'APPROVE Certification',
    icon: faBadgeCheck,
    variant: 'success',
    fn: (item) => {
      void modals.certifications.quickReviewCertification({
        status: 'APPROVED',
        certifications: item.certifications as Certification[],
      });
    },
    hidden: ({ certifications }) => {
      return !certifications?.some(
        (c) =>
          c.status === 'AWAITING_ATTEMPT' ||
          c.status === 'AWAITING_REATTEMPT' ||
          c.status === 'AWAITING_REVIEW' ||
          c.status === 'REVIEWING',
      );
    },
  },
  {
    label: 'DENY Certification',
    icon: faSealExclamation,
    variant: 'warning',
    fn: (item) => {
      void modals.certifications.quickReviewCertification({
        status: 'DENIED',
        certifications: item.certifications as Certification[],
      });
    },
    hidden: ({ certifications }) => {
      return !certifications?.some(
        (c) =>
          c.status === 'AWAITING_ATTEMPT' ||
          c.status === 'AWAITING_REATTEMPT' ||
          c.status === 'AWAITING_REVIEW' ||
          c.status === 'REVIEWING',
      );
    },
  },
  {
    label: 'REVOKE Certification',
    icon: faSealExclamation,
    variant: 'danger',
    fn: (item) => {
      void modals.certifications.quickReviewCertification({
        status: 'REVOKED',
        certifications: item.certifications as Certification[],
      });
    },
    hidden: ({ certifications }) => {
      return !certifications?.some(
        (c) =>
          c.status === 'AWAITING_ATTEMPT' ||
          c.status === 'AWAITING_REATTEMPT' ||
          c.status === 'AWAITING_REVIEW' ||
          c.status === 'REVIEWING' ||
          c.status === 'CURRENT',
      );
    },
  },
];

@Module({ namespaced: true })
export class CourseStudentsTablePanel
  extends TablePanel<User>
  implements TablePanel.Props<User>
{
  onPageChanged?: TablePanel.OnPageChangedCallback<User>;
  readonly module = 'users';
  readonly loadAction = 'searchByCourse';
  readonly pk = 'id';
  readonly label = 'STUDENTS';
  readonly columns = columns;
  readonly operations = operations;

  readonly filterTags?: TablePanel.FilterTag[];
  readonly filterParams?: TablePanel.FilterParam[];
  readonly infoMessage?: string | null;

  readonly enabled = true;
  readonly progressive = false;

  get tableMenu() {
    const items: TablePanel.MenuItem<User>[] = [];

    items.push({
      key: 'invite',
      label: 'Invite',
      click: () => void modals.user.inviteStudent(),
    });

    const isInstructorOrAdmin = auth.isActiveRole(
      RoleId.LasAdmin,
      RoleId.InstitutionAdmin,
      RoleId.Instructor,
    );

    const hasCertificationPrivileges =
      (auth.activeRole?.certifications?.length ?? 0) > 0;

    if (isInstructorOrAdmin && hasCertificationPrivileges) {
      items.push(
        {
          key: 'issueCertificationExam',
          label: 'Issue Certification Exam',
          // TODO: Eric - disable this menu option if the user does not have
          // access to the certification exams.
          disabled: ({ selection }) => selection.length <= 0,
          click: ({ selection }) => {
            void modals.certifications.issueCertificationExamToStudents({
              students: selection as User.BasicInfo[],
            });
          },
        },
        {
          key: 'bulkReviewCertificationExam',
          label: 'Bulk Review Certification Exam',
          disabled: ({ selection }) => selection.length <= 0,
          click: ({ selection }) => {
            void modals.certifications.bulkReviewCertification({
              users: selection,
              status: 'APPROVED',
            });
          },
        },
      );
    }

    if (auth.isActiveRole(RoleId.LasAdmin)) {
      items.push(
        {
          key: 'loadReports',
          label: 'Load Reports',
          disabled: this.loadingMetrics,
          click: () => {
            void store.dispatch('users/computeMetrics');
          },
        },
        {
          key: 'print',
          label: 'Print',
          disabled: this.loadingMetrics,
          click: () => printTable.fromTableData(this.columns, this.rows),
        },
      );
    }

    return items;
  }

  private get loadingMetrics() {
    return this.context.rootState.users.loadingMetrics;
  }
}
