export const MOZ = {
  name: {
    common: 'Mozambique',
    official: 'Republic of Mozambique',
    native: {
      por: { official: 'República de Moçambique', common: 'Moçambique' },
    },
  },
  cities: [
    'Maputo',
    'Mozambique',
    'Matola',
    'Nampula',
    'Quelimane',
    'Beira',
    'Pemba',
    'Tete',
  ],
  tld: ['.mz'],
  cca2: 'MZ',
  ccn3: '508',
  cca3: 'MOZ',
  cioc: 'MOZ',
  currency: ['MZN'],
  callingCode: ['258'],
  capital: 'Maputo',
  altSpellings: ['MZ', 'Republic of Mozambique', 'República de Moçambique'],
  region: 'Africa',
  subregion: 'Eastern Africa',
  languages: { por: 'Portuguese' },
  translations: {
    deu: { official: 'Republik Mosambik', common: 'Mosambik' },
    fra: { official: 'République du Mozambique', common: 'Mozambique' },
    hrv: { official: 'Republika Mozambiku', common: 'Mozambik' },
    ita: { official: 'Repubblica del Mozambico', common: 'Mozambico' },
    jpn: { official: 'モザンビーク共和国', common: 'モザンビーク' },
    nld: { official: 'Republiek Mozambique', common: 'Mozambique' },
    por: { official: 'República de Moçambique', common: 'Moçambique' },
    rus: { official: 'Республика Мозамбик', common: 'Мозамбик' },
    spa: { official: 'República de Mozambique', common: 'Mozambique' },
    fin: { official: 'Mosambikin tasavalta', common: 'Mosambik' },
  },
  latlng: [-18.25, 35],
  demonym: 'Mozambican',
  landlocked: false,
  borders: ['MWI', 'ZAF', 'SWZ', 'TZA', 'ZMB', 'ZWE'],
  area: 801590,
  provinces: [
    'Cabo Delgado',
    'Gaza',
    'Inhambane',
    'Manica',
    'Maputo',
    'Nampula',
    'Niassa',
    'Sofala',
    'Tete',
    'Zambezia',
  ],
  nativeName: 'Moçambique',
  timezones: ['UTC+02:00'],
} as const;
