import { Module, Action } from '@vuex/decorators';
import { TablePanel } from 'table-panel';

import { faEye } from '@icons/solid/faEye';
import { faEdit } from '@icons/solid/faEdit';
import { faCopy } from '@icons/solid/faCopy';

import { dateFilter } from '@filters/date';
import { secToHmsFilter } from '@filters/sec-to-hms';
import { modals } from '@modals';
import { Assignment } from '@models';
import { router } from '@router';
import { auth, RoleId } from '@auth';
import { isString } from '@tools/type-guards';

const columns: TablePanel.Column<Assignment>[] = [
  {
    key: 'dueDate',
    label: 'Due Date',
    type: 'date',
    value: 'dueDate',
  },
  {
    key: 'dateRange',
    label: 'Date Range',
    value: ({ startDate, endDate }) => dateRangeFilter(startDate, endDate),
  },
  {
    key: 'minimumScore',
    label: 'Minimum Score',
    value: ({ minimumScore }) => minimumScore ?? 'N/A',
  },
  {
    key: 'totalTime',
    label: 'Total Time (hh:mm:ss)',
    value: ({ totalTime }) => (totalTime ? secToHmsFilter(totalTime) : 'N/A'),
  },
  {
    key: 'module',
    label: 'Scene/Module',
    value: ({ module, scene }) => `${scene.name} | ${module.name}`,
  },
  {
    key: 'details',
    label: 'Details',
    value: 'details',
  },
];

const operations: TablePanel.Row.Operation<Assignment>[] = [
  {
    label: 'View',
    icon: faEye,
    fn: ({ id, course, organization }) => {
      const params = {
        organizationId: organization.id.toString(),
        courseId: course.id.toString(),
        assignmentId: id.toString(),
      };

      void router.push({
        name: 'dashboardCourseAssignment',
        params,
      });
    },
  },
  {
    label: 'Edit',
    icon: faEdit,
    fn: ({ id, organization, course }) => {
      void modals.assignment.edit({
        assignmentId: id,
        organizationId: organization.id,
        courseId: course.id,
      });
    },
    hidden: () => {
      return !auth.isActiveRole(
        RoleId.Instructor,
        RoleId.InstitutionAdmin,
        RoleId.LasAdmin,
      );
    },
  },
  {
    label: 'Create Copy',
    icon: faCopy,
    fn: ({ id, organization, course }) => {
      void modals.assignment.create({
        assignmentId: id,
        organizationId: organization.id,
        courseId: course.id,
      });
    },
    hidden: () => {
      return !auth.isActiveRole(
        RoleId.Instructor,
        RoleId.InstitutionAdmin,
        RoleId.LasAdmin,
      );
    },
  },
];

@Module({ namespaced: true })
export class CourseAssignmentsTablePanel
  extends TablePanel<Assignment>
  implements TablePanel.Props<Assignment>
{
  readonly module = 'assignments';
  readonly loadAction = 'listByCourse';
  readonly pk = 'id';
  readonly label = 'ASSIGNMENTS';
  readonly columns = columns;
  readonly operations = operations;
  readonly createAction = 'createAssignment';
  readonly deleteAction = 'deleteAssignment';
  readonly tableSortType = 'dueDate';
  readonly tableSortReverse = true;

  readonly enabled = true;

  @Action
  async createAssignment() {
    // grab the ID of the course from the URL if they're on the
    // course management page

    const options = {
      organizationId: '',
      courseId: '',
    };

    const organizationId =
      this.context.rootState.me.selectedRole?.organization?.id;
    const url = window.location.pathname;
    const lastPart = url.split('/').pop();

    if (url.includes('/course/') && lastPart?.length && organizationId) {
      options.courseId = lastPart;
      options.organizationId = organizationId;
    }

    await modals.assignment.create(options);
  }

  @Action
  async deleteAssignment({ items }: AssignmentsTablePanel.DeleteItemOptions) {
    await modals.confirm.deleteItems({ itemType: 'assignments', items });
  }
}

export namespace AssignmentsTablePanel {
  /** ... */
  export interface DeleteItemOptions {
    items: Assignment[];
  }
}

//#region Helper Functions

/**
 * ...
 *
 * @param startDate ...
 * @param endDate ...
 * @return ...
 */
function dateRangeFilter(startDate: unknown, endDate: unknown) {
  if (!isString(startDate) || !isString(endDate)) return 'N/A';

  const filteredStartDate = dateFilter(startDate, 'MM/dd/yyyy').toString();
  const filteredEndDate = dateFilter(endDate, 'MM/dd/yyyy').toString();

  return `${filteredStartDate} - ${filteredEndDate}`;
}

//#endregion Helper Functions
