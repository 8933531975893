import { Module, Action } from '@vuex/decorators';
import { TablePanel } from 'table-panel';

import { faEdit } from '@icons/solid/faEdit';

import { DLC } from '@models';
import { modals } from '@modals';
import { alert } from '@services/alert';

/** ... */
const columns: TablePanel.Column<DLC>[] = [
  {
    key: 'name',
    label: 'Name',
    value: 'name',
  },
  { key: 'id', label: 'ID', value: 'id' },
  {
    key: 'os',
    label: 'OS',
    value: 'os',
    component: 'TableCellListOperatingSystems',
  },
];

/** ... */
const operations: TablePanel.Row.Operation<DLC>[] = [
  {
    label: 'Edit',
    icon: faEdit,
    fn: async ({ id, name }) => {
      const osSelection = await modals.util.selectItemFromList({
        title: 'SELECT AN OS',
        options: [
          {
            value: 'mac',
            label: 'mac',
          },
          {
            value: 'win',
            label: 'win',
          },
        ],
      });

      if (!osSelection) {
        alert.warning('Must select an OS to continue');
        return;
      }

      void modals.dlc.manage({ dlcId: id, dlcName: name, osSelection });
    },
  },
];

@Module({ namespaced: true })
export class DLCTablePanel
  extends TablePanel<DLC>
  implements TablePanel.Props<DLC>
{
  readonly module = 'dlc';
  readonly loadAction = 'list';
  readonly pk = 'id';
  readonly label = 'DLC';
  readonly columns = columns;
  readonly operations = operations;
  readonly createAction = 'createDLC';
  //   readonly deleteAction = 'deleteDLC';
  readonly enabled = true;
  readonly tableSortType = 'id';
  readonly tablePageSize = 50;

  /**
   * ...
   */
  @Action
  async createDLC() {
    const osSelection = await modals.util.selectItemFromList({
      title: 'SELECT AN OS',
      options: [
        {
          value: 'mac',
          label: 'mac',
        },
        {
          value: 'win',
          label: 'win',
        },
      ],
    });
    await modals.dlc.manage({ osSelection });

    await this.context.dispatch('load');
  }

  /**
   * ...
   */
  //   @Action
  //   async deleteDLC({ items }: DLCTablePanel.DeleteItemOptions) {
  //     await modals.confirm.deleteItems({ itemType: 'drones', items });
  //   }
}

export namespace DLCTablePanel {
  /** ... */
  export interface DeleteItemOptions {
    items: DLC[];
  }
}
