export const SVN = {
  name: {
    common: 'Slovenia',
    official: 'Republic of Slovenia',
    native: { slv: { official: 'Republika Slovenija', common: 'Slovenija' } },
  },
  cities: [
    'Velenje',
    'Ljubljana',
    'Smartno ob Paki',
    'Radovljica',
    'Begunje na Gorenjskem',
    'Sencur',
    'Bevke',
    'Kamnik',
    'Lesce',
    'Bled',
    'Å½irovnica',
    'Prebold',
    'Tisina',
    'Jesenice',
    'Bohinjska Bela',
    'Crensovci',
    'Mojstrana',
    'KoÄevje',
    'Piran',
    'SeÅ¾ana',
    'Nova Gorica',
    'Cerknica',
    'Ig',
    'Zgornje Gorje',
    'Kranj',
    'Gornja Radgona',
    'Ptuj',
    'Gorisnica',
    'Markovci',
    'Lendava',
    'Beltinci',
    'Sredisce ob Dravi',
    'Naklo',
    'Breginj',
    'LaÅ¡ko',
    'Novo Mesto',
    'Velika Loka',
    'Zgornje Hoce',
    'Prevalje',
    'Å entjur pri Celju',
    'RogaÅ¡ka Slatina',
    'Mozirje',
    'Mezica',
    'Slovenj Gradec',
    'Stahovica',
    'Murska Sobota',
    'Kranjska Gora',
    'Koper',
    'Breznica',
    'Maribor',
    'Cirkovce',
    'Pernica',
    'Krajna',
    'DomÅ¾ale',
    'Dob',
    'Dobrova',
    'Grosuplje',
    'Trbovlje',
    'Ilirska Bistrica',
    'Vrhnika',
    'Logatec',
    'Prestranek',
    'Horjul',
    'Globoko',
    'Stari Trg pri Lozu',
    'Izola',
    'Spodnji Ivanjci',
    'Hrusica',
    'Kidricevo',
    'Komenda',
    'Å entjernej',
    'Medvode',
    'KrÅ¡ko',
    'Dolenjske Toplice',
    'Pesnica pri Mariboru',
    'Poljcane',
    'RuÅ¡e',
    'Lovrenc na Dravskem Polju',
    'Miklavz na Dravskem Polju',
    'Spodnji Duplek',
    'Slovenska Bistrica',
    'Å½alec',
    'Dol pri Ljubljani',
    'Polhov Gradec',
    'Videm',
    'Pobegi',
    'AjdovÅ¡Äina',
    'Radomlje',
    'Vipava',
    'Litija',
    'Notranje Gorice',
    'Smlednik',
    'Hotedrsica',
    'Dornava',
    'Vojnik',
    'Ljubecna',
    'Å kofja Loka',
    'Zgornje Skofije',
    'Skofljica',
    'DivaÄa',
    'Kobarid',
    'Polzela',
    'Menges',
    'Stara Cerkev',
    'Kojsko',
    'Ankaran',
    'Tolmin',
    'PodÄetrtek',
    'Log pri Brezovici',
    'Ravne',
    'Trzin',
    'Sevnica',
    'Brestanica',
    'Zelezniki',
    'Grize',
    'Verzej',
    'Mirna',
    'Knezak',
    'Dekani',
    'Moravce',
    'Lenart v Slov. Goricah',
    'Limbus',
    'BreÅ¾ice',
    'Sentvid pri Sticni',
    'Borovnica',
    'Besnica',
    'Smarje-Sap',
    'Postojna',
    'Smarje pri Jelsah',
    'Slovenske Konjice',
    'Hrastnik',
    'Zagorje ob Savi',
    'Lovrenc na Pohorju',
    'Dravograd',
    'Smarje',
    'Selnica ob Dravi',
    'Bistrica pri Rusah',
    'Radlje ob Dravi',
    'Podbocje',
    'Zgornja Polskava',
    'Puconci',
    'Rocinj',
    'Dobravlje',
    'Vodice',
    'Idrija',
    'Gmajnica',
    'Ravne na Koroskem',
    'Sostanj',
    'Sempeter pri Gorici',
    'Rakek',
    'PortoroÅ¾',
    'Gorenja Vas',
    'Cemsenik',
    'Spodnji Brnik',
    'Preserje',
    'Trzic',
    'Ziri',
    'Starse',
    'Lokev',
    'Secovlje',
    'Cersak',
    'Cerklje ob Krki',
    'Spodnje Hoce',
    'Nova Cerkev',
    'Sentjanz',
    'Zidani Most',
    'Petrovce',
    'Dobrunje',
    'Miren',
  ],
  tld: ['.si'],
  cca2: 'SI',
  ccn3: '705',
  cca3: 'SVN',
  cioc: 'SLO',
  currency: ['EUR'],
  callingCode: ['386'],
  capital: 'Ljubljana',
  altSpellings: ['SI', 'Republic of Slovenia', 'Republika Slovenija'],
  region: 'Europe',
  subregion: 'Southern Europe',
  languages: { slv: 'Slovene' },
  translations: {
    deu: { official: 'Republik Slowenien', common: 'Slowenien' },
    fra: { official: 'République de Slovénie', common: 'Slovénie' },
    hrv: { official: 'Republika Slovenija', common: 'Slovenija' },
    ita: { official: 'Repubblica di Slovenia', common: 'Slovenia' },
    jpn: { official: 'スロベニア共和国', common: 'スロベニア' },
    nld: { official: 'Republiek Slovenië', common: 'Slovenië' },
    por: { official: 'República da Eslovénia', common: 'Eslovénia' },
    rus: { official: 'Республика Словения', common: 'Словения' },
    spa: { official: 'República de Eslovenia', common: 'Eslovenia' },
    fin: { official: 'Slovenian tasavalta', common: 'Slovenia' },
  },
  latlng: [46.11666666, 14.81666666],
  demonym: 'Slovene',
  landlocked: false,
  borders: ['AUT', 'HRV', 'ITA', 'HUN'],
  area: 20273,
  provinces: [
    'Ajdovscina',
    'Beltinci',
    'Bled',
    'Bohinj',
    'Borovnica',
    'Bovec',
    'Brda',
    'Brezice',
    'Brezovica',
    'Cankova-Tisina',
    'Celje',
    'Cerklje na Gorenjskem',
    'Cerknica',
    'Cerkno',
    'Crensovci',
    'Crna na Koroskem',
    'Crnomelj',
    'Destrnik-Trnovska Vas',
    'Divaca',
    'Dobrepolje',
    'Dobrova-Horjul-Polhov Gradec',
    'Dol pri Ljubljani',
    'Domzale',
    'Dornava',
    'Dravograd',
    'Duplek',
    'Gorenja Vas-Poljane',
    'Gorisnica',
    'Gornja Radgona',
    'Gornji Grad',
    'Gornji Petrovci',
    'Grosuplje',
    'Hodos Salovci',
    'Hrastnik',
    'Hrpelje-Kozina',
    'Idrija',
    'Ig',
    'Ilirska Bistrica',
    'Ivancna Gorica',
    'Izola',
    'Jesenice',
    'Jursinci',
    'Kamnik',
    'Kanal',
    'Kidricevo',
    'Kobarid',
    'Kobilje',
    'Kocevje',
    'Komen',
    'Koper',
    'Kozje',
    'Kranj',
    'Kranjska Gora',
    'Krsko',
    'Kungota',
    'Kuzma',
    'Lasko',
    'Lenart',
    'Lendava',
    'Litija',
    'Ljubljana',
    'Ljubno',
    'Ljutomer',
    'Logatec',
    'Loska Dolina',
    'Loski Potok',
    'Luce',
    'Lukovica',
    'Majsperk',
    'Maribor',
    'Medvode',
    'Menges',
    'Metlika',
    'Mezica',
    'Miren-Kostanjevica',
    'Mislinja',
    'Moravce',
    'Moravske Toplice',
    'Mozirje',
    'Murska Sobota',
    'Muta',
    'Naklo',
    'Nazarje',
    'Nova Gorica',
    'Novo Mesto',
    'Odranci',
    'Ormoz',
    'Osilnica',
    'Pesnica',
    'Piran',
    'Pivka',
    'Podcetrtek',
    'Podvelka-Ribnica',
    'Postojna',
    'Preddvor',
    'Ptuj',
    'Puconci',
    'Race-Fram',
    'Radece',
    'Radenci',
    'Radlje ob Dravi',
    'Radovljica',
    'Ravne-Prevalje',
    'Ribnica',
    'Rogasevci',
    'Rogaska Slatina',
    'Rogatec',
    'Ruse',
    'Semic',
    'Sencur',
    'Sentilj',
    'Sentjernej',
    'Sentjur pri Celju',
    'Sevnica',
    'Sezana',
    'Skocjan',
    'Skofja Loka',
    'Skofljica',
    'Slovenj Gradec',
    'Slovenska Bistrica',
    'Slovenske Konjice',
    'Smarje pri Jelsah',
    'Smartno ob Paki',
    'Sostanj',
    'Starse',
    'Store',
    'Sveti Jurij',
    'Tolmin',
    'Trbovlje',
    'Trebnje',
    'Trzic',
    'Turnisce',
    'Velenje',
    'Velike Lasce',
    'Videm',
    'Vipava',
    'Vitanje',
    'Vodice',
    'Vojnik',
    'Vrhnika',
    'Vuzenica',
    'Zagorje ob Savi',
    'Zalec',
    'Zavrc',
    'Zelezniki',
    'Ziri',
    'Zrece',
  ],
  nativeName: 'Slovenija',
  timezones: ['UTC+01:00'],
} as const;
