import {
  isBoolean,
  isString,
  isNumber,
  isBigInt,
  isFunction,
  isSymbol,
  isNull,
  isUndefined,
} from './type-guards';

/**
 * Create a stringified version of the provided value.
 *
 * @param value The value to stringify.
 * @returns The stringified value.
 */
export function stringify(value: unknown) {
  if (isString(value)) return value;

  if (isUndefined(value)) return 'undefined';

  if (isNull(value)) return 'null';

  if (
    isNumber(value) ||
    isBoolean(value) ||
    isBigInt(value) ||
    isFunction(value) ||
    isSymbol(value)
  ) {
    return value.toString();
  }

  try {
    return JSON.stringify(value) ?? '';
  } catch {
    return '';
  }
}
