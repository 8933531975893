import type { WatchOptions } from 'vue';
import VuexClass from 'vuex';
import type * as vuex from 'vuex';

export * from 'vuex';

/* eslint-disable-next-line @typescript-eslint/no-empty-interface */
export interface Getters {
  // ...
}

export declare class Store<S> extends VuexClass.Store<S> {
  readonly getters: Getters;

  dispatch: Dispatch;
  commit: Commit;
  // commit: TestCommit;

  watch<T>(
    getter: (state: S, getters: Getters) => T,
    cb: (value: T, oldValue: T) => void,
    options?: WatchOptions,
  ): () => void;
}

/* eslint-disable-next-line @typescript-eslint/no-empty-interface */
export interface DispatchMap {
  // ...
}

/* eslint-disable-next-line @typescript-eslint/no-empty-interface */
export interface Dispatch extends vuex.Dispatch {
  // eslint-disable-next-line @typescript-eslint/prefer-function-type
  <T extends keyof DispatchMap>(
    type: T,
    payload?: Parameters<DispatchMap[T]>[0],
    options?: vuex.CommitOptions,
    // ...args: Parameters<DispatchMap[T]>
  ): ReturnType<DispatchMap[T]>;
}

/* eslint-disable-next-line @typescript-eslint/no-empty-interface */
export interface CommitMap {
  // ...
}

/* eslint-disable-next-line @typescript-eslint/no-empty-interface */
export interface Commit {
  // eslint-disable-next-line @typescript-eslint/prefer-function-type
  <T extends keyof CommitMap>(
    type: T,
    payload?: Parameters<CommitMap[T]>[0],
    options?: vuex.CommitOptions,
    // ...args: Parameters<CommitMap[T]>
  ): ReturnType<CommitMap[T]>;
}

export const Vuex = VuexClass as unknown as Omit<typeof VuexClass, 'Store'> & {
  Store: typeof Store;
};

export default Vuex;

// export interface ActionContext<S, R, G = any> {
//   dispatch: Dispatch;
//   commit: Commit;
//   state: S;
//   getters: G;
//   rootState: R;
//   rootGetters: Getters;
// }

// ...
