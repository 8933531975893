import { AsyncComponent } from 'vue';

import { openModal } from '../open-modal';

/**
 * Open Create ExtensionCode modal.
 *
 * @return Modal promise.
 */
export async function create() {
  const modalOptions = {
    title: 'CREATE EXTENSION CODE',
    component: (() => import('./CreateExtensionCode.vue')) as AsyncComponent
  };

  try {
    await openModal(modalOptions);
  } catch (_) {
    return;
  }
}
