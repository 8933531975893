var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.CardLoadable,
    { attrs: { loading: _vm.loading, "no-body": "" } },
    [
      _c("b-card-header", [
        _c("h6", { staticClass: "mb-0" }, [_vm._v("Current Courses")]),
      ]),
      _vm._v(" "),
      _c(
        "b-card-body",
        { staticClass: "p-0" },
        [
          _c("b-table", {
            attrs: { fields: _setup.fields, items: _setup.currentCourses },
            scopedSlots: _vm._u([
              {
                key: "head(operations)",
                fn: function ({ label }) {
                  return [
                    _c("div", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(label)),
                    ]),
                  ]
                },
              },
              {
                key: "cell(operations)",
                fn: function ({ item }) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "icon-button",
                        on: {
                          click: function ($event) {
                            return _setup.goToCourse(item)
                          },
                        },
                      },
                      [_c("Icon", { attrs: { icon: _setup.faEye } })],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }