export const CAF = {
  name: {
    common: 'Central African Republic',
    official: 'Central African Republic',
    native: {
      fra: {
        official: 'République centrafricaine',
        common: 'République centrafricaine',
      },
      sag: { official: 'Ködörösêse tî Bêafrîka', common: 'Bêafrîka' },
    },
  },
  cities: ['Bangui'],
  tld: ['.cf'],
  cca2: 'CF',
  ccn3: '140',
  cca3: 'CAF',
  cioc: 'CAF',
  currency: ['XAF'],
  callingCode: ['236'],
  capital: 'Bangui',
  altSpellings: ['CF', 'Central African Republic', 'République centrafricaine'],
  region: 'Africa',
  subregion: 'Middle Africa',
  languages: { fra: 'French', sag: 'Sango' },
  translations: {
    cym: {
      official: 'Central African Republic',
      common: 'Gweriniaeth Canolbarth Affrica',
    },
    deu: {
      official: 'Zentralafrikanische Republik',
      common: 'Zentralafrikanische Republik',
    },
    fra: {
      official: 'République centrafricaine',
      common: 'République centrafricaine',
    },
    hrv: {
      official: 'Centralna Afrička Republika',
      common: 'Srednjoafrička Republika',
    },
    ita: {
      official: 'Repubblica Centrafricana',
      common: 'Repubblica Centrafricana',
    },
    jpn: { official: '中央アフリカ共和国', common: '中央アフリカ共和国' },
    nld: {
      official: 'Centraal-Afrikaanse Republiek',
      common: 'Centraal-Afrikaanse Republiek',
    },
    por: {
      official: 'República Centro-Africano',
      common: 'República Centro-Africana',
    },
    rus: {
      official: 'Центрально-Африканская Республика',
      common: 'Центральноафриканская Республика',
    },
    spa: {
      official: 'República Centroafricana',
      common: 'República Centroafricana',
    },
    fin: {
      official: 'Keski-Afrikan tasavalta',
      common: 'Keski-Afrikan tasavalta',
    },
  },
  latlng: [7, 21],
  demonym: 'Central African',
  landlocked: true,
  borders: ['CMR', 'TCD', 'COD', 'COG', 'SSD', 'SDN'],
  area: 622984,
  provinces: [
    'Bamingui-Bangoran',
    'Bangui',
    'Basse-Kotto',
    'Gribingui',
    'Haut-Mbomou',
    'Haute-Kotto',
    'Haute-Sangha',
    'Kemo-Gribingui',
    'Lobaye',
    'Mbomou',
    'Nana-Mambere',
    'Ombella-Mpoko',
    'Ouaka',
    'Ouham',
    'Ouham-Pende',
    'Sangha',
    'Vakaga',
  ],
  nativeName: 'Ködörösêse tî Bêafrîka',
  timezones: ['UTC+01:00'],
} as const;
