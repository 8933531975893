export const ZWE = {
  name: {
    common: 'Zimbabwe',
    official: 'Republic of Zimbabwe',
    native: {
      bwg: { official: 'Republic of Zimbabwe', common: 'Zimbabwe' },
      eng: { official: 'Republic of Zimbabwe', common: 'Zimbabwe' },
      kck: { official: 'Republic of Zimbabwe', common: 'Zimbabwe' },
      khi: { official: 'Republic of Zimbabwe', common: 'Zimbabwe' },
      ndc: { official: 'Republic of Zimbabwe', common: 'Zimbabwe' },
      nde: { official: 'Republic of Zimbabwe', common: 'Zimbabwe' },
      nya: { official: 'Republic of Zimbabwe', common: 'Zimbabwe' },
      sna: { official: 'Republic of Zimbabwe', common: 'Zimbabwe' },
      sot: { official: 'Republic of Zimbabwe', common: 'Zimbabwe' },
      toi: { official: 'Republic of Zimbabwe', common: 'Zimbabwe' },
      tsn: { official: 'Republic of Zimbabwe', common: 'Zimbabwe' },
      tso: { official: 'Republic of Zimbabwe', common: 'Zimbabwe' },
      ven: { official: 'Republic of Zimbabwe', common: 'Zimbabwe' },
      xho: { official: 'Republic of Zimbabwe', common: 'Zimbabwe' },
      zib: { official: 'Republic of Zimbabwe', common: 'Zimbabwe' },
    },
  },
  cities: [
    'Harare',
    'Greendale',
    'Bulawayo',
    'Mutare',
    'Chinhoyi',
    'Kwekwe',
    'Victoria Falls',
    'Gwanda',
    'Mufakose',
  ],
  provinces: [
    'Bulawayo',
    'Harare',
    'ManicalandMashonaland Central',
    'Mashonaland East',
    'Mashonaland',
  ],
  nativeName: 'Zimbabwe',
  tld: ['.zw'],
  cca2: 'ZW',
  ccn3: '716',
  cca3: 'ZWE',
  cioc: 'ZIM',
  currency: ['ZWL'],
  callingCode: ['263'],
  capital: 'Harare',
  altSpellings: ['ZW', 'Republic of Zimbabwe'],
  region: 'Africa',
  subregion: 'Eastern Africa',
  languages: {
    bwg: 'Chibarwe',
    eng: 'English',
    kck: 'Kalanga',
    khi: 'Khoisan',
    ndc: 'Ndau',
    nde: 'Northern Ndebele',
    nya: 'Chewa',
    sna: 'Shona',
    sot: 'Sotho',
    toi: 'Tonga',
    tsn: 'Tswana',
    tso: 'Tsonga',
    ven: 'Venda',
    xho: 'Xhosa',
    zib: 'Zimbabwean Sign Language',
  },
  translations: {
    deu: { official: 'Republik Simbabwe', common: 'Simbabwe' },
    fra: { official: 'République du Zimbabwe', common: 'Zimbabwe' },
    hrv: { official: 'Republika Zimbabve', common: 'Zimbabve' },
    ita: { official: 'Repubblica dello Zimbabwe', common: 'Zimbabwe' },
    jpn: { official: 'ジンバブエ共和国', common: 'ジンバブエ' },
    nld: { official: 'Republiek Zimbabwe', common: 'Zimbabwe' },
    por: { official: 'República do Zimbabwe', common: 'Zimbabwe' },
    rus: { official: 'Республика Зимбабве', common: 'Зимбабве' },
    spa: { official: 'República de Zimbabue', common: 'Zimbabue' },
    fin: { official: 'Zimbabwen tasavalta', common: 'Zimbabwe' },
  },
  latlng: [-20, 30],
  demonym: 'Zimbabwean',
  landlocked: true,
  borders: ['BWA', 'MOZ', 'ZAF', 'ZMB'],
  area: 390757,
  timezones: ['UTC+02:00'],
} as const;
