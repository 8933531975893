import uniq from 'lodash/uniq';

import { isString, isArray } from '@tools/type-guards';

/**
 * Returns `true` if one or more tabs are currently registered, otherwise
 * `false`.
 */
export function hasMembers() {
  return !!getInstanceId() || !!getActiveTabs().length;
}

/**
 * Register the current tab by assigning it an instance ID and adding it the
 * global list of active tabs.
 */
export function registerInstance() {
  let instanceId = getInstanceId();

  // If the current tab has not yet been registered, create a new instance ID
  // and add it to the session storage.
  if (!instanceId) {
    instanceId = Math.round(Math.random() * 100_000).toString();

    sessionStorage.setItem('zephyr.tabId', instanceId);
  }

  setActiveTabs(uniq(getActiveTabs().concat(instanceId)));
}

// Before the page is unloaded, remove the current tab's instance ID
// (if it exists), from the global list of active tabs. In the event the tab
// is not being closed (i.e. a page reload), the tab can/should be added back
// to the list by calling `registerInstance`.
window.addEventListener('pagehide', () => {
  const instanceId = getInstanceId();

  if (!instanceId) return;

  const tabIds = getActiveTabs().filter((id) => id !== instanceId);

  setActiveTabs(tabIds);
});

//#region Helper Functions

function getInstanceId() {
  return sessionStorage.getItem('zephyr.tabId') ?? null;
}

function getActiveTabs() {
  let data: unknown = null;

  try {
    data = JSON.parse(localStorage.getItem('zephyr.activeTabs') ?? '[]');
  } catch {
    return [];
  }

  if (!isArray(data) || data.some((item) => !isString(item))) return [];

  return data as string[];
}

function setActiveTabs(tabIds: string[]) {
  localStorage.setItem('zephyr.activeTabs', JSON.stringify(tabIds));
}

//#endregion Helper Functions
