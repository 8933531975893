import { server } from '@api/request';
import { Faq } from '@models';
import { isObject } from '@tools/type-guards';

/** ... */
const IS_DEV = /(dev.zephyr-sim|localhost)/.test(location.hostname)
  ? 'true'
  : 'false';

/**
 * ...
 */
export interface ListOptions {
  dev?: boolean;
}

/**
 * List all FAQs.
 *
 * @param options Request options bag.
 * @return A list of FAQs.
 */
export async function list(options?: ListOptions) {
  const dev = options?.dev?.toString() ?? IS_DEV;

  return await server.list(`v2/faq/${dev}`, processFaqData);
}

/**
 * ...
 */
export interface UpdateOptions {
  faqs: Faq[];
}

/**
 * Update an FAQ.
 *
 * @param options Request options bag.
 */
export async function update(options: UpdateOptions) {
  // throw new Error('NOT_IMPLEMENTED!');

  return await server.post(
    `v2/faq`,
    { ...options, dev: IS_DEV === 'true' },
    processFaqData,
  );
}

//#region Helper Functions

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
function isValidFaqData(value: unknown): value is Faq.Attributes {
  if (!isObject(value)) return false;

  // TEMP: Use a more strict validation.

  return true;
}

/**
 * ...
 *
 * @param data ...
 * @return ...
 */
function processFaqData(data: unknown) {
  if (!isValidFaqData(data)) {
    throw new Error('Invalid FAQ data item.');
  }

  return { ...data } as Faq;
}

//#endregion Helper Functions
