export const CAN = {
  name: {
    common: 'Canada',
    official: 'Canada',
    native: {
      eng: { official: 'Canada', common: 'Canada' },
      fra: { official: 'Canada', common: 'Canada' },
    },
  },
  cities: [
    'Toronto',
    'Montreal',
    'Vancouver',
    'Lachine',
    'Mississauga',
    'Leamington',
    'Camrose',
    'Richmond',
    'Markham',
    'Creston',
    'Coquitlam',
    'Victoria',
    'Port Coquitlam',
    'North Vancouver',
    'Burnaby',
    'Surrey',
    'Delta',
    'Vernon',
    'New Westminster',
    'Langley',
    'Kamloops',
    'Maple Ridge',
    'Abbotsford',
    'Chilliwack',
    'Whistler Village',
    'Kelowna',
    'Port Moody',
    'Calgary',
    'Lethbridge',
    'Edmonton',
    'Lacombe',
    'Fort McMurray',
    'Fort Saskatchewan',
    'Red Deer',
    'Morinville',
    'Spruce Grove',
    'Sherwood Park',
    'Okotoks',
    'Strathmore',
    'Airdrie',
    'High Level',
    'Fairview, Alberta',
    'Leduc',
    'Grande Prairie',
    'Chatham',
    'Tavistock',
    'Perth',
    'Midland',
    'Laval',
    'Hamilton',
    'Ottawa',
    'Fergus',
    'Scarborough',
    'Cambridge',
    'Waterloo',
    'Welland',
    'Vieux-Saint-Laurent',
    'Kitchener',
    'Longueuil',
    'Oshawa',
    'Brampton',
    'Rougemont',
    'Saint-Remi',
    'London',
    'Milton',
    'Pointe-Claire',
    'Sherbrooke',
    'Kanata',
    'Gatineau',
    'Lasalle',
    'Quebec',
    'Beauport',
    'Donnacona',
    'Charlesbourg',
    'Saint-Augustin',
    'Joliette',
    'Saint-Felicien',
    'Dolbeau-Mistassini',
    'Albanel',
    'Port Clements',
    'Queen Charlotte',
    'Masset',
    'Ingersoll',
    'Alma',
    'Hawkesbury',
    'Shannonville',
    'Elk Point',
    'Burgessville',
    'Brantford',
    'Woodstock',
    'Thornhill',
    'Stoney Creek',
    'Caledonia',
    'Burlington',
    'Grimsby',
    'Vineland Station',
    'Beamsville',
    'Niagara Falls',
    'Smithville',
    'Jordan',
    'Peterborough',
    'Granby',
    'Cowansville',
    'Boisbriand',
    'Levis',
    'Sorel',
    'Blainville',
    'Mont-Royal',
    'Saint-Michel-des-Saints',
    'Saint-Hubert-de-Riviere-du-Loup',
    'Rosemere',
    'Sainte-Marthe',
    'Nominingue',
    'Saint-Eustache',
    'Sainte-Catherine',
    'Terrebonne',
    'Saint-Leonard',
    'Westmount',
    'Kirkland',
    'Delson',
    'Saint-Sauveur',
    'Saint-Jean-sur-Richelieu',
    'Sabrevois',
    'Saint-Lambert',
    'Mascouche',
    'Saint-Jerome',
    'Chambly',
    'Varennes',
    'Mirabel',
    'RiviÃ¨re-du-Loup',
    'Brossard',
    'Repentigny',
    'Sainte-Therese',
    'Dorval',
    'Leonard',
    'Saint-Lin-Laurentides',
    'Verdun',
    'Saint-Agapit',
    'Boucherville',
    'Jonquiere',
    'Chicoutimi',
    'Saint-Lambert',
    'Brossard',
    'Candiac',
    'Kincardine',
    'North Perth',
    'Stratford',
    'Les Escoumins',
    'Saint-Jacques',
    "L'Assomption",
    'Greenfield Park',
    'Deux-Montagnes',
    'Waterville',
    'Amos',
    'La Morandiere',
    'Barraute',
    "Val-d'Or",
    'Rouyn-Noranda',
    'Saint-Prosper',
    'Saint-Georges',
    'Saint-Simon-de-Rimouski',
    'Saint-Victor',
    'La Guadeloupe',
    'Alma',
    'Drummondville',
    'Roberval',
    'Asbestos',
    'Rimouski',
    'Saint-Anaclet-de-Lessard',
    'Trois-RiviÃ¨res',
    'Magog',
    'Shawinigan',
    'Sainte-Agathe-des-Monts',
    'Sainte-Adele',
    'Sainte-Anne-des-Monts',
    'Salaberry-de-Valleyfield',
    'Saint-Germain-de-Grantham',
    'Thetford-Mines',
    'Champlain',
    'North York',
    'Aurora',
    'Richmond Hill',
    'Orleans',
    'Etobicoke',
    'Guelph',
    'Whitby',
    'Pickering',
    'Vercheres',
    'Saint-Gabriel-de-Valcartier',
    'Canning',
    'Wolfville',
    'Kentville',
    'Centreville',
    'Cambridge',
    'Ancaster',
    'Jerseyville',
    'North Russell',
    'Cote-Saint-Luc',
    'Mont-Tremblant',
    'Saint-Faustin--Lac-Carre',
    'Plessisville',
    'Port-Cartier',
    'Sept-Iles',
    'Montmagny',
    'Notre-Dame-du-Mont-Carmel',
    'Shawinigan-Sud',
    'Saint-Mathieu',
    'Windsor',
    'Belle River',
    'Tecumseh',
    'Kingsville',
    'Amherstburg',
    'Newmarket',
    'Essex',
    'Harrow',
    'Wheatley',
    'Tecumseh',
    'Memphremagog',
    'Stettler',
    'Rocky Mountain House',
    'Banff',
    'Canmore',
    'Didsbury',
    'Carstairs',
    'Blairmore',
    'Pincher Creek',
    'Coleman',
    'Cowley',
    'Taber',
    'Brooks',
    'Drumheller',
    'High River',
    'Drayton Valley',
    'Barrhead',
    'Edson',
    'Lloydminster',
    'Vermilion',
    'Winnipeg',
    'Hinton',
    'Jasper',
    'Westlock',
    'Kimberley',
    'Cranbrook',
    'Marysville',
    'Fernie',
    'Canyon',
    'Golden',
    'Invermere',
    'Fairmont Hot Springs',
    'Penticton',
    'Summerland',
    'Okanagan Centre',
    'Westbank',
    'Chase',
    'Nelson',
    'Coldstream',
    'Port Alberni',
    'Sooke',
    'Courtenay',
    'Comox',
    'Parksville',
    'Cumberland',
    'Winfield',
    'Enderby',
    'Salmon Arm',
    'Armstrong',
    'Sorrento',
    'Williams Lake',
    'Grand Forks',
    'Greenwood',
    'Peachland',
    'Regina',
    'Rosetown',
    'Canora',
    'Kindersley',
    'La Ronge',
    'Humboldt',
    'Warman',
    'Melfort',
    'Meadow Lake',
    'Unity',
    'Esterhazy',
    'Kamsack',
    'North Battleford',
    'Weyburn',
    'Maidstone',
    'Estevan',
    'Yorkton',
    'Melville',
    'Maple Creek',
    'Moosomin',
    'Saint-Pierre-de-Broughton',
    'Saint-Hyacinthe',
    'Steinbach',
    'Stonewall',
    'Selkirk',
    'Teulon',
    'Pine Falls',
    'Beausejour',
    'Thompson',
    'St. Paul',
    'Portage la Prairie',
    'Tsawwassen',
    'White Rock',
    'Sechelt',
    'Mission',
    'Biggar',
    'Midale',
    'Nipawin',
    'Lampman',
    'Saskatoon',
    'Halifax',
    'Bridgewater',
    'Italy Cross',
    'Lunenburg',
    'Shelburne',
    'Mahone Bay',
    'Dartmouth',
    'Eastern Passage',
    'Sydney',
    'Truro',
    'Springhill',
    'Halifax',
    'Sackville',
    'Mount Uniacke',
    'Beaver Bank',
    'Port Dover',
    'Canfield',
    'Dunnville',
    'Hagersville',
    'Waterford',
    'Cayuga',
    'Sidney',
    'Crofton',
    'Saanichton',
    'North Saanich',
    'Mayne',
    'Shawnigan Lake',
    'Duncan',
    'Chemainus',
    'Lake Cowichan',
    'Cobble Hill',
    'Ladysmith',
    'Powell River',
    'Van Anda',
    'Sault Ste. Marie',
    'Manitouwadge',
    'Wawa',
    'Thunder Bay',
    'Atikokan',
    'Terrace Bay',
    'Schreiber',
    'Woodbridge',
    'Shelburne',
    'Bolton',
    'Puslinch',
    'York',
    'Acton',
    'Stouffville',
    'Ajax',
    'Orangeville',
    'Glencoe',
    'Caledon',
    'Oakville',
    'Norfolk County',
    'Tillsonburg',
    'Orillia',
    'Millbrook',
    'Arthur',
    'North Hatley',
    'Roxboro',
    'Crabtree',
    'Huntingdon',
    'Beloeil',
    'Rawdon',
    'Bourget',
    'Niagara-on-the-Lake',
    'Concord',
    'Maskinonge',
    'Saint-Maurice',
    'Saint-Barthelemy',
    'Batiscan',
    'Pierreville',
    'Becancour',
    'Nicolet',
    'Saint-Etienne-des-Gres',
    'Yamachiche',
    'GaspÃ©',
    'Baie-Comeau',
    'Saint-Pierre',
    'Sainte-Angele-de-Premont',
    'Chandler',
    'Val-David',
    'Yamaska',
    'Matane',
    'Saint-Ulric',
    "Saint-Leonard-d'Aston",
    'Deschaillons-sur-Saint-Laurent',
    'Aston-Jonction',
    'Sainte-Perpetue',
    'Saint-Cyrille-de-Wendover',
    'Roxton Falls',
    'Richmond',
    'Disraeli',
    'Normandin',
    'Saint-Gedeon',
    'Louiseville',
    'Saint-Dominique',
    'East Broughton',
    'Desbiens',
    'Hebertville',
    'Carmel',
    'Sainte-Anne-de-la-Perade',
    "Ayer's Cliff",
    'Windsor',
    'Tumbler Ridge',
    'Osoyoos',
    'Oliver',
    'Fenelon Falls',
    'Lindsay',
    'Bobcaygeon',
    'Dunsford',
    'Potter',
    'Vaughan',
    'Murray River',
    'Charlottetown',
    'New Glasgow',
    'Trenton',
    'Stellarton',
    'Sydney',
    'North Sydney',
    'Villa Marie',
    'Yarmouth',
    'Digby',
    'Brookfield',
    'Victoria',
    "Clarke's Beach",
    'Harbour Grace',
    'Bellevue',
    'Bay Roberts',
    "Heart's Delight-Islington",
    'Summerside',
    'Coleman',
    'Annapolis Royal',
    'Oxford',
    'Amherst',
    'Fogo',
    'Greenspond',
    'Lumsden',
    'Digby',
    'Antigonish',
    'Kensington',
    'Arichat',
    'Port Hawkesbury',
    "D'Escousse",
    'Petit-de-Grat',
    'Cornwall',
    'Georgetown',
    'New Hamburg',
    "St. John's",
    'Mount Pearl',
    'Bedford',
    'Church Point',
    'Placentia',
    'Meteghan River',
    'East Chezzetcook',
    'Sydney Mines',
    'Stratford',
    'Windsor',
    'Barrie',
    'King City',
    'Beeton',
    'Alliston',
    'Bradford',
    'Uxbridge',
    'Angus',
    'Schomberg',
    'Tottenham',
    'Caledon',
    'Greater Sudbury',
    'Lively',
    'Chelmsford',
    'New Liskeard',
    'Timmins',
    'Copper Cliff',
    'Elliot Lake',
    'Blind River',
    'Casimir',
    'Massey',
    'Rockwood',
    'Belleville',
    'Sturgeon Falls',
    'North Bay',
    'Aylmer',
    'Garson',
    'Port Elgin',
    'Hanover',
    'Listowel',
    'Mildmay',
    'Palmerston',
    'Wingham',
    'Mount Forest',
    'Chesley',
    'Kingston',
    'Napanee',
    'Simcoe',
    'Marmora',
    'Campbellford',
    'Napanee',
    'Winchester',
    'Walkerton',
    'Chesterville',
    'Cornwall',
    'Kapuskasing',
    'Picton',
    'Fort-Coulonge',
    'Owen Sound',
    'Durham',
    'Britt',
    'Gore Bay',
    'Dundas',
    'Waterdown',
    'Carlisle',
    'Hanna',
    'Lougheed',
    'Wetaskiwin',
    'Cold Lake',
    'Calmar',
    'Clairmont',
    'Bonnyville',
    'Aylesford',
    'Berwick',
    'Greenwood',
    'Middleton',
    'Kingston',
    'Three Mile Plains',
    'Saint-Frederic',
    'Rigaud',
    'Huntsville',
    'Bracebridge',
    'Gravenhurst',
    'Parry Sound',
    'Maple',
    'Saint-Joseph-de-Beauce',
    'La Patrie',
    'Saint-Camille',
    'Eastman',
    'Napierville',
    'Austin',
    'Martinville',
    'Compton',
    'Sawyerville',
    'Sainte-Marie',
    'Saint-Louis-de-Gonzague',
    'Trenton',
    'Corbyville',
    'Petawawa',
    'Pembroke',
    'Grenville',
    'Elora',
    'Vaudreuil-Dorion',
    'Lachute',
    'Saint-Basile-le-Grand',
    'Chateauguay',
    "Sainte-Sophie-d'Halifax",
    'Pointe-aux-Trembles',
    'Mont-Saint-Hilaire',
    'Otterburn Park',
    "Circonscription electorale d'Anjou",
    'Howick',
    'Pointe-Calumet',
    'La Pocatiere',
    'Ange-Gardien',
    'Saint Romuald',
    'Saint-Honore-de-Temiscouata',
    'Dollard-Des Ormeaux',
    'Sainte-Julie',
    "L'Epiphanie",
    'La Prairie',
    "Baie-D'Urfe",
    'Oka',
    "Saint-Jean-de-l'Ile-d'Orleans",
    'Beaupre',
    'Marieville',
    'Clarence-Rockland',
    'Contrecoeur',
    'Maniwaki',
    'Saint-Lin',
    'Lavaltrie',
    'Hampton',
    'Saint John',
    'Luskville',
    'Hudson',
    'Bruno',
    'Saint-Joachim-de-Shefford',
    'McMasterville',
    'Mercier',
    'Sainte-Julienne',
    'Saint-Antonin',
    'Tadoussac',
    'Chute-aux-Outardes',
    'Pointe-Lebel',
    'Beaconsfield',
    'Saint-Marc-sur-Richelieu',
    'Portneuf',
    'Farnham',
    'Saint-Venant-de-Paquette',
    'Vallee-Jonction',
    'Sutton',
    'Saint-Zacharie',
    'Lac-Megantic',
    'Brome',
    'Sainte-Cecile-de-Whitton',
    'Ormstown',
    'Stanbridge East',
    'Dudswell',
    'Saint-Elzear',
    'St. Catharines',
    'Callander',
    'Renfrew',
    'Gibsons',
    'Prince George',
    'Princeton',
    'Merritt',
    'Fort Nelson',
    'Mackenzie',
    'Roberts Creek',
    'Halfmoon Bay',
    'La Sarre',
    'Dupuy',
    'Notre-Dame-du-Nord',
    'Ville-Marie',
    'Temiscaming',
    'Macamic',
    'Massueville',
    'Saint-Hugues',
    'Malartic',
    'Bearn',
    'La Tuque',
    'Laverlochere',
    'Richmond',
    'St. Thomas',
    'Nepean',
    'Stewiacke',
    'Valley',
    'Westville',
    'Eureka',
    'Pictou',
    'Marystown',
    'Troy',
    'La Conception',
    'Middle Musquodoboit',
    'Hebron',
    'Cavendish',
    'Grono Road',
    'Jordan Falls',
    'Barrington Passage',
    'Barrington',
    'Crapaud',
    'Brigus',
    'Clarenville',
    'Blackville',
    'Scotsburn',
    "Hant's Harbour",
    'Carbonear',
    'Holyrood',
    'Cornwallis',
    'Black Diamond',
    'Trochu',
    'Three Hills',
    'Bonne Bay',
    'Fortune',
    'Morell',
    'Bridgetown',
    'Clementsvale',
    'Bear River',
    'Springdale',
    'Murray Harbour',
    'Seldom-Little Seldom',
    'Liverpool',
    'Port Medway',
    'Paradise Cove',
    'Victoria Cove',
    'Birchy Bay',
    'Gander',
    'Twillingate',
    'Burin',
    "St. Alban's",
    'West Lake',
    'Margaretsville',
    'Hampton',
    'Corner Brook',
    'Wainwright',
    "Daniel's Harbour",
    'Tusket',
    'Saulnierville',
    'Rockville',
    'Chester',
    'Baie Verte',
    'Happy Valley-Goose Bay',
    'Gambo',
    'Granville Ferry',
    'Western Bay',
    'Old Perlican',
    'Bay de Verde',
    "Circonscription electorale d'Outremont",
    'Bromont',
    'Victoriaville',
    'Acton Vale',
    'Valcourt',
    'Metabetchouan-Lac-a-la-Croix',
    'Wickham',
    'Warwick',
    'La Presentation',
    'Saint-Damase',
    'La Baie',
    "L'Anse-Saint-Jean",
    'Pont-Rouge',
    'Baie-Saint-Paul',
    'Mitchell',
    'Paisley',
    'Iroquois Falls',
    'Kirkland Lake',
    'Levack',
    'Omemee',
    'Pefferlaw',
    'Goderich',
    'Clinton',
    'Exeter',
    'Bayfield',
    'Prescott',
    'Berthierville',
    'Lakefield',
    'Cobourg',
    'Baltimore',
    'Port Hope',
    'Port Perry',
    'Kitimat',
    'Terrace',
    'Smithers',
    'Prince Rupert',
    'Roseneath',
    'Brandon',
    'Stittsville',
    'Norway House',
    'Winkler',
    'Port Colborne',
    'Noyan',
    'Fredericton',
    'Moncton',
    'Perth-Andover',
    'Tracadieâ€“Sheila',
    'Miramichi',
    'Woodstock',
    'Edmundston',
    'Campbellton',
    'Bathurst',
    'Souris',
    'Coniston',
    'Saint-Quentin',
    'Plaster Rock',
    'Neguac',
    'Canterbury',
    'Rogersville',
    'Doaktown',
    'Lavillette',
    'Hartland',
    'Nackawic',
    'Pointe-Verte',
    'Dover',
    'Lewisporte',
    'Port Saunders',
    "Saint Fintan's",
    "L'Anse-au-Loup",
    'Deer Lake',
    'Grand Falls-Windsor',
    'Conception Bay South',
    'Hantsport',
    'Bay Bulls',
    'Dieppe',
    'Berry Mills',
    'Rothesay',
    'Quispamsis',
    'South Porcupine',
    'Haileybury',
    'Grand Bayâ€“Westfield',
    'Timberlea',
    'Elmsdale',
    'Carters Cove',
    'Mira Gut',
    'Sable River',
    'Alberton',
    'Glace Bay',
    'New Waterford',
    'Portugal Cove',
    'Grand Falls',
    'Pouch Cove',
    'Sicamous',
    'Tappen',
    'Pritchard',
    'Pitt Meadows',
    'Fort St. John',
    'Onoway',
    'St. Albert',
    'Oyama',
    'Aldergrove',
    'Squamish',
    'Garibaldi',
    'Brackendale',
    'Trail',
    'Castlegar',
    'Hope',
    'Vanderhoof',
    'Fort St. James',
    'Lumby',
    'Sexsmith',
    'McLennan',
    'Peace River',
    'Grimshaw',
    'Beaverlodge',
    'Rycroft',
    'Irricana',
    'Turner Valley',
    'Bassano',
    'Innisfail',
    'Blackfalds',
    'Sylvan Lake',
    'Ponoka',
    'Campbell River',
    'Medicine Hat',
    'Redcliff',
    'Whitecourt',
    'Magrath',
    'Claresholm',
    'Raymond',
    'Bellevue',
    'Donnelly',
    'Wallaceburg',
    'Mooretown',
    'Sarnia',
    'Rockland',
    'Innisfil',
    'Collingwood',
    'Thamesville',
    'Mandeville',
    "Sainte-Emelie-de-l'Energie",
    'Thurso',
    'Sainte-Cecile-de-Masham',
    'Kemptville',
    'Petrolia',
    'Glen Robertson',
    'Long Sault',
    'Ridgeway',
    'Keswick Beach',
    'Paris',
    'Harriston',
    'Alexandria',
    'Cardinal',
    'Norwood',
    'Pinawa',
    'Weir',
    "L'Ile Perrot",
    'La Salle',
    'Milford',
    'Milford Station',
    'Bancroft',
    'Belwood',
    'Cochrane',
    'Hearst',
    'Moosonee',
    'Matheson',
    'Earlton',
    'Iqaluit',
    'Gloucester',
    'Niagara',
    'Drumbo',
    'Delhi',
    'Devon',
    'Gananoque',
    'Bath',
    'Newburgh',
    'Stella',
    'Sydenham',
    'Moraviantown',
    'Blenheim',
    'Oil Springs',
    'Merlin',
    'Garibaldi Highlands',
    'Dauphin',
    'Gilbert Plains',
    'Lang',
    'Rouleau',
    'Moose Jaw',
    'Wadena',
    'Foam Lake',
    'Theodore',
    'Wynyard',
    'Kelvington',
    'Swift Current',
    'Grenfell',
    'Carlyle',
    'North Portal',
    'Redvers',
    'Wakaw',
    'Dundurn',
    'Prince Albert',
    'Indian Head',
    'Broadview',
    'Wawota',
    'Wilkie',
    'Kerrobert',
    'Macklin',
    'Coleville',
    'Bella Bella',
    'Valemount',
    'Kitwanga',
    'Kitchener',
    'Dawson Creek',
    'Port Renfrew',
    'Nakusp',
    'Skidegate',
    'Revelstoke',
    'Westport',
    'Elgin',
    'Yarker',
    'Saint-Jean-de-Matha',
    'Port Royal',
    'Slave Lake',
    'Valleyview',
    'Morden',
    'Atikameg',
    'Forestburg',
    'Bashaw',
    'Quesnel',
    'Port McNeill',
    'Ucluelet',
    'Tofino',
    'Merville',
    'Nanaimo',
    'Heron Bay',
    'Sioux Lookout',
    'Kuujjuaq',
    'Gogama',
    'Sandy Lake',
    'Cat Lake',
    'Ivujivik',
    'Pikangikum',
    'Bearskin Lake',
    'Fermont',
    'Ohsweken',
    'Griswold',
    'Swan River',
    'Lashburn',
    'Onion Lake',
    'Eston',
    'Outlook',
    'Mortlach',
    'Cabri',
    'Dryden',
    'Saint-Andre',
    'Saint-Sebastien',
    'Nantes',
    'Vankleek Hill',
    'Haliburton',
    'Espanola',
    'Thorold',
    'Mount Brydges',
    'Grand Valley',
    'Camlachie',
    'Deseronto',
    'Ayr',
    'Yellowknife',
    'Cambridge Bay',
    'Kugaaruk',
    'Sanikiluaq',
    'Rankin Inlet',
    'Kugluktuk',
    'Deline',
    'Orton',
    'Eaglesham',
    'Lachenaie',
    'Atlin',
    'Shaunavon',
    'Saint-Guillaume',
    'Masson-Angers',
    'Montreal East',
    'Bowmanville',
    'Melbourne Ridge',
    'Saint-Gabriel',
    'Finch',
    'Arnprior',
    'Saint-Pie',
    'Stayner',
    'Limoges',
    'Greely',
    'Sainte-Melanie',
    'Carleton Place',
    'Brockville',
    'Coldwater',
    'Everett',
    'Fort Frances',
    'Ignace',
    'La Malbaie',
    'Baie-Sainte-Catherine',
    'Casselman',
    'Almonte',
    'Thorndale',
    'Markdale',
    'Wiarton',
    'Saint-Zotique',
    'Kohler',
    'Holman',
    'Galahad',
    'Neudorf',
    'Kars',
    'Ardrossan',
    'Apsley',
    'Havelock',
    "Lion's Head",
    'Clermont',
    'Eden',
    'Wyoming',
    'Morrisburg',
    'Port Burwell',
    'Mont-Joli',
    'Metis-sur-Mer',
    'Saint-Fabien',
    'Sainte-Croix',
    'Czar',
    'Cherry Grove',
    'Athabasca',
    'Mannville',
    'Paradise Valley',
    'Nampa',
    'Lac La Biche',
    'Wolseley',
    'Montmartre',
    'Balcarres',
    'Wood Mountain',
    'Glentworth',
    'Saint-Colomban',
    'Cookshire-Eaton',
    'Saint-Pacome',
    'Saint-Philippe-de-Neri',
    'Saint-Roch-des-Aulnaies',
    'Saint-Jean-Port-Joli',
    'Saint-Philippe',
    'Kamouraska',
    'Saint-Aubert',
    'Les Cedres',
    "L'Islet",
    'Lorraine',
    'Bois-des-Filion',
    'Deer Lake',
    'Inukjuak',
    'Poplar Hill',
    'Fort Hope',
    'Kangiqsujuaq',
    'Tasiujaq',
    'Fort Severn',
    'Trois Pistoles',
    'Saint-Raymond',
    'St. Andrews',
    'Hay Lakes',
    'Houston',
    'Fruitvale',
    'Pemberton',
    'Clearwater',
    'Cache Creek',
    'Logan Lake',
    'Savona',
    'Landrienne',
    'Prince',
    'Saint-Elie',
    'Beauceville',
    'Sainte-Monique',
    'Daveluyville',
    'Bluewater',
    'Hensall',
    'Lucan',
    'Grand Bend',
    'Dublin',
    'Huron East',
    'Norwich',
    'Ailsa Craig',
    'Thedford',
    'Saint-Adalbert',
    'Lotbiniere',
    'Saint-Apollinaire',
    'Cap-Saint-Ignace',
    'Little Current',
    'South River',
    'Markstay',
    'Fort Macleod',
    'Hawkestone Beach',
    'Midhurst',
    'Hythe',
    'Chestermere',
    'Moosehorn',
    'Emsdale',
    'Kagawong',
    'Inverness',
    'Roland',
    'Milk River',
    'Dashwood',
    'Lucknow',
    'Kippen',
    'Ripley',
    'Kirkton',
    'Daysland',
    'Viking',
    'Rosalind',
    'Willingdon',
    'Vulcan',
    'Lake Louise',
    'Vegreville',
    'Saint-Cesaire',
    'Baden',
    'Alvinston',
    'Arkona',
    'Beaverton',
    'Hemmingford',
    'Emo',
    "L'Ascension",
    'Saint-Eusebe',
    'Saint-Zenon',
    'Saint-Donat',
    'Tweed',
    'Saint-Ferdinand',
    'Deep River',
    'Ingleside',
    'Don Mills',
    'Hespeler',
    'Erin',
    'Stirling',
    'Saint-Come',
    'Ilderton',
    'Arundel',
    'Alfred',
    'Weedon',
    'Port Stanley',
    'Chertsey',
    'South Lancaster',
    'Lancaster',
    'Brighton',
    'Colborne',
    'Carrying Place',
    'Lyn',
    'Athens',
    'Brochet',
    "L'Orignal",
    'Verner',
    'Ayton',
    'Dominion City',
    'Inwood',
    'Watford',
    'Blue River',
    'Sainte-Brigitte-des-Saults',
    'Big River',
    'Canwood',
    'Spiritwood',
    'Francis',
    'Willow Bunch',
    'Assiniboia',
    'Gravelbourg',
    'Lafleche',
    'Viscount',
    'Allan',
    'Gull Lake',
    'St. Walburg',
    'Glaslyn',
    'Ile-a-la-Crosse',
    'La Loche',
    'Canoe Narrows',
    'Arborfield',
    'Carrot River',
    'Cumberland House',
    'Langenburg',
    'Rocanville',
    'Milden',
    'Elrose',
    'Elbow',
    'Buffalo Narrows',
    'Hanley',
    'Oxdrift',
    'Wabigoon',
    'Saint-Stanislas-de-Kostka',
    'Clifford',
    'Teeswater',
    'Watrous',
    'Watson',
    'Southend Reindeer',
    'White Fox',
    'Nokomis',
    'Lumsden',
    'Southey',
    'Cupar',
    'Stevensville',
    'Fort Erie',
    'Cochrane',
    'Lancaster Park',
    'Bentley',
    'Beaumont',
    'Stony Plain',
    'Redwater',
    'Grand-Remous',
    'Namur',
    'Papineauville',
    'Ripon',
    'Messines',
    'Shawville',
    'Portage-du-Fort',
    'Laurentia Beach',
    'Downsview',
    'Enterprise',
    'Leader',
    'Rosthern',
    'Waldheim',
    'Radville',
    'Herbert',
    'Pilot Butte',
    'Frobisher',
    'Wishart',
    'Dalmeny',
    'Langham',
    'Silton',
    'Neilburg',
    'Caronport',
    'Brechin',
    'Chicoutimi',
    'Minden',
    'Senneterre',
    'Saint-Marc-de-Figuery',
    'Sainte-Gertrude',
    'Kenora',
    'Keewatin',
    'Niverville',
    'Shoal Lake',
    'Nanton',
    'Rimbey',
    'Langdon',
    'Nobleford',
    'Stirling',
    'Rainbow Lake',
    'Foremost',
    'Milverton',
    'Atwood',
    'Saint-Ludger',
    'Lambton',
    'Saint-Severe',
    'Saint-Alexis-des-Monts',
    'Saint-Pierre-les-Becquets',
    'La Visitation',
    'Saint-Liboire',
    'Saint-Paulin',
    'Grand-Saint-Esprit',
    'Parisville',
    'Saint-Barnabe-Sud',
    'Saint-Wenceslas',
    'Coleraine',
    'Manseau',
    'Saint-Sylvere',
    'Cleveland',
    'River John',
    'Mabou',
    "Burk's Falls",
    'Forest',
    'Mount Elgin',
    'Courtland',
    'Sainte-Anne-de-Bellevue',
    'Saint-Roch-de-Richelieu',
    'Saint-Charles-sur-Richelieu',
    'Pincourt',
    'Shannon',
    'Saint-Bruno-de-Montarville',
    'Calixa-Lavallee',
    'Beaver Crossing',
    'Quaqtaq',
    'Puvirnituq',
    'Kangirsuk',
    'Oxford House',
    'Saint-Jean-de-Dieu',
    'Fonthill',
    "L'Ancienne-Lorette",
    'Lanark',
    'Roblin',
    'Ridgetown',
    'Saint-Paul-de-la-Croix',
    'Riviere-Saint-Paul',
    'Madoc',
    'Nakina',
    'Frankford',
    'Dorchester',
    'Saint-Isidore',
    'Nobleton',
    'Saint-Denis',
    'Riviere-Beaudette',
    'McGregor',
    'Foymount',
    'Cheapside',
    'West Osgoode',
    'Williamsburg',
    'Port Hardy',
    'Sointula',
    'Lefroy',
    'Smiths Falls',
    'Sundridge',
    'Alton',
    'Port Credit',
    'Val-des-Monts',
    'Newcastle Village',
    'Severn Bridge',
    'Penetanguishene',
    'Wainfleet',
    'Maidstone',
    'Washago',
    'Coboconk',
    'Biencourt',
    'Cap-Chat',
    'Saint-Joachim',
    'Sainte-Marcelline-de-Kildare',
    'Lanoraie',
    'Wasaga Beach',
    'Tilbury',
    'Labelle',
    'Spanish',
    'Dundalk',
    'Echo Bay',
    'Saint-Roch',
    'Geraldton',
    'South Gower',
    'Drayton',
    'Saint-LÃ©onard',
    'Bala',
    'Elmvale',
    'Saint-Anicet',
    'Pointe-Fortune',
    'Manotick Station',
    'Clarkson',
    'Ascot Corner',
    'Tring-Jonction',
    'Begin',
    'Montebello',
    'Plantagenet Station',
    'Waubaushene',
    'Bowden',
    'Olds',
    'Penhold',
    'Coaldale',
    'Clive',
    'Gabriola',
    'Qualicum Beach',
    'Nanoose Bay',
    'Bowser',
    'Honeymoon Bay',
    'Youbou',
    'Cowichan Bay',
    'Agassiz',
    'Crossfield',
    'Bow Island',
    'Red Rock',
    'Nipigon',
    'White River',
    'Marathon',
    'Red Lake',
    '100 Mile House',
    'Rossland',
    'Lillooet',
    'Charlemagne',
    'Saint-Lazare-de-Bellechasse',
    'Saint-Felix-de-Valois',
    'Saint-Charles-de-Bellechasse',
    'Sainte-Martine',
    'Lery',
    'Gore',
    'Saint-Hilaire',
    'Beauharnois',
    'Colliers',
    'Port au Port',
    'Stephenville',
    'Musquodoboit Harbour',
    'Middle Arm',
    'Shubenacadie',
    'Plympton',
    'Stanley Bridge, Hope River, Bayview, Cavendish and North Rustico',
    'Arcadia',
    'New-Wes-Valley',
    'Enfield',
    'Burgeo',
    'Colinet',
    'Upper Island Cove',
    'West Chezzetcook',
    'Lawrencetown',
    "Fort Qu'Appelle",
    'Maryfield',
    'Bredenbury',
    'Luseland',
    'Waldeck',
    'Duck Lake',
    'Calder',
    'Hudson Bay',
    'Lucky Lake',
    'Dinsmore',
    'Carnduff',
    'Lipton',
    'Domain',
    'Lac-Drolet',
    'Knowlton',
    'Dixville',
    'Saint-Sylvestre',
    'Scotstown',
    'Racine',
    'Cardston',
    'Courtright',
    'Point Edward',
    'Fraser Lake',
    'Alexander',
    'Shamattawa',
    'Sheho',
    'Cumberland',
    'Foxtrap',
    "Bishop's Falls",
    'Grande-Anse',
    'Strathroy',
    'Elmira',
    'Thamesford',
    'Memramcook',
    'Ashmont',
    'Myrnam',
    'Two Hills',
    'Thorhild',
    'Lamont',
    'Tofield',
    'Holden',
    'Mundare',
    'Irma',
    'Boyle',
    'Turtleford',
    'Woodridge',
    'Berwyn',
    'Hastings',
    'Buckhorn',
    'MacTier',
    'Saint Etienne-De-Lauzon',
    'Creemore',
    'Sombra',
    'New Dundee',
    'Saint-Francois-du-Lac',
    'Charny',
    'Saint-Odilon-de-Cranbourne',
    'Sainte-Justine',
    'Fortierville',
    'Saint-Fabien-de-Panet',
    'Baie-du-Febvre',
    'Dunham',
    'East Angus',
    'Roxton Pond',
    'Berthier-Sur-Mer',
    'Cabano',
    'Saint-Vallier',
    'Riviere-Bleue',
    'Coaticook',
    'Cheneville',
    'Sainte-Claire',
    'Melocheville',
    'Saint-Henri',
    'Saint-Anselme',
    'Virden',
    'Cromer',
    'Saint-Godefroi',
    'McCreary',
    'Killarney',
    'Rivers',
    'Fort Albany',
    'Attawapiskat',
    'Amqui',
    'Kingsey Falls',
    'Ham-Nord',
    'Blandford',
    'Eatonville',
    'Saint-Tite',
    'Saint-Stanislas',
    'Chelsea',
    'Clarke City',
    'Val-Brillant',
    'Fort Kent',
    'Girouxville',
    'Mallaig',
    'Smoky Lake',
    'Andrew',
    'Glendon',
    'Woodlands',
    'Stony Mountain',
    'Altona',
    'Sainte-Helene-de-Bagot',
    'Armagh',
    'Saint-Alban',
    'Saint-Georges-de-Windsor',
    'Saint-Adelphe',
    'Murdochville',
    'Paspebiac',
    'Newport',
    'Grande-Riviere',
    'Maria',
    'Saint-Michel-de-Bellechasse',
    'Deschambault',
    'Cap-Sante',
    'Saint-Basile',
    'Saint-Antoine-de-Tilly',
    'Saint-Philemon',
    'Saint-Pamphile',
    'Gadsby',
    'New-Richmond',
    'Windermere',
    'Boswell',
    'Kaslo',
    'Salmo',
    'Leslieville',
    'Notre-Dame-de-Lourdes',
    'Esprit-Saint',
    'New Carlisle',
    'Bonaventure',
    'Westree',
    'Hay River',
    'Fort Good Hope',
    'Fort Simpson',
    'WhatÃ¬',
    'Whitehorse',
    'Inuvik',
    'Elliston',
    "King's Point",
    'New Ross',
    'Chapleau',
    'Bedford',
    'Pont-Viau',
    'Carp',
    'Pass Lake',
    'Theresa',
    'Victoria Harbour',
    'Utterson',
    'Saint-Clement',
    'Circonscription electorale de Jacques-Cartier',
    'Cartier',
    'Pakenham',
    'Warkworth',
    'Saint-Cyprien',
    'Parham',
    'Keene',
    'Notre-Dame-du-Portage',
    'Carignan',
    'Saint-Flavien',
    'Saint-Polycarpe',
    'Saint-Placide',
    'Princeville',
    'St-Lazare',
    'Hull',
    'Hubbards',
    'Brooklyn',
    'Mill Village',
    'Lower Island Cove',
    'Roddickton',
    'Entwistle',
    'Newtown',
    'Petitcodiac',
    'Dawson Settlement',
    'Waweig',
    'Keswick',
    'Sussex',
    'Gold Bridge',
    'Seton Portage',
    'Canim Lake',
    'Cobden',
    'Duchess',
    'Tilley',
    'Rosemary',
    'Gem',
    'Union Bay',
    'Black Creek',
    'Cumberland Bay',
    'Lanigan',
    'Oxbow',
    'Davidson',
    'Cudworth',
    'Lacolle',
    'Saint-Urbain-Premier',
    'Newton',
    'Port au Choix',
    'Upton',
    'Bonsecours',
    'Labrador City',
    'Wallace',
    'Cheticamp',
    'Pugwash',
    'Castle Bay',
    'Terre Noire',
    'Laurier-Station',
    'Havre-Saint-Pierre',
    'Willowdale',
    'Thornbury',
    'Rexdale',
    'Conestogo',
    'Churchill',
    'Hillsburgh',
    'Embrun',
    'Sutton',
    'Wellesley',
    'St. Marys',
    'Torbay',
    'Witless Bay',
    'Scotland',
    'Maxville',
    'Delaware West',
    'Sparta',
    'Komoka',
    'Jacksons',
    'Port McNicoll',
    'Meaford',
    'Newcastle Creek',
    'Oromocto',
    'Millville',
    'Riverview',
    'Salisbury',
    'Shediac',
    'Fredericton Junction',
    'Ripples',
    'Orono',
    'Denfield',
    'Kitscoty',
    'Flesherton',
    'St. Jacobs',
    'South Augusta',
    'DeBolt',
    'St. Brieux',
    'Strasbourg',
    'Raymore',
    'Granton',
    'Borden',
    'Brentwood Bay',
    'Pelly',
    'Sturgis',
    "L'Etang-du-Nord",
    'Chalk River',
    'Saint-Luc',
    'Boischatel',
    'Chateau-Richer',
    'Sainte-Famille',
    'Sainte-Anne-de-Beaupre',
    'Saint-Ferreol-les-Neiges',
    'Aylmer',
    'Price',
    'Duhamel',
    'St-Hubert',
    'Danville',
    'Barriere',
    'Charlie Lake',
    'Burns Lake',
    'Nisku',
    'Montague',
    'Debert',
    'Port Lorne',
    'Central Bedeque',
    'Hunter River',
    'North Rustico',
    'Milton',
    'LaHave',
    'Great Village',
    'Head of Jeddore',
    'Meteghan',
    'Caledonia',
    'Porters Lake',
    'Mount Stewart',
    'Cardigan',
    'Vernon Bridge',
    'Tatamagouche',
    'Minto',
    'Pynns',
    'Glenwood',
    'Stephenville Crossing',
    'Pasadena',
    'Raleigh',
    'Barnaby River',
    "Spaniard's Bay",
    'Tyndall',
    'Neepawa',
    'The Pas',
    'Sperling',
    'Pilot Mound',
    'Carman',
    'Gimli',
    "Heart's Content",
    'Trout River',
    'Bell Island',
    'New Harbour',
    'Chapel Arm',
    'Eastport',
    'Fermeuse',
    'St. Anthony',
    'Garnish',
    "O'Regan's",
    'Cow Head',
    "Parson's Pond",
    'Clarenville-Shoal Harbour',
    "St. Joseph's",
    'Rencontre East',
    'Caraquet',
    'Mobile',
    'Wabana',
    'Stoughton',
    'Fillmore',
    'Vonda',
    'Battleford',
    'Arcola',
    'Preeceville',
    'White City',
    'Shellbrook',
    'Wymark',
    'Star City',
    'Gronlid',
    'Garrick',
    'Bienfait',
    'Radisson',
    'Richibucto',
    'Shanklin',
    'Saint Andrews',
    'Florenceville-Bristol',
    'Baddeck',
    'Canso',
    'Riverside-Albert',
    'Scoudouc',
    'Apohaqui',
    'Oak Point',
    'Havre Boucher',
    'Mulgrave',
    'Little Narrows',
    'Kingsville',
    'Guysborough',
    'Sherbrooke',
    'Dalhousie',
    'Bouctouche',
    'Wabush',
    'Port Elgin',
    'River Hebert',
    'Chipman',
    'Weldon',
    'Harvey',
    'La Tabatiere',
    'Betsiamites',
    'Miscouche',
    'East Margaree',
    'Whites Lake',
    'Ingonish',
    'Margaree Valley',
    'Renous',
    'Flat River',
    'Emerald Junction',
    'Iona',
    'Mont-Laurier',
    'Chibougamau',
    'Saint-Aime-du-Lac-des-Iles',
    'Chapais',
    'Beaux-Rivages--Lac-des-Ecorces--Val-Barrette',
    'Sainte-Anne-du-Lac',
    'Ferme-Neuve',
    'Otter Lake',
    'Mont-Saint-Michel',
    'Matagami',
    'Guigues',
    'Angliers',
    'Palmarolle',
    'Cap-aux-Meules',
    'Authier',
    'Forestville',
    'Taschereau',
    'East Coulee',
    'Rumsey',
    'Hazelton',
    'Blacks Harbour',
    'Caroline',
    'Saint-Gilles',
    'Lac-aux-Sables',
    'Saint-Marc-des-Carrieres',
    'Saint-Come--Liniere',
    'Borden-Carleton',
    'Saint-Leon-de-Standon',
    'Sayabec',
    'Causapscal',
    'Slocan',
    'Audet',
    'Glenwood',
    'Plamondon',
    'Wittenburg',
    'Lytton',
    'Alert Bay',
    'Ardmore',
    'Carberry',
    'Torrington',
    'Acme',
    'Moose Creek',
    'Golden Lake',
    'Boissevain',
    'Sainte-Helene-de-Breakeyville',
    'Gleichen',
    'Beiseker',
    'Millet',
    'Fort Vermilion',
    'Taylor',
    'Petite-Riviere-Saint-Francois',
    'Eganville',
    'Carleton-sur-Mer',
    'Saint-Paul',
    'Saint-Thomas',
    'Saint-Pascal',
    'Saguenay',
    'Arthabaska',
    'Mont-Saint-Gregoire',
    'Dorion-Gardens',
    'Saint-Andre-Avellin',
    'Quathiaski Cove',
    'Denman Island',
    'La Durantaye',
    'Saint-Ubalde',
    'Sainte-Marguerite',
    'Inglewood',
    'Edgerton',
    'Provost',
    'Marwayne',
    'Legal',
    'Hardisty',
    'Cayley',
    'Delburne',
    'Hobbema',
    'Wembley',
    'Winfield',
    'Breton',
    'Salmon River',
    'Wellington',
    'Ramea',
    'Lac du Bonnet',
    'Whitemouth',
    'Miami',
    'Kinistino',
    'Birch Hills',
    'Saint-Antoine-sur-Richelieu',
    'Clyde River',
    'Hare Bay',
    'Glovertown',
    'Notre-Dame-du-Bon-Conseil',
    'Shigawake',
    'Port-Daniel',
    'Saint-Camille',
    'Willowvale',
    'Wakefield',
    'Lourdes',
    'Lethbridge',
    'Gander Bay',
    'St. Peters Bay',
    'Tlell',
    'Saint-Nazaire',
    'Hornepayne',
    "Sainte-Brigide-d'Iberville",
    'Henryville',
    'Mansonville',
    'Nauwigewauk',
    'Perth',
    'Lakeburn',
    'Howley',
    'Harbour Main',
    'Allardville',
    'Saint-Louis de Kent',
    'Pennfield',
    'Big Valley',
    'High Prairie',
    'Wildwood',
    'Ganges',
    'Canal Flats',
    'Grindrod',
    'Bloomfield',
    'Les Coteaux',
    'Dresden',
    "L'Isle-Verte",
    'Wellington',
    'Claremont',
    'Saint-Ambroise',
    'Senneville',
    'Saint-Denis',
    'Londonderry',
    'Flin Flon',
    'Elgin',
    'Rossburn',
    'Pain Court',
    'La Corey',
    'Economy',
    'Cannington',
    'Sainte-Foy',
    'Wilcox',
    'Sainte-Marthe-sur-le-Lac',
    'Iroquois',
    'Priceville',
    'South Mountain',
    'Petherton',
    'Merrickville',
    'Avonmore',
    'Apple Hill',
    'North Lunenburg',
    'Quyon',
    'Maberly',
    'Kazabazua',
    'Warsaw',
    'Islington',
    'Millarville',
    'Weston',
    'Burnside',
    'Portage',
    'Waterloo',
    'Saint-Paul',
    "Saint-Paul-d'Abbotsford",
    'Brigham',
    'Rapid City',
    'Dufresne',
    'Fabreville',
    'Prospect',
    'Eriksdale',
    'Minnedosa',
    'Cranberry Portage',
    'Bonshaw',
    'Colonsay',
    'Coburg',
    'Sardis',
    'Pascal',
    'Hafford',
    'Spencerville',
    'Odessa',
    'Trout Creek',
    'Powassan',
    'Sorel-Tracy',
    'Kispiox',
    'Cedar Valley',
    'Barons',
    'Cottam',
    'Saint-Augustin-de-Desmaures',
    'Fort McPherson',
    'Tuktoyaktuk',
    'Aklavik',
    'Breslau',
    'Morrin',
    'Youngstown',
    'Consort',
    'Empress',
    'Jenner',
    'Clinton',
    'Eckville',
    'Riverton',
    'Sherrington',
    'Scott',
    'Mar',
    'Grande Cache',
    'Saint-Michel',
    'Tracadie',
    'Newdale',
    'Saint-Raphael',
    'West Hill',
    'Granum',
    'Binscarth',
    'Moffat',
    'Otterburne',
    'Beauval',
    'Yellow Grass',
    'Craik',
    'Norquay',
    'Quill Lake',
    'Piney',
    'Morris',
    'Plum Coulee',
    'Manitou',
    'Churchill',
    'Arborg',
    'Moose Lake',
    'Gillam',
    'Winnipegosis',
    'Comber',
    'Campbellville',
    'Havre-Aubert',
    'Bamfield',
    'North Delta',
    'Churchville',
    'Kinuso',
    'Munson',
    'St. Stephen',
    'Arva',
    'Malton',
    'West Bay',
    'Pontypool',
    'Clarence',
    'South Slocan',
    'Lantzville',
    'New Aiyansh',
    'Port Edward',
    'Pinantan Lake',
    'Riviere-Ouelle',
    'Munster',
    'Kilworthy',
    'Elma',
    'MacGregor',
    'Gladstone',
    'Riviere-Rouge',
    'Norval',
    'Chatsworth',
    'Unionville',
    'Rossport',
    'Wemindji',
    'Arviat',
    'Cape Dorset',
    'Saint-Bruno',
    'Rosedale',
    'Sillery',
    'Cartwright',
    'Russell',
    'Ashville',
    'Treherne',
    'Gretna',
    'Streetsville',
    'Clair',
    'Ferintosh',
    'Agincourt',
    'Omerville',
    'Bramalea',
    'Yahk',
    'Sunderland',
    'Nouvelle',
    'Edam',
    'Buckingham',
    'Muirkirk',
    'Coutts',
    'Pukatawagan',
    'Ogoki',
    'Kuujjuarapik',
    'Cross Lake',
    'Grand Rapids',
    'South Indian Lake',
    'Oldcastle',
    'Pouce Coupe',
    'Dog Creek',
    'New Denver',
    'Deroche',
    'Parson',
    'Spillimacheen',
    'Saint-Casimir',
    'Sainte-Thecle',
    'Lemberg',
    'Tompkins',
    'Penobsquis',
    'Whitney',
    'Kerwood',
    'Port Alice',
    'Stoneham',
    'Saint-Telesphore',
    'Obedjiwan',
    'Saint-Jude',
    'Baldonnel',
    'Hagensborg',
    'Malakwa',
    'Edgewood',
    'Sayward',
    'Les Mechins',
    'Neuville',
    'Plumas',
    'Kedgwick',
    'Val-Morin',
    'Dawson City',
    'Carmacks',
    'Watson Lake',
    'Paulatuk',
    'Norman Wells',
    'Gray Creek',
    'Bridge Lake',
    'Klemtu',
    'Field',
    'Tisdale',
    'Spy Hill',
    'Bromhead',
    'Debden',
    'Abbey',
    'Blaine Lake',
    'Wiseton',
    'Mankota',
    'Bethune',
    "Qu'Appelle",
    'Kisbey',
    'Ituna',
    'Mistatim',
    'Conquest',
    'Saint-Felix-de-Kingsey',
    'Sainte-Clotilde-de-Horton',
    'Saint-Malachie',
    'Wrentham',
    'Carseland',
    'Delaware',
    'Snow Lake',
    'Dugald',
    'Marchand',
    'Mountain View',
    'Worsley',
    'Standard',
    'Smooth Rock Falls',
    'Acadia Valley',
    'Spirit River',
    'Englehart',
    'Moonbeam',
    'Swastika',
    'Hampden',
    'Aylsham',
    'Penzance',
    'Delisle',
    'Rhein',
    'Arelee',
    'Baie-Des-Sables',
    'Schefferville',
    'Tahsis',
    'Marbleton',
    'Coal Harbour',
    'McBride',
    'Grasmere',
    'Barkerville',
    'Moberly Lake',
    'Bic',
    'Ashcroft',
    'Hartley Bay',
    'North Star',
    'Cranberry',
    'Dorset',
    'Field',
    'Warner',
    'Tompkins',
    'Kettleby',
    'Saint-Irenee',
    'Saint-Arsene',
    'Saint-Urbain',
    'Pointe-a-la-Croix',
    'Ladysmith',
    'Windham Centre',
    'Parkhill',
    'Kent Bridge',
    'Searchmont',
    'Garden River',
    'Blackie',
    'Seaforth',
    'Roblin',
    'Grandora',
    'St. Louis',
    'Lake Lenore',
    'Cut Knife',
    'Birtle',
    'Hartney',
    'Pierson',
    'Onanole',
    'Baldur',
    'Melita',
    'Bryson',
    'Springfield',
    'Takla Landing',
    'Louisbourg',
    'Port Morien',
    'Malagawatch',
    'Whycocomagh',
    'Fox Creek',
    'Fort Smith',
    'Arctic Bay',
    'Kimmirut',
    'Colville Lake',
    'Hamiota',
    'Inglis',
    'Oak Lake',
    'Souris',
    'Sandy Bay',
    'Young',
    'Domremy',
    'St-Timothee',
    'Sharbot Lake',
    'Beachburg',
    'Morewood',
    'Spring Hill',
    'Allenford',
    'Emerald',
    'Alameda',
    'Manor',
    'Ceylon',
    'Naicam',
    'Wright-Gracefield-Northfield',
    'Frelighsburg',
    'Lyster',
    'Saint-Benjamin',
    "Saint-Francois-de-l'Ile-d'Orleans",
    'Vauxhall',
    'Tennion',
  ],
  tld: ['.ca'],
  cca2: 'CA',
  ccn3: '124',
  cca3: 'CAN',
  cioc: 'CAN',
  currency: ['CAD'],
  callingCode: ['1'],
  capital: 'Ottawa',
  altSpellings: ['CA'],
  region: 'Americas',
  subregion: 'Northern America',
  languages: { eng: 'English', fra: 'French' },
  translations: {
    cym: { official: 'Canada', common: 'Canada' },
    deu: { official: 'Kanada', common: 'Kanada' },
    fra: { official: 'Canada', common: 'Canada' },
    hrv: { official: 'Kanada', common: 'Kanada' },
    ita: { official: 'Canada', common: 'Canada' },
    jpn: { official: 'カナダ', common: 'カナダ' },
    nld: { official: 'Canada', common: 'Canada' },
    por: { official: 'Canadá', common: 'Canadá' },
    rus: { official: 'Канада', common: 'Канада' },
    spa: { official: 'Canadá', common: 'Canadá' },
    fin: { official: 'Kanada', common: 'Kanada' },
  },
  latlng: [60, -95],
  demonym: 'Canadian',
  landlocked: false,
  borders: ['USA'],
  area: 9984670,
  provinces: [
    'Alberta',
    'British Columbia',
    'Manitoba',
    'New Brunswick',
    'Newfoundland',
    'Northwest Territories',
    'Nova Scotia',
    'Nunavut',
    'Ontario',
    'Prince Edward Island',
    'Quebec',
    'Saskatchewan',
    'Yukon Territory',
  ],
  nativeName: 'Canada',
  timezones: [
    'UTC−08:00',
    'UTC−07:00',
    'UTC−06:00',
    'UTC−05:00',
    'UTC−04:00',
    'UTC−03:30',
  ],
} as const;
