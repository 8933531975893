import { Module, Action } from '@vuex/decorators';
import { TablePanel } from 'table-panel';

import { dateFilter } from '@filters/date';
import { PromotionCode } from '@models';
import { RoleId } from '@values/roles';

import { faEdit } from '@icons/solid/faEdit';

const columns: TablePanel.Column<PromotionCode>[] = [
  {
    key: 'code',
    label: 'Code',
    value: 'code',
  },
  {
    key: 'coupon',
    label: 'Coupon',
    value: ({ coupon }) => coupon.name,
  },
  {
    key: 'amount',
    label: 'Amount',
    value: ({ coupon }) => coupon.amount_off,
    placeholder: 'N/A',
  },
  {
    key: 'percent',
    label: 'Percent',
    value: ({ coupon }) => `${coupon.percent_off}%`,
    placeholder: 'N/A',
  },
  {
    key: 'uses',
    label: 'Uses/Max Uses',
    value: ({ coupon, max_redemptions }) => {
      const uses = coupon.times_redeemed;
      const maxUses = max_redemptions ?? null;

      return maxUses !== 0 ? `${uses ?? '--'}/${maxUses ?? '--'}` : null;
    },
    placeholder: 'N/A',
  },
  {
    key: 'expires_at',
    label: 'Expiration Date',
    value: ({ expires_at }) => {
      return expires_at ? dateFilter(expires_at, 'MM/dd/yyyy') : null;
    },
    placeholder: 'N/A',
  },
  {
    key: 'created',
    label: 'Created',
    value: ({ created }) => {
      return created ? dateFilter(created, 'MM/dd/yyyy') : null;
    },
    placeholder: 'N/A',
  },
];

const operations: TablePanel.Row.Operation<PromotionCode>[] = [
  {
    label: 'Manage',
    icon: faEdit,
    fn: ({ coupon }) => openStripeDashboard(`coupons/${coupon.id}`),
  },
];

@Module({ namespaced: true })
export class PromotionCodesTablePanel
  extends TablePanel<PromotionCode>
  implements TablePanel.Props<PromotionCode>
{
  readonly module = 'promotionCodes';
  readonly loadAction = 'list';
  readonly pk = 'id';
  readonly label = 'PROMOTION CODES';
  readonly columns = columns;
  readonly operations = operations;
  readonly createAction = 'createPromotionCode';
  readonly tableSortType = 'createdAt';
  readonly tableSortReverse = true;
  readonly progressive = false;

  get enabled() {
    return this.isActiveRole(RoleId.LasAdmin);
  }

  @Action
  createPromotionCode() {
    openStripeDashboard('coupons/create');
  }
}

export namespace PromotionCodesTablePanel {
  export interface DeleteItemOptions {
    items: PromotionCode[];
  }
}

//#region Helper Functions

const STRIPE_WEB_BASE = 'https://dashboard.stripe.com/';

function openStripeDashboard(subUrl?: string) {
  let url = STRIPE_WEB_BASE;

  if (process.env.NODE_ENV === 'development') {
    url += 'test/';
  }

  if (subUrl) {
    url += subUrl;
  }

  window.open(url, '_blank');
}

//#endregion Helper Functions
