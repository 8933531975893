import { server } from '@api/request';
import { PromotionCode } from '@models';
import { isObject } from '@tools/type-guards';

/**
 * List all existing Stripe promotion codes.
 *
 * @returns A promise that resolves to a list of promotion codes.
 *
 * @see {@link ZephyrApi.Admin.PromotionCode.listPromotionCodes `ListPromotionCodes`} API route in `zephyr-serverless`
 */
export async function list() {
  return await server.list(
    'v2/admin/promotion-codes',
    processPromotionCodeData,
  );
}

//#region Helper Functions

/**
 * Determine if a value is a valid {@link PromotionCode}.
 *
 * @param value The value to check.
 * @returns `true` if the value is a valid {@link PromotionCode}, otherwise
 * `false`.
 */
function isValidPromotionCodeData(value: unknown): value is PromotionCode {
  if (!isObject(value)) return false;

  // TEMP: Use a more strict validation.

  return true;
}

/**
 * Process data received from a request expected to be a {@link PromotionCode}.
 *
 * @param data Data received from the request.
 * @returns The processed data value.
 */
function processPromotionCodeData(data: unknown) {
  if (!isValidPromotionCodeData(data)) {
    throw new Error('Invalid promotion code data item.');
  }

  return { ...data } as PromotionCode;
}

//#endregion Helper Functions
