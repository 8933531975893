export const SYC = {
  name: {
    common: 'Seychelles',
    official: 'Republic of Seychelles',
    native: {
      crs: { official: 'Repiblik Sesel', common: 'Sesel' },
      eng: { official: 'Republic of Seychelles', common: 'Seychelles' },
      fra: { official: 'République des Seychelles', common: 'Seychelles' },
    },
  },
  cities: ['Victoria', 'Takamaka'],
  tld: ['.sc'],
  cca2: 'SC',
  ccn3: '690',
  cca3: 'SYC',
  cioc: 'SEY',
  currency: ['SCR'],
  callingCode: ['248'],
  capital: 'Victoria',
  altSpellings: [
    'SC',
    'Republic of Seychelles',
    'Repiblik Sesel',
    'République des Seychelles',
  ],
  region: 'Africa',
  subregion: 'Eastern Africa',
  languages: { crs: 'Seychellois Creole', eng: 'English', fra: 'French' },
  translations: {
    deu: { official: 'Republik der Seychellen', common: 'Seychellen' },
    fra: { official: 'République des Seychelles', common: 'Seychelles' },
    hrv: { official: 'Republika Sejšeli', common: 'Sejšeli' },
    ita: { official: 'Repubblica delle Seychelles', common: 'Seychelles' },
    jpn: { official: 'セイシェル共和国', common: 'セーシェル' },
    nld: { official: 'Republiek der Seychellen', common: 'Seychellen' },
    por: { official: 'República das Seychelles', common: 'Seicheles' },
    rus: {
      official: 'Республика Сейшельские Острова',
      common: 'Сейшельские Острова',
    },
    spa: { official: 'República de las Seychelles', common: 'Seychelles' },
    fin: { official: 'Seychellien tasavalta', common: 'Seychellit' },
  },
  latlng: [-4.58333333, 55.66666666],
  demonym: 'Seychellois',
  landlocked: false,
  borders: [],
  area: 452,
} as const;
