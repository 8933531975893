var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("Transition", { attrs: { appear: "" } }, [
    _c(
      "div",
      {
        staticClass: "parallax-layer",
        style: { ..._setup.style, transform: _setup.transform },
      },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }