import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import { faFacebook } from '@icons/brands/faFacebook';
import { faXTwitter } from '@icons/brands/faXTwitter';
import { faInstagram } from '@icons/brands/faInstagram';
import { faYoutube } from '@icons/brands/faYoutube';
import { faLinkedin } from '@icons/brands/faLinkedin';

export interface ExternalSiteLink {
  id: string;
  title: string;
  url: string;
  icon?: IconDefinition;
}

/**
 * Collection of various external links related to Zephyr.
 */
export const externalLinks = {
  //#region Resources

  wiki: {
    id: 'wiki',
    title: 'Wiki',
    url: 'https://wiki.zephyr-sim.com',
  },
  faqs: {
    id: 'faqs',
    title: 'FAQs',
    url: 'https://wiki.zephyr-sim.com/help/faqs',
  },
  patchNotes: {
    id: 'patch-notes',
    title: 'Patch Notes',
    url: 'https://wiki.zephyr-sim.com/patch-notes/zephyr',
  },
  roadmap: {
    id: 'roadmap',
    title: 'Roadmap',
    url: 'https://wiki.zephyr-sim.com/roadmap',
  },

  //#endregion Resources

  //#region Platform,

  enterpriseBilling: {
    id: 'enterprise-billing',
    title: 'Enterprise Billing',
    url: 'https://wiki.zephyr-sim.com/learning-management-system/enterprise-billing',
  },
  integrations: {
    id: 'integrations',
    title: 'Integrations',
    url: 'https://wiki.zephyr-sim.com/integrations',
  },
  security: {
    id: 'security',
    title: 'Security',
    url: 'https://wiki.zephyr-sim.com/privacy-and-security/security',
  },
  status: {
    id: 'status',
    title: 'Status',
    url: 'https://zephyr.statuspage.io',
  },

  //#endregion Platform

  //#region Social

  facebook: {
    id: 'facebook',
    title: 'Facebook',
    url: 'https://www.facebook.com/littlearmsstudios',
    icon: faFacebook,
  },
  twitter: {
    id: 'twitter',
    title: 'Twitter',
    url: 'https://twitter.com/LASgames',
    icon: faXTwitter,
  },
  instagram: {
    id: 'instagram',
    title: 'Instagram',
    url: 'https://www.instagram.com/zephyr_sim',
    icon: faInstagram,
  },
  linkedin: {
    id: 'linkedin',
    title: 'LinkedIn',
    url: 'https://www.linkedin.com/company/little-arms-studios',
    icon: faLinkedin,
  },
  youtube: {
    id: 'youtube',
    title: 'YouTube',
    url: 'https://www.youtube.com/@littlearmsstudios549',
    icon: faYoutube,
  },

  //#endregion Social
} as const satisfies Record<string, ExternalSiteLink>;
