export const PAN = {
  name: {
    common: 'Panama',
    official: 'Republic of Panama',
    native: { spa: { official: 'República de Panamá', common: 'Panamá' } },
  },
  cities: [
    'Panama City',
    'Santa Catalina',
    'Playa Blanca',
    'El Arado',
    'Cocle',
    'Chiriqui',
    'ColÃ³n',
    'Plaza',
    'Bocas del Toro',
    'Guadalupe',
    'PenonomÃ©',
    'Chigore',
    'David',
    'Boquete',
    'Portobelo',
    'Bella Vista',
    'Pueblo Nuevo',
    'Veraguas',
    'Sacramento',
    'Arraijan',
    'Paitilla',
    'Santa Clara',
    'Balboa',
    'Torre',
    'Kuba',
    'ChitrÃ©',
    'Santiago',
    'San Miguelito',
    'La Chorrera',
    'Juan Franco',
    'Juan Diaz',
    'Vista Alegre',
    'Juan Diaz',
    'Mulatupo',
    'Torres Bluff',
    'Entre Rios',
    'Las Mercedes',
    'Rio Abajo',
    'El Cangrejo',
    'San Francisco',
    'Parque Lefevre',
    'Llano Tugri',
    'Bugaba',
    'Santo Domingo',
    'Ancon',
    'Las Tablas',
    'Cerro Viento',
    'San Felipe',
    'Santa Isabel',
    'San Cristobal',
    'Quebrada de Camilo',
    'Arosemena',
    'Tocumen',
    'Las Sabanas',
    'Los Angeles',
    'Calidonia',
    'Corozal',
    'Santa Ana',
    'Curundu',
    'Elena',
    'Albrook',
    'Jimenez',
    'El Lago',
    'Campo Alegre',
    'Vista Hermosa',
    'Muerto',
    'Victoria',
    'La Loma',
    'Finca Blanco Numero Uno',
    'La Exposicion',
    'Cristobal',
    'Fuerte Amador',
    'Aguadulce',
    'Peru',
    'Sabalo',
    'PlayÃ³n Chico',
    'Curundame',
  ],
  tld: ['.pa'],
  cca2: 'PA',
  ccn3: '591',
  cca3: 'PAN',
  cioc: 'PAN',
  currency: ['PAB', 'USD'],
  callingCode: ['507'],
  capital: 'Panama City',
  altSpellings: ['PA', 'Republic of Panama', 'República de Panamá'],
  region: 'Americas',
  subregion: 'Central America',
  languages: { spa: 'Spanish' },
  translations: {
    deu: { official: 'Republik Panama', common: 'Panama' },
    fra: { official: 'République du Panama', common: 'Panama' },
    hrv: { official: 'Republika Panama', common: 'Panama' },
    ita: { official: 'Repubblica di Panama', common: 'Panama' },
    jpn: { official: 'パナマ共和国', common: 'パナマ' },
    nld: { official: 'Republiek Panama', common: 'Panama' },
    por: { official: 'República do Panamá', common: 'Panamá' },
    rus: { official: 'Республика Панама', common: 'Панама' },
    spa: { official: 'República de Panamá', common: 'Panamá' },
    fin: { official: 'Panaman tasavalta', common: 'Panama' },
  },
  latlng: [9, -80],
  demonym: 'Panamanian',
  landlocked: false,
  borders: ['COL', 'CRI'],
  area: 75417,
  provinces: [
    'Bocas del Toro',
    'Chiriqui',
    'Cocle',
    'Colon',
    'Darien',
    'Herrera',
    'Los Santos',
    'Panama',
    'San Blas',
    'Veraguas',
  ],
  nativeName: 'Panamá',
  timezones: ['UTC−05:00'],
} as const;
