export const IDN = {
  name: {
    common: 'Indonesia',
    official: 'Republic of Indonesia',
    native: { ind: { official: 'Republik Indonesia', common: 'Indonesia' } },
  },
  cities: [
    'Bandung',
    'Jakarta',
    'Samarinda',
    'Pekanbaru',
    'Balikpapan',
    'Padang',
    'Patam',
    'Malang',
    'Medan',
    'Pangaturan',
    'Tebingtinggi',
    'Sungailiat',
    'Palembang',
    'Bengkalis',
    'Jambi City',
    'Depok',
    'Bogor',
    'Sangereng',
    'Bekasi',
    'Karawang',
    'Sukabumi',
    'Tasikmalaya',
    'Subang',
    'Ciamis',
    'Cirebon',
    'Garut',
    'Kuningan',
    'Majalengka',
    'Sumedang',
    'Sukoharjo',
    'Semarang',
    'Pekalongan',
    'Kudus',
    'Klaten',
    'Jepara',
    'Demak',
    'Salatiga',
    'Tegal',
    'Yogyakarta',
    'Sleman',
    'Cilacap',
    'Magelang',
    'Wonosobo',
    'Surakarta',
    'Bantul',
    'Temanggung',
    'Kebumen',
    'Purwokerto',
    'Purbalingga',
    'Kulon',
    'Surabaya',
    'Bangkalan',
    'Pasuruan',
    'Mojokerto',
    'Sidoarjo',
    'Surabayan',
    'Batu',
    'Blitar',
    'Lumajang',
    'Tulungagung',
    'Magetan',
    'Kediri',
    'Trenggalek',
    'Madiun',
    'Ngawi',
    'Nganjuk',
    'Bojonegoro',
    'Banyuwangi',
    'Jember',
    'Situbondo',
    'Probolinggo',
    'Gresik',
    'Lamongan',
    'Pamekasan',
    'Pontianak',
    'Singkawang',
    'Banjarmasin',
    'Buntok',
    'Bontang',
    'Palangkaraya',
    'Tarakan',
    'Denpasar',
    'Badung',
    'Ubud',
    'Mataram',
    'Selong',
    'Manado',
    'Tondano',
    'Bitung',
    'Bima',
    'Sungguminasa',
    'Adiantorop',
    'Makassar',
    'Sekupang',
    'Kota',
    'Bangkinang',
    'Binjai',
    'Banda Aceh',
    'Lhokseumawe',
    'Serdang',
    'Balige',
    'Lampeong',
    'Baturaja',
    'Bandar',
    'Cimahi',
    'Indramayu',
    'Banyumas',
    'Jombang',
    'Mojoagung',
    'Kepanjen',
    'Ponorogo',
    'Pacitan',
    'Palu',
    'Sengkang',
    'Gorontalo',
    'Gianyar',
    'Jayapura',
    'Soasio',
    'Wonosari',
    'Bengkulu',
    'Guntung',
    'Langsa',
    'Kerinci',
    'Porsea',
    'Bali',
    'Cianjur',
    'Tirtagangga',
    'Purworejo',
    'Pandeglang',
    'Tigaraksa',
    'Cilegon',
    'Cilegon',
    'Sanur',
    'Darussalam',
    'Kupang',
    'Bandar Lampung',
    'Pati',
    'Panasuan',
    'Darmaga',
    'Dumai',
    'Timur',
    'Riau',
    'Bukit Tinggi',
    'Parman',
    'Cihampelas',
    'Tangsel',
    'Duren',
    'Angkasa',
    'Jimbaran',
    'Menara',
    'Pamulang',
    'Bantan',
    'Baratjaya',
    'Utara',
    'Veteran',
    'Tengah',
    'Tenggara',
    'Selatan',
    'Simpang',
    'Gunungsitoli',
    'Pemalang',
    'Tenggarong',
    'Tanjung Balai',
    'Serang',
    'Cikarang',
    'Cibitung',
    'Bondowoso',
    'Singaraja',
    'Poso',
    'Ambon City',
    'Negeribesar',
    'Cempaka',
    'Lestari',
    'Kandangan',
    'Ciputat',
    'Kartasura',
    'Jagakarsa',
    'Pondok',
    'Solo',
    'Polerejo',
    'Muntilan',
    'Boyolali',
    'Nusantara',
    'Cipinanglatihan',
    'Kalimantan',
    'Serang',
    'Serpong',
    'Cikini',
    'Purwodadi Grobogan',
    'Kendal',
    'Tanjungpinang',
    'Lubuk Pakam',
    'Nusa',
    'Kelapa Dua',
    'Gandul',
    'Gedung',
    'Tanjung',
    'Kuta',
    'Kalideres',
    'Mega',
    'Area',
    'Wilayah',
    'Soho',
    'Menteng',
    'Tuban',
    'Cilincing',
    'Sunggal',
    'Sijunjung',
    'Kerobokan',
    'Negara',
    'Amlapura',
    'Baubau',
    'Karanganyar',
    'Sampang',
    'Depok Jaya',
    'Parakan',
    'Lawang',
    'Pare',
    'Airmadidi',
    'Tembagapura',
    'Banjarbaru',
    'Palangka',
    'Cimanggis',
    'Kebayoran Baru',
    'Lapan',
    'Pusat',
    'Sigli',
    'Kabanjahe',
    'Pematangsiantar',
    'Payakumbuh',
    'Kebayoran Lama Selatan',
    'Tigarasa',
    'Purwakarta',
    'Cibubur',
    'Wonogiri',
    'Sragen',
    'Ungaran',
    'Batang',
    'Ambarawa',
    'Palaihari',
    'Tanjung',
    'Sampit',
    'Bulukumba',
    'Bangli',
    'Soe',
    'Nusa Dua',
    'Stabat',
    'Maros',
    'Tipar Timur',
    'Holis',
    'Banjarnegara',
    'Banjar',
    'Kopeng',
    'Duri',
    'Bantaeng',
    'Blora',
    'Tomohon',
    'Citeureup',
    'Pekan',
    'Mamuju',
    'Badung',
    'Abadi',
    'Anggrek',
    'Sejahtera',
    'Cakrawala',
    'Indo',
    'Sentul',
    'Utama',
    'Mail',
    'Udayana',
    'Cengkareng',
    'Kemang',
    'Tabanan',
  ],
  tld: ['.id'],
  cca2: 'ID',
  ccn3: '360',
  cca3: 'IDN',
  cioc: 'INA',
  currency: ['IDR'],
  callingCode: ['62'],
  capital: 'Jakarta',
  altSpellings: ['ID', 'Republic of Indonesia', 'Republik Indonesia'],
  region: 'Asia',
  subregion: 'South-Eastern Asia',
  languages: { ind: 'Indonesian' },
  translations: {
    deu: { official: 'Republik Indonesien', common: 'Indonesien' },
    fra: { official: "République d'Indonésie", common: 'Indonésie' },
    hrv: { official: 'Republika Indonezija', common: 'Indonezija' },
    ita: { official: 'Repubblica di Indonesia', common: 'Indonesia' },
    jpn: { official: 'インドネシア共和国', common: 'インドネシア' },
    nld: { official: 'Republiek Indonesië', common: 'Indonesië' },
    por: { official: 'República da Indonésia', common: 'Indonésia' },
    rus: { official: 'Республика Индонезия', common: 'Индонезия' },
    spa: { official: 'República de Indonesia', common: 'Indonesia' },
    fin: { official: 'Indonesian tasavalta', common: 'Indonesia' },
  },
  latlng: [-5, 120],
  demonym: 'Indonesian',
  landlocked: false,
  borders: ['TLS', 'MYS', 'PNG'],
  area: 1904569,
  provinces: [
    'Aceh',
    'Bali',
    'Banten',
    'Bengkulu',
    'Gorontalo',
    'Jakarta',
    'Jambi',
    'Jawa Barat',
    'Jawa Tengah',
    'Jawa Timur',
    'Kalimantan Barat',
    'Kalimantan Selatan',
    'Kalimantan Tengah',
    'Kalimantan Timur',
    'Kalimantan Utara',
    'Kepulauan Bangka Belitung',
    'Kepulauan Riau',
    'Lampung',
    'Maluku',
    'Maluku Utara',
    'Nusa Tenggara Barat',
    'Nusa Tenggara Timur',
    'Papua',
    'Papua Barat',
    'Riau',
    'Sulawesi Barat',
    'Sulawesi Selatan',
    'Sulawesi Tengah',
    'Sulawesi Utara',
    'Sumatera Barat',
    'Sumatera Selatan',
    'Sumatera Utara',
    'Yogyakarta',
  ],
  nativeName: 'Indonesia',
  timezones: ['UTC+07:00', 'UTC+08:00', 'UTC+09:00'],
} as const;
