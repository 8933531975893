import { Module } from '@vuex/decorators';
import { TablePanel } from 'table-panel';

import { faArrowUp } from '@icons/solid/faArrowUp';
import { faArrowDown } from '@icons/solid/faArrowDown';

import { api } from '@api';
import { License } from '@models';
import { modals } from '@modals';
import { alert } from '@services/alert';
import { ensureError } from '@tools/ensure-error';

/** ... */
const columns: TablePanel.Column<License>[] = [
  {
    key: 'key',
    label: 'Key',
    value: 'key',
    component: 'TableCellLicenseKey',
  },
  {
    key: 'createdAt',
    label: 'Created',
    type: 'date',
    value: 'createdAt',
  },
  {
    key: 'active',
    label: 'Active',
    type: 'boolean',
    value: 'active',
  },
  {
    key: 'userId',
    label: 'User ID',
    value: 'userId',
  },
  {
    key: 'productId',
    label: 'Product ID',
    value: 'productId',
  },
  {
    key: 'expiration',
    label: 'Expiration',
    value: 'expiration',
  },
  {
    key: 'preGenerated',
    label: 'Pre-generated',
    type: 'boolean',
    value: 'preGenerated',
  },
];

/** ... */
const operations: TablePanel.Row.Operation<License>[] = [
  {
    label: 'Set Inactive',
    icon: faArrowDown,
    // TODO: Move to liscnes store dispath method.
    fn: async (license) => {
      try {
        await api.licenses.setState({ id: license.id, active: false });
      } catch (err) {
        return alert.error(ensureError(err).feedback ?? err);
      }

      license.active = false;
    },
    hidden: (license) => !license.active,
  },
  {
    label: 'Set Active',
    icon: faArrowUp,
    // TODO: Move to liscnes store dispath method.
    fn: async (license) => {
      try {
        await api.licenses.setState({ id: license.id, active: true });
      } catch (err) {
        return alert.error(ensureError(err).feedback ?? err);
      }

      license.active = true;
    },
    hidden: (license) => license.active,
  },
];

const filterFields = ['name', 'email'];

@Module({ namespaced: true })
export class LicensesTablePanel
  extends TablePanel<License>
  implements TablePanel.Props<License>
{
  readonly module = 'licenses';
  readonly loadAction = 'list';
  readonly loadPageAction = 'loadPage';
  readonly pk = 'id';
  readonly label = 'LICENSES';
  readonly columns = columns;
  readonly operations = operations;
  readonly filterFields = filterFields;
  readonly progressive = true;

  get enabled() {
    return this.isActiveRole(9);
  }

  get tableMenu() {
    const items: TablePanel.MenuItem[] = [];

    // <div class="toggle">
    //   <div class="switch pull-right">
    //     <input type="checkbox" ng-model="$ctrl.showOnlyGenLicenses">
    //     <div class="slider" ng-click="$ctrl.showOnlyGenLicenses = !$ctrl.showOnlyGenLicenses"></div>
    //   </div>
    //   <label class="pull-right" style="margin-right:10px">Show Pre Generated Licenses Only</label>
    // </div>

    items.push({
      key: 'generate',
      label: 'GENERATE',
      // $ctrl.create('licenses')
      click: () => {
        void modals.account.generateLicenses();
      },
    });

    return items;
  }
}
