export const TON = {
  name: {
    common: 'Tonga',
    official: 'Kingdom of Tonga',
    native: {
      eng: { official: 'Kingdom of Tonga', common: 'Tonga' },
      ton: { official: 'Kingdom of Tonga', common: 'Tonga' },
    },
  },
  cities: ['Neiafu', 'Kanokupolu'],
  tld: ['.to'],
  cca2: 'TO',
  ccn3: '776',
  cca3: 'TON',
  cioc: 'TGA',
  currency: ['TOP'],
  callingCode: ['676'],
  capital: "Nuku'alofa",
  altSpellings: ['TO'],
  region: 'Oceania',
  subregion: 'Polynesia',
  languages: { eng: 'English', ton: 'Tongan' },
  translations: {
    deu: { official: 'Königreich Tonga', common: 'Tonga' },
    fra: { official: 'Royaume des Tonga', common: 'Tonga' },
    hrv: { official: 'Kraljevina Tonga', common: 'Tonga' },
    ita: { official: 'Regno di Tonga', common: 'Tonga' },
    jpn: { official: 'トンガ王国', common: 'トンガ' },
    nld: { official: 'Koninkrijk Tonga', common: 'Tonga' },
    por: { official: 'Reino de Tonga', common: 'Tonga' },
    rus: { official: 'Королевство Тонга', common: 'Тонга' },
    spa: { official: 'Reino de Tonga', common: 'Tonga' },
    fin: { official: 'Tongan kuningaskunta', common: 'Tonga' },
  },
  latlng: [-20, -175],
  demonym: 'Tongan',
  landlocked: false,
  borders: [],
  area: 747,
} as const;
