import { server } from '@api/request';
import * as models from '@models';
import { isObject } from '@tools/type-guards';

/**
 * List all existing assignments.
 *
 * @return A list of assignments.
 */
export async function list() {
  return await server.list(`v2/admin/assignments`, processAssignmentData);
}

/**
 * ...
 */
export interface ListByOrganizationOptions {
  organizationId: models.Organization['id'];
}

/**
 * List all assignments issued under a specified organization.
 *
 * @param options Request options bag.
 * @return A list of assignments.
 */
export async function listByOrganization(options: ListByOrganizationOptions) {
  return await server.list(
    `v2/organizations/${options.organizationId}/assignments`,
    processAssignmentData,
  );
}

/**
 * ...
 */
export interface ListByCourseOptions {
  organizationId: models.Organization['id'];
  courseId: models.Course['id'];
}

/**
 * List all assignments that belong to a specified course.
 *
 * @param options Request options bag.
 * @return A list of assignments.
 */
export async function listByCourse(options: ListByCourseOptions) {
  return await server.list(
    `v2/organizations/${options.organizationId}/courses/${options.courseId}/assignments`,
    processAssignmentData,
  );
}

/**
 * ...
 */
export interface GetOptions {
  assignmentId: models.Assignment['id'];
  organizationId: models.Organization['id'];
  courseId: models.Course['id'];
}

/**
 * Get a specified assignment.
 *
 * @param options Request options bag.
 * @return The specified assignment.
 */
export async function get(options: GetOptions) {
  return await server.get(
    `v2/organizations/${options.organizationId}/courses/${options.courseId}/assignments/${options.assignmentId}`,
    processAssignmentData,
  );
}

/**
 * ...
 */
export interface CreateOptions {
  organizationId: models.Organization['id'];
  courseId: models.Course['id'];
  moduleId: models.Module['id'];
  sceneId: models.Scene['id'];
  dueDate: string;
  startDate: string | null;
  endDate: string | null;
  details: string | null;
  minimumScore: number | null;
  totalTime: number | null;
}

/**
 * Create assignment.
 *
 * @param options Request options bag.
 */
export async function create(options: CreateOptions) {
  const { organizationId, courseId, ...data } = options;

  return await server.post(
    `v2/organizations/${organizationId}/courses/${courseId}/assignments`,
    data,
    processAssignmentData,
  );
}

/**
 * ...
 */
export interface UpdateOptions {
  assignmentId: models.Assignment['id'];
  organizationId: models.Organization['id'];
  courseId: models.Course['id'];
  moduleId?: models.Module['id'];
  sceneId?: models.Scene['id'];
  dueDate?: string;
  startDate?: string | null;
  endDate?: string | null;
  details?: string | null;
  minimumScore?: number | null;
  totalTime?: number | null;
}

/**
 * Update assignment.
 *
 * @param options Request options bag.
 */
export async function update(options: UpdateOptions) {
  const { assignmentId, organizationId, courseId, ...data } = options;

  return await server.post(
    `v2/organizations/${organizationId}/courses/${courseId}/assignments/${assignmentId}`,
    data,
    processAssignmentData,
  );
}

/**
 * ...
 */
export interface DeleteOptions {
  assignmentId: models.Assignment['id'];
  organizationId: models.Organization['id'];
  courseId: models.Course['id'];
}

/**
 * Delete a specified product.
 *
 * @param options Request options bag.
 */
export async function del(options: DeleteOptions) {
  await server.delete(
    `v2/organizations/${options.organizationId}/courses/${options.courseId}/assignments/${options.assignmentId}`,
  );
}

//#region Helper Functions

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
function isValidAssignmentItem(value: unknown): value is models.Assignment {
  if (!isObject(value)) return false;

  // TEMP: Use a more strict validation.

  return true;
}

/**
 * ...
 *
 * @param data ...
 * @return ...
 */
function processAssignmentData(data: unknown) {
  if (!isValidAssignmentItem(data)) {
    throw new Error('Invalid assignment data item.');
  }

  return { ...data } as models.Assignment;
}

//#endregion Helper Functions
