var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "organization-billing-type-table-cell" }, [
    _setup.value === "BillMeLater" ? _c("span", [_vm._v("Billed")]) : _vm._e(),
    _vm._v(" "),
    _setup.value === "POT"
      ? _c("span", [_vm._v("Point of Transaction")])
      : _vm._e(),
    _vm._v(" "),
    _setup.value === "StudentPays"
      ? _c("span", [_vm._v("Student Pays")])
      : _vm._e(),
    _vm._v(" "),
    _setup.value === "SiteLicense"
      ? _c("span", [_vm._v("Site License")])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }