import { server } from '@api/request';
import { DownloadLink } from '@interfaces';

/** ... */
const ROOT_LAUNCHER_URL = 'https://download.littlearms.com/launcher/';

/** ... */
export type OperatingSystem = 'macOS' | 'windows';

/** ... */
export interface GetDownloadLinksResponse {
  links: DownloadLink[];
}

/**
 * ...
 */
export interface LauncherLinkInfo {
  sha512: string;
  size: number;
  url: string;
}

/**
 * ...
 */
export interface LauncherDownloadInfo {
  path: string;
  releaseDate: string;
  sha512: string;
  version: string;
  files: LauncherLinkInfo[];
}

/** ... */
export interface GetDownloadLinksOptions {
  os?: OperatingSystem;
  beta?: string;
}

/**
 * Get download links for Little Arms Launcher.
 *
 * @return Download link data.
 */
export async function getDownloadLinks(options: GetDownloadLinksOptions) {
  const platRequested = options.os;
  const os = options.os ?? 'windows';

  const baseUrl = `v2/launcher/${options.beta ? 'beta/' : 'latest/'}`;

  const data = await server.get<LauncherDownloadInfo>(baseUrl + os);

  if (options.beta) {
    const name = (os === 'macOS' ? 'MAC' : 'WINDOWS') + '  (BETA)';

    return {
      links: [
        {
          name,
          icon: 'download',
          link: ROOT_LAUNCHER_URL + data.path.replace(/ /g, '+'),
        },
      ],
    };
  }

  const link = createLauncherLink(data.files, os);

  if (platRequested) return { links: link ? [link] : [] };

  const macData = await server.get<LauncherDownloadInfo>(baseUrl + 'Mac');

  const macLink = createLauncherLink(macData.files, 'macOS');

  return { links: [link, macLink] } as GetDownloadLinksResponse;
}

//#region Helper Functions

/**
 * ...
 *
 * @param files ...
 * @param os ...
 */
function createLauncherLink(files: LauncherLinkInfo[], os: OperatingSystem) {
  let extension = '';
  let platform = '';
  let icon = '';

  if (os === 'macOS') {
    extension = '.dmg';
    platform = 'MAC';
    icon = 'apple';
  } else {
    extension = '.exe';
    platform = 'WINDOWS';
    icon = 'windows';
  }

  const file = files.find((file) => file.url.includes(extension));

  if (!file) return;

  const link = ROOT_LAUNCHER_URL + file.url.replace(/ /g, '+');

  return { name: platform, icon, link } as DownloadLink;
}

//#endregion Helper Functions
