var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { ref: "el", staticClass: "table-options-column" }, [
    _vm.item
      ? _c(
          "div",
          {
            class: ["column-button", { selected: _setup.flyout.isOpen }],
            on: { click: _setup.toggleFlyout },
          },
          [_c("Icon", { attrs: { icon: _setup.faCog } })],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }