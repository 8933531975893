export const ISL = {
  name: {
    common: 'Iceland',
    official: 'Iceland',
    native: { isl: { official: 'Ísland', common: 'Ísland' } },
  },
  tld: ['.is'],
  cca2: 'IS',
  ccn3: '352',
  cca3: 'ISL',
  cioc: 'ISL',
  currency: ['ISK'],
  callingCode: ['354'],
  capital: 'Reykjavik',
  altSpellings: ['IS', 'Island', 'Republic of Iceland', 'Lýðveldið Ísland'],
  region: 'Europe',
  subregion: 'Northern Europe',
  languages: { isl: 'Icelandic' },
  translations: {
    deu: { official: 'Island', common: 'Island' },
    fra: { official: "République d'Islande", common: 'Islande' },
    hrv: { official: 'Island', common: 'Island' },
    ita: { official: 'Islanda', common: 'Islanda' },
    jpn: { official: 'アイスランド', common: 'アイスランド' },
    nld: { official: 'IJsland', common: 'IJsland' },
    por: { official: 'Islândia', common: 'Islândia' },
    rus: { official: 'Исландия', common: 'Исландия' },
    spa: { official: 'Islandia', common: 'Islandia' },
    fin: { official: 'Islanti', common: 'Islanti' },
  },
  latlng: [65, -18],
  demonym: 'Icelander',
  landlocked: false,
  borders: [],
  area: 103000,
  provinces: [
    'Akranes',
    'Akureyri',
    'Arnessysla',
    'Austur-Bardhastrandarsysla',
    'Austur-Hunavatnssysla',
    'Austur-Skaftafellssysla',
    'Borgarfjardharsysla',
    'Dalasysla',
    'Eyjafjardharsysla',
    'Gullbringusysla',
    'Hafnarfjordhur',
    'Husavik',
    'Isafjordhur',
    'Keflavik',
    'Kjosarsysla',
    'Kopavogur',
    'Myrasysla',
    'Neskaupstadhur',
    'Nordhur-Isafjardharsysla',
    'Nordhur-Mulasys-la',
    'Nordhur-Thingeyjarsysla',
    'Olafsfjordhur',
    'Rangarvallasysla',
    'Reykjavik',
    'Saudharkrokur',
    'Seydhisfjordhur',
    'Siglufjordhur',
    'Skagafjardharsysla',
    'Snaefellsnes-og Hnappadalssysla',
    'Strandasysla',
    'Sudhur-Mulasysla',
    'Sudhur-Thingeyjarsysla',
    'Vesttmannaeyjar',
    'Vestur-Bardhastrandarsysla',
    'Vestur-Hunavatnssysla',
    'Vestur-Isafjardharsysla',
    'Vestur-Skaftafellssysla',
  ],
  nativeName: 'Ísland',
  timezones: ['UTC'],
} as const;
