import { Module, Action } from '@vuex/decorators';
import { TablePanel } from 'table-panel';

import { faEye } from '@icons/solid/faEye';
import { faEdit } from '@icons/solid/faEdit';
import { faUsers } from '@icons/solid/faUsers';
import { faEnvelope } from '@icons/solid/faEnvelope';
import { faCopy } from '@icons/solid/faCopy';

import { router } from '@router';
import { modals } from '@modals';
import { Course } from '@models';
import { nameFormat } from '@utils/name-format';

/** ... */
const columns: TablePanel.Column<Course>[] = [
  {
    key: 'name',
    label: 'Name',
    value: 'name',
    component: 'TableCellCourseName',
  },
  {
    key: 'account',
    label: 'Account',
    value: ({ organization }) => organization.name,
    filterByFormatted: true,
    sortByFormatted: true,
  },
  {
    key: 'startDate',
    label: 'Start Date',
    type: 'date',
    value: 'startDate',
  },
  {
    key: 'endDate',
    label: 'End Date',
    type: 'date',
    value: 'endDate',
  },
  {
    key: 'teacher',
    label: 'Teacher',
    value: ({ teacher }) => `${nameFormat(teacher)}`,
  },
  {
    key: 'TeacherAssistant',
    label: 'Teacher Assistant',
    value: ({ teacherAssistant }) => {
      return !teacherAssistant ? 'N/A' : `${nameFormat(teacherAssistant)}`;
    },
  },
];

/** ... */
const operations: TablePanel.Row.Operation<Course>[] = [
  {
    label: 'View',
    icon: faEye,
    fn: ({ id, organization }) => {
      // void router.push(`/dashboard/course/${id}`);

      const params = { organizationId: organization.id, courseId: id };

      void router.push({ name: 'dashboardCourse', params });
    },
    // hidden: (course: CourseData, role) => {
    //   if (!role) return true;
    //   if (role?.id === 9 || role?.id === 5) return false;
    //   return (
    //     course.teacherId !== role.userId &&
    //     course.teacherAssistantId !== role.userId
    //   );
    // },
  },
  {
    label: 'Edit',
    icon: faEdit,
    fn: ({ id, organization }) => {
      void modals.course.edit({
        courseId: id,
        organizationId: organization.id,
      });
    },
    // hidden: (course: CourseData, role) => {
    //   if (!role) return true;
    //   if (role?.id === 9 || role?.id === 5) return false;
    //   return (
    //     course.teacherId !== role.userId &&
    //     course.teacherAssistantId !== role.userId
    //   );
    // },
  },
  {
    label: 'Create Copy',
    icon: faCopy,
    fn: ({ id, organization }) => {
      void modals.course.create({
        courseId: id,
        organizationId: organization.id,
      });
    },
  },
  {
    label: 'Manage Students',
    icon: faUsers,
    fn: ({ id, organization }) => {
      void modals.course.manageStudents({
        courseId: id,
        organizationId: organization.id,
      });
    },
    // hidden: (course: CourseData, role) => {
    //   if (!role) return true;
    //   if (role?.id === 9 || role?.id === 5) return false;
    //   return (
    //     course.teacherId !== role.userId &&
    //     course.teacherAssistantId !== role.userId
    //   );
    // },
  },
  {
    label: 'Send Extension',
    icon: faEnvelope,
    fn: (course) => {
      // $ctrl.sendExtensionCodes(course);
    },
    // TODO: Determine best way of handling the property -- search in original
    // project source for more info [admin controller "checkCourses" method].
    hidden: (course, role) => {
      return !course.extensionsRequired;
      // if (!role) return true;
      // if (role?.id === 9 || role?.id === 5) return !course.extensionsRequired;
      // if (
      //   course.teacherId === role.userId ||
      //   course.teacherAssistantId === role.userId
      // ) {
      //   return !course.extensionsRequired;
      // }
      // return true;
    },
  },
];

/** ... */
const filterFields = [
  'name',
  'institution',
  'createdAt',
  'teacher',
  'teacherAssistant',
];

@Module({ namespaced: true })
export class CourseTablePanel
  extends TablePanel<Course>
  implements TablePanel.Props<Course>
{
  readonly module = 'courses';
  readonly loadAction = 'list';
  readonly pk = 'id';
  readonly label = 'COURSES';
  readonly columns = columns;
  readonly operations = operations;
  readonly filterFields = filterFields;
  readonly createAction = 'createCourse';
  readonly deleteAction = 'deleteCourse';
  readonly tableSortType = 'startDate';
  readonly tableSortReverse = true;

  get enabled() {
    return this.isActiveRole(4, 5, 9);
  }

  @Action
  async createCourse() {
    await modals.course.create();
  }

  @Action
  async deleteCourse({ items }: CourseTablePanel.DeleteItemOptions) {
    await modals.confirm.deleteItems({ itemType: 'courses', items });
  }
}

export namespace CourseTablePanel {
  /** ... */
  export interface DeleteItemOptions {
    items: Course[];
  }
}
