export const PRK = {
  name: {
    common: 'North Korea',
    official: "Democratic People's Republic of Korea",
    native: { kor: { official: '조선 민주주의 인민 공화국', common: '북한' } },
  },
  cities: ['Pyongyang'],
  tld: ['.kp'],
  cca2: 'KP',
  ccn3: '408',
  cca3: 'PRK',
  cioc: 'PRK',
  currency: ['KPW'],
  callingCode: ['850'],
  capital: 'Pyongyang',
  altSpellings: [
    'KP',
    "Democratic People's Republic of Korea",
    '조선민주주의인민공화국',
    'Chosŏn Minjujuŭi Inmin Konghwaguk',
    "Korea, Democratic People's Republic of",
  ],
  region: 'Asia',
  subregion: 'Eastern Asia',
  languages: { kor: 'Korean' },
  translations: {
    deu: { official: 'Demokratische Volksrepublik Korea', common: 'Nordkorea' },
    fra: {
      official: 'République populaire démocratique de Corée',
      common: 'Corée du Nord',
    },
    hrv: {
      official: 'Demokratska Narodna Republika Koreja',
      common: 'Sjeverna Koreja',
    },
    ita: {
      official: 'Repubblica democratica popolare di Corea',
      common: 'Corea del Nord',
    },
    jpn: {
      official: '朝鮮民主主義人民共和国',
      common: '朝鮮民主主義人民共和国',
    },
    nld: {
      official: 'Democratische Volksrepubliek Korea',
      common: 'Noord-Korea',
    },
    por: {
      official: 'República Popular Democrática da Coreia',
      common: 'Coreia do Norte',
    },
    rus: {
      official: 'Корейская Народно-Демократическая Республика Корея',
      common: 'Северная Корея',
    },
    spa: {
      official: 'República Popular Democrática de Corea',
      common: 'Corea del Norte',
    },
    fin: {
      official: 'Korean demokraattinen kansantasavalta',
      common: 'Pohjois-Korea',
    },
  },
  latlng: [40, 127],
  demonym: 'North Korean',
  landlocked: false,
  borders: ['CHN', 'KOR', 'RUS'],
  area: 120538,
  provinces: [
    'Chagang-do (Chagang Province)',
    'Hamgyong-bukto (North Hamgyong Province)',
    'Hamgyong-namdo (South Hamgyong Province)',
    'Hwanghae-bukto (North Hwanghae Province)',
    'Hwanghae-namdo (South Hwanghae Province)',
    'Kaesong-si (Kaesong City)',
    'Kangwon-do (Kangwon Province)',
    "Namp'o-si (Namp'o City)",
    "P'yongan-bukto (North P'yongan Province)",
    "P'yongan-namdo (South P'yongan Province)",
    "P'yongyang-si (P'yongyang City)",
    'Yanggang-do (Yanggang Province)',
  ],
  nativeName: '북한',
  timezones: ['UTC+09:00'],
} as const;
