import { Module, VuexModule, Mutation, Action } from '@vuex/decorators';

import { api } from '@api';
import { ExtensionCode } from '@models';
import { Root } from '@store';
import { findById, addById, removeById } from '@utils/array';

@Module({ namespaced: true })
export class ExtensionCodes
  extends VuexModule<ExtensionCodes.State, Root.State>
  implements ExtensionCodes.State
{
  items: ExtensionCode[] = [];

  /** ... */
  get findById() {
    return (id: ExtensionCode['id']) => findById(this.items, id);
  }

  //#region Mutations

  /**
   * ...
   */
  @Mutation
  SET(options: ExtensionCodes.SetMutationOptions) {
    this.items = options.data;
  }

  /**
   * ...
   */
  @Mutation
  ADD(options: ExtensionCodes.AddMutationOptions) {
    this.items = addById(this.items, options);
  }

  /**
   * ...
   */
  @Mutation
  DELETE(options: ExtensionCodes.DeleteMutationOptions) {
    this.items = removeById(this.items, options.extensionCodeId);
  }

  /**
   * ...
   */
  @Mutation
  CLEAR() {
    this.items = [];
  }

  //#endregion Mutations

  //#region Actions

  /**
   * ...
   */
  @Action
  async list() {
    const data = await api.extensionCodes.list();

    this.context.commit('SET', { data });

    return data;
  }

  /**
   * ...
   */
  @Action
  async get(options: ExtensionCodes.GetActionOptions) {
    const data = await api.extensionCodes.get(options);

    this.context.commit('ADD', data);

    return data;
  }

  /**
   * ...
   */
  @Action
  async create(options: ExtensionCodes.CreateActionOptions) {
    const data = await api.extensionCodes.create(options);

    this.context.commit('ADD', data);

    return data;
  }

  /**
   * ...
   */
  @Action
  async del(options: ExtensionCodes.DeleteActionOptions) {
    await api.extensionCodes.del(options);

    this.context.commit('DELETE', options);
  }

  //#endregion Actions
}

export namespace ExtensionCodes {
  /**
   * ...
   */
  export interface State {
    items: ExtensionCode[];
  }

  /**
   * ...
   */
  export interface SetMutationOptions {
    data: ExtensionCode[];
  }

  /** ... */
  export type AddMutationOptions = ExtensionCode;
  /** ... */
  export type DeleteMutationOptions = api.extensionCodes.DeleteOptions;
  /** ... */
  export type GetActionOptions = api.extensionCodes.GetOptions;
  /** ... */
  export type CreateActionOptions = api.extensionCodes.CreateOptions;
  /** ... */
  export type DeleteActionOptions = api.extensionCodes.DeleteOptions;
}

export default ExtensionCodes;
