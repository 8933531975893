var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      attrs: {
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 130.2 130.2",
      },
    },
    [
      _c("circle", {
        staticClass: "path circle",
        attrs: {
          fill: "none",
          stroke: "#D06079",
          "stroke-width": "6",
          "stroke-miterlimit": "10",
          cx: "65.1",
          cy: "65.1",
          r: "62.1",
        },
      }),
      _vm._v(" "),
      _c("line", {
        staticClass: "path line",
        attrs: {
          fill: "none",
          stroke: "#D06079",
          "stroke-width": "6",
          "stroke-linecap": "round",
          "stroke-miterlimit": "10",
          x1: "34.4",
          y1: "37.9",
          x2: "95.8",
          y2: "92.3",
        },
      }),
      _vm._v(" "),
      _c("line", {
        staticClass: "path line",
        attrs: {
          fill: "none",
          stroke: "#D06079",
          "stroke-width": "6",
          "stroke-linecap": "round",
          "stroke-miterlimit": "10",
          x1: "95.8",
          y1: "38",
          x2: "34.4",
          y2: "92.2",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }