export const VAT = {
  name: {
    common: 'Vatican City',
    official: 'Vatican City State',
    native: {
      ita: { official: 'Stato della Città del Vaticano', common: 'Vaticano' },
      lat: { official: 'Status Civitatis Vaticanæ', common: 'Vaticanæ' },
    },
  },
  tld: ['.va'],
  cca2: 'VA',
  ccn3: '336',
  cca3: 'VAT',
  cioc: '',
  currency: ['EUR'],
  callingCode: ['3906698', '379'],
  capital: 'Vatican City',
  altSpellings: [
    'VA',
    'Holy See (Vatican City State)',
    'Vatican City State',
    'Stato della Città del Vaticano',
  ],
  region: 'Europe',
  subregion: 'Southern Europe',
  languages: { ita: 'Italian', lat: 'Latin' },
  translations: {
    deu: { official: 'Staat Vatikanstadt', common: 'Vatikanstadt' },
    fra: { official: 'Cité du Vatican', common: 'Cité du Vatican' },
    hrv: { official: 'Vatikan', common: 'Vatikan' },
    ita: { official: 'Città del Vaticano', common: 'Città del Vaticano' },
    jpn: { official: 'バチカン市国の状態', common: 'バチカン市国' },
    nld: { official: 'Vaticaanstad', common: 'Vaticaanstad' },
    por: { official: 'Cidade do Vaticano', common: 'Cidade do Vaticano' },
    rus: { official: 'Город-государство Ватикан', common: 'Ватикан' },
    spa: { official: 'Ciudad del Vaticano', common: 'Ciudad del Vaticano' },
    fin: { official: 'Vatikaanin kaupunkivaltio', common: 'Vatikaani' },
  },
  latlng: [41.9, 12.45],
  demonym: 'Italian',
  landlocked: true,
  borders: ['ITA'],
  area: 0.44,
} as const;
