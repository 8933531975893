export const LVA = {
  name: {
    common: 'Latvia',
    official: 'Republic of Latvia',
    native: { lav: { official: 'Latvijas Republikas', common: 'Latvija' } },
  },
  cities: [
    'Riga',
    'Centrs',
    'Salaspils',
    'Jelgava',
    'Ozolnieki',
    'Priedkalne',
    'Ventspils',
    'LiepÄja',
    'JÅ«rmala',
    'PreiÄ¼i',
    'Aizkraukle',
    'RopaÅ¾i',
    'Tukums',
    'IkÅ¡Ä·ile',
    'Daugavpils',
    'Agenskalns',
    'RÄ“zekne',
    'CÄ“sis',
    'Dobele',
    'Izvalta',
    'Liepa',
    'Valmiera',
    'AlÅ«ksne',
    'Pilsrundale',
    'Bauska',
    'Ogre',
    'LÄ“dmane',
    'Ä¶egums',
    'Ilguciems',
    'Saldus',
    'KuldÄ«ga',
    'JÄ“kabpils',
    'Marupe',
    'Valka',
    'Balvi',
    'StrenÄi',
    'Madona',
    'LÄ«vÄni',
    'Zasa',
    'Stalgene',
    'Pure',
    'Aizpute',
    'Kandava',
    'Ä¶ekava',
    'Carnikava',
    'Malta',
    'Zilupe',
    'VarakÄ¼Äni',
    'Talsi',
    'Lapmezciems',
    'Smiltene',
    'Sigulda',
    'Mazsalaca',
    'Kolka',
    'Varsava',
    'Jaunolaine',
    'Adazi',
    'Ludza',
    'Olaine',
    'Baldone',
    'LimbaÅ¾i',
    'Gulbene',
    'SunÄkste',
    'ViesÄ«te',
    'Malpils',
    'Brankas',
    'Durbe',
  ],
  tld: ['.lv'],
  cca2: 'LV',
  ccn3: '428',
  cca3: 'LVA',
  cioc: 'LAT',
  currency: ['EUR'],
  callingCode: ['371'],
  capital: 'Riga',
  altSpellings: ['LV', 'Republic of Latvia', 'Latvijas Republika'],
  region: 'Europe',
  subregion: 'Northern Europe',
  languages: { lav: 'Latvian' },
  translations: {
    deu: { official: 'Republik Lettland', common: 'Lettland' },
    fra: { official: 'République de Lettonie', common: 'Lettonie' },
    hrv: { official: 'Republika Latvija', common: 'Latvija' },
    ita: { official: 'Repubblica di Lettonia', common: 'Lettonia' },
    jpn: { official: 'ラトビア共和国', common: 'ラトビア' },
    nld: { official: 'Republiek Letland', common: 'Letland' },
    por: { official: 'República da Letónia', common: 'Letónia' },
    rus: { official: 'Латвийская Республика', common: 'Латвия' },
    spa: { official: 'República de Letonia', common: 'Letonia' },
    fin: { official: 'Latvian tasavalta', common: 'Latvia' },
  },
  latlng: [57, 25],
  demonym: 'Latvian',
  landlocked: false,
  borders: ['BLR', 'EST', 'LTU', 'RUS'],
  area: 64559,
  provinces: [
    'Aizkraukles Rajons',
    'Aluksnes Rajons',
    'Balvu Rajons',
    'Bauskas Rajons',
    'Cesu Rajons',
    'Daugavpils',
    'Daugavpils Rajons',
    'Dobeles Rajons',
    'Gulbenes Rajons',
    'Jekabpils Rajons',
    'Jelgava',
    'Jelgavas Rajons',
    'Jurmala',
    'Kraslavas Rajons',
    'Kuldigas Rajons',
    'Leipaja',
    'Liepajas Rajons',
    'Limbazu Rajons',
    'Ludzas Rajons',
    'Madonas Rajons',
    'Ogres Rajons',
    'Preilu Rajons',
    'Rezekne',
    'Rezeknes Rajons',
    'Riga',
    'Rigas Rajons',
    'Saldus Rajons',
    'Talsu Rajons',
    'Tukuma Rajons',
    'Valkas Rajons',
    'Valmieras Rajons',
    'Ventspils',
    'Ventspils Rajons',
  ],
  nativeName: 'Latvija',
  timezones: ['UTC+02:00'],
} as const;
