export const ESP = {
  name: {
    common: 'Spain',
    official: 'Kingdom of Spain',
    native: {
      cat: { official: "Regne d'Espanya", common: 'Espanya' },
      eus: { official: 'Espainiako Erresuma', common: 'Espainia' },
      glg: { official: 'Reino de España', common: '' },
      oci: { official: "Reialme d'Espanha", common: 'Espanha' },
      spa: { official: 'Reino de España', common: 'España' },
    },
  },
  cities: [
    'Valencia',
    'Riola',
    'Barcelona',
    'MontornÃ¨s del VallÃ¨s',
    'Begues',
    'Terrassa',
    'Sant Cugat del VallÃ¨s',
    "l'Hospitalet de l'Infant",
    'Vallirana',
    'Blanes',
    'el Prat de Llobregat',
    'CornellÃ  de Llobregat',
    'Sant Just Desvern',
    'Gava',
    'PallejÃ ',
    'Castelldefels',
    'Madrid',
    'LeganÃ©s',
    'Villaviciosa de Odon',
    'AlcorcÃ³n',
    'Valdemoro',
    'Mostoles',
    'Cobena',
    'Getafe',
    'Aranjuez',
    'Humanes de Madrid',
    'Las Rozas de Madrid',
    'Arroyomolinos',
    'Colmenar Viejo',
    'Santa Cruz',
    'Majadahonda',
    'Fuenlabrada',
    'Arucas',
    'Las Palmas de Gran Canaria',
    'Badalona',
    'Collado Villalba',
    'Villamanta',
    'Alcobendas',
    'AlcalÃ¡ de Henares',
    'Torrelodones',
    'Illescas',
    'Sesena',
    'Mataelpino',
    'Villalbilla',
    'Pinto',
    'Moralzarzal',
    'Ciempozuelos',
    'San SebastiÃ¡n de los Reyes',
    'Galapagar',
    'Parla',
    'Toledo',
    'San Martin de la Vega',
    'Coslada',
    'Arganda',
    'Hoyo de Manzanares',
    'Los Hueros',
    'Los Molinos',
    'Torres de la Alameda',
    'Tarragona',
    'les Borges del Camp',
    'Jesus',
    'Reus',
    'Santa Coloma de Queralt',
    'Cambrils',
    'Cartagena',
    'Murcia',
    'Lorca',
    'Balsicas',
    'Villafranca',
    'Boadilla del Monte',
    'Valdilecha',
    'Villanueva del Pardillo',
    'Portillo de Toledo',
    'Villanueva de la Canada',
    'Grinon',
    'El Alamo',
    'Torrejon de la Calzada',
    'Monte',
    'Brunete',
    'Cerceda',
    'ChinchÃ³n',
    'Sevilla La Nueva',
    "L'Hospitalet de Llobregat",
    'Cervello',
    'Granollers',
    'Navalcarnero',
    'Pozuelo de AlarcÃ³n',
    'Robledo de Chavela',
    'El Escorial',
    'Campo Real',
    'Nuevo Baztan',
    'TorrejÃ³n de Ardoz',
    'Perales del Alfambra',
    'Becerril de la Sierra',
    'Colmenarejo',
    'San Martin de Valdeiglesias',
    'Colmenar del Arroyo',
    'Batres',
    'Las Ventas de Retamosa',
    'Las Matas',
    'RubÃ­',
    'Gelida',
    'Badajoz',
    'MÃ©rida',
    'Olivenza',
    'Telde',
    'GÃ¡ldar',
    'Candelaria',
    'Alta',
    'Arona',
    'MondragÃ³n',
    'Ermua',
    'Girona',
    'Sant Bartomeu del Grau',
    'Olula del Rio',
    'Garrucha',
    'AlmerÃ­a',
    'Vera',
    'Huercal de Almeria',
    'Nijar',
    'El Ejido',
    'Albox',
    'Los Gallardos',
    'Cuevas del Almanzora',
    'Viator',
    'Aguadulce',
    'Roquetas de Mar',
    'Tijola',
    'Huercal Overa',
    'Arboleas',
    'Tabernas',
    'Vicar',
    'Sant Joan de Vilatorrada',
    'Sitges',
    'Vilanova del CamÃ­',
    'Dosrius',
    'Ametlla',
    'Vallgorguina',
    'Valles',
    'Vall de Almonacid',
    'Lucena del Cid',
    'Manresa',
    'Roda de Bara',
    'Piera',
    'Olesa de Montserrat',
    'Igualada',
    'Sant Pere de Ribes',
    'Mijas',
    'MÃ¡laga',
    'Fuengirola',
    'Huelva',
    'Mazagon',
    'Los Marines',
    'Aracena',
    'Hinojos',
    'Bonares',
    'Ãšbeda',
    'JaÃ©n',
    'Alcala la Real',
    'Santiago',
    'Linares',
    'Jodar',
    'Sabiote',
    'Bilbao',
    'Guadix',
    'Oria',
    'Irun',
    'San SebastiÃ¡n de La Gomera',
    'Zumarraga',
    'San Sebastian',
    'Fuentes de Leon',
    'Burguillos del Cerro',
    'Azuaga',
    'San CristÃ³bal de La Laguna',
    'Santa Cruz de Tenerife',
    'La Orotava',
    'La Higuerita',
    'Playa',
    'Adeje',
    'Sabadell',
    'Marbella',
    'Torremolinos',
    'Antequera',
    'El Moral',
    'Rincon de la Victoria',
    'LogroÃ±o',
    'Valle',
    'Las Navas del Marques',
    'MatarÃ³',
    'Taradell',
    'Rupit',
    'Canovelles',
    'Tacoronte',
    'Granadilla de Abona',
    'La Vera',
    'Santa Cruz de La Palma',
    'Santa Ãšrsula',
    'Puerto de la Cruz',
    'Icod de los Vinos',
    'La Victoria de Acentejo',
    'San Isidro',
    'Tigaiga',
    'Valverde',
    'Tegueste',
    'Los Cristianos',
    'Zamora',
    'Corrales',
    'Seville',
    'CÃ¡ceres',
    'Santa Maria de CorcÃ³',
    'Valldoreix',
    'Tortosa',
    'Malgrat de Mar',
    'Villena',
    'Vilassar de Mar',
    'Algete',
    'El Astillero',
    'Lucena',
    'Puebla de Alfinden',
    'Loeches',
    'Mollet del VallÃ¨s',
    'Algeciras',
    'Azuqueca de Henares',
    'La Alameda de la Sagra',
    'Creixell',
    'Fuentelapena',
    'Torroella de Montgri',
    'Mahon',
    'Tona',
    'Artes',
    'TorellÃ³',
    'Vic',
    'San Juan',
    'Mojacar Pueblo',
    'CastellÃ³n de la Plana',
    'Cuevas de Vinroma',
    'Cadiz',
    'Cordova',
    'Segura',
    'Villanueva del Arzobispo',
    'Alginet',
    'Santa Pola',
    'San Julian de Muskiz',
    'Betera',
    'MorÃ³n de la Frontera',
    'Poboa de San Xulian',
    'Marchena',
    'Ãguilas',
    'La Puebla de Cazalla',
    'Alicante',
    'Granada',
    'Lugo',
    'Amorebieta',
    'Tortella',
    'VÃ©lez-MÃ¡laga',
    'Corchuela',
    'Onda',
    'Chiva',
    'Beniparrell',
    'Almenara',
    'Brenes',
    'Jerez de la Frontera',
    'Los Barrios',
    'Alsasua â€“ Altsasu',
    'Orcoyen',
    'Santander',
    'Manzanares',
    'Castro Urdiales',
    'Santa Eulalia Bajera',
    'Villanueva de Villaescusa',
    'Ribadeo',
    'Jijona',
    'Elche',
    'Errenteria',
    'Zaragoza',
    'Tolosa',
    'Valladolid',
    'Santa Lucia',
    'Moana',
    'LeÃ³n',
    'Vitoria-Gasteiz',
    'Zafra',
    'La Red de Valdetuejar',
    'Mairena del Aljarafe',
    'Pedreguer',
    'Trujillo',
    'Los Alcazares',
    'Pilar de la Horadada',
    'Almoradi',
    'Hortichuela',
    'Puerto Real',
    'Cartama',
    'Cullera',
    'Guadalajara',
    'Esquivias',
    'Cervera',
    'La Pobla de Lillet',
    'Ourense',
    'Roldan',
    'Torre-Pacheco',
    'San Pedro del Pinatar',
    'Torrevieja',
    'San Miguel',
    'Real',
    'Acala del Rio',
    'Vigo',
    'Pontevedra',
    'Cangas do Morrazo',
    'Nigran',
    'Valdemorillo',
    'San Fernando de Henares',
    'El Escorial',
    'Segovia',
    'Llosa de Ranes',
    'La Guancha',
    'Pamplona',
    'Estella-Lizarra',
    'Atarrabia',
    'Berriozar',
    'Baranain',
    'Tudela',
    'Burlata',
    'A CoruÃ±a',
    'Ferrol',
    'NarÃ³n',
    'Sada',
    'Ames',
    'Fene',
    'Verin',
    'Palencia',
    'Villamuriel de Cerrato',
    'Viveiro',
    'Vivero',
    'Toro',
    'Parets del VallÃ¨s',
    'la Garriga',
    'Cardedeu',
    'Polinya',
    'Palafolls',
    'Pineda de Mar',
    'Santa Coloma de CervellÃ³',
    'Calella',
    'Castellar del Valles',
    'Ibiza',
    'Santa EulÃ ria des Riu',
    'Moratalla',
    'Totana',
    'Las Torres de Cotillas',
    'Cieza',
    'Palma de Mallorca',
    'Capdepera',
    'Inca',
    'PollenÃ§a',
    'MarratxÃ­',
    'Premia de Mar',
    'Vilanova de SegriÃ¡',
    'Moia',
    'Ripoll',
    'Plegamans',
    'Manlleu',
    'Alboraya',
    'Sagunto',
    'Paterna',
    'Torrent',
    'Benisano',
    'San Antonio',
    'Gandia',
    'La Seca',
    'Molina de Segura',
    'Alcantarilla',
    'La UniÃ³n',
    'Jabali Nuevo',
    'Ceuti',
    'Los Garres',
    'Alcoy',
    'Villanueva de Castellon',
    'Alfafar',
    'Corbera de Llobregat',
    'Vilanova i la GeltrÃº',
    'Molins de Rei',
    'Cabrils',
    'Torrelles de Llobregat',
    'Calafell',
    'Castellet',
    'Sant Vicenc de Castellet',
    'Terrassa',
    'MontmelÃ³',
    'Abrera',
    'els Hostalets de Pierola',
    'Silla',
    'Margarida',
    'La Llagosta',
    'Martorell',
    'el Masnou',
    'Tiana',
    'Sallent',
    'Santa Perpetua de Mogoda',
    'Santa Margarida',
    'Montseny',
    'Port de Sagunt',
    'Palmera',
    'Bormujos',
    'Bollullos de la Mitacion',
    'Salamanca',
    'San Javier',
    'Anora',
    'Penarroya-Pueblonuevo',
    'Galvez',
    'Muro del Alcoy',
    'La Llacuna',
    'Los Palacios y Villafranca',
    'Capellades',
    'Iznate',
    'Nerva',
    'La Galera',
    'Hellin',
    'Baza',
    'Sayalonga',
    'Monterrubio de la Serena',
    'Urda',
    'Tomelloso',
    'Villar del Arzobispo',
    'Alzira',
    'Mislata',
    'Albolote',
    'Atarfe',
    'Dos Hermanas',
    'Oasis (La Marina)',
    'Hernani',
    'Orihuela',
    'Rojales',
    'Laredo',
    'Elexalde',
    'Pucol',
    'Rafelbunyol',
    'Blanca',
    'Archena',
    'Mondujar',
    'BenalmÃ¡dena',
    'Coin',
    'Yecla',
    'Ferreries',
    'Campos',
    'San Juan de Moro',
    'Montellano',
    'Oliva',
    'Olvera',
    'La Roda',
    'Almagro',
    'Librilla',
    'Villaverde del Rio',
    'Benicassim',
    'Argentona',
    'Alhaurin de la Torre',
    'El Pozo de los Frailes',
    'GijÃ³n',
    'Mogente',
    "l' Argentera",
    'Nules',
    'Punta UmbrÃ­a',
    'Aizoain',
    'Silleda',
    'Almoster',
    'Torres de Berrellen',
    'Casar de Caceres',
    'Aliseda',
    'Rute',
    'Tomares',
    'Legazpia',
    'Villacanas',
    'Rielves',
    'Milagro',
    'Boiro',
    'Sobarzo',
    'Zumaia',
    'Muriedas',
    'Santiago Pena',
    'Rebordanes',
    'Castalla',
    'Castilleja de la Cuesta',
    'Santpedor',
    'Cambre',
    'Villalar de los Comuneros',
    'Cheste',
    'Durango',
    'Petrel',
    'Arbo',
    'Balaguer',
    'Cabrera de Almanza',
    'Llafranc',
    'Ponferrada',
    'Elda',
    'Onil',
    'Mejorada del Campo',
    'La Puebla del Rio',
    'Albacete',
    'Hoya-Gonzalo',
    'Villaricos',
    'Adra',
    'Somo',
    'Arenys de Munt',
    'Fuensalida',
    'Falset',
    'Lleida',
    'Maspalomas',
    'Cuenca',
    'Alhaurin el Grande',
    'El Puerto de Santa MarÃ­a',
    'Manacor',
    'Culleredo',
    'Ribadavia',
    'Burgos',
    'Aspe',
    'Palafrugell',
    'Villarrobledo',
    'Pozoblanco',
    'Cruce de Arinaga',
    'Monforte de Lemos',
    'Oviedo',
    'Las Vegas',
    'Alcorisa',
    'Castilblanco de los Arroyos',
    'Tomino',
    'Biar',
    'el Morche',
    'Serracines',
    'Navalperal de Pinares',
    'Benidorm',
    'Villamiel de Toledo',
    'Ripollet',
    'Meis',
    'Catarroja',
    'Jaraiz de la Vera',
    'Llanera',
    'AvilÃ©s',
    'Pina de Ebro',
    'Jaca',
    'Villanueva del Rosario',
    'Vergel',
    'El Palmar',
    'Cobatillas',
    'Gallarta',
    'Langreo',
    'Sant Joan les Fonts',
    'El Papiol',
    'Couso de Salas',
    'Ribeira',
    "L'Alcudia",
    'Algemesi',
    'Melilla',
    'La Guardia de Jaen',
    'Los Villares',
    'Vilches',
    'La Carlota',
    'Utrera',
    'Las Cabezas de San Juan',
    'San Juan de Aznalfarache',
    'Santa Pau',
    'Bescano',
    'Cabanes',
    'Baena',
    'Cortiguera',
    'Cenes de la Vega',
    'Carlet',
    'Salinas',
    'Barakaldo',
    'Ribarroja',
    'Vinalesa',
    'Churriana de la Vega',
    'Santiago de Compostela',
    "Vilanova d'Escornalbou",
    'Marchamalo',
    'Vaciamadrid',
    'Torrelavega',
    'Limpias',
    'Ampuero',
    'Los Corrales de Buelna',
    'Liencres',
    'Maliano',
    'Colindres',
    'Santona',
    'Guarnizo',
    'Cabezon de la Sal',
    'Santiago de Cartes',
    'Santa Cruz de Bezana',
    'Liano',
    'Entrambasaguas',
    'Termino',
    'Soto de la Marina',
    'Renedo',
    'CalviÃ ',
    'LlubÃ­',
    'Santa Maria del Cami',
    'Felanitx',
    'ses Salines',
    'Muro',
    'Ibi',
    'Vila-real',
    'Novelda',
    "l'Alcora",
    'Paradas',
    'Chiclana de la Frontera',
    'Valencina de la Concepcion',
    'San Pedro',
    'Rafelguaraf',
    'Paterna del Campo',
    'Casabermeja',
    'Cambados',
    'Arrecife',
    'Ã‰cija',
    'La Roda de Andalucia',
    'Rioja',
    'Lloret de Mar',
    'Benitachell',
    'Mengibar',
    'Pilas',
    'Alcossebre',
    'Puerto del Carmen',
    'Carrion de los Cespedes',
    'Soller',
    'Puig',
    'Cocentaina',
    'Miramar',
    'Campillo',
    'Aznalcazar',
    'Herrera',
    'Burriana',
    'Salteras',
    'Chucena',
    'Alovera',
    'Olot',
    'Alcoletge',
    'Subirats',
    'Villabona',
    'Erandio',
    'Portugalete',
    'Usurbil',
    'Lekeitio',
    'Urtuella',
    'Eibar',
    'Ondarroa',
    'Huesca',
    'San Fernando',
    "Quart d'Onyar",
    'Moncada',
    "L'Eliana",
    'Rocafort',
    'Godella',
    'Laguna de Duero',
    'Cisterniga',
    'Porrino',
    'Ogijares',
    'Motril',
    'Albal',
    'Vilagarcia de Arousa',
    'Daganzo de Arriba',
    'Canals',
    'Cantillana',
    'Puerto',
    'PeÃ±Ã­scola',
    'Mirador',
    'Almodovar del Rio',
    'Capela',
    'Madrigalejo',
    'Estepa',
    'Bocairent',
    'Don Benito',
    'Aranda de Duero',
    'Ledana',
    'Fuente del Maestre',
    'Oleiros',
    'Foz',
    'Lalin',
    'As Pontes de Garcia Rodriguez',
    'Rianxo',
    'Oroso',
    'Teo',
    'Carballo',
    'Mino',
    'A Estrada',
    'Estrada',
    'Brion',
    'Ordes',
    'Arzua',
    'Betanzos',
    'Burela de Cabo',
    'O Carballino',
    'O Barco de Valdeorras',
    'Gondomar',
    'Rairiz de Veiga',
    'Redondela',
    'Curtis',
    'Friol',
    'Bergondo',
    'Trives',
    'Caion',
    'Muro',
    'Laracha',
    'Garcia',
    'Arino',
    'Villafranca del Campo',
    'Cabra',
    'Campillos',
    'La Rambla',
    'Rota',
    'Alanis',
    'SanlÃºcar de Barrameda',
    'Ronda',
    'Tordera',
    'Valls',
    'Baga',
    'Castellbell i el Vilar',
    'Cubelles',
    'Camargo',
    'Pradejon',
    'Arce',
    'La Alberca',
    'Denia',
    'Almassora',
    'Moja',
    'Sant Andreu de la Barca',
    'Viladecans',
    'Santa Coloma de Gramenet',
    'Algorta',
    'Los Navalucillos',
    'Estepona',
    'Idiazabal',
    'Puertollano',
    'el Campello',
    'Calpe',
    'Javea',
    'Sant Feliu de GuÃ­xols',
    'Celra',
    'Pau',
    'Vila',
    'Lardero',
    'La LÃ­nea de la ConcepciÃ³n',
    'Amposta',
    'El Vendrell',
    'Bueu',
    'Plasencia',
    'Paiporta',
    'Santa Maria',
    'Gines',
    'la Vall',
    'Irura',
    'Frailes',
    'Montesquiu',
    'Salceda',
    'Ciudad Real',
    'Muchamiel',
    'Mancha Real',
    'Huetor Vega',
    'Maracena',
    'Costa',
    'Santurtzi',
    'Talavera de la Reina',
    'Entrevias',
    "Sant Sadurni d'Anoia",
    'Gurb',
    'Berga',
    'Altea',
    'Pola de Siero',
    'Mieres',
    'Utebo',
    'Calatayud',
    'Casetas',
    'PalamÃ³s',
    'Armilla',
    'Cala del Moral',
    'Casares',
    'Arroyo de la Miel',
    'Andujar',
    'Monzon',
    'Churra',
    'Beniajan',
    'Salou',
    'Calahorra',
    'AlmuÃ±Ã©car',
    'Martos',
    'Medina del Campo',
    'Nerja',
    'AlcalÃ¡ de Guadaira',
    'Villajoyosa',
    'Zarautz',
    'La Rinconada',
    'Carmona',
    'Montcada i Reixac',
    'Figaro',
    'Bargas',
    'Miguelturra',
    'Santa Maria de Palautordera',
    'Alella',
    'Zaramillo',
    'Moguer',
    'Mairena del Alcor',
    'Santomera',
    'Gernika-Lumo',
    'Cala Millor',
    'Churriana',
    'San Agustin de Guadalix',
    'Requena',
    'Teulada',
    'Alameda',
    'Palma del Rio',
    'Fuentes de Andalucia',
    'CehegÃ­n',
    'San Vicent del Raspeig',
    'Almonte',
    'Valverde del Camino',
    'Caravaca',
    'Las Islas',
    'Guardo',
    'Trobajo del Camino',
    'Armunia',
    'San Andres del Rabanedo',
    'Miranda de Ebro',
    'Montearagon',
    'Albarreal de Tajo',
    'Anchuelo',
    'Alberic',
    'Priego de Cordoba',
    'Mora de Rubielos',
    'La Fresneda',
    'Ateca',
    'Andorra',
    'Ceuta',
    'Espartinas',
    'Villares de la Reina',
    'Noain',
    'Mutilva Baja',
    'Huarte-Araquil',
    'Alguazas',
    'Incio',
    'Tordesillas',
    'Pedrajas',
    'Artana',
    'Elizondo',
    'Urdiain',
    'Mayor',
    'Noja',
    'Saron',
    'Mugardos',
    'Garriguella',
    'Godelleta',
    'Argamasilla de Alba',
    'Medina-Sidonia',
    'Socuellamos',
    'Guadarrama',
    'Monserrat',
    'Huertas',
    'Firgas',
    'Meliana',
    'Catral',
    'Benejuzar',
    'Covaleda',
    'Canet de Mar',
    'Balenya',
    'Corralejo',
    'Escalonilla',
    'Villasequilla de Yepes',
    'La Puebla de Montalban',
    'Navata',
    'Vilajuiga',
    'Bas',
    'CadaquÃ©s',
    "MÃ³ra d'Ebre",
    'Alcanar',
    'Ventallo',
    'Ontigola',
    'Alumbres',
    'San Roque',
    'Santa Lucia',
    'Arenys de Mar',
    'Loja',
    'Cijuela',
    'La Algaba',
    'Piedrabuena',
    'Simancas',
    'Ajofrin',
    'Vilanova',
    'Santalla',
    'Chantada',
    'Malpartida de Caceres',
    'Arroyo',
    'Egues-Uharte',
    'Castejon',
    'Corella',
    'Arcicollar',
    'Olias del Rey',
    'El Viso de San Juan',
    'Cistierna',
    'Palomares del Rio',
    'San Miguel',
    'Lomo de Arico',
    'Aldeatejada',
    'Alhendin',
    'Navalcan',
    'Labajos',
    'Poblete',
    'Prado del Rey',
    'Artajo',
    'Arroyo de la Luz',
    'Montehermoso',
    'Vinaros',
    'Borriol',
    'BenicarlÃ³',
    'Cala',
    'Breda',
    'La Bisbal',
    'Sant Pere de TorellÃ³',
    'Vilabertran',
    'Joanetes',
    'Serra',
    'Turia',
    'Arges',
    'La Canonja',
    'Taboada',
    'Trujillanos',
    'Busturi-Axpe',
    'Barcarrota',
    'Salvatierra de los Barros',
    'Berja',
    'Duenas',
    'Reyes',
    'Navarrete',
    'Quel',
    'Portocolom',
    'Algaida',
    'Riells i Viabrea',
    'Navarcles',
    'Masquefa',
    'Lepe',
    'Aljaraque',
    'Cabana',
    'Cacheiras',
    'Sils',
    'Bellvis',
    'Sabinanigo',
    'Fraga',
    'Teguise',
    'Ugijar',
    'Sant Joan de les Abadesses',
    'Villarrubia de Santiago',
    'Almenar',
    'Alcarras',
    "el Palau d'Anglesola",
    'Els Monjos',
    'Beniganim',
    'Agullent',
    'Albaida',
    'Matadepera',
    'Alcantara',
    'Madrigal de la Vera',
    'Mestanza',
    'Ãvila',
    'Cardenosa',
    'Soria',
    'La Flecha',
    'Villalba de los Alcores',
    'Puente-Genil',
    'Navarres',
    'Collbato',
    'Lasarte',
    'Valencia de Don Juan',
    'Cea',
    'Pomar de Cinca',
    'Fruiz',
    'Ubrique',
    'Guadiaro',
    'La Mojonera',
    'San Cristobal de Entrevinas',
    'Castello',
    'Villablino',
    'Basauri',
    'Priego',
    'La Figuera',
    'Paracuellos de Jarama',
    'AlcaÃ±iz',
    'Lledo',
    'Toreno',
    'Villaconejos',
    'Ibarra',
    'CintruÃ©nigo',
    'Valdemaqueda',
    'Hernansancho',
    'Navaconcejo',
    'Galaroza',
    'Castrillon',
    'Arriate',
    'El Saler',
    'Santisteban del Puerto',
    'Ibros',
    'Durcal',
    'Vilamalla',
    'Arnedo',
    'Quer',
    'Haro',
    'Escatron',
    'Reocin',
    'NÃ¡jera',
    'Cabanillas del Campo',
    'Igualeja',
    'Zalamea la Real',
    'Cartaya',
    'Minas de Riotinto',
    'GuÃ­a de Isora',
    'La Puerta de Segura',
    'Bailen',
    'Ataun',
    'Alcala de Xivert',
    'Nambroca',
    'Mocejon',
    'Puerto de Gandia',
    'Amurrio',
    'Villamalea',
    'Plasenzuela',
    'Jerte',
    'Villafranca de Cordoba',
    'Venta',
    'San Pedro',
    'Valladolises',
    'La Cavada',
    'Parbayon',
    'Baeza',
    'Oyon',
    'Eneriz',
    'San Sebastian de los Ballesteros',
    'Algar',
    'Mediona',
    'Conil de la Frontera',
    'Manises',
    'El Barco de Avila',
    'Cabrerizos',
    'Villabanez',
    'Valencia',
    'Gilet',
    'Cazorla',
    'AgÃ¼imes',
    'Numancia de la Sagra',
    'Collado Mediano',
    'Boltana',
    'Sojuela',
    'Oliana',
    'La Torre',
    'Escalona',
    'Villaluenga',
    'Iznajar',
    'TeiÃ ',
    'Castro',
    'Reinosa',
    'Yeles',
    'Enguera',
    'La Batlloria',
    'Carranque',
    'Lantadilla',
    'Coiros',
    'Torreaguera',
    'Alquerias',
    'Aiguafreda',
    'Meco',
    'Coria',
    'Sierra de Fuentes',
    'Balerma',
    'Zubia',
    'Graus',
    'Gandesa',
    'Guadalix de la Sierra',
    'Gerena',
    'Chelva',
    'San Nicolas',
    'Yepes',
    'Ocana',
    'Cercedilla',
    'Posadas',
    'Tarancon',
    'Carinena',
    'Ã“rgiva',
    'Odena',
    'La Robla',
    'La Llosa',
    'Pals',
    'Begur',
    'FernÃ¡n NÃºÃ±ez',
    'Montilla',
    'Cangas de Onis',
    'Torrijos',
    'Valdepenas',
    'El Medano',
    'Horta de Sant Joan',
    'Finestrat',
    'Montemayor de Pililla',
    'Boecillo',
    'Huecas',
    'Palol',
    'Sarria',
    'Hinojosa del Duque',
    'Huelves',
    'Villar de Olalla',
    'El Sauzal',
    'Urroz',
    'El Vellon',
    'Jimena de la Frontera',
    'Arcos de la Frontera',
    'La Yedra',
    'La Abadilla',
    'Viveda',
    'Alava',
    'La Puebla de Hijar',
    'Beniarres',
    'Ahigal',
    'Paracuellos',
    'Axpe',
    'Arbizu',
    'Carcar',
    'Pueyo',
    'Serranillos del Valle',
    'El Molar',
    'Arcade',
    'el Poal',
    'Beas',
    'Pola de Laviana',
    'Bolvir',
    'Toral de los Vados',
    'Aguilar de Campoo',
    'Saldana',
    'Selva',
    'Capileira',
    'Osuna',
    'Villa del Prado',
    'Ribes de Freser',
    'El Real de la Jara',
    'Sorbas',
    'Lopera',
    'Barbastro',
    'AlcÃºdia',
    'Cortegana',
    'Isla Cristina',
    'Arama',
    'Arafo',
    'Rivabellosa',
    'Aravaca',
    'Alpedrete',
    'AlarÃ³',
    'Monistrol de Calders',
    'La Virgen del Camino',
    'Viana',
    'Formentera de Segura',
    'Arjona',
    'Beas de Segura',
    'Ciutadella',
    'Andoain',
    'Pescueza',
    'Casas del Monte',
    'Palau',
    'Benahavis',
    'Gallur',
    'Morata de Jalon',
    'Quintanar del Rey',
    'Utiel',
    'Villarcayo',
    'Valderrey',
    'Villafranca de los Caballeros',
    'Parcent',
    'Robledillo de la Vera',
    'HervÃ¡s',
    'Albalat dels Tarongers',
    'Aizarnazabal',
    'Nubledo',
    'Montgat',
    'Penagos',
    'Sopela',
    'Villasana de Mena',
    'Gironella',
    'Olesa de Bonesvalls',
    'La Rapita',
    'Castellvell del Camp',
    'Santa Barbara',
    'Albalate de Cinca',
    'El Grado',
    'Porto',
    'Cerdeda',
    'Camarinas',
    'Oliva de Plasencia',
    'Villanueva de la Serena',
    'Marin',
    'Peligros',
    'Frigiliana',
    'Lerin',
    'Tamames',
    'Celanova',
    'Torrecaballeros',
    'Carpio',
    'Mojados',
    'Penafiel',
    'Chipiona',
    'La Caridad',
    'Solares',
    'Torreperogil',
    'Altafulla',
    'Santa Oliva',
    'Pego',
    "l'Ametlla de Mar",
    'El Espinar',
    'San Esteban del Valle',
    'Vinales',
    'Vinaixa',
    'Alfaro',
    'Casla',
    'Archidona',
    'Dumbria',
    'Valdesogo de Abajo',
    "l'Estartit",
    'Torresandino',
    'Sant Josep de sa Talaia',
    'Benameji',
    'Villarquemado',
    'Espejo',
    'Valdecabras',
    'Quintana de Raneros',
    'Alaejos',
    'Pedrajas de San Esteban',
    'Hontanares de Eresma',
    'Laguardia',
    'Coca',
    'Segovia',
    'Huercanos',
    'Tormantos',
    'Autol',
    'Cenicientos',
    'Bermeo',
    'Tarazona de la Mancha',
    'Almensilla',
    'Sant Pere, Santa Caterina i La Ribera',
    'Carrion de los Condes',
    'Alforja',
    'Torreblascopedro',
    'Cobisa',
    'Bernuy',
    'Falces',
    'Villamayor',
    'Portonovo',
    'Torrejon del Rey',
    'Tortola de Henares',
    'Lugones',
    'Candas',
    'Playa Blanca',
    'Galapagos',
    'Polop',
    'San Juan de Alicante',
    'Coristanco',
    'Villarta de San Juan',
    'Hontoria del Pinar',
    'Traslavina',
    'El Burgo de Osma',
    'Villamayor',
    'Mecina Fondales',
    'Purchil',
    'Gelves',
    'Velez de Benaudalla',
    'La Almunia de Dona Godina',
    'Aguilar del Rio Alhama',
    'Jumilla',
    'Herrera de Pisuerga',
    'Fuente Vaqueros',
    'Grado',
    'Beriain',
    'El Ballestero',
    'Salobre',
    'Vedra',
    'Corme-Porto',
    'Pozuelo del Rey',
    'Binefar',
    'Abanto',
    'Los Narejos',
    'Alberite',
    'Elburgo',
    'Son Curt',
    'Derio',
    'La Oliva',
    'Villamediana de Iregua',
    'Daya Vieja',
    'Sotrondio',
    'Villafranca de los Barros',
    'Anorbe',
    'Puerto',
    'Casas del Castanar',
    'Navalmoral de la Mata',
    'Santa Coloma de Farners',
    'Nanclares de la Oca',
    'Medinya',
    'Manzanares el Real',
    'Torremenga',
    'Cuarte de Huerva',
    'BuÃ±ol',
    'Portus',
    'Pedrezuela',
    'Ingenio',
    'Camarenilla',
    'Sarria de Ter',
    'Salas de los Infantes',
    'Guitiriz',
    'Arrigorriaga',
    'Zamudio',
    'Elorrio',
    'La Muela',
    'Mariola',
    'Quijorna',
    'Masdenverge',
    'Ajalvir',
    'Villatuerta',
    'Madridejos',
    'Dosbarrios',
    'Consuegra',
    'Ugena',
    'Cubas',
    'Pinoso',
    "L'Arboc",
    'Guadiana del Caudillo',
    'Robledillo de Gata',
    'Garrovillas',
    'Las Galletas',
    'Salt',
    'Luanco',
    'Pravia',
    'Illas',
    'Riudellots de la Selva',
    'els Pallaresos',
    'Cunit',
    "L' Espluga de Francoli",
    'Pedro Abad',
    'Val de Santo Domingo',
    'Miraflores de la Sierra',
    'Riudoms',
    'el Morell',
    'La Adrada',
    'La Guardia',
    'Cabanas de la Sagra',
    'Guadamur',
    'La Pueblanueva',
    'Petres',
    'Cubas',
    'Perales del Puerto',
    'Los Navalmorales',
    'La Orden',
    'Llagostera',
    'El Garrobo',
    'Consell',
    'Huelma',
    'Benavente',
    'Huete',
    'Langa de Duero',
    'Ares',
    'Villares del Saz',
    'Ayamonte',
    'Alosno',
    'Sant Carles de la Rapita',
    'Beniarjo',
    'Moya',
    'La Roca del Valles',
    'Avia',
    'Villaviad',
    'Cecenas',
    'San Felices',
    'Lamadrid',
    'Navia',
    'Capella',
    'Bellpuig',
    'Villar de Gallimazo',
    'Valdepolo',
    'Carcastillo',
    'Miranda del Castanar',
    'Santa Marta de Tormes',
    'Malpica',
    'La Calzada de Oropesa',
    'Torre del Mar',
    'Sufli',
    'Casas Viejas',
    'Sestao',
    'Galdakao',
    'Alhama de Granada',
    'Villacarrillo',
    'NavÃ s',
    'Olite',
    'Illora',
    'Artesa de Segre',
    'Luzaide',
    'Colmenar de Oreja',
    "Vallbona d'Anoia",
    'Gata de Gorgos',
    'Alcover',
    'Deba',
    'Constanti',
    'Aguilar',
    'Candeleda',
    'Manilva',
    'San Martin',
    'Yunquera de Henares',
    'Ador',
    'Amer',
    'Castejon del Puente',
    'Santa Fe',
    'Santa Eulalia',
    'Brea de Aragon',
    'Santiago de la Ribera',
    'PadrÃ³n',
    'Flix',
    'Calasparra',
    'Villafranca',
    'la Nucia',
    'Berango',
    'Macanet de la Selva',
    'Mula',
    'Luyando',
    'Torrefarrera',
    'Casarrubios del Monte',
    'Soto',
    'Lebrija',
    'Valleseco',
    'Los Yebenes',
    'Salcedo',
    'Castellbisbal',
    'Neda',
    'Espiel',
    'Recas',
    'Santa Brigida',
    'Balsareny',
    'Humanes',
    'Alcazar de San Juan',
    'Lagartera',
    'Carbajal de la Legua',
    'Teruel',
    'Ezcaray',
    'Tafalla',
    'Alora',
    'Torrox',
    'Sotopalacios',
    'La Pobla de Claramunt',
    'Cardona',
    'Camponaraya',
    'Cortes de la Frontera',
    'La Vinuela',
    'Velilla de San Antonio',
    'La Selva',
    'Verges',
    'Mira',
    'Llerena',
    'Belgida',
    'Navaridas',
    'La Codosera',
    'Pinos del Valle',
    'Sanlucar la Mayor',
    'Los Montes',
    'Espirdo',
    'La Calzada',
    'Mondonedo',
    'Orellana la Vieja',
    'Olvega',
    'Villaquilambre',
    'Castillo del Romeral',
    'Sabucedo',
    'Llinars del Valles',
    'Mora',
    'Huetor-Tajar',
    'Albatera',
    'Figueras',
    'Monteagudo',
    'Sueca',
    'Sedavi',
    'Esplugues de Llobregat',
    'Tarrega',
    'Torredembarra',
    'La Riba',
    'Alfondeguilla',
    'Altet',
    'La Puebla de Almoradiel',
    'Herencia',
    'Zaratamo',
    'Chauchina',
    'Esteiro',
    'Botorrita',
    'Chiclana de Segura',
    'Urnieta',
    'Renedo',
    'Villacastin',
    'Hornachuelos',
    'Mallen',
    'Cogollos',
    'Quincoces de Yuso',
    'Odon',
    'Rosell',
    'Sancti Spiritus',
    'Santiurde de Toranzo',
    'Bujaraloz',
    'Belicena',
    'Almendralejo',
    'Trillo',
    'Villamayor',
    'La Torre',
    'San BartolomÃ© de Tirajana',
    'Castelseras',
    'La Palma del Condado',
    'la Bisbal del Penedes',
    'Llano',
    'Mondariz',
    'Monterroso',
    'Hinojosa del Valle',
    'Guimar',
    'Guarena',
    'Otero',
    'Ribera del Fresno',
    'Cruz',
    'Mezalocha',
    "S'Arenal",
    'Perillo',
    'Guardamar del Segura',
    'Valderrobres',
    'Sesena Nuevo',
    'Talamanca de Jarama',
    'Puerto del Rosario',
    'Uceda',
    'Alfambra',
    'La Arena',
    'Calo',
    'Orpesa/Oropesa del Mar',
    'Abaran',
    'Luarca',
    'Olazagutia',
    'Yuncler',
    'Igueste',
    'Zarzalejo',
    'Villa',
    'MonÃ²ver',
    'Lucillos',
    'Villacondide',
    'La Antilla',
    'Zarzuela',
    'Pereiro de Aguiar',
    'Muros de Nalon',
    'Cati',
    'Arnedillo',
    'La Paz',
    'La Carolina',
    'El Tablero',
    'Ojen',
    'La Granja',
    'Teror',
    'Marmolejo',
    'Mollina',
    'MazarrÃ³n',
    'Diezma',
    'Limones',
    'Bornos',
    'Los Santos de la Humosa',
    'Malpartida de Plasencia',
    'Santa Maria del Campo',
    'Palos de la Frontera',
    'Roales',
    'Benatae',
    'Olmedo',
    'Guadalcazar',
    'Maside',
    'La Florida',
    'Ricla',
    'Estellencs',
    'Artajona',
    'Fitero',
    'Fortuna',
    'Tudela de Duero',
    'El Toro',
    'Quintes',
    'Cangas del Narcea',
    'Corbera',
    'Villarejo',
    'Molina de Aragon',
    'Alegria',
    'Arenas de San Pedro',
    'Villamayor de Santiago',
    'Mercadal',
    'Higuera la Real',
    'Villanueva de la Torre',
    'Sabero',
    'La Fuente de San Esteban',
    'Albujon',
    'Membrilla',
    'Almodovar del Campo',
    'Pola de Lena',
    'Cruilles',
    'Asteasu',
    'Tobarra',
    'Useras',
    'SalobreÃ±a',
    'Algadefe',
    'Lliria',
    'Baina',
    'Monesterio',
    'Benagalbon',
    'Astigarraga',
    'Alhama de Murcia',
    'Cerro Muriano',
    'Faura',
    'Gata',
    'Cuadros',
    'Palmeira',
    'El Corrillo',
    'Santa Marta de Ortigueira',
    'Sierra',
    'Plan',
    'Plentzia',
    'Moriscos',
    'Barbate',
    'Santa Cruz de la Zarza',
    'Benissa',
    'Galdames Beitia',
    'Herrera del Duque',
    'Santa EugÃ¨nia de Berga',
    'Cifuentes',
    'Montijo',
    'Paradela',
    'Alcanadre',
    'Pinseque',
    'Cantimpalos',
    'La Secuita',
    'la Febro',
    'Ouces',
    'Vilafant',
    'Mura',
    'Torrecilla de Alcaniz',
    'Tuineje',
    'Ibarruri',
    'Cuntis',
    'Cedillo del Condado',
    'Yaiza',
    'Ledrada',
    'Terradillos',
    'Camas',
    'Muros',
    'Malpica',
    'Villarrubia de los Ojos',
    'Sant Andreu de Llavaneres',
    'Fornells de la Selva',
    'Vejer de la Frontera',
    'Agaete',
    'Guamasa',
    'San Luis de Sabinillas',
    'Comares',
    'La Serna',
    'Villaralbo',
    'Navatejera',
    'La Granada',
    'Albuixech',
    'Museros',
    'Massamagrell',
    'La Plana',
    'Torrent',
    'Aldaia',
    'Villarreal de Alava',
    'Vilavella',
    'Burjassot',
    'Benimodo',
    'La Mancha Blanca',
    'Berastegui',
    'Zuazo de Vitoria',
    'Olaberria',
    'Mendaro',
    'Anoeta',
    'Orio',
    'Berriz',
    'La Puebla de Arganzon',
    'Gizaburuaga',
    'Moraleja de Enmedio',
    'Villacarriedo',
    'San Jorge',
    'Lucena del Puerto',
    'San Martino',
    'El Raal',
    'Cenicero',
    'Fuenmayor',
    'Santo Domingo de la Calzada',
    'Berriobeiti',
    'Marcilla',
    'Aduna',
    'Fuentes de Ebro',
    'Vilamaniscle',
    'Navacerrada',
    'Santa Maria del Paramo',
    'Norena',
    'Brafim',
    'Cabanas',
    'Monzalbarba',
    'Rincon',
    'Cabezo de Torres',
    'Garachico',
    'Fasnia',
    'Segura de la Sierra',
    'Riba',
    'Anglesola',
    'Juneda',
    'Barranda',
    'la Nou de Bergueda',
    'Caldelas',
    'Catoira',
    'Lillo',
    'Allariz',
    'Coles',
    "La Seu d'Urgell",
    'Azagra',
    'Campohermoso',
    'Maria de la Salut',
    'Coria del RÃ­o',
    'Aldealsenor',
    'Golmayo',
    'Bigues i Riells',
    'Torrelletas',
    'Cidones',
    'San Feliz de Torio',
    'Medrano',
    'PremiÃ  de Dalt',
    'Guenes',
    'Tombrio de Arriba',
    'SamalÃºs',
    'Yebes',
    'Grove, O',
    'Encinasola',
    'Sevares',
    'Valverde del Fresno',
    'Martin',
    'Zahara de los Atunes',
    'Fortia',
    'Mutiloa',
    'Illana',
    'Checa',
    'Rios',
    'Montfulla',
    'Torres de Segre',
    'Llanes',
    'Carrascal de Barregas',
    'Martinet',
    'San Anton',
    'Suances',
    'Selaya',
    'Sotogrande',
    'Azpeitia',
    'Crevillent',
    'Morro del Jable',
    'Albalat dels Sorells',
    'Sepulveda',
    'La Virgen del Pilar',
    'Costur',
    'Andeiro',
    'La Pedrera',
    'Valdezufre',
    'Puigpelat',
    'Aldeanueva de Ebro',
    'La Campana',
    'Marugan',
    'Pantoja',
    'Puerto Lumbreras',
    'Navamorcuende',
    'Santa Uxia de Ribeira',
    'La Perdoma',
    'Casillas de Coria',
    'Urduna',
    'Motilla del Palancar',
    'Henares',
    'San Juan del Puerto',
    'Trazo',
    'Villanueva de Gallego',
    'El Perello',
    'Villaverde de los Cestos',
    'Calders',
    'Almonacid de Zorita',
    'Ipazter',
    'Alonsotegi',
    'San Pedro',
    'Villanueva de Gomez',
    'Los Dolores',
    'Benavarri / Benabarre',
    'Sixto',
    'Agoncillo',
    'Santovenia de Pisuerga',
    'Cuellar',
    'Tias',
    'Lago',
    'Fuente el Saz',
    'Oliva de Merida',
    'Sant Jordi',
    'El Viso del Alcor',
    'Pedralba',
    'Benisuera',
    'Tarazona',
    'Anguciana',
    'Algezares',
    'Cervera del Rio Alhama',
    'Castellar de la Frontera',
    'Mombeltran',
    'La Granja de San Vicente',
    'Bicorp',
    'Umbrete',
    'Losar de la Vera',
    'Traiguera',
    'Chilches',
    'Getaria',
    'Aielo de Malferit',
    'CassÃ  de la Selva',
    'Chiloeches',
    'Valdeaveruelo',
    'Verdu',
    'Perafort',
    'Castillo de Locubin',
    'O Grove',
    'Cilleros',
    'Lobios',
    "L'Olleria",
    'Gamiz',
    'AvinyÃ³',
    'Arteixo',
    'Madronera',
    'Grijota',
    'Perdiguera',
    'Cazalegas',
    'Garciaz',
    'Canillas de Albaida',
    'Son Servera',
    'Torija',
    'Moscas del Paramo',
    'Corrales',
    'ArtÃ ',
    'Ejea de los Caballeros',
    'la Riera de Gaia',
    'Pauels',
    'Antigua',
    'Almargen',
    'Piedra',
    'Pontos',
    'Encinas Reales',
    'Elgorriaga',
    'Pajara',
    'Caldes de Malavella',
    'Agullana',
    'Bullas',
    'Alba de Tormes',
    'Carrizo de la Ribera',
    'Pont de Molins',
    'Sagra',
    'Benavides',
    'Casillas del Angel',
    'Vilacolum',
    'Los Rosales',
    'Penaflor',
    'Callosa de Segura',
    'Itza',
    'Vallada',
    'Babilafuente',
    'Aldeaseca de Armuna',
    "L'Albir",
    'Madridanos',
    'Aldeamayor de San Martin',
    'Briviesca',
    'Fabero',
    'Catral',
    'Vicolozano',
    'Fresno de la Vega',
    'Puentenansa',
    'Ramales de la Victoria',
    'Camarma de Esteruelas',
    'Genoves',
    'Nivar',
    'Montagut',
    'Portillo',
    'Ondara',
    'Olocau',
    'Jorba',
    'Tineo',
    'Navas del Rey',
    'Corvera',
    'Puebla del Maestre',
    'Corvera',
    'Fuente-Alamo',
    'Valsequillo de Gran Canaria',
    'Camos',
    'Pruvia',
    'Dolores',
    'Argamasilla de Calatrava',
    'Magan',
    'Torres',
    'El Abrigo',
    'Villasevil',
    'Cruces',
    'BonmatÃ­',
    'Almansa',
    'Mont',
    'Pulgar',
    'Antas',
    'Almazcara',
    'Sarinena',
    'Barros',
    'Rasines',
    'Almudebar',
    'Trespaderne',
    'Jerez de los Caballeros',
    'Puentelarra',
    'Begis',
    'Morella',
    'Argonos',
    'Porto Cristo',
    'Ferreira',
    'San BartolomÃ©',
    'Dalias',
    'Jabaga',
    'Yuncos',
    'Nalda',
    'Villanueva de Cordoba',
    'Cubillos del Sil',
    'Infantes',
    'Carabana',
    'Begijar',
    'Talarn',
    'Agramunt',
    'Villamartin',
    'Sarroca de Lleida',
    'Rodonya',
    'Morata de Tajuna',
    'Castandiello',
    'San Blas',
    'Matapozuelos',
    'La Espina',
    'Caso',
    'Broto',
    'Mata',
    'Zubiri',
    'Carral',
    'Meano',
    'Borox',
    'Novallas',
    'Laudio-Llodio',
    'Mentrida',
    'Mutriku',
    'Grazalema',
    'Berantevilla',
    'Corral de Almaguer',
    'Hostalric',
    'Abanilla',
    'Alburquerque',
    'Rafelcofer',
    'Lorqui',
    'Gaceta',
    'Pelayos de la Presa',
    'San Clemente',
    'Gojar',
    'Sant Marti de Malda',
    'Cabanaquinta',
    'Torre del Campo',
    'Chinchilla de Monte Aragon',
    'La Penilla',
    'Castellvi de Rosanes',
    'Seva',
    'Planes',
    'La Pedraja de Portillo',
    'Zuera',
    'Alagon',
    'Corgo',
    'Torredonjimeno',
    'Ron',
    'Avinyonet del Penedes',
    'Les Fonts',
    'Alfara de Algimia',
    'Siguenza',
    'Brihuega',
    'Castrogonzalo',
    'Calamonte',
    'Fuensanta de Martos',
    'Aldeanueva',
    'Lajares',
    'Torrelaguna',
    'Monachil',
    'Vitigudino',
    'Horcajo de las Torres',
    'Nestares',
    'Somahoz',
    'San Miguel de las Duenas',
    'Alfarrasi',
    'Sax',
    'Berriatua',
    'La Garganta',
    "Bellcaire d'Emporda",
    'San Rafael',
    'Tijarafe',
    'Amavida',
    'San Isidro',
    'Bollullos par del Condado',
    'Periedo',
    'Oruna',
    'Ajo',
    'Ortiguero',
    'Cudillero',
    'SangÃ¼esa',
    'Echarri-Aranaz',
    'Pozo de la Serna',
    'Campo de Criptana',
    'Pedro Munoz',
    'Villar',
    'Sant Pere de Riudebitlles',
    'Viladrau',
    'Aranguren',
    'Llofriu',
    'Andosilla',
    'Lezama',
    "Platja d'Aro",
    'Albendin',
    'Canete de las Torres',
    'Pueyo de Santa Cruz',
    'Esplus',
    'Arico',
    'AnglÃ¨s',
    'Pinar',
    'Villa del Rio',
    'AlmadÃ©n',
    'Cox',
    'Jacarilla',
    'Bogajo',
    'Cornellana',
    'Villaseca de la Sagra',
    'Quintanar de la Orden',
    'Villamanrique',
    'Santa Cruz de Mudela',
    'Chozas de Canales',
    'Mosqueruela',
    'Zorraquin',
    'Zaratan',
    'La Canada de San Urbano',
    'La Arena',
    'Aznalcollar',
    "l'Escala",
    'Benidoleig',
    'Burguillos',
    'Cueto',
    'Villanueva de la Reina',
    'Matamorosa',
    'Cadiar',
    'Monda',
    'Bembibre',
    'Villanueva del Ariscal',
    'Bedmar',
    'Capmany',
    'Santa Olalla',
    'Pezuela de las Torres',
    'Beranga',
    'Collado',
    'Sigueiro',
    'Carcabuey',
    'el Catllar',
    'Masllorenc',
    'SÃºria',
    'La Frontera',
    'Escurial',
    'Cicero',
    'Bolanos de Calatrava',
    'Torralba de Calatrava',
    'Malagon',
    'Belvis de Jarama',
    'Puebla de la Calzada',
    'Montefrio',
    'Torrenueva',
    'Xunqueira de Espadanedo',
    'Aguilafuente',
    'Olula de Castro',
    'Benahadux',
    'Torremocha del Campo',
    'Albelda de Iregua',
    'Tremp',
    'Arevalo',
    'Boimorto',
    'Berguenda',
    'El Arahal',
    'Terrer',
    'Sarria',
    'Molinos de Duero',
    'Luzmela',
    'Riaza',
    'Pasaia',
    'Trasona',
    'Illueca',
    'Touro',
    'Vilanova de Arousa',
    'Lodosa',
    'San Adrian',
    'Legasa',
    'Cizur Menor',
    'Bara',
    'Mungia',
    'Medina de Pomar',
    'Abadino',
    'Usansolo',
    'Ciriza',
    'Gorriti',
    'Villacuende',
    'Revellinos',
    'Bustarviejo',
    'La Victoria',
    'Millares',
    'Sollana',
    'Nino Perdido',
    'Caserio El Campello',
    'Forua',
    'Landa',
    'Villanueva',
    'Villafranqueza',
    'Santo Angel',
    'Pedro',
    'Palmones',
    'Los Barrios',
    'La Canada',
    'Valverde',
    'San Roman',
    'Ontinyent',
    'La Nora',
    'Guadalupe',
    'Balazote',
    'Bejar',
    'Ciudad Rodrigo',
    'Astorga',
    'Montesa',
    "L'Alcudia de Crespins",
    'Manuel',
    'Quart de Poblet',
    'Caudete',
    'Mortera',
    'Valdecilla',
    'Ontur',
    'Naquera',
    'Loriguilla',
    'Fuentealbilla',
    'Benijofar',
    'Daimiel',
    'el Camp de Mirra',
    'Orce',
    'Galera',
    'Mequinensa / Mequinenza',
    'Salinas',
    'Solsona',
    'Padul',
    'Campo',
    'Sangonera la Verde',
    'Calig',
    'Santa Maria',
    'Gelsa',
    'Pedrola',
    'Torralba de Oropesa',
    'Venturada',
    'Pomar',
    'Alfajarin',
    "Canet d'En Berenguer",
    'Trebujena',
    'Espera',
    'Benifato',
    'Tamaraceite',
    'San Cristobal',
    'La Felguera',
    'La Baneza',
    'Cigales',
    'Fuentes de Valdepero',
    'Linyola',
    'Olivella',
    'Cerdanyola del VallÃ¨s',
    'Cebolla',
    'Villaviciosa',
    'Sotillo de la Adrada',
    'Cuevas de San Marcos',
    'Cajar',
    'La Herradura',
    'Huetor Santillan',
    'Dodro',
    'Oleiros',
    'Sodupe',
    'Placencia',
    'Legorreta',
    'Elgoibar',
    'Landa',
    'Azkoitia',
    'Compostela',
    'Rendar',
    'Orial',
    'Berron',
    'Villaviciosa',
    'Ribadesella',
    'Obanos',
    'Peralta â€“ Azkoien',
    'Cadreita',
    'Betelu',
    'Mollo',
    'Serrateix',
    'Bellver de Cerdanya',
    'Siete Iglesias de Trabancos',
    'San Martin de Unx',
    'Lazagurria',
    'Eltzaburu',
    'Tordillos',
    'Valcabado',
    'Garrigas',
    'Guijuelo',
    'Robleda-Cervantes',
    'Trevino',
    "L'Ampolla",
    'Bordils',
    'Pedrosillo de Alba',
    'Gozon de Ucieza',
    'Mataluenga',
    'la Pobla de Montornes',
    'Santa Fe de Mondujar',
    'Tarifa',
    'Bajamar',
    'Alozaina',
    'La Playa de Arguineguin',
    'Salvatierra',
    'Marzagan',
    'Tejina',
    'Sierra de Luna',
    'La Rambla',
    'Gamonal',
    'Las Hortichuelas',
    'El Puerto',
    'Nueva',
    'GibraleÃ³n',
    'El Sotillo',
    'Fuentes',
    'Aceuchal',
    'Villanueva de Perales',
    'Real de Gandia',
    'Venta de Banos',
    'Orusco',
    'Rajadell',
    'San Nicolas del Puerto',
    'Tenteniguada',
    'Pescador',
    'Santa Eulalia',
    'Hornachos',
    'Velilla',
    'Borben',
    'Guejar-Sierra',
    'Valdepenas de la Sierra',
    'Valderrubio',
    'El Torno',
    'Brazatortas',
    'Granja de Rocamora',
    'Almazan',
    'Guadalupe',
    'La Solana',
    'Competa',
    'Pruna',
    'Tous',
    'Castellar de Santiago',
    'Almassera',
    'Villarrubia',
    'Calonge',
    'Montillana',
    'Montejicar',
    'Sancibrian',
    'Elechas',
    'Pontejos',
    'Colinas de Trasmonte',
    'Lanaja',
    'Pioz',
    'Calahonda',
    'Moncofar',
    'Botarell',
    'La Calzada de Calatrava',
    'Cabezarrubias del Puerto',
    'Astrain',
    'Ullastrell',
    'Garraf',
    'Los Angeles',
    'Gautegiz Arteaga',
    'la Baronia de Rialb',
    'La Torre',
    'Castell de Ferro',
    'Alpicat',
    'Cabeza del Buey',
    'Navezuelas',
    'Capsec',
    'Ponts',
    'La Pola de Gordon',
    'Valdeavero',
    'Sarandones',
    'Miono',
    'Hinojedo',
    'Los Silos',
    'Titulcia',
    'Villabalter',
    'Campanario',
    'Beniarbeig',
    'Calaf',
    'Berrobi',
    'Horche',
    'Miranda de Arga',
    'Castropol',
    'Astudillo',
    'Daroca',
    'Agreda',
    'Vallmoll',
    'El Burgo de Ebro',
    'Pozo de Guadalajara',
    'Campuzano',
    'Ruente',
    'Barcena de Cicero',
    'Montmajor',
    'Benifairo de les Valls',
    'Abegondo',
    'Maceira',
    'Santo Domingo',
    'La Aljorra',
    'Riumors',
    'Santa Cristina de Aro',
    'Camprodon',
    'CampdevÃ nol',
    'Llers',
    'Peratallada',
    'Vila-seca',
    'Fuente el Fresno',
    'Benamargosa',
    'Lerma',
    'San Pedro',
    'Colera',
    'Ordis',
    'Gordon',
    'Outes',
    'Posadas',
    'El Palmar de Troya',
    'Guillena',
    'Villanubla',
    'Fuentes de Jiloca',
    'Rincon de Soto',
    'El Provencio',
    'Constantina',
    'La Puebla de los Infantes',
    'Los Llanos de Aridane',
    'Altura',
    'Rubielos de Mora',
    'Valmojado',
    'Can Picafort',
    'San Juan',
    'Galizano',
    'Puente Viesgo',
    'Nembro',
    'Valverde del Majano',
    'Banugues',
    'Langa',
    'Arrubal',
    'Curro',
    'Villanueva de Azoague',
    'Las Navas de la Concepcion',
    'Perafita',
    'la Granadella',
    'Serena',
    'Gaucin',
    'Villanueva del Rio',
    'San Ildefonso',
    'Peralejo',
    'Ourense',
    'Cartelle',
    'Pastrana',
    'El Casar',
    'Lanjaron',
    'Herrera',
    'Maria de Huerva',
    'Villarluengo',
    'Cebreros',
    'Iniesta',
    'Dilar',
    'Redovan',
    'La Raya',
    'Montroy',
    'Para',
    'Pasai San Pedro',
    'Benacazon',
    'Monforte del Cid',
    'Agost',
    'Barbarroja',
    'Castilleja de Guzman',
    'Esparreguera',
    'Espinardo',
    'Roses',
    'Los Palacios',
    'Torrefeta',
    'Bellvei',
    'San Vicente',
    'Arnuero',
    'Ayegui',
    'Almuna',
    'Chapela',
    'Salvatierra de Mino',
    'Luou',
    'Vilasantar',
    'A Pobra do Caraminal',
    'Camarassa',
    'Arroyal',
    'Alza',
    'Lezo',
    'Golmes',
    'Central',
    'Ministerio',
    'El Pedernoso',
    'Villalpardo',
    'Hondon de las Nieves',
    'Rus',
    'Huerto',
    'Panillo',
    'Pinos Puente',
    'Otura',
    'Astrabudua',
    'Fuenterrabia',
    'Catadau',
    'Cala Ratjada',
    'Carbajosa de la Sagrada',
    'El Grao',
    'Carpesa',
    'Rafal',
    'Celeiros',
    'Santiponce',
    'Pedrosillo el Ralo',
    'Lijar',
    'Santa Cristina',
    'Adrall',
    'Benicolet',
    'Beniel',
    'Belvis de la Jara',
    'Parres',
    'Santa EulÃ lia de Riuprimer',
    'Hernialde',
    'Poboleda',
    'Comillas',
    'Paredes de Nava',
    'Calatorao',
    'Hondon de los Frailes',
    'Castuera',
    'Alfara del Patriarca',
    'Poris de Abona',
    'Isora',
    'Illa',
    'Navas de Riofrio',
    'Cetina',
    'Valpalmas',
    'Senija',
    'Tahiche',
    'San Fernando',
    'Artenara',
    'San Vicente de Alcantara',
    'Cervo',
    'Pulianas',
    'Panticosa',
    'Turis',
    'La Cabrera',
    'Oza de los Rios',
    'Alcala del Obispo',
    'Villanueva del Fresno',
    'Sant JuliÃ  de Vilatorta',
    'Mota del Marques',
    'Fregenal de la Sierra',
    'Segorbe',
    'Montalban de Cordoba',
    'Alhambra',
    'Villanueva de la Jara',
    'Miguel Esteban',
    'Pozoamargo',
    "s'ArracÃ³",
    'Sardina',
    'Querol',
    'La Lantejuela',
    'Villamanrique de la Condesa',
    'Isla Becoa',
    'Montealegre del Castillo',
    'Sudanell',
    'Tora de Riubregos',
    'Madrigueras',
    'Redes',
    'Soto de Cerrato',
    'Campina',
    'Torroella de Fluvia',
    'Saamasas',
    'Negreira',
    'Navahermosa',
    'Medio',
    'Campins',
    'Salobral',
    'Caspe',
    'La Torre',
    'Puebla de Don Fadrique',
    'La Canalosa',
    'Busot',
    'Alhama de Aragon',
    'PulpÃ­',
    'Bitem',
    'Hijar',
    'Guzman',
    'Padilla del Ducado',
    'Arca',
    'Buenache de Alarcon',
    'Noves',
    'Valdetorres',
    'Vilatenim',
    'Sant Feliu de Llobregat',
    'la Pobla de Mafumet',
    'PuigcerdÃ ',
    'Algodonales',
    'Mejorada',
    'Algarrobo',
    'La Mamola',
    'Velez-Rubio',
    'Rebolledo',
    'AlcalÃ¡ de los Gazules',
    'El Hoyo de Pinares',
    'Valle de Oro',
    'Macael',
    'Colonia de San Pedro',
    'Soneja',
    'Sobradiel',
    'Hurchillo',
    'Villa de Don Fadrique',
    'El Alamo',
    'O Mazo',
    'Quintana de la Serena',
    'Ontinena',
    'Moreda',
    'Nuez de Ebro',
    'Hospital',
    'Cardenal',
    'Canar',
    'Neda',
    'Celeiro',
    'Boceguillas',
    'La Matanza de Acentejo',
    'San Vicente de Leon',
    'Seoane',
    'Deustu',
    'Arroyo del Ojanco',
    'Canal',
    'Vilaplana',
    'Olivares',
    'la Vall del Bac',
    'Domingo Perez',
    'Santa Marina del Rey',
    'Tiagua',
    'Nava de la Asuncion',
    'Meira',
    'Zahinos',
    'Trabada',
    'Villahermosa',
    'Miajadas',
    'Teba',
    'Caneja',
    'Bigastro',
    'Los Corrales',
    'Las Ventanas',
    'Epila',
    'Luceni',
    'Grisen',
    'Aroche',
    'El Cuervo',
    'El Coronil',
    'Sobremazas',
    'Valdetorres de Jarama',
    'A Cidade',
    'Argelaguer',
    'La Floresta Pearson',
    'Villalonga',
    'Espolla',
    'Lousame',
    'Segura',
    'Polan',
    'Los Rios',
    'Guevejar',
    'Caborana',
    'Ujo',
    'Ziga',
    'Nora',
    'La Gineta',
    'Triana',
    'Blancos',
    'Parada',
    'Lama',
    'Gargallo',
    'Borja',
    'La Luisiana',
    'Huevar',
    'Beznar',
    'Fuensanta',
    'Lires',
  ],
  tld: ['.es'],
  cca2: 'ES',
  ccn3: '724',
  cca3: 'ESP',
  cioc: 'ESP',
  currency: ['EUR'],
  callingCode: ['34'],
  capital: 'Madrid',
  altSpellings: ['ES', 'Kingdom of Spain', 'Reino de España'],
  region: 'Europe',
  subregion: 'Southern Europe',
  languages: {
    cat: 'Catalan',
    eus: 'Basque',
    glg: 'Galician',
    oci: 'Occitan',
    spa: 'Spanish',
  },
  translations: {
    deu: { official: 'Königreich Spanien', common: 'Spanien' },
    fra: { official: "Royaume d'Espagne", common: 'Espagne' },
    hrv: { official: 'Kraljevina Španjolska', common: 'Španjolska' },
    ita: { official: 'Regno di Spagna', common: 'Spagna' },
    jpn: { official: 'スペイン王国', common: 'スペイン' },
    nld: { official: 'Koninkrijk Spanje', common: 'Spanje' },
    por: { official: 'Reino de Espanha', common: 'Espanha' },
    rus: { official: 'Королевство Испания', common: 'Испания' },
    spa: { official: 'Reino de España', common: 'España' },
    fin: { official: 'Espanjan kuningaskunta', common: 'Espanja' },
  },
  latlng: [40, -4],
  demonym: 'Spanish',
  landlocked: false,
  borders: ['AND', 'FRA', 'GIB', 'PRT', 'MAR'],
  area: 505992,
  provinces: ['Spratly Islands'],
  nativeName: 'España',
  timezones: ['UTC', 'UTC+01:00'],
} as const;
