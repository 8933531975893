export const SPM = {
  name: {
    common: 'Saint Pierre and Miquelon',
    official: 'Saint Pierre and Miquelon',
    native: {
      fra: {
        official: 'Collectivité territoriale de Saint-Pierre-et-Miquelon',
        common: 'Saint-Pierre-et-Miquelon',
      },
    },
  },
  tld: ['.pm'],
  cca2: 'PM',
  ccn3: '666',
  cca3: 'SPM',
  cioc: '',
  currency: ['EUR'],
  callingCode: ['508'],
  capital: 'Saint-Pierre',
  altSpellings: ['PM', 'Collectivité territoriale de Saint-Pierre-et-Miquelon'],
  region: 'Americas',
  subregion: 'Northern America',
  languages: { fra: 'French' },
  translations: {
    deu: {
      official: 'St. Pierre und Miquelon',
      common: 'Saint-Pierre und Miquelon',
    },
    fra: {
      official: 'Saint-Pierre-et-Miquelon',
      common: 'Saint-Pierre-et-Miquelon',
    },
    hrv: {
      official: 'Saint Pierre i Miquelon',
      common: 'Sveti Petar i Mikelon',
    },
    ita: {
      official: 'Saint Pierre e Miquelon',
      common: 'Saint-Pierre e Miquelon',
    },
    jpn: {
      official: 'サンピエール島·ミクロン島',
      common: 'サンピエール島・ミクロン島',
    },
    nld: {
      official: 'Saint-Pierre en Miquelon',
      common: 'Saint Pierre en Miquelon',
    },
    por: {
      official: 'Saint Pierre e Miquelon',
      common: 'Saint-Pierre e Miquelon',
    },
    rus: { official: 'Сен-Пьер и Микелон', common: 'Сен-Пьер и Микелон' },
    spa: { official: 'San Pedro y Miquelón', common: 'San Pedro y Miquelón' },
    fin: {
      official: 'Saint-Pierre ja Miquelon',
      common: 'Saint-Pierre ja Miquelon',
    },
  },
  latlng: [46.83333333, -56.33333333],
  demonym: 'French',
  landlocked: false,
  borders: [],
  area: 242,
} as const;
