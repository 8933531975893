export const ARE = {
  name: {
    common: 'United Arab Emirates',
    official: 'United Arab Emirates',
    native: {
      ara: {
        official: 'الإمارات العربية المتحدة',
        common: 'دولة الإمارات العربية المتحدة',
      },
    },
  },
  provinces: [
    "'Ajman",
    'Abu Zaby (Abu Dhabi)',
    'Al Fujayrah',
    'Ash Shariqah (Sharjah)',
    'Dubayy (Dubai)',
    "Ra's al Khaymah",
    'Umm al Qaywayn',
  ],
  timezones: ['UTC+04'],
  nativeName: 'دولة الإمارات العربية المتحدة',
  cities: [
    'Abu Dhabi',
    'Sharjah',
    'Dubai',
    'Dayrah',
    'Al Ain',
    'Fujairah',
    'Ras al-Khaimah',
    'Ar Ruways',
    'As Satwah',
    'Al Khan',
  ],
  tld: ['.ae', 'امارات.'],
  cca2: 'AE',
  ccn3: '784',
  cca3: 'ARE',
  cioc: 'UAE',
  currency: ['AED'],
  callingCode: ['971'],
  capital: 'Abu Dhabi',
  altSpellings: ['AE', 'UAE', 'Emirates'],
  region: 'Asia',
  subregion: 'Western Asia',
  languages: { ara: 'Arabic' },
  translations: {
    deu: {
      official: 'Vereinigte Arabische Emirate',
      common: 'Vereinigte Arabische Emirate',
    },
    fra: { official: 'Émirats arabes unis', common: 'Émirats arabes unis' },
    hrv: {
      official: 'Ujedinjeni Arapski Emirati',
      common: 'Ujedinjeni Arapski Emirati',
    },
    ita: { official: 'Emirati Arabi Uniti', common: 'Emirati Arabi Uniti' },
    jpn: { official: 'アラブ首長国連邦', common: 'アラブ首長国連邦' },
    nld: {
      official: 'Verenigde Arabische Emiraten',
      common: 'Verenigde Arabische Emiraten',
    },
    por: {
      official: 'Emirados Árabes Unidos',
      common: 'Emirados Árabes Unidos',
    },
    rus: {
      official: 'Объединенные Арабские Эмираты',
      common: 'Объединённые Арабские Эмираты',
    },
    spa: {
      official: 'Emiratos Árabes Unidos',
      common: 'Emiratos Árabes Unidos',
    },
    fin: {
      official: 'Yhdistyneet arabiemiirikunnat',
      common: 'Arabiemiraatit',
    },
  },
  latlng: [24, 54],
  demonym: 'Emirati',
  landlocked: false,
  borders: ['OMN', 'SAU'],
  area: 83600,
} as const;
