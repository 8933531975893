export const MDV = {
  name: {
    common: 'Maldives',
    official: 'Republic of the Maldives',
    native: {
      div: {
        official: 'ދިވެހިރާއްޖޭގެ ޖުމްހޫރިއްޔާ',
        common: 'ދިވެހިރާއްޖޭގެ',
      },
    },
  },
  cities: ['MalÃ©', 'Naifaru'],
  tld: ['.mv'],
  cca2: 'MV',
  ccn3: '462',
  cca3: 'MDV',
  cioc: 'MDV',
  currency: ['MVR'],
  callingCode: ['960'],
  capital: 'Malé',
  altSpellings: [
    'MV',
    'Maldive Islands',
    'Republic of the Maldives',
    'Dhivehi Raajjeyge Jumhooriyya',
  ],
  region: 'Asia',
  subregion: 'Southern Asia',
  languages: { div: 'Maldivian' },
  translations: {
    deu: { official: 'Republik Malediven', common: 'Malediven' },
    fra: { official: 'République des Maldives', common: 'Maldives' },
    hrv: { official: 'Republika Maldivi', common: 'Maldivi' },
    ita: { official: 'Repubblica delle Maldive', common: 'Maldive' },
    jpn: { official: 'モルディブ共和国', common: 'モルディブ' },
    nld: { official: 'Republiek van de Malediven', common: 'Maldiven' },
    por: { official: 'República das Maldivas', common: 'Maldivas' },
    rus: { official: 'Республика Мальдивы', common: 'Мальдивы' },
    spa: { official: 'República de las Maldivas', common: 'Maldivas' },
    fin: { official: 'Malediivien tasavalta', common: 'Malediivit' },
  },
  latlng: [3.25, 73],
  demonym: 'Maldivan',
  landlocked: false,
  borders: [],
  area: 300,
} as const;
