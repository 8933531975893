import { computed } from 'vue';
import { TablePanel } from 'table-panel';

import { useStore } from '@store';

import { DataItem } from './table';

type TP = TablePanel<DataItem>;

const store = useStore();

/**
 * ...
 *
 * @param tableKey ...
 * @returns ...
 */
export function useRows(tableKey: string) {
  const getterPath = `${tableKey}/rows`;

  if (!(getterPath in store.getters)) {
    throw new Error(
      `[useRows] store getter path "${getterPath}" could not be found.`,
    );
  }

  return createRef<TP['rows']>(getterPath);
}

/**
 * ...
 *
 * @param tableKey ...
 * @returns ...
 */
export function useRowCount(tableKey: string) {
  const getterPath = `${tableKey}/rowCount`;

  if (!(getterPath in store.getters)) {
    throw new Error(
      `[useRowCount] store getter path "${getterPath}" could not be found.`,
    );
  }

  return createRef<TP['rowCount']>(getterPath);
}

/**
 * ...
 *
 * @param tableKey ...
 * @returns ...
 */
export function useFilteredRows(tableKey: string) {
  const getterPath = `${tableKey}/filteredRows`;

  if (!(getterPath in store.getters)) {
    throw new Error(
      `[useFilteredRows] store getter path "${getterPath}" could not be found.`,
    );
  }

  return createRef<TP['filteredRows']>(getterPath);
}

/**
 * ...
 *
 * @param tableKey ...
 * @returns ...
 */
export function useTableMenu(tableKey: string) {
  const getterPath = `${tableKey}/tableMenu`;

  if (!(getterPath in store.getters)) {
    throw new Error(
      `[useTableMenu] store getter path "${getterPath}" could not be found.`,
    );
  }

  return createRef<TP['tableMenu']>(getterPath);
}

//#region Helper Functions

function createRef<T = unknown>(getterPath: string) {
  return computed(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    return (store.getters as any)[getterPath] as T;
  });
}

//#endregion Helper Functions
