export const MKD = {
  name: {
    common: 'Macedonia',
    official: 'Republic of Macedonia',
    native: { mkd: { official: 'Република Македонија', common: 'Македонија' } },
  },
  cities: [
    'Lisice',
    'Skopje',
    'Prilep',
    'Veles',
    'Kumanovo',
    'Ilinden',
    'Cair',
    'Kavadarci',
    'Pripor',
    'KoÄani',
    'Makedonska Kamenica',
    'Struga',
    'Shtip',
    'Bitola',
    'Debar',
    'Demir Hisar',
    'Strumica',
    'Kicevo',
    'Berovo',
    'Tetovo',
    'Ohrid',
    'Gostivar',
    'Labunista',
    'Radovis',
    'Negotino',
    'Probistip',
    'Gevgelija',
    'Bogdanci',
    'Delcevo',
    'Kadino',
    'Novo Selo',
  ],
  tld: ['.mk'],
  cca2: 'MK',
  ccn3: '807',
  cca3: 'MKD',
  cioc: 'MKD',
  currency: ['MKD'],
  callingCode: ['389'],
  capital: 'Skopje',
  altSpellings: [
    'MK',
    'Macedonia, the Former Yugoslav Republic of',
    'Republic of Macedonia',
    'Република Македонија',
  ],
  region: 'Europe',
  subregion: 'Southern Europe',
  languages: { mkd: 'Macedonian' },
  translations: {
    deu: { official: 'Republik Mazedonien', common: 'Mazedonien' },
    fra: { official: 'République de Macédoine', common: 'Macédoine' },
    hrv: { official: 'Republika Makedonija', common: 'Makedonija' },
    ita: { official: 'Repubblica di Macedonia', common: 'Macedonia' },
    jpn: {
      official: 'マケドニア共和国',
      common: 'マケドニア旧ユーゴスラビア共和国',
    },
    nld: { official: 'Republic of Macedonia', common: 'Macedonië' },
    por: { official: 'República da Macedónia', common: 'Macedónia' },
    rus: { official: 'Республика Македония', common: 'Республика Македония' },
    spa: { official: 'República de Macedonia', common: 'Macedonia' },
    fin: { official: 'Makedonian tasavalta', common: 'Makedonia' },
  },
  latlng: [41.83333333, 22],
  demonym: 'Macedonian',
  landlocked: true,
  borders: ['ALB', 'BGR', 'GRC', 'KOS', 'SRB'],
  area: 25713,
  provinces: [
    'Aracinovo',
    'Bac',
    'Belcista',
    'Berovo',
    'Bistrica',
    'Bitola',
    'Blatec',
    'Bogdanci',
    'Bogomila',
    'Bogovinje',
    'Bosilovo',
    'Brvenica',
    'Cair (Skopje)',
    'Capari',
    'Caska',
    'Cegrane',
    'Centar (Skopje)',
    'Centar Zupa',
    'Cesinovo',
    'Cucer-Sandevo',
    'Debar',
    'Delcevo',
    'Delogozdi',
    'Demir Hisar',
    'Demir Kapija',
    'Dobrusevo',
    'Dolna Banjica',
    'Dolneni',
    'Dorce Petrov (Skopje)',
    'Drugovo',
    'Dzepciste',
    'Gazi Baba (Skopje)',
    'Gevgelija',
    'Gostivar',
    'Gradsko',
    'Ilinden',
    'Izvor',
    'Jegunovce',
    'Kamenjane',
    'Karbinci',
    'Karpos (Skopje)',
    'Kavadarci',
    'Kicevo',
    'Kisela Voda (Skopje)',
    'Klecevce',
    'Kocani',
    'Konce',
    'Kondovo',
    'Konopiste',
    'Kosel',
    'Kratovo',
    'Kriva Palanka',
    'Krivogastani',
    'Krusevo',
    'Kuklis',
    'Kukurecani',
    'Kumanovo',
    'Labunista',
    'Lipkovo',
    'Lozovo',
    'Lukovo',
    'Makedonska Kamenica',
    'Makedonski Brod',
    'Mavrovi Anovi',
    'Meseista',
    'Miravci',
    'Mogila',
    'Murtino',
    'Negotino',
    'Negotino-Poloska',
    'Novaci',
    'Novo Selo',
    'Oblesevo',
    'Ohrid',
    'Orasac',
    'Orizari',
    'Oslomej',
    'Pehcevo',
    'Petrovec',
    'Plasnia',
    'Podares',
    'Prilep',
    'Probistip',
    'Radovis',
    'Rankovce',
    'Resen',
    'Rosoman',
    'Rostusa',
    'Samokov',
    'Saraj',
    'Sipkovica',
    'Sopiste',
    'Sopotnika',
    'Srbinovo',
    'Star Dojran',
    'Staravina',
    'Staro Nagoricane',
    'Stip',
    'Struga',
    'Strumica',
    'Studenicani',
    'Suto Orizari (Skopje)',
    'Sveti Nikole',
    'Tearce',
    'Tetovo',
    'Topolcani',
    'Valandovo',
    'Vasilevo',
    'Veles',
    'Velesta',
    'Vevcani',
    'Vinica',
    'Vitoliste',
    'Vranestica',
    'Vrapciste',
    'Vratnica',
    'Vrutok',
    'Zajas',
    'Zelenikovo',
    'Zileno',
    'Zitose',
    'Zletovo',
    'Zrnovci',
  ],
  nativeName: 'Македонија',
  timezones: ['UTC+01:00'],
} as const;
