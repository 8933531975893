export const PCN = {
  name: {
    common: 'Pitcairn Islands',
    official: 'Pitcairn Group of Islands',
    native: {
      eng: {
        official: 'Pitcairn Group of Islands',
        common: 'Pitcairn Islands',
      },
    },
  },
  cities: ['Adamstown'],
  tld: ['.pn'],
  cca2: 'PN',
  ccn3: '612',
  cca3: 'PCN',
  cioc: '',
  currency: ['NZD'],
  callingCode: ['64'],
  capital: 'Adamstown',
  altSpellings: ['PN', 'Pitcairn', 'Pitcairn Henderson Ducie and Oeno Islands'],
  region: 'Oceania',
  subregion: 'Polynesia',
  languages: { eng: 'English' },
  translations: {
    deu: { official: 'Pitcairn Inselgruppe', common: 'Pitcairn' },
    fra: { official: "Groupe d'îles Pitcairn", common: 'Îles Pitcairn' },
    hrv: { official: 'Pitcairn skupine otoka', common: 'Pitcairnovo otočje' },
    ita: { official: 'Pitcairn gruppo di isole', common: 'Isole Pitcairn' },
    jpn: { official: '島のピトケアングループ', common: 'ピトケアン' },
    nld: { official: 'Pitcairn groep eilanden', common: 'Pitcairneilanden' },
    por: { official: 'Pitcairn grupo de ilhas', common: 'Ilhas Pitcairn' },
    rus: { official: 'Питкэрн группа островов', common: 'Острова Питкэрн' },
    spa: { official: 'Grupo de Islas Pitcairn', common: 'Islas Pitcairn' },
    fin: { official: 'Pitcairn', common: 'Pitcairn' },
  },
  latlng: [-25.06666666, -130.1],
  demonym: 'Pitcairn Islander',
  landlocked: false,
  borders: [],
  area: 47,
} as const;
