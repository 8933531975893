import { server } from '@api/request';

/**
 * ...
 */
export interface SignupOptions {
  email: string;
  password: string;
  reCaptcha: string;
  birthdate?: string;
  firstName?: string;
  lastName?: string;
  address1?: string;
  address2?: string;
  address3?: string;
  city?: string;
  stateProvince?: string;
  country?: string;
  postalCode?: string;
  orderId?: string;
}

/**
 * ...
 *
 * @param options ...
 * @return ...
 */
export async function signup(options: SignupOptions) {
  return await server.post('v2/signup', options);
}

export namespace signup {
  export type SignupOptions = import('.').SignupOptions;
}
