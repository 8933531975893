var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "user-certifications-table-cell" },
    [
      _setup.certifications.length
        ? _vm._l(_setup.certifications, function (certification) {
            return _c("div", { key: certification.id }, [
              _c("span", [_vm._v("•")]),
              _vm._v(" "),
              _c("span", { staticClass: "certification-name pr-1" }, [
                _vm._v(_vm._s(certification.product.name)),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "certification-status" }, [
                _c(
                  "span",
                  {
                    class: {
                      danger:
                        certification.status === "REVOKED" ||
                        certification.status === "DENIED" ||
                        certification.status === "SUSPENDED" ||
                        certification.status === "EXPIRED",
                      success:
                        certification.status === "CURRENT" ||
                        certification.status === "APPROVED",
                      warning: certification.status === "AWAITING_ATTEMPT",
                      "sub-value": "sub-value",
                    },
                  },
                  [_vm._v(_vm._s(certification.status))]
                ),
              ]),
            ])
          })
        : _c("span", [_vm._v(" ... ")]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }