var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "tag-score-rating", class: { fixed: _vm.fixed } },
    [
      _vm.rating === "Good"
        ? _c("span", { staticClass: "score-good" }, [_vm._v("good")])
        : _vm.rating === "Average"
        ? _c("span", { staticClass: "score-average" }, [_vm._v("average")])
        : _vm.rating === "Poor"
        ? _c("span", { staticClass: "score-poor" }, [_vm._v("poor")])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }