export const BIH = {
  name: {
    common: 'Bosnia and Herzegovina',
    official: 'Bosnia and Herzegovina',
    native: {
      bos: { official: 'Bosna i Hercegovina', common: 'Bosna i Hercegovina' },
      hrv: { official: 'Bosna i Hercegovina', common: 'Bosna i Hercegovina' },
      srp: { official: 'Боснa и Херцеговина', common: 'Боснa и Херцеговина' },
    },
  },
  cities: [
    'Sarajevo',
    'Zenica',
    'Mostar',
    'Livno',
    'Ljubuski',
    'Lukavac',
    'Tuzla',
    'Jelah',
    'BihaÄ‡',
    'Vogosca',
    'Bijeljina',
    'Doboj',
    'Banja',
    'Donja Mahala',
    'Srpska',
    'Banja Luka',
    'Tesanj',
    'Breza',
    'Visoko',
    'Stup',
    'Lopare',
    'Prijedor',
    'Bosanska Dubica',
    'Zivinice',
    'Gracanica',
    'Core',
    'Brcko',
    'Bosanska Krupa',
    'Sanski Most',
    'Cazin',
    'Odzak',
    'Modrica',
    'Zepce',
    'GoraÅ¾de',
    'Bugojno',
    'Jajce',
    'Travnik',
    'Novi Travnik',
    'Vitez',
    'Kiseljak',
    'Siroki Brijeg',
    'Ilidza',
  ],
  tld: ['.ba'],
  cca2: 'BA',
  ccn3: '070',
  cca3: 'BIH',
  cioc: 'BIH',
  currency: ['BAM'],
  callingCode: ['387'],
  capital: 'Sarajevo',
  altSpellings: ['BA', 'Bosnia-Herzegovina', 'Босна и Херцеговина'],
  region: 'Europe',
  subregion: 'Southern Europe',
  languages: { bos: 'Bosnian', hrv: 'Croatian', srp: 'Serbian' },
  translations: {
    cym: { official: 'Bosnia and Herzegovina', common: 'Bosnia a Hercegovina' },
    deu: {
      official: 'Bosnien und Herzegowina',
      common: 'Bosnien und Herzegowina',
    },
    fra: { official: 'Bosnie-et-Herzégovine', common: 'Bosnie-Herzégovine' },
    hrv: { official: 'Bosna i Hercegovina', common: 'Bosna i Hercegovina' },
    ita: { official: 'Bosnia-Erzegovina', common: 'Bosnia ed Erzegovina' },
    jpn: {
      official: 'ボスニア·ヘルツェゴビナ',
      common: 'ボスニア・ヘルツェゴビナ',
    },
    nld: { official: 'Bosnië-Herzegovina', common: 'Bosnië en Herzegovina' },
    por: { official: 'Bósnia e Herzegovina', common: 'Bósnia e Herzegovina' },
    rus: { official: 'Босния и Герцеговина', common: 'Босния и Герцеговина' },
    spa: { official: 'Bosnia y Herzegovina', common: 'Bosnia y Herzegovina' },
    fin: {
      official: 'Bosnia ja Hertsegovina',
      common: 'Bosnia ja Hertsegovina',
    },
  },
  latlng: [44, 18],
  demonym: 'Bosnian, Herzegovinian',
  landlocked: false,
  borders: ['HRV', 'MNE', 'SRB'],
  area: 51209,
  provinces: ['Federation of Bosnia and Herzegovina', 'Republika Srpska'],
  nativeName: 'Bosna i Hercegovina',
  timezones: ['UTC+01:00'],
} as const;
