import { reactive } from 'vue';
import { TablePanel } from 'table-panel';

import { isArray, isFunction } from '@tools/type-guards';

//#region Filter Tags

/**
 * Structure for encapsulating a table filter tag's info, including its
 * current value.
 */
export interface FilterTagEntry {
  label: string;
  enabled: boolean;
}

/**
 * Map of all {@link FilterTagEntry filter tag entries} present on a given
 * table.
 */
export type FilterTagMap = Record<string, FilterTagEntry>;

/**
 * Composable for initializing a table's filter param map.
 */
export function useFilterTags(config: TablePanel.FilterTag[]) {
  const map: FilterTagMap = {};

  for (const { key, label } of config) {
    map[key] = { label, enabled: false };
  }

  return reactive(map);
}

//#endregion Filter Tags

//#region Filter Params

/**
 * Structure for encapsulating a table filter param's info, including its
 * current value.
 */
export interface FilterParamEntry {
  label: string;
  value: TablePanel.FilterParam.OptionValue;
  options?: TablePanel.FilterParam.Option[];
}

/**
 * Map of all {@link FilterParamEntry filter param entries} present on a given
 * table.
 */
export type FilterParamMap = Record<string, FilterParamEntry>;

/**
 * Composable for initializing a table's filter param map.
 */
export function useFilterParams(
  config: TablePanel.FilterParam[],
  addTablePromise: (promise: Promise<unknown>) => void,
) {
  const map: FilterParamMap = {};

  for (const { key, label, options } of config) {
    const entry: FilterParamEntry = { label, value: null };

    if (isArray(options)) {
      entry.options = options;
    } else if (isFunction(options)) {
      const value = options();

      if (value instanceof Promise) {
        addTablePromise(value.then((o) => (entry.options = o)));
        // Add empty array as a temporary placeholder
        entry.options = [];
      } else {
        entry.options = value;
      }
    }

    map[key] = entry;
  }

  return reactive(map);
}

//#endregion Filter Params
