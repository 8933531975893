import * as typeGuards from '@tools/type-guards';

/** ... */
type AssertionType =
  | 'string'
  | 'number'
  | 'bigint'
  | 'boolean'
  | 'symbol'
  | 'undefined'
  | 'null'
  | 'object'
  | 'function'
  | 'array';

type ObjectBuilderDescriptor<T> = Record<
  keyof T,
  AssertionType | { type: AssertionType; required?: boolean }
>;

/**
 * ...
 *
 * @param value ...
 * @param required ...
 * @return ...
 */
class DataUtilities {
  /**
   * ...
   *
   * @param value ...
   * @returns ...
   */
  assertIsBoolean(value: unknown): boolean;
  assertIsBoolean(value: unknown, required: true): string;
  assertIsBoolean(value: unknown, required: false): string | null;
  assertIsBoolean(value: unknown, required = true) {
    return assertIsType(value, 'boolean', !required);
  }

  /**
   * ...
   *
   * @param value ...
   * @returns ...
   */
  assertIsNumber(value: unknown, required?: true): number;
  assertIsNumber(value: unknown, required: false): number | null;
  assertIsNumber(value: unknown, required = true) {
    return assertIsType(value, 'number', !required);
  }

  /**
   * ...
   *
   * @param value ...
   * @returns ...
   */
  assertIsString(value: unknown, required?: true): string;
  assertIsString(value: unknown, required: false): string | null;
  assertIsString(value: unknown, required = true) {
    return assertIsType(value, 'string', !required);
  }

  /**
   * ...
   *
   * @param value ...
   * @returns ...
   */
  assertIsArray<T = unknown>(value: unknown, required?: true): T[];
  assertIsArray<T = unknown>(value: unknown, required: false): T[] | null;
  assertIsArray(value: unknown, required = true) {
    return assertIsType(value, 'array', !required);
  }

  /**
   * ...
   *
   * @param value ...
   * @returns ...
   */
  parseInt(value: unknown, required = false) {
    return parseNumber('int', value, required);
  }

  /**
   * ...
   *
   * @param value ...
   * @returns ...
   */
  parseFloat(value: unknown, required = false) {
    return parseNumber('float', value, required);
  }

  /**
   * ...
   *
   * @param data ...
   * @returns ...
   */
  buildDataObject<T>(
    data: GenericObject,
    descriptor: ObjectBuilderDescriptor<T>,
  ) {
    const output: GenericObject = {};

    const errors: string[] = [];

    for (const key in descriptor) {
      const prop = key as keyof T;
      const options = descriptor[prop];

      let type: AssertionType;
      let required: boolean;

      if (typeGuards.isString(options)) {
        type = options;
        required = true;
      } else {
        type = options.type;
        required = options.required ?? true;
      }

      try {
        output[key] = assertIsType(data[key], type, required);
      } catch (err) {
        errors.push(`${key}: ${(err as Error).message}`);
      }
    }

    if (errors.length) {
      throw new Error(
        `[buildDataObject] one or more properties had invalid values:\n\n${errors.join(
          '\n',
        )}`,
      );
    }

    // return output as Partial<T>;
    return output as T;
  }
}

// Record<keyof T, AssertionType | 'nullish' | (AssertionType | 'nullish')[]>;

/** ... */
export const dataUtils = new DataUtilities();

// region Helper Functions

/**
 * ...
 *
 * @param value ...
 * @param type ...
 * @return ...
 */
function assertIsType(value: unknown, type: AssertionType, required = false) {
  const tg =
    type === 'string'
      ? typeGuards.isString
      : type === 'number'
      ? typeGuards.isNumber
      : type === 'bigint'
      ? typeGuards.isBigInt
      : type === 'boolean'
      ? typeGuards.isBoolean
      : type === 'symbol'
      ? typeGuards.isSymbol
      : type === 'undefined'
      ? typeGuards.isUndefined
      : type === 'null'
      ? typeGuards.isNull
      : type === 'object'
      ? typeGuards.isObject
      : type === 'function'
      ? typeGuards.isFunction
      : type === 'array'
      ? typeGuards.isArray
      : null;

  if (!tg) {
    throw new Error('[assertType] the specified assertion type was not valid.');
  }

  if (tg(value) || (!required && typeGuards.isNullish(value))) {
    return value ?? null;
  }

  // ...
  const valueType =
    value === null
      ? 'null'
      : typeGuards.isArray(value)
      ? 'array'
      : typeof value;

  throw new TypeError(
    `[DataUtilities:assertType] provided value expected to be "${type}", but was "${valueType}".`,
  );
}

/**
 * ...
 *
 * @param type ...
 * @param value ...
 * @param required ...
 * @return ...
 */
function parseNumber(type: 'int' | 'float', value: unknown, required: boolean) {
  // ...
  const parser = type === 'int' ? parseInt : parseFloat;

  if (typeGuards.isNumber(value)) return value;

  if (typeGuards.isString(value)) return parser(value);

  if (typeGuards.isNullish(value)) {
    if (!required) return null;

    throw new TypeError(
      `[DataUtilities:parseNumber] provided value to be parsed as a number was "${typeof value}", but was marked as required.`,
    );
  }

  throw new TypeError(
    `[DataUtilities:parseNumber] provided value to be parsed as a number was not a valid type: was "${typeof value}", but must be either "number", "string", "null" or "undefined."`,
  );
}

// endregion Helper Functions
