import { Module, VuexModule, Mutation, Action } from '@vuex/decorators';

import { api } from '@api';
import { Certification } from '@models';
import { Root } from '@store';
import { findById, addById } from '@utils/array';
import { auth, RoleId } from '@auth';
import { find } from 'lodash';

declare module '@vuex/core' {
  export interface Getters {
    ['certifications/findById']: Certifications['findById'];
  }

  export interface CommitMap {
    'certifications/SET': Certifications['SET'];
    'certifications/ADD': Certifications['ADD'];
    'certifications/CLEAR': Certifications['CLEAR'];
  }

  export interface DispatchMap {
    'certifications/list': Certifications['list'];
    'certifications/listAvailable': Certifications['listAvailable'];
    'certifications/listMine': Certifications['listMine'];
    'certifications/listReviewable': Certifications['listReviewable'];
    'certifications/listClaimed': Certifications['listClaimed'];
    'certifications/listManageable': Certifications['listManageable'];
    'certifications/get': Certifications['get'];
    'certifications/submitAttempt': Certifications['submitAttempt'];
    'certifications/claimForReview': Certifications['claimForReview'];
    'certifications/reviewAttempt': Certifications['reviewAttempt'];
    'certifications/issueExam': Certifications['issueExam'];
  }
}

@Module({ namespaced: true })
export class Certifications
  extends VuexModule<Certifications.State, Root.State>
  implements Certifications.State
{
  items: Certification[] = [];

  /**
   * ...
   */
  get findById() {
    return (id: string) => findById(this.items, id) ?? null;
  }

  //#region Mutations

  /**
   * ...
   */
  @Mutation
  SET(options: Certifications.SetMutationOptions) {
    this.items = options.data;
  }

  /**
   * ...
   */
  @Mutation
  ADD(options: Certifications.AddMutationOptions) {
    this.items = addById(this.items, options);
  }

  /**
   * ...
   */
  @Mutation
  CLEAR() {
    this.items = [];
  }

  //#endregion Mutations

  //#region Actions

  /**
   * ...
   */
  @Action
  async list() {
    const data = await api.certifications.list();

    this.context.commit('SET', { data });

    return this.items;
  }

  /**
   * ...
   */
  @Action
  async listAvailable() {
    // if we're an las admin, we need to list all
    const data = auth.isActiveRole(RoleId.LasAdmin)
      ? await api.certifications.list()
      : await api.certifications.listAvailable();

    this.context.commit('SET', { data });

    return this.items;
  }

  /**
   * Load logged in user's earned certifications.
   *
   * @return ...
   */
  @Action
  async listMine() {
    // ...
    const userId = this.context.rootState.me.id;

    if (!userId) {
      throw new Error(
        '[store - certifications/listMine] must be logged in to retrieve owned certifications.',
      );
    }

    const data = await api.certifications.listForUser({ userId });

    this.context.commit('SET', { data });

    return data;
  }

  /**
   * ...
   *
   * @return ...
   */
  @Action
  async listReviewable() {
    const data = await api.certifications.listAvailable();

    this.context.commit('ADD', data);

    return data;
  }

  /**
   * ...
   *
   * @return ...
   */
  @Action
  async listClaimed() {
    const data = await api.certifications.listClaimed();

    this.context.commit('ADD', data);

    return data;
  }

  /**
   * ...
   *
   * @return ...
   */
  @Action
  async listManageable() {
    const data = await api.certifications.listClaimed();

    this.context.commit('ADD', data);

    return data;
  }

  /**
   * ...
   */
  @Action
  async get(options: Certifications.GetActionOptions) {
    const data = await api.certifications.get(options);

    this.context.commit('ADD', data);

    return data;
  }

  /**
   * ...
   */
  @Action
  async submitAttempt(options: Certifications.SubmitAttemptActionOptions) {
    const data = await api.certifications.submitAttempt(options);

    const cert = await this.context.dispatch('get', {
      certificationId: options.certificationId,
    });

    this.context.commit('ADD', cert);

    return data;
  }

  /**
   * ...
   */
  @Action
  async claimForReview(options: Certifications.ClaimForReviewActionOptions) {
    const data = await api.certifications.claimForReview(options);

    const cert = await this.context.dispatch('get', {
      certificationId: options.certificationId,
    });

    this.context.commit('ADD', cert);

    return data;
  }

  /**
   * ...
   */
  @Action
  async issueExam(
    options: Certifications.AssignCertificationExamActionOptions,
  ) {
    const data = await api.certifications.assignExam(options);

    const users = this.context.rootState.users.items;

    for (const userId of data.success) {
      const user = find(users, { id: userId });

      if (!user) continue;

      const certifications = await api.certifications.listForUser({
        userId: user.id,
      });

      this.context.commit(
        'users/UPDATE',
        { id: user.id, certifications },
        { root: true },
      );
    }

    return data;
  }

  /**
   * ...
   */
  @Action
  async reviewAttempt(options: Certifications.ReviewAttemptActionOptions) {
    const data = await api.certifications.reviewAttempt(options);

    const cert = await this.context.dispatch('get', {
      certificationId: options.certificationId,
    });

    this.context.commit('ADD', cert);

    const users = this.context.rootState.users.items;

    const user = find(users, { id: cert.user.id });

    if (!user) return data;

    const certifications = await api.certifications.listForUser({
      userId: user.id,
    });

    this.context.commit(
      'users/UPDATE',
      { id: user?.id, certifications },
      { root: true },
    );

    return data;
  }

  //#endregion Actions
}

export namespace Certifications {
  /**
   * ...
   */
  export interface State {
    items: Certification[];
  }

  /**
   * ...
   */
  export interface SetMutationOptions {
    data: Certification[];
  }

  /** ... */
  export type AddMutationOptions = Certification | Certification[];
  /** ... */
  export type GetActionOptions = api.certifications.GetOptions;
  /** ... */
  export type SubmitAttemptActionOptions =
    api.certifications.SubmitAttemptOptions;
  /** ... */
  export type ClaimForReviewActionOptions =
    api.certifications.ClaimForReviewOptions;
  /** ... */
  export type ReviewAttemptActionOptions =
    api.certifications.ReviewAttemptOptions;
  /** ... */
  export type AssignCertificationExamActionOptions =
    api.certifications.AssignCertificationExamOptions;
}

export default Certifications;
