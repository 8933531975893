var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "score-table-cell" }, [
    _setup.status
      ? _c(
          "span",
          { class: [_setup.status.color] },
          [
            _c("Icon", { attrs: { icon: _setup.status.icon } }),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_setup.status.text))]),
          ],
          1
        )
      : _c("span", [_vm._v("--")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }