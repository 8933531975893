var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.expanded
    ? _c("div", { staticClass: "modal-expandable-section" }, [
        _c("div", { staticClass: "section-content-wrapper" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "section-content" }, [_vm._t("default")], 2),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "pointer" }, [_c("span")])
  },
]
render._withStripped = true

export { render, staticRenderFns }