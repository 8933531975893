var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("span", { staticClass: "table-panel-cell" }, [
    _c("pre", { class: ["cell-value", _setup.cell.classList] }, [
      _vm._v(_vm._s(_setup.cell.value)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }