export const CHE = {
  name: {
    common: 'Switzerland',
    official: 'Swiss Confederation',
    native: {
      fra: { official: 'Confédération suisse', common: 'Suisse' },
      gsw: { official: 'Schweizerische Eidgenossenschaft', common: 'Schweiz' },
      ita: { official: 'Confederazione Svizzera', common: 'Svizzera' },
      roh: { official: 'Confederaziun svizra', common: 'Svizra' },
    },
  },
  cities: [
    'Zurich',
    'Geneva',
    'Lucerne',
    'Grenchen',
    'Bettlach',
    'Lengnau',
    'Pieterlen',
    'Huttwil',
    'Langenthal',
    'Lotzwil',
    'Murgenthal',
    'Roggwil',
    'Butzberg',
    'Bannwil',
    'Wynau',
    'Leukerbad',
    'Zermatt',
    'Visp',
    'Brig',
    'Glis',
    'Naters',
    'Saas-Fee',
    'Matzendorf',
    'Oensingen',
    'Egerkingen',
    'Holderbank',
    'Wolfwil',
    'Attiswil',
    'Oberbipp',
    'Balsthal',
    'Mumliswil',
    'Wiedlisbach',
    'Welschenrohr',
    'Mohlin',
    'Niederbipp',
    'Bern',
    'Gumligen',
    'Muri bei Bern',
    'Kirchberg',
    'Hindelbank',
    'Wasen',
    'Burgdorf',
    'Lyssach',
    'Oberburg',
    'Ersigen',
    'Kernenried',
    'Kirchberg',
    'Belp',
    'Munsingen',
    'Rubigen',
    'Toffen',
    'Aarberg',
    'Seedorf',
    'Herzogenbuchsee',
    'Oberonz',
    'Rheinfelden',
    'Therwil',
    'Basel',
    'Oberwil',
    'Binningen',
    'Bottmingen',
    'Pratteln',
    'Oberdorf',
    'Oberdorf',
    'Zeglingen',
    'Wegenstetten',
    'Gelterkinden',
    'Reinach',
    'Seltisberg',
    'Kaiseraugst',
    'Wallisellen',
    'Dubendorf',
    'Collombey',
    'Bouveret',
    'Martigny-Ville',
    'Basse-Nendaz',
    'Saxon',
    'Monthey',
    'Riddes',
    'Arlesheim',
    'Onex',
    'Doettingen',
    'Bariswil',
    'Lausanne',
    'Breitenbach',
    'Thayngen',
    'Uhwiesen',
    'Reinach',
    'Biel/Bienne',
    'Pully',
    'Sachseln',
    'Zizers',
    'Cazis',
    'Chur',
    'Steinhausen',
    'Ebnat-Kappel',
    'Meyrin',
    'Hunenberg',
    'Giubiasco',
    'Laufen',
    'Frauenfeld',
    'Affoltern am Albis',
    'Pfaeffikon',
    'Kriens',
    'Yverdon-les-Bains',
    'Rueti',
    'Wetzikon',
    'Mendrisio',
    'Stabio',
    'Vevey',
    'Orbe',
    'Baar',
    'Uster',
    'Trimbach',
    'Appenzell',
    'Fallanden',
    'Fribourg',
    'Diessenhofen',
    'Sulgen',
    'Kreuzlingen',
    'Schonholzerswilen',
    'Schaffhausen',
    'Bulach',
    'Winterthur',
    'Au',
    'Massagno',
    'Lugano',
    'Rotkreuz',
    'Bernex',
    'St. Gallen',
    'Nidau',
    'Carouge',
    'Wil',
    'Neuenhof',
    'Viganello',
    'Renens',
    'Eysins',
    'Nyon',
    'Prilly',
    'Aigle',
    'Sarnen',
    'Chene-Bourg',
    'Bern / Liebefeld',
    'Erlenbach',
    'Hochdorf',
    'Entlebuch',
    'Unterageri',
    'Dielsdorf',
    'Sissach',
    'Wettingen',
    'Deitingen',
    'Hergiswil',
    'Rupperswil',
    'Forel',
    'Muri',
    'Turbenthal',
    'Laupen',
    'Chiasso',
    'Riva San Vitale',
    'Cadro',
    'Banco',
    'Melide',
    'Chene-Bougeries',
    'Solothurn',
    'Augst',
    'Oberschlatt',
    'Beinwil',
    'Rorschach',
    'Baretswil',
    'Lenzburg',
    'Oberlunkhofen',
    'Villigen',
    'Zaziwil',
    'Hagneck',
    'Schanis',
    'Autigny',
    'DÃ¼dingen',
    'Menziken',
    'Frutigen',
    'Bassersdorf',
    'Nurensdorf',
    'Ruschlikon',
    'Mannedorf',
    'Tenero',
    'Blonay',
    'Weinfelden',
    'Wabern',
    'Wurenlos',
    'Oberentfelden',
    'Jona',
    'Kronbuhl',
    'Goldach',
    'Daettlikon',
    'Schlieren',
    'Montreux',
    'Dornach',
    'Peseux',
    'Allschwil',
    'Marin',
    'Rumlang',
    'Horw',
    'Saint-Sulpice',
    'Munchenstein',
    'Emmenbruecke',
    'Veyrier',
    'Liestal',
    'Klingnau',
    'Dottikon',
    'Windisch',
    'Dietikon',
    'Gland',
    'Celigny',
    'Gingins',
    'Coppet',
    'Crassier',
    'Kriegstetten',
    'Derendingen',
    'Subingen',
    'Lohn',
    'Munchenbuchsee',
    'Urtenen',
    'Schonbuhl',
    'Richenthal',
    'Nussbaumen',
    'Wollerau',
    'Lachen',
    'Merenschwand',
    'Bonstetten',
    'Kuesnacht',
    'Thun',
    'Courtion',
    'Berikon',
    'Nunningen',
    'Kloten',
    'Fischingen',
    'Baden',
    'Bioggio',
    'Siebnen',
    'Gummenen',
    'Bex',
    'Rorbas',
    'Embrach',
    'Wittenbach',
    'Teufen AR',
    'Morschwil',
    'Oftringen',
    'Cointrin',
    'Regensdorf',
    'La Chaux-de-Fonds',
    'Neuchatel',
    'Horgen',
    'Richterswil',
    'Oberweningen',
    'Lucens',
    'Moudon',
    'Thierachern',
    'Heimberg',
    'Zollikofen',
    'Wadenswil',
    'Pregassona',
    'Wikon',
    'Strengelbach',
    'Hagendorf',
    'Olten',
    'Aarau',
    'Volketswil',
    'Crissier',
    'Corgemont',
    'Bellevue',
    'Vernier',
    'Versoix',
    'Plan-les-Ouates',
    'Samstagern',
    'Pfaeffikon',
    'Vullierens',
    'Ecublens',
    'Niederrohrdorf',
    'Fehraltorf',
    'Ausser-Dinhard',
    'Bussigny',
    'Romanel',
    'Saint-Prex',
    'Tegerfelden',
    'Untersiggenthal',
    'Nussbaumen',
    'Birr',
    'Wurenlingen',
    'Endingen',
    'Turgi',
    'Habsburg',
    'Kirchdorf',
    'Holderbank',
    'Gebenstorf',
    'Birmenstorf',
    'Brugg',
    'Seewis im Pratigau',
    'Tamins',
    'Seengen',
    'Wohlen',
    'Egliswil',
    'Nanikon',
    'Meisterschwanden',
    'Bruttisellen',
    'Flums',
    'Untervaz',
    'Domat',
    'Haldenstein',
    'Wangs',
    'Greifensee',
    'Bassecourt',
    'Malleray',
    'Bevilard',
    'Rapperswil',
    'Eschenbach',
    'Roche',
    'Chatel-Saint-Denis',
    'Buchs / Buchs (Dorf)',
    'Bellinzona',
    'Belmont-sur-Lausanne',
    'Zollikon',
    'Le Locle',
    'Altdorf',
    'Courtepin',
    'Lamboing',
    'La Neuveville',
    'Wileroltigen',
    'Speicher',
    'Vacallo',
    'Mettmenstetten',
    'Nottwil',
    'Sursee',
    'Mauensee',
    'Avenches',
    'Couvet',
    'Zofingen',
    'Heerbrugg',
    'Berneck',
    'Ermatingen',
    'Tagerwilen',
    'Holstein',
    'Magden',
    'Colombier',
    'Cornaux',
    'Preles',
    'DelÃ©mont',
    'Minusio',
    'Langnau am Albis',
    'Herisau',
    'Frick',
    'Ober Urdorf',
    'Murten',
    'Vesenaz',
    'Rickenbach',
    'Zug',
    'Feuerthalen',
    'Huntwangen',
    'Wagenhausen',
    'Biberist',
    'Morges',
    'Chardonne',
    'Le Mont-sur-Lausanne',
    'Gossau',
    'Grueningen',
    'KÃ¼ttigen',
    'Troistorrents',
    'Villars-sur-Glane',
    'Gstaad',
    'Sempach',
    'Unterengstringen',
    'Cadempino',
    'Locarno',
    'Tann',
    'Berlikon',
    'Hinwil',
    'Weiningen',
    'Meilen',
    'Oberengstringen',
    'Stettlen',
    'Illnau',
    'KÃ¼ssnacht',
    'Elsau-Raeterschen',
    'Hinterkappelen',
    'Losone',
    'Oetwil / Oetwil an der Limmat',
    'Cham',
    'Vandoeuvres',
    'Morigen',
    'Bristen',
    'Willisau',
    'Beromuenster',
    'Moutier',
    'Hunibach',
    'Schmitten',
    'Ueberstorf',
    'Albligen',
    'Schwarzenburg',
    'KÃ¶niz',
    'Puidoux',
    'Cully',
    'Chexbres',
    'Payerne',
    'Rolle',
    'Gottlieben',
    'Romanshorn',
    'Steckborn',
    'Confignon',
    'Niederuzwil',
    'Oberuzwil',
    'Otelfingen',
    'Andwil',
    'Hettlingen',
    'Elgg',
    'Glattfelden',
    'Thalwil',
    'Rudolfstetten',
    'Oberbuchsiten',
    'Lostorf',
    'Ecuvillens',
    'Avry',
    'Neyruz',
    'GruyÃ¨res',
    'Grandvillard',
    'Treyvaux',
    'Domdidier',
    'Faoug',
    'Broc',
    'Dompierre',
    'Ependes',
    'Opfikon',
    'Henggart',
    'Zell',
    'Russikon',
    'Seuzach Dorf',
    'Hagenbuch',
    'Pfungen',
    'Aadorf',
    'Weisslingen',
    'Gravesano',
    'Morbio Inferiore',
    'Morcote',
    'Littau',
    'Schachen',
    'Dattwil',
    'Courrendlin',
    'Courroux',
    'Coeuve',
    'Chambesy',
    'Bach',
    'Granges',
    'Apples',
    'Cudrefin',
    'Kempttal',
    'Oberembrach',
    'Reconvilier',
    'Saint-Imier',
    'Cortaillod',
    'Boudry',
    'Bevaix',
    'Fontainemelon',
    'Grandson',
    'Estavayer-le-Lac',
    'Champagne',
    'Bolligen',
    'Worblaufen',
    'Jegenstorf',
    'Ostermundigen',
    'Adliswil',
    'Kilchberg',
    'Steffisburg',
    'Spiez',
    'Einigen',
    'Durnten',
    'Hergiswil',
    'Kastanienbaum',
    'Alpnach',
    'Seegraben',
    'Monchaltorf',
    'Ebikon',
    'Aeugst am Albis',
    'Mettmenstetten',
    'Stallikon',
    'Rondchatel',
    'Schenkon',
    'Eich',
    'Oberkirch',
    'Weggis',
    'Emmen',
    'Wiesendangen',
    'Roschenz',
    'Birmensdorf',
    'Herrliberg',
    'Hombrechtikon',
    'Feldmeilen',
    'Stafa',
    'Oetwil am See',
    'Uerikon',
    'Uetikon',
    'Glattburg',
    'Villars-sur-Ollon',
    'Altstatten',
    'Arbon',
    'Buix',
    'Courtemaiche',
    'Courgenay',
    'Courtedoux',
    'Riedholz',
    'Zuchwil',
    'Gerlafingen',
    'Batterkinden',
    'Obergerlafingen',
    'Yvonand',
    'Saint-Maurice',
    "Val d'Illiez",
    'Vionnaz',
    'Villeneuve',
    'Ayent',
    'Erde',
    'Sion',
    'Bramois',
    'Sierre',
    'Vetroz',
    'Grimisuat',
    'Saint-Leonard',
    'Ayer',
    'Ossingen',
    'Le Landeron',
    'Altenrhein',
    'Abtwil',
    'Evilard',
    'Tramelan',
    'Orvin',
    'Villeret',
    'Neftenbach',
    'Niederweningen',
    'Koblenz',
    'Leibstadt',
    'Bad Zurzach',
    'Rekingen',
    'Porrentruy',
    'Court',
    'Wangen',
    'Buchs',
    'Bremgarten',
    'Eggenwil',
    'Lutry',
    'Ormalingen',
    'Mellingen',
    'Hagglingen',
    'Hendschiken',
    'Trimmis',
    'Arosa',
    'Cheseaux-sur-Lausanne',
    'Uzwil',
    'Unterehrendingen',
    'Saignelegier',
    'Frenkendorf',
    'Unterlunkhofen',
    'Jonen',
    'Les Breuleux',
    'Hermetschwil-Staffeln',
    'Oberwil',
    'Moriken',
    'Reigoldswil',
    'Waldenburg',
    'Oberglatt',
    'Thonex',
    'Eschlikon',
    'Stein',
    'Kaisten',
    'Laufenburg',
    'Hofstetten',
    'Travers',
    'Wahlen',
    'Cottens',
    'Lausen',
    'Brutten',
    'Rothrist',
    'Ã‰challens',
    'Wald',
    'Sainte-Croix',
    'Bubikon',
    'Gossau',
    'Neunkirch',
    'Flaach',
    'Uitikon',
    'Niederurnen',
    'Uetendorf',
    'Brenzikofen',
    'Killwangen',
    'Kehrsatz',
    'Sementina',
    'Bottens',
    'Gunten',
    'Habkern',
    'Rafz',
    'Aarburg',
    'Muhlau',
    'Davos',
    'Schoetz',
    'KÃ¼nten',
    'Schnottwil',
    'Saviese',
    'Conthey',
    'Arbaz',
    'Nax',
    'Evionnaz',
    'Grone',
    'Montana',
    'Martigny-Croix',
    'Chalais',
    'Granges-pres-Sion',
    'Champsec',
    'Champex',
    'Sembrancher',
    'Verbier',
    'Chippis',
    'Vercorin',
    'Fully',
    'Charrat-les-Chenes',
    'DÃ¼rrenroth',
    'Russin',
    'Prangins',
    'Fulenbach',
    'Burglen',
    'Rechthalten',
    'Charmey',
    'Niederhallwil',
    'Le Grand-Saconnex',
    'Balerna',
    'Hitzkirch',
    'Winkel',
    'Spreitenbach',
    'Termen',
    'Munster',
    'Oberhelfenschwil',
    'Stansstad',
    'Obfelden',
    'Muhen',
    'Hirzel-Kirche',
    'Rheineck',
    'Wolhusen',
    'Neuheim',
    'Root',
    'Meierskappel',
    'Werthenstein',
    'Neuenkirch',
    'Brunnen',
    'Ottenbach',
    'Walchwil',
    'Kappel',
    'Vuadens',
    'Romont',
    'Bulle',
    'Riaz',
    'Marsens',
    'Siviriez',
    'Chavannes-les-Forts',
    'Hauteville',
    'Maur',
    'Zumikon',
    'Egg',
    'Lignieres',
    'Saint-Blaise',
    'Hauterive',
    'EnnetbÃ¼rgen',
    'Giswil',
    'Thierrens',
    'Epalinges',
    'Wangi',
    'Bazenheid',
    'Cremines',
    'Muhleberg',
    'Bavois',
    'Aubonne',
    'Gimel',
    'Grandval',
    'Pery',
    'Les Genevez',
    'Lurtigen',
    'Lyss',
    'Saint-Livres',
    'Meinisberg',
    'Lenzerheide',
    'Langnau',
    'Gwatt',
    'Rikon / Rikon (Dorfkern)',
    'Beringen',
    'Flurlingen',
    'Buch',
    'Schlatt',
    'Trasadingen',
    'Kappel',
    'Portalban- Dessous',
    'Schwyz',
    'Kerns',
    'Enney',
    'Cugy',
    'Kefikon',
    'Le Vaud',
    'Savigny',
    'Daniken',
    'Meggen',
    'Bangerten',
    'Le Cret',
    'Hauptwil',
    'Himmelried',
    'Bissegg',
    'Preverenges',
    'Paudex',
    'Worben',
    'Busswil',
    'Bosingen',
    'Liebistorf',
    'Tafers',
    'Cordast',
    'Heitenried',
    'Ferenbalm',
    'Schubelbach',
    'Ascona',
    'Berg',
    'Rickenbach',
    'Heimiswil',
    'Agno',
    'Erlach',
    'Freienbach',
    'Safenwil',
    'Kerzers',
    'Ins',
    'Siselen',
    'Altendorf',
    'Reichenburg',
    'Ziegelbrucke',
    'Bilten',
    'Kallnach',
    'Unteriberg',
    'La Tour-de-Peilz',
    'Bettingen',
    'Villmergen',
    'Corcelles',
    'Arisdorf',
    'Fullinsdorf',
    'Nafels',
    'Mollis',
    'Hundwil',
    'Uznach',
    'Sankt Gallenkappel',
    'Ennenda',
    'Fahrwangen',
    'Blumenstein',
    'Einsiedeln',
    'Rothenthurm',
    'Corcelles',
    'Sariswil',
    'Thorishaus',
    'Niederlenz',
    'Wildegg',
    'Staufen',
    'Stadel',
    'Airolo',
    'Netstal',
    'Glarus',
    'Goslikon',
    'Sarmenstorf',
    'La Chiesaz',
    'Yvorne',
    'Vouvry',
    'Burglen',
    'Schindellegi',
    'Kolliken',
    'Lungern',
    'Inwil',
    'Felsberg',
    'Kaiserstuhl',
    'Alt-Bachs',
    'Siglistorf',
    'Chavannes',
    'Bottenwil',
    'Brittnau',
    'Vordemwald',
    'Villars',
    'Cossonay',
    'Densbueren',
    'Courtelary',
    'Niedergosgen',
    'Erschwil',
    'Quartino',
    'Saint-Cergue',
    'Chavannes de Bogis',
    'Tesserete',
    'Interlaken',
    'Benken',
    'Suhr',
    'Sonvico',
    'Udligenswil',
    'Pfeffikon',
    'Sigriswil',
    'DÃ¤rligen',
    'Buchs',
    'Muttenz',
    'Caslano',
    'Stein',
    'Othmarsingen',
    'Zuzgen',
    'Canobbio',
    'Stans',
    'Buochs',
    'Dallenwil',
    'Wolfenschiessen',
    'Beckenried',
    'Buren nid dem Bach',
    'BrÃ¼gg',
    'Zuzwil',
    'Neukirch',
    'Amriswil',
    'Horn',
    'Novazzano',
    'Magadino',
    'Gordola',
    'Matzingen',
    'Ellikon an der Thur',
    'Melano',
    'Zwingen',
    'Gunzgen',
    'Ricken',
    'Adligenswil',
    'Steinen',
    'Grosswangen',
    'Rueggisberg',
    'Aeschau',
    'Schinznach Dorf',
    'Obergosgen',
    'Untererlinsbach',
    'Seon',
    'Villnachern',
    'Founex',
    'Jussy',
    'Oron-la-ville',
    'Arth',
    'Aesch',
    'Worb',
    'Cressier',
    'Gryon',
    'Ruswil',
    'Weesen',
    'Wangen',
    'Flamatt',
    'Amsoldingen',
    'Effretikon',
    'Waldstatt',
    'Bonnefontaine',
    'Le Bry',
    'Bellerive',
    'Vex',
    'Veytaux',
    'Epautheyres',
    'Rothenbach',
    'Schoftland',
    'Nesslau',
    'Rebstein',
    'Schattdorf',
    'Grancy',
    'Zuckenriet',
    'Hoerstetten',
    'Essertines-sur-Rolle',
    "L'Abbaye",
    'Biere',
    'Vernayaz',
    'Feldbach',
    'Chapelle',
    'Villaz-Saint-Pierre',
    'Birsfelden',
    'Oberhofen bei Etzgen',
    'Vaulruz',
    'Steg',
    'Vallorbe',
    'Alterswil',
    'Niederbuchsiten',
    'Munchwilen',
    "L'Isle",
    'Plaffeien',
    'Sins',
    'Buttes',
    'Mumpf',
    'Soyhieres',
    'Gampelen',
    'Kiesen',
    'Davos Platz',
    'Vauderens',
    'Chamoson',
    'Ovronnaz',
    'Froideville',
    'Felben',
    'Neu-Rheinau',
    'Lenk',
    'Territet',
    'Leutwil',
    'Oberrieden',
    'Oberstocken',
    'Bubendorf',
    'Veltheim',
    'Grandcour',
    'Tuggen',
    'Saint-Aubin-Sauges',
    'Les Verrieres',
    'Wattwil',
    'Rhazuns',
    'Contone',
    'Brissago',
    'Neuhausen',
    'Tauffelen',
    'Sankt Margrethen',
    'Chatelaine',
    'Vicques',
    'Eschenbach',
    'Cernier',
    'Hildisrieden',
    'Degersheim',
    'Dardagny',
    'Morschach',
    'Malters',
    'Buchrain',
    'Fluehli',
    'Buttisholz',
    'Hellbuehl',
    'Schuepfheim',
    'Cartigny',
    'Rue',
    'Begnins',
    'Coppet',
    'La Plaine',
    'Graenichen',
    'Maggia',
    'Rossens',
    'Busserach',
    'Grindelwald',
    'Basadingen',
    'Dachsen',
    'Eglisau',
    'Rudlingen',
    'Buchberg',
    'Stein am Rhein',
    'Nohl',
    'Hemmental',
    'Ramsen',
    'Grabs',
    'Mels',
    'Lienz',
    'Au',
    'Sargans',
    'Balgach',
    'Sevelen',
    'Sennwald',
    'Cressier',
    'Thielle',
    'Gachlingen',
    'Erlen',
    'Diepoldsau',
    'Bad Ragaz',
    'Daillens',
    'Rohrbach',
    'Muolen',
    'Gipf-Oberfrick',
    'Islikon',
    'Wauwil',
    'Freidorf',
    'Schonenwerd',
    'Niedererlinsbach',
    'Wittnau',
    'Thalheim',
    'Auenstein',
    'Schafisheim',
    'Moosleerau',
    'Teufenthal',
    'Unterkulm',
    'Gontenschwil',
    'Reiden',
    'Dagmersellen',
    'Ibach',
    'Fischenthal',
    'Tavannes',
    'Hornussen',
    'Vinzel',
    'Klosters Serneus',
    'Furstenaubruck',
    'Wolfgang',
    'Cheyres',
    'Rohr',
    'Hofstetten',
    'Bachenbulach',
    'Thundorf',
    'Pfaffnau',
    'Corsier',
    'Le Noirmont',
    'Montagnola',
    'Gandria',
    'Selzach',
    'Biel-Benken',
    'Landquart',
    'Arni',
    'Auvernier',
    'Schonenberg',
    'Bauma',
    'Gordevio',
    'Iragna',
    'Durrenasch',
    'Oberegg',
    'Hittnau',
    'Boll',
    'Campfer',
    'Obersaxen',
    'Dombresson',
    'Sottens',
    'Oberdiessbach',
    'Langnau',
    'Geuensee',
    'Utzenstorf',
    'Sezegnin',
    'Koppigen',
    'Bonaduz',
    "Chateau-d'Oex",
    'Corminboeuf',
    'Les Pommerats',
    'Ligerz',
    'Trubschachen',
    'Oberhofen',
    'Miecourt',
    'Courtetelle',
    'La Chaux',
    'Gampel',
    'Ilanz',
    'Bellmund',
    'Emmetten',
    'Grund',
    'Flims',
    'Waldhaus',
    'Laax',
    'Igis',
    'Luvis',
    'Weiningen',
    'Brislach',
    'Buren an der Aare',
    'Belfaux',
    'Attalens',
    'Palezieux',
    'La Sarraz',
    'Wil',
    'Bronschhofen',
    'Hausen',
    'La Roche',
    'Ballwil',
    'Goldau',
    'Sorengo',
    'Agra',
    'Concise',
    'Eiken',
    'Beuson',
    'Scuol',
    'Diegten',
    'Wangen an der Aare',
    'Schonenbuch',
    'Orsonnens',
    'Bigenthal',
    'Baulmes',
    'Ettingen',
    'Aettenschwil',
    'Wigoltingen',
    'Hilterfingen',
    'Kaltbrunn',
    'Chez-le-Bart',
    'Unter-Teufen',
    'Wichtrach',
    'Adelboden',
    'Wimmis',
    'Saanenmoser',
    'Gommiswald',
    'Klosters Platz',
    'Davos Dorf',
    'Eggersriet',
    'BÃ¼hler',
    'Oberriet',
    'Ruthi',
    'Eichberg',
    'Camorino',
    'Pampigny',
    'Yens',
    'Auw',
    'Onnens',
    'Kleinlutzel',
    'Seftigen',
    'Grolley',
    'Weissbad',
    'Kradolf',
    'Mettendorf',
    'Stettfurt',
    'Matt',
    'Assens',
    'Bursins',
    'Morgins',
    'Flawil',
    'Full',
    'Sirnach',
    'Saint-Aubin',
    'Muotathal',
    'Satigny',
    'Vilters',
    'Guttingen',
    'Altishofen',
    'Schongau',
    'Vitznau',
    'Sattel',
    'Attinghausen',
    'Novaggio',
    'Buonas',
    'Bogis-Bossey',
    'Alchenstorf',
    'Noreaz',
    'Buus',
    'Anieres',
    'Alberswil',
    'Immensee',
    'Givrins',
    'Borex',
    'Meinier',
    'Henau',
    'Hausen am Albis / Hausen (Dorf)',
    'Claro',
    'Lodrino',
    'Waldkirch',
    'Richigen',
    'Arogno',
    'Glaris',
    'Maschwanden',
    'Schmerikon',
    'Euthal',
    'Orpund',
    'Zollbruck',
    'Sulz',
    'Hochwald',
    'Gersau',
    'Bottighofen',
    'Walzenhausen',
    'Egnach',
    'Heiden',
    'Maerstetten-Dorf',
    'Mullheim',
    'Grono',
    'Sarn',
    'Tasch',
    'Les Hauts-Geneveys',
    'Tanay',
    'Niederburen',
    'Lutisburg',
    'Mosnang',
    'Maisprach',
    'Amden',
    'Arzier',
    'Gelfingen',
    'Aristau',
    'Schleitheim',
    'Semsales',
    'Porsel',
    'Gerzensee',
    'Niederscherli',
    'Ingenbohl',
    'Courfaivre',
    'Aesch',
    'Gais',
    'Lichtensteig',
    'Pfeffingen',
    'Rueyres',
    'Colombier',
    'Diessbach',
    'Goldiwil',
    'Fraubrunnen',
    'Messen',
    'Konolfingen',
    'Triengen',
    'Castione',
    'Krauchthal',
    'Ursenbach',
    'Chene-Paquier',
    'Sonvilier',
    'Fleurier',
    'Lenz',
    'LÃ¼tzelflÃ¼h',
    'Langenbruck',
    'Uerkheim',
    'Cadenazzo',
    "Cassina d'Agno",
    'Trubbach',
    'Thalheim',
    'Schmitten',
    'Schwellbrunn',
    'Gettnau',
    'Maroggia',
    'Altnau',
    'Roggwil',
    'Oberwangen',
    'Trogen',
    'Chavornay',
    'Vuiteboeuf',
    'Rances',
    'Romainmotier',
    'Le Brassus',
    'Ardon',
    'Leysin',
    'Jonschwil',
    'Engelberg',
    'Biasca',
    'Capolago',
    'Manno',
    'Feldbrunnen',
    'Unterseen',
    'Twann',
    'Arzo',
    'Burchen',
    'Merishausen',
    'UrnÃ¤sch',
    'Balterswil',
    'Menzingen',
    'Iserables',
    'Bissone',
    'Glion',
    'Melchnau',
    'Mettlen',
    'Buetschwil',
    'Zihlschlacht',
    'Ried',
    'Bitsch',
    'Les Geneveys-sur-Coffrane',
    'Pfyn',
    'Riggisberg',
    'Bigorio',
    'Zeihen',
    'Roveredo',
    'Arbedo',
    'Chesieres',
    'Schiers',
    'Seedorf',
    'Erstfeld',
    'Hasle',
    'Felsenau',
    'Kollbrunn',
    'Eggiwil',
    'Rehetobel',
    'Genestrerio',
    'Grellingen',
    'Oberbalm',
    'Gerlikon',
    'Barbereche',
    'Schinznach Bad',
    'Cugnasco',
    'Wila',
    'Iseo',
    'Schwarzenberg',
    'Pura',
    'Waltenschwil',
    'Rapperswil',
    'Gnosca',
    'Perroy',
    'Chancy',
    'Liddes',
    'Grimentz',
    'Seelisberg',
    'Schonried',
    'Greppen',
    'Thusis',
    'Saanen',
    'Zullwil',
    'Sils-Segl Maria',
    'Celerina',
    'Raperswilen',
    'Feusisberg',
    'Sonceboz',
    'Salmsach',
    'Ittenthal',
    'Laufelfingen',
    'Lauerz',
    'Les Acacias',
    'Le Sentier',
    'Herdern',
    'Gonten',
    'Andermatt',
    'Stoos',
    'Zunzgen',
    'Niederhelfenschwil',
    'Wuppenau',
    'Lommis',
    'Ganterschwil',
    'Wilderswil',
    'Ringgenberg',
    'Brienz',
    'Bonigen',
    'Meiringen',
    'Walkringen',
    'Ufhusen',
    'Zell',
    'Ouchy',
    'Villa',
    'Apro',
    'Finhaut',
    'Allaman',
    'Saint-Sulpice',
    'Motiers',
    'Winterberg',
    'Lindau',
    'Schupfen',
    'Perlen',
    'Arcegno',
    'Kulm',
    'Tschuggen',
    'Trun',
    'Wolfhalden',
    'Savognin',
    'Ulrichen',
    'Lauenen',
    'Sumiswald',
    'Cheiry',
    'Vessy',
    'Hunzenschwil',
    'Dozwil',
    'Montet',
    'Wengen',
    'Raron',
    'Verscio',
    'Flueli',
    'Rufi',
    'Hermance',
    'Saas-Grund',
    'Jenaz',
    'Loco',
    'Roemerswil',
    'Aeschlen ob Gunten',
    'Escholzmatt',
    'Ferden',
    'Herbetswil',
    'Grossandelfingen',
    'MÃ¼hlehorn',
    'Muerren',
    'Zufikon',
    'Obervaz',
    'Rifferswil',
    'Les Diablerets',
    'Brusino Arsizio',
    'Sessa',
  ],
  tld: ['.ch'],
  cca2: 'CH',
  ccn3: '756',
  cca3: 'CHE',
  cioc: 'SUI',
  currency: ['CHE', 'CHF', 'CHW'],
  callingCode: ['41'],
  capital: 'Bern',
  altSpellings: [
    'CH',
    'Swiss Confederation',
    'Schweiz',
    'Suisse',
    'Svizzera',
    'Svizra',
  ],
  region: 'Europe',
  subregion: 'Western Europe',
  languages: {
    fra: 'French',
    gsw: 'Swiss German',
    ita: 'Italian',
    roh: 'Romansh',
  },
  translations: {
    deu: { official: 'Schweizerische Eidgenossenschaft', common: 'Schweiz' },
    fra: { official: 'Confédération suisse', common: 'Suisse' },
    hrv: { official: 'švicarska Konfederacija', common: 'Švicarska' },
    ita: { official: 'Confederazione svizzera', common: 'Svizzera' },
    jpn: { official: 'スイス連邦', common: 'スイス' },
    nld: { official: 'Zwitserse Confederatie', common: 'Zwitserland' },
    por: { official: 'Confederação Suíça', common: 'Suíça' },
    rus: { official: 'Швейцарская Конфедерация', common: 'Швейцария' },
    spa: { official: 'Confederación Suiza', common: 'Suiza' },
    fin: { official: 'Sveitsin valaliitto', common: 'Sveitsi' },
  },
  latlng: [47, 8],
  demonym: 'Swiss',
  landlocked: true,
  borders: ['AUT', 'FRA', 'ITA', 'LIE', 'DEU'],
  area: 41284,
  provinces: [
    'Aargau',
    'Ausser-Rhoden',
    'Basel-Landschaft',
    'Basel-Stadt',
    'Bern',
    'Fribourg',
    'Geneve',
    'Glarus',
    'Graubunden',
    'Inner-Rhoden',
    'Jura',
    'Luzern',
    'Neuchatel',
    'Nidwalden',
    'Obwalden',
    'Sankt Gallen',
    'Schaffhausen',
    'Schwyz',
    'Solothurn',
    'Thurgau',
    'Ticino',
    'Uri',
    'Valais',
    'Vaud',
    'Zug',
    'Zurich',
  ],
  nativeName: 'Schweiz',
  timezones: ['UTC+01:00'],
} as const;
