export const TWN = {
  name: {
    common: 'Taiwan',
    official: 'Republic of China (Taiwan)',
    native: { cmn: { official: '中华民国', common: '臺灣' } },
  },
  cities: [
    'Taipei',
    'Taoyuan',
    'Tuchang',
    'Hsinchu',
    'Nankan',
    'Taichung',
    'Chang-hua',
    'Kao-sung',
    'Taitung',
    'Tainan',
    'Keelung',
    'Yunlin County',
    'Lugang',
    'Puli',
    'Pingtung',
    "Penghu'ercun",
    'Nantou',
    'Miaoli',
    'Hualian',
    'Gaozhongyicun',
    'Wutai',
    'Checheng',
    'Hengchun',
    'Shalun',
    'Chiayi',
    'Maru',
    'Matsu',
    'Yilan',
    'Shuishang',
    'Shuyi',
    'Budai',
    'Toucheng',
    'Shoufeng',
    'Ma-kung',
    "Wang'an",
    'Meilun',
    'Jincheng',
    'Shulin',
    'Pozi',
    'Suqin',
    'Neihu',
    'Yangmei',
    'Hemei',
    'Wangtongwen',
    'Kaohsiung City',
    'Xinzhuang',
    'Taichang',
    'Linkou',
    'Yanping',
    'Taipingding',
    'Wan-hua',
    'Zhunan',
    'Caogang',
    'Douliu',
    'Erlin',
    'Shuiliu',
    'Dahu',
    'Gangshan',
    'Fengshan',
    'Xinji',
    'Nanhua',
    'Shetou',
    'Fanlu',
    'Shuilin',
    'Zhuqi',
    'Yuli',
    "Su'ao",
    'Wanluan',
    'Zhubei',
  ],
  tld: ['.tw', '.台湾', '.台灣'],
  cca2: 'TW',
  ccn3: '158',
  cca3: 'TWN',
  cioc: 'TPE',
  currency: ['TWD'],
  callingCode: ['886'],
  capital: 'Taipei',
  altSpellings: [
    'TW',
    'Táiwān',
    'Republic of China',
    '中華民國',
    'Zhōnghuá Mínguó',
    'Chinese Taipei for IOC',
    'Taiwan, Province of China',
  ],
  region: 'Asia',
  subregion: 'Eastern Asia',
  languages: { cmn: 'Mandarin' },
  translations: {
    deu: { official: 'Republik China (Taiwan)', common: 'Taiwan' },
    fra: { official: 'République de Chine (Taïwan)', common: 'Taïwan' },
    hrv: { official: 'Republika Kina', common: 'Tajvan' },
    ita: { official: 'Repubblica cinese (Taiwan)', common: 'Taiwan' },
    jpn: { official: '中華民国', common: '台湾（台湾省/中華民国）' },
    nld: { official: 'Republiek China (Taiwan)', common: 'Taiwan' },
    por: { official: 'República da China', common: 'Ilha Formosa' },
    rus: { official: 'Китайская Республика', common: 'Тайвань' },
    spa: { official: 'República de China en Taiwán', common: 'Taiwán' },
    fin: { official: 'Kiinan tasavalta', common: 'Taiwan' },
  },
  latlng: [23.5, 121],
  demonym: 'Taiwanese',
  landlocked: false,
  borders: [],
  area: 36193,
  provinces: [
    'Chang-hua',
    'Chi-lung',
    'Chia-i',
    'Chia-i',
    "Chung-hsing-hsin-ts'un",
    'Hsin-chu',
    'Hsin-chu',
    'Hua-lien',
    'I-lan',
    'Kao-hsiung',
    'Kao-hsiung',
    'Miao-li',
    "Nan-t'ou",
    "P'eng-hu",
    "P'ing-tung",
    "T'ai-chung",
    "T'ai-chung",
    "T'ai-nan",
    "T'ai-nan",
    "T'ai-pei",
    "T'ai-pei",
    "T'ai-tung",
    "T'ao-yuan",
    'Yun-lin',
  ],
  nativeName: '臺灣',
  timezones: ['UTC+08:00'],
} as const;
