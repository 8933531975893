export const JPN = {
  name: {
    common: 'Japan',
    official: 'Japan',
    native: { jpn: { official: '日本', common: '日本' } },
  },
  cities: [
    'Tokyo',
    'Hiroshima',
    'Saitama',
    "Nihon'odori",
    'Ibaraki',
    'Urayasu',
    'Suita',
    'Funabashi',
    'Nagareyama',
    'Ichikawa',
    'Isesaki',
    'Koga',
    'Ichihara',
    'Koshigaya',
    'Shibukawa',
    'Aoicho',
    'Yamakita',
    'Gotemba',
    'Nisshin',
    'Nishinomiya',
    "Den'en-chofu",
    'Kawasaki',
    'Toyama-shi',
    'Moriguchi',
    'Chita',
    'Sano',
    'Nagoya-shi',
    'Kyoto',
    'Hamamatsu',
    'Shimotoda',
    'HachiÅji',
    'Tsuchiura',
    'Wakamatsucho',
    'Yachiyo',
    'Tsukuba',
    'Chiba',
    'Yokohama',
    'Yamanashi',
    'Ashihara',
    'Kawaguchi',
    'Kasukabe',
    'Shizuoka',
    'Kawanishi',
    'Itami',
    'Kobe',
    'Nara',
    'Yao',
    'Osaka',
    'Handa',
    'Yakuin-Horibata',
    'Toyonaka',
    'Honjocho',
    'Kishiwada',
    'Susono',
    'Nagasaki',
    'Setagaya-ku',
    'Zushi',
    'Sugito',
    'Yabasehoncho',
    'Yamaguchi',
    'Kanazawa',
    'Maruyama',
    'Tahara',
    'Obu',
    'Nishio',
    'Ichinomiya',
    'Kasugai',
    'Inazawa',
    'Kariya',
    'Toyohashi',
    'Okazaki',
    'Seto',
    'Konan',
    'Nasa',
    'Odawara',
    'Minamiashigara',
    'Hiratsuka',
    'Kuroiwa',
    'Kagoshima',
    'Yamashitacho',
    'Fukuoka',
    'Kasuga',
    'Kitakyushu',
    'Kurume',
    'Amagasaki',
    'Ochikawa',
    'Kagawa',
    'Fussa',
    'Inzai',
    'Sakai',
    'Iwakura',
    'Kamagaya-shi',
    'Tokorozawa',
    'Machida',
    'Tomakomai',
    'Shiogama',
    'Shimizukoji',
    'Hanawadamachi',
    'Utsunomiya',
    'Sendai',
    'ÅŒtsu',
    'Matsudo',
    'Hino',
    'Sagamihara-shi',
    'Tokushima',
    'Morowa',
    'Sapporo',
    'Numazu',
    'Takajomachi',
    'Mishima',
    'Fujieda',
    'Ashikaga',
    'Matsuyama',
    'Saijo',
    'Okayama',
    'Akita',
    'Minami',
    'Komatsubara',
    'Kameyama',
    'Taiji',
    'Machi',
    'Soka',
    'Niiza',
    'Kawagoe',
    'Shichibancho',
    'ÅŒita',
    'Kumagaya',
    'Ageo',
    'Yorii',
    'Kukichuo',
    'Fujimino',
    'Yashio-shi',
    'Wakayama',
    'Hita',
    'Kashiwa',
    'Fuchu-machi',
    'Yasu-shi',
    'Shiraoka',
    'Hamura',
    'Hitachi',
    'Shinshuku',
    'Shinsuka',
    'Ogaki',
    'Anjo',
    'Bandaicho',
    'Hanabatacho',
    'Sakurakabu',
    'Obihiro',
    'Bakurocho',
    'Machi',
    'Shirayamamachi',
    'Hakui',
    'Shiojiri',
    'Ueda, Nagano',
    'Yokotemachi',
    'Toyohira-ku',
    'Kakegawa',
    'Noda',
    'Yotsukaido',
    'Abiko',
    'Tsurugashima',
    'Gyoda',
    'Yokosuka',
    'Yamatomachi-mitsuai',
    'Niigata',
    'Machi',
    'Chino',
    'Yamagata-shi',
    'Hanamaki',
    'Hashimoto',
    'Uchimaru',
    'Gamagori',
    'Mihama',
    'Higashiosaka',
    'Fujisawa',
    'Koto',
    'Kamirenjaku',
    'Ishioka',
    'Ryugasaki',
    'Toride',
    'Zama',
    'Chigasaki',
    'Kamakura',
    'Kimitsu',
    'Nerima-ku',
    'Nobitome',
    'Sumidacho',
    'Otemachi',
    'Isehara',
    'Hadano',
    'Komae-shi',
    'Asaka',
    'Ushiku',
    'Kodaira',
    'None-mura',
    'Kunitachi',
    'Kokubunji',
    'Miyazaki-shi',
    'Atsugi',
    'Yugawara',
    'Higashimurayama-shi',
    'Kasama',
    'Takahama',
    'Kiyosu',
    'Kanagawa',
    'Sayama',
    'Okegawa',
    'Ebina',
    'Tsubame',
    'Higashiboridori',
    'Ota',
    'Takasaki',
    'Naganohara',
    'Shiki',
    'Mizunami',
    'Nago',
    'Sakuracho',
    'Iida',
    'Moriya',
    'Kami-nagarekawacho',
    'Fukuyama',
    'Aioi',
    'Ashiya',
    'Shibata',
    'Fukayacho',
    'Kanie',
    'Yoshikawa',
    'Tatebayashi',
    'Nakagawa',
    'Edogawa-ku',
    'Toyokawa',
    'Mizuko',
    'Tajimi',
    'Nabari',
    'Miyakonojo',
    'Shirokane',
    'Shibadaimon',
    'Shimizumachi',
    'Fuji',
    'Fujinomiya',
    'Yaizu',
    'Shimoda',
    'Shimada',
    'Fukuroi',
    'Makinohara',
    'Matsuyama',
    'Koganei',
    'Musashino',
    'Fujimi',
    'Wako',
    'Miura',
    'Ayase',
    'Ishinomaki',
    'Otawara',
    'Takatsuki',
    'Nishikicho',
    'Tochigi',
    'Hirakata',
    'Kiryu',
    'Nakatsugawa',
    'Takayama',
    'Beppu',
    'Tendo',
    'Noshiro',
    'Sakado',
    'Kazo',
    'Kitahiroshima',
    'Choshi',
    'Iizuka',
    'Suzuka',
    'Hekinan',
    'Usa',
    'Okinawa',
    'Urasoe',
    'Naha',
    'Chichibu',
    'Asahi',
    'Kita-sannomaru',
    'Hirokawa',
    'Ishigaki',
    'Higashine',
    'Tsuruoka',
    'Asahikawa',
    'Minatomachi',
    'Sannohe',
    'Tottori-shi',
    'Higashiasahimachi',
    'Iwata',
    'Koriyama',
    'Hanno',
    'Takarazuka',
    'Kuwana-shi',
    'Kakogawa',
    'Komaki',
    'Mitake',
    'Tondabayashi',
    'Matsumoto',
    'Kakamigahara',
    'Onomichi',
    'Kure',
    'Maebaru',
    'Tokai',
    'Tosu',
    'Kumamoto',
    'Tobata',
    'Saga-shi',
    'Tamana',
    'Iwamizawa',
    'Narashino-shi',
    'Nakanoshima',
    'Himeji',
    'Kashiwara',
    'Sandacho',
    'Uji',
    'Ikeda',
    'Sasayama',
    'Koka',
    'Hino',
    'Izumi',
    'Matsubara',
    'Kashihara',
    'Iwade',
    'Neyagawa',
    'Kadoma',
    'Tsuruga',
    'Kasumigaura',
    'Nagaoka',
    'Toyota',
    'Fukui-shi',
    'ItÅ',
    'Obanazawa',
    'Masaki',
    'Iwaki',
    'Higashiyama',
    'Waseda',
    'Takaoka',
    'Namerikawa',
    'Narita',
    'Ishikawa',
    'Annaka',
    'Ureshino',
    'Tsushima',
    'Tokoname',
    'Midori',
    'Kitakami',
    'Bibai',
    'Kaigandori',
    'Hitoyoshi',
    'Yamaga',
    'Saku',
    'Minato',
    'Masuda',
    'Honjo',
    'Yokkaichi',
    'Ebisu',
    'Tainoura',
    'Mine',
    'Hatsukaichi',
    'Mino',
    'Akashi',
    'Kasai',
    'Miyazu',
    'Maizuru',
    'Nishiwaki',
    'Sumoto',
    'Yawata',
    'Miki',
    'Ono',
    'Toyooka',
    'Maibara',
    'Ikoma',
    'Hyogo',
    'Taga',
    'Kainan',
    'Kaizuka',
    'Hannan',
    'Sennan',
    'Fukuchiyama',
    'Tatsunocho-tominaga',
    'Shuchi',
    'Tama',
    'Oyama',
    'NikkÅ',
    'Sakura',
    'Hakodate',
    'Kushiro',
    'Katori-shi',
    'Kasumicho',
    'Aomori',
    'Kurihashi',
    'Mito-shi',
    'Suginomecho',
    'Naka',
    'Niihama',
    'Ube',
    'Tagajo-shi',
    'Kameoka',
    'Morioka',
    'Kuroishi',
    'Kofu',
    'Nagano-shi',
    'Warabi-shi',
    'Fukushima-shi',
    'Hinode',
    'Omihachiman',
    'Hikone',
    'Muko',
    'Hachiman-cho',
    'Koyama',
    'Musa',
    'Takanawa',
    'Hasuda',
    'Honden',
    'Nomura',
    'Hashimoto',
    'Ohara',
    'Date',
    'Horyuji',
    'Takamatsu',
    'Anabuki',
    'Hachinohe',
    'Misawa',
    'Suwa',
    'Kashiwazaki',
    'Daido',
    'Koyasan',
    'Iwakuni',
    'Kanaya',
    'Tsu',
    'Matsusaka',
    'Misato',
    'Ginowan',
    'Iwanuma',
    'Izu',
    'Kamo',
    'Hirosaki',
    'Nomimachi',
    'Nita',
    'Miyagi',
    'Maebashi',
    'Toyo',
    'Nishi-Tokyo-shi',
    'Yasugicho',
    'Mobara',
    'Tonami',
    'Uozu',
    'Kamigori',
    'Nanao',
    'Yamato-Takada',
    'Osaka',
    "Kan'onjicho",
    'Kobe',
    'Moriyama',
    'Mino',
    'Shiroi',
    'Komoro',
    'Aizu-Wakamatsu',
    'Mibu',
    'Chuo',
    'Kanuma',
    'Futtsu',
    'Jogo',
    'Natori-shi',
    'Gifu',
    'Sasaguri',
    'Imari',
    'Asahimachi',
    'Karatsu',
    'Takeo',
    'Kashima',
    'Shiroishi',
    'Kitsuki',
    'Ichinomotocho',
    'Sawara',
    'Isoya',
    'Tsukubamirai',
    'Ami',
    'Shimonoseki',
    'Kisarazu',
    'Omiya-ku',
    'Tone',
    'Mitsucho-iwami',
    'Hagi',
    'Yonezawa',
    'Nanae',
    'Isahaya',
    'Oizu',
    'Tenri',
    'Gojo',
    'Gujo',
    'Oyamato',
    'Kashiba',
    'Akasaka',
    'Yoshino',
    'Tono',
    'Hatogaya-honcho',
    'Daitocho',
    'Ueno-ebisumachi',
    'Kaizu',
    'Yabu',
    'Yatsushiro',
    'Kokura',
    'Hakata',
    'Kyoritsu',
    'Konosu',
    'Okuura',
    'Sanno',
    'Suigetsu',
    'Makuhari',
    'Noguchi',
    'Hanyu',
    'Omagari',
    'Kurogi',
    'Sasebo',
    'Uenohara',
    'Yashima',
    'Kyowa',
    'Showa',
    'Takedamachi',
    'Sendai',
    'Innoshima Hara-cho',
    'Nakatsu',
    'Nakada',
    'Ichinomiya',
    'Inagi-shi',
    'Aikawa',
    'Koga',
    'Nose',
    'Izumo',
    'Gundo',
    'Hidaka',
    'Shoinmachi-koji',
    'Nada',
    'Nakamura',
    'Akao',
    'Hokuto-shi',
    'Ueno',
    'Ida',
    'Nakano',
    'Takashima',
    'Tanushimarumachi-sugawara',
    'Azumino',
    'Kurashiki',
    'Chiryu',
    'Komatsu',
    'Yamawaki',
    'Otake',
    'Kumano',
    'Wajima',
    'Tsubata',
    'Hachimori',
    'Honcho',
    'Katta',
    'Akeno',
    'Chiran',
    'Matsue-shi',
    'Nagahama',
    'Kosai',
    'Yoshida-kasugacho',
    'Shisui',
    'Agano',
    'Tomo',
    'Tsudanuma',
    'Kaita',
    'Minamata',
    'Hojo',
    'Nakano',
    'Kameoka',
    'Miemachi',
    'Shinmachi',
    'Tobo',
    'Ritto',
    'Ikaruga',
    'Omuta',
    'Yachimata',
    'Ebetsu',
    'Kiyose',
    'Imagami',
    'Komatsu',
    'Kinokawa',
    'Tanabe',
    'Susami',
    'Kushimoto',
    'Koza',
    'Shingu',
    'Sonobe',
    'Kaseda-shirakame',
    'Kokawa',
    'Koyo',
    'Raiba',
    'Tachibana',
    'Minoshima',
    'Gobo',
    'Arita',
    'Minabe',
    'Ise',
    'Anan',
    'Hommachi',
    'Shimotsucho-kominami',
    'Muroto-misakicho',
    'Kochi-shi',
    'Motomiya',
    'Okaya',
    'Inuyama',
    'Inami',
    'Takasagocho-takasemachi',
    'Takaishi',
    'Takashima',
    'Kusatsu',
    'Higashiomi-shi',
    'Sakurai',
    'Nishihara',
    'Ginza',
    'Kojima',
    'Kocho',
    'Roppongi',
    'Kashiwagimachi',
    'Hiranuma',
    'Tajiri',
    'Sarugakucho',
    'Tamatsukuri',
    'Yoshii',
    'Komagome',
    'Sakaidecho',
    'Kokubu-matsuki',
    'Yodogawa',
    'Shimomeguro',
    'Uwayama',
    'Ichinogo',
    'Kinoshita',
    'Kita',
    'Higashiiya-takano',
    'Obayashi',
    'Natsumi',
    'Senju',
    'Takata',
    'Chiji',
    'Daimon',
    'Sumiyoshi',
    'Edagawa',
    'Toranomon',
    'Gotanda',
    'Higashiiya-wakabayashi',
    'Nishi',
    'Meguro',
    'Honcho',
    'Misakicho',
    'Nakahata',
    'Yamatomachi',
    'Shobusawa',
    'Imabari-shi',
    'Ikejiri',
    'Kiyokawa',
    'Komagawa',
    'Okubo',
    'Suda',
    'Omiya',
    'Minato',
    'Nakazawa',
    'Tamagawa',
    'Ichibanhoridoricho',
    'Shibaura',
    'Taniyama-chuo',
    'Fukuda',
    'Minamiaoyama',
    'Yamada',
    'Onna',
    'Hiki',
    'Hodogayacho',
    'Kashima-shi',
    'Minami',
    'Echizen',
    'Kaminoyama',
    'Suzaka',
    'Kodamacho-kodamaminami',
    'Nakaichi',
    'Omachi',
    'Itoi',
    'Ninomiya',
    'Chikusei',
    'Hinata',
    'Gosen',
    'Mooka',
    'Akishima',
    'Arida',
    'Marugame',
    'Nobeoka',
    'Chitose',
    'Ome',
    'Higashiyamato',
    'Akiruno-shi',
    'Toyoake',
    'Sanmu',
    'Heiwajima',
    'Mutsu',
    'Iruma',
    'Takasu',
    'Kamitosa',
    'Kii-nagashimaku-nagashima',
    'Nakama',
    'Kesennuma',
    'Sumiyoshi',
    'Bando',
    'Imizucho',
    'Usuki',
    'Tagawa',
    'Shobara',
    'Yunoshima',
    'Ishikari',
    'Tomiya',
    'Mizugama',
    'Uruma',
    'Kano',
    'Tomioka',
    'Inashiki',
    'Yubari',
    'Yomitan Son',
    'Tobe',
    'Taga',
    'Kanzaki',
    'Mihara',
    'Yahagi',
    'Satte',
    'Hokota',
    'Matsukawamachi',
    'Nonoichi',
    'Kaga',
    'Goshogawara',
    'Obama',
    'Izumiotsu',
    'Otaru',
    'Keta',
    'Tsurumi',
    'Yokoo',
    'Ina',
    'Mitsuke',
    'Nagawado',
    'Akasaka',
    'Miyama',
    'Kurobeshin',
    'Wakasa',
    'Abira',
    'Togane',
    'Mizusawa',
    'Yufutsu',
    'Ichinoseki',
    'Sanjo',
    'Takikawa',
    'Otsuki',
    'Murakami',
    'Sakata',
    'Iwatsuki',
    'Tomisato',
    'Hiyoshi',
    'Shizukuishi',
    'Kudamatsu',
    'Shitama',
    'Uwajima',
    'Kitanagoya',
    'Kani',
    'Haibara-akanedai',
    'Kanzaki',
    'Kikuchi',
    'Nogata',
    'Yukuhashi',
    'Yahata',
    'Sayo',
    'Hayama',
    'Rankoshi-cho',
    'Izunokuni',
    'Harigaya',
    'Kitakata',
    'Gannosu',
    'Wadayama',
    'Hofu',
    'Ibara',
    'Fukai',
    'Narutocho-mitsuishi',
    'Mihara',
    'Yaita',
    'Hidakacho-ebara',
    'Takahashi',
    'Otsuku-kibi',
    'Oiwake',
    'Ama-higashimachi',
    'owariasahi',
    'Inabe',
    'Okawa',
    'Ogori-shimogo',
    'Itabashi',
    'Goya',
    'Kaminokawa',
    'Miyoshi',
    'Marunouchi',
    'Kitamoto',
    'Kahoku',
    'Miyako',
    'Settsu',
    'Shingu',
    'Ikuno',
    'Ryuo',
    'Sayama',
    'Habikino',
    'Hashima',
    'Yonago',
    'Hachimantai',
    'Yuzawa',
    'Yamanashi',
    'Sado',
    'Koriyama',
    'Yamazakicho-nakabirose',
    'Togitsu',
    'Ojiya',
    'Joetsu',
    'Miyoshi',
    'Fujiidera',
    'Toki',
    'Takemiya',
    'Kitami',
    'Kasaoka',
    'Kazuno',
    'Itako',
    'Nasukarasuyama',
    'Ninohe',
    'Tsuyama',
    'Sukagawa',
    'Yurihonjo',
    'Eniwa-shi',
    'Nihommatsu',
    'Meiji',
    'Abashiri',
    'Godo',
    'Gushikawa',
    'Shinjuku',
    'Obari',
    'Gotanda',
    'Higashikawa',
    'Isomura',
    'Tamashima',
    'Tamano',
    'Soja',
    'Wakicho',
    'Niimi',
    'Yakage',
    'Ochiai',
    'Kuse',
    'Katsumada',
    'Hayashino',
    'Yuge',
    'Hayashima',
    'Mori',
    'Mizushima-nishichidoricho',
    'Katsuyama',
    'Saidaiji-naka',
    'Kamogatacho-kamogata',
    'Toba',
    'Minobu',
    'Kitaozuma',
    'Nagase',
    'Murata',
    'Tennojicho-kita',
    'Omori',
    'Yanocho-futatsugi',
    'Yasuda',
    'Sanuki',
    'Ena',
    'Minokamo',
    'Kasamatsucho',
    'Mihama',
    'Taketoyo',
    'Saikai',
    'Umeda',
    'Kamisu',
    'Ogawa',
    'Tateyama',
    'Kyodo',
    'Shiroishi',
    'Mashiko',
    'Matsuyama',
    'Tsuchiara',
    'Towada',
    'Yakumo',
    'Awara',
    'Kikugawa',
    'Atami',
    'Katsuura',
    'Toshima Mura',
    'Yaese',
    'Sagae',
    'Fujioka',
    'Ueki',
    'Haijima',
    'Budo',
    'Mitsui',
    'Ishikawa',
    'Utazu',
    'Rifu',
    'Ichinomiyacho',
    'Uki',
    'Monzenmachi-igisu',
    'Tadotsu',
    'Tanaka',
    'Muroran',
    'Ishida',
    'Otsukacho',
    'Okuma',
    'Yokogawa',
    'Hayashi',
    'Taiki',
    'Omura',
    'Mitsukaido',
    'Minowa',
    'Komagane',
    'Nichinan',
    'Nikaho',
    'Senboku',
    'Totsukacho',
    'Nanto',
    'Fukui',
    'Kayano',
    'Aisai',
    'Miwa',
    'Higashicho',
    'Shioya',
    'Echizen',
    'Shijonawate',
    'Higashi-takadama',
    'Azuchi',
    'Higashiura',
    'Nakanoshima',
    'Nakahai',
    'Tamachi',
    'Nara',
    'Oshu',
    'Naitobokujo',
    'Sodegaura',
    'Kitagata',
    'Shimabara',
    'Shinshiro',
    'Haebaru',
    'Kurayoshi',
    'Nirasaki',
    'Ikedacho-itano',
    'Sukumo',
    'Nodani',
    'Wakkanai',
    'Hakozaki',
    'Hiji',
    'Mochida',
    'Nakanishi',
    'Otani',
    'Kose',
    'Sugiyama',
    'Tashiro',
    'Fumoto',
    'Yanagawa',
    'Kurihara',
    'Kokufu',
    'Nishiko',
    'Nakano-kami',
    'Nagato',
    'Tatsuno',
    'Takamori',
    'Hikari',
    'Sabae',
    'Omaezaki',
    'Osaki',
    'Himi',
    'Kamikawa',
    'Tokuyama',
    'Kotoku',
    'Aso',
    'Senzoku',
    'Higashi-iwashiro',
    'Fushiki-minatomachi',
    'Tsukayama',
    'Kahoku',
    'Inami',
    'Temma',
    'Tokiwamachi-tokiwa',
    'Ariake',
    'Yamano',
    'Yamauchi',
    'Matsuto',
    'Shiretoko',
    'Agena',
    'Shimo-iino',
    'Machi',
    'Nakagomi',
    'Murayama',
    'Odaira',
    'Komono',
    'Fujita',
    'Arai',
    'Chatan',
    'Shin-shigai',
    'Oarai',
    'Shin',
    'Tsuru',
    'Oshima',
    'Aitsu',
    'Jissen',
    'Niitsu',
    'Ogura',
    'Shikamaku-miya',
    'Hakone',
    'Mima',
    'Koori',
    'Mineyama',
    'Nishimachi',
    'Kataoka',
    'Kikyo',
    'Koryocho-itazu',
    'Seta',
    'Kagoya',
    'Sakurajima-koikecho',
    'Murai',
    'Iju',
    'Nishime',
    'Daisen',
    'Sakaiminato',
    'Shinagawa',
    'Tashima',
    'Yomogita',
    'Ichimura',
    'Fuchucho',
    'Tamura',
    'Takaiwa',
    'Kure',
    'Imabuku',
    'Nyuzen',
    'Kariya',
    'Jumonji',
    'Mitai',
    'Ichi',
    'Kasumigaseki',
    'Shonaicho',
    'Komatsushimacho',
    'Susaki',
    'Kawahara',
    'Kurami',
    'Tatsumi',
    'Musashimurayama',
    'Yoneyamacho-nishino',
    'Anesaki',
    'Nemuro',
    'Kiyama',
    'Yamazaki',
    'Kamo',
    'Kanda',
    'Urayama',
    'Hamada',
    'Kakuda',
    'Oiso',
    'Akashi',
    'Tokiwa',
    'Tsukumiura',
    'Kanayama',
    'Ujiie',
    'Hotei',
    'Kochino',
    'Nayoro',
    'Suijo',
    'Sone',
    'Kamimura',
    'Saiki',
    'Yabuki',
    'Machiya',
    'Oguni',
    'Sekicho-furumaya',
    'Muikamachi',
    'Hikawa',
    'Ishii',
    'Onojo',
    'Tsuhako',
    'Takase',
    'Fukiage-fujimi',
    'Oyabe',
    'Shinjo',
    'Toyoda',
    'Ishiki',
    'Yuki',
    'Kugayama',
    'Arakawa',
    'Akasaki',
    'Momoishi',
    'Tomobe',
    'Joshi',
    'Kamaishi',
    'Onuma',
    'Yamadayu',
    'Mimasaka',
    'Shimotsuke',
    'Konancho-fukawa',
    'Kabe',
    'Shirakawa-machi',
    'Komaba',
    'Hongo',
    'Shimogo',
    'Isawa',
    'Seiro',
    'Minamiuonuma',
    'Kanada',
    'Sumiyocho-kawauchi',
    'Sogabe-minamijo',
    'Fukuchi',
    'Mutsumi',
    'Fujisato',
    'Miyata',
    'Kusai',
    'Senriyama',
    'Kusunoki',
    'Hama',
    'Shima',
    'Kokuryo',
    'Naebocho',
    'Kubo',
    'Misakimachi-konami',
    'Morodomitsu',
    'Joso',
    'Hosobara',
    'Shizunai-misono',
    'Geinocho-mukumoto',
    'Yoshida',
    'Isumi',
    'Takinogawa',
    'Oyama',
    'Tsukawaki',
    'Okawachi',
    'Mombetsu',
    'Yorishimacho',
    'Minatomachi',
    'Esashi',
    'Honjo',
    'Okuchi',
    'Imaicho',
    'Yano',
    'Minatogawa',
    'Subashiri',
    'Kichijoji-honcho',
    'Takicho-oda',
    'Miyakojima',
    'Odori',
    'Nagashima',
    'Kotohira',
    'Daigo',
    'Kanomata',
    'Inage',
    'Morohongo',
    'Toge',
    'Itoigawa',
    'Maki',
    'Yanagi',
    'Kasai',
    'Nishiyama',
    'Youkaichi',
    'Hatoyama',
    'Nagao',
    'Osawa',
    'Kanegasaki',
    'Yutaka',
    'Mitsushimacho-osaki',
    'Toyonomachi-toyono',
    'Nakayama',
    'Wakimachi',
    'Oyama',
    'Kitsuregawa',
    'Ishige',
    'Kaisei',
    'Sakae',
    'Satocho-sato',
    'Kami-kawakami',
    'Teine-ku',
    'Onogawa',
    'Futoo',
    'Katsura',
    'Shinkawa',
    'Yokaichiba',
    'Moji',
    'Kamoi',
    'Aboshiku-okinohama',
    'Sango',
    'Tawaramoto',
    'Yoichi',
    'Hamana',
    'Fuefuki-shi',
    'Kameda-honcho',
    'Gotsucho',
    'Ogi',
    'Iiyama',
    'Obuse',
    'Ochiai',
    'Yoshimi',
    'Uonuma',
    'Fushimi',
    'Kitaibaraki',
    'Onoda',
    'Iwanai',
    'Kutchan',
    'Noboribetsu',
    'Yanai',
    'Iwakiri',
    'Motegi',
    'Nakijin',
    'Ino',
    'Itoman',
    'Nakagusuku',
    'Nagaoka',
    'Takara',
    'Hagi',
    'Kamikita-kita',
    'Aki',
    'Nishikawa',
    'Shiobaru',
    'Kazuka',
    'Toyota',
    'Takajo',
    'Ozu',
    'Horinouchi',
    'Yabase',
    'Hirakawa',
    'Kasumicho',
    'Watari',
    'Sakura',
    'Asahi',
    'Bakurocho',
    'Kamibun',
    'Shiba-Kaigandori',
    'Tokamachi',
  ],
  tld: ['.jp', '.みんな'],
  cca2: 'JP',
  ccn3: '392',
  cca3: 'JPN',
  cioc: 'JPN',
  currency: ['JPY'],
  callingCode: ['81'],
  capital: 'Tokyo',
  altSpellings: ['JP', 'Nippon', 'Nihon'],
  region: 'Asia',
  subregion: 'Eastern Asia',
  languages: { jpn: 'Japanese' },
  translations: {
    deu: { official: 'Japan', common: 'Japan' },
    fra: { official: 'Japon', common: 'Japon' },
    hrv: { official: 'Japan', common: 'Japan' },
    ita: { official: 'Giappone', common: 'Giappone' },
    jpn: { official: '日本', common: '日本' },
    nld: { official: 'Japan', common: 'Japan' },
    por: { official: 'Japão', common: 'Japão' },
    rus: { official: 'Япония', common: 'Япония' },
    spa: { official: 'Japón', common: 'Japón' },
    fin: { official: 'Japani', common: 'Japani' },
  },
  latlng: [36, 138],
  demonym: 'Japanese',
  landlocked: false,
  borders: [],
  area: 377930,
  provinces: [
    'Aichi',
    'Akita',
    'Aomori',
    'Chiba',
    'Ehime',
    'Fukui',
    'Fukuoka',
    'Fukushima',
    'Gifu',
    'Gumma',
    'Hiroshima',
    'Hokkaido',
    'Hyogo',
    'Ibaraki',
    'Ishikawa',
    'Iwate',
    'Kagawa',
    'Kagoshima',
    'Kanagawa',
    'Kochi',
    'Kumamoto',
    'Kyoto',
    'Mie',
    'Miyagi',
    'Miyazaki',
    'Nagano',
    'Nagasaki',
    'Nara',
    'Niigata',
    'Oita',
    'Okayama',
    'Okinawa',
    'Osaka',
    'Saga',
    'Saitama',
    'Shiga',
    'Shimane',
    'Shizuoka',
    'Tochigi',
    'Tokushima',
    'Tokyo',
    'Tottori',
    'Toyama',
    'Wakayama',
    'Yamagata',
    'Yamaguchi',
    'Yamanashi',
  ],
  nativeName: '日本',
  timezones: ['UTC+09:00'],
} as const;
