var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "post-list" },
    [
      _c(
        "TransitionGroup",
        { attrs: { tag: "ul", appear: "" } },
        _vm._l(
          _setup.items,
          function ({ key, title, date, link, icon }, index) {
            return _c(
              "li",
              {
                directives: [
                  {
                    name: "transition-delay",
                    rawName: "v-transition-delay.group",
                    value: { index },
                    expression: "{ index }",
                    modifiers: { group: true },
                  },
                ],
                key: key,
              },
              [
                _c("a", { attrs: { href: link, target: "__blank" } }, [
                  _c("div", [
                    _c(
                      "span",
                      { staticClass: "circle-icon small" },
                      [_c(_setup.Icon, { attrs: { icon: icon } })],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "py-4 position-relative d-flex flex-column flex-grow-1 aligin-items-center",
                      },
                      [
                        _c("span", { staticClass: "post-description mb-1" }, [
                          _vm._v(_vm._s(title)),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "post-date text-muted" }, [
                          _vm._v(_vm._s(date)),
                        ]),
                        _vm._v(" "),
                        _c("hr", { staticClass: "post-border m-0" }),
                      ]
                    ),
                  ]),
                ]),
              ]
            )
          }
        ),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }