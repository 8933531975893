import { ref, computed } from 'vue';
import { LoadPageOptions } from 'table-panel';

import { alert } from '@services/alert';
import { useStore, TablePanels } from '@store';
import { defer, Deferred } from '@tools/defer';
import { delay } from '@tools/delay';
import { ensureError } from '@tools/ensure-error';
import { randomId } from '@tools/random-id';
import { isNotNullish } from '@tools/type-guards';

import type { FilterTagMap, FilterParamMap } from './filters';
import type { DataItem } from './table';

export interface ProgressiveLoaderOptions {
  tableKey: TablePanels.ModuleKey;
  tableFilterTags?: FilterTagMap;
  tableFilterParams?: FilterParamMap;
}

/**
 * Composable that provides core functionality for implementing progressive
 * (scroll-based pagination) data loading for a given table panel.
 *
 * @param props Configuration options object
 * @returns Data object including reactive loader state values and functions
 * for linking loader functionality to table.
 */
export function useProgressiveLoader(props: ProgressiveLoaderOptions) {
  const store = useStore();

  const table = computed(() => store.state.tablePanels[props.tableKey]);

  /** Identifier for determining the most recently-placed query. */
  const currentQueryId = ref<string | null>(null);

  const loadingPage = computed(() => currentQueryId.value !== null);

  /** ... */
  const loadProgress = ref(0);
  /** ... */
  const loadDeferred = ref<Deferred | null>(null);

  const allResultsLoaded = computed(() => {
    return table.value.allResultsLoaded;
  });

  const provider = async (clearPrevious = false) => {
    const options: LoadPageOptions = { clearPrevious };

    if (props.tableFilterTags) {
      options.filterTags = Object.entries(props.tableFilterTags)
        .filter(([, item]) => item.enabled)
        .map(([key]) => key);
    }

    if (props.tableFilterParams) {
      options.filterParams = Object.fromEntries(
        Object.entries(props.tableFilterParams)
          .filter(([, item]) => isNotNullish(item.value))
          .map(([key, item]) => [key, item.value]),
      );
    }

    return (await store.dispatch(
      `tablePanels/${props.tableKey}/loadProgressive`,
      options,
    )) as DataItem[];
  };

  const loadItems = async (clearPrevious = false) => {
    const queryId = (currentQueryId.value = randomId());

    loadDeferred.value = defer();

    let error: ErrorOrNullish = null;

    loadProgress.value = 0;

    await delay();

    loadProgress.value = 75;

    try {
      await provider(clearPrevious);
    } catch (err) {
      error = ensureError(err);
    }

    // If another query has sense been placed, let it handle setting the
    // "query-complete" state.
    if (queryId !== currentQueryId.value) return;

    loadProgress.value = 100;

    await loadDeferred.value.promise;

    currentQueryId.value = null;

    if (error) {
      alert.error(
        `There was an issue while trying to load table items: ${error.message}`,
      );
    }
  };

  const onScroll = (event: Event) => {
    if (table.value.loading || allResultsLoaded.value) return;

    const { scrollHeight, scrollTop, offsetHeight } =
      event.target as HTMLElement;

    const bottom = scrollHeight - offsetHeight;
    const distance = bottom - scrollTop;

    if (distance < 2) void loadItems();
  };

  const onAnimationEnd = () => {
    if (loadProgress.value >= 100) loadDeferred.value?.resolve();
  };

  return {
    loadingPage,
    loadProgress,
    loadDeferred,
    allResultsLoaded,
    loadItems,
    onScroll,
    onAnimationEnd,
  };
}
