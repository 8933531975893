export const POL = {
  name: {
    common: 'Poland',
    official: 'Republic of Poland',
    native: { pol: { official: 'Rzeczpospolita Polska', common: 'Polska' } },
  },
  cities: [
    'WrocÅ‚aw',
    'Warsaw',
    'Elzbieta',
    'Debica',
    'Sedziszow Malopolski',
    'Dabki',
    'SÅ‚awno',
    'Gorlice',
    'Wola Rebkowska',
    'Cierpice',
    'MiÅ„sk Mazowiecki',
    'Zabia Wola',
    'Kampinos',
    'Krakow',
    'Olesnica',
    'PruszkÃ³w',
    'Marek',
    'Grupa',
    'Szczecin',
    'Kielce',
    'Warzachewka Polska',
    'Olsztyn',
    'EÅ‚k',
    'Golasowice',
    'Katowice',
    'MysÅ‚owice',
    'Zawiercie',
    'ChorzÃ³w',
    'Gracze',
    'CzÄ™stochowa',
    'Wadowice',
    'Radom',
    'Raszyn',
    'Tarnogrod',
    'Rzewnie',
    'BiaÅ‚ystok',
    'Otwock',
    'Garwolin',
    'Borowiec',
    'Gliwice',
    'GdaÅ„sk',
    'Wielki Klincz',
    'Debina',
    'Sedziszow',
    'Gozdowo',
    'Lublin',
    'Radoslaw',
    'Czersk',
    'Skoczow',
    'Jozefow',
    'Sopot',
    'Nowe Lignowy',
    'Poznan',
    'Gdynia',
    'Limanowa',
    'Chociwel',
    'Grzmucin',
    'Lubicz',
    'CelestynÃ³w',
    'Siemianowice ÅšlÄ…skie',
    'Lidzbark',
    'Sulejowek',
    'Biedrusko',
    'Malbork',
    'Chelm',
    'CheÅ‚m',
    'Kwidzyn',
    'Mielec',
    'Chorzelow',
    'RzeszÃ³w',
    'Åšwidnica',
    'Przeclaw',
    'SuwaÅ‚ki',
    'Wejherowo',
    'Puck',
    'Rumia',
    'Tomaszow',
    'Golub-Dobrzyn',
    'Bielsko-Biala',
    'Bydgoszcz',
    'Bielawa',
    'Polanica-ZdrÃ³j',
    'Lebork',
    'Stegna',
    'NakÅ‚o nad NoteciÄ…',
    'Koscierzyna',
    'Jelenia GÃ³ra',
    'OstroÅ‚Ä™ka',
    'Nowa Ruda',
    'Libiaz',
    'MÅ‚ynary',
    'OÅ›wiÄ™cim',
    'JarosÅ‚aw',
    'Koszalin',
    'ZambrÃ³w',
    'Lukow',
    'Wisniew',
    'WieluÅ„',
    'ÅÃ³dÅº',
    'GorzÃ³w Wielkopolski',
    'Teofilow',
    'Koniecpol',
    'Klomnice',
    'Kruszyna',
    'Zawada',
    'Huta Stara',
    'Praszka',
    'Czeladz',
    'Stalowa Wola',
    'Suchowola',
    'Zabrze',
    'Opole',
    'WaÅ‚brzych',
    'Linowko',
    'Witkowo',
    'Ranizow',
    'Nowy Tomysl',
    'Kornik',
    'Dolice',
    'Zawady',
    'MiÅ‚omÅ‚yn',
    'Barciany',
    'Biskupiec',
    'Bytom',
    'Radostowice',
    'Przeworsk',
    'Szowsko',
    'Radymno',
    'Trzebownisko',
    'Strazow',
    'Sokolow Malopolski',
    'Wysoka Strzyzowska',
    'Ogrody',
    'Siedlce',
    'KotuÅ„',
    'ÅšwiÄ™tochÅ‚owice',
    'Cisiec',
    'Wegierska Gorka',
    'Trawniki',
    'Swidnik',
    'GiÅ¼ycko',
    'MszczonÃ³w',
    'KÅ‚odawa',
    'ZÄ…bki',
    'RadzyÅ„ CheÅ‚miÅ„ski',
    'Bierun',
    'Sosnowiec',
    'Gorno',
    'Pabianice',
    'Rybnik',
    'Zakopane',
    'Lubin',
    'Sulechow',
    'Kargowa',
    'Zielona GÃ³ra',
    'Szprotawa',
    'Przylep',
    'Czerwiensk',
    'Przemysl',
    'PiotrkÃ³w Trybunalski',
    'Kutno',
    'Rabien',
    'Gadka Stara',
    'TomaszÃ³w Mazowiecki',
    'Sieradz',
    'BeÅ‚chatÃ³w',
    'KoÅ‚o',
    'Poswietne',
    'Chalupki',
    'Janin',
    'Braniewo',
    'BiaÅ‚a Podlaska',
    'Kamienna Gora',
    'GoleniÃ³w',
    'Ostrowiec ÅšwiÄ™tokrzyski',
    'Szypliszki',
    'WoÅ‚omin',
    'Ruda ÅšlÄ…ska',
    'SÅ‚upsk',
    'Budziska',
    'Tarnowskie Gory',
    'Police',
    'SÅ‚ubice',
    'Nowy Dwor Mazowiecki',
    'Kalisz',
    'MiÄ™dzyrzecz',
    'Wilczyce',
    'Debno',
    'PuÅ‚awy',
    'Tyczyn',
    'Poreba',
    'Piekary',
    'Tarnowiec',
    'Radgoszcz',
    'DÄ…browa GÃ³rnicza',
    'MikoÅ‚Ã³w',
    'Wieliczka',
    'Tychy',
    'Gorz',
    'Raszowa',
    'Nowy SÄ…cz',
    'Wilkowice',
    'Korytow',
    'Fabianki',
    'Marki',
    'Znin',
    'Swarzedz',
    'PÅ‚ock',
    'GrudziÄ…dz',
    'Dzierzazno',
    'Polkowice',
    'Modlnica',
    'Teresin',
    'Sochaczew',
    'Skwierzyna',
    'Pielice',
    'Deszczno',
    'Wojciech',
    'TarnÃ³w',
    'WyszkÃ³w',
    'Strzegom',
    'Swiebodzice',
    'Oborniki ÅšlÄ…skie',
    'BiÅ‚goraj',
    'Gmina Widawa',
    'InowrocÅ‚aw',
    'Anin',
    'Glowno',
    'TÅ‚uszcz',
    'Nowe Skalmierzyce',
    'Konstancin-Jeziorna',
    'Kowalowa',
    'Proszowice',
    'Gorzow Slaski',
    'Mysliborz',
    'Walcz',
    'Bolszewo',
    'Ozarow Mazowiecki',
    'Zdunska Wola',
    'Starachowice',
    'Kobylka',
    'Sadlinki',
    'Dabrowa Chotomowska',
    'Piekary Slaskie',
    'Piastow',
    'GÅ‚ogÃ³w',
    'Liszki',
    'Grodzisk Mazowiecki',
    'Krasnystaw',
    'Lowicz',
    'Dabrowka Wielka',
    'Czerwionka-Leszczyny',
    'Ornontowice',
    'Przyszowice',
    'Sanok',
    'Zgorzelec',
    'Piensk',
    'Zbaszyn',
    'Zbaszynek',
    'Jaslo',
    'Konin',
    'Å»ywiec',
    'Lipsko',
    'Reda',
    'Lubartow',
    'Plochocin',
    'Knurow',
    'Brochocin',
    'Barcin',
    'WÅ‚ocÅ‚awek',
    'Jablonica',
    'CheÅ‚mno',
    'Miedzyborow',
    'Serock',
    'Zielonka',
    'Sroda Wielkopolska',
    'Belsk Duzy',
    'Radzymin',
    'Gmina Morawica',
    'Gmina BrwinÃ³w',
    'Gmina KoÅ„skie',
    'Zielonki',
    'Lask',
    'Jerzmanowice',
    'Krzeszowice',
    'Tuszyn',
    'Mechelinki',
    'KoÅ›cian',
    'Szemud',
    'Lomianki',
    'Komorow',
    'Parczew',
    'Å»yrardÃ³w',
    'Szczawno-Zdroj',
    'Lubliniec',
    'Zlocieniec',
    'Strzelce Opolskie',
    'Warka',
    'ToruÅ„',
    'Mragowo',
    'Lodygowice',
    'Wabrzezno',
    'Annopol',
    'Ladek',
    'ChrzanÃ³w',
    'Gmina Strzelin',
    'Wola Zaradzynska',
    'Mikoszewo',
    'Rostarzewo',
    'Ryman',
    'Swietoniowa',
    'Strzyzow',
    'PÅ‚oÅ„sk',
    'Raciaz',
    'Solec Kujawski',
    'Bielsk',
    'ZamoÅ›Ä‡',
    'Lack',
    'OstrÃ³da',
    'Korsze',
    'Starogard GdaÅ„ski',
    'MoÅ„ki',
    'SokoÅ‚Ã³w Podlaski',
    'Suchy Las',
    'Wysogotowo',
    'Lulin',
    'Murowana Goslina',
    'Kosakowo',
    'Pierwoszyno',
    'Pogwizdow',
    'Leszno',
    'Pobiedziska',
    'Tarnowo Podgorne',
    'Puszczykowo',
    'Gniezno',
    'Stawiany',
    'Garby',
    'SzamotuÅ‚y',
    'Strzalkowo',
    'Buk',
    'Pleszew',
    'Wronki',
    'Czarze',
    'Maksymilianowo',
    'Gruczno',
    'Gzin',
    'Adama',
    'Wrzesnia',
    'Borzykowo',
    'Oblaczkowo',
    'Tulce',
    'Obrzycko',
    'Plewiska',
    'Gmina Åšrem',
    'Mogilno',
    'Zabierzow Bochenski',
    'ÅšwinoujÅ›cie',
    'Maria',
    'Cigacice',
    'Chotel',
    'Nowa Sol',
    'Legnica',
    'Lubsko',
    'Grebocice',
    'Gorzyce',
    'Lubaszowa',
    'Wieszowa',
    'Tyczyn',
    'Chmielnik',
    'Blazowa',
    'Dynow',
    'Lubenia',
    'ZÄ…bkowice ÅšlÄ…skie',
    'Gorzow',
    'Zarow',
    'Jaworzyna Slaska',
    'Tomaszow',
    'Szczytno',
    'Ciechocinek',
    'Rypin',
    'Kozieglowy',
    'Grzebien',
    'Gora',
    'Balice',
    'Studzieniec',
    'Czarnkow',
    'Zlotow',
    'ChodzieÅ¼',
    'Pila',
    'Miroslawiec',
    'Ujscie',
    'Wyrzysk',
    'Slupca',
    'MiÄ™dzychÃ³d',
    'Grodzisk Wielkopolski',
    'Gostyn',
    'Owinska',
    'Trzcianka',
    'Osno',
    'Wijewo',
    'Bochnia',
    'Dziewin',
    'JastrzÄ™bie ZdrÃ³j',
    'Nysa',
    'Nowe Miasto Lubawskie',
    'Wojkowice',
    'Zofia',
    'OstrÃ³w Wielkopolski',
    'Tarchaly Wielkie',
    'Krotoszyn',
    'Jarocin',
    'Lubraniec',
    'Kluczbork',
    'OsiÄ™ciny',
    'WÅ‚adysÅ‚awowo',
    'Cekcyn',
    'Tarnobrzeg',
    'Olkusz',
    'Serock',
    'Odolin',
    'Czechowice-Dziedzice',
    'Meszna',
    'Legionowo',
    'Piekoszow',
    'Swoboda',
    'Wilkowisko',
    'Kolbudy',
    'BytÃ³w',
    'AleksandrÃ³w Kujawski',
    'Sadlno',
    'CheÅ‚mÅ¼a',
    'Karczew',
    'BÅ‚onie',
    'Jozefoslaw',
    'Zalubice Stare',
    'KoÅ‚biel',
    'Jutrosin',
    'Ustron',
    'WodzisÅ‚aw ÅšlÄ…ski',
    'Strumien',
    'Lubon',
    'Åapy',
    'Blachownia',
    'Ziebice',
    'Jaworzno',
    'Pewel Mala',
    'Naklo',
    'BÄ™dzin',
    'Miasteczko Slaskie',
    'Weglowice',
    'Koszecin',
    'MaryLka',
    'Zgierz',
    'Skawina',
    'Pruszcz Gdanski',
    'Tluczan',
    'Podegrodzie',
    'Wegrzce Wielkie',
    'Stary Sacz',
    'Olawa',
    'Naleczow',
    'Piaseczno',
    'Kozy',
    'Nowa',
    'Å»agaÅ„',
    'Gubin',
    'Luban',
    'Kowary',
    'Piechowice',
    'BolesÅ‚awiec',
    'Jawor',
    'Pieszkow',
    'Bystrzyca Klodzka',
    'Elblag',
    'Swiebodzin',
    'Borek Strzelinski',
    'Cieszyn',
    'AleksandrÃ³w ÅÃ³dzki',
    'Rzeszotary',
    'Ozorkow',
    'Prochowice',
    'KÅ‚odzko',
    'Szymanow',
    'Kunice',
    'Zary',
    'Sieniawa Zarska',
    'Lipinki Luzyckie',
    'Niwica',
    'Sobotka',
    'Pietrzykowice',
    'Åšroda ÅšlÄ…ska',
    'Mary',
    'Jugow',
    'Bogatynia',
    'Miedzylesie',
    'Zalesie GÃ³rne',
    'Pisz',
    'HrubieszÃ³w',
    'Haczow',
    'Nadarzyn',
    'Chojnice',
    'Ostrog',
    'RacibÃ³rz',
    'Wasilkow',
    'WÄ™grÃ³w',
    'Zmigrod',
    'Tczew',
    'SokÃ³Å‚ka',
    'Siemiatycze',
    'ZabÅ‚udÃ³w',
    'Mazancowice',
    'Sierpc',
    'ÅomÅ¼a',
    'MorÄ…g',
    'Wagrowiec',
    'Dzierzoniow',
    'Nowogard',
    'Choszczno',
    'Gmina JÄ™drzejÃ³w',
    'Ustka',
    'Gmina Åšwiecie',
    'ÅÄ™czyca',
    'ChojnÃ³w',
    'Frysztak',
    'Twardawa',
    'Lubomia',
    'Smigiel',
    'Andrespol',
    'Oksywie',
    'Siechnice',
    'Lutynia',
    'Rzuchowa',
    'Radzanowo',
    'Pyrzyce',
    'Sompolno',
    'Å»ory',
    'BiaÅ‚ogard',
    'Swidwin',
    'Karlino',
    'Zaczernie',
    'Korczyna',
    'Strykow',
    'Katy',
    'Przemysl',
    'Makow Mazowiecki',
    'Wielen Zaobrzanski',
    'Skierniewice',
    'Chmielow',
    'Polajewo',
    'Bukowice',
    'Zaniemysl',
    'Gryfice',
    'Skorzewo',
    'Sosnie',
    'Kuznica Czarnkowska',
    'Mikstat',
    'Trzciel',
    'Przezmierowo',
    'Szczaniec',
    'Wolsztyn',
    'Gmina Lipno',
    'Pogorzela',
    'Zalakowo',
    'SÅ‚uÅ¼ewo',
    'Stargard SzczeciÅ„ski',
    'TrzebiatÃ³w',
    'Wolin',
    'Mrowino',
    'Papowo',
    'Mielno',
    'Kostrzyn',
    'Steszew',
    'Gozdnica',
    'Gnuszyn',
    'Babimost',
    'Czerwonak',
    'Kamien Pomorski',
    'Biesiekierz',
    'Swietno',
    'Rosnowko',
    'Klenica',
    'Zerkow',
    'Budzyn',
    'Wilkanowo',
    'Iwiczna',
    'Zosin',
    'Ostrowek',
    'Cmielow',
    'Sandomierz',
    'Rakow',
    'Wachock',
    'Nowa Deba',
    'Sidra',
    'Maslow',
    'Busko-ZdrÃ³j',
    'MichaÅ‚owo',
    'Tykocin',
    'Bodzentyn',
    'Iwaniska',
    'Kazimierza Wielka',
    'Bejsce',
    'Nowa Slupia',
    'Deblin',
    'Ulez',
    'Suchedniow',
    'Mniow',
    'Lipnik',
    'BraÅ„sk',
    'Chmielnik',
    'Gmina Gnojno',
    'Gmina Sadowie',
    'Jastkow',
    'Terespol',
    'Stopnica',
    'Staszow',
    'Baranow',
    'Krosno',
    'Narew',
    'Slawno B',
    'BoÄ‡ki',
    'Gmina Wyszki',
    'Zagnansk',
    'Olsztynek',
    'Gmina PoÅ‚aniec',
    'Humniska',
    'Choroszcz',
    'Kamien',
    'Wola Kopcowa',
    'Margonin',
    'Barwice',
    'Wolow',
    'Lubomierz',
    'Winsko',
    'Kamionki',
    'Stare Kurowo',
    'Osielsko',
    'Gizalki',
    'Gmina DÄ…bie',
    'Dzikowiec',
    'Stara Kamienica',
    'Miszkowice',
    'Czarny Bor',
    'Bogdaszowice',
    'Domaslaw',
    'Strzeszow',
    'Zloty Stok',
    'Milicz',
    'Drawsko Pomorskie',
    'Msciwojow',
    'Sepolno Krajenskie',
    'Gmina Bobrowo',
    'Brzeg',
    'Kudowa-ZdrÃ³j',
    'Gmina BÄ…dkowo',
    'Przysiek',
    'Wystep',
    'Bochowo',
    'Gniewkowo',
    'Osiek',
    'Dabrowa Chelminska',
    'Waganiec',
    'Swiekatowo',
    'Ciechocin',
    'KoÅ‚obrzeg',
    'Kobierzyce',
    'Pilawa',
    'Å»uromin',
    'Latowicz',
    'Beblo',
    'Gmina Chmielno',
    'Zblewo',
    'MÅ‚awa',
    'Racula',
    'Smolnik',
    'Laska',
    'Pinczow',
    'Tolkmicko',
    'Lacko',
    'Szydlowo',
    'Przechlewo',
    'SupraÅ›l',
    'Kielczow',
    'Balice',
    'Makow',
    'Lotyn',
    'Kotlin',
    'Stolno',
    'Sucha Beskidzka',
    'Hucisko Jawornickie',
    'Kobior',
    'Ledziny',
    'Rybno',
    'MilanÃ³wek',
    'Mogilany',
    'Rozprza',
    'Dziekanow Lesny',
    'Wolka',
    'Raciazek',
    'Dobra',
    'Staniatki',
    'Zagorow',
    'Pegow',
    'Trzebnica',
    'Zwolen',
    'Myslenice',
    'Rzgow Pierwszy',
    'Rawa Mazowiecka',
    'Regimin',
    'Tarczyn',
    'PuÅ‚tusk',
    'Ksieginice',
    'Golczewo',
    'Swieta Katarzyna',
    'Santok',
    'Wloszczowa',
    'Chylice',
    'Bralin',
    'Gmina KsiÄ…Å¼ Wielkopolski',
    'Targanice',
    'Sztum',
    'Kostrzyn nad Odra',
    'Czluchow',
    'Radzionkow',
    'Wola Krzysztoporska',
    'Pionki',
    'Brzostowka',
    'Oborniki',
    'Gmina StÄ…porkÃ³w',
    'Turobin',
    'Cwiklice',
    'KoÅ„skowola',
    'Jemielnica',
    'Dobrodzien',
    'Lidzbark WarmiÅ„ski',
    'Mszana Dolna',
    'Andrychow',
    'Ruda',
    'NieporÄ™t',
    'Gizyce',
    'Tyszowce',
    'Krzyszkowice',
    'Grojec',
    'Scinawa Nyska',
    'Kamieniec',
    'Otoki',
    'Ilowo',
    'Kowal',
    'Bielsk Podlaski',
    'Wschowa',
    'Krosno Odrzanskie',
    'Dukla',
    'Bazanowka',
    'Gogolin',
    'ZÅ‚otoryja',
    'Bielkowo',
    'GoÅ‚dap',
    'Tuchola',
    'Brzeg Dolny',
    'Opoczno',
    'Kowiesy',
    'Jedlicze',
    'Karpacz',
    'Gronowo Elblaskie',
    'Zrecin',
    'KÄ™trzyn',
    'Niemcz',
    'Wylatowo',
    'Krzepice',
    'Å»ychlin',
    'Zdzieszowice',
    'Szczucin',
    'Debno',
    'Wisla',
    'Kazimierz Biskupi',
    'Kolbuszowa',
    'Skarszewy',
    'Wlodawa',
    'Dygowo',
    'Lublewo',
    'Rogow',
    'Tymowa',
    'Ostrow',
    'Dziwnow',
    'Chelmsko Slaskie',
    'Bolewice',
    'Sycow',
    'Miroslaw',
    'Witaszyce',
    'Ropczyce',
    'Lubanie',
    'Przeclaw',
    'Rewa',
    'Nidzica',
    'Orneta',
    'Rzepin',
    'Barlinek',
    'Pszczyna',
    'Stara',
    'Ksiazenice',
    'Kocmyrzow',
    'Stryszow',
    'AugustÃ³w',
    'Mosty',
    'Labiszyn',
    'Gmina Moszczenica',
    'Krokowa',
    'Zegrze Pomorskie',
    'Prudnik',
    'Konarzyce',
    'Popowice',
    'Nowy Dwor',
    'Krzyki-Partynice',
    'Lobez',
    'Kowalew',
    'DziaÅ‚dowo',
    'Piaski',
    'Grodki',
    'Rokietnica',
    'Lubnice',
    'Mialy',
    'Chelmek',
    'Kliniska',
    'Brodnica',
    'Krepa Kaszubska',
    'Wicko',
    'Konczyce Male',
    'Kozmin Wielkopolski',
    'Zebrzydowice',
    'Jelesnia',
    'Susz',
    'Cybinka',
    'Gluszyca',
    'Borkowice',
    'Iwla',
    'Kozy',
    'Gora Pulawska',
    'Stary Garbow',
    'Jablonna',
    'Pobiedna',
    'Chocianow',
    'Pajeczno',
    'RoÅ¼ental',
    'Nowy Targ',
    'Dobra',
    'Olesno',
    'Sulejow',
    'Lubawa',
    'Dzwierzuty',
    'Blizne',
    'Kopki',
    'Rawicz',
    'Bystra',
    'Katy',
    'Jordanow',
    'Czernichow',
    'Pruchna',
    'Jawiszowice',
    'Stadla',
    'Walim',
    'Gmina MiÄ™kinia',
    'RyduÅ‚towy',
    'Milkowice',
    'Poczesna',
    'Mierzecice',
    'Klobuck',
    'Raszowka',
    'Wieloglowy',
    'Koniusza',
    'Wambierzyce',
    'Sokolowsko',
    'Ostroszowice',
    'Gmina BorÃ³w',
    'Pila Koscielecka',
    'Piasek',
    'Paniowki',
    'Nowogrodziec',
    'BoguszÃ³w-Gorce',
    'Bobrowniki',
    'Trabki',
    'Wilczyn',
    'Toszek',
    'Kedzierzyn-Kozle',
    'Luborzyca',
    'Klikuszowa',
    'Kalwaria Zebrzydowska',
    'Boleslaw',
    'Wisniowa',
    'Orzesze',
    'Karniowice',
    'Suloszowa',
    'Niepolomice',
    'Malczyce',
    'Jankowice',
    'Wrzosowa',
    'KroÅ›niewice',
    'Zelow',
    'Pyskowice',
    'Swierklany',
    'Slemien',
    'Koniakow',
    'Krzyzanowice',
    'Myszkow',
    'Jaroszow',
    'Niemcza',
    'Bestwinka',
    'Glogowek',
    'Glucholazy',
    'Rogow',
    'Stare Bogaczowice',
    'Sieniawka',
    'Pilawa Gorna',
    'Jastrzebie',
    'Bestwina',
    'Lipowa',
    'Koscielisko',
    'Biala',
    'Kamienica Polska',
    'Koluszki',
    'Dobron',
    'Trzebinia',
    'Ogrodzieniec',
    'Czaniec',
    'Jaroszowiec',
    'Wola Filipowska',
    'Belk',
    'Katy Wroclawskie',
    'Korfantow',
    'Gdow',
    'Stryszawa',
    'Rudy',
    'Skala',
    'Polanka Wielka',
    'Radlin',
    'Chrzesne',
    'Grodzisk',
    'Chotomow',
    'Nowe Miasto nad Pilica',
    'Laskarzew',
    'Åosice',
    'Przasnysz',
    'Lancut',
    'Trzciana',
    'Kurylowka',
    'Czudec',
    'Kanczuga',
    'Rudnik nad Sanem',
    'Zurawica',
    'Rakszawa',
    'Majdan Krolewski',
    'IÅ‚awa',
    'Kuligow',
    'Nacpolsk',
    'Stare Babice',
    'Wyszogrod',
    'Lesnica',
    'Szadek',
    'Gmina Lutomiersk',
    'Kolonowskie',
    'Barglowka',
    'Bojszow',
    'Nowy Wisnicz',
    'Rybna',
    'Czarny Dunajec',
    'Brzeszcze',
    'Gromiec',
    'Barcice',
    'Slomniki',
    'Piekielnik',
    'Brzezinka',
    'Chrzan',
    'Tegoborze',
    'Kukow',
    'Zalesie',
    'Siemkowice',
    'Sidzina',
    'Bialy Dunajec',
    'Kojszowka',
    'Gorzyce',
    'Wymiarki',
    'Prabuty',
    'Szczawno',
    'Swidnica',
    'Dabrowa Biskupia',
    'Lysomice',
    'Kcynia',
    'Rogowo',
    'Komorsk',
    'Tuczno',
    'Miedzyzdroje',
    'Bojano',
    'Straszyn',
    'Kolodziejewo',
    'Gmina Sierakowice',
    'Ilowa',
    'Dargoslaw',
    'Sarbinowo',
    'Jeziora Wielkie',
    'Zbiczno',
    'Baruchowo',
    'Pieszyce',
    'Gmina BolkÃ³w',
    'Marcinowice',
    'Krasnik',
    'Grajewo',
    'Checiny',
    'Ryn',
    'Szyldak',
    'Bisztynek',
    'Dabrowno',
    'Dywity',
    'Dylewo',
    'KurÃ³w',
    'Grunwald',
    'Bartoszyce',
    'Zabrowo',
    'Stawiguda',
    'Kozienice',
    'Drozdowo',
    'Trzesniow',
    'Swiatki',
    'Samin',
    'Dobre Miasto',
    'Stare Juchy',
    'Zalewo',
    'Trzydnik Duzy',
    'Prawiedniki',
    'Garbow',
    'Milowka',
    'Zablocie',
    'Dobroszyce',
    'Gmina Puszcza MariaÅ„ska',
    'Falkow',
    'Bedkow',
    'Radomsko',
    'Kamien Slaski',
    'Nieborowice',
    'Slawkow',
    'Wilkow',
    'Roztoka',
    'Zembrzyce',
    'Ciezkowice',
    'Hecznarowice',
    'Szklarska PorÄ™ba',
    'Zagrodno',
    'Krosnowice',
    'Krasiejow',
    'Lubawka',
    'Twardogora',
    'Zorawina',
    'Prusice',
    'Smardzew',
    'Domaniewice',
    'Brudzice',
    'Zduny',
    'Tworog',
    'Stroze',
    'Klucze',
    'Alwernia',
    'Suchy Dab',
    'Rokitki',
    'Sztutowo',
    'Ostaszewo',
    'Drewnica',
    'Godziszewo',
    'Pelplin',
    'Drezdenko',
    'Dobrzany',
    'Trzcinsko',
    'Klecko',
    'Ostrowite',
    'Pszczew',
    'Kicin',
    'Legowo',
    'Barczewo',
    'Swarzewo',
    'Leba',
    'PasÅ‚Ä™k',
    'Komorniki',
    'Kartuzy',
    'Raczki',
    'Rokitnica',
    'Bukowina Tatrzanska',
    'Myslachowice',
    'Siennica Nadolna',
    'Radzyn Podlaski',
    'Gorki Wielkie',
    'Brenna',
    'Sianow',
    'Smardzowice',
    'Turek',
    'Gmina Przywidz',
    'Zukowo',
    'Opatow',
    'Kunow',
    'Nowy Dwor Gdanski',
    'Milcza',
    'Krzywin',
    'Kazmierz',
    'Skopanie',
    'Kwaczala',
    'Labowa',
    'Orzysz',
    'Lubochnia',
    'Frombork',
    'Gmina Kolno',
    'Kowalewo',
    'Rejowiec',
    'Brzesko',
    'Dabrowa',
    'Sieroszowice',
    'Zawonia',
    'Wojkowice Koscielne',
    'Gowarzewo',
    'Wreczyca Wielka',
    'Gmina GoÅ‚uchÃ³w',
    'Siekierczyn',
    'Dziegielow',
    'Wrzosowka',
    'Oleszno',
    'Swarorzyn',
    'Falkowo',
    'Niechanowo',
    'KikÃ³Å‚',
    'Wielgie',
    'Radomin',
    'Otyn',
    'Kaczkowo',
    'Osno Lubuskie',
    'Swiniary',
    'Krzyz Wielkopolski',
    'Rydzyna',
    'Pepowo',
    'Baranowko',
    'Czapury',
    'Klodawa',
    'Czempin',
    'Piaski',
    'Gmina NowogrÃ³d BobrzaÅ„ski',
    'Wielichowo',
    'Gmina Babiak',
    'Kwilcz',
    'Szczytniki',
    'Gmina Szubin',
    'Lisewo',
    'Gruszczyn',
    'Karnice',
    'Darnowo',
    'Skrzetusz',
    'Duszniki-Zdroj',
    'Gmina KostomÅ‚oty',
    'Kielcz',
    'Pruszcz Pomorski',
    'Manowo',
    'Siemirowice',
    'Damnica',
    'Czernica',
    'Tanowo',
    'Stare Czarnowo',
    'Rewal',
    'Stanowice',
    'Miedzyborz',
    'Lomnica',
    'Laka',
    'Konopiska',
    'Poraj',
    'Glubczyce',
    'Rzeczyce',
    'Ozimek',
    'Jasienica',
    'Lekawica',
    'Huta Dlutowska',
    'Dobieszowice',
    'Ligota',
    'Niemodlin',
    'Kraszew',
    'Czekanow',
    'Brudzowice',
    'Kocierzew Poludniowy',
    'Karchowice',
    'Wierzchowisko',
    'Torzym',
    'CheÅ‚mce',
    'Lipno',
    'Kleszczewo',
    'Gmina Kiszkowo',
    'Slone',
    'Golanice',
    'Kowalewo Pomorskie',
    'Witnica',
    'Niegoslawice',
    'Szadlowice',
    'Pniewy',
    'Zaparcin',
    'Paliszewo',
    'Melno',
    'Juchnowiec Koscielny',
    'Gmina Ciechanowiec',
    'Grabowka',
    'Krypno',
    'Gmina JedliÅ„sk',
    'Knyszyn',
    'Osieck',
    'CiechanÃ³w',
    'Niedrzwica Duza',
    'Bochotnica',
    'Moszczanka',
    'Wawolnica',
    'Lipiny',
    'Klikawa',
    'Wola Baranowska',
    'Wadowice Gorne',
    'Wielbark',
    'Gorzkow',
    'Bychawa',
    'Jeziorany',
    'Siennica',
    'Pilawa',
    'Ojrzen',
    'Duczki',
    'GÄ…socin',
    'Dzierzgon',
    'Miloradz',
    'Koleczkowo',
    'Milobadz',
    'Liniewo',
    'Stary DzierzgoÅ„',
    'Gmina PoddÄ™bice',
    'Gmina SulÄ™cin',
    'Nowe Miasto nad Warta',
    'GoÅ‚ubie',
    'Sulmin',
    'Slawoszyno',
    'Mierzeszyn',
    'Otomin',
    'Choczewo',
    'Kobylnica',
    'Szlachta',
    'Rakowiec',
    'Stankowo',
    'Majewo',
    'Borkowo',
    'Bojanowo',
    'Lipusz',
    'Sobowidz',
    'Pniewy',
    'Lubichowo',
    'Tenczyn',
    'Nowa Biala',
    'Szyce',
    'Michalowice',
    'Gaworzyce',
    'Iwanowice',
    'Konstantynow',
    'Konstantynow Lodzki',
    'Olszyna',
    'Lesna',
    'LÄ…dek ZdrÃ³j',
    'Rabka-Zdroj',
    'Krapkowice',
    'Mucharz',
    'Szewce',
    'Wozniki',
    'Dlugopole-Zdroj',
    'Kruszyn',
    'Tymbark',
    'Skarzysko-Kamienna',
    'Peczniew',
    'Gluchow',
    'Gryfow Slaski',
    'Gmina Zakliczyn',
    'Bibice',
    'Gorzyczki',
    'Kalety',
    'Biskupice',
    'Mirkow',
    'Szczyrk',
    'Jarkowice',
    'Wegliniec',
    'Mokrsko',
    'Zawadzkie',
    'Ciasna',
    'Uciechow',
    'Pielgrzymka',
    'Szczercow',
    'Garki',
    'Klecza Dolna',
    'Nisko',
    'Kroscienko Wyzne',
    'Lezajsk',
    'Gmina KsiÄ™Å¼pol',
    'Nielisz',
    'Ostrow Lubelski',
    'Grodzisko Dolne',
    'Milanow',
    'Nowo-Aleksandrowo',
    'Jezowe',
    'Pysznica',
    'Susiec',
    'Lubycza Krolewska',
    'Szczuczyn',
    'Otrebusy',
    'Slupno',
    'Frydrychowice',
    'Kuznia',
    'Dankowice',
    'Dzialoszyn',
    'Marklowice',
    'Rogoznik',
    'Bielany',
    'Bolechowice',
    'Minoga',
    'Chybie',
    'Popow',
    'Jablonka',
    'Zawoja',
    'Biecz',
    'Byczyna',
    'Gorzkowice',
    'Druzbice',
    'Trzebunia',
    'Gora Siewierska',
    'Skrbensko',
    'Wisla Wielka',
    'Smolec',
    'Konradowka',
    'Bielany Wroclawskie',
    'Drzewica',
    'Konstantyn',
    'Namyslow',
    'Janow',
    'Szczejkowice',
    'Sanniki',
    'Rajszew',
    'Jasieniec',
    'Ustanow',
    'Pomiechowek',
    'Kazimierz Dolny',
    'Czarna',
    'Nasielsk',
    'Ryki',
    'StaroÅºreby',
    'Bukowsko',
    'Magnuszew',
    'Strzyze',
    'Dunaj',
    'Trzeszczyn',
    'Brzeznica',
    'Belsznica',
    'Kasinka',
    'Szczecinek',
    'Baran',
    'Zielone',
    'Makowiec',
    'Praca',
    'Kielno',
    'Rogozno',
    'Szreniawa',
    'Gmina WÅ‚adysÅ‚awÃ³w',
    'Gniewino',
    'Luzino',
    'GoÅ›cicino',
    'Karlikowo',
    'Dobrzyca',
    'Stara Lubianka',
    'Pila',
    'Dolaszewo',
    'Turowiec',
    'Grzegorz',
    'Suszec',
    'Szczurowa',
    'Dulcza Wielka',
    'Liw',
    'Ostrow Mazowiecka',
    'Szczepanow',
    'Borzecin',
    'Wysokie Mazowieckie',
    'Szepietowo',
    'Åšniadowo',
    'Rudzica',
    'Kokoszkowy',
    'Rychwal',
    'Ostrzeszow',
    'Golkowice',
    'Zelazkow',
    'KÄ™pno',
    'Brok',
    'Chwaszczyno',
    'Bierun Nowy',
    'Odolanow',
    'Topola Mala',
    'Rebkow',
    'Cieszkow',
    'Zduny',
    'Strzelce Krajenskie',
    'Polomia',
    'Miejska Gorka',
    'Strzelno',
    'Goleszow',
    'Rozanka',
    'Lochow',
    'Henrykow',
    'Pietrowice Wielkie',
    'Gorzyce',
    'Glowienka',
    'Reszel',
    'Olecko',
    'Dabrowskie',
    'Zabor',
    'Kozuchow',
    'Czosnow',
    'Zielona',
    'Wilga',
    'Zloczew',
    'Zagosciniec',
    'Podlasie',
    'Zerniki',
    'Sulistrowice',
    'Chyliczki',
    'Kleczany',
    'MiÄ™dzyrzec Podlaski',
    'Bratkowice',
    'Wolbrom',
    'Radlow',
    'Wawel',
    'Rozgarty',
    'Osiek',
    'Pawlowice',
    'Pszow',
    'Zagorzyce',
    'Iwierzyce',
    'Nawodna',
    'Warzymice',
    'DarÅ‚owo',
    'Ostrowy',
    'MiÅ‚ki',
    'WÄ™gorzewo',
    'Prabuty',
    'Stare Miasto',
    'Brzozow',
    'Kamien',
    'Stoczek',
    'HajnÃ³wka',
    'Leczna',
    'Leszkowice',
    'Strzyzow',
    'Sulmierzyce',
    'Karsko',
    'Pustkow',
    'Zyrakow',
    'Brzostek',
    'Radomysl Wielki',
    'Brzeznica',
    'Zarowka',
    'Rzemien',
    'Plesna',
    'Niedomice',
    'Bogumilowice',
    'Borne Sulinowo',
    'Polczyn-Zdroj',
    'Czaplinek',
    'Szostka',
    'Kuzniki',
    'Cianowice Duze',
    'Zbroslawice',
    'Zernica',
    'Biezanow-Prokocim',
    'Prokocim',
    'Suwaki',
    'JabÅ‚onowo Pomorskie',
    'Krzywcza',
    'Zabrzeg',
    'Zglobien',
    'Krzeszow',
    'Mscice',
    'Rudna Mala',
    'Zielonki-Wies',
    'Bramki',
    'Makow Podhalanski',
    'Czernica',
    'Nowa Wies Elcka',
    'Gmina OsjakÃ³w',
    'Mieczewo',
    'Kety',
    'Klaj',
    'Czerniewice',
    'Raczka',
    'Serwis',
    'Gomunice',
    'Rusiec',
    'Marta',
    'Debe Wielkie',
    'Olszowice',
    'Kowalkow',
    'Banino',
    'Skrzyszow',
    'Lachowice',
    'Rumianek',
    'Kobylanka',
    'Radziechowy',
    'Giby',
    'Bukowno',
    'Modrze',
    'Mosina',
    'Miastko',
    'Keblowo',
    'Wielowies',
    'Opatowek',
    'Strzebin',
    'Stary Zamosc',
    'Labunie',
    'Skomlin',
    'Warzno',
    'Klimontow',
    'Lochowo',
    'Debrzno Wies',
    'Rudziniec',
    'Lany',
    'Ujazd',
    'Trzyciaz',
    'Pilica',
    'Bydlin',
    'Nebrowo Wielkie',
    'Naprawa',
    'Szydlowiec',
    'Bielcza',
    'Arkadia',
    'Lubsza',
    'Babienica',
    'Miedzybrodzie Zywieckie',
    'Ryglice',
    'Sieniawa',
    'Przystajn',
    'Przemyslaw',
    'Bukowiec Opoczynski',
    'Maszewo',
    'Orzel',
    'Dabrowa Bialostocka',
    'Jozefatow',
    'BolimÃ³w',
    'Rzasnia',
    'Rudka',
    'Buczkowice',
    'Pomorskie',
    'Gieraltowice',
    'Chocznia',
    'Obsza',
    'Sobienie Jeziory',
    'Dobiesz',
    'Czersk',
    'Gora Kalwaria',
    'Bojadla',
    'Grzmiaca',
    'Ilza',
    'Siwek',
    'Strzeleczki',
    'Gorazdze',
    'Osieczna',
    'Harasiuki',
    'Liskow',
    'Strzebielino',
    'Gryfino',
    'Skorcz',
    'Grebocin',
    'Chorzele',
    'Kobiernice',
    'Korzenna',
    'Koronowo',
    'Wielopole Skrzynskie',
    'Przygodzice',
    'Sulmierzyce',
    'Opalenica',
    'Maslice',
    'Bobrza',
    'Dzielna',
    'Zarszyn',
    'Huta Dabrowa',
    'CegÅ‚Ã³w',
    'Borowa',
    'BiaÅ‚a Rawska',
    'Kolonia Zawada',
    'Sochocin',
    'Staniszow',
    'Bialobrzegi',
    'Byslaw',
    'Pogodki',
    'Brynica',
    'Daleszyce',
    'Gloskow',
    'StrachÃ³wka',
    'Wola Radlowska',
    'Golotczyzna',
    'Osiek Jasielski',
    'Baniocha',
    'Czarny Las',
    'Radziejowice',
    'Wola Rasztowska',
    'Rebowo',
    'Zglobice',
    'Borki',
    'Kielpin',
    'Nowy Staw',
    'Bielawy',
    'Janow Lubelski',
    'Leszno',
    'Koteze',
    'Kurdwanow',
    'Nowa Huta',
    'Czyzyny',
    'Podgorze',
    'Goszczyn',
    'Wisznice',
    'Koszyce',
    'Koszyce',
    'Wieliszew',
    'Miedzna',
    'Czarne',
    'Dopiewiec',
    'Lusowko',
    'Sady',
    'Paledzie',
    'Biskupice',
    'Przemet',
    'Rogalinek',
    'Gostynin',
    'Skorzec',
    'Kusnierz',
    'GÄ…bin',
    'Kurzetnik',
    'Zabno',
    'Dobroszyce',
    'Olejnica',
    'Czastary',
    'Bierutow',
    'Zawada',
    'Wiazow',
    'Kurek',
    'Bojszowy',
    'Subkowy',
    'Myszyniec',
    'Juszkowo',
    'Olszany',
    'Puszczew',
    'Zorawia',
    'Katarzyna',
    'Braszewice',
    'Tomaszow Lubelski',
    'Wieckowice',
    'Staw',
    'Pisarzowice',
    'Trojanow',
    'Baborow',
    'Lobzenica',
    'Grabiec',
    'Jasienica Dolna',
    'Stary Broniszew',
    'Przyrow',
    'Charzykowy',
    'Pastuchow',
    'Mackowice',
    'Krynica-Zdroj',
    'Tylicz',
    'Morawica',
    'Nowy Swietow',
    'Chrzowice',
    'Lelow',
    'Tuchow',
    'Lipnica Murowana',
    'Wysoka',
    'Pawlowice',
    'Czarna BiaÅ‚ostocka',
    'RadziejÃ³w',
    'Scinawa Mala',
    'Jastrzebie',
    'Czarnozyly',
    'Baboszewo',
    'Drohiczyn',
    'Iwonicz-Zdroj',
    'Kosina',
    'Brzyska Wola',
    'Bircza',
    'Podleze',
    'Zator',
    'Tomaszowice',
    'Wilczeta',
    'Swiecie nad Osa',
    'Wapielsk',
    'Bledzew',
    'Trzemeszno Lubuskie',
    'Rymanow',
    'Odrzykon',
    'Roczyny',
    'Kolno',
    'Grabow nad Prosna',
    'Zaleszany',
    'Turbia',
    'Milejow',
    'Gmina Ludwin',
    'Kaczor',
    'Kochcice',
    'Brzeziny',
    'Frank',
    'Imielin',
    'Cmolas',
    'Wolborz',
    'Zawidow',
    'Gieraltowice',
    'Stronie Slaskie',
    'Lewin Brzeski',
    'Podgorne',
    'Gniew',
    'Laziska Gorne',
    'Nowe Chechlo',
    'Soczewka',
    'Tarnow Opolski',
    'Gmina Konarzyny',
    'Swierk',
    'Reczno',
    'Kamien Krajenski',
    'Zgorsko',
    'Polskie',
    'Boguslaw',
    'Ustrzyki Dolne',
    'Chojna',
    'Towarzystwo',
    'Borowe',
    'Sobotka',
    'Lajsk',
    'Pokrzywnica',
    'Prosna',
    'Supienie',
    'Pilchowice',
    'Unieszewo',
    'Mszana',
    'Bory',
    'Gaszowice',
    'Syrynia',
    'Bogunice',
    'Å»elechÃ³w',
    'Siewierz',
    'Skierdy',
    'Modlniczka',
    'Zgorzala',
    'Burzenin',
    'Wasniow',
    'Radoszyce',
    'Nowy Korczyn',
    'Pacanow',
    'Grabki Duze',
    'Gmina SzydÅ‚Ã³w',
    'Strawczyn',
    'Kedzierzyn',
    'Bolkow',
    'Karpiska',
    'Wieruszow',
    'Laziska',
    'Tecza',
    'Biskupice Oloboczne',
    'Biskupin',
    'Hornowek',
    'Bohdan',
    'Debowiec',
    'Medyka',
    'Pcim',
    'Lubien',
    'Lomianki Dolne',
    'Sycewice',
    'Bielawa',
    'Niewierz',
    'Glowna',
    'Winnica',
    'Krasocin',
    'Blizyn',
    'Bieliny',
    'Jedlnia-Letnisko',
    'Olimpia',
    'BrzeÅ›Ä‡ Kujawski',
    'Lipnica Wielka',
    'Mieroszow',
    'Jastrowie',
    'Lubaczow',
    'Krynica',
    'Gmina Opole Lubelskie',
    'Siedlec',
    'Åšlesin',
    'Kozminek',
    'Zaluski',
    'Paulina',
    'Kleszczow',
    'Miechow',
    'Wlodzimierz',
    'Zebowice',
    'Chrzastowka',
    'Szebnie',
    'Zalubice Nowe',
    'Pedziwiatry',
    'Medynia Glogowska',
    'Nienadowka',
    'Dziechciniec',
    'Glogow Malopolski',
    'Krasne',
    'Kozieglowy',
    'Izabelin',
    'Keblowo',
    'Gora',
    'Gowino',
    'Moryn',
    'Kijewo Krolewskie',
    'Lesko',
    'Domaradz',
    'SuraÅ¼',
    'Radzanow',
    'Malogoszcz',
    'Mikolajki',
    'Mirsk',
    'Trzemeszno',
    'PakoÅ›Ä‡',
    'Orchowo',
    'GÄ™bice',
    'Izabela',
    'Dobromierz',
    'Wiorek',
    'Brzoza Krolewska',
    'Narzym',
    'Gmina PotÄ™gowo',
    'Smolnica',
    'Grodkow',
    'Konarzewo',
    'Muszyna',
    'Cekow',
    'Trzemesnia',
    'Mrocza',
    'Grybow',
    'Oltarzew',
    'Drawno',
    'Tylmanowa',
    'Zalasewo',
    'Dobrzen Wielki',
  ],
  tld: ['.pl'],
  cca2: 'PL',
  ccn3: '616',
  cca3: 'POL',
  cioc: 'POL',
  currency: ['PLN'],
  callingCode: ['48'],
  capital: 'Warsaw',
  altSpellings: ['PL', 'Republic of Poland', 'Rzeczpospolita Polska'],
  region: 'Europe',
  subregion: 'Eastern Europe',
  languages: { pol: 'Polish' },
  translations: {
    deu: { official: 'Republik Polen', common: 'Polen' },
    fra: { official: 'République de Pologne', common: 'Pologne' },
    hrv: { official: 'Republika Poljska', common: 'Poljska' },
    ita: { official: 'Repubblica di Polonia', common: 'Polonia' },
    jpn: { official: 'ポーランド共和国', common: 'ポーランド' },
    nld: { official: 'Republiek Polen', common: 'Polen' },
    por: { official: 'República da Polónia', common: 'Polónia' },
    rus: { official: 'Республика Польша', common: 'Польша' },
    spa: { official: 'República de Polonia', common: 'Polonia' },
    fin: { official: 'Puolan tasavalta', common: 'Puola' },
  },
  latlng: [52, 20],
  demonym: 'Polish',
  landlocked: false,
  borders: ['BLR', 'CZE', 'DEU', 'LTU', 'RUS', 'SVK', 'UKR'],
  area: 312679,
  provinces: [
    'Dolnoslaskie',
    'Kujawsko-Pomorskie',
    'Lodzkie',
    'Lubelskie',
    'Lubuskie',
    'Malopolskie',
    'Mazowieckie',
    'Opolskie',
    'Podkarpackie',
    'Podlaskie',
    'Pomorskie',
    'Slaskie',
    'Swietokrzyskie',
    'Warminsko-Mazurskie',
    'Wielkopolskie',
    'Zachodniopomorskie',
  ],
  nativeName: 'Polska',
  timezones: ['UTC+01:00'],
} as const;
