import { server } from '@api/request';
import { UnityCatalog } from '@client/models';

export interface DownloadVersionFileOptions {
  os: 'mac' | 'win';
  appName: 'Zephyr';
  dlcId?: string;
  channel: UnityCatalog['channel'];
}

export interface AlphaReleaseResponse {
  alpha: ReleaseObject;
}

export interface BetaReleaseResponse {
  beta: ReleaseObject;
}

export interface ReleaseCandidateReleaseResponse {
  rc: ReleaseObject;
}

export interface StableReleaseResponse {
  release: ReleaseObject;
}

export interface ReleaseObject {
  latest: string;
  releases: ReleaseInfo;
}
export interface ReleaseInfo {
  version: string;
}

/**
 * Download Version File
 *
 * @return A Version File
 */
export async function downloadVersionFile(
  options: DownloadVersionFileOptions,
): Promise<
  | AlphaReleaseResponse
  | BetaReleaseResponse
  | ReleaseCandidateReleaseResponse
  | StableReleaseResponse
> {
  return await server.post('v2/download/version-file', options);
}
