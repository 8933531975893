var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "invoice-number-cell" }, [
    _c(
      "span",
      {
        directives: [
          {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: "Click to copy",
            expression: "'Click to copy'",
            modifiers: { hover: true, right: true },
          },
        ],
        on: { click: _setup.copyKey },
      },
      [_vm._v("\n    " + _vm._s(_setup.invoiceNumber) + "\n  ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }