import { Vue } from '@vue';

import { isNullish } from '@tools/type-guards';
import { logger } from '@utils/styled-logger';

import * as modalGroups from './modal-groups';

/** ... */
type ModalGroups = typeof modalGroups;

/** Series of Zephyr database API request-based services. */
export const modals = Vue.observable(setupModalGroups(modalGroups));

// if (module.hot) {
//   module.hot.accept('./modal-groups', () =>
//     Object.assign(modals, setupModalGroups(modalGroups)),
//   );
// }

if (import.meta.hot) {
  import.meta.hot.accept('./modal-groups', () =>
    Object.assign(modals, setupModalGroups(modalGroups)),
  );
}

//#region Helper Functions

function setupModalGroups(groups: ModalGroups) {
  groups = { ...groups };

  if (DEVELOPMENT) addModalLoggers(groups);

  return groups;
}

/**
 * Add loggers to each modal function of each modal group. Logs modal's name,
 * group, and any arguments passed to it.
 */
function addModalLoggers(groups: ModalGroups) {
  for (const [groupName, group] of Object.entries(groups)) {
    if (isNullish(group)) continue;

    const entries = Object.entries(group).map(([modalName, modalFn]) => {
      const wrappedModalFn = (...args: unknown[]) => {
        logger.group(
          [
            ' MODAL ',
            { color: '#000000', background: '#ff8036', borderRadius: '6px' },
          ],
          '  ',
          [groupName, '#ff6e7a'],
          ['.', '#e06c75ad'],
          [modalName, '#ff6e7a'],
        );

        /* eslint-disable-next-line no-console */
        console.log('arguments', args);

        logger.groupEnd();

        return modalFn(...args);
      };

      return [modalName, wrappedModalFn];
    });

    Object.assign(groups, { [groupName]: Object.fromEntries(entries) });
  }
}

//#endregion Helper Functions
