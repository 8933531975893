import { Vue } from '@vue';

import { defer } from '@tools/defer';

import { stack, ModalStack } from './stack';
import ModalRoot from './ModalRoot.vue';

declare module 'vue/types/vue' {
  interface Vue {
    $uibModals: typeof uibModals;
  }
}

let promiseChain: Promise<unknown> | null = Promise.resolve();

/** ... */
const defaultModalOptions = { animation: true, backdrop: true, keyboard: true };

/**
 * Open an instance of a modal.
 *
 * @param modalOptions ...
 * @return ...
 */
function open<R = unknown>(modalOptions: UibModals.OpenModalOptions) {
  const modalResultDeferred = defer<R>();
  const modalOpenedDeferred = defer();
  const modalClosedDeferred = defer();
  const modalRenderDeferred = defer();

  // Prepare an instance of a modal to be injected into controllers and
  // returned to a caller.
  const modalInstance: UibModals.Modal<R> = {
    result: modalResultDeferred.promise,
    opened: modalOpenedDeferred.promise,
    closed: modalClosedDeferred.promise,
    rendered: modalRenderDeferred.promise,
    close: () => false,
    dismiss: () => false,
  };

  // Merge and clean up options.
  modalOptions = { ...defaultModalOptions, ...modalOptions };
  modalOptions.resolve = modalOptions.resolve ?? {};

  if (!document.body) {
    throw new Error(
      'appendTo element not found. Make sure that the element passed is in DOM.',
    );
  }

  // Verify options.
  if (!modalOptions.component as unknown) {
    throw new Error('Component options is required.');
  }

  // ...
  const resolveWithComponent = () => modalOptions.component;

  // Wait for the resolution of the existing promise chain. Then switch to our
  // own combined promise dependency (regardless of how the previous modal
  // fared). Then add to $modalStack and resolve opened. Finally clean up the
  // chain variable if no subsequent modal has overwritten it.
  let samePromise = Promise.resolve();

  samePromise = promiseChain = Promise.all([promiseChain])
    .then(resolveWithComponent, resolveWithComponent)
    .then(
      (component) => {
        const modal: ModalStack.ModalData = {
          component,
          props: modalOptions.props,
          deferred: modalResultDeferred,
          renderDeferred: modalRenderDeferred,
          closedDeferred: modalClosedDeferred,
          animation: modalOptions.animation,
          backdrop: modalOptions.backdrop,
          keyboard: modalOptions.keyboard,
          backdropClass: modalOptions.backdropClass ?? null,
          windowClass: modalOptions.windowClass,
          ariaLabelledBy: modalOptions.ariaLabelledBy,
          ariaDescribedBy: modalOptions.ariaDescribedBy,
          size: modalOptions.size,
          openedClass: modalOptions.openedClass,
        };

        stack.emit('open', modalInstance, modal);

        modalOpenedDeferred.resolve(true);
      },
      (reason) => {
        modalOpenedDeferred.reject(reason);
        modalResultDeferred.reject(reason);
      },
    )
    .finally(() => {
      if (promiseChain === samePromise) {
        promiseChain = null;
      }
    });

  return modalInstance;
}

/** ... */
export const uibModals = { open };

/**
 * ...
 */
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class UibModals {
  static installed = false;

  static install(Vue: Vue.OriginalConstructor) {
    if (this.installed) return;

    this.installed = true;

    Vue.component('ModalRoot', ModalRoot);

    Object.assign(Vue.prototype, { $uibModals: uibModals });
  }
}

export namespace UibModals {
  /** ... */
  export type Component = ModalStack.ComponentValue;

  /** ... */
  export type Size = 'sm' | 'md' | 'lg' | 'xl' | 'auto';

  /**
   * ...
   */
  export interface OpenModalOptions {
    animation?: boolean;
    backdrop?: boolean | 'static';
    keyboard?: boolean;
    backdropClass?: string | string[];
    windowClass?: string | string[];
    ariaLabelledBy?: string;
    ariaDescribedBy?: string;
    size?: Size;
    openedClass?: string;
    resolve?: Modal.ResolveOptions;
    props?: unknown;
    component: Component;
  }

  /**
   * ...
   */
  export interface Modal<R> {
    result: Promise<R>;
    opened: Promise<unknown>;
    closed: Promise<unknown>;
    rendered: Promise<unknown>;
    close(result?: unknown): boolean;
    dismiss(reason?: string): boolean;
  }

  export namespace Modal {
    /** ... */
    export type ResolveOptions = Record<string, unknown>;
  }
}

export default UibModals;
