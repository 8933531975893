import { Module, VuexModule, Mutation, Action } from '@vuex/decorators';
import { find, findIndex } from 'lodash';

import { api } from '@api';
import { Scene } from '@models';
import { auth, RoleId } from '@auth';
import { Root } from '@store';
import { isString } from '@tools/type-guards';

declare module '@vuex/core' {
  export interface Getters {
    'scenes/findById': Scenes['findById'];
  }

  export interface CommitMap {
    'scenes/SET': Scenes['SET'];
    'scenes/ADD': Scenes['ADD'];
    'scenes/UPDATE': Scenes['UPDATE'];
    'scenes/DELETE': Scenes['DELETE'];
    'scenes/CLEAR': Scenes['CLEAR'];
  }

  export interface DispatchMap {
    'scenes/list': Scenes['list'];
    'scenes/get': Scenes['get'];
    'scenes/create': Scenes['create'];
    'scenes/update': Scenes['update'];
    'scenes/del': Scenes['del'];
  }
}

@Module({ namespaced: true })
export class Scenes
  extends VuexModule<Scenes.State, Root.State>
  implements Scenes.State
{
  items: Scene[] = [];

  get findById() {
    return (id: string) => find(this.items, { id });
  }

  // region Mutations

  @Mutation
  SET(options: Scenes.SetMutationOptions) {
    this.items = options.data;
  }

  @Mutation
  ADD(options: Scenes.AddMutationOptions) {
    const items = [...this.items];

    const i = findIndex(items, { id: options.id });

    if (i !== -1) {
      items[i] = options;
    } else {
      items.push(options);
    }

    this.items = items;
  }

  @Mutation
  UPDATE(options: Scenes.UpdateMutationOptions) {
    const items = [...this.items];

    const i = findIndex(items, { id: options.sceneId });

    if (i === -1) {
      throw new Error(`Scene with ID ${options.sceneId} not found.`);
    }

    for (const key in options) items[i][key] = options[key];

    this.items = items;
  }

  @Mutation
  DELETE(options: Scenes.DeleteMutationOptions) {
    const index = findIndex(this.items, { id: options.sceneId });

    if (index === -1) {
      throw new Error(`Scene with ID ${options.sceneId} not found.`);
    }

    this.items = this.items.filter((_, i) => i !== index);
  }

  @Mutation
  CLEAR() {
    this.items = [];
  }

  // endregion Mutations

  // region Actions

  @Action
  async list() {
    const { me } = this.context.rootState;

    let data: Scene[] = [];

    if (auth.isActiveRole(RoleId.LasAdmin)) {
      data = await api.scenes.list();
    } else if (auth.isActiveRole(RoleId.Instructor, RoleId.InstitutionAdmin)) {
      const organizationId = me.organization?.id;

      if (!isString(organizationId)) {
        return console.error(
          `Expected User.Institution.Id to be a number. Got ${typeof organizationId} instead`,
        );
      }

      data = await api.scenes.listByOrganization({ organizationId });
    }

    this.context.commit('SET', { data });

    return this.items;
  }

  @Action
  async get(options: Scenes.GetActionOptions) {
    const data = await api.scenes.get(options);

    this.context.commit('ADD', data);

    return data;
  }

  @Action
  async create(options: Scenes.CreateActionOptions) {
    const data = await api.scenes.create(options);

    this.context.commit('ADD', data);

    return data;
  }

  @Action
  async update(options: Scenes.UpdateActionOptions) {
    await api.scenes.update(options);

    return (await this.context.dispatch('get', options)) as Scene;
  }

  @Action async addModules(options: Scenes.AddRemoveModulesActionOptions) {
    await api.scenes.addModules(options);

    return (await this.context.dispatch('get', options)) as Scene;
  }

  @Action async removeModules(options: Scenes.AddRemoveModulesActionOptions) {
    await api.scenes.removeModules(options);

    return (await this.context.dispatch('get', options)) as Scene;
  }

  @Action
  async del(options: Scenes.DeleteActionOptions) {
    await api.scenes.del(options);

    this.context.commit('DELETE', options);
  }

  // endregion Actions
}

export namespace Scenes {
  /**
   * ...
   */
  export interface State {
    items: Scene[];
  }

  /**
   * ...
   */
  export interface SetMutationOptions {
    data: Scene[];
  }

  /** ... */
  export type AddMutationOptions = Scene;
  /** ... */
  export type UpdateMutationOptions = api.scenes.UpdateSceneOptions;
  /** ... */
  export type DeleteMutationOptions = api.scenes.DeleteSceneOptions;
  /** ... */
  export type GetActionOptions = api.scenes.GetSceneOptions;
  /** ... */
  export type CreateActionOptions = api.scenes.CreateSceneOptions;
  /** ... */
  export type UpdateActionOptions = api.scenes.UpdateSceneOptions;
  /** ... */
  export type AddRemoveModulesActionOptions =
    api.scenes.AddRemoveSceneModulesOptions;
  /** ... */
  export type DeleteActionOptions = api.scenes.DeleteSceneOptions;
}

export default Scenes;
