export const ARM = {
  name: {
    common: 'Armenia',
    official: 'Republic of Armenia',
    native: {
      hye: { official: 'Հայաստանի Հանրապետություն', common: 'Հայաստան' },
      rus: { official: 'Республика Армения', common: 'Армения' },
    },
  },
  cities: [
    'Aparan',
    'Yerevan',
    'Jermuk',
    'Abovyan',
    'Vanadzor',
    'Apaga',
    'Ashtarak',
    'Arabkir',
    'Agarak',
    'Kapan',
    'Erebuni Fortress',
    'Hrazdan',
    'Ijevan',
    'Tsaghkadzor',
  ],
  tld: ['.am'],
  cca2: 'AM',
  ccn3: '051',
  cca3: 'ARM',
  cioc: 'ARM',
  currency: ['AMD'],
  callingCode: ['374'],
  capital: 'Yerevan',
  altSpellings: [
    'AM',
    'Hayastan',
    'Republic of Armenia',
    'Հայաստանի Հանրապետություն',
  ],
  region: 'Asia',
  subregion: 'Western Asia',
  languages: { hye: 'Armenian', rus: 'Russian' },
  translations: {
    cym: { official: 'Republic of Armenia', common: 'Armenia' },
    deu: { official: 'Republik Armenien', common: 'Armenien' },
    fra: { official: "République d'Arménie", common: 'Arménie' },
    hrv: { official: 'Republika Armenija', common: 'Armenija' },
    ita: { official: 'Repubblica di Armenia', common: 'Armenia' },
    jpn: { official: 'アルメニア共和国', common: 'アルメニア' },
    nld: { official: 'Republiek Armenië', common: 'Armenië' },
    por: { official: 'República da Arménia', common: 'Arménia' },
    rus: { official: 'Республика Армения', common: 'Армения' },
    spa: { official: 'República de Armenia', common: 'Armenia' },
    fin: { official: 'Armenian tasavalta', common: 'Armenia' },
  },
  latlng: [40, 45],
  demonym: 'Armenian',
  landlocked: true,
  borders: ['AZE', 'GEO', 'IRN', 'TUR'],
  area: 29743,
  provinces: [
    'Aragatsotn',
    'Ararat',
    'Armavir',
    "Geghark'unik'",
    "Kotayk'",
    'Lorri',
    'Shirak',
    "Syunik'",
    'Tavush',
    "Vayots' Dzor",
    'Yerevan',
  ],
  nativeName: 'Հայաստան',
  timezones: ['UTC+04:00'],
} as const;
