var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { class: ["display-value", { bold: _vm.bold }] },
    [
      _c(
        "label",
        { staticClass: "value-label" },
        [
          _c("span", { staticClass: "label-text" }, [
            _vm._v(_vm._s(_vm.label)),
          ]),
          _vm._v(" "),
          _vm._t("after-label"),
        ],
        2
      ),
      _vm._v(" "),
      _c("Spacer"),
      _vm._v(" "),
      _vm._t("default", function () {
        return [
          _vm.href
            ? _c("div", [
                _c(
                  "span",
                  {
                    staticClass: "link",
                    on: {
                      click: function ($event) {
                        return _setup.openUrl(_vm.href)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_setup.displayValue) + "\n        "
                    ),
                    _c("Icon", {
                      attrs: { icon: _setup.faArrowUpRightFromSquare },
                    }),
                  ],
                  1
                ),
              ])
            : _c("div", [_vm._v(_vm._s(_setup.displayValue))]),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }