export const SWE = {
  name: {
    common: 'Sweden',
    official: 'Kingdom of Sweden',
    native: { swe: { official: 'Konungariket Sverige', common: 'Sverige' } },
  },
  cities: [
    'Stockholm',
    'Uppsala',
    'Malmo',
    'Handen',
    'Skaerholmen',
    'Gothenburg',
    'Lidingoe',
    'Vaellingby',
    'Lindome',
    'Solna',
    'OErkelljunga',
    'Bromma',
    'Vallentuna',
    'Vaxjo',
    'Motala',
    'Haegersten',
    'Tyreso Strand',
    'BorÃ¥s',
    'Alta',
    'Sollentuna',
    'Akersberga',
    'NorrkÃ¶ping',
    'Taby',
    'Vaermdoe',
    'Halmstad',
    'Varby',
    'Forsby',
    'Norsborg',
    'Huddinge',
    'Storvreta',
    'Molnbo',
    'Rimbo',
    'Djurhamn',
    'GrÃ¤nna',
    'Trollhattan',
    'Uddevalla',
    'Nodinge-Nol',
    'Skanninge',
    'Nynashamn',
    'EkerÃ¶',
    'Norrtalje',
    'Malma',
    'Graestorp',
    'Flen',
    'Kungsbacka',
    'Moelndal',
    'Asa',
    'Hoerby',
    'Vastra Frolunda',
    'Gabo',
    'Savedalen',
    'Aled',
    'Eskilstuna',
    'Oskarstrom',
    'Ã–rebro',
    'SÃ¶dertÃ¤lje',
    'Nacka',
    'Arloev',
    'Enkoping',
    'Hjo',
    'Kista',
    'Alvsjo',
    'Sigtuna',
    'Hasselby',
    'Billesholm',
    'Upplands Vasby',
    'Mora',
    'Stora',
    'Svedala',
    'Marsta',
    'Skepplanda',
    'NykÃ¶ping',
    'Haerryda',
    'OEdeshoeg',
    'Saro',
    'Tierp',
    'Helsingborg',
    'Partille',
    'Immeln',
    'Kalmar',
    'Rockneby',
    'Krokom',
    'Varberg',
    'Hallstavik',
    'Slottsbron',
    'Halso',
    'Oregrund',
    'LinkÃ¶ping',
    'Romakloster',
    'Bro',
    'Enskede-Arsta-Vantoer',
    'Laxa',
    'Molkom',
    'Mariestad',
    'Marback',
    'SkÃ¶vde',
    'Vaddo',
    'Svangsta',
    'Nysater',
    'Skanor med Falsterbo',
    'Lidkoping',
    'Hollviken',
    'Borgstena',
    'Varnamo',
    'Bergsjoe',
    'VÃ¤sterÃ¥s',
    'SÃ¶lvesborg',
    'Hultafors',
    'Reso',
    'Morrum',
    'Visby',
    'Stenungsund',
    'Ronninge',
    'Stocksund',
    'Danderyd',
    'Bollebygd',
    'Huskvarna',
    'Karlshamn',
    'Lund',
    'Barseback',
    'Vimmerby',
    'Staffanstorp',
    'Solleftea',
    'Hallevadsholm',
    'Stroemstad',
    'Hedekas',
    'Munkedal',
    'KungÃ¤lv',
    'Falkenberg',
    'Gammelstad',
    'LuleÃ¥',
    'Ã–stersund',
    'Gnesta',
    'GÃ¤vle',
    'Djursholm',
    'Tullinge',
    'Spanga',
    'Rydeback',
    'Bara',
    'Tranas',
    'Nynas',
    'Karlstad',
    'Rottne',
    'UmeÃ¥',
    'JÃ¶nkÃ¶ping',
    'Saltsjobaden',
    'Karlskoga',
    'Skallinge',
    'Brunflo',
    'Boden',
    'Lerum',
    'Osterhaninge',
    'Varsta',
    'Skarblacka',
    'Graddo',
    'Hallingsjo',
    'Bjuv',
    'Ludvika',
    'BorlÃ¤nge',
    'Sala',
    'Strangnas',
    'Stenhamra',
    'Vetlanda',
    'Tollarp',
    'Gislaved',
    'Osmo',
    'Gualov',
    'Toereboda',
    'Vagnharad',
    'Arboga',
    'Smedjebacken',
    'Aby',
    'Nykvarn',
    'Falun',
    'Langshyttan',
    'Backa',
    'Vanersborg',
    'Lilla Edet',
    'Ljungby',
    'Saevsjoe',
    'Rotebro',
    'Kaevlinge',
    'Vargarda',
    'Lonashult',
    'Gemla',
    'Kristinehamn',
    'Sundbyberg',
    'Kallered',
    'Alingsas',
    'Kareby',
    'Harestad',
    'Eslov',
    'Kode',
    'Bohus',
    'Ojersjo',
    'Gnosjoe',
    'PiteÃ¥',
    'SkellefteÃ¥',
    'Bromoella',
    'Nodinge',
    'Ornskoldsvik',
    'Lyckeby',
    'Tving',
    'Hjarnarp',
    'Alafors',
    'Ulricehamn',
    'Aseda',
    'Oskarshamn',
    'Ytterby',
    'Jordbro',
    'Sundsvall',
    'Lycksele',
    'Krylbo',
    'Vendelso',
    'Edsbro',
    'Surahammar',
    'Knivsta',
    'Saltsjo-Duvnas',
    'Holo',
    'Vasterfarnebo',
    'Hallstahammar',
    'Akers Styckebruk',
    'Fagersta',
    'Vingaker',
    'Sandviken',
    'Karra',
    'Ullared',
    'Sjomarken',
    'Teckomatorp',
    'Lysekil',
    'Skarpnaeck',
    'Tumba',
    'Johanneshov',
    'Brottby',
    'Orbyhus',
    'Tegeltorp',
    'Oxie',
    'Ingmarso',
    'Bodafors',
    'Hedemora',
    'Furulund',
    'Astorp',
    'Trangsund',
    'Herrljunga',
    'Timra',
    'Ã„ngelholm',
    'Domsjo',
    'Angered',
    'Degerfors',
    'Morgongava',
    'Bjornlunda',
    'Fritsla',
    'Skogas',
    'Stallarholmen',
    'Torshalla',
    'Katrineholm',
    'Dalaro',
    'Ransta',
    'Trosa',
    'Jarna',
    'Laholm',
    'Jokkmokk',
    'Grums',
    'Skurup',
    'Hoganas',
    'Surte',
    'Orsa',
    'Raettvik',
    'Uttran',
    'Skara',
    'Landskrona',
    'Mjolby',
    'Mardaklev',
    'Dals Rostock',
    'Vinninga',
    'Hunnestad',
    'Molnlycke',
    'FalkÃ¶ping',
    'Olofstorp',
    'Langas',
    'Skene',
    'Fjaeras station',
    'Landvetter',
    'Hono',
    'Jonsered',
    'Grabo',
    'Askim',
    'Kullavik',
    'Goetene',
    'Floda',
    'Vargon',
    'Roro',
    'Varobacka',
    'Malsryd',
    'Orby',
    'Ã…hus',
    'Ramdala',
    'Kristianstad',
    'Hoeoer',
    'Paarp',
    'Akarp',
    'Loddekopinge',
    'Ronneby',
    'Tyringe',
    'Ekeby',
    'Sturko',
    'Karlskrona',
    'Sibbhult',
    'Kattarp',
    'Osby',
    'Limhamn',
    'Tagarp',
    'Tingsryd',
    'Simrishamn',
    'Klagstorp',
    'Morarp',
    'HÃ¤ssleholm',
    'Smedstorp',
    'Vinslov',
    'Klippan',
    'Asarum',
    'Sjoebo',
    'Svaloev',
    'Trelleborg',
    'Navlinge',
    'Fjalkinge',
    'Hyllinge',
    'Bjarnum',
    'Listerby',
    'Billeberga',
    'Ystad',
    'Harby',
    'Vankiva',
    'Olofstroem',
    'Bjarred',
    'Nattraby',
    'Haljarp',
    'Vastra Ramlosa',
    'Delsbo',
    'Forslov',
    'Obbola',
    'Tvaralund',
    'Bygdsiljum',
    'Byske',
    'Jarved',
    'Taberg',
    'Moelle',
    'Villan',
    'Restad',
    'Mullsjoe',
    'Faerjestaden',
    'Oxelosund',
    'Lindesberg',
    'Bergshamra',
    'Ransater',
    'Viken',
    'Kungshamn',
    'Vansbro',
    'Bengtsfors',
    'Getinge',
    'Sennan',
    'OEsthammar',
    'OEckeroe',
    'Leksand',
    'Deje',
    'Torslanda',
    'Gimo',
    'Forshaga',
    'Uddeholm',
    'Ekshaerad',
    'Stollet',
    'Tidan',
    'Stenstorp',
    'Tidaholm',
    'Likenas',
    'Hagfors',
    'Ambjornarp',
    'Henan',
    'Ljungskile',
    'Edsvalla',
    'Torup',
    'Harplinge',
    'Kilafors',
    'Bollnas',
    'Lanna',
    'Knivsta',
    'Overlida',
    'Sexdrega',
    'Munkfors',
    'Harmanger',
    'Alno',
    'Bergeforsen',
    'Hudiksvall',
    'Savsjostrom',
    'Avesta',
    'Mariefred',
    'Vidsel',
    'Stenkullen',
    'Varekil',
    'Liden',
    'Hebo',
    'Rengsjo',
    'Vallsta',
    'Arbra',
    'Segersta',
    'Billdal',
    'Vellinge',
    'Balsta',
    'HÃ¤rnÃ¶sand',
    'Veinge',
    'Ockelbo',
    'Torsaker',
    'Svartinge',
    'Nasum',
    'Haesselby',
    'Vaxholm',
    'Holmsund',
    'Bjorkoby',
    'Kage',
    'Ursviken',
    'Burtraesk',
    'Burea',
    'Nassjo',
    'Mellbystrand',
    'Knared',
    'Finspang',
    'Vaennaes',
    'Ojebyn',
    'Hemmingsmark',
    'Tavelsjo',
    'Froso',
    'Tvaaker',
    'Kungsaengen',
    'Alvesta',
    'Karlsborg',
    'Molltorp',
    'Svenljunga',
    'Taftea',
    'Sjotofta',
    'Ed',
    'Fristad',
    'Dalstorp',
    'Sodra Sunderbyn',
    'Grebbestad',
    'Hofors',
    'Svanesund',
    'Rydboholm',
    'Faergelanda',
    'Jamshog',
    'Agunnaryd',
    'Kyrkobyn',
    'Glommen',
    'Kramfors',
    'Edsele',
    'Svardsjo',
    'Grycksbo',
    'Arjeplog',
    'Bygdea',
    'Robertsfors',
    'Bastad',
    'Lagan',
    'Valdemarsvik',
    'Sodra Sandby',
    'Alvangen',
    'Romelanda',
    'Norsholm',
    'Soederkoeping',
    'Garsnas',
    'Kivik',
    'Sankt Olof',
    'Hammenhog',
    'Tomelilla',
    'Vitaby',
    'Kopingebro',
    'Tenhult',
    'Lomma',
    'Vissefjarda',
    'Bralanda',
    'Mellerud',
    'Frandefors',
    'Ucklum',
    'Jorlanda',
    'Stora Hoga',
    'Nybro',
    'Jaemjoe',
    'Torsas',
    'Smalandsstenar',
    'Asarna',
    'Oviken',
    'Fjardhundra',
    'Amal',
    'Gusum',
    'Bunkeflostrand',
    'Markaryd',
    'Haparanda',
    'Vaxtorp',
    'Eldsberga',
    'Skummeslovsstrand',
    'Genevad',
    'Munka-Ljungby',
    'Grillby',
    'Paskallavik',
    'VÃ¤stervik',
    'Blentarp',
    'Borrby',
    'Brosarp',
    'Kimstad',
    'Stra',
    'Ostra Ryd',
    'Johannishus',
    'Kallinge',
    'Hovas',
    'Hammerdal',
    'Harbo',
    'OEstervala',
    'Tarnsjo',
    'Forserum',
    'Tallberg',
    'Kisa',
    'Anderstorp',
    'Svenstavik',
    'Ã…re',
    'Vittaryd',
    'Elloes',
    'Kiruna',
    'Lit',
    'Hyltebruk',
    'Kolmarden',
    'Billingsfors',
    'Perstorp',
    'Ashammar',
    'Ankarsvik',
    'Arvika',
    'Amotfors',
    'Klassbol',
    'Charlottenberg',
    'Guldsmedshyttan',
    'Gusselby',
    'Fellingsbro',
    'Borensberg',
    'Asensbruk',
    'Vinberg',
    'GÃ¤llivare',
    'Rosersberg',
    'Valskog',
    'Nashulta',
    'Harslov',
    'Alandsbro',
    'Vato',
    'Arholma',
    'Stravalla',
    'Kungsoer',
    'Rekarne',
    'Sunne',
    'Gullspang',
    'Timmersdala',
    'Skoldinge',
    'Svartsjo',
    'Malmkoping',
    'Halleforsnas',
    'Valla',
    'Aneby',
    'Ormaryd',
    'Flisby',
    'Malmback',
    'Grimstorp',
    'Hajom',
    'Horred',
    'Kinna',
    'Skee',
    'Moensteras',
    'Tygelsjo',
    'Glimakra',
    'Broby',
    'Killeberg',
    'Hastveda',
    'Tormestorp',
    'Finja',
    'Sosdala',
    'Vittsjo',
    'Ballingslov',
    'Hasslo',
    'Oxaback',
    'Viskafors',
    'Ljustero',
    'Haellefors',
    'Grythyttan',
    'Storfors',
    'Vintrosa',
    'Gyttorp',
    'Skaerhamn',
    'Edsbyn',
    'Alfta',
    'Kristdala',
    'Atvidaberg',
    'Vikingstad',
    'Nykil',
    'Saeffle',
    'Skinnskatteberg',
    'Hoernefors',
    'Saevar',
    'Hjaerup',
    'Kopparberg',
    'Alunda',
    'Osterbybruk',
    'Bankeryd',
    'Nora',
    'Malmberget',
    'Kvissleby',
    'Tanumshede',
    'Insjon',
    'Asbro',
    'Kolback',
    'Degeberga',
    'AElvdalen',
    'Vannasby',
    'Iggesund',
    'Skoghall',
    'Marma',
    'Nasviken',
    'Soderhamn',
    'Ljusne',
    'Siljansnas',
    'Djura',
    'Almunge',
    'Skultuna',
    'Loberod',
    'Storuman',
    'Haknas',
    'Enviken',
    'Stoby',
    'Bollstabruk',
    'Helgum',
    'Langsele',
    'Nyland',
    'Bjartra',
    'Kopmanholmen',
    'Mjallom',
    'Gidea',
    'Bjasta',
    'Langviksmon',
    'Overhornas',
    'Sjalevad',
    'Bjorna',
    'Husum',
    'Sidensjo',
    'Moliden',
    'Eksjoe',
    'Klintehamn',
    'Farnas',
    'Veddige',
    'Varing',
    'Ronnang',
    'Vara',
    'Orsundsbro',
    'Valbo',
    'Kalix',
    'Kil',
    'Ljungsbro',
    'Tungelsta',
    'Sjuntorp',
    'Frillesas',
    'Skogstorp',
    'Alunda',
    'Hallsberg',
    'Storvik',
    'Hede',
    'Sveg',
    'Sifferbo',
    'Mantorp',
    'Vislanda',
    'Mala',
    'Glommerstrask',
    'Bullmark',
    'Dalby',
    'Vreta Kloster',
    'Sturefors',
    'Hedesunda',
    'Norrsundet',
    'Ljusdal',
    'Farila',
    'Jaervsoe',
    'Tibro',
    'Fagersanna',
    'Ostra Karup',
    'Vejbystrand',
    'Vastra Karup',
    'Hoegsby',
    'Ekenassjon',
    'Nossebro',
    'Sollebrunn',
    'Stromsbruk',
    'Sorsele',
    'Vilhelmina',
    'Bjurholm',
    'Asele',
    'Garpenberg',
    'Ska',
    'Koping',
    'Skondal',
    'Hogsjo',
    'Vindeln',
    'Sandarne',
    'Hova',
    'Norsjoe',
    'Stensele',
    'Dorotea',
    'Dikanaes',
    'Nordmaling',
    'Lavsjo',
    'Botsmark',
    'Matfors',
    'Soderala',
    'Norrala',
    'Bergvik',
    'Stratjara',
    'Holmsveden',
    'Fjugesta',
    'Brakne-Hoby',
    'Backaryd',
    'Hammaro',
    'Gustavsberg',
    'Enebyberg',
    'Arjaeng',
    'Valberg',
    'Toere',
    'Norrahammar',
    'Vaggeryd',
    'Odensbacken',
    'Trekanten',
    'Dalsjofors',
    'Garphyttan',
    'Finnerodja',
    'Gronskara',
    'Gnarp',
    'Jarbo',
    'Indal',
    'Soraker',
    'Bramhult',
    'Bredared',
    'Ljung',
    'Seglora',
    'Unnaryd',
    'Rydobruk',
    'Gunnilse',
    'Rejmyre',
    'Klagshamn',
    'Gustafs',
    'Atorp',
    'Palange',
    'Alem',
    'Dala-Floda',
    'Genarp',
    'Bjoringe',
    'Storebro',
    'Langasjo',
    'Alsterbro',
    'Mockfjard',
    'Farlov',
    'Grangesberg',
    'Odakra',
    'Hok',
    'Saeter',
    'Vallda',
    'Figeholm',
    'Mansarp',
    'Enanger',
    'Hemse',
    'Emmaboda',
    'Slite',
    'Ostansjo',
    'Morlunda',
    'Filipstad',
    'Erikslund',
    'Fagelfors',
    'Atran',
    'Torsby',
    'Ryd',
    'Malung',
    'Balinge',
    'Transtrand',
    'Askersund',
    'Satila',
    'Eriksmala',
    'Linneryd',
    'Kinnarp',
    'Bjorkvik',
    'Bettna',
    'Hammarstrand',
    'Braecke',
    'Sjovik',
    'Tollered',
    'Dala-Jarna',
    'Vasterljung',
    'Gamleby',
    'Ostra Hindalebyn',
    'Kinnared',
    'Grisslehamn',
    'Hunnebostrand',
    'Bjorneborg',
    'Tystberga',
    'Stanga',
    'Drottningholm',
    'Vadstena',
    'Bjorklinge',
    'Overum',
    'Hacksvik',
    'Forsa',
    'Ostra',
    'Faro',
    'Stalldalen',
    'Gullbrandstorp',
    'Kvicksund',
    'Hallsta',
    'Lottorp',
    'Norberg',
    'Agnesberg',
    'Rostanga',
    'Skillingaryd',
    'Vretstorp',
    'Tranemo',
    'Backefors',
    'Ingelstad',
    'Kumla',
    'Uddebo',
    'Branno',
    'Borgholm',
    'Moerbylanga',
    'Riddarhyttan',
    'Nyhammar',
    'Rangedala',
    'Hultsfred',
    'Sandby',
    'Axvall',
    'OEverkalix',
    'Ranea',
    'Kvibille',
    'Lycke',
    'Uppharad',
    'Floby',
    'Askloster',
    'Hanaskog',
    'Ramvik',
    'Dals Langed',
    'Knutby',
    'Nygard',
    'Kungsgarden',
    'AElvsbyn',
    'Rodeby',
    'Vikmanshyttan',
    'Lerberget',
    'Norrfjarden',
    'Skruv',
    'Dio',
    'Glanshammar',
    'Stora Mellosa',
    'Malilla',
    'Larv',
    'Hedared',
    'Vegby',
    'Gards Kopinge',
    'Reftele',
    'Veberod',
    'Soederbaerke',
    'Skillinge',
    'Boxholm',
    'Lerdala',
    'Mellansel',
    'Hamburgsund',
    'Arnasvall',
    'Sodergard',
    'Ankarsrum',
    'Kosta',
    'AElmhult',
    'Havdhem',
    'Rosson',
    'Frufallan',
    'Langhem',
    'Aspered',
    'Styrso',
    'Hortlax',
    'Jarpen',
    'Vintrie',
    'Edshultshall',
    'Slutarp',
    'Raa',
    'Ramsele',
    'Vattholma',
    'Bjursas',
    'Stora Skedvi',
    'Frovi',
    'Ange',
    'Fransta',
    'Ljungaverk',
    'Torpshammar',
    'Kagerod',
    'Nyvang',
    'Saxtorp',
    'Mariannelund',
    'OEsterbymo',
    'Zinkgruvan',
    'Katthammarsvik',
    'OEvertornea',
    'Orrefors',
    'Maleras',
    'Bockara',
    'Linghem',
    'Lidhult',
    'Lovikka',
    'Soderakra',
    'Vassmolosa',
    'Stjarnhov',
    'Vase',
    'Fagervik',
    'Sorberge',
    'Overkovland',
    'Ljustorp',
    'Njurundabommen',
    'Duved',
    'Morsil',
    'Sloinge',
    'Ramnas',
    'Kolsva',
    'Virsbo Bruk',
    'Toecksfors',
    'Hovmantorp',
    'Grimslov',
    'Jarlasa',
    'Utansjo',
    'Sand',
    'Smoegen',
    'Grundsund',
    'Dingle',
    'Brastad',
    'Allerum',
    'Strovelstorp',
    'Vessigebro',
    'Kvidinge',
    'Vega',
    'Rolfstorp',
    'Sorombacken',
    'Lima',
    'Fredriksberg',
    'Ludvigsborg',
    'Dosjebro',
    'Kvanum',
    'Lodose',
    'Nyhamnslage',
    'Joern',
    'Gallo',
    'Follinge',
    'Stroemsund',
    'Pilgrimstad',
    'Skarplinge',
    'Soderfors',
    'Mankarbo',
    'Aplared',
    'Svaneholm',
    'Ganghester',
    'Mullhyttan',
    'Skollersta',
    'Harads',
    'Stode',
    'Skelleftehamn',
    'Ljungbyhed',
    'Skanes Fagerhult',
    'Traryd',
    'Vedum',
    'Limmared',
    'Yngsjo',
    'Varsas',
    'Bjuraker',
    'Sundsbruk',
    'Garsas',
    'Osterskar',
    'Bottnaryd',
    'Vikarbyn',
    'Vartofta',
    'Svensbyn',
    'Sundborn',
    'Lindas',
    'Ugglarp',
    'Larbro',
    'Aspas',
    'Valadalen',
    'Anderstorp',
    'Hillerstorp',
    'Ostra Frolunda',
    'Norrhult',
    'Karesuando',
    'Junosuando',
    'Lannavaara',
    'Stigen',
    'Nissafors',
    'Vastra Torup',
    'Simlangsdalen',
    'Knislinge',
    'Klagerup',
    'Stehag',
    'Rydsgard',
    'Skivarp',
    'Musko',
    'Taljo',
    'Hallabro',
    'Karna',
    'Grasmyr',
    'Roback',
    'Kattilstorp',
    'Backe',
    'Myresjo',
    'Sandhem',
    'Donso',
    'Ljungbyholm',
    'Vallakra',
    'Arla',
    'Skyllberg',
    'Ringarum',
    'Moheda',
    'Pajala',
    'Djuras',
    'Korpilombolo',
    'Soderby',
    'Orno',
    'Sandhult',
    'Tingstade',
    'Forsbacka',
    'Blattnicksele',
    'Blasmark',
    'Taenndalen',
    'Brokind',
    'Graso',
    'Marieholm',
    'Marstrand',
    'Halta',
    'Skane',
    'Vollsjo',
    'Sovde',
    'Grangarde',
    'Ulrika',
    'Almeboda',
    'Korsberga',
    'Hillared',
    'Stigtomta',
    'Hjalteby',
    'Fagered',
    'Follinge',
    'Pixbo',
    'Hindas',
    'Gagnef',
    'Sagmyra',
    'Ravlanda',
    'Kulla',
    'Furuvik',
    'Arvidsjaur',
    'Fors',
    'Alsen',
    'Glostorp',
    'Korskrogen',
    'Ramsjo',
    'Tallasen',
    'Los',
    'Stugun',
    'Virserum',
    'Holmsjo',
    'Sommen',
    'Horn',
    'Rimforsa',
    'Borghamn',
    'Holsbybrunn',
    'Tolg',
    'Hammar',
    'Flyinge',
    'Taernaby',
    'Hokerum',
    'Grimsas',
    'Holsljunga',
    'Olsfors',
    'AElvkarleby',
    'Asmundtorp',
    'Gallstad',
    'Sunnemo',
    'Lessebo',
    'Hasselfors',
    'Hallekis',
    'Gota',
    'Odeborg',
    'Glumslov',
    'Stora Vika',
    'Bergkvara',
    'Eneryda',
    'Klavrestrom',
    'Lammhult',
    'Aryd',
    'Ryssby',
    'Vittaryd',
    'Svenshogen',
    'Morup',
    'Timmele',
    'Onnestad',
    'Stromsholm',
    'Malma',
    'Timmernabben',
    'Berga',
    'Hasslarp',
    'Tun',
    'Stromsnasbruk',
    'Navekvarn',
    'Boliden',
    'Osterbybruk',
    'Lenhovda',
    'Lonsboda',
    'Aspero',
    'As',
    'Fengersfors',
    'Hoviksnas',
    'Moholm',
    'Lysvik',
    'Fageras',
    'Skattkarr',
    'Dyltabruk',
    'Vackelsang',
    'Urshult',
    'Konga',
    'Sodra Vi',
    'Braas',
    'Vrena',
    'Fallfors',
    'Trehorningsjo',
    'Arsunda',
    'Undersaker',
    'Loderup',
    'Blackstad',
    'Hamneda',
    'Kallby',
    'Njutanger',
    'Gryt',
    'Lekeryd',
    'Vastra Amtervik',
    'Ovanaker',
    'Viksjofors',
    'Jonaker',
    'Bredaryd',
    'Rydaholm',
    'Bjorbo',
    'Orviken',
    'Asenhoga',
    'Kulltorp',
    'Byske',
    'Hogsaters',
    'Lovanger',
    'Billinge',
    'Rundvik',
    'Lovestad',
    'Bofors',
    'Sparsor',
    'Traslovslage',
    'Anderslov',
    'Alstermo',
    'Grondal',
    'Fagerhult',
    'Nyland',
    'Lugnvik',
    'Kristineberg',
    'Skaraborg',
    'Varmland',
    'Fleninge',
    'Jude',
    'Bua',
    'Halland',
    'Grimeton',
    'Ansvar',
    'Hagglund',
    'Fiskeby',
    'Storlien',
    'Ingarp',
    'Talje',
    'Holmen',
    'Fagerberg',
    'Travad',
    'Otterbacken',
    'Gamla stan',
    'Hackas',
    'Altersbruk',
    'Barkarby',
    'Hakkas',
    'Bjorkhaga',
    'Torekov',
    'Vaeja',
    'Traslovslage',
    'Farna',
    'Brunnby',
    'Ljungsarp',
    'Lillpite',
    'Nordingra',
    'Hyssna',
    'Gargnas',
    'Hallberg',
    'Rinkaby',
    'Hestra',
    'Svarta',
    'Beddinge Lage',
    'Hjartum',
    'Landfjarden',
    'Bor',
    'Forsheda',
    'Spekerod',
    'Sandared',
    'Landeryd',
    'Skyttorp',
    'Landsbro',
    'Rorvik',
    'Stockaryd',
    'Marieholm',
    'Horndal',
    'Rosvik',
    'Farbo',
    'Kallo',
    'Vaderstad',
    'Saevast',
    'Hallestad',
    'Annelov',
    'Huarod',
    'Arkelstorp',
    'Roke',
    'Tjornarp',
    'Hallarod',
    'Nykroppa',
    'Koppom',
    'Hamrangefjarden',
    'Segmon',
    'Hallestad',
    'Ring',
    'Ruda',
    'Karl Gustav',
    'Herrangen',
    'Annerstad',
    'Palsboda',
    'Varnhem',
    'Ljugarn',
    'Vastervik',
    'Barsebackshamn',
    'Myggenas',
    'Svaneholm',
    'Lotorp',
    'Ljusfallshammar',
    'Gunnebo',
    'Sunnansjo',
    'Kladesholmen',
    'Klovedal',
    'Nalden',
    'Koskullskulle',
  ],
  tld: ['.se'],
  cca2: 'SE',
  ccn3: '752',
  cca3: 'SWE',
  cioc: 'SWE',
  currency: ['SEK'],
  callingCode: ['46'],
  capital: 'Stockholm',
  altSpellings: ['SE', 'Kingdom of Sweden', 'Konungariket Sverige'],
  region: 'Europe',
  subregion: 'Northern Europe',
  languages: { swe: 'Swedish' },
  translations: {
    deu: { official: 'Königreich Schweden', common: 'Schweden' },
    fra: { official: 'Royaume de Suède', common: 'Suède' },
    hrv: { official: 'Kraljevina Švedska', common: 'Švedska' },
    ita: { official: 'Regno di Svezia', common: 'Svezia' },
    jpn: { official: 'スウェーデン王国', common: 'スウェーデン' },
    nld: { official: 'Koninkrijk Zweden', common: 'Zweden' },
    por: { official: 'Reino da Suécia', common: 'Suécia' },
    rus: { official: 'Королевство Швеция', common: 'Швеция' },
    spa: { official: 'Reino de Suecia', common: 'Suecia' },
    fin: { official: 'Ruotsin kuningaskunta', common: 'Ruotsi' },
  },
  latlng: [62, 15],
  demonym: 'Swedish',
  landlocked: false,
  borders: ['FIN', 'NOR'],
  area: 450295,
  provinces: [
    'Blekinge',
    'Dalarnas',
    'Gavleborgs',
    'Gotlands',
    'Hallands',
    'Jamtlands',
    'Jonkopings',
    'Kalmar',
    'Kronobergs',
    'Norrbottens',
    'Orebro',
    'Ostergotlands',
    'Skane',
    'Sodermanlands',
    'Stockholms',
    'Uppsala',
    'Varmlands',
    'Vasterbottens',
    'Vasternorrlands',
    'Vastmanlands',
    'Vastra Gotalands',
  ],
  nativeName: 'Sverige',
  timezones: ['UTC+01:00'],
} as const;
