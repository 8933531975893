export const KEN = {
  name: {
    common: 'Kenya',
    official: 'Republic of Kenya',
    native: {
      eng: { official: 'Republic of Kenya', common: 'Kenya' },
      swa: { official: 'Republic of Kenya', common: 'Kenya' },
    },
  },
  cities: [
    'Nairobi',
    'Mombasa',
    'Kisumu',
    'Machakos',
    'Thika',
    'Eldoret',
    'Kitui',
    'Kabete',
    'Wote',
    'Kiambu',
    'Nakuru',
    'Kaiboi',
    'Kitale',
    'Kisii',
    'Bondo',
    'Siaya',
    'Chuka',
    'Karatina',
    'Maseno',
    'Kikuyu',
    'Mbita',
    'Sawa Sawa',
    'Turbo',
  ],
  tld: ['.ke'],
  cca2: 'KE',
  ccn3: '404',
  cca3: 'KEN',
  cioc: 'KEN',
  currency: ['KES'],
  callingCode: ['254'],
  capital: 'Nairobi',
  altSpellings: ['KE', 'Republic of Kenya', 'Jamhuri ya Kenya'],
  region: 'Africa',
  subregion: 'Eastern Africa',
  languages: { eng: 'English', swa: 'Swahili' },
  translations: {
    deu: { official: 'Republik Kenia', common: 'Kenia' },
    fra: { official: 'République du Kenya', common: 'Kenya' },
    hrv: { official: 'Republika Kenija', common: 'Kenija' },
    ita: { official: 'Repubblica del Kenya', common: 'Kenya' },
    jpn: { official: 'ケニア共和国', common: 'ケニア' },
    nld: { official: 'Republiek Kenia', common: 'Kenia' },
    por: { official: 'República do Quénia', common: 'Quénia' },
    rus: { official: 'Республика Кения', common: 'Кения' },
    spa: { official: 'República de Kenya', common: 'Kenia' },
    fin: { official: 'Kenian tasavalta', common: 'Kenia' },
  },
  latlng: [1, 38],
  demonym: 'Kenyan',
  landlocked: false,
  borders: ['ETH', 'SOM', 'SSD', 'TZA', 'UGA'],
  area: 580367,
  provinces: [
    'Central',
    'Coast',
    'Eastern',
    'Nairobi Area',
    'North Eastern',
    'Nyanza',
    'Rift Valley',
    'Western',
  ],
  nativeName: 'Kenya',
  timezones: ['UTC+03:00'],
} as const;
