import * as data from './modules';

/**
 * ...
 */
export interface CountryData {
  name: CountryData.NameInfo;
  cities?: string[];
  nativeName?: string;
  provinces?: string[];
  tld: string[];
  cca2: string;
  ccn3: string;
  cca3: string;
  cioc: string;
  currency: string[];
  callingCode: string[];
  capital: string;
  altSpellings: string[];
  region: string;
  subregion: string;
  languages: CountryData.LanguageInfo;
  translations: CountryData.TranslationInfo;
  latlng: number[];
  demonym: string;
  landlocked: boolean;
  borders: string[];
  area: number;
  timezones?: string[];
}

export namespace CountryData {
  /** ... */
  interface ValueVariants {
    common: string;
    official: string;
  }

  /** ... */
  export interface NameInfo extends ValueVariants {
    native: Record<string, ValueVariants>;
  }

  /** ... */
  export type LanguageInfo = Record<string, string>;

  /** ... */
  export type TranslationInfo = Record<string, ValueVariants>;
}

/** ... */
export type CCA3 = keyof typeof data;
/** ... */
export type CCA2 = typeof data[CCA3]['cca2'];
/** ... */
export type CountryCode = CCA3 | CCA2;

/** ... */
export const COUNTRY_DATA = data as unknown as Record<string, CountryData>;
