var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "form-field-input" },
    [
      _c(
        "FormInput",
        _vm._g(
          _vm._b(
            {
              model: {
                value: _vm.field.$model,
                callback: function ($$v) {
                  _vm.$set(_vm.field, "$model", $$v)
                },
                expression: "field.$model",
              },
            },
            "FormInput",
            {
              ..._vm.$attrs,
              type: _vm.type,
              state: _vm.state,
              label: _vm.label,
            },
            false
          ),
          _vm.$listeners
        )
      ),
      _vm._v(" "),
      _vm.showErrorMessage
        ? _c("b-form-invalid-feedback", { attrs: { state: false } }, [
            _vm._v("\n    " + _vm._s(_vm.errorMessage) + "\n  "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }