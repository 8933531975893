var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      class: [
        "modal-body",
        {
          processing: _vm.processing,
          "no-padding": _vm.noPadding,
          "no-scroll": _vm.noScroll,
        },
      ],
    },
    [
      _vm.processing
        ? _c(
            _setup.Overlay,
            { attrs: { background: "transparent" } },
            [
              !_vm.success && !_vm.errored
                ? _c(_setup.Spinner, { attrs: { size: 3, unit: "rem" } })
                : _vm._e(),
              _vm._v(" "),
              _vm.success ? _c(_setup.AnimatedSuccess) : _vm._e(),
              _vm._v(" "),
              _vm.errored ? _c(_setup.AnimatedError) : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-skeleton-wrapper",
        _vm._b(
          {
            scopedSlots: _vm._u([
              {
                key: "loading",
                fn: function () {
                  return [
                    _c("b-skeleton", { attrs: { width: "85%" } }),
                    _vm._v(" "),
                    _c("b-skeleton", { attrs: { width: "55%" } }),
                    _vm._v(" "),
                    _c("b-skeleton", { attrs: { width: "70%" } }),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("b-skeleton", { attrs: { width: "45%" } }),
                    _vm._v(" "),
                    _c("b-skeleton", { attrs: { width: "25%" } }),
                    _vm._v(" "),
                    _c("b-skeleton", { attrs: { width: "90%" } }),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("b-skeleton", { attrs: { width: "25%" } }),
                    _vm._v(" "),
                    _c("b-skeleton", { attrs: { width: "65%" } }),
                    _vm._v(" "),
                    _c("b-skeleton", { attrs: { width: "40%" } }),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "b-skeleton-wrapper",
          { loading: !!_vm.loading },
          false
        ),
        [
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-body-content" },
            [_vm._t("default")],
            2
          ),
          _vm._v(" "),
          _vm._t("secondary-content"),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }