export const SWZ = {
  name: {
    common: 'Swaziland',
    official: 'Kingdom of Swaziland',
    native: {
      eng: { official: 'Kingdom of Swaziland', common: 'Swaziland' },
      ssw: { official: 'Kingdom of Swaziland', common: 'Swaziland' },
    },
  },
  cities: ['Mbabane', 'Piggs Peak', 'Lobamba', 'Kwaluseni', 'Manzini'],
  tld: ['.sz'],
  cca2: 'SZ',
  ccn3: '748',
  cca3: 'SWZ',
  cioc: 'SWZ',
  currency: ['SZL'],
  callingCode: ['268'],
  capital: 'Lobamba',
  altSpellings: [
    'SZ',
    'weSwatini',
    'Swatini',
    'Ngwane',
    'Kingdom of Swaziland',
    'Umbuso waseSwatini',
  ],
  region: 'Africa',
  subregion: 'Southern Africa',
  languages: { eng: 'English', ssw: 'Swazi' },
  translations: {
    deu: { official: 'Königreich Swasiland', common: 'Swasiland' },
    fra: { official: 'Royaume du Swaziland', common: 'Swaziland' },
    hrv: { official: 'Kraljevina Svazi', common: 'Svazi' },
    ita: { official: 'Regno dello Swaziland', common: 'Swaziland' },
    jpn: { official: 'スワジランド王国', common: 'スワジランド' },
    nld: { official: 'Koninkrijk Swaziland', common: 'Swaziland' },
    por: { official: 'Reino da Suazilândia', common: 'Suazilândia' },
    rus: { official: 'Королевство Свазиленд', common: 'Свазиленд' },
    spa: { official: 'Reino de Swazilandia', common: 'Suazilandia' },
    fin: { official: 'Swazimaan kuningaskunta', common: 'Swazimaa' },
  },
  latlng: [-26.5, 31.5],
  demonym: 'Swazi',
  landlocked: true,
  borders: ['MOZ', 'ZAF'],
  area: 17364,
  provinces: ['Hhohho', 'Lubombo', 'Manzini', 'Shiselweni'],
  nativeName: 'Swaziland',
  timezones: ['UTC+02:00'],
} as const;
