export const RUS = {
  name: {
    common: 'Russia',
    official: 'Russian Federation',
    native: { rus: { official: 'Русская Федерация', common: 'Россия' } },
  },
  cities: [
    'Moscow',
    'Omsk',
    'Peschanka',
    'Novovarshavka',
    'Luzino',
    "Mar'yanovskiy",
    'Alekseyevka',
    'Abakan',
    'Sayanogorsk',
    'Chernogorsk',
    'Borodino',
    'Beya',
    'Volgograd',
    'Rostov-on-Don',
    "Stavropol'skiy Rayon",
    'Smolensk',
    'Kursk',
    'Astrakhan',
    'Ulyanovsk',
    'Rybinsk',
    'Lipetsk',
    'Dimitrovgrad',
    'Kostroma',
    'Kaluga',
    'Kaliningrad',
    'Tolyatti',
    'Sterlitamak',
    'Kemerovo',
    'Chelyabinsk',
    'Krasnodar',
    'Severodvinsk',
    'Samara',
    'Beloretsk',
    'Voronezh',
    'Ufa',
    'Samara',
    'Cherepovets',
    'Tyumen',
    'Saint Petersburg',
    'Shebekino',
    'Elektrostal',
    'Khabarovsk',
    'Novosibirsk',
    'Ryazan',
    'Avtozavodskiy Rayon',
    'Khimki',
    'Korolev',
    'Pushkino',
    'Mytishchi',
    'Kubinka',
    'Kolomna',
    "Novoural'sk",
    'Yekaterinburg',
    "Severoural'sk",
    'Syktyvkar',
    "Kamensk-Ural'skiy",
    'Ulan-Ude',
    'Barnaul',
    'Surgut',
    'Kushva',
    'Liski',
    'Zlatoust',
    'Miass',
    "Yuzhnoural'sk",
    'Vladivostok',
    'Nizhnevartovsk',
    'Zvezda',
    'Shatura',
    'Chik',
    'Iskitim',
    'Linevo',
    'Kuybyshev',
    "Yegor'yevka",
    'Pyatigorsk',
    'Stavropol',
    'Yessentuki',
    'BudÃ«nnovsk',
    'Georgiyevsk',
    'Vinsady',
    'Kislovodsk',
    'Georgiyevskoye',
    'Sochi',
    'Labinsk',
    'Zarechenskiy',
    'Bataysk',
    'Taganrog',
    'Novocherkassk',
    'Zolotarevka',
    'Novorossiysk',
    'Svetlyy',
    'Mostovskoy',
    'Chyorny Yar',
    'Cherkessk',
    'Sovetskiy',
    'Megion',
    'Tarko-Sale',
    'Urengoy',
    'Perm',
    'Revda',
    'Alapayevsk',
    'Kurgan',
    'Gubkinskiy',
    "Lys'va",
    'Pangody',
    'Nefteyugansk',
    'Salekhard',
    'Nyagan',
    'Verkhnyaya Salda',
    "Sysert'",
    "Nev'yansk",
    'Aykino',
    'Ukhta',
    'Kotlas',
    'Arkhangelsk',
    'Chernyakhovsk',
    'Sovetsk',
    'Murmansk',
    'Severomorsk',
    'Murmashi',
    'Kandalaksha',
    'Anuchino',
    'Ussuriysk',
    'Nakhodka',
    'Yekaterinovka',
    'Sergeyevka',
    'Novoshakhtinskiy',
    "Arsen'yev",
    "Spassk-Dal'niy",
    'ArtÃ«m',
    'Belgorod',
    'Tver',
    'Sputnik',
    'Vologda',
    'Sheksna',
    'Vsevolozhsk',
    'Bryansk',
    'Volgodonsk',
    'Pavlovsk',
    "Sevastopol'",
    'Cheboksary',
    'Taushkasy',
    'Veliky Novgorod',
    'Krasnoyarsk',
    'Yaroslavl',
    'Posad',
    'Tula',
    'Kurgan',
    'Magnitogorsk',
    'Samara',
    'Samsonovka',
    "Syzran'",
    'Nizhniy Novgorod',
    'Bor',
    'Izhevsk',
    'Irkutsk',
    'Kalashnikovo',
    'Penza',
    'Solnechnogorsk',
    'Saratov',
    'Tomsk',
    'Seversk',
    'Ufa',
    'Angarsk',
    'Yoshkar-Ola',
    'Bibayevo',
    'Pervouralsk',
    'Vladikavkaz',
    'Novovoronezh',
    "Rossosh'",
    'Baranchinskiy',
    'Domodedovo',
    'Lytkarino',
    'Podolsk',
    'Pskov',
    "Usol'ye-Sibirskoye",
    'Kushnarenkovo',
    'Iglino',
    'Dyurtyuli',
    'Yaratovo',
    'Tuymazy',
    'Meleuz',
    'Belebey',
    'Ramenskoye',
    'Noginsk',
    'Moskovskiy',
    'Anapa',
    'Zhukovskiy',
    'Azov',
    'Krasnyy Sulin',
    'Zernograd',
    'Rodionovka',
    'Kamensk-Shakhtinskiy',
    'Donetsk',
    'Shakhty',
    'Kurgan',
    'Tagil',
    'Serpukhov',
    'Krasnoufimsk',
    'Lesnoy',
    'Lesnoy',
    'Vladimir',
    'Sobinka',
    'Lakinsk',
    'Arzamas',
    'Balakovo',
    "Engel's",
    'Chkalov',
    'Novosokolovogorskiy',
    'Volga',
    'Kuznetsk',
    'Druzhba',
    "Nal'chik",
    'Glazov',
    'Maykop',
    'Novaya Silava',
    'Bratsk',
    'Pivovar',
    'Konakovo',
    'Biysk',
    'Rybnoye',
    'Shelekhov',
    'Lisikha',
    'Baykal',
    'Volzhskiy',
    'Apatity',
    'Kirovsk',
    "Khot'kovo",
    'Tuapse',
    'Pokrov',
    'Nevinnomyssk',
    'Polevskoy',
    'Unecha',
    'Kingisepp',
    'Chita',
    "Gus'-Khrustal'nyy",
    'Tikhvin',
    "Severobaykal'sk",
    'Novokruchininskiy',
    'Novokuznetsk',
    'Kuznetskiy Rayon',
    'Maloyaroslavets',
    'Blagoveshchensk',
    "Alatyr'",
    'Zavodoukovsk',
    'Kungur',
    'Berezniki',
    'Okhansk',
    'Kachkanar',
    'Irbit',
    'Asha',
    "Chebarkul'",
    'Borovskiy',
    'Ishim',
    'Uktuz',
    'Yalutorovsk',
    'Uray',
    'Novy Urengoy',
    'Krasnokamsk',
    'Nytva',
    'Khanty-Mansiysk',
    'Kstovo',
    'Yurga',
    'Betlitsa',
    "Mosal'sk",
    'Kamyshin',
    'Nikolayevsk',
    'Borodynovka',
    'Kurganinsk',
    'Kanevskaya',
    'Starovelichkovskaya',
    "Gul'kevichi",
    'Uspenskoye',
    'Abinsk',
    'Slavyansk-na-Kubani',
    'Armavir',
    'Chelbasskaya',
    'Yeysk',
    'MikhnÃ«vo',
    'Vyksa',
    'Novodmitriyevka',
    'Zheleznogorsk',
    'Dzerzhinsk',
    'Saransk',
    'Start',
    "Bol'shoye",
    'Kolpino',
    'Pushkin',
    'Borisoglebsk',
    'Chekhov',
    'Protvino',
    'Klimovsk',
    'Bilibino',
    'Khrenovoye',
    'Volga',
    'Tayga',
    'KiselÃ«vsk',
    'Sevsk',
    'Kopeysk',
    'Voskresenskoye',
    'Tambov',
    'Uvarovo',
    'TimashÃ«vsk',
    'Gelendzhik',
    'Belorechensk',
    'Dinskaya',
    'Krymsk',
    'Brod',
    'Noyabrsk',
    'Labytnangi',
    'Nadym',
    "Tobol'sk",
    'Stary Oskol',
    'Orenburg',
    'Sosnovoborsk',
    'Kirov',
    'Kazanâ€™',
    'Sibay',
    'Ishkulovo',
    'Birsk',
    'Novobelokatay',
    "Karaidel'",
    'Aramil',
    'Mezhdurechensk',
    'Berdsk',
    'Ural',
    'Solikamsk',
    'Artem',
    'Novocheboksarsk',
    'Novokuybyshevsk',
    'Novosemeykino',
    'Vyshniy VolochÃ«k',
    'Belogorsk',
    'Makhachkala',
    "Kuban'",
    'Nizhniy Tagil',
    'Liniya',
    'Zheleznogorsk',
    'Zheleznovodsk',
    'Kodinsk',
    'Krasnogorsk',
    'Lomonosov',
    'Petergof',
    'Zarechnyy',
    "Sovetskaya Gavan'",
    'Odintsovo',
    'Putilkovo',
    'Veshki',
    'Strezhevoy',
    'Zelenograd',
    'Rassvet',
    'Kamenolomni',
    'Selo',
    'Gukovo',
    'Krasnoye-na-Volge',
    "Kazan'",
    'Alikovo',
    'Zelenodolsk',
    'Achinsk',
    'Fryazino',
    'Vega',
    'Snezhinsk',
    'Lesozavodsk',
    'Priozersk',
    'Roshchino',
    'Yakutsk',
    'Novozolotovskaya',
    "Bol'shiye Saly",
    'Orlovskiy',
    'Persianovka',
    'Rodionovo-Nesvetayskaya',
    'Chaykovskiy',
    'Kurchatov',
    'Novozavedennoye',
    'Belgorod',
    'OrÃ«l',
    'Kromy',
    'Kamyshovka',
    'Fatezh',
    'Nizhnekamsk',
    'Dmitrov',
    'Aleksandrov',
    'Sarapul',
    'Khabarskoye',
    'Chiverevo',
    'Pioner',
    'Tikhoretsk',
    'Kropotkin',
    'Leningradskaya',
    'Slavyanka',
    'Parkovyy',
    "Ust'-Labinsk",
    "Kirpil'skaya",
    'Pavlovskaya',
    'Novokorsunskaya',
    'Lesodacha',
    'Kalininskaya',
    'Moldavanskoye',
    'Leninskiy',
    'Staronizhestebliyevskaya',
    'Ivanovo',
    'Rubtsovsk',
    'Snezhinskiy',
    'Serov',
    'Verkhnyaya Pyshma',
    'Svetlyy',
    'Zelenogorsk',
    'Lesosibirsk',
    'Myski',
    'Nova',
    'Zvenigorod',
    'Kaltan',
    'Kristall',
    'Balashov',
    'Vyborg',
    'Gusev',
    'Neftekamsk',
    'Ishimbay',
    'Volga',
    'Salavat',
    'Orsk',
    'Kovrov',
    'Zaraysk',
    'Bashkortostan',
    'Kineshma',
    'Novoaltaysk',
    'Leninskoye',
    'Ruza',
    'Naro-Fominsk',
    'Postnikova',
    'Filial',
    'Asbest',
    'Rubin',
    "Ust'-Ilimsk",
    'Vyatka',
    'Furmanov',
    "Vspomogatel'nyy",
    'Tynda',
    'Kholmsk',
    'Tuchkovo',
    'Skovorodino',
    'Konosha',
    'Istra',
    'Chernogolovka',
    'Kupavna',
    'Vorovskogo',
    'Sharapova Okhota',
    'Sukhanovo',
    'Kosteniki',
    'Amur',
    'Dubna',
    'Komintern',
    'Svetlograd',
    'Lenina',
    'Obninsk',
    "Del'ta",
    'Staraya Russa',
    'Techa',
    'Patrushi',
    'Pushchino',
    'Sosnogorsk',
    'Nartkala',
    'Zhukovskogo',
    "Arkhangel'skoye",
    'Kanavka',
    'Dudinka',
    'Zheleznodorozhnyy',
    'Petropavlovsk-Kamchatsky',
    'Partizansk',
    'Spassk',
    "Dal'negorsk",
    'Chernigovka',
    "Primor'ye",
    "Bol'shoy Kamen'",
    'Barabash',
    "Bol'shak",
    "Koz'modem'yansk",
    'Zvenigovo',
    'Volzhsk',
    'Lobnya',
    'OzÃ«ry',
    'Klin',
    'Reutov',
    'Orekhovo-Zuyevo',
    'Elektrougli',
    'Losino-Petrovskiy',
    'Bronnitsy',
    'Rostov',
    "Gran'",
    'Tarusa',
    'Agidel',
    'Kamchatka',
    'Petropavlovka',
    'Yelizovo',
    'Nazran',
    'Volkhonshchino',
    'Nauka',
    'Pavlovskiy Posad',
    'Rozhdestvenka',
    'Klintsy',
    'Manturovo',
    'Oshib',
    'Ishley',
    'Zavodouspenskoye',
    "Ust'-Kut",
    'Zima',
    'Balabanovo',
    'Borovichi',
    'Gatchina',
    'Kolomino',
    'Romanovka',
    'Marks',
    'Mozhga',
    'Snezhnogorsk',
    'Nikel',
    'Petrozavodsk',
    "Nikol'skoye",
    'Vorkuta',
    'Usinsk',
    'Okulovka',
    'Chudovo',
    'Svetlogorsk',
    'Velikiye Luki',
    'Pryazha',
    'Morozovo',
    'Naryan-Mar',
    'Koryazhma',
    'Sertolovo',
    'Votkinsk',
    'Kvatchi',
    'Pychas',
    "Kush'ya",
    'Kizner',
    'Bras',
    'Igra',
    'Gorno-Altaysk',
    'Temryuk',
    'Morshansk',
    'Kirsanov',
    'Dmitriyevka',
    "Bugul'ma",
    'Metallostroy',
    'Yekaterinoslavka',
    'Svobodnyy',
    'Arkhara',
    'Seryshevo',
    'Belogorka',
    'Zeya',
    'Magdagachi',
    'Raychikhinsk',
    'Vysokovsk',
    'Nadezhdinka',
    'Neryungri',
    'Aldan',
    'Ibresi',
    'Magnitnyy',
    'Agapovka',
    'Novator',
    'Bologoye',
    'Redkino',
    'Kirishi',
    'Mamontovka',
    'Lyubertsy',
    'Kirovgrad',
    "Kotel'niki",
    'Slantsy',
    "Stroitel'",
    'Berezayka',
    'Teykovo',
    "Noril'sk",
    'Glebychevo',
    'Kirzhach',
    'Yuzhno-Sakhalinsk',
    'Pugachev',
    'Otradnyy',
    'Chapayevsk',
    'Zhigulevsk',
    'Lukhovitsy',
    'Sokol',
    'Gagarin',
    'Komsomolsk-on-Amur',
    'Zelenogorsk',
    'Leningradskiy',
    'Piter',
    'Sarov',
    'Vitebskaya',
    'Sloboda',
    'Chernushka',
    'Dolgoprudnyy',
    'Izmaylovo',
    'Vichuga',
    "Ural'skaya",
    'Derbent',
    'Khasavyurt',
    'Malaya Vishera',
    'Podstepki',
    'Magadan',
    'Detchino',
    'Grozny',
    'Staraya Kupavna',
    'Satis',
    'Pskovskoye',
    'Krasnoarmeyskaya',
    'Pervo',
    'Torzhok',
    'Smolenskaya',
    'Kirova',
    'Stroiteley',
    'Altayskoye',
    'Kabardino',
    'Vladykino',
    'Nakhabino',
    'Vnukovo',
    'Kyshtym',
    'Trekhgornyy',
    'Melenki',
    'Lebedev',
    'Sofrino',
    'Tyrma',
    'Borzya',
    'Safonovo',
    'Nizhneudinsk',
    "Ust'-Katav",
    'Elista',
    'Barnaul',
    "Al'met'yevsk",
    'Yelabuga',
    "Chistopol'",
    'Skala',
    'Sharnutovskiy',
    'Nelidovo',
    'Lesnoy Gorodok',
    'Odintsovo',
    'Tomilino',
    'Metallurg',
    'Koptyuga',
    'Markovo',
    'Urgal',
    'Rzhev',
    'Desnogorsk',
    'Krasnoznamensk',
    'Uglich',
    'Kronshtadt',
    'Pargolovo',
    'Sergiyevskoye',
    'Morozov',
    'Mayakovskogo',
    'Ogarev',
    'Lenkino',
    'Gogolya',
    'Prokhladnyy',
    'Baksan',
    'Akhtubinsk',
    'Krasnyy Yar',
    'Frolovo',
    'Rovnoye',
    'Sorochinsk',
    'Ariadnoye',
    'Bovykiny',
    'Chemashur',
    'Lazo',
    'Altay',
    'Shebalino',
    'Millerovo',
    'Zubovo',
    'Nikolayevsk-on-Amur',
    'Birobidzhan',
    'Amursk',
    'Moskovskaya',
    'Rossiyskiy',
    'Mikhaylovskoye',
    'Rodniki',
    'Khangash-Yurt',
    "Kinel'",
    'Volzhskiy',
    'Pervomayskaya',
    'Progress',
    'Sary',
    'Aksenovo',
    'Kadrovik',
    'Korkino',
    'Avangard',
    'Gubakha',
    'Chastyye',
    'Minusinsk',
    'Potok',
    'Vidnoye',
    'Studenets',
    'Sistema',
    'Mariinskiy Posad',
    'Kanash',
    'China',
    'Olimpiyskiy',
    'Neman',
    'Vagay',
    'Nizhegorodskaya',
    'Kraskovo',
    'Verkhnekazymskiy',
    'Soldatskoye',
    'Sofrino',
    'Sviblovo',
    'Krasnoarmeysk',
    'Sergiyev Posad',
    'Bugry',
    'Ugra',
    'Mira',
    'Kartaly',
    'Novotroitsk',
    'Mednogorsk',
    'Troitsk',
    'Sartykov',
    'Sosnovo',
    'ChÃ«rnaya Kholunitsa',
    'Vostochnyy',
    'Karin Perevoz',
    'Vostochnaya',
    'Yelanskiy',
    'Lyantor',
    'Sverdlovskaya',
    'Miasskoye',
    'Krasnokamensk',
    "Shtol'nyy",
    'Novomoskovsk',
    'Rakita',
    'Glazunovka',
    "Severo-Kuril'sk",
    'Uglegorsk',
    'Komarov',
    'Slyudyanka',
    'Cheremkhovo',
    'Isheyevka',
    'Povarovo',
    "Bad'-Pashnya",
    'Voskresensk',
    'Denisovo',
    'Dzerzhinskiy',
    'Radist',
    'Ilya',
    "Osel'ki",
    'Novoye Devyatkino',
    'Klyuchi',
    'Zaprudnya',
    'Sosnovyy Bor',
    'Karabulak',
    'Izberbash',
    'Dedovsk',
    'Satka',
    "Yel'niki",
    'Drezna',
    'Damkhurts',
    'Dagomys',
    'Golitsyno',
    'Kavkaz',
    'Novyy',
    'Gorodna',
    'Purovsk',
    'Orshanka',
    'Atlas',
    'Nazarovo',
    'Kholmistyy',
    'Buzuluk',
    'Gudermes',
    'Kulikov',
    'Ovchinnikov',
    'Murom',
    'Verkhniy Ufaley',
    "Al'fa",
    'Kansk',
    'Ilim',
    "L'vovskiy",
    'Novomichurinsk',
    "Uren'",
    'Stupino',
    'Kamennomostskiy',
    'Novaya Igirma',
    "Mebel'naya Fabrika",
    'Poronaysk',
    'Severo-Zapad',
    "Krasnotur'insk",
    'Uryupinsk',
    'Krasnogorskiy',
    'Kugesi',
    'Uyar',
    'Sayansk',
    'Lada',
    'Perepravnaya',
    'Yanaul',
    'Sheregesh',
    'Troitsk',
    'Udomlya',
    "Zaural'skiy",
    "Zavolzh'ye",
    'Lodeynoye Pole',
    "Podporozh'ye",
    "Vyaz'ma",
    'Bezhetsk',
    'Ibrya',
    "Star'",
    'Rostovskaya',
    'Gorin',
    'Vyazemskiy',
    "Kor'",
    "Komsomol'sk",
    'Vysokogornyy',
    "Obluch'ye",
    'Bogorodskoye',
    'Nikulin',
    'Sestroretsk',
    'Begunitsy',
    'Novodvinsk',
    'Nelazskoye',
    'Buyantsevo',
    'Nadvoitsy',
    'Kostomuksha',
    'Prirechensk',
    'Novoselovo',
    'Asino',
    'Zheleznogorsk-Ilimskiy',
    'Kolpashevo',
    "Prokop'yevsk",
    'Anzhero-Sudzhensk',
    'Berezovka',
    'Leninsk-Kuznetsky',
    'Topki',
    'Borkovka',
    "Roslavl'",
    'Kumertau',
    'Zelenogradsk',
    'Avri',
    'Staropesterevo',
    'Belovo',
    'Shchelkovo',
    'Sverdlovskiy',
    'Monino',
    'Perovka',
    'Kamenka',
    "Lomov'",
    "Bednodem'yanovsk",
    'Sursk',
    'Baza',
    'Bogoroditsk',
    'Reftinskiy',
    'Starodub',
    'Kovdor',
    'Dalmatovo',
    'Livny',
    'Korsakov',
    'Okha',
    'Novoshakhtinsk',
    "Ivan'",
    'Osnova',
    'Aro',
    'Sverdlova',
    'Svirsk',
    'Segezha',
    'Aleksin',
    'Shchekino',
    'Kimovsk',
    'Plavsk',
    'Dubovka',
    'Blagovar',
    'Tatlybayevo',
    'Ashchibutak',
    'Kirgiz-Miyaki',
    'Povedniki',
    'Barda',
    'Kusa',
    'Rezh',
    'Ocher',
    'Roshchino',
    'Arti',
    'Yugorsk',
    'Novozybkov',
    'Karachev',
    'Severskaya',
    'Mega',
    'Pulkovo',
    'Pyshma',
    'Kalinina',
    'Sortavala',
    'Raduga',
    'Selyatino',
    "Vrangel'",
    'Kastornoye',
    'Kyzyl',
    "Bol'shaya Rakovka",
    'Dedenevo',
    'Yakhroma',
    'Svobodnaya',
    'Surkhakhi',
    'Ilovlya',
    "Bol'shaya FÃ«dorovka",
    'BelozÃ«rsk',
    'Belyy',
    'Kirovo-Chepetsk',
    'Spas-Demensk',
    'Mga',
    'Ivangorod',
    "Singer'",
    'Elektrogorsk',
    'Krugloye',
    'Likino-Dulevo',
    'Kashira',
    'Mineralnye Vody',
    "Rossosh'",
    'Pavlovo',
    'Zaozersk',
    'Baltiysk',
    'Nizhniy Odes',
    'Troitsko-Pechorsk',
    'Pechora',
    'Inta',
    'Vytegra',
    'Gryazovets',
    'Pestovo',
    'Parfino',
    'Russa',
    'Valday',
    'Monchegorsk',
    'Zori',
    'Tosno',
    "Kinel'-Cherkassy",
    'Vanino',
    "Gavan'",
    "Gavan'",
    'Gubkin',
    'Alekseyevka',
    'Mozhaysk',
    'Shaturtorf',
    'Balashikha',
    'Pushchino',
    'Ratmirovo',
    'Kabanovo',
    "Roshal'",
    'Vurnary',
    'Kochkurovo',
    'Insar',
    'Maksimovka',
    'Atkarsk',
    'Parapino',
    'Snegiri',
    'Gaurilov',
    'Chesnokovka',
    'Kasimov',
    "Yelat'ma",
    "Kol'chugino",
    'Kiryabinskoye',
    'Uchaly',
    'Lesnoy',
    "Pereslavl'-Zalesskiy",
    'Krasnogvardeyskiy',
    'Nevskiy',
    'Frunzenskiy',
    'Uzlovaya',
    'ShchÃ«kino',
    'Donskoy',
    'Serdobsk',
    'Naumkino',
    'Voskresenskoye',
    'Luchegorsk',
    'Lampovo',
    'Krasnodarskiy',
    "Kol'tsovo",
    'Gagino',
    "Sibir'",
    'Chaplygin',
    'Volovo',
    'Yelets',
    'Olenegorsk',
    'Enkheluk',
    'Gusinoozyorsk',
    'Starobachaty',
    'Nizhneyashkino',
    "Gur'yevsk",
    'Yashkino',
    'Yaya',
    "Odan'",
    'Bogdashino',
    'Mtsensk',
    'Golovchino',
    'Dubenka',
    'Leninogorsk',
    'Gornozavodsk',
    'Divnogorsk',
    'Tsna',
    'Aksay',
    'Morozovsk',
    'Ryasnoye',
    'Krasnoarmeyskoye',
    'Krasnoobsk',
    'Yermolkino',
    'Pleloye',
    'Turinsk',
    'SuzÃ«mka',
    "Kozel'sk",
    'Pechory',
    'Volgorechensk',
    'Omchak',
    'Aleysk',
    'Kimry',
    'Sosny',
    'Zarinsk',
    'Belaya Kalitva',
    'Voronezhskiy',
    'Sredneuralsk',
    'Semibratovo',
    'Yugra',
    'Neya',
    'Vyazniki',
    'Poselok',
    'Varvarovka',
    'Pushkina',
    "Ob'",
    'Bagan',
    'Maslyanino',
    'Cherepanovo',
    'Zainsk',
    'Otradnoye',
    'Danilov',
    'Obyedineniye',
    'Ugresha',
    'Lipetsk',
    'Urus-Martan',
    "Tsivil'sk",
    'Taksimo',
    'Kusakovka',
    'Bogorodsk',
    'Kizlyar',
    "Kotel'nich",
    'Ruzayevka',
    'Fialka',
    'Krasnokamensk',
    'Tochka',
    'Aprelevka',
    'Shadrinsk',
    'Zaokskiy',
    'Kuzovatovo',
    'Kondopoga',
    'Adygeysk',
    'Tyrnyauz',
    'Pregradnaya',
    'Mozdok',
    'Shalushka',
    'Vyatskiye Polyany',
    'Shoshma',
    'Urzhum',
    'Podgortsy',
    "Lyambir'",
    'Usinskoye',
    'Krasnyy Kut',
    'Kharp',
    'Kalino',
    "Ivdel'",
    'Dobryanka',
    'Ishimskiy',
    'Kurtamysh',
    'Michurinsk',
    'Rikasikha',
    'Kichmengskiy Gorodok',
    'Velikiy Ustyug',
    'Syamzha',
    "Ust'ye",
    'Poselok',
    'Revda',
    'Kola',
    'Polyarnyy',
    'Kirovsk',
    "Shlissel'burg",
    'Boksitogorsk',
    'Svetogorsk',
    'Pitkyaranta',
    'Sebezh',
    "Bol'shoe Isakovo",
    'Gvardeysk',
    "Tot'ma",
    "Oktyabr'skiy",
    'Serafimovskiy',
    'Linda',
    'Kirovskaya',
    'Klyuchi-Bulak',
    'Shalinskoye',
    'Boguchany',
    'Srostki',
    'Mamontovo',
    "Kamen'-na-Obi",
    "Kuz'minka",
    'Sokolovo',
    'Apazovo',
    'Naberezhnyye Chelny',
    "Lebedyan'",
    'Yalta',
    'Volna',
    'Noginskaya',
    'Lermontov',
    'Volochek',
    'Pavlovskaya Sloboda',
    'Cherkizovo',
    "Usman'",
    'Tolstoy',
    'Birzha',
    'Valentin',
    "Mor'ye",
    'Dubrovka',
    'Divo',
    "Verkhotur'ye",
    'Bulgakov',
    'Bogdanovich',
    'Maykor',
    'Tazovskiy',
    'Filatovskoye',
    'Kasli',
    'Chernushka',
    'Muravlenko',
    'Panayevsk',
    'Tayshet',
    'Zhuravna',
    'Tulun',
    'Medvenka',
    'Tim',
    'Granit',
    'Malino',
    'Semyonov',
    'Kamennogorsk',
    'Poztykeres',
    'Medvedevo',
    'Dubovka',
    "Nevel'sk",
    'Zhirnovsk',
    'Yakovlevo',
    'Tsudakhar',
    "Ust'-Ilimsk",
    'Volkhov',
    "Syas'stroy",
    'Aleksandrovka',
    'Taldom',
    'Timiryazev',
    'Neva',
    'Kamensk',
    "Sven'",
    'Beryozovsky',
    'Gubkin',
    'Pushchino',
    'Volodarskiy',
    'Salym',
    'Karabash',
    "Yemel'yanovo",
    'Rosa',
    'Trubchevsk',
    'Katav-Ivanovsk',
    'Krasnoznamensk',
    'Svetlaya',
    'Ostrov',
    "Buret'",
    'Kirilly',
    'Ostankino',
    'Sodyshka',
    'Karla Marksa',
    'Pikalyovo',
    'Slavgorod',
    "Solnechnodol'sk",
    'Severo',
    'Malysheva',
    'Shumerlya',
    'Aleksino',
    'Skat',
    'Gorbatov',
    "Kel'dysh",
    'Neftekumsk',
    'Vikhorevka',
    'Tapkhar',
  ],
  tld: ['.ru', '.su', '.рф'],
  cca2: 'RU',
  ccn3: '643',
  cca3: 'RUS',
  cioc: 'RUS',
  currency: ['RUB'],
  callingCode: ['7'],
  capital: 'Moscow',
  altSpellings: [
    'RU',
    'Rossiya',
    'Russian Federation',
    'Российская Федерация',
    'Rossiyskaya Federatsiya',
  ],
  region: 'Europe',
  subregion: 'Eastern Europe',
  languages: { rus: 'Russian' },
  translations: {
    deu: { official: 'Russische Föderation', common: 'Russland' },
    fra: { official: 'Fédération de Russie', common: 'Russie' },
    hrv: { official: 'Ruska Federacija', common: 'Rusija' },
    ita: { official: 'Federazione russa', common: 'Russia' },
    jpn: { official: 'ロシア連邦', common: 'ロシア連邦' },
    nld: { official: 'Russische Federatie', common: 'Rusland' },
    por: { official: 'Federação Russa', common: 'Rússia' },
    rus: { official: 'Россия Федерация', common: 'Россия' },
    spa: { official: 'Federación de Rusia', common: 'Rusia' },
    fin: { official: 'Venäjän federaatio', common: 'Venäjä' },
  },
  latlng: [60, 100],
  demonym: 'Russian',
  landlocked: false,
  borders: [
    'AZE',
    'BLR',
    'CHN',
    'EST',
    'FIN',
    'GEO',
    'KAZ',
    'PRK',
    'LVA',
    'LTU',
    'MNG',
    'NOR',
    'POL',
    'UKR',
  ],
  area: 17098242,
  provinces: [
    'Adygeya (Maykop)',
    'Aginskiy Buryatskiy (Aginskoye)',
    'Altay (Gorno-Altaysk)',
    'Altayskiy (Barnaul)',
    'Amurskaya (Blagoveshchensk)',
    "Arkhangel'skaya",
    'Astrakhanskaya',
    'Bashkortostan (Ufa)',
    'Belgorodskaya',
    'Bryanskaya',
    'Buryatiya (Ulan-Ude)',
    'Chechnya (Groznyy)',
    'Chelyabinskaya',
    'Chitinskaya',
    "Chukotskiy (Anadyr')",
    'Chuvashiya (Cheboksary)',
    'Dagestan (Makhachkala)',
    'Evenkiyskiy (Tura)',
    "Ingushetiya (Nazran')",
    'Irkutskaya',
    'Ivanovskaya',
    "Kabardino-Balkariya (Nal'chik)",
    'Kaliningradskaya',
    'Kalmykiya (Elista)',
    'Kaluzhskaya',
    'Kamchatskaya (Petropavlovsk-Kamchatskiy)',
    'Karachayevo-Cherkesiya (Cherkessk)',
    'Kareliya (Petrozavodsk)',
    'Kemerovskaya',
    'Khabarovskiy',
    'Khakasiya (Abakan)',
    'Khanty-Mansiyskiy (Khanty-Mansiysk)',
    'Kirovskaya',
    'Komi (Syktyvkar)',
    'Komi-Permyatskiy (Kudymkar)',
    'Koryakskiy (Palana)',
    'Kostromskaya',
    'Krasnodarskiy',
    'Krasnoyarskiy',
    'Kurganskaya',
    'Kurskaya',
    'Leningradskaya',
    'Lipetskaya',
    'Magadanskaya',
    'Mariy-El (Yoshkar-Ola)',
    'Mordoviya (Saransk)',
    'Moskovskaya',
    'Moskva (Moscow)',
    'Murmanskaya',
    "Nenetskiy (Nar'yan-Mar)",
    'Nizhegorodskaya',
    'Novgorodskaya',
    'Novosibirskaya',
    'Omskaya',
    'Orenburgskaya',
    'Orlovskaya (Orel)',
    'Penzenskaya',
    'Permskaya',
    'Primorskiy (Vladivostok)',
    'Pskovskaya',
    'Rostovskaya',
    'Ryazanskaya',
    'Sakha (Yakutsk)',
    'Sakhalinskaya (Yuzhno-Sakhalinsk)',
    'Samarskaya',
    'Sankt-Peterburg (Saint Petersburg)',
    'Saratovskaya',
    'Severnaya Osetiya-Alaniya [North Ossetia] (Vladikavkaz)',
    'Smolenskaya',
    "Stavropol'skiy",
    'Sverdlovskaya (Yekaterinburg)',
    'Tambovskaya',
    "Tatarstan (Kazan')",
    'Taymyrskiy (Dudinka)',
    'Tomskaya',
    "Tul'skaya",
    'Tverskaya',
    'Tyumenskaya',
    'Tyva (Kyzyl)',
    'Udmurtiya (Izhevsk)',
    "Ul'yanovskaya",
    "Ust'-Ordynskiy Buryatskiy (Ust'-Ordynskiy)",
    'Vladimirskaya',
    'Volgogradskaya',
    'Vologodskaya',
    'Voronezhskaya',
    'Yamalo-Nenetskiy (Salekhard)',
    'Yaroslavskaya',
    'Yevreyskaya',
  ],
  nativeName: 'Россия',
  timezones: [
    'UTC+03:00',
    'UTC+04:00',
    'UTC+06:00',
    'UTC+07:00',
    'UTC+08:00',
    'UTC+09:00',
    'UTC+10:00',
    'UTC+11:00',
    'UTC+12:00',
  ],
} as const;
