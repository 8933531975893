import { Vue } from '@vue';
import { Module, VuexModule, Mutation, Action } from '@vuex/decorators';
import camelCase from 'lodash/camelCase';

import { api } from '@api';
import { Role } from '@models';
import { deviceInfo } from '@services/device-info';
import { ls } from '@services/ls';
import { Currency } from '@values/currencies';
import { dashboardMenus } from '@values/dashboard-menus';

import * as modules from './modules';

declare module '@vuex/core' {
  export interface Getters {
    user: Root['user'];
    activeRole: Root['activeRole'];
    activeTheme: Root['theme'];
    dashboardMenu: Root['dashboardMenu'];
  }

  export interface CommitMap {
    SET_WINDOW_SIZE: Root['SET_WINDOW_SIZE'];
    SET_THEME: Root['SET_THEME'];
    SET_DASHBOARD_MODE: Root['SET_DASHBOARD_MODE'];
    SET_DISPLAY_MODE: Root['SET_DISPLAY_MODE'];
    SET_CURRENT_STATE: Root['SET_CURRENT_STATE'];
    SET_LAUNCHER_LINKS: Root['SET_LAUNCHER_LINKS'];
    SET_INITIAL_NAVIGATION: Root['SET_INITIAL_NAVIGATION'];
  }

  export interface DispatchMap {
    getLauncherLinks: Root['getLauncherLinks'];
  }
}

declare module '@vuex/class' {
  /* eslint-disable @typescript-eslint/prefer-function-type */
  export interface StateTransformer {
    (state: Root.State, getters: Root.Getters): unknown;
  }
  /* eslint-enable @typescript-eslint/prefer-function-type */

  export interface StateBindingHelper extends BindingHelper {
    (type: StateTransformer, options?: BindingOptions): VuexDecorator;
  }

  export interface BindingHelper {
    // eslint-disable-next-line @typescript-eslint/prefer-function-type
    (type: keyof Root.State, options?: BindingOptions): VuexDecorator;
  }
}

/** ... */
const MAX_MOBILE_WIDTH = 990;

/**
 * ...
 */
interface RootState {
  window: Root.BrowserWindow;
  theme: ZephyrWeb.AppTheme;
  dashboardMode: ZephyrWeb.AppDashboardMode;
  displayMode: ZephyrWeb.AppDisplayMode;
  initialNavigationCompleted: boolean;
  launcherLinks: ZephyrWeb.DownloadLink[];
  regionalCurrency: Currency;
}

@Module
export class Root
  extends VuexModule<RootState, Root.State>
  implements RootState, Root.Getters
{
  window = getCurrentWindowSize();
  theme = getCurrentAppTheme();
  dashboardMode = getCurrentDashboardMode();
  displayMode = getCurrentDisplayMode();
  initialNavigationCompleted = false;
  launcherLinks: ZephyrWeb.DownloadLink[] = [];
  /**
   * NOTE: this should be set dynamically.
   */
  regionalCurrency: Currency = 'usd';

  /** ... */
  get user() {
    const { me } = this.context.rootState;

    return typeof me.id === 'string'
      ? (me as modules.Me.UserSessionInfo)
      : null;
  }

  /** ... */
  get activeRole() {
    return this.user?.selectedRole ?? null;
  }

  get activeDashboardMode() {
    return this.user?.selectedRole ?? null;
  }

  /** ... */
  get dashboardMenu() {
    const roleId = this.activeRole?.roleId;

    if (!roleId || !(roleId in dashboardMenus)) return [];

    return dashboardMenus[roleId as keyof typeof dashboardMenus];
  }

  //#region Mutations

  /**
   * ...
   */
  @Mutation
  SET_WINDOW_SIZE() {
    this.window = getCurrentWindowSize();

    const mode: ZephyrWeb.AppDisplayMode =
      this.window.width <= MAX_MOBILE_WIDTH ? 'mobile' : 'desktop';

    if (this.displayMode !== mode) {
      this.displayMode = mode;
    }
  }

  /**
   * ...
   */
  @Mutation
  SET_THEME(options: Root.SetThemeOptions) {
    this.theme = options.theme;

    if (DEVELOPMENT) {
      /* eslint-disable-next-line no-console */
      console.log(`Switching to theme -- ${this.theme}`);
    }

    ls.set('zephyr.theme', this.theme);
  }

  /**
   * ...
   */
  @Mutation
  SET_DASHBOARD_MODE(options: Root.SetDashboardModeOptions) {
    this.dashboardMode = options.mode;

    if (DEVELOPMENT) {
      /* eslint-disable-next-line no-console */
      console.log(`Switching to dashboard mode -- ${this.dashboardMode}`);
    }

    ls.set('zephyr.dashboardMode', this.dashboardMode);
  }

  /**
   * ...
   */
  @Mutation
  SET_DISPLAY_MODE(options: Root.SetDisplayModeOptions) {
    if (!isAppDisplayMode(options.mode)) {
      /* eslint-disable-next-line no-console */
      return console.warn(
        `App display mode "${options.mode as string}" is not valid.`,
      );
    }

    this.displayMode = options.mode;
  }

  /**
   * ...
   */
  @Mutation
  SET_CURRENT_STATE(options: Root.SetCurrentStateOptions) {
    Vue.set(this, 'currentState', options.state || null);
  }

  /**
   * ...
   */
  @Mutation
  SET_LAUNCHER_LINKS(options?: Root.SetLauncherLinksOptions) {
    this.launcherLinks = options?.links ?? [];
  }

  /**
   * ...
   */
  @Mutation
  SET_INITIAL_NAVIGATION() {
    this.initialNavigationCompleted = true;
  }

  //#endregion Mutations

  //#region Actions

  /**
   * ...
   */
  @Action
  async getLauncherLinks() {
    let os: api.launcher.OperatingSystem;

    if (deviceInfo.os === 'windows') {
      os = 'windows';
    } else if (deviceInfo.os === 'mac') {
      os = 'macOS';
    } else {
      return [];
    }

    const { links } = await api.launcher.getDownloadLinks({ os });

    this.context.commit('SET_LAUNCHER_LINKS', { links });

    return links;
  }

  //#endregion Actions
}

Root.modules = getModules();

export namespace Root {
  /** ... */
  export interface BrowserWindow {
    width: number;
    height: number;
  }

  /** ... */
  export interface Modules {
    announcements: modules.Announcements.State;
    assignments: modules.Assignments.State;
    cart: modules.Cart.State;
    certifications: modules.Certifications.State;
    courses: modules.Courses.State;
    apiKeys: modules.ApiKeys.State;
    dlc: modules.Dlc.State;
    drones: modules.Drones.State;
    extensionCodes: modules.ExtensionCodes;
    faqs: modules.Faqs.State;
    invites: modules.Invites.State;
    invoices: modules.Invoices.State;
    licenses: modules.Licenses.State;
    me: modules.Me.State;
    modules: modules.Modules.State;
    news: modules.News.State;
    orders: modules.Orders.State;
    organizations: modules.Organizations.State;
    outputLogEvents: modules.OutputLogEvents.State;
    paymentMethods: modules.PaymentMethods.State;
    promotionCodes: modules.PromotionCodes.State;
    promotions: modules.Promotions.State;
    products: modules.Products.State;
    resellers: modules.ResellersState;
    scenes: modules.Scenes.State;
    shop: modules.Shop.State;
    tablePanels: modules.TablePanels;
    users: modules.Users.State;
    unityCatalogs: modules.UnityCatalogs.State;
  }

  /** Store's root getters. */
  export interface Getters {
    user: modules.Me.State | null;
    activeRole: Role | null;
  }

  /** ... */
  export type State = RootState & Modules;

  /**
   * ...
   */
  export interface SetThemeOptions {
    theme: ZephyrWeb.AppTheme;
  }

  /**
   * ...
   */
  export interface SetDashboardModeOptions {
    mode: ZephyrWeb.AppDashboardMode;
  }

  /**
   * ...
   */
  export interface SetDisplayModeOptions {
    mode: ZephyrWeb.AppDisplayMode;
  }

  /**
   * ...
   */
  export interface SetCurrentStateOptions {
    state: unknown;
  }

  /**
   * ...
   */
  export interface SetLauncherLinksOptions {
    links: ZephyrWeb.DownloadLink[];
  }
}

//#region Helper Functions

/**
 * ...
 */
function getModules(mods = modules) {
  return Object.fromEntries(
    Object.entries(mods).map(([key, value]) => [camelCase(key), value]),
  );
}

/**
 * ...
 *
 * @return ...
 */
function getCurrentWindowSize() {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  } as Root.BrowserWindow;
}

/**
 * Determine if a value is of type `AppDisplayMode`.
 *
 * @param value The value to check.
 * @return `true` if the value is of type `AppDisplayMode`, otherwise `false`.
 */
export function isAppDisplayMode(
  value: unknown,
): value is ZephyrWeb.AppDisplayMode {
  return value === 'desktop' || value === 'mobile';
}

/**
 * Determine if a value is of type `AppTheme`.
 *
 * @param value The value to check.
 * @return `true` if the value is of type `AppTheme`, otherwise `false`.
 */
export function isAppTheme(value: unknown): value is ZephyrWeb.AppTheme {
  return value === 'light' || value === 'dark' || value === 'auto';
}

/**
 * Determine if a value is of type `AppDashboardMode`.
 *
 * @param value The value to check.
 * @return `true` if the value is of type `AppDashboardMode`, otherwise `false`.
 */
export function isDashboardMode(
  value: unknown,
): value is ZephyrWeb.AppDashboardMode {
  return value === 'regular' || value === 'fullwidth';
}

/**
 * ...
 *
 * @return ...
 */
function getCurrentAppTheme() {
  const value = ls.get('zephyr.theme');

  return isAppTheme(value) ? value : 'auto';
}

/**
 * ...
 *
 * @return ...
 */
function getCurrentDashboardMode() {
  const value = ls.get('zephyr.dashboardMode');

  return isDashboardMode(value) ? value : 'regular';
}

/**
 * ...
 *
 * @return ...
 */
function getCurrentDisplayMode() {
  return (
    window.innerWidth <= MAX_MOBILE_WIDTH ? 'mobile' : 'desktop'
  ) as ZephyrWeb.AppDisplayMode;
}

//#endregion Helper Functions

// if (import.meta.hot) {
//   import.meta.hot.accept('./modules', (newModules) => {
//     if (!newModules) return;

//     store.hotUpdate({ modules: getModules(newModules) });
//   });
// }
