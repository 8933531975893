export const CRI = {
  name: {
    common: 'Costa Rica',
    official: 'Republic of Costa Rica',
    native: {
      spa: { official: 'República de Costa Rica', common: 'Costa Rica' },
    },
  },
  cities: [
    'San JosÃ©',
    'Alajuela',
    'Puerto Jimenez',
    'Santa Ana',
    'Heredia',
    'Tres Rios',
    'Santa Cruz',
    'San Rafael',
    'Alfaro',
    'San Pedro',
    'Guadalupe',
    'Barva',
    'Corazon de Jesus',
    'Moravia',
    'Alajuelita',
    'San Isidro',
    'La Garita',
    'Puntarenas',
    'Cartago',
    'Escazu',
    'Santa Cecilia',
    'Liberia',
    'Coyol',
    'Curridabat',
    'Paraiso',
    'Llorente',
    'Pavas',
    'Desamparados',
    'LimÃ³n',
    'San Pablo',
    'San Rafael',
    'Colon',
    'Perez',
    'Aserri',
    'Guacima',
    'San Ramon',
    'Guachipelin',
    'Guapiles',
    'San Francisco',
    'Grecia',
    'Isla de Tibas',
    'San Josecito',
    'Turrialba',
    'San Marcos',
    'Coronado',
    'San Francisco',
    'Palmares',
    'Zapote',
    'San Diego',
    'Quesada',
    'Zarcero',
    'Mexico',
    'Santo Domingo',
    'San Joaquin',
    'Llorente',
    'Mercedes',
    'Sabanilla',
    'San Jose de la Montana',
    'San Juan',
    'Turrucares',
    'Miramar',
    'Filadelfia',
    'Atenas',
    'Jaco',
    'Poas',
    'Sarchi',
    'Quepos',
    'Siquirres',
    'Esparza',
  ],
  tld: ['.cr'],
  cca2: 'CR',
  ccn3: '188',
  cca3: 'CRI',
  cioc: 'CRC',
  currency: ['CRC'],
  callingCode: ['506'],
  capital: 'San José',
  altSpellings: ['CR', 'Republic of Costa Rica', 'República de Costa Rica'],
  region: 'Americas',
  subregion: 'Central America',
  languages: { spa: 'Spanish' },
  translations: {
    cym: { official: 'Republic of Costa Rica', common: 'Costa Rica' },
    deu: { official: 'Republik Costa Rica', common: 'Costa Rica' },
    fra: { official: 'République du Costa Rica', common: 'Costa Rica' },
    hrv: { official: 'Republika Kostarika', common: 'Kostarika' },
    ita: { official: 'Repubblica di Costa Rica', common: 'Costa Rica' },
    jpn: { official: 'コスタリカ共和国', common: 'コスタリカ' },
    nld: { official: 'Republiek Costa Rica', common: 'Costa Rica' },
    por: { official: 'República da Costa Rica', common: 'Costa Rica' },
    rus: { official: 'Республика Коста-Рика', common: 'Коста-Рика' },
    spa: { official: 'República de Costa Rica', common: 'Costa Rica' },
    fin: { official: 'Costa Rican tasavalta', common: 'Costa Rica' },
  },
  latlng: [10, -84],
  demonym: 'Costa Rican',
  landlocked: false,
  borders: ['NIC', 'PAN'],
  area: 51100,
  provinces: [
    'Alajuela',
    'Cartago',
    'Guanacaste',
    'Heredia',
    'Limon',
    'Puntarenas',
    'San Jose',
  ],
  nativeName: 'Costa Rica',
  timezones: ['UTC−06:00'],
} as const;
