var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-form-group",
    { staticClass: "form-floating" },
    [_vm._t("default"), _vm._v(" "), _c("label", [_vm._v(_vm._s(_vm.label))])],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }