import Vue from 'vue';

import { api } from './api';

declare module 'vue/types/vue' {
  interface Vue {
    /** Zephyr API service. */
    $api: typeof api;
  }
}

export * as api from './modules';
export * from './api';
export { ApiRequestError } from './request';

Vue.prototype.$api = api;
