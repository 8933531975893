import { Module } from '@vuex/decorators';
import { TablePanel } from 'table-panel';

import { faEye } from '@icons/solid/faEye';
import { faHand } from '@icons/solid/faHand';
import { faFileMagnifyingGlass } from '@icons/solid/faFileMagnifyingGlass';
import { modals } from '@modals';
import { Certification } from '@models';
import { computeDisplayName } from '@utils/user';
import { RoleId } from '@values/roles';

/** ... */
const columns: TablePanel.Column<Certification>[] = [
  {
    key: 'productName',
    label: 'Certificate Name',
    value: ({ product }) => product.name,
  },
  {
    key: 'createdAt',
    label: 'Created',
    value: 'createdAt',
    type: 'date',
  },
  {
    key: 'status',
    label: 'Status',
    value: 'status',
    component: 'TableCellCertificationStatus',
  },
  {
    key: 'lastAction',
    label: 'Last Action',
    component: 'TableCellCertificationLastAction',
    value: (item) => {
      return item.approvedAt ?? item.reattemptPromptAt ?? item.deniedAt ?? null;
    },
  },
  {
    key: 'user',
    label: 'User',
    value: ({ user }) => computeDisplayName(user),
  },
  {
    key: 'reviewer',
    label: 'Reviewer',
    value: ({ reviewer }) => (reviewer ? computeDisplayName(reviewer) : null),
  },
  {
    key: 'expiresAt',
    label: 'Expires',
    value: 'expiresAt',
    type: 'date',
  },
];

/** ... */
const operations: TablePanel.Row.Operation<Certification>[] = [
  {
    label: 'Claim',
    icon: faHand,
    fn: ({ id }) => {
      void modals.certifications.reviewAttempt({ certificationId: id });
    },
    hidden: ({ status }) => {
      return status !== 'AWAITING_REVIEW' && status !== 'AWAITING_ATTEMPT';
    },
  },
  {
    label: 'View',
    icon: faEye,
    fn: ({ id }) => {
      void modals.certifications.view({ certificationId: id });
    },
  },
  {
    label: 'Review',
    icon: faFileMagnifyingGlass,
    fn: ({ id }) => {
      void modals.certifications.reviewAttempt({ certificationId: id });
    },
    hidden: ({ status, reviewer }, activeUser) => {
      return status !== 'REVIEWING' || reviewer?.id !== activeUser.id;
    },
  },
];

const filterFields = ['productName', 'status', 'user', 'reviewer'];

// const specialFilters = [
//   {
//     type: 'toggle',
//     label: 'Filter Awaiting Review',
//     handler: (item: Certification) => item.status === 'AWAITING_REVIEW',
//   },
//   {
//     type: 'toggle',
//     label: 'Filter Awaiting Attempt',
//     handler: (item: Certification) => item.status === 'AWAITING_ATTEMPT',
//   },
//   {
//     type: 'toggle',
//     label: 'Filter Reviewing',
//     handler: (item: Certification) => item.status === 'REVIEWING',
//   },
// ];

const specialFilters = [
  {
    type: 'dropdown',
    label: 'Status',
    options: [
      { value: '--', text: '--' },
      {
        value: 'AWAITING_REVIEW',
        text: 'Awaiting Review',
      },
      {
        value: 'AWAITING_ATTEMPT',
        text: 'Awaiting Attempt',
      },
      {
        value: 'REVIEWING',
        text: 'Reviewing',
      },
    ],
    handler: (item: Certification, choice: string) => {
      return item?.status ? item.status === choice : null;
    },
  },
];

@Module({ namespaced: true })
export class ManageCertificationsTablePanel
  extends TablePanel<Certification>
  implements TablePanel.Props<Certification>
{
  readonly module = 'certifications';
  readonly loadAction = 'listAvailable';
  readonly pk = 'id';
  readonly label = 'MANAGE CERTIFICATIONS';
  readonly columns = columns;
  readonly operations = operations;
  readonly tableSortType = 'createdAt';
  readonly tableSortReverse = true;
  readonly filterFields = filterFields;
  readonly specialFilters = specialFilters;

  get enabled() {
    return (
      this.isActiveRole(RoleId.Reviewer, RoleId.LasAdmin) ||
      this.activeRole?.certifications?.length > 0
    );
  }

  get items() {
    // Override base `items` getter to only include certifications from store
    // that are reviewable.
    // return this.storeModule.items.filter(({ reviewer, status }) => {
    //   return (
    //     reviewer?.id !== this.activeUser.id && status === 'AWAITING_REVIEW'
    //   );
    // });
    return this.storeModule.items;
  }
}
