import { Module, VuexModule, Mutation, Action } from '@vuex/decorators';
import type { TablePanel } from 'table-panel';

import { api } from '@api';
import type { UserSearch, UserSearchByOrganization } from '@api/modules/users';
import { User } from '@models';
import { auth, RoleId } from '@auth';
import { Root } from '@store';
import { roundTo } from '@tools/math';
import { isString } from '@tools/type-guards';
import { findById, addById, updateById, removeById } from '@utils/array';

declare module '@vuex/core' {
  export interface Getters {
    'users/findById': Users['findById'];
    'users/metricAverages': Users['metricAverages'];
  }

  export interface CommitMap {
    'users/SET': Users['SET'];
    'users/SET_METRICS': Users['SET_METRICS'];
    'users/SET_LAST_EVALUATED_KEY': Users['SET_LAST_EVALUATED_KEY'];
    'users/SET_LOADING_METRICS_STATE': Users['SET_LOADING_METRICS_STATE'];
    'users/SET_LOADING_USERS_DETAILS': Users['SET_LOADING_USERS_DETAILS'];
    'users/ADD': Users['ADD'];
    'users/ADD_METRICS': Users['ADD_METRICS'];
    'users/UPDATE': Users['UPDATE'];
    'users/DELETE': Users['DELETE'];
    'users/CLEAR': Users['CLEAR'];
  }

  export interface DispatchMap {
    'users/loadPage': Users['loadPage'];
    'users/list': Users['list'];
    'users/listByOrganization': Users['listByOrganization'];
    'users/listByCourse': Users['listByCourse'];
    'users/searchByCourse': Users['searchByCourse'];
    'users/get': Users['get'];
    'users/computeMetrics': Users['computeMetrics'];
    'users/getDetails': Users['getDetails'];
    'users/update': Users['update'];
    'users/addRole': Users['addRole'];
    'users/addLicense': Users['addLicense'];
    'users/updateRole': Users['updateRole'];
    'users/removeRole': Users['removeRole'];
    'users/deleteUser': Users['deleteUser'];
  }
}

@Module({ namespaced: true })
export class Users
  extends VuexModule<Users.State, Root.State>
  implements Users.State
{
  loadingMetrics = false;
  loadingUserDetails = false;
  items: User[] = [];
  metrics: api.users.AggregateReportResults | null = null;
  lastEvaluatedKey: ZephyrWeb.Pagination.PlacementKey | null = null;
  allResultsLoaded = false;

  /** ... */
  get findById() {
    return (id: User['id']) => findById(this.items, id) ?? null;
  }

  /** ... */
  get metricAverages() {
    if (!this.metrics) return null;

    let count = 0;
    let highScore = 0;
    let violations = 0;
    let crashes = 0;

    for (const metrics of Object.values(this.metrics)) {
      if (metrics.reportCount === 0) continue;

      count++;

      highScore += metrics.reportsWithHighScore.fraction ?? 0;
      violations += metrics.reportsWithViolations.fraction ?? 0;
      crashes += metrics.reportsWithCrashes.fraction ?? 0;
    }

    return {
      reportsCompleted: roundTo(100 * (highScore / count), 2),
      reportsWithViolations: roundTo(100 * (violations / count), 2),
      reportsWithCrashes: roundTo(100 * (crashes / count), 2),
    };
  }

  // region Mutations

  /**
   * ...
   */
  @Mutation
  SET(options: Users.SetMutationOptions) {
    this.items = options.data;
  }

  /**
   * ...
   */
  @Mutation
  SET_METRICS(options: Users.SetReportMetricsMutationOptions) {
    this.metrics = options;
  }

  /**
   * ...
   */
  @Mutation
  SET_LAST_EVALUATED_KEY(options: {
    lastEvaluatedKey: ZephyrWeb.Pagination.PlacementKey | null;
  }) {
    this.lastEvaluatedKey = options.lastEvaluatedKey;
  }

  /**
   * ...
   */
  @Mutation
  SET_LOADING_METRICS_STATE(
    options: Users.SetLoadingMetricsStateMutationOptions,
  ) {
    this.loadingMetrics = !!options.active;
  }

  /**
   * ...
   */
  @Mutation
  SET_LOADING_USERS_DETAILS(
    options: Users.SetLoadingUserDetailsStateMutationOptions,
  ) {
    this.loadingUserDetails = !!options.active;
  }

  /**
   * ...
   */
  @Mutation
  ADD(options: Users.AddMutationOptions) {
    this.items = addById(this.items, options);
  }

  /**
   * ...
   */
  @Mutation
  ADD_METRICS(options: Users.SetReportMetricsMutationOptions) {
    this.metrics = { ...this.metrics, ...options };
  }

  /**
   * ...
   */
  @Mutation
  UPDATE({ userId, ...options }: Users.UpdateMutationOptions) {
    this.items = updateById(this.items, { id: userId, ...options });
  }

  /**
   * ...
   */
  @Mutation
  DELETE(options: Users.DeleteMutationOptions) {
    this.items = removeById(this.items, options.userId);
  }

  /**
   * ...
   */
  @Mutation
  CLEAR() {
    this.items = [];
    this.metrics = {};
  }

  // endregion Mutations

  // region Actions

  /**
   * ...
   */
  @Action
  async loadPage(options: TablePanel.LoadPageOptions<User>) {
    const searchOptions: UserSearch.Options | UserSearchByOrganization.Options =
      {
        includeRoles: true,
        includeLicenses: false,
        limit: 25,
      };

    if (isString(options.filter.contains)) {
      searchOptions.contains = options.filter.contains;
    }

    // Disabled for now -- `options.filter.equals` does not have the proper
    // interface to be compatible with `searchOptions.equals`.
    // if (isString(options.filter.equals)) {
    //   searchOptions.equals = options.filter.equals;
    // }

    let data: User[];

    if (this.lastEvaluatedKey && !options.clearPrevious) {
      searchOptions.startKey = this.lastEvaluatedKey;
      data = [...this.items];
    } else {
      data = [];
    }

    let res: ZephyrWeb.Pagination.Results<User> | null = null;

    if (auth.isActiveRole(RoleId.LasAdmin)) {
      res = await api.users.search({
        ...searchOptions,
        admin: true,
      });
    } else if (auth.isActiveRole(RoleId.InstitutionAdmin, RoleId.Instructor)) {
      res = await api.users.searchByOrganization({
        ...searchOptions,
        organizationId: auth.activeRole?.organization?.id as string,
      });
    }

    data.push(...(res?.items ?? []));

    this.context.commit('SET', { data });
    this.context.commit('SET_LAST_EVALUATED_KEY', {
      lastEvaluatedKey: res?.lastEvaluatedKey ?? null,
    });

    return res;
  }

  /**
   * ...
   */
  @Action
  async list() {
    const { me } = this.context.rootState;

    let data: User[] = [];

    if (auth.isActiveRole(9)) {
      data = await api.users.list();
    } else if (auth.isActiveRole(4, 5)) {
      const organizationId = me.organization?.id;

      if (!isString(organizationId)) {
        // eslint-disable-next-line no-console
        return console.error(
          `Expected User.Institution.Id to be a number. Got ${typeof organizationId} instead`,
        );
      }

      data = await api.users.listByOrganization({ organizationId });
    }

    this.context.commit('SET', { data });

    return this.items;
  }

  /**
   * ...
   */
  @Action
  async listByOrganization(options: Users.ListByOrganizationActionOptions) {
    const data = await api.users.listByOrganization(options);

    this.context.commit('SET', { data });

    return data;
  }

  /**
   * ...
   */
  @Action
  async listByCourse(options: Users.ListByCourseActionOptions) {
    const data = await api.users.listByCourse(options);

    this.context.commit('SET', { data });

    return data;
  }

  @Action
  async searchByCourse(options: Users.SearchByCourseActionOptions) {
    const hasCertifications =
      !!auth.activeRole?.certifications?.length ?? 0 > 0;

    const hasAccess = auth.isActiveRole(
      RoleId.LasAdmin,
      RoleId.Instructor,
      RoleId.InstitutionAdmin,
    );

    const details = await api.users.searchByCourse({
      courseId: options.courseId,
      organizationId: options.organizationId,
      // includeCertifications: hasCertifications && hasAccess,
      includeCertifications: true,
      includeRoles: false,
      includeLicenses: false,
      limit: 1000, /// Currently we expect that satisfy the needs and no course would have this amount of users.  When we paginate the table this will be resolved
    });

    this.context.commit('SET', { data: details.items });

    return details.items;
  }

  /**
   * ...
   */
  @Action
  async get(options: Users.GetActionOptions) {
    // const data = await api.users.get(options);
    //
    // this.context.commit('ADD', data);
    //
    // return data;

    const { me } = this.context.rootState;

    let data: User | null = null;

    if (auth.isActiveRole(9)) {
      data = await api.users.get({ userId: options.userId });
    } else if (auth.isActiveRole(4, 5)) {
      const organizationId = me.organization?.id;

      if (!isString(organizationId)) {
        // eslint-disable-next-line no-console
        console.error(
          `Expected User.Institution.Id to be a number. Got ${typeof organizationId} instead`,
        );

        return null;
      }

      data = await api.users.getByOrganization({
        userId: options.userId,
        organizationId,
      });
    }

    if (!data) return data;

    this.context.commit('ADD', data);

    return data;
  }

  /**
   * ...
   */
  @Action
  async computeMetrics(options?: Users.ComputeMetricsOptions) {
    this.context.commit('SET_LOADING_METRICS_STATE', { active: true });

    // TODO api.users.getReportStats is deprecated
    //
    // const userIds = options?.userIds ?? this.items.map(({ id }) => id);
    // const metrics = await api.users.getReportStats({ userIds });
    const metrics = (await Promise.resolve(
      {},
    )) as api.users.AggregateReportResults;

    this.context.commit('SET_LOADING_METRICS_STATE', { active: false });

    this.context.commit('ADD_METRICS', metrics);
  }

  /**
   * DEPRECATED (I Think)
   * NOTE: This is intended to be used for institution admins ONLY until
   * the institution admins user table is paginated. Remove once properly
   * implemented.
   */
  @Action
  async getDetails(options: Users.GetDetailsOptions) {
    // if (!auth.isActiveRole(RoleId.Instructor, RoleId.InstitutionAdmin)) {
    //   // eslint-disable-next-line no-console
    //   console.warn(
    //     'The "users/getDetails" action is intended for instructors and institution admins only. No data will be fetched.',
    //   );
    //   return [];
    // }
    // options = { ...options, includeLicenses: false, includeRoles: true };
    // this.context.commit('SET_LOADING_USERS_DETAILS', { active: true });
    // const userDetails = await api.users.searchByOrganization(options);
    // this.context.commit('SET_LOADING_USERS_DETAILS', { active: false });
    // if (!userDetails || !userDetails.length) return;
    // this.context.commit('ADD', userDetails);
    // return userDetails;
  }

  /**
   * ...
   */
  @Action
  async update(options: Users.UpdateActionOptions) {
    await api.users.update(options);

    return (await this.context.dispatch('get', options)) as User;
  }

  /**
   * ...
   */
  @Action
  async addRole(options: Users.AddRoleActionOptions) {
    const { id: roleId } = await api.users.addRole(options);

    // Fetch updated user.
    const user = (await this.context.dispatch('get', options)) as User;

    const role = (user.roles ?? []).find((role) => role.id === roleId);

    if (!role) {
      throw new Error('[addRole] ...');
    }

    return role;
  }

  /**
   * ...
   */
  @Action async addLicense(options: Users.AddLicenseActionOptions) {
    if (!auth.isActiveRole(RoleId.LasAdmin)) return;

    await api.users.adminAddLicense(options);

    return (await this.context.dispatch('get', options)) as User;
  }

  /**
   * ...
   */
  @Action
  async updateRole(options: Users.UpdateRoleActionOptions) {
    await api.users.updateRole(options);

    // Fetch updated user.
    const user = (await this.context.dispatch('get', options)) as User;

    const role = (user.roles ?? []).find(
      (role) => role.id === options.assignedRoleId,
    );

    if (!role) {
      throw new Error('[updateRole] ...');
    }

    return role;
  }

  /**
   * ...
   */
  @Action
  async removeRole(options: Users.RemoveRoleActionOptions) {
    await api.users.removeRole(options);

    // Fetch updated user.
    await this.context.dispatch('get', options);
  }

  /**
   * ...
   */
  @Action async deleteUser(options: Users.DeleteUserActionOptions) {
    if (!auth.isActiveRole(RoleId.LasAdmin)) return;
    await api.users.deleteUser(options);

    this.context.commit('DELETE', options);
  }

  // endregion Actions
}

export namespace Users {
  /**
   * ...
   */
  export interface State {
    loadingMetrics: boolean;
    items: User[];
    metrics: api.users.AggregateReportResults | null;
  }

  /**
   * ...
   */
  export interface SetMutationOptions {
    data: User[];
  }

  /**
   * ...
   */
  export interface ComputeMetricsOptions {
    userIds: User['id'][];
  }

  /**
   * ...
   */
  export interface SetLoadingMetricsStateMutationOptions {
    active: boolean;
  }

  /**
   * ...
   */
  export interface SetLoadingUserDetailsStateMutationOptions {
    active: boolean;
  }

  /** ... */
  export type SetReportMetricsMutationOptions =
    api.users.AggregateReportResults;
  /** ... */
  export type AddMutationOptions = User;
  /** ... */
  export type UpdateMutationOptions = api.users.UpdateOptions;
  /** ... */
  export type DeleteMutationOptions = api.users.DeleteUserOptions;
  /** ... */
  export type GetActionOptions = api.users.GetOptions;
  /** ... */
  export type ListByCourseActionOptions = api.users.ListByCourseOptions;
  /** ... */
  export type SearchByCourseActionOptions =
    api.users.UserSearchByCourse.Options;
  /** ... */
  export type ListByOrganizationActionOptions =
    api.users.ListByOrganizationOptions;
  /** ... */
  export type GetDetailsOptions = api.users.SearchByOrganizationOptions;
  /** ... */
  export type UpdateActionOptions = api.users.UpdateOptions;
  /** ... */
  export type AddRoleActionOptions = api.users.AddRoleOptions;
  /** ... */
  export type AddLicenseActionOptions = api.users.AdminAddLicenseOptions;
  /** ... */
  export type UpdateRoleActionOptions = api.users.UpdateRoleOptions;
  /** ... */
  export type RemoveRoleActionOptions = api.users.RemoveRoleOptions;
  /** ... */
  export type DeleteUserActionOptions = api.users.DeleteUserOptions;
}

export default Users;
