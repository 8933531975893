export const WLF = {
  name: {
    common: 'Wallis and Futuna',
    official: 'Territory of the Wallis and Futuna Islands',
    native: {
      fra: {
        official: 'Territoire des îles Wallis et Futuna',
        common: 'Wallis et Futuna',
      },
    },
  },
  cities: ['Vailala', 'Mata-Utu'],
  provinces: ['Alo', 'Sigave', 'Wallis'],
  nativeName: 'Wallis et Futuna',
  tld: ['.wf'],
  cca2: 'WF',
  ccn3: '876',
  cca3: 'WLF',
  cioc: '',
  currency: ['XPF'],
  callingCode: ['681'],
  capital: 'Mata-Utu',
  altSpellings: [
    'WF',
    'Territory of the Wallis and Futuna Islands',
    'Territoire des îles Wallis et Futuna',
  ],
  region: 'Oceania',
  subregion: 'Polynesia',
  languages: { fra: 'French' },
  translations: {
    deu: {
      official: 'Gebiet der Wallis und Futuna',
      common: 'Wallis und Futuna',
    },
    fra: {
      official: 'Territoire des îles Wallis et Futuna',
      common: 'Wallis-et-Futuna',
    },
    hrv: { official: 'Teritoriju Wallis i Futuna', common: 'Wallis i Fortuna' },
    ita: {
      official: 'Territorio delle Isole Wallis e Futuna',
      common: 'Wallis e Futuna',
    },
    jpn: { official: 'ウォリス·フツナ諸島の領土', common: 'ウォリス・フツナ' },
    nld: {
      official: 'Grondgebied van de Wallis en Futuna',
      common: 'Wallis en Futuna',
    },
    por: {
      official: 'Território das Ilhas Wallis e Futuna',
      common: 'Wallis e Futuna',
    },
    rus: {
      official: 'Территория Уоллис и Футуна острова',
      common: 'Уоллис и Футуна',
    },
    spa: {
      official: 'Territorio de las Islas Wallis y Futuna',
      common: 'Wallis y Futuna',
    },
    fin: {
      official: 'Wallisin ja Futunan yhteisö',
      common: 'Wallis ja Futuna',
    },
  },
  latlng: [-13.3, -176.2],
  demonym: 'Wallis and Futuna Islander',
  landlocked: false,
  borders: [],
  area: 142,
} as const;
