import Vue from 'vue';

import { isString, isNumber, isObject } from '@tools/type-guards';

declare module 'vue/types/vue' {
  interface Vue {
    /** `AlertService` instance. */
    $alert: AlertService;
  }
}

/**
 * Amount of time an alert should be displayed for. If set to `true`, the alert
 * will remain indefinitely until dismissed manually.
 */
type PersistValue = number | true;

export interface AlertOptions {
  persist?: PersistValue;
  clear?: boolean;
}

const FALLBACK_ERROR_MESSAGE =
  "Oops... something happened. It's not your fault. If you continue to experience this or other issues, please contact us at support@littlearms.com.";

export class AlertService {
  /** Spawn an informational-themed alert. */
  info(message: string, options?: PersistValue | AlertOptions) {
    spawnAlert('info', message, normalizeAlertOptions(options));
  }

  /** Spawn an success-themed alert. */
  success(message: string, options?: PersistValue | AlertOptions) {
    spawnAlert('success', message, normalizeAlertOptions(options));
  }

  /** Spawn an warning-themed alert. */
  warning(message: string, options?: PersistValue | AlertOptions) {
    spawnAlert('warn', message, normalizeAlertOptions(options));
  }

  /** Spawn an error-themed alert. */
  error(reason: unknown, options?: PersistValue | AlertOptions) {
    /* eslint-disable-next-line no-console */
    console.error(reason);

    const message = (getMessage(reason) ?? FALLBACK_ERROR_MESSAGE).replace(
      /\n/g,
      '<br />',
    );

    spawnAlert('error', message, normalizeAlertOptions(options));
  }

  /** Clear all currently-active alerts. */
  clear() {
    clearAlerts();
  }
}

/** `AlertService` instance. */
export const alert = new AlertService();

Vue.prototype.$alert = alert;

export default alert;

//#region Helper Functions

function normalizeAlertOptions(
  options: PersistValue | AlertOptions | undefined,
) {
  const normalizeOptions: AlertOptions = {};

  if (options === true || isNumber(options)) {
    normalizeOptions.persist = options;
  } else if (options !== undefined) {
    Object.assign(normalizeOptions, options);
  }

  return normalizeOptions;
}

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
function getMessage(value: unknown) {
  if (isString(value)) return value;

  if (!isObject(value)) return null;

  if (isObject(value['data'])) {
    if (isString(value['data.feedback'])) return value['data.feedback'];
    if (isString(value['data.message'])) return value['data.message'];
  }

  if (isString(value['feedback'])) return value['feedback'];
  if (isString(value['message'])) return value['message'];

  if (value['status'] === 404) return 'Entity Not Found';
  if (value['status'] === 401) return 'Unauthorized';
  // if (reason.status === 422)   return '';
  if (value['status'] === 403) return 'Forbidden.';
  if (value['status'] === 408) return 'Request Timeout.';
  if (value['status'] === 500) {
    return 'Internal Server Error. Something went wrong on our end. Please contact support@littlearms.com for assistance.';
  }

  if (value['status'] === -1) {
    return 'Timeout. Please contact us at support@littlearms.com for help.';
  }

  return null;
}

/**
 * Translate the provided value into the desired duration value for alerts. A
 * provided value of `true` corresponds to a indefinite persistance of the
 * alert.
 */
function getDuration(value: number | boolean) {
  return isNumber(value) ? value : value ? -1 : 5_000;
}

function clearAlerts() {
  Vue.notify({ group: 'reg', clean: true });
}

function spawnAlert(type: string, text: string, options: AlertOptions = {}) {
  if (options.clear) {
    clearAlerts();
  }

  Vue.notify({
    group: 'reg',
    type,
    text,
    duration: getDuration(options.persist ?? false),
  });
}

//#endregion Helper Functions
