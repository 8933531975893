import { Product } from '@models';
import { store } from '@store';

import { openModal } from '../open-modal';

/**
 * ...
 */
export interface CreateProductModalOptions {
  type: Product.Type;
}

/**
 * Open Create Product modal.
 *
 * @param options ...
 * @return Modal promise.
 */
export async function create(options?: CreateProductModalOptions) {
  await openModal.safe({
    title: 'CREATE PRODUCT',
    size: 'xl',
    props: { initialType: options?.type ?? null },
    component: () => import('./CreateProduct.vue'),
  });
}

/**
 * ...
 */
export interface EditProductModalOptions {
  productId: Product['id'];
}

/**
 * Open Edit Product modal.
 *
 * @param options ...
 * @return Modal promise.
 */
export async function edit(options: EditProductModalOptions) {
  const product = await store.dispatch('products/get', options);

  await openModal.safe({
    title: `EDIT PRODUCT -- ${product.name}`,
    size: 'xl',
    props: { product },
    component: () => import('./EditProduct.vue'),
  });
}

export interface AddPriceModalOptions {
  productId: Product['id'];
  prices: Product['prices'];
}

export async function addPrice(options: AddPriceModalOptions) {
  await openModal.safe({
    title: '',
    props: { productId: options.productId, prices: options.prices },
    component: () => import('./AddPrice.vue'),
  });
}
