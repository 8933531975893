export const CHL = {
  name: {
    common: 'Chile',
    official: 'Republic of Chile',
    native: { spa: { official: 'República de Chile', common: 'Chile' } },
  },
  cities: [
    'Las Condes',
    'Santiago',
    'ViÃ±a del Mar',
    'Hanga Roa',
    'Antofagasta',
    'Iquique',
    'ConcepciÃ³n',
    'ValparaÃ­so',
    'Ovalle',
    'Chuquicamata',
    'Providencia',
    'CopiapÃ³',
    'Maquina Atacama',
    'Valdivia',
    'Coihaique',
    'Galvarino',
    'Port Montt',
    'Collipulli',
    'Molina',
    'Los Angeles',
    'Quillota',
    'Punta Arenas',
    'Tome',
    'Chillan',
    'La Laja',
    'Temuco',
    'La Union',
    'QuilpuÃ©',
    'Puchuncavi',
    'Macul',
    'Lampa',
    'Rancagua',
    'CuricÃ³',
    'Talca',
    'Vallenar',
    'Coquimbo',
    'Mejillones',
    'Talcahuano',
    'San Javier',
    'San Fernando',
    'La Cisterna',
    'Pucon',
    'Constitucion',
    'San Carlos',
    'Chillan Viejo',
    'Bulnes',
    'Rengo',
    'Graneros',
    'Nacimiento',
    'San Bernardo',
    'Talagante',
    'Nunoa',
    'Colina',
    'Machali',
    'Caldera',
    'El Monte',
    'Melipilla',
    'La Granja',
    'Coronel',
    'Monte Aguila',
    'Arauco',
    'Chiguayante',
    'Curacavi',
    'La Reina',
    'Osorno',
    'Centro',
    'Puerto Varas',
    'Castro',
    'Quilicura',
    'La Serena',
    'Villa Alemana',
    'Buin',
    'Arica',
    'Calama',
    'San Antonio',
    'Renaca',
    'Concon',
    'Renca',
    'Recoleta',
    'Apoquindo',
    'Lolol',
    'Puerto Aisen',
    'Penablanca',
    'Maipu',
    'San Felipe',
    'Villarrica',
    'Paillaco',
    'Rio Bueno',
    'Puente Alto',
    'Olmue',
    'Purranque',
    'Chicureo Abajo',
    'El Salvador',
    'Miraflores',
    'El Salto',
    'Rinconada',
    'El Arco',
    'Cerrillos de Tamaya',
    'El Bosque',
    'El Valle',
    'Santa Barbara',
    'Los Andes',
    'San Pedro',
    'Cunco',
    'Angol',
    'Puren',
    'San Martin',
    'Vitacura',
    'Fresia',
    'Tarapaca',
    'Maule',
    'Lo Barnechea',
    'Puerto Natales',
    'La Calera',
    'La Ligua',
    'Nogales',
    'Chimbarongo',
    'Llanquihue',
    'Algarrobo',
    'Dalcahue',
    'Lautaro',
    'Quemchi',
    'Quellon',
    'Chonchi',
    'Catemu',
    'Penaflor',
    'El Quisco',
  ],
  tld: ['.cl'],
  cca2: 'CL',
  ccn3: '152',
  cca3: 'CHL',
  cioc: 'CHI',
  currency: ['CLF', 'CLP'],
  callingCode: ['56'],
  capital: 'Santiago',
  altSpellings: ['CL', 'Republic of Chile', 'República de Chile'],
  region: 'Americas',
  subregion: 'South America',
  languages: { spa: 'Spanish' },
  translations: {
    cym: { official: 'Republic of Chile', common: 'Chile' },
    deu: { official: 'Republik Chile', common: 'Chile' },
    fra: { official: 'République du Chili', common: 'Chili' },
    hrv: { official: 'Republika Čile', common: 'Čile' },
    ita: { official: 'Repubblica del Cile', common: 'Cile' },
    jpn: { official: 'チリ共和国', common: 'チリ' },
    nld: { official: 'Republiek Chili', common: 'Chili' },
    por: { official: 'República do Chile', common: 'Chile' },
    rus: { official: 'Республика Чили', common: 'Чили' },
    spa: { official: 'República de Chile', common: 'Chile' },
    fin: { official: 'Chilen tasavalta', common: 'Chile' },
  },
  latlng: [-30, -71],
  demonym: 'Chilean',
  landlocked: false,
  borders: ['ARG', 'BOL', 'PER'],
  area: 756102,
  provinces: [
    'Aisen del General Carlos Ibanez del Campo',
    'Antofagasta',
    'Araucania',
    'Atacama',
    'Bio-Bio',
    'Coquimbo',
    "Libertador General Bernardo O'Higgins",
    'Los Lagos',
    'Magallanes y de la Antartica Chilena',
    'Maule',
    'Region Metropolitana (Santiago)',
    'Tarapaca',
    'Valparaiso',
  ],
  nativeName: 'Chile',
  timezones: ['UTC−06:00', 'UTC−04:00'],
} as const;
