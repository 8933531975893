export const AGO = {
  name: {
    common: 'Angola',
    official: 'Republic of Angola',
    native: { por: { official: 'República de Angola', common: 'Angola' } },
  },
  cities: [
    'Kuito',
    'UÃ­ge',
    'Luanda',
    'Benguela',
    'Lobito',
    'Cabinda',
    'Malanje',
    'Piri',
    'Namibe',
    'Camabatela',
    'Lucapa',
    "N'dalatando",
    'Menongue',
    'Talatona',
    'Ondjiva',
    'Cazeta',
    'Cacole',
    'Saurimo',
    'Ambriz',
    'Muxaluando',
    'Lumeje',
    'Huambo',
    'Lubango',
  ],
  provinces: [
    'Andorra la Vella',
    'Bengo',
    'Benguela',
    'Bie',
    'Cabinda',
    'Canillo',
    'Cuando Cubango',
    'Cuanza Norte',
    'Cuanza Sul',
    'Cunene',
    'Encamp',
    'Escaldes-Engordany',
    'Huambo',
    'Huila',
    'La Massana',
    'Luanda',
    'Lunda Norte',
    'Lunda Sul',
    'Malanje',
    'Moxico',
    'Namibe',
    'Ordino',
    'Sant Julia de Loria',
    'Uige',
    'Zaire',
  ],
  nativeName: 'Angola',
  tld: ['.ao'],
  cca2: 'AO',
  ccn3: '024',
  cca3: 'AGO',
  cioc: 'ANG',
  currency: ['AOA'],
  callingCode: ['244'],
  capital: 'Luanda',
  altSpellings: ['AO', 'República de Angola', "ʁɛpublika de an'ɡɔla"],
  region: 'Africa',
  subregion: 'Middle Africa',
  languages: { por: 'Portuguese' },
  translations: {
    cym: { official: 'Republic of Angola', common: 'Angola' },
    deu: { official: 'Republik Angola', common: 'Angola' },
    fra: { official: "République d'Angola", common: 'Angola' },
    hrv: { official: 'Republika Angola', common: 'Angola' },
    ita: { official: "Repubblica dell'Angola", common: 'Angola' },
    jpn: { official: 'アンゴラ共和国', common: 'アンゴラ' },
    nld: { official: 'Republiek Angola', common: 'Angola' },
    por: { official: 'República de Angola', common: 'Angola' },
    rus: { official: 'Республика Ангола', common: 'Ангола' },
    spa: { official: 'República de Angola', common: 'Angola' },
    fin: { official: 'Angolan tasavalta', common: 'Angola' },
  },
  latlng: [-12.5, 18.5],
  demonym: 'Angolan',
  landlocked: false,
  borders: ['COG', 'COD', 'ZMB', 'NAM'],
  area: 1246700,
  timezones: ['UTC+01:00'],
} as const;
