import { server } from '@api/request';
import { DLC, DLCSubmission } from '@models';
import { isObject } from '@tools/type-guards';

// List DLC
/**
 * List all DLC
 *
 * @return A list of DLC
 */
export async function list() {
  return await server.list('v2/admin/dlc', processDLCData);
}

// Get DLC
/**
 * ...
 */
export interface GetOptions {
  dlcId: DLC['id'];
}

/**
 * Get a specified DLC
 *
 * @param options Request options bag.
 * @return The specified DLC's mac and win submissions/history
 * { win, mac }
 */
export async function get(options: GetOptions) {
  return await server.get(`v2/admin/dlc/${options.dlcId}`, processDLCData);
}

// Create DLC
/**
 * ...
 */
export interface CreateOptions {
  id: DLC['id'];
  name: DLC['name'];
  os: 'win' | 'mac';
  private: boolean;
  version: DLCSubmission['version'];
  sourceFileKey: DLCSubmission['sourceFileKey'];
}

/**
 * Create a DLC / DLC Submission
 *
 * @param options Request options bag.
 */
export async function create(options: CreateOptions) {
  return await server.post(`v2/admin/dlc`, options);
}

// Update DLC
/**
 * basically creating a new DLC Submission
 */
export interface UpdateOptions {
  id: DLC['id'];
  name: DLC['name'];
  os: 'win' | 'mac';
  private: boolean;
  version: DLCSubmission['version'];
  sourceFileKey: DLCSubmission['sourceFileKey'];
}

/**
 * Create a DLC / DLC Submission
 *
 * @param options Request options bag.
 */
export async function update(options: UpdateOptions) {
  return await server.post(`v2/admin/dlc`, options);
}

export interface StartOptions {
  dlcId: string;
  buildNumber: string;
}

/**
 * Start DCL Submission
 */
export async function startSubmission(options: StartOptions) {
  return await server.post('v2/admin/builds/start', options);
}

export interface ApproveDenyOptions {
  dlcId: DLC['id'];
  buildNumber: DLCSubmission['buildNumber'];
  status: 'Approved' | 'Rejected';
  summary: string;
}
/**
 * Approve/Reject DLC Submission
 */
export async function approveDenySubmission(options: ApproveDenyOptions) {
  return await server.post(`v2/admin/builds/approval-result`, options);
}

/**
 * Upload DLC Submission
 */
export interface UploadDLCOptions {
  dlcId: DLC['id'];
  buildNumber: DLCSubmission['buildNumber'];
  version: DLCSubmission['version'];
  channel: 'develop' | 'release';
  os: 'win' | 'mac';
}

/**
 * Upload DLC
 */
export async function uploadDLC(options: UploadDLCOptions) {
  return await server.post(`v2/admin/upload`, options);
}

//#region Helper Functions
/**
 *
 */
function isValidDLCData(value: unknown): value is DLC.Attributes {
  if (!isObject(value)) return false;

  // TEMP: Use a more strict validation.

  return true;
}
/**
 *
 */
function processDLCData(data: unknown) {
  if (!isValidDLCData(data)) {
    throw new Error('Invalid drone data item.');
  }

  return { ...data } as DLC;
}
//#endregion Helper Functions
