export const UNK = {
  name: {
    common: 'Kosovo',
    official: 'Republic of Kosovo',
    native: {
      sqi: { official: 'Republika e Kosovës', common: 'Kosova' },
      srp: { official: 'Република Косово', common: 'Косово' },
    },
  },
  cities: ['Pristina', 'Ferizaj', 'Mitrovica', 'Dunavo', 'Prizren'],
  tld: [],
  cca2: 'XK',
  ccn3: '',
  cca3: 'UNK',
  cioc: 'KOS',
  currency: ['EUR'],
  callingCode: ['383'],
  capital: 'Pristina',
  altSpellings: ['XK', 'Република Косово'],
  region: 'Europe',
  subregion: 'Eastern Europe',
  languages: { sqi: 'Albanian', srp: 'Serbian' },
  translations: {
    fra: { official: 'République du Kosovo', common: 'Kosovo' },
    hrv: { official: 'Republika Kosovo', common: 'Kosovo' },
    por: { official: 'República do Kosovo', common: 'Kosovo' },
    rus: { official: 'Республика Косово', common: 'Республика Косово' },
    spa: { official: 'República de Kosovo', common: 'Kosovo' },
    fin: { official: 'Kosovon tasavalta', common: 'Kosovo' },
  },
  latlng: [42.666667, 21.166667],
  demonym: 'Kosovar',
  landlocked: true,
  borders: ['ALB', 'MKD', 'MNE', 'SRB'],
  area: 10908,
} as const;
