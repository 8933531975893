import { concat, isString, isNumber, isDate } from 'lodash';

import { DATE_FORMATS } from './date-formats';
import * as DatetimeFormats from './datetime-formats';

const ALL_COLONS = /:/g;
const NUMBER_STRING = /^-?\d+$/;
const DATE_FORMATS_SPLIT =
  /((?:[^yMLdHhmsaZEwG']+)|(?:'(?:[^']|'')*')|(?:E+|y+|M+|L+|d+|H+|h+|m+|s+|a|Z|G+|w+))([\s\S]*)/;
const R_ISO8601_STR =
  /^(\d{4})-?(\d\d)-?(\d\d)(?:T(\d\d)(?::?(\d\d)(?::?(\d\d)(?:\.(\d+))?)?)?(Z|([+-])(\d\d):?(\d\d))?)?$/;

/**
 * ...
 *
 * @param date ...
 * @param format ...
 * @param timezone ...
 * @return ...
 */
export function dateFilter(
  date: string | number | Date,
  format = 'MM/dd/yyyy',
  timezone?: string,
) {
  let parts: string[] = [];

  let formatter =
    format in DatetimeFormats
      ? DatetimeFormats[format as keyof typeof DatetimeFormats]
      : format;

  // format = DATE_FORMATS[format || 'mediumDate'] || format;

  if (isString(date)) {
    date = NUMBER_STRING.test(date) ? parseInt(date) : jsonStringToDate(date);
  }

  if (isNumber(date)) {
    date = new Date(date);
  }

  if (!isDate(date) || !isFinite(date.getTime())) {
    return date;
  }

  let match: RegExpMatchArray | null = null;

  while (formatter) {
    match = DATE_FORMATS_SPLIT.exec(formatter);

    if (match) {
      parts = concat(parts, match.slice(1));
      formatter = parts.pop();
    } else {
      parts.push(formatter);
      formatter = null;
    }
  }

  let dateTimezoneOffset = date.getTimezoneOffset();

  if (timezone) {
    dateTimezoneOffset = timezoneToOffset(timezone, dateTimezoneOffset);
    date = convertTimezoneToLocal(date, timezone, true);
  }

  let text = '';

  for (const part of parts) {
    const fn = DATE_FORMATS[part];

    text += fn
      ? fn(date, dateTimezoneOffset)
      : part === "''"
      ? "'"
      : part.replace(/(^'|'$)/g, '').replace(/''/g, "'");
  }

  return text;
}

export default dateFilter;

//#region Helper Functions

/**
 * ...
 *
 * @param date ...
 * @param minutes ...
 * @return ...
 */
function addDateMinutes(date: Date, minutes: number) {
  date = new Date(date.getTime());
  date.setMinutes(date.getMinutes() + minutes);

  return date;
}

/**
 * ...
 *
 * @param timezone ...
 * @param fallback ...
 * @return ...
 */
function timezoneToOffset(timezone: string, fallback: number) {
  // Support: IE 9-11 only, Edge 13-15+
  // IE/Edge do not "understand" colon (`:`) in timezone
  timezone = timezone.replace(ALL_COLONS, '');

  const requestedTimezoneOffset =
    Date.parse('Jan 01, 1970 00:00:00 ' + timezone) / 60_000;

  return isNaN(requestedTimezoneOffset) ? fallback : requestedTimezoneOffset;
}

/**
 * ...
 *
 * @param date ...
 * @param timezone ...
 * @param reverse ...
 * @return ...
 */
function convertTimezoneToLocal(
  date: Date,
  timezone: string,
  reverse?: boolean,
) {
  const dateTimezoneOffset = date.getTimezoneOffset();
  const timezoneOffset = timezoneToOffset(timezone, dateTimezoneOffset);

  return addDateMinutes(
    date,
    (reverse ? -1 : 1) * (timezoneOffset - dateTimezoneOffset),
  );
}

function jsonStringToDate(str: string) {
  const match = R_ISO8601_STR.exec(str);

  if (!match) {
    // return str;
    return new Date(str);
  }

  const date = new Date(0);
  const dateSetter = match[8] ? date.setUTCFullYear : date.setFullYear;
  const timeSetter = match[8] ? date.setUTCHours : date.setHours;

  let tzHour = 0;
  let tzMin = 0;

  if (match[9]) {
    tzHour = parseInt(match[9] + match[10]);
    tzMin = parseInt(match[9] + match[11]);
  }

  dateSetter.call(
    date,
    parseInt(match[1]),
    parseInt(match[2]) - 1,
    parseInt(match[3]),
  );

  const h = parseInt(match[4] || '0') - tzHour;
  const m = parseInt(match[5] || '0') - tzMin;
  const s = parseInt(match[6] || '0');
  const ms = Math.round(parseFloat('0.' + (match[7] || '0')) * 1_000);

  timeSetter.call(date, h, m, s, ms);

  return date;
}

//#endregion Helper Functions
