import { maxBy, sumBy } from 'lodash';

import { api } from '@api';
import * as models from '@models';
import { isNullish } from '@tools/type-guards';
import { dates } from '@utils/dates';

/**
 * ...
 */
export interface AssignmentCompletionSummary {
  report: models.Report | null;
  completed: boolean;
  score: number;
  onTime: boolean;
  completedDate: string | null;
  allAttempts: models.Report[];
  totalTime: number;
}

/**
 * ...
 */
export interface AssignmentCompletionResult extends Partial<models.User> {
  assignment: AssignmentCompletionSummary;
}

/**
 * Check list of students against an assignment and check for completion.
 *
 * @param options ...
 * @returns ...
 */
export async function checkAssignmentCompletion(
  options: checkAssignmentCompletion.Options,
) {
  const { students, assignment, course } = options;

  // ...
  const userAssignments = await getOrganizationUserAssignments(assignment);

  // ...
  const dueDate = new Date(assignment.dueDate);

  // ...
  const uniqueId = scenarioUid(assignment.module.id, assignment.scene.id);

  // for some reason we were getting the error
  // "Cannot access 'assignment' before declaration"
  // https://github.com/webpack/webpack/issues/9173
  // assigning the variables we need to use is the workaround for now
  const assignmentMinimumScore = assignment.minimumScore;
  const assignmentStartDate = assignment.startDate;
  const assignmentEndDate = assignment.endDate;
  const assignmentTotalTime = assignment.totalTime;

  // ...
  const assignmentReports = await api.reports.queryForOrganization({
    organizationId: assignment.organization.id,
    assignmentId: assignment.id,
    startDate: assignmentStartDate ?? course.startDate,
    endDate: assignmentEndDate ?? dueDate.toISOString(),
  });

  const results: checkAssignmentCompletion.Result[] = [];

  for (const student of students) {
    const reports = assignmentReports.filter(
      ({ completedBy }) => completedBy.id === student.id,
    );

    // Check if user has user-assignment object.
    //
    // NOTE:
    const userAssignment =
      userAssignments.find(({ pk }) => pk.includes(student.id)) ?? null;

    if (userAssignment && userAssignment.status === 'COMPLETED') {
      results.push(
        getCompletionInfoFromAssignment(reports, userAssignment, student),
      );

      continue;
    }

    // No user assignment relation found. See if we can
    // detect assignment completion from reports

    let completed = false;
    let highestScore = 0;
    let onTime = false;
    let completedDate: string | null = null;
    let report: models.Report | null = null;
    let allAttempts: models.Report[] = [];
    let totalTime = 0;

    // ...
    const reportsRelatedToAssignment = reports.filter((r) => {
      // ...
      const scenarioMatch = uniqueId === scenarioUid(r.module?.id);

      if (!scenarioMatch) return false;

      if (isNullish(assignmentMinimumScore)) return true;

      return ensureInt(r.score) >= ensureInt(assignmentMinimumScore);
    });

    // ...
    const reportsOnTime = reportsRelatedToAssignment.filter(({ createdAt }) => {
      if (!dates.isSameOrBefore(createdAt, dueDate)) return false;

      if (
        assignmentStartDate &&
        assignmentEndDate &&
        !dates.isBetween(createdAt, assignmentStartDate, assignmentEndDate)
      ) {
        return false;
      }

      return true;
    });

    if (reportsOnTime.length) {
      allAttempts = reportsOnTime;
      totalTime = ensureInt(sumBy(reportsOnTime, 'totalTime'));
    }

    // ...
    const reportsLate = reportsRelatedToAssignment.filter(({ createdAt }) =>
      dates.isAfter(createdAt, dueDate),
    );

    // ...
    // const reportsEarly = reportsRelatedToAssignment.filter(({ createdAt }) => {
    //   return dates.isSameOrBefore(createdAt, assignmentStartDate ?? dueDate);
    // });

    // ...

    if (reportsOnTime.length) {
      completed = assignmentTotalTime
        ? ensureInt(totalTime) >= ensureInt(assignmentTotalTime)
        : true;

      onTime = true;
      report = maxBy(reportsOnTime, 'score') ?? null;
    } else if (reportsLate.length) {
      completed = true;
      report = maxBy(reportsLate, 'score') ?? null;
    }

    if (report) {
      highestScore = report.score;
      completedDate = dates.format(report.createdAt, 'MM/DD/YYYY HH:mm a');
    }

    results.push({
      ...student,
      assignment: {
        report,
        completed,
        score: highestScore,
        onTime,
        completedDate,
        allAttempts,
        totalTime,
      },
    });
  }

  return results;
}

export namespace checkAssignmentCompletion {
  /** ... */
  export type Summary = AssignmentCompletionSummary;
  /** ... */
  export type Result = AssignmentCompletionResult;

  /**
   * ...
   */
  export interface Options {
    students: models.User[];
    assignment: models.Assignment;
    course: models.Course;
  }
}

//#region Helper Functions

/**
 * ...
 *
 * @param assignment ...
 * @returns ...
 */
async function getOrganizationUserAssignments(assignment: models.Assignment) {
  let userAssignments: models.UserAssignment[];

  try {
    userAssignments = await api.userAssignments.listByOrganizationAssignment({
      organizationId: assignment.organization.id,
      assignmentId: assignment.id,
    });
  } catch {
    userAssignments = [];
  }

  return userAssignments;
}

/**
 * ...
 *
 * @param moduleId ...
 * @param sceneId ...
 * @returns ...
 */
function scenarioUid(
  moduleId: Nullable<models.Module['id']>,
  sceneId?: Nullable<models.Scene['id']>,
) {
  return `${moduleId ?? ''}-${sceneId ?? ''}`;
}

/**
 * ...
 *
 * @param value ...
 * @returns ...
 */
function ensureInt(value: string | number) {
  return parseInt(value.toString());
}

/**
 * ...
 *
 * @param reports ...
 * @param assignment ...
 * @returns ...
 */
function getCompletionInfoFromAssignment(
  reports: models.Report[],
  assignment: models.UserAssignment,
  student: models.User,
) {
  // ...
  const assignmentReports = reports.filter(({ id }) =>
    assignment.reportIds.includes(id),
  );

  const report = maxBy(reports, 'score');

  if (!report) {
    throw new Error(
      '[getCompletionInfoFromAssignment] could not locate report with highest score.',
    );
  }

  return {
    ...student,
    assignment: {
      report,
      completed: assignment.status === 'COMPLETED',
      score: report.score,
      onTime: assignment.status === 'COMPLETED',
      completedDate: dates.format(assignment.updatedAt, 'MM/DD/YYYY HH:mm a'),
      allAttempts: assignmentReports,
      totalTime: assignment.totalTime,
    },
  };
}

//#endregion Helper Functions
