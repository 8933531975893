export const HND = {
  name: {
    common: 'Honduras',
    official: 'Republic of Honduras',
    native: { spa: { official: 'República de Honduras', common: 'Honduras' } },
  },
  cities: [
    'Tegucigalpa',
    'San Pedro Sula',
    'Morazan',
    'La Ceiba',
    'Santa Barbara',
    'CopÃ¡n',
    'Coxen Hole',
    'El Progreso',
    'San Antonio de Flores',
    'Piraera',
    'La Hacienda',
    'Comayaguela',
    'Choloma',
    'Comayagua',
    'Nacaome',
    'Pinalejo',
    'Puerto Lempira',
    'Sula',
    'El Barro',
    'El Paraiso',
  ],
  tld: ['.hn'],
  cca2: 'HN',
  ccn3: '340',
  cca3: 'HND',
  cioc: 'HON',
  currency: ['HNL'],
  callingCode: ['504'],
  capital: 'Tegucigalpa',
  altSpellings: ['HN', 'Republic of Honduras', 'República de Honduras'],
  region: 'Americas',
  subregion: 'Central America',
  languages: { spa: 'Spanish' },
  translations: {
    deu: { official: 'Republik Honduras', common: 'Honduras' },
    fra: { official: 'République du Honduras', common: 'Honduras' },
    hrv: { official: 'Republika Honduras', common: 'Honduras' },
    ita: { official: 'Repubblica di Honduras', common: 'Honduras' },
    jpn: { official: 'ホンジュラス共和国', common: 'ホンジュラス' },
    nld: { official: 'Republiek Honduras', common: 'Honduras' },
    por: { official: 'República de Honduras', common: 'Honduras' },
    rus: { official: 'Республика Гондурас', common: 'Гондурас' },
    spa: { official: 'República de Honduras', common: 'Honduras' },
    fin: { official: 'Hondurasin tasavalta', common: 'Honduras' },
  },
  latlng: [15, -86.5],
  demonym: 'Honduran',
  landlocked: false,
  borders: ['GTM', 'SLV', 'NIC'],
  area: 112492,
  provinces: [
    'Atlantida',
    'Choluteca',
    'Colon',
    'Comayagua',
    'Copan',
    'Cortes',
    'El Paraiso',
    'Francisco Morazan',
    'Gracias a Dios',
    'Intibuca',
    'Islas de la Bahia',
    'La Paz',
    'Lempira',
    'Ocotepeque',
    'Olancho',
    'Santa Barbara',
    'Valle',
    'Yoro',
  ],
  nativeName: 'Honduras',
  timezones: ['UTC−06:00'],
} as const;
