var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "organization-site-license-type-table-cell" },
    [
      _setup.billingType === "SiteLicense"
        ? _c("span", [_vm._v("\n    " + _vm._s(_setup.totalLicenses) + "\n  ")])
        : _c("span", [_vm._v("N/A")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }