import { server } from '@api/request';
import { isObject, isBoolean } from '@tools/type-guards';

/**
 * {@link verify Verify account} API request payload options.
 */
export interface VerifyOptions {
  email: string;
  reCaptcha: string;
}

/**
 * {@link verify Verify account} API request response.
 */
export interface VerifyResponse {
  verified: boolean;
}

/**
 * Verify if a given email belongs to an existing account or not.
 *
 * @param options Request options payload.
 * @returns `true` if the email belongs to a user, otherwise `false`.
 */
export async function verify(options: VerifyOptions) {
  return await server.post(
    'v2/account/verify',
    options,
    processVerifyResponseData,
  );
}

/**
 * Determine if a value is valid {@link VerifyResponse verify response data}.
 *
 * @param value The value to check.
 * @returns `true` if the value is a valid
 * {@link VerifyResponse verify response data}, otherwise `false`.
 */
function isValidVerifyResponseData(value: unknown): value is VerifyResponse {
  if (!isObject(value)) return false;

  if (!isBoolean(value.verified)) return false;

  return true;
}

/**
 * Process data received from a request expected to be
 * {@link VerifyResponse verify response data}.
 *
 * @param data Data received from the request.
 * @returns The processed data value.
 */
function processVerifyResponseData(data: unknown) {
  if (!isValidVerifyResponseData(data)) {
    throw new Error('Invalid verify response data item.');
  }

  return { ...data } as VerifyResponse;
}
