export const TCD = {
  name: {
    common: 'Chad',
    official: 'Republic of Chad',
    native: {
      ara: { official: 'جمهورية تشاد', common: 'تشاد‎' },
      fra: { official: 'République du Tchad', common: 'Tchad' },
    },
  },
  cities: ['Massaguet', 'AbÃ©chÃ©', "N'Djamena", 'Moundou'],
  tld: ['.td'],
  cca2: 'TD',
  ccn3: '148',
  cca3: 'TCD',
  cioc: 'CHA',
  currency: ['XAF'],
  callingCode: ['235'],
  capital: "N'Djamena",
  altSpellings: ['TD', 'Tchad', 'Republic of Chad', 'République du Tchad'],
  region: 'Africa',
  subregion: 'Middle Africa',
  languages: { ara: 'Arabic', fra: 'French' },
  translations: {
    cym: { official: 'Republic of Chad', common: 'Tsiad' },
    deu: { official: 'Republik Tschad', common: 'Tschad' },
    fra: { official: 'République du Tchad', common: 'Tchad' },
    hrv: { official: 'Čadu', common: 'Čad' },
    ita: { official: 'Repubblica del Ciad', common: 'Ciad' },
    jpn: { official: 'チャド共和国', common: 'チャド' },
    nld: { official: 'Republiek Tsjaad', common: 'Tsjaad' },
    por: { official: 'República do Chade', common: 'Chade' },
    rus: { official: 'Республика Чад', common: 'Чад' },
    spa: { official: 'República de Chad', common: 'Chad' },
    fin: { official: 'Tšadin tasavalta', common: 'Tšad' },
  },
  latlng: [15, 19],
  demonym: 'Chadian',
  landlocked: true,
  borders: ['CMR', 'CAF', 'LBY', 'NER', 'NGA', 'SSD'],
  area: 1284000,
  provinces: [
    'Batha',
    'Biltine',
    'Borkou-Ennedi-Tibesti',
    'Chari-Baguirmi',
    'Guera',
    'Kanem',
    'Lac',
    'Logone Occidental',
    'Logone Oriental',
    'Mayo-Kebbi',
    'Moyen-Chari',
    'Ouaddai',
    'Salamat',
    'Tandjile',
  ],
  nativeName: 'Tchad',
  timezones: ['UTC+01:00'],
} as const;
