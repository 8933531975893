export const PER = {
  name: {
    common: 'Peru',
    official: 'Republic of Peru',
    native: {
      aym: { official: 'Piruw Suyu', common: 'Piruw' },
      que: { official: 'Piruw Ripuwlika', common: 'Piruw' },
      spa: { official: 'República del Perú', common: 'Perú' },
    },
  },
  cities: [
    'Lima',
    'Cerro de Pasco',
    'Santiago',
    'San Miguel',
    'Cusco',
    'Arequipa',
    'Iquitos',
    'Callao',
    'San Isidro',
    'Chimbote',
    'Machu Picchu',
    'Juliaca',
    'San Borja',
    'Tarapoto',
    'Ica',
    'Huancayo',
    'Puno',
    'Ayacucho',
    'Piura',
    'Abancay',
    'San Juan Bautista',
    'Trujillo',
    'Pimentel',
    'Chiclayo',
    'Huancavelica',
    'Bellavista',
    'Victoria',
    'Chincha',
    'Huaraz',
    'Jose Olaya',
    'Jesus Maria',
    'Puerto Maldonado',
    'Huacho',
    'Pucallpa',
    'Tacna',
    'Ilo',
    'Lambayeque',
    'Paucarpata',
    'Lince',
    'La Punta',
    'Bolivar',
    'La Perla',
    'Surquillo',
    'San Martin',
    'Lurigancho',
    'Barranco',
    'La Molina',
    'Rimac',
    'Ate',
    'El Agustino',
    'Santiago De Surco',
    'Barranca',
    'Cajamarca',
    'Huaytara',
    'Huaral',
    'Jaen',
    'Junin',
    'Surco',
    'Cuzco',
    'Calle',
    'Ventanilla',
    'HuÃ¡nuco',
    'Moquegua',
    'Talara',
    'Chachapoyas',
    'San Martin',
    'Minas de Marcona',
    'Maldonado',
    'Bagua',
    'Caras',
    'Loreto',
    'Hacienda La Palma',
    'Rioja',
    'Pisco',
    'Imperial',
    'Tingo Maria',
    'Tumbes',
    'Moyobamba',
    'Miraflores',
    'Puerto Inca',
    'Pasco',
    'Sullana',
    'Progreso',
    'Rimac',
    'Santa',
    'San Isidro',
  ],
  tld: ['.pe'],
  cca2: 'PE',
  ccn3: '604',
  cca3: 'PER',
  cioc: 'PER',
  currency: ['PEN'],
  callingCode: ['51'],
  capital: 'Lima',
  altSpellings: ['PE', 'Republic of Peru', 'República del Perú'],
  region: 'Americas',
  subregion: 'South America',
  languages: { aym: 'Aymara', que: 'Quechua', spa: 'Spanish' },
  translations: {
    deu: { official: 'Republik Peru', common: 'Peru' },
    fra: { official: 'République du Pérou', common: 'Pérou' },
    hrv: { official: 'Republika Peru', common: 'Peru' },
    ita: { official: 'Repubblica del Perù', common: 'Perù' },
    jpn: { official: 'ペルー共和国', common: 'ペルー' },
    nld: { official: 'Republiek Peru', common: 'Peru' },
    por: { official: 'República do Peru', common: 'Perú' },
    rus: { official: 'Республика Перу', common: 'Перу' },
    spa: { official: 'República de Perú', common: 'Perú' },
    fin: { official: 'Perun tasavalta', common: 'Peru' },
  },
  latlng: [-10, -76],
  demonym: 'Peruvian',
  landlocked: false,
  borders: ['BOL', 'BRA', 'CHL', 'COL', 'ECU'],
  area: 1285216,
  provinces: [
    'Amazonas',
    'Ancash',
    'Apurimac',
    'Arequipa',
    'Ayacucho',
    'Cajamarca',
    'Callao',
    'Cusco',
    'Huancavelica',
    'Huanuco',
    'Ica',
    'Junin',
    'La Libertad',
    'Lambayeque',
    'Lima',
    'Loreto',
    'Madre de Dios',
    'Moquegua',
    'Pasco',
    'Piura',
    'Puno',
    'San Martin',
    'Tacna',
    'Tumbes',
    'Ucayali',
  ],
  nativeName: 'Perú',
  timezones: ['UTC−05:00'],
} as const;
