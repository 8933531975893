import Vue from 'vue';

import { AuthClient } from './auth-client';

declare module 'vue/types/vue' {
  interface Vue {
    /** {@link AuthClient} instance used by the application. */
    $auth: AuthClient;
  }
}

export * from './types';

/** Dedicated app authentication client instance. */
export const auth = new AuthClient();

Vue.prototype.$auth = auth;
