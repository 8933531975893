export const MUS = {
  name: {
    common: 'Mauritius',
    official: 'Republic of Mauritius',
    native: {
      eng: { official: 'Republic of Mauritius', common: 'Mauritius' },
      fra: { official: 'République de Maurice', common: 'Maurice' },
      mfe: { official: 'Republik Moris', common: 'Moris' },
    },
  },
  cities: [
    'Port Louis',
    'Beau Bassin',
    'Quatre Bornes',
    'Goodlands',
    'Tamarin',
    'Rose Hill',
    'Floreal',
    'Port Mathurin',
    'Chemin Grenier',
    'Ebene CyberCity',
    'Le Reduit',
    'Vacoas',
    'Saint Jean',
  ],
  tld: ['.mu'],
  cca2: 'MU',
  ccn3: '480',
  cca3: 'MUS',
  cioc: 'MRI',
  currency: ['MUR'],
  callingCode: ['230'],
  capital: 'Port Louis',
  altSpellings: ['MU', 'Republic of Mauritius', 'République de Maurice'],
  region: 'Africa',
  subregion: 'Eastern Africa',
  languages: { eng: 'English', fra: 'French', mfe: 'Mauritian Creole' },
  translations: {
    deu: { official: 'Republik Mauritius', common: 'Mauritius' },
    fra: { official: 'République de Maurice', common: 'Île Maurice' },
    hrv: { official: 'Republika Mauricijus', common: 'Mauricijus' },
    ita: { official: 'Repubblica di Mauritius', common: 'Mauritius' },
    jpn: { official: 'モーリシャス共和国', common: 'モーリシャス' },
    nld: { official: 'Republiek Mauritius', common: 'Mauritius' },
    por: { official: 'República das Maurícias', common: 'Maurício' },
    rus: { official: 'Республика Маврикий', common: 'Маврикий' },
    spa: { official: 'República de Mauricio', common: 'Mauricio' },
    fin: { official: 'Mauritiuksen tasavalta', common: 'Mauritius' },
  },
  latlng: [-20.28333333, 57.55],
  demonym: 'Mauritian',
  landlocked: false,
  borders: [],
  area: 2040,
} as const;
