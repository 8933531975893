export const MWI = {
  name: {
    common: 'Malawi',
    official: 'Republic of Malawi',
    native: {
      eng: { official: 'Republic of Malawi', common: 'Malawi' },
      nya: { official: 'Chalo cha Malawi, Dziko la Malaŵi', common: 'Malaŵi' },
    },
  },
  cities: ['Made', 'Mzuzu', 'Blantyre', 'Lilongwe'],
  tld: ['.mw'],
  cca2: 'MW',
  ccn3: '454',
  cca3: 'MWI',
  cioc: 'MAW',
  currency: ['MWK'],
  callingCode: ['265'],
  capital: 'Lilongwe',
  altSpellings: ['MW', 'Republic of Malawi'],
  region: 'Africa',
  subregion: 'Eastern Africa',
  languages: { eng: 'English', nya: 'Chewa' },
  translations: {
    deu: { official: 'Republik Malawi', common: 'Malawi' },
    fra: { official: 'République du Malawi', common: 'Malawi' },
    hrv: { official: 'Republika Malavi', common: 'Malavi' },
    ita: { official: 'Repubblica del Malawi', common: 'Malawi' },
    jpn: { official: 'マラウイ共和国', common: 'マラウイ' },
    nld: { official: 'Republiek Malawi', common: 'Malawi' },
    por: { official: 'República do Malawi', common: 'Malawi' },
    rus: { official: 'Республика Малави', common: 'Малави' },
    spa: { official: 'República de Malawi', common: 'Malawi' },
    fin: { official: 'Malawin tasavalta', common: 'Malawi' },
  },
  latlng: [-13.5, 34],
  demonym: 'Malawian',
  landlocked: true,
  borders: ['MOZ', 'TZA', 'ZMB'],
  area: 118484,
  provinces: [
    'Balaka',
    'Blantyre',
    'Chikwawa',
    'Chiradzulu',
    'Chitipa',
    'Dedza',
    'Dowa',
    'Karonga',
    'Kasungu',
    'Likoma',
    'Lilongwe',
    'Machinga (Kasupe)',
    'Mangochi',
    'Mchinji',
    'Mulanje',
    'Mwanza',
    'Mzimba',
    'Nkhata Bay',
    'Nkhotakota',
    'Nsanje',
    'Ntcheu',
    'Ntchisi',
    'Phalombe',
    'Rumphi',
    'Salima',
    'Thyolo',
    'Zomba',
  ],
  nativeName: 'Malawi',
  timezones: ['UTC+02:00'],
} as const;
