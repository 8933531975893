export const LBY = {
  name: {
    common: 'Libya',
    official: 'State of Libya',
    native: { ara: { official: 'الدولة ليبيا', common: '‏ليبيا' } },
  },
  cities: ['Tripoli', 'Benghazi', 'Misratah', 'Zliten', 'Sabha'],
  tld: ['.ly'],
  cca2: 'LY',
  ccn3: '434',
  cca3: 'LBY',
  cioc: 'LBA',
  currency: ['LYD'],
  callingCode: ['218'],
  capital: 'Tripoli',
  altSpellings: ['LY', 'State of Libya', 'Dawlat Libya'],
  region: 'Africa',
  subregion: 'Northern Africa',
  languages: { ara: 'Arabic' },
  translations: {
    deu: { official: 'Staat Libyen', common: 'Libyen' },
    fra: {
      official: 'Grande République arabe libyenne populaire et socialiste',
      common: 'Libye',
    },
    hrv: { official: 'Država Libiji', common: 'Libija' },
    ita: { official: 'Stato della Libia', common: 'Libia' },
    jpn: { official: 'リビアの国家', common: 'リビア' },
    nld: { official: 'Staat van Libië', common: 'Libië' },
    por: { official: 'Estado da Líbia', common: 'Líbia' },
    rus: { official: 'Государство Ливии', common: 'Ливия' },
    spa: { official: 'Estado de Libia', common: 'Libia' },
    fin: { official: 'Libyan valtio', common: 'Libya' },
  },
  latlng: [25, 17],
  demonym: 'Libyan',
  landlocked: false,
  borders: ['DZA', 'TCD', 'EGY', 'NER', 'SDN', 'TUN'],
  area: 1759540,
  provinces: [
    'Ajdabiya',
    "Al 'Aziziyah",
    'Al Fatih',
    'Al Jabal al Akhdar',
    'Al Jufrah',
    'Al Khums',
    'Al Kufrah',
    'An Nuqat al Khams',
    "Ash Shati'",
    'Awbari',
    'Az Zawiyah',
    'Banghazi',
    'Darnah',
    'Ghadamis',
    'Gharyan',
    'Misratah',
    'Murzuq',
    'Sabha',
    'Sawfajjin',
    'Surt',
    'Tarabulus',
    'Tarhunah',
    'Tubruq',
    'Yafran',
    'Zlitan',
  ],
  nativeName: '‏ليبيا',
  timezones: ['UTC+01:00'],
} as const;
