var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.NavDropdown,
    { ref: "dropdown" },
    [
      _c("FormSelect", {
        staticClass: "m-0",
        attrs: { label: "Appearance", options: _setup.THEME_OPTIONS },
        model: {
          value: _setup.theme,
          callback: function ($$v) {
            _setup.theme = $$v
          },
          expression: "theme",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }