export const IRL = {
  name: {
    common: 'Ireland',
    official: 'Republic of Ireland',
    native: {
      eng: { official: 'Republic of Ireland', common: 'Ireland' },
      gle: { official: 'Poblacht na hÉireann', common: 'Éire' },
    },
  },
  cities: [
    'Roscommon',
    'Roscrea',
    'Mountrath',
    'Dublin',
    'Porterstown',
    'Carrigaline',
    'Cork',
    'Ballina',
    'Manorhamilton',
    'Kells',
    'Listowel',
    'Lisselton',
    'Carrick',
    'Galway',
    'Tipperary',
    'Carlow',
    'Abbeyleix',
    'Mullinavat',
    'Maynooth',
    'Tallaght',
    'Templeogue',
    'Swords',
    'Bray',
    'Cabinteely',
    'Clondalkin',
    'Ballyfermot',
    'Artane',
    'Malahide',
    'Limerick',
    'Saggart',
    'Prosperous',
    'Castleknock',
    'Clonsilla',
    'Kilkenny',
    'Droichead Nua',
    'Kildare',
    'Celbridge',
    'Lucan',
    'Blackrock',
    'Dundrum',
    'Wicklow',
    'Rathgar',
    'Stillorgan',
    'Mullingar',
    'Clonmel',
    'Sandyford',
    'Ashbourne',
    'Athlone',
    'Marino',
    'Dalkey',
    'Finglas',
    'Glasnevin',
    'Naas',
    'Blessington',
    'Waterford',
    'Leixlip',
    'Walkinstown',
    'Mayo',
    'Terenure',
    'Clane',
    'Killarney',
    'Trim',
    'Seafield',
    'Mount Merrion',
    'Glen',
    'Nenagh',
    'Shannon',
    'Kilbride Cross Roads',
    'Killybegs',
    'Sligo',
    'Athboy',
    'Crosshaven',
    'Drogheda',
    'Youghal',
    'Greystones',
    'Ballsbridge',
    'Foxrock',
    'Shankill',
    'Killiney',
    'Abbeyfeale',
    'Dunshaughlin',
    'Navan',
    'Ballincollig',
    'Ardee',
    'Ballyroe',
    'Sandymount',
    'Slieve',
    'Macroom',
    'Wexford',
    'Blanchardstown',
    'Milltown',
    'Dundalk',
    'Athy',
    'Kilrush',
    'Dungarvan',
    'Gorey',
    'Letterkenny',
    'Ballymahon',
    'Island',
    'Stepaside',
    'Ballintober',
    'Firhouse',
    'Cabra',
    'Donabate',
    'Coolock',
    'Rathfarnham',
    'Thurles',
    'Kilcock',
    'Sutton',
    'Newport',
    'Monaghan',
    'Hospital',
    'Bettystown',
    'River',
    'Tralee',
    'Ballybrit',
    'Cobh',
    'Enniskerry',
    'Duleek',
    'Rush',
    'Lusk',
    'Mitchelstown',
    'Kanturk',
    'Balbriggan',
    'Rathmolyon',
    'Crumlin',
    'Clontarf',
    'Kingswood',
    'Kinsale',
    'Dunboyne',
    'Caher',
    'Broadford',
    'Kilmichael',
    'Berrings',
    'Ballineen',
    'Tyrrellspass',
    'Donegal',
    'Bundoran',
    'Glenties',
    'Killygordon',
    'Athenry',
    'Moycullen',
    'Mallow',
    'Castlebar',
    'Ratoath',
    'Bandon',
    'Douglas',
    'Glengarriff',
    'Ballygarvan',
    'Clonakilty',
    'Ballyphilip',
    'Boherbue',
    'Kilfinane',
    'Fermoy',
    'Carbury',
    'Monasterevin',
    'Monkstown',
    'Clogherhead',
    'Enfield',
    'Edenderry',
    'Kildalkey',
    'Castlerea',
    'Stradbally',
    'Skerries',
    'Garristown',
    'Santry',
    'Ballybrack',
    'DÃºn Laoghaire',
    'Leopardstown',
    'Ballyhooly',
    'Claregalway',
    'Dunlavin',
    'Straffan',
    'Summerhill',
    'Ballymount',
    'Courtown',
    'Loughrea',
    'Enniscorthy',
    'Portarlington',
    'Buncrana',
    'Cashel',
    'Carrickmacross',
    'Geevagh',
    'Killala',
    'Carrick on Shannon',
    'Saint Mullins',
    'Carrickmines',
    'Killorglin',
    'Passage West',
    'Ennis',
    'Oysterhaven',
    'Cavan',
    'Virginia',
    'Clones',
    'Bailieborough',
    'Oranmore',
    'Shrule',
    'Callan',
    'Tullow',
    'Carnew',
    'New Ross',
    'Castlebridge',
    'Glenealy',
    'Carrick-on-Suir',
    'Ballymote',
    'Longford',
    'Ballivor',
    'Louth',
    'Castleblayney',
    'Birr',
    'Clarecastle',
    'Tullamore',
    'Ballon',
    'Kingscourt',
    'Coachford',
    'Clonee',
    'Kilmainham',
    'County Wexford',
    'Balgriffin',
    'Kiltamagh',
    'Foxford',
    'Westport',
    'Claremorris',
    'Swinford',
    'Foynes',
    'Sixmilebridge',
    'Newcastle West',
    'Grange',
    'Dunleer',
    'Longwood',
    'Killaloe',
    'Feakle',
    'Baldoyle',
    'Windy Arbour',
    'Doughiska',
    'Thomastown',
    'Raheny',
    'Dundrum',
    'Rathowen',
    'Patrickswell',
    'Delgany',
    'Ballyvaghan',
    'Doolin',
    'Killurin',
    'Midleton',
    'Ringaskiddy',
    'Skibbereen',
    'Ballinadee',
    'Kinvarra',
    'Oughterard',
    'Ballinrobe',
    'Tuam',
    'Headford',
    'Spiddal',
    'Gort',
    'Williamstown',
    'Ballinasloe',
    'Cahersiveen',
    'Bantry',
    'Crookhaven',
    'Portumna',
    'County Galway',
    'Kilmore',
    'Leamlara',
    'Bagenalstown',
    'Ballyragget',
    'Carraroe',
    'Killinick',
    'Crusheen',
    'Glanmire',
    'Arklow',
    'Kilcoole',
    'Kilcullen',
    'Ferbane',
    'Mornington',
    'Naul',
    'Donnybrook',
    'Portmarnock',
    'Ballycullen',
    'Sallins',
    'Irishtown',
    'Ballymun',
    'Jamestown',
    'Bodyke',
    'Rathcoole',
    'Rathmines',
    'Inchicore',
    'Kenmare',
    'Sallynoggin',
    'Carrigtohill',
    'Cross',
    'Castlemaine',
    'Newmarket',
  ],
  tld: ['.ie'],
  cca2: 'IE',
  ccn3: '372',
  cca3: 'IRL',
  cioc: 'IRL',
  currency: ['EUR'],
  callingCode: ['353'],
  capital: 'Dublin',
  altSpellings: ['IE', 'Éire', 'Republic of Ireland', 'Poblacht na hÉireann'],
  region: 'Europe',
  subregion: 'Northern Europe',
  languages: { eng: 'English', gle: 'Irish' },
  translations: {
    deu: { official: 'Republik Irland', common: 'Irland' },
    fra: { official: "République d'Irlande", common: 'Irlande' },
    hrv: { official: 'Republika Irska', common: 'Irska' },
    ita: { official: "Repubblica d'Irlanda", common: 'Irlanda' },
    jpn: { official: 'アイルランド共和国', common: 'アイルランド' },
    nld: { official: 'Republic of Ireland', common: 'Ierland' },
    por: { official: 'República da Irlanda', common: 'Irlanda' },
    rus: { official: 'Ирландия', common: 'Ирландия' },
    spa: { official: 'República de Irlanda', common: 'Irlanda' },
    fin: { official: 'Irlannin tasavalta', common: 'Irlanti' },
  },
  latlng: [53, -8],
  demonym: 'Irish',
  landlocked: false,
  borders: ['GBR'],
  area: 70273,
  provinces: [
    'Carlow',
    'Cavan',
    'Clare',
    'Cork',
    'Donegal',
    'Dublin',
    'Galway',
    'Kerry',
    'Kildare',
    'Kilkenny',
    'Laois',
    'Leitrim',
    'Limerick',
    'Longford',
    'Louth',
    'Mayo',
    'Meath',
    'Monaghan',
    'Offaly',
    'Roscommon',
    'Sligo',
    'Tipperary',
    'Waterford',
    'Westmeath',
    'Wexford',
    'Wicklow',
  ],
  nativeName: 'Éire',
  timezones: ['UTC'],
} as const;
