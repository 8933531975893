export const BRA = {
  name: {
    common: 'Brazil',
    official: 'Federative Republic of Brazil',
    native: {
      por: { official: 'República Federativa do Brasil', common: 'Brasil' },
    },
  },
  cities: [
    'Sao Paulo',
    'Sorocaba',
    'Curitiba',
    'Salvador',
    'Recife',
    'Porto Alegre',
    'Campinas',
    'Rio de Janeiro',
    'BrasÃ­lia',
    'Lagoa',
    'Tibau',
    'Campos dos Goytacazes',
    'Itaquaquecetuba',
    'Bocaiuva do Sul',
    'PetrÃ³polis',
    'SÃ£o JosÃ© dos Campos',
    'FlorianÃ³polis',
    'Belo Horizonte',
    'Campina Grande',
    'JoÃ£o Pessoa',
    'Rio Branco',
    'Ariquemes',
    'GoiÃ¢nia',
    'Dourados',
    'Terenos',
    'Ceilandia',
    'Caarapo',
    'Porto Nacional',
    'AnÃ¡polis',
    'Rio Verde de Mato Grosso',
    'Cacoal',
    'Ponta Pora',
    'Vilhena',
    'VÃ¡rzea Grande',
    'CuiabÃ¡',
    'Campo Grande',
    'Palmas',
    'Marialva',
    'Fenix',
    'AraucÃ¡ria',
    'Palmas',
    'Ponta Grossa',
    'Sao Francisco do Sul',
    'Aguas de Chapeco',
    'Ararangua',
    'Timbo',
    'Itapema',
    'Rio Negrinho',
    'Forquilhinha',
    'Icara',
    'Joinville',
    'Brusque',
    'Blumenau',
    'Sombrio',
    'Imbituba',
    'Orleans',
    'Palhoca',
    'Cascavel',
    'Colombo',
    'Rio Branco do Sul',
    'Borrazopolis',
    'SÃ£o JosÃ© dos Pinhais',
    'Rio Brilhante',
    'Juruena',
    'Posto Fiscal Rolim de Moura',
    'Senador Canedo',
    'Mutum',
    'Pelotas',
    'Ibiaca',
    'Caxias do Sul',
    'Rio Grande',
    'Eldorado',
    'Ijui',
    'Santiago',
    'Jatai',
    'Sobradinho',
    'Porto Velho',
    'JaraguÃ¡ do Sul',
    'Araquari',
    'Rio do Sul',
    'Ibirama',
    'Indaial',
    'Tubarao',
    'CriciÃºma',
    'Novo Hamburgo',
    'Dom Feliciano',
    'Rosario do Sul',
    'Farroupilha',
    'Canela',
    'Capao do Leao',
    'GuaÃ­ba',
    'Campo Bom',
    'SÃ£o Leopoldo',
    'ViamÃ£o',
    'Panambi',
    'Sao Sebastiao do Cai',
    'Virmond',
    'Chopinzinho',
    'Cambe',
    'Sarandi',
    'Imbituva',
    'Guaramirim',
    'Camboriu',
    'Mafra',
    'Apiuna',
    'Santo',
    'Xaxim',
    'Nova Palma',
    'Sao Jose',
    'Goias',
    'RondonÃ³polis',
    'Acorizal',
    'TrÃªs Lagoas',
    'Guara',
    'Santa Rosa',
    'Porteira do Pinhal',
    'Canoas',
    'Dom Pedrito',
    'Dois Irmaos',
    'Taquara',
    'Boa Vista do Burica',
    'Uniao da Vitoria',
    'Arapongas',
    'Luziania',
    'Miranda',
    'Horizontina',
    'Alvorada',
    'Butia',
    'Taquari',
    'Lima Campos',
    'Sapucaia',
    'Cachoeira do Sul',
    'Flores da Cunha',
    'Foz do IguaÃ§u',
    'ParanaguÃ¡',
    'Castro',
    'Lapa',
    'MaringÃ¡',
    'Campo Largo',
    'Guaratuba',
    'Novo Recreio',
    'Caceres',
    'Garopaba',
    'Gaspar',
    'SÃ£o Bento do Sul',
    'Andradina',
    'Santa Maria',
    'Nova Prata',
    'Vera Cruz',
    'Paverama',
    'Cachoeirinha',
    'Tramandai',
    'Mato Grosso',
    'Miracema do Tocantins',
    'Realeza',
    'Guarapuava',
    'Inhumas',
    'Bela Vista',
    'Quarai',
    'Candelaria',
    'GravataÃ­',
    'Lambari',
    'Morro Agudo',
    'Sao Miguel',
    'Urussanga',
    'Itauna',
    'MarabÃ¡',
    'Sao Sepe',
    'Bandeirantes',
    'Venancio Aires',
    'Porto Ferreira',
    'Jaci',
    'SÃ£o Paulo',
    'Embu',
    'Caico',
    'Jucurutu',
    'Santana do Matos',
    'Amparo',
    'Limeira',
    'Camacari',
    'RibeirÃ£o Preto',
    'Fernandopolis',
    'Barrinha',
    'Igarapava',
    'Catanduva',
    'Americo Brasiliense',
    'Mirassol',
    'Jaborandi',
    'Monte Aprazivel',
    'Araraquara',
    'Jaguariuna',
    'Rio Claro',
    'Ipero',
    'Leme',
    'Piracicaba',
    'Cosmopolis',
    'HortolÃ¢ndia',
    'Itapira',
    'Louveira',
    'Porto Feliz',
    'Capivari',
    'Americana',
    'Iracemapolis',
    'Cerquilho',
    'Serra Negra',
    'Valinhos',
    'Mogi Mirim',
    'Candido Mota',
    'Presidente Venceslau',
    'Presidente Prudente',
    'Poa',
    'Guarulhos',
    'Caieiras',
    'Varzea Paulista',
    'Francisco Morato',
    'JundiaÃ­',
    'Cabreuva',
    'Franco da Rocha',
    'SÃ£o Bernardo do Campo',
    'Sao Vicente',
    'Itanhaem',
    'Carapicuiba',
    'Santo Antonio do Pinhal',
    'Cachoeira Paulista',
    'Guaratingueta',
    'Lorena',
    'Tremembe',
    'Canas',
    'JacareÃ­',
    'Jacupiranga',
    'Santos',
    'Santo AndrÃ©',
    'Diadema',
    'Itapecerica da Serra',
    'Barueri',
    'Cotia',
    'Santana de Parnaiba',
    'Osasco',
    'Jandira',
    'Itu',
    'Taboao da Serra',
    'Itapevi',
    'Embu Guacu',
    'Varginha',
    'Medianeira',
    'Sao Miguel do Iguacu',
    'Extrema',
    'Sao Goncalo',
    'Palmeira',
    'Sao Luiz Gonzaga',
    'Barro',
    'Sao Marcos',
    'Nova Bassano',
    'Vacaria',
    'Sete Lagoas',
    'Estancia',
    'Goncalves',
    'Paraisopolis',
    'Montes Claros',
    'Sao Joao de Meriti',
    'Xanxere',
    'Caxambu',
    'Volta Redonda',
    'Itatiaia',
    'Duque de Caxias',
    'Seropedica',
    'Japeri',
    'Penha',
    'ItajaÃ­',
    'Vinhedo',
    'Rialma',
    'Ceres',
    'Teresina',
    'Piracanjuba',
    'Ipameri',
    'Roca Sales',
    'Passos',
    'Garibaldi',
    'Alegrete',
    'Marataizes',
    'Nova IguaÃ§u',
    'Cachoeiro de Itapemirim',
    'Santa Rita do Passa Quatro',
    'SÃ£o LourenÃ§o',
    'Lagarto',
    'Salgado',
    'Poco Verde',
    'Erechim',
    'Delfinopolis',
    'Feliz',
    'Maua',
    'Domingos Martins',
    'Jaiba',
    'NiterÃ³i',
    'Natal',
    'Itaporanga',
    'Abaetetuba',
    'Ananindeua',
    'MacapÃ¡',
    'Santa Isabel do Para',
    'Ipira',
    'Paulo Jacinto',
    'MaceiÃ³',
    'Arapiraca',
    'Rio Largo',
    'Balsas',
    'AraguaÃ­na',
    'Prata',
    'Londrina',
    'Rolandia',
    'Paranavai',
    'Pinhais',
    'Pinhoes',
    'Quatro Barras',
    'Freitas',
    'Olinda',
    'Paulista',
    'Cariacica',
    'VitÃ³ria',
    'Vila Velha',
    'Jaboatao dos Guararapes',
    'Igarassu',
    'Serra',
    'Tijuca',
    'Taguatinga',
    'Taguatinga',
    'Trindade',
    'Xavantina',
    'Paranoa',
    'Esteio',
    'Passo Fundo',
    'Estancia Velha',
    'Nossa Senhora Das Gracas',
    'Camaqua',
    'Suzano',
    'Juiz de Fora',
    'Contagem',
    'Ibirite',
    'Marica',
    'Cocal',
    'Caucaia',
    'Maracanau',
    'Bento GonÃ§alves',
    'Brumadinho',
    'Itatinga',
    'Anhembi',
    'Itapeva',
    'Aracaju',
    'Nossa Senhora',
    'Sao Domingos',
    'Itabaiana',
    'Serafina',
    'Parai',
    'Marau',
    'Guapore',
    'Simoes',
    'Uchoa',
    'Barretos',
    'Granja',
    'Marco',
    'Acarau',
    'Paraipaba',
    'Veranopolis',
    'Sao Sebastiao da Amoreira',
    'Mangueirinha',
    'Itapua',
    'Navegantes',
    'Alto Piquiri',
    'Matinhos',
    'Cornelio Procopio',
    'Ipiranga',
    'Nova Brescia',
    'Encantado',
    'Quixeramobim',
    'BagÃ©',
    'Tambau',
    'Ibiruba',
    'Itajuba',
    'Flexeiras',
    'Bom Conselho',
    'Canhotinho',
    'Calcado',
    'Garanhuns',
    'Corguinho',
    'Sao Gabriel',
    'Governador Valadares',
    'Itabirinha de Mantena',
    'Itanhomi',
    'Lavras da Mangabeira',
    'Monteiro',
    'Mairinque',
    'Doutor Mauricio Cardoso',
    'Ipatinga',
    'Nazare',
    'Jaguaripe',
    'Muniz Ferreira',
    'Matozinhos',
    'Grao Mogol',
    'Sao Domingos',
    'Sao Gotardo',
    'Santo Estevao',
    'Madre de Deus',
    'Carpina',
    'Barra do GarÃ§as',
    'Rio Pardo',
    'Minas',
    'Tucum',
    'Braganca Paulista',
    'Bauru',
    'Manaus',
    'Sao Caetano do Sul',
    'Praia Grande',
    'Mogi das Cruzes',
    'Lajeado',
    'Itapetininga',
    'Guaruja',
    'Franca',
    'Sacramento',
    'SÃ£o Carlos',
    'MarÃ­lia',
    'Aracatuba',
    'Indaiatuba',
    'Cubatao',
    'Atibaia',
    'Lagoa dos Gatos',
    'Amaraji',
    'Gloria do Goita',
    'Sao Joaquim do Monte',
    'Palmares',
    'Cortes',
    'Escada',
    'Moreno',
    'Santa Cruz do Capibaribe',
    'Pesqueira',
    'Diamantina',
    'Serro',
    'Tres Marias',
    'Santa Fe',
    'Sumare',
    'Votorantim',
    'TatuÃ­',
    'Rio Pardo de Minas',
    'Conceicao do Araguaia',
    'Monte Belo',
    'Sao Jose da Barra',
    'Cassia',
    'Pouso Alegre',
    'Juruaia',
    'Campos Novos',
    'Dores do Indaia',
    'Bambui',
    'Cataguases',
    'Duas Barras',
    'Mar de Espanha',
    'Alto Santo',
    'Morada Nova',
    'Mossoro',
    'Gomes',
    'Acopiara',
    'Iguatu',
    'Piquet Carneiro',
    'Limoeiro do Norte',
    'Russas',
    'Pau dos Ferros',
    'Coronel',
    'Norte',
    'Juazeiro do Norte',
    'Eloi Mendes',
    'Lavrinhas',
    'Parana',
    'Banabuiu',
    'Lagoa da Prata',
    'Boa Esperanca',
    'Vicosa',
    'MuriaÃ©',
    'Charqueadas',
    'Sao Jeronimo',
    'Arroio dos Ratos',
    'Colinas',
    'Assis Chateaubriand',
    'Guanambi',
    'Bom Jesus da Lapa',
    'VitÃ³ria da Conquista',
    'Matina',
    'Brejo Santo',
    'Tres Pontas',
    'Muqui',
    'Vargem Alta',
    'Nossa Senhora do Socorro',
    'Torres',
    'Tres Cachoeiras',
    'Valentim Gentil',
    'Sao Sebastiao do Paraiso',
    'Pimenta',
    'Vista Alegre do Alto',
    'Taquaral',
    'Ferraz de Vasconcelos',
    'Igrejinha',
    'Formiga',
    'Palotina',
    'Itajuipe',
    'Iacu',
    'Saquarema',
    'Araruama',
    'Sao Pedro da Aldeia',
    'Sao Vicente de Paula',
    'Sao Tome',
    'Pitangueiras',
    'Francisco Beltrao',
    'Linhares',
    'Itaipu',
    'Colatina',
    'Camaragibe',
    'Guararapes',
    'Itamaraca',
    'Campos',
    'Belford Roxo',
    'Brasilandia',
    'Almirante Tamandare',
    'Esmeraldas',
    'Betim',
    'Salvador',
    'Cabo de Santo Agostinho',
    'Jaguaruna',
    'Aracuai',
    'Itabira',
    'Jacarepagua',
    'Conde',
    'Brasopolis',
    'Lavras',
    'Unai',
    'Brumado',
    'Caetite',
    'Itamarati',
    'Rio Bonito',
    'Tabuleiro do Norte',
    'Cardeal da Silva',
    "Itaporanga d'Ajuda",
    'Belem',
    'Monte Azul',
    'Jerico',
    'Carnaiba',
    'Afogados da Ingazeira',
    'SÃ£o LuÃ­s',
    'Botucatu',
    'Itaguai',
    'Rio Bonito',
    'Ouro Fino',
    'Stio Ouro Fino',
    'Borda da Mata',
    'Ponte Nova',
    'Oratorios',
    'Urucania',
    'Sem Peixe',
    'Dom Silverio',
    'Santo Antonio do Grama',
    'Salto',
    'Agulha',
    'Araras',
    'Sao Jose do Ouro',
    'Campo Mourao',
    'Itirapina',
    'Itanhandu',
    'Ibaiti',
    'Japira',
    'Bom Jesus',
    'Itumbiara',
    'Ararica',
    'Miraima',
    'Cordeiropolis',
    'Estrela',
    'Mogi-Gaucu',
    'Estreito',
    'Petrolina',
    'Laranjeiras',
    'Sao Cristovao',
    'Antonio Carlos',
    'Biguacu',
    'Tijucas',
    'Araguari',
    'Nova Venecia',
    'Mantena',
    'Valerio',
    'Marilandia',
    'Boa Esperanca',
    'Nova Friburgo',
    'Pedranopolis',
    'BelÃ©m',
    'Carolina',
    'Sao Geraldo do Araguaia',
    'Nova Esperanca',
    'Presidente Medici',
    'Piraju',
    'Ourinhos',
    'Jacarezinho',
    'Altamira',
    'Itaperuna',
    'Teresopolis',
    'Guapimirim',
    'Parapeuna',
    'Santo Antonio',
    'Aracati',
    'Icapui',
    'Sao Bernardo',
    'Crateus',
    'RibeirÃ£o das Neves',
    'Joao Monlevade',
    'Bom Jesus dos Perdoes',
    'Vilar dos Teles',
    'Sao Domingos',
    'Vargem Grande Paulista',
    'Tiete',
    'Monte Mor',
    'Parnamirim',
    'Sao Goncalo do Amarante',
    'Mesquita',
    'Aparecida',
    'Manacapuru',
    'Alagoinhas',
    'Santa Rita do Sapucai',
    'Sape',
    'Sao Fidelis',
    'Oeiras',
    'Picos',
    'Novo Oriente',
    'Simplicio Mendes',
    'Santo Antonio de Lisboa',
    'Betania',
    'Morro da Fumaca',
    'Aguas Lindas',
    'Uberlandia',
    'Aracas',
    'Sao Jose do Rio Preto',
    'Guajeru',
    'Tanque Novo',
    'Bom Jardim de Minas',
    'Liberdade',
    'Mococa',
    'Bela Vista de Goias',
    'Santa',
    'Niquelandia',
    'Itapaci',
    'Santo Antonio de Padua',
    'Formosa',
    'Maraial',
    'Girau do Ponciano',
    'Pedreira',
    'Sao Pedro do Sul',
    'Serra',
    'Barra do Bugres',
    'Votuporanga',
    'Biritiba Mirim',
    'Cajamar',
    'Campos do Jordao',
    'Iguape',
    'Peruibe',
    'Taubate',
    'Pindamonhangaba',
    'Olimpia',
    'Sertaozinho',
    'Sao Sebastiao da Grama',
    'Serrana',
    'Dumont',
    'Novo Horizonte',
    'Aracoiaba da Serra',
    'Paulinia',
    'Pirapozinho',
    'Boituva',
    'Santa Luzia',
    'Angra dos Reis',
    'Nova Odessa',
    'Pontal',
    'Morrinhos',
    'Palmeira das Missoes',
    'Sarandi',
    'Carazinho',
    'Paraty',
    'Umbauba',
    'Tobias Barreto',
    'Ivaipora',
    'Prudentopolis',
    'Pirassununga',
    'Santa Maria',
    'Itaguacu',
    'Santa Teresa',
    'Barra Mansa',
    'Quatis',
    "Aparecida d'Oeste",
    'Cristalina',
    'Sao Gabriel',
    'Vera Cruz',
    'Sao Paulo do Potengi',
    'Palmares do Sul',
    'Guarei',
    'Queimados',
    'Itabaianinha',
    'Tomar do Geru',
    'Tabira',
    'Teixeira',
    'Osorio',
    'Apodi',
    'Rolante',
    'Bonito',
    'Itubera',
    'Conceicao',
    'Seabra',
    'Navirai',
    'Fatima',
    'Maxaranguape',
    'Touros',
    'Pomerode',
    'Sitio Figueira',
    'Santo Antonio do Amparo',
    'Sertania',
    'Terra Rica',
    "Palmeira d'Oeste",
    'Sousa',
    'Pombal',
    'Miguel Pereira',
    'Patos',
    'Santa Luzia',
    'Porto Uniao',
    'Vicencia',
    'Macaparana',
    'Umbuzeiro',
    'Orobo',
    'Maravilha',
    'Abelardo Luz',
    'Quilombo',
    "Sao Miguel d'Oeste",
    'Cordilheira',
    'Itamonte',
    'TrÃªs CoraÃ§Ãµes',
    'Sao Goncalo do Sapucai',
    'Alfenas',
    'Cambuquira',
    'Santa Cruz',
    'Ribeirao Pires',
    'Campo',
    'MairiporÃ£',
    'Tatuamunha',
    'JaÃº',
    'Marcelino Ramos',
    'Campinas do Sul',
    'Capao da Canoa',
    'Piracaia',
    'Goianesia',
    'Sales',
    'Potirendaba',
    'Toritama',
    'Caruaru',
    'Sanharo',
    'Propria',
    'Presidente Kennedy',
    'Brejetuba',
    'Cachoeiras',
    'Lencois',
    'Itapecerica',
    'Mage',
    'Cosmorama',
    'Alagoa Grande',
    'Arroio Grande',
    'Aragarcas',
    'Caiaponia',
    'Patrocinio',
    'Santana do Paraiso',
    'Coronel Fabriciano',
    'Rio das Ostras',
    'Santo Antonio de Jesus',
    'America Dourada',
    'Cafarnaum',
    'Ceara Mirim',
    'Joao Camara',
    'Turmalina',
    'Curitibanos',
    'Santa Isabel do Ivai',
    'Querencia do Norte',
    'Simao Dias',
    'Santo Antonio do Leverger',
    'Primavera',
    'Jaguarao',
    'Jequitinhonha',
    'Toledo',
    'Biritinga',
    'Terra Boa',
    'Rondon',
    'Doutor Camargo',
    'Santo Antonio do Caiua',
    'Jacutinga',
    'Socorro',
    'Vespasiano',
    'Lontra',
    'Curvelo',
    'Morro de Sao Paulo',
    'Restinga Seca',
    'Ipora',
    'Jacarau',
    'Cacimba de Dentro',
    'Parelhas',
    'Bom Despacho',
    'BalneÃ¡rio CamboriÃº',
    'Joaquim Tavora',
    'Bonito',
    'Quipapa',
    'Quirinopolis',
    'Santo Antonio da Patrulha',
    'Altinopolis',
    'Matao',
    'Santo Amaro',
    "Olho d'Agua",
    'Sabara',
    'Sao Joaquim de Bicas',
    'Sao Jose do Rio Pardo',
    'Icem',
    'Pongai',
    'Sao Lourenco da Mata',
    'Lages',
    'Concordia',
    'Porto Real',
    'Itaborai',
    'Campina Grande do Sul',
    'Fazenda Rio Grande',
    'Samambaia',
    'Cidade',
    'Aguas Claras',
    'Guarapari',
    'Sao Roque',
    'Barreiras',
    'Feira de Santana',
    'Maragogipe',
    'Catu',
    'Sapeacu',
    'Candeal',
    'Sao Goncalo dos Campos',
    'Cansancao',
    'Timbe',
    'Nova Lima',
    'Iuna',
    'Abreu e Lima',
    'MacaÃ©',
    'Timbauba',
    'Goiana',
    'Pancas',
    'Ituporanga',
    'Ascurra',
    'Sao Joaquim',
    'Capanema',
    'GravatÃ¡',
    'Milagres',
    'Poco das Antas',
    'Cruzeiro do Sul',
    'Soledade',
    'Tuiuti',
    'Itatiba',
    'Cruzeiro',
    'Sao Sebastiao',
    'Sao Lourenco da Serra',
    'Charqueada',
    'Santa Gertrudes',
    'Ipeuna',
    'Rio das Pedras',
    'Agudos',
    'Assis',
    'Dracena',
    'Brotas',
    'Promissao',
    'Gaviao Peixoto',
    'Pitangueiras',
    'Monte Alto',
    'Pradopolis',
    'Vera Cruz',
    'Pirajui',
    'Pacaembu',
    'Macatuba',
    'Botuvera',
    'Ouro Branco',
    'Caete',
    'Leopoldina',
    'Apucarana',
    'Tres Rios',
    'Tucurui',
    'Cerro Branco',
    'Cameta',
    'Baiao',
    'Tome Acu',
    'Heliodora',
    'Cruzeiro do Sul',
    'Irati',
    'Currais Novos',
    'Caxias',
    'Joao Pinheiro',
    'Brasil',
    'Lagoa Santa',
    'Orlandia',
    'Ribeirao Corrente',
    'Ipua',
    'Guara',
    'Nuporanga',
    'Sao Joaquim da Barra',
    'Jardinopolis',
    'Brodosqui',
    'Guaira',
    'Sales Oliveira',
    'Ituverava',
    'Batatais',
    'Miguelopolis',
    'Joaquim',
    'Tupaciguara',
    'Patos de Minas',
    'Ituiutaba',
    'Capinopolis',
    'Sao Joao da Boa Vista',
    'Santo Antonio do Jardim',
    'Catalao',
    'Camanducaia',
    'Florania',
    'Sao Vicente',
    'Marechal Candido Rondon',
    'Quatro Pontes',
    'Barao de Cocais',
    'Conceicao do Mato Dentro',
    'Guapo',
    'Bom Jardim',
    'Guararema',
    'Pedro Leopoldo',
    'Mariana',
    'Igarape',
    'Ipojuca',
    'HumaitÃ¡',
    'Jaguaribe',
    'Ico',
    'Maria da Fe',
    'Laguna',
    'Piraquara',
    'Anhumas',
    'Bocaiuva',
    'Mandaguacu',
    'Catende',
    'Araripina',
    'Jaguariaiva',
    'Mariluz',
    'Ji Parana',
    'Bom Retiro do Sul',
    'Imperatriz',
    'Rio do Pires',
    'Macaubas',
    'Botupora',
    'Afonso Claudio',
    'Pontalina',
    'Arealva',
    'Ibitinga',
    'Aloandia',
    'Pien',
    'Benedito Novo',
    'Descanso',
    'Itapiranga',
    'Braco do Norte',
    'Barbalha',
    'Getulio Vargas',
    'Apora',
    'Santa Luzia',
    'Sao Carlos',
    'Ponte Serrada',
    'Barbacena',
    'SÃ£o JoÃ£o del Rei',
    'Santo Inacio',
    'Jaboticatubas',
    'Sao Francisco',
    'Sao Salvador',
    'Sao Joao Evangelista',
    'Maracaju',
    'Camamu',
    'Itacare',
    'Ipiau',
    'Itagiba',
    'Candeias',
    'Coremas',
    'Inhauma',
    'Aracai',
    'Barauna',
    'Tiangua',
    'Caninde de Sao Francisco',
    'Santa Cruz do Sul',
    'Cedral',
    'Sapiranga',
    'Canarana',
    'Agua Boa',
    'Querencia',
    'Cocalinho',
    'Carlos Barbosa',
    'SantarÃ©m',
    'Abidos',
    'Almeirim',
    'Martinopolis',
    'Presidente Bernardes',
    'Centenario do Sul',
    'Alvares Machado',
    'PoÃ§os de Caldas',
    'Candeias',
    'Porto Grande',
    'Anajas',
    'Joacaba',
    'Alegre',
    'Santo Aleixo',
    'CaÃ§ador',
    'Cianorte',
    'Corumba de Goias',
    'Nazare da Mata',
    'Niteroi',
    'ArujÃ¡',
    'Caratinga',
    'Salvador',
    'Cruz das Almas',
    'Laguna Carapa',
    'Glorinha',
    'Sapucaia do Sul',
    'Lagoa Salgada',
    'Ouricangas',
    'Itapicuru',
    'Caraguatatuba',
    'Conchal',
    "Santa Barbara d'Oeste",
    'Coelho',
    'Crato',
    'Granito',
    'Monte Santo',
    'Santa Cruz Cabralia',
    'Cabralia',
    'Aracruz',
    'Ubatuba',
    'Sao Roque',
    'Rio Grande da Serra',
    'Avare',
    'Pena',
    'Sao Pedro',
    'Pilar do Sul',
    'Jose Bonifacio',
    'Monte Azul Paulista',
    'Salto de Pirapora',
    'Artur Nogueira',
    'Joanopolis',
    'Iacanga',
    'Paraguacu Paulista',
    'Areia Branca',
    'Boa Vista',
    'Picui',
    'Machadinho',
    'Birigui',
    'Campo Verde',
    'Coroados',
    'Buritama',
    'Jaru',
    'Sao Joao',
    'Videira',
    'Heliopolis',
    'Cicero Dantas',
    'Conceicao de Macabu',
    'Macuco',
    'Alta Floresta',
    'Progresso',
    'Bento',
    'Pato Branco',
    'Umuarama',
    'Convento da Penha',
    'Aquidaba',
    'Recanto',
    'Piedade',
    'Cabo Frio',
    'Brejo da Madre de Deus',
    'Vassouras',
    'Rosario',
    'Itapecuru Mirim',
    'Luis Correia',
    'Vicosa',
    'Campo Alegre',
    'Boa Vista',
    'Boa Vista',
    'Conselheiro Lafaiete',
    'Sarzedo',
    'Claudio',
    'Barra do PiraÃ­',
    'Paracambi',
    'Salinas da Margarida',
    'Ouro Preto',
    'Tabatinga',
    'Pirai',
    'Cristiano Otoni',
    'Jaguaruana',
    'Juru',
    'Sao Jose do Egito',
    'Virgem da Lapa',
    'Chapada',
    'Cristais',
    'Francisco Sa',
    'Varzelandia',
    'Janauba',
    'Sao Sebastiao',
    'Penapolis',
    'Barbosa',
    'Itapolis',
    'Pederneiras',
    'Registro',
    'Mongagua',
    'Salesopolis',
    'Lencois Paulista',
    'Bertioga',
    'Abadiania',
    'Terra Nova',
    'Repartimento',
    'AraxÃ¡',
    'Uberaba',
    'Divinopolis',
    'Meier',
    'Maracana',
    'Sao Jose',
    'Cajuru',
    'Arceburgo',
    'Ipu',
    'Sao Caetano',
    'Bezerros',
    'Aquidauana',
    'SÃ£o Miguel das MissÃµes',
    'Gramado',
    'Rio Branco',
    'CorumbÃ¡',
    'Ivoti',
    'Montenegro',
    'Sao Lourenco do Sul',
    'Campina',
    'Bela Vista do Paraiso',
    'Montividiu',
    'Jaciara',
    'Araruna',
    'Cangucu',
    'Paraiso',
    'Itau de Minas',
    'Nobres',
    'Branco',
    'Monte Alegre',
    'Elias Fausto',
    'Bom Jesus do Itabapoana',
    'Riacho das Almas',
    'Parobe',
    'Nova Cruz',
    'Venda Nova',
    'Machado',
    'Ouvidor',
    'Caldas Novas',
    'Guaraciaba',
    'Maruim',
    'Siqueira Campos',
    'Jaboticabal',
    'Taquaritinga',
    'Jarinu',
    'General Salgado',
    'Bebedouro',
    'Guaicara',
    'Vargem Grande do Sul',
    'Lins',
    'Condado',
    'Itapetinga',
    'Alterosa',
    'Julio de Castilhos',
    'Mateus Leme',
    'Reduto',
    'Teixeira',
    'Arroio do Silva',
    'Cacapava',
    'Cravinhos',
    'Congonhas',
    'Itaitinga',
    'Varzea',
    'Garimpo Novo',
    'Espinosa',
    'Macedonia',
    'Sao Jose do Goiabal',
    'Lagoa do Carro',
    'Sao Miguel dos Campos',
    'Nilopolis',
    'Amazonas',
    'Fortaleza',
    'Vicente',
    'Tamandare',
    'Raposos',
    'Pacatuba',
    'Eusebio',
    'Riachao do Jacuipe',
    'Santa Rita',
    'Timoteo',
    'Maranguape',
    'Queimadas',
    'Frei Paulo',
    'Altinho',
    'Amarante',
    'Lucena',
    'Goianira',
    'Vitoria de Santo Antao',
    'Novo Mundo',
    'Arapua',
    'Itambe',
    'Caapora',
    'Alem Paraiba',
    'Para de Minas',
    'Serra',
    'Viana',
    'Caldas',
    'Guaraci',
    'Itapixuna',
    'Jaguaquara',
    'Tocantins',
    'Piedade dos Gerais',
    'Quixada',
    'Tupanatinga',
    'Alto',
    'Calcoene',
    'Sao Gabriel',
    'Cantanhede',
    'Itapipoca',
    'Sobral',
    'Abrantes',
    'Caninde',
    'Andarai',
    'Uruguaiana',
    'Botafogo',
    'Resende',
    'Italva',
    'Niteroi',
    'Dois Corregos',
    'Limoeiro',
    'Castanhal',
    'Uba',
    'Itabuna',
    'Bombinhas',
    'Ribeirao do Pinhal',
    'Jardim Alegre',
    'Sao Jose do Cedro',
    'Forquilha',
    'Nova Canaa',
    'Sao Pedro dos Ferros',
    'Caputira',
    'Sao Jose de Mipibu',
    'Goianinha',
    'Mutum',
    'Jeronimo Monteiro',
    'Cajazeiras',
    'Espumoso',
    'Planalto',
    'Caparao',
    'Manhumirim',
    'Espera Feliz',
    'Jetiba',
    'Sao Jose do Calcado',
    'Tupa',
    'Mineiros do Tiete',
    'Carmo do Rio Claro',
    'Serrania',
    'Rancharia',
    'Andradas',
    'Joao Lisboa',
    'Sitio Novo',
    'Caripi',
    'Graca Aranha',
    'Ibipeba',
    'Irece',
    'Morro do Chapeu',
    'Carapebus',
    'Casimiro de Abreu',
    'Ribeirao',
    'Valparaiso',
    'Tiradentes',
    'Valente',
    'Sao Francisco de Paula',
    'Nova Petropolis',
    'Guanhaes',
    'Inga',
    'Gardenia Azul',
    'Pontal do Parana',
    'Marques',
    'Neopolis',
    'Marechal Deodoro',
    'Messias',
    'Sao Jose da Laje',
    'Coruripe',
    'Anadia',
    'Capela',
    'Sertao',
    'Sananduva',
    'Guamare',
    'Pauliceia',
    'Junqueiropolis',
    'Panorama',
    'Mostardas',
    'Barra do Ribeiro',
    'Cerro Grande',
    'Itapuranga',
    'Santana da Vargem',
    'Campos Gerais',
    'Cha Grande',
    'Cuite',
    'Ribeirao Bonito',
    'Pedro Avelino',
    'Campo Novo',
    'Frederico Westphalen',
    'Seberi',
    'Paranaiba',
    'Nova Serrana',
    'Lebon Regis',
    'Aquiraz',
    'Cacequi',
    'Bom Jesus do Amparo',
    'Santa Barbara',
    'Santana',
    'Piuma',
    'TeÃ³filo Otoni',
    'Silva Jardim',
    'Itabapoana',
    'Tangua',
    'Miracema',
    'Aperibe',
    'Itaocara',
    'Boa Vista',
    'Campo Grande',
    'Bras',
    'Sao Manuel',
    'Santos Dumont',
    'Sao Luis de Montes Belos',
    'Cesario Lange',
    'Jumirim',
    'Alambari',
    'Marzagao',
    'Parnaiba',
    'Catarina',
    'Machados',
    'Paracuru',
    'Tamboril',
    'Floriano',
    'Campo Maior',
    'Marcolandia',
    'Capistrano',
    'Baturite',
    'Visconde do Rio Branco',
    'Quixere',
    'Nova Alianca',
    'Guapiacu',
    'Balsamo',
    'Urupes',
    'Santa Fe do Sul',
    'Bady Bassitt',
    'Mendonca',
    'Ubarana',
    'Capelinha',
    'Berilo',
    'Arroio do Meio',
    'Cacapava do Sul',
    'Sao Joao Batista',
    'Jardim de Piranhas',
    'Acu',
    'Ipumirim',
    'Goianapolis',
    'Palmitos',
    'Campo Alegre',
    'Porto Seguro',
    'Pau Brasil',
    'Barrocas',
    'Serrinha',
    'Conceicao do Coite',
    'Araci',
    'Valenca',
    'Alvorada do Sul',
    'Itapagipe',
    'Palestina',
    'Narandiba',
    'Amarante do Maranhao',
    'Pratapolis',
    'Mamonas',
    'Volta Grande',
    'Pedra Bonita',
    'Raul Soares',
    'Mangaratiba',
    'Quissama',
    'Natercia',
    'Malacacheta',
    'Cruz Alta',
    'Tenorio',
    'Alagoa Nova',
    'Nossa Senhora da Gloria',
    'Ribeira do Pombal',
    'Gurupi',
    'Guaxupe',
    'Alegres',
    'Santo Anastacio',
    'Castelo',
    'Arcos',
    'Piabeta',
    'Aroeiras',
    'Laranjeiras do Sul',
    'Itapejara',
    'Cerqueira Cesar',
    'Arandu',
    'Cabedelo',
    'Sao Joao do Paraiso',
    'Bicas',
    'Lagoa da Canoa',
    'Pao de Acucar',
    'Paulo Afonso',
    'Junqueiro',
    'Penedo',
    'Nanuque',
    'Medeiros Neto',
    'Mucuri',
    'Fundao',
    'Redencao',
    'Itaete',
    'Boa Vista',
    'Lagoa Vermelha',
    'Arroio do Tigre',
    'Progresso',
    'Ibipora',
    'Silveiras',
    'Trindade',
    'Arraial do Cabo',
    'Sao Francisco',
    'Jose Boiteux',
    'Presidente Getulio',
    'Witmarsum',
    'Mirim Doce',
    'Sebastianopolis do Sul',
    'Mangabeira',
    'Juatuba',
    'Boa Vista',
    'Sampaio Correia',
    'Mendes',
    'Dias',
    'Pojuca',
    'Paraopeba',
    'Pote',
    'Rio Casca',
    'Moema',
    'Santa Luzia',
    'Vila Muriqui',
    'Pinheiral',
    'IlhÃ©us',
    'Vera Cruz',
    'Barra de Sao Miguel',
    'Chapadinha',
    'Santa Maria de Itabira',
    'Santana do Cariri',
    'Altaneira',
    'Exu',
    'Dix-sept Rosado',
    'Paragominas',
    'Urucuca',
    'Ibirataia',
    'Nilo Pecanha',
    'Porto',
    'Pedra Grande',
    'Fortaleza',
    'Claro dos Pocoes',
    'Irai',
    'Mandaguari',
    'Tapira',
    'Sao Domingos',
    'Ipaba',
    'Encruzilhada do Sul',
    'Itabirito',
    'Capetinga',
    'Itaberaba',
    'Pratania',
    'Piratininga',
    'Santa Ines',
    'Adustina',
    'Bernardo',
    'Tupancireta',
    'Sao Jose do Inhacora',
    'Mineiros',
    'Rancho Queimado',
    'Luzerna',
    "Erval d'Oeste",
    'Reboucas',
    'Massaranduba',
    'Correia Pinto',
    'Rio dos Cedros',
    'Tres Coroas',
    'Nucleo Bandeirante',
    'Sao Miguel do Araguaia',
    'Porangatu',
    'Seara',
    'Campo Ere',
    'Jacuizinho',
    'Iguaracu',
    'Vila Rica',
    'Pirapora do Bom Jesus',
    'Espirito Santo',
    'Espirito Santo do Pinhal',
    'Fartura',
    'Palmeira',
    'Barra Bonita',
    'Cabralia Paulista',
    'Salto Grande',
    'Tupi Paulista',
    'Santa Isabel',
    'Guaianases',
    'Terra Roxa',
    'Braganca',
    'Paraiba do Sul',
    'Morretes',
    'Mambore',
    'Paicandu',
    'Canoinhas',
    'Tres Barras',
    'Simao Pereira',
    'Teutonia',
    'Cachoeiras de Macacu',
    'Dois Vizinhos',
    'Gandu',
    'Ubata',
    'ValenÃ§a',
    'Santa Helena',
    'Sao Jose de Piranhas',
    'Rio Negro',
    'Conceicao',
    'Sao Joao Nepomuceno',
    'Ponte de Itabapoana',
    'Itabaiana',
    'Juripiranga',
    'Mogeiro',
    'Taua',
    'Missao Velha',
    'Campos Sales',
    'Parana',
    'Pereiro',
    'Caraubas',
    'Iracema',
    'Carius',
    'Vicosa do Ceara',
    'Pimenta Bueno',
    'Diamantino',
    'Timon',
    'Sao Goncalo do Para',
    'SÃ£o Mateus',
    'Aguas de Sao Pedro',
    'Palmital',
    'Mamanguape',
    'Baia da Traicao',
    'Sao Manoel',
    'Ilhabela',
    'Cajati',
    'Morungaba',
    'Bom Jesus',
    'Triunfo',
    'Miranda',
    'Sao Jose de Ribamar',
    'Nunes Freire',
    'Buriti',
    'Itaguatins',
    'Cururupu',
    'Raposa (1)',
    'Angicos',
    'Serra',
    'Macaiba',
    'Extremoz',
    'Japaratinga',
    'Barao de Grajau',
    'Angical do Piaui',
    'Esperantina',
    'Pedreira',
    'Mirinzal',
    'Vitorino Freire',
    'Cacique Doble',
    'Conchas',
    'Pinhalzinho',
    'Carinhanha',
    'Correntina',
    'Santa Maria da Vitoria',
    'Cocos',
    'Santa Leopoldina',
    'Guaira',
    'Portao',
    'Alto Paraiso de Goias',
    'Capao Alto',
    'Esperanca',
    'Pinhalao',
    'Urai',
    'Ladario',
    'Itaqui',
    'Jundiai do Sul',
    'General Carneiro',
    'Bairro da Velha Central',
    'Santana do Livramento',
    'Amambai',
    'Pirajuba',
    'Batista',
    'Tapirai',
    'Cassilandia',
    'Sao Lourenco dOeste',
    'Restinga',
    'Urania',
    'Cruz',
    'Sao Luis do Paraitinga',
    'Monteiro Lobato',
    'Bananal',
    'Santa Branca',
    'Paraibuna',
    'Juquitiba',
    'Mirandopolis',
    'Duartina',
    'Ibiuna',
    'Descalvado',
    'Contenda',
    'Varjota',
    'Apuiares',
    'Luminarias',
    'Cambui',
    'Bueno Brandao',
    'Santo Antonio do Retiro',
    'Rio Bananal',
    'Dionisio Cerqueira',
    'Sao Gabriel da Cachoeira',
    'Jataizinho',
    'Rio Claro',
    'Puxinana',
    'Putinga',
    'Nao Me Toque',
    'Erval Grande',
    'Grajau',
    'Capela',
    'Belem',
    'Riacho Fundo',
    'Benevides',
    'Lapa',
    'Monte Carmelo',
    'Ibate',
    'Itaituba',
    'Paraibano',
    'Colinas',
    'Presidente Dutra',
    'Sao Felipe',
    'Ubaira',
    'Nova Itarana',
    'Santa Teresinha',
    'Jiquirica',
    'Entre Rios de Minas',
    'Paraguacu',
    'Campestre',
    'Mendes Pimentel',
    'Tumiritinga',
    'Garca',
    'Laranjal Paulista',
    'Pirangi',
    'Juazeiro',
    'Gameleira',
    'Serra Talhada',
    'Belo Jardim',
    'Velha',
    'Massape',
    'Morrinhos',
    'Sao Paulo',
    'Chaval',
    'Alagoinha',
    'Andre da Rocha',
    'Caraa',
    'Uirauna',
    'Vespasiano Correa',
    'Bom Principio',
    'Schroeder',
    'Quata',
    'Jampruca',
    'Virginopolis',
    'Lagoa Seca',
    'Esperanca',
    'Cassino',
    'Carmopolis',
    'Buritizeiro',
    'Sao Francisco do Conde',
    'Mairi',
    'Piritiba',
    'Uaua',
    'Campo Formoso',
    'Nova',
    'Cachoeira',
    'Uibai',
    'Jucara',
    'Jesuania',
    'Caxambu',
    'Buzios',
    'Armacao de Buzios',
    'Arapoti',
    'Wenceslau Braz',
    'Itarare',
    'Resende Costa',
    'Caiana',
    'Guariba',
    'Nova Independencia',
    'Igarata',
    'Guarabira',
    'Luis Antonio',
    'Dourado',
    'Ilha Solteira',
    'Serra Azul',
    'Sao Simao',
    'Aguas de Lindoia',
    'Cajobi',
    'Itajobi',
    'Santa Adelia',
    'Taiuva',
    'Elisiario',
    'Sao Domingos',
    'Jacobina',
    'Redencao',
    'Muritiba',
    'Tapejara',
    'Santo Amaro da Imperatriz',
    'Luis Antonio',
    'Cidade',
    'Tres Passos',
    'Tucunduva',
    'Palmitinho',
    'Areado',
    'Guaranesia',
    'Rio Manso',
    'Piracema',
    'Carmopolis de Minas',
    'Bonfim',
    'Aguanil',
    'Cidade Nova',
    'Aldeia',
    'Cerro Largo',
    'Renascenca',
    'Igaracu do Tiete',
    'Nova Resende',
    'Jandaia do Sul',
    'Tuparendi',
    'Joao Neiva',
    'Santana do Ipanema',
    'Belem do Brejo do Cruz',
    'Virginia',
    'Coreau',
    'Senhor do Bonfim',
    'Itiuba',
    'Pindobacu',
    'Serra Preta',
    'Gurjao',
    'Paulo Lopes',
    'Boqueirao',
    'Padre',
    'Rio Novo do Sul',
    'Anchieta',
    'Guaranta',
    'Inubia Paulista',
    'Muzambinho',
    'Rafard',
    'Groairas',
    'Rio Acima',
    'Cruzilia',
    'Lambari',
    'Santa Cruz do Rio Pardo',
    'CodÃ³',
    'Fragoso',
    'Sao Sebastiao',
    'Antonio Prado',
    'Nova Londrina',
    'Sertanopolis',
    'Antonina',
    'Marumbi',
    'Cruzeiro do Oeste',
    'Engenheiro Beltrao',
    'Imperatriz',
    'Posse',
    'Sao Luis',
    'Campos',
    'Acucena',
    'Manga',
    'Canelinha',
    'Muliterno',
    'Vila Maria',
    'Casca',
    'Conceicao das Alagoas',
    'Frutal',
    'Presidente Olegario',
    'Jaguaras',
    'Guarani dOeste',
    'Julio Mesquita',
    'Taiacu',
    'Pariquera Acu',
    'Coroata',
    'Alto Alegre',
    'Sao Jose',
    'Gloria',
    'Nova Aurora',
    'Riacho Fundo',
    'Sarapui',
    'Santa Barbara do Sul',
    'Sangao',
    'Iraceminha',
    'Faxinal dos Guedes',
    'Erval Velho',
    'Mandirituba',
    'Peabiru',
    'Santo Ã‚ngelo',
    'Sidrolandia',
    'Paraiso do Tocantins',
    'Brejinho de Nazare',
    'Monte Alegre',
    'Turvo',
    'Jaguapita',
    'Balsa Nova',
    'Iretama',
    'Ibatiba',
    'Manhuacu',
    'Lajinha',
    'Muniz Freire',
    'Rio Paranaiba',
    'Cruzeiro da Fortaleza',
    'Matutina',
    'Nova Ponte',
    'Pitangui',
    'Perdigao',
    'Araujos',
    'Papagaios',
    'Nova Araca',
    'Pedro de Toledo',
    'Parnamirim',
    'Ribas do Rio Pardo',
    'Guararapes',
    'Barcarena',
    'Satuba',
    'Carmo da Mata',
    'Oliveira',
    'Rubim',
    'Taiobeiras',
    'Itarantim',
    'Paraiso',
    'Ibicoara',
    'Itororo',
    'Sao Bento',
    'America de Cima',
    'Presidente Epitacio',
    'Iguai',
    'Faxinal do Soturno',
    'Quebrangulo',
    'Itapissuma',
    'Cupira',
    'Salgueiro',
    'Bayeux',
    'Areial',
    'Remigio',
    'Rio Tinto',
    'Boquim',
    'Feira Nova',
    'Porto Esperidiao',
    'Porto Amazonas',
    'Candoi',
    'Rio Azul',
    'Porto Belo',
    'Itapo',
    'Armazem',
    'Lauro',
    'Comodoro',
    'Guaragi',
    'Colorado',
    'Campinorte',
    'Curimata',
    'Morro Reuter',
    'Aparecida do Rio Negro',
    'Ouro Verde',
    'Porecatu',
    'Munhoz',
    'Pirai do Sul',
    'Santa Teresa',
    'Itamogi',
    'Cedro do Abaete',
    'Sabinopolis',
    'Marcelino Vieira',
    'Colina',
    'Auriflama',
    'Casa Nova',
    'Sao Bento',
    'Marituba',
    'Arcoverde',
    'Flores',
    'Buique',
    'Sao Joao de Pirabas',
    'Inhangapi',
    'Bonito',
    'Santarem Novo',
    'Maracana',
    'Piedade do Rio Grande',
    'Carmo do Cajuru',
    'Itatira',
    'Umirim',
    'Belo Horizonte',
    'Irupi',
    'Macau',
    'Palma',
    'Eugenopolis',
    'Perdoes',
    'Montes Altos',
    'Pompeia',
    'Cordeiro',
    'Cantagalo',
    'Patrocinio Paulista',
    'Santo Antonio do Monte',
    'Alto do Rodrigues',
    'Sao Sebastiao do Maranhao',
    'Aracariguama',
    'Itaparica',
    'Rodrigues',
    'Canavieiras',
    'Icarai',
    'Sao Joao da Barra',
    'Pachecos',
    'Rio Piracicaba',
    'Azurita',
    'Paulista',
    'Ibirarema',
    'Dobrada',
    'Paraitinga',
    'Nazare Paulista',
    'Fortaleza',
    'Iturama',
    'Sapucaia',
    'Sorriso',
    'Cipotanea',
    'Antonio Carlos',
    'Caiua',
    'Bela Vista',
    'Juquia',
    'Saudades',
    'Cunha Pora',
    'Pecem',
    'Beberibe',
    'Pindoretama',
    'Florinia',
    'Monte Siao',
    'Ocara',
    'Tamoios',
    'Eugenio',
    'Barra do Corda',
    'Pauini',
    'Fortuna',
    'Pastos Bons',
    'Luz',
    'Faxinal',
    'JequiÃ©',
    'Central',
    'Chavantes',
    'Palmital',
    'Tenente Ananias',
    'Brasa',
    'Jales',
    'Rio Formoso',
    'Andre',
    'Botafogo',
    'Parana',
    'Santa Terezinha',
    'Nova Esperanca',
    'Chapadao',
    'Pitanga',
    'Barra do Jacare',
    'Aricanduva',
    'Garuva',
    'Jussara',
    'Carangola',
    'Ervalia',
    'Sao Bento do Sapucai',
    'Icoraci',
    'Capitao Poco',
    'Campo Magro',
    'Quatigua',
    'Bituruna',
    'Tibagi',
    'Ceu Azul',
    'Sulina',
    'Sabaudia',
    'Pompeu',
    'Ipaucu',
    'Lavinia',
    'Aguai',
    'Aguas da Prata',
    'Roseira',
    'Capao Bonito',
    'Tanabi',
    'Itambaraca',
    'Matelandia',
    'Entre Rios',
    'Jose de Freitas',
    'Floresta Azul',
    'Terra Nova',
    'Altos',
    'Avanhandava',
    'Fronteira',
    'Pontes e Lacerda',
    'Coari',
    'Porto Sao Jose Velho',
    'Sao Pedro do Ivai',
    'California',
    'Santa Helena',
    'Buriti',
    'Picada',
    'Estancia Nova',
    'Capela do Alto',
    'Carambei',
    'Urubici',
    'Itaipava',
    'Barracao',
    'Turvo',
    'Foz do Jordao',
    'Boa Ventura',
    'Ipubi',
    'Uruburetama',
    'Rio Novo',
    'Agrestina',
    'Chapadao',
    'Matipo',
    'Sao Sebastiao',
    'Rodeio Bonito',
    'Ronda Alta',
    'Nova Timboteua',
    'Ilhota',
    'Barras',
    'Tarumirim',
    'Coromandel',
    'Teodoro Sampaio',
    'Forquilha',
    'Jussari',
    'Santa Cruz da Baixa Verde',
    'Pilao Arcado',
    'Itape',
    'Antas',
    'Pereira Barreto',
    'Rosalia',
    'Santo Expedito',
    'Lavras do Sul',
    'Taquarana',
    'Sao Sebastiao',
    'Agua Clara',
    'Santo Antonio de Posse',
    'Itobi',
    'Miracatu',
    'Rubiataba',
    'Neropolis',
    'Loanda',
    'Lumiar',
    'Sardoa',
    'Cabrobo',
    'Ubaitaba',
    'Sao Felix',
    'Planalto',
    'Conde',
    'Poco Branco',
    'Pendencias',
    'Guacui',
    'SÃ£o Borja',
    'Caibate',
    'Pedro Afonso',
    'Jabora',
    'Cafelandia',
    'Salto',
    'Platina',
    'Quitandinha',
    'Iguaba Grande',
    'Barra Velha',
    'Palmeiras de Goias',
    'Clevelandia',
    'Sede Nova',
    'Inhacora',
    'Apiai',
    'Areal',
    'Cidade de Deus',
    'Igreja Nova',
    'Palmeira dos Indios',
    'Santa Lucia',
    'Bela Vista',
    'Porto Velho',
    'Ouro Preto do Oeste',
    'Itagi',
    'Coroas',
    'Otacilio Costa',
    'Adamantina',
    'Santo Amaro',
    'Nova',
    'Angatuba',
    'Palmelo',
    'Rodeiro',
    'Breves',
    'Monte Dourado',
    'Juruti',
    'Sao Miguel',
    'Clementina',
    'Arraial',
    'Bangu',
    'Barra da Tijuca',
    'Palestina',
    'Barao de Cotegipe',
    'Indianopolis',
    'Itinga da Serra',
    'Cachoeira Grande',
    'Baixo Guandu',
    'Barra',
    'Abreu',
    'Ouricuri',
    'Santo Antonio da Platina',
    'Telemaco Borba',
    'Cerro Azul',
    'Mantenopolis',
    'Agudo',
    'Inocencia',
    'Nova Trento',
    'Paranapanema',
    'Sao Miguel Arcanjo',
    'Cardoso',
    'Fernando Prestes',
    'Iranduba',
    'Bom Jesus',
    'Conceicao da Barra',
    'Barra',
    'Guai',
    'Alfredo Chaves',
    'Bonito',
    'Primeiro de Maio',
    'Catanduvas',
    'Engenheiro Schmidt',
    'Estiva',
    'Terra Roxa',
    'Brochier',
  ],
  tld: ['.br'],
  cca2: 'BR',
  ccn3: '076',
  cca3: 'BRA',
  cioc: 'BRA',
  currency: ['BRL'],
  callingCode: ['55'],
  capital: 'Brasília',
  altSpellings: [
    'BR',
    'Brasil',
    'Federative Republic of Brazil',
    'República Federativa do Brasil',
  ],
  region: 'Americas',
  subregion: 'South America',
  languages: { por: 'Portuguese' },
  translations: {
    cym: { official: 'Federative Republic of Brazil', common: 'Brasil' },
    deu: { official: 'Föderative Republik Brasilien', common: 'Brasilien' },
    fra: { official: 'République fédérative du Brésil', common: 'Brésil' },
    hrv: { official: 'Savezne Republike Brazil', common: 'Brazil' },
    ita: { official: 'Repubblica federativa del Brasile', common: 'Brasile' },
    jpn: { official: 'ブラジル連邦共和国', common: 'ブラジル' },
    nld: { official: 'Federale Republiek Brazilië', common: 'Brazilië' },
    por: { official: 'República Federativa do Brasil', common: 'Brasil' },
    rus: { official: 'Федеративная Республика Бразилия', common: 'Бразилия' },
    spa: { official: 'República Federativa del Brasil', common: 'Brasil' },
    fin: { official: 'Brasilian liittotasavalta', common: 'Brasilia' },
  },
  latlng: [-10, -55],
  demonym: 'Brazilian',
  landlocked: false,
  borders: [
    'ARG',
    'BOL',
    'COL',
    'GUF',
    'GUY',
    'PRY',
    'PER',
    'SUR',
    'URY',
    'VEN',
  ],
  area: 8515767,
  provinces: [
    'Acre',
    'Alagoas',
    'Amapa',
    'Amazonas',
    'Bahia',
    'Ceara',
    'Distrito Federal',
    'Espirito Santo',
    'Goias',
    'Maranhao',
    'Mato Grosso',
    'Mato Grosso do Sul',
    'Minas Gerais',
    'Para',
    'Paraiba',
    'Parana',
    'Pernambuco',
    'Piaui',
    'Rio de Janeiro',
    'Rio Grande do Norte',
    'Rio Grande do Sul',
    'Rondonia',
    'Roraima',
    'Santa Catarina',
    'Sao Paulo',
    'Sergipe',
    'Tocantins',
  ],
  nativeName: 'Brasil',
  timezones: ['UTC−05:00', 'UTC−04:00', 'UTC−03:00', 'UTC−02:00'],
} as const;
