var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "score-table-cell" }, [
    _c("span", { class: { important: _setup.extensionsRequired } }, [
      _c("span", [_vm._v(_vm._s(_setup.courseName))]),
      _vm._v(" "),
      _setup.extensionsRequired
        ? _c(
            "span",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip",
                  value: _setup.tooltip,
                  expression: "tooltip",
                },
              ],
            },
            [_c("Icon", { attrs: { icon: _setup.faExclamationTriangle } })],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }