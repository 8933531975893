export const NLD = {
  name: {
    common: 'Netherlands',
    official: 'Netherlands',
    native: { nld: { official: 'Nederland', common: 'Nederland' } },
  },
  cities: [
    'Amsterdam',
    'The Hague',
    'Zoetermeer',
    'Helmond',
    'Beek',
    'Breukelen',
    'Almere',
    'Enschede',
    'Alphen aan den Rijn',
    'Dordrecht',
    'Leeuwarden',
    'Rotterdam',
    'Geldrop',
    'Son en Breugel',
    'Vianen',
    'Heerenveen',
    'Sint-Oedenrode',
    'Dronten',
    'Groningen',
    'Veendam',
    'Vught',
    'Sevenum',
    'Asten',
    'Gemert',
    'Bakel',
    'Erp',
    'Eindhoven',
    'Greup',
    'Ede',
    'Burgum',
    'Hilversum',
    'Haarlem',
    'Bleskensgraaf',
    'Nunspeet',
    'Valkenswaard',
    'Arnhem',
    'Apeldoorn',
    'Vlaardingen',
    'Hellevoetsluis',
    'Voorthuizen',
    'Westervoort',
    'Nijmegen',
    'Hoofddorp',
    'Diemen',
    'Sliedrecht',
    'Alkmaar',
    'Zaandam',
    'Oud-Beijerland',
    'Puttershoek',
    'Strijensas',
    'Drachten',
    "'s-Gravendeel",
    'Maasdam',
    'Twello',
    'Zuilichem',
    'Zutphen',
    'Huissen',
    'Spijkenisse',
    'Purmerend',
    'Vaassen',
    'Groesbeek',
    'Lelystad',
    'Wageningen',
    'Warnsveld',
    'Druten',
    'Amersfoort',
    'Renkum',
    'Deest',
    'Oude-Tonge',
    'Duiven',
    'Koog aan de Zaan',
    'Bleiswijk',
    'Hengelo',
    'Schiedam',
    'Heerhugowaard',
    'Waardenburg',
    'Woerden',
    'Valkenburg',
    'Brunssum',
    'Egmond aan Zee',
    'Oudega',
    'Emmeloord',
    'Breda',
    'Wellerlooi',
    'Huizen',
    'Raalte',
    'Utrecht',
    'Loenen',
    'Amstelveen',
    'De Cocksdorp',
    'Silvolde',
    'Op den Bosch',
    'Leek',
    'Ulft',
    'Terborg',
    'Megchelen',
    'Netterden',
    'Heerde',
    'Haalderen',
    'Mildam',
    'Uddel',
    'Abcoude',
    'Nederweert',
    'Liessel',
    'Barneveld',
    'Deurne',
    'Numansdorp',
    'Mijnsheerenland',
    'Veldhoven',
    'Ospel',
    'Bussum',
    'Putten',
    'Ermelo',
    'Ridderkerk',
    'Velddriel',
    'Papendrecht',
    'Valburg',
    'Westzaan',
    'Wijchen',
    'Tiel',
    'Wolfheze',
    'Roden',
    'Moordrecht',
    'Waddinxveen',
    'Mook',
    'Heythuysen',
    'Beek',
    'Hellendoorn',
    'Hoorn',
    'Domburg',
    'Wassenaar',
    'Zierikzee',
    'Petten',
    'Hardenberg',
    'Bergen',
    'Oirschot',
    'Kinderdijk',
    'Udenhout',
    'Dalfsen',
    'Lemiers',
    'Horn',
    'Flushing',
    'Ouddorp',
    'Winschoten',
    'Rockanje',
    'Nieuwkoop',
    'Wormerveer',
    'Gilze',
    'Rijswijk',
    'Venlo',
    'Bergeijk',
    'De Lutte',
    'Nieuwerkerk aan den IJssel',
    'Voorburg',
    'Haamstede',
    'Baarn',
    'Dokkum',
    'Nes',
    'Zwolle',
    'Rheden',
    'Schijndel',
    'Waalwijk',
    'Hapert',
    'De Westereen',
    'Kollum',
    'Buren',
    'Nieuwegein',
    'Mijdrecht',
    'Zeist',
    'Dieren',
    'Oosterhout',
    'Norg',
    'Veghel',
    'Gulpen',
    'Scherpenzeel',
    'Middelburg',
    'Roermond',
    'Heesch',
    'Renesse',
    'Sellingen',
    'Epen',
    'Eethen',
    'Rozenburg',
    'Oostvoorne',
    'Leiden',
    'Maastricht',
    'Bodegraven',
    'Witteveen',
    'Badhoevedorp',
    'Oudenbosch',
    'Nieuwkuijk',
    'Schinnen',
    'Dijk',
    'Heiloo',
    'Nootdorp',
    'Herten',
    'Bilthoven',
    'Naaldwijk',
    'Leusden',
    'Veenendaal',
    'Bergharen',
    "'s-Hertogenbosch",
    'Sint Anthonis',
    'Soest',
    'Soesterberg',
    'Woudenberg',
    'Beuningen',
    'Elst',
    'Zevenaar',
    'Best',
    'Ommen',
    'Deventer',
    'Ewijk',
    'Molenhoek',
    'Vragender',
    'Duivendrecht',
    'Dirksland',
    'Bergschenhoek',
    "'s-Graveland",
    'Krommenie',
    'West-Knollendam',
    'Zaandijk',
    'Beekbergen',
    'Zwijndrecht',
    'Wehl',
    'Assendelft',
    'Wittem',
    'Bladel',
    'Tilburg',
    'Oranje',
    'Delft',
    'Overasselt',
    'Noordwijk-Binnen',
    'Velden',
    'Lies',
    'Delfzijl',
    'Someren',
    'Zevenbergen',
    'Oostwold',
    'De Steeg',
    'Krimpen aan den IJssel',
    'Bergen op Zoom',
    'Kaatsheuvel',
    'Uithoorn',
    'Arcen',
    'Wognum',
    'Boxmeer',
    'Edam',
    'Vleuten',
    'Etten',
    'Baars',
    'Overloon',
    'IJsselstein',
    'Enkhuizen',
    'Haarlemmerliede',
    'Assen',
    'Hummelo',
    'Borger',
    'De Bilt',
    'Foxhol',
    'Wolvega',
    'Zoutelande',
    'Centrum',
    "'s-Gravenzande",
    'De Lier',
    'Wateringen',
    'Maassluis',
    'Hendrik-Ido-Ambacht',
    'Gouda',
    'Borculo',
    'Geesteren',
    'Hilvarenbeek',
    'Schipluiden',
    'Aalsmeer',
    'Malden',
    'Harderwijk',
    'Biest',
    'Doorn',
    'Kudelstaart',
    'Poortugaal',
    'Maasdijk',
    'Culemborg',
    'Maasland',
    'Honselersdijk',
    'Monster',
    'Kwintsheul',
    'Delfgauw',
    'Pijnacker',
    'Rhoon',
    'Leidschendam',
    'Velp',
    'Landsmeer',
    'Geldermalsen',
    'Nederhemert',
    'Uitgeest',
    'Doorwerth',
    'Den Helder',
    'Voorschoten',
    'Meppel',
    'Oss',
    'Weert',
    'Posterholt',
    'Spaubeek',
    'Lievelde',
    'Volendam',
    'Rijswijk',
    'Palemig',
    'Limbricht',
    'Beneden-Leeuwen',
    'Heerlen',
    'Ulvenhout',
    'Goes',
    'Lisse',
    'Rodenrijs',
    'Sassenheim',
    'Velsen',
    'Krimpen aan de Lek',
    'Staphorst',
    'Urmond',
    'Kerkrade',
    'Annen',
    'Retranchement',
    'Hedel',
    'Aagtekerke',
    'Eersel',
    'Roosendaal',
    'Born',
    'Noordwelle',
    'Roelofarendsveen',
    'Sittard',
    'Eibergen',
    'Harkema',
    'Surhuisterveen',
    'Heemstede',
    'Middelharnis',
    'Barendrecht',
    'Lochem',
    'Epse',
    'Heerjansdam',
    'Doetinchem',
    'Nieuwe-Tonge',
    'Brummen',
    'Biddinghuizen',
    'Doesburg',
    'Rixtel',
    'Waalre',
    'Wezep',
    'Boven-Leeuwen',
    'De Zilk',
    'Hoek van Holland',
    'Hurdegaryp',
    'Leuth',
    'Winssen',
    'Bennekom',
    'Eerbeek',
    'Voorst',
    'Winterswijk',
    'Nuenen',
    'Hoogvliet',
    'Duin',
    'Hoge Donk',
    'Oostzaan',
    'Oudehorne',
    'Afferden',
    'Puiflijk',
    'Grou',
    'Steensel',
    'Akkrum',
    'Oosterbeek',
    'Wintelre',
    'Aalten',
    'Kekerdom',
    'Soerendonk',
    'Weesp',
    'Erichem',
    'Berlicum',
    'Heinenoord',
    'Giesbeek',
    'Bemmel',
    'Nieuwendijk',
    'Aalst',
    'Heukelum',
    'Boven-Hardinxveld',
    'Muiderberg',
    'Hallum',
    'Steenbergen',
    'Sluis',
    'Moergestel',
    'Den Burg',
    'De Koog',
    'Slenaken',
    'Vierhouten',
    'IJmuiden',
    'Coevorden',
    'Almelo',
    'Laren',
    'Axel',
    'Zaamslag',
    'Terneuzen',
    'Hoek',
    'Westdorpe',
    'Hulst',
    'Koewacht',
    'Oostburg',
    'Arnemuiden',
    'Oudelande',
    'Hoedekenskerke',
    'Clinge',
    "'s-Heerenhoek",
    'Koudekerke',
    'Westkapelle',
    'Sint Annaland',
    'Tholen',
    'Sint Philipsland',
    'Scherpenisse',
    'Noordgouwe',
    'Bruinisse',
    'Oosterland',
    'Rilland',
    'Kruiningen',
    'Yerseke',
    'Oost-Souburg',
    'Eede',
    'Schore',
    'Wemeldinge',
    "'s-Gravenpolder",
    'Drunen',
    'Capelle aan den IJssel',
    'Sluiskil',
    'Kapelle',
    'Giessendam',
    'Bosch en Duin',
    'Oudemirdum',
    'Schagen',
    'Blaricum',
    'Zeewolde',
    'Anloo',
    'Houten',
    'Venhuizen',
    'Pieterburen',
    'Zandvoort',
    'Emmen',
    'Langedijk',
    'Wieringerwerf',
    'Grootebroek',
    'Rhenen',
    'Hoenderloo',
    'Garderen',
    'Buitenpost',
    'Montfoort',
    'Ezinge',
    'Sneek',
    'Joure',
    'Boesingheliede',
    'Ossendrecht',
    'Hoogerheide',
    'Hoogland',
    'Didam',
    'Munstergeleen',
    'Oijen',
    'Herwijnen',
    'Tweede Exloermond',
    'Noordwijkerhout',
    'Sprang',
    'Lage Mierde',
    'Elburg',
    'Oosterend',
    'Oirsbeek',
    'Rozenburg',
    'Holthone',
    'Biervliet',
    'Hoogeveen',
    'Neede',
    'Beilen',
    'Boerakker',
    'Simpelveld',
    'Wijk bij Duurstede',
    'Opheusden',
    'Sint Annaparochie',
    'Kesteren',
    'Overberg',
    'Epe',
    'Eelderwolde',
    'Alblasserdam',
    'Gemonde',
    'Smilde',
    'Overdinkel',
    'Uden',
    'Espel',
    'De Meern',
    'Zetten',
    'Hedel',
    'Buren',
    'Hollandsche Rading',
    'Engelen',
    'Beverwijk',
    'Noord-Scharwoude',
    'Reeuwijk',
    'Ankeveen',
    'Philippine',
    'Dongen',
    'Oegstgeest',
    'Damwald',
    'Hoensbroek',
    'Hoogkarspel',
    'Gorinchem',
    'Zuidoostbeemster',
    'Haren',
    'Westerbork',
    'Bavel',
    'Heijningen',
    'Dinteloord',
    'Loon op Zand',
    'Sint Joost',
    'Nieuwe Pekela',
    'Roodeschool',
    'Boelenslaan',
    'Oud-Alblas',
    'Kerk-Avezaath',
    'Sint Odilienberg',
    'Dalen',
    'Loozen',
    'Oosterbierum',
    'Lutten',
    'Nieuw-Lekkerland',
    'Rosmalen',
    'Dinxperlo',
    'Gorssel',
    'Den Dolder',
    'Toldijk',
    'Tynaarlo',
    'Borne',
    'Wervershoof',
    'Heemskerk',
    'Cuijk',
    'Oldebroek',
    'Zoeterwoude',
    'Leiderdorp',
    'Almere Haven',
    'Swalmen',
    'Goor',
    'Nijkerk',
    'Terheijden',
    'Vilt',
    'Baarle-Nassau',
    'Schoonhoven',
    'Nederhorst den Berg',
    'Beugen',
    'Waarland',
    'Hazerswoude-Dorp',
    'Volkel',
    'Bellingwolde',
    'Kantens',
    'Nieuwolda',
    'Diepenheim',
    'Augustinusga',
    'Appelscha',
    'Oldemarkt',
    'Tytsjerk',
    'Zuidland',
    'Groot-Ammers',
    'Achthuizen',
    'Maarn',
    'Belfeld',
    'De Wijk',
    'Ens',
    'Ane',
    'Heeten',
    'Raamsdonksveer',
    'Brielle',
    'Klundert',
    'Holtum',
    'Tegelen',
    'Kessel',
    'Grathem',
    'Maarssen',
    'Geleen',
    'Westmaas',
    's-Heerenberg',
    'De Horst',
    'Geervliet',
    'Meerssen',
    'Haaften',
    'Zaltbommel',
    'Lathum',
    'IJzendoorn',
    'Gemeente Rotterdam',
    'Fijnaart',
    'Aalden',
    'Harich',
    'Dodewaard',
    'Olst',
    'Beek',
    'Warmond',
    'Nijverdal',
    'Rijnsburg',
    'Driebruggen',
    'Ravenstein',
    'Middenbeemster',
    'Monnickendam',
    'Vinkeveen',
    'Zevenbergschen Hoek',
    'Zelhem',
    'Eefde',
    'Schalkhaar',
    'Veenoord',
    'Bunschoten',
    'Spakenburg',
    'Lichtenvoorde',
    'Heino',
    'Tricht',
    'Boskoop',
    'Mierlo',
    'Luyksgestel',
    'Muiden',
    'Beesd',
    'Jubbega',
    'Swifterbant',
    'Gendringen',
    'Rekken',
    'Budel-Dorplein',
    'Suwald',
    'Heelsum',
    'Riethoven',
    'Stiens',
    'Ameide',
    'Tolkamer',
    'Earnewald',
    'Noardburgum',
    'Garyp',
    'Reusel',
    'Dronryp',
    'Kerkdriel',
    'Ingen',
    'Hoogeloon',
    'Varik',
    'Rozendaal',
    'Drogeham',
    'Twijzelerheide',
    'Molenhoek',
    'Drempt',
    'Britsum',
    'Strijen',
    'Lent',
    'Budel',
    'Goudswaard',
    'Beusichem',
    'Klaaswaal',
    'Millingen aan de Rijn',
    'Oudorp',
    'Sumar',
    'Sommelsdijk',
    'Gameren',
    'Maarheeze',
    'Ellecom',
    'Marrum',
    'Baambrugge',
    'Gaanderen',
    'Goutum',
    'Ouderkerk aan de Amstel',
    'Pernis',
    'Lieshout',
    'Zwartebroek',
    'Plasmolen',
    'Gytsjerk',
    'Lienden',
    'Stellendam',
    'Sterksel',
    'Lexmond',
    'Santpoort-Noord',
    'Mookhoek',
    'Broek op Langedijk',
    'De Glind',
    'Baexem',
    'Yde',
    'Haaksbergen',
    'Werkendam',
    'Holten',
    'Andijk',
    'Colmschate',
    'Zeddam',
    'Bunnik',
    'Poeldijk',
    'Asperen',
    'Chaam',
    'Balk',
    'Noordwolde',
    'Kootstertille',
    'Denekamp',
    'Oldenzaal',
    'De Hoef',
    'Naarden',
    'Dwingeloo',
    'Ter Apel',
    'Eemnes',
    'Linschoten',
    'Venray',
    'Dedemsvaart',
    'Huis ter Heide',
    'Boeicop',
    'Zevenhoven',
    'De Waal',
    'Westerwijtwerd',
    'Boxtel',
    'Zwaag',
    'Katwijk',
    'Gendt',
    'Nieuwerbrug',
    'Hoogezand',
    'Broekhin',
    'Oranjewoud',
    'Hillegom',
    'Hierden',
    'Ederveen',
    'Genemuiden',
    'Valkenburg',
    'Markelo',
    'Borsele',
    'Velsen-Noord',
    'Sint Agatha',
    'Den Hoorn',
    'Feanwalden',
    'Walterswald',
    'Anjum',
    'Oosternijkerk',
    'Nieuw-Vennep',
    'Vogelenzang',
    'Sambeek',
    'Vlijmen',
    'Enschot',
    'Halsteren',
    'Lutjebroek',
    'Leersum',
    'Maurik',
    'Melick',
    'Enter',
    'Haaren',
    'Hooghalen',
    'Bathmen',
    'Veen',
    'Lunteren',
    'Ten Boer',
    'Gieten',
    'Schipborg',
    'Grijpskerk',
    'Gasselte',
    'Herpen',
    'Amstenrade',
    'Delden',
    'Einighausen',
    'Eys',
    'Joppe',
    'Willemstad',
    'Erica',
    'Lottum',
    'Montfort',
    'Maasbree',
    'Berkhout',
    'Vijfhuizen',
    'Eext',
    'Losser',
    'Vaals',
    'Kortenhoef',
    'Heteren',
    'Achtmaal',
    'Vlagtwedde',
    'Ureterp',
    'Partij',
    "'t Zand",
    'Groet',
    'Hartwerd',
    'Haelen',
    'Aerdenhout',
    'Moerkapelle',
    'Herkingen',
    'Hernen',
    'Berkenwoude',
    'Bredevoort',
    'Oene',
    'Ingelum',
    'Abbekerk',
    'Eexterzandvoort',
    'Bocholtz',
    'Gronsveld',
    'Bierum',
    'Farmsum',
    'Appingedam',
    'Spijk',
    'Oude Pekela',
    'Muntendam',
    'Noordbroek',
    'Meeden',
    'Zuidbroek',
    'Rottum',
    'Winsum',
    'Zandeweer',
    'Warffum',
    'Bedum',
    'Eenrum',
    'Ulrum',
    'Vriezenveen',
    'Vries',
    'Zuidlaren',
    'Buinerveen',
    'Tubbergen',
    'Albergen',
    'Wierden',
    'Uithuizermeeden',
    'Daarle',
    'Geesteren',
    'Fleringen',
    'Haarle',
    'Vroomshoop',
    'Manderveen',
    'Vasse',
    'Ysbrechtum',
    'Weidum',
    'Woudsend',
    'Nijland',
    'IJlst',
    'Heeg',
    'Hennaard',
    'Jutrijp',
    'Wommels',
    'Burgwerd',
    'Hoogersmilde',
    'Lemmer',
    'Hemelum',
    'Echtenerbrug',
    'Koudum',
    'Oosterwolde',
    'Haule',
    'Wijckel',
    'Bakhuizen',
    'Loppersum',
    'Schildwolde',
    'Stedum',
    'Zeerijp',
    'Oosterzee',
    'Workum',
    'Hindeloopen',
    'Hoogkerk',
    'Bovensmilde',
    'Rolde',
    'Hijken',
    'Wijster',
    'Tilligte',
    'Westerhaar-Vriezenveensewijk',
    'Delden',
    'Hengevelde',
    'Kamperland',
    'Rietveld',
    'Rossum',
    'Wilnis',
    'Roggel',
    'Wilp',
    'Spaarndam',
    'Castricum',
    'Poortvliet',
    'Helenaveen',
    'De Kwakel',
    'Bosschenhoofd',
    'Sint Willebrord',
    'Rucphen',
    'Stampersgat',
    'Sprundel',
    'Oud-Gastel',
    'Putte',
    'Liempde',
    'Bunde',
    'Appeltern',
    'Oudeschild',
    'Geertruidenberg',
    'Nieuw-Buinen',
    'Elsloo',
    'Steenwijk',
    'Hout',
    'Zwartemeer',
    'Balgoij',
    'Kampen',
    'Doornspijk',
    'Peize',
    'Oudehaske',
    'Dreumel',
    'Rivierenwijk',
    'Oudendijk',
    'Wormer',
    'Oisterwijk',
    'Leerdam',
    'Made',
    'Guttecoven',
    'Stadskanaal',
    'Kerkenveld',
    'Wijhe',
    'Rijssen',
    'Vlodrop',
    'Steijl',
    'Sloterdijk',
    'Schoondijke',
    'Hoofdplaat',
    'Kats',
    'Brouwershaven',
    'Wissenkerke',
    'Kortgene',
    'Colijnsplaat',
    'Gapinge',
    'Breskens',
    'Sint Maartensdijk',
    'Serooskerke',
    'Oostkapelle',
    'Sint Jansteen',
    'Heikant',
    'Vogelwaarde',
    'Sas van Gent',
    'Wolphaartsdijk',
    'Kloetinge',
    'Aardenburg',
    'Bruchem',
    'Arkel',
    'Moerdijk',
    'Genderen',
    'Stompwijk',
    'Hoornaar',
    'Nieuwveen',
    'Ter Aar',
    'Galder',
    'Prinsenbeek',
    'Teteringen',
    'Giessenburg',
    'Hoogblokland',
    'Meeuwen',
    'Noordeloos',
    'Lopik',
    'Ammerstol',
    'Bergambacht',
    'Hank',
    'Sleeuwijk',
    'Neer-Andel',
    'Harmelen',
    'Driebergen',
    'Odijk',
    'Renswoude',
    'Tull',
    'Maartensdijk',
    'Nieuwer-Ter-Aa',
    'Zijderveld',
    'Elsendorp',
    'Everdingen',
    'Voorhout',
    'Katwijk aan Zee',
    'Oud-Zuilen',
    'Loosdrecht',
    'Noordwijk aan Zee',
    'Zevenhuizen',
    'Hooglanderveen',
    'Craailo',
    'Berg',
    'Achterveld',
    'Zuidhorn',
    'Overveen',
    'Berghem',
    'Maasbommel',
    'Bitgum',
    'Kamerik',
    "'s-Heer-Hendrikskinderen",
    'Merkelbeek',
    'Hattem',
    'Holthees',
    'Gasselternijveen',
    'Wijk aan Zee',
    'Oosthuizen',
    'Rinsumageast',
    'Groessen',
    'Nuth',
    'Koudekerk aan den Rijn',
    'Oudkarspel',
    'Uithuizen',
    'Millingen',
    'Rijen',
    'Goirle',
    "'s Gravenmoer",
    'Waspik',
    'Heeswijk',
    'Aalburg',
    'De Moer',
    'Haren',
    'Schinveld',
    'Obbicht',
    'Sweikhuizen',
    'Voerendaal',
    'Klimmen',
    'Jabeek',
    'Schimmert',
    'Puth',
    'Eygelshoven',
    'Hulsberg',
    'Eijsden',
    'Rijsbergen',
    'Zundert',
    'Kruisland',
    'Nieuw-Vossemeer',
    'Wouwsche Plantage',
    'Oudemolen',
    'Heerle',
    'Nuland',
    'Helvoirt',
    'Cromvoirt',
    'Nistelrode',
    'Heusden',
    'Sint-Michielsgestel',
    'Den Dungen',
    'Maaskantje',
    'Herkenbosch',
    'Beesel',
    'Echt',
    'Ittervoort',
    'Maasbracht',
    'Reuver',
    'Wessem',
    'Stevensweert',
    'Susteren',
    'Stein',
    'Buchten',
    'Dieteren',
    'Nieuwstadt',
    'Roosteren',
    'Meijel',
    'Panningen',
    'Beringe',
    'Grashoek',
    'Helden',
    'Egchel',
    'Blerick',
    'Baarlo',
    'Well',
    'Groeningen',
    'Vierlingsbeek',
    'Rijkevoort',
    'Grave',
    'Zeeland',
    'Maashees',
    'Vortum-Mullem',
    'Wanroij',
    'Haps',
    'Schaijk',
    'Escharen',
    'Mill',
    'Stevensbeek',
    'Margraten',
    'Banholt',
    'Wijlre',
    'Terblijt',
    'Noorbeek',
    'Cadier en Keer',
    'Mechelen',
    'Milsbeek',
    'Nieuw-Bergen',
    'Ven-Zelderheide',
    'Wanssum',
    'Merselo',
    'Bergen',
    'Ottersum',
    'Grubbenvorst',
    'Blitterswijck',
    'Siebengewald',
    'Almkerk',
    'Woudrichem',
    'Heijen',
    'Meerlo',
    'Tienraij',
    'Horst',
    'America',
    'Diessen',
    'Molenschot',
    'Barchem',
    'Beinsdorp',
    'Buurse',
    'Kraggenburg',
    'Zonnemaire',
    'Tjerkwerd',
    'Nieuw-Balinge',
    'Nieuwdorp',
    'Vuren',
    'Erm',
    'Hengelo',
    'Gees',
    'Tijnje',
    'Ursem',
    'Varsseveld',
    'Maarssenbroek',
    'Limmen',
    'Finsterwolde',
    'Slochteren',
    'Anna Paulowna',
    'Ruurlo',
    'Halle',
    'Mastenbroek',
    'Uitwellingerga',
    'Nuis',
    'Ulicoten',
    'Ulestraten',
    'Gouderak',
    'Sint Pancras',
    'Bovenkarspel',
    'Lithoijen',
    'Milheeze',
    'IJzendijke',
    'Valthermond',
    'Papekop',
    'Heeze',
    'Zwartsluis',
    'Weurt',
    'Kalenberg',
    'Kockengen',
    'Sappemeer',
    'Angeren',
    'Purmerland',
    'Hazerswoude-Rijndijk',
    'Nijbroek',
    'Winkel',
    'Nijkerkerveen',
    'Ter Apelkanaal',
    'Hoevelaken',
    'Dorst',
    'Den Hout',
    'Wijdenes',
    'Hem',
    'Twisk',
    'Nibbixwoud',
    'Oostwoud',
    'Benningbroek',
    'Hoorn',
    'Benthuizen',
    'Katwoude',
    'Oterleek',
    'Obdam',
    'Stompetoren',
    'Tweede Valthermond',
    'De Rijp',
    'Schermerhorn',
    'Beets',
    'Broek in Waterland',
    'Wijdewormer',
    'Jisp',
    'Westbeemster',
    'De Goorn',
    'Avenhorn',
    'Schellinkhout',
    'Hensbroek',
    'Blokker',
    'Medemblik',
    'Hauwert',
    'Middenmeer',
    'Den Oever',
    'Hoogwoud',
    'Opmeer',
    'Spanbroek',
    'Spierdijk',
    'Keijenborg',
    'Nieuw-Beijerland',
    "Stad aan 't Haringvliet",
    'Gelselaar',
    'Werkhoven',
    'Gennep',
    'Driemond',
    'Heveadorp',
    'Nigtevecht',
    'Wamel',
    'Tuitjenhorn',
    'Zieuwent',
    'Langezwaag',
    'Stoutenburg',
    'Boekel',
    'Hooge Zwaluwe',
    'Woerdense Verlaat',
    'Nederasselt',
    'Waarder',
    'Surhuizum',
    'Wapenveld',
    'Marienberg',
    'Batenburg',
    'Marum',
    'Mortel',
    'Nieuwpoort',
    'Bergentheim',
    'Koedijk',
    'Marknesse',
    'Bloemendaal',
    'Homoet',
    'Huijbergen',
    'Doornenburg',
    'Warder',
    'Leimuiden',
    'Emst',
    'Westbroek',
    'Noordhorn',
    'Oostendam',
    'Neer',
    'Kootwijkerbroek',
    'Brucht',
    'Stolwijk',
    'Vreeland',
    'Gorredijk',
    'Garmerwolde',
    'Slijk-Ewijk',
    'Woltersum',
    'Haulerwijk',
    'Enspijk',
    'Ophemert',
    'Ilpendam',
    'Nieuwleusen',
    'Beerzerveld',
    'IJsselmuiden',
    'Uitdam',
    'Eckart',
    'Breugel',
    'Rips',
    'Brakel',
    'Elst',
    'Ochten',
    'Randwijk',
    'Hellouw',
    "'t Harde",
    'Lekkerkerk',
    'Rhenoy',
    'Lijnden',
    'Wildervank',
    'Vledder',
    'Rossum',
    'Zorgvlied',
    'Ferwert',
    'Bemelen',
    'Callantsoog',
    'Princenhof',
    'Cadzand',
    'Alem',
    'Broekhuizen',
    'Kaag',
    'Ootmarsum',
    'Groot-Agelo',
    'Oudewater',
    'Vrouwenpolder',
    'Houtakker',
    'Ugchelen',
    'Molenaarsgraaf',
    'Hekelingen',
    'Tinte',
    'Zwartewaal',
    'Heenvliet',
    'Vierpolders',
    'Pannerden',
    'Lobith',
    'Babberich',
    'Aerdt',
    'Groenlo',
    'Meerkerk',
    'Alde Leie',
    'Westerhoven',
    'Rooth',
    'Wierum',
    'Ballum',
    'Alphen',
    'Hoogmade',
    'Woubrugge',
    'Noorden',
    'Wagenberg',
    'Lage Zwaluwe',
    'Amerongen',
    'Breukeleveen',
    'Tienhoven',
    'Stationsbuurt',
    'Beegden',
    'Nunhem',
    'Ell',
    'Julianadorp',
    'Breezand',
    'Oudesluis',
    'Zoutkamp',
    'Steggerda',
    'Mussel',
    'Gieterveen',
    'Garsthuizen',
    'Kropswolde',
    'Aduard',
    'Briltil',
    'Oldekerk',
    'Kommerzijl',
    'Grootegast',
    'Valthe',
    'Pieterzijl',
    'Opeinde',
    'Glimmen',
    'Scharmer',
    'Harkstede',
    'Eelde-Paterswolde',
    'Enumatil',
    'Tolbert',
    'De Wilp',
    'Noordlaren',
    'Paterswolde',
    'Onnen',
    'Diepenveen',
    'Lettele',
    'Nieuw-Schoonebeek',
    'Schoonebeek',
    'Noord-Sleen',
    'Emmer-Compascuum',
    'Klazienaveen',
    'Oosterhesselen',
    'Nieuwlande',
    'Noordscheschut',
    'Stuifzand',
    'Zuidwolde',
    'Zwinderen',
    'Hollandscheveld',
    'Gramsbergen',
    'Kloosterhaar',
    'Slagharen',
    'Sibculo',
    'Vorstenbosch',
    'Linne',
    'Kolham',
    'Beerta',
    'Scheemda',
    'Siddeburen',
    'Havelte',
    'Oosterwolde',
    'Giethoorn',
    'Koekange',
    'Ruinerwold',
    'Frederiksoord',
    'IJhorst',
    'Diever',
    'Den Bommel',
    'Ooltgensplaat',
    'Lienden',
    'Heilig Landstichting',
    'Berg en Dal',
    'Stroe',
    'Herveld',
    'Franeker',
    'Zuidermeer',
    'Budel-Schoot',
    'Oud-Ade',
    'Nieuweschoot',
    "'t Kabel",
    'Baken',
    'Halfweg',
    'Zuid-Beijerland',
    'Piershil',
    'Hulshorst',
    'Zaanstad',
    'Bolsward',
    'Ammerzoden',
    'Laag-Soeren',
    'Ressen',
    'Dorp',
    'Venhorst',
    'Bourtange',
    'Vlieland',
    'Zwaagdijk-Oost',
    'Zuidzande',
    'Otterlo',
    'De Punt',
    'Cruquius',
    'De Klomp',
    'Makkum',
    'Fluitenberg',
    'Buitenkaag',
    'Terschuur',
    'Rijckholt',
    'Nijnsel',
    'Oeffelt',
    'Zalk',
    'Schiermonnikoog',
    'Akersloot',
    'Schin op Geul',
    'Holwierde',
    'Den Deijl',
    'Heeswijk-Dinther',
    'Taarlo',
    'Ruinen',
    'Witharen',
    'Vorden',
    'Oldehove',
    'Nijhuizum',
    'Amstelhoek',
    'Driel',
    'Meer',
    'Burum',
    'Ovezande',
    'Beetsterzwaag',
    'Dirkshorn',
    'Keldonk',
    'Sint Hubert',
    'Limmel',
    'Waterland',
    'Burgerveen',
    'Anderen',
    'Wijngaarden',
    'Nieuw-Namen',
    'Kuitaart',
    'Rijpwetering',
    'Oude Wetering',
    'Nieuwe Wetering',
    'Nieuwehorne',
    'Den Ilp',
    'Schoorl',
    'Ten Post',
    'Wagenborgen',
    'Legemeer',
    'Sint Nicolaasga',
    'Exloo',
    'Veenhuizen',
    'Doenrade',
    'Wijnandsrade',
    'Middelbeers',
    'Achterberg',
    'Zuiderpark',
    'Woensdrecht',
    'Hollum',
    'Hantum',
    'Oudwoude',
    'De Falom',
    'Hantumhuizen',
    'Baarland',
    'Harlingen',
    'Wijnaldum',
    'Herbaijum',
    'Tzummarum',
    'Oldeberkoop',
    'Lippenhuizen',
    'Ravenswoud',
    'Sintjohannesga',
    'Rottum',
    'West-Terschelling',
    'Echten',
    'Midlum',
    'De Blesse',
    'Oldeholtpade',
    'Kimswerd',
    'Wons',
    'Pingjum',
    'Hippolytushoef',
    'Slootdorp',
    'Kolhorn',
    'Nieuwe-Niedorp',
    'Zuid-Scharwoude',
    'Hasselt',
    'Urk',
    'Rutten',
    'Lemele',
    'Lemelerveld',
    'Blokzijl',
    'Schagerbrug',
    'Sint Maarten',
    'Warmenhuizen',
    'Groenveld',
    'Sleen',
    'Nieuw-Amsterdam',
    'Nieuw-Dordrecht',
    'Bennebroek',
    'Rijsenhout',
    'Aalsmeerderbrug',
    'Abbenes',
    'Zwaanshoek',
    'Zwanenburg',
    'Egmond-Binnen',
    'Lutjewinkel',
    'Wieringerwaard',
    'Driehuis',
    'Saasveld',
    'Weerselo',
    'Lewenborg',
    'Ooij',
  ],
  tld: ['.nl'],
  cca2: 'NL',
  ccn3: '528',
  cca3: 'NLD',
  cioc: 'NED',
  currency: ['EUR'],
  callingCode: ['31'],
  capital: 'Amsterdam',
  altSpellings: ['NL', 'Holland', 'Nederland'],
  region: 'Europe',
  subregion: 'Western Europe',
  languages: { nld: 'Dutch' },
  translations: {
    deu: { official: 'Niederlande', common: 'Niederlande' },
    fra: { official: 'Pays-Bas', common: 'Pays-Bas' },
    hrv: { official: 'Holandija', common: 'Nizozemska' },
    ita: { official: 'Paesi Bassi', common: 'Paesi Bassi' },
    jpn: { official: 'オランダ', common: 'オランダ' },
    nld: { official: 'Nederland', common: 'Nederland' },
    por: { official: 'Holanda', common: 'Holanda' },
    rus: { official: 'Нидерланды', common: 'Нидерланды' },
    spa: { official: 'Países Bajos', common: 'Países Bajos' },
    fin: { official: 'Alankomaat', common: 'Alankomaat' },
  },
  latlng: [52.5, 5.75],
  demonym: 'Dutch',
  landlocked: false,
  borders: ['BEL', 'DEU'],
  area: 41850,
  provinces: [
    'Drenthe',
    'Flevoland',
    'Friesland',
    'Gelderland',
    'Groningen',
    'Limburg',
    'Noord-Brabant',
    'Noord-Holland',
    'Overijssel',
    'Utrecht',
    'Zeeland',
    'Zuid-Holland',
  ],
  nativeName: 'Nederland',
} as const;
