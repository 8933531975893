var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("span", { staticClass: "spinner", style: _setup.rootStyle }, [
    _c("div", { ref: "anchorEl" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }