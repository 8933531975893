import { HorizontalDirection, VerticalDirection } from './util';

/** ... */
export const defaults = {
  position: ['top', 'right'] as [VerticalDirection, HorizontalDirection],
  cssAnimation: 'vn-fade',
  velocityAnimation: {
    enter: (el: HTMLElement) => {
      const height = el.clientHeight;

      return { height: [height, 0], opacity: [1, 0] };
    },
    leave: { height: 0, opacity: [0, 1] },
  },
} as const;
