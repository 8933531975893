export const BOL = {
  name: {
    common: 'Bolivia',
    official: 'Plurinational State of Bolivia',
    native: {
      aym: { official: 'Wuliwya Suyu', common: 'Wuliwya' },
      grn: { official: 'Tetã Volívia', common: 'Volívia' },
      que: { official: 'Buliwya Mamallaqta', common: 'Buliwya' },
      spa: { official: 'Estado Plurinacional de Bolivia', common: 'Bolivia' },
    },
  },
  cities: [
    'La Paz',
    'Santa Cruz',
    'Sucre',
    'Cochabamba',
    'Santa Cruz',
    'PotosÃ­',
    'Oruro',
    'Guayaramerin',
    'Tarija',
    'Riberalta',
    'Trinidad',
    'Bermejo',
    'Cruz',
    'Caracasa',
    'Cobija',
    'Aroma',
    'Cotoca',
    'Anillo',
  ],
  tld: ['.bo'],
  cca2: 'BO',
  ccn3: '068',
  cca3: 'BOL',
  cioc: 'BOL',
  currency: ['BOB', 'BOV'],
  callingCode: ['591'],
  capital: 'Sucre',
  altSpellings: [
    'BO',
    'Buliwya',
    'Wuliwya',
    'Bolivia, Plurinational State of',
    'Plurinational State of Bolivia',
    'Estado Plurinacional de Bolivia',
    'Buliwya Mamallaqta',
    'Wuliwya Suyu',
    'Tetã Volívia',
  ],
  region: 'Americas',
  subregion: 'South America',
  languages: { aym: 'Aymara', grn: 'Guaraní', que: 'Quechua', spa: 'Spanish' },
  translations: {
    cym: { official: 'Plurinational State of Bolivia', common: 'Bolifia' },
    deu: { official: 'Multinationaler Staat von Bolivien', common: 'Bolivien' },
    fra: { official: 'État plurinational de Bolivie', common: 'Bolivie' },
    hrv: { official: 'Plurinational State of Bolivia', common: 'Bolivija' },
    ita: { official: 'Stato Plurinazionale della Bolivia', common: 'Bolivia' },
    jpn: { official: 'ボリビアの多民族国', common: 'ボリビア多民族国' },
    nld: { official: 'Plurinationale Staat van Bolivia', common: 'Bolivia' },
    por: { official: 'Estado Plurinacional da Bolívia', common: 'Bolívia' },
    rus: {
      official: 'Многонациональное Государство Боливия',
      common: 'Боливия',
    },
    spa: { official: 'Estado Plurinacional de Bolivia', common: 'Bolivia' },
    fin: { official: 'Bolivian monikansainen valtio', common: 'Bolivia' },
  },
  latlng: [-17, -65],
  demonym: 'Bolivian',
  landlocked: true,
  borders: ['ARG', 'BRA', 'CHL', 'PRY', 'PER'],
  area: 1098581,
  provinces: [
    'Beni',
    'Chuquisaca',
    'Cochabamba',
    'La Paz',
    'Oruro',
    'Pando',
    'Potosi',
    'Santa Cruz',
    'Tarija',
  ],
  nativeName: 'Bolivia',
  timezones: ['UTC−04:00'],
} as const;
