import { api } from '@api';

export type CartItem = api.payments.CreateCheckoutSessionOptions.CartItem;

export type Context =
  | 'shop'
  | 'student-seat'
  | 'organization-seats'
  | 'student-certifications';

/**
 * {@link initiateUserCheckout User checkout} configuration options.
 */
export interface UserCheckoutOptions
  extends api.payments.CreateCheckoutSessionOptions {
  context?: Context;
}

/**
 * Initiate checkout process for the current site user given a list of products.
 * This will create a remote Stripe checkout session before redirecting the user
 * to a dedicated checkout webpage hosted by Stripe.
 *
 * @param options User checkout options data object.
 */
export async function initiateUserCheckout(options: UserCheckoutOptions) {
  const { sessionUrl } = await api.payments.createSession(options);

  if (!sessionUrl) {
    throw new Error(
      '[pos:initiateUserCheckout] request to create session did not result in a valid session URL.',
    );
  }

  window.location.assign(sessionUrl);
}
