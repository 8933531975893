import { Module, VuexModule, Mutation, Action } from '@vuex/decorators';

import { api } from '@api';
import { DLC, DLCSubmission } from '@models';
import { Root } from '@store';
import { findById, addById, updateById } from '@utils/array';

import { rcompare } from 'semver';

declare module '@vuex/core' {
  export interface Getters {
    'dlc/findById': Dlc['findById'];
    'dlc/findLatestSubmission': Dlc['findLatestSubmission'];
    'dlc/sortedSubmissions': Dlc['sortedSubmissions'];
  }

  export interface CommitMap {
    'dlc/SET': Dlc['SET'];
    'dlc/ADD': Dlc['ADD'];
    'dlc/UPDATE': Dlc['UPDATE'];
    // 'dlc/DELETE': Dlc['DELETE'];
    'dlc/CLEAR': Dlc['CLEAR'];
  }

  export interface DispatchMap {
    'dlc/list': Dlc['list'];
    'dlc/get': Dlc['get'];
    'dlc/create': Dlc['create'];
    'dlc/update': Dlc['update'];
    'dlc/startDLCBuild': Dlc['startDLCBuild'];
    'dlc/approveDenyDLCBuild': Dlc['approveDenyDLCBuild'];
    // 'dlc/del': Dlc['del'];
  }
}

@Module({ namespaced: true })
export class Dlc
  extends VuexModule<Dlc.State, Root.State>
  implements Dlc.State
{
  items: DLC[] = [];

  /** Fetch a specific DLC data object by ID */
  get findById() {
    return (id: string) => findById(this.items, id);
  }

  /** Fetch the latest DLC submission given ID and OS */
  get findLatestSubmission() {
    return (id: string, os: 'win' | 'mac') => {
      return this.sortedSubmissions(id, os)?.[0];
    };
  }

  /** Fetch a sorted list of DLC submissions given ID and OS */
  get sortedSubmissions() {
    return (id: string, os: 'win' | 'mac') => {
      const dlc = findById(this.items, id);

      const dlcSubmissions = dlc?.[os]?.submissions;

      if (!dlcSubmissions || !Array.isArray(dlcSubmissions)) return;

      return dlcSubmissions
        .map((item: DLCSubmission) => {
          let status: DLCSubmission['status'] = 'N/A';

          if (Array.isArray(item.events)) {
            status = item.events.reduce((a, b) =>
              new Date(a.timestamp) > new Date(b.timestamp) ? a : b,
            )?.status as DLCSubmission['status'];
          }

          if (!status) status = 'N/A';

          return { ...item, status };
        })
        .sort((a: DLCSubmission, b: DLCSubmission) =>
          rcompare(a.version, b.version),
        ) as DLCSubmission[];
    };
  }

  //#region Mutations

  /**
   * ...
   */
  @Mutation
  SET(options: Dlc.SetMutationOptions) {
    this.items = options.data;
  }

  /**
   * ...
   */
  @Mutation
  ADD(options: Dlc.AddMutationOptions) {
    this.items = addById(this.items, options);
  }

  /**
   * ...
   */
  @Mutation
  UPDATE({ id, ...options }: Dlc.UpdateMutationOptions) {
    this.items = updateById(this.items, { id, ...options });
  }

  /**
   * ...
   */
  //   @Mutation
  //   DELETE(options: Dlc.DeleteMutationOptions) {
  //     this.items = removeById(this.items, options.dlcId);
  //   }

  /**
   * ...
   */
  @Mutation
  CLEAR() {
    this.items = [];
  }

  //#endregion Mutations

  //#region Actions

  /**
   * ...
   */
  @Action
  async list() {
    const data = await api.dlc.list();

    this.context.commit('SET', { data });

    return this.items;
  }

  /**
   * ...
   */
  @Action
  async get(options: Dlc.GetActionOptions) {
    const data = await api.dlc.get(options);

    data.id = options.dlcId;

    // This should just update the win/mac list
    // on the DLC object since that's what's returned
    // on the get API call

    this.context.commit('UPDATE', data);

    return data;
  }

  /**
   * ...
   */
  @Action
  async create(options: Dlc.CreateActionOptions) {
    const data = await api.dlc.create(options);

    this.context.commit('ADD', data);

    return data;
  }

  /**
   * ...
   */
  @Action
  async update(options: Dlc.UpdateActionOptions) {
    await api.dlc.update(options);

    return await this.context.dispatch('get', { dlcId: options.id });
  }

  /**
   * ...
   */
  @Action async startDLCBuild(options: Dlc.StartDLCBuildOptions) {
    await api.dlc.startSubmission(options);

    return await this.context.dispatch('get', options);
  }

  /**
   * ...
   */
  @Action async approveDenyDLCBuild(options: Dlc.ApproveDenyDLCBuildOptions) {
    await api.dlc.approveDenySubmission(options);

    return await this.context.dispatch('get', options);
  }
}

export namespace Dlc {
  export interface State {
    items: DLC[];
  }

  export interface SetMutationOptions {
    data: DLC[];
  }

  export type AddMutationOptions = DLC;
  export type GetActionOptions = api.dlc.GetOptions;
  export type CreateActionOptions = api.dlc.CreateOptions;
  export type UpdateMutationOptions = DLC;
  export type UpdateActionOptions = api.dlc.UpdateOptions;
  export type StartDLCBuildOptions = api.dlc.StartOptions;
  export type ApproveDenyDLCBuildOptions = api.dlc.ApproveDenyOptions;
}

export default Dlc;
