export const MYT = {
  name: {
    common: 'Mayotte',
    official: 'Department of Mayotte',
    native: { fra: { official: 'Département de Mayotte', common: 'Mayotte' } },
  },
  cities: [
    'Koungou',
    'Mamoudzou',
    'Tsoundzou 1',
    'Pamandzi',
    'Sada',
    'Chiconi',
    'Ouangani',
    'Tsingoni',
    'Bandaboa',
    'Dzoumonye',
    'Combani',
    'Dzaoudzi',
  ],
  tld: ['.yt'],
  cca2: 'YT',
  ccn3: '175',
  cca3: 'MYT',
  cioc: '',
  currency: ['EUR'],
  callingCode: ['262'],
  capital: 'Mamoudzou',
  altSpellings: ['YT', 'Department of Mayotte', 'Département de Mayotte'],
  region: 'Africa',
  subregion: 'Eastern Africa',
  languages: { fra: 'French' },
  translations: {
    deu: { official: 'Übersee-Département Mayotte', common: 'Mayotte' },
    fra: { official: 'Département de Mayotte', common: 'Mayotte' },
    hrv: { official: 'Odjel Mayotte', common: 'Mayotte' },
    ita: { official: 'Dipartimento di Mayotte', common: 'Mayotte' },
    jpn: { official: 'マヨット科', common: 'マヨット' },
    nld: { official: 'Afdeling Mayotte', common: 'Mayotte' },
    por: { official: 'Departamento de Mayotte', common: 'Mayotte' },
    rus: { official: 'Департамент Майотта', common: 'Майотта' },
    spa: { official: 'Departamento de Mayotte', common: 'Mayotte' },
    fin: { official: 'Mayotte', common: 'Mayotte' },
  },
  latlng: [-12.83333333, 45.16666666],
  demonym: 'Mahoran',
  landlocked: false,
  borders: [],
  area: 374,
} as const;
