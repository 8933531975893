export const ETH = {
  name: {
    common: 'Ethiopia',
    official: 'Federal Democratic Republic of Ethiopia',
    native: {
      amh: { official: 'የኢትዮጵያ ፌዴራላዊ ዲሞክራሲያዊ ሪፐብሊክ', common: 'ኢትዮጵያ' },
    },
  },
  cities: ['Addis Ababa', 'Awasa', 'Jijiga'],
  tld: ['.et'],
  cca2: 'ET',
  ccn3: '231',
  cca3: 'ETH',
  cioc: 'ETH',
  currency: ['ETB'],
  callingCode: ['251'],
  capital: 'Addis Ababa',
  altSpellings: [
    'ET',
    'ʾĪtyōṗṗyā',
    'Federal Democratic Republic of Ethiopia',
    'የኢትዮጵያ ፌዴራላዊ ዲሞክራሲያዊ ሪፐብሊክ',
  ],
  region: 'Africa',
  subregion: 'Eastern Africa',
  languages: { amh: 'Amharic' },
  translations: {
    cym: {
      official: 'Federal Democratic Republic of Ethiopia',
      common: 'Ethiopia',
    },
    deu: {
      official: 'Demokratische Bundesrepublik Äthiopien',
      common: 'Äthiopien',
    },
    fra: {
      official: "République fédérale démocratique d'Éthiopie",
      common: 'Éthiopie',
    },
    hrv: {
      official: 'Savezna Demokratska Republika Etiopija',
      common: 'Etiopija',
    },
    ita: {
      official: 'Repubblica federale democratica di Etiopia',
      common: 'Etiopia',
    },
    jpn: { official: 'エチオピア連邦民主共和国', common: 'エチオピア' },
    nld: {
      official: 'Federale Democratische Republiek Ethiopië',
      common: 'Ethiopië',
    },
    por: {
      official: 'República Federal Democrática da Etiópia',
      common: 'Etiópia',
    },
    rus: {
      official: 'Федеративная Демократическая Республика Эфиопия',
      common: 'Эфиопия',
    },
    spa: {
      official: 'República Democrática Federal de Etiopía',
      common: 'Etiopía',
    },
    fin: {
      official: 'Etiopian demokraattinen liittotasavalta',
      common: 'Etiopia',
    },
  },
  latlng: [8, 38],
  demonym: 'Ethiopian',
  landlocked: true,
  borders: ['DJI', 'ERI', 'KEN', 'SOM', 'SSD', 'SDN'],
  area: 1104300,
  provinces: [
    'Adis Abeba (Addis Ababa)',
    'Afar',
    'Amara',
    'Dire Dawa',
    'Gambela Hizboch',
    'Hareri Hizb',
    'Oromiya',
    'Sumale',
    'Tigray',
    'YeDebub Biheroch Bihereseboch na Hizboch',
  ],
  nativeName: 'ኢትዮጵያ',
  timezones: ['UTC+03:00'],
} as const;
