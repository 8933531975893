var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-container",
    { staticClass: "order-card-item" },
    [
      _c(
        "b-row",
        { staticClass: "py-3" },
        [
          _c("b-col", { attrs: { cols: "auto" } }, [
            _c("div", {
              staticClass: "item-thumbnail",
              style: { backgroundImage: `url(${_setup.imageUrl})` },
            }),
          ]),
          _vm._v(" "),
          _c(
            "b-col",
            [
              _c("div", { staticClass: "h6" }, [
                _vm._v(_vm._s(_vm.cartItem.name)),
              ]),
              _vm._v(" "),
              _vm._l(_setup.itemDetails, function (item) {
                return _c("div", { key: item.id, staticClass: "item-detail" }, [
                  _c("div", { staticClass: "detail-label" }, [
                    _vm._v(_vm._s(item.label)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "detail-spacer" },
                    [_c(_setup.Spacer)],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "detail-value" },
                    [
                      _c(
                        item.component,
                        _vm._b(
                          { tag: "component" },
                          "component",
                          { value: item.value },
                          false
                        )
                      ),
                    ],
                    1
                  ),
                ])
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }