var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "button",
    {
      class: [
        "mobile-menu-button",
        _vm.collapsed ? "toggle-close" : "toggle-open",
      ],
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", [
      _c("span", { staticClass: "icon-bar" }),
      _vm._v(" "),
      _c("span", { staticClass: "icon-bar" }),
      _vm._v(" "),
      _c("span", { staticClass: "icon-bar" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }