export const JOR = {
  name: {
    common: 'Jordan',
    official: 'Hashemite Kingdom of Jordan',
    native: {
      ara: { official: 'المملكة الأردنية الهاشمية', common: 'الأردن' },
    },
  },
  cities: ['Amman', 'Irbid', 'Farah', 'Sahab', 'Madaba', 'Salt', 'Ar Ramtha'],
  tld: ['.jo', 'الاردن.'],
  cca2: 'JO',
  ccn3: '400',
  cca3: 'JOR',
  cioc: 'JOR',
  currency: ['JOD'],
  callingCode: ['962'],
  capital: 'Amman',
  altSpellings: [
    'JO',
    'Hashemite Kingdom of Jordan',
    'al-Mamlakah al-Urdunīyah al-Hāshimīyah',
  ],
  region: 'Asia',
  subregion: 'Western Asia',
  languages: { ara: 'Arabic' },
  translations: {
    deu: {
      official: 'Haschemitisches Königreich Jordanien',
      common: 'Jordanien',
    },
    fra: { official: 'Royaume hachémite de Jordanie', common: 'Jordanie' },
    hrv: { official: 'Hašemitske Kraljevine Jordan', common: 'Jordan' },
    ita: { official: 'Regno hascemita di Giordania', common: 'Giordania' },
    jpn: { official: 'ヨルダン·ハシミテ王国', common: 'ヨルダン' },
    nld: { official: 'Hasjemitisch Koninkrijk Jordanië', common: 'Jordanië' },
    por: { official: 'Reino Hachemita da Jordânia', common: 'Jordânia' },
    rus: {
      official: 'Иорданского Хашимитского Королевства',
      common: 'Иордания',
    },
    spa: { official: 'Reino Hachemita de Jordania', common: 'Jordania' },
    fin: {
      official: 'Jordanian hašemiittinen kunigaskunta',
      common: 'Jordania',
    },
  },
  latlng: [31, 36],
  demonym: 'Jordanian',
  landlocked: false,
  borders: ['IRQ', 'ISR', 'SAU', 'SYR'],
  area: 89342,
  provinces: [
    "'Amman",
    'Ajlun',
    "Al 'Aqabah",
    "Al Balqa'",
    'Al Karak',
    'Al Mafraq',
    'At Tafilah',
    "Az Zarqa'",
    'Irbid',
    'Jarash',
    "Ma'an",
    'Madaba',
  ],
  nativeName: 'الأردن',
  timezones: ['UTC+03:00'],
} as const;
