import { Module, Action } from '@vuex/decorators';
import { TablePanel } from 'table-panel';

import { faEdit } from '@icons/solid/faEdit';
import { faCopy } from '@icons/solid/faCopy';

import { dateFilter } from '@filters/date';
import { Assignment } from '@models';
import { modals } from '@modals';

/** ... */
const columns: TablePanel.Column<Assignment>[] = [
  {
    key: 'dueDate',
    label: 'Due Date',
    value: ({ dueDate }) => dateFilter(dueDate, 'MM/dd/yyyy'),
  },
  {
    key: 'course',
    label: 'Course',
    value: ({ course }) => course.name,
  },
  {
    key: 'module',
    label: 'Module',
    value: ({ module }) => module.name,
  },
  {
    key: 'institution',
    label: 'Institution',
    value: ({ organization }) => organization.name,
  },
];

/** ... */
const operations: TablePanel.Row.Operation<Assignment>[] = [
  {
    label: 'Edit',
    icon: faEdit,
    fn: ({ id, organization, course }) => {
      void modals.assignment.edit({
        assignmentId: id,
        organizationId: organization.id,
        courseId: course.id,
      });
    },
  },
  {
    label: 'Create Copy',
    icon: faCopy,
    fn: ({ id, organization, course }) => {
      void modals.assignment.create({
        assignmentId: id,
        organizationId: organization.id,
        courseId: course.id,
      });
    },
  },
];

/** ... */
const filterFields = ['course', 'module', 'institution'];

@Module({ namespaced: true })
export class AssignmentsTablePanel
  extends TablePanel<Assignment>
  implements TablePanel.Props<Assignment>
{
  readonly module = 'assignments';
  readonly loadAction = 'list';
  readonly pk = 'id';
  readonly label = 'ASSIGNMENTS';
  readonly columns = columns;
  readonly operations = operations;
  readonly filterFields = filterFields;
  readonly createAction = 'createAssignment';
  readonly deleteAction = 'deleteAssignment';

  get enabled() {
    return this.isActiveRole(9);
  }

  @Action
  async createAssignment() {
    await modals.assignment.create();
  }

  @Action
  async deleteAssignment({ items }: AssignmentsTablePanel.DeleteItemOptions) {
    await modals.confirm.deleteItems({ itemType: 'assignments', items });
  }
}

export namespace AssignmentsTablePanel {
  /** ... */
  export interface DeleteItemOptions {
    items: Assignment[];
  }
}
