import { Module } from '@vuex/decorators';
import { TablePanel } from 'table-panel';

import { dateFilter } from '@filters/date';
import { modals } from '@modals';
import { Order } from '@models';
import * as currencies from '@utils/currencies';

import { faEye } from '@icons/solid/faEye';

const columns: TablePanel.Column<Order>[] = [
  {
    key: 'created',
    label: 'Created',
    value: ({ createdAt }) => {
      return dateFilter(createdAt, 'MM/dd/yyyy hh:mm:ss');
    },
  },
  {
    key: 'email',
    label: 'Email',
    value: 'email',
  },
  {
    key: 'total',
    label: 'Total',
    value: ({ total, currency }) => {
      return currencies.format({
        amount: total,
        currency,
        unitType: 'primary',
      });
    },
  },
];

const operations: TablePanel.Row.Operation<Order>[] = [
  {
    label: 'View',
    icon: faEye,
    fn: ({ id }) => {
      void modals.order.view({ order: id });
    },
  },
];

@Module({ namespaced: true })
export class MyTransactionsTablePanel
  extends TablePanel<Order>
  implements TablePanel.Props<Order>
{
  readonly module = 'orders';
  readonly loadAction = 'listMine';
  readonly pk = 'id';
  readonly label = 'MY ORDERS';
  readonly columns = columns;
  readonly operations = operations;

  readonly enabled = true;
  readonly progressive = false;
}
