var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "user-roles-table-cell" },
    [
      _setup.roles.length
        ? _vm._l(_setup.roles, function (role) {
            return _c("div", { key: role.id }, [
              _c("span", [_vm._v("•")]),
              _vm._v(" "),
              _c("span", { staticClass: "role-name pr-1" }, [
                _vm._v(_vm._s(role.name)),
              ]),
              _vm._v(" "),
              role.organization?.name
                ? _c("span", { staticClass: "role-institution" }, [
                    _c("span", { staticClass: "sub-label" }, [
                      _vm._v("Institution:"),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "sub-value" }, [
                      _vm._v(_vm._s(role.organization.name)),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              role.course?.name ? _c("span", [_vm._v(", ")]) : _vm._e(),
              _vm._v(" "),
              role.course?.name
                ? _c("span", { staticClass: "role-course" }, [
                    _c("span", { staticClass: "sub-label" }, [
                      _vm._v("Course:"),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "sub-value" }, [
                      _vm._v(_vm._s(role.course.name)),
                    ]),
                  ])
                : _vm._e(),
            ])
          })
        : _c("span", [_vm._v(" ... ")]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }