/** ... */
type Formatter = (date: Date) => string;
/** ... */
type TokenFormatter = (this: Date, format: string) => string;

/** ... */
// const defaultLongDateFormat = {
//   LTS: 'h:mm:ss A',
//   LT: 'h:mm A',
//   L: 'MM/DD/YYYY',
//   LL: 'MMMM D, YYYY',
//   LLL: 'MMMM D, YYYY h:mm A',
//   LLLL: 'dddd, MMMM D, YYYY h:mm A',
// };

/** ... */
const formattingTokens =
  /(\[[^[]*\])|(\\)?([Hh]mm(ss)?|Mo|MM?M?M?|Do|DDDo|DD?D?D?|ddd?d?|do?|w[o|w]?|W[o|W]?|Qo?|N{1,5}|YYYYYY|YYYYY|YYYY|YY|y{2,4}|yo?|gg(ggg?)?|GG(GGG?)?|e|E|a|A|hh?|HH?|kk?|mm?|ss?|S{1,9}|x|X|zz?|ZZ?|.)/g;

/** ... */
const localFormattingTokens = /(\[[^[]*\])|(\\)?(LTS|LT|LL?L?L?|l{1,4})/g;
/** ... */
const formatFunctions: Partial<Record<string, Formatter>> = {};
/** ... */
const formatTokenFunctions: Partial<Record<string, TokenFormatter>> = {};

/**
 * Format date using native date object.
 *
 * @param date ...
 * @param format ...
 * @return ...
 */
export function formatDate(date: Date, format: string) {
  // ...
  format = expandFormat(format);

  const fn = (formatFunctions[format] =
    formatFunctions[format] ?? makeFormatFunction(format));

  return fn(date);
}

/**
 * ...
 *
 * @param input ...
 * @return ...
 */
function removeFormattingTokens(input: string) {
  return input.match(/\[[\s\S]/)
    ? input.replace(/^\[|\]$/g, '')
    : input.replace(/\\/g, '');
}

/**
 * ...
 *
 * @param format ...
 * @return ...
 */
function makeFormatFunction(format: string) {
  // ...
  const matches = format.match(formattingTokens);

  // ...
  const arr = (matches ?? []).map((value) => {
    return formatTokenFunctions[value] ?? removeFormattingTokens(value);
  });

  return function (date: Date) {
    let output = '';

    for (const fn of arr) {
      output += typeof fn === 'function' ? fn.call(date, format) : fn;
    }

    return output;
  };
}

/**
 * ...
 *
 * @param format ...
 * @return ...
 */
function expandFormat(format: string) {
  let i = 5;

  const replaceTokens = (input: string) => longDateFormat(input) || input;

  localFormattingTokens.lastIndex = 0;

  while (i >= 0 && localFormattingTokens.test(format)) {
    format = format.replace(localFormattingTokens, replaceTokens);
    localFormattingTokens.lastIndex = 0;
    i -= 1;
  }

  return format;
}

/**
 * ...
 *
 * @param key ...
 * @return ...
 */
function longDateFormat(key: string): string {
  const format = longDateFormat[key];
  const formatUpper = longDateFormat[key.toUpperCase()];

  if (format || !formatUpper) return format;

  longDateFormat[key] = formatUpper
    .match(formattingTokens)
    .map((tok) => {
      if (tok === 'MMMM' || tok === 'MM' || tok === 'DD' || tok === 'dddd') {
        return tok.slice(1);
      }

      return tok;
    })
    .join('');

  return longDateFormat[key];
}

/**
 * ...
 *
 * @param number ...
 * @param targetLength ...
 * @param forceSign ...
 * @return ...
 */
function zeroFill(
  number: number,
  targetLength: number,
  forceSign?: string | number,
) {
  const absNumber = '' + Math.abs(number).toString();
  const zerosToFill = targetLength - absNumber.length;
  const sign = number >= 0;

  return (
    (sign ? (forceSign ? '+' : '') : '-') +
    Math.pow(10, Math.max(0, zerosToFill)).toString().substring(1) +
    absNumber
  );
}

/**
 * ...
 *
 * @param token ...
 * @param padded ...
 * @param ordinal ...
 * @param callback ...
 * @return ...
 */
function addFormatToken(
  token: 0 | string,
  padded: 0 | [string] | [string, number] | [string, number, boolean],
  ordinal: 0 | string | number,
  callback:
    | keyof Date
    | ((this: Date, format: string, ...args: unknown[]) => number | string),
) {
  // ...
  const func =
    typeof callback === 'function'
      ? callback
      : function (this: Date) {
          return this[callback]();
        };

  // ...
  if (token) formatTokenFunctions[token] = func;

  if (padded) {
    const p1 = padded[0];
    const p2 = padded[1];
    const p3 = padded[2];

    formatTokenFunctions[p1] = function (
      this: Date,
      format: string,
      ...args: unknown[]
    ) {
      return zeroFill(func.apply(this, [format, ...args]), p2, p3);
    };
  }

  if (ordinal) {
    formatTokenFunctions[ordinal] = function (this: Date) {
      return this.localeData().ordinal(func.apply(this, arguments), token);
    };
  }
}

addFormatToken('M', ['MM', 2], 'Mo', function () {
  return this.getMonth() + 1;
});

addFormatToken('MMM', 0, 0, function (format) {
  return this.localeData().monthsShort(this, format);
});

addFormatToken('MMMM', 0, 0, function (format) {
  return this.localeData().months(this, format);
});

addFormatToken('Y', 0, 0, function () {
  const y = this.getFullYear();

  return y <= 9999 ? zeroFill(y, 4) : '+' + y;
});

addFormatToken(0, ['YY', 2], 0, function () {
  return this.getFullYear() % 100;
});

addFormatToken(0, ['YYYY', 4], 0, 'getFullYear');
addFormatToken(0, ['YYYYY', 5], 0, 'getFullYear');
addFormatToken(0, ['YYYYYY', 6, true], 0, 'getFullYear');

addFormatToken('w', ['ww', 2], 'wo', 'week');
addFormatToken('W', ['WW', 2], 'Wo', 'isoWeek');

addFormatToken('d', 0, 'do', 'day');

addFormatToken('dd', 0, 0, function (format) {
  return this.localeData().weekdaysMin(this, format);
});

addFormatToken('ddd', 0, 0, function (format) {
  return this.localeData().weekdaysShort(this, format);
});

addFormatToken('dddd', 0, 0, function (format) {
  return this.localeData().weekdays(this, format);
});

addFormatToken('e', 0, 0, 'weekday');
addFormatToken('E', 0, 0, 'isoWeekday');

/** ... */
function hFormat(this: Date) {
  return this.getHours() % 12 || 12;
}

/** ... */
function kFormat(this: Date) {
  return this.getHours() || 24;
}

addFormatToken('H', ['HH', 2], 0, 'getHours');
addFormatToken('h', ['hh', 2], 0, hFormat);
addFormatToken('k', ['kk', 2], 0, kFormat);

addFormatToken('hmm', 0, 0, function () {
  return '' + hFormat.apply(this).toString() + zeroFill(this.getMinutes(), 2);
});

addFormatToken('hmmss', 0, 0, function () {
  return (
    '' +
    hFormat.apply(this).toString() +
    zeroFill(this.getMinutes(), 2) +
    zeroFill(this.getSeconds(), 2)
  );
});

addFormatToken('Hmm', 0, 0, function () {
  return '' + this.getHours().toString() + zeroFill(this.getMinutes(), 2);
});

addFormatToken('Hmmss', 0, 0, function () {
  return (
    '' +
    this.getHours().toString() +
    zeroFill(this.getMinutes(), 2) +
    zeroFill(this.getSeconds(), 2)
  );
});

addFormatToken('N', 0, 0, 'eraAbbr');
addFormatToken('NN', 0, 0, 'eraAbbr');
addFormatToken('NNN', 0, 0, 'eraAbbr');
addFormatToken('NNNN', 0, 0, 'eraName');
addFormatToken('NNNNN', 0, 0, 'eraNarrow');

addFormatToken('y', ['y', 1], 'yo', 'eraYear');
addFormatToken('y', ['yy', 2], 0, 'eraYear');
addFormatToken('y', ['yyy', 3], 0, 'eraYear');
addFormatToken('y', ['yyyy', 4], 0, 'eraYear');

// addFormatToken(0, ['gg', 2], 0, function () {
//   return this.weekYear() % 100;
// });

// addFormatToken(0, ['GG', 2], 0, function () {
//   return this.isoWeekYear() % 100;
// });

/** ... */
function getSetQuarter(this: Date, input?: number) {
  const date = new Date(this);

  return input == null
    ? Math.ceil((date.getMonth() + 1) / 3)
    : date.setMonth((input - 1) * 3 + (date.getMonth() % 3));
}

addFormatToken('Q', 0, 'Qo', getSetQuarter);

addFormatToken('D', ['DD', 2], 'Do', 'getDate');

addFormatToken('DDD', ['DDDD', 3], 'DDDo', 'dayOfYear');

addFormatToken('m', ['mm', 2], 0, 'getMinutes');

addFormatToken('s', ['ss', 2], 0, 'getSeconds');

addFormatToken('S', 0, 0, function () {
  return ~~(this.getTime() / 100);
});

addFormatToken(0, ['SS', 2], 0, function () {
  return ~~(this.getTime() / 10);
});

addFormatToken(0, ['SSS', 3], 0, 'getTime');
addFormatToken(0, ['SSSS', 4], 0, function () {
  return this.getTime() * 10;
});
addFormatToken(0, ['SSSSS', 5], 0, function () {
  return this.getTime() * 100;
});
addFormatToken(0, ['SSSSSS', 6], 0, function () {
  return this.getTime() * 1_000;
});
addFormatToken(0, ['SSSSSSS', 7], 0, function () {
  return this.getTime() * 10_000;
});
addFormatToken(0, ['SSSSSSSS', 8], 0, function () {
  return this.getTime() * 100_000;
});
addFormatToken(0, ['SSSSSSSSS', 9], 0, function () {
  return this.getTime() * 1_000_000;
});

/** ... */
function getZoneAbbr(this: Date) {
  return this._isUTC ? 'UTC' : '';
}

/** ... */
function getZoneName(this: Date) {
  return this._isUTC ? 'Coordinated Universal Time' : '';
}

addFormatToken('z', 0, 0, getZoneAbbr);
addFormatToken('zz', 0, 0, getZoneName);

/** ... */
function unix(this: Date) {
  return Math.floor(this.valueOf() / 1000);
}

addFormatToken('X', 0, 0, unix);
addFormatToken('x', 0, 0, 'valueOf');
