export const ERI = {
  name: {
    common: 'Eritrea',
    official: 'State of Eritrea',
    native: {
      ara: { official: 'دولة إرتريا', common: 'إرتريا‎' },
      eng: { official: 'State of Eritrea', common: 'Eritrea' },
      tir: { official: 'ሃገረ ኤርትራ', common: 'ኤርትራ' },
    },
  },
  cities: ['Asmara'],
  tld: ['.er'],
  cca2: 'ER',
  ccn3: '232',
  cca3: 'ERI',
  cioc: 'ERI',
  currency: ['ERN'],
  callingCode: ['291'],
  capital: 'Asmara',
  altSpellings: [
    'ER',
    'State of Eritrea',
    'ሃገረ ኤርትራ',
    'Dawlat Iritriyá',
    'ʾErtrā',
    'Iritriyā',
  ],
  region: 'Africa',
  subregion: 'Eastern Africa',
  languages: { ara: 'Arabic', eng: 'English', tir: 'Tigrinya' },
  translations: {
    cym: { official: 'State of Eritrea', common: 'Eritrea' },
    deu: { official: 'Staat Eritrea', common: 'Eritrea' },
    fra: { official: "État d'Érythrée", common: 'Érythrée' },
    hrv: { official: 'Država Eritreji', common: 'Eritreja' },
    ita: { official: 'Stato di Eritrea', common: 'Eritrea' },
    jpn: { official: 'エリトリア国', common: 'エリトリア' },
    nld: { official: 'Staat Eritrea', common: 'Eritrea' },
    por: { official: 'Estado da Eritreia', common: 'Eritreia' },
    rus: { official: 'Государство Эритрея', common: 'Эритрея' },
    spa: { official: 'Estado de Eritrea', common: 'Eritrea' },
    fin: { official: 'Eritrean valtio', common: 'Eritrea' },
  },
  latlng: [15, 39],
  demonym: 'Eritrean',
  landlocked: false,
  borders: ['DJI', 'ETH', 'SDN'],
  area: 117600,
  provinces: [
    'Akale Guzay',
    'Barka',
    'Denkel',
    'Hamasen',
    'Sahil',
    'Semhar',
    'Senhit',
    'Seraye',
  ],
  nativeName: 'ኤርትራ',
  timezones: ['UTC+03:00'],
} as const;
