/**
 * ...
 */
export interface CookiesOptions {
  path?: string;
  domain?: string;
  expires?: string | Date;
  secure?: boolean;
  samesite?: string;
}

declare module '@vue' {
  interface ExtendedVue {
    /** ... */
    $cookies: CookieService;
  }
}

/**
 * ...
 *
 * @param str ...
 * @returns ...
 */
function safeDecodeURIComponent(str: string) {
  try {
    return decodeURIComponent(str);
  } catch {
    return str;
  }
}

/**
 * ...
 */
export class CookieService {
  #lastCookies: Partial<Record<string, string>> = {};
  #lastCookieString = '';

  /**
   * Returns the value of given cookie key.
   *
   * @param key Id to use for lookup.
   * @returns Raw cookie value.
   */
  get(key: string) {
    return this.readCookie()[key] ?? undefined;
  }

  /**
   * Returns the deserialized value of given cookie key.
   *
   * @param key Id to use for lookup.
   * @returns Deserialized cookie value.
   */
  getObject(key: string) {
    const value = this.get(key);

    return value === undefined ? value : (JSON.parse(value) as unknown);
  }

  /**
   * Returns a key value object with all the cookies.
   *
   * @returns All cookies.
   */
  getAll() {
    return this.readCookie();
  }

  /**
   * Sets a value for given cookie key.
   *
   * @param key Id for the value.
   * @param value Raw value to be stored.
   * @param options Options object.
   */
  put(key: string, value: string, options?: CookiesOptions) {
    this.writeCookie(key, value, options);
  }

  /**
   * Serializes and sets a value for given cookie key.
   *
   * @param key Id for the value.
   * @param value Value to be stored.
   * @param options Options object.
   */
  putObject(key: string, value: unknown, options?: CookiesOptions) {
    this.put(key, JSON.stringify(value), options);
  }

  /**
   * Remove given cookie.
   *
   * @param key Id of the key-value pair to delete.
   * @param options Options object.
   */
  remove(key: string, options?: CookiesOptions) {
    this.writeCookie(key, undefined, options);
  }

  /**
   * ...
   *
   * @returns ...
   */
  private readCookie() {
    const currentCookieString = document.cookie;

    if (currentCookieString === this.#lastCookieString) {
      return this.#lastCookies;
    }

    this.#lastCookieString = currentCookieString;
    this.#lastCookies = {};

    const cookies = this.#lastCookieString.split('; ');

    for (const cookie of cookies) {
      const index = cookie.indexOf('=');

      if (index <= 0) continue;

      const name = safeDecodeURIComponent(cookie.substring(0, index));

      if (this.#lastCookies[name] !== undefined) continue;

      this.#lastCookies[name] = safeDecodeURIComponent(
        cookie.substring(index + 1),
      );
    }

    return this.#lastCookies;
  }

  /**
   * ...
   *
   * @param name ...
   * @param value ...
   * @param options ...
   * @returns ...
   */
  private writeCookie(name: string, value?: string, options?: CookiesOptions) {
    options ||= {};

    let expires = options.expires;

    if (value === undefined) {
      expires = 'Thu, 01 Jan 1970 00:00:00 GMT';
      value = '';
    }

    if (typeof expires === 'string') {
      expires = new Date(expires);
    }

    let str = encodeURIComponent(name) + '=' + encodeURIComponent(value);
    str += options.path ? ';path=' + options.path : '';
    str += options.domain ? ';domain=' + options.domain : '';
    str += expires ? ';expires=' + expires.toUTCString() : '';
    str += options.secure ? ';secure' : '';
    str += options.samesite ? ';samesite=' + options.samesite : '';

    const cookieLength = str.length + 1;

    if (cookieLength > 4096) {
      // eslint-disable-next-line no-console
      console.warn(
        `Cookie '${name}' possibly not set or overflowed because it was too large (${cookieLength} > 4096 bytes)!`,
      );
    }

    document.cookie = str;
  }
}

/** ... */
export const cookies = new CookieService();

export default cookies;
