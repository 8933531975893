import { Module } from '@vuex/decorators';
import { TablePanel } from 'table-panel';
import dateFilter from '@filters/date';

import { faEye } from '@icons/solid/faEye';

import { Order } from '@models';
import { modals } from '@modals';
import * as currencies from '@utils/currencies';
const columns: TablePanel.Column<Order>[] = [
  {
    key: 'createdAt',
    label: 'Created',
    type: 'date',
    value: ({ createdAt }) =>
      createdAt ? dateFilter(createdAt, 'MM/dd/yyyy hh:mm:ss a') : 'N/A',
  },
  {
    key: 'transactionId',
    label: 'Transaction ID',
    value: 'transactionId',
  },
  {
    key: 'email',
    label: 'Email',
    value: ({ email }) => email,
  },
  {
    key: 'total',
    label: 'Total',
    type: 'currency',
    value: ({ total, currency }) => {
      return currencies.format({
        amount: total,
        currency,
        unitType: 'smallest',
      });
    },
  },
];

const operations: TablePanel.Row.Operation<Order>[] = [
  {
    label: 'View',
    icon: faEye,
    // $ctrl.view(transaction, 'transaction')
    fn: (order) => {
      void modals.order.view({ order });
    },
  },
];

@Module({ namespaced: true })
export class OrganizationTransactionsTablePanel
  extends TablePanel<Order>
  implements TablePanel.Props<Order>
{
  readonly module = 'orders';
  readonly loadAction = 'listByOrganization';
  readonly pk = 'id';
  readonly label = 'INSTITUTION TRANSACTIONS';
  readonly columns = columns;
  readonly operations = operations;
  readonly tableSortType = 'createdAt';
  readonly tableSortReverse = true;

  get enabled() {
    return this.isActiveRole(5);
  }
}
