import { server } from '@api/request';
import { SsoLogin } from '@models';
import { isString, isNull, isObject, isOneOf } from '@tools/type-guards';

/**
 * Lists all institutions with sso
 */
export async function list() {
  return await server.list(`sso`, processSsoLoginData);
}

/**
 * ...
 */
export interface GetSsoLogin {
  ssoId: SsoLogin['id'];
}

/**
 * Get a specified sso data
 *
 * @param options Request options bag.
 * @return SsoLoginData object
 */
export async function get(options: GetSsoLogin) {
  return await server.get(`sso/${options.ssoId}`, processSsoLoginData);
}

//#region Helper Functions

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
function isValidSsoLoginData(value: unknown): value is SsoLogin {
  return (
    isObject(value) &&
    isString(value['id']) &&
    isString(value['name']) &&
    isOneOf(value['logo'], isString, isNull) &&
    isOneOf(value['color'], isString, isNull)
  );
}

/**
 * ...
 *
 * @param data ...
 * @return ...
 */
function processSsoLoginData(data: unknown) {
  if (!isValidSsoLoginData(data)) {
    throw new Error('Invalid SSO login data item.');
  }

  return { ...data } as SsoLogin;
}

//#endregion Helper Functions
