import { Module, VuexModule, Mutation } from '@vuex/decorators';
import find from 'lodash/find';
import some from 'lodash/some';
import sumBy from 'lodash/sumBy';

import { Product } from '@models';
import { alert } from '@services/alert';
import { ls } from '@services/ls';
import { Root, Shop } from '@store';
import * as typeGuards from '@tools/type-guards';
import { copy } from '@utils/copy';
import * as currencies from '@utils/currencies';

/** IDs of products that are free. */
const FREE_PRODUCTS = ['FREE-TRIAL', 'TRIAL-LICENSE'];

/** IDs of scenes that should be ignored when checking for duplicates. */
const IGNORE_DUPLICATE_SCENE_IDS = ['1', '9'];

declare module '@vuex/core' {
  export interface Getters {
    'cart/get': Cart['get'];
    'cart/hasItem': Cart['hasItem'];
    'cart/total': Cart['total'];
    'cart/containsItemWithScenes': Cart['containsItemWithScenes'];
    'cart/containsFreeLicense': Cart['containsFreeLicense'];
    'cart/itemMap': Cart['itemMap'];
  }

  export interface CommitMap {
    'cart/ADD_ITEM': Cart['ADD_ITEM'];
    'cart/REMOVE_ITEM': Cart['REMOVE_ITEM'];
    'cart/UPDATE_ITEM_QUANTITY': Cart['UPDATE_ITEM_QUANTITY'];
    'cart/INCREASE_ITEM_QUANTITY': Cart['INCREASE_ITEM_QUANTITY'];
    'cart/DECREASE_ITEM_QUANTITY': Cart['DECREASE_ITEM_QUANTITY'];
    'cart/CLEAR': Cart['CLEAR'];
  }
}

/** ... */
type CartItem = Shop.Item & { total: number };

@Module({ namespaced: true })
export class Cart
  extends VuexModule<Cart.State, Root.State>
  implements Cart.State
{
  items = getSavedShoppingCart();

  /**
   * Currency the cart should use to display prices.
   *
   * NOTE: currently hardset to USD -- this should likely be set globally and
   * dynamically.
   */
  currency: currencies.Currency = 'usd';

  /** ... */
  get get() {
    return (id: string) => find(this.items, { id }) ?? null;
  }

  /** ... */
  get hasItem() {
    return (id: string) => !!this.get(id);
  }

  /** ... */
  get total() {
    return sumBy(this.items, 'total');
  }

  /**
   * Check to see if one or more scenes is already present in the cart under
   * another item.
   *
   * @param scenes List of scenes to check.
   * @return `true` if an item does contain one of the provided scenes,
   * otherwise `false`.
   */
  get containsItemWithScenes() {
    return (scenes: Product.SceneInfo[]) => {
      return containsItemWithScenes(this.items, scenes);
    };
  }

  /**
   * Check to see if cart contains a "free" or "trial" license
   *
   * @returns 'true' if item does exist in cart with "free" or "trial" ID,
   * otherwise returns false
   */
  get containsFreeLicense() {
    return containsFreeLicense(this.items);
  }

  /** ... */
  get itemMap() {
    return Object.fromEntries(this.items.map((item) => [item.id, item]));
  }

  //#region Mutations

  @Mutation
  ADD_ITEM({ item }: Cart.AddItemMutationOptions) {
    // ...
    if (item.scenes && containsItemWithScenes(this.items, item.scenes)) {
      throw new DuplicateItemScenesError();
    }

    // ...
    const itemsList = copy(this.items);

    // ...
    const match = find(itemsList, { id: item.id });

    if (
      match &&
      match.quantity + item.quantity > item.limit &&
      item.limit !== 0
    ) {
      // Check for quantity limit reached
      return alert.warning(`Per Cart Item Limit Reached: ${item.limit}`);
    }

    const targetPrice =
      item.prices.find((price) => price.currency === this.currency)?.amount ??
      NaN;

    let total: number;

    if (item.onSale) {
      // NOTE: Sale price usage is currently disabled due to Stripe pricing
      // migration.
      // total = item.salePrice;
      total = currencies.convertUnit(targetPrice, this.currency, 'primary');
    } else {
      // NOTE: Price currently assumed to be first `price` in the `prices` array
      // with a currency in USD.
      // total = item.price;
      total = currencies.convertUnit(targetPrice, this.currency, 'primary');
    }

    total *= item.quantity;

    if (match) {
      match.quantity += item.quantity;
      match.total += total;
    } else {
      itemsList.push(copy({ ...item, total }));
    }

    this.items = itemsList;

    ls.setObject('shoppingCart', itemsList);
  }

  @Mutation
  REMOVE_ITEM({ itemId }: Cart.RemoveItemMutationOptions) {
    const itemIndex = this.items.findIndex((item) => item.id === itemId);

    if (itemIndex < 0) {
      // eslint-disable-next-line no-console
      return console.warn(
        `[cart.remove] item with ID "${itemId}" could not be found.`,
      );
    }

    this.items = this.items.filter((_, i) => i !== itemIndex);

    if (this.items.length) {
      ls.setObject('shoppingCart', this.items);
    } else {
      ls.remove('shoppingCart');
    }
  }

  @Mutation
  UPDATE_ITEM_QUANTITY({
    itemId,
    quantity,
  }: Cart.UpdateItemQuantityMutationOptions) {
    const item = getCartItem(this.items, itemId);

    assertValidItemQuantity(item, quantity);

    item.quantity = quantity;
  }

  @Mutation
  INCREASE_ITEM_QUANTITY({
    itemId,
    count = 1,
  }: Cart.IncreaseItemQuantityMutationOptions) {
    const item = getCartItem(this.items, itemId);

    const quantity = item.quantity + count;

    assertValidItemQuantity(item, quantity);

    item.quantity = quantity;
  }

  @Mutation
  DECREASE_ITEM_QUANTITY({
    itemId,
    count = 1,
  }: Cart.DecreaseItemQuantityMutationOptions) {
    const item = getCartItem(this.items, itemId);

    const quantity = item.quantity - count;

    assertValidItemQuantity(item, quantity);

    item.quantity = quantity;
  }

  @Mutation
  CLEAR() {
    this.items = [];

    ls.remove('shoppingCart');
  }

  //#endregion Mutations
}

export namespace Cart {
  /** ... */
  export type Item = CartItem;

  /**
   * ...
   */
  export interface State {
    items: Item[];
  }

  /**
   * ...
   */
  export interface AddItemMutationOptions {
    item: Shop.Item;
  }

  /**
   * ...
   */
  export interface RemoveItemMutationOptions {
    itemId: string;
  }

  /**
   * ...
   */
  export interface UpdateItemQuantityMutationOptions {
    itemId: string;
    quantity: number;
  }

  /**
   * ...
   */
  export interface IncreaseItemQuantityMutationOptions {
    itemId: string;
    count?: number;
  }

  /**
   * ...
   */
  export interface DecreaseItemQuantityMutationOptions {
    itemId: string;
    count?: number;
  }
}

export default Cart;

//#region Errors

class DuplicateItemScenesError extends Error {
  code = 'DUPLICATE_ITEM_SCENES';

  constructor() {
    super(
      'You already have a product in your cart with scenarios from this new product.',
    );
  }
}

//#region Helper Functions

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
function isCartItem(value: unknown): value is CartItem {
  return typeGuards.isObject(value);

  // if (!typeGuards.isObject(value)) return false;
  //
  // return (
  //   typeGuards.isBoolean(value['active']) &&
  //   typeGuards.isBoolean(value['allowBackorder']) &&
  //   typeGuards.isString(value['createdAt']) &&
  //   typeGuards.isArray(value['dependencies']) &&
  //   typeGuards.isString(value['description']) &&
  //   typeGuards.isNumber(value['downSell']) &&
  //   typeGuards.isArray(value['drones']) &&
  //   typeGuards.isString(value['id']) &&
  //   typeGuards.isArray(value['images']) &&
  //   typeGuards.isArray(value['institutions']) &&
  //   typeGuards.isBoolean(value['instructionalVideo']) &&
  //   typeGuards.isNumber(value['limit']) &&
  //   typeGuards.isString(value['name']) &&
  //   typeGuards.isBoolean(value['onSale']) &&
  //   typeGuards.isNumber(value['price']) &&
  //   typeGuards.isNumber(value['quantity']) &&
  //   typeGuards.isNumber(value['salePrice']) &&
  //   typeGuards.isArray(value['sceneNames']) &&
  //   typeGuards.isArray(value['scenes']) &&
  //   typeGuards.isBoolean(value['shipping']) &&
  //   typeGuards.isString(value['sku']) &&
  //   typeGuards.isNumber(value['stock']) &&
  //   typeGuards.isNumber(value['upSell']) &&
  //   typeGuards.isNumber(value['weight']) &&
  //   typeGuards.isNumber(value['total'])
  // );
}

/**
 * ...
 *
 * @param items ...
 * @param itemId ...
 * @returns ...
 */
function getCartItem(items: CartItem[], itemId: string) {
  const item = find(items, { id: itemId });

  if (!item) {
    throw new Error(`Product with ID ${itemId} not found.`);
  }

  return item;
}

/**
 * ...
 *
 * @param item ...
 * @param quantity ...
 */
function assertValidItemQuantity(item: CartItem, quantity: number) {
  if (quantity <= 0) {
    return alert.warning('Item quantity must be greater than or equal to 1.');
  } else if (quantity >= item.limit) {
    return alert.warning(
      `Item quantity must be less than or equal to ${item.limit}`,
    );
  }
}

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
function isShoppingCart(value: unknown): value is CartItem[] {
  // ...
  if (!typeGuards.isArray(value)) return false;

  // ...
  return value.every(isCartItem);
}

/**
 * ...
 *
 * @return ...
 */
function getSavedShoppingCart() {
  // ...
  const data = ls.getObject('shoppingCart');

  return isShoppingCart(data) ? data : [];
}

/**
 * ...
 *
 * @param items ...
 * @param scenes ...
 * @return ...
 */
function containsItemWithScenes(
  items: CartItem[],
  scenes: Product.SceneInfo[],
) {
  const sceneIds: string[] = [];

  for (const scene of scenes) {
    if (!IGNORE_DUPLICATE_SCENE_IDS.includes(scene.id)) {
      sceneIds.push(scene.id);
    }
  }

  for (const item of items) {
    for (const scene of item.scenes ?? []) {
      if (sceneIds.includes(scene.id)) return true;
    }
  }

  return false;
}

/**
 *
 */
function containsFreeLicense(items: CartItem[]) {
  return !!some(items, (i) => FREE_PRODUCTS.includes(i.id));
}

//#endregion Helper Functions
