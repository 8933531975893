import { server } from '@api/request';

export interface StartOptions {
  productId: string;
  dlcId: string;
  buildNumber: string;
}
/**
 * Start a Build
 */
export async function start(options: StartOptions) {
  return await server.post('v2/admin/builds/start', options);
}
