var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.NavDropdown,
    {
      ref: "dropdown",
      attrs: { width: "450px", height: "50vh", "no-body-padding": "" },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip",
                      value: _setup.sortBtnTooltip,
                      expression: "sortBtnTooltip",
                    },
                  ],
                  class: ["sort-btn", { active: _setup.sortByUnread }],
                  on: { click: _setup.toggleSortMode },
                },
                [_c("Icon", { attrs: { icon: _setup.sortBtnIcon } })],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      !_vm.announcements.length
        ? _c(_setup.Overlay, { attrs: { background: "transparent" } }, [
            _c("div", { staticClass: "h4 text-center text-muted" }, [
              _vm._v("\n      You don't have any announcements.\n    "),
            ]),
          ])
        : _c("div", { staticClass: "announcement-list-items" }, [
            _c(
              "ul",
              _vm._l(_setup.items, function (item) {
                return _c(
                  "li",
                  {
                    key: item.id,
                    class: ["list-item", { unread: !item.read }],
                    on: {
                      click: function ($event) {
                        return _setup.view(item)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "item-header" }, [
                      _c("div", { staticClass: "item-title" }, [
                        _c("span", [_vm._v(_vm._s(item.title))]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-date" }, [
                        _vm._v(_vm._s(item.createdAt)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-content-preview" }, [
                      _c("div", {
                        domProps: { innerHTML: _vm._s(item.message) },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "ellipsis" }, [_vm._v("...")]),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }