import { isNumber } from '@tools/type-guards';
import { currencies, Currency, CurrencyInfo } from '@values/currencies';

export type UnitType = 'primary' | 'smallest';

export type { Currency };

/**
 * Get {@link CurrencyInfo currency info} for the provided currency identifier.
 *
 * @param currency The identifier of the target currency info,
 * @returns The target {@link CurrencyInfo currency info}.
 */
export function getInfo(currency: Currency) {
  return currencies[currency];
}

/**
 * Convert a currency amount between its `primary` and `smallest` units.
 *
 * @param amount The amount to convert.
 * @param currency The currency of the provided amount.
 * @param unit The unit type to convert the provided amount to.
 * @returns The converted amount.
 */
export function convertUnit(
  amount: number,
  currency: Currency = 'usd',
  unitType: UnitType = 'primary',
) {
  const { unitConversion } = currencies[currency];

  if (unitType === 'primary') {
    amount *= unitConversion;
  } else {
    amount /= unitConversion;
  }

  return amount;
}

/**
 * Convert a currency amount from its smallest unit to its primary unit.
 *
 * @param amount The amount to convert.
 * @param currency The currency of the provided amount.
 * @returns The converted amount.
 */
export function toPrimaryUnit(amount: number, currency: Currency = 'usd') {
  return convertUnit(amount, currency, 'primary');
}

/**
 * Convert a currency amount from its primary unit to its smallest unit.
 *
 * @param amount The amount to convert.
 * @param currency The currency of the provided amount.
 * @returns The converted amount.
 */
export function toSmallestUnit(amount: number, currency: Currency = 'usd') {
  return convertUnit(amount, currency, 'smallest');
}

/**
 * Format options for currency {@link format} utility.
 */
export interface FormatCurrencyOptions {
  amount: number;
  currency: Currency;
  unitType: UnitType;
}

/**
 * Create a formatted string of some amount based on the provided currency.
 *
 * @param amount The numeric amount.
 * @param currency The currency the provided amount should be represented in
 * (defaults to `usd`).
 * @returns A string representing the formatted amount.
 */
export function format(amount: number, currency?: Currency): string;
/**
 * Create a formatted string of some amount based on the provided currency.
 *
 * @param options Currency format {@link FormatCurrencyOptions options}.
 * @returns A string representing the formatted amount.
 */
export function format(options: FormatCurrencyOptions): string;
export function format(arg1: number | FormatCurrencyOptions, arg2?: Currency) {
  let amount: number;
  let currency: Currency;
  let unitType: UnitType;

  if (isNumber(arg1)) {
    amount = arg1;
    currency = arg2 ?? 'usd';
    unitType = 'primary';
  } else {
    amount = arg1.amount;
    currency = arg1.currency;
    unitType = arg1.unitType;
  }

  if (unitType === 'smallest') {
    amount = toPrimaryUnit(amount, currency);
  }

  return amount.toLocaleString('en-US', { style: 'currency', currency });
}

// export function format(amount: number, currency: Currency = 'usd') { return
//   amount.toLocaleString('en-US', { style: 'currency', currency });
// }

//#region TEMPORARY Product Specific

type Product = import('@models/product').Product;
type ProductPriceInfo = import('@models/product').Product.PriceInfo;

type BasePriceInfo = Pick<ProductPriceInfo, 'amount' | 'currency'>;
type ProductPriceRef = BasePriceInfo | BasePriceInfo[] | Product;

function normalizeProductPriceInfo(priceRef: ProductPriceRef) {
  let price: BasePriceInfo | null = null;

  if (Array.isArray(priceRef)) {
    price = priceRef[0] as unknown as BasePriceInfo;
  } else if ('amount' in priceRef && 'currency' in priceRef) {
    price = priceRef as unknown as BasePriceInfo;
  } else {
    price = priceRef.prices[0] ?? null;
  }

  return price;
}

/**
 * Get the "default" price value for a product. Optionally convert it from the
 * currency's smallest unit to its primary unit.
 *
 * `NOTE`: This is intended to be a TEMPORARY utility function to assist with
 * the first phase of the migration to Stripe's pricing system.
 *
 * @param priceRef Price info or a product that contains it.
 * @param unitType The unit type the returned price amount should be in
 * (defaults to `primary`).
 * @returns A string representing the formatted price.
 */
export function getProductPrice(
  priceRef: ProductPriceRef,
  unitType: UnitType = 'primary',
) {
  const price = normalizeProductPriceInfo(priceRef);

  if (!price) {
    throw new Error(
      '[getProductPrice] could not deduce default price info from provided price reference.',
    );
  }

  let amount: number;

  if (unitType === 'primary') {
    amount = toPrimaryUnit(price.amount, price.currency);
  } else {
    amount = price.amount;
  }

  return amount;
}

/**
 * Format the "default" price of a given product for display in a typical,
 * ease-to-read currency format.
 *
 * `NOTE`: This is intended to be a TEMPORARY utility function to assist with
 * the first phase of the migration to Stripe's pricing system.
 *
 * @param priceRef Price info or a product that contains it.
 * @param quantity Number of products to compute the final cost for (defaults to
 * `1`).
 * @returns A string representing the formatted price.
 */
export function formatProductPrice(priceRef: ProductPriceRef, quantity = 1) {
  const price = normalizeProductPriceInfo(priceRef);

  if (!price) return 'INVALID PRICE';

  return format({
    amount: price.amount * quantity,
    currency: price.currency,
    unitType: 'smallest',
  });
}

//#endregion TEMPORARY Product Specific
