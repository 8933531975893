export const PHL = {
  name: {
    common: 'Philippines',
    official: 'Republic of the Philippines',
    native: {
      eng: { official: 'Republic of the Philippines', common: 'Philippines' },
      fil: { official: 'Republic of the Philippines', common: 'Pilipinas' },
    },
  },
  cities: [
    'Manila',
    'Ayala',
    'Bayan',
    'Roosevelt',
    'Blumentritt',
    'Cardona',
    'Pasong Tamo',
    'Valenzuela',
    'Caloocan City',
    'Taytay',
    'Quezon City',
    'Las Pinas',
    'Makati City',
    'Marikina City',
    'San Juan',
    'Pasig',
    'Paranaque City',
    'Muntinlupa',
    'Salinas',
    'Poblacion',
    'Roxas City',
    'Puerto Princesa City',
    'Pasay',
    'Caloocan',
    'Buting',
    'Lipa City',
    'Taguig',
    'Baguio City',
    'San Pablo City',
    'San Mateo',
    'Rizal',
    'Dasmarinas',
    'Sucat',
    'Sampaloc',
    'Marilag',
    'Angeles City',
    'Mandaluyong City',
    'Malabon',
    'Rodriguez',
    'Fairview',
    'Angeles City',
    'Davao City',
    'Iligan',
    'Cagayan de Oro',
    'Tagum',
    'Butuan',
    'Mawab',
    'Ozamiz City',
    'Bacoor',
    'Cavite City',
    'Carmona',
    'Imus',
    'Tacurong',
    'Pateros',
    'Dumaguete',
    'Carolinas',
    'San Pedro',
    'General Santos',
    'Malolos',
    'Cebu City',
    'Malate',
    'New Manila',
    'Antipolo',
    'Villanueva',
    'Concepcion',
    'Koronadal',
    'Pandi',
    'West',
    'Lucena City',
    'Balayan',
    'Laguna',
    'Calamba',
    'Subic',
    'Bonifacio',
    'Quezon',
    'Lapu-Lapu City',
    'Tagaytay',
    'San Carlos City',
    'San Jose',
    'Asia',
    'Subic',
    'Libis',
    'Palma Gil',
    'Fernandez',
    'Kalibo',
    'Santos',
    'Vargas',
    'Novaliches',
    'Plaza',
    'San Antonio',
    'Philippine',
    'Rizal',
    'Alabang',
    'Legaspi',
    'Laguna',
    'Summit',
    'Bates',
    'Bagumbayan',
    'Salcedo',
    'Batis',
    'Cubao',
    'Binondo',
    'Balabag',
    'General',
    'Guagua',
    'Liberty',
    'Tandang Sora',
    'Capitol',
    'Naga City',
    'Binangonan',
    'San Francisco',
    'Mabalacat',
    'Pikit',
    'Antipolo',
    'Imus',
    'Tarlac City',
    'Batangas',
    'Calapan',
    'Binan',
    'Cabuyao',
    'Hilongos',
    'Mandaue City',
    'Tanza',
    'Caloocan',
    'Silang',
    'Malabon',
    'Navotas',
    'Angat',
    'Navotas',
    'Bulacan',
    'Laoag',
    'Bocaue',
    'Dagupan',
    'Calasiao',
    'Dingras',
    'Cainta',
    'Iloilo City',
    'Iloilo City',
    'Tagbilaran City',
    'Balanga',
    'Pulilan',
    'Olongapo City',
    'Marilao',
    'San Jose',
    'San Fernando',
    'Plaridel',
    'Baliuag',
    'Cabanatuan City',
    'Bataan',
    'Navotas',
    'San Fernando',
    'Meycauayan',
    'Manila',
    'Kawit',
    'Bacolod City',
    'Angono',
    'Los Banos',
    'Paete',
    'Santa Rosa',
    'Calamba City',
    'Santa Cruz',
    'Pangil',
    'Canlubang',
    'Tayabas',
    'Pagsanjan',
    'Zamboanga City',
    'Talisay City',
    'Mandaue City',
    'Ugong Norte',
    'Luna',
    'Olongapo City',
    'Tanauan',
    'Cabatuan',
    'Pasig',
    'West Triangle',
    'Pias',
    'San Pedro',
    'Dipolog City',
    'Victorias City',
    'Zamboanga City',
    'Subic',
    'Abucay',
    'Samal',
    'Orion',
    'Arayat',
    'Boac',
    'Siniloan',
    'San Agustin',
    'Cotabato City',
    'Veronica',
    'Masbate',
    'Bool',
    'Tacloban City',
    'Danao',
    'Rosario West',
    'Taft',
    'Sulo',
    'Centre',
    'Santo',
    'Pagasinan',
    'Cafe',
    'Leyte',
    'Interior',
    'Pagadian',
    'Molave',
    'Palo',
    'Mamungan',
    'Hagonoy',
    'Paul',
    'Bagong Nayon',
    'Mauban',
    'La Trinidad',
    'Apalit',
    'Mariveles',
    'General Trias',
    'Cabugao',
    'Province of Pangasinan',
    'Urdaneta',
    'Alaminos',
    'Malasiqui',
    'Bay',
    'Balungao',
    'Amadeo',
    'Macabebe',
    'Del Monte',
    'Limay',
    'Calbayog City',
    'Real',
    'Tanay',
    'Kapatagan',
    'Trece Martires City',
    'Lamitan',
    'Bagac',
    'Jagna',
    'Obando',
    'Balagtas',
    'Kidapawan',
    'Pampanga',
    'Naga',
    'Diliman Primero',
    'Baao',
    'Cauayan',
    'Pacita',
    'Central',
    'Rosales',
    'Bulacan',
    'Roque',
    'Silang',
    'La Salette',
    'San Miguel',
    'Baclaran',
    'Marcos',
    'Suyo',
    'Maquiling',
    'Plaridel',
    'Los Martires',
    'Acacia',
    'Minalin',
    'Salle',
    'Agusan Pequeno',
    'Gitagum',
    'Pardo',
    'Gallego',
    'Gloria',
    'Nueva',
    'Upper Bicutan',
    'Wines',
    'Vito',
    'La Union',
    'Poblacion, San Felipe',
    'Lawis',
    'Toledo City',
    'Concepcion',
    'Digos',
    'Cogan',
    'Calinan',
    'Mindanaw',
    'Dagupan',
    'Barbar',
    'Mangaldan',
    'San Fabian',
    'Alcala',
    'Bautista',
    'Aquino',
    'Ilog',
    'Manaoag',
    'Tayug',
    'Luzon',
    'San Miguel',
    'Victory',
    'Sta Cruz',
    'Sultan Kudarat',
    'Magsaysay',
    'Dapitan',
    'Mangrove',
    'Tanauan',
    'Alfonso',
    'Island Park',
    'Maria',
    'Tigbauan',
    'Campus',
    'Valencia',
    'Occidental',
    'Panabo',
    'Munoz East',
    'Aguilar',
    'Fortuna',
    'Matandang Balara',
    'Osmena',
    'Leon',
    'Balamban',
    'Cotabato City',
    'Cavinti',
    'Alitagtag',
    'Trinidad',
    'Lilio',
    'Carlos',
    'Vigan',
    'Domingo',
    'Candon',
    'Norte',
    'Oara',
    'Alaminos',
    'Calaca',
    'Padre Garcia',
    'Bauan',
    'Cagayan',
    'Tuguegarao City',
    'Ilagan',
    'Santiago',
    'Llanera',
    'China',
    'Ramon',
    'Feliciano',
    'Marina',
    'Cruz',
    'Ventura',
    'Tambo',
    'Tejeros Convention',
    'Del Pilar',
    'Province of Cebu',
    'Bustos',
    'Daraga',
    'Minglanilla',
    'Sariaya',
    'Bauan',
    'Himamaylan',
    'Silay',
    'Orani',
    'Paniqui',
    'San Jose del Monte',
    'Luna',
    'Smart',
    'Santo Tomas',
    'Aurora',
    'Lahug',
    'Miagao',
    'Dinas',
    'Matalam',
    'Province of Pampanga',
    'Sorsogon',
    'Candelaria',
    'Carlatan',
    'Malvar',
    'Princesa',
    'Looc',
    'Putatan',
    'Villa',
    'Cinco',
    'Bataan',
    'Pinaglabanan',
    'Bago City',
    'Tambler',
    'Tuktukan',
    'Bayawan',
    'Laguerta',
    'Isabela',
    'Mabini',
    'Talon',
    'Don Jose',
    'Dolores',
    'Iriga City',
    'Misamis',
    'Taal',
    'Valencia',
    'Bukidnon',
    'Mapua',
    'Don Bosco Executive Village',
    'Centro',
    'Lourdes',
    'Fernando',
    'Tamag',
    'City of Batac',
    'Merville Subdivision',
    'San Andres',
    'Burgos',
    'Naguilian',
    'San Vicente',
    'Sto Nino',
    'Province of Laguna',
    'Quezon',
  ],
  tld: ['.ph'],
  cca2: 'PH',
  ccn3: '608',
  cca3: 'PHL',
  cioc: 'PHI',
  currency: ['PHP'],
  callingCode: ['63'],
  capital: 'Manila',
  altSpellings: ['PH', 'Republic of the Philippines', 'Repúblika ng Pilipinas'],
  region: 'Asia',
  subregion: 'South-Eastern Asia',
  languages: { eng: 'English', fil: 'Filipino' },
  translations: {
    deu: { official: 'Republik der Philippinen', common: 'Philippinen' },
    fra: { official: 'République des Philippines', common: 'Philippines' },
    hrv: { official: 'Republika Filipini', common: 'Filipini' },
    ita: { official: 'Repubblica delle Filippine', common: 'Filippine' },
    jpn: { official: 'フィリピン共和国', common: 'フィリピン' },
    nld: { official: 'Republiek der Filipijnen', common: 'Filipijnen' },
    por: { official: 'República das Filipinas', common: 'Filipinas' },
    rus: { official: 'Республика Филиппины', common: 'Филиппины' },
    spa: { official: 'República de las Filipinas', common: 'Filipinas' },
    fin: { official: 'Filippiinien tasavalta', common: 'Filippiinit' },
  },
  latlng: [13, 122],
  demonym: 'Filipino',
  landlocked: false,
  borders: [],
  area: 342353,
  provinces: [
    'Abra',
    'Agusan del Norte',
    'Agusan del Sur',
    'Aklan',
    'Albay',
    'Angeles',
    'Antique',
    'Aurora',
    'Bacolod',
    'Bago',
    'Baguio',
    'Bais',
    'Basilan',
    'Basilan City',
    'Bataan',
    'Batanes',
    'Batangas',
    'Batangas City',
    'Benguet',
    'Bohol',
    'Bukidnon',
    'Bulacan',
    'Butuan',
    'Cabanatuan',
    'Cadiz',
    'Cagayan',
    'Cagayan de Oro',
    'Calbayog',
    'Caloocan',
    'Camarines Norte',
    'Camarines Sur',
    'Camiguin',
    'Canlaon',
    'Capiz',
    'Catanduanes',
    'Cavite',
    'Cavite City',
    'Cebu',
    'Cebu City',
    'Cotabato',
    'Dagupan',
    'Danao',
    'Dapitan',
    'Davao City Davao',
    'Davao del Sur',
    'Davao Oriental',
    'Dipolog',
    'Dumaguete',
    'Eastern Samar',
    'General Santos',
    'Gingoog',
    'Ifugao',
    'Iligan',
    'Ilocos Norte',
    'Ilocos Sur',
    'Iloilo',
    'Iloilo City',
    'Iriga',
    'Isabela',
    'Kalinga-Apayao',
    'La Carlota',
    'La Union',
    'Laguna',
    'Lanao del Norte',
    'Lanao del Sur',
    'Laoag',
    'Lapu-Lapu',
    'Legaspi',
    'Leyte',
    'Lipa',
    'Lucena',
    'Maguindanao',
    'Mandaue',
    'Manila',
    'Marawi',
    'Marinduque',
    'Masbate',
    'Mindoro Occidental',
    'Mindoro Oriental',
    'Misamis Occidental',
    'Misamis Oriental',
    'Mountain',
    'Naga',
    'Negros Occidental',
    'Negros Oriental',
    'North Cotabato',
    'Northern Samar',
    'Nueva Ecija',
    'Nueva Vizcaya',
    'Olongapo',
    'Ormoc',
    'Oroquieta',
    'Ozamis',
    'Pagadian',
    'Palawan',
    'Palayan',
    'Pampanga',
    'Pangasinan',
    'Pasay',
    'Puerto Princesa',
    'Quezon',
    'Quezon City',
    'Quirino',
    'Rizal',
    'Romblon',
    'Roxas',
    'Samar',
    'San Carlos (in Negros Occidental)',
    'San Carlos (in Pangasinan)',
    'San Jose',
    'San Pablo',
    'Silay',
    'Siquijor',
    'Sorsogon',
    'South Cotabato',
    'Southern Leyte',
    'Sultan Kudarat',
    'Sulu',
    'Surigao',
    'Surigao del Norte',
    'Surigao del Sur',
    'Tacloban',
    'Tagaytay',
    'Tagbilaran',
    'Tangub',
    'Tarlac',
    'Tawitawi',
    'Toledo',
    'Trece Martires',
    'Zambales',
    'Zamboanga',
    'Zamboanga del Norte',
    'Zamboanga del Sur',
  ],
  nativeName: 'Pilipinas',
  timezones: ['UTC+08:00'],
} as const;
