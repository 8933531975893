/**
 * ...
 */
interface IIndexable {
  id: string | number | symbol;
}

/**
 * Find an element's index from within an array by ID.
 *
 * @param array The array to inspect.
 * @param value The value of the ID to evaluate.
 * @return The matching element's index, if found.
 */
export function findIndexById<T extends IIndexable>(
  array: T[],
  value: T['id'],
) {
  return array.findIndex(({ id }) => id === value);
}

/**
 * Find an element from within an array by ID.
 *
 * @param array The array to inspect.
 * @param value The value of the ID to evaluate.
 * @return The matching element, if found.
 */
export function findById<T extends IIndexable>(array: T[], value: T['id']) {
  return array.find(({ id }) => id === value);
}

/**
 * Add an element(s) to an array by ID.
 *
 * @param array The array to inspect.
 * @param value The element(s) to add.
 * @return A new array with the added element(s).
 */
export function addById<T extends IIndexable>(array: T[], value: T | T[]) {
  array = [...array];

  const items = Array.isArray(value) ? value : [value];

  for (const item of items) {
    const i = findIndexById(array, item.id);

    if (i !== -1) {
      array[i] = item;
    } else {
      array.push(item);
    }
  }

  return array;
}

/**
 * Update an element within an array by ID.
 *
 * @param array The array to inspect.
 * @param value The data to update the element with.
 * @return A new array with the updated element.
 */
export function updateById<
  T extends IIndexable,
  D extends IIndexable & Partial<Omit<T, 'id'>>,
>(array: T[], value: D) {
  array = [...array];

  const i = findIndexById(array, value.id);

  if (i === -1) {
    throw new Error(`Item with ID ${String(value.id)} not found.`);
  }

  const item = array[i] as unknown as T;

  Object.assign(item, value);

  return array;
}

/**
 * Remove an element from an array by ID.
 *
 * @param array The array to inspect.
 * @param value The value of the element's ID to be removed.
 * @return A new array without the removed element.
 */
export function removeById<T extends IIndexable>(array: T[], value: T['id']) {
  array = [...array];

  const i = findIndexById(array, value);

  if (i !== -1) {
    array.splice(i, 1);
  }

  return array;
}

/**
 * ...
 *
 * @param array ...
 * @param fromIndex ...
 * @param toIndex ...
 * @returns ...
 */
export function move<T>(array: T[], fromIndex: number, toIndex: number) {
  array = [...array];

  const elems = array.splice(fromIndex, 1);

  if (!elems.length) {
    throw new Error('...');
  }

  const elem = elems[0] as T;

  array.splice(toIndex, 0, elem);

  return array;
}
