import * as DateTimeFormats from './datetime-formats';

const ZERO_CHAR = '0';

function padNumber(
  num: number,
  digits: number,
  trim?: boolean,
  negWrap?: boolean
) {
  let neg = '';

  if (num < 0 || (negWrap && num <= 0)) {
    if (negWrap) {
      num = -num + 1;
    } else {
      num = -num;
      neg = '-';
    }
  }

  let numStr = `${num}`;

  while (numStr.length < digits) numStr = ZERO_CHAR + numStr;

  if (trim) {
    numStr = numStr.substr(numStr.length - digits);
  }

  return neg + numStr;
}

function getFirstThursdayOfYear(year: number) {
  // 0 = index of January
  const dayOfWeekOnFirst = new Date(year, 0, 1).getDay();
  // 4 = index of Thursday (+1 to account for 1st = 5)
  // 11 = index of *next* Thursday (+1 account for 1st = 12)

  return new Date(year, 0, (dayOfWeekOnFirst <= 4 ? 5 : 12) - dayOfWeekOnFirst);
}

function getThursdayThisWeek(datetime: Date) {
  return new Date(
    datetime.getFullYear(),
    datetime.getMonth(),
    // 4 = index of Thursday
    datetime.getDate() + (4 - datetime.getDay())
  );
}

function dateGetter(
  name: string,
  size: number,
  offset?: number,
  trim?: boolean,
  negWrap?: boolean
) {
  offset = offset || 0;

  return (date: Date) => {
    let value = date['get' + name]();

    if (offset > 0 || value > -offset) {
      value += offset;
    }

    if (value === 0 && offset === -12) value = 12;

    return padNumber(value, size, trim, negWrap);
  };
}

function dateStrGetter(
  name: string,
  shortForm?: boolean,
  standAlone?: boolean
) {
  return (date: Date) => {
    const value = date['get' + name]();

    const propPrefix =
      (standAlone ? 'Standalone' : '') + (shortForm ? 'Short' : '');

    const get = (propPrefix + name).toUpperCase();

    return DateTimeFormats[get][value];
  };
}

function timeZoneGetter(_date: Date, offset: number) {
  const zone = -1 * offset;
  let paddedZone = zone >= 0 ? '+' : '';

  paddedZone +=
    padNumber(Math[zone > 0 ? 'floor' : 'ceil'](zone / 60), 2) +
    padNumber(Math.abs(zone % 60), 2);

  return paddedZone;
}

function weekGetter(size: number) {
  return (date: Date) => {
    const firstThurs = getFirstThursdayOfYear(date.getFullYear()),
      thisThurs = getThursdayThisWeek(date);

    const diff = +thisThurs - +firstThurs,
      result = 1 + Math.round(diff / 6.048e8); // 6.048e8 ms per week

    return padNumber(result, size);
  };
}

function ampmGetter(date: Date) {
  return date.getHours() < 12
    ? DateTimeFormats.AmPms[0]
    : DateTimeFormats.AmPms[1];
}

function eraGetter(date: Date) {
  return date.getFullYear() <= 0
    ? DateTimeFormats.Eras[0]
    : DateTimeFormats.Eras[1];
}

function longEraGetter(date: Date) {
  return date.getFullYear() <= 0
    ? DateTimeFormats.EraNames[0]
    : DateTimeFormats.EraNames[1];
}

// export const yyyy = dateGetter('FullYear', 4, 0, false, true);
// export const yy = dateGetter('FullYear', 2, 0, true, true);
// export const y = dateGetter('FullYear', 1, 0, false, true);
// export const MMMM = dateStrGetter('Month');
// export const MMM = dateStrGetter('Month', true);
// export const MM = dateGetter('Month', 2, 1);
// export const M = dateGetter('Month', 1, 1);
// export const LLLL = dateStrGetter('Month', false, true);
// export const dd = dateGetter('Date', 2);
// export const d = dateGetter('Date', 1);
// export const HH = dateGetter('Hours', 2);
// export const H = dateGetter('Hours', 1);
// export const hh = dateGetter('Hours', 2, -12);
// export const h = dateGetter('Hours', 1, -12);
// export const mm = dateGetter('Minutes', 2);
// export const m = dateGetter('Minutes', 1);
// export const ss = dateGetter('Seconds', 2);
// export const s = dateGetter('Seconds', 1);
// // while ISO 8601 requires fractions to be prefixed with `.` or `,`
// // we can be just safely rely on using `sss` since we currently don't support
// // single or two digit fractions.
// export const sss = dateGetter('Milliseconds', 3);
// export const EEEE = dateStrGetter('Day');
// export const EEE = dateStrGetter('Day', true);
// export const a = ampmGetter;
// export const Z = timeZoneGetter;
// export const ww = weekGetter(2);
// export const w = weekGetter(1);
// export const G = eraGetter;
// export const GG = eraGetter;
// export const GGG = eraGetter;
// export const GGGG = longEraGetter;

export const DATE_FORMATS = {
  yyyy: dateGetter('FullYear', 4, 0, false, true),
  yy: dateGetter('FullYear', 2, 0, true, true),
  y: dateGetter('FullYear', 1, 0, false, true),
  MMMM: dateStrGetter('Month'),
  MMM: dateStrGetter('Month', true),
  MM: dateGetter('Month', 2, 1),
  M: dateGetter('Month', 1, 1),
  LLLL: dateStrGetter('Month', false, true),
  dd: dateGetter('Date', 2),
  d: dateGetter('Date', 1),
  HH: dateGetter('Hours', 2),
  H: dateGetter('Hours', 1),
  hh: dateGetter('Hours', 2, -12),
  h: dateGetter('Hours', 1, -12),
  mm: dateGetter('Minutes', 2),
  m: dateGetter('Minutes', 1),
  ss: dateGetter('Seconds', 2),
  s: dateGetter('Seconds', 1),
  // while ISO 8601 requires fractions to be prefixed with `.` or `,`
  // we can be just safely rely on using `sss` since we currently don't support
  // single or two digit fractions.
  sss: dateGetter('Milliseconds', 3),
  EEEE: dateStrGetter('Day'),
  EEE: dateStrGetter('Day', true),
  a: ampmGetter,
  Z: timeZoneGetter,
  ww: weekGetter(2),
  w: weekGetter(1),
  G: eraGetter,
  GG: eraGetter,
  GGG: eraGetter,
  GGGG: longEraGetter
};
