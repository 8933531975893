import { render, staticRenderFns } from "./TableCellPrivateStatus.vue?vue&type=template&id=fa0e8de0&scoped=true"
import script from "./TableCellPrivateStatus.vue?vue&type=script&setup=true&lang=ts"
export * from "./TableCellPrivateStatus.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./TableCellPrivateStatus.vue?vue&type=style&index=0&id=fa0e8de0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa0e8de0",
  null
  
)

export default component.exports