export const IRN = {
  name: {
    common: 'Iran',
    official: 'Islamic Republic of Iran',
    native: { fas: { official: 'جمهوری اسلامی ایران', common: 'ایران' } },
  },
  cities: [
    'Karaj',
    'Golestan',
    'Mashhad',
    'Khorasan',
    'Razaviyeh',
    'Semnan',
    'Sistan-e `Olya',
    'Birjand',
    'Qazvin',
    'Farsi',
    'Tiran',
    'Bandar BÅ«shehr',
    'Kerman',
    'Sirjan',
    'Yasuj',
    'Kermanshah',
    'Hamadan',
    'Kordestan-e `Olya',
    'Tabriz',
    'Azaran',
    'Ardabil',
    'Zanjan',
    'Shahid',
    'Pasargad',
    'Sena',
    'Sabz',
    'Baharan',
    'Negar',
    'Rasht',
    'Rafsanjan',
    'Isfahan',
    'Ahvaz',
    'Shiraz',
    'Khong',
    'Baladeh',
    'Barbun',
    'Hasanabad-e Qadamgah',
    'Behjan',
    'Shahrak-e Pars',
    'Peshgoman',
    'Gostar',
    'Yazd',
    'Ilam',
    'Bakhtiari',
    'Gharbi',
    'Gilan-e Gharb',
    'Gazan-e Bala',
    'Sharif',
    'Shahrud',
    '`Oryan',
    'Ashna',
    'Shahrak',
    'Payam',
    'Baseri Hadi',
    'Mohr',
    'Tonekabon',
    'Dezful',
    'Peyk',
    'Abadan',
    'Samen',
    'Shahriar',
    'Azad',
    'Taha',
    'Borran-e Bala',
    'Arnan',
    'Najafabad',
    'Golriz',
    'Hirad',
    'Kashan',
    'Banak',
    'Torkan',
    'Vaj',
    'Piruz',
    'Mehin',
    'Bakhtiaruyeh',
    'Golpayegan',
    'Bandar-e Mahshahr',
    'Sanandaj',
    'Zahedan',
    'Arak',
    'Bastak',
    'Gilas',
    'Urmia',
    'Lahijan',
    'Shahr',
    'Parpis',
    'Kelarabad',
    'Neka',
    'Behshahr',
    'Esfarayen',
    'Abol',
    'Chabahar',
    'Zabol',
    'Saravan',
    'Iranshahr',
    'Rasak',
    'Khash',
    'Falavarjan',
    'Chah Deraz',
    'Baghin',
    'Zangiabad',
    'Kord Kandi',
    'Rigan',
    'Jiroft',
    'Bandar-e Khamir',
    'Ravar',
    'Varamin',
    'Damavand',
    'Razan',
    'Nahavand',
    'Homa',
    'Hashtgerd',
    'Amol',
    'Arsanjan',
    'Karimkhan',
    'Fardis',
    'Parsian',
    'Emam',
    'Neyshabur',
    'Vandar',
    'Hormozabad',
    'Baft',
    'Onar',
    'Bardsir',
    'Qeshm',
    'Siahkal',
    'Miandoab',
    'Hadishahr',
    'Sahand',
    'Soltanabad',
    'Sabzevar',
    'Gorgan',
    'Kordovan-e Sofla',
    'Chapar',
    'Shahid Madani',
    'Khameneh',
    'Khalkhal',
    'Manjil',
    'Hashtpar',
    'Salmas',
    'Shirvan',
    'Shahrak-e Milad',
    'Darmian',
    'Nehbandan',
    'Quchan',
    'Babol',
    'Mamasani',
    'Malayer',
    'Razi',
    'Band',
    'Badr',
    'Behbahan',
    'Ashan',
    'Dadeh',
    'Saman',
    'Sari',
    'Shahrak-e Direh',
    'Fasa',
    'Wahna',
    'Qasr od Dasht',
    'Mahestan',
    'Jahan',
    'Mobarakeh',
    'Kaman',
    'Kish',
    'Bidak',
    'Ravand',
    'Jadir',
    'Jahrom',
    'Post',
    'Naji',
    'Hush',
    'Mehregan',
    'Khowrshid',
    'Birizg',
    'Gerash',
    'Lamerd',
    'Khowrasgan',
    'Kian',
    'Novin',
    'Shahreza',
    'Idah',
    'Jahan Nama',
    'Behdasht',
    'Azadi',
    'Evin',
    'Nasir',
    'Shadman',
    'Khvajeh',
    'Malek Talesh',
    'Bostan',
    'Eslamshahr',
    'Saveh',
    'Mahallat',
    'Takestan',
    'Zahra',
    'Garmsar',
    'Damghan',
    'Shazand',
    'Khomeyn',
    'Ashtian',
    'Semirom',
    'Khomeyni Shahr',
    'Valilu',
    'Rayen',
    'Meybod',
    'Ardakan',
    'Rabor',
    '`Aliabad',
    'Zarand',
    'Neyriz',
    'Estahban',
    'Fordu',
    'Servan',
    'Maragheh',
    'Marand',
    'Bonab',
    'Malekan',
    'Mianej',
    'Julfa',
    'Khvoy',
    'Mahabad',
    'Bukan',
    'Meshgin Shahr',
    'Moghan',
    'Germi',
    '`Aliabad-e Aq Hesar',
    'Astara',
    'Amlash',
    'Asadabad',
    'Dorud',
    'Ravansar',
    'Shahid Chamran',
    'Kabir',
  ],
  tld: ['.ir', 'ایران.'],
  cca2: 'IR',
  ccn3: '364',
  cca3: 'IRN',
  cioc: 'IRI',
  currency: ['IRR'],
  callingCode: ['98'],
  capital: 'Tehran',
  altSpellings: [
    'IR',
    'Islamic Republic of Iran',
    'Iran, Islamic Republic of',
    'Jomhuri-ye Eslāmi-ye Irān',
  ],
  region: 'Asia',
  subregion: 'Southern Asia',
  languages: { fas: 'Persian' },
  translations: {
    deu: { official: 'Islamische Republik Iran', common: 'Iran' },
    fra: { official: "République islamique d'Iran", common: 'Iran' },
    hrv: { official: 'Islamska Republika Iran', common: 'Iran' },
    jpn: {
      official: 'イラン·イスラム共和国',
      common: 'イラン・イスラム共和国',
    },
    nld: { official: 'Islamitische Republiek Iran', common: 'Iran' },
    por: { official: 'República Islâmica do Irã', common: 'Irão' },
    rus: { official: 'Исламская Республика Иран', common: 'Иран' },
    spa: { official: 'República Islámica de Irán', common: 'Iran' },
    fin: { official: 'Iranin islamilainen tasavalta', common: 'Iran' },
  },
  latlng: [32, 53],
  demonym: 'Iranian',
  landlocked: false,
  borders: ['AFG', 'ARM', 'AZE', 'IRQ', 'PAK', 'TUR', 'TKM'],
  area: 1648195,
  provinces: [
    'Ardabil',
    'Azarbayjan-e Gharbi',
    'Azarbayjan-e Sharqi',
    'Bushehr',
    'Chahar Mahall va Bakhtiari',
    'Esfahan',
    'Fars',
    'Gilan',
    'Golestan',
    'Hamadan',
    'Hormozgan',
    'Ilam',
    'Kerman',
    'Kermanshah',
    'Khorasan',
    'Khuzestan',
    'Kohgiluyeh va Buyer Ahmad',
    'Kordestan',
    'Lorestan',
    'Markazi',
    'Mazandaran',
    'Qazvin',
    'Qom',
    'Semnan',
    'Sistan va Baluchestan',
    'Tehran',
    'Yazd',
    'Zanjan',
  ],
  nativeName: 'Irān',
  timezones: ['UTC+03:30'],
} as const;
