/** ... */
export type typeGuards<T> = (input: unknown) => input is T;
/** ... */
export type Predicate<Input = unknown> = (input: Input) => boolean;

/**
 * ...
 *
 * @param
 * @return
 */
export function or<A>(a: typeGuards<A>): typeGuards<A>;
export function or<A, B>(a: typeGuards<A>, b: typeGuards<B>): typeGuards<A | B>;
export function or<A, B, C>(
  a: typeGuards<A>,
  b: typeGuards<B>,
  c: typeGuards<C>,
): typeGuards<A | B | C>;
export function or<A, B, C, D>(
  a: typeGuards<A>,
  b: typeGuards<B>,
  c: typeGuards<C>,
  d: typeGuards<D>,
): typeGuards<A | B | C | D>;
export function or<T>(...guards: Predicate<T>[]): Predicate<T>;
export function or(...guards: Predicate[]): Predicate;
export function or(...predicates: Predicate[]): Predicate {
  return (input: any) => predicates.some((guard) => guard(input));
}

/**
 * Determine if a value is a string.
 *
 * @param value The value to check.
 * @return `true` if the value is an string, otherwise `false`.
 */
export function isString(value: unknown): value is string {
  return typeof value === 'string';
}

/**
 * Determine if a value is a number.
 *
 * @param value The value to check.
 * @return `true` if the value is an number, otherwise `false`.
 */
export function isNumber(value: unknown): value is number {
  return typeof value === 'number';
}

/**
 * Determine if a value is a bigint.
 *
 * @param value The value to check.
 * @return `true` if the value is an bigint, otherwise `false`.
 */
export function isBigInt(value: unknown): value is bigint {
  return typeof value === 'bigint';
}

/**
 * Determine if a value is a boolean.
 *
 * @param value The value to check.
 * @return `true` if the value is an boolean, otherwise `false`.
 */
export function isBoolean(value: unknown): value is boolean {
  return typeof value === 'boolean';
}

/**
 * Determine if a value is a function.
 *
 * @param value The value to check.
 * @return `true` if the value is an function, otherwise `false`.
 */
export function isFunction(value: unknown): value is GenericFunction {
  return typeof value === 'function';
}

/**
 * Determine if a value is a standard object.
 *
 * @param value The value to check.
 * @return `true` if the value is an object, otherwise `false`.
 */
export function isObject(value: unknown): value is GenericObject {
  return value !== null && typeof value === 'object';
}

/**
 * Determine if a value is a symbol.
 *
 * @param value The value to check.
 * @return `true` if the value is a symbol, otherwise `false`.
 */
export function isSymbol(value: unknown): value is symbol {
  return typeof value === 'symbol';
}

/**
 * Determine if a value is an array.
 *
 * @param value The value to check.
 * @return `true` if the value is an object, otherwise `false`.
 */
export function isArray(value: unknown): value is unknown[] {
  return Array.isArray(value);
}

/**
 * Determine if a value is `null`.
 *
 * @param value The value to check.
 * @return `true` if the value is null, otherwise `false`.
 */
export function isNull(value: unknown): value is null {
  return value === null;
}

/**
 * Determine if a value is undefined.
 *
 * @param value The value to check.
 * @return `true` if the value is undefined, otherwise `false`.
 */
export function isUndefined(value: unknown): value is undefined {
  return value === undefined;
}

/**
 * Determine if a value is nullish.
 *
 * @param value The value to check.
 * @return `true` if the value is nullish, otherwise `false`.
 */
export function isNullish(value: unknown): value is null | undefined {
  return isNull(value) || isUndefined(value);
}

/**
 * Determine if a value is not nullish.
 *
 * @param value The value to check.
 * @return `true` if the value is not nullish, otherwise `false`.
 */
export function isNotNullish(value: unknown): value is NotNullish {
  return !isNullish(value);
}

/**
 * Determine if a value is a `Date` instance.
 *
 * @param value The value to check.
 * @return `true` if the value is a `Date` instance, otherwise `false`.
 */
export function isDate(value: unknown): value is Date {
  return value instanceof Date;
}

/**
 * Determine if a value can be parsed as a `Date` object.
 *
 * @param value The value to check.
 * @return `true` if the value can be parsed as a `Date`, otherwise `false`.
 */
export function isDateish(value: unknown): value is string | number | Date {
  return isString(value) || isNumber(value) || value instanceof Date;
}

/**
 * Determine if a value is one of the types dictated by the provided type
 * gaurds.
 *
 * @param value The value to check.
 * @return `true` if the value passes one of the type gaurds, otherwise `false`.
 */
export function isOneOf<A>(value: unknown, a: typeGuards<A>): value is A;
export function isOneOf<A, B>(
  value: unknown,
  a: typeGuards<A>,
  b: typeGuards<B>,
): value is A | B;
export function isOneOf<A, B, C>(
  value: unknown,
  a: typeGuards<A>,
  b: typeGuards<B>,
  c: typeGuards<C>,
): value is A | B | C;
export function isOneOf<A, B, C, D>(
  value: unknown,
  a: typeGuards<A>,
  b: typeGuards<B>,
  c: typeGuards<C>,
  d: typeGuards<D>,
): value is A | B | C | D;
export function isOneOf(value: unknown, ...predicates: Predicate[]) {
  return or(...predicates)(value);
}
