var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { class: ["form-datepicker", { vertical: _vm.vertical }] },
    _vm._l(_setup.fields, function (field) {
      return _c(
        "FloatingLabel",
        {
          key: field.key,
          class: `${field.key}-field`,
          attrs: { label: field.label },
        },
        [
          field.key === "year" && _vm.datepicker
            ? _c(
                "b-input-group",
                [
                  _c("b-form-select", {
                    attrs: {
                      value: _setup.date[field.key],
                      placeholder: field.label,
                      state: _vm.state ?? null,
                      options: field.options,
                    },
                    on: {
                      input: function ($event) {
                        return _setup.onFieldUpdated(field.key, $event)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "b-input-group-append",
                    [
                      _c("b-form-datepicker", {
                        staticClass: "ml-2",
                        attrs: {
                          value: _setup.datePickerValue,
                          "button-only": "",
                        },
                        on: { input: _setup.onDatePickerInput },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c("b-form-select", {
                attrs: {
                  value: _setup.date[field.key],
                  placeholder: field.label,
                  state: _vm.state ?? null,
                  options: field.options,
                },
                on: {
                  input: function ($event) {
                    return _setup.onFieldUpdated(field.key, $event)
                  },
                },
              }),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }