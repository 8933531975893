export const BHS = {
  name: {
    common: 'Bahamas',
    official: 'Commonwealth of the Bahamas',
    native: {
      eng: { official: 'Commonwealth of the Bahamas', common: 'Bahamas' },
    },
  },
  cities: [
    'Nassau',
    'Freeport',
    'Marsh Harbour',
    'Spanish Wells',
    'Andros Town',
    'Dunmore Town',
    'Palmetto Point',
    '',
  ],
  tld: ['.bs'],
  cca2: 'BS',
  ccn3: '044',
  cca3: 'BHS',
  cioc: 'BAH',
  currency: ['BSD'],
  callingCode: ['1242'],
  capital: 'Nassau',
  altSpellings: ['BS', 'Commonwealth of the Bahamas'],
  region: 'Americas',
  subregion: 'Caribbean',
  languages: { eng: 'English' },
  translations: {
    cym: { official: 'Commonwealth of the Bahamas', common: 'Bahamas' },
    deu: { official: 'Commonwealth der Bahamas', common: 'Bahamas' },
    fra: { official: 'Commonwealth des Bahamas', common: 'Bahamas' },
    hrv: { official: 'Zajednica Bahama', common: 'Bahami' },
    ita: { official: 'Commonwealth delle Bahamas', common: 'Bahamas' },
    jpn: { official: 'バハマ', common: 'バハマ' },
    nld: { official: "Gemenebest van de Bahama's", common: 'Bahama’s' },
    por: { official: 'Comunidade das Bahamas', common: 'Bahamas' },
    rus: {
      official: 'Содружество Багамских Островов',
      common: 'Багамские Острова',
    },
    spa: { official: 'Commonwealth de las Bahamas', common: 'Bahamas' },
    fin: { official: 'Bahaman liittovaltio', common: 'Bahamasaaret' },
  },
  latlng: [24.25, -76],
  demonym: 'Bahamian',
  landlocked: false,
  borders: [],
  area: 13943,
  provinces: [
    'Acklins and Crooked Islands',
    'Bimini',
    'Cat Island',
    'Exuma',
    'Freeport',
    'Fresh Creek',
    "Governor's Harbour",
    'Green Turtle Cay',
    'Harbour Island',
    'High Rock',
    'Inagua',
    'Kemps Bay',
    'Long Island',
    'Marsh Harbour',
    'Mayaguana',
    'New Providence',
    'Nicholls Town and Berry Islands',
    'Ragged Island',
    'Rock Sound',
    'San Salvador and Rum Cay',
    'Sandy Point',
  ],
  nativeName: 'Bahamas',
  timezones: ['UTC−05:00'],
} as const;
