/**
 * ...
 */
export enum Rating {
  Poor = 'poor',
  Average = 'average',
  Good = 'good',
}

/**
 * ...
 */
export interface Bounds {
  upper: number;
  lower: number;
  reverse: boolean;
}

/**
 * ...
 */
export interface DataSet {
  mean: number;
  standardDeviation: number;
}

/**
 * ...
 *
 * @param mean
 * @param standardDeviation
 * @param reverse
 * @return
 */
export function createBounds(
  mean: number,
  standardDeviation: number,
  reverse?: boolean,
) {
  const range = 0.5 * standardDeviation;

  const upper = mean + range;
  const lower = mean - range;

  return { upper, lower, reverse } as Bounds;
}

/**
 * ...
 *
 * @param value
 * @param bounds
 * @return
 */
export function rate(value: number, bounds: Bounds) {
  if (value <= bounds.lower) {
    return bounds.reverse ? Rating.Good : Rating.Poor;
  }

  if (value >= bounds.upper) {
    return bounds.reverse ? Rating.Poor : Rating.Good;
  }

  return Rating.Average;
}
