var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("Transition", [
    _vm.open
      ? _c("div", { staticClass: "split-view-page" }, [
          _c(
            "div",
            { staticClass: "page-header" },
            [
              _c(
                "b-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closed")
                    },
                  },
                },
                [
                  _c(_setup.Icon, { attrs: { icon: _setup.faChevronLeft } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Back")]),
                ],
                1
              ),
              _vm._v(" "),
              _vm.title
                ? _c("span", { staticClass: "header-item header-title" }, [
                    _vm._v(_vm._s(_vm.title)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("span"),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "page-content" }, [
            _c("div", [
              _c(
                "div",
                { staticClass: "content-wrapper" },
                [_vm._t("default")],
                2
              ),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }