export const LTU = {
  name: {
    common: 'Lithuania',
    official: 'Republic of Lithuania',
    native: { lit: { official: 'Lietuvos Respublikos', common: 'Lietuva' } },
  },
  cities: [
    'Vilnius',
    'Alytus',
    'MarijampolÄ—',
    'Å ilutÄ—',
    'GrigiÅ¡kÄ—s',
    'Å iauliai',
    'MaÅ¾eikiai',
    'PlungÄ—',
    'Jurbarkas',
    'KlaipÄ—da',
    'Kaunas',
    'RadviliÅ¡kis',
    'Jonava',
    'Panevezys',
    'Garliava',
    'Mastaiciai',
    'RokiÅ¡kis',
    'JoniÅ¡kÄ—lis',
    'KupiÅ¡kis',
    'TelÅ¡iai',
    'Kretinga',
    'ElektrÄ—nai',
    'Vievis',
    'Visaginas',
    'Uzliedziai',
    'AnykÅ¡Äiai',
    'KaiÅ¡iadorys',
    'Juskonys',
    'Trakai',
    'Palanga',
    'GargÅ¾dai',
    'KÄ—dainiai',
    'Vilniaus Apskritis',
    'Ignalina',
    'TauragÄ—',
    'Anciskiai',
    'EiÅ¡iÅ¡kÄ—s',
    'Salcininkai',
    'Antakalnis',
    'KelmÄ—',
    'Sakiai',
    'Venta',
    'Ukmerge',
  ],
  tld: ['.lt'],
  cca2: 'LT',
  ccn3: '440',
  cca3: 'LTU',
  cioc: 'LTU',
  currency: ['EUR'],
  callingCode: ['370'],
  capital: 'Vilnius',
  altSpellings: ['LT', 'Republic of Lithuania', 'Lietuvos Respublika'],
  region: 'Europe',
  subregion: 'Northern Europe',
  languages: { lit: 'Lithuanian' },
  translations: {
    deu: { official: 'Republik Litauen', common: 'Litauen' },
    fra: { official: 'République de Lituanie', common: 'Lituanie' },
    hrv: { official: 'Republika Litva', common: 'Litva' },
    ita: { official: 'Repubblica di Lituania', common: 'Lituania' },
    jpn: { official: 'リトアニア共和国', common: 'リトアニア' },
    nld: { official: 'Republiek Litouwen', common: 'Litouwen' },
    por: { official: 'República da Lituânia', common: 'Lituânia' },
    rus: { official: 'Литовская Республика', common: 'Литва' },
    spa: { official: 'República de Lituania', common: 'Lituania' },
    fin: { official: 'Liettuan tasavalta', common: 'Liettua' },
  },
  latlng: [56, 24],
  demonym: 'Lithuanian',
  landlocked: false,
  borders: ['BLR', 'LVA', 'POL', 'RUS'],
  area: 65300,
  provinces: [
    'Akmenes Rajonas',
    'Alytaus Rajonas',
    'Alytus',
    'Anyksciu Rajonas',
    'Birstonas',
    'Birzu Rajonas',
    'Druskininkai',
    'Ignalinos Rajonas',
    'Jonavos Rajonas',
    'Joniskio Rajonas',
    'Jurbarko Rajonas',
    'Kaisiadoriu Rajonas',
    'Kaunas',
    'Kauno Rajonas',
    'Kedainiu Rajonas',
    'Kelmes Rajonas',
    'Klaipeda',
    'Klaipedos Rajonas',
    'Kretingos Rajonas',
    'Kupiskio Rajonas',
    'Lazdiju Rajonas',
    'Marijampole',
    'Marijampoles Rajonas',
    'Mazeikiu Rajonas',
    'Moletu Rajonas',
    'Neringa Pakruojo Rajonas',
    'Palanga',
    'Panevezio Rajonas',
    'Panevezys',
    'Pasvalio Rajonas',
    'Plunges Rajonas',
    'Prienu Rajonas',
    'Radviliskio Rajonas',
    'Raseiniu Rajonas',
    'Rokiskio Rajonas',
    'Sakiu Rajonas',
    'Salcininku Rajonas',
    'Siauliai',
    'Siauliu Rajonas',
    'Silales Rajonas',
    'Silutes Rajonas',
    'Sirvintu Rajonas',
    'Skuodo Rajonas',
    'Svencioniu Rajonas',
    'Taurages Rajonas',
    'Telsiu Rajonas',
    'Traku Rajonas',
    'Ukmerges Rajonas',
    'Utenos Rajonas',
    'Varenos Rajonas',
    'Vilkaviskio Rajonas',
    'Vilniaus Rajonas',
    'Vilnius',
    'Zarasu Rajonas',
  ],
  nativeName: 'Lietuva',
  timezones: ['UTC+02:00'],
} as const;
