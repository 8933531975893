export const LKA = {
  name: {
    common: 'Sri Lanka',
    official: 'Democratic Socialist Republic of Sri Lanka',
    native: {
      sin: {
        official: 'ශ්‍රී ලංකා ප්‍රජාතාන්ත්‍රික සමාජවාදී ජනරජය',
        common: 'ශ්‍රී ලංකාව',
      },
      tam: { official: 'இலங்கை சனநாயக சோசலிசக் குடியரசு', common: 'இலங்கை' },
    },
  },
  cities: [
    'Colombo',
    'Katubedda',
    'Kandy',
    'Pannipitiya',
    'Makola South',
    'Wattala',
    'Maharagama',
    'Wellampitiya',
    'Kaduwela',
    'Battaramulla South',
    'Nugegoda',
    'Sri Jayewardenepura Kotte',
    'Homagama',
    'Dehiwala',
    'Orugodawatta',
    'Kohuwala',
    'Piliyandala',
    'Dehiwala-Mount Lavinia',
    'Ragama',
    'Negombo',
    'Boralesgamuwa South',
    'Moratuwa',
    'Badulla',
    'Padukka',
    'Kandana',
    'Hekitta',
    'Rajagiriya',
    'Gampaha',
    'Nattandiya Town',
    'Matale',
    'Peradeniya',
    'Eppawala',
    'Talapathpitiya',
    'Katunayaka',
    'Kelaniya',
    'Gangodawila North',
    'Biyagama',
  ],
  tld: ['.lk', '.இலங்கை', '.ලංකා'],
  cca2: 'LK',
  ccn3: '144',
  cca3: 'LKA',
  cioc: 'SRI',
  currency: ['LKR'],
  callingCode: ['94'],
  capital: 'Colombo',
  altSpellings: ['LK', 'ilaṅkai', 'Democratic Socialist Republic of Sri Lanka'],
  region: 'Asia',
  subregion: 'Southern Asia',
  languages: { sin: 'Sinhala', tam: 'Tamil' },
  translations: {
    deu: {
      official: 'Demokratische Sozialistische Republik Sri Lanka',
      common: 'Sri Lanka',
    },
    fra: {
      official: 'République démocratique socialiste du Sri Lanka',
      common: 'Sri Lanka',
    },
    hrv: {
      official: 'Demokratska Socijalističke Republike Šri Lanke',
      common: 'Šri Lanka',
    },
    ita: {
      official: 'Repubblica democratica socialista dello Sri Lanka',
      common: 'Sri Lanka',
    },
    jpn: { official: 'スリランカ民主社会主義共和国', common: 'スリランカ' },
    nld: {
      official: 'Democratische Socialistische Republiek Sri Lanka',
      common: 'Sri Lanka',
    },
    por: {
      official: 'República Democrática Socialista do Sri Lanka',
      common: 'Sri Lanka',
    },
    rus: {
      official: 'Демократическая Социалистическая Республика Шри-Ланка',
      common: 'Шри-Ланка',
    },
    spa: {
      official: 'República Democrática Socialista de Sri Lanka',
      common: 'Sri Lanka',
    },
    fin: {
      official: 'Sri Lankan demokraattinen sosialistinen tasavalta',
      common: 'Sri Lanka',
    },
  },
  latlng: [7, 81],
  demonym: 'Sri Lankan',
  landlocked: false,
  borders: ['IND'],
  area: 65610,
  provinces: [
    'Central',
    'Eastern',
    'North Central',
    'North Eastern',
    'North Western',
    'Northern',
    'Sabaragamuwa',
    'Southern',
    'Uva',
    'Western',
  ],
  nativeName: 'śrī laṃkāva',
  timezones: ['UTC+05:30'],
} as const;
