export const SVK = {
  name: {
    common: 'Slovakia',
    official: 'Slovak Republic',
    native: { slk: { official: 'Slovenská republika', common: 'Slovensko' } },
  },
  cities: [
    'Bratislava',
    "Vel'ke Canikovce",
    'Zavod',
    'Studienka',
    'Å½ilina',
    'Likavka',
    'Krupina',
    'Zvolen',
    'Klokoc',
    'Pliesovce',
    'Trnava',
    'StarÃ¡ Ä½ubovÅˆa',
    'BanskÃ¡ Bystrica',
    'Michalovce',
    'HumennÃ©',
    'Sobrance',
    'Varin',
    'Zelenec',
    'Detva',
    'RuÅ¾omberok',
    'Galanta',
    'Dubnica nad VÃ¡hom',
    'Vrutky',
    'Lukacovce',
    'Nitra',
    "Vel'ky Grob",
    'Potvorice',
    'Krompachy',
    'Lozorno',
    'Poprad',
    'Vrable',
    'Dolna Streda',
    'Vahovce',
    'KoÅ¡ice',
    'NovÃ¡ Dubnica',
    'Gbely',
    'Dlha nad Oravou',
    'Mutne',
    'Prievidza',
    'Tvrdosin',
    'Oravske Vesele',
    'NÃ¡mestovo',
    'Trstena',
    'Pernek',
    'Solosnica',
    'Krizovany nad Dudvahom',
    'Dolny Stal',
    'Pata',
    'Sucha nad Parnou',
    'Å aÄ¾a',
    'PieÅ¡Å¥any',
    'Hlohovec',
    'NovÃ© ZÃ¡mky',
    'Malacky',
    'Opoj',
    'Stara Tura',
    'Sladkovicovo',
    'Pezinok',
    'Stupava',
    'Ruzindol',
    'Marianka',
    'Slovensky Grob',
    'Reca',
    'Kvetoslavov',
    'Boleraz',
    "Topol'niky",
    'Hruby Sur',
    'Jelka',
    'Cerova',
    'LuÄenec',
    'Vranov nad TopÄ¾ou',
    'PreÅ¡ov',
    'Moldava nad Bodvou',
    'Ludanice',
    'Dolny Hricov',
    'Ladce',
    'PÃºchov',
    'Liptovsky Hradok',
    'Vojcice',
    'Giraltovce',
    'LevoÄa',
    'Sabinov',
    'Dlhe nad Cirochou',
    'Fintice',
    'Sarisske Bohdanovce',
    'Gelnica',
    'Bardejov',
    'Cecejovce',
    'Secovska Polianka',
    'Bernolakovo',
    'Cifer',
    'Baka',
    "Presel'any nad Ipl'om",
    'Kopcany',
    'Senica',
    'Bahon',
    'Nizna Kamenica',
    "Dol'any",
    'Skalica',
    "Vel'ke Kostol'any",
    'Miloslavov',
    'Prakovce',
    'Komjatice',
    'TrenÄÃ­n',
    'Golianovo',
    'Branc',
    'Levice',
    'Cerveny Hradok',
    'Bystricka',
    'Opatovce nad Nitrou',
    'Martin',
    'TurÄianske Teplice',
    'Horna Stubna',
    'TopoÄ¾Äany',
    'Hlinik',
    'Ivanka pri Dunaji',
    'Cerveny Kamen',
    'Myjava',
    'Hrochot,Slovakia',
    'PovaÅ¾skÃ¡ Bystrica',
    'Zikava',
    'Lednicke Rovne',
    'KysuckÃ© NovÃ© Mesto',
    'Lemesany',
    'SvidnÃ­k',
    'Udavske',
    'Bohdanovce',
    'Zuberec',
    'Ilava',
    'Kysucky Lieskovec',
    'Rosina',
    'Nededza',
    'LiptovskÃ½ MikulÃ¡Å¡',
    'KomÃ¡rno',
    'RoÅ¾Åˆava',
    'Tlmace',
    "Host'ovce",
    'Nova Bana',
    'Okoc',
    'Spissky Stiavnik',
    'Druzstevna pri Hornade',
    'DolnÃ½ KubÃ­n',
    'DunajskÃ¡ Streda',
    'Samorin',
    'ÄŒadca',
    'SvÃ¤tÃ½ Jur',
    'Rovinka',
    'Staskov',
    'Velicna',
    'Istebne',
    'Hencovce',
    'BanskÃ¡ Å tiavnica',
    'Snina',
    'Brezno',
    'SpiÅ¡skÃ¡ NovÃ¡ Ves',
    "Vel'ky Kyr",
    'Stropkov',
    'Nitrianske Rudno',
    'Vlkanova',
    'Dolne Vestenice',
    'Å urany',
    'Podbrezova',
    'Å tÃºrovo',
    "Ipel'ske Ul'any",
    'Bela',
    'KeÅ¾marok',
    'Jelenec',
    'Holic',
    'Dlhe Klcovo',
    'Velcice',
    'Slovenska Ves',
    'Banovce nad Bebravou',
    'TrebiÅ¡ov',
    'Secovce',
    "Vel'ke Kapusany",
    "Hnust'a",
    'Borovce',
    'Mojmirovce',
    'Spacince',
    'Senec',
    'Stefanov',
    'Pastuchov',
    'Chtelnica',
    'Dolne Oresany',
    'Eliasovce',
    'Moravany nad Vahom',
    'Vlckovce',
    'Madunice',
    "Topol'nica",
    'Svodin',
    'Jablonove',
    'Jacovce',
    'Olesna',
    'Chorvatsky Grob',
    'Tomasov',
    'Turany',
    'Sucany',
    'Klokocov',
    'Trnovec nad Vahom',
    'Tesedikovo',
    'Tvrdosovce',
    "Kmet'ovo",
    'Hlinik nad Hronom',
    'Ziar nad Hronom',
    'Besenov',
    'Sena',
    'Nove Sady',
    'Brezova pod Bradlom',
    'Praznovce',
    'BytÄa',
    'Zlate',
    'Vinne',
    'Zbince',
    'Hrinova',
    'VrbovÃ©',
    'Sipkove',
    'Polomka',
    'Handlova',
    'Smizany',
    'Spissky Stvrtok',
    'Jaslovske Bohunice',
    "Vel'ky Krtis",
    'Zeliezovce',
    'Torysky',
    'Spissky Hrusov',
    'RimavskÃ¡ Sobota',
    'Nizna',
    'Urmince',
    'Kanianka',
    'Diviaky nad Nitricou',
    'Novaky',
    'PartizÃ¡nske',
    'Novy Salas',
    'Varhanovce',
    'Svaty Peter',
    'Nyrovce',
    'Proc',
    'RevÃºca',
    'NovÃ© Mesto nad VÃ¡hom',
    'Plave Vozokany',
    'Nitrianske Sucany',
    'Blatnica',
    'Cechynce',
    'Velky Meder',
    'Holice',
    'Vysne Raslavice',
    "Vel'aty",
    'Nana',
    'Tajov',
    'Svit',
    "Vel'ka Maca",
    'Selice',
    'TatranskÃ¡ Lomnica',
    'Kremnica',
    'Stary Smokovec',
    'Stara Lesna',
    'Liptovske Revuce',
    'Kosicka Nova Ves',
    'Maly Lapas',
    'Vysny Orlik',
    'Horne Oresany',
    'Klatova Nova Ves',
    'Hradiste',
    'Praha',
    'Horny Smokovec',
    'Kajal',
    "Vel'ke Orviste",
    'Horna Lehota',
    'Lehota',
    'Kukova',
    'JelÅ¡ava',
    'Kolarovo',
    'Horne Saliby',
    "Novot'",
    'Zakamenne',
    'Svaty Kriz',
    'Terchova',
    'Lubina',
    'Mesto',
    'Beladice',
    'Krusovce',
    'Dolna Marikova',
    'Plavecke Podhradie',
    'Podolie',
    'Pobedim',
    'SliaÄ',
    'Vecelkov',
    'Chlebnice',
    'Jasenica',
    'Bobrov',
    'Vitanova',
    "Dolne Sl'azany",
    'Diakovce',
    'Selpice',
  ],
  tld: ['.sk'],
  cca2: 'SK',
  ccn3: '703',
  cca3: 'SVK',
  cioc: 'SVK',
  currency: ['EUR'],
  callingCode: ['421'],
  capital: 'Bratislava',
  altSpellings: ['SK', 'Slovak Republic', 'Slovenská republika'],
  region: 'Europe',
  subregion: 'Eastern Europe',
  languages: { slk: 'Slovak' },
  translations: {
    deu: { official: 'Slowakische Republik', common: 'Slowakei' },
    fra: { official: 'République slovaque', common: 'Slovaquie' },
    hrv: { official: 'slovačka', common: 'Slovačka' },
    ita: { official: 'Repubblica slovacca', common: 'Slovacchia' },
    jpn: { official: 'スロバキア共和国', common: 'スロバキア' },
    nld: { official: 'Slowaakse Republiek', common: 'Slowakije' },
    por: { official: 'República Eslovaca', common: 'Eslováquia' },
    rus: { official: 'Словацкая Республика', common: 'Словакия' },
    spa: { official: 'República Eslovaca', common: 'República Eslovaca' },
    fin: { official: 'Slovakian tasavalta', common: 'Slovakia' },
  },
  latlng: [48.66666666, 19.5],
  demonym: 'Slovak',
  landlocked: true,
  borders: ['AUT', 'CZE', 'HUN', 'POL', 'UKR'],
  area: 49037,
  provinces: [
    'Banskobystricky',
    'Bratislavsky',
    'Kosicky',
    'Nitriansky',
    'Presovsky',
    'Trenciansky',
    'Trnavsky',
    'Zilinsky',
  ],
  nativeName: 'Slovensko',
  timezones: ['UTC+01:00'],
} as const;
