import { store } from '@store';

import { uibModals } from './uib-modals';
import { NgModalOptions } from './types';

/**
 * Summons a modal based on a provided set of options.
 *
 * @param options Open modal options object.
 * @returns A `Promise` that resolves once the modal is closed.
 */
export async function openModal<T = unknown, P = object>(
  options: NgModalOptions<P>,
) {
  return modalOpener<T>(options);
}

/**
 * Summons a modal based on a provided set of options. Any exception thrown by
 * the modal will be handled silently before dismissing the modal.
 *
 * @param options Open modal options object.
 * @returns A `Promise` that resolves once the modal is closed.
 */
openModal.safe = <T = unknown, P = object>(options: NgModalOptions<P>) => {
  return modalOpener<T>({ ...options, safe: true });
};

export namespace openModal {
  /** {@link openModal} configuration options. */
  export type Options = NgModalOptions;
}

// region Helper Functions

/**
 * Summons a modal based on a provided set of options.
 *
 * @param options OPen modal options object.
 * @returns A `Promise` that resolves once the modal is closed.
 */
export async function modalOpener<R = unknown>(
  options: NgModalOptions<unknown> & { safe?: boolean },
) {
  const props: Record<string, unknown> = {};

  if ('props' in options) Object.assign(props, options.props);

  // Passing in item properties to either edit or view item in modal.
  if (options.getItemProps?.length) {
    for (const gip of options.getItemProps) {
      // Try to get item from store items.
      let item = getStoreDataItem(gip.storeName, gip.id);

      // Try to get item from backend.
      if (!item) item = await gip.getter(gip.id);

      if (!item) {
        /* eslint-disable-next-line no-console */
        console.error(
          `Could not retrieve item from store or backend. store name: ${gip.storeName} | id: ${gip.id}`,
        );
      }

      // Assign item to model props.
      if (item) props[gip.key] = item;
    }
  }

  props['title'] = options.title ?? 'MODAL';
  props['dismissible'] = 'dismissible' in options ? options.dismissible : true;

  const modal = uibModals.open<R>({
    props: {
      ...props,
      buttons: [
        {
          classes: 'btn-default',
          text: 'Close',
          click: (e?: string) => modal.dismiss(e),
        },
      ],
    },
    component: options.component,
    size: options.size ?? 'md',
    windowClass: options.classList ?? 'modal-default',
    backdrop: 'static' as const,
    resolve: { item: () => options.props },
  });

  if (!options.safe) return modal.result;

  try {
    return await modal.result;
  } catch {
    return;
  }
}

/**
 * ...
 *
 * @param moduleName ...
 * @param itemId ...
 * @returns ...
 */
function getStoreDataItem(moduleName: string, itemId: unknown) {
  const getterKey = `${moduleName}/get`;

  if (!(getterKey in store.getters)) {
    throw new Error(
      `[getStoreDataItem] there is no getter in the store at "${getterKey}".`,
    );
  }

  // ...
  const storeModuleGetter = (store.getters as unknown as GenericObject)[
    getterKey
  ];

  if (typeof storeModuleGetter !== 'function') {
    throw new Error(
      `[getStoreDataItem] the value returned by the store getter "${getterKey}" is not a function.`,
    );
  }

  return (storeModuleGetter as (id: unknown) => unknown)(itemId);
}

// endregion Helper Functions
