import { Module, Action } from '@vuex/decorators';
import { TablePanel } from 'table-panel';

import { faEye } from '@icons/solid/faEye';

import { Certification } from '@models';
import { modals } from '@modals';
import { computeDisplayName } from '@utils/user';

const columns: TablePanel.Column<Certification>[] = [
  {
    key: 'productName',
    label: 'Product Name',
    value: ({ product }) => product.name,
  },
  {
    key: 'createdAt',
    label: 'Created',
    value: 'createdAt',
    type: 'date',
  },
  {
    key: 'status',
    label: 'Status',
    value: 'status',
    component: 'TableCellCertificationStatus',
  },
  {
    key: 'lastAction',
    label: 'Last Action',
    component: 'TableCellCertificationLastAction',
    value: (item) => {
      return item.approvedAt ?? item.reattemptPromptAt ?? item.deniedAt ?? null;
    },
  },
  {
    key: 'user',
    label: 'User',
    value: (item) => computeDisplayName(item.user),
  },
  {
    key: 'reviewer',
    label: 'Reviewer',
    value: ({ reviewer }) => (reviewer ? computeDisplayName(reviewer) : null),
  },
];

const operations: TablePanel.Row.Operation<Certification>[] = [
  {
    label: 'View',
    icon: faEye,
    fn: ({ id }) => {
      void modals.certifications.view({ certificationId: id });
    },
  },
];

@Module({ namespaced: true })
export class CertificationTablePanel
  extends TablePanel<Certification>
  implements TablePanel.Props<Certification>
{
  readonly module = 'certifications';
  readonly loadAction = 'list';
  readonly pk = 'id';
  readonly label = 'CERTIFICATIONS';
  readonly columns = columns;
  readonly operations = operations;
  readonly createAction = 'createCertification';
  readonly deleteAction = 'deleteCertification';

  /**
   * ...
   */
  get enabled() {
    return this.isActiveRole(9);
  }

  /**
   * ...
   */
  @Action
  async createCertification() {
    await modals.product.create({ type: 'CERTIFICATION' });

    await this.context.dispatch('load');
  }

  // /**
  //  * ...
  //  */
  // @Action
  // async deleteCertification({
  //   items,
  // }: CertificationTablePanel.DeleteItemOptions) {
  //   await ngModals.confirm.deleteItems({ itemType: 'certifications', items });
  // }
}

export namespace CertificationTablePanel {
  /** ... */
  export interface DeleteItemOptions {
    items: Certification[];
  }
}
