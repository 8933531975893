import { Vue, Component, Prop, Watch } from '@vue';

import { faTimes } from '@icons/regular/faTimes';
import { faImage } from '@icons/duotone/faImage';

import { generateDataUrl } from '@utils/generate-data-url';

/**
 * Prompt the user for an image file using the native file system dialog.
 *
 * @return Image file data, if an image file was selected.
 */
async function addImageDialog() {
  const el = document.createElement('input');
  el.setAttribute('type', 'file');
  el.setAttribute('hidden', 'true');
  el.setAttribute('accept', 'image/png, image/jpeg, image/gif, image/tiff');

  document.body.append(el);

  const target = await new Promise<unknown>((resolve) => {
    el.oninput = ({ target }) => resolve(target);

    el.click();
  });

  el.remove();

  return target instanceof HTMLInputElement
    ? target.files?.item(0) ?? null
    : null;
}

@Component
export default class FormFileDrop extends Vue {
  @Prop(File) readonly value!: File | null;

  readonly icons = { faTimes, faImage };

  dragging = false;
  loading = false;
  dataUrl: string | null = null;

  @Watch('value')
  async onValueChanged(value: File | null) {
    if (!value) {
      this.dataUrl = null;

      return;
    }

    this.loading = true;

    this.dataUrl = await generateDataUrl(value);

    this.loading = false;
  }

  /**
   * ...
   */
  get showPreview() {
    return this.dataUrl || this.loading;
  }

  /**
   * ...
   */
  removeImage() {
    this.$emit('input', null);
  }

  /**
   * ...
   */
  async addFile() {
    const file = await addImageDialog();

    if (!file) return;

    this.$emit('input', file);
  }

  /**
   * ...
   */
  async onDrop({ dataTransfer }: DragEvent) {
    this.dragging = false;

    const file = dataTransfer?.files.item(0) ?? null;

    if (!file) return;

    console.log(file.type);

    if (!/^image\/(jpg|jpeg|png|gif|tiff)$/.test(file.type)) {
      return this.$alert.error('The file you tried to add was not an image.');
    }

    this.$emit('input', file);
  }
}
