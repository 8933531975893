export const LSO = {
  name: {
    common: 'Lesotho',
    official: 'Kingdom of Lesotho',
    native: {
      eng: { official: 'Kingdom of Lesotho', common: 'Lesotho' },
      sot: { official: 'Kingdom of Lesotho', common: 'Lesotho' },
    },
  },
  cities: ['Maseru', 'Roma'],
  tld: ['.ls'],
  cca2: 'LS',
  ccn3: '426',
  cca3: 'LSO',
  cioc: 'LES',
  currency: ['LSL', 'ZAR'],
  callingCode: ['266'],
  capital: 'Maseru',
  altSpellings: ['LS', 'Kingdom of Lesotho', 'Muso oa Lesotho'],
  region: 'Africa',
  subregion: 'Southern Africa',
  languages: { eng: 'English', sot: 'Sotho' },
  translations: {
    deu: { official: 'Königreich Lesotho', common: 'Lesotho' },
    fra: { official: 'Royaume du Lesotho', common: 'Lesotho' },
    hrv: { official: 'Kraljevina Lesoto', common: 'Lesoto' },
    ita: { official: 'Regno del Lesotho', common: 'Lesotho' },
    jpn: { official: 'レソト王国', common: 'レソト' },
    nld: { official: 'Koninkrijk Lesotho', common: 'Lesotho' },
    por: { official: 'Reino do Lesoto', common: 'Lesoto' },
    rus: { official: 'Королевство Лесото', common: 'Лесото' },
    spa: { official: 'Reino de Lesotho', common: 'Lesotho' },
    fin: { official: 'Lesothon kuningaskunta', common: 'Lesotho' },
  },
  latlng: [-29.5, 28.5],
  demonym: 'Mosotho',
  landlocked: true,
  borders: ['ZAF'],
  area: 30355,
  provinces: [
    'Berea',
    'Butha-Buthe',
    'Leribe',
    'Mafeteng',
    'Maseru',
    'Mohales Hoek',
    'Mokhotlong',
    "Qacha's Nek",
    'Quthing',
    'Thaba-Tseka',
  ],
  nativeName: 'Lesotho',
  timezones: ['UTC+02:00'],
} as const;
