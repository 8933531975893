var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "user-status-table-cell" }, [
    _setup.blocked
      ? _c(
          "span",
          {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover",
                modifiers: { hover: true },
              },
            ],
            staticClass: "blocked",
            attrs: {
              title:
                "User has been blocked from logging in for suspicious activity",
            },
          },
          [_vm._v("\n    BLOCKED\n  ")]
        )
      : _c(
          "span",
          {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover",
                modifiers: { hover: true },
              },
            ],
            staticClass: "enabled",
            attrs: { title: "User account is active and in good standing" },
          },
          [_vm._v("ACTIVE")]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }