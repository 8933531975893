var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.CardLoadable,
    { attrs: { loading: !!_vm.loading, "no-body": "" } },
    [
      _c("b-card-header", { staticClass: "bg-transparent" }, [
        _c("h6", { staticClass: "mb-0" }, [_vm._v("Course")]),
      ]),
      _vm._v(" "),
      !_vm.loading
        ? [
            !_vm.organization || !_vm.course
              ? _c("OverlayRenderIssue")
              : _vm._e(),
            _vm._v(" "),
            _c(
              "b-card-body",
              [
                _c("b-card-title", [_vm._v(_vm._s(_setup.displayCourseName))]),
                _vm._v(" "),
                _c("b-card-sub-title", { staticClass: "mb-2" }, [
                  _c("span", [_vm._v(_vm._s(_setup.displayDateRange))]),
                  _vm._v(" "),
                  _c(
                    "span",
                    [
                      _c("Icon", {
                        class: [_setup.status.className],
                        attrs: { icon: _setup.faInfoCircle },
                      }),
                      _vm._v(" "),
                      _c("span", { class: [_setup.status.className] }, [
                        _vm._v(_vm._s(_setup.status.label)),
                      ]),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "mb-1" }, [
                  _vm._v("Instructor: " + _vm._s(_setup.displayTeacherInfo)),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "mb-1" }, [
                  _vm._v(
                    "Instructor Assistant: " +
                      _vm._s(_setup.displayAssistantInfo)
                  ),
                ]),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }