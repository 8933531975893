export const TUN = {
  name: {
    common: 'Tunisia',
    official: 'Tunisian Republic',
    native: { ara: { official: 'الجمهورية التونسية', common: 'تونس' } },
  },
  cities: [
    'Tunis',
    'Le Bardo',
    'Sousse',
    'Gafsa',
    'Monastir',
    'Hammamet',
    'Sidi Bouzid',
    'Manouba',
    'Beja',
    'Rades',
    'Ariana',
    'Sfax',
  ],
  tld: ['.tn'],
  cca2: 'TN',
  ccn3: '788',
  cca3: 'TUN',
  cioc: 'TUN',
  currency: ['TND'],
  callingCode: ['216'],
  capital: 'Tunis',
  altSpellings: ['TN', 'Republic of Tunisia', 'al-Jumhūriyyah at-Tūnisiyyah'],
  region: 'Africa',
  subregion: 'Northern Africa',
  languages: { ara: 'Arabic' },
  translations: {
    deu: { official: 'Tunesische Republik', common: 'Tunesien' },
    fra: { official: 'République tunisienne', common: 'Tunisie' },
    hrv: { official: 'Tuniski Republika', common: 'Tunis' },
    ita: { official: 'Repubblica tunisina', common: 'Tunisia' },
    jpn: { official: 'チュニジア共和国', common: 'チュニジア' },
    nld: { official: 'Republiek Tunesië', common: 'Tunesië' },
    por: { official: 'República da Tunísia', common: 'Tunísia' },
    rus: { official: 'Тунисской Республики', common: 'Тунис' },
    spa: { official: 'República de Túnez', common: 'Túnez' },
    fin: { official: 'Tunisian tasavalta', common: 'Tunisia' },
  },
  latlng: [34, 9],
  demonym: 'Tunisian',
  landlocked: false,
  borders: ['DZA', 'LBY'],
  area: 163610,
  provinces: [
    'Ariana',
    'Beja',
    'Ben Arous',
    'Bizerte',
    'El Kef',
    'Gabes',
    'Gafsa',
    'Jendouba',
    'Kairouan',
    'Kasserine',
    'Kebili',
    'Mahdia',
    'Medenine',
    'Monastir',
    'Nabeul',
    'Sfax',
    'Sidi Bou Zid',
    'Siliana',
    'Sousse',
    'Tataouine',
    'Tozeur',
    'Tunis',
    'Zaghouan',
  ],
  nativeName: 'تونس',
  timezones: ['UTC+01:00'],
} as const;
