import * as models from '@models';
import { openModal } from '../open-modal';

/**
 * Open Create Api Key modal.
 *
 * @return Modal promise.
 */
export async function createApiKey() {
  const modalOptions = {
    title: 'CREATE API KEY',

    component: () => import('./CreateApiKey.vue'),
  };

  await openModal.safe(modalOptions);
}

/**
 * ...
 */
export interface ViewApiKeyModalOptions {
  apiKey: models.ApiKey;
}

/**
 * Open View Api Key modal.
 *
 * @return Modal promise.
 */
export async function viewApiKey(options: ViewApiKeyModalOptions) {
  const modalOptions = {
    title: 'VIEW API KEY',
    props: { ...options },
    component: () => import('./ViewApiKey.vue'),
  };

  await openModal.safe(modalOptions);
}
