/**
 * ...
 */
export interface ComputeDisplayNameOptions {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
}

/**
 * ...
 *
 * @param options ...
 * @return ...
 */
export function computeDisplayName(options: ComputeDisplayNameOptions) {
  if (!options.firstName && !options.lastName) return options.email ?? '--';

  const names: string[] = [];

  if (options.firstName) names.push(options.firstName);

  if (options.lastName) names.push(options.lastName);

  return names.join(' ');
}
