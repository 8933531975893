export const TZA = {
  name: {
    common: 'Tanzania',
    official: 'United Republic of Tanzania',
    native: {
      eng: { official: 'United Republic of Tanzania', common: 'Tanzania' },
      swa: { official: 'Jamhuri ya Muungano wa Tanzania', common: 'Tanzania' },
    },
  },
  cities: [
    'Dar es Salaam',
    'Arusha',
    'Mwanza',
    'Zanzibar',
    'Dodoma',
    'Morogoro',
    'Tanga',
    'Raha',
    'Bukoba',
    'Njombe',
  ],
  tld: ['.tz'],
  cca2: 'TZ',
  ccn3: '834',
  cca3: 'TZA',
  cioc: 'TAN',
  currency: ['TZS'],
  callingCode: ['255'],
  capital: 'Dodoma',
  altSpellings: [
    'TZ',
    'Tanzania, United Republic of',
    'United Republic of Tanzania',
    'Jamhuri ya Muungano wa Tanzania',
  ],
  region: 'Africa',
  subregion: 'Eastern Africa',
  languages: { eng: 'English', swa: 'Swahili' },
  translations: {
    deu: { official: 'Vereinigte Republik Tansania', common: 'Tansania' },
    fra: { official: 'République -Unie de Tanzanie', common: 'Tanzanie' },
    hrv: { official: 'Ujedinjena Republika Tanzanija', common: 'Tanzanija' },
    ita: { official: 'Repubblica Unita di Tanzania', common: 'Tanzania' },
    jpn: { official: 'タンザニア連合共和国', common: 'タンザニア' },
    nld: { official: 'Verenigde Republiek Tanzania', common: 'Tanzania' },
    por: { official: 'República Unida da Tanzânia', common: 'Tanzânia' },
    rus: { official: 'Объединенная Республика Танзания', common: 'Танзания' },
    spa: { official: 'República Unida de Tanzania', common: 'Tanzania' },
    fin: { official: 'Tansanian yhdistynyt tasavalta', common: 'Tansania' },
  },
  latlng: [-6, 35],
  demonym: 'Tanzanian',
  landlocked: false,
  borders: ['BDI', 'COD', 'KEN', 'MWI', 'MOZ', 'RWA', 'UGA', 'ZMB'],
  area: 945087,
  provinces: [
    'Arusha',
    'Dar es Salaam',
    'Dodoma',
    'Iringa',
    'Kagera',
    'Kigoma',
    'Kilimanjaro',
    'Lindi',
    'Mara',
    'Mbeya',
    'Morogoro',
    'Mtwara',
    'Mwanza',
    'Pemba North',
    'Pemba South',
    'Pwani',
    'Rukwa',
    'Ruvuma',
    'Shinyanga',
    'Singida',
    'Tabora',
    'Tanga',
    'Zanzibar Central/South',
    'Zanzibar North',
    'Zanzibar Urban/West',
  ],
  nativeName: 'Tanzania',
  timezones: ['UTC+03:00'],
} as const;
