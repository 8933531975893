/**
 * Ensures the output is always an array. If the input is not an array, it will
 * return one with the value as its sole element.
 *
 * @param value The value to ensure.
 * @return An `Array`.
 */
export function ensureArray<T>(value: T): T extends unknown[] ? T : T[];
export function ensureArray<T>(value: T) {
  return value === undefined ? [] : !Array.isArray(value) ? [value] : value;
}
