import * as regex from './regex';
import * as types from './types';
import * as values from './values';

declare module 'vue/types/vue' {
  interface Vue {
    /**
     * Service for getting information about current device information.
     */
    $deviceInfo: typeof deviceInfo;
  }
}

const raw = {
  os: setRawValues(values.PLATFORM, regex.OS_RE),
  browser: setRawValues(values.BROWSER, regex.BROWSERS_RE),
  device: setRawValues(values.PLATFORM, regex.DEVICES_RE),
  osVersion: setRawValues(values.OS, regex.osVersionS_RE),
};

/** Current browser name. */
export const browser = matchType(values.BROWSER.UNKNOWN, raw.browser, [
  values.BROWSER.CHROME,
  values.BROWSER.FIREFOX,
  values.BROWSER.SAFARI,
  values.BROWSER.OPERA,
  values.BROWSER.IE,
  values.BROWSER.MS_EDGE,
  values.BROWSER.CORDOVA,
  values.BROWSER.FB_MESSENGER,
]);

/** Current device name. */
export const device = matchType(values.DEVICE.UNKNOWN, raw.device, [
  values.DEVICE.ANDROID,
  values.DEVICE.I_PAD,
  values.DEVICE.IPHONE,
  values.DEVICE.I_POD,
  values.DEVICE.BLACKBERRY,
  values.DEVICE.FIREFOX_OS,
  values.DEVICE.CHROME_BOOK,
  values.DEVICE.WINDOWS_PHONE,
  values.DEVICE.PS4,
  values.DEVICE.CHROMECAST,
  values.DEVICE.APPLE_TV,
  values.DEVICE.GOOGLE_TV,
  values.DEVICE.VITA,
]);

/** Current OS name. */
export const os = matchType(values.PLATFORM.UNKNOWN, raw.os, [
  values.PLATFORM.WINDOWS,
  values.PLATFORM.IOS,
  values.PLATFORM.MAC,
  values.PLATFORM.ANDROID,
  values.PLATFORM.LINUX,
  values.PLATFORM.UNIX,
  values.PLATFORM.FIREFOX_OS,
  values.PLATFORM.CHROME_OS,
  values.PLATFORM.WINDOWS_PHONE,
]);

/** Current OS version name. */
export const osVersion = matchType(values.OS.UNKNOWN, raw.osVersion, [
  values.OS.WINDOWS_3_11,
  values.OS.WINDOWS_95,
  values.OS.WINDOWS_ME,
  values.OS.WINDOWS_98,
  values.OS.WINDOWS_CE,
  values.OS.WINDOWS_2000,
  values.OS.WINDOWS_XP,
  values.OS.WINDOWS_SERVER_2003,
  values.OS.WINDOWS_VISTA,
  values.OS.WINDOWS_7,
  values.OS.WINDOWS_8_1,
  values.OS.WINDOWS_8,
  values.OS.WINDOWS_10,
  values.OS.WINDOWS_PHONE_7_5,
  values.OS.WINDOWS_PHONE_8_1,
  values.OS.WINDOWS_PHONE_10,
  values.OS.WINDOWS_NT_4_0,
  values.OS.MACOSX,
  values.OS.MACOSX_3,
  values.OS.MACOSX_4,
  values.OS.MACOSX_5,
  values.OS.MACOSX_6,
  values.OS.MACOSX_7,
  values.OS.MACOSX_8,
  values.OS.MACOSX_9,
  values.OS.MACOSX_10,
  values.OS.MACOSX_11,
  values.OS.MACOSX_12,
  values.OS.MACOSX_13,
  values.OS.MACOSX_14,
  values.OS.MACOSX_15,
]);

//#region Browser Version

const BROWSER_VERSIONS_RE_MAP = {
  chrome: [/\bChrome\/([\d.]+)\b/, /\bCriOS\/([\d.]+)\b/],
  firefox: [/\bFirefox\/([\d.]+)\b/, /\bFxiOS\/([\d.]+)\b/],
  safari: /\bVersion\/([\d.]+)\b/,
  opera: [/\bVersion\/([\d.]+)\b/, /\bOPR\/([\d.]+)\b/],
  ie: [/\bMSIE ([\d.]+\w?)\b/, /\brv:([\d.]+\w?)\b/],
  cordova: /\bCordova\/([\d.]+)\b/,
  'ms-edge': /\bEdge\/([\d.]+)\b/,
} as Partial<Record<types.Browser, RegExp | [RegExp, RegExp]>>;

function getBrowserVersion() {
  let value;

  if (browser in BROWSER_VERSIONS_RE_MAP) {
    const regex = BROWSER_VERSIONS_RE_MAP[browser];

    if (regex) {
      value = execRe(window.navigator.userAgent, regex)?.[1];
    }
  }

  return value ?? '0';
}

/** ... */
export const browserVersion = getBrowserVersion();

//#endregion Browser Version

/**
 * Returns `true` if the site is being displayed on a mobile device, otherwise
 * `false`.
 */
export function isMobile() {
  return [
    values.DEVICE.ANDROID,
    values.DEVICE.I_PAD,
    values.DEVICE.IPHONE,
    values.DEVICE.I_POD,
    values.DEVICE.BLACKBERRY,
    values.DEVICE.FIREFOX_OS,
    values.DEVICE.WINDOWS_PHONE,
    values.DEVICE.VITA,
  ].some((item) => item === device);
}

/**
 * Returns `true` if the site is being displayed on a tablet, otherwise `false`.
 */
export function isTablet() {
  return [values.DEVICE.I_PAD, values.DEVICE.FIREFOX_OS].some(
    (item) => item === device,
  );
}

/**
 * Returns `true` if the site is being displayed on a desktop computer,
 * otherwise `false`.
 */
export function isDesktop() {
  return [
    values.DEVICE.PS4,
    values.DEVICE.CHROME_BOOK,
    values.DEVICE.UNKNOWN,
  ].some((item) => item === device);
}

export const deviceInfo = {
  raw,
  os,
  browser,
  device,
  osVersion,
  browserVersion,
  isMobile,
  isTablet,
  isDesktop,
};

export default deviceInfo;

//#region Helper Functions

/**
 * ...
 *
 * @param str ...
 * @param re ...
 */
function testRe(
  str: string,
  re: Nullable<string | regex.ConditionalExpression>,
): boolean {
  if (!re) return false;

  if (typeof re === 'string') {
    re = new RegExp(re);
  }

  if (re instanceof RegExp) {
    return re.test(str);
  }

  if ('and' in re && Array.isArray(re.and)) {
    return re.and.every((item) => testRe(str, item));
  }

  if ('or' in re && Array.isArray(re.or)) {
    return re.or.some((item) => testRe(str, item));
  }

  if ('not' in re) {
    return !testRe(str, re.not);
  }

  return false;
}

/**
 * ...
 *
 * @param str ...
 * @param re ...
 */
function execRe(
  str: string,
  re: string | RegExp | [RegExp, RegExp],
): RegExpExecArray | null {
  if (typeof re === 'string') {
    re = new RegExp(re);
  }

  if (re instanceof RegExp) {
    return re.exec(str);
  }

  if (Array.isArray(re)) {
    return re.reduce(
      (res, item) => (res ? res : execRe(str, item)),
      null as RegExpExecArray | null,
    );
  }

  return null;
}

/**
 * ...
 *
 * @param types ...
 * @param tests ...
 */
function setRawValues(
  types: Record<string, string>,
  tests: Record<string, string | regex.ConditionalExpression>,
) {
  return Object.keys(types).reduce<Record<string, boolean>>((obj, item) => {
    obj[types[item]] = testRe(window.navigator.userAgent, tests[item]);

    return obj;
  }, {});
}

/**
 * ...
 *
 * @param initial ...
 * @param values ...
 * @param tests ...
 */
function matchType<I extends string, T extends string[]>(
  initial: I,
  values: Record<string, boolean>,
  tests: T,
) {
  return tests.reduce(
    (previous, current) =>
      previous === initial && values[current] ? current : previous,
    initial,
  ) as I | T[number];
}

//#endregion Helper Functions
