var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "form-field-phone" },
    [
      _c(
        _setup.FormPhone,
        _vm._g(
          _vm._b(
            {
              model: {
                value: _vm.field.$model,
                callback: function ($$v) {
                  _vm.$set(_vm.field, "$model", $$v)
                },
                expression: "field.$model",
              },
            },
            "FormPhone",
            { ..._vm.$attrs, state: _setup.state },
            false
          ),
          _vm.$listeners
        )
      ),
      _vm._v(" "),
      _setup.showErrorMessage
        ? _c("b-form-invalid-feedback", { attrs: { state: false } }, [
            _vm._v("\n    " + _vm._s(_setup.errorMessage) + "\n  "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }