export const ALB = {
  name: {
    common: 'Albania',
    official: 'Republic of Albania',
    native: {
      sqi: { official: 'Republika e Shqipërisë', common: 'Shqipëria' },
    },
  },
  cities: [
    'Tirana',
    'Pogradec',
    'Petran',
    'Ura Vajgurore',
    'Shkoder',
    'Elbasan',
    'DurrÃ«s',
    'VlorÃ«',
  ],
  tld: ['.al'],
  cca2: 'AL',
  ccn3: '008',
  cca3: 'ALB',
  cioc: 'ALB',
  currency: ['ALL'],
  callingCode: ['355'],
  capital: 'Tirana',
  altSpellings: ['AL', 'Shqipëri', 'Shqipëria', 'Shqipnia'],
  region: 'Europe',
  subregion: 'Southern Europe',
  languages: { sqi: 'Albanian' },
  translations: {
    cym: { official: 'Republic of Albania', common: 'Albania' },
    deu: { official: 'Republik Albanien', common: 'Albanien' },
    fra: { official: "République d'Albanie", common: 'Albanie' },
    hrv: { official: 'Republika Albanija', common: 'Albanija' },
    ita: { official: "Repubblica d'Albania", common: 'Albania' },
    jpn: { official: 'アルバニア共和国', common: 'アルバニア' },
    nld: { official: 'Republiek Albanië', common: 'Albanië' },
    por: { official: 'República da Albânia', common: 'Albânia' },
    rus: { official: 'Республика Албания', common: 'Албания' },
    spa: { official: 'República de Albania', common: 'Albania' },
    fin: { official: 'Albanian tasavalta', common: 'Albania' },
  },
  latlng: [41, 20],
  demonym: 'Albanian',
  landlocked: false,
  borders: ['MNE', 'GRC', 'MKD', 'KOS'],
  area: 28748,
  provinces: [
    'Berat',
    'Bulqize',
    'Delvine',
    'Devoll (Bilisht)',
    'Diber (Peshkopi)',
    'Durres',
    'Elbasan',
    'Fier',
    'Gjirokaster',
    'Gramsh',
    'Has (Krume)',
    'Kavaje',
    'Kolonje (Erseke)',
    'Korce',
    'Kruje',
    'Kucove',
    'Kukes',
    'Kurbin',
    'Lezhe',
    'Librazhd',
    'Lushnje',
    'Malesi e Madhe (Koplik)',
    'Mallakaster (Ballsh)',
    'Mat (Burrel)',
    'Mirdite (Rreshen)',
    'Peqin',
    'Permet',
    'Pogradec',
    'Puke',
    'Sarande',
    'Shkoder',
    'Skrapar (Corovode)',
    'Tepelene',
    'Tirane (Tirana)',
    'Tirane (Tirana)',
    'Tropoje (Bajram Curri)',
    'Vlore',
  ],
  nativeName: 'Shqipëria',
  timezones: ['UTC+01:00'],
} as const;
