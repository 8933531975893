var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "heatmap-table" },
    [
      _c("b-table", {
        attrs: {
          items: _setup.updatedItems,
          fields: _setup.fields,
          "tbody-tr-class": "rowClass",
          borderless: "",
          fixed: "",
        },
        scopedSlots: _vm._u([
          {
            key: "cell(January)",
            fn: function (data) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "cell-class",
                    style: _setup.computeColor(data),
                    attrs: { id: data.value.id },
                  },
                  [_vm._v("\n        " + _vm._s(data.value.total) + "\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "b-tooltip",
                  { ref: "tooltip", attrs: { target: data.value.id } },
                  [
                    _c("div", [
                      _c("p", [
                        _vm._v(
                          "\n            Approved: " +
                            _vm._s(data.value.approved) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            Denied: " +
                            _vm._s(data.value.denied) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            Revoked: " +
                            _vm._s(data.value.revoked) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            Fee: $" +
                            _vm._s(data.value.reviewerFee) +
                            "\n          "
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]
            },
          },
          {
            key: "cell(February)",
            fn: function (data) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "cell-class",
                    style: _setup.computeColor(data),
                    attrs: { id: data.value.id },
                  },
                  [_vm._v("\n        " + _vm._s(data.value.total) + "\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "b-tooltip",
                  { ref: "tooltip", attrs: { target: data.value.id } },
                  [
                    _c("div", [
                      _c("p", [
                        _vm._v(
                          "\n            Approved: " +
                            _vm._s(data.value.approved) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            Denied: " +
                            _vm._s(data.value.denied) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            Revoked: " +
                            _vm._s(data.value.revoked) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            Fee: $" +
                            _vm._s(data.value.reviewerFee) +
                            "\n          "
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]
            },
          },
          {
            key: "cell(March)",
            fn: function (data) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "cell-class",
                    style: _setup.computeColor(data),
                    attrs: { id: data.value.id },
                  },
                  [_vm._v("\n        " + _vm._s(data.value.total) + "\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "b-tooltip",
                  { ref: "tooltip", attrs: { target: data.value.id } },
                  [
                    _c("div", [
                      _c("p", [
                        _vm._v(
                          "\n            Approved: " +
                            _vm._s(data.value.approved) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            Denied: " +
                            _vm._s(data.value.denied) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            Revoked: " +
                            _vm._s(data.value.revoked) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            Fee: $" +
                            _vm._s(data.value.reviewerFee) +
                            "\n          "
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]
            },
          },
          {
            key: "cell(April)",
            fn: function (data) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "cell-class",
                    style: _setup.computeColor(data),
                    attrs: { id: data.value.id },
                  },
                  [_vm._v("\n        " + _vm._s(data.value.total) + "\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "b-tooltip",
                  { ref: "tooltip", attrs: { target: data.value.id } },
                  [
                    _c("div", [
                      _c("p", [
                        _vm._v(
                          "\n            Approved: " +
                            _vm._s(data.value.approved) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            Denied: " +
                            _vm._s(data.value.denied) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            Revoked: " +
                            _vm._s(data.value.revoked) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            Fee: $" +
                            _vm._s(data.value.reviewerFee) +
                            "\n          "
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]
            },
          },
          {
            key: "cell(May)",
            fn: function (data) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "cell-class",
                    style: _setup.computeColor(data),
                    attrs: { id: data.value.id },
                  },
                  [_vm._v("\n        " + _vm._s(data.value.total) + "\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "b-tooltip",
                  { ref: "tooltip", attrs: { target: data.value.id } },
                  [
                    _c("div", [
                      _c("p", [
                        _vm._v(
                          "\n            Approved: " +
                            _vm._s(data.value.approved) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            Denied: " +
                            _vm._s(data.value.denied) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            Revoked: " +
                            _vm._s(data.value.revoked) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            Fee: $" +
                            _vm._s(data.value.reviewerFee) +
                            "\n          "
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]
            },
          },
          {
            key: "cell(June)",
            fn: function (data) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "cell-class",
                    style: _setup.computeColor(data),
                    attrs: { id: data.value.id },
                  },
                  [_vm._v("\n        " + _vm._s(data.value.total) + "\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "b-tooltip",
                  { ref: "tooltip", attrs: { target: data.value.id } },
                  [
                    _c("div", [
                      _c("p", [
                        _vm._v(
                          "\n            Approved: " +
                            _vm._s(data.value.approved) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            Denied: " +
                            _vm._s(data.value.denied) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            Revoked: " + _vm._s(data.value.revoked)
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            Fee: $" +
                            _vm._s(data.value.reviewerFee) +
                            "\n          "
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]
            },
          },
          {
            key: "cell(July)",
            fn: function (data) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "cell-class",
                    style: _setup.computeColor(data),
                    attrs: { id: data.value.id },
                  },
                  [_vm._v("\n        " + _vm._s(data.value.total) + "\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "b-tooltip",
                  { ref: "tooltip", attrs: { target: data.value.id } },
                  [
                    _c("div", [
                      _c("p", [
                        _vm._v(
                          "\n            Approved: " +
                            _vm._s(data.value.approved) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            Denied: " +
                            _vm._s(data.value.denied) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            Revoked: " +
                            _vm._s(data.value.revoked) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            Fee: $" +
                            _vm._s(data.value.reviewerFee) +
                            "\n          "
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]
            },
          },
          {
            key: "cell(August)",
            fn: function (data) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "cell-class",
                    style: _setup.computeColor(data),
                    attrs: { id: data.value.id },
                  },
                  [_vm._v("\n        " + _vm._s(data.value.total) + "\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "b-tooltip",
                  { ref: "tooltip", attrs: { target: data.value.id } },
                  [
                    _c("div", [
                      _c("p", [
                        _vm._v(
                          "\n            Approved: " +
                            _vm._s(data.value.approved) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            Denied: " +
                            _vm._s(data.value.denied) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            Revoked: " + _vm._s(data.value.revoked)
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            Fee: $" +
                            _vm._s(data.value.reviewerFee) +
                            "\n          "
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]
            },
          },
          {
            key: "cell(September)",
            fn: function (data) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "cell-class",
                    style: _setup.computeColor(data),
                    attrs: { id: data.value.id },
                  },
                  [_vm._v("\n        " + _vm._s(data.value.total) + "\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "b-tooltip",
                  { ref: "tooltip", attrs: { target: data.value.id } },
                  [
                    _c("div", [
                      _c("p", [
                        _vm._v(
                          "\n            Approved: " +
                            _vm._s(data.value.approved) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            Denied: " +
                            _vm._s(data.value.denied) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            Revoked: " +
                            _vm._s(data.value.revoked) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            Fee: $" +
                            _vm._s(data.value.reviewerFee) +
                            "\n          "
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]
            },
          },
          {
            key: "cell(October)",
            fn: function (data) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "cell-class",
                    style: _setup.computeColor(data),
                    attrs: { id: data.value.id },
                  },
                  [_vm._v("\n        " + _vm._s(data.value.total) + "\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "b-tooltip",
                  { ref: "tooltip", attrs: { target: data.value.id } },
                  [
                    _c("div", [
                      _c("p", [
                        _vm._v(
                          "\n            Approved: " +
                            _vm._s(data.value.approved) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            Denied: " +
                            _vm._s(data.value.denied) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            Revoked: " +
                            _vm._s(data.value.revoked) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            Fee: $" +
                            _vm._s(data.value.reviewerFee) +
                            "\n          "
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]
            },
          },
          {
            key: "cell(November)",
            fn: function (data) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "cell-class",
                    style: _setup.computeColor(data),
                    attrs: { id: data.value.id },
                  },
                  [_vm._v("\n        " + _vm._s(data.value.total) + "\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "b-tooltip",
                  { ref: "tooltip", attrs: { target: data.value.id } },
                  [
                    _c("div", [
                      _c("p", [
                        _vm._v(
                          "\n            Approved: " +
                            _vm._s(data.value.approved) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            Denied: " +
                            _vm._s(data.value.denied) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            Revoked: " +
                            _vm._s(data.value.revoked) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            Fee: " +
                            _vm._s(data.value.reviewerFee) +
                            "\n          "
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]
            },
          },
          {
            key: "cell(December)",
            fn: function (data) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "cell-class",
                    style: _setup.computeColor(data),
                    attrs: { id: data.value.id },
                  },
                  [_vm._v("\n        " + _vm._s(data.value.total) + "\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "b-tooltip",
                  { ref: "tooltip", attrs: { target: data.value.id } },
                  [
                    _c("div", { staticClass: "heatmap-tooltip" }, [
                      _c("p", [
                        _vm._v(
                          "\n            Approved: " +
                            _vm._s(data.value.approved) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            Denied: " +
                            _vm._s(data.value.denied) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            Revoked: " +
                            _vm._s(data.value.revoked) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            Fee: " +
                            _vm._s(data.value.reviewerFee) +
                            "\n          "
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }