export const FRO = {
  name: {
    common: 'Faroe Islands',
    official: 'Faroe Islands',
    native: {
      dan: { official: 'Færøerne', common: 'Færøerne' },
      fao: { official: 'Føroyar', common: 'Føroyar' },
    },
  },
  tld: ['.fo'],
  cca2: 'FO',
  ccn3: '234',
  cca3: 'FRO',
  cioc: '',
  currency: ['DKK'],
  callingCode: ['298'],
  capital: 'Tórshavn',
  altSpellings: ['FO', 'Føroyar', 'Færøerne'],
  region: 'Europe',
  subregion: 'Northern Europe',
  languages: { dan: 'Danish', fao: 'Faroese' },
  translations: {
    deu: { official: 'Färöer', common: 'Färöer-Inseln' },
    fra: { official: 'Îles Féroé', common: 'Îles Féroé' },
    hrv: { official: 'Farski Otoci', common: 'Farski Otoci' },
    ita: { official: 'Isole Faroe', common: 'Isole Far Oer' },
    jpn: { official: 'フェロー諸島', common: 'フェロー諸島' },
    nld: { official: 'Faeröer', common: 'Faeröer' },
    por: { official: 'Ilhas Faroe', common: 'Ilhas Faroé' },
    rus: { official: 'Фарерские острова', common: 'Фарерские острова' },
    spa: { official: 'Islas Feroe', common: 'Islas Faroe' },
    fin: { official: 'Färsaaret', common: 'Färsaaret' },
  },
  latlng: [62, -7],
  demonym: 'Faroese',
  landlocked: false,
  borders: [],
  area: 1393,
} as const;
