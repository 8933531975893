var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "display-address",
      style: { flexDirection: _vm.singleLine ? "row" : "column" },
    },
    [
      _vm.value.first || _vm.value.last
        ? _c("span", [
            _vm.value.first
              ? _c("span", [_vm._v(_vm._s(_vm.value.first))])
              : _vm._e(),
            _vm._v(" "),
            _vm.value.last
              ? _c("span", [_vm._v(_vm._s(_vm.value.last))])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.value.address1))]),
      _vm._v(" "),
      _vm.value.address2
        ? _c("span", [_vm._v(_vm._s(_vm.value.address2))])
        : _vm._e(),
      _vm._v(" "),
      _vm.value.address3
        ? _c("span", [_vm._v(_vm._s(_vm.value.address3))])
        : _vm._e(),
      _vm._v(" "),
      _c("span", [
        _vm.value.stateProvince
          ? _c("span", [_vm._v(_vm._s(_vm.value.stateProvince))])
          : _vm._e(),
        _vm._v(" "),
        _vm.value.city
          ? _c("span", [_vm._v(_vm._s(_vm.value.city))])
          : _vm._e(),
        _vm._v(" "),
        _vm.value.postalCode
          ? _c("span", [_vm._v(_vm._s(_vm.value.postalCode))])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.value.country))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }