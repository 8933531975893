import imageAmex from '@assets/images/credit-cards/amex.svg';
import imageDiners from '@assets/images/credit-cards/diners.svg';
import imageDiscover from '@assets/images/credit-cards/discover.svg';
import imageJcb from '@assets/images/credit-cards/jcb.svg';
import imageMaestro from '@assets/images/credit-cards/maestro.svg';
import imageMasterCard from '@assets/images/credit-cards/mastercard.svg';
import imageUnionPay from '@assets/images/credit-cards/unionpay.svg';
import imageVisa from '@assets/images/credit-cards/visa.svg';

/**
 * Interface for basic credit card information.
 */
export interface CreditCardProfile {
  id: string;
  name: string;
  image: string;
}

const amex: CreditCardProfile = {
  id: 'amex',
  name: 'American Express',
  image: imageAmex,
};

const diners: CreditCardProfile = {
  id: 'diners',
  name: 'Diners',
  image: imageDiners,
};

const discover: CreditCardProfile = {
  id: 'discover',
  name: 'Discover',
  image: imageDiscover,
};

const jcb: CreditCardProfile = {
  id: 'jcb',
  name: 'Jcb',
  image: imageJcb,
};

const maestro: CreditCardProfile = {
  id: 'maestro',
  name: 'Maestro',
  image: imageMaestro,
};

const mastercard: CreditCardProfile = {
  id: 'mastercard',
  name: 'MasterCard',
  image: imageMasterCard,
};

const unionpay: CreditCardProfile = {
  id: 'unionpay',
  name: 'UnionPay',
  image: imageUnionPay,
};

const visa: CreditCardProfile = {
  id: 'visa',
  name: 'Visa',
  image: imageVisa,
};

export const creditCards = {
  amex,
  diners,
  discover,
  jcb,
  maestro,
  mastercard,
  unionpay,
  visa,
};
