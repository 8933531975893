import { stringify } from './stringify';
import { isString, isObject } from './type-guards';

/**
 * ...
 */
// eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style
interface ErrorLike extends Error {
  [key: string]: unknown;
}

/**
 * Error class representing an ensured error.
 */
export class EnsuredError extends Error implements ErrorLike {
  [key: string]: unknown;

  constructor(value: unknown) {
    super();

    const props: Record<string, unknown> = {};

    if (isString(value)) {
      props['message'] = value;
    } else if (!isObject(value)) {
      props['value'] = value;
    } else {
      Object.assign(props, value ?? { value });
    }

    for (const propKey in props) {
      const propValue = props[propKey];

      this[propKey] = propKey === 'message' ? stringify(propValue) : propValue;
    }
  }
}

/**
 * Ensures the output will always be an `Error`. If an error is passed in, it
 * will simply be returned. Otherwise, it will attempt to create an error using
 * the provided value.
 *
 * @param value Value to process.
 * @returns An `Error` object.
 */
export function ensureError(value: unknown) {
  return (
    value instanceof Error ? value : new EnsuredError(value)
  ) as ErrorLike;
}

export namespace ensureError {
  export type Error = ReturnType<typeof ensureError>;
}
