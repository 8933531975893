/** ... */
const FLOAT_REGEXP = '[-+]?[0-9]*.?[0-9]+';

/** ... */
const TYPES = [
  {
    name: 'px',
    regexp: new RegExp(`^${FLOAT_REGEXP}px$`),
  },
  {
    name: '%',
    regexp: new RegExp(`^${FLOAT_REGEXP}%$`),
  },
  /** If no suffix specified, assigning "px". */
  {
    name: 'px',
    regexp: new RegExp(`^${FLOAT_REGEXP}$`),
  },
] as const;

/**
 * ...
 */
export interface ParseResult {
  type: string;
  value: number;
}

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
function getType(value: string) {
  if (value === 'auto') return { type: value, value: 0 };

  for (const type of TYPES) {
    if (type.regexp.test(value)) {
      return { type: type.name, value: parseFloat(value) };
    }
  }

  return { type: '', value: parseFloat(value) } as ParseResult;
}

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
export function parse(value: unknown) {
  switch (typeof value) {
    case 'number':
      return { type: 'px', value } as ParseResult;
    case 'string':
      return getType(value);
    default:
      return { type: '', value } as ParseResult;
  }
}
