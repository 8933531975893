import { dateFilter } from '@filters/date';

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
export function formatReportDistance(value: number) {
  const meters = value.toFixed(2);
  const feet = (value * 3.280839895).toFixed(2);

  return `${meters}m/${feet}ft`;
}

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
export function formatReportSpeed(value: number) {
  const kph = value.toFixed(2);
  const mph = (value * 0.62137119223733).toFixed(2);

  return `${kph}kph/${mph}mph`;
}

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
export function formatReportDate(value: string) {
  return dateFilter(new Date(value), 'MM/dd/yyyy hh:mm:ss').toString();
}
