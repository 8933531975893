
import { computed, defineComponent, type PropType } from 'vue';
// import { BFormSelect } from 'bootstrap-vue';

import FormSelect, {
  type Props as FormSelectProps,
} from '@components/Form/FormSelect.vue';

import { useFormField, FormFieldProps, PROP_OPTIONS } from './form-field';

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    FormFieldSelect: ComponentWithProps<Props>;
  }
}

/** ... */
interface OptionBase {
  value: unknown;
  disabled?: boolean;
}

/** ... */
export type Option = OptionBase & ({ text: string } | { label: string });

/**
 * `FormFieldSelect` component properties.
 */
export interface Props extends FormSelectProps, FormFieldProps {
  options?: Option[];
}

export default defineComponent({
  name: 'FormFieldSelect',
  props: {
    ...PROP_OPTIONS,
    options: {
      type: Array as PropType<Option[]>,
      default: () => [],
    },
  },
  setup(props) {
    /** ... */
    const formField = useFormField(props);

    /** ... */
    const selectOptions = computed(() => {
      // const options: Option[] = [{ text: '--', value: null }];

      const options: Option[] = [];

      let hasNullOption = false;

      for (const option of props.options) {
        const text = 'text' in option ? option.text : option.label;

        if (option.value === null) {
          hasNullOption = true;
        }

        options.push({
          text,
          value: option.value,
          disabled: !!option.disabled,
        });
      }

      if (!hasNullOption) {
        options.unshift({ text: '--', value: null });
      }

      return options;
    });

    return { ...formField, selectOptions };
  },
});
