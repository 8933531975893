import { render, staticRenderFns } from "./ModalBackdrop.vue?vue&type=template&id=0f439f19&scoped=true"
import script from "./ModalBackdrop.vue?vue&type=script&lang=ts&setup=true"
export * from "./ModalBackdrop.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./ModalBackdrop.vue?vue&type=style&index=0&id=0f439f19&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f439f19",
  null
  
)

export default component.exports