export const KWT = {
  name: {
    common: 'Kuwait',
    official: 'State of Kuwait',
    native: { ara: { official: 'دولة الكويت', common: 'الكويت' } },
  },
  cities: [
    'Kuwait City',
    'Bayan',
    'Al Farwaniyah',
    'Hawalli',
    'Ar Rawdah',
    'As Salimiyah',
    'Salwa',
    'Al Shamiya',
    'Kayfan',
    'Al Ahmadi',
    'Abraq Khaytan',
    'Ash Shuwaykh',
    'Janub as Surrah',
    'Ad Dasmah',
    'Ad Dawhah',
    'Ash Shu`aybah',
  ],
  tld: ['.kw'],
  cca2: 'KW',
  ccn3: '414',
  cca3: 'KWT',
  cioc: 'KUW',
  currency: ['KWD'],
  callingCode: ['965'],
  capital: 'Kuwait City',
  altSpellings: ['KW', 'State of Kuwait', 'Dawlat al-Kuwait'],
  region: 'Asia',
  subregion: 'Western Asia',
  languages: { ara: 'Arabic' },
  translations: {
    deu: { official: 'Staat Kuwait', common: 'Kuwait' },
    fra: { official: 'État du Koweït', common: 'Koweït' },
    hrv: { official: 'Država Kuvajt', common: 'Kuvajt' },
    ita: { official: 'Stato del Kuwait', common: 'Kuwait' },
    jpn: { official: 'クウェート国', common: 'クウェート' },
    nld: { official: 'Staat Koeweit', common: 'Koeweit' },
    por: { official: 'Estado do Kuwait', common: 'Kuwait' },
    rus: { official: 'Государство Кувейт', common: 'Кувейт' },
    spa: { official: 'Estado de Kuwait', common: 'Kuwait' },
    fin: { official: 'Kuwaitin valtio', common: 'Kuwait' },
  },
  latlng: [29.5, 45.75],
  demonym: 'Kuwaiti',
  landlocked: false,
  borders: ['IRQ', 'SAU'],
  area: 17818,
  provinces: [
    "Al 'Asimah",
    'Al Ahmadi',
    'Al Farwaniyah',
    "Al Jahra'",
    'Hawalli',
  ],
  nativeName: 'الكويت',
  timezones: ['UTC+03:00'],
} as const;
