/**
 * ...
 *
 * @param count
 * @param total
 * @return
 */
function tally(count: number, total: number) {
  if (total < 0 || total !== Math.trunc(total)) {
    throw new Error(
      '[aggregateReports.tally] value of "total" must be a positive integer.',
    );
  }

  if (count < 0 || count > total || count !== Math.trunc(count)) {
    throw new Error(
      '[aggregateReports.tally] value of "count" must be a integer between 0 and the provided total.',
    );
  }

  const fraction = total === 0 ? null : count / total;

  return { count, fraction } as aggregateReports.Tally;
}

/**
 * ...
 *
 * @param reports ...
 * @return
 */
export function aggregateReports(reports: aggregateReports.ReportInfo[]) {
  let reportCount = 0;
  let totalFlightTime = 0;

  let highScoreTally = 0;
  let violationTally = 0;
  let crashTally = 0;
  let scoreOfZeroTally = 0;

  const now = Date.now();

  for (const report of reports) {
    totalFlightTime += report.totalTime;

    // Only reports for the last 90 days.
    if (now - new Date(report.createdAt).getTime() > 7_776_000_000) {
      continue;
    }

    reportCount++;

    const reportPercentage = report.score / report.possibleScore;
    // high score mean 80% or greater

    if (report.score === 0) {
      scoreOfZeroTally++;
    } else if (reportPercentage >= 0.8) {
      highScoreTally++;
    }

    if (report.altitudeViolations > 0 || report.lostLineOfSightViolations > 0) {
      violationTally++;
    }

    if (report.crashCount > 0) {
      crashTally++;
    }
  }

  // ...
  const adjustedReportCount = reportCount - scoreOfZeroTally;

  // ...
  const reportsWithHighScore = tally(highScoreTally, adjustedReportCount);
  const reportsWithViolations = tally(violationTally, reportCount);
  const reportsWithCrashes = tally(crashTally, reportCount);

  return {
    reportCount,
    totalFlightTime,
    reportsWithHighScore,
    reportsWithViolations,
    reportsWithCrashes,
  } as aggregateReports.Results;
}

export namespace aggregateReports {
  /**
   * ...
   */
  export interface ReportInfo {
    createdAt: string;
    totalTime: number;
    score: number;
    possibleScore: number;
    altitudeViolations: number;
    lostLineOfSightViolations: number;
    crashCount: number;
  }

  /**
   * ...
   */
  export interface Tally {
    /** ... */
    count: number;
    /** ... */
    fraction: number | null;
  }

  /**
   * ...
   */
  export interface Results {
    /** ... */
    reportCount: number;
    /** ... */
    totalFlightTime: number;
    /** ... */
    reportsWithHighScore: Tally;
    /** ... */
    reportsWithViolations: Tally;
    /** ... */
    reportsWithCrashes: Tally;
  }
}
