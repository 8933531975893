export const HKG = {
  name: {
    common: 'Hong Kong',
    official:
      "Hong Kong Special Administrative Region of the People's Republic of China",
    native: {
      eng: {
        official:
          "Hong Kong Special Administrative Region of the People's Republic of China",
        common: 'Hong Kong',
      },
      zho: { official: '香港中国特别行政区的人民共和国', common: '香港' },
    },
  },
  cities: [
    'Shuen Wan',
    'Central District',
    'Hung Hom',
    'Kowloon',
    'Quarry Bay',
    'Ngau Tau Kok',
    'Ying Pun',
    'Repulse Bay',
    'Causeway Bay',
    'Tseung Kwan O',
    'Tai Kok Tsui',
    'Tai Wai',
    'Ma On Shan Tsuen',
    'To Kwa Wan',
    'Wong Tai Sin',
    'Tuen Mun San Hui',
    'Ma Yau Tong',
    'Ngau Chi Wan',
    'Yau Ma Tei',
    'Kennedy Town',
    'Chai Wan Kok',
    'Sham Shui Po',
    'Mid Levels',
    'North Point',
    'Happy Valley',
    'Sai Keng',
    'Kwun Hang',
    'Mong Kok',
    'Shek Tong Tsui',
    'Cheung Sha Wan',
    'Sham Tseng',
    'Yuen Long San Hui',
    'Kwai Chung',
    'Sha Tin Wai',
    'Tin Shui Wai',
    'Hong Kong',
    'Tai Hang',
    'Fo Tan',
    'Tsimshatsui',
    'Tsz Wan Shan',
    'San Tung Chung Hang',
    'Peng Chau',
    'Sha Po Kong',
    'Wan Tsai',
    'Shek Kip Mei',
    'Aberdeen',
    'Tai Po',
    'Lai Chi Wo',
    'Shau Kei Wan',
    'Cheung Kong',
    'Tai Lin Pai',
    'Chuen Lung',
    'Sheung Shui',
    'Sheung Tsuen',
    'Fanling',
    'Fa Yuen',
    'Chek Chue',
    'Tai Tan',
    'Kowloon Tong',
    'Ho Man Tin',
    'Ma Wan',
    'Cha Kwo Ling',
    'Wo Che',
    'Lam Tin',
    'Nam A',
    'Tsing Lung Tau',
    'Ting Kau',
    'Tai Chau To',
    'Lin Fa Tei',
    'Chung Hau',
    'Ping Yeung',
    'Wong Chuk Hang',
    'San Tsuen',
    'Pak Ngan Heung',
    'Lam Tei',
    'Kat O Sheung Wai',
    'Lo So Shing',
    'Sha Tau Kok',
    'Tin Wan Resettlement Estate',
    'Tai Wan To',
    'Mau Ping',
    'Shatin',
  ],
  tld: ['.hk', '.香港'],
  cca2: 'HK',
  ccn3: '344',
  cca3: 'HKG',
  cioc: 'HKG',
  currency: ['HKD'],
  callingCode: ['852'],
  capital: 'City of Victoria',
  altSpellings: ['HK'],
  region: 'Asia',
  subregion: 'Eastern Asia',
  languages: { eng: 'English', zho: 'Chinese' },
  translations: {
    deu: {
      official: 'Sonderverwaltungszone der Volksrepublik China',
      common: 'Hongkong',
    },
    fra: {
      official:
        'Région administrative spéciale de Hong Kong de la République populaire de Chine',
      common: 'Hong Kong',
    },
    hrv: {
      official: 'Hong Kong Posebnog upravnog područjaNarodne Republike Kine',
      common: 'Hong Kong',
    },
    ita: {
      official:
        'Hong Kong Regione amministrativa speciale della Repubblica Popolare Cinese',
      common: 'Hong Kong',
    },
    jpn: { official: '中華人民共和国香港特別行政区', common: '香港' },
    nld: {
      official:
        'Hong Kong Speciale Administratieve Regio van de Volksrepubliek China',
      common: 'Hongkong',
    },
    por: {
      official:
        'Hong Kong Região Administrativa Especial da República Popular da China',
      common: 'Hong Kong',
    },
    rus: {
      official:
        'Hong Kong Специальный административный район Китайской Народной Республики Китая',
      common: 'Гонконг',
    },
    spa: {
      official:
        'Hong Kong Región Administrativa Especial de la República Popular China',
      common: 'Hong Kong',
    },
    fin: { official: 'Hong Kongin erityishallintoalue', common: 'Hongkong' },
  },
  latlng: [22.267, 114.188],
  demonym: 'Hong Konger',
  landlocked: false,
  borders: ['CHN'],
  area: 1104,
} as const;
