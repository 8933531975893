var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "CardLoadable",
    _vm._b(
      { attrs: { "no-body": "" } },
      "CardLoadable",
      { loading: !!_vm.loading },
      false
    ),
    [
      _c(
        "b-card-body",
        [
          _c("b-card-title", [
            _c("div", { staticClass: "h6" }, [
              _c("span", { staticClass: "stat-card-title" }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
              _vm._v(" "),
              _vm.subtitle
                ? _c("span", { staticClass: "small" }, [
                    _vm._v(_vm._s(_vm.subtitle)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.info
                ? _c(
                    "span",
                    {
                      staticClass: "icon-wrapper",
                      on: { click: _setup.viewInfo },
                    },
                    [_c(_setup.Icon, { attrs: { icon: _setup.faInfoCircle } })],
                    1
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", [_vm._t("header")], 2),
          ]),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "value-display" },
            [
              typeof _vm.value === "number"
                ? _c(_setup.DisplayTextNumber, {
                    staticClass: "h1 m-0",
                    style: { color: _vm.color },
                    attrs: { value: _vm.value },
                  })
                : _c(
                    "span",
                    { staticClass: "h1 m-0", style: { color: _vm.color } },
                    [_vm._v("N/A")]
                  ),
              _vm._v(" "),
              _vm._t("default"),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.$scopedSlots["footer"]
        ? _c("b-card-footer", [_vm._t("footer")], 2)
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }