var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { class: ["password-validation-item", { valid: _vm.condition }] },
    [
      _c(
        "span",
        { staticClass: "item-bullet" },
        [
          _c("Transition", { attrs: { appear: "" } }, [
            _vm.condition
              ? _c(
                  "span",
                  { staticClass: "bullet-valid" },
                  [_c("Icon", { attrs: { icon: _setup.faCheck } })],
                  1
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("Transition", { attrs: { appear: "" } }, [
            !_vm.condition
              ? _c(
                  "span",
                  { staticClass: "bullet-invalid" },
                  [_c("Icon", { attrs: { icon: _setup.faCircle } })],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("span", { staticClass: "item-text" }, [
        _vm._v(_vm._s(_vm.description)),
      ]),
      _vm._v(" "),
      _vm.tooltip
        ? _c(_setup.InfoCircle, { attrs: { text: _vm.tooltip } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }