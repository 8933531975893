import { server } from '@api/request';
import { UnityCatalog } from '@client/models/unity-catalog';
import { isObject } from '@tools/type-guards';

/**
 * List all UnityCatalogs
 *
 * @return A list of Unity Catalogs
 */
export async function list() {
  return await server.list('v2/admin/unity-catalogs', processUnityCatalogData);
}

export interface SubmitCatalogOptions {
  version: UnityCatalog['version'];
  os: UnityCatalog['os'];
  data: UnityCatalog['data'];
  hash: UnityCatalog['hash'];
  channel: UnityCatalog['channel'];
  dlc: UnityCatalog['dlc'];
  baseApp: UnityCatalog['baseApp'];
}

/**
 * SubmitUnityCatalog
 *
 * @return A Unity Catalog Object
 */
export async function submitUnityCatalog(options: SubmitCatalogOptions) {
  return await server.post('v2/admin/builds/submit-catalog', options);
}

export interface ApproveCatalogResultOptions {
  id: UnityCatalog['id'];
  buildNumber: UnityCatalog['buildNumber'];
  status: 'Approve' | 'Rejected';
}

/**
 * ApproveCatalogResult
 *
 * @return A Unity Catalog Object
 */
export async function approveCatalogResult(
  options: ApproveCatalogResultOptions,
) {
  return await server.post('v2/admin/builds/approve-catalog-result', options);
}

//#region Helper Functions
/**
 *
 */
function isValidUnityCatalogData(value: unknown): value is UnityCatalog {
  if (!isObject(value)) return false;

  // TEMP: Use a more strict validation.

  return true;
}

/**
 *
 */
function processUnityCatalogData(data: unknown) {
  if (!isValidUnityCatalogData(data)) {
    throw new Error('Invalid unity catalog data item.');
  }

  return { ...data } as UnityCatalog;
}
//#endregion Helper Functions
