import { Module, VuexModule, Mutation, Action } from '@vuex/decorators';
import { find, findIndex } from 'lodash';

import { api } from '@api';
import { Assignment } from '@models';
import { Root } from '@store';

declare module '@vuex/core' {
  export interface Getters {
    'assignments/findById': Assignments['findById'];
  }

  export interface CommitMap {
    'assignments/SET': Assignments['SET'];
    'assignments/ADD': Assignments['ADD'];
    'assignments/UPDATE': Assignments['UPDATE'];
    'assignments/DELETE': Assignments['DELETE'];
    'assignments/CLEAR': Assignments['CLEAR'];
  }

  export interface DispatchMap {
    'assignments/list': Assignments['list'];
    'assignments/listByCourse': Assignments['listByCourse'];
    'assignments/get': Assignments['get'];
    'assignments/create': Assignments['create'];
    'assignments/update': Assignments['update'];
    'assignments/del': Assignments['del'];
  }
}

@Module({ namespaced: true })
export class Assignments
  extends VuexModule<Assignments.State, Root.State>
  implements Assignments.State
{
  items: Assignment[] = [];

  /**
   * ...
   */
  get findById() {
    return (id: string) => find(this.items, { id });
  }

  // region Mutations

  /**
   * ...
   */
  @Mutation
  SET(options: Assignments.SetMutationOptions) {
    this.items = options.data;
  }

  /**
   * ...
   */
  @Mutation
  ADD(options: Assignments.AddMutationOptions) {
    const items = [...this.items];

    const i = findIndex(items, { id: options.id });

    if (i !== -1) {
      items[i] = options;
    } else {
      items.push(options);
    }

    this.items = items;
  }

  /**
   * ...
   */
  @Mutation
  UPDATE(options: Assignments.UpdateMutationOptions) {
    const items = [...this.items];

    const i = findIndex(items, { id: options.assignmentId });

    if (i === -1) {
      throw new Error(`Assignment with ID ${options.assignmentId} not found.`);
    }

    for (const key in options) items[i][key] = options[key];

    this.items = items;
  }

  /**
   * ...
   */
  @Mutation
  DELETE(options: Assignments.DeleteMutationOptions) {
    const index = findIndex(this.items, { id: options.assignmentId });

    if (index === -1) {
      throw new Error(`Assignment with ID ${options.assignmentId} not found.`);
    }

    this.items = this.items.filter((_, i) => i !== index);
  }

  /**
   * ...
   */
  @Mutation
  CLEAR() {
    this.items = [];
  }

  // endregion Mutations

  // region Actions

  /**
   * ...
   */
  @Action
  async list() {
    const data = await api.assignments.list();

    this.context.commit('SET', { data });

    return this.items;
  }

  /**
   * ...
   */
  @Action
  async listByCourse(options?: Assignments.ListByCourseActionOptions) {
    if (!options) {
      const role = this.context.rootState.me.selectedRole;

      if (!role) {
        throw new Error('No Selected Role Detected on User');
      }

      if (!role.organization) {
        throw new Error(
          'Selected user role has no association to an organization.',
        );
      }

      if (!role.course) {
        throw new Error('Selected user role has no association to a course.');
      }

      options = {
        organizationId: role.organization.id,
        courseId: role.course.id,
      };
    }

    const data = await api.assignments.listByCourse(options);

    this.context.commit('SET', { data });

    return this.items;
  }

  /**
   * ...
   */
  @Action
  async get(options: Assignments.GetActionOptions) {
    const data = await api.assignments.get(options);

    this.context.commit('ADD', data);

    return data;
  }

  /**
   * ...
   */
  @Action
  async create(options: Assignments.CreateActionOptions) {
    const data = await api.assignments.create(options);

    this.context.commit('ADD', data);

    return data;
  }

  /**
   * ...
   */
  @Action
  async update(options: Assignments.UpdateActionOptions) {
    const data = await api.assignments.update(options);

    this.context.commit('ADD', data);

    return data;
  }

  /**
   * ...
   */
  @Action
  async del(options: Assignments.DeleteActionOptions) {
    await api.assignments.del(options);

    this.context.commit('DELETE', options);
  }

  // endregion Actions
}

export namespace Assignments {
  /**
   * ...
   */
  export interface State {
    items: Assignment[];
  }

  /**
   * ...
   */
  export interface SetMutationOptions {
    data: Assignment[];
  }

  /** ... */
  export type AddMutationOptions = Assignment;
  /** ... */
  export type UpdateMutationOptions = api.assignments.UpdateOptions;
  /** ... */
  export type DeleteMutationOptions = api.assignments.DeleteOptions;
  /** ... */
  export type ListByCourseActionOptions = api.assignments.ListByCourseOptions;
  /** ... */
  export type GetActionOptions = api.assignments.GetOptions;
  /** ... */
  export type CreateActionOptions = api.assignments.CreateOptions;
  /** ... */
  export type UpdateActionOptions = api.assignments.UpdateOptions;
  /** ... */
  export type DeleteActionOptions = api.assignments.DeleteOptions;
}

export default Assignments;
