export const fullDate = 'EEEE, d MMMM y';
export const longDate = 'd MMMM y';
export const medium = 'd MMM y h:mm:ss a';
export const mediumDate = 'd MMM y';
export const mediumTime = 'h:mm:ss a';
export const short = 'dd/MM/y h:mm a';
export const shortDate = 'dd/MM/y';
export const shortTime = 'h:mm a';

export const AmPms = ['AM', 'PM'] as const;

export const Day = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
] as const;

export const EraNames = ['Before Christ', 'Anno Domini'] as const;

export const Eras = ['BC', 'AD'] as const;

export const FirstDayOfWeek = 0;

export const Month = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
] as const;

export const ShortDay = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
] as const;

export const ShortMonth = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
] as const;

export const StandaloneMonth = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
] as const;

export const WeekendRange = [5, 6] as const;
