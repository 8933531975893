export type ConditionalExpression =
  | { and: (RegExp | ConditionalExpression)[] }
  | { or: (RegExp | ConditionalExpression)[] }
  | { not: RegExp | ConditionalExpression }
  | RegExp;

/** ... */
export const OS_RE = {
  WINDOWS: {
    and: [
      { or: [/\bWindows|(Win\d\d)\b/, /\bWin 9x\b/] },
      { not: /\bWindows Phone\b/ },
    ],
  },
  MAC: { and: [/\bMac OS\b/, { not: /Windows Phone/ }] },
  IOS: {
    and: [
      { or: [/\biPad\b/, /\biPhone\b/, /\biPod\b/] },
      { not: /Windows Phone/ },
    ],
  },
  ANDROID: { and: [/\bAndroid\b/, { not: /Windows Phone/ }] },
  LINUX: /\bLinux\b/,
  UNIX: /\bUNIX\b/,
  FIREFOX_OS: { and: [/\bFirefox\b/, /Mobile\b/] },
  CHROME_OS: /\bCrOS\b/,
  WINDOWS_PHONE: { or: [/\bIEMobile\b/, /\bWindows Phone\b/] },
  PS4: /\bMozilla\/5.0 \(PlayStation 4\b/,
  VITA: /\bMozilla\/5.0 \(Play(S|s)tation Vita\b/,
} as const satisfies Record<string, ConditionalExpression>;

/** ... */
export const BROWSERS_RE = {
  CHROME: {
    and: [
      { or: [/\bChrome\b/, /\bCriOS\b/] },
      { not: { or: [/\bOPR\b/, /\bEdge\b/, /\bCordova\b/] } },
    ],
  },
  FIREFOX: {
    and: [{ or: [/\bFirefox\b/, /\bFxiOS\b/] }, { not: /\bCordova\b/ }],
  },
  SAFARI: {
    and: [
      /^((?!CriOS).)*\Safari\b.*$/,
      {
        not: {
          or: [
            /\bOPR\b/,
            /\bEdge\b/,
            /Windows Phone/,
            /\bCordova\b/,
            /\bChrome\b/,
          ],
        },
      },
    ],
  },
  OPERA: { or: [/Opera\b/, /\bOPR\b/] },
  IE: {
    or: [
      /\bMSIE\b/,
      /\bTrident\b/,
      /^Mozilla\/5\.0 \(Windows NT 10\.0; Win64; x64\)$/,
    ],
  },
  MS_EDGE: { or: [/\bEdge\b/] },
  PS4: /\bMozilla\/5.0 \(PlayStation 4\b/,
  VITA: /\bMozilla\/5.0 \(Play(S|s)tation Vita\b/,
  CORDOVA: /\bCordova\b/,
  FB_MESSENGER: /\bFBAN\/MessengerForiOS\b/,
} as const satisfies Record<string, ConditionalExpression>;

/** ... */
export const DEVICES_RE = {
  ANDROID: { and: [/\bAndroid\b/, { not: /Windows Phone/ }] },
  I_PAD: /\biPad\b/,
  IPHONE: { and: [/\biPhone\b/, { not: /Windows Phone/ }] },
  I_POD: /\biPod\b/,
  BLACKBERRY: /\bblackberry\b/,
  FIREFOX_OS: { and: [/\bFirefox\b/, /\bMobile\b/] },
  CHROME_BOOK: /\bCrOS\b/,
  WINDOWS_PHONE: { or: [/\bIEMobile\b/, /\bWindows Phone\b/] },
  PS4: /\bMozilla\/5.0 \(PlayStation 4\b/,
  CHROMECAST: /\bCrKey\b/,
  APPLE_TV: /^iTunes-AppleTV\/4.1$/,
  GOOGLE_TV: /\bGoogleTV\b/,
  VITA: /\bMozilla\/5.0 \(Play(S|s)tation Vita\b/,
} as const satisfies Record<string, ConditionalExpression>;

/** ... */
export const osVersionS_RE = {
  WINDOWS_3_11: /Win16/,
  WINDOWS_95: /(Windows 95|Win95|Windows_95)/,
  WINDOWS_ME: /(Win 9x 4.90|Windows ME)/,
  WINDOWS_98: /(Windows 98|Win98)/,
  WINDOWS_CE: /Windows CE/,
  WINDOWS_2000: /(Windows NT 5.0|Windows 2000)/,
  WINDOWS_XP: /(Windows NT 5.1|Windows XP)/,
  WINDOWS_SERVER_2003: /Windows NT 5.2/,
  WINDOWS_VISTA: /Windows NT 6.0/,
  WINDOWS_7: /(Windows 7|Windows NT 6.1)/,
  WINDOWS_8_1: /(Windows 8.1|Windows NT 6.3)/,
  WINDOWS_8: /(Windows 8|Windows NT 6.2)/,
  WINDOWS_10: /(Windows NT 10.0)/,
  WINDOWS_PHONE_7_5: /(Windows Phone OS 7.5)/,
  WINDOWS_PHONE_8_1: /(Windows Phone 8.1)/,
  WINDOWS_PHONE_10: /(Windows Phone 10)/,
  WINDOWS_NT_4_0: {
    and: [
      /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/,
      { not: /Windows NT 10.0/ },
    ],
  },
  MACOSX: /(MAC OS X\s*[^ 0-9])/,
  MACOSX_3: /(Darwin 10.3|Mac OS X 10.3)/,
  MACOSX_4: /(Darwin 10.4|Mac OS X 10.4)/,
  MACOSX_5: /(Mac OS X 10.5)/,
  MACOSX_6: /(Mac OS X 10.6)/,
  MACOSX_7: /(Mac OS X 10.7)/,
  MACOSX_8: /(Mac OS X 10.8)/,
  MACOSX_9: /(Mac OS X 10.9)/,
  MACOSX_10: /(Mac OS X 10.10)/,
  MACOSX_11: /(Mac OS X 10.11)/,
  MACOSX_12: /(Mac OS X 10.12)/,
  MACOSX_13: /(Mac OS X 10.13)/,
  MACOSX_14: /(Mac OS X 10.14)/,
  MACOSX_15: /(Mac OS X 10.15)/,
} as const satisfies Record<string, ConditionalExpression>;
