import { Module, Action } from '@vuex/decorators';
import { TablePanel } from 'table-panel';

import { RoleId } from '@auth';
import { ExtensionCode } from '@models';
import { modals } from '@modals';

const columns: TablePanel.Column<ExtensionCode>[] = [
  {
    key: 'id',
    label: 'ID',
    value: 'id',
  },
  {
    key: 'courseId',
    label: 'Course',
    value: 'courseId',
  },
  {
    key: 'userId',
    label: 'User',
    value: 'userId',
  },
  {
    key: 'createdAt',
    label: 'Created',
    type: 'date',
    value: 'createdAt',
  },
];

@Module({ namespaced: true })
export class ExtensionCodesTablePanel
  extends TablePanel<ExtensionCode>
  implements TablePanel.Props<ExtensionCode>
{
  onPageChanged?: TablePanel.OnPageChangedCallback<ExtensionCode>;
  readonly module = 'extensionCodes';
  readonly loadAction = 'list';
  readonly pk = 'id';
  readonly label = 'EXTENSION CODES';
  readonly columns = columns;
  readonly createAction = 'createExtensionCode';
  readonly deleteAction = 'deleteExtensionCode';

  get enabled() {
    return this.isActiveRole(RoleId.LasAdmin);
  }

  @Action
  async createExtensionCode() {
    await modals.extensionCodes.create();
  }

  @Action
  async deleteExtensionCode({
    items,
  }: ExtensionCodesTablePanel.DeleteItemOptions) {
    await modals.confirm.deleteItems({ itemType: 'extensionCodes', items });
  }
}

export namespace ExtensionCodesTablePanel {
  /** ... */
  export interface DeleteItemOptions {
    items: ExtensionCode[];
  }
}
