/** ... */
export const nistGradingCriteria = {
  NIST_TEST_SCENE_ID: 17,
  APSA_TEST_SCENE_ID: 22,
  NIST_TEST_MODULES: {
    118: { type: 'position', feet: 10 },
    119: { type: 'traversal', feet: 10 },
    120: { type: 'orbital', feet: 10 },
    121: { type: 'spiral', feet: 10 },
    122: { type: 'recon', feet: 10 },
    123: { type: 'position', feet: 20 },
    124: { type: 'traversal', feet: 20 },
    125: { type: 'orbital', feet: 20 },
    126: { type: 'spiral', feet: 20 },
    127: { type: 'recon', feet: 20 },
    128: { type: 'position', feet: 30 },
    129: { type: 'traversal', feet: 30 },
    130: { type: 'orbital', feet: 30 },
    131: { type: 'spiral', feet: 30 },
    132: { type: 'recon', feet: 30 },
    153: { type: 'bperp', feet: 10 },
  } as const,
  NIST_TEST_GRADING_RUBRIC: {
    position: {
      objectives: [
        {
          simReportName:
            '1. Hover at {S}Ft over stand 1, then align drone to capture target 1.',
          label: 'HOVER AT ALTITUDE (S) OVER STAND 1',
          stepNo: '1',
          type: 'capture',
          target: '1',
        },
        {
          simReportName: '2. Align drone to capture target 2A.',
          label: 'ALIGN/IMAGE DOWNWARD THEN FORWARD IN ORDER',
          stepNo: '2',
          type: 'capture',
          target: '2A',
        },
        {
          simReportName: '4. Align drone to capture target 1.',
          label: 'YAW LEFT 360 DEGREES',
          stepNo: '3',
          type: 'capture',
          target: '1',
        },
        {
          simReportName: '5. Align drone to capture target 2A.',
          label: 'ALIGN/IMAGE DOWNWARD THEN FORWARD IN ORDER',
          stepNo: '4',
          type: 'capture',
          target: '2A',
        },
        {
          simReportName: '7. Align drone to capture target 1.',
          label: 'YAW RIGHT 360 DEGREES',
          stepNo: '5',
          type: 'capture',
          target: '1',
        },
        {
          simReportName: '8. Align drone to capture target 2A.',
          label: 'ALIGN/IMAGE DOWNWARD THEN FORWARD IN ORDER',
          stepNo: '6',
          type: 'capture',
          target: '2A',
        },
        {
          simReportName:
            '9. Climb to {2S}Ft over stand 1, then align drone to capture target 1.',
          label: 'CLIMB TO ALTITUDE 2(S)',
          stepNo: '7',
          type: 'capture',
          target: '1',
        },
        {
          simReportName: '10. Align drone to capture taget 3A.',
          label: 'ALIGN/IMAGE DOWNWARD THEN FORWARD IN ORDER',
          stepNo: '8',
          type: 'capture',
          target: '3A',
        },
        {
          simReportName:
            '11. Descend to {S}Ft over stand 1, then align drone to capture target 1.',
          label: 'DESCEND TO ALTITUDE (S)',
          stepNo: '9',
          type: 'capture',
          target: '1',
        },
        {
          simReportName: '12. Align drone to capture target 2A.',
          label: 'ALIGN/IMAGE DOWNWARD THEN FORWARD IN ORDER',
          stepNo: '10',
          type: 'capture',
          target: '2A',
        },
        {
          simReportName:
            '13. Hover at {S}Ft over stand 2, then align drone to capture target 2.',
          label: 'FORWARD OVER STAND 2',
          stepNo: '11',
          type: 'capture',
          target: '2',
        },
        {
          simReportName: '14. Align drone to capture target 3A.',
          label: 'ALIGN/IMAGE DOWNWARD THEN FORWARD IN ORDER',
          stepNo: '12',
          type: 'capture',
          target: '3A',
        },
        {
          simReportName:
            '15. Hover at {S}Ft over stand 1, then align drone to capture target 1.',
          label: 'BACKWARD OVER STAND 1',
          stepNo: '13',
          type: 'capture',
          target: '1',
        },
        {
          simReportName: '16. Align drone to capture target 2A.',
          label: 'ALIGN/IMAGE DOWNWARD THEN FORWARD IN ORDER',
          stepNo: '14',
          type: 'capture',
          target: '2A',
        },
        {
          simReportName:
            '17. Hover at {S}Ft over stand 2, Yaw 180 degrees, then align drone to capture target 2.',
          label: 'FORWARD OVER STAND 2 AND YAW RIGHT 180 DEGREES',
          stepNo: '15',
          type: 'capture',
          target: 'UPSIDE 2 DOWN',
        },
        {
          simReportName: '18. Align drone to capture target 1C.',
          label: 'ALIGN/IMAGE DOWNWARD THEN FORWARD IN ORDER',
          stepNo: '16',
          type: 'capture',
          target: '1C',
        },
        {
          simReportName:
            '19. Hover at {S}Ft over landing pad, Yaw 180 degrees, then align drone to capture target L.',
          label: 'FORWARD OVER LANDING AND YAW LEFT 180 DEGREES',
          stepNo: '17',
          type: 'capture',
          target: 'L',
        },
        {
          simReportName: '20. Align drone to capture target 1A.',
          label: 'ALIGN/IMAGE DOWNWARD THEN FORWARD IN ORDER',
          stepNo: '18',
          type: 'capture',
          target: '1A',
        },
        {
          simReportName: '22. Capture P1 and P2.',
          label: 'LAND CENTERED FACING STANDS (WORTH 2 POINTS)',
          stepNo: '19',
          type: 'capture',
          target: 'CENTERED | PERCH 1',
        },
        {
          simReportName: '22. Capture P1 and P2.',
          label: 'IMAGE FORWARD PERCH TARGETS P1/P2 IN ORDER',
          stepNo: '20',
          type: 'capture',
          target: 'CENTERED | PERCH 2',
        },
      ],
    },
    traversal: {
      objectives: [
        {
          simReportName:
            '1. Hover at {S}ft over landing pad, capture target 1A.',
          label: 'HOVER AT ALTITUDE S OVER THE LAUNCH PLATFORM',
          stepNo: '1',
          type: 'capture',
          target: '1A',
        },
        {
          simReportName: '2. Traverse clockwise and capture target 1B.',
          label: 'TRAVERSE LEFTWARD (CLOCKWISE)',
          stepNo: '2',
          type: 'capture',
          target: '1B',
        },
        {
          simReportName: '3. Traverse clockwise and capture target 2B.',
          label: 'TRAVERSE LEFTWARD (CLOCKWISE)',
          stepNo: '3',
          type: 'capture',
          target: '2B',
        },
        {
          simReportName: '4. Traverse clockwise and capture target 3B.',
          label: 'TRAVERSE LEFTWARD (CLOCKWISE)',
          stepNo: '4',
          type: 'capture',
          target: '3B',
        },
        {
          simReportName: '5. Traverse clockwise and capture target 3C.',
          label: 'TRAVERSE LEFTWARD (CLOCKWISE)',
          stepNo: '5',
          type: 'capture',
          target: '3C',
        },
        {
          simReportName: '6. Traverse clockwise and capture target 3D.',
          label: 'TRAVERSE LEFTWARD (CLOCKWISE)',
          stepNo: '6',
          type: 'capture',
          target: '3D',
        },
        {
          simReportName: '7. Traverse clockwise and capture target 2D.',
          label: 'TRAVERSE LEFTWARD (CLOCKWISE)',
          stepNo: '7',
          type: 'capture',
          target: '2D',
        },
        {
          simReportName: '8. Traverse clockwise and capture target 1D.',
          label: 'TRAVERSE LEFTWARD (CLOCKWISE)',
          stepNo: '8',
          type: 'capture',
          target: '1D',
        },
        {
          simReportName: '9. Traverse clockwise and capture target 1A.',
          label: 'TRAVERSE LEFTWARD (CLOCKWISE)',
          stepNo: '9',
          type: 'capture',
          target: '1A',
        },
        {
          simReportName: '11. Capture target P1.',
          label: 'LAND CENTERED FACING STANDS',
          stepNo: '10',
          type: 'capture',
          target: 'CENTERED | PERCH 1',
        },
        {
          simReportName:
            '12. Hover at {S}ft over landing pad, capture target 1A.',
          label: 'HOVER AT ALTITUDE S OVER THE LAUNCH PLATFORM',
          stepNo: '11',
          type: 'capture',
          target: '1A',
        },
        {
          simReportName:
            '13. Traverse counter-clockwise and capture target 1D.',
          label: 'TRAVERSE RIGHTWARD (COUNTER CLOCKWISE)',
          stepNo: '12',
          type: 'capture',
          target: '1D',
        },
        {
          simReportName:
            '14. Traverse counter-clockwise and capture target 2D.',
          label: 'TRAVERSE RIGHTWARD (COUNTER CLOCKWISE)',
          stepNo: '13',
          type: 'capture',
          target: '2D',
        },
        {
          simReportName:
            '15. Traverse counter-clockwise and capture target 3D.',
          label: 'TRAVERSE RIGHTWARD (COUNTER CLOCKWISE)',
          stepNo: '14',
          type: 'capture',
          target: '3D',
        },
        {
          simReportName:
            '16. Traverse counter-clockwise and capture target 3C.',
          label: 'TRAVERSE RIGHTWARD (COUNTER CLOCKWISE)',
          stepNo: '15',
          type: 'capture',
          target: '3C',
        },
        {
          simReportName:
            '17. Traverse counter-clockwise and capture target 3B.',
          label: 'TRAVERSE RIGHTWARD (COUNTER CLOCKWISE)',
          stepNo: '16',
          type: 'capture',
          target: '3B',
        },
        {
          simReportName:
            '18. Traverse counter-clockwise and capture target 2B.',
          label: 'TRAVERSE RIGHTWARD (COUNTER CLOCKWISE)',
          stepNo: '17',
          type: 'capture',
          target: '2B',
        },
        {
          simReportName:
            '19. Traverse counter-clockwise and capture target 1B.',
          label: 'TRAVERSE RIGHTWARD (COUNTER CLOCKWISE)',
          stepNo: '18',
          type: 'capture',
          target: '1B',
        },
        {
          simReportName:
            '20. Traverse counter-clockwise and capture target 1A.',
          label: 'TRAVERSE RIGHTWARD (COUNTER CLOCKWISE)',
          stepNo: '19',
          type: 'capture',
          target: '1A',
        },
        {
          simReportName: '22. Capture target P2.',
          label: 'LAND CENTERED FACING STANDS',
          stepNo: '20',
          type: 'capture',
          target: 'CENTERED | PERCH 2',
        },
      ],
    },
    orbital: {
      objectives: [
        {
          simReportName: '1. Hover at {S}ft over stand 2, capture target 2',
          label: 'ALIGN OVER STAND 2 AT ATITIDE S TO CHECK RADIUS',
          stepNo: '1',
          type: 'capture',
          target: '2',
        },
        {
          simReportName: '2. Align and capture target 3A',
          label: 'ALIGN WITH BUCKET 3A TO CHECK ALTITUDE S',
          stepNo: '2',
          type: 'capture',
          target: '3A',
        },
        {
          simReportName: '3. Orbit clockwise, capture target 3B.',
          label: 'ORBIT LEFTWARD (CW)',
          stepNo: '3',
          type: 'capture',
          target: '3B',
        },
        {
          simReportName: '4. Orbit clockwise, capture target 3C.',
          label: 'ORBIT LEFTWARD (CW)',
          stepNo: '4',
          type: 'capture',
          target: '3C',
        },
        {
          simReportName: '5. Orbit clockwise, capture target 3D.',
          label: 'ORBIT LEFTWARD (CW)',
          stepNo: '5',
          type: 'capture',
          target: '3D',
        },
        {
          simReportName: '6. Hover at {S}ft over stand 2, capture target 2.',
          label: 'ALIGN OVER STAND 2 AT ATITIDE S TO CHECK RADIUS',
          stepNo: '6',
          type: 'capture',
          target: '2',
        },
        {
          simReportName: '7. Align and capture target 3A.',
          label: 'ALIGN WITH BUCKET 3A TO CHECK ALTITUDE S',
          stepNo: '7',
          type: 'capture',
          target: '3A',
        },
        {
          simReportName: '8. Orbit counter-clockwise, capture target 3D.',
          label: 'ORBIT RIGHTWARD (CCW)',
          stepNo: '8',
          type: 'capture',
          target: '3D',
        },
        {
          simReportName: '9. Orbit counter-clockwise, capture target 3C.',
          label: 'ORBIT RIGHTWARD (CCW)',
          stepNo: '9',
          type: 'capture',
          target: '3C',
        },
        {
          simReportName: '10. Orbit counter-clockwise, capture target 3B.',
          label: 'ORBIT RIGHTWARD (CCW)',
          stepNo: '10',
          type: 'capture',
          target: '3B',
        },
        {
          simReportName: '11. Hover at {S}ft over stand 2, capture target 2.',
          label: 'ALIGN OVER STAND 2 AT ATITIDE S TO CHECK RADIUS',
          stepNo: '11',
          type: 'capture',
          target: '2',
        },
        {
          simReportName: '12. Align and capture target 3A.',
          label: 'ALIGN WITH BUCKET 3A TO CHECK ALTITUDE S',
          stepNo: '12',
          type: 'capture',
          target: '3A',
        },
        {
          simReportName: '13. Orbit clockwise, capture target 3B.',
          label: 'ORBIT LEFTWARD (CW)',
          stepNo: '13',
          type: 'capture',
          target: '3B',
        },
        {
          simReportName: '14. Orbit clockwise, capture target 3C.',
          label: 'ORBIT LEFTWARD (CW)',
          stepNo: '14',
          type: 'capture',
          target: '3C',
        },
        {
          simReportName: '15. Orbit clockwise, capture target 3D.',
          label: 'ORBIT LEFTWARD (CW)',
          stepNo: '15',
          type: 'capture',
          target: '3D',
        },
        {
          simReportName: '16. Hover at {S}ft over stand 2, capture target 2.',
          label: 'ALIGN OVER STAND 2 AT ATITIDE S TO CHECK RADIUS',
          stepNo: '16',
          type: 'capture',
          target: '2',
        },
        {
          simReportName: '17. Align and capture target 3A.',
          label: 'ALIGN WITH BUCKET 3A TO CHECK ALTITUDE S',
          stepNo: '17',
          type: 'capture',
          target: '3A',
        },
        {
          simReportName: '18. Orbit counter-clockwise, capture target 3D.',
          label: 'ORBIT RIGHTWARD (CCW)',
          stepNo: '18',
          type: 'capture',
          target: '3D',
        },
        {
          simReportName: '19. Orbit counter-clockwise, capture target 3C.',
          label: 'ORBIT RIGHTWARD (CCW)',
          stepNo: '19',
          type: 'capture',
          target: '3C',
        },
        {
          simReportName: '20. Orbit counter-clockwise, capture target 3B.',
          label: 'ORBIT RIGHTWARD (CCW)',
          stepNo: '20',
          type: 'capture',
          target: '3B',
        },
      ],
    },
    spiral: {
      objectives: [
        {
          simReportName: '1. Align and capture target 1',
          label: 'INSPECT SPIRAL STAND 1 LEFTWARD (CW)',
          stepNo: '1',
          type: 'capture',
          target: '1',
        },
        {
          simReportName: '2. Align and capture target 1A',
          label: 'IN ORDER: TOP – A – B – C – D',
          stepNo: '2',
          type: 'capture',
          target: '1A',
        },
        {
          simReportName: '3. Align and capture target 1B',
          label: 'FREE FLIGHT BUT ALIGN WITH BUCKETS IN ORDER',
          stepNo: '3',
          type: 'capture',
          target: '1B',
        },
        {
          simReportName: '4. Align and capture target 1C',
          label: 'FREE FLIGHT BUT ALIGN WITH BUCKETS IN ORDER',
          stepNo: '4',
          type: 'capture',
          target: '1C',
        },
        {
          simReportName: '5. Align and capture target 1D',
          label: 'FREE FLIGHT BUT ALIGN WITH BUCKETS IN ORDER',
          stepNo: '5',
          type: 'capture',
          target: '1D',
        },
        {
          simReportName: '6. Align and capture target 2',
          label: 'INSPECT SPIRAL STAND 2 RIGHTWARD (CCW)',
          stepNo: '6',
          type: 'capture',
          target: '2',
        },
        {
          simReportName: '7. Align and capture target 2A',
          label: 'IN ORDER: TOP – A – C – B – D',
          stepNo: '7',
          type: 'capture',
          target: '2A',
        },
        {
          simReportName: '8. Align and capture target 2D',
          label: 'FREE FLIGHT BUT ALIGN WITH BUCKETS IN ORDER',
          stepNo: '8',
          type: 'capture',
          target: '2D',
        },
        {
          simReportName: '9. Align and capture target 2C',
          label: 'FREE FLIGHT BUT ALIGN WITH BUCKETS IN ORDER',
          stepNo: '9',
          type: 'capture',
          target: '2C',
        },
        {
          simReportName: '10. Align and capture target 2B',
          label: 'FREE FLIGHT BUT ALIGN WITH BUCKETS IN ORDER',
          stepNo: '10',
          type: 'capture',
          target: '2B',
        },
        {
          simReportName: '11. Align and capture target 3',
          label: 'INSPECT SPIRAL STAND 3 LEFTWARD (CW)',
          stepNo: '11',
          type: 'capture',
          target: '3',
        },
        {
          simReportName: '12. Align and capture target 3A',
          label: 'IN ORDER: TOP – A – B – C – D',
          stepNo: '12',
          type: 'capture',
          target: '3A',
        },
        {
          simReportName: '13. Align and capture target 3B',
          label: 'FREE FLIGHT BUT ALIGN WITH BUCKETS IN ORDER',
          stepNo: '13',
          type: 'capture',
          target: '3B',
        },
        {
          simReportName: '14. Align and capture target 3C',
          label: 'FREE FLIGHT BUT ALIGN WITH BUCKETS IN ORDER',
          stepNo: '14',
          type: 'capture',
          target: '3C',
        },
        {
          simReportName: '15. Align and capture target 3D',
          label: 'FREE FLIGHT BUT ALIGN WITH BUCKETS IN ORDER',
          stepNo: '15',
          type: 'capture',
          target: '3D',
        },
        {
          simReportName: '16. Align and capture target 4',
          label: 'INSPECT SPIRAL STAND 4 RIGHTWARD (CCW)',
          stepNo: '16',
          type: 'capture',
          target: '4',
        },
        {
          simReportName: '17. Align and capture target 4A',
          label: 'IN ORDER: TOP – A – C – B – D',
          stepNo: '17',
          type: 'capture',
          target: '4A',
        },
        {
          simReportName: '18. Align and capture target 4D',
          label: 'FREE FLIGHT BUT ALIGN WITH BUCKETS IN ORDER',
          stepNo: '18',
          type: 'capture',
          target: '4D',
        },
        {
          simReportName: '19. Align and capture target 4C',
          label: 'FREE FLIGHT BUT ALIGN WITH BUCKETS IN ORDER',
          stepNo: '19',
          type: 'capture',
          target: '4C',
        },
        {
          simReportName: '20. Align and capture target 4B',
          label: 'FREE FLIGHT BUT ALIGN WITH BUCKETS IN ORDER',
          stepNo: '20',
          type: 'capture',
          target: '4B',
        },
      ],
    },
    recon: {
      objectives: [
        {
          simReportName:
            '1. Hover at altitude S, fly down range, align and capture target 4',
          label: 'FLY DOWN RANGE STRAIGHT & LEVEL, ALIGN BUCKET 4',
          stepNo: '1',
          type: 'capture',
          target: '4',
        },
        {
          simReportName: '3. Align and capture target 4 (Upside Down)',
          label: 'YAW 180 DEGREES, ALIGN BUCKET 4 (UPSIDE DOWN)',
          stepNo: '2',
          type: 'capture',
          target: 'UPSIDE 4 DOWN',
        },
        {
          simReportName: '4. Fly up range, align and capture target L',
          label: 'FLY UP RANGE STRAIGHT & LEVEL, ALIGN LANDING',
          stepNo: '3',
          type: 'capture',
          target: 'L',
        },
        {
          simReportName: '5. Yaw 180 degrees and align and capture target 1A',
          label: 'YAW 180 DEGREES LOOKING DOWN, ALIGN BUCKET 1A',
          stepNo: '4',
          type: 'capture',
          target: '1A',
        },
        {
          simReportName:
            '6. Hover at altitude S, fly down range, align and capture target 4',
          label: 'REPEAT DOWN RANGE',
          stepNo: '5',
          type: 'capture',
          target: '4',
        },
        {
          simReportName: '8. Align and capture target 4 (Upside Down)',
          label: 'REPEAT DOWN RANGE',
          stepNo: '6',
          type: 'capture',
          target: 'UPSIDE 4 DOWN',
        },
        {
          simReportName: '9. Fly up range, align and capture target L',
          label: 'REPEAT UP RANGE',
          stepNo: '7',
          type: 'capture',
          target: 'L',
        },
        {
          simReportName: '10. Yaw 180 degrees and align and capture target 1A',
          label: 'REPEAT UP RANGE',
          stepNo: '8',
          type: 'capture',
          target: '1A',
        },
        {
          simReportName:
            '11. Hover at altitude S, fly down range, align and capture target 4',
          label: 'REPEAT DOWN RANGE',
          stepNo: '9',
          type: 'capture',
          target: '4',
        },
        {
          simReportName: '13. Align and capture target 4 (Upside Down)',
          label: 'REPEAT DOWN RANGE',
          stepNo: '10',
          type: 'capture',
          target: 'UPSIDE 4 DOWN',
        },
        {
          simReportName: '14. Fly up range, align and capture target L',
          label: 'REPEAT UP RANGE',
          stepNo: '11',
          type: 'capture',
          target: 'L',
        },
        {
          simReportName: '15. Yaw 180 degrees and align and capture target 1A',
          label: 'REPEAT UP RANGE',
          stepNo: '12',
          type: 'capture',
          target: '1A',
        },
        {
          simReportName:
            '16. Hover at altitude S, fly down range, align and capture target 4',
          label: 'REPEAT DOWN RANGE',
          stepNo: '13',
          type: 'capture',
          target: '4',
        },
        {
          simReportName: '18. Align and capture target 4 (Upside Down)',
          label: 'REPEAT DOWN RANGE',
          stepNo: '14',
          type: 'capture',
          target: 'UPSIDE 4 DOWN',
        },
        {
          simReportName: '19. Fly up range, align and capture target L',
          label: 'REPEAT UP RANGE',
          stepNo: '15',
          type: 'capture',
          target: 'L',
        },
        {
          simReportName: '20. Yaw 180 degrees and align and capture target 1A',
          label: 'REPEAT UP RANGE',
          stepNo: '16',
          type: 'capture',
          target: '1A',
        },
        {
          simReportName:
            '21. Hover at altitude S, fly down range, align and capture target 4',
          label: 'REPEAT DOWN RANGE',
          stepNo: '17',
          type: 'capture',
          target: '4',
        },
        {
          simReportName: '23. Align and capture target 4 (Upside Down)',
          label: 'REPEAT DOWN RANGE',
          stepNo: '18',
          type: 'capture',
          target: 'UPSIDE 4 DOWN',
        },
        {
          simReportName: '24. Fly up range, align and capture target L',
          label: 'REPEAT UP RANGE',
          stepNo: '19',
          type: 'capture',
          target: 'L',
        },
        {
          simReportName: '25. Yaw 180 degrees and align and capture target 1A',
          label: 'REPEAT UP RANGE',
          stepNo: '20',
          type: 'capture',
          target: '1A',
        },
      ],
    },
    bperp: {
      objectives: [
        {
          simReportName:
            '1. Launch and hover over stand 1 to align with target 1.',
          label: 'Launch and hover over stand 1 to align with target 1',
          stepNo: '1',
          type: 'capture',
          target: '1',
        },
        {
          simReportName: '2. Align to target 2A.',
          label: 'Align to target 2A',
          stepNo: '2',
          type: 'capture',
          target: '2A',
        },
        {
          simReportName: '4. Align to target 1.',
          label: 'Align to target 1',
          stepNo: '3',
          type: 'capture',
          target: '1',
        },
        {
          simReportName: '5. Align to target 2A.',
          label: 'Align to target 2A',
          stepNo: '4',
          type: 'capture',
          target: '2A',
        },
        {
          simReportName: '7. Align to target 1.',
          label: 'Align to target 1',
          stepNo: '5',
          type: 'capture',
          target: '1',
        },
        {
          simReportName: '8. Align to target 2A.',
          label: 'Align to target 2A',
          stepNo: '6',
          type: 'capture',
          target: '2A',
        },
        {
          simReportName:
            '9. Climb vertically over stand 1 to align with target 1.',
          label: 'Climb vertically over stand 1 to align with target 1',
          stepNo: '7',
          type: 'capture',
          target: '1',
        },
        {
          simReportName: '10. Align to taget 3A.',
          label: 'Align to taget 3A',
          stepNo: '8',
          type: 'capture',
          target: '3A',
        },
        {
          simReportName:
            '11. Descend vertically over stand 1 to align to target 1.',
          label: 'Descend vertically over stand 1 to align to target 1',
          stepNo: '9',
          type: 'capture',
          target: '1',
        },
        {
          simReportName: '12. Align to target 2A.',
          label: 'Align to target 2A',
          stepNo: '10',
          type: 'capture',
          target: '2A',
        },
        {
          simReportName: '13. Forward over stand 2, align to target 2.',
          label: 'Forward over stand 2 to align to target 2',
          stepNo: '11',
          type: 'capture',
          target: '2',
        },
        {
          simReportName: '14. Align to target 3A.',
          label: 'Align to target 3A',
          stepNo: '12',
          type: 'capture',
          target: '3A',
        },
        {
          simReportName: '15. Backward over stand 1 to align to target 1.',
          label: 'Backward over stand 1 to align to target 1',
          stepNo: '13',
          type: 'capture',
          target: '1',
        },
        {
          simReportName: '16. Align to target 2A.',
          label: 'Align to target 2A',
          stepNo: '14',
          type: 'capture',
          target: '2A',
        },
        {
          simReportName:
            '17. Forward over stand 2, rotate 180 degrees to align drone to target 2.',
          label:
            'Forward over stand 2, rotate 180 degrees to align drone to target 2',
          stepNo: '15',
          type: 'capture',
          target: '2',
        },
        {
          simReportName: '18. Align to target 1C.',
          label: 'Align to target 1C',
          stepNo: '16',
          type: 'capture',
          target: '1C',
        },
        {
          simReportName:
            '19. Forward over landing, rotate 180 degrees to align to target L.',
          label:
            'Forward over landing, rotate 180 degrees to align to target L',
          stepNo: '17',
          type: 'capture',
          target: 'L',
        },
        {
          simReportName: '20. Align to target 1A.',
          label: 'Align to target 1A',
          stepNo: '18',
          type: 'capture',
          target: '1A',
        },
        {
          simReportName: '21. Land in the circle on the landing pad.',
          label: 'Land in the circle on the landing pad',
          stepNo: '19',
          type: 'landing',
          target: '',
        },
        {
          simReportName:
            '22. Hover over the landing pad to align to target 1A.',
          label: 'Hover over the landing pad to align to target 1A',
          stepNo: '20',
          type: 'capture',
          target: '1A',
        },
        {
          simReportName:
            '23. Orbit 90 degrees leftward around stand 1 to align to target 1B.',
          label:
            'Orbit 90 degrees leftward around stand 1 to align to target 1B',
          stepNo: '21',
          type: 'capture',
          target: '1B',
        },
        {
          simReportName:
            '24. Traverse leftward to stand 2 to align to target 2B.',
          label: 'Traverse leftward to stand 2 to align to target 2B',
          stepNo: '22',
          type: 'capture',
          target: '2B',
        },
        {
          simReportName:
            '25. Traverse leftward to stand 3 to align to target 3B.',
          label: 'Traverse leftward to stand 3 to align to target 3B',
          stepNo: '23',
          type: 'capture',
          target: '3B',
        },
        {
          simReportName:
            '26. Orbit 90 degrees leftward around stand 3 to align to target 3C.',
          label:
            'Orbit 90 degrees leftward around stand 3 to align to target 3C',
          stepNo: '24',
          type: 'capture',
          target: '3C',
        },
        {
          simReportName:
            '27. Orbit 90 degrees leftward around stand 3 to align to target 3D.',
          label:
            'Orbit 90 degrees leftward around stand 3 to align to target 3D',
          stepNo: '25',
          type: 'capture',
          target: '3D',
        },
        {
          simReportName:
            '28. Traverse leftward to stand 2 to align to target 2D.',
          label: 'Traverse leftward to stand 2 to align to target 2D',
          stepNo: '26',
          type: 'capture',
          target: '2D',
        },
        {
          simReportName:
            '29. Traverse leftward to stand 1 to align to target 1D.',
          label: 'Traverse leftward to stand 1 to align to target 1D',
          stepNo: '27',
          type: 'capture',
          target: '1D',
        },
        {
          simReportName:
            '30. Orbit 90 degrees leftward around stand 1 to align to target 1A.',
          label:
            'Orbit 90 degrees leftward around stand 1 to align to target 1A',
          stepNo: '28',
          type: 'capture',
          target: '1A',
        },
        {
          simReportName: '31. Land in the circle on the landing pad.',
          label: 'Land in the circle on the landing pad',
          stepNo: '29',
          type: 'landing',
          target: '',
        },
        {
          simReportName:
            '32. Hover over the landing pad to align to target 1A.',
          label: 'Hover over the landing pad to align to target 1A',
          stepNo: '30',
          type: 'capture',
          target: '1A',
        },
        {
          simReportName:
            '33. Orbit 90 degrees rightward around stand 1 to align to target 1D.',
          label:
            'Orbit 90 degrees rightward around stand 1 to align to target 1D',
          stepNo: '31',
          type: 'capture',
          target: '1D',
        },
        {
          simReportName:
            '34. Traverse rightward to stand 2 to align to target 2D.',
          label: 'Traverse rightward to stand 2 to align to target 2D',
          stepNo: '32',
          type: 'capture',
          target: '2D',
        },
        {
          simReportName:
            '35. Traverse rightward to stand 3 to align to target 3D.',
          label: 'Traverse rightward to stand 3 to align to target 3D',
          stepNo: '33',
          type: 'capture',
          target: '3D',
        },
        {
          simReportName:
            '36. Orbit 90 degrees rightward around stand 3 to align to target 3C.',
          label:
            'Orbit 90 degrees rightward around stand 3 to align to target 3C',
          stepNo: '34',
          type: 'capture',
          target: '3C',
        },
        {
          simReportName:
            '37. Orbit 90 degrees rightward around stand 3 to align to target 3B.',
          label:
            'Orbit 90 degrees rightward around stand 3 to align to target 3B',
          stepNo: '35',
          type: 'capture',
          target: '3B',
        },
        {
          simReportName:
            '38. Traverse rightward to stand 2 to align to target 2B.',
          label: 'Traverse rightward to stand 1 to align to target 2B',
          stepNo: '36',
          type: 'capture',
          target: '2B',
        },
        {
          simReportName:
            '39. Traverse rightward to stand 1 to align to target 1B.',
          label: 'Traverse rightward to stand 1 to align to target 1B',
          stepNo: '37',
          type: 'capture',
          target: '1B',
        },
        {
          simReportName:
            '40. Orbit 90 degrees rightward around stand 1 to align to target 1A.',
          label:
            'Orbit 90 degrees rightward around stand 1 to align to target 1A',
          stepNo: '38',
          type: 'capture',
          target: '1A',
        },
        {
          simReportName: '41. Land in the circle on the landing pad.',
          label: 'Land in the circle on the landing pad',
          stepNo: '39',
          type: 'landing',
          target: '',
        },
      ],
    },
  },
};

export namespace nistGradingCriteria {
  /** ... */
  export namespace Rubric {
    /** ... */
    export type Key = keyof typeof nistGradingCriteria.NIST_TEST_GRADING_RUBRIC;

    /** ... */
    export interface Objective {
      // type: 'capture' | 'landing';
      type: string;
      label: string;
      target: string;
      stepNo: string;
      simReportName: string;
    }
  }

  /** ... */
  export namespace Modules {
    /** ... */
    export type Key = keyof typeof nistGradingCriteria.NIST_TEST_MODULES;
  }
}

export default nistGradingCriteria;
