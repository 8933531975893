import * as models from '@models';

import { openModal } from '../open-modal';

/**
 * Open Submit Unity Catalog modal.
 *
 * @return Modal promise.
 */
export async function submitUnityCatalog() {
  const modalOptions = {
    title: 'SUBMIT UNITY CATALOG',

    component: () => import('./SubmitUnityCatalog.vue'),
  };

  await openModal.safe(modalOptions);
}

interface ApprovalResultModalOptions {
  unityCatalog: models.UnityCatalog;
}

/**
 * Open Approve Result Unity Catalog modal.
 *
 * @return Modal promise.
 */
export async function approveResultUnityCatalog(
  options: ApprovalResultModalOptions,
) {
  const { unityCatalog } = options;
  const modalOptions = {
    title: '',
    props: { unityCatalog },
    component: () => import('./ApproveResultUnityCatalog.vue'),
  };

  await openModal.safe(modalOptions);
}
