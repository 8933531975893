export const CZE = {
  name: {
    common: 'Czech Republic',
    official: 'Czech Republic',
    native: {
      ces: { official: 'česká republika', common: 'Česká republika' },
      slk: { official: 'Česká republika', common: 'Česká republika' },
    },
  },
  cities: [
    'Prague',
    'Chodov',
    'ÃšstÃ­ nad Labem',
    'Jablunkov',
    'ÄŒeskÃ½ TÄ›Å¡Ã­n',
    'Vlcovice',
    'Chrudim',
    'KolÃ­n',
    'Tehov',
    'Koprivnice',
    'Beroun',
    'Horovice',
    'Valasske Klobouky',
    'Bylnice',
    'Slavicin',
    'Poritsch',
    'As',
    'Lanskroun',
    'Chlumcany',
    'Cernosice',
    'Jablonec nad Nisou',
    "Zd'ar nad Sazavou",
    'Bystrice nad Pernstejnem',
    'Semily',
    'Roznov pod Radhostem',
    'Hovezi',
    'Nove Straseci',
    'Pilsen',
    'Naklo',
    'Kyjov',
    'Milotice',
    'Rtyne',
    'Jaroslav',
    'Uhersky Ostroh',
    'Ceska',
    'Horni Bojanovice',
    'HodonÃ­n',
    'Straznice',
    'Raj',
    'Silherovice',
    'Darkovicky',
    'Vsetin',
    'Valasske Mezirici',
    'Strazek',
    'KutnÃ¡ Hora',
    'Troja',
    'Pysely',
    'Brno',
    'Caslav',
    'Ronov nad Doubravou',
    'Chotebor',
    'Rychnov nad Kneznou',
    'Policka',
    'Prosetin',
    'Prelouc',
    'Ledec nad Sazavou',
    'Svetla nad Sazavou',
    'Golcuv Jenikov',
    'Bojanov',
    'Hermanuv Mestec',
    'Nove Mesto nad Metuji',
    'Ostrava',
    'Sudkov',
    'Olomouc',
    'Cestice',
    'DÄ›ÄÃ­n',
    'Citoliby',
    'Zeliv',
    'Karlovy Vary',
    'Zampach',
    'Lichkov',
    'ZlÃ­n',
    'Jablunka',
    'Nymburk',
    'Votice',
    'Podebrady',
    'BÅ™eclav',
    'Lomnice',
    'Nedvedice',
    'Bruntal',
    'Dolni Sucha',
    'Krnov',
    'PÃ­sek',
    'Ostrov',
    'Teplice',
    'Liberec',
    'Vysoke Myto',
    'Jevicko',
    'Ceska Ves',
    'Sumperk',
    'Jihlava',
    "Trest'",
    'Radostin',
    'LitomÄ›Å™ice',
    'TÅ™ebÃ­Ä',
    'Novy Bydzov',
    'Jablonne v Podjestedi',
    'Trstenice',
    'Pardubice',
    'Hodice',
    'Chrastna',
    'Kladno',
    'Brezi',
    'Hosteradice',
    'Jistebnik',
    'Opava',
    'Sezemice',
    'Jaromer',
    'ÄŒeskÃ¡ LÃ­pa',
    'Vamberk',
    'Pribor',
    'Krupka',
    'Milovice',
    'Susice',
    'Nyrsko',
    'Blatna',
    'Velke Hydcice',
    'Tvrdonice',
    'Dolni Bojanovice',
    'Prusanky',
    'Lanzhot',
    'Hluboka nad Vltavou',
    'Chomutov',
    'Litvinov',
    'Krivoklat',
    'Horesedly',
    'Louny',
    'Postoloprty',
    'Kralupy nad Vltavou',
    'MÄ›lnÃ­k',
    'VyÅ¡kov',
    'Drnovice',
    'Bucovice',
    'ProstÄ›jov',
    'HavlÃ­ÄkÅ¯v Brod',
    'Boskovice',
    'Blansko',
    'Tisnov',
    'Cesky Brod',
    'Hulin',
    'Tachov',
    'Bor',
    'MariÃ¡nskÃ© LÃ¡znÄ›',
    'Plana',
    'Siluvky',
    'Kurim',
    'Mesice',
    'Podivin',
    'Bludovice',
    'Dehylov',
    'Velka Bites',
    'Stritez nad Ludinou',
    'Letohrad',
    'Frydlant nad Ostravici',
    'Frydek-Mistek',
    'Nachod',
    'Libeznice',
    'Hradec KrÃ¡lovÃ©',
    'Bernartice nad Odrou',
    'Vitkov',
    'Moran',
    'Petrvald',
    'Jirkov',
    'Zdiby',
    'Cepi',
    'Chrast',
    'Zamberk',
    'Jablonne nad Orlici',
    'Kratonohy',
    'Dobre',
    'Karvina',
    'Lutin',
    'Vsenory',
    'Zabcice',
    'Turnov',
    'Novy Jicin',
    'Mohelnice',
    'Znojmo',
    'Horni Becva',
    'Vladislav',
    'Unetice',
    'Spalene Porici',
    'Bystrice pod Hostynem',
    'Ricany',
    'Stare Mesto',
    'Uhersky Brod',
    'Dolni Nemci',
    'Ceska Trebova',
    'Kostelec nad Orlici',
    'Lysa nad Labem',
    'Dobriv',
    'Velke Mezirici',
    'Hranice',
    'Nyrany',
    'Merklin',
    'Domazlice',
    'Kbely',
    'Mratin',
    'Zajeci',
    'Ejpovice',
    'Opocno',
    'Banov',
    'UherskÃ© HradiÅ¡tÄ›',
    'Bilovice',
    'Spytihnev',
    'Dubne',
    'Telc',
    'Zidlochovice',
    'Albrechtice',
    'Hustopece',
    'Holoubkov',
    'JiÄÃ­n',
    'Chodov',
    'Napajedla',
    'Kunovice',
    'Uhrice',
    'Vacenovice',
    'Svatava',
    'Frenstat pod Radhostem',
    'Sokolov',
    'Cheb',
    'Vysoka nad Labem',
    'Lazne Bohdanec',
    'Most',
    'Å½atec',
    'Klasterec nad Ohri',
    'Dobris',
    'Mnisek pod Brdy',
    'Kraluv Dvur',
    'Tachlovice',
    'Modrice',
    'PÅ™erov',
    'Mnichovice',
    'Babice',
    'MladÃ¡ Boleslav',
    'Hlucin',
    'Strakonice',
    'HavÃ­Å™ov',
    'Lhota',
    'Rychvald',
    'TÃ¡bor',
    'Klatovy',
    'Liban',
    'Brandys nad Labem-Stara Boleslav',
    'Skalsko',
    'Velky Borek',
    'Rokycany',
    'KromÄ›Å™Ã­Å¾',
    'Orlova',
    'Jindrichuv Hradec',
    'Jalubi',
    'Nove Mesto na Morave',
    'Kosmonosy',
    'Rovensko pod Troskami',
    'Bela pod Bezdezem',
    'Rakovnik',
    'Luzna',
    'Vlasim',
    'Skutec',
    'Roudnicek',
    'Roudnice nad Labem',
    'Nedomice',
    'Steti',
    'Rovna',
    'Ctidruzice',
    'Pocatky',
    'Jevisovice',
    'Batelov',
    'Lesonice',
    'Satov',
    'Zeletava',
    'Trinec',
    'Vendryne',
    'Slany',
    'Lodenice',
    'Svojek',
    'Volyne',
    'Veseli nad Moravou',
    'ÄŒeskÃ© BudÄ›jovice',
    'Lhota',
    'Nova Lhota',
    'Ratibor',
    'Zubri',
    'Polna',
    'Grucovice',
    'Velke Heraltice',
    'Vizovice',
    'Nydek',
    'Celadna',
    'Bavory',
    'Moravsky Zizkov',
    'Valtice',
    'Mikulov',
    'Starovicky',
    'Zlata',
    'Vlachovice',
    'Litovel',
    'Jilovec',
    'Moravska Trebova',
    'Chocen',
    'Ladvi',
    'Luhacovice',
    'Janovice nad Uhlavou',
    'Rousinov',
    'Kraslice',
    'Ondrejov',
    'Krasna Lipa',
    'Jenec',
    'Mokrany',
    'Dvur Kralove nad Labem',
    'Marianska',
    'Borsice',
    'Ktis',
    'Predklasteri',
    'Pisecna',
    'Vysker',
    'Velka Polom',
    'Vimperk',
    'Strunkovice nad Blanici',
    'Chlumec nad Cidlinou',
    'Uvaly',
    'Jirny',
    'Sibrina',
    'Doubravy',
    'Bystrice',
    'Hlinsko',
    'Kamenne Zehrovice',
    'Velka Dobra',
    'Pchery',
    'Bustehrad',
    'Tremosna',
    'Mnichovo HradiÅ¡tÄ›',
    'Uhonice',
    'Sany',
    'Rymarov',
    'Malesov',
    'Dubnany',
    'Hysly',
    'Stribro',
    'Stenovice',
    'Vrbno pod Pradedem',
    'Sedlcany',
    'Vrbice',
    'Cejkovice',
    'Usti nad Orlici',
    'Prestice',
    'Holysov',
    'Holice',
    'Pariz',
    'Frystak',
    'Svitavy',
    'Kretin',
    'Slavkov u Brna',
    'Novosedly',
    'Hostomice',
    'Ostopovice',
    'Ostrozska Nova Ves',
    'Stochov',
    'Moravske Budejovice',
    'Cerveny Kostelec',
    'Filipova',
    'Drisy',
    'BeneÅ¡ov',
    'Bukovec',
    'Vsechovice',
    'Horni Pocernice',
    'Benatky nad Jizerou',
    'Krhova',
    'Sadska',
    'Prezletice',
    'Deblin',
    'Hradec nad Moravici',
    'Velke Losiny',
    'Lhota pod Libcany',
    'Hradek',
    'Sivice',
    'Hrusovany nad Jevisovkou',
    'Vinarice',
    'Kamenice',
    'Nehvizdy',
    'Hradiste',
    'Rudna',
    'Cvikov',
    'Ostrov u Macochy',
    'Skripov',
    'Okrisky',
    'Ceska Skalice',
    'Dvorce',
    'Chotesov',
    'Novy Bor',
    'Slavonice',
    'Choustnik',
    'Hrdejovice',
    'Roztoky',
    'Horni Podluzi',
    'Dolni Poustevna',
    'Sumice',
    'Lubenec',
    'Harrachov',
    'Snezne',
    'Letovice',
    'Tremesna',
    'Lysice',
    'Svitavka',
    'Temice',
    'Cechtice',
    'Osecna',
    'Bystrice',
    'Mesto Touskov',
    'Dily',
    'Sobeslav',
    'Lampertice',
    'Rychnov',
    'Hradec',
    'Velka Jesenice',
    'Trutnov',
    'Dolni Radechova',
    'Planany',
    'Dymokury',
    'Chyne',
    'Drzovice',
    'Pisecna',
    'Sternberk',
    'Telnice',
    'Mukarov',
    'Luby',
    'Hranice',
    'Horni Slavkov',
    'Mikulovice',
    'Cernotin',
    'Drahotuse',
    'Senice na Hane',
    'Malenovice',
    'Hostivice',
    'Lovosice',
    'Novy Knin',
    'Maly Ujezd',
    'Stribrna Skalice',
    'Libos',
    'Jilove u Prahy',
    'Chodova Plana',
    'Dolni Sytova',
    'Zasova',
    'Velke Prilepy',
    'Brezova',
    'Trebon',
    'Bernartice',
    'Raspenava',
    'Dolni Kounice',
    'Nove Veseli',
    'Osik',
    'Velke Popovice',
    'Hostomice',
    'Mokra Horakov',
    'Zulova',
    'Stalkov',
    'Chocerady',
    'Doubravnik',
    'Pelhrimov',
    'Pecin',
    'Velky Osek',
    'Kacice',
    'Lhotka',
    'Veverska Bityska',
    'Strachotin',
    'Veseli nad Luznici',
    'Tursko',
    'Kravare',
    'Kolodeje',
    'Prepere',
    'Brusperk',
    'Chribska',
    'Habartov',
    'Velke Brezno',
    'Vsetaty',
    'Sluknov',
    'Varnsdorf',
    'Novy Hrozenkov',
    'Halenkov',
    'Moravska Nova Ves',
    'Zatcany',
    'Tucapy',
    'Slapanice',
    'Bohumin',
    'Hlasna Treban',
    'Jinocany',
    'Horousanky',
    'Lounovice pod Blanikem',
    'Dalovice',
    'Bolebor',
    'Dolni Benesov',
    'Doksy',
    'Svojkov',
    'Kozmice',
    'Trhovy Stepanov',
    'Senov',
    'Vrchlabi',
    'Kamyk',
    'Dobroslavice',
    'Dubi',
    'Kostelec nad Labem',
    'Celakovice',
    'Dubec',
    'Jesenice',
    'Mutenice',
    'Tyniste nad Orlici',
    'Hradek nad Nisou',
    'Olsany',
    'Dlouha Trebova',
    'Kraliky',
    'Nechanice',
    'Kaplice',
    'Bechyne',
    'Milevsko',
    'Kostice',
    'Krumvir',
    'Fulnek',
    'Malcice',
    'Ujezd nad Lesy',
    'Zadni Treban',
    'Trebechovice pod Orebem',
    'Ochoz',
    'Nezamyslice',
    'Boretice',
    'Mlada Vozice',
    'Brod',
    'Kralovice',
    'Kamenice',
    'Syrovice',
    'Rajec-Jestrebi',
    'Vidce',
    'Knezmost',
    'Zvole',
    'Sobotka',
    'Poricany',
    'Msec',
    'Pustejov',
    'Zdeslav',
    'Prachatice',
    'Menany',
    'Vselibice',
    'Bochov',
    'Mladkov',
    'Libocany',
    'Loukov',
    'Hospozin',
    'Rajecko',
    'Nepomysl',
    'Stitina',
    'Lipova',
    'Brodek',
    'Jedovnice',
    'Potstejn',
    'Zabreh',
    'Jaroslavice',
    'Damnice',
    'Sedliste',
    'Hodslavice',
    'Cesky Dub',
    'Loket',
    'Desna',
    'Vyprachtice',
    'Leskovec',
    'Jiloviste',
    'Radnice',
    'Benesov',
    'Lachov',
    'Nova Vcelnice',
    'Tuchomerice',
    'Osek',
    'Pecky',
    'Kamenny Privoz',
    'Krepenice',
    'Stribrec',
    'Velke Opatovice',
    'Chrast',
    'Krizanov',
    'Lichnov (o. Novy Jicin)',
    'Libcany',
    'Branov',
    'Veltrusy',
    'Dolni Brezany',
    'Tynec nad Sazavou',
    'Mirosov',
    'Jirice',
    'Skuhrov nad Belou',
    'Arnoltice',
    'Tetin',
    'Citov',
    'Zehun',
    'Dolni Becva',
    'Stare Sedliste',
    'Smrzovka',
    'Strancice',
    'Mechenice',
    'Ohrobec',
    'Trebestovice',
    'Cervene Pecky',
    'Bilovice nad Svitavou',
    'Starovice',
    'Petrovice u Karvine',
    'Polepy',
    'Cerekvice nad Loucnou',
    'Horsovsky Tyn',
    'Branky',
    'Bratrikov',
    'Nova Paka',
    'Otrokovice',
    'Haj ve Slezsku',
    'Mosty u Jablunkova',
    'Vcelna',
    'Chvalec',
    'Zbuch',
    'Protivin',
    'Domasov',
    'Nove Dvory',
    'Prikazy',
    'Neratovice',
    'Psary',
    'Horomerice',
    'Zipotin',
    'Stoky',
    'Mlazovice',
    'Postrelmov',
    'Radun',
    'Liten',
    'Brezno',
    'Velvary',
    'Citov',
    'Strachotice',
    'Cheznovice',
    'Prlov',
    'Klecany',
    'Ujezd',
    'Hukvaldy',
    'Kostelec na Hane',
    'Krmelin',
    'Borovany',
    'Bilina',
    'PÅ™Ã­bram',
    'Skalice',
    'Rozdrojovice',
    'Duchcov',
    'Bilina',
    'Smirice',
    'Dukovany',
    'Mlade Buky',
    'Horice',
    'Zelezny Brod',
    'Dejvice',
    'Haje',
    'Visnove',
    'Hlubocepy',
    'Krchleby',
    'Stod',
    'Velehrad',
    'Radostice',
    'Ivancice',
    'Hronov',
    'Broumov',
    'Marov u Upice',
    'Police nad Metuji',
    'Zbysov',
    'Kvetnice',
    'Sedlec',
    'Brezolupy',
    'Pacov',
    'Luze',
    'Vernovice',
    'Kralice na Hane',
    'Senec',
    'Stezery',
    'Nivnice',
    'Chotoviny',
    'Nejdek',
    'Malenice',
    'Rozsochatec',
    'Nova Ves',
    'Blovice',
    'Odry',
    'Ricany',
    'Zeleznice',
    'Line',
    'Bystrice',
    'Dolni Berkovice',
    'Bezdekov',
    'Kamenicky Senov',
    'Rebesovice',
    'Dolni Terlicko',
    'Kunratice',
    'Dusejov',
    'Lhota',
    'Zdirec',
    'Dobronin',
    'Kochovice',
    'Nupaky',
    'Frantiskovy Lazne',
    'Nelahozeves',
    'Horni Lutyne',
    'Blizevedly',
    'Trmice',
    'Lisov',
    'Komorni Lhotka',
    'Lomnice nad Popelkou',
    'Perstejn',
    'Krouna',
    'Ostromer',
    'Velke Svatonovice',
    'Horni Briza',
    'Ckyne',
    'Dolni Jircany',
    'Krtiny',
    'Nova Cerekev',
    'Hamr',
    'Merklin',
    'Jirickov',
    'Vinicne Sumice',
    'Doudleby nad Orlici',
    'Bitovany',
    'Vsechovice',
    'Lazne Libverda',
    'Cercany',
    'Predmerice nad Labem',
    'Zdice',
    'Kunstat',
    'Predmerice nad Jizerou',
    'Budyne nad Ohri',
    'Strelice',
    'Olovi',
    'Mostek',
    'Cerncice',
    'Prazmo',
    'Senohraby',
    'Stity',
    'Stankov',
    'Cerhenice',
    'Lustenice',
    'Zlate Hory',
    'Mrlinek',
    'Brodce',
    'Borohradek',
    'Zelesice',
    'Humpolec',
    'Rikovice',
    'Michle',
    'Mirosovice',
    'Starec',
    'Seberov',
    'Mseno',
    'Stara Paka',
    'Moravsky Beroun',
    'Provodov',
    'Obristvi',
    'Lety',
    'Baska',
    'Opatovice nad Labem',
    'Kdyne',
    'Horni Nemci',
    'Veverske Kninice',
    'Kostelni Hlavno',
    'Litava',
    'Kostelec nad Cernymi Lesy',
    'Nesovice',
    'Zborovice',
    'Tisice',
    'Rapotice',
    'Bosin',
    'Choustnikovo Hradiste',
    'Detmarovice',
    'Nedakonice',
    'Havrice',
    'Vracov',
    'Zdounky',
    'Jilemnice',
    'Sloup',
    'Unicov',
    'Vresina',
    'Velka Bystrice',
    'Nucice',
    'Rudikov',
    'Belotin',
    'Rudolfov',
    'Mimon',
    'Kadan',
    'Komarice',
    'Mezibori',
    'Lipnik nad Becvou',
    'Velesin',
    'Mala Skala',
    'Vratkov',
    'Ratiskovice',
    'Hostin',
    'Dobra Voda',
    'Holesov',
    'Cebin',
    'Odolena Voda',
    'Holubice',
    'Vrane nad Vltavou',
    'Markvartovice',
    'Tuchlovice',
    'Ostrozska Lhota',
    'Zeravice',
    'Hradiste',
    'Dobra',
    'Sviadnov',
    'Tosovice',
    'Hudlice',
    'Snedovice',
    'Sestajovice',
    'Bludov',
    'Mcely',
    'Loucen',
    'Strasice',
    'Rozmital pod Tremsinem',
    'Repy',
    'Luka',
    'Hradcovice',
    'Bojkovice',
    'Drzkov',
    'Chroustovice',
    'Navsi u Jablunkova',
    'Zlicin',
    'Predmesti',
    'Kylesovice',
    'Hostice',
    'Ivanovice na Hane',
    'Seibersdorf',
    'Vtelno',
    'Straz nad Nisou',
    'Plouznice',
    'Benesovice',
    'Cista',
    'Adamov',
    'Prackovice nad Labem',
    'Kresice',
    'Rapotin',
    'Jesenik',
    'Rokytnice',
    'Svihov',
    'Milin',
    'Davle',
    'Hajnice',
    'Libochovicky',
    'Hovorcovice',
    'Dolni Zandov',
    'Dubenec',
    'Slapy',
    'Moravsky Krumlov',
    'Libesice',
    'Uzice',
    'Hvozd',
    'Zelenec',
    'Chlupice',
    'Miroslav',
    'Hrusovany u Brna',
    'Oslavany',
    'Otnice',
    'Rudoltice',
    'Tupesy',
    'Polesovice',
    'Dolni Dunajovice',
    'Petrovice',
    'Studenka',
    'Velke Albrechtice',
    'Krnsko',
    'Kouty',
    'Moravsky Pisek',
    'Vysoka',
    'Divisov',
    'Frydlant',
    'Milikov',
    'Rovina',
    'Kotencice',
    'Vojkovice',
    'Rokytnice nad Jizerou',
    'Lasenice',
    'Kacerov',
    'Vratimov',
    'Palkovice',
    'Zdanice',
    'Korycany',
    'Hovorany',
    'Horni Benesov',
    'Bohunovice',
    'Loucany',
    'Kaznejov',
    'Konice',
    'Radim',
    'Nezvestice',
    'Prerov nad Labem',
    'ÄŒeskÃ½ Krumlov',
    'Lomnice nad Luznici',
    'Svaty Jan nad Malsi',
    'Vysoke Veseli',
    'Zelenka',
    'Kourim',
    'Ceske Mezirici',
    'Nikolcice',
    'Borotin',
    'Olomucany',
    'Sebrov',
    'Chlumec',
    'Ruda nad Moravou',
    'Vodnany',
    'Oparany',
    'Zliv',
    'Stare Mesto',
    'Hlubocky',
    'Tesetice',
    'Kuncice',
    'Novy Oldrichov',
    'Straz pod Ralskem',
    'Preckov',
    'Nenkovice',
    'Hostinne',
    'Holasice',
    'Ustin',
    'Dlouhe',
    'Statenice',
    'Blucina',
    'Stepanov',
    'Dobrany',
    'Mezilesi',
    'Pavlice',
    'Klimkovice',
    'Obrany',
    'Stara Voda',
    'Zandov',
    'Pistovice',
    'Libis',
    'Jince',
    'Klicany',
    'Krenovice',
    'Janovice',
    'Horni Redice',
    'Karlin',
    'Zizkov',
    'Lednice',
    'Velky',
    'Primda',
    'Cerna Hora',
    'Vochov',
    'Moravicany',
    'Dubicko',
    'Rosice',
    'Pribyslav',
    'Pruhonice',
    'Velesovice',
    'Krasna Hora nad Vltavou',
    'Sanov',
    'Jezov',
    'Sucha Loz',
    'Milonice',
    'Ritka',
    'Kuncina',
    'LitomyÅ¡l',
    'Vsestary',
    'Stary Bydzov',
    'Dobrichovice',
    'Bolatice',
    'Bradlec',
    'Kopidlno',
    'Chotetov',
    'Kyjov',
    'Lipence',
    'Dobruska',
    'Klobouky',
    'Dolany',
    'Tvarozna',
    'Libice nad Cidlinou',
    'Zilina',
    'Horin',
    'Hrabetice',
    'Rosice',
    'Cerna za Bory',
    'Nasavrky',
    'Koci',
    'Mestec',
    'Brtnice',
    'Cakovice',
    'Podborany',
    'Ujezd',
    'Slusovice',
    'Lukov',
    'Rataje',
    'Francova Lhota',
    'Rybna',
    'Vlastislav',
    'Sterboholy',
    'Tman',
    'Tlumacov',
    'Domazelice',
    'Pocenice',
    'Cejc',
  ],
  tld: ['.cz'],
  cca2: 'CZ',
  ccn3: '203',
  cca3: 'CZE',
  cioc: 'CZE',
  currency: ['CZK'],
  callingCode: ['420'],
  capital: 'Prague',
  altSpellings: ['CZ', 'Česká republika', 'Česko'],
  region: 'Europe',
  subregion: 'Eastern Europe',
  languages: { ces: 'Czech', slk: 'Slovak' },
  translations: {
    cym: { official: 'Czech Republic', common: 'Y Weriniaeth Tsiec' },
    deu: { official: 'Tschechische Republik', common: 'Tschechische Republik' },
    fra: { official: 'République tchèque', common: 'République tchèque' },
    hrv: { official: 'Češka', common: 'Češka' },
    ita: { official: 'Repubblica Ceca', common: 'Repubblica Ceca' },
    jpn: { official: 'チェコ共和国', common: 'チェコ' },
    nld: { official: 'Tsjechische Republiek', common: 'Tsjechië' },
    por: { official: 'República Checa', common: 'República Checa' },
    rus: { official: 'Чешская Республика', common: 'Чехия' },
    spa: { official: 'República Checa', common: 'República Checa' },
    fin: { official: 'Tšekin tasavalta', common: 'Tšekki' },
  },
  latlng: [49.75, 15.5],
  demonym: 'Czech',
  landlocked: true,
  borders: ['AUT', 'DEU', 'POL', 'SVK'],
  area: 78865,
  provinces: [
    'Brnensky',
    'Budejovicky',
    'Jihlavsky',
    'Karlovarsky',
    'Kralovehradecky',
    'Liberecky',
    'Olomoucky',
    'Ostravsky',
    'Pardubicky',
    'Plzensky',
    'Praha',
    'Stredocesky',
    'Ustecky',
    'Zlinsky',
  ],
  nativeName: 'Česká republika',
  timezones: ['UTC+01:00'],
} as const;
