export const AIA = {
  name: {
    common: 'Anguilla',
    official: 'Anguilla',
    native: { eng: { official: 'Anguilla', common: 'Anguilla' } },
  },
  cities: ['The Valley', 'George Hill'],
  tld: ['.ai'],
  cca2: 'AI',
  ccn3: '660',
  cca3: 'AIA',
  cioc: '',
  currency: ['XCD'],
  callingCode: ['1264'],
  capital: 'The Valley',
  altSpellings: ['AI'],
  region: 'Americas',
  subregion: 'Caribbean',
  languages: { eng: 'English' },
  translations: {
    deu: { official: 'Anguilla', common: 'Anguilla' },
    fra: { official: 'Anguilla', common: 'Anguilla' },
    hrv: { official: 'Anguilla', common: 'Angvila' },
    ita: { official: 'Anguilla', common: 'Anguilla' },
    jpn: { official: 'アングィラ', common: 'アンギラ' },
    nld: { official: 'Anguilla', common: 'Anguilla' },
    por: { official: 'Anguilla', common: 'Anguilla' },
    rus: { official: 'Ангилья', common: 'Ангилья' },
    spa: { official: 'Anguila', common: 'Anguilla' },
    fin: { official: 'Anguilla', common: 'Anguilla' },
  },
  latlng: [18.25, -63.16666666],
  demonym: 'Anguillian',
  landlocked: false,
  borders: [],
  area: 91,
} as const;
