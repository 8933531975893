export const SUR = {
  name: {
    common: 'Suriname',
    official: 'Republic of Suriname',
    native: { nld: { official: 'Republiek Suriname', common: 'Suriname' } },
  },
  cities: [
    'Friendship',
    'Paramaribo',
    'Onverwacht',
    'Totness',
    'Nieuw Amsterdam',
    'Moengo',
    'Botopasi',
    'Groningen',
    'Brownsweg',
  ],
  tld: ['.sr'],
  cca2: 'SR',
  ccn3: '740',
  cca3: 'SUR',
  cioc: 'SUR',
  currency: ['SRD'],
  callingCode: ['597'],
  capital: 'Paramaribo',
  altSpellings: [
    'SR',
    'Sarnam',
    'Sranangron',
    'Republic of Suriname',
    'Republiek Suriname',
  ],
  region: 'Americas',
  subregion: 'South America',
  languages: { nld: 'Dutch' },
  translations: {
    deu: { official: 'Republik Suriname', common: 'Suriname' },
    fra: { official: 'République du Suriname', common: 'Surinam' },
    hrv: { official: 'Republika Surinam', common: 'Surinam' },
    ita: { official: 'Repubblica del Suriname', common: 'Suriname' },
    jpn: { official: 'スリナム共和国', common: 'スリナム' },
    nld: { official: 'Republiek Suriname', common: 'Suriname' },
    por: { official: 'República do Suriname', common: 'Suriname' },
    rus: { official: 'Республика Суринам', common: 'Суринам' },
    spa: { official: 'República de Suriname', common: 'Surinam' },
    fin: { official: 'Surinamen tasavalta', common: 'Suriname' },
  },
  latlng: [4, -56],
  demonym: 'Surinamer',
  landlocked: false,
  borders: ['BRA', 'GUF', 'GUY'],
  area: 163820,
  provinces: [
    'Brokopondo',
    'Commewijne',
    'Coronie',
    'Marowijne',
    'Nickerie',
    'Para',
    'Paramaribo',
    'Saramacca',
    'Sipaliwini',
    'Wanica',
  ],
  nativeName: 'Suriname',
  timezones: ['UTC−03:00'],
} as const;
