var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", {
    staticClass: "spacer",
    style: { borderTopStyle: _vm.type },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }