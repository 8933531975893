var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "Overlay",
    {
      class: ["overlay-render-issue", { bordered: _vm.bordered }],
      attrs: { background: "transparent" },
    },
    [
      _c(
        "div",
        { staticClass: "overlay-inner" },
        [
          _c("Icon", { attrs: { icon: _setup.faExclamationCircle } }),
          _vm._v(" "),
          _vm.message
            ? _c("div", { staticClass: "issue-message" }, [
                _vm._v(_vm._s(_vm.message)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _setup.retryButton
            ? _c(
                "b-button",
                {
                  staticClass: "px-5",
                  attrs: { size: "lg", pill: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("retry-requested")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_setup.retryButton.text) + "\n    "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }