import { server } from '@api/request';
import * as models from '@models';
import * as typeGuards from '@tools/type-guards';

type PaginatedReportsResults = ZephyrWeb.Pagination.Results<models.Report>;

interface ReportQueryBaseOptions {
  courseId?: models.Course['id'];
  assignmentId?: models.Assignment['id'];
  sceneId?: models.Scene['id'];
  moduleId?: models.Module['id'];
  /**
   * Optional start date from which to include results.
   *
   *
   * `Note`: date string must be in `local (en-CA)` format.
   * @example
   *
   * var startDate = date.toLocaleDateString('en-CA');
   */
  startDate?: string;
  /**
   * Optional end date before which to include results.
   *
   *
   * `Note`: date string must be in `local (en-CA)` format.
   * @example
   *
   * var endDate = date.toLocaleDateString('en-CA');
   */
  endDate?: string;
  score?: number;
  highest?: boolean;
  completed?: boolean;
  scoreNotZero?: boolean;
  order?: 'ASC' | 'DESC';
  limit?: number;
  startKey?: ZephyrWeb.Pagination.PlacementKey;
}

/**
 * {@link get Get report} API request options.
 */
export interface GetOptions {
  reportId: models.Report['id'];
}

/**
 * Get a specified report.
 *
 * @param options Request options bag.
 * @returns The specified report.
 */
export async function get(options: GetOptions) {
  return await server.get(`v2/reports/${options.reportId}`, processReportData);
}

/**
 * {@link searchForUser Search user reports} API request namespace.
 */
export namespace UserReportSearch {
  /**
   * {@link searchForUser Search user reports} API request options.
   */
  export interface Options extends ReportQueryBaseOptions {
    userId: models.User['id'];
    organizationId?: models.Organization['id'];
  }

  /**
   * {@link searchForUser Search user reports} API request response.
   */
  export type Results = PaginatedReportsResults;
}

/**
 * Search for user report details by a specified criteria (paginated).
 *
 * @param options Request options bag.
 * @returns Search {@link UserReportSearch.Results results} data object.
 *
 * @see {@link ZephyrApi.Admin.Users.searchReportsV2 `SearchReportsV2`} API route in `zephyr-serverless`
 */
export async function searchForUser(options: UserReportSearch.Options) {
  const { userId, ...data } = options;

  return await server.post(
    `v2/admin/users/${userId}/reports/searchV2`,
    data,
    processSearchResults,
  );
}

/**
 * {@link queryForUser Query reports for user} API request options.
 */
export interface QueryForUserOptions {
  userId: models.User['id'];
  organizationId?: models.Organization['id'];
  courseId?: models.Course['id'];
  sceneId?: models.Scene['id'];
  moduleId?: models.Module['id'];
  assignmentId?: models.Assignment['id'];
}

/**
 * Make a query to grab reports for a specified user.
 *
 * @param options Request options bag.
 * @deprecated
 * @returns A list of reports.
 */
export async function queryForUser(options: QueryForUserOptions) {
  const { userId, ...data } = options;

  return await server.query(
    `v2/admin/users/${userId}/reports/search`,
    data,
    processReportData,
  );
}

/**
 * {@link queryForOrganization Query reports for organization} API request options.
 */
export interface QueryForOrganizationOptions {
  organizationId: models.Organization['id'];
  courseId?: models.Course['id'];
  sceneId?: models.Scene['id'];
  moduleId?: models.Module['id'];
  assignmentId?: models.Assignment['id'];
  startDate?: string;
  endDate?: string;
}

/**
 * Make a query to grab reports associated with a specified organization.
 *
 * @param options Request options bag.
 * @returns A list of reports.
 */
export async function queryForOrganization(
  options: QueryForOrganizationOptions,
) {
  const { organizationId, ...data } = options;

  return await server.query(
    `v2/organizations/${organizationId}/reports/search`,
    data,
    processReportData,
  );
}

/**
 * ...
 */
export namespace StudentReportSearch {
  export interface Options extends ReportQueryBaseOptions {
    userId: models.User['id'];
    organizationId: models.Organization['id'];
  }

  export type Results = PaginatedReportsResults;
}

/**
 * Search for student report details by a specified criteria (paginated).
 *
 * @param options Request options bag.
 * @returns Search {@link StudentReportSearch.Results results} data object.
 *
 * @see {@link ZephyrApi.Organizations.searchReportsV2 `SearchReportsV2`} API route in `zephyr-serverless`
 */
export async function searchForStudent(options: StudentReportSearch.Options) {
  const { organizationId, userId, ...data } = options;

  return await server.post(
    `v2/organizations/${organizationId}/users/${userId}/reports/searchV2`,
    data,
    processSearchResults,
  );
}

/**
 * {@link queryForStudent Query reports for student} API request options.
 */
export interface QueryForStudentOptions {
  organizationId: models.Organization['id'];
  userId: models.User['id'];
  courseId?: models.Course['id'];
  assignmentId?: models.Assignment['id'];
  sceneId?: models.Scene['id'];
  moduleId?: models.Module['id'];
}

/**
 * Make a query to grab reports associated with a student belonging to a
 * specified organization.
 *
 * @param options Request options bag.
 * @deprecated
 * @returns A list of reports.
 */
export async function queryForStudent(options: QueryForStudentOptions) {
  const { organizationId, userId, ...data } = options;

  return await server.query(
    `v2/organizations/${organizationId}/users/${userId}/reports/search`,
    data,
    processReportData,
  );
}

export namespace ReportSearch {
  export interface Options extends ReportQueryBaseOptions {
    organizationId?: models.Organization['id'];
  }

  export type Results = PaginatedReportsResults;
}

/**
 * Search for report details by a specified criteria (paginated).
 *
 * @param options Request options bag.
 * @returns Search {@link ReportSearch.Results results} data object.
 *
 * @see {@link ZephyrApi.Users.searchReportsV2 `SearchReportsV2`} API route in `zephyr-serverless`
 */
export async function searchMine(options: ReportSearch.Options = {}) {
  return await server.post(
    `v2/users/reports/searchV2`,
    options,
    processSearchResults,
  );
}

/**
 * {@link queryMine Query my reports} API request options.
 */
export interface QueryMineOptions {
  organizationId?: models.Organization['id'];
  courseId?: models.Course['id'];
  assignmentId?: models.Assignment['id'];
  sceneId?: models.Scene['id'];
  moduleId?: models.Module['id'];
}

/**
 * ...
 *
 * @returns A list of reports.
 *
 * @deprecated
 */
export async function queryMine(options: QueryMineOptions = {}) {
  const reports: models.Report[] = [];

  let startKey: ReportSearch.PlacementKey | null = null;

  do {
    const params: ReportSearch.Options = { ...options, limit: 100 };

    if (startKey) params.startKey = startKey;

    const { items, lastEvaluatedKey } = await searchMine(params);

    reports.push(...items);
    startKey = lastEvaluatedKey ?? null;
  } while (startKey);

  return reports;
}

/**
 * Sync DroneLogbook.
 *
 * @returns Success or error message.
 */
export async function droneLogbookSync() {
  await server.post('v2/users/dronelogbook/sync');
}

/**
 * ...
 */
export interface DecryptOptions {
  reportData: string;
}

/**
 * Decrypt report.
 *
 * @returns Success or error message.
 */
export async function decrypt(options: DecryptOptions) {
  return await server.post<string | object>(
    'v2/admin/reports/decrypt',
    options,
  );
}

//#region Helper Functions

/**
 * ...
 *
 * @param value ...
 * @returns ...
 */
function isValidReportData(value: unknown): value is models.Report {
  if (!typeGuards.isObject(value)) return false;

  return true;
}

/**
 * ...
 *
 * @param data ...
 * @returns ...
 */
function processReportData(data: unknown) {
  if (!isValidReportData(data)) {
    throw new Error('Invalid report data item.');
  }

  return { ...data } as models.Report;
}

/**
 * ...
 *
 * @param value ...
 * @returns ...
 */
function isValidSearchResults(value: unknown): value is ReportSearch.Results {
  return (
    typeGuards.isObject(value) &&
    typeGuards.isArray(value['items']) &&
    (typeGuards.isObject(value['lastEvaluatedKey']) ||
      typeGuards.isNull(value['lastEvaluatedKey']))
  );
}

/**
 * ...
 *
 * @param data ...
 * @returns ...
 */
function processSearchResults(data: unknown) {
  if (!isValidSearchResults(data)) {
    throw new Error('Invalid search result data.');
  }

  return data;
}

//#endregion Helper Functions
