import { delay } from '@tools/delay';

declare module 'vue/types/vue' {
  interface Vue {
    /** ... */
    $promises: PromiseUtilitiesService;
  }
}

class PromiseUtilitiesService {
  /**
   * ...
   *
   * @param promise ...
   * @param ms ...
   */
  async pad<T>(promise: Promise<T>, ms = 750) {
    let value: unknown = undefined;
    let error: unknown = undefined;

    let rejected = false;

    const wrappedPromise = promise.then(
      (v) => {
        value = v;
      },
      (err) => {
        rejected = true;
        error = err;
      },
    );

    await Promise.all([wrappedPromise, delay(ms)]);

    if (rejected) {
      throw error;
    }

    return value as T;
  }
}

/** ... */
export const promises = new PromiseUtilitiesService();

export default promises;
