
import { defineComponent, type PropType } from 'vue';

import FormInput, {
  type Props as FormInputProps,
} from '@components/Form/FormInput.vue';

import { useFormField, FormFieldProps, PROP_OPTIONS } from './form-field';

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    FormFieldInput: ComponentWithProps<Props>;
  }
}

/** ... */
export type Type =
  | 'text'
  | 'number'
  | 'email'
  | 'password'
  | 'search'
  | 'url'
  | 'tel'
  | 'date'
  | 'time'
  | 'range'
  | 'color';

/**
 * `FormFieldInput` component properties.
 */
export interface Props extends FormInputProps, FormFieldProps {
  type?: Type;
}

export default defineComponent({
  name: 'FormFieldInput',
  props: {
    ...PROP_OPTIONS,
    type: {
      type: String as PropType<Type>,
      default: () => 'text',
    },
  },
  setup: (props) => useFormField(props),
});
