var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "product-sale-notice-cell" },
    [
      _c(
        "span",
        { staticClass: "notice-text" },
        [_c(_setup.Icon, { attrs: { icon: _setup.faTriangleExclamation } })],
        1
      ),
      _vm._v(" "),
      _c(_setup.InfoCircle, { attrs: { text: _setup.NOTICE_MESSAGE } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }