export const ROU = {
  name: {
    common: 'Romania',
    official: 'Romania',
    native: { ron: { official: 'România', common: 'România' } },
  },
  cities: [
    'PiteÅŸti',
    'Bucharest',
    'BacÄƒu',
    'Roman',
    'OneÅŸti',
    'Piatra NeamÅ£',
    'GalaÈ›i',
    'ConstanÈ›a',
    'CernavodÄƒ',
    'Tulcea',
    'Navodari',
    'Calarasi',
    'Sibiu',
    'TÃ¢rgu MureÅŸ',
    'RÃ¢mnicu VÃ¢lcea',
    'Cluj-Napoca',
    'Alba Iulia',
    'MediaÅŸ',
    'Petresti',
    'BotoÅŸani',
    'Craiova',
    'BuzÄƒu',
    'Pantelimon',
    'Cuza Voda',
    'Mihai Bravu',
    'Santimbru',
    'BraÅŸov',
    'TimiÅŸoara',
    'Targu Gangulesti',
    'Suceava',
    'Motru',
    'Teiu',
    'Voronet',
    'Giurgiu',
    'Borca',
    'Filipestii de Padure',
    'Cetatea de Balta',
    'Alexandria',
    'Cetatuia',
    'Berzasca',
    'Cernisoara',
    'Miercurea Sibiului',
    'Miercurea-Ciuc',
    'Racoasa',
    'Panciu',
    'Urziceni',
    'Seini',
    'Bucu',
    'Corbeanca',
    'Hunedoara',
    'Ramnicu Sarat',
    'Chiajna',
    'Salcioara',
    'TÃ¢rgoviÅŸte',
    'Floresti',
    'Alba',
    'Pucheni',
    'PloieÅŸti',
    'BistriÅ£a',
    'Turda',
    'Odorheiu Secuiesc',
    'ZalÄƒu',
    'Pascani',
    'Buhusi',
    'Radauti',
    'Musenita',
    'Bocsa',
    'Campina',
    'Falticeni',
    'Buftea',
    'Magurele',
    'Dimitrie Cantemir',
    'FocÅŸani',
    'Garla-Mare',
    'Lehliu-Gara',
    'Jibou',
    'Rosia',
    'Turnu Ruieni',
    'Salatrucel',
    'Blaj',
    'Reghin',
    'PetroÅŸani',
    'Popesti-Leordeni',
    'Tomesti',
    'IaÈ™i',
    'Deva',
    'Campia Turzii',
    'Lunguletu',
    'Slobozia',
    'Mangalia',
    'Titu',
    'Chitila',
    'Campia',
    'Chisoda',
    'BÃ¢rlad',
    'Vaslui',
    'Oradea',
    'TÃ¢rgu Jiu',
    'Hateg',
    'Arad',
    'Bethausen',
    'Simeria',
    'Fogarasch',
    'Huedin',
    'ReÅŸiÅ£a',
    'Cisnadie',
    'Carei',
    'Satu Mare',
    'Curtea de ArgeÅŸ',
    'Victoria',
    'Husi',
    'Orsova',
    'Slatina',
    'Ditrau',
    'Lugoj',
    'Tecuci',
    'Baia Mare',
    'Caransebes',
    'Gaesti',
    'Cugir',
    'Sighetu MarmaÅ£iei',
    'Traian',
    'Mihail Kogalniceanu',
    'Madaras',
    'Decebal',
    'Sovata',
    'Voluntari',
    'Otopeni',
    'Geoagiu',
    'BrÄƒila',
    'Afumati',
    'Schela',
    'Otelu Rosu',
    'Mircea',
    'Obreja',
    'Horia',
    'Rosiori de Vede',
    'Corabia',
    'Turnu Magurele',
    'Gherla',
    'Viseu de Jos',
    'Iernut',
    'Busteni',
    'Sfantu Gheorghe',
    'Dobroesti',
    'Unirea',
    'Spiru Haret',
    'Marginea',
    'Aiud',
    'Berceni',
    'Lacu',
    'Domnesti',
    'Racovita',
    'Zimnicea',
    'Buzias',
    'Moinesti',
    'Bragadiru',
    'Vetresti-Herastrau',
    'Targu Neamt',
    'Sfantu-Gheorghe',
    'Avrig',
    'Mogosoaia',
    'Sighisoara',
    'Crasna',
    'Bogdanita',
    'Baicoi',
    'Harsova',
    'Ungheni',
    'Vidin',
    'Bascov',
    'Racari',
    'Fetesti',
    'Ocna Mures',
    'Covasna',
    'Trestiana',
    'Catcau',
    'Gheorgheni',
    'Medgidia',
    'Nasaud',
    'Somcuta Mare',
    'Lacu Sinaia',
    'Caracal',
    'Magura',
    'Timus',
    'Cristuru Secuiesc',
    'Vacaresti',
    'Cara',
    'Doamna',
    'Ludesti',
    'Gherea',
    'Valea Seaca',
    'Alunu',
    'Lazuri',
    'TÃ¢rnÄƒveni',
    'Moldova Noua',
    'Eforie',
    'Matasari',
    'Crangasi',
    'Tina',
    'Sangeorge',
    'Nucsoara',
    'Bilciuresti',
    'Iorga',
    'Dorohoi',
    'Uioara de Jos',
    'Faget',
    'Bucurestii Noi',
    'Tulnici',
    'Petresti',
    'Catunele',
    'Urlati',
    'Nana',
    'Mizil',
    'Oltenita',
    'Bozieni',
    'Valenii de Munte',
    'Comana',
    'Fabrica',
    'Baba Novac',
    'Adjud',
    'Popesti',
    'Tatarani',
    'Roata de Jos',
    'Moisei',
    'Vedea',
    'Livezi',
    'Falcau',
    'Calan',
    'NÄƒdlac',
    'Bujor',
    'Campeni',
    'Titesti',
    'Bucsani',
    'Masloc',
    'Giarmata',
    'Moreni',
    'Lenauheim',
    'Dudestii Vechi',
    'Voiteg',
    'Tipari',
    'Deta',
    'Becicherecu Mic',
    'Gataia',
    'Dej',
    'Gilau',
    'Daia Romana',
    'Teius',
    'Unirea',
    'Salonta',
    'Tileagd',
    'Alesd',
    'Soimus',
    'Viseu de Sus',
    'Borsa',
    'Baia Sprie',
    'Branistea',
    'Rohia',
    'Sangeorz-Bai',
    'Floresti',
    'Mioveni',
    'Utvin',
    'Tartasesti',
    'Floresti',
    'Bolintin Deal',
    'Tunari',
    'Tandarei',
    'Filias',
    'Sfantu Gheorghe',
    'Apata',
    'Cotnari',
    'Tuzla',
    'Poiana Ilvei',
    'Radovanu',
    'Fundulea',
    'Carbunesti',
    'Costesti',
    'Stalpeni',
    'Stroesti',
    'Voinesti',
    'Comanesti',
    'Iadara',
    'Sancraieni',
    'Agnita',
    'Breaza',
    'Plopeni',
    'Telega',
    'Bobolia',
    'Magurele',
    'Vatra Dornei',
    'Vama',
    'Gramesti',
    'Corlateni',
    'Gura Humorului',
    'Salcea',
    'Codlea',
    'Prejmer',
    'Talisoara',
    'Baraolt',
    'Dor Marunt',
    'Valea Lupului',
    'Birda',
    'Dumbravita',
    'Mocira',
    'Curtici',
    'Tibeni',
    'Topoloveni',
    'Barbuletu',
    'Dridu',
    'Balotesti',
    'Jilava',
    'Chiselet',
    'Ocnita',
    'Albesti-Paleologu',
    'Calinesti',
    'Corbita',
    'Dragotesti',
    'Moara Vlasiei',
    'Marasheshty',
    'Prod',
    'Apahida',
    'Susenii Bargaului',
    'Banesti',
    'Brazii de Sus',
    'Sutesti',
    'Ion Ghica',
    'RÃ¢ÅŸnov',
    'Grojdibodu',
    'Oravita',
    'Fagetu',
    'Giroc',
    'Macin',
    'Sebes',
    'Lancram',
    'Saveni',
    'Bucecea',
    'Padureni',
    'Cristian',
    'Stefanesti',
    'Baile Olanesti',
    'Balomiru de Camp',
    'Glina',
    'Prim Decembrie',
    'Campulung Moldovenesc',
    'Negresti',
    'Ciumani',
    'Potlogi',
    'Stefanestii de Jos',
    'Cojasca',
    'Rabagani',
    'Stanilesti',
    'Videle',
    'Tatarusi',
    'Murfatlar',
    'Calafat',
    'Chisineu-Cris',
    'Mandruloc',
    'Moroda',
    'Cris',
    'Pecica',
    'Cobadin',
    'Nenciulesti',
    'Panduri',
    'Campulung Muscel',
    'Bic',
    'Manta',
    'Bumbesti-Jiu',
    'Baiut',
    'Negru Voda',
    'Cosbuc',
    'Humulesti',
    'Vidra',
    'Filiasi',
    'Izvoru Crisului',
    'Zetea',
    'Giulesti',
    'Odobesti',
    'Hangulesti',
    'Sacele',
    'Marasti',
    'Podu Dambovitei',
    'Siculeni',
    'Valcea',
    'Patarlagele',
    'Matei',
    'Nicolae Balcescu',
  ],
  tld: ['.ro'],
  cca2: 'RO',
  ccn3: '642',
  cca3: 'ROU',
  cioc: 'ROU',
  currency: ['RON'],
  callingCode: ['40'],
  capital: 'Bucharest',
  altSpellings: ['RO', 'Rumania', 'Roumania', 'România'],
  region: 'Europe',
  subregion: 'Eastern Europe',
  languages: { ron: 'Romanian' },
  translations: {
    deu: { official: 'Rumänien', common: 'Rumänien' },
    fra: { official: 'Roumanie', common: 'Roumanie' },
    hrv: { official: 'Rumunija', common: 'Rumunjska' },
    ita: { official: 'Romania', common: 'Romania' },
    jpn: { official: 'ルーマニア', common: 'ルーマニア' },
    nld: { official: 'Roemenië', common: 'Roemenië' },
    por: { official: 'Romênia', common: 'Roménia' },
    rus: { official: 'Румыния', common: 'Румыния' },
    spa: { official: 'Rumania', common: 'Rumania' },
    fin: { official: 'Romania', common: 'Romania' },
  },
  latlng: [46, 25],
  demonym: 'Romanian',
  landlocked: false,
  borders: ['BGR', 'HUN', 'MDA', 'SRB', 'UKR'],
  area: 238391,
  provinces: [
    'Alba',
    'Arad',
    'Arges',
    'Bacau',
    'Bihor',
    'Bistrita-Nasaud',
    'Botosani',
    'Braila',
    'Brasov',
    'Bucuresti',
    'Buzau',
    'Calarasi',
    'Caras-Severin',
    'Cluj',
    'Constanta',
    'Covasna',
    'Dimbovita',
    'Dolj',
    'Galati',
    'Giurgiu',
    'Gorj',
    'Harghita',
    'Hunedoara',
    'Ialomita',
    'Iasi',
    'Maramures',
    'Mehedinti',
    'Mures',
    'Neamt',
    'Olt',
    'Prahova',
    'Salaj',
    'Satu Mare',
    'Sibiu',
    'Suceava',
    'Teleorman',
    'Timis',
    'Tulcea',
    'Vaslui',
    'Vilcea',
    'Vrancea',
  ],
  nativeName: 'România',
  timezones: ['UTC+02:00'],
} as const;
