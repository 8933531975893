export const SOM = {
  name: {
    common: 'Somalia',
    official: 'Federal Republic of Somalia',
    native: {
      ara: { official: 'جمهورية الصومال‎‎', common: 'الصومال‎‎' },
      som: {
        official: 'Jamhuuriyadda Federaalka Soomaaliya',
        common: 'Soomaaliya',
      },
    },
  },
  cities: ['Hargeysa', 'Mogadishu'],
  tld: ['.so'],
  cca2: 'SO',
  ccn3: '706',
  cca3: 'SOM',
  cioc: 'SOM',
  currency: ['SOS'],
  callingCode: ['252'],
  capital: 'Mogadishu',
  altSpellings: [
    'SO',
    'aṣ-Ṣūmāl',
    'Federal Republic of Somalia',
    'Jamhuuriyadda Federaalka Soomaaliya',
    'Jumhūriyyat aṣ-Ṣūmāl al-Fiderāliyya',
  ],
  region: 'Africa',
  subregion: 'Eastern Africa',
  languages: { ara: 'Arabic', som: 'Somali' },
  translations: {
    deu: { official: 'Bundesrepublik Somalia', common: 'Somalia' },
    fra: { official: 'République fédérale de Somalie', common: 'Somalie' },
    hrv: { official: 'Savezna Republika Somaliji', common: 'Somalija' },
    ita: { official: 'Repubblica federale di Somalia', common: 'Somalia' },
    jpn: { official: 'ソマリア連邦共和国', common: 'ソマリア' },
    nld: { official: 'Federale Republiek Somalië', common: 'Somalië' },
    por: { official: 'República Federal da Somália', common: 'Somália' },
    rus: { official: 'Федеративная Республика Сомали', common: 'Сомали' },
    spa: { official: 'República Federal de Somalia', common: 'Somalia' },
    fin: { official: 'Somalian liittotasavalta', common: 'Somalia' },
  },
  latlng: [10, 49],
  demonym: 'Somali',
  landlocked: false,
  borders: ['DJI', 'ETH', 'KEN'],
  area: 637657,
  provinces: [
    'Awdal',
    'Bakool',
    'Banaadir',
    'Bari',
    'Bay',
    'Galguduud',
    'Gedo',
    'Hiiraan',
    'Jubbada Dhexe',
    'Jubbada Hoose',
    'Mudug',
    'Nugaal',
    'Sanaag',
    'Shabeellaha Dhexe',
    'Shabeellaha Hoose',
    'Sool',
    'Togdheer',
    'Woqooyi Galbeed',
  ],
  nativeName: 'Soomaaliya',
  timezones: ['UTC+03:00'],
} as const;
