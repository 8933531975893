export const LCA = {
  name: {
    common: 'Saint Lucia',
    official: 'Saint Lucia',
    native: { eng: { official: 'Saint Lucia', common: 'Saint Lucia' } },
  },
  cities: [
    'Castries',
    'Dauphin',
    'Vieux Fort',
    'Anse La Raye',
    'Gros Islet',
    'Choiseul',
  ],
  tld: ['.lc'],
  cca2: 'LC',
  ccn3: '662',
  cca3: 'LCA',
  cioc: 'LCA',
  currency: ['XCD'],
  callingCode: ['1758'],
  capital: 'Castries',
  altSpellings: ['LC'],
  region: 'Americas',
  subregion: 'Caribbean',
  languages: { eng: 'English' },
  translations: {
    deu: { official: 'St. Lucia', common: 'Saint Lucia' },
    fra: { official: 'Sainte-Lucie', common: 'Sainte-Lucie' },
    hrv: { official: 'Sveta Lucija', common: 'Sveta Lucija' },
    ita: { official: 'Santa Lucia', common: 'Santa Lucia' },
    jpn: { official: 'セントルシア', common: 'セントルシア' },
    nld: { official: 'Saint Lucia', common: 'Saint Lucia' },
    por: { official: 'Santa Lúcia', common: 'Santa Lúcia' },
    rus: { official: 'Сент-Люсия', common: 'Сент-Люсия' },
    spa: { official: 'Santa Lucía', common: 'Santa Lucía' },
    fin: { official: 'Saint Lucia', common: 'Saint Lucia' },
  },
  latlng: [13.88333333, -60.96666666],
  demonym: 'Saint Lucian',
  landlocked: false,
  borders: [],
  area: 616,
} as const;
