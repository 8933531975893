export const SXM = {
  name: {
    common: 'Sint Maarten',
    official: 'Sint Maarten',
    native: {
      eng: { official: 'Sint Maarten', common: 'Sint Maarten' },
      fra: { official: 'Saint-Martin', common: 'Saint-Martin' },
      nld: { official: 'Sint Maarten', common: 'Sint Maarten' },
    },
  },
  cities: ['Philipsburg'],
  tld: ['.sx'],
  cca2: 'SX',
  ccn3: '534',
  cca3: 'SXM',
  cioc: '',
  currency: ['ANG'],
  callingCode: ['1721'],
  capital: 'Philipsburg',
  altSpellings: ['SX', 'Sint Maarten (Dutch part)'],
  region: 'Americas',
  subregion: 'Caribbean',
  languages: { eng: 'English', fra: 'French', nld: 'Dutch' },
  translations: {
    deu: { official: 'Sint Maarten', common: 'Sint Maarten' },
    fra: { official: 'Sint Maarten', common: 'Saint-Martin' },
    ita: { official: 'Sint Maarten', common: 'Sint Maarten' },
    jpn: { official: 'シントマールテン島', common: 'シント・マールテン' },
    nld: { official: 'Sint Maarten', common: 'Sint Maarten' },
    por: { official: 'Sint Maarten', common: 'São Martinho' },
    rus: { official: 'Синт-Маартен', common: 'Синт-Мартен' },
    spa: { official: 'Sint Maarten', common: 'Sint Maarten' },
    fin: { official: 'Sint Maarten', common: 'Sint Maarten' },
  },
  latlng: [18.033333, -63.05],
  demonym: 'St. Maartener',
  landlocked: false,
  borders: ['MAF'],
  area: 34,
} as const;
