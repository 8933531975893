import { api } from '@api';
import * as models from '@models';

import { openModal } from '../open-modal';

/**
 * ...
 */
export interface CreateAnnouncmentModalOptions {
  announcement: models.Announcement;
}

/**
 * Open Create Announcements modal.
 *
 * @return Modal promise.
 */
export async function create(options?: CreateAnnouncmentModalOptions) {
  let targetCourse: models.Course | null = null;

  const courseId = options?.announcement.course?.id;
  const organizationId = options?.announcement.organization?.id;

  if (courseId && organizationId) {
    targetCourse = await api.courses.get({ courseId, organizationId });
  }

  const modalOptions = {
    title: 'CREATE ANNOUNCEMENT',
    props: {
      mode: 'create',
      announcement: options?.announcement ?? null,
      targetCourse: targetCourse ?? null,
    },
    component: () => import('./ManageAnnouncement.vue'),
  };

  await openModal.safe(modalOptions);
}

/**
 * ...
 */
export interface EditAnnouncmentModalOptions {
  announcement: models.Announcement;
}

/**
 * Open Edit Announcements modal.
 *
 * @return Modal promise.
 */
export async function edit(options: EditAnnouncmentModalOptions) {
  let targetCourse: models.Course | null = null;

  const courseId = options.announcement.course?.id;
  const organizationId = options.announcement.organization?.id;

  if (courseId && organizationId) {
    targetCourse = await api.courses.get({ courseId, organizationId });
  }

  const modalOptions = {
    title: 'EDIT ANNOUNCEMENT',
    props: {
      mode: 'edit',
      announcement: options.announcement,
      targetCourse: targetCourse ?? null,
    },
    component: () => import('./ManageAnnouncement.vue'),
  };

  await openModal.safe(modalOptions);
}

/**
 * ...
 */
export interface ViewAnnouncmentModalOptions {
  announcement: models.Announcement;
}

/**
 * Open View Announcements modal.
 *
 * @return Modal promise.
 */
export async function view(options: ViewAnnouncmentModalOptions) {
  const modalOptions = {
    title: 'VIEW ANNOUNCEMENT',
    props: { ...options },
    component: () => import('./ViewAnnouncement.vue'),
  };

  await openModal.safe(modalOptions);
}
