import { Vue, Component, Inject } from '@vue';

import type { ModalState } from './modal-state';

import ModalHeader from './ModalHeader.vue';
import ModalBody from './ModalBody.vue';
import ModalFooter from './ModalFooter.vue';

@Component({
  components: {
    ModalHeader,
    ModalBody,
    ModalFooter,
  },
})
/**
 * @depreciated
 */
export class UibModal extends Vue {
  @Inject('modal') readonly modal!: ModalState;

  get $title() {
    return this.modal.title;
  }

  get $dismissible() {
    return this.modal.dismissible;
  }

  get $size() {
    return this.modal.size;
  }

  get $fullscreen() {
    return this.modal.fullscreen;
  }

  get $toggleFullscreen() {
    return this.modal.toggleFullscreen;
  }

  get $close() {
    return this.modal.close;
  }

  get $dismiss() {
    return this.modal.dismiss;
  }

  get $kill() {
    return this.modal.kill;
  }
}
