export const GIB = {
  name: {
    common: 'Gibraltar',
    official: 'Gibraltar',
    native: { eng: { official: 'Gibraltar', common: 'Gibraltar' } },
  },
  cities: ['Gibraltar'],
  tld: ['.gi'],
  cca2: 'GI',
  ccn3: '292',
  cca3: 'GIB',
  cioc: '',
  currency: ['GIP'],
  callingCode: ['350'],
  capital: 'Gibraltar',
  altSpellings: ['GI'],
  region: 'Europe',
  subregion: 'Southern Europe',
  languages: { eng: 'English' },
  translations: {
    deu: { official: 'Gibraltar', common: 'Gibraltar' },
    fra: { official: 'Gibraltar', common: 'Gibraltar' },
    hrv: { official: 'Gibraltar', common: 'Gibraltar' },
    ita: { official: 'Gibilterra', common: 'Gibilterra' },
    jpn: { official: 'ジブラルタル', common: 'ジブラルタル' },
    nld: { official: 'Gibraltar', common: 'Gibraltar' },
    por: { official: 'Gibraltar', common: 'Gibraltar' },
    rus: { official: 'Гибралтар', common: 'Гибралтар' },
    spa: { official: 'Gibraltar', common: 'Gibraltar' },
    fin: { official: 'Gibraltar', common: 'Gibraltar' },
  },
  latlng: [36.13333333, -5.35],
  demonym: 'Gibraltar',
  landlocked: false,
  borders: ['ESP'],
  area: 6,
} as const;
