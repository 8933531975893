export const PAK = {
  name: {
    common: 'Pakistan',
    official: 'Islamic Republic of Pakistan',
    native: {
      eng: { official: 'Islamic Republic of Pakistan', common: 'Pakistan' },
      urd: { official: 'اسلامی جمہوریۂ پاكستان', common: 'پاكستان' },
    },
  },
  cities: [
    'Gulshan-e-Iqbal',
    'Habib Baihk',
    'Karachi',
    'Ziauddin',
    'Okara',
    'Islamabad',
    'Rawalpindi',
    'Khanpur',
    'Jhelum',
    'SwÄbi',
    'Lahore',
    'Faisalabad',
    'Cantt',
    'Gujar Khan',
    'Attock',
    'Goth Abad Magsi',
    'Kasur',
    'Nangar',
    'Sheikhupura',
    'Sialkot',
    'Mandi Bahauddin',
    'Gujrat',
    'Wazirabad',
    'Narowal',
    'Sargodha',
    'Mianwali',
    'Daud Khel',
    'BahÄwalpur',
    'Burewala',
    'Abbottabad',
    'Batgram',
    'Havelian',
    'Haripur',
    'Mansehra',
    'Plot',
    'Hyderabad',
    'Miran Shah',
    'Peshawar',
    'Gujranwala',
    'Multan',
    'Quetta',
    'Khan',
    'Kabirwala',
    'Fazal',
    'Clifton',
    'Sarwar',
    'New Mirpur',
    'FÄtima',
    'Saddar',
    'Gulberg',
    'Gilgit',
    'Muzaffarabad',
    'Sarai Sidhu',
    'Dera Ghazi Khan',
    'Sahiwal',
    'Chakwal',
    'Bhimbar',
    'Sukkur',
    'Mandi',
    'Usman',
    'ChÄrsadda',
    'Nowshera',
    'Mardan',
    'Mian Channu',
    'Khanewal',
    'Jhang Sadr',
    'Jhang City',
    'Toba Tek Singh',
    'Jhumra',
    'Daska',
    'Kohat',
    'Nankana Sahib',
    'Pindi',
    'Rawlakot',
  ],
  tld: ['.pk'],
  cca2: 'PK',
  ccn3: '586',
  cca3: 'PAK',
  cioc: 'PAK',
  currency: ['PKR'],
  callingCode: ['92'],
  capital: 'Islamabad',
  altSpellings: [
    'PK',
    'Pākistān',
    'Islamic Republic of Pakistan',
    "Islāmī Jumhūriya'eh Pākistān",
  ],
  region: 'Asia',
  subregion: 'Southern Asia',
  languages: { eng: 'English', urd: 'Urdu' },
  translations: {
    deu: { official: 'Islamische Republik Pakistan', common: 'Pakistan' },
    fra: { official: 'République islamique du Pakistan', common: 'Pakistan' },
    hrv: { official: 'Islamska Republika Pakistan', common: 'Pakistan' },
    ita: { official: 'Repubblica islamica del Pakistan', common: 'Pakistan' },
    jpn: { official: 'パキスタン', common: 'パキスタン' },
    nld: { official: 'Islamitische Republiek Pakistan', common: 'Pakistan' },
    por: { official: 'República Islâmica do Paquistão', common: 'Paquistão' },
    rus: { official: 'Исламская Республика Пакистан', common: 'Пакистан' },
    spa: { official: 'República Islámica de Pakistán', common: 'Pakistán' },
    fin: { official: 'Pakistanin islamilainen tasavalta', common: 'Pakistan' },
  },
  latlng: [30, 70],
  demonym: 'Pakistani',
  landlocked: false,
  borders: ['AFG', 'CHN', 'IND', 'IRN'],
  area: 881912,
  provinces: [
    'Balochistan',
    'Federally Administered Tribal Areas',
    'Islamabad Capital Territory',
    'North-West Frontier Province',
    'Punjab',
    'Sindh',
  ],
  nativeName: 'Pakistan',
  timezones: ['UTC+05:00'],
} as const;
