import { NotificationItem } from './Notifications.vue';

/** ... */
const DIRECTIONS = {
  x: ['left', 'center', 'right'],
  y: ['top', 'bottom'],
} as const;

/** ... */
export type HorizontalDirection = typeof DIRECTIONS['x'][number];
/** ... */
export type VerticalDirection = typeof DIRECTIONS['y'][number];

/** Sequential ID generator. */
export const generateUid = ((i = 0) => {
  return () => i++;
})();

/**
 * Splits space/tab separated string into array and cleans empty string items.
 */
export const split = (value: unknown) => {
  return typeof value === 'string' ? value.split(/\s+/gi).filter((v) => v) : [];
};

/**
 * Cleanse and transforms string of format `"x y"` into object `{ x, y }`.
 * Possible combinations:
 *   x - `left`, `center`, `right`
 *   y - `top`, `bottom`
 */
export const listToDirection = (value: string | string[]) => {
  const dirs = typeof value === 'string' ? split(value) : value;

  let x: HorizontalDirection | null = null;
  let y: VerticalDirection | null = null;

  for (const dir of dirs) {
    if (isVerticalDirection(dir)) y = dir;
    if (isHorizontalDirection(dir)) x = dir;
  }

  return { x, y };
};

/**
 * ...
 */
export class Timer {
  readonly #callback: Timer.Callback;
  readonly #item: NotificationItem;

  #start: number;
  #remaining: number;

  constructor(callback: Timer.Callback, delay: number, item: NotificationItem) {
    this.#callback = (...args: unknown[]) => callback(...args);
    this.#item = item;

    this.#start = 0;
    this.#remaining = delay;

    this.resume();
  }

  /**
   * ...
   */
  pause() {
    clearTimeout(this.#item.timer);

    this.#remaining -= Date.now() - this.#start;
  }

  /**
   * ...
   */
  resume() {
    this.#start = Date.now();

    window.clearTimeout(this.#item.timer);

    this.#item.timer = window.setTimeout(this.#callback, this.#remaining);
  }
}

export namespace Timer {
  /** ... */
  export type Callback = (...args: unknown[]) => void;
}

//#region Helper Functions

function isHorizontalDirection(value: unknown): value is HorizontalDirection {
  return (
    typeof value === 'string' &&
    (DIRECTIONS.x as unknown as string[]).includes(value)
  );
}

function isVerticalDirection(value: unknown): value is VerticalDirection {
  return (
    typeof value === 'string' &&
    (DIRECTIONS.y as unknown as string[]).includes(value)
  );
}

//#endregion Helper Functions
