import { server } from '@api/request';
import { Scene, Organization, Module } from '@models';
import { isObject } from '@tools/type-guards';

/**
 * List all existing scenes.
 *
 * @returns A list of scenes.
 *
 * @see {@link ZephyrApi.Admin.Scenes.listScenes `AdminListScenes`} API route in `zephyr-serverless`
 */
export async function list() {
  return await server.list('v2/admin/scenes', processSceneData);
}

/**
 * List all existing scenes.
 *
 * @returns A list of scenes.
 *
 * @see {@link ZephyrApi.Scenes.listActiveScenes `ListActiveScenes`} API route in `zephyr-serverless`
 */
export async function listActive() {
  return await server.list('v2/scenes/active', processSceneData);
}

/**
 * {@link listByOrganization List organization scenes} API request options.
 */
export interface ListByOrganizationOptions {
  organizationId: Organization['id'];
}

/**
 * List all scenes accessible to a specified organization.
 *
 * @returns A list of scenes.
 *
 * @see {@link ZephyrApi.Organizations.listScenes `ListOrganizationScenes`} API route in `zephyr-serverless`
 */
export async function listByOrganization(options: ListByOrganizationOptions) {
  return await server.list(
    `v2/organizations/${options.organizationId}/scenes`,
    processSceneData,
  );
}

/**
 * {@link get Get scenes} API request options.
 */
export interface GetSceneOptions {
  sceneId: Scene['id'];
}

/**
 * Get a specified scene.
 *
 * @param options Request options bag.
 * @returns The specified scene.
 *
 * @see {@link ZephyrApi.Scenes.getScene `GetScene`} API route in `zephyr-serverless`
 */
export async function get(options: GetSceneOptions) {
  return await server.get(`v2/scenes/${options.sceneId}`, processSceneData);
}

/**
 * {@link create Create scenes} API request options.
 */
export interface CreateSceneOptions {
  id: string;
  name: string;
  info?: string;
  active?: boolean;
  isPrivate?: boolean;
}

/**
 * Create a scene.
 *
 * @param options Request options bag.
 * @returns The created scene
 *
 * @see {@link ZephyrApi.Admin.Scenes.createScene `AdminCreateScene`} API route in `zephyr-serverless`
 */
export async function create(options: CreateSceneOptions) {
  return await server.post(`v2/admin/scenes`, options, processSceneData);
}

/**
 * {@link update Update scenes} API request options.
 */
export interface UpdateSceneOptions {
  sceneId: Scene['id'];
  name?: string;
  info?: string;
  active?: boolean;
  isPrivate?: boolean;
}

/**
 * Update a specified scene.
 *
 * @param options Request options bag.
 * @returns The updated scene.
 *
 * @see {@link ZephyrApi.Admin.Scenes.updateScene `AdminUpdateScene`} API route in `zephyr-serverless`
 */
export async function update(options: UpdateSceneOptions) {
  const { sceneId, ...data } = options;

  return await server.post(
    `v2/admin/scenes/${sceneId}`,
    data,
    processSceneData,
  );
}

/**
 * {@link del Delete scenes} API request options.
 */
export interface DeleteSceneOptions {
  sceneId: Scene['id'];
}

/**
 * Delete a specified scene.
 *
 * @param options Request options bag.
 *
 * @see {@link ZephyrApi.Admin.Scenes.deleteScene `AdminDeleteScene`} API route in `zephyr-serverless`
 */
export async function del(options: DeleteSceneOptions) {
  await server.delete(`v2/admin/scenes/${options.sceneId}`);
}

/**
 * {@link addModules Add modules to scene} API request options.
 */
export interface AddRemoveSceneModulesOptions {
  sceneId: Scene['id'];
  modules: Module['id'][];
}

/**
 * Add modules to a scene.
 *
 * @param options Request options bag.
 *
 * @see {@link ZephyrApi.Admin.Scenes.addModules `AdminAddModules`} API route in `zephyr-serverless`
 */
export async function addModules(options: AddRemoveSceneModulesOptions) {
  await server.post(`v2/admin/scenes/${options.sceneId}/add-modules`, {
    modules: options.modules,
  });
}

/**
 * Remove modules from a scene.
 *
 * @param options Request options bag.
 *
 * @see {@link ZephyrApi.Admin.Scenes.removeModules `AdminRemoveModules`} API route in `zephyr-serverless`
 */
export async function removeModules(options: AddRemoveSceneModulesOptions) {
  await server.post(`v2/admin/scenes/${options.sceneId}/remove-modules`, {
    modules: options.modules,
  });
}

//#region Helper Functions

/**
 * ...
 *
 * @param value ...
 * @returns ...
 */
function isValidSceneData(value: unknown): value is Scene {
  if (!isObject(value)) return false;

  // TEMP: Use a more strict validation.

  return true;
}

/**
 * ...
 *
 * @param data ...
 * @returns ...
 */
function processSceneData(data: unknown) {
  if (!isValidSceneData(data)) {
    throw new Error('Invalid scene data item.');
  }

  return { ...data } as Scene;
}

//#endregion Helper Functions
