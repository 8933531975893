export const TUR = {
  name: {
    common: 'Turkey',
    official: 'Republic of Turkey',
    native: { tur: { official: 'Türkiye Cumhuriyeti', common: 'Türkiye' } },
  },
  cities: [
    'Izmir',
    'Ankara',
    'Antakya',
    'Dortyol',
    'Ä°skenderun',
    'Bitlis',
    'Samsun',
    'Istanbul',
    'Antalya',
    'Kisir',
    'Orhangazi',
    'Bursa',
    'Gebze',
    'Trabzon',
    'Ordu',
    'Rize',
    'DiyarbakÄ±r',
    'Ä°zmit',
    'Mersin',
    'Etimesgut',
    'Adana',
    'AdapazarÄ±',
    'Sincan',
    'Eskisehir',
    'Sisli',
    'Magnesia ad Sipylum',
    'Bergama',
    'Tire',
    'Balcova',
    'Konya',
    'Kartal',
    'Kibriskoy',
    'Mahmutkoy',
    'Edirne',
    'Denizli',
    'Zonguldak',
    'Muratpasa',
    'Sisman',
    'Kirikkale',
    'Karamursel',
    'Atakoy',
    'Bodrum',
    'Fatih',
    'Kadikoy',
    'Kayseri',
    'Acibadem',
    'Ã‡orlu',
    'Erzurum',
    'Sanayi',
    'Sanliurfa',
    'Dogankoy',
    'Kars',
    'Mugla',
    'Isparta',
    'Bulut',
    'Girne',
    'Bogazici',
    'Ugur',
    'Bilgi',
    'Osmaniye',
    'Afyonkarahisar',
    'Malatya',
    'Mardin',
    'KahramanmaraÅŸ',
    'Batman',
    'NevÅŸehir',
    'KÄ±rÅŸehir',
    'Amasya',
    'Yalova',
    'BalÄ±kesir',
    'Doga',
    'Merzifon',
    'TekirdaÄŸ',
    'Gaziantep',
    'Bandirma',
    'Kadikoey',
    'Maltepe',
    'Erzincan',
    'Guzelyurt',
    'Ã‡anakkale',
    'Golmarmara',
    'Ã‡erkezkÃ¶y',
    'Sultanpinar',
    'Dogus',
    'Burgaz',
    'Aydogan',
    'Bolu',
    'Bilecik',
    'Duzce',
    'Siirt',
    'Cermik',
    'Seydisehir',
    'Kastamonu',
    'Kecioeren',
    'Demetevleri',
    'Yaman',
    'GÃ¼mÃ¼ÅŸhane',
    'Bayburt',
    'ArdeÅŸen',
    'Giresun',
    'Sivas',
    'Aydin',
    'Ceyhan',
    'Ada',
    'Kozan',
    'Seyhan',
    'MuÅŸ',
    'Ardahan',
    'Manavgat',
    'Burdur',
    'Bartin',
    'Iskilip',
    'Ã‡orum',
    'Sehitkamil',
    'Yozgat',
    'Calkaya',
    'NiÄŸde',
    'Van',
    'Aksaray',
    'Ercis',
    'Nizip',
    'Tunceli',
    'KarabÃ¼k Province',
    'Karabuk',
    'Hakkari',
    'GÃ¶rele',
    'Tokat Province',
    'Artvin',
    'Niksar',
    'Kadirli',
    'Cekme',
    'Kucukcekmece',
    'Niluefer',
    'AÄŸrÄ±',
    'UEskuedar',
    'Pendik',
    'Cine',
    'Selcuk',
    'Umraniye',
    'UÅŸak',
    'Banaz',
    'Konak',
    'Nazilli',
    'Mustafakemalpasa',
    'Gemlik',
    'Ä°negÃ¶l',
    'LÃ¼leburgaz',
    'Uchisar',
    'Urgub',
    'Tesvikiye',
    'KÄ±rklareli',
    'Esenyurt',
    'Silifke',
    'Siliviri',
    'Bueyuekcekmece',
    'Bayrampasa',
    'Zeytinburnu',
    'Uzun Keupru',
    'Edremit',
    'Soeke',
    'Kusadasi',
    'Erbaa',
    'Tarsus',
    'Karaman',
    'ElÃ¢zÄ±ÄŸ',
    'Besiktas',
    'KÃ¼tahya',
    'SÃ¼rmene',
    'Gelibolu',
    'Karatekin',
    'Sirnak',
    'Alpaslan',
    'Mehmet Akif Ersoy',
    'Harran',
    'Yagcilar',
    'Sinop',
    'Bingol',
    'Marmaris',
    'Ã‡ankÄ±rÄ±',
    'Patnos',
    'Maslak',
    'Kagithane',
    'Yasarkoy',
    'Harbiye',
    'Ulker',
    'Safranbolu',
    'Taksim',
    'Beykoz',
    'Bor',
    'Yuregir',
    'Germencik',
    'Torbali',
    'Ikitelli',
    'Zekeriya',
    'Amasra',
    'Ayazaga',
    'Karaca',
    'Bozuyuk',
    'Darica',
    'Geulzuk',
    'Elbistan',
    'Findikli',
    'Osmaneli',
    'Camliyayla',
    'Sahinbey',
    'Baskil',
    'Kumluca',
    'Bucak',
    'Erzin',
    'Meric',
    'Sariyer',
    'Akhisar',
    'Turgutlu',
    'Biga',
    'Salihli',
    'Burhaniye',
    'Gonen',
    'Hendek',
    'Dalaman',
    'Milas',
    'Fethiye',
    'Urla',
    'Seferihisar',
    'Bornova',
    'Buca',
    'AkÃ§aabat',
    'Yakakoy',
    'Pamukkale',
    'Sile',
    'Unieh',
    'Beykent',
    'Kocatepe',
    'IÅŸÄ±k',
    'Yeni',
    'Gunbuldu',
    'Ostim',
    'Tasdelen',
    'Esenkent',
    'Esentepe',
    'Mezitli',
    'Ibrahim',
    'Erenkoy',
    'Kuzey',
    'Belek',
    'Sungurlu',
    'Alanya',
    'Serik',
    'Babaeski',
    'Serdivan',
    'Yildiz',
    'Suleyman',
    'Ataturk',
    'Abdullah',
    'Doruklu',
    'Mustafa Kemal',
    'Bitam',
    'Toros',
    'Elek',
    'Dokuz',
    'Kocakoy',
    'Muradiye',
    'Kilis',
    'Korfez',
    'Andac',
    'Birbir',
    'Dayanikli',
    'Bugdayli',
    'Aydogdu',
    'Mustafa',
    'Duran',
    'Eser',
    'Beysehir',
    'Ferizli',
    'Karakoy',
    'Turgut',
    'Dogu',
    'Cukurova',
    'Basari',
    'Edebey',
    'Piri',
    'Sabanci',
    'Osmangazi',
    'Kadir',
    'Baskent',
    'Kemal',
    'AdÄ±yaman',
    'Gediz',
    'Trakya',
    'Gazi',
    'Sinankoy',
    'Adnan Menderes',
    'Celal',
    'Deniz',
    'Uludag',
    'IÄŸdÄ±r',
    'Erciyes',
    'Meliksah',
    'Dumlupinar',
    'Toprak',
    'Yeditepe',
    'Orta',
    'Hurriyet',
    'Atlas',
    'Ulus',
    'Tatvan',
    'Saglik',
    'Yenibosna',
    'Susurluk',
    'Ä°biÅŸkÅy',
    'Halic',
  ],
  tld: ['.tr'],
  cca2: 'TR',
  ccn3: '792',
  cca3: 'TUR',
  cioc: 'TUR',
  currency: ['TRY'],
  callingCode: ['90'],
  capital: 'Ankara',
  altSpellings: ['TR', 'Turkiye', 'Republic of Turkey', 'Türkiye Cumhuriyeti'],
  region: 'Asia',
  subregion: 'Western Asia',
  languages: { tur: 'Turkish' },
  translations: {
    deu: { official: 'Republik Türkei', common: 'Türkei' },
    fra: { official: 'République de Turquie', common: 'Turquie' },
    hrv: { official: 'Republika Turska', common: 'Turska' },
    ita: { official: 'Repubblica di Turchia', common: 'Turchia' },
    jpn: { official: 'トルコ共和国', common: 'トルコ' },
    nld: { official: 'Republiek Turkije', common: 'Turkije' },
    por: { official: 'República da Turquia', common: 'Turquia' },
    rus: { official: 'Республика Турции', common: 'Турция' },
    spa: { official: 'República de Turquía', common: 'Turquía' },
    fin: { official: 'Turkin tasavalta', common: 'Turkki' },
  },
  latlng: [39, 35],
  demonym: 'Turkish',
  landlocked: false,
  borders: ['ARM', 'AZE', 'BGR', 'GEO', 'GRC', 'IRN', 'IRQ', 'SYR'],
  area: 783562,
  provinces: [
    'Adana',
    'Adiyaman',
    'Afyon',
    'Agri',
    'Aksaray',
    'Amasya',
    'Ankara',
    'Antalya',
    'Ardahan',
    'Artvin',
    'Aydin',
    'Balikesir',
    'Bartin',
    'Batman',
    'Bayburt',
    'Bilecik',
    'Bingol',
    'Bitlis',
    'Bolu',
    'Burdur',
    'Bursa',
    'Canakkale',
    'Cankiri',
    'Corum',
    'Denizli',
    'Diyarbakir',
    'Duzce',
    'Edirne',
    'Elazig',
    'Erzincan',
    'Erzurum',
    'Eskisehir',
    'Gaziantep',
    'Giresun',
    'Gumushane',
    'Hakkari',
    'Hatay',
    'Icel',
    'Igdir',
    'Isparta',
    'Istanbul',
    'Izmir',
    'Kahramanmaras',
    'Karabuk',
    'Karaman',
    'Kars',
    'Kastamonu',
    'Kayseri',
    'Kilis',
    'Kirikkale',
    'Kirklareli',
    'Kirsehir',
    'Kocaeli',
    'Konya',
    'Kutahya',
    'Malatya',
    'Manisa',
    'Mardin',
    'Mugla',
    'Mus',
    'Nevsehir',
    'Nigde',
    'Ordu',
    'Osmaniye',
    'Rize',
    'Sakarya',
    'Samsun',
    'Sanliurfa',
    'Siirt',
    'Sinop',
    'Sirnak',
    'Sivas',
    'Tekirdag',
    'Tokat',
    'Trabzon',
    'Tunceli',
    'Usak',
    'Van',
    'Yalova',
    'Yozgat',
    'Zonguldak',
  ],
  nativeName: 'Türkiye',
  timezones: ['UTC+02:00'],
} as const;
