import { Vue, Component, Prop } from '@vue';
import { uniqueId } from 'lodash';

import { faLightbulbOn } from '@icons/duotone/faLightbulbOn';

/**
 * ...
 */
interface KeyedSection extends SiteDocument.Section {
  key: string;
}

@Component
export class SiteDocument extends Vue implements SiteDocument.Props {
  @Prop(Array) readonly sections!: SiteDocument.Section[];

  readonly icons = { faLightbulbOn };

  /** ... */
  get items() {
    return mapSections(this.sections);
  }

  /** ... */
  get hasSubText() {
    return this.items.some(({ subText }) => subText);
  }
}

export namespace SiteDocument {
  /**
   * ...
   */
  export interface Line {
    text: string;
    // subText?: string;
    tag?: string;
    classList?: string[];
    style?: Partial<CSSStyleDeclaration>;
  }

  /**
   * ...
   */
  export interface Section {
    title?: string;
    paragraphs: Paragraph[];
    subText?: string;
  }

  /**
   * ...
   */
  export type Paragraph = string | Line | string[];

  /**
   * ...
   */
  export interface Props {
    sections: Section[];
  }
}

export default SiteDocument;

// region Helper Functions

/**
 * ...
 *
 * @param paragraphs ...
 * @return ...
 */
function normalizeSectionParagraphs(paragraphs: SiteDocument.Paragraph[]) {
  return paragraphs.map((value) => {
    if (Array.isArray(value)) return value;

    const line: SiteDocument.Line =
      typeof value !== 'string' ? { ...value } : { text: value };

    line.tag = line.tag ?? 'p';
    line.classList = line.classList ?? [];
    line.style = line.style ?? {};

    return line;
  });
}

/**
 * ...
 *
 * @param sections ...
 * @return ...
 */
function mapSections(sections: SiteDocument.Section[]) {
  return sections.map(({ title, paragraphs, subText }) => {
    const config: KeyedSection = {
      key: uniqueId(),
      paragraphs: normalizeSectionParagraphs(paragraphs),
    };

    if (title) {
      config.title = title;
    }

    if (subText) {
      config.subText = subText;
    }

    return config;
  });
}

// endregion Helper Functions
