var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-container",
    {
      staticClass: "section-customer-stories",
      on: { mouseover: _setup.stop, mouseleave: _setup.start },
    },
    [
      _c(
        "b-row",
        { staticClass: "mb-5 justify-content-md-center" },
        [
          _c("b-col", { staticClass: "mx-auto", attrs: { lg: "6" } }, [
            _c("h2", [_vm._v("Customer Stories")]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _setup.state
        ? _c(
            "b-row",
            { staticClass: "justify-content-center mb-4" },
            [
              _c("b-col", { attrs: { lg: "6" } }, [
                _c("div", { key: _setup.state.id, staticClass: "story-body" }, [
                  _c("div", { staticClass: "story-content" }, [
                    _c(
                      "div",
                      { staticClass: "quote-block quote-block-left" },
                      [
                        _c(_setup.Icon, {
                          attrs: { icon: _setup.faQuoteLeft },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("pre", [_vm._v(_vm._s(_setup.state.body))]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "quote-block quote-block-right" },
                      [
                        _c(_setup.Icon, {
                          attrs: { icon: _setup.faQuoteRight },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "story-footer" }, [
                    _c(
                      "span",
                      { staticClass: "story-thumbnail" },
                      [_c(_setup.Icon, { attrs: { icon: _setup.faUser } })],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "story-author" }, [
                      _vm._v("- " + _vm._s(_setup.state.author)),
                    ]),
                  ]),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("div", { staticClass: "section-footer" }, [
              _c(
                "div",
                { staticClass: "pagination-btns" },
                _vm._l(_vm.stories, function (story, i) {
                  return _c("div", {
                    key: story.id,
                    class: [
                      "pagination-btn",
                      { active: story === _setup.state },
                    ],
                    on: {
                      click: function ($event) {
                        return _setup.select(i)
                      },
                    },
                  })
                }),
                0
              ),
              _vm._v(" "),
              !_setup.paused
                ? _c("div", {
                    key: _setup.state.id,
                    staticClass: "progress-bar",
                    style: { animationDuration: _setup.animationDuration },
                  })
                : _vm._e(),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }