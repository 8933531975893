import { Module, Action } from '@vuex/decorators';
import { TablePanel } from 'table-panel';

import { faEdit } from '@icons/solid/faEdit';
import { faEye } from '@icons/solid/faEye';
import { UnityCatalog } from '@models';
import { modals } from '@modals';
import { faBadgeCheck } from '@fortawesome/pro-solid-svg-icons/faBadgeCheck';

/** ... */
const columns: TablePanel.Column<UnityCatalog>[] = [
  {
    key: 'baseApp',
    label: 'App',
    value: 'baseApp',
    component: 'TableCellBaseApp',
  },
  {
    key: 'version',
    label: 'Version',
    value: 'version',
  },
  {
    key: 'os',
    label: 'OS',
    value: 'os',
    component: 'TableCellOperatingSystem',
  },
  {
    key: 'dlc',
    label: 'DLC',
    value: 'dlc',
    component: 'TableCellListDLC',
  },
  {
    key: 'events',
    label: 'Events',
    value: 'events',
    component: 'TableCellListCatalogEvents',
  },
];

const operations: TablePanel.Row.Operation<UnityCatalog>[] = [
  {
    label: 'View',
    icon: faEye,
    fn: () => {
      return;
    },
  },
  {
    label: 'Edit',
    icon: faEdit,
    fn: () => {
      return;
    },
  },
  {
    label: 'Approve/Reject Catalog',
    icon: faBadgeCheck,
    fn(item) {
      void modals.unityCatalog.approveResultUnityCatalog({
        unityCatalog: item,
      });
    },
    hidden(item) {
      return item.events.some(
        (e) => e.status === 'DEPLOYED' || e.status === 'REVOKED',
      );
    },
  },
];

const specialFilters = [
  {
    type: 'dropdown',
    label: 'Filter',
    options: [
      { value: '--', text: 'All' },
      { value: 'mac', text: 'macOS' },
      { value: 'win', text: 'Windows' },
    ],
    handler: (item: UnityCatalog, choice: string) => {
      if (choice === 'mac' || choice === 'win') {
        return item.os === choice;
      }
      return false;
    },
  },
];

@Module({ namespaced: true })
export class UnityCatalogTablePanel
  extends TablePanel<UnityCatalog>
  implements TablePanel.Props<UnityCatalog>
{
  readonly module = 'unityCatalogs';
  readonly loadAction = 'list';
  readonly pk = 'id';
  readonly label = 'UNITY CATALOGS';
  readonly columns = columns;
  readonly operations = operations;
  readonly createAction = 'submitCatalog';

  readonly enabled = true;
  readonly tableSortType = 'appName';
  readonly tablePageSize = 50;

  readonly specialFilters = specialFilters;

  @Action
  async submitCatalog() {
    await modals.unityCatalog.submitUnityCatalog();
  }
}

export namespace UnityCatalogTablePanel {
  /** ... */
  export interface DeleteItemOptions {
    items: UnityCatalog[];
  }
}
