export const SYR = {
  name: {
    common: 'Syria',
    official: 'Syrian Arab Republic',
    native: { ara: { official: 'الجمهورية العربية السورية', common: 'سوريا' } },
  },
  cities: ['Damascus', '`Ara', 'Aleppo', 'Ad Darah'],
  tld: ['.sy', 'سوريا.'],
  cca2: 'SY',
  ccn3: '760',
  cca3: 'SYR',
  cioc: 'SYR',
  currency: ['SYP'],
  callingCode: ['963'],
  capital: 'Damascus',
  altSpellings: [
    'SY',
    'Syrian Arab Republic',
    'Al-Jumhūrīyah Al-ʻArabīyah As-Sūrīyah',
  ],
  region: 'Asia',
  subregion: 'Western Asia',
  languages: { ara: 'Arabic' },
  translations: {
    deu: { official: 'Arabische Republik Syrien', common: 'Syrien' },
    fra: { official: 'République arabe syrienne', common: 'Syrie' },
    hrv: { official: 'Sirijska Arapska Republika', common: 'Sirija' },
    ita: { official: 'Repubblica araba siriana', common: 'Siria' },
    jpn: { official: 'シリアアラブ共和国', common: 'シリア・アラブ共和国' },
    nld: { official: 'Syrische Arabische Republiek', common: 'Syrië' },
    por: { official: 'República Árabe Síria', common: 'Síria' },
    rus: { official: 'Сирийская Арабская Республика', common: 'Сирия' },
    spa: { official: 'República Árabe Siria', common: 'Siria' },
    fin: { official: 'Syyrian arabitasavalta', common: 'Syyria' },
  },
  latlng: [35, 38],
  demonym: 'Syrian',
  landlocked: false,
  borders: ['IRQ', 'ISR', 'JOR', 'LBN', 'TUR'],
  area: 185180,
  provinces: [
    'Al Hasakah',
    'Al Ladhiqiyah',
    'Al Qunaytirah',
    'Ar Raqqah',
    "As Suwayda'",
    "Dar'a",
    'Dayr az Zawr',
    'Dimashq',
    'Halab',
    'Hamah',
    'Hims',
    'Idlib',
    'Rif Dimashq',
    'Tartus',
  ],
  nativeName: 'سوريا',
  timezones: ['UTC+02:00'],
} as const;
