import { Vue } from '@vue';

import { importContext } from '@utils/import-context';
import { isVueComponent } from '@utils/is-vue-component';

/** ... */
export const components: Record<string, Vue.OriginalConstructor> = {};

/** Required context for local Vue component modules. */
const context = require.context(
  '.',
  true,
  // /\.\/[\w-]+(?!\.Async)(\.vue|\/index\.(vue|ts))$/,
  /(\.vue|\/index\.(vue|ts))$/,
);

// Attempt to import all adjacent modules and register them as Vue components.
importContext(context, ({ name, value }) => {
  if (name.startsWith('_')) {
    // eslint-disable-next-line no-console
    return console.warn(
      `[components] the imported module "${name}" includes an "_" at the beginning of its name and will not be registered.`,
    );
  }

  // If the imported value is not a Vue component, log a warning n the console
  // and skip registration.
  if (!isVueComponent(value)) {
    // eslint-disable-next-line no-console
    return console.warn(
      `[components] the imported module "${name}" is not a valid Vue component.`,
    );
  }

  // Ensure the component name is pascal-case.
  name = name.replace(/^./, (char) => char.toUpperCase());

  // ...
  components[name] = value;

  Vue.component(name, value);
});
