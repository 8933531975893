import { Module, VuexModule, Mutation, Action } from '@vuex/decorators';

import { api } from '@api';
import { Root } from '@store';
import { UnityCatalog } from '@models';
import { findById, addById, updateById } from '@utils/array';

declare module '@vuex/core' {
  export interface CommitMap {
    'unityCatalogs/SET': UnityCatalogs['SET'];
    'unityCatalogs/ADD': UnityCatalogs['ADD'];
    'unityCatalogs/CLEAR': UnityCatalogs['CLEAR'];
    'unityCatalogs/UPDATE': UnityCatalogs['UPDATE'];
  }

  export interface DispatchMap {
    'unityCatalogs/list': UnityCatalogs['list'];
    'unityCatalogs/create': UnityCatalogs['create'];
    'unityCatalogs/approveResult': UnityCatalogs['approveResult'];
  }
}

@Module({ namespaced: true })
export class UnityCatalogs
  extends VuexModule<UnityCatalogs.State, Root.State>
  implements UnityCatalogs.State
{
  items: UnityCatalog[] = [];

  /** Fetch a specific Unity Catalog data object by ID */
  get findById() {
    return (id: string) => findById(this.items, id);
  }

  /**
   * ...
   */
  @Mutation
  SET(options: UnityCatalogs.SetMutationOptions) {
    this.items = options.data;
  }

  /**
   * ...
   */
  @Mutation
  ADD(options: UnityCatalogs.AddMutationOptions) {
    this.items = addById(this.items, options);
  }

  /**
   * ...
   */
  @Mutation
  UPDATE({ id, ...options }: UnityCatalogs.UpdateMutationOptions) {
    this.items = updateById(this.items, { id, ...options });
  }

  /**
   * ...
   */
  @Mutation
  CLEAR() {
    this.items = [];
  }

  /**
   * ...
   */
  @Action
  async list() {
    const data = await api.unityCatalogs.list();

    this.context.commit('SET', { data });

    return this.items;
  }

  /**
   * ...
   */
  @Action
  async create(options: UnityCatalogs.CreateActionOptions) {
    const data = await api.unityCatalogs.submitUnityCatalog(options);

    this.context.commit('ADD', data);

    return data;
  }

  /**
   * ...
   */
  @Action
  async approveResult(options: UnityCatalogs.ApproveResultActionOptions) {
    const data = await api.unityCatalogs.approveCatalogResult(options);

    this.context.commit('UPDATE', data);

    return data;
  }
}

export namespace UnityCatalogs {
  export interface State {
    items: UnityCatalog[];
  }

  export interface SetMutationOptions {
    data: UnityCatalog[];
  }

  /** ... */
  export type AddMutationOptions = UnityCatalog;

  /** ... */
  export type UpdateMutationOptions = UnityCatalog;

  /** ... */
  export type CreateActionOptions = api.unityCatalogs.SubmitCatalogOptions;

  /** ... */
  export type ApproveResultActionOptions =
    api.unityCatalogs.ApproveCatalogResultOptions;
}

export default UnityCatalogs;
