import * as models from '@models';

import { openModal } from '../open-modal';

/**
 * ...
 */
interface UserFilterData {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
}

/**
 * ...
 */
export interface ReportRequestOptions {
  filteredUsers: UserFilterData[];
}

/**
 * Open Report Request modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function reportRequest(options?: ReportRequestOptions) {
  const modalOptions = {
    title: 'REPORT REQUEST',
    props: { ...options },
    component: () => import('./ReportRequest/index.vue'),
  };

  await openModal.safe(modalOptions);
}

export interface ViewReportModalOptions {
  report: models.Report;
}

/**
 * Open View Report modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function viewReport(options: ViewReportModalOptions) {
  const modalOptions = {
    title: 'VIEW REPORT',
    props: { ...options },
    component: () => import('./ViewReport.vue'),
    size: 'xl',
  };

  await openModal.safe(modalOptions);
}

/**
 * ...
 */
export interface ViewReportsModalOptions {
  user: models.User;
}

/**
 * Open View Reports modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function viewReports(options: ViewReportsModalOptions) {
  const modalOptions = {
    title: 'VIEW REPORTS',
    props: { ...options },
    component: () => import('./ViewReports.vue'),
    size: 'lg',
  };

  await openModal.safe(modalOptions);
}
