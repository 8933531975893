export const DOM = {
  name: {
    common: 'Dominican Republic',
    official: 'Dominican Republic',
    native: {
      spa: { official: 'República Dominicana', common: 'República Dominicana' },
    },
  },
  cities: [
    'Santo Domingo',
    'Santiago de los Caballeros',
    'San Cristobal',
    'San Fernando de Monte Cristi',
    'Moca',
    'Dominica',
    'Boca Chica',
    'Punta Cana',
    'San Jose de Ocoa',
    'La Romana',
    'Puerto Plata',
    'San Francisco de MacorÃ­s',
    'San Pedro de MacorÃ­s',
    'Jimani',
    'Guaricano',
    'Los Alcarrizos',
    'Santa Cruz de Barahona',
    'BanÃ­',
    'SalvaleÃ³n de HigÃ¼ey',
    'Hato Mayor del Rey',
    'Arenazo',
    'Pedernales',
    'Cotui',
    'Cabarete',
    'ConcepciÃ³n de la Vega',
    'Sabaneta',
    'Bavaro',
    'Los Prados',
  ],
  tld: ['.do'],
  cca2: 'DO',
  ccn3: '214',
  cca3: 'DOM',
  cioc: 'DOM',
  currency: ['DOP'],
  callingCode: ['1809', '1829', '1849'],
  capital: 'Santo Domingo',
  altSpellings: ['DO'],
  region: 'Americas',
  subregion: 'Caribbean',
  languages: { spa: 'Spanish' },
  translations: {
    cym: { official: 'Dominican Republic', common: 'Gweriniaeth_Dominica' },
    deu: {
      official: 'Dominikanische Republik',
      common: 'Dominikanische Republik',
    },
    fra: {
      official: 'République Dominicaine',
      common: 'République dominicaine',
    },
    hrv: {
      official: 'Dominikanska Republika',
      common: 'Dominikanska Republika',
    },
    ita: { official: 'Repubblica Dominicana', common: 'Repubblica Dominicana' },
    jpn: { official: 'ドミニカ共和国', common: 'ドミニカ共和国' },
    nld: {
      official: 'Dominicaanse Republiek',
      common: 'Dominicaanse Republiek',
    },
    por: { official: 'República Dominicana', common: 'República Dominicana' },
    rus: {
      official: 'Доминиканская Республика',
      common: 'Доминиканская Республика',
    },
    spa: { official: 'República Dominicana', common: 'República Dominicana' },
    fin: {
      official: 'Dominikaaninen tasavalta',
      common: 'Dominikaaninen tasavalta',
    },
  },
  latlng: [19, -70.66666666],
  demonym: 'Dominican',
  landlocked: false,
  borders: ['HTI'],
  area: 48671,
  provinces: [
    'Azua',
    'Baoruco',
    'Barahona',
    'Dajabon',
    'Distrito Nacional',
    'Duarte',
    'El Seibo',
    'Elias Pina',
    'Espaillat',
    'Hato Mayor',
    'Independencia',
    'La Altagracia',
    'La Romana',
    'La Vega',
    'Maria Trinidad Sanchez',
    'Monsenor Nouel',
    'Monte Cristi',
    'Monte Plata',
    'Pedernales',
    'Peravia',
    'Puerto Plata',
    'Salcedo',
    'Samana',
    'San Cristobal',
    'San Juan',
    'San Pedro de Macoris',
    'Sanchez Ramirez',
    'Santiago',
    'Santiago Rodriguez',
    'Valverde',
  ],
  nativeName: 'República Dominicana',
  timezones: ['UTC−04:00'],
} as const;
