import { Course } from '@models';

// const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const PASSWORD_MINIMUM_LENGTH_REGEX = /^\S{8,99}$/;
const PASSWORD_UPPERCASE_REGEX = /^(?=.*[A-Z]).*$/;
const PASSWORD_LOWERCASE_REGEX = /^(?=.*[a-z]).*$/;
const PASSWORD_NUMBER_REGEX = /^(?=.*[0-9]).*$/;
const PASSWORD_SPECIAL_CHARACTER_REGEX =
  /^(?=.*[\^$*.[\]{}()?\-^$*.[\]{}()?"!@#%&/,><':;|_~`’:;|_~`]).*$/;

/**
 * Ensure an email is valid.
 *
 * @param email The email to validate.
 * @return Whether or not the email is valid.
 */
export function checkEmail(email: string) {
  return EMAIL_REGEX.test(email);
}

/**
 * Validate password based on strength requirements.
 *
 * @param value The password to validate.
 * @return A validation object detailing each requirment and whether or not
 * the provided password met it.
 */
export function validatePassword(value: string) {
  const minimumLength = PASSWORD_MINIMUM_LENGTH_REGEX.test(value);
  const hasLowerCaseLetter = PASSWORD_LOWERCASE_REGEX.test(value);
  const hasUpperCaseLetter = PASSWORD_UPPERCASE_REGEX.test(value);
  const hasNumber = PASSWORD_NUMBER_REGEX.test(value);
  const hasSpecialCharacter = PASSWORD_SPECIAL_CHARACTER_REGEX.test(value);

  const valid =
    minimumLength &&
    hasLowerCaseLetter &&
    hasUpperCaseLetter &&
    hasNumber &&
    hasSpecialCharacter;

  return {
    minimumLength,
    hasLowerCaseLetter,
    hasUpperCaseLetter,
    hasNumber,
    hasSpecialCharacter,
    valid,
  };
}

/**
 * Check password.
 *
 * @param value ...
 * @return ...
 */
export function checkPassword(value: string) {
  return validatePassword(value).valid;
}

/**
 * Check if date has passed.
 *
 * @param target ...
 * @param reference ...
 * @return ...
 */
export function datePassed(
  target: string | number | Date,
  reference: string | number | Date,
) {
  return new Date(target) > new Date(reference);
}

/**
 * Check 2 dates validation.
 *
 * @param firstDate ...
 * @param secondDate ...
 * @return ...
 */
export function twoDateValidate(
  firstDate: string | number | Date,
  secondDate: string | number | Date,
) {
  return new Date(firstDate).getTime() < new Date(secondDate).getTime();
}

/**
 * Check course status.
 *
 * @param course ...
 * @return ...
 */
export function courseStatus(course: Course) {
  const today = new Date().getTime();

  return today < new Date(course.startDate).getTime()
    ? 'Upcoming'
    : today > new Date(course.endDate).getTime()
    ? 'Ended'
    : 'In Progress';
}

/**
 * Remove Item from Array.
 *
 * @param arr ...
 * @param index ...
 * @return ...
 */
export function removeItemFromArray(arr: unknown[], index: number) {
  arr.splice(index, 1);
}

/**
 * Move Array Item 1 space.
 *
 * @param arr ...
 * @param index ...
 * @param down ...
 * @return ...
 */
export function moveArrayItem<T>(
  arr: (T | undefined)[],
  index: number,
  down?: boolean,
  targetIndex?: number,
) {
  if (typeof down !== 'boolean' && typeof targetIndex !== 'number') {
    throw new Error('Down or TargetIndex Not Defined');
  }

  const newIndex =
    typeof targetIndex === 'number' ? targetIndex : index + (down ? 1 : -1);

  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;

    while (k--) arr.push(undefined);
  }

  arr.splice(newIndex, 0, arr.splice(index, 1)[0]);

  return arr;
}
