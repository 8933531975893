import { Module, Action } from '@vuex/decorators';
import { TablePanel } from 'table-panel';

import { auth } from '@auth';
import { Announcement } from '@models';
import { modals } from '@modals';

import { faEye } from '@icons/solid/faEye';
import { faEdit } from '@icons/solid/faEdit';
import { faCopy } from '@icons/solid/faCopy';

const filterFields = ['title', 'createdAt', 'course', 'institution'];

const columns: TablePanel.Column<Announcement>[] = [
  {
    key: 'title',
    label: 'Title',
    value: 'title',
  },
  {
    key: 'createdAt',
    label: 'Created',
    type: 'date',
    value: 'createdAt',
  },
  {
    key: 'institution',
    label: 'Institution',
    value: ({ organization: account }) => account?.name,
  },
  {
    key: 'course',
    label: 'Course',
    value: ({ course }) => course?.name,
  },
];

const operations: TablePanel.Row.Operation<Announcement>[] = [
  {
    label: 'View',
    icon: faEye,
    fn: (announcement) => {
      void modals.announcement.view({ announcement });
    },
  },
  {
    label: 'Edit',
    icon: faEdit,
    fn: (announcement) => {
      void modals.announcement.edit({ announcement });
    },
    hidden: shouldBeReadonly,
  },
  {
    label: 'Create Copy',
    icon: faCopy,
    fn: (announcement) => {
      void modals.announcement.create({ announcement });
    },
    hidden: shouldBeReadonly,
  },
];

@Module({ namespaced: true })
export class AnnouncementTablePanel
  extends TablePanel<Announcement>
  implements TablePanel.Props<Announcement>
{
  filterTags?: TablePanel.FilterTag[];
  filterParams?: TablePanel.FilterParam[];
  infoMessage?: string | null;
  onPageChanged?: TablePanel.OnPageChangedCallback<Announcement>;
  readonly module = 'announcements';
  readonly loadAction = 'list';
  readonly pk = 'id';
  readonly label = 'ANNOUNCEMENTS';
  readonly columns = columns;
  readonly operations = operations;
  readonly filterFields = filterFields;
  readonly createAction = 'createAnnouncement';
  readonly deleteAction = 'deleteAnnouncement';
  readonly tableSortType = 'createdAt';
  readonly tableSortReverse = true;

  get enabled() {
    return this.isActiveRole(4, 5, 9);
  }

  readonly progressive = false;

  @Action
  async createAnnouncement() {
    await modals.announcement.create();
  }

  @Action
  async deleteAnnouncement({
    items,
  }: AnnouncementTablePanel.DeleteItemOptions) {
    await modals.confirm.deleteItems({ itemType: 'announcements', items });

    void this.context.dispatch('load');
  }
}

export namespace AnnouncementTablePanel {
  export interface DeleteItemOptions {
    items: Announcement[];
  }
}

//#region Helper Functions

function shouldBeReadonly(announcement: Announcement) {
  const isPublic = !announcement.organization;

  // If announcement is public and user is not las admin.
  if (isPublic && !auth.isActiveRole(ZephyrWeb.RoleId.LasAdmin)) {
    return true;
  }

  // If announcement is institution's and user is not an las admin, admin,
  // or instructor.
  if (
    !isPublic &&
    !auth.isActiveRole(
      ZephyrWeb.RoleId.Instructor,
      ZephyrWeb.RoleId.InstitutionAdmin,
      ZephyrWeb.RoleId.LasAdmin,
    )
  ) {
    return true;
  }

  return false;
}

//#endregion Helper Functions
