export const BLZ = {
  name: {
    common: 'Belize',
    official: 'Belize',
    native: {
      bjz: { official: 'Belize', common: 'Belize' },
      eng: { official: 'Belize', common: 'Belize' },
      spa: { official: 'Belice', common: 'Belice' },
    },
  },
  cities: [
    'Belize City',
    'Benque Viejo del Carmen',
    'Belmopan',
    'San Ignacio',
    'Ladyville',
    'Freetown Sibun',
    'San Pedro Town',
  ],
  tld: ['.bz'],
  cca2: 'BZ',
  ccn3: '084',
  cca3: 'BLZ',
  cioc: 'BIZ',
  currency: ['BZD'],
  callingCode: ['501'],
  capital: 'Belmopan',
  altSpellings: ['BZ'],
  region: 'Americas',
  subregion: 'Central America',
  languages: { bjz: 'Belizean Creole', eng: 'English', spa: 'Spanish' },
  translations: {
    cym: { official: 'Belize', common: 'Belize' },
    deu: { official: 'Belize', common: 'Belize' },
    fra: { official: 'Belize', common: 'Belize' },
    hrv: { official: 'Belize', common: 'Belize' },
    ita: { official: 'Belize', common: 'Belize' },
    jpn: { official: 'ベリーズ', common: 'ベリーズ' },
    nld: { official: 'Belize', common: 'Belize' },
    por: { official: 'Belize', common: 'Belize' },
    rus: { official: 'Белиз', common: 'Белиз' },
    spa: { official: 'Belice', common: 'Belice' },
    fin: { official: 'Belize', common: 'Belize' },
  },
  latlng: [17.25, -88.75],
  demonym: 'Belizean',
  landlocked: false,
  borders: ['GTM', 'MEX'],
  area: 22966,
  provinces: [
    'Belize',
    'Cayo',
    'Corozal',
    'Orange Walk',
    'Stann Creek',
    'Toledo',
  ],
  nativeName: 'Belize',
  timezones: ['UTC−06:00'],
} as const;
