var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      class: ["v-input-mark", "input-checkbox", ..._setup.classList],
      on: { click: _setup.toggle },
    },
    [
      _c(
        "input",
        _vm._b(
          { attrs: { type: "checkbox" } },
          "input",
          { value: _vm.value },
          false
        )
      ),
      _vm._v(" "),
      _c("Transition", [
        _vm.value
          ? _c("div", { staticClass: "input-mark" }, [
              _c(
                "span",
                { staticClass: "input-icon" },
                [_c("Icon", { attrs: { icon: _setup.faCheck } })],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }