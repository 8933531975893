export const NAM = {
  name: {
    common: 'Namibia',
    official: 'Republic of Namibia',
    native: {
      afr: { official: 'Republiek van Namibië', common: 'Namibië' },
      deu: { official: 'Republik Namibia', common: 'Namibia' },
      eng: { official: 'Republic of Namibia', common: 'Namibia' },
      her: { official: 'Republic of Namibia', common: 'Namibia' },
      hgm: { official: 'Republic of Namibia', common: 'Namibia' },
      kwn: { official: 'Republic of Namibia', common: 'Namibia' },
      loz: { official: 'Republic of Namibia', common: 'Namibia' },
      ndo: { official: 'Republic of Namibia', common: 'Namibia' },
      tsn: { official: 'Lefatshe la Namibia', common: 'Namibia' },
    },
  },
  cities: [
    'Windhoek',
    'Katima Mulilo',
    'Olympia',
    'Outapi',
    'Otjiwarongo',
    'Swakopmund',
    'Walvis Bay',
    'Oshakati',
    'Ondangwa',
    'Ongwediva',
    'Tsumeb',
    'Grootfontein',
    'Keetmanshoop',
    'Oranjemund',
    'Mpapuka',
    'Etunda',
  ],
  tld: ['.na'],
  cca2: 'NA',
  ccn3: '516',
  cca3: 'NAM',
  cioc: 'NAM',
  currency: ['NAD', 'ZAR'],
  callingCode: ['264'],
  capital: 'Windhoek',
  altSpellings: ['NA', 'Namibië', 'Republic of Namibia'],
  region: 'Africa',
  subregion: 'Southern Africa',
  languages: {
    afr: 'Afrikaans',
    deu: 'German',
    eng: 'English',
    her: 'Herero',
    hgm: 'Khoekhoe',
    kwn: 'Kwangali',
    loz: 'Lozi',
    ndo: 'Ndonga',
    tsn: 'Tswana',
  },
  translations: {
    deu: { official: 'Republik Namibia', common: 'Namibia' },
    fra: { official: 'République de Namibie', common: 'Namibie' },
    hrv: { official: 'Republika Namibija', common: 'Namibija' },
    ita: { official: 'Repubblica di Namibia', common: 'Namibia' },
    jpn: { official: 'ナミビア共和国', common: 'ナミビア' },
    nld: { official: 'Republiek Namibië', common: 'Namibië' },
    por: { official: 'República da Namíbia', common: 'Namíbia' },
    rus: { official: 'Республика Намибия', common: 'Намибия' },
    spa: { official: 'República de Namibia', common: 'Namibia' },
    fin: { official: 'Namibian tasavalta', common: 'Namibia' },
  },
  latlng: [-22, 17],
  demonym: 'Namibian',
  landlocked: false,
  borders: ['AGO', 'BWA', 'ZAF', 'ZMB'],
  area: 825615,
  provinces: [
    'Caprivi',
    'Erongo',
    'Hardap',
    'Karas',
    'Khomas',
    'Kunene',
    'Ohangwena',
    'Okavango',
    'Omaheke',
    'Omusati',
    'Oshana',
    'Oshikoto',
    'Otjozondjupa',
  ],
  nativeName: 'Namibia',
  timezones: ['UTC+01:00'],
} as const;
