import { BillingCycle } from '@values/billing-cycles';

/**
 * Billing cycle selection options.
 */
export const billingCycleOptions: ZephyrWeb.OptionItem<BillingCycle>[] = [
  { text: 'Monthly', value: BillingCycle.Monthly },
  { text: 'Quarterly', value: BillingCycle.Quarterly },
  { text: 'Bi-Annually', value: BillingCycle.BiAnnually },
  { text: 'Annually', value: BillingCycle.Annually },
];
