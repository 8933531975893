var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "modal-footer" }, [
    _c(
      "div",
      { staticClass: "modal-footer-group group-left" },
      [
        _vm._l(_setup.buttonList, function ({ key, classes, text, click }) {
          return _c(
            "b-button",
            { key: key, class: classes, on: { click: click } },
            [_vm._v("\n      " + _vm._s(text) + "\n    ")]
          )
        }),
        _vm._v(" "),
        _vm._t("group-left"),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "modal-footer-group group-right" },
      [_vm._t("group-right")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }