export const HRV = {
  name: {
    common: 'Croatia',
    official: 'Republic of Croatia',
    native: { hrv: { official: 'Republika Hrvatska', common: 'Hrvatska' } },
  },
  cities: [
    'Velika Gorica',
    'Zagreb',
    'Ivanec',
    'Core',
    'Labin',
    'VaraÅ¾din',
    'Split',
    'Glina',
    'Vinkovci',
    'Sinj',
    'Karlovac',
    'Rijeka',
    'Gornja Lomnica',
    'Å ibenik',
    'Zlatar',
    'Novi Vinodolski',
    'Dubrovnik',
    'Cavtat',
    'Zadar',
    'Simuni',
    'Tar',
    'Rab',
    'Lupoglav',
    'Å½upanja',
    'Äakovo',
    'Piskorevci',
    'Vukovar',
    'Brsadin',
    'Nustar',
    'Delnice',
    'Vrbnik',
    'Krk',
    'Lovran',
    'Petrcane',
    'Trogir',
    'Betina',
    'Dugo Selo',
    'Sisak',
    'Zapresic',
    'Oroslavje',
    'Matulji',
    'PoreÄ',
    'Makarska',
    'Brela',
    'Baska Voda',
    'Lekenik',
    'Strmec Samoborski',
    'Petrinja',
    'Galgovo',
    'Krapina',
    'Vrbovec',
    'Sesvete',
    'Popovaca',
    'Bestovje',
    'Nedelisce',
    'Rijeka',
    'MetkoviÄ‡',
    'Mlini',
    'Postira',
    'Primosten',
    'DrniÅ¡',
    'Privlaka',
    'Darda',
    'Samobor',
    'Opatija',
    'Bibinje',
    'Podastrana',
    'Osijek',
    'Fuzine',
    'Orebic',
    'Rovinj',
    'Lukavec',
    'Pula',
    'Viskovci',
    'Podgajci Posavski',
    'Antunovac',
    'Cepin',
    'Silas',
    'Vardarac',
    'Solin',
    'ÄŒakovec',
    'Kriz',
    'Lupoglav',
    'Kastel Gomilica',
    'Kastel Luksic',
    'Crikvenica',
    'Pazin',
    'Krsan',
    'Umag',
    'Medulin',
    'Icici',
    'Buje',
    'Sapjane',
    'Karojba',
    'Kostrena',
    'Fazana',
    'Cabuna',
    'Pakrac',
    'Bizovac',
    'Vojnic',
    'Ivanic-Grad',
    'Novo Cice',
    'Sveti Ivan Zelina',
    'Orle',
    'Bregana',
    'Donja Pusca',
    'Gornji Stupnik',
    'Bedekovcina',
    'Koprivnica',
    'Varazdinske Toplice',
    'Mursko Sredisce',
    'Vinica',
    'Desinic',
    'Prelog',
    'Pregrada',
    'Torcec',
    'Donji Kraljevec',
    'Novi Marof',
    'Senkovec',
    'Brdovec',
    'Zabok',
    'Strmec',
    'Kraljevec na Sutli',
    'PoÅ¾ega',
    'Nova Gradiska',
    'BeliÅ¡Ä‡e',
    'Okrug Gornji',
    'Cestica',
    'Vratisinec',
    'Lepoglava',
    'Tuzno',
    'Sokolovac',
    'Kastel Sucurac',
    'Bracevci',
    'Virovitica',
    'Orahovica',
    'Slavonski Brod',
    'Kutjevo',
    'Sice',
    'Vetovo',
    'Slatina',
    'Dalj',
    'Bjelovar',
    'Sesvetski Kraljevec',
    'Vrata',
    'Hreljin',
    'Rakitje',
    'Gornji Kriz',
    'Virje',
    'Splitska',
    'Baska',
    'Lapad',
    'Donja Zelina',
  ],
  tld: ['.hr'],
  cca2: 'HR',
  ccn3: '191',
  cca3: 'HRV',
  cioc: 'CRO',
  currency: ['HRK'],
  callingCode: ['385'],
  capital: 'Zagreb',
  altSpellings: ['HR', 'Hrvatska', 'Republic of Croatia', 'Republika Hrvatska'],
  region: 'Europe',
  subregion: 'Southern Europe',
  languages: { hrv: 'Croatian' },
  translations: {
    cym: { official: 'Republic of Croatia', common: 'Croatia' },
    deu: { official: 'Republik Kroatien', common: 'Kroatien' },
    fra: { official: 'République de Croatie', common: 'Croatie' },
    hrv: { official: 'Republika Hrvatska', common: 'Hrvatska' },
    ita: { official: 'Repubblica di Croazia', common: 'Croazia' },
    jpn: { official: 'クロアチア共和国', common: 'クロアチア' },
    nld: { official: 'Republiek Kroatië', common: 'Kroatië' },
    por: { official: 'República da Croácia', common: 'Croácia' },
    rus: { official: 'Республика Хорватия', common: 'Хорватия' },
    spa: { official: 'República de Croacia', common: 'Croacia' },
    fin: { official: 'Kroatian tasavalta', common: 'Kroatia' },
  },
  latlng: [45.16666666, 15.5],
  demonym: 'Croatian',
  landlocked: false,
  borders: ['BIH', 'HUN', 'MNE', 'SRB', 'SVN'],
  area: 56594,
  provinces: [
    'Bjelovarsko-Bilogorska Zupanija',
    'Brodsko-Posavska Zupanija',
    'Dubrovacko-Neretvanska Zupanija',
    'Istarska Zupanija',
    'Karlovacka Zupanija',
    'Koprivnicko-Krizevacka Zupanija',
    'Krapinsko-Zagorska Zupanija',
    'Licko-Senjska Zupanija',
    'Medimurska Zupanija',
    'Osjecko-Baranjska Zupanija',
    'Pozesko-Slavonska Zupanija',
    'Primorsko-Goranska Zupanija',
    'Sibensko-Kninska Zupanija',
    'Sisacko-Moslavacka Zupanija',
    'Splitsko-Dalmatinska Zupanija',
    'Varazdinska Zupanija',
    'Viroviticko-Podravska Zupanija',
    'Vukovarsko-Srijemska Zupanija',
    'Zadarska Zupanija',
    'Zagreb',
    'Zagrebacka Zupanija',
  ],
  nativeName: 'Hrvatska',
  timezones: ['UTC+01:00'],
} as const;
