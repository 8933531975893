import { api } from '@api';
import * as models from '@models';
import { store } from '@store';

import { openModal } from '../open-modal';

/**
 * ...
 */
export interface CreateAssignmentModalOptions {
  assignmentId?: models.Assignment['id'];
  organizationId: models.Organization['id'];
  courseId: models.Course['id'];
}

/**
 * Open Create Assignment modal.
 *
 * @return Modal promise.
 */
export async function create(options?: CreateAssignmentModalOptions) {
  const props: Record<string, unknown> = {};

  if (options) {
    props['targetOrganizationId'] = options.organizationId;
    props['targetCourseId'] = options.courseId;

    if (options.assignmentId) {
      props['assignment'] = await api.assignments.get({
        assignmentId: options.assignmentId,
        organizationId: options.organizationId,
        courseId: options.courseId,
      });
    }
  }

  const modalOptions = {
    title: 'CREATE ASSIGNMENT',
    props,
    component: () => import('./CreateAssignment.vue'),
  };

  await openModal.safe(modalOptions);
}

/**
 * ...
 */
export interface EditAssignmentModalOptions {
  assignmentId: models.Assignment['id'];
  organizationId: models.Organization['id'];
  courseId: models.Course['id'];
  referrer?: string | null;
  assignment?: models.Assignment | null;
  prohibitCourseSelection?: boolean | null;
}

/** ... */
export interface ReturnedAssignment extends models.Assignment {
  sceneId: models.Scene['id'];
  moduleId: models.Module['id'];
}

/**
 * Open Edit Assignment modal.
 *
 * @return Modal promise.
 */
export async function edit(options: EditAssignmentModalOptions) {
  let assignment = null;

  if (!options.assignment) assignment = await api.assignments.get(options);

  const modalOptions = {
    title: 'EDIT ASSIGNMENT',
    props: {
      assignment: options.assignment ? options.assignment : assignment,
      referrer: options.referrer,
      prohibitCourseSelection: options.prohibitCourseSelection,
    },
    component: () => import('./EditAssignment.vue'),
  };

  try {
    return await openModal<ReturnedAssignment>(modalOptions);
  } catch {
    return null;
  }
}

/**
 * ...
 */
export interface CopyAssignmentsModalOptions {
  newCourseId: models.Course['id'];
  oldCourseId: models.Course['id'];
  organizationId: models.Organization['id'];
}

/**
 * Copy Assignments Modal
 *
 * @return Modal promise.
 */
export async function copyAssignments(options: CopyAssignmentsModalOptions) {
  const oldCourse = await store.dispatch('courses/get', {
    courseId: options.oldCourseId,
    organizationId: options.organizationId,
  });

  const newCourse = await store.dispatch('courses/get', {
    courseId: options.newCourseId,
    organizationId: options.organizationId,
  });

  const modalOptions = {
    title: 'COPY ASSIGNMENTS',
    props: { oldCourse, newCourse },
    size: 'xl',
    component: () => import('./CopyAssignments.vue'),
  };

  await openModal.safe(modalOptions);
}
