var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _vm.tag,
    { tag: "component", class: ["display-text", { bold: _vm.bold }] },
    [_vm._v("\n  " + _vm._s(_vm.value) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }