import {
  http,
  AxiosResponse,
  AxiosError,
  AxiosRequestConfig,
} from '@services/http';

import { isFunction, isArray } from '@tools/type-guards';

import { ApiRequestError, TransformError, TransformGroupError } from './errors';
import { logger } from './logger';

/** Alias for {@link AxiosRequestConfig}. */
type Config = AxiosRequestConfig;
/** Request response data transformer function. */
type Transformer<T> = (item: unknown) => T;
/**
 * Request options parameter, consisting either of a response
 * {@link Transformer transformer function}, or an request
 * {@link Config config} data object (which can include a transformer function).
 */
type Options<T> = Transformer<T> | (Config & { transformer?: Transformer<T> });

class ApiServerClient {
  /**
   * Send a GET request to the API server.
   *
   * @param route API endpoint to send the request to.
   * @param options A response transformer function, or a more extensive request
   * configuration object.
   * @returns Response data from the API server, if any was returned.
   */
  async get<T = unknown>(route: string, options?: Options<T>) {
    const { config, transformer } = normalizeRequestOptions(options);

    const data = await requestWrapper(
      http.get(`${process.env.API_ORIGIN}/${route}`, config),
    );

    return transformer ? transformer(data) : (data as T);
  }

  /**
   * Send a GET (list) request to the API server. The response is expected to be
   * an array of objects.
   *
   * @param route API endpoint to send the request to.
   * @param options A response transformer function, or a more extensive request
   * configuration object.
   * @returns Response data from the API server, if any was returned.
   */
  async list<T = unknown>(route: string, options?: Options<T>) {
    const { config, transformer } = normalizeRequestOptions(options);

    const data = await requestWrapper(
      http.get(`${process.env.API_ORIGIN}/${route}`, config),
    );

    if (!isArray(data)) {
      throw new Error(
        `[ServerlessApiRequest.list] data returned from the request to ${route} was not an array.`,
      );
    }

    if (!transformer) return data as T[];

    const transformedData: T[] = [];
    const transformErrors: TransformError[] = [];

    for (const item of data) {
      try {
        transformedData.push(transformer(item));
      } catch (err) {
        transformErrors.push(new TransformError(item, err));
      }
    }

    if (transformErrors.length) {
      // eslint-disable-next-line no-console
      console.error(new TransformGroupError(transformErrors));
    }

    return transformedData;
  }

  /**
   * Send a POST request to the API server.
   *
   * @param route API endpoint to send the request to.
   * @param payload Data payload to send along with the request.
   * @param options A response transformer function, or a more extensive request
   * configuration object.
   * @returns Response data from the API server, if any was returned.
   */
  async post<T = unknown>(
    route: string,
    payload?: unknown,
    options?: Options<T>,
  ) {
    const { config, transformer } = normalizeRequestOptions(options);

    const data = await requestWrapper(
      http.post(`${process.env.API_ORIGIN}/${route}`, payload, config),
    );

    return transformer ? transformer(data) : (data as T);
  }

  /**
   * Send a POST (query) request to the API server. The response is expected to
   * be an array of objects.
   *
   * @param route API endpoint to send the request to.
   * @param payload Data payload to send along with the request.
   * @param options A response transformer function, or a more extensive request
   * configuration object.
   * @returns Response data from the API server, if any was returned.
   */
  async query<T = unknown>(
    route: string,
    payload?: unknown,
    options?: Options<T>,
  ) {
    const { config, transformer } = normalizeRequestOptions(options);

    const data = await requestWrapper(
      http.post(`${process.env.API_ORIGIN}/${route}`, payload, config),
    );

    if (!isArray(data)) {
      throw new Error(
        `[ServerlessApiRequest.query] data returned from the request to ${route} was not an array.`,
      );
    }

    if (!transformer) return data as T[];

    const transformedData: T[] = [];
    const transformErrors: TransformError[] = [];

    for (const item of data) {
      try {
        transformedData.push(transformer(item));
      } catch (err) {
        transformErrors.push(new TransformError(item, err));
      }
    }

    if (transformErrors.length) {
      // eslint-disable-next-line no-console
      console.error(new TransformGroupError(transformErrors));
    }

    return transformedData;
  }

  /**
   * Send a DELETE request to the API server.
   *
   * @param route API endpoint to send the request to.
   * @param options A response transformer function, or a more extensive request
   * configuration object.
   * @returns Response data from the API server, if any was returned.
   */
  async delete<T = unknown>(route: string, options?: Options<T>) {
    const { config, transformer } = normalizeRequestOptions(options);

    const data = await requestWrapper(
      http.delete(`${process.env.API_ORIGIN}/${route}`, config),
    );

    return transformer ? transformer(data) : (data as T);
  }
}

/** Zephyr API server client. */
export const server = new ApiServerClient();

//#region Errors

class InvalidResponseError extends Error {
  name = 'InvalidResponseError';

  constructor(error: AxiosError | null) {
    let { message, ...props } = error ?? {};

    message =
      '[api.request] A request resulted in an invalid response' +
      (message ? `: ${message}` : '.');

    super(message);

    Object.assign(this, props);
  }
}

//#endregion Errors

//#region Helper Functions

function normalizeRequestOptions<T>(
  options: Transformer<T> | Options<T> | undefined,
) {
  if (isFunction(options)) {
    options = { transformer: options };
  } else if (!options) {
    options = {};
  }

  const { transformer, ...config } = options;

  return { config: config as Config, transformer };
}

async function requestWrapper<T = unknown>(request: Promise<AxiosResponse<T>>) {
  let response: AxiosResponse<unknown> | null = null;
  let error: AxiosError | null = null;

  // Create a timestamp before making the request so its duration can be
  // calculated.
  const timestamp = Date.now();

  try {
    response = await request;
  } catch (err) {
    error = err as AxiosError;
  }

  // Ensure regardless of the result of the call that we're dealing with a valid
  // `AxiosResponse`. If not, some other issue internal to axios occurred.
  const result = error?.response ?? response ?? null;

  if (!result) {
    throw new InvalidResponseError(error);
  }

  // Log the resulting `AxiosResponse`.
  logger(result, Date.now() - timestamp);

  // If the error was thrown by axios during the call, throw using the
  // `AxiosError` object itself.
  if (error) {
    throw new ApiRequestError(error);
  }

  return result.data;
}

//#endregion Helper Functions
