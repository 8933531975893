export const ATF = {
  name: {
    common: 'French Southern and Antarctic Lands',
    official: 'Territory of the French Southern and Antarctic Lands',
    native: {
      fra: {
        official: 'Territoire des Terres australes et antarctiques françaises',
        common: 'Terres australes et antarctiques françaises',
      },
    },
  },
  tld: ['.tf'],
  cca2: 'TF',
  ccn3: '260',
  cca3: 'ATF',
  cioc: '',
  currency: ['EUR'],
  callingCode: [],
  capital: 'Port-aux-Français',
  cities: ['Port-aux-Français'],
  altSpellings: ['TF', 'French Southern Territories'],
  region: '',
  subregion: '',
  languages: { fra: 'French' },
  translations: {
    deu: {
      official: 'Gebiet der Französisch Süd-und Antarktisgebiete',
      common: 'Französische Süd-und Antarktisgebiete',
    },
    fra: {
      official: 'Territoire des Terres australes et antarctiques françaises',
      common: 'Terres australes et antarctiques françaises',
    },
    hrv: {
      official: 'Teritoriju Francuski južni i antarktički teritoriji',
      common: 'Francuski južni i antarktički teritoriji',
    },
    ita: {
      official: 'Territorio della australi e antartiche francesi Terre',
      common: 'Territori Francesi del Sud',
    },
    jpn: {
      official: 'フランス領南方·南極地域の領土',
      common: 'フランス領南方・南極地域',
    },
    nld: {
      official: 'Grondgebied van de Franse Zuidelijke en Antarctische gebieden',
      common: 'Franse Gebieden in de zuidelijke Indische Oceaan',
    },
    por: {
      official: 'Território do Sul e Antártica Francesa',
      common: 'Terras Austrais e Antárticas Francesas',
    },
    rus: {
      official: 'Территория Французские Южные и Антарктические земли',
      common: 'Французские Южные и Антарктические территории',
    },
    spa: {
      official: 'Territorio del Francés Tierras australes y antárticas',
      common: 'Tierras Australes y Antárticas Francesas',
    },
    fin: {
      official: 'Ranskan eteläiset ja antarktiset alueet',
      common: 'Ranskan eteläiset ja antarktiset alueet',
    },
  },
  latlng: [-49.25, 69.167],
  demonym: 'French',
  landlocked: false,
  borders: [],
  area: 7747,
  provinces: [
    'Adelie Land',
    'Ile Crozet',
    'Iles Kerguelen',
    'Iles Saint-Paul et Amsterdam',
  ],
  nativeName: 'Territoire des Terres australes et antarctiques françaises',
} as const;
