import { render, staticRenderFns } from "./FormPhone.vue?vue&type=template&id=334ba90b&scoped=true"
import script from "./FormPhone.vue?vue&type=script&setup=true&lang=ts"
export * from "./FormPhone.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./FormPhone.vue?vue&type=style&index=0&id=334ba90b&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "334ba90b",
  null
  
)

export default component.exports