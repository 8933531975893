export const NOR = {
  name: {
    common: 'Norway',
    official: 'Kingdom of Norway',
    native: {
      nno: { official: 'Kongeriket Noreg', common: 'Noreg' },
      nob: { official: 'Kongeriket Norge', common: 'Norge' },
      smi: { official: 'Norgga gonagasriika', common: 'Norgga' },
    },
  },
  cities: [
    'Jar',
    'Karlshus',
    'Moss',
    'Oslo',
    'Frogner',
    'Drammen',
    'Vestby',
    'Aursmoen',
    'Tranby',
    'Bergen',
    'Sarpsborg',
    'Kongsvinger',
    'Asgardstrand',
    'Kolbotn',
    'LillestrÃ¸m',
    'Horten',
    'Lysaker',
    'Nesbru',
    'Skarnes',
    'Aseral',
    'Stokke',
    'Hammerfest',
    'Askim',
    'Sortland',
    'Rygge',
    'Leirsund',
    'Dal',
    'Raholt',
    'Dilling',
    'Elverum',
    'Trondheim',
    'Kolsas',
    'Fornebu',
    'Skien',
    'Finnsnes',
    'Tomasjorda',
    'TromsÃ¸',
    'Tromsdalen',
    'Hovik verk',
    'Stabekk',
    'Oppegard',
    'Arendal',
    'GjÃ¸vik',
    'Salhus',
    'Hol',
    'Gjerdrum',
    'Fetsund',
    'Ã…lesund',
    'Molde',
    'Skodje',
    'Fosnavag',
    'Brattvag',
    'Langevag',
    'Syvik',
    'Vestnes',
    'Volda',
    'Ulsteinvik',
    'Eide',
    'Hareid',
    'Stranda',
    'Stordal',
    'Hjelset',
    'Tennfjord',
    'Lyngstad',
    'Kristiansund',
    'Rovde',
    'Aukra',
    'Skotterud',
    'Moelv',
    'Magnor',
    'Brumunddal',
    'Harstad',
    'Larvik',
    'Andalsnes',
    'Ski',
    'Halden',
    'Sandefjord',
    'Fredrikstad',
    'TÃ¸nsberg',
    'Porsgrunn',
    'Baerums verk',
    'Tertnes',
    'Rjukan',
    'Holmestrand',
    'Lillehammer',
    'BodÃ¸',
    'Krakeroy',
    'Rolvsoy',
    'Saltnes',
    'Haukeland',
    'Ranheim',
    'Jessheim',
    'Sandvika',
    'Haslum',
    'Asker',
    'Langhus',
    'Stavern',
    'Gressvik',
    'Kragero',
    'Nevlunghamn',
    'Hamar',
    'Ottestad',
    'Arnes',
    'Gran',
    'Mo i Rana',
    'Frekhaug',
    'Eidsvoll verk',
    'Kristiansand',
    'Narvik',
    'Stathelle',
    'Heimdal',
    'NÃ¸tterÃ¸y',
    'Sorum',
    'Sorumsand',
    'Saupstad',
    'Ã˜rsta',
    'Bones',
    'Steinkjer',
    'Engalsvik',
    'Karlshus',
    'Nesoddtangen',
    'Stange',
    'Honefoss',
    'Jevnaker',
    'Royken',
    'Hunndalen',
    'Melsomvik',
    'HonningsvÃ¥g',
    'Slemmestad',
    'Randaberg',
    'Krokstadelva',
    'Raufoss',
    'Heggedal',
    'Namsos',
    'Sellebakk',
    'Haugesund',
    'Kyrksaeterora',
    'Geilo',
    'Al',
    'Gol',
    'Nesbyen',
    'Sagvag',
    'Levanger',
    'Tiller',
    'Hell',
    'Hegra',
    'Stavanger',
    'Indre Arna',
    'Laksevag',
    'Spydeberg',
    'Orkanger',
    'Ã…fjord',
    'Son',
    'Nesttun',
    'Langesund',
    'Sorbo',
    'Floro',
    'Selje',
    'Stryn',
    'Bygstad',
    'Sandane',
    'Naustdal',
    'Vassenden',
    'Kalvag',
    'Forde',
    'Deknepollen',
    'Raudeberg',
    'Leikang',
    'Sokndal',
    'Hoyanger',
    'Storslett',
    'Skjervoy',
    'Silsand',
    'Samuelsberg',
    'Lyngseidet',
    'Moen',
    'Gargan',
    'VadsÃ¸',
    'Jakobselv',
    'Kirkenes',
    'BÃ¥tsfjord',
    'Ivgobahta',
    'Cavkkus',
    'Tennevoll',
    'Birtavarre',
    'Olderdalen',
    'Kautokeino',
    'Hasvik',
    'Sorvaer',
    'Akkarfjord',
    'Talvik',
    'Varangerbotn',
    'Evje',
    'Grimstad',
    'Kvinesdal',
    'Nodeland',
    'Lyngdal',
    'Valle',
    'Strommen',
    'Kopervik',
    'Orje',
    'As',
    'Kongsberg',
    'Vatne',
    'Gardermoen',
    'Garnes',
    'Saebo',
    'Manger',
    'Hjellestad',
    'Godvik',
    'Flekke',
    'Granvin',
    'Storebo',
    'Os',
    'Leirvik',
    'Mosjoen',
    'Loddefjord',
    'Kvinlog',
    'Amli',
    'Mandal',
    'Sandnessjoen',
    'Hemnesberget',
    'Movik',
    'Vennesla',
    'Fenstad',
    'Naersnes',
    'Oppdal',
    'Holen',
    'Hvalstad',
    'Husoy',
    'Skedsmokorset',
    'Fla',
    'Lena',
    'Eidsvoll',
    'Dokka',
    'Koppang',
    'Vingelen',
    'Heggenes',
    'Ringebu',
    'Kolbu',
    'Vestre Gausdal',
    'Rogne',
    'Land',
    'Gaupen',
    'Sor-Fron',
    'Bruflat',
    'Eina',
    'Follebu',
    'Hov',
    'Valer',
    'Nordland',
    'Ballangen',
    'Fauske',
    'Nesna',
    'Straumen',
    'Alta',
    'Karasjok',
    'Lakselv',
    'Bjornevatn',
    'Burfjord',
    'SvolvÃ¦r',
    'Rorvik',
    'Angvika',
    'Eide',
    'Batnfjordsora',
    'Kvisvik',
    'Flemma',
    'Vagland',
    'Indre Klubben',
    'Sandnes',
    'Nydalen',
    'Hemnes',
    'Loken',
    'Bjorkelangen',
    'Fosser',
    'Trollfjorden',
    'Stokmarknes',
    'Melbu',
    'Dverberg',
    'Andenes',
    'Stronstad',
    'Risoyhamn',
    'Drag',
    'Manndalen',
    'Fagernes',
    'Leira',
    'Rakkestad',
    'Eidsberg',
    'Solbergelva',
    'Sande',
    'Mjondalen',
    'Lunner',
    'Jaren',
    'Felle',
    'Drangedal',
    'Stabbestad',
    'Sannidal',
    'Etne',
    'Kolnes',
    'Svortland',
    'Rubbestadneset',
    'Vestra Mosterhamn',
    'Skjoldastraumen',
    'Stol',
    'Naerbo',
    'Tau',
    'Tananger',
    'Algard',
    'Byglandsfjord',
    'Flekkefjord',
    'Farsund',
    'Vanse',
    'Eiken',
    'Varhaug',
    'Royneberg',
    'Oltedal',
    'Jorpeland',
    'Bryne',
    'Sola',
    'Kongsvika',
    'Evenskjer',
    'Borkenes',
    'Gratangen',
    'Lodingen',
    'Sandeid',
    'Skudeneshavn',
    'Vedavagen',
    'Olen',
    'Bomlo',
    'Akrehamn',
    'Sveio',
    'Tjelta',
    'Gullaug',
    'Lier',
    'Klepp',
    'Kleppe',
    'Vikedal',
    'Odda',
    'Berge',
    'Bo',
    'Bo',
    'Notodden',
    'Hokksund',
    'Seljord',
    'Ulefoss',
    'Dirdal',
    'Hommersak',
    'Steinberg',
    'Svelvik',
    'Greaker',
    'Sirevag',
    'Konsmo',
    'Finsand',
    'Mosby',
    'Fossdalen',
    'Oveland',
    'Borhaug',
    'Ovrebo',
    'Fiskum',
    'Skoppum',
    'Flam',
    'LÃ¦rdal',
    'Voksa',
    'Isdalsto',
    'Drobak',
    'Suldalsosen',
    'Myre',
    'Inndyr',
    'Melhus',
    'Egersund',
    'Erdal',
    'Mathopen',
    'Hylkje',
    'Mysen',
    'Florvag',
    'Kleppesto',
    'SÃ¸gne',
    'Froland',
    'Klofta',
    'Straume',
    'Loten',
    'Oksvoll',
    'Agotnes',
    'Vollen',
    'Ikornnes',
    'Hvam',
    'Hakadal',
    'Brekstad',
    'Lillesand',
    'Vestfossen',
    'Bjerkvik',
    'Bjaland',
    'Almas',
    'Amot',
    'Odnes',
    'Minnesund',
    'Maura',
    'Setermoen',
    'Auli',
    'Austmarka',
    'Kirkenaer',
    'Kroderen',
    'Bruvik',
    'Brandbu',
    'Sokna',
    'Grua',
    'Gan',
    'Sjovegan',
    'Vaksdal',
    'Sandvoll',
    'Holter',
    'Kjeller',
    'Kvelde',
    'Valestrandfossen',
    'Torpo',
    'Ranasfoss',
    'Saebovik',
    'Helgeroa',
    'Hvittingfoss',
    'Tonstad',
    'Hemsedal',
    'Nordby Bruk',
    'Fjellstrand',
    'Skabu',
    'Otta',
    'Lom',
    'Vinstra',
    'Faberg',
    'Fyrde',
    'Davik',
    'Nordfjordeid',
    'Rognan',
    'Bud',
    'Rossland',
    'Lonevag',
    'Sandtorg',
    'Tovik',
    'Stavern',
    'Lalm',
    'Dovre',
    'Vage',
    'Kolltveit',
    'Voss',
    'Moi',
    'Kvam',
    'Maloy',
    'Sogn',
    'Heroysund',
    'Hagavik',
    'Feiring',
    'Lidaladdi',
    'Myre',
    'Sorvik',
    'Tomter',
    'Fagerstrand',
    'Hyllestad',
    'Hellvik',
    'Kjopsvik',
    'Skutvika',
    'Nykirke',
    'Hof',
    'Skreia',
    'Trengereid',
    'Hornnes',
    'Skatoy',
    'Staubo',
    'Vegarshei',
    'Grendi',
    'Hagan',
    'Venabygd',
    'Ise',
    'Kjellmyra',
    'Langfjordbotn',
    'Kjollefjord',
    'Mehamn',
    'Tysnes',
    'Vormedal',
    'Fitjar',
    'Figgjo',
    'Hovden',
    'Bykle',
    'Onarheim',
    'Skanevik',
    'Mirza Rafi Sauda',
    'Skjold',
    'Folderoy',
    'Sira',
    'Hauge',
    'Sandnes',
    'Ana-Sira',
    'Sto',
    'Utsira',
    'Flateby',
    'Roa',
    'Finnoy',
    'Rost',
    'VÃ¦rÃ¸y',
    'Gravdal',
    'Kabelvag',
    'Henningsvaer',
    'Stamsund',
    'Leknes',
    'Ballstad',
    'Soreidgrenda',
    'Havik',
    'Uggdal',
    'Reistad',
    'Sylling',
    'Bostad',
    'Gvarv',
    'Glomfjord',
    'Kaupanger',
    'Svarstad',
    'Dyrvika',
    'Hvitsten',
    'Lunde',
    'Nedenes',
    'Faervik',
    'Birkeland',
    'Fevik',
    'Eikelandsosen',
    'Rong',
    'Eikanger',
    'Amot',
    'Geithus',
    'Vikersund',
    'Eggedal',
    'Prestfoss',
    'Romedal',
    'Alvdal',
    'Innbygda',
    'Indre Ulvsvag',
    'Siggerud',
    'Bjoneroa',
    'Evanger',
    'Skotbu',
    'Torod',
    'Lokken Verk',
    'Skaun',
    'Eggkleiva',
    'Storas',
    'Rennebu',
    'Berkak',
    'Hommelvik',
    'Tanem',
    'Rud',
    'Berger',
    'Barkaker',
    'Sande',
    'Austbygdi',
    'Lysoysundet',
    'Eidsvag',
    'Hestvika',
    'Kval',
    'Hovin',
    'Gjolme',
    'Haerland',
    'Sunndalsora',
    'Feda',
    'Surnadalsora',
    'Nedre Frei',
    'Halsa',
    'Enga',
    'Verdal',
    'Furnes',
    'Sander',
    'Roverud',
    'Knapper',
    'Uvdal',
    'Rodberg',
    'Fardal',
    'Ardalstangen',
    'Rypefjord',
    'Sistranda',
    'Ovre Ardal',
    'Skjolden',
    'Hamarvik',
    'Singsas',
    'Follese',
    'Knarrlaget',
    'Vangsvika',
    'Balestrand',
    'Solvorn',
    'Tjeldsto',
    'Knarrevik',
    'Saetre',
    'Slastad',
    'Brandval',
    'Arneberg',
    'Misje',
    'Glesvaer',
    'Hitra',
    'Tofte',
    'Vevang',
    'Hersaeter',
    'Meraker',
    'Neverdal',
    'Selvaer',
    'Reipa',
    'Vadheim',
    'Slattum',
    'RisÃ¸r',
    'Bagn',
    'Aurdal',
    'TjÃ¸me',
    'Ramnes',
    'Beitostolen',
    'Krakstad',
    'Salsnes',
    'Foldereid',
    'Abelvaer',
    'Flesberg',
    'Tyristrand',
    'Valle',
    'Tveit',
    'Ulvik',
    'Sunde',
    'Uskedalen',
    'Valen',
    'Rosendal',
    'Berge',
    'Havik',
    'Hatlestranda',
    'Dimmelsvik',
    'Tafjord',
    'Godoy',
    'Eidsnes',
    'Valldal',
    'Grong',
    'Skage',
    'Sorli',
    'Veggli',
    'Rykene',
    'Lindas',
    'Hellesylt',
    'Kjerstad',
    'Brennasen',
    'Tyssedal',
    'Kolvereid',
    'Brattholmen',
    'Namsskogan',
    'Trones',
    'Snaase',
    'Hemnskjela',
    'Eidsvag',
    'Brevik',
    'Eidfjord',
    'Na',
    'Lofthus',
    'Norheimsund',
    'Oystese',
    'Herand',
    'Oma',
    'Mjolkeraen',
    'Torp',
    'Skjeberg',
    'Knappstad',
    'Borgen',
    'Bo',
    'Vikesa',
    'Vigrestad',
    'Bru',
    'HavÃ¸ysund',
    'Oresvika',
    'Masfjorden',
    'Reinsvoll',
    'Hokkasen',
    'Kleive',
    'Lovund',
    'Spangereid',
    'Sparbu',
    'Asen',
    'Salsbruket',
    'Skogn',
    'Skogn',
    'Aure',
    'Tingvoll',
    'Oksfjord',
    'Sandstad',
    'Haddal',
    'Sem',
    'Longva',
    'Nordkisa',
    'Tistedal',
    'Siljan',
    'Herre',
    'Saeveland',
    'Meldal',
    'Adalsbruk',
    'Re',
    'Digermulen',
    'Kleppstad',
    'Sommaroy',
    'BerlevÃ¥g',
    'Ramberg',
    'Sund',
    'Ulvagen',
    'Sorvagen',
    'Malm',
    'Indreby',
    'Hansnes',
    'Hjelmas',
    'Valestrand',
    'Kvalsund',
    'Haram',
    'Vinje',
    'Fyresdal',
    'Kviteseid',
    'Treungen',
    'Opphaug',
    'Selva',
    'Sandshamn',
    'Tvedestrand',
    'Svene',
    'Hetlevik',
    'Eidsfoss',
    'Ask',
    'Sletta',
    'Aros',
    'Strusshamn',
    'Indre Billefjord',
    'Bronnoysund',
    'Somna',
    'Hordvik',
    'Fister',
    'Helgeland',
    'Hundven',
    'Eidsdal',
    'Otteroy',
    'Sperrebotn',
    'Haltdalen',
    'Korsvegen',
    'Tjotta',
    'Leirfjord',
    'Bekkjarvik',
    'Austbo',
    'Utskarpen',
    'Eydehavn',
    'Buskerud',
    'Noresund',
    'Steigen',
    'Kongshamn',
    'Hallingby',
    'Bjerka',
    'Storforshei',
    'Haegeland',
    'Rena',
    'Re',
    'Leines',
    'Jomna',
    'Nybergsund',
    'Ringsaker',
    'Os',
    'Tolga',
    'Laukvik',
    'Tynset',
    'Myking',
    'AskÃ¸y',
    'Hovet',
    'Kamben',
    'Tysse',
    'Ervik',
    'Klokkarvik',
    'Nordstrono',
    'Mo',
    'Modalen',
    'Holmefjord',
    'Saevareid',
    'Roldal',
    'Fedje',
    'Vannvag',
    'Laksvatn',
    'Storsteinnes',
    'Midsund',
    'Tornes',
    'Rollag',
    'Tjorvag',
    'Barstadvik',
    'Lauvstad',
    'Flesnes',
    'Batsto',
    'Vikran',
    'Stamnes',
    'Hustad',
    'Folkestad',
    'Orre',
    'Rindal',
    'Valsoyfjord',
    'Kvanne',
    'Groa',
    'Meisingset',
    'Sundal',
    'Raudsand',
    'VardÃ¸',
    'BugÃ¸ynes',
    'Rauland',
    'Svelgen',
    'Vevelstad',
    'Loen',
    'Bovagen',
    'Eidsa',
    'Rokland',
    'Lepsoy',
    'Beisfjord',
    'Ytre Alvik',
    'Gjerstad',
    'Roros',
    'Malvik',
    'Vormsund',
    'Turoy',
    'Gasbakken',
    'Vikebygd',
    'Eivindvik',
    'Ankenes',
    'Ramfjordbotn',
    'Trofors',
    'Lorenfallet',
    'Dalen',
    'Konsvikosen',
    'Bleikvasslia',
    'Kyrkjebo',
    'Kvammen',
    'Gransherad',
    'Korgen',
    'Nesgrenda',
    'Horte',
    'Alen',
    'Moldjord',
    'Forde',
    'Fall',
    'Miland',
    'Steinsdalen',
    'Brusand',
    'Helleland',
    'Espeland',
    'Undeim',
    'Erfjord',
    'Hovag',
    'Lauvsnes',
    'Snillfjord',
    'Svartskog',
    'Disena',
    'Sand',
    'Fonnes',
    'Nygardsjoen',
    'Blaker',
    'Bjordal',
    'Brekke',
    'Dale',
    'Byrknes Nordre',
    'Ardal',
    'Blakset',
    'Olden',
    'Bangsund',
    'Sulisjielmma',
    'Agskardet',
    'Ornes',
    'Rodoy',
    'Skjerstad',
    'Tjong',
    'Misvaer',
    'Tangen',
    'Larkollen',
    'Arland',
    'Sand',
    'Vale',
    'Hyggen',
    'Holsen',
    'Hedal',
    'Ebru',
    'Morgedal',
    'Austevoll',
    'Spillum',
    'Bjorke',
    'Nyborg',
    'Tretten',
    'Lundamo',
    'Ask',
    'Langangen',
    'Oyeren',
    'Reine',
    'Hebnes',
    'From',
    'Aker',
    'Vraliosen',
    'Trana',
    'Nyhus',
    'Ringstad',
    'Borge',
    'Valer',
    'Dolemo',
    'Borregard',
    'Lensvik',
    'Innvik',
    'Selasvatn',
    'Sondeled',
    'Moen',
    'Kaldfarnes',
    'Follafoss',
    'Enebakkneset',
    'Boverfjorden',
    'Lenvik',
    'Amli',
    'Hildre',
    'Fotlandsvag',
    'Hole',
    'Rana',
    'Reitan',
    'Holmen',
    'Kornsjo',
    'Skarer',
    'Mestervik',
    'Skulsfjord',
    'Adland',
    'Fiska',
    'Annstad',
    'Kolbjornsvik',
    'Hjorungavag',
    'Ulstein',
    'Holmenkollen',
    'Slemdal',
    'Buvika',
    'Hellandsjoen',
    'Marheim',
    'Fresvik',
    'Soknedal',
    'Vossestrand',
    'Skeie',
  ],
  tld: ['.no'],
  cca2: 'NO',
  ccn3: '578',
  cca3: 'NOR',
  cioc: 'NOR',
  currency: ['NOK'],
  callingCode: ['47'],
  capital: 'Oslo',
  altSpellings: [
    'NO',
    'Norge',
    'Noreg',
    'Kingdom of Norway',
    'Kongeriket Norge',
    'Kongeriket Noreg',
  ],
  region: 'Europe',
  subregion: 'Northern Europe',
  languages: { nno: 'Norwegian Nynorsk', nob: 'Norwegian Bokmål', smi: 'Sami' },
  translations: {
    deu: { official: 'Königreich Norwegen', common: 'Norwegen' },
    fra: { official: 'Royaume de Norvège', common: 'Norvège' },
    hrv: { official: 'Kraljevina Norveška', common: 'Norveška' },
    ita: { official: 'Regno di Norvegia', common: 'Norvegia' },
    jpn: { official: 'ノルウェー王国', common: 'ノルウェー' },
    nld: { official: 'Koninkrijk Noorwegen', common: 'Noorwegen' },
    por: { official: 'Reino da Noruega', common: 'Noruega' },
    rus: { official: 'Королевство Норвегия', common: 'Норвегия' },
    spa: { official: 'Reino de Noruega', common: 'Noruega' },
    fin: { official: 'Norjan kuningaskunta', common: 'Norja' },
  },
  latlng: [62, 10],
  demonym: 'Norwegian',
  landlocked: false,
  borders: ['FIN', 'SWE', 'RUS'],
  area: 323802,
  provinces: [
    'Akershus',
    'Aust-Agder',
    'Buskerud',
    'Finnmark',
    'Hedmark',
    'Hordaland',
    'More og Romsdal',
    'Nord-Trondelag',
    'Nordland',
    'Oppland',
    'Oslo',
    'Ostfold',
    'Rogaland',
    'Sogn og Fjordane',
    'Sor-Trondelag',
    'Telemark',
    'Troms',
    'Vest-Agder',
    'Vestfold',
  ],
  nativeName: 'Norge',
  timezones: ['UTC+01:00'],
} as const;
