import { server } from '@api/request';
import { Certification, User } from '@models';
import { isObject, isArray, isNull } from '@tools/type-guards';

type PaginatedCertificationsResults =
  ZephyrWeb.Pagination.Results<Certification>;

/**
 * List all existing certifications.
 *
 * @return A list of certifications.
 */
export async function list() {
  return await server.list('v2/admin/certifications', processCertificationData);
}

/**
 * {@link search Search certifications} API request namespace.
 */
export namespace CertificationsSearch {
  /**
   * {@link search Search certifications} API request options.
   */
  export interface Options {
    organizationId?: string;
    productId?: string;
    startDate?: string;
    endDate?: string;
    order?: 'ASC' | 'DESC';
    limit?: number;
    startKey?: ZephyrWeb.Pagination.PlacementKey;
  }

  /**
   * {@link search Search certifications} API request response.
   */
  export type Results = PaginatedCertificationsResults;
}

/**
 * Search for certifications by a specified criteria (paginated).
 *
 * @param options Request options bag.
 * @returns `CertificationsSearch.Results` data object.
 *
 * @see {@link ZephyrApi.Admin.Certifications.searchV2 `AdminSearchCertifications`} API route in `zephyr-serverless`
 */
export async function search(options?: CertificationsSearch.Options) {
  return await server.post(
    'v2/admin/certifications/searchV2',
    options,
    processSearchResults,
  );
}

/**
 * List all unclaimed certifications the current user has permissions for.
 *
 * @returns A list of certifications.
 */
export async function listAvailable() {
  return await server.list('v2/certifications', processCertificationData);
}

/** ... */
export interface ListForUsersOptions {
  userId: User['id'];
}

/**
 * List all certifications owned by a specified user.
 *
 * @returns A list of certifications.
 */
export async function listForUser(options: ListForUsersOptions) {
  return await server.list(
    `v2/users/${options.userId}/certifications`,
    processCertificationData,
  );
}

/**
 * List all certifications claimed for review by user.
 * (Note) - must have a `Reviewer` role.
 *
 * @returns A list of certifications.
 */
export async function listClaimed() {
  return await server.list(
    'v2/reviewer/certifications',
    processCertificationData,
  );
}

/** ... */
export interface GetOptions {
  certificationId: Certification['id'];
}

/**
 * Get a specified certification.
 *
 * @param options Request options bag.
 * @returns The specified certification.
 */
export async function get(options: GetOptions) {
  return await server.get(
    `v2/certifications/${options.certificationId}`,
    processCertificationData,
  );
}

/**
 * Get a specified certification meta data.
 *
 * @param options Request options bag.
 * @returns The specified certification.
 */
export async function getMeta(options: GetOptions) {
  return await server.post(
    'v2/certifications/verify',
    options,
    processCertificationMetaData,
  );
}

/**
 * {@link updateUserName Update certification user name} API request options.
 */
export interface UpdateUserNameOptions {
  certificationId: Certification['id'];
  userId: User['id'];
  firstName: string;
  lastName: string;
}

/**
 * Add or update the user name on the specified certification.
 *
 * @param options Request options bag.
 */
export async function updateUserName(options: UpdateUserNameOptions) {
  const { certificationId, userId, ...data } = options;

  await server.post(
    `v2/users/${userId}/certifications/${certificationId}/name`,
    data,
  );
}

/**
 * {@link submitAttempt Submit certification attempt} API request options.
 */
export interface SubmitAttemptOptions {
  certificationId: Certification['id'];
  userId: User['id'];
  url: string;
  reportId?: string;
}

/**
 * Submit a certification attempt for review.
 *
 * @param options Request options bag.
 *
 * @see {@link ZephyrApi.Users.addAttempt `SubmitCertificationAttempt`} API route in `zephyr-serverless`
 */
export async function submitAttempt(options: SubmitAttemptOptions) {
  const { userId, certificationId, ...data } = options;

  return await server.post(
    `v2/users/${userId}/certifications/${certificationId}/attempts`,
    data,
  );
}

/** ... */
export interface ClaimForReviewOptions {
  certificationId: Certification['id'];
}

/**
 * ...
 *
 * @param options Request options bag.
 * @return ...
 */
export async function claimForReview(options: ClaimForReviewOptions) {
  return await server.post(`v2/certifications/${options.certificationId}`, {
    status: 'REVIEWING',
  });
}

/** ... */
export interface ReviewAttemptOptions {
  certificationId: Certification['id'];
  status: Certification.Status;
  notes?: string;
}

/**
 * ...
 *
 * @param options Request options bag.
 * @return ...
 */
export async function reviewAttempt(options: ReviewAttemptOptions) {
  const { certificationId, ...data } = options;

  return await server.post(`v2/certifications/${certificationId}`, data);
}

/**
 * {@link assignExam Assign certification exam} API request namespace.
 */
export interface AssignCertificationExamOptions {
  organizationId: string;
  productId: string;
  userIds: string[];
}

export interface AssignExamResponse {
  success: string[];
  failed: string[];
}

/**
 * Assign a certification exam to a list of students.
 *
 * @param options Request options bag.
 *
 * @see {@link ZephyrApi.Organizations.assignCertificationExam `AssignCertificationExams`} API route in `zephyr-serverless`
 */
export async function assignExam(options: AssignCertificationExamOptions) {
  const { organizationId, ...data } = options;

  return await server.post<AssignExamResponse>(
    `v2/organizations/${organizationId}/assign-certification-exams`,
    data,
  );
}

/**
 * {@link generatePdf certifications.generatePdf} API request payload options.
 */
export interface GeneratePdfOptions {
  userId: string;
  certificationId: Certification['id'];
}

/**
 * ...
 *
 * @returns Certification PDF data.
 *
 * @see {@link ZephyrApi.Certifications.generateCertificationPdf `GenerateCertificationPdf`} API route in `zephyr-serverless`
 */
export async function generatePdf(options: GeneratePdfOptions) {
  return await server.post('v2/certifications/generate', options);
}

//#region Helper Functions

/**
 * Determine if a value is a valid {@link Certification}.
 *
 * @param value The value to check.
 * @returns `true` if the value is a valid {@link Certification}, otherwise
 * `false`.
 */
function isValidCertification(value: unknown): value is Certification {
  if (!isObject(value)) return false;

  // TEMP: Use a more strict validation.

  return true;
}

/**
 * Process data received from a request expected to be a {@link Certification}.
 *
 * @param data Data received from the request.
 * @returns The processed data value.
 */
function processCertificationData(data: unknown) {
  if (!isValidCertification(data)) {
    throw new Error('Invalid certification data item.');
  }

  return { ...data } as Certification;
}

export interface CertificationMeta {
  issuedTo: string;
  issuedAt: string;
  issuedBy: string;
  issuedByUrl: string;
  issuedUsing: string;
  issuedUsingUrl: string;
  acceptedAt: string;
  expiresAt: string;
  lastUpdated: string;
  badgeUrl: string | null;
  name: string;
  description: string;
  status: Certification.Status;
}

/**
 * Determine if a value is a valid {@link CertificationMeta}.
 *
 * @param value The value to check.
 * @returns `true` if the value is a valid {@link CertificationMeta}, otherwise
 * `false`.
 */
function isValidCertificationMeta(value: unknown): value is CertificationMeta {
  if (!isObject(value)) return false;

  // TEMP: Use a more strict validation.

  return true;
}

/**
 * Process data received from a request expected to be a
 * {@link CertificationMeta}.
 *
 * @param data Data received from the request.
 * @returns The processed data value.
 */
function processCertificationMetaData(data: unknown) {
  if (!isValidCertificationMeta(data)) {
    throw new Error('Invalid certification meta data item.');
  }

  return { ...data } as CertificationMeta;
}

/**
 * Determine if a value is a valid {@link CertificationsSearch.Results}.
 *
 * @param value The value to check.
 * @returns `true` if the value is a valid {@link CertificationsSearch.Results},
 * otherwise `false`.
 */
function isValidSearchResults(
  value: unknown,
): value is CertificationsSearch.Results {
  return (
    isObject(value) &&
    isArray(value.items) &&
    (isObject(value.lastEvaluatedKey) || isNull(value.lastEvaluatedKey))
  );
}

/**
 * Process data received from a request expected to be a
 * {@link CertificationsSearch.Results}.
 *
 * @param data Data received from the request.
 * @returns The processed data value.
 */
function processSearchResults(data: unknown) {
  if (!isValidSearchResults(data)) {
    throw new Error('Invalid search result data.');
  }

  return data;
}

//#endregion Helper Functions
