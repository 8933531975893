var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _setup.props.value === "mac"
        ? _c("icon", { attrs: { icon: _setup.faApple } })
        : _vm._e(),
      _vm._v(" "),
      _setup.props.value === "win"
        ? _c("icon", { attrs: { icon: _setup.faWindows } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }