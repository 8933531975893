/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { camelCase } from 'lodash';

/**
 * Resulting file information parsed from a required module's key.
 */
interface FileInfo {
  name: string;
  extension: string;
  directory: string;
}

/**
 * Parse `FileInfo` from required module key.
 *
 * @param key The module key.
 * @return Resulting `FileInfo`.
 */
function getFileInfo(key: string) {
  // const [, directory, name, extension] =
  //   /\/?(.*?)\/([\w-]+?)\.(js|tsx?|vue)$/.exec(key) ?? [];

  const [file, directory] = key.split('/').reverse();
  const [, name, extension] = /(.+?)\.(js|tsx?|vue)$/.exec(file ?? '') ?? [];

  return {
    name: name ?? '',
    extension: extension ?? '',
    directory: directory ?? '',
  } as FileInfo;
}

/**
 * Generate a name for a required module based off it's key (which itself is a
 * relative file path).
 *
 * @param info File info from the required module.
 * @return The generated name.
 */
function generateModuleName(info: FileInfo) {
  // If the file's name is somehow falsy, return `null`.
  if (!info.name) return null;

  // If the fil is an index file, use the name of the parent directory to
  // generate the module name.
  const name = info.name === 'index' ? info.directory : info.name;

  // If the module is a Vue SFC, leave the name as-is. Otherwise, apply a
  // camel-case filter to it.
  return info.extension === 'vue' ? name : camelCase(name);
}

/**
 * Helper function for handling the result of a webpack `require.context` call.
 *
 * @param rc The required context function.
 * @param callback A function that's called for each required module.
 */
export function importContext(
  rc: __WebpackModuleApi.RequireContext,
  callback?: importContext.Callback,
) {
  rc.keys().forEach((key) => {
    const info = getFileInfo(key);

    // If the key maps to a root index file ('./index...'), ignore it.
    if (info.directory === '.' && info.name === 'index') return;

    // If no callback was provided, simply require it and move on.
    if (!callback) return rc(key);

    // Generate a name for the module.
    const name = generateModuleName(info);

    // If the moduel name is somehow invalid, do not include it.
    if (!name) {
      return console.warn(
        `[@utils/importContext] could not generate a name for the required module located at ${rc.resolve(
          key,
        )}, and as a result, will be ignored. Check to make sure the file name is valid.`,
      );
    }

    const value = rc(key);

    // For the result of the required module, provide the value of `export default`
    // if it exists, otherwise provide the value as-is.
    callback({ name, value: value.default ?? value });
  });
}

export namespace importContext {
  /**
   * Object containing the result of a required module.
   */
  export interface RequireResult {
    name: string;
    value: unknown;
  }

  /** Callback function for handling required modules. */
  export type Callback = (result: RequireResult) => void;
}
