import { server } from '@api/request';
import type { Organization, Module, User } from '@models';

/**
 * ...
 */
export interface CreateOptions {
  organizationId: Organization['id'];
  email: string;
  startDate: string | null;
  endDate: string;
  modules: Module['id'][];
  users: User['id'][];
  altitudeViolations: boolean;
  averageAltitude: boolean;
  averageSpeed: boolean;
  completed: boolean;
  crashCount: boolean;
  droneName: boolean;
  grade: boolean;
  highestScore: boolean;
  lostLineOfSightViolations: boolean;
  maxAltitude: boolean;
  moduleCompleted: boolean;
  moduleName: boolean;
  moduleStartTime: boolean;
  moduleTotalTime: boolean;
  resetCount: boolean;
  sceneName: boolean;
  score: boolean;
  scorePercent: boolean;
  topSpeed: boolean;
  userName: boolean;
}

/**
 * Create a report request.
 *
 * @param options ...
 * @returns ...
 */
export async function create(options: CreateOptions) {
  await server.post(
    `v2/organizations/${options.organizationId}/reports/requests`,
    options,
  );
}
