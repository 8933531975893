var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("Transition", [
    _setup.visible
      ? _c("div", { staticClass: "route-load-progress-bar" }, [
          _c("div", { style: { right: `${100 - _setup.displayProgress}%` } }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }