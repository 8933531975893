export const CIV = {
  name: {
    common: 'Ivory Coast',
    official: "Republic of Côte d'Ivoire",
    native: {
      fra: { official: "République de Côte d'Ivoire", common: "Côte d'Ivoire" },
    },
  },
  cities: ['Abidjan'],
  tld: ['.ci'],
  cca2: 'CI',
  ccn3: '384',
  cca3: 'CIV',
  cioc: 'CIV',
  currency: ['XOF'],
  callingCode: ['225'],
  capital: 'Yamoussoukro',
  altSpellings: [
    'CI',
    "Côte d'Ivoire",
    'Ivory Coast',
    "Republic of Côte d'Ivoire",
    "République de Côte d'Ivoire",
  ],
  region: 'Africa',
  subregion: 'Western Africa',
  languages: { fra: 'French' },
  translations: {
    deu: { official: "Republik Côte d'Ivoire", common: 'Elfenbeinküste' },
    fra: { official: "République de Côte d' Ivoire", common: "Côte d'Ivoire" },
    hrv: { official: "Republika Côte d'Ivoire", common: 'Obala Bjelokosti' },
    ita: {
      official: "Repubblica della Costa d'Avorio",
      common: "Costa d'Avorio",
    },
    jpn: { official: 'コートジボワール共和国', common: 'コートジボワール' },
    nld: { official: 'Republiek Ivoorkust', common: 'Ivoorkust' },
    por: { official: "República da Côte d'Ivoire", common: 'Costa do Marfim' },
    rus: { official: "Республика Кот-д'Ивуаре", common: 'Кот-д’Ивуар' },
    spa: { official: "República de Côte d'Ivoire", common: 'Costa de Marfil' },
    fin: {
      official: 'Norsunluurannikon tasavalta',
      common: 'Norsunluurannikko',
    },
  },
  latlng: [8, -5],
  demonym: 'Ivorian',
  landlocked: false,
  borders: ['BFA', 'GHA', 'GIN', 'LBR', 'MLI'],
  area: 322463,
  provinces: [
    'Abengourou',
    'Abidjan',
    'Aboisso',
    "Adiake'",
    'Adzope',
    'Agboville',
    'Agnibilekrou',
    "Ale'pe'",
    'Bangolo',
    'Beoumi',
    'Biankouma',
    'Bocanda',
    'Bondoukou',
    'Bongouanou',
    'Bouafle',
    'Bouake',
    'Bouna',
    'Boundiali',
    'Dabakala',
    'Dabon',
    'Daloa',
    'Danane',
    'Daoukro',
    'Dimbokro',
    'Divo',
    'Duekoue',
    'Ferkessedougou',
    'Gagnoa',
    'Grand Bassam',
    'Grand-Lahou',
    'Guiglo',
    'Issia',
    'Jacqueville',
    'Katiola',
    'Korhogo',
    'Lakota',
    'Man',
    'Mankono',
    'Mbahiakro',
    'Odienne',
    'Oume',
    'Sakassou',
    'San-Pedro',
    'Sassandra',
    'Seguela',
    'Sinfra',
    'Soubre',
    'Tabou',
    'Tanda',
    'Tiassale',
    'Tiebissou',
    'Tingrela',
    'Touba',
    'Toulepleu',
    'Toumodi',
    'Vavoua',
    'Yamoussoukro',
    'Zuenoula',
  ],
  nativeName: "Côte d'Ivoire",
  timezones: ['UTC'],
} as const;
