export const KIR = {
  name: {
    common: 'Kiribati',
    official: 'Independent and Sovereign Republic of Kiribati',
    native: {
      eng: {
        official: 'Independent and Sovereign Republic of Kiribati',
        common: 'Kiribati',
      },
      gil: { official: 'Ribaberiki Kiribati', common: 'Kiribati' },
    },
  },
  cities: ['Umwa Village'],
  tld: ['.ki'],
  cca2: 'KI',
  ccn3: '296',
  cca3: 'KIR',
  cioc: 'KIR',
  currency: ['AUD'],
  callingCode: ['686'],
  capital: 'South Tarawa',
  altSpellings: ['KI', 'Republic of Kiribati', 'Ribaberiki Kiribati'],
  region: 'Oceania',
  subregion: 'Micronesia',
  languages: { eng: 'English', gil: 'Gilbertese' },
  translations: {
    deu: {
      official: 'Unabhängige und souveräne Republik Kiribati',
      common: 'Kiribati',
    },
    fra: { official: 'République de Kiribati', common: 'Kiribati' },
    hrv: {
      official: 'Samostalne i suverene Republike Kiribati',
      common: 'Kiribati',
    },
    ita: {
      official: 'Repubblica indipendente e sovrano di Kiribati',
      common: 'Kiribati',
    },
    jpn: { official: 'キリバスの独立と主権共和国', common: 'キリバス' },
    nld: {
      official: 'Onafhankelijke en soevereine republiek Kiribati',
      common: 'Kiribati',
    },
    por: {
      official: 'Independente e soberano República de Kiribati',
      common: 'Kiribati',
    },
    rus: {
      official: 'Независимой и суверенной Республики Кирибати',
      common: 'Кирибати',
    },
    spa: {
      official: 'República Independiente y Soberano de Kiribati',
      common: 'Kiribati',
    },
    fin: { official: 'Kiribatin tasavalta', common: 'Kiribati' },
  },
  latlng: [1.41666666, 173],
  demonym: 'I-Kiribati',
  landlocked: false,
  borders: [],
  area: 811,
} as const;
