var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "os-cell-container" },
    _vm._l(_setup.items, function ({ key, item }) {
      return _c(
        "div",
        { key: key, staticClass: "mx-1" },
        [
          item === "mac"
            ? _c("icon", { attrs: { icon: _setup.faApple } })
            : _vm._e(),
          _vm._v(" "),
          item === "win"
            ? _c("icon", { attrs: { icon: _setup.faWindows } })
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }