import type { Vue as _Vue } from '@vue';

import { events } from './events';
import Notifications from './Notifications.vue';

declare module 'vue/types/vue' {
  interface Vue {
    $notify: Notify;
  }

  interface VueConstructor {
    notify: Notify;
  }
}

/**
 * ...
 */
export interface NotificationOptions {
  id?: number;
  title?: string;
  text?: string;
  type?: string;
  group?: string;
  duration?: number;
  speed?: number;
  data?: object;
  clean?: boolean;
  velocity?: unknown;
  // ...
  ignoreDuplicates?: boolean;
}

/**
 * ...
 *
 * @param options ...
 */
function notify(options: NotificationOptions | string) {
  const params: NotificationOptions =
    typeof options === 'string' ? { text: options } : options;

  events.$emit('add', params);
}

/**
 * ...
 *
 * @param id ..
 */
notify.close = (id: string) => {
  events.$emit('close', id);
};

/** `vue-notifications` plugin instance. */
type Notify = typeof notify;

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class VueNotifications {
  static installed = false;
  static params: NotificationOptions | null = null;

  static install(
    Vue: _Vue.OriginalConstructor,
    options: NotificationOptions = {},
  ) {
    if (this.installed) return;

    this.installed = true;
    this.params = options;

    Vue.component('Notifications', Notifications);

    Vue.prototype.$notify = notify;
    Vue.notify = notify;
  }
}

export namespace VueNotifications {
  /** ... */
  export type Options = NotificationOptions;
}
