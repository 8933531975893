import VueClass, { type PropType } from 'vue';
import { Vue as IVue } from 'vue/types/vue';

declare module 'vue/types/vue' {
  interface VueConstructor {
    prototype: { -readonly [P in keyof IVue]: IVue[P] };
  }
}

declare global {
  /** ... */
  type ComponentWithProps<T = unknown> = new () => Vue & { $props: T };

  /** ... */
  // type ShimComponentProps<T extends new (...args: any) => Vue> =
  //   new () => InstanceType<T> & {
  //     $props: ComponentProps<Omit<InstanceType<T>, keyof Vue>>;
  //   };

  type ShimComponentProps<T extends typeof Vue> = new () => InstanceType<T> & {
    $props: ComponentProps<Omit<InstanceType<T>, keyof Vue>>;
  };
}

/**
 * ...
 */
export declare class ExtendedVue extends VueClass {
  // Add or overload Vue class properties declaring them elsewhere in the
  // codebase.
}

export * from './next';
export * from './class-component';

/** Original Vue class reference typecast with a fully overlodable interface. */
export const Vue = VueClass as typeof ExtendedVue;

export type Vue = ExtendedVue;

export namespace Vue {
  /** ... */
  export type Constructor = typeof ExtendedVue;
  /** ... */
  export type OriginalConstructor = typeof VueClass;
  /** ... */
  export type Component = import('vue').Component;
  /** ... */
  export type ComponentOptions<V = Vue> = import('vue').ComponentOptions<V>;
  /** ... */
  export type PropOptions<T = unknown> = import('vue').PropOptions<T>;
  /** ... */
  export type AsyncComponent = import('vue').AsyncComponent;
  /** ... */
  export type PluginFunction<T = never> = import('vue').PluginFunction<T>;
}

export default Vue;

//#region Local Utility Types

/** ... */
type ComponentProps<T> = {
  [K in keyof Pick<T, ReadonlyKeys<T> & OptionalKeys<T>>]?: T[K];
} & { [K in keyof Pick<T, ReadonlyKeys<T> & RequiredKeys<T>>]: T[K] };

/** ... */
type Equal<X, Y> = (<T>() => T extends X ? 1 : 2) extends <T>() => T extends Y
  ? 1
  : 2
  ? true
  : false;

/** ... */
type ReadonlyKeys<
  T,
  U extends Readonly<T> = Readonly<T>,
  K extends keyof T = keyof T,
> = K extends keyof T
  ? Equal<Pick<T, K>, Pick<U, K>> extends true
    ? K
    : never
  : never;

/** ... */
type OptionalKeys<T> = keyof {
  [K in keyof T as T[K] extends Required<T>[K] ? never : K]: T[K];
};

/** ... */
type RequiredKeys<T> = keyof {
  [K in keyof T as T[K] extends Required<T>[K] ? K : never]: T[K];
};

//#endregion Local Utility Types

//#region `ExtractPublicPropTypes` Utility Type

type InferPropType<T> = [T] extends [null]
  ? any
  : [T] extends [{ type: null | true }]
  ? any
  : [T] extends [ObjectConstructor | { type: ObjectConstructor }]
  ? Record<string, any>
  : [T] extends [BooleanConstructor | { type: BooleanConstructor }]
  ? boolean
  : [T] extends [DateConstructor | { type: DateConstructor }]
  ? Date
  : [T] extends [(infer U)[] | { type: (infer U)[] }]
  ? U extends DateConstructor
    ? Date | InferPropType<U>
    : InferPropType<U>
  : [T] extends [Prop<infer V, infer D>]
  ? unknown extends V
    ? IfAny<V, V, D>
    : V
  : T;

type IfAny<T, Y, N> = 0 extends 1 & T ? Y : N;

type PublicRequiredKeys<T> = {
  [K in keyof T]: T[K] extends {
    required: true;
  }
    ? K
    : never;
}[keyof T];

type PublicOptionalKeys<T> = Exclude<keyof T, PublicRequiredKeys<T>>;

interface PropOptions<T = any, D = T> {
  type?: PropType<T> | true | null;
  required?: boolean;
  default?: D | DefaultFactory<D> | null | undefined | object;
  validator?(value: unknown): boolean;
}

export type Prop<T, D = T> = PropOptions<T, D> | PropType<T>;

type DefaultFactory<T> = (
  props: Record<string, unknown>,
) => T | null | undefined;

declare global {
  /**
   * ...
   */
  type ExtractComponentPublicPropTypes<O> = {
    [K in keyof Pick<O, PublicRequiredKeys<O>>]: InferPropType<O[K]>;
  } & {
    [K in keyof Pick<O, PublicOptionalKeys<O>>]?: InferPropType<O[K]>;
  };
}

//#endregion `ExtractPublicPropTypes` Utility Type
