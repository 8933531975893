export const SEN = {
  name: {
    common: 'Senegal',
    official: 'Republic of Senegal',
    native: { fra: { official: 'République du Sénégal', common: 'Sénégal' } },
  },
  cities: [
    'Sama',
    'Dakar',
    'Guediawaye',
    'Louga',
    'Kaolack',
    'Dodji',
    'Boussinki',
    'Tanaf',
    'Saint-Louis',
    'Camberene',
    'Kedougou',
    'Madina Kokoun',
  ],
  tld: ['.sn'],
  cca2: 'SN',
  ccn3: '686',
  cca3: 'SEN',
  cioc: 'SEN',
  currency: ['XOF'],
  callingCode: ['221'],
  capital: 'Dakar',
  altSpellings: ['SN', 'Republic of Senegal', 'République du Sénégal'],
  region: 'Africa',
  subregion: 'Western Africa',
  languages: { fra: 'French' },
  translations: {
    deu: { official: 'Republik Senegal', common: 'Senegal' },
    fra: { official: 'République du Sénégal', common: 'Sénégal' },
    hrv: { official: 'Republika Senegal', common: 'Senegal' },
    ita: { official: 'Repubblica del Senegal', common: 'Senegal' },
    jpn: { official: 'セネガル共和国', common: 'セネガル' },
    nld: { official: 'Republiek Senegal', common: 'Senegal' },
    por: { official: 'República do Senegal', common: 'Senegal' },
    rus: { official: 'Республика Сенегал', common: 'Сенегал' },
    spa: { official: 'República de Senegal', common: 'Senegal' },
    fin: { official: 'Senegalin tasavalta', common: 'Senegal' },
  },
  latlng: [14, -14],
  demonym: 'Senegalese',
  landlocked: false,
  borders: ['GMB', 'GIN', 'GNB', 'MLI', 'MRT'],
  area: 196722,
  provinces: [
    'Dakar',
    'Diourbel',
    'Fatick',
    'Kaolack',
    'Kolda',
    'Louga',
    'Saint-Louis',
    'Tambacounda',
    'Thies',
    'Ziguinchor',
  ],
  nativeName: 'Sénégal',
  timezones: ['UTC'],
} as const;
