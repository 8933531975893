export const SGP = {
  name: {
    common: 'Singapore',
    official: 'Republic of Singapore',
    native: {
      cmn: { official: '新加坡共和国', common: '新加坡' },
      eng: { official: 'Republic of Singapore', common: 'Singapore' },
      msa: { official: 'Republik Singapura', common: 'Singapura' },
      tam: { official: 'சிங்கப்பூர் குடியரசு', common: 'சிங்கப்பூர்' },
    },
  },
  cities: [
    'Singapore',
    'Yishun New Town',
    'Bedok New Town',
    'Ayer Raja New Town',
    'Kalang',
    'Tampines New Town',
    'Ang Mo Kio New Town',
    'Kampong Pasir Ris',
    'Hougang',
    'Yew Tee',
    'Choa Chu Kang New Town',
    'Punggol',
    'Changi Village',
    'Bukit Timah Estate',
    'Serangoon',
    'Jurong Town',
    'Tanglin Halt',
    'Woodlands New Town',
    'Jurong East New Town',
    'Bukit Panjang New Town',
    'Bukit Batok New Town',
    'Pasir Panjang',
    'Holland Village',
    'Tai Seng',
    'Toa Payoh New Town',
    'Bukit Timah',
    'Jurong West New Town',
    'Kembangan',
    'Queenstown Estate',
    'Boon Lay',
    'Simei New Town',
    'Pandan Valley',
    'Clementi New Town',
    'Tanjong Pagar',
  ],
  tld: ['.sg', '.新加坡', '.சிங்கப்பூர்'],
  cca2: 'SG',
  ccn3: '702',
  cca3: 'SGP',
  cioc: 'SIN',
  currency: ['SGD'],
  callingCode: ['65'],
  capital: 'Singapore',
  altSpellings: ['SG', 'Singapura', 'Republik Singapura', '新加坡共和国'],
  region: 'Asia',
  subregion: 'South-Eastern Asia',
  languages: { cmn: 'Mandarin', eng: 'English', msa: 'Malay', tam: 'Tamil' },
  translations: {
    deu: { official: 'Republik Singapur', common: 'Singapur' },
    fra: { official: 'République de Singapour', common: 'Singapour' },
    hrv: { official: 'Republika Singapur', common: 'Singapur' },
    ita: { official: 'Repubblica di Singapore', common: 'Singapore' },
    jpn: { official: 'シンガポール共和国', common: 'シンガポール' },
    nld: { official: 'Republiek Singapore', common: 'Singapore' },
    por: { official: 'República de Singapura', common: 'Singapura' },
    rus: { official: 'Республика Сингапур', common: 'Сингапур' },
    spa: { official: 'República de Singapur', common: 'Singapur' },
    fin: { official: 'Singaporen tasavalta', common: 'Singapore' },
  },
  latlng: [1.36666666, 103.8],
  demonym: 'Singaporean',
  landlocked: false,
  borders: [],
  area: 710,
} as const;
