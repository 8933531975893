export const MEX = {
  name: {
    common: 'Mexico',
    official: 'United Mexican States',
    native: { spa: { official: 'Estados Unidos Mexicanos', common: 'México' } },
  },
  cities: [
    'Monterrey',
    'Mexico City',
    'CancÃºn',
    'San NicolÃ¡s de los Garza',
    'Santiago de Queretaro',
    'Guadalajara',
    'Puebla',
    'Pachuca',
    'Mexicali',
    'Acapulco',
    'Puerto Vallarta',
    'Cabo San Lucas',
    'Tijuana',
    'Playa del Carmen',
    'Rosarito',
    'Chihuahua',
    'Veracruz',
    'Baja California',
    'Quintana Roo',
    'Ecatepec de Morelos',
    'Oaxaca',
    'Ciudad Hidalgo',
    'Campeche',
    'Aguascalientes',
    'Cholula',
    'Tlaquepaque',
    'Colonia Madero (Madero)',
    'Colonia Mexico',
    'MÃ©rida',
    'Ciudad JuÃ¡rez',
    'Saltillo',
    'Colima',
    'Salamanca',
    'Toluca',
    'Morelia',
    'Zacatecas',
    'Cuernavaca',
    'Tlaxcala',
    'Tuxtla GutiÃ©rrez',
    'Reynosa',
    'Victoria',
    'Heroica Matamoros',
    'Guanajuato',
    'La Paz',
    'Hermosillo',
    'Celaya',
    'Abasolo',
    'Villahermosa',
    'Jalapa',
    'Coatzacoalcos',
    'Cuauhtemoc',
    'San Luis PotosÃ­',
    'CuliacÃ¡n',
    'Motozintla de Mendoza',
    'Arriaga',
    'Jiutepec',
    'San Nicolas',
    'Tampico',
    'Topolobampo',
    'Tuxpan',
    'Zapopan',
    'Ciudad del Carmen',
    'Xalapa',
    'Ciudad Victoria',
    'Garza Garcia',
    'Tala',
    'LeÃ³n',
    'Kilometro 32 Texcoco Lecheria',
    'Guadalupe',
    'Nuevo Leon',
    'Nuevo Laredo',
    'MazatlÃ¡n',
    'Irapuato',
    'Naucalpan',
    'Garcia',
    'Apodaca',
    'Tulancingo',
    'Tlalnepantla',
    'La Reforma',
    'Huixquilucan',
    'TorreÃ³n',
    'Cuauhtemoc',
    'Boca del Rio',
    'Salinas de Hidalgo',
    'Tepic',
    'Jalupa',
    'Poncitlan',
    'Ramos Arizpe',
    'Orizaba',
    'Cuautitlan',
    'Zamora',
    'Los Reyes',
    'Centro',
    'Ameca',
    'Adolfo Ruiz Cortines',
    'San Andres Tuxtla',
    'Zaragoza',
    'Santa Isabel Tlanepantla',
    'Chalco de Diaz Covarrubias',
    'Ejido Distrito Federal',
    'Victoria',
    'Venustiano Carranza',
    'Santa Catarina',
    'Lerdo',
    'San Bernardo',
    'Uruapan',
    'Ciudad ObregÃ³n',
    'Jacobo',
    'CuautitlÃ¡n Izcalli',
    'Valle de Santiago',
    'Montemorelos',
    'Mextepec',
    'Ciudad Nezahualcoyotl',
    'Netzahualcoyotl',
    'San Patricio',
    'Granjas',
    'Temascalcingo',
    'Tlajomulco de Zuniga',
    'Durango',
    'Tlalpan',
    'Insurgentes',
    'Salvatierra',
    'Mineral de la Reforma',
    'Teoloyucan',
    'Tultitlan',
    'Paseos de Tecamac',
    'Tultepec',
    'Piedras Negras',
    'Azcapotzalco',
    'Ensenada',
    'Cajeme',
    'Tablon Grande (Bombas de Apaxco)',
    'Pueblo Juarez (La Magdalena)',
    'Santa Maria Chimalhuacan',
    'Chilpancingo',
    'Tula de Allende',
    'Tampico Alto',
    'Chiapas',
    'Sinaloa de Leyva',
    'Cocula',
    'Navojoa',
    'Chihuahua',
    'Colonia Benito Juarez',
    'Nuevo Casas Grandes',
    'Jocotepec',
    'San Antonio',
    'Union de Tula',
    'Xalisco',
    'Patzcuaro',
    'Pastor Ortiz',
    'Los Reyes',
    'Yurecuaro',
    'Tocumbo',
    'Los Reyes de Salgado',
    'Tangancicuaro de Arista',
    'La Piedad',
    'Barranca de Santa Clara',
    'Izucar de Matamoros',
    'Apizaco',
    'Tecamachalco',
    'San Andres Cholula',
    'Atlixco',
    'San Juan',
    'Libres',
    'Agua Azul',
    'San Martin Texmelucan de Labastida',
    'Berriozabal',
    'Rayon',
    'Graciano Sanchez',
    'San Pedro de Nolasco',
    'Linares',
    'Escobedo',
    'Guadalupe',
    'Cadereyta',
    'El Leon',
    'Juarez',
    'Benito Juarez',
    'Santiago',
    'El Colli',
    'Tequila',
    'Tonala',
    'El Salto',
    'El Salto',
    'Tapalpa',
    'Tecalitlan',
    'Ciudad GuzmÃ¡n',
    'San Miguel de Cozumel',
    'CÃ³rdoba',
    'San Agustin (Salvador Alvarado)',
    'Morelos',
    'Fresnillo',
    'Sombrerete',
    'Guadalupe',
    'Jesus Maria',
    'Tapachula',
    'San CristÃ³bal de las Casas',
    'Tuxtla',
    'Ocozocoautla de Espinosa',
    'San Juan del RÃ­o',
    'Cortazar',
    'Acambaro',
    'Pedro Escobedo',
    'Corregidora',
    'San Miguel de Allende',
    'Yuriria',
    'La Florida',
    'Morelos',
    'Escondida de lo Verde',
    'Iztacalco',
    'Tuxpan',
    'LÃ¡zaro CÃ¡rdenas',
    'Banderas del Aguila',
    'Ajijic',
    'Bucerias',
    'Reyes de Vallarta',
    'TepatitlÃ¡n de Morelos',
    'Atotonilco el Alto',
    'El Tecuan',
    'Ciudad de Villa de Alvarez',
    'Izamal',
    'TizimÃ­n',
    'Valladolid',
    'Hunucma',
    'Yucatan',
    'Los Mochis',
    'El Alto Culiacan',
    'Navolato',
    'Guamuchil',
    'El Fuerte de Montes Claros',
    'Guasave',
    'Ahome',
    'La Cruz',
    'Candelaria',
    'Tlapa de Comonfort',
    'Petaquillas',
    'Mazatlan',
    'Ixtapa-Zihuatanejo',
    'El Ocotito',
    'Zitlala',
    'Mixquiahuala de Juarez',
    'Coacalco',
    'Tolcayuca',
    'Ixtapaluca',
    'Isla Holbox',
    'Puerto Morelos',
    'Juarez',
    'Tulum',
    'Chetumal',
    'Tecax',
    'Muna',
    'Uman',
    'Oxkutzkab',
    'Cananea',
    'Heroica Caborca',
    'Bahia de Kino',
    'La Paletena',
    'Nacozari Viejo',
    'Nogales',
    'Leon',
    'Magdalena de Kino',
    'Cuautepec de Hinojosa',
    'Huauchinango',
    'Monterrey',
    'San Nicolas',
    'Escobedo',
    'General Escobedo',
    'Refractarios Guadalajara',
    'El Sabinal (San Pedro)',
    'Cuautlancingo',
    'Estado de Mexico',
    'Iztapalapa',
    'CoyoacÃ¡n',
    'Tlalmanalco de Velazquez',
    'Tlahuac',
    'Benito Juarez',
    'Panotla',
    'Fortin de las Flores',
    'Zongolica',
    'Miguel Aleman',
    'Mendoza',
    'Tezonapa',
    'Yanga',
    'Paraje Nuevo',
    'Ciudad Mendoza',
    'Cuitlahuac',
    'Tierra Blanca',
    'Rio Blanco',
    'Cadereyta Jimenez',
    'San Nicolas',
    'Allende',
    'San Juan',
    'Villa Hidalgo',
    'La Barca',
    'Tacambaro de Codallos',
    'ApatzingÃ¡n',
    'Tamazula de Gordiano',
    'Ciudad Ayala',
    'Tabasco',
    'Macuspana',
    'Comalcalco',
    'Cardenas',
    'Cosoleacaque',
    'Villa Nanchital',
    'MinatitlÃ¡n',
    'San Juan del Rio',
    'Cuautla',
    'Tecate',
    'Yautepec',
    'Emiliano Zapata',
    'Valles',
    'San Vicente Chicoloapan',
    'Ocotlan',
    'Poza Rica Chacas',
    'Las Delicias',
    'Seccion Primera Santiago Tetla',
    'San Salvador Tzompantepec',
    'Guadalajara',
    'Epazoyucan',
    'Cunduacan',
    'Ciudad Lopez Mateos',
    'Villa Cuauhtemoc Otzolotepec',
    'Melchor Muzquiz',
    'Los Sabinos',
    'Santiago Ixcuintla',
    'Colonia Hidalgo',
    'Jala',
    'Zacualpan',
    'Ixtlan del Rio',
    'Manzanillo',
    'Tecoman',
    'Zacapu',
    'Villadiego',
    'Heroica Zitacuaro',
    'Huandacareo',
    'Campestre Tarimbaro',
    'Puruandiro',
    'Tejupilco de Hidalgo',
    'Tenancingo de Degollado',
    'Lerma',
    'San Mateo',
    'Zacatepec',
    'San Miguel Zinacantepec',
    'Valle de Bravo',
    'Tonatico',
    'Ixtapan de la Sal',
    'Luvianos',
    'San Mateo Mexicaltzingo',
    'Malinalco',
    'Ocoyoacac',
    'Tequisquiapan',
    'Gueramaro',
    'Santa Anita',
    'La Victoria',
    'Jaumave',
    'Atoyac de Alvarez',
    'Colonia Guerrero (Los Guajes)',
    'Petatlan',
    'Ex-Hacienda Pantitlan',
    'La Ciudad',
    'TehuacÃ¡n',
    'San Pedro Cholula',
    'Vicente Guerrero',
    'Huejotzingo',
    'Colonia Cuauhtemoc',
    'Mazatecochco',
    'Nueva Italia de Ruiz',
    'Apatzingan',
    'Colimas',
    'Zapotitlan de Vadillo',
    'El Grullo',
    'San Jose del Valle',
    'Ixtapa',
    'Tecuala',
    'Cuarta Brigada',
    'Libramiento Leon-Salamanca',
    'Tepotzotlan',
    'Purisima del Zapote',
    'San Francisco del RincÃ³n',
    'Purisima de Bustos',
    'Nicolas Romero',
    'Rincon de Tamayo',
    'Silao',
    'San Francisco',
    'Venustiano Carranza',
    'Tlalnepantla',
    'Atizapan',
    'Gustavo A. Madero Borough',
    'Chiconcuac',
    'Colonia Emiliano Zapata (Ejido Santiago Temoaya)',
    'Acambay',
    'Tlaltizapan',
    'San Mateo Atenco',
    'Temascaltepec de Gonzalez',
    'San Agustin',
    'Fraccionamiento Iberica Calimaya',
    'Zacualpan',
    'Santa Apolonia',
    'Valle Hermoso',
    'Rio Bravo',
    'El Torreon',
    'Madero',
    'La Canada',
    'San Jose Iturbide',
    'Quiroga',
    'Cadereyta de Montes',
    'Asuncion Ixtaltepec',
    'Ciudad de Huajuapam de Leon',
    'Acatlan de Perez Figueroa',
    'Miguel Hidalgo',
    'La Pista de Tizayuca',
    'Pueblo Hidalgo',
    'Iglesia Vieja',
    'Tepeji de Ocampo',
    'Ixmiquilpan',
    'Tlahuelilpan',
    'Brisas Barra de Suchiate',
    'Huixtla',
    'Vicente Guerrero',
    'ComitÃ¡n',
    'Perote',
    'Zacapoaxtla',
    'Altotonga',
    'Magdalena Petlacalco',
    'Selene',
    'Xochimilco',
    'Alvaro Obregon Borough',
    'Amecameca de Juarez',
    'Juarez',
    'Banderilla',
    'Emiliano Zapata',
    'Angamacutiro de la Union',
    'Zinapecuaro',
    'Jiquilpan de Juarez',
    'Sahuayo de Morelos',
    'Vistahermosa de Negrete',
    'Chiapa de Corzo',
    'Pericos',
    'Cajititlan',
    'Acatic',
    'San Sebastian el Grande',
    'El Sauz',
    'Coatzintla',
    'Cazones de Herrera',
    'Ciudad Valles',
    'Pedro Meoqui',
    'San Martin de Camargo',
    'Marquelia',
    'Iguala de la Independencia',
    'Lagos de Moreno',
    'San Agustin Tlaxiaca',
    'Las Delicias',
    'Ciudad Madero',
    'San Martin Notario',
    'San Salvador Huixcolotla',
    'Autopista Puebla Orizaba 185 (La Condusa)',
    'Tlaxcalancingo',
    'Jose Cardel',
    'Heroica Alvarado',
    'Tlacotalpan',
    'Medellin de Bravo',
    'San Juan Bautista Tuxtla',
    'Chiautempan',
    'Zacatelco',
    'Chignahuapan',
    'Atlzayanca',
    'Cuyoaco',
    'San Sebastian Tutla',
    'Acatzingo de Hidalgo',
    'Jesus Carranza',
    'Agua Dulce',
    'Las Choapas',
    'San Cristobal',
    'Rayon',
    'Huimanguillo',
    'Tonala',
    'La Habana',
    'Teapa',
    'Reforma (Madrigal 2da. Seccion)',
    'Pichucalco',
    'Balancan de Dominguez',
    'Paraiso',
    'Centro',
    'Mina Mexico',
    'Polanco',
    'Cuautepec',
    'Colonia Lopez Nelio (Las Palmas)',
    'Juarez',
    'Jesus del Monte',
    'Cuajimalpa',
    'Jilotepec',
    'Atlatlahucan',
    'Tepoztlan',
    'Tlaquiltenango',
    'Cocoyoc',
    'Temixco',
    'Jojutla',
    'Oaxtepec',
    'Xochitepec',
    'Tezoyuca',
    'Yecapixtla',
    'Tepetlapa',
    'Almoloya',
    'Jiquipilco',
    'Ejido Santa Maria Tianguistenco (Ejido el Rosario)',
    'San Simon de Guerrero',
    'Santiago Tianguistenco',
    'Xonacatlan',
    'San Antonio la Isla',
    'Atlacomulco',
    'Actopan',
    'Tezontepec',
    'Ciudad Sahagun',
    'Tlanalapa',
    'Tepeapulco',
    'Huehuetoca',
    'Noxtepec de Zaragoza (El Aguacate)',
    'Acolman',
    'San Sebastian Xhala',
    'Zumpango',
    'Puerto Escondido',
    'Asuncion Nochixtlan',
    'Santa Catarina Juquila',
    'Morelos',
    'Quintana Roo',
    'Isla Mujeres',
    'Colonia Veracruz',
    'Alamo',
    'Los Garzas',
    'Jimenez',
    'Ojinaga',
    'Cuauhtemoc',
    'Nuevo Coahuila',
    'Francisco I. Madero',
    'Mariano Matamoros',
    'Soto la Marina',
    'Altamira',
    'Colonia Miguel Aleman Valdez',
    'Camargo',
    'Atlacholoaya',
    'Puente Grande',
    'Mineral',
    'Atequiza',
    'Tizapan el Alto',
    'Monte Leon',
    'Dolores Hidalgo Cuna de la Independencia Nacional',
    'Ignacio Allende',
    'Apaseo el Grande',
    'Moroleon',
    'La Union',
    'Apaseo el Alto',
    'San Luis de la Paz',
    'San Felipe Torres Mochas [Ganaderia]',
    'Villagran',
    'San Miguel',
    'Tepalcatepec',
    'San Miguel el Alto',
    'Zapotlanejo',
    'Ixtlahuacan del Rio',
    'Zapotlanejo',
    'San Luis Soyatlan',
    'Autlan de Navarro',
    'Huejucar',
    'Guadalupito',
    'Juan',
    'Villa Garcia',
    'Jalpa',
    'Loreto',
    'Jacona de Plancarte',
    'Francisco Castellanos',
    'Chicago',
    'Tirindaro',
    'Erongaricuaro',
    'San Martin Hidalgo',
    'Ahualulco de Mercado',
    'Tenamaxtlan',
    'Chapala',
    'Ciudad Constitucion',
    'Loreto',
    'Todos Santos',
    'Las Canoras (Altiva)',
    'Teloloapan',
    'Escuinapa de Hidalgo',
    'Badiraguato',
    'San JosÃ© del Cabo',
    'Guaymas',
    'Sonora',
    'Empalme',
    'Cabos',
    'Huatabampo',
    'Ezequiel Montes',
    'El Rio',
    'Manuel Avila Camacho',
    'El Mirador',
    'San Miguel el Alto (El Puerto)',
    'Nextlalpan',
    'San Gabriel',
    'Guadalupe Victoria',
    'Queseria',
    'Villa de Costa Rica',
    'Anahuac',
    'Ojuelos de Jalisco',
    'Pabellon de Arteaga',
    'San Pedro Piedra Gorda',
    'Tlacolula de Matamoros',
    'Heroica Coscomatepec de Bravo',
    'Salina Cruz',
    'Tocatlan',
    'Zacatlan',
    'Mineral del Chico',
    'Martinez de la Torre',
    'Coatepec',
    'Tlapacoyan',
    'La Isla',
    'TeziutlÃ¡n',
    'Melchor',
    'Tequisistlan',
    'Ejido de Otumba (Loma Bonita)',
    'San Francisco Javier',
    'Pantanal',
    'Ixtapa',
    'Puerto PeÃ±asco',
    'Bamoa',
    'Agua Prieta',
    'El Potrero',
    'Rancho Xalnene',
    'Tuxpan de Rodriguez Cano',
    'Ures',
    'Amado Nervo',
    'JuchitÃ¡n de Zaragoza',
    'San Luis RÃ­o Colorado',
    'Kanasin',
    'Benito Juarez',
    'Gomez Palacio',
    'Tekoh',
    'Poza Rica de Hidalgo',
    'Zapotlan de Juarez',
    'Progreso',
    'Jaltipan de Morelos',
    'San Juan de Aragon',
    'Guadalupe',
    'Pesqueria',
    'Estados Unidos',
    'Agua Amarga',
    'El Carmen',
    'Colon',
    'Colonia de Venado',
    'Taxco',
    'San Pablo Hidalgo',
    'Coyuca de Catalan',
    'Tilzapotla',
    'Axochiapan',
    'Almada',
    'Parral',
    'Huanimaro',
    'Escuintla',
    'La Moncada',
    'Huimilpan',
    'Las Cabanas (Colinas de los Angeles)',
    'General Teran',
    'Acuna',
    'Tacotalpa',
    'Suchiapa',
    'Tenosique de Pino Suarez',
    'La Tapachula',
    'Aldama',
    'Arizpe',
    'San Pedro',
    'Frontera',
    'Monclova',
    'Ojo de Agua',
    'Puerto Mexico',
    'Chiautla',
    'Gutierrez Zamora',
    'Jimenez',
    'Ixtlahuaca de Villada',
    'Tlatlaya',
    'Satelite',
    'Pueblo Viejo',
    'San Nicolas Totolapan',
    'Monclova',
    'Canatlan',
    'Nuevo Ideal',
    'San Miguel de Papasquiaro',
    'Tlatlauquitepec',
    'Cosamaloapan de Carpio',
    'Ursulo Galvan',
    'Tierra Colorada',
    'Pueblo Yaqui',
    'San Fernando',
    'Roma',
    'Coahuila',
    'Coyoacan',
    'Pabellon',
    'Alamos',
    'San Cristobal',
    'San Fernando',
    'San Luis',
    'San Pedro Pochutla',
    'Huichapan',
    'Ciudad Sabinas',
    'Catemaco',
    'Tepeaca',
    'Mier',
    'Anahuac',
    'Colonia la Presa (Ejido de Chapultepec)',
    'Las Americas',
    'Santa Catalina',
    'Jalostotitlan',
    'San Jeronimo de Juarez',
    'Primero de Mayo',
    'La Joya',
    'Paso Real',
    'Jalisco',
    'Jesus Maria',
    'Jocotitlan',
    'Tequixquiac',
    'Apaxco de Ocampo',
    'Teotihuacan de Arista',
    'San Luis',
    'La Herradura',
    'Cardonal',
    'Zacualtipan',
    'Apan',
    'Tezontepec de Aldama',
    'Mineral del Monte',
    'Tezoyuca',
    'San Pedro de Las Colonias',
    'Magiscatzin',
    'Xochimilco',
    'Jerez de Garcia Salinas',
    'San Salvador Atenco',
    'El Parque',
    'Texcoco',
    'Azteca',
    'Barra de Navidad',
    'Compostela',
    'Santa Fe',
    'San Felipe',
    'Cutzamala de Pinzon',
    'San Agustin Altamirano',
    'Puente de Ixtla',
    'Jonacatepec de Leandro Valle',
    'Arcelia',
    'San Pedro',
    'Mazatepec',
    'San Martin Duraznos',
    'Pachuca de Soto',
    'San Antonio Virreyes',
    'Cerritos',
    'San Luis PotosÃ­',
    'Loma de los Cavazos (Maria de la Luz)',
    'Visitacion',
    'San Rafael',
    'Leyva Solano',
    'Chihuahua',
    'Prolongacion Avenida Mexico Norte',
    'Barrio de Mexico',
    'Santa Lucia',
    'San Pedro',
    'San Angel Inn',
    'Pedregal de Jerico',
    'San Miguel',
    'Adolfo Lopez Mateos',
    'San Vicente',
    'Arandas',
    'Uriangato',
    'Tamaulipas',
    'Laredo',
    'Villa de Almoloya de Juarez',
    'Florida',
    'Sierra Madre',
    'Colonia',
    'Piedras Negras',
    'San Miguel de Atotonilco',
    'San Miguel Balderas',
    'San Francisco',
    'Moctezuma',
    'Tepetlacolco',
    'Benito Juarez (Santa Elena)',
  ],
  tld: ['.mx'],
  cca2: 'MX',
  ccn3: '484',
  cca3: 'MEX',
  cioc: 'MEX',
  currency: ['MXN'],
  callingCode: ['52'],
  capital: 'Mexico City',
  altSpellings: [
    'MX',
    'Mexicanos',
    'United Mexican States',
    'Estados Unidos Mexicanos',
  ],
  region: 'Americas',
  subregion: 'Central America',
  languages: { spa: 'Spanish' },
  translations: {
    deu: { official: 'Vereinigte Mexikanische Staaten', common: 'Mexiko' },
    fra: { official: 'États-Unis du Mexique', common: 'Mexique' },
    hrv: { official: 'Sjedinjene Meksičke Države', common: 'Meksiko' },
    ita: { official: 'Stati Uniti del Messico', common: 'Messico' },
    jpn: { official: 'メキシコ合衆国', common: 'メキシコ' },
    nld: { official: 'Verenigde Mexicaanse Staten', common: 'Mexico' },
    por: { official: 'Estados Unidos Mexicanos', common: 'México' },
    rus: { official: 'Мексиканских Соединенных Штатов', common: 'Мексика' },
    spa: { official: 'Estados Unidos Mexicanos', common: 'México' },
    fin: { official: 'Meksikon yhdysvallat', common: 'Meksiko' },
  },
  latlng: [23, -102],
  demonym: 'Mexican',
  landlocked: false,
  borders: ['BLZ', 'GTM', 'USA'],
  area: 1964375,
  provinces: [
    'Aguascalientes',
    'Baja California',
    'Baja California Sur',
    'Campeche',
    'Chiapas',
    'Chihuahua',
    'Coahuila de Zaragoza',
    'Colima',
    'Distrito Federal',
    'Durango',
    'Guanajuato',
    'Guerrero',
    'Hidalgo',
    'Jalisco',
    'Mexico',
    'Michoacan de Ocampo',
    'Morelos',
    'Nayarit',
    'Nuevo Leon',
    'Oaxaca',
    'Puebla',
    'Queretaro de Arteaga',
    'Quintana Roo',
    'San Luis Potosi',
    'Sinaloa',
    'Sonora',
    'Tabasco',
    'Tamaulipas',
    'Tlaxcala',
    'Veracruz-Llave',
    'Yucatan',
    'Zacatecas',
  ],
  nativeName: 'México',
  timezones: ['UTC−08:00', 'UTC−07:00', 'UTC−06:00'],
} as const;
