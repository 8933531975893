var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-form-group",
    _vm._b(
      { staticClass: "form-color", attrs: { "label-cols": "auto" } },
      "b-form-group",
      { label: _vm.label },
      false
    ),
    [
      _c(
        "div",
        { staticClass: "options-wrapper" },
        _vm._l(_setup.colorOptions, function (option) {
          return _c(
            "div",
            { key: option.key, staticClass: "option-wrapper" },
            [
              _c(
                "b-form-radio",
                _vm._b(
                  {
                    class: ["color-option", option.key],
                    style: { color: `${option.value} !important` },
                    attrs: { checked: option.value === _vm.value },
                    nativeOn: {
                      click: function ($event) {
                        return _setup.onSelection($event, option)
                      },
                    },
                  },
                  "b-form-radio",
                  { name: _setup.fieldId, value: option.value },
                  false
                )
              ),
              _vm._v(" "),
              option.key === "none"
                ? _c("div", { staticClass: "option-label text-muted" }, [
                    _vm._v("\n        None\n      "),
                  ])
                : option.key === "custom"
                ? _c("div", { staticClass: "option-label text-muted" }, [
                    _vm._v("\n        Custom\n      "),
                  ])
                : _vm._e(),
              _vm._v(" "),
              option.key === "custom"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _setup.customColor,
                        expression: "customColor",
                      },
                    ],
                    ref: "customColorInput",
                    refInFor: true,
                    staticClass: "custom-color-input",
                    attrs: { type: "color" },
                    domProps: { value: _setup.customColor },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _setup.customColor = $event.target.value
                        },
                        function ($event) {
                          return _setup.setValue(option.value)
                        },
                      ],
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }