export const BMU = {
  name: {
    common: 'Bermuda',
    official: 'Bermuda',
    native: { eng: { official: 'Bermuda', common: 'Bermuda' } },
  },
  cities: ['Hamilton', 'Saint George', 'Somerset'],
  tld: ['.bm'],
  cca2: 'BM',
  ccn3: '060',
  cca3: 'BMU',
  cioc: 'BER',
  currency: ['BMD'],
  callingCode: ['1441'],
  capital: 'Hamilton',
  altSpellings: [
    'BM',
    'The Islands of Bermuda',
    'The Bermudas',
    'Somers Isles',
  ],
  region: 'Americas',
  subregion: 'Northern America',
  languages: { eng: 'English' },
  translations: {
    cym: { official: 'Bermuda', common: 'Bermiwda' },
    deu: { official: 'Bermuda', common: 'Bermuda' },
    fra: { official: 'Bermudes', common: 'Bermudes' },
    hrv: { official: 'Bermuda', common: 'Bermudi' },
    ita: { official: 'Bermuda', common: 'Bermuda' },
    jpn: { official: 'バミューダ', common: 'バミューダ' },
    nld: { official: 'Bermuda', common: 'Bermuda' },
    por: { official: 'Bermudas', common: 'Bermudas' },
    rus: { official: 'Бермудские острова', common: 'Бермудские Острова' },
    spa: { official: 'Bermuda', common: 'Bermudas' },
    fin: { official: 'Bermuda', common: 'Bermuda' },
  },
  latlng: [32.33333333, -64.75],
  demonym: 'Bermudian',
  landlocked: false,
  borders: [],
  area: 54,
  provinces: [
    'Devonshire',
    'Hamilton',
    'Hamilton',
    'Paget',
    'Pembroke',
    'Saint George',
    'Saint Georges',
    'Sandys',
    'Smiths',
    'Southampton',
    'Warwick',
  ],
  nativeName: 'Bermuda',
} as const;
