import { server } from '@api/request';
import * as models from '@models';
import { isObject, isArray, isNull } from '@tools/type-guards';

type PaginatedInvitesResults = ZephyrWeb.Pagination.Results<models.Invite>;

/**
 * List all existing invites.
 *
 * @returns A list of invites.
 */
export async function list() {
  return await server.list('v2/admin/invites', processInviteData);
}

/**
 * {@link search Search invites} API request namespace.
 */
export namespace InviteSearch {
  /**
   * {@link search Search invites} API request options.
   */
  export interface Options {
    inviteId?: string;
    email?: string;
    order?: string;
    startDate?: string;
    endDate?: string;
    organizationId?: string;
    limit?: number;
    startKey?: ZephyrWeb.Pagination.PlacementKey;
  }

  /**
   * {@link search Search invites} API request response.
   */
  export type Results = PaginatedInvitesResults;
}

/**
 * Search for invites by a specified criteria (paginated).
 *
 * @param options Request options bag.
 * @returns `InviteSearch.Results` data object.
 *
 * @see {@link ZephyrApi.Admin.Invites.searchInvites `AdminSearchInvites`} API route in `zephyr-serverless`
 */
export async function search(options: InviteSearch.Options) {
  return await server.post(
    'v2/admin/invites/search',
    options,
    processSearchResults,
  );
}

/**
 * {@link get Get invite} API request options.
 */
export interface GetOptions {
  inviteId: models.Invite['id'];
}

/**
 * Get a specified invite.
 *
 * @returns The specified invite.
 */
export async function get(options: GetOptions) {
  return await server.get(`v2/invites/${options.inviteId}`, processInviteData);
}

/**
 * Get current user's invites.
 *
 * @returns ...
 */
export async function getMine() {
  return await server.get('v2/users/invites', processUserInviteData);
}

/**
 * {@link getByOrganization Get organization invites} API request options.
 */
export interface GetByOrganizationOptions {
  organizationId: models.Organization['id'];
}

/**
 * Get specified account's invites.
 *
 * @returns ...
 */
export async function getByOrganization(options: GetByOrganizationOptions) {
  return await server.list(
    `v2/organizations/${options.organizationId}/invites`,
    processInviteData,
  );
}

/**
 * {@link send Send invite} API request options.
 */
export interface SendOptions {
  roleId: models.Role['roleId'];
  emails: string[];
  products: string[];
  expirationDate?: string;
}

/**
 * Send an invite to a list of emails.
 */
export async function send(options: SendOptions) {
  await server.post('v2/admin/invites', options);
}

/**
 * {@link accept Accept invite} API request options.
 */
export interface AcceptOptions {
  inviteId: models.Invite['id'];
}

/**
 * Accept an invitation.
 */
export async function accept(options: AcceptOptions) {
  await server.post(`v2/invites/${options.inviteId}/accept`);
}

/**
 * {@link decline Decline invite} API request options.
 */
export interface DeclineOptions {
  inviteId: models.Invite['id'];
}

/**
 * Decline an invitation.
 */
export async function decline(options: DeclineOptions) {
  await server.post(`v2/invites/${options.inviteId}/reject`);
}

/**
 * {@link del Delete invite} API request options.
 */
export interface DeleteOptions {
  inviteId: models.Invite['id'];
  admin?: boolean;
  organizationId?: models.Organization['id'] | undefined;
}

/**
 * Delete a specified product.
 */
export async function del(options: DeleteOptions) {
  if (!options.admin && !options.organizationId)
    throw new Error('If not admin. Organization ID must be provided.');

  let url = '';

  if (!options.admin && typeof options.organizationId === 'string') {
    url = `v2/organizations/${options.organizationId}/invites/${options.inviteId}`;
  } else {
    url = `v2/admin/invites/${options.inviteId}`;
  }

  await server.delete(url);
}

//#region Helper Functions

/**
 * ...
 *
 * @param value ...
 * @returns ...
 */
function isValidInviteData(value: unknown): value is models.Invite {
  if (!isObject(value)) return false;

  // TEMP: Use a more strict validation.

  return true;
}

/**
 * ...
 *
 * @param data ...
 * @returns ...
 */
function processInviteData(data: unknown) {
  if (!isValidInviteData(data)) {
    throw new Error('Invalid invite data item.');
  }

  return data;
}

/**
 * ...
 *
 * @param data ...
 * @returns ...
 */
function processUserInviteData(data: unknown) {
  if (!isObject(data)) {
    throw new Error('Invalid invite data item.');
  }

  // ...
  const sent = (isArray(data['invitesSent']) ? data['invitesSent'] : []).map(
    processInviteData,
  );

  // ...
  const received = (
    isArray(data['invitesReceived']) ? data['invitesReceived'] : []
  ).map(processInviteData);

  return { sent, received };
}

/**
 * ...
 *
 * @param value ...
 * @returns ...
 */
function isValidSearchResults(value: unknown): value is InviteSearch.Results {
  return (
    isObject(value) &&
    isArray(value['items']) &&
    (isObject(value['lastEvaluatedKey']) || isNull(value['lastEvaluatedKey']))
  );
}

/**
 * ...
 *
 * @param data ...
 * @returns ...
 */
function processSearchResults(data: unknown) {
  if (!isValidSearchResults(data)) {
    throw new Error('Invalid search result data.');
  }

  return data;
}

//#endregion Helper Functions
