export const DJI = {
  name: {
    common: 'Djibouti',
    official: 'Republic of Djibouti',
    native: {
      ara: { official: 'جمهورية جيبوتي', common: 'جيبوتي‎' },
      fra: { official: 'République de Djibouti', common: 'Djibouti' },
    },
  },
  cities: ['Djibouti'],
  tld: ['.dj'],
  cca2: 'DJ',
  ccn3: '262',
  cca3: 'DJI',
  cioc: 'DJI',
  currency: ['DJF'],
  callingCode: ['253'],
  capital: 'Djibouti',
  altSpellings: [
    'DJ',
    'Jabuuti',
    'Gabuuti',
    'Republic of Djibouti',
    'République de Djibouti',
    'Gabuutih Ummuuno',
    'Jamhuuriyadda Jabuuti',
  ],
  region: 'Africa',
  subregion: 'Eastern Africa',
  languages: { ara: 'Arabic', fra: 'French' },
  translations: {
    cym: { official: 'Republic of Djibouti', common: 'Djibouti' },
    deu: { official: 'Republik Dschibuti', common: 'Dschibuti' },
    fra: { official: 'République de Djibouti', common: 'Djibouti' },
    hrv: { official: 'Republika Džibuti', common: 'Džibuti' },
    ita: { official: 'Repubblica di Gibuti', common: 'Gibuti' },
    jpn: { official: 'ジブチ共和国', common: 'ジブチ' },
    nld: { official: 'Republiek Djibouti', common: 'Djibouti' },
    por: { official: 'República do Djibouti', common: 'Djibouti' },
    rus: { official: 'Республика Джибути', common: 'Джибути' },
    spa: { official: 'República de Djibouti', common: 'Djibouti' },
    fin: { official: 'Dijiboutin tasavalta', common: 'Dijibouti' },
  },
  latlng: [11.5, 43],
  demonym: 'Djibouti',
  landlocked: false,
  borders: ['ERI', 'ETH', 'SOM'],
  area: 23200,
  provinces: ["'Ali Sabih", 'Dikhil', 'Djibouti', 'Obock', 'Tadjoura'],
  nativeName: 'Djibouti',
  timezones: ['UTC+03:00'],
} as const;
