export const GBR = {
  name: {
    common: 'United Kingdom',
    official: 'United Kingdom of Great Britain and Northern Ireland',
    native: {
      eng: {
        official: 'United Kingdom of Great Britain and Northern Ireland',
        common: 'United Kingdom',
      },
    },
  },
  provinces: [
    'Barking and Dagenham',
    'Barnet',
    'Barnsley',
    'Bath and North East Somerset',
    'Bedfordshire',
    'Bexley',
    'Birmingham',
    'Blackburn with Darwen',
    'Blackpool',
    'Bolton',
    'Bournemouth',
    'Bracknell Forest',
    'Bradford',
    'Brent',
    'Brighton and Hove',
    'Bromley',
    'Buckinghamshire',
    'Bury',
    'Calderdale',
    'Cambridgeshire',
    'Camden',
    'Cheshire',
    'City of Bristol',
    'City of Kingston upon Hull',
    'City of London',
    'Cornwall',
    'Coventry',
    'Croydon',
    'Cumbria',
    'Darlington',
    'Derby',
    'Derbyshire',
    'Devon',
    'Doncaster',
    'Dorset',
    'Dudley',
    'Durham',
    'Ealing',
    'East Riding of Yorkshire',
    'East Sussex',
    'Enfield',
    'Essex',
    'Gateshead',
    'Gloucestershire',
    'Greenwich',
    'Hackney',
    'Halton',
    'Hammersmith and Fulham',
    'Hampshire',
    'Haringey',
    'Harrow',
    'Hartlepool',
    'Havering',
    'Herefordshire',
    'Hertfordshire',
    'Hillingdon',
    'Hounslow',
    'Isle of Wight',
    'Islington',
    'Kensington and Chelsea',
    'Kent',
    'Kingston upon Thames',
    'Kirklees',
    'Knowsley',
    'Lambeth',
    'Lancashire',
    'Leeds',
    'Leicester',
    'Leicestershire',
    'Lewisham',
    'Lincolnshire',
    'Liverpool',
    'Luton',
    'Manchester',
    'Medway',
    'Merton',
    'Middlesbrough',
    'Milton Keynes',
    'Newcastle upon Tyne',
    'Newham',
    'Norfolk',
    'North East Lincolnshire',
    'North Lincolnshire',
    'North Somerset',
    'North Tyneside',
    'North Yorkshire',
    'Northamptonshire',
    'Northumberland',
    'Nottingham',
    'Nottinghamshire',
    'Oldham',
    'Oxfordshire',
    'Peterborough',
    'Plymouth',
    'Poole',
    'Portsmouth',
    'Reading',
    'Redbridge',
    'Redcar and Cleveland',
    'Richmond upon Thames',
    'Rochdale',
    'Rotherham',
    'Rutland',
    'Salford',
    'Sandwell',
    'Sefton',
    'Sheffield',
    'Shropshire',
    'Slough',
    'Solihull',
    'Somerset',
    'South Gloucestershire',
    'South Tyneside',
    'Southampton',
    'Southend-on-Sea',
    'Southwark',
    'St. Helens',
    'Staffordshire',
    'Stockport',
    'Stockton-on-Tees',
    'Stoke-on-Trent',
    'Suffolk',
    'Sunderland',
    'Surrey',
    'Sutton',
    'Swindon',
    'Tameside',
    'Telford and Wrekin',
    'Thurrock',
    'Torbay',
    'Tower Hamlets',
    'Trafford',
    'Wakefield',
    'Walsall',
    'Waltham Forest',
    'Wandsworth',
    'Warrington',
    'Warwickshire',
    'West Berkshire',
    'West Sussex',
    'Westminster',
    'Wigan',
    'Wiltshire',
    'Windsor and Maidenhead',
    'Wirral',
    'Wokingham',
    'Wolverhampton',
    'Worcestershire',
    'York',
  ],
  timezones: [
    'UTC−08:00',
    'UTC−05:00',
    'UTC−04:00',
    'UTC−03:00',
    'UTC−02:00',
    'UTC',
    'UTC+01:00',
    'UTC+02:00',
    'UTC+06:00',
  ],
  nativeName: 'United Kingdom',
  cities: [
    'Pershore',
    'Rowley Regis',
    'Stroud',
    'Birmingham',
    'Worcester',
    'Smethwick',
    'Alcester',
    'Dudley',
    'Gloucester',
    'Solihull',
    'Tamworth',
    'Bidford-on-Avon',
    'Tewkesbury',
    'Oldbury',
    'Coleford',
    'Stourbridge',
    'Cheltenham',
    'Redditch',
    'Malvern',
    'Stonehouse',
    'Bromsgrove',
    'Mitcheldean',
    'Erdington',
    'Halesowen',
    'Rugby',
    'Walsall',
    'Llandrindod Wells',
    'Sutton Coldfield',
    'Evesham',
    'Stratford-upon-Avon',
    'Bristol',
    'Plymouth',
    'Fareham',
    'Newquay',
    'Wells',
    'Swanage',
    'Bournemouth',
    'Frome',
    'Burnham-on-Sea',
    'Liskeard',
    'Verwood',
    'Redruth',
    'Weymouth',
    'Devizes',
    'Poole',
    'St Austell',
    'Trowbridge',
    'Christchurch',
    'Bath',
    'Torquay',
    'New Milton',
    'Warminster',
    'Southampton',
    'Bradford-on-Avon',
    'Bideford',
    'Pontypridd',
    'Swansea',
    'Cardiff',
    'Abertillery',
    'Caerphilly',
    'Blackwood',
    'Aberdare',
    'Hengoed',
    'Maesteg',
    'Mountain Ash',
    'Usk',
    'Carmarthen',
    'Bargoed',
    'Newport',
    'Penarth',
    'Neath',
    'Rhondda',
    'Llanelli',
    'Tonypandy',
    'Bridgend',
    'Salisbury',
    'Porth',
    'Porthcawl',
    'Ammanford',
    'Ebbw Vale',
    'Caldicot',
    'Leicester',
    'Nottingham',
    'Coalville',
    'Grantham',
    'Coventry',
    'Burton-on-Trent',
    'Heanor',
    'Northampton',
    'Lutterworth',
    'Bourne',
    'Buxton',
    'Ilkeston',
    'Atherstone',
    'Derby',
    'Barrow upon Soar',
    'Loughborough',
    'Gainsborough',
    'Prestwick',
    'Edinburgh',
    'Dalkeith',
    'Kilsyth',
    'Maybole',
    'Dunbar',
    'Falkirk',
    'Livingston',
    'Bonnybridge',
    'Largs',
    'St Andrews',
    'Kilmarnock',
    'Ayr',
    'Penicuik',
    'Kilwinning',
    'Kennoway',
    'Leven',
    'Darvel',
    'Larbert',
    'Clydebank',
    'Irvine',
    'Kilbirnie',
    'Troon',
    'Fochabers',
    'Stirling',
    'Crieff',
    'Aviemore',
    'Carluke',
    'Kinross',
    'Carlisle',
    'South Shields',
    'Newcastle upon Tyne',
    'York',
    'Middlesbrough',
    'Farnham',
    'Crawley',
    'Sandhurst',
    'Southsea',
    'Westerham',
    'Uxbridge',
    'Woking',
    'Bexhill',
    'Uxbridge',
    'Aldershot',
    'Reading',
    'Chichester',
    'Byfleet',
    'Portsmouth',
    'Petersfield',
    'Horsham',
    'South Hayling',
    'Faversham',
    'Guildford',
    'Leatherhead',
    'Kent',
    'Basingstoke',
    'Gillingham',
    'Ryde',
    'Whitstable',
    'Billingshurst',
    'Camberley',
    'Fleet',
    'Newport',
    'Dover',
    'Aberdeen',
    'Glasgow',
    'Musselburgh',
    'Alloa',
    'Dunfermline',
    'East Kilbride',
    'West Linton',
    'Ballynahinch',
    'Strathaven',
    'Broxburn',
    'Paisley',
    'Lanark',
    'Belfast',
    'London',
    'Chelmsford',
    'Hove',
    'Eastbourne',
    'Hastings',
    'New Cross',
    'Shoreham-by-Sea',
    'Uckfield',
    'Royal Tunbridge Wells',
    'Crowborough',
    'Rochester',
    'Henfield',
    'Littlehampton',
    'Polegate',
    'Emsworth',
    'Newhaven',
    'Hailsham',
    'Brighton',
    'Hassocks',
    'Sevenoaks',
    'Waterlooville',
    'Belvedere',
    'Barnet',
    'Enfield',
    'Greenford',
    'Rickmansworth',
    'Stanmore',
    'Wickford',
    'Royston',
    'Abbots Langley',
    'Wembley',
    'Edgware',
    'Ruislip',
    'Hatfield',
    'Borehamwood',
    'Braintree',
    'Hertford',
    'Harrow',
    'Heckmondwike',
    'Bradford',
    'Sheffield',
    'Leeds',
    'Wetherby',
    'Keighley',
    'Barnsley',
    'Ripon',
    'Wakefield',
    'Halifax',
    'Bridlington',
    'Huddersfield',
    'Birstall',
    'Castleford',
    'Scarborough',
    'Knottingley',
    'Dewsbury',
    'Leigh-on-Sea',
    'Loughton',
    'Rayleigh',
    'Upminster',
    'Walton-on-the-Naze',
    'Ilford',
    'Gravesend',
    'Romford',
    'Ipswich',
    'South Ockendon',
    'Grays',
    'Maidstone',
    'Maldon',
    'Hornchurch',
    'Basildon',
    'Colchester',
    'Billericay',
    'Tonbridge',
    'Sheerness',
    'Tilbury',
    'Banstead',
    'Tadworth',
    'Dartford',
    'Beckenham',
    'Croydon',
    'Worcester Park',
    'Surbiton',
    'Acton',
    'Sutton',
    'Thornton Heath',
    'Orpington',
    'Malden',
    'Burgess Hill',
    'Bexleyheath',
    'Milton Keynes',
    'Dunstable',
    'Hitchin',
    'Wellingborough',
    'Wootton',
    'Kidlington',
    'Luton',
    'Chipping Norton',
    'Towcester',
    'Chacombe',
    'Abingdon',
    'Market Harborough',
    'Oxford',
    'Kings Langley',
    'Daventry',
    'Flitwick',
    'Wallingford',
    'Biggleswade',
    'Ware',
    'Corby',
    'Bedford',
    'Manchester',
    'Warrington',
    'Cheadle',
    'Upton',
    'Oldham',
    'Stockport',
    'Chester',
    'Liverpool',
    'Salford',
    'Colne',
    'Colwyn Bay',
    'Leyland',
    'Bacup',
    'Bolton',
    'Urmston',
    'Runcorn',
    'Bury',
    'Sale',
    'Northwich',
    'Wallasey',
    'Mochdre',
    'Flint',
    'Wigan',
    'Lymm',
    'Jarrow',
    'Darlington',
    'Durham',
    'Sunderland',
    'Hartlepool',
    'Ashington',
    'Gateshead',
    'North Shields',
    'Redcar',
    'Blaydon',
    'Seaham',
    'Newton Aycliffe',
    'Whitley Bay',
    'Brandon',
    'Prudhoe',
    'Rowlands Gill',
    'Spennymoor',
    'Crook',
    'Peterlee',
    'Bedlington',
    'Shildon',
    'Corbridge',
    'Houghton-le-Spring',
    'Chester-le-Street',
    'Bishop Auckland',
    'Billingham',
    'Consett',
    'Bognor Regis',
    'Huntingdon',
    'Kettering',
    'Wymondham',
    'Bungay',
    'Mildenhall',
    'Saint Neots',
    'Brigg',
    'Haverhill',
    'Sandy',
    'March',
    'Skegness',
    'Kings Lynn',
    'Long Melford',
    'Boston',
    'Waltham',
    'Oakham',
    'Peterborough',
    'Attleborough',
    'Grimsby',
    'Halstead',
    'Holt',
    'Sudbury',
    'Norwich',
    'Wattisfield',
    'Spalding',
    'Stowmarket',
    'Wrawby',
    'Lincoln',
    'Bury',
    'Clacton-on-Sea',
    'Cambridge',
    'Saxtead',
    'Graveley',
    'Southport',
    'Morecambe',
    'Blackpool',
    'Ellesmere Port',
    'St Helens',
    'Preston',
    'Darwen',
    'Poulton le Fylde',
    'Chorley',
    'Lytham',
    'Clitheroe',
    'Skelmersdale',
    'Rawtenstall',
    'Blackburn',
    'Hyde',
    'Birkenhead',
    'Accrington',
    'Dalton in Furness',
    'Ormskirk',
    'Killamarsh',
    'Worksop',
    'Rotherham',
    'Wrexham',
    'Doncaster',
    'Alfreton',
    'East Retford',
    'Mansfield',
    'Barrow in Furness',
    'Chesterfield',
    'Llanfair-Dyffryn-Clwyd',
    'Slough',
    'Southall',
    'Sunbury-on-Thames',
    'Redhill',
    'Kingsbridge',
    'London Borough of Hounslow',
    'Shepperton',
    'Hayes',
    'Feltham',
    'Brentford',
    'Chard',
    'Shaftesbury',
    'Honiton',
    'Martock',
    'Sidmouth',
    'Marlow',
    'Wokingham',
    'Exeter',
    'Ottery St Mary',
    'Exmouth',
    'Newbury',
    'Welshpool',
    'Telford',
    'Wolverhampton',
    'Stoke-on-Trent',
    'Tipton',
    'Nantwich',
    'Stafford',
    'Newent',
    'Willenhall',
    'Lichfield',
    'Broseley',
    'Cannock',
    'Winsford',
    'Crewe',
    'Kingswinford',
    'Hereford',
    'Macclesfield',
    'Oswestry',
    'Kidderminster',
    'Bilston',
    'Congleton',
    'Newport',
    'Uttoxeter',
    'Shrewsbury',
    'Cradley',
    'Wem',
    'Ossett',
    'Biggin Hill',
    'Boxley',
    'Wotton-under-Edge',
    'Burntwood',
    'Droitwich',
    'Stourport On Severn',
    'Ledbury',
    'Cirencester',
    'Dursley',
    'Brierley Hill',
    'Dukinfield',
    'Farnworth',
    'Rochdale',
    'Littleborough',
    'Heswall',
    'Rhyl',
    'Knutsford',
    'Hinckley',
    'Kenilworth',
    'Warwick',
    'Belper',
    'Twickenham',
    'Ilminster',
    'Richmond',
    'Barnstaple',
    'Colyton',
    'Taunton',
    'Dawlish',
    'Seaton',
    'Thatcham',
    'Totnes',
    'Tadley',
    'Blandford Forum',
    'Wishaw',
    'Alexandria',
    'Woodford Green',
    'Worthing',
    'Chatham',
    'Greenhithe',
    'Forfar',
    'Peterhead',
    'Glossop',
    'Southminster',
    'Harwich',
    'Brentwood',
    'Epping',
    'Aylesford',
    'Barking',
    'Cramlington',
    'Morpeth',
    'Penrith',
    'Ferryhill',
    'Blyth',
    'Galashiels',
    'Stanley',
    'Hexham',
    'Dumfries',
    'Wallsend',
    'Cockermouth',
    'Stockton-on-Tees',
    'Haslemere',
    'Haywards Heath',
    'Newick',
    'Wadhurst',
    'Saint Leonards-on-Sea',
    'Havant',
    'Edenbridge',
    'Catford',
    'Ilkley',
    'Driffield',
    'Sowerby Bridge',
    'Pudsey',
    'Hebden Bridge',
    'Barnoldswick',
    'Harrogate',
    'Wath upon Dearne',
    'Holmfirth',
    'Selby',
    'Mirfield',
    'Southwell',
    'Buckley',
    'Pontefract',
    'Ashfield',
    'Bakewell',
    'Newark on Trent',
    'Matlock',
    'Herne Bay',
    'Horley',
    'Ashford',
    'Margate',
    'Winchester',
    'Eastleigh',
    'Staines',
    'West Cowes',
    'Sandown',
    'Cranleigh',
    'Canterbury',
    'Warden',
    'Carnforth',
    'Lancaster',
    'Cleveleys',
    'Burnley',
    'Bebington',
    'Nelson',
    'Glastonbury',
    'Wroughton',
    'Saltash',
    'Penryn',
    'Ringwood',
    'Weston-super-Mare',
    'Launceston',
    'Par',
    'Swindon',
    'Newton Abbot',
    'Westbury',
    'Penzance',
    'Bushey',
    'Saffron Walden',
    'Banbury',
    'Bicester',
    'Royal Leamington Spa',
    'Cheshunt',
    'Tring',
    'Hoddesdon',
    'Broxbourne',
    'Tottenham',
    'Watford',
    'Harpenden',
    'Pyle',
    'Merthyr Tydfil',
    'Pontypool',
    'New Tredegar',
    'Milford Haven',
    'Kidwelly',
    'Tredegar',
    'Barry',
    'Fishguard',
    'Llangattock',
    'Treorchy',
    'Tenby',
    'Woodstock',
    'Stevenage',
    'Rushden',
    'Leighton Buzzard',
    'Lidlington',
    'Corsham',
    'Aylesbury',
    'Swindon Village',
    'Thame',
    'Kilbride',
    'Burntisland',
    'Perth',
    'Bathgate',
    'Ashby de la Zouch',
    'Saint Bees',
    "Bishop's Stortford",
    'Potters Bar',
    'Hemel Hempstead',
    'Dromore',
    'Palmers Green',
    'Pinner',
    'Witham',
    'Baldock',
    'Shefford',
    'Wickham Bishops',
    'Dagenham',
    'Rainham',
    'St Albans',
    'Harlow',
    'Shipley',
    'Mitcham',
    'Epsom',
    'Godalming',
    'Covent Garden',
    'East Grinstead',
    'Lavant',
    'Wallington',
    'Tiverton',
    'Northolt',
    'Reigate',
    'Isleworth',
    'High Wycombe',
    'Ashford',
    'Wellington',
    'Lyndhurst',
    'Henstridge',
    'Great Torrington',
    'Sturminster Newton',
    'Redhill',
    'Bewdley',
    'Quinton',
    'Tenbury Wells',
    'Southam',
    'Ludlow',
    'Middlewich',
    'Stone',
    'Bridgnorth',
    'Armitage',
    'Newcastle',
    'Louth',
    'Holywell',
    'Brook',
    'Clevedon',
    'Tetbury',
    'Wilmslow',
    'Bangor-is-y-coed',
    'Stalybridge',
    'High Peak',
    'Chepstow',
    'Hamble',
    'Bodmin',
    'Helston',
    'Milford on Sea',
    'Tavistock',
    'Dorchester',
    'Truro',
    'Bridgwater',
    'Todmorden',
    'Kingston',
    'West Wittering',
    'Bracknell',
    'Weybridge',
    'Drayton Saint Leonard',
    'Alton',
    'Farnborough',
    'Hook',
    'Shanklin',
    'Kendal',
    'Craigavon',
    'Bury St Edmunds',
    'Hayes',
    'Felixstowe',
    'Great Yarmouth',
    'Liversedge',
    'Batley',
    'Brighouse',
    'Washington',
    'Wednesbury',
    'Dundee',
    'Ashton-under-Lyne',
    'Ely',
    'Wisbech',
    'Mauchline',
    'Hackbridge',
    'Carshalton',
    'Queensferry',
    'Axminster',
    'Crewkerne',
    'Yeovil',
    'Widnes',
    'Diss',
    'Perranporth',
    'Lydney',
    'Prestatyn',
    'Langport',
    'London Borough of Bromley',
    'Harleston',
    'Halesworth',
    'Thetford',
    'Wimborne Minster',
    'Buckie',
    'Longton',
    'Ascot',
    'Buckfastleigh',
    'East Stour',
    'Folkestone',
    'Hedgerley',
    'Bisley',
    'Montrose',
    'Heywood',
    'Denbigh',
    'Bagillt',
    'Caernarfon',
    'West Calder',
    'Grangemouth',
    'Glenrothes',
    "Bo'ness",
    'Berwick-Upon-Tweed',
    'Linlithgow',
    'Kirkcaldy',
    'Beaconsfield',
    'Windsor',
    'Maidenhead',
    'Lancing',
    'Seaford',
    'Lochgelly',
    'Ferndale',
    'Gelligaer',
    'Lisburn',
    'Portadown',
    'Cowdenbeath',
    'Altrincham',
    'Hurstpierpoint',
    'Mexborough',
    'Coppull',
    'Leek',
    'Ramsgate',
    'Elgin',
    'Inverness',
    'Dereham',
    'Brough',
    'East Ham',
    'Erith',
    'Sandbach',
    'Kidsgrove',
    'Bingley',
    'Southend-on-Sea',
    'Redbourn',
    'Chesham',
    'Newtownabbey',
    'Thornaby',
    'Whyteleafe',
    'South Benfleet',
    'Cromer',
    'Wadebridge',
    'Cwmbran',
    'Wimbledon',
    'West Wickham',
    'West Drayton',
    'Colnbrook',
    'Freshwater',
    'Wargrave',
    'Crowthorne',
    'Menai Bridge',
    'Moreton',
    'Hawley',
    'Snodland',
    'Scunthorpe',
    'Hornsea',
    'Walthamstow',
    'Stuartfield',
    'Antrim',
    'Stamford',
    'Tranent',
    'Gosport',
    'Gorleston-on-Sea',
    'Abergavenny',
    'Morden',
    'Welling',
    'Pendlebury',
    'Risca',
    'Newry',
    'Old Malton',
    'Conway',
    'Brandon',
    'Wicken',
    'Saint Ives',
    'Burry Port',
    'Addlestone',
    'Calne',
    'Prescot',
    'Wrea Green',
    'Caterham',
    'Lingfield',
    'Old Colwyn',
    'Lowestoft',
    'Sompting',
    'Cullompton',
    'Rosyth',
    'Carrickfergus',
    'Stanton Fitzwarren',
    'Downham Market',
    'Stanford',
    'Bangor',
    'Andover',
    'Swadlincote',
    'Rugeley',
    'Magherafelt',
    'Hockley',
    'Keith',
    'Cumnock',
    'Chippenham',
    'Cookham',
    'Weedon Bec',
    'Shipston on Stour',
    'Ashton in Makerfield',
    'Larne',
    'West Bromwich',
    'Girvan',
    'Denny',
    'Haslingden',
    'Llandudno Junction',
    'Camborne',
    'Newton-le-Willows',
    'Newmarket',
    'Ardrossan',
    'Oakley',
    'Goole',
    'Egham',
    'Teddington',
    'Port Talbot',
    'Londonderry',
    'Strabane',
    'Bellshill',
    'Fraserburgh',
    'Drayton Bassett',
    'Bishopstoke',
    'Dumbarton',
    'Crossgar',
    'Ballymena',
    'Millom',
    'Berkhamstead',
    'Motherwell',
    'Saltcoats',
    'Sittingbourne',
    'Birchington',
    'Hamilton',
    'Edmonton',
    'Nuneaton',
    'Ulverston',
    'Newtownards',
    'Waltham Abbey',
    'Huyton',
    'Melton Mowbray',
    'Gretna Green',
    'Irlam',
    'Cleckheaton',
    'Coleraine',
    'Fulham',
    'Shotton',
    'Paignton',
    'Haltwhistle',
    'Hackney',
    'Dronfield',
    'Chipping Ongar',
    'Heathfield',
    'St Asaph',
    'Rochford',
    'Dalry',
    'Cowbridge',
    'Rathfriland',
    'Carterton',
    'Prestonpans',
    'Northwood',
    'Woolwich',
    'Atherton',
    'Hayle',
    'Houghton on the Hill',
    'Llandudno',
    'Holywood',
    'Didcot',
    'Westgate on Sea',
    'Blackwood',
    'Chapel en le Frith',
    'North Berwick',
    'Whitburn',
    'Grendon',
    'Teignmouth',
    'Ilfracombe',
    'Hull',
    'Draycott in the Moors',
    'Sidcup',
    'Welwyn Garden City',
    'Chatteris',
    'Streatham',
    'Tarleton',
    'Warfield',
    'Cumbernauld',
    'Saltburn-by-the-Sea',
    'Bonnyrigg',
    'Ormiston',
    'Longfield',
    'Yarm',
    'Northallerton',
    'Trimdon',
    'Clapham',
    'Berkeley',
    'Bishops Cleeve',
    'Drybrook',
    'Highworth',
    'Leominster',
    'Ross on Wye',
    'Faringdon',
    'Broadstairs',
    'Oxted',
    'Iver',
    'Woodbridge',
    'Kirkliston',
    'Fleetwood',
    'Walton on the Hill',
    'Esher',
    'Ellon',
    'Rhoose',
    'Chartham',
    'Deal',
    'Teynham',
    'Bedworth',
    'Catterick',
    'Cam',
    'Shrivenham',
    'Sedgefield',
    'Lewes',
    'Newcastle-under-Lyme',
    'Lossiemouth',
    'Invergordon',
    'Molesey',
    'Bourne End',
    'Johnstone',
    'Well',
    'Gorebridge',
    'Currie',
    'Limavady',
    'Banbridge',
    'Hendon',
    'Mold',
    'Oswaldtwistle',
    'Renfrew',
    'Coatbridge',
    'Newport Pagnell',
    'Chessington',
    'Eastwood',
    'Abergele',
    'Kenley',
    'Otford',
    'Kirkintilloch',
    'Syston',
    'Frodsham',
    'Haydock',
    'Bridgemary',
    'Manningtree',
    'Frinton-on-Sea',
    'Portchester',
    'Leigh',
    'Nelson',
    'Royston',
    'Betchworth',
    'Cobham',
    'Hungerford',
    'Hillingdon',
    'Hanwell',
    'Calverton',
    'Eastington',
    'Egremont',
    'Tweedmouth',
    'Ponteland',
    'Rothbury',
    'Countess Wear',
    'Maryport',
    'Barton upon Humber',
    'Beccles',
    'Warlingham',
    'Chislehurst',
    'Askam in Furness',
    'Gillingham',
    'Richmond',
    'Halewood',
    'Blairgowrie',
    'Cupar',
    'Thurso',
    'Kinghorn',
    'Ruthin',
    'Crickhowell',
    'Oundle',
    'Somersham',
    'Sherborne',
    'Caersws',
    'Brackley',
    'Great Gransden',
    'Airdrie',
    'Clovenfords',
    'Stromness',
    'Gosberton',
    'Biddenden',
    'Roydon',
    'Knaresborough',
    'Shepton Mallet',
    'Helensburgh',
    'Bolney',
    'Curdridge',
    'Llandeilo',
    'Tenterden',
    'Fakenham',
    'Aberaeron',
    'Market Drayton',
    'Holyhead',
    'Clun',
    'Wigton',
    'Bowes',
    'Brecon',
    'Neston',
    'Treforest',
    'River',
    'Granby',
    'Melbourne',
    'Arnold',
    'Meldreth',
    'Hessle',
    'Beverley',
    'North Ferriby',
    'Cottingham',
    'Anlaby',
    'Elloughton',
    'Hillside',
    'Hursley',
    'Newton Stewart',
    'Carlton',
    'Pickering',
    'Bedale',
    'Thornton-in-Craven',
    'Settle',
    'Manor',
    'Fauldhouse',
    'Sleaford',
    'Duffield',
    'Monmouth',
    'Coupar Angus',
    'Wick',
    'Chipstead',
    'West Kirby',
    'Magheralin',
    'Alnwick',
    'Haverfordwest',
    'Holmewood',
    'Winslow',
    'Ampthill',
    'Hillsborough',
    'Dunmurry',
    'Thirsk',
    'East Hagbourne',
    'Rhayader',
    'Newport',
    'Abberton',
    'Sandwich',
    'Much Wenlock',
    'Laugharne',
    'Clunderwen',
    'Churchill',
    'Finchampstead',
    'Godstone',
    'Howden',
    'Stanford',
    'Tregarth',
    'Pembroke Dock',
    'Newtown',
    'Ely',
    'Rye',
    'New Romney',
    'Coulsdon',
    'Dorking',
    'Newham',
    'Cliffe',
    'Goring',
    'Molesey',
    'Hampton',
    'Pewsey',
    'Marlborough',
    'Croston',
    'Elland',
    'Pocklington',
    'Witney',
    'Padbury',
    'Radstock',
    'Holt',
    'Alva',
    'Gourock',
    'Greenock',
    'Stanwell',
    'Craven Arms',
    'Bucknell',
    'Ferryside',
    'Treharris',
    'Lampeter',
    'Lauder',
    'Malton',
    'Crowland',
    'Whittlesey',
    'Llanfyrnach',
    'Weston',
    'Budleigh Salterton',
    'Ashington',
    'Llanishen',
    'Battle',
    'Minehead',
    'Liphook',
    'Chertsey',
    'Ingatestone',
    'Brasted',
    'Headley',
    'Arundel',
    'Axbridge',
    'Amlwch',
    'Hawick',
    'Alness',
    'Auchinleck',
    'Anstruther',
    'Cross',
    'Ballycastle',
    'Portrush',
    'Woodhall Spa',
    'Southwold',
    'Totternhoe',
    'Wantage',
    'Walmer',
    'Falmouth',
    'Milnathort',
    'Skipton',
    'Great Dunmow',
    'Brechin',
    'Birtley',
    'Bildeston',
    'Papworth Everard',
    'Wrangaton',
    'Fordingbridge',
    'Bagshot',
    'Earley',
    'Bolsover',
    'Bromyard',
    'Stokesley',
    'Tillicoultry',
    'Haddington',
    'Armadale',
    'Romsey',
    'Draycott',
    'Sedbergh',
    'Otley',
    'Meanwood',
    'Market Rasen',
    'East Boldon',
    'Workington',
    'Llandovery',
    'Rutherglen',
    'Peebles',
    'Horwich',
    'Ballyclare',
    'Buckingham',
    'Purley',
    'Kings Sutton',
    'Okehampton',
    'Stone Allerton',
    'Yelverton',
    'Blackford',
    'Wedmore',
    'Ashtead',
    'Hill',
    'Omagh',
    'Barnard Castle',
    'Appleby',
    'Frithville',
    'Caythorpe',
    'Swaffham',
    'Milnthorpe',
    'Kelso',
    'Shinfield',
    'Swinton',
    'Buntingford',
    'Holbeach',
    'Sunningdale',
    'Somerset',
    'East Molesey',
    'Sheringham',
    'Caistor',
    'Gretton',
    'Whitby',
    'Kilmacolm',
    'Stow on the Wold',
    'North Walsham',
    'Dromara',
    'Aughnacloy',
    'Whitchurch',
    'Liss',
    'Stone',
    'Dinnington',
    'Sutton in Ashfield',
    'Chinley',
    'Braunstone',
    'Ibstock',
    'Heath',
    'Deptford',
    'Denham',
    'Bootle',
    'Burslem',
    'Broughton',
    'Bulkington',
    'Barwell',
    'Stocksbridge',
    'High Peak Junction',
    'Chadwell',
    'Attleborough',
    'Perivale',
    'Tunstall',
    'Whitefield',
    'Brierfield',
    'Balerno',
    'Speldhurst',
    'Knighton',
    'Wick',
    'Leeds',
    'Eltham',
    'Amersham',
    'Iwerne Courtney',
    'Tollard Royal',
    'Dartmouth',
    'Cookstown',
    'Burscough',
    'Aston',
    'Lerwick',
    'Kelbrook',
    'Knebworth',
    'Sawbridgeworth',
    'Bridge',
    'Toddington',
    'Hammersmith',
    'Crymych',
    'Sutton on the Hill',
    'Letchworth',
    'Redland',
    'New Ferry',
    'Walton on Thames',
    'Levenshulme',
    'Choppington',
    'Normanton',
    'Dungannon',
    'Armagh',
    'Eastchurch',
    'Beckton',
    'Woodford',
    'Rawreth',
    'Stansted',
    'Lymington',
    'Robertsbridge',
    'Croxley Green',
    'Biggar',
    'Uppingham',
    'Hotham',
    'Partridge Green',
    'Burythorpe',
    'Derwen',
    'Studley',
    'Ripley',
    'Wareham',
    'South Brewham',
    'Thornton',
    'Aberford',
    'Melksham',
    'Golborne',
    'Patchway',
    'Valley',
    'Stoke upon Tern',
    'Llantrisant',
    'Woodsetts',
    'Markfield',
    'Finchley',
    'Wigston',
    'Back',
    'Islington',
    'Ruddington',
    'Send',
    'Seale',
    'Grove',
    'Hindhead',
    'Long Eaton',
    'Bawtry',
    'Turnberry',
    'Morley',
    'Overton',
    'Victoria',
    'Eastcote',
    'Harefield',
    'Seven Kings',
    'Newlands',
    'Kilkeel',
    'Princethorpe',
    'Beaumont',
    'Hurworth',
    'London Borough of Wandsworth',
    'Corringham',
    'Marsham',
    'Porthleven',
    'Sandwell',
    'Shardlow',
    'Stoneleigh',
    'Star',
    'Bluntisham',
    'Minchinhampton',
    'Chiswick',
    'Fairford',
    'Ickenham',
    'Chigwell',
    'Clifton',
    'Pulborough village hall',
    'Laurencekirk',
    'Watlington',
    'Cheddar',
    'Woodhouse',
    'Wilden',
    'Arlesey',
    'North Weald',
    'Mill Hill',
    'Castle Donington',
    'Castlereagh',
    'Ifield',
    'Kirby Cross',
    'Waltham Cross',
    'Caddington',
    'Reddish',
    'Bransgore',
    'Stretford',
    'Timperley',
    'Wythenshawe',
    'Wales',
    'Seaton',
    'Standlake',
    'Lower Brailes',
    'Takeley',
    'Long Buckby',
    'Great Missenden',
    'Selsey',
    'Hedon',
    'Market Weighton',
    'Withernsea',
    'Burnham',
    'Winnersh',
    'Aintree',
    'Crediton',
    'Formby',
    'Carnoustie',
    'Silsden',
    'Swanscombe',
    'Northfleet',
    'Aveley',
    'Strood',
    'Heston',
    'Albrighton',
    'Chapeltown',
    'Filey',
    'Sunninghill',
    'Bickenhill',
    'Christleton',
    'Pembury',
    'East Horsley',
    'Upton',
    'Burton',
    'Blackford',
    'Swalwell',
    'Broughton',
    'Bramley',
    'Skelton',
    'Crick',
    'Mayfair',
    'Whisby',
    'Willaston',
    'Buxted',
    'Duxford',
    'Moreton in Marsh',
    'Three Legged Cross',
    'Hunstanton',
    'Radlett',
    'Broadway',
    'Winnington',
    'Corwen',
    'Cooksbridge',
    'Shoeburyness',
    'Drumchapel',
    'Larkhall',
    'Higham Ferrers',
    'Steeton',
    'Farnham Royal',
    'Chandlers Ford',
    'Ashley',
    'Twyford',
    'Farningham',
    'Wrangle',
    'Forest Row',
    'Horrabridge',
    'Portishead',
    'Hadleigh',
    'Bishops Waltham',
    'Brightlingsea',
    'Cuckfield',
    'Steyning',
    'Southwick',
    'Ivybridge',
    'Kirton in Lindsey',
    'Treuddyn',
    'Tewin',
    'Ramsbottom',
    'Tynemouth',
    'Spilsby',
    'Denby',
    'Henley',
    'Hartley',
    'Box',
    'Ripe',
    'Thurleigh',
    'Alresford',
    'Little Hulton',
    'Halton',
    'Dunoon',
    'Port Glasgow',
    'Bishopton',
    'Trent',
    'Streatley',
    'Hythe',
    'Wilmington',
    'Wickham',
    'Eastham',
    'Thames Ditton',
    'Little Paxton',
    'Meggies',
    'Merton',
    'Bookham',
    'Cottenham',
    'Earls Court',
    'Immingham',
    'Barrow upon Humber',
    'Bingham',
    'Broadstone',
    'Southbourne',
    'Biddulph',
    'Bridport',
    'Beer',
    'Thornton',
    'Huntspill',
    'Isham',
    'Thrapston',
    'Queenborough',
    'Stornoway',
    'Postling',
    'Cranbrook',
    'Fillongley',
    'Kirriemuir',
    'Elstree',
    'Slinfold',
    'Wombwell',
    'Linton',
    'Buckhurst Hill',
    'Cosham',
    'Catherington',
    'Broughton',
    'Burley',
    'Beauly',
    'Teddington',
    'Twyford',
    'Midsomer Norton',
    'Uddingston',
    'Ambleside',
    'Alford',
    'Low Ham',
    'Barton on Sea',
    'Wethersfield',
    'Hindley',
    'Pelsall',
    'Darlaston',
    'Gnosall',
    'Newbiggin-by-the-Sea',
    'Bromborough',
    'Highbridge',
    'Ashbourne',
    'Aberystwyth',
    'Windsor',
    'Hopwood',
    'Woodham Ferrers',
    'Auchterarder',
    'Chinnor',
    'Checkley',
    'Heathrow',
    'Knowsley',
    'Minsterley',
    'Clarbeston Road',
    'Lee-on-the-Solent',
    'Pinxton',
    'Alderley Edge',
    'Gorton',
    'Padiham',
    'The Hyde',
    'Downpatrick',
    'Cuffley',
    'Wolstanton',
    'Worsley',
    'Tilehurst',
    'Guisborough',
    'Westgate',
    'Clifton',
    'Shirebrook',
    'Kirkby in Ashfield',
    'Houghton Regis',
    'Hapton',
    'Mount Bures',
    'Rothwell',
    'Clifton',
    'Maulden',
    'Sutton on Trent',
    'West Byfleet',
    'Mansfield Woodhouse',
    'Frimley',
    'Ravenstone',
    'Shepshed',
    'Old Buckenham',
    'Desborough',
    'Rainhill',
    'Fitzwilliam',
    'Garstang',
    'Dulwich',
    'Pilton',
    'Tile Hill',
    'Llanidloes',
    'Eaglesham',
    'Whitchurch',
    'Pwllheli',
    'Blakeney',
    'South Petherton',
    'Stutton',
    'Melton Constable',
    'Jedburgh',
    'Fishburn',
    'Corfe Mullen',
    'Hartwell',
    'Ketley',
    'Radcliffe',
    'Fort William',
    'Galston',
    'Arbroath',
    'Strathmiglo',
    'Clackmannan',
    'Beaulieu',
    'Plockton',
    'Voe',
    'Inveraray',
    'Kinglassie',
    'Buckland',
    'Purleigh',
    'Beaminster',
    'Easingwold',
    'Llanymynech',
    'Newburgh',
    'Fintry',
    'Alford',
    'Aberchirder',
    'Callander',
    'Aberlour',
    'Cotgrave',
    'Great Chesterford',
    'Lyme Regis',
    'Longstanton',
    'Shotts',
    'Unstone',
    'Brewood',
    'Whitwick',
    'Somerton',
    'Stockbridge',
    'Lyminge',
    'Broughton',
    'Gunnislake',
    'Saint Agnes',
    'Easington',
    'Garston',
    'Church Gresley',
    'West Kilbride',
    'Normandy',
    'Rainham',
    'Milton',
    'Keswick',
    'Etchingham',
    'Saxmundham',
    'Anstey',
    'Saint Brides Major',
    'Ventnor',
    'Hindon',
    'Weston under Lizard',
    "Bishop's Castle",
    'Kinnerley',
    'Barkway',
    'Hurstbourne Tarrant',
    'Westbury-sub-Mendip',
    'Dolwyddelan',
    'Talybont',
    'Castleton',
    'Northleach',
    'Chipping Campden',
    'New Quay',
    'Ottershaw',
    'Petworth',
    'Bedminster',
    'Caston',
    'Selston',
    'Effingham',
    'Brixham',
    'New Southgate',
    'Ashwell',
    'Totton',
    'Bletchley',
    'Winterbourne Dauntsey',
    'Southwater',
    'Blakeney',
    'Llangefni',
    'Staplehurst',
    'Great Fransham',
    'Pencarreg',
    'Benenden',
    'Limekilns',
    'Leyburn',
    'Great Gidding',
    'Hemingstone',
    'Chaddesden',
    'Little Kimble',
    'Seascale',
    'Exminster',
    'Loanhead',
    'Atworth',
    'Brixton Hill',
    'Datchet',
    'Middleton One Row',
    'Middleton',
    'Tollerton',
    'Much Hadham',
    'More',
    'West Horsley',
    'Ulceby',
    'Myton on Swale',
    'Lindfield',
    'Nairn',
    'Northrepps',
    'Church Stretton',
    'Shifnal',
    'Dyserth',
    'Arthog',
    'Malmesbury',
    'Wincanton',
    'Silloth',
    'Weobley',
    'Kirkwall',
    'Carlton le Moorland',
    'Broom',
    'Cinderford',
    'Rogiet',
    'Offord Cluny',
    'Pevensey',
    'Eyemouth',
    'Carbrooke',
    'Kimbolton',
    'Giggleswick',
    'Cobham',
    'Leysdown-on-Sea',
    'Oare',
    'Banchory',
    'Hinton St George',
    'Ashburton',
    'Windermere',
    'Whitehaven',
    'Saundersfoot',
    'Compton',
    'Crumlin',
    'Whitbourne',
    'Fulmer',
    'Pencader',
    'Shotley Gate',
    'Fressingfield',
    'Corfe Castle',
    'Melrose',
    'Innerleithen',
    'Whiteparish',
    'Zeals',
    'Holsworthy',
    'Pitlochry',
    'Lockerbie',
    'Seend',
    'Burghclere',
    'Drayton',
    'Insch',
    'Inverness-shire',
    'Gargunnock',
    'Port Erroll',
    'Llanbedr',
    'Beckley',
    'Symington',
    'Wymeswold',
    'Banff',
    'Nordelph',
    'Hartfield',
    'Bayford',
    'Greensted',
    'Dulverton',
    'Bickleigh',
    'Oakamoor',
    'Beckingham',
    'Dymock',
    'Tadcaster',
    'Wragby',
    'Gosforth',
    'Narberth',
    'North Moreton',
    'Hawes',
    'New Barnet',
    'Stratford',
    'Burstwick',
    'Aldeburgh',
    'Mablethorpe',
    'Beith',
    'Tiptree',
    'Watton',
    'Bridgham',
    'Drighlington',
    'Inverurie',
    'Connahs Quay',
    'Dinas Powys',
    'Soham',
    'Leiston',
    'Gosfield',
    'Wootton',
    'Stonehaven',
    'Moston',
    'Muir of Ord',
    'Codsall',
    'Stretton on Fosse',
    'Barnwell',
    'Llangollen',
    'Herstmonceux',
    'South Milford',
    'Bottesford',
    'Gaerwen',
    'Montgomery',
    'Cynwyd',
    'Broadwas',
    'Talgarth',
    'Great Ryburgh',
    'Wyverstone',
    'Sheering',
    'Meifod',
    'Brampton',
    'Machynlleth',
    'Kirkby',
    'Auchtermuchty',
    'Mark',
    'Lifton',
    'Polperro',
    'South Molton',
    'Alfold',
    'Birnam',
    'Strathyre',
    'Cardigan',
    'Lechlade',
    'Clavering',
    'Gayton Thorpe',
    'Malpas',
    'Longdown',
    'Ramsey',
    'Piddington',
    'Newport-On-Tay',
    'Blunham',
    'Wilburton',
    'Dumbleton',
    'Owslebury',
    'Easton',
    'Bruton',
    'Aycliffe',
    'Angmering',
    'Henley-on-Thames',
    'Cricklade',
    'Edwalton',
    'Harlaxton',
    'South Normanton',
    'Chingford',
    'Clipstone',
    'Tansley',
    'Princes Risborough',
    'Stock',
    'Waterbeach',
    'Ormesby',
    'Leyton',
    'Bishopbriggs',
    'Portslade-by-Sea',
    'Goodmayes',
    'Stanford',
    'Lowton',
    'Chalgrove',
    'Studham',
    'Botley',
    'Denton',
    'Kempston',
    'Lewisham',
    'Market Deeping',
    'Rainworth',
    'Swavesey',
    'Hucknall Torkard',
    'Droylsden',
    'Sawley',
    'Keynsham',
    'Sidlesham',
    'Harrow Weald',
    'Brockworth',
    'Hebburn',
    'Theydon Bois',
    'Burbage',
    'Crayford',
    'Hersham',
    'Balloch',
    'High Blantyre',
    'Thornbury',
    'Yate',
    'Norwood',
    'Winlaton',
    'Studley',
    'Whickham',
    'Salfords',
    'Maghull',
    'Neasden',
    'Hanworth',
    'Comrie',
    'Purfleet',
    'Houston',
    'Baildon',
    'Nailsea',
    'Polmont',
    'Walton on the Hill',
    'Norbury',
    'Addington',
    'Cheam',
    'Boldon Colliery',
    'Chelsfield',
    'Sedgley',
    'Edgbaston',
    'Freckleton',
    'Honingham',
    'Northfield',
    'Holmes Chapel',
    'Much Hoole',
    'Bowdon',
    'Middleton',
    'East Linton',
    'Charfield',
    'Gipping',
    'Milton on Stour',
    'Greenfield',
    'Moulton St Michael',
    'Chilbolton',
    'Histon',
    'Bothwell',
    'Barton-le-Clay',
    'Square',
    'Burnopfield',
    'Aston Clinton',
    'Langstone',
    'Whitchurch',
    'Nettleton',
    'Waldringfield',
    'Greenwich',
    'Rosslea',
    'Chryston',
    'Skilgate',
    'Chalfont Saint Peter',
    'Waddesdon',
    'Horley',
    'Grantown on Spey',
    'Ben Rhydding',
    'Oban',
    'Chestfield',
    'Stoke Poges',
    'East Harling',
    'Eye',
    'Thorrington',
    'Burnham on Crouch',
    'Sanderstead',
    'Stallingborough',
    'Rustington',
    'Binsted',
    'Steventon',
    'Heysham',
    'Torpoint',
    'Worle',
    'Wick',
    'Blidworth',
    'Stonesfield',
    'Laxton',
    'Billesley',
    'Uphall',
    'Hilton',
    'Oadby',
    'Cowley',
    'Lubenham',
    'Lambourn',
    'West Malling',
    'Willington',
    'Pencaitland',
    'Lochmaddy',
    'Whitland',
    'Willington',
    'West Mersea',
    'Wentworth',
    'Painswick',
    'Donnington',
    'Hatch',
    'Turvey',
    'Haddenham',
    'Millbrook',
    'Pembroke',
    'Chellaston',
    'Bletchingley',
    'Balham',
    'Tonyrefail',
    'Carnmoney',
    'East Dulwich',
    'Irthlingborough',
    'Puckeridge',
    'Wheathampstead',
    'Briton Ferry',
    'Westhoughton',
    'Cheadle Hulme',
    'Yeadon',
    'Bulford',
    'Godmanchester',
    'Henlow',
    'Coseley',
    'Humberston',
    'Stotfold',
    'Horndean',
    'Tongham',
    'Elderslie',
    'Sandiacre',
    'Marske',
    'North Hykeham',
    'Tyldesley',
    'Olney',
    'Sheldon',
    'Cambuslang',
    'Thornaby',
    'Ash Vale',
    'Sonning',
    'Willesden',
    'Bealings',
    'East Cowes',
    'Wootton',
    'Durrington',
    'Blaenavon',
    'Dunchurch',
    'Horsmonden',
    'Brixworth',
    'Harwell',
    'Bugbrooke',
    'Cleator Moor',
    'Steeple Claydon',
    'Rothesay',
    'Headington',
    'Dovercourt',
    'Tarporley',
    'Greenisland',
    'Carlton',
    'Coalisland',
    'Hagley',
    'Brimscombe',
    'Pulloxhill',
    'Welwyn',
    'Kingston Seymour',
    'Binfield',
    'Royal Wootton Bassett',
    'Newcastle',
    'Monifieth',
    'Duffus',
    'Llantwit Major',
    'Stewarton',
    'Dunblane',
    'South Killingholme',
    'Itchen Abbas',
    'West Raynham',
    'Kirkby Stephen',
    'Stillington',
    'Inverkeilor',
    'Glenshee',
    'Kingussie',
    'Campbeltown',
    'Leitholm',
    'Prees',
    'Bala',
    'Dunkeld',
    'Longniddry',
    'Sutton Courtenay',
    'Wix',
    'Yoxford',
    'Leigh',
    'Spetisbury',
    'Kingskerswell',
    'Looe',
    'Upwell',
    'Pampisford',
    'Tain',
    'Marden',
    'Penn',
    'Horsted Keynes',
    'East Bergholt',
    'Westcott',
    'Stocksfield',
    'Oldmeldrum',
    'Canewdon',
    'Winterborne Kingston',
    'Kirkconnel',
    'Mayfield',
    'Norton',
    'Haydon Bridge',
    'Horam',
    'Little Gaddesden',
    'Stanbridge',
    'Turriff',
    'Lochgilphead',
    'East Tilbury',
    'Storrington',
    'Hathersage',
    'Macduff',
    'Dingwall',
    'Farmborough',
    'Gullane',
    'Bozeat',
    'Crosby',
    'Wick',
    'Glasdrumman',
    'Llanrwst',
    'Hadleigh',
    'Warrenpoint',
    'Enniskillen',
    'Fivemiletown',
    'Ellington',
    'Selkirk',
    'Ringmer',
    'Hawkhurst',
    'Barnham',
    'Bude',
    'Prestwich',
    'Ashdon',
    'Cogenhoe',
    'Portree',
    'Aultbea',
    'Enstone',
    'Finedon',
    'Grange',
    'Frant',
    'High Halden',
    'Lochwinnoch',
    'Arrochar',
    'Winkleigh',
    'Watchet',
    'Beaworthy',
    'Doune',
    'Stiffkey',
    'Bonar Bridge',
    'High Bentham',
    'Edgefield',
    'Gargrave',
    'Glentham',
    'North Kilworth',
    'Ramsbury',
    'Lyng',
    'Heckington',
    'Lowdham',
    'Lydbury North',
    'Dolgelly',
    'Blaenau-Ffestiniog',
    'Barmston',
    'Whittlebury',
    'Llanwrtyd Wells',
    'Nutley',
    'Cardross',
    'Harlech',
    'Brede',
    'Hilborough',
    'Portmadoc',
    'Penshurst',
    'Templecombe',
    'Moira',
    'Flordon',
    'Yatton',
    'Milton',
    'Slapton',
    'Kelty',
    'Dollar',
    'Braunton',
    'South Cerney',
    'Horncastle',
    'Dornoch',
    'Rowhedge',
    'South Carlton',
    'Grendon Underwood',
    'Grateley',
    'Pentraeth',
    'Llwyngwril',
    'Mollington',
    'Little Barningham',
    'Trehafod',
    'Martley',
    'Ellesmere',
    'Warton',
    'Little Canfield',
    'Bradwell on Sea',
    'Tackley',
    'Dunkeswell',
    'Nutfield',
    'Dunning',
    'Abergynolwyn',
    'Westhay',
    'Huntly',
    'Baslow',
    'Bowness-on-Windermere',
    'Chulmleigh',
    'Charmouth',
    'Llanfairfechan',
    'New Buckenham',
    'Tarbert',
    'Wyton',
    'Rock Ferry',
    'Alderholt',
    'Odiham',
    'Calstock',
    'Benburb',
    'Bangor',
    'Hemsworth',
    'St Ives',
    'Templepatrick',
    'Higher Bebington',
    'Boscastle',
    'Castlederg',
    'Helens Bay',
    'Marhamchurch',
    'Snettisham',
    'Besthorpe',
    'Midhurst',
    'Stow',
    'Banham',
    'Great Waldingfield',
    'Norton',
    'Kilbarchan',
    'Claygate',
    'Gorslas',
    'Mytholmroyd',
    'Presteigne',
    'Upton upon Severn',
    'Amesbury',
    'Drumahoe',
    'Juniper Green',
    'Horndon on the Hill',
    'Barrow',
    'Aghalee',
    'Ruabon',
    'Ramsey Saint Marys',
    'Wirksworth',
    'Peterculter',
    'Kingswood',
    'Fladbury',
    'Callington',
    'Napton on the Hill',
    'Tayport',
    'Wing',
    'Hingham',
    'Hermitage',
    'Wellington',
    'Littleport',
    'Anstey',
    'Biddenham',
    'Inverkeithing',
    'Fowey',
    'Conon Bridge',
    'Marple',
    'Hednesford',
    'Saint Columb Major',
    'Newcastle Emlyn',
    'Aberkenfig',
    'North Tawton',
    'Great Milton',
    'Matching',
    'Taynuilt',
    'Shalford',
    'Great Glemham',
    'Northop',
    'Froggatt',
    'Brandesburton',
    'Masham',
    'Birdbrook',
    'Landrake',
    'Trusthorpe',
    'Reydon',
    'Darfield',
    'Whimple',
    'Elstead',
    'Widdrington',
    'Lambeth',
    'East Peckham',
    'Taplow',
    'Benllech',
    'Bramford',
    'Kibworth Harcourt',
    'Byfield',
    'Kimberley',
    'Keresley',
    'Barton under Needwood',
    'Clare',
    'Pilham',
    'Patrington',
    'Helmsley',
    'Leconfield',
    'Roxwell',
    'Mickleton',
    'Bow',
    'Henham',
    'Sherfield upon Loddon',
    'Kirkcudbright',
    'Duddington',
    'Tetsworth',
    'Naseby',
    'Akeley',
    'Nettlebed',
    'Harrold',
    'Colyford',
    'Blewbury',
    'Loddington',
    'Aylsham',
    'Cranfield',
    'Totteridge',
    'Maghera',
    'Deighton',
    'Gresford',
    'Southgate',
    'Ballingry',
    'Newton on Trent',
    'Lisnaskea',
    'Ballymoney',
    'Newport',
    'Harworth',
    'Eccleshall',
    'Annesley',
    'Oxshott',
    'Randalstown',
    'Moneymore',
    'East Budleigh',
    'Tandragee',
    'Stawell',
    'Silverstone',
    'Long Ditton',
    'Windlesham',
    'Monkswood',
    'Sedgeberrow',
    'Mendlesham',
    'Selborne',
    'Lustleigh',
    'Talsarnau',
    'Avoch',
    'Stockcross',
    'Kelmarsh',
    'Gamlingay',
    'Stanton',
    'Lostwithiel',
    'Westleton',
    'Corpach',
    'Belbroughton',
    'Thorpe St Peter',
    'Broughton',
    'Seaview',
    'East Barnet',
    'Falkland',
    'Kelsall',
    'Minster Lovell',
    'Shireoaks',
    'Forres',
    'Wombourne',
    'Claverdon',
    'Measham',
    'Rotherfield',
    'Wingate',
    'Menheniot',
    'Radcliffe on Trent',
    'Blackwater',
    'Earnley',
    'Kedington',
    'Camelford',
    'Newbridge',
    'Barmouth',
    'Bledlow',
    'Llanfyllin',
    'Cowden',
    'Staindrop',
    'Kingham',
    'Cockerham',
    'Frizington',
    'Silverdale',
    'Willingham',
    'Laxfield',
    'Butterton',
    'Blaenwaun',
    'Barton in Fabis',
    'Ashen',
    'Burbage',
    'Eynsham',
    'Weston',
    'Llanwrda',
    'Betws',
    'Elsworth',
    'Ufford',
    'Kirtlington',
    'Faulkland',
    'Hatherleigh',
    'Scalby',
    'Cullen',
    'Fringford',
    'Grasmere',
    'Hightown',
    'Hambleton',
    'Friskney',
    'Stoke Climsland',
    'Clifton Hampden',
    'Horbling',
    'Marcham',
    'Guiseley',
    'Wroxall',
    'Frensham',
    'Avonmouth',
    'Trefonen',
    'East Challow',
    'Rhos-on-Sea',
    'Stevenston',
    'Addington',
    'Castlewellan',
    'Maiden Newton',
    'Rowlands Castle',
    'Boroughbridge',
    'Linton upon Ouse',
    'Nafferton',
    'Coalbrookdale',
    'Lower Darwen',
    'Mells',
    'Inverkip',
    'Meopham',
    'Roath',
    'Broughty Ferry',
    'Sileby',
    'Widmerpool',
    'North Waltham',
    'Doagh',
    'Saint Erme',
    'Harold Wood',
    'Dorney',
    'Brockenhurst',
    'Fairlight',
    'Goring',
    'Morriston',
    'Alsager',
    'Wemyss Bay',
    'Murton',
    'Bodelwyddan',
    'Ticehurst',
    'Alveston',
    'Garrison',
    'Skelmorlie',
    'Roehampton',
    'Bacton',
    'Nassington',
    'Wraysbury',
    'Castle Cary',
    'Capel',
    'Four Marks',
    'Llansantffraid-ym-Mechain',
    'Aldridge',
    'Caister-on-Sea',
    'Bordesley',
    'Shalford',
    'Godmersham',
    'West Clandon',
    'Brownhills',
    'Linton',
    'Edenfield',
    'Rothwell',
    'Builth Wells',
    'Cross in Hand',
    'Wanstead',
    'Bembridge',
    'High Legh',
    'Empingham',
    'Hever',
    'Arley',
    'Pontesbury',
    'Water Orton',
    'Wolverley',
    'Tisbury',
    'Harlesden',
    'Paddock Wood',
    'Sully',
    'Llwynypia',
    'Carrowdore',
    'Burwash',
    'Chilton',
    'Loudwater',
    'Theale',
    'Renton',
    'Langley',
    'Pittenweem',
    'Moulton St Michael',
    'Englefield Green',
    'Halstead',
    'Cheadle',
    'Lapworth',
    'South Cave',
    'Marnhull',
    'Blackwater',
    'Brimpton',
    'Risley',
    'Wooburn',
    'Broughton',
    'Crofton',
    'Whetstone',
    'Kensington',
    'Ardmillan',
    'Powfoot',
    'Girton',
    'Kennington',
    'Rainford',
    'Bredbury',
    'Beach',
    'Brenchley',
    'Pennington',
    'Littlebury',
    'Clayton West',
    'Kirkham',
    'Staple',
    'Kelvedon',
    'Spratton',
    'Bramhall',
    'Bloomsbury',
    'Brook',
    'Martin',
    'City of London',
    'Bourton on the Water',
    'Charlwood',
    'Croughton',
    'Southgate',
    'Hennock',
    'Old Windsor',
    'Laindon',
    'Walkden',
    'Barnes',
    'Norton Lindsey',
    'Eversholt',
    'Horton Kirby',
    'Wilshamstead',
    'Crookham',
    'Bermondsey',
    'Castle Douglas',
    'Neilston',
    'Whitwell',
    'Longridge',
    'East Leake',
    'Alvechurch',
    'Eccles',
    'Ashmore',
    'Loughor',
    'Hampton Lucy',
    'Tattershall',
    'Ystalyfera',
    'Kinmel',
    'Merthyr Mawr',
    'Milford',
    'Llangennech',
    'Epping Green',
    'Preesall',
    'Ewelme',
    'Whittlesford',
    'Mountsorrel',
    'Ripley',
    'Hoylake',
    'Willington Quay',
    'Pathhead',
    'Framlingham',
    'East Hanningfield',
    'Donaghadee',
    'North Elmsall',
    'Milltimber',
    'Askern',
    'Oldbury',
    'Pontycymer',
    'Great Baddow',
    'Dollis Hill',
    'Slawitt',
    'Glenboig',
    'Colne',
    'Portlethen',
    'Little Lever',
    'Bulwell',
    'Felbridge',
    'Poplar',
    'Shenfield',
    'Northenden',
    'Threlkeld',
    'Ullesthorpe',
    'Stanton',
    'Goostrey',
    'Walgrave',
    'Berwick',
    'Halwell',
    'Mallaig',
    'Ticknall',
    'Bentley',
    'Kneeton',
    'Tatsfield',
    'Broughton',
    'Millbrook',
    'Stalham',
    'Cleator',
    'Gretna',
    'Lenham',
    'Ewhurst',
    'Sacriston',
    'Melton',
    'Caversham',
    'Wickham Market',
    'Penwortham',
    'Grimsargh',
    'Cambusnethan',
    'Litherland',
    'Gatwick',
    'Bromham',
    'Lurgan',
    'North Petherton',
    'East Hoathly',
    'Manton',
    'Rawmarsh',
    'Draperstown',
    'Otterburn',
    'Kilbride',
    'Lavendon',
    'Lee',
    'Leek Wootton',
    'Potton',
    'Mobberley',
    'Swynnerton',
    'South Hetton',
    'Pontardawe',
    'Hucknall under Huthwaite',
    'Weston in Gordano',
    'Needingworth',
    'Partington',
    'Portstewart',
    'Dungiven',
    'Swaythling',
    'Handforth',
    'Caxton',
    'Hadlow Down',
    'Harrow on the Hill',
    'Weaverham',
    'Bidston',
    'Abercynon',
    'Lawrencetown',
    'Stranraer',
    'Bollington',
    'Pencoed',
    'Woodley',
    'Maerdy',
    'Whaley Bridge',
    'Stony Stratford',
    'Abergwynfi',
    'Nantyglo',
    'Dorridge',
    'Astley',
    'Shirehampton',
    'Raynes Park',
    'Kings Norton',
    'Maida Vale',
    'Chipping Sodbury',
    'Wrington',
    'Hawkwell',
    'Menston',
    'Poynton',
    'Port Sunlight',
    'Clapham',
    'Hanley',
    'Horbury',
    'Bignor',
    'Altham',
    'Oxenhope',
    'Sandycroft',
    'Llantwit Fardre',
    'New Mills',
    'St. Dogmaels',
    'Higham on the Hill',
    'Elmley Lovett',
    'Aberfeldy',
    'Earlston',
    'Charlestown',
    'Carrbridge',
    'Spondon',
    'Shaw',
    'Hatton',
    'West Rudham',
    'Whitton',
    'Tillingham',
    'Pleshey',
    'Botesdale',
    'Willingale Doe',
    'Coed-Talon',
    'Beaumaris',
    'Alston',
    'Woore',
    'Bosbury',
    'Tadmarton',
    'Brigstock',
    'Clutton',
    'Mere',
    'Highcliffe',
    'Burton Pidsea',
    'Middleton',
    'Withernwick',
    'North Somercotes',
    'Wold Newton',
    'Tideswell',
    'Fangfoss',
    'Porlock',
    'Loppington',
    'Powick',
    'Diddington',
    'Saint Annes on the Sea',
    'Woolacombe',
    'Chilcompton',
    'Stanstead',
    'Ponders End',
    'Borough Green',
    'Buckden',
    'Birtley',
    'Little Eaton',
    'Dundonald',
    'Hawarden',
    'Barrowford',
    'Mundesley',
    'Sutton on Hull',
    'Shawbury',
    'Oulton',
    'Methil',
    'Langho',
    'Burgh le Marsh',
    'Lakenheath',
    'Lesmahagow',
    'Midgham',
    'Parbold',
    'Long Stratton',
    'Brandon',
    'Sanquhar',
    'Longhope',
    'Portaferry',
    'Tempo',
    'Waringstown',
    'Rowde',
    'Whitchurch',
    'Watchfield',
    'Whalley',
    'Moulton',
    'Mattishall',
    'Metheringham',
    'Duns',
    'Aboyne',
    'Heybridge',
    'Great Staughton',
    'Smarden',
    'Shilton',
    'Ridingmill',
    'Walker',
    'Cawston',
    'Farndon',
    'Conisbrough',
    'Sarratt',
    'Leuchars',
    'East Malling',
    'Llanddulas',
    'Clydach',
    'Wheatley',
    'Islip',
    'West Ashby',
    'Stifford',
    'Ilchester',
    'Padstow',
    'Tywardreath',
    'Roche',
    'Camberwell',
    'Goodwick',
    'Treherbert',
    'Radnage',
    'Cublington',
    'Chilton',
    'Llanharan',
    'Ripponden',
    'Gaydon',
    'Chasetown',
    'Bilsthorpe',
    'Gomersal',
    'Wendover',
    'Groombridge',
    'Weare',
    'Upper Beeding',
    'Coggeshall',
    'Heriot',
    'Appleford',
    'Belcoo',
    'Belleek',
    'Kingswood',
    'Blaina',
    'Gordon',
    'Norton',
    'Appleton',
    'Moorsholm',
    'Roffey',
    'Kingston',
    'Seacroft',
    'Daresbury',
    'Babraham',
    'Hornsey',
    'Reed',
    'Birchanger',
    'Smalley',
    'Edwinstowe',
    'Monyash',
    'Friern Barnet',
    'Newhall',
    'Stockton',
    'Seghill',
    'Adlingfleet',
    'Plumstead',
    'Keele',
    'Eagle',
    'Bishopstrow',
    'Temple',
    'Rimington',
    'West Wickham',
    'South Brent',
    'Isleham',
    'Annan',
    'Upton',
    'Sutton Bridge',
    'Plaistow',
    'South Elmsall',
    'Potterspury',
    'Newtonmore',
    'Stepps',
    'Dalbeattie',
    'Keston',
    'Rottingdean',
    'Crosshouse',
    'Cricklewood',
    'Rogerstone',
    'Bovey Tracey',
    'Kingsteignton',
    'Ryton',
    'East Preston',
    'Rayne',
    'Kirk Ella',
    'Sturmer',
    'Stisted',
    'Egton',
    'Appley Bridge',
    'Hartford',
    'Oakford',
    'Paul',
    'Bayble',
    'Pitsford',
    'Rock',
    'Guthrie',
    'Lympsham',
    'Medstead',
    'Midford',
    'Comber',
    'Mid Calder',
    'West Row',
    'Hemswell',
    'Speke',
    'Whitnash',
    'Capenhurst',
    'Itchen',
    'Brompton',
    'Jordanstown',
    'Southwark',
    'Bishop Burton',
    'West Drayton',
    'Wotton Underwood',
    'Dawley',
    'Benson',
    'Upper Slaughter',
    'Worth',
    'Bekesbourne',
    'Stoke',
    'Medmenham',
    'Brookland',
    'Hooke',
    'Nuffield',
    'Failsworth',
    'Newbold',
    'Ballybogy',
    'Annalong',
    'Gifford',
    'Writtle',
    'Newbridge',
    'Newham',
    'Bradfield St George',
    'Lindal in Furness',
    'Ardingly',
    'Mickleton',
    'Ovingdean',
    'Stranmillis',
    'Walford',
    'Askham',
    'Heythrop',
    'Charing Cross',
    'City of Westminster',
    'Llandrillo',
    'Gorseinon',
    'North Newton',
    'Lupton',
    'Egerton',
    'Stoke Newington',
    'North Leigh',
    'Repton',
    'Tendring',
    'Great Billing',
    'Cromwell',
    'Bampton',
    'Marr',
    'Cranford',
    'Wyke',
    'Felsted',
    'Clare',
    'Stoke by Nayland',
    'Thurston',
    'Herriard',
    'Crathorne',
    'Dyce',
    'Kelly',
    'Chilwell',
    'Theale',
    'Belmont',
    'Hambleden',
    'Mossley',
    'Woldingham',
    'Charlton on Otmoor',
    'Brockley Green',
    'Eton',
    'Barley',
    'Ford',
    'Moss',
    'Dean',
    'Ball',
    'Hartpury',
    'Holborn',
    'Bournville',
    'Llanon',
    'Highbury',
    'Humbie',
    'Cardonald',
    'Stow',
    'Anniesland',
    'Havering atte Bower',
    'Greenhead',
    'Handsworth',
    'Waltham',
    'Salthouse',
    'Inchinnan',
    'Longport',
    'Milton',
    'Holywell',
    'Beaufort',
    'Earls Colne',
    'Wealdstone',
    'Weedon',
    'Burwell',
    'Warmington',
    'Surrey',
    'Hazelwood',
    'Wilnecote',
    'Hellesdon',
    'Weald',
    'Reepham',
    'Eaglesfield',
    'Maryhill',
    'Portglenone',
    'Broad Blunsdon',
    'Scapa',
    'Wheaton Aston',
    'Church',
    'Myddle',
    'Penkridge',
    'Woodhouse',
    'Rivington',
    'Stanground',
    'Sidcot',
    'Monkton',
    'Wootton',
    'Spittal',
    'Plymstock',
    'Stowe',
    'Witley',
    'Ratcliffe on Soar',
    'Hadlow',
    'Huish',
    'Paston',
    'Otley',
    'East Down',
    'Cadbury',
    'Alton',
    'Sparsholt',
    'Hilderstone',
    'Nash',
    'Burton',
    'Kingsland',
    'Meesden',
    'Stratfield Mortimer',
    'Sprowston',
    'Moseley',
    'Earlham',
    'Ashby de la Launde',
    'Mayfield',
    'Bettws',
    'Lytchett Minster',
    'Hampton',
    'Roade',
    'Wednesfield',
    'Stanhope',
    'Abersychan',
    'Highway',
    'Cross Keys',
    'Cottered',
    'East Keal',
    'Kyle of Lochalsh',
    'Killin',
    'Great Barton',
    'Dingestow',
    'Headcorn',
    'Plaitford',
    'Whitehouse',
    'Great Amwell',
    'Boreham',
    'Quedgeley',
    'Swanland',
    'Ushaw Moor',
    'Worminghall',
    'Ash',
    'Old Basing',
    'Ystrad Mynach',
    'Moorside',
    'Aldermaston',
    'Beeston',
    'Bainton',
    'Rushden',
    'Shadoxhurst',
    'Hardham',
    'Stoke Prior',
    'Shelford',
    'Boston Spa',
    'Fownhope',
    'Criccieth',
    'Ystradgynlais',
    'Church Leigh',
    'Drymen',
    'Little Milton',
    'Barbaraville',
    'Oakhill',
    'Bierton',
    'Droxford',
    'Ulcombe',
    'Oving',
    'Kinloch Rannoch',
    'Llantrisant',
    'Thakeham',
    'Staverton',
    'Woburn Sands',
    'Essendon',
    'Saint Clears',
    'Crossgates',
    'Lower Beeding',
    'West Grinstead',
    'Bransford',
    'Privett',
    'Woodmancote',
    'Aberfoyle',
    'Douglas',
    'Augher',
    'Hazel Grove',
    'Bloxwich',
    'Linthwaite',
    'Silsoe',
    'Sundon Park',
    'Goudhurst',
    'Brooke',
    'Compton Dundon',
    'Beal',
    'Coppenhall',
    'Garth',
    'Minster',
    'Brayton',
    'Snaresbrook',
    'Norham',
    'Mount Hamilton',
    'Penrice',
    'Westfield',
    'Chilton Trinity',
    'Downend',
    'Urchfont',
    'Winton',
    'Withington',
    'Wickersley',
    'Burford',
    'Childwall',
    'Hitcham',
    'Westleigh',
    'West Langdon',
    'Handcross',
    'Blackheath',
    'Alrewas',
    'Clapham',
    'Harby',
  ],
  tld: ['.uk'],
  cca2: 'GB',
  ccn3: '826',
  cca3: 'GBR',
  cioc: 'GBR',
  currency: ['GBP'],
  callingCode: ['44'],
  capital: 'London',
  altSpellings: ['GB', 'UK', 'Great Britain'],
  region: 'Europe',
  subregion: 'Northern Europe',
  languages: { eng: 'English' },
  translations: {
    deu: {
      official: 'Vereinigtes Königreich Großbritannien und Nordirland',
      common: 'Vereinigtes Königreich',
    },
    fra: {
      official: "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord",
      common: 'Royaume-Uni',
    },
    hrv: {
      official: 'Ujedinjeno Kraljevstvo Velike Britanije i Sjeverne Irske',
      common: 'Ujedinjeno Kraljevstvo',
    },
    ita: {
      official: 'Regno Unito di Gran Bretagna e Irlanda del Nord',
      common: 'Regno Unito',
    },
    jpn: {
      official: 'グレート·ブリテンおよび北アイルランド連合王国',
      common: 'イギリス',
    },
    nld: {
      official: 'Verenigd Koninkrijk van Groot-Brittannië en Noord-Ierland',
      common: 'Verenigd Koninkrijk',
    },
    por: {
      official: 'Reino Unido da Grã-Bretanha e Irlanda do Norte',
      common: 'Reino Unido',
    },
    rus: {
      official: 'Соединенное Королевство Великобритании и Северной Ирландии',
      common: 'Великобритания',
    },
    spa: {
      official: 'Reino Unido de Gran Bretaña e Irlanda del Norte',
      common: 'Reino Unido',
    },
    fin: {
      official: 'Ison-Britannian ja Pohjois-Irlannin yhdistynyt kuningaskunta',
      common: 'Yhdistynyt kuningaskunta',
    },
  },
  latlng: [54, -2],
  demonym: 'British',
  landlocked: false,
  borders: ['IRL'],
  area: 242900,
} as const;
