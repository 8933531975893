export const CYM = {
  name: {
    common: 'Cayman Islands',
    official: 'Cayman Islands',
    native: { eng: { official: 'Cayman Islands', common: 'Cayman Islands' } },
  },
  cities: [
    'Coral Gables',
    'George Town',
    'West Bay',
    'Savannah',
    'Spot Bay',
    'Bodden Town',
    'Newlands',
  ],
  tld: ['.ky'],
  cca2: 'KY',
  ccn3: '136',
  cca3: 'CYM',
  cioc: 'CAY',
  currency: ['KYD'],
  callingCode: ['1345'],
  capital: 'George Town',
  altSpellings: ['KY'],
  region: 'Americas',
  subregion: 'Caribbean',
  languages: { eng: 'English' },
  translations: {
    cym: { official: 'Cayman Islands', common: 'Ynysoedd_Cayman' },
    deu: { official: 'Cayman-Inseln', common: 'Kaimaninseln' },
    fra: { official: 'Îles Caïmans', common: 'Îles Caïmans' },
    hrv: { official: 'Kajmanski otoci', common: 'Kajmanski otoci' },
    ita: { official: 'Isole Cayman', common: 'Isole Cayman' },
    jpn: { official: 'ケイマン諸島', common: 'ケイマン諸島' },
    nld: { official: 'Caymaneilanden', common: 'Caymaneilanden' },
    por: { official: 'Ilhas Cayman', common: 'Ilhas Caimão' },
    rus: { official: 'Каймановы острова', common: 'Каймановы острова' },
    spa: { official: 'Islas Caimán', common: 'Islas Caimán' },
    fin: { official: 'Caymansaaret', common: 'Caymansaaret' },
  },
  latlng: [19.5, -80.5],
  demonym: 'Caymanian',
  landlocked: false,
  borders: [],
  area: 264,
} as const;
