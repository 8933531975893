/** Used by scrollbarWidth() function to cache scrollbar's width. */
let SCROLLBAR_WIDTH: number | undefined;

/**
 * Scrollbar on body and html element in IE and Edge overlay
 * content and should be considered 0 width.
 */
let BODY_SCROLLBAR_WIDTH: number | undefined;

/**
 * ...
 */
export interface StackMapItem<K, V> {
  key: K;
  value: V;
}

/**
 * ...
 */
export class MultiMap<T> {
  #map: Record<string, T[]> = {};

  /**
   * ...
   *
   * @returns ...
   */
  entries() {
    return Object.keys(this.#map).map((key) => ({
      key,
      value: this.#map[key],
    }));
  }

  /**
   * ...
   *
   * @param key ...
   * @returns ...
   */
  get(key: string) {
    return this.#map[key];
  }

  /**
   * ...
   *
   * @param key ...
   * @returns ...
   */
  hasKey(key: string) {
    return !!this.#map[key];
  }

  /**
   * ...
   *
   * @returns ...
   */
  keys() {
    return Object.keys(this.#map);
  }

  /**
   * ...
   *
   * @param key ...
   * @param value ...
   * @returns ...
   */
  put(key: string, value: T) {
    if (!this.#map[key]) {
      this.#map[key] = [];
    }

    this.#map[key]?.push(value);
  }

  /**
   * ...
   *
   * @param key ...
   * @param value ...
   * @returns ...
   */
  remove(key: string, value: T) {
    const values = this.#map[key];

    if (!values) return;

    const idx = values.indexOf(value);

    if (idx !== -1) values.splice(idx, 1);

    if (!values.length) delete this.#map[key];
  }
}

export class StackedMap<K, V> {
  private stack: StackMapItem<K, V>[] = [];

  /**
   * ...
   *
   * @param key ...
   * @param value ...
   */
  add(key: K, value: V) {
    this.stack.push({ key, value });
  }

  /**
   * ...
   *
   * @param key ...
   * @returns ...
   */
  get(key: K) {
    for (const item of this.stack) {
      if (key === item.key) return item;
    }

    return undefined;
  }

  /**
   * ...
   *
   * @returns ...
   */
  keys() {
    return this.stack.map(({ key }) => key);
  }

  /**
   * ...
   *
   * @returns ...
   */
  top() {
    return this.stack[this.stack.length - 1];
  }

  /**
   * ...
   *
   * @param key ...
   * @returns ...
   */
  remove(key: K) {
    let idx = -1;

    for (let i = 0; i < this.stack.length; i++) {
      if (key === this.stack[i]?.key) {
        idx = i;

        break;
      }
    }

    return this.stack.splice(idx, 1)[0];
  }

  /**
   * ...
   *
   * @returns ...
   */
  removeTop() {
    return this.stack.pop();
  }

  /**
   * ...
   *
   * @returns ...
   */
  length() {
    return this.stack.length;
  }
}

const OVERFLOW_REGEX = {
  normal: /(auto|scroll)/,
  hidden: /(auto|scroll|hidden)/,
};

/**
 * Checks to see if the element is scrollable.
 *
 * @param el The node to check.
 * @param includeHidden Should scroll style of 'hidden' be considered, default
 * is false.
 * @returns Whether the element is scrollable.
 */
export function isScrollable(el: HTMLElement, includeHidden?: boolean) {
  const overflowRegex = includeHidden
    ? OVERFLOW_REGEX.hidden
    : OVERFLOW_REGEX.normal;

  const elemStyle = window.getComputedStyle(el);

  return overflowRegex.test(
    elemStyle.overflow + elemStyle.overflowY + elemStyle.overflowX,
  );
}

export interface ScrollbarPadding {
  /** The width of the scrollbar */
  scrollbarWidth: number;
  /** Whether the the width is overflowing */
  widthOverflow: boolean;
  /** The amount of right padding on the element needed to replace the scrollbar */
  right: number;
  /** The amount of right padding currently on the element */
  originalRight: number;
  /** Whether the the height is overflowing */
  heightOverflow: boolean;
  /** The amount of bottom padding on the element needed to replace the scrollbar */
  bottom: number;
  /** The amount of bottom padding currently on the element */
  originalBottom: number;
}

/**
 * Provides the padding required on an element to replace the scrollbar.
 *
 * @returns The scrollbar padding.
 */
export function scrollbarPadding(el: HTMLElement) {
  const elemStyle = window.getComputedStyle(el);
  const paddingRight = parseStyle(elemStyle.paddingRight);
  const paddingBottom = parseStyle(elemStyle.paddingBottom);
  const scrollParent = getScrollParent(el, false, true);
  const scrollbarWidth = getScrollbarWidth(
    /(HTML|BODY)/.test(scrollParent.tagName),
  );

  const padding: ScrollbarPadding = {
    scrollbarWidth: scrollbarWidth,
    widthOverflow: scrollParent.scrollWidth > scrollParent.clientWidth,
    right: paddingRight + scrollbarWidth,
    originalRight: paddingRight,
    heightOverflow: scrollParent.scrollHeight > scrollParent.clientHeight,
    bottom: paddingBottom + scrollbarWidth,
    originalBottom: paddingBottom,
  };

  return padding;
}

//#region Helper Functions

/**
 * Provides a parsed number for a style property. Strips units and casts invalid numbers to 0.
 *
 * @param value The style value to parse.
 * @returns A valid number.
 */
function parseStyle(value: string) {
  const num = parseFloat(value);

  return isFinite(num) ? num : 0;
}

/**
 * Provides the closest scrollable ancestor.
 *
 * @param el The element to find the scroll parent of.
 * @param includeHidden Should scroll style of 'hidden' be considered, default is false.
 * @param includeSelf Should the element being passed be included in the scrollable llokup.
 * @returns A HTML element.
 */
function getScrollParent(
  el: HTMLElement,
  includeHidden?: boolean,
  includeSelf?: boolean,
) {
  const overflowRegex = includeHidden
    ? OVERFLOW_REGEX.hidden
    : OVERFLOW_REGEX.normal;

  const documentEl = document.documentElement;

  const elemStyle = window.getComputedStyle(el);

  if (
    includeSelf &&
    overflowRegex.test(
      elemStyle.overflow + elemStyle.overflowY + elemStyle.overflowX,
    )
  ) {
    return el;
  }

  let excludeStatic = elemStyle.position === 'absolute';
  let scrollParent = el.parentElement ?? documentEl;

  if (scrollParent === documentEl || elemStyle.position === 'fixed') {
    return documentEl;
  }

  while (scrollParent.parentElement && scrollParent !== documentEl) {
    const spStyle = window.getComputedStyle(scrollParent);

    if (excludeStatic && spStyle.position !== 'static') {
      excludeStatic = false;
    }

    if (
      !excludeStatic &&
      overflowRegex.test(
        spStyle.overflow + spStyle.overflowY + spStyle.overflowX,
      )
    ) {
      break;
    }

    scrollParent = scrollParent.parentElement;
  }

  return scrollParent;
}

/**
 * Provides the scrollbar width.
 *
 * @param isBody ...
 * @returns The width of the browser scollbar.
 */
function getScrollbarWidth(isBody: boolean) {
  if (isBody) {
    if (BODY_SCROLLBAR_WIDTH === undefined) {
      document.body.classList.add('uib-position-body-scrollbar-measure');

      BODY_SCROLLBAR_WIDTH = window.innerWidth - document.body.clientWidth;
      BODY_SCROLLBAR_WIDTH = isFinite(BODY_SCROLLBAR_WIDTH)
        ? BODY_SCROLLBAR_WIDTH
        : 0;

      document.body.classList.remove('uib-position-body-scrollbar-measure');
    }

    return BODY_SCROLLBAR_WIDTH;
  }

  if (SCROLLBAR_WIDTH === undefined) {
    const scrollElem = document.createElement('div');
    scrollElem.classList.add('uib-position-scrollbar-measure');

    document.body.append(scrollElem);

    SCROLLBAR_WIDTH = scrollElem.offsetWidth - scrollElem.clientWidth;
    SCROLLBAR_WIDTH = isFinite(SCROLLBAR_WIDTH) ? SCROLLBAR_WIDTH : 0;

    scrollElem.remove();
  }

  return SCROLLBAR_WIDTH;
}

//#endregion Helper Functions
