import { modals } from '@modals';
import { clipboard } from '@services/clipboard';

/**
 * ...
 */
export function createVerificationLink(certificationId: string) {
  return `${window.location.origin}/verification/${certificationId}`;
}

/**
 * ...
 */
export async function copyCertificationLink(certificationId: string) {
  await clipboard.copy(createVerificationLink(certificationId), 'Link copied');
}

/**
 * ...
 */
export async function copyEmbedCode(
  certificationId: string,
  certificationBadge: string,
) {
  const link = createVerificationLink(certificationId);

  const code = `
    <a href="${link}" style="display: inline-block">
      <img width="200" src="${certificationBadge}" />
    </a>
  `;

  await modals.util.copyEmbedCode({ code });
}
