export const SAU = {
  name: {
    common: 'Saudi Arabia',
    official: 'Kingdom of Saudi Arabia',
    native: {
      ara: { official: 'المملكة العربية السعودية', common: 'العربية السعودية' },
    },
  },
  cities: [
    'Riyadh',
    'Dammam',
    'Safwa',
    'Al Qatif',
    'Dhahran',
    'Al Faruq',
    'Khobar',
    'Jubail',
    'Sayhat',
    'Jeddah',
    "Ta'if",
    'Mecca',
    'Al Hufuf',
    'Medina',
    'Rahimah',
    'Rabigh',
    'Yanbu` al Bahr',
    'Abqaiq',
    'Mina',
    'Ramdah',
    'Linah',
    'Abha',
    'Jizan',
    'Al Yamamah',
    'Tabuk',
    'Sambah',
    'Ras Tanura',
    'At Tuwal',
    'Sabya',
    'Buraidah',
    'Madinat Yanbu` as Sina`iyah',
    'Hayil',
    'Khulays',
    'Khamis Mushait',
    "Ra's al Khafji",
    'Najran',
    'Sakaka',
    'Al Bahah',
    'Rahman',
    'Jazirah',
    'Jazirah',
  ],
  tld: ['.sa', '.السعودية'],
  cca2: 'SA',
  ccn3: '682',
  cca3: 'SAU',
  cioc: 'KSA',
  currency: ['SAR'],
  callingCode: ['966'],
  capital: 'Riyadh',
  altSpellings: [
    'Saudi',
    'SA',
    'Kingdom of Saudi Arabia',
    'Al-Mamlakah al-‘Arabiyyah as-Su‘ūdiyyah',
  ],
  region: 'Asia',
  subregion: 'Western Asia',
  languages: { ara: 'Arabic' },
  translations: {
    deu: { official: 'Königreich Saudi-Arabien', common: 'Saudi-Arabien' },
    fra: { official: "Royaume d'Arabie Saoudite", common: 'Arabie Saoudite' },
    hrv: {
      official: 'Kraljevina Saudijska Arabija',
      common: 'Saudijska Arabija',
    },
    ita: { official: 'Arabia Saudita', common: 'Arabia Saudita' },
    jpn: { official: 'サウジアラビア王国', common: 'サウジアラビア' },
    nld: { official: 'Koninkrijk van Saoedi-Arabië', common: 'Saoedi-Arabië' },
    por: { official: 'Reino da Arábia Saudita', common: 'Arábia Saudita' },
    rus: {
      official: 'Королевство Саудовская Аравия',
      common: 'Саудовская Аравия',
    },
    spa: { official: 'Reino de Arabia Saudita', common: 'Arabia Saudí' },
    fin: { official: 'Saudi-Arabian kuningaskunta', common: 'Saudi-Arabia' },
  },
  latlng: [25, 45],
  demonym: 'Saudi Arabian',
  landlocked: false,
  borders: ['IRQ', 'JOR', 'KWT', 'OMN', 'QAT', 'ARE', 'YEM'],
  area: 2149690,
  provinces: [
    "'Asir",
    'Al Bahah',
    'Al Hudud ash Shamaliyah',
    'Al Jawf',
    'Al Madinah',
    'Al Qasim',
    'Ar Riyad',
    'Ash Sharqiyah (Eastern Province)',
    "Ha'il",
    'Jizan',
    'Makkah',
    'Najran',
    'Tabuk',
  ],
  nativeName: 'العربية السعودية',
  timezones: ['UTC+03:00'],
} as const;
