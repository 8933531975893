export const ATG = {
  name: {
    common: 'Antigua and Barbuda',
    official: 'Antigua and Barbuda',
    native: {
      eng: { official: 'Antigua and Barbuda', common: 'Antigua and Barbuda' },
    },
  },
  cities: [
    "St. John's",
    'Old Road',
    'All Saints',
    'Woods',
    'Cassada Gardens',
    'Codrington',
    'Parham',
  ],
  tld: ['.ag'],
  cca2: 'AG',
  ccn3: '028',
  cca3: 'ATG',
  cioc: 'ANT',
  currency: ['XCD'],
  callingCode: ['1268'],
  capital: "Saint John's",
  altSpellings: ['AG'],
  region: 'Americas',
  subregion: 'Caribbean',
  languages: { eng: 'English' },
  translations: {
    cym: { official: 'Antigua and Barbuda', common: 'Antigwa a Barbiwda' },
    deu: { official: 'Antigua und Barbuda', common: 'Antigua und Barbuda' },
    fra: { official: 'Antigua -et-Barbuda', common: 'Antigua-et-Barbuda' },
    hrv: { official: 'Antigva i Barbuda', common: 'Antigva i Barbuda' },
    ita: { official: 'Antigua e Barbuda', common: 'Antigua e Barbuda' },
    jpn: {
      official: 'アンチグアバーブーダ',
      common: 'アンティグア・バーブーダ',
    },
    nld: { official: 'Antigua en Barbuda', common: 'Antigua en Barbuda' },
    por: { official: 'Antigua e Barbuda', common: 'Antígua e Barbuda' },
    rus: { official: 'Антигуа и Барбуда', common: 'Антигуа и Барбуда' },
    spa: { official: 'Antigua y Barbuda', common: 'Antigua y Barbuda' },
    fin: { official: 'Antigua ja Barbuda', common: 'Antigua ja Barbuda' },
  },
  latlng: [17.05, -61.8],
  demonym: 'Antiguan, Barbudan',
  landlocked: false,
  borders: [],
  area: 442,
} as const;
