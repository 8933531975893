export const GHA = {
  name: {
    common: 'Ghana',
    official: 'Republic of Ghana',
    native: { eng: { official: 'Republic of Ghana', common: 'Ghana' } },
  },
  cities: [
    'Tema',
    'Accra',
    'Koforidua',
    'Sunyani',
    'Bolgatanga',
    'Winneba',
    'Home',
    'Navrongo',
    'Legon',
    'Wa',
    'Kumasi',
    'Takoradi',
    'Mampong',
    'Cape Coast',
    'Bawku',
    'Berekum',
  ],
  tld: ['.gh'],
  cca2: 'GH',
  ccn3: '288',
  cca3: 'GHA',
  cioc: 'GHA',
  currency: ['GHS'],
  callingCode: ['233'],
  capital: 'Accra',
  altSpellings: ['GH'],
  region: 'Africa',
  subregion: 'Western Africa',
  languages: { eng: 'English' },
  translations: {
    deu: { official: 'Republik Ghana', common: 'Ghana' },
    fra: { official: 'République du Ghana', common: 'Ghana' },
    hrv: { official: 'Republika Gana', common: 'Gana' },
    ita: { official: 'Repubblica del Ghana', common: 'Ghana' },
    jpn: { official: 'ガーナ共和国', common: 'ガーナ' },
    nld: { official: 'Republiek Ghana', common: 'Ghana' },
    por: { official: 'República do Gana', common: 'Gana' },
    rus: { official: 'Республика Гана', common: 'Гана' },
    spa: { official: 'República de Ghana', common: 'Ghana' },
    fin: { official: 'Ghanan tasavalta', common: 'Ghana' },
  },
  latlng: [8, -2],
  demonym: 'Ghanaian',
  landlocked: false,
  borders: ['BFA', 'CIV', 'TGO'],
  area: 238533,
  provinces: [
    'Ashanti',
    'Brong-Ahafo',
    'Central',
    'Eastern',
    'Greater Accra',
    'Northern',
    'Upper East',
    'Upper West',
    'Volta',
    'Western',
  ],
  nativeName: 'Ghana',
  timezones: ['UTC'],
} as const;
