var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-card",
    { staticClass: "card-table-group-item", attrs: { "no-body": "" } },
    [
      _c(
        "b-card-header",
        {
          staticClass: "p-3 d-flex justify-content-between",
          attrs: { "header-tag": "header", role: "tab" },
          on: {
            click: function ($event) {
              _setup.expanded = !_setup.expanded
            },
          },
        },
        [
          _c("span", [_vm._v(_vm._s(_setup.tablePanel.label))]),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "pull-right" },
            [
              _c(_setup.Icon, {
                attrs: {
                  icon: _setup.expanded ? _setup.faMinus : _setup.faPlus,
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        {
          attrs: { accordion: "data-table-accordion", role: "tabpanel" },
          model: {
            value: _setup.expanded,
            callback: function ($$v) {
              _setup.expanded = $$v
            },
            expression: "expanded",
          },
        },
        [
          _c(
            "b-card-body",
            { staticClass: "p-0" },
            [
              _setup.expanded
                ? _c(
                    _setup.tableComponent,
                    _vm._b(
                      { tag: "component", attrs: { table: _vm.tableKey } },
                      "component",
                      _setup.tablePanel,
                      false
                    )
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }