import { Module, VuexModule, Mutation, Action } from '@vuex/decorators';
import type { TablePanel } from 'table-panel';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';

import { api } from '@api';
import { auth } from '@auth';
import { Order, User } from '@models';
import { Root } from '@store';
import { isString } from '@tools/type-guards';

declare module '@vuex/core' {
  export interface Getters {
    'orders/getById': Orders['getById'];
    'orders/getMyOrders': Orders['getMyOrders'];
  }

  export interface CommitMap {
    'orders/SET': Orders['SET'];
    'orders/ADD': Orders['ADD'];
    'orders/CLEAR_ORDERS': Orders['CLEAR_ORDERS'];
  }

  export interface DispatchMap {
    'orders/getPublic': Orders['getPublic'];
    'orders/listMine': Orders['listMine'];
    'orders/listByUser': Orders['listByUser'];
    'orders/listByOrganization': Orders['listByOrganization'];
    'orders/get': Orders['get'];
    'orders/assign': Orders['assign'];
  }
}

@Module({ namespaced: true })
export class Orders
  extends VuexModule<Orders.State, Root.State>
  implements Orders.State
{
  items: Order[] = [];
  lastEvaluatedKey: ZephyrWeb.Pagination.PlacementKey | null = null;
  allResultsLoaded = false;

  /**
   * ...
   */
  get getById() {
    return (id: Order['id']) => find(this.items, { id }) ?? null;
  }

  /**
   * ...
   */
  get getMyOrders() {
    return (userId: User['id']) => find(this.items, { userId }) ?? null;
  }

  //#region Mutations

  /**
   * ...
   *
   * @param options ...
   */
  @Mutation
  SET(options: Orders.SetMutationOptions) {
    this.items = options.data;
  }

  /**
   * ...
   */
  @Mutation
  SET_LAST_EVALUATED_KEY(options: {
    lastEvaluatedKey: ZephyrWeb.Pagination.PlacementKey | null;
  }) {
    this.lastEvaluatedKey = options.lastEvaluatedKey;
  }

  /**
   * ...
   *
   * @param options ...
   */
  @Mutation
  ADD(options: Orders.AddMutationOptions) {
    const items = [...this.items];

    const i = findIndex(items, { id: options.data.id });

    if (i !== -1) {
      items[i] = options.data;
    } else {
      items.push(options.data);
    }

    this.items = items;
  }

  /**
   * ...
   */
  @Mutation
  CLEAR_ORDERS() {
    this.items = [];
  }

  //#endregion Mutations

  //#region Actions

  /**
   * ...
   */
  @Action
  async loadPage(options: TablePanel.LoadPageOptions<Order>) {
    const searchOptions = {
      limit: 25,
    } as api.orders.OrderSearch.Options;

    if (isString(options.filter.contains)) {
      searchOptions.orderId = options.filter.contains;
      searchOptions.email = options.filter.contains;
    }

    if (options.filter.tags) {
      if (options.filter.tags.includes('paidOrders')) {
        searchOptions.hideFreeOrders = true;
      }

      if (options.filter.tags.includes('referralOrders')) {
        searchOptions.onlyReferralOrders = true;
      }
    }

    let data: Order[];

    if (this.lastEvaluatedKey && !options.clearPrevious) {
      searchOptions.startKey = this.lastEvaluatedKey;
      data = [...this.items];
    } else {
      data = [];
    }

    const res = await api.orders.search(searchOptions);

    data.push(...res.items);

    this.context.commit('SET', { data });
    this.context.commit('SET_LAST_EVALUATED_KEY', {
      lastEvaluatedKey: res.lastEvaluatedKey,
    });

    return res;
  }

  /**
   * Load a public order.
   *
   * @param options ...
   * @return ...
   */
  @Action
  async getPublic(options: Orders.GetPublicActionOptions) {
    const data = await api.orders.getPublic(options);

    this.context.commit('SET', { data });

    return data;
  }

  /**
   * Load logged in user's orders.
   *
   * @return ...
   */
  @Action
  async listMine() {
    const data = await api.orders.listMine();

    this.context.commit('SET', { data });

    return data;
  }

  /**
   * Load a single user's orders.
   *
   * @returns ...
   */
  @Action
  async listByUser(options: Orders.ListByUserActionOptions) {
    const data = await api.orders.listByUser(options);

    this.context.commit('SET', { data });

    return data;
  }

  /**
   * Load institution's orders.
   *
   * @returns ...
   */
  @Action
  async listByOrganization() {
    const { me } = this.context.rootState;

    if (!auth.isActiveRole(5) || !me.organization?.id) {
      console.error('Must be Institution Admin');

      return null;
    }

    const data = await api.orders.listByOrganization({
      organizationId: me.organization.id,
    });

    this.context.commit('SET', { data });

    return data;
  }

  /**
   * Load a Single Order.
   *
   * @param orderId ...
   * @returns ...
   */
  @Action
  async get(orderId: string) {
    const data = await api.orders.get({ orderId });

    this.context.commit('ADD', { data });

    return data;
  }

  /**
   * Assign order to user.
   *
   * @returns ...
   */
  @Action
  async assign(options: Orders.AssignOrderOptions) {
    const data = await api.orders.assign(options);

    this.context.commit('ADD', { data });

    return data;
  }

  //#endregion Actions
}

export namespace Orders {
  /** ... */
  export interface State {
    items: Order[];
  }

  /** ... */
  export interface SetMutationOptions {
    data: Order[];
  }

  /** ... */
  export interface AddMutationOptions {
    data: Order;
  }

  /** ... */
  export type GetPublicActionOptions = api.orders.GetPublicOptions;
  /** ... */
  export type ListByUserActionOptions = api.orders.ListByUserOptions;
  /** ... */
  export type AssignOrderOptions = api.orders.AssignOrderOptions;
  /** ... */
  export type ListByInstitutionActionOptions =
    api.orders.ListByOrganizationOptions;
}

export default Orders;
