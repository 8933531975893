import { Module, VuexModule, Mutation, Action } from '@vuex/decorators';

import { api } from '@api';
import { Drone } from '@models';
import { Root } from '@store';
import { findById, addById, updateById, removeById } from '@utils/array';

declare module '@vuex/core' {
  export interface Getters {
    'drones/findById': Drones['findById'];
  }

  export interface CommitMap {
    'drones/SET': Drones['SET'];
    'drones/ADD': Drones['ADD'];
    'drones/UPDATE': Drones['UPDATE'];
    'drones/DELETE': Drones['DELETE'];
    'drones/CLEAR': Drones['CLEAR'];
  }

  export interface DispatchMap {
    'drones/list': Drones['list'];
    'drones/get': Drones['get'];
    'drones/create': Drones['create'];
    'drones/update': Drones['update'];
    'drones/del': Drones['del'];
  }
}

@Module({ namespaced: true })
export class Drones
  extends VuexModule<Drones.State, Root.State>
  implements Drones.State
{
  items: Drone[] = [];

  /** Fetch a specific `Drone` data object by ID. */
  get findById() {
    return (id: string) => findById(this.items, id);
  }

  //#region Mutations

  /**
   * ...
   */
  @Mutation
  SET(options: Drones.SetMutationOptions) {
    this.items = options.data;
  }

  /**
   * ...
   */
  @Mutation
  ADD(options: Drones.AddMutationOptions) {
    this.items = addById(this.items, options);
  }

  /**
   * ...
   */
  @Mutation
  UPDATE({ droneId, ...options }: Drones.UpdateMutationOptions) {
    this.items = updateById(this.items, { id: droneId, ...options });
  }

  /**
   * ...
   */
  @Mutation
  DELETE(options: Drones.DeleteMutationOptions) {
    this.items = removeById(this.items, options.droneId);
  }

  /**
   * ...
   */
  @Mutation
  CLEAR() {
    this.items = [];
  }

  //#endregion Mutations

  //#region Actions

  /**
   * ...
   */
  @Action
  async list() {
    const data = await api.drones.list();

    this.context.commit('SET', { data });

    return this.items;
  }

  /**
   * ...
   */
  @Action
  async get(options: Drones.GetActionOptions) {
    const data = await api.drones.get(options);

    this.context.commit('ADD', data);

    return data;
  }

  /**
   * ...
   */
  @Action
  async create(options: Drones.CreateActionOptions) {
    const data = await api.drones.create(options);

    this.context.commit('ADD', data);

    return data;
  }

  /**
   * ...
   */
  @Action
  async update(options: Drones.UpdateActionOptions) {
    await api.drones.update(options);

    return await this.context.dispatch('get', options);
  }

  /**
   * ...
   */
  @Action
  async del(options: Drones.DeleteActionOptions) {
    await api.drones.del(options);

    this.context.commit('DELETE', options);
  }

  //#endregion Actions
}

export namespace Drones {
  export interface State {
    items: Drone[];
  }

  export interface SetMutationOptions {
    data: Drone[];
  }

  export type AddMutationOptions = Drone;
  export type UpdateMutationOptions = api.drones.UpdateOptions;
  export type DeleteMutationOptions = api.drones.DeleteOptions;
  export type GetActionOptions = api.drones.GetOptions;
  export type CreateActionOptions = api.drones.CreateOptions;
  export type UpdateActionOptions = api.drones.UpdateOptions;
  export type DeleteActionOptions = api.drones.DeleteOptions;
}

export default Drones;
