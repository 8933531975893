import Vue from '@vue';

import { importContext } from '@utils/import-context';

/** Required context for local Vue directive modules. */
const context = require.context(
  '.',
  false,
  /(?<!(index|imports\.webpack|imports\.vite))\.ts$/,
);

importContext(context, ({ name, value }) => Vue.directive(name, value));
