import { Module, VuexModule, Mutation, Action } from '@vuex/decorators';
import { find } from 'lodash';
import type { TablePanel } from 'table-panel';
import type { LicenseSearch } from '@api/modules/licenses';
import { auth, RoleId } from '@auth';
import { api } from '@api';
import { License } from '@models';
import { Root } from '@store';
import { isString } from '@tools/type-guards';

declare module '@vuex/core' {
  export interface CommitMap {
    'licenses/SET': Licenses['SET'];
    'licenses/SET_LAST_EVALUATED_KEY': Licenses['SET_LAST_EVALUATED_KEY'];
  }

  export interface DispatchMap {
    'licenses/loadPage': Licenses['loadPage'];
  }
}

@Module({ namespaced: true })
export class Licenses
  extends VuexModule<Licenses.State, Root.State>
  implements Licenses.State
{
  items: License[] = [];
  lastEvaluatedKey: ZephyrWeb.Pagination.PlacementKey | null = null;
  allResultsLoaded = false;

  /**
   * ...
   */
  get get() {
    return (id: string) => find(this.items, { id });
  }

  // region Mutations

  /**
   * ...
   */
  @Mutation
  SET(options: Licenses.SetLicensesMutationOptions) {
    this.items = options.data;
  }

  /**
   * ...
   */
  @Mutation
  CLEAR() {
    this.items = [];
  }

  /**
   * ...
   */
  @Mutation
  SET_LAST_EVALUATED_KEY(options: {
    lastEvaluatedKey: ZephyrWeb.Pagination.PlacementKey | null;
  }) {
    this.lastEvaluatedKey = options.lastEvaluatedKey;
  }

  // endregion Mutations

  // region Actions

  /**
   * ...
   */
  @Action
  async list() {
    const data = await api.licenses.list();

    this.context.commit('SET', { data });

    return this.items;
  }
  /**
   * ...
   */
  @Action
  async loadPage(options: TablePanel.LoadPageOptions<License>) {
    const searchOptions: LicenseSearch.Options = {
      limit: 25,
    };
    if (isString(options.filter.contains)) {
      searchOptions.licenseKey = options.filter.contains;
      searchOptions.productId = options.filter.contains;
      searchOptions.userId = options.filter.contains;
    }
    // Disabled for now -- `options.filter.equals` does not have the proper
    // interface to be compatible with `searchOptions.equals`.
    // if (isString(options.filter.equals)) {
    //   searchOptions.equals = options.filter.equals;
    // }

    let data: License[];

    if (this.lastEvaluatedKey && !options.clearPrevious) {
      searchOptions.startKey = this.lastEvaluatedKey;
      data = [...this.items];
    } else {
      data = [];
    }

    let res: ZephyrWeb.Pagination.Results<License> | null = null;

    if (auth.isActiveRole(RoleId.LasAdmin)) {
      res = await api.licenses.search({
        ...searchOptions,
      });
    }

    data.push(...(res?.items ?? []));

    this.context.commit('SET', { data });
    this.context.commit('SET_LAST_EVALUATED_KEY', {
      lastEvaluatedKey: res?.lastEvaluatedKey ?? null,
    });

    return res;
  }

  /**
   * ...
   */
  @Action
  async create(options: Licenses.CreateLicenseActionOptions) {
    await api.licenses.create(options);

    await this.context.dispatch('list');
  }

  // endregion Actions
}

export namespace Licenses {
  /**
   * ...
   */
  export interface State {
    items: License[];
  }

  /**
   * ...
   */
  export interface SetLicensesMutationOptions {
    data: License[];
  }

  /** ... */
  export type CreateLicenseActionOptions = api.licenses.CreateOptions;
}

export default Licenses;
