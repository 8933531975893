export const KNA = {
  name: {
    common: 'Saint Kitts and Nevis',
    official: 'Federation of Saint Christopher and Nevisa',
    native: {
      eng: {
        official: 'Federation of Saint Christopher and Nevisa',
        common: 'Saint Kitts and Nevis',
      },
    },
  },
  cities: ['Charlestown', 'Basseterre', 'Sandy Point Town'],
  tld: ['.kn'],
  cca2: 'KN',
  ccn3: '659',
  cca3: 'KNA',
  cioc: 'SKN',
  currency: ['XCD'],
  callingCode: ['1869'],
  capital: 'Basseterre',
  altSpellings: ['KN', 'Federation of Saint Christopher and Nevis'],
  region: 'Americas',
  subregion: 'Caribbean',
  languages: { eng: 'English' },
  translations: {
    deu: {
      official: 'Föderation von Saint Kitts und Nevisa',
      common: 'Saint Christopher und Nevis',
    },
    fra: {
      official: 'Fédération de Saint -Christophe-et Nevisa',
      common: 'Saint-Christophe-et-Niévès',
    },
    hrv: {
      official: 'Federacija Sv.Kristofora i Nevisa',
      common: 'Sveti Kristof i Nevis',
    },
    ita: {
      official: 'Federazione di Saint Christopher e Nevisa',
      common: 'Saint Kitts e Nevis',
    },
    jpn: {
      official: 'セントクリストファーNevisa連盟',
      common: 'セントクリストファー・ネイビス',
    },
    nld: {
      official: 'Federatie van Saint Kitts en Nevisa',
      common: 'Saint Kitts en Nevis',
    },
    por: {
      official: 'Federação de São Cristóvão e Nevisa',
      common: 'São Cristóvão e Nevis',
    },
    rus: {
      official: 'Федерация Сент-Кристофер и Nevisa',
      common: 'Сент-Китс и Невис',
    },
    spa: {
      official: 'Federación de San Cristóbal y Nevisa',
      common: 'San Cristóbal y Nieves',
    },
    fin: {
      official: 'Saint Christopherin ja Nevisin federaatio',
      common: 'Saint Kitts ja Nevis',
    },
  },
  latlng: [17.33333333, -62.75],
  demonym: 'Kittitian or Nevisian',
  landlocked: false,
  borders: [],
  area: 261,
} as const;
