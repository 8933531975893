export const LBN = {
  name: {
    common: 'Lebanon',
    official: 'Lebanese Republic',
    native: {
      ara: { official: 'الجمهورية اللبنانية', common: 'لبنان' },
      fra: { official: 'République libanaise', common: 'Liban' },
    },
  },
  cities: [
    'Beirut',
    'Zgharta',
    'Bsalim',
    'Halba',
    'Ashrafiye',
    'Sidon',
    'Dik el Mehdi',
    'Baalbek',
    'Tripoli',
    'Baabda',
    'Adma',
    'Hboub',
    'Yanar',
    'Dbaiye',
    'Aaley',
    'Broummana',
    'Sarba',
    'Chekka',
  ],
  tld: ['.lb'],
  cca2: 'LB',
  ccn3: '422',
  cca3: 'LBN',
  cioc: 'LIB',
  currency: ['LBP'],
  callingCode: ['961'],
  capital: 'Beirut',
  altSpellings: ['LB', 'Lebanese Republic', 'Al-Jumhūrīyah Al-Libnānīyah'],
  region: 'Asia',
  subregion: 'Western Asia',
  languages: { ara: 'Arabic', fra: 'French' },
  translations: {
    deu: { official: 'Libanesische Republik', common: 'Libanon' },
    fra: { official: 'République libanaise', common: 'Liban' },
    hrv: { official: 'Libanonska Republika', common: 'Libanon' },
    ita: { official: 'Repubblica libanese', common: 'Libano' },
    jpn: { official: 'レバノン共和国', common: 'レバノン' },
    nld: { official: 'Libanese Republiek', common: 'Libanon' },
    por: { official: 'República Libanesa', common: 'Líbano' },
    rus: { official: 'Ливанская Республика', common: 'Ливан' },
    spa: { official: 'República Libanesa', common: 'Líbano' },
    fin: { official: 'Libanonin tasavalta', common: 'Libanon' },
  },
  latlng: [33.83333333, 35.83333333],
  demonym: 'Lebanese',
  landlocked: false,
  borders: ['ISR', 'SYR'],
  area: 10452,
  provinces: [
    'Beyrouth',
    'Ech Chimal',
    'Ej Jnoub',
    'El Bekaa',
    'Jabal Loubnane',
  ],
  nativeName: 'لبنان',
  timezones: ['UTC+02:00'],
} as const;
