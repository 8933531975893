export const JAM = {
  name: {
    common: 'Jamaica',
    official: 'Jamaica',
    native: {
      eng: { official: 'Jamaica', common: 'Jamaica' },
      jam: { official: 'Jamaica', common: 'Jamaica' },
    },
  },
  cities: [
    'Runaway Bay',
    'Kingston',
    'Spanish Town',
    'Montego Bay',
    'Portmore',
    'Gregory Park',
    'Gordon Town',
    'Mandeville',
    'May Pen',
    'Ocho Rios',
    'Portland Cottage',
    'Old Harbour',
    'Negril',
    'Port Maria',
    'Browns Town',
    'Moneague',
    'Black River',
  ],
  tld: ['.jm'],
  cca2: 'JM',
  ccn3: '388',
  cca3: 'JAM',
  cioc: 'JAM',
  currency: ['JMD'],
  callingCode: ['1876'],
  capital: 'Kingston',
  altSpellings: ['JM'],
  region: 'Americas',
  subregion: 'Caribbean',
  languages: { eng: 'English', jam: 'Jamaican Patois' },
  translations: {
    deu: { official: 'Jamaika', common: 'Jamaika' },
    fra: { official: 'Jamaïque', common: 'Jamaïque' },
    hrv: { official: 'Jamajka', common: 'Jamajka' },
    ita: { official: 'Giamaica', common: 'Giamaica' },
    jpn: { official: 'ジャマイカ', common: 'ジャマイカ' },
    nld: { official: 'Jamaica', common: 'Jamaica' },
    por: { official: 'Jamaica', common: 'Jamaica' },
    rus: { official: 'Ямайка', common: 'Ямайка' },
    spa: { official: 'Jamaica', common: 'Jamaica' },
    fin: { official: 'Jamaika', common: 'Jamaika' },
  },
  latlng: [18.25, -77.5],
  demonym: 'Jamaican',
  landlocked: false,
  borders: [],
  area: 10991,
  provinces: [
    'Clarendon',
    'Hanover',
    'Kingston',
    'Manchester',
    'Portland',
    'Saint Andrew',
    'Saint Ann',
    'Saint Catherine',
    'Saint Elizabeth',
    'Saint James',
    'Saint Mary',
    'Saint Thomas',
    'Trelawny',
    'Westmoreland',
  ],
  nativeName: 'Jamaica',
  timezones: ['UTC−05:00'],
} as const;
