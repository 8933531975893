/**
 * Basic user info.
 */
export interface UserInfo {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
}

/**
 * Generates a user label for displaying basic user info.
 *
 * @param info User info.
 * @param includeEmail If `true`, the user's email will be included in the label
 * if provided.
 * @param lastNameFirst If `true`, the user's last name will be positioned
 * first.
 * @returns A user label.
 */
export function userLabelFilter(
  info: UserInfo,
  includeEmail = true,
  lastNameFirst = true,
) {
  const names: string[] = [];

  if (info.firstName) names.push(info.firstName);
  if (info.lastName) names.push(info.lastName);

  let label =
    (lastNameFirst ? names.reverse().join(', ') : names.join(' ')) ||
    '(No Name)';

  if (includeEmail && info.email) {
    label += ` | ${info.email}`;
  }

  return label;
}

export default userLabelFilter;
