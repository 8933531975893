import { Module } from '@vuex/decorators';
import { TablePanel } from 'table-panel';

import { faEye } from '@icons/solid/faEye';
import { faHand } from '@icons/solid/faHand';
import { faFileMagnifyingGlass } from '@icons/solid/faFileMagnifyingGlass';

import { Certification } from '@models';
import { modals } from '@modals';
import { computeDisplayName } from '@utils/user';

/** ... */
const columns: TablePanel.Column<Certification>[] = [
  {
    key: 'productName',
    label: 'Product Name',
    value: ({ product }) => product.name,
  },
  {
    key: 'status',
    label: 'Status',
    value: 'status',
    component: 'TableCellCertificationStatus',
  },
  {
    key: 'lastAction',
    label: 'Last Action',
    component: 'TableCellCertificationLastAction',
    value: (item) => {
      return item.approvedAt ?? item.reattemptPromptAt ?? item.deniedAt ?? null;
    },
  },
  {
    key: 'user',
    label: 'User',
    value: (item) => computeDisplayName(item.user),
  },
  {
    key: 'reviewer',
    label: 'Reviewer',
    value: ({ reviewer }) => (reviewer ? computeDisplayName(reviewer) : null),
  },
];

/** ... */
const operations: TablePanel.Row.Operation<Certification>[] = [
  {
    label: 'View',
    icon: faEye,
    fn: ({ id }) => {
      void modals.certifications.view({ certificationId: id });
    },
  },
  {
    label: 'Claim',
    icon: faHand,
    fn: ({ id }) => {
      void modals.certifications.reviewAttempt({ certificationId: id });
    },
    hidden: ({ status }) => {
      return status !== 'AWAITING_REVIEW';
    },
  },
  {
    label: 'Review',
    icon: faFileMagnifyingGlass,
    fn: ({ id }) => {
      void modals.certifications.reviewAttempt({ certificationId: id });
    },
    hidden: ({ status, reviewer }, activeUser) => {
      return status !== 'REVIEWING' || reviewer?.id !== activeUser.id;
    },
  },
];

@Module({ namespaced: true })
export class ReviewerCertificationsTablePanel
  extends TablePanel<Certification>
  implements TablePanel.Props<Certification>
{
  readonly module = 'certifications';
  readonly loadAction = 'listAvailable';
  readonly pk = 'id';
  readonly label = 'USER CERTIFICATIONS';
  readonly columns = columns;
  readonly operations = operations;

  /** ... */
  get enabled() {
    return this.isActiveRole(8, 9);
  }
}
