import { server } from '@api/request';
import { Drone } from '@models';
import { isObject } from '@tools/type-guards';

/**
 * List all existing drones.
 *
 * @return A list of drones.
 */
export async function list() {
  return await server.list('v2/admin/drones', processDroneData);
}

/**
 * ...
 */
export interface GetOptions {
  droneId: Drone['id'];
}

/**
 * Get a specified drone.
 *
 * @param options Request options bag.
 * @return The specified drone.
 */
export async function get(options: GetOptions) {
  return await server.get(
    `v2/admin/drones/${options.droneId}`,
    processDroneData,
  );
}

/**
 * ...
 */
export interface CreateOptions {
  id: string;
  name: string;
  manufacturer: string;
  model: string;
  active: boolean;
  image?: ZephyrWeb.ImageInfo;
}

/**
 * Create a drone.
 *
 * @param options Request options bag.
 */
export async function create(options: CreateOptions) {
  return await server.post(`v2/admin/drones`, options);
}

/**
 * ...
 */
export interface UpdateOptions {
  droneId: Drone['id'];
  name?: string;
  active?: boolean;
  image?: ZephyrWeb.ImageInfo;
  manufacturer?: string;
  model?: string;
}

/**
 * Update a specified drone.
 *
 * @param options Request options bag.
 */
export async function update(options: UpdateOptions) {
  const { droneId, ...data } = options;

  return await server.post(`v2/admin/drones/${droneId}`, data);
}

/**
 * ...
 */
export interface DeleteOptions {
  droneId: Drone['id'];
}

/**
 * Delete a specified drone.
 *
 * @param options Request options bag.
 */
export async function del(options: DeleteOptions) {
  return await server.delete(`v2/admin/drones/${options.droneId}`);
}

//#region Helper Functions

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
function isValidDroneData(value: unknown): value is Drone.Attributes {
  if (!isObject(value)) return false;

  // TEMP: Use a more strict validation.

  return true;
}

/**
 * ...
 *
 * @param data ...
 * @return ...
 */
function processDroneData(data: unknown) {
  if (!isValidDroneData(data)) {
    throw new Error('Invalid drone data item.');
  }

  return { ...data } as Drone;
}

//#endregion Helper Functions
