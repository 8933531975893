export const LIE = {
  name: {
    common: 'Liechtenstein',
    official: 'Principality of Liechtenstein',
    native: {
      deu: { official: 'Fürstentum Liechtenstein', common: 'Liechtenstein' },
    },
  },
  cities: [
    'Mauren',
    'Eschen',
    'Nendeln',
    'Vaduz',
    'Schellenberg',
    'Planken',
    'Triesen',
    'Balzers',
    'Triesenberg',
    'Schaan',
    'Gamprin',
    'Ruggell',
    'Bendern',
    'Schaanwald',
  ],
  tld: ['.li'],
  cca2: 'LI',
  ccn3: '438',
  cca3: 'LIE',
  cioc: 'LIE',
  currency: ['CHF'],
  callingCode: ['423'],
  capital: 'Vaduz',
  altSpellings: [
    'LI',
    'Principality of Liechtenstein',
    'Fürstentum Liechtenstein',
  ],
  region: 'Europe',
  subregion: 'Western Europe',
  languages: { deu: 'German' },
  translations: {
    deu: { official: 'Fürstentum Liechtenstein', common: 'Liechtenstein' },
    fra: { official: 'Principauté du Liechtenstein', common: 'Liechtenstein' },
    hrv: { official: 'Kneževina Lihtenštajn', common: 'Lihtenštajn' },
    ita: { official: 'Principato del Liechtenstein', common: 'Liechtenstein' },
    jpn: { official: 'リヒテンシュタイン公国', common: 'リヒテンシュタイン' },
    nld: { official: 'Vorstendom Liechtenstein', common: 'Liechtenstein' },
    por: { official: 'Principado de Liechtenstein', common: 'Liechtenstein' },
    rus: { official: 'Княжество Лихтенштейн', common: 'Лихтенштейн' },
    spa: { official: 'Principado de Liechtenstein', common: 'Liechtenstein' },
    fin: { official: 'Liechensteinin ruhtinaskunta', common: 'Liechenstein' },
  },
  latlng: [47.26666666, 9.53333333],
  demonym: 'Liechtensteiner',
  landlocked: true,
  borders: ['AUT', 'CHE'],
  area: 160,
} as const;
