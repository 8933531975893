import { render, staticRenderFns } from "./OverlayLoading.vue?vue&type=template&id=04a1705f&scoped=true"
import script from "./OverlayLoading.vue?vue&type=script&setup=true&lang=ts"
export * from "./OverlayLoading.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./OverlayLoading.vue?vue&type=style&index=0&id=04a1705f&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04a1705f",
  null
  
)

export default component.exports