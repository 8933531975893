import { createIdentifierMap } from '@tools/create-identifier-map';

export interface CurrencyInfo {
  /**
   * Value used for currency info lookup.
   */
  id: string;
  /**
   * Official ISO 4217 code of the currency.
   */
  code: string;
  /**
   * Conversion for the currency's primary unit to it's smallest unit.
   */
  unitConversion: number;
}

const CURRENCIES = [
  {
    id: 'usd',
    code: 'USD',
    unitConversion: 0.01,
  },
  {
    id: 'aed',
    code: 'AED',
    unitConversion: 0.01,
  },
  {
    id: 'afn',
    code: 'AFN',
    unitConversion: 0.01,
  },
  {
    id: 'all',
    code: 'ALL',
    unitConversion: 0.01,
  },
  {
    id: 'amd',
    code: 'AMD',
    unitConversion: 0.01,
  },
  {
    id: 'ang',
    code: 'ANG',
    unitConversion: 0.01,
  },
  {
    id: 'aoa',
    code: 'AOA',
    unitConversion: 0.01,
  },
  {
    id: 'ars',
    code: 'ARS',
    unitConversion: 0.01,
  },
  {
    id: 'aud',
    code: 'AUD',
    unitConversion: 0.01,
  },
  {
    id: 'awg',
    code: 'AWG',
    unitConversion: 0.01,
  },
  {
    id: 'azn',
    code: 'AZN',
    unitConversion: 0.01,
  },
  {
    id: 'bam',
    code: 'BAM',
    unitConversion: 0.01,
  },
  {
    id: 'bbd',
    code: 'BBD',
    unitConversion: 0.01,
  },
  {
    id: 'bdt',
    code: 'BDT',
    unitConversion: 0.01,
  },
  {
    id: 'bgn',
    code: 'BGN',
    unitConversion: 0.01,
  },
  {
    id: 'bif',
    code: 'BIF',
    unitConversion: 1,
  },
  {
    id: 'bmd',
    code: 'BMD',
    unitConversion: 0.01,
  },
  {
    id: 'bnd',
    code: 'BND',
    unitConversion: 0.01,
  },
  {
    id: 'bob',
    code: 'BOB',
    unitConversion: 0.01,
  },
  {
    id: 'brl',
    code: 'BRL',
    unitConversion: 0.01,
  },
  {
    id: 'bsd',
    code: 'BSD',
    unitConversion: 0.01,
  },
  {
    id: 'bwp',
    code: 'BWP',
    unitConversion: 0.01,
  },
  {
    id: 'byn',
    code: 'BYN',
    unitConversion: 0.01,
  },
  {
    id: 'bzd',
    code: 'BZD',
    unitConversion: 0.01,
  },
  {
    id: 'cad',
    code: 'CAD',
    unitConversion: 0.01,
  },
  {
    id: 'cdf',
    code: 'CDF',
    unitConversion: 0.01,
  },
  {
    id: 'chf',
    code: 'CHF',
    unitConversion: 0.01,
  },
  {
    id: 'clp',
    code: 'CLP',
    unitConversion: 1,
  },
  {
    id: 'cny',
    code: 'CNY',
    unitConversion: 0.01,
  },
  {
    id: 'cop',
    code: 'COP',
    unitConversion: 0.01,
  },
  {
    id: 'crc',
    code: 'CRC',
    unitConversion: 0.01,
  },
  {
    id: 'cve',
    code: 'CVE',
    unitConversion: 0.01,
  },
  {
    id: 'czk',
    code: 'CZK',
    unitConversion: 0.01,
  },
  {
    id: 'djf',
    code: 'DJF',
    unitConversion: 1,
  },
  {
    id: 'dkk',
    code: 'DKK',
    unitConversion: 0.01,
  },
  {
    id: 'dop',
    code: 'DOP',
    unitConversion: 0.01,
  },
  {
    id: 'dzd',
    code: 'DZD',
    unitConversion: 0.01,
  },
  {
    id: 'egp',
    code: 'EGP',
    unitConversion: 0.01,
  },
  {
    id: 'etb',
    code: 'ETB',
    unitConversion: 0.01,
  },
  {
    id: 'eur',
    code: 'EUR',
    unitConversion: 0.01,
  },
  {
    id: 'fjd',
    code: 'FJD',
    unitConversion: 0.01,
  },
  {
    id: 'fkp',
    code: 'FKP',
    unitConversion: 0.01,
  },
  {
    id: 'gbp',
    code: 'GBP',
    unitConversion: 0.01,
  },
  {
    id: 'gel',
    code: 'GEL',
    unitConversion: 0.01,
  },
  {
    id: 'gip',
    code: 'GIP',
    unitConversion: 0.01,
  },
  {
    id: 'gmd',
    code: 'GMD',
    unitConversion: 0.01,
  },
  {
    id: 'gnf',
    code: 'GNF',
    unitConversion: 1,
  },
  {
    id: 'gtq',
    code: 'GTQ',
    unitConversion: 0.01,
  },
  {
    id: 'gyd',
    code: 'GYD',
    unitConversion: 0.01,
  },
  {
    id: 'hkd',
    code: 'HKD',
    unitConversion: 0.01,
  },
  {
    id: 'hnl',
    code: 'HNL',
    unitConversion: 0.01,
  },
  {
    id: 'htg',
    code: 'HTG',
    unitConversion: 0.01,
  },
  {
    id: 'huf',
    code: 'HUF',
    unitConversion: 0.01,
  },
  {
    id: 'idr',
    code: 'IDR',
    unitConversion: 0.01,
  },
  {
    id: 'ils',
    code: 'ILS',
    unitConversion: 0.01,
  },
  {
    id: 'inr',
    code: 'INR',
    unitConversion: 0.01,
  },
  {
    id: 'isk',
    code: 'ISK',
    unitConversion: 0.01,
  },
  {
    id: 'jmd',
    code: 'JMD',
    unitConversion: 0.01,
  },
  {
    id: 'jpy',
    code: 'JPY',
    unitConversion: 1,
  },
  {
    id: 'kes',
    code: 'KES',
    unitConversion: 0.01,
  },
  {
    id: 'kgs',
    code: 'KGS',
    unitConversion: 0.01,
  },
  {
    id: 'khr',
    code: 'KHR',
    unitConversion: 0.01,
  },
  {
    id: 'kmf',
    code: 'KMF',
    unitConversion: 0.01,
  },
  {
    id: 'krw',
    code: 'KRW',
    unitConversion: 1,
  },
  {
    id: 'kyd',
    code: 'KYD',
    unitConversion: 0.01,
  },
  {
    id: 'kzt',
    code: 'KZT',
    unitConversion: 0.01,
  },
  {
    id: 'lak',
    code: 'LAK',
    unitConversion: 0.01,
  },
  {
    id: 'lbp',
    code: 'LBP',
    unitConversion: 0.01,
  },
  {
    id: 'lkr',
    code: 'LKR',
    unitConversion: 0.01,
  },
  {
    id: 'lrd',
    code: 'LRD',
    unitConversion: 0.01,
  },
  {
    id: 'lsl',
    code: 'LSL',
    unitConversion: 0.01,
  },
  {
    id: 'mad',
    code: 'MAD',
    unitConversion: 0.01,
  },
  {
    id: 'mdl',
    code: 'MDL',
    unitConversion: 0.01,
  },
  {
    id: 'mga',
    code: 'MGA',
    unitConversion: 0.2,
  },
  {
    id: 'mkd',
    code: 'MKD',
    unitConversion: 0.01,
  },
  {
    id: 'mmk',
    code: 'MMK',
    unitConversion: 0.01,
  },
  {
    id: 'mnt',
    code: 'MNT',
    unitConversion: 0.01,
  },
  {
    id: 'mop',
    code: 'MOP',
    unitConversion: 0.01,
  },
  {
    id: 'mur',
    code: 'MUR',
    unitConversion: 0.01,
  },
  {
    id: 'mvr',
    code: 'MVR',
    unitConversion: 0.01,
  },
  {
    id: 'mwk',
    code: 'MWK',
    unitConversion: 0.01,
  },
  {
    id: 'mxn',
    code: 'MXN',
    unitConversion: 0.01,
  },
  {
    id: 'myr',
    code: 'MYR',
    unitConversion: 0.01,
  },
  {
    id: 'mzn',
    code: 'MZN',
    unitConversion: 0.01,
  },
  {
    id: 'nad',
    code: 'NAD',
    unitConversion: 0.01,
  },
  {
    id: 'ngn',
    code: 'NGN',
    unitConversion: 0.01,
  },
  {
    id: 'nio',
    code: 'NIO',
    unitConversion: 0.01,
  },
  {
    id: 'nok',
    code: 'NOK',
    unitConversion: 0.01,
  },
  {
    id: 'npr',
    code: 'NPR',
    unitConversion: 0.01,
  },
  {
    id: 'nzd',
    code: 'NZD',
    unitConversion: 0.01,
  },
  {
    id: 'pab',
    code: 'PAB',
    unitConversion: 0.01,
  },
  {
    id: 'pen',
    code: 'PEN',
    unitConversion: 0.01,
  },
  {
    id: 'pgk',
    code: 'PGK',
    unitConversion: 0.01,
  },
  {
    id: 'php',
    code: 'PHP',
    unitConversion: 0.01,
  },
  {
    id: 'pkr',
    code: 'PKR',
    unitConversion: 0.01,
  },
  {
    id: 'pln',
    code: 'PLN',
    unitConversion: 0.01,
  },
  {
    id: 'pyg',
    code: 'PYG',
    unitConversion: 0.01,
  },
  {
    id: 'qar',
    code: 'QAR',
    unitConversion: 0.01,
  },
  {
    id: 'ron',
    code: 'RON',
    unitConversion: 0.01,
  },
  {
    id: 'rsd',
    code: 'RSD',
    unitConversion: 0.01,
  },
  {
    id: 'rub',
    code: 'RUB',
    unitConversion: 0.01,
  },
  {
    id: 'rwf',
    code: 'RWF',
    unitConversion: 1,
  },
  {
    id: 'sar',
    code: 'SAR',
    unitConversion: 0.01,
  },
  {
    id: 'sbd',
    code: 'SBD',
    unitConversion: 0.01,
  },
  {
    id: 'scr',
    code: 'SCR',
    unitConversion: 0.01,
  },
  {
    id: 'sek',
    code: 'SEK',
    unitConversion: 0.01,
  },
  {
    id: 'sgd',
    code: 'SGD',
    unitConversion: 0.01,
  },
  {
    id: 'shp',
    code: 'SHP',
    unitConversion: 0.01,
  },
  {
    id: 'sle',
    code: 'SLE',
    unitConversion: 0.01,
  },
  {
    id: 'sos',
    code: 'SOS',
    unitConversion: 0.01,
  },
  {
    id: 'srd',
    code: 'SRD',
    unitConversion: 0.01,
  },
  {
    id: 'std',
    code: 'STD',
    unitConversion: 0.01,
  },
  {
    id: 'szl',
    code: 'SZL',
    unitConversion: 0.01,
  },
  {
    id: 'thb',
    code: 'THB',
    unitConversion: 0.01,
  },
  {
    id: 'tjs',
    code: 'TJS',
    unitConversion: 0.01,
  },
  {
    id: 'top',
    code: 'TOP',
    unitConversion: 0.01,
  },
  {
    id: 'try',
    code: 'TRY',
    unitConversion: 0.01,
  },
  {
    id: 'ttd',
    code: 'TTD',
    unitConversion: 0.01,
  },
  {
    id: 'twd',
    code: 'TWD',
    unitConversion: 0.01,
  },
  {
    id: 'tzs',
    code: 'TZS',
    unitConversion: 0.01,
  },
  {
    id: 'uah',
    code: 'UAH',
    unitConversion: 0.01,
  },
  {
    id: 'ugx',
    code: 'UGX',
    unitConversion: 0.01,
  },
  {
    id: 'uyu',
    code: 'UYU',
    unitConversion: 0.01,
  },
  {
    id: 'uzs',
    code: 'UZS',
    unitConversion: 0.01,
  },
  {
    id: 'vnd',
    code: 'VND',
    unitConversion: 0.01,
  },
  {
    id: 'vuv',
    code: 'VUV',
    unitConversion: 1,
  },
  {
    id: 'wst',
    code: 'WST',
    unitConversion: 0.01,
  },
  {
    id: 'xaf',
    code: 'XAF',
    unitConversion: 1,
  },
  {
    id: 'xcd',
    code: 'XCD',
    unitConversion: 0.01,
  },
  {
    id: 'xof',
    code: 'XOF',
    unitConversion: 1,
  },
  {
    id: 'xpf',
    code: 'XPF',
    unitConversion: 1,
  },
  {
    id: 'yer',
    code: 'YER',
    unitConversion: 0.01,
  },
  {
    id: 'zar',
    code: 'ZAR',
    unitConversion: 0.01,
  },
  {
    id: 'zmw',
    code: 'ZMW',
    unitConversion: 0.01,
  },
] as const satisfies CurrencyInfo[];

export type Currency = (typeof CURRENCIES)[number]['id'];

/**
 * Record containing information for all supported currencies.
 */
export const currencies = createIdentifierMap<CurrencyInfo, Currency>(
  CURRENCIES,
);
