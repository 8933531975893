import { Module, VuexModule, Mutation, Action } from '@vuex/decorators';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';

import { api } from '@api';
import { Course } from '@models';
import { auth } from '@auth';
import { Root } from '@store';
import { isString } from '@tools/type-guards';

declare module '@vuex/core' {
  export interface Getters {
    'courses/getById': Courses['getById'];
    'courses/getCourseStatus': Courses['getCourseStatus'];
  }

  export interface CommitMap {
    'courses/SET': Courses['SET'];
    'courses/ADD': Courses['ADD'];
    'courses/UPDATE': Courses['UPDATE'];
    'courses/DELETE': Courses['DELETE'];
    'courses/CLEAR': Courses['CLEAR'];
  }

  export interface DispatchMap {
    'courses/list': Courses['list'];
    'courses/get': Courses['get'];
    'courses/create': Courses['create'];
    'courses/addStudents': Courses['addStudents'];
    'courses/update': Courses['update'];
    'courses/del': Courses['del'];
  }
}

@Module({ namespaced: true })
export class Courses
  extends VuexModule<Courses.State, Root.State>
  implements Courses.State
{
  items: Course[] = [];

  /** ... */
  get getById() {
    return (id: Course['id']) => find(this.items, { id });
  }

  /** ... */
  get getCourseStatus() {
    return (id: Course['id']) => {
      const course = this.getById(id);

      if (!course) {
        throw new Error(`Course with ID "${id}" not found.`);
      }

      const now = Date.now();

      if (now < new Date(course.startDate).getTime()) {
        return 'UPCOMING';
      }

      if (now > new Date(course.endDate).getTime()) {
        return 'ENDED';
      }

      return 'IN_PROGRESS';
    };
  }

  //#region Mutations

  /**
   * ...
   */
  @Mutation
  SET(options: Courses.SetMutationOptions) {
    this.items = options.data;
  }

  /**
   * ...
   */
  @Mutation
  ADD(options: Courses.AddMutationOptions) {
    const items = [...this.items];

    const i = findIndex(items, { id: options.id });

    if (i !== -1) {
      items[i] = options;
    } else {
      items.push(options);
    }

    this.items = items;
  }

  /**
   * ...
   */
  @Mutation
  UPDATE(options: Courses.UpdateMutationOptions) {
    const items = [...this.items];

    const i = findIndex(this.items, { id: options.courseId });

    if (i <= -1) {
      throw new Error(`Course with ID "${options.courseId}" not found.`);
    }

    for (const key in options) items[i][key] = options[key];

    this.items = items;
  }

  /**
   * ...
   */
  @Mutation
  DELETE(options: Courses.DeleteMutationOptions) {
    const index = findIndex(this.items, { id: options.courseId });

    if (index === -1) {
      throw new Error(`Course with ID "${options.courseId}" not found.`);
    }

    this.items = this.items.filter((_, i) => i !== index);
  }

  /**
   * ...
   */
  @Mutation
  CLEAR() {
    this.items = [];
  }

  //#endregion Mutations

  //#region Actions

  /**
   * ...
   */
  @Action
  async list() {
    const { me } = this.context.rootState;

    let data: Course[] = [];

    if (auth.isActiveRole(9)) {
      data = await api.courses.list();
    } else if (auth.isActiveRole(4, 5)) {
      const organizationId = me.organization?.id;

      if (!isString(organizationId)) {
        console.error(
          `Expected user account ID to be a string. Got ${typeof organizationId} instead`,
        );

        return null;
      }

      data = await api.courses.listByOrganization({ organizationId });
    }

    this.context.commit('SET', { data });

    return this.items;
  }

  /**
   * ...
   */
  @Action
  async get(options: Courses.GetActionOptions) {
    const data = await api.courses.get(options);

    this.context.commit('ADD', data);

    return data;
  }

  /**
   * ...
   */
  @Action
  async addStudents(options: Courses.AddStudentsActionOptions) {
    await api.courses.addStudents(options);

    const users = this.context.rootState.users.items;

    for (const userId of options.userIds ?? []) {
      const user = find(users, { id: userId });

      if (!user) continue;

      const { roles } = await api.users.getByOrganization({
        userId,
        organizationId: options.organizationId,
      });

      this.context.commit(
        'users/UPDATE',
        { id: userId, roles },
        { root: true },
      );
    }
  }

  /**
   * ...
   */
  @Action
  async create(options: Courses.CreateActionOptions) {
    const data = await api.courses.create(options);

    this.context.commit('ADD', data);

    return data;
  }

  /**
   * ...
   */
  @Action
  async update(options: Courses.UpdateActionOptions) {
    await api.courses.update(options);

    return (await this.context.dispatch('get', options)) as Course;
  }

  /**
   * ...
   */
  @Action
  async del(options: Courses.DeleteActionOptions) {
    await api.courses.del(options);

    this.context.commit('DELETE', options);
  }

  //#endregion Actions
}

export namespace Courses {
  /**
   * ...
   */
  export interface State {
    items: Course[];
  }

  /**
   * ...
   */
  export interface SetMutationOptions {
    data: Course[];
  }

  /** ... */
  export type AddMutationOptions = Course;
  /** ... */
  export type UpdateMutationOptions = api.courses.UpdateOptions;
  /** ... */
  export type DeleteMutationOptions = api.courses.DeleteOptions;
  /** ... */
  export type GetActionOptions = api.courses.GetOptions;
  /** ... */
  export type AddStudentsActionOptions = api.courses.AddStudentsOptions;
  /** ... */
  export type CreateActionOptions = api.courses.CreateOptions;
  /** ... */
  export type UpdateActionOptions = api.courses.UpdateOptions;
  /** ... */
  export type DeleteActionOptions = api.courses.DeleteOptions;
}

export default Courses;
