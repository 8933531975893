var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      class: [
        "modal-header",
        { minimal: _vm.minimal, fullscreen: _setup.modal.fullscreen },
      ],
    },
    [
      _setup.modal.title && !_vm.noTitle
        ? _c("h4", { staticClass: "flex-grow-1 h6 m-0" }, [
            _vm._v("\n    " + _vm._s(_setup.modal.title) + "\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.$env.isDevelopment
        ? [
            !_setup.modal.fullscreen
              ? _c(
                  "button",
                  {
                    attrs: { title: "Maximize modal" },
                    on: {
                      click: function ($event) {
                        return _setup.modal.toggleFullscreen()
                      },
                    },
                  },
                  [
                    _c("Icon", {
                      attrs: {
                        icon: _setup.faArrowUpRightAndArrowDownLeftFromCenter,
                      },
                    }),
                  ],
                  1
                )
              : _c(
                  "button",
                  {
                    attrs: { title: "Minimize modal" },
                    on: {
                      click: function ($event) {
                        return _setup.modal.toggleFullscreen()
                      },
                    },
                  },
                  [
                    _c("Icon", {
                      attrs: {
                        icon: _setup.faArrowDownLeftAndArrowUpRightToCenter,
                      },
                    }),
                  ],
                  1
                ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _setup.modal.dismissible
        ? _c(
            "button",
            {
              attrs: { title: "Close modal" },
              on: { click: _setup.modal.dismiss },
            },
            [_c("Icon", { attrs: { icon: _setup.faXmark } })],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }