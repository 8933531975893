export const FIN = {
  name: {
    common: 'Finland',
    official: 'Republic of Finland',
    native: {
      fin: { official: 'Suomen tasavalta', common: 'Suomi' },
      swe: { official: 'Republiken Finland', common: 'Finland' },
    },
  },
  cities: [
    'Helsinki',
    'Hamina',
    'Kotka',
    'Kuusankoski',
    'Lappeenranta',
    'Imatra',
    'Tampere',
    'Kuopio',
    'Rauma',
    'Eurajoki',
    'Oulu',
    'Parkano',
    'Kihnioe',
    'Pori',
    'Tyrnaevae',
    'Lahti',
    'Espoo',
    'Ulvila',
    'HÃ¤meenlinna',
    'Tuomikylae',
    'Hyvinkaeae',
    'Kankaanpaeae',
    'Nummela',
    'Nokia',
    'Turku',
    'Tuusula',
    'Vantaa',
    'JyvÃ¤skylÃ¤',
    'Marttila',
    'Tarvasjoki',
    'KÃ¤rsÃ¤mÃ¤ki',
    'Savonlinna',
    'Louhi',
    'VarpaisjÃ¤rvi',
    'Haukipudas',
    'Heinola',
    'Lohja',
    'Orimattila',
    'Nastola',
    'Vaeaeksy',
    'JÃ¤rvenpÃ¤Ã¤',
    'Larsmo',
    'Nykarleby',
    'Jakobstad',
    'Lepplax',
    'Littoinen',
    'BennÃ¤s',
    'Ruovesi',
    'Maenttae',
    'Virrat',
    'Valkeakoski',
    'Poikelus',
    'Halli',
    'Vilppula',
    'Hollola',
    'VillÃ¤hde',
    'Salo',
    'Kimito',
    'Somero',
    'Saunalahti',
    'YlÃ¶jÃ¤rvi',
    'Joensuu',
    'Vaasa',
    'Kajaani',
    'Vuokatti',
    'Outokumpu',
    'Peraseinajoki',
    'Hollola',
    'Porvoo',
    'Hammaslahti',
    'Kitee',
    'Kontiolahti',
    'Ylaemylly',
    'Pirkkala',
    'Rovaniemi',
    'Suomussalmi',
    'Kerava',
    'Kempele',
    'Kolinkylae',
    'Sotkuma',
    'PolvijÃ¤rvi',
    'Kauttua',
    'Sipoo',
    'Loviisa',
    'Tolkis',
    'PernÃ¥',
    'Porlammi',
    'Soederkulla',
    'LapinjÃ¤rvi',
    'Rautjaervi',
    'Anjala',
    'Kouvola',
    'Koria',
    'Neuvoton',
    'Ravijoki',
    'Iisalmi',
    'Kiuruvesi',
    'SonkajÃ¤rvi',
    'VieremÃ¤',
    'Lielax',
    'Pargas',
    'Hoegsara',
    'Kirjala',
    'Hiltulanlahti',
    'Kirkkonummi',
    'Mikkeli',
    'Oulunsalo',
    'Vammala',
    'Koskioinen',
    'Karkku',
    'Punkalaidun',
    'Pornainen',
    'Huuvari',
    'MonninkylÃ¤',
    'MyrskylÃ¤',
    'Hinthaara',
    'SÃ¤linkÃ¤Ã¤',
    'Kerkkoo',
    'MÃ¤ntsÃ¤lÃ¤',
    'Anttila',
    'Laukkoski',
    'Multia',
    'JÃ¤msÃ¤nkoski',
    'Tikkakoski',
    'Ã„Ã¤nekoski',
    'Muurame',
    'Jokela',
    'Taipalsaari',
    'Joutseno',
    'RiihimÃ¤ki',
    'JÃ¤Ã¤li',
    'Pattijoki',
    'Huittinen',
    'KokemÃ¤ki',
    'Soeoermarkku',
    'Kauvatsa',
    'Poitsila',
    'Kausala',
    'Valkeala',
    'Pilkanmaa',
    'Kauniainen',
    'Suolahti',
    'Keuruu',
    'JÃ¤msÃ¤',
    'Laukaa',
    'Purola',
    'Pockar',
    'Varkaus',
    'Harjavalta',
    'Naantali',
    'Raisio',
    'PieksÃ¤mÃ¤ki',
    'Puumala',
    'Kemi',
    'Tornio',
    'Ypaejae',
    'Aura',
    'Loimaa',
    'Kangasniemi',
    'Hirvensalmi',
    'Ristiina',
    'Rantasalmi',
    'Sotkamo',
    'Alavus',
    'Kokkola',
    'Terjaerv',
    'Kaustinen',
    'SeinÃ¤joki',
    'Kokkola',
    'Kaakamo',
    'Lautiosaari',
    'Viantie',
    'Kuivakangas',
    'Karunki',
    'Aavasaksa',
    'KemijÃ¤rvi',
    'Kolari',
    'Kaarina',
    'Merimasku',
    'Noormarkku',
    'Toijala',
    'Kaipiainen',
    'Linnavuori',
    'Aitoo',
    'Forssa',
    'Paelkaene',
    'Laitikkala',
    'Kylmaekoski',
    'Metsaekansa',
    'Erajarvi',
    'Viiala',
    'Tammela',
    'Tuulos',
    'Turenki',
    'Akaa',
    'Saaksmaki',
    'Riihikoski',
    'Utti',
    'RuotsinpyhtÃ¤Ã¤',
    'ElimÃ¤ki',
    'Gammelby',
    'Jurva',
    'Kaskinen',
    'Kurikka',
    'Teuva',
    'Laihia',
    'Kauhajoki',
    'Isojoki',
    'Niinijoki',
    'Vaskio',
    'Saarijaervi',
    'Viitasaari',
    'Niittylahti',
    'Nurmes',
    'Nunnanlahti',
    'Suhmura',
    'Taavetti',
    'Ruokolahti',
    'Savitaipale',
    'Sammaljoki',
    'Haijaa',
    'Roismala',
    'Mouhijaervi',
    'Kivijaervi',
    'KyyjÃ¤rvi',
    'Kinnula',
    'Pihtipudas',
    'Karstula',
    'Kannonkoski',
    'Humppila',
    'Latovainio',
    'Jokioinen',
    'Liljendal',
    'Korsholm',
    'NÃ¤rpes',
    'Toelby',
    'VÃ¶rÃ¥',
    'Seinaejoki',
    'Mantila',
    'Lapua',
    'Kuortane',
    'Tuuri',
    'Ã„htÃ¤ri',
    'Muhos',
    'Raahe',
    'Alavieska',
    'Ylivieska',
    'Nivala',
    'Pyhaejoki',
    'Kannus',
    'Kalajoki',
    'Sykaeraeinen',
    'Sievi',
    'Haapavesi',
    'Oulainen',
    'Lampinsaari',
    'Inkeroinen',
    'Klaukkala',
    'Nuppulinna',
    'SiilinjÃ¤rvi',
    'Uusikaupunki',
    'Kalanti',
    'Pyhamaa',
    'Vaulammi',
    'Halikko',
    'Kisko',
    'Kruusila',
    'Pertteli',
    'Pernioe',
    'Karkkila',
    'Linna',
    'Skinnarby',
    'Laitila',
    'Padasjoki',
    'Jaervelae',
    'Karinkanta',
    'Kiiminki',
    'Laeyliaeinen',
    'NurmijÃ¤rvi',
    'Sajaniemi',
    'HikiÃ¤',
    'Jyskae',
    'Vaajakoski',
    'Korplax',
    'HaapamÃ¤ki',
    'Keminmaa',
    'Ylitornio',
    'Aapajoki',
    'Kantomaanpaeae',
    'Simoniemi',
    'Lehtimaeki',
    'Evijaervi',
    'Okskoski',
    'Vimpeli',
    'Veteli',
    'Kauhava',
    'Lappajaervi',
    'LempÃ¤Ã¤lÃ¤',
    'Kangasala',
    'Paattinen',
    'Mynaemaeki',
    'PiikkiÃ¶',
    'RymÃ¤ttylÃ¤',
    'Vahto',
    'Tortinmaeki',
    'Masku',
    'Piispanristi',
    'Vanhalinna',
    'Nousiainen',
    'Lieto',
    'Kantvik',
    'Veikkola',
    'Masala',
    'YlÃ¤maa',
    'Laikko',
    'SÃ¤rkisalmi',
    'LuumÃ¤ki',
    'OripÃ¤Ã¤',
    'Alastaro',
    'Karttula',
    'Suonenjoki',
    'LeppÃ¤virta',
    'Lapinlahti',
    'HeinÃ¤vesi',
    'Punkaharju',
    'Maentyharju',
    'Kaipola',
    'PetÃ¤jÃ¤vesi',
    'Palokka',
    'Kyroeskoski',
    'Kyynaeroe',
    'Ii',
    'Pello',
    'Uurainen',
    'Valkola',
    'Lammi',
    'Hauho',
    'Levanto',
    'Kello',
    'Kontiomaeki',
    'Puolanka',
    'Venetheitto',
    'Jormua',
    'Saukkola',
    'Liminka',
    'Kronoby',
    'Alaveteli',
    'Sundby',
    'Munsala',
    'Pjelax',
    'Purmo',
    'Forsby',
    'KÃ¥llby',
    'Oja',
    'KÃ¤lviÃ¤',
    'Ilmajoki',
    'Naervijoki',
    'Ylihaermae',
    'IsokyrÃ¶',
    'Nurmo',
    'Siikainen',
    'Kantti',
    'Tervakoski',
    'Ilveskylae',
    'Kopsa',
    'Lohtaja',
    'Revonlahti',
    'Himanka',
    'Luohua',
    'Saloinen',
    'Mankila',
    'Toholampi',
    'Angelniemi',
    'Somerniemi',
    'Suomusjaervi',
    'Muurla',
    'Alvettula',
    'Sappee',
    'Hattula',
    'Bergoe',
    'KerimÃ¤ki',
    'Svarta',
    'Nakkila',
    'Ihode',
    'Vasarainen',
    'Saekylae',
    'Salomaa',
    'Martensby',
    'Kellokoski',
    'Sahalahti',
    'Launonen',
    'Kulho',
    'Solberg',
    'Kuusisto',
    'Kaivanto',
    'Lehmo',
    'Siivikkala',
    'Siuntio',
    'Narva',
    'Oitti',
    'Ryttylae',
    'Saynatsalo',
    'Loppi',
    'Hausjaervi',
    'Vesanka',
    'Kormu',
    'Paippinen',
    'Nummenkylae',
    'Paimio',
    'Sauvo',
    'Lievestuore',
    'Jorvas',
    'Siuro',
    'Tottijarvi',
    'Pukkila',
    'Vakkola',
    'Nummenkylae',
    'Hanko',
    'Pankakoski',
    'Lieksa',
    'Juuka',
    'Uimaharju',
    'Liperi',
    'EkenÃ¤s',
    'Simpele',
    'NilsiÃ¤',
    'Saeviae',
    'Lumijoki',
    'VikajÃ¤rvi',
    'Kiistala',
    'Peraelae',
    'SÃ¤Ã¤ksjÃ¤rvi',
    'Askola',
    'KÃ¶yliÃ¶',
    'Peipohja',
    'Hervanta',
    'Halsua',
    'Raseborg',
    'Karjaa',
    'Fiskars',
    'Karjalohja',
    'Tenala',
    'Viljakkala',
    'HÃ¤meenkyrÃ¶',
    'Vihti',
    'Hoeytiae',
    'Vehniae',
    'Nuutajaervi',
    'Nahkela',
    'RajamÃ¤ki',
    'Roeykkae',
    'Nukari',
    'Vihtavuori',
    'LeppÃ¤vesi',
    'Box',
    'Kaerrby',
    'Vaesterskog',
    'Kaemmenniemi',
    'Kuivaniemi',
    'Myllykoski',
    'Perttula',
    'MÃ¶nni',
    'Rusko',
    'Orivesi',
    'Juupajoki',
    'Degerby',
    'Pusula',
    'Poeytyae',
    'Ilmarinen',
    'Ruutana',
    'Jokikunta',
    'Tervalampi',
    'Otalampi',
    'Palojoki',
    'Kiljava',
    'Kuohu',
    'Evitskog',
    'Kuhmalahti',
    'Nummi',
    'LempÃ¤Ã¤',
    'IngÃ¥',
    'Toivala',
    'MerijÃ¤rvi',
    'HaapajÃ¤rvi',
    'Karhula',
    'Pellinki',
    'Korppoo',
    'Lahela',
    'Virolahden KirkonkylÃ¤',
    'Karvia',
    'Koskenkorva',
    'Valkama',
    'Tervajoki',
    'Kuni',
    'Sundom',
    'Kvevlax',
    'Ruto',
    'OEvermalax',
    'Helsingby',
    'Mieto',
    'Hirvihaara',
    'Skuru',
    'Kauhakorpi',
    'Tampella',
    'Pohjola',
    'Majvik',
    'Rautjaervi',
    'Utsjoki',
    'Muonio',
    'SodankylÃ¤',
    'Mattila',
    'Kuusamo',
    'Mehtaekylae',
    'Heimari',
    'Haeme',
    'Ilkka',
    'Ivalo',
    'Eura',
    'Parkkuu',
    'Tarttila',
    'Pyhaentae',
    'Joroinen',
    'PyhÃ¤salmi',
    'AlajÃ¤rvi',
    'Paalijaervi',
    'Luoma-aho',
    'Haukkala',
    'Itaekylae',
    'Koljonvirta',
    'Rautalampi',
    'Lemi',
    'Broby',
    'Kuhmo',
    'Eno',
    'Haermae',
    'RÃ¤Ã¤kkylÃ¤',
    'PyhtÃ¤Ã¤',
    'Iittala',
    'Harviala',
    'Janakkala',
    'Jokimaa',
    'Lepaa',
    'Riistavesi',
    'Halkia',
  ],
  tld: ['.fi'],
  cca2: 'FI',
  ccn3: '246',
  cca3: 'FIN',
  cioc: 'FIN',
  currency: ['EUR'],
  callingCode: ['358'],
  capital: 'Helsinki',
  altSpellings: [
    'FI',
    'Suomi',
    'Republic of Finland',
    'Suomen tasavalta',
    'Republiken Finland',
  ],
  region: 'Europe',
  subregion: 'Northern Europe',
  languages: { fin: 'Finnish', swe: 'Swedish' },
  translations: {
    deu: { official: 'Republik Finnland', common: 'Finnland' },
    fra: { official: 'République de Finlande', common: 'Finlande' },
    hrv: { official: 'Republika Finska', common: 'Finska' },
    ita: { official: 'Repubblica di Finlandia', common: 'Finlandia' },
    jpn: { official: 'フィンランド共和国', common: 'フィンランド' },
    nld: { official: 'Republiek Finland', common: 'Finland' },
    por: { official: 'República da Finlândia', common: 'Finlândia' },
    rus: { official: 'Финляндская Республика', common: 'Финляндия' },
    spa: { official: 'República de Finlandia', common: 'Finlandia' },
    fin: { official: 'Suomen tasavalta', common: 'Suomi' },
  },
  latlng: [64, 26],
  demonym: 'Finnish',
  landlocked: false,
  borders: ['NOR', 'SWE', 'RUS'],
  area: 338424,
  provinces: [
    'Aland',
    'Etela-Suomen Laani',
    'Ita-Suomen Laani',
    'Lansi-Suomen Laani',
    'Lappi',
    'Oulun Laani',
  ],
  nativeName: 'Suomi',
  timezones: ['UTC+02:00'],
} as const;
