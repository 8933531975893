import { Module, VuexModule, Mutation, Action } from '@vuex/decorators';
import find from 'lodash/find';

import { api } from '@api';
import { PromotionCode } from '@models';
import { Root } from '@store';

declare module '@vuex/core' {
  export interface Getters {
    'promotionCodes/findById': PromotionCodes['findById'];
  }

  export interface CommitMap {
    'promotionCodes/SET': PromotionCodes['SET'];
    'promotionCodes/CLEAR': PromotionCodes['CLEAR'];
  }

  export interface DispatchMap {
    'promotionCodes/list': PromotionCodes['list'];
  }
}

@Module({ namespaced: true })
export class PromotionCodes
  extends VuexModule<PromotionCodes.State, Root.State>
  implements PromotionCodes.State
{
  items: PromotionCode[] = [];

  get findById() {
    return (id: string) => find(this.items, { id });
  }

  //#region Mutations

  @Mutation
  SET(options: PromotionCodes.SetMutationOptions) {
    this.items = options.data;
  }

  @Mutation
  CLEAR() {
    this.items = [];
  }

  //#endregion Mutations

  //#region Actions

  @Action
  async list() {
    const data = await api.promotionCodes.list();

    this.context.commit('SET', { data });

    return this.items;
  }

  //#endregion Actions
}

export namespace PromotionCodes {
  /**
   * {@link PromotionCode} store state structure.
   */
  export interface State {
    items: PromotionCode[];
  }

  /**
   * {@link PromotionCode} store `SET` mutation options.
   */
  export interface SetMutationOptions {
    data: PromotionCode[];
  }
}

export default PromotionCodes;
