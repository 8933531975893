var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "Transition",
    { attrs: { appear: "" } },
    [
      _c(
        "b-card",
        {
          staticClass: "w-100",
          style: { transitionDelay: _setup.transitionDelay },
          attrs: { "no-body": "" },
        },
        [
          _c(
            "b-card-header",
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("div", [_vm._v("Order Placed")]),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "order-date" },
                      [
                        _c(_setup.DisplayTextDate, {
                          attrs: { value: _vm.order.createdAt },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("b-col", [
                    _c("div", [_vm._v("Total")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "order-amount" },
                      [
                        _c(_setup.DisplayTextCurrency, {
                          attrs: {
                            value: _vm.order.total,
                            currency: _vm.order.currency,
                            unit: "smallest",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("b-col", [
                    _c("div", [_vm._v("Status")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("DisplayText", {
                          attrs: { value: _vm.order.status, capitalize: "" },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.order.discountCode
                    ? _c("b-col", [
                        _c("div", [_vm._v("Discount")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "order-amount" },
                          [
                            _c(_setup.DisplayTextCurrency, {
                              attrs: {
                                value: _vm.order.discountTotal,
                                currency: _vm.order.currency,
                                unit: "smallest",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "link" },
                          on: { click: _setup.goToOrderPage },
                        },
                        [_vm._v("\n            See Details\n          ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-card-body",
            { staticClass: "p-0" },
            _vm._l(_vm.order.cart, function (item, i) {
              return _c(_setup.OrderCardItem, {
                key: item.id,
                class: { "border-bottom": i < _vm.order.cart.length - 1 },
                attrs: { "cart-item": item, currency: _vm.order.currency },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }