export const TJK = {
  name: {
    common: 'Tajikistan',
    official: 'Republic of Tajikistan',
    native: {
      rus: { official: 'Республика Таджикистан', common: 'Таджикистан' },
      tgk: { official: 'Ҷумҳурии Тоҷикистон', common: 'Тоҷикистон' },
    },
  },
  cities: ['Dushanbe', 'Khorugh'],
  tld: ['.tj'],
  cca2: 'TJ',
  ccn3: '762',
  cca3: 'TJK',
  cioc: 'TJK',
  currency: ['TJS'],
  callingCode: ['992'],
  capital: 'Dushanbe',
  altSpellings: [
    'TJ',
    'Toçikiston',
    'Republic of Tajikistan',
    'Ҷумҳурии Тоҷикистон',
    'Çumhuriyi Toçikiston',
  ],
  region: 'Asia',
  subregion: 'Central Asia',
  languages: { rus: 'Russian', tgk: 'Tajik' },
  translations: {
    deu: { official: 'Republik Tadschikistan', common: 'Tadschikistan' },
    fra: { official: 'République du Tadjikistan', common: 'Tadjikistan' },
    hrv: { official: 'Republika Tadžikistan', common: 'Tađikistan' },
    ita: { official: 'Repubblica del Tajikistan', common: 'Tagikistan' },
    jpn: { official: 'タジキスタン共和国', common: 'タジキスタン' },
    nld: { official: 'Tadzjikistan', common: 'Tadzjikistan' },
    por: { official: 'República do Tajiquistão', common: 'Tajiquistão' },
    rus: { official: 'Республика Таджикистан', common: 'Таджикистан' },
    spa: { official: 'República de Tayikistán', common: 'Tayikistán' },
    fin: { official: 'Tadžikistanin tasavalta', common: 'Tadžikistan' },
  },
  latlng: [39, 71],
  demonym: 'Tadzhik',
  landlocked: true,
  borders: ['AFG', 'CHN', 'KGZ', 'UZB'],
  area: 143100,
  provinces: [
    'Viloyati Khatlon',
    'Viloyati Leninobod',
    'Viloyati Mukhtori Kuhistoni Badakhshon',
  ],
  nativeName: 'Тоҷикистон',
  timezones: ['UTC+05:00'],
} as const;
