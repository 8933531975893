export const SLE = {
  name: {
    common: 'Sierra Leone',
    official: 'Republic of Sierra Leone',
    native: {
      eng: { official: 'Republic of Sierra Leone', common: 'Sierra Leone' },
    },
  },
  cities: ['Freetown'],
  tld: ['.sl'],
  cca2: 'SL',
  ccn3: '694',
  cca3: 'SLE',
  cioc: 'SLE',
  currency: ['SLL'],
  callingCode: ['232'],
  capital: 'Freetown',
  altSpellings: ['SL', 'Republic of Sierra Leone'],
  region: 'Africa',
  subregion: 'Western Africa',
  languages: { eng: 'English' },
  translations: {
    deu: { official: 'Republik Sierra Leone', common: 'Sierra Leone' },
    fra: { official: 'République de Sierra Leone', common: 'Sierra Leone' },
    hrv: { official: 'Republika Sijera Leone', common: 'Sijera Leone' },
    ita: { official: 'Repubblica della Sierra Leone', common: 'Sierra Leone' },
    jpn: { official: 'シエラレオネ共和国', common: 'シエラレオネ' },
    nld: { official: 'Republiek Sierra Leone', common: 'Sierra Leone' },
    por: { official: 'República da Serra Leoa', common: 'Serra Leoa' },
    rus: { official: 'Республика Сьерра-Леоне', common: 'Сьерра-Леоне' },
    spa: { official: 'República de Sierra Leona', common: 'Sierra Leone' },
    fin: { official: 'Sierra Leonen tasavalta', common: 'Sierra Leone' },
  },
  latlng: [8.5, -11.5],
  demonym: 'Sierra Leonean',
  landlocked: false,
  borders: ['GIN', 'LBR'],
  area: 71740,
  provinces: ['Eastern', 'Northern', 'Southern', 'Western'],
  nativeName: 'Sierra Leone',
  timezones: ['UTC'],
} as const;
