
import { defineComponent } from 'vue';
// import { BFormInput } from 'bootstrap-vue';

import FormDatepicker, {
  Props as FormDateProps,
} from '@client/components/Form/FormDatepicker.vue';

import { useFormField, FormFieldProps, PROP_OPTIONS } from './form-field';

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    FormFieldDate: ComponentWithProps<Props>;
  }
}

/**
 * `FormFieldInput` component properties.
 */
export interface Props extends FormFieldProps, FormDateProps {}

export default defineComponent({
  name: 'FormFieldDate',
  props: {
    ...PROP_OPTIONS,
  },
  setup: (props) => useFormField(props),
});
