import type { Role, AuthenticationMethod, User } from '@models';

import { openModal } from '../open-modal';

// export interface RemoveMFAOptions {
//   authenticationMethod: AuthenticationMethod;
// }

export interface EnrollMFAModalOptions {
  user: User;
}

/**
 * Enroll MFA Modal.
 *
 * @returns Modal promise.
 */
export async function enrollMFA(options: EnrollMFAModalOptions) {
  let result;

  try {
    result = await openModal<{ enrolled: boolean }>({
      title: 'Secure Your Account',
      props: { ...options },
      component: () => import('./MFA.vue'),
    });
  } catch {
    result = null;
  }

  return { enrolled: !!result?.enrolled };
}

export interface RemoveMFAOptions {
  authenticationMethod: AuthenticationMethod;
}

/**
 * Remove MFA Modal.
 *
 * @returns Modal promise.
 */
export async function removeMFA(options: RemoveMFAOptions) {
  let result;

  try {
    result = await openModal<{ mfaRemoved: boolean }>({
      title: 'REMOVE MFA',
      props: { ...options },
      component: () => import('./RemoveMFA.vue'),
    });
  } catch {
    result = null;
  }

  return { mfaRemoved: !!result?.mfaRemoved };
}

/**
 * Open Select Role modal.
 *
 * @returns Modal promise.
 */
export async function selectRole() {
  const modalOptions = {
    title: 'SELECT ROLE',
    component: () => import('./SelectRole.vue'),
  };

  try {
    return await openModal<Role['id']>(modalOptions);
  } catch {
    return null;
  }
}

/**
 * Open Generate Licenses modal.
 *
 * @returns Modal promise.
 */
export async function generateLicenses() {
  await openModal.safe({
    title: 'GENERATE LICENSES',
    component: () => import('./GenerateLicenses.vue'),
  });
}

/**
 * Open Idle Logout Warning modal.
 *
 * @return Modal promise.
 */
export async function idleLogoutWarning() {
  const modalOptions = {
    title: 'IDLE LOGOUT WARNING',
    component: () => import('./IdleLogoutWarning.vue'),
  };

  try {
    return await openModal<boolean>(modalOptions);
  } catch {
    return true;
  }
}

/**
 * Open Redeem Key modal.
 *
 * @returns Modal promise.
 */
export async function redeemKey() {
  await openModal.safe({
    title: 'REDEEM KEY',
    component: () => import('./RedeemKey.vue'),
  });
}

/**
 * Open Settings modal.
 *
 * @returns Modal promise.
 */
export async function settings() {
  await openModal.safe({
    title: 'SETTINGS',
    component: () => import('./SettingsV2.vue'),
    size: 'xl',
    // component: () => import('./Settings/index'),
  });

  // await openModal.safe({
  //   title: 'SETTINGS',
  //   size: 'xl',
  //   component: () => import('./SettingsV2.vue'),
  // });
}
