import { openModal } from '../open-modal';
import { useStore, store } from '@store';

/**
 * Open Create Product modal.
 *
 * @returns Modal promise.
 */
export async function createProduct() {
  await openModal.safe({
    title: 'CREATE PRODUCT',
    component: () => import('./CreateProduct.vue'),
  });
}

/**
 * ...
 */
export interface EditProductModalOptions {
  productId: string;
}

/**
 * Open Edit Product modal.
 *
 * @param options Modal options.
 * @returns Modal promise.
 */
export async function editProduct(options: EditProductModalOptions) {
  await openModal.safe({
    title: 'EDIT PRODUCT',
    props: { ...options },
    component: () => import('./EditProduct.vue'),
  });
}

/**
 * ...
 */
export interface ProductDetailsModalOptions {
  productId: string;
}

/**
 * Open Product Details modal.
 *
 * @param options Modal options.
 * @returns Modal promise.
 */
export async function productDetails(options: ProductDetailsModalOptions) {
  if (!useStore().state.shop.products[options.productId]) {
    await store.dispatch('shop/load');
  }

  await openModal.safe({
    title: 'PRODUCT DETAILS',
    size: 'lg',
    props: { ...options },
    component: () => import('./ProductDetails.vue'),
  });
}
