var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "table-cell-list-items" },
    _vm._l(_setup.items, function ({ key, item }) {
      return _c("div", { key: key }, [_vm._v("• " + _vm._s(item))])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }