export const MHL = {
  name: {
    common: 'Marshall Islands',
    official: 'Republic of the Marshall Islands',
    native: {
      eng: {
        official: 'Republic of the Marshall Islands',
        common: 'Marshall Islands',
      },
      mah: { official: 'Republic of the Marshall Islands', common: 'M̧ajeļ' },
    },
  },
  cities: ['Majuro'],
  tld: ['.mh'],
  cca2: 'MH',
  ccn3: '584',
  cca3: 'MHL',
  cioc: 'MHL',
  currency: ['USD'],
  callingCode: ['692'],
  capital: 'Majuro',
  altSpellings: [
    'MH',
    'Republic of the Marshall Islands',
    'Aolepān Aorōkin M̧ajeļ',
  ],
  region: 'Oceania',
  subregion: 'Micronesia',
  languages: { eng: 'English', mah: 'Marshallese' },
  translations: {
    deu: { official: 'Republik der Marshall-Inseln', common: 'Marshallinseln' },
    fra: { official: 'République des Îles Marshall', common: 'Îles Marshall' },
    hrv: { official: 'Republika Maršalovi Otoci', common: 'Maršalovi Otoci' },
    ita: {
      official: 'Repubblica delle Isole Marshall',
      common: 'Isole Marshall',
    },
    jpn: { official: 'マーシャル諸島共和国', common: 'マーシャル諸島' },
    nld: {
      official: 'Republiek van de Marshall-eilanden',
      common: 'Marshalleilanden',
    },
    por: { official: 'República das Ilhas Marshall', common: 'Ilhas Marshall' },
    rus: {
      official: 'Республика Маршалловы острова',
      common: 'Маршалловы Острова',
    },
    spa: {
      official: 'República de las Islas Marshall',
      common: 'Islas Marshall',
    },
    fin: {
      official: 'Marshallinsaarten tasavalta',
      common: 'Marshallinsaaret',
    },
  },
  latlng: [9, 168],
  demonym: 'Marshallese',
  landlocked: false,
  borders: [],
  area: 181,
} as const;
