export const PRT = {
  name: {
    common: 'Portugal',
    official: 'Portuguese Republic',
    native: { por: { official: 'República português', common: 'Portugal' } },
  },
  cities: [
    'Amarante',
    'Sanfins do Douro',
    'Vilarinho das Paranheiras',
    'Chaves',
    'Boticas',
    'Braganca',
    'Vila Real',
    'Miranda do Douro',
    'Carrazedo',
    'Vilarinho de Arcos',
    'Mirandela',
    'Macedo de Cavaleiros',
    'Vinhais',
    'Peso da RÃ©gua Municipality',
    'Lamego',
    'Tabuaco',
    'Penafiel',
    'Dalvares',
    'Santa Marta de Penaguiao',
    'Torre de Moncorvo',
    'Viana do Castelo',
    'Moncao',
    'Caminha',
    'Paredes de Coura',
    'Braga',
    'Ponte da Barca',
    'Vila Praia de Ancora',
    'Castelo',
    'Arcos de Valdevez',
    'PaÃ§os de Ferreira',
    'Maia',
    'Castelo de Paiva',
    'Santo Tirso',
    'Paredes',
    'Carvalhosa',
    'Porto',
    'Sobrado de Paiva',
    'Sobrosa',
    'Lousada',
    'Marco',
    'GuimarÃ£es',
    'Vila Nova de Gaia',
    'Olival',
    'Ponte de Lima',
    'Paredes da Beira',
    'Tarouca',
    'Mesao Frio',
    'Coimbra',
    'Mira',
    'Cernache',
    'Condeixa-a-Nova',
    'Alfarelos',
    'Soure Municipality',
    'Ceira',
    'Montemor-o-Velho',
    'Coimbra',
    'Granja do Ulmeiro',
    'Carapinheira',
    'Sao Joao do Campo',
    'Miranda do Corvo',
    'Viseu',
    'Arganil',
    'Tondela',
    'Castelejo',
    'Ãgueda Municipality',
    'Campia',
    'Nelas',
    'Satao',
    'Sao Vicente de Lafoes',
    'Parada de Gonta',
    'Mangualde',
    'Santa Comba Dao',
    'Mamouros',
    'Murtede',
    'Cantanhede',
    'Mortagua',
    'Oliveira do Bairro',
    'Mealhada',
    'Poiares',
    'Anadia',
    'Vilarinho do Bairro',
    'Ovar',
    'Oliveira de AzemÃ©is',
    'Esmoriz',
    'Sao Roque',
    'Cucujaes',
    'Feira',
    'Sao Joao da Madeira',
    'Arouca',
    'Vale de Cambra',
    'Santiago de Riba-Ul',
    'Aveiro',
    'Vagos',
    'Aguada de Cima',
    'Palhaca',
    'Estarreja',
    'Mourisca do Vouga',
    'Arrifana',
    'Sao Joao de Ver',
    'Romariz',
    'Sao Joao',
    'Vale',
    'Caldas de Sao Jorge',
    'Nogueira do Cravo',
    'Matosinhos Municipality',
    'Vieira de Leiria',
    'Porto',
    'Baguim do Monte',
    'Guarda',
    'Seia',
    'Gouveia',
    'Vilar Formoso',
    'Trancoso',
    'Lagarinhos',
    'Sao Vicente da Beira',
    'Silvares',
    'Castelo Branco',
    'Lisbon',
    'Covilha',
    'Fundao',
    'Paul',
    'Carvalho',
    'Valverde',
    'Serta',
    'SantarÃ©m',
    'Vale da Pinta',
    'Rio Maior',
    'Almeirim',
    'Ponte de Sor',
    'Vila Cha de Ourique',
    'Cartaxo',
    'Pinheiro de Azere',
    'LousÃ£',
    'Penacova',
    'Ferrel',
    'Caldas da Rainha',
    'Nadadouro',
    'Benedita',
    'Atouguia da Baleia',
    'Alcobaca',
    'NazarÃ©',
    'Peniche',
    'Obidos',
    'Casal da Areia',
    'Tomar',
    'Abrantes',
    'Entroncamento',
    'Minde',
    'Ourem',
    'Amiaes de Cima',
    'Constancia',
    'Alcanena',
    'Salvaterra de Magos',
    'Mafra',
    'Azueira',
    'Torres Vedras',
    'Ericeira',
    'Sobreiro',
    'Sobral de Monte Agraco',
    'Carregado',
    'Samora Correia',
    'Vila Franca de Xira',
    'Sacavem',
    'Alenquer',
    'Cercal',
    'Pombal',
    'Leiria',
    'Figueira da Foz Municipality',
    'Avelar',
    'Costa de Lavos',
    'Carrico',
    'Alvaiazere',
    'Marinha Grande',
    'Porto de Mos',
    'Calvaria de Cima',
    'Maceira',
    'Juncal',
    'Souto da Carpalhosa',
    'Pedreiras',
    'Garruchas',
    'Batalha',
    'Pataias',
    'Amor',
    'Martinganca',
    'Serpins',
    'Carvalhal',
    'Murteira',
    'Obidos',
    'Vila Nova da Barquinha',
    'Pontevel',
    'Praia do Ribatejo',
    'Torres Novas',
    'Aveiras de Cima',
    'Lavos',
    'Figueiro dos Vinhos',
    'Vermoil',
    'Graca',
    'Zambujal',
    'Regueira de Pontes',
    'Benavente',
    'Guia',
    'Gondomar',
    'Espinho',
    'Senhora da Hora',
    'Soito',
    'Vila Nova de Famalicao',
    'Riba de Ave',
    'Marinha',
    'Porto Alto',
    'Sao Romao do Coronado',
    'Portimao',
    'Cesar',
    'SetÃºbal',
    'Almada',
    'Massama',
    'Alcochete',
    'Felgueiras',
    'Vila do Conde',
    'Rio Tinto',
    'Mem Martins',
    'Oeiras',
    'Cacem',
    'Queluz',
    'Alfragide',
    'Amora',
    'Moita',
    'Pedroucos',
    'Vizela',
    'Barreiro',
    'Faro',
    'Bouca',
    'Barcelos',
    'Bombarral',
    'Celorico de Basto',
    'Angra do HeroÃ­smo',
    'Pinhal Novo',
    'Oliveira do Douro',
    'Silveira',
    'Moscavide',
    'Rio de Mouro',
    'Ferradosa',
    'Turcifal',
    'Prime',
    'Vizela',
    'Amadora',
    'Cacela',
    'Palmela',
    'Macieira da Lixa',
    'Ponta Delgada',
    'Funchal',
    'Estoril',
    'Beja',
    'Recarei',
    'Cascais',
    'Povoa de Lanhoso',
    'Almancil',
    'Amoreira',
    'Bucelas',
    'Albufeira',
    'Quejas',
    'PÃ³voa de Varzim',
    'Odivelas',
    'Valongo',
    'Caparica',
    'Loures',
    'Gafanha da Nazare',
    'Amares',
    'Carnaxide',
    'Colmeias',
    'Fafe',
    'Povoa de Santa Iria',
    'Caxias',
    'Vila Nova',
    'Gandra',
    'Leca da Palmeira',
    'Acores',
    'Varzea do Douro',
    'Vale',
    'Moure',
    'Roriz',
    'Vialonga',
    'Seixal',
    'Paco de Arcos',
    'Alcabideche',
    'Alges',
    'Sintra',
    'Monte Estoril',
    'Galiza',
    'LourinhÃ£',
    'Seixal',
    'Olivais',
    'Alhos Vedros',
    'Montijo',
    'Loule',
    'Tavira Municipality',
    'Sao Bras',
    'Quarteira',
    'Tercena',
    'Agualva',
    'Pevidem',
    'Rana',
    'Praia do Almoxarife',
    'Parede',
    'Sao Pedro do Estoril',
    'Sao Domingos de Rana',
    'Famoes',
    'Costa de Caparica',
    'Lagos',
    'Lagoa',
    'Parchal',
    'Olhao',
    'Cavaleiros',
    'Santa Iria da Azoia',
    'Quinta',
    'Marinhais',
    'Boliqueime',
    'Palhais',
    'Sobralinho',
    'Samouco',
    'Porto Salvo',
    'Frielas',
    'Linda a Velha',
    'Portela',
    'Alhandra',
    'Santo Antao do Tojal',
    'Carcavelos',
    'Silves',
    'Santo Antonio',
    'Vila Nogueira de Azeitao',
    'Castanheira do Ribatejo',
    'Porto da Cruz',
    'Santo Antonio da Charneca',
    'Pinhal',
    'Sarilhos Grandes',
    'Pinheiro',
    'Sesimbra',
    'Trancoso de Baixo',
    'Lousa',
    'Alverca',
    'Montemor-o-Novo',
    'Evora',
    'Alandroal',
    'Redondo',
    'Sao Juliao do Tojal',
    'Torre da Marinha',
    'Ramada',
    'Elvas',
    'Dona Maria',
    'Alter do Chao',
    'Manique de Baixo',
    'Lagoa',
    'Colares',
    'Charneca',
    'Venda do Pinheiro',
    'Ourique',
    'Estremoz',
    'Sao Joao dos Montes',
    'Milharado',
    'Pontinha',
    'Sao Bras de Alportel',
    'Alportel',
    'Almodovar',
    'Alfornelos',
    'Rinchoa',
    'Aldeia de Paio Pires',
    'Barcarena',
    'Caldas de Vizela',
    'Aguiar da Beira Municipality',
    'Anta',
    'Sao Mamede de Infesta',
    'Lourical',
    'Runa',
    'Penha Garcia',
    'Atalaia',
    'Zibreira',
    'Reguengo Grande',
    'Ilhavo',
    'Madeira',
    'Alcains',
    'Chao de Couce',
    'Vila Franca do Rosario',
    'Golega',
    'Arruda dos Vinhos',
    'Alpiarca',
    'Gandara dos Olivais',
    'Ponte de Vagos',
    'Boavista',
    'Lago',
    'CÃ¢mara de Lobos',
    'Vila Nova',
    'Cabecudos',
    'Felgueiras',
    'Povoa do Forno',
    'Canical',
    'Canico',
    'Santa Cruz',
    'Oliveira',
    'Cortegaca',
    'Pragal',
    'Corroios',
    'Ferro',
    'Santo Andre',
    'Baleia',
    'Sanguedo',
    'Arraiolos',
    'Vendas Novas',
    'Vidigueira',
    'Sao Francisco',
    'Carvoeiro',
    'Pardilho',
    'Valado de Frades',
    'Assumar',
    'Valega',
    'Argoncilhe',
    'Mozelos',
    'Belmonte',
    'Roque',
    'Portel',
    'Canedo',
    'Silva',
    'Portalegre',
    'Oliveira do Hospital',
    'Sabugal',
    'Quinta do Anjo',
    'Gulpilhares',
    'Lordelo',
    'Queluz de Baixo',
    'Malveira',
    'Taveiro',
    'Linho',
    'Damaia',
    'Cruz Quebrada',
    'Canecas',
    'Lavradio',
    'Vilar do Pinheiro',
    'Machico',
    'Estombar',
    'Monchique',
    'Mexilhoeira Grande',
    'Castro Verde Municipality',
    'Estoi',
    'Poceirao',
    'Pegoes Velhos',
    'Santa Barbara de Nexe',
    'Pero Pinheiro',
    'Almargem',
    'Bicesse',
    'Campo Maior',
    'Praia do Carvoeiro',
    'Algoz',
    'Benafim',
    'Pechao',
    'Santana',
    'Casal',
    'Cano',
    'Armacao de Pera',
    'Castro Marim',
    'Altura',
    'Alvarinhos',
    'Ponta do Sol',
    'Porto Moniz',
    'Tires',
    'Lajes',
    'Igreja Nova',
    'Abobada',
    'Cabeco de Vide',
    'Cacilhas',
    'Vila Alva',
    'Tunes',
    'Santa Clara-a-Velha',
    'Cabrela',
    'Nisa',
    'Arronches',
    'Olhos de Agua',
    'Ribeira Grande',
    'Ermidas',
    'Santiago do Cacem',
    'Sines Municipality',
    'Serpa',
    'Aljustrel',
    'Vila Nova',
    'Cuba',
    'Grandola',
    'Santiago',
    'Campanario',
    'Vila de Porto Santo',
    'Viana do Alentejo',
    'Monsaraz',
    'Penteado',
    'Sobreda',
    'Praia da Vitoria',
    'Reguengos de Monsaraz',
    'Sao Joao das Lampas',
    'Paderne',
    'Montelavar',
    'Canhas',
    'Arco da Calheta',
    'Monforte',
    'Vila Real de Santo AntÃ³nio',
    'Porto',
    'Lourel de Baixo',
    'Souzel',
    'Bobadela',
    'Cidade',
    'Algueirao',
    'Belas',
    'Moura',
    'Moura',
    'Outeiro',
    'Campinho',
    'Vimieiro',
    'Fogueteiro',
    'Odemira',
    'Escoural',
    'Povoa da Galega',
    'Buraca',
    'Borba',
    'Aljezur',
    'Poco Barreto',
    'Canedo',
    'Vila Verde',
    'Trofa',
    'Canelas',
    'Marco de Canaveses',
    'Santa Marta',
    'Felgueiras',
    'Vermelha',
    'Vidago',
    'Travanca',
    'Grijo',
    'Lavra',
    'Alfena',
    'Azurem',
    'Creixomil',
    'Godim',
    'Escapaes',
    'Leca do Bailio',
    'Guimaraes',
    'Brito',
    'Porto',
    'Esposende',
    'Fao',
    'Joane',
    'Vilela',
    'Pedroso',
    'Granja',
    'Vila Cha',
    'Muro',
    'Sendim',
    'Paredes',
    'Moreira de Conegos',
    'Lousado',
    'Forte',
    'Vila Nova',
    'Massarelos',
    'Sao Marcos',
    'Manique do Intendente',
    'Sardoal',
    'Bico',
    'Trafaria',
    'Malveira da Serra',
    'Fanzeres',
    'Bemfica',
    'Sobrado',
    'Sao Felix da Marinha',
    'Sul',
    'Praia de Mira',
    'Sangalhos',
    'Arcozelo',
    'Infesta',
    'Baguim',
    'Caldas das Taipas',
    'Aldeia dos Fernandes',
    'Penela',
    'Povoa de Santo Adriao',
    'Freamunde',
    'Camarate',
    'Talaide',
    'Polima',
    'Sao Joao da Talha',
    'Entroncamento',
    'Riachos',
    'FÃ¡tima',
    'Mira',
    'Azoia',
    'Alcoentre',
    'Foz do Sousa',
    'Valbom',
    'Rio de Moinhos',
    'Milhazes',
    'Santa Catarina',
    'Proenca-a-Nova',
    'Teixoso',
    'Canidelo',
    'Santiago',
    'Piedade',
    'Vilar de Perdizes',
    'Montes',
    'Cinfaes',
    'Seroa',
    'Vila Boa de Quires',
    'Touguinha',
    'Fradelos',
    'Ribeirao',
    'Ferreira',
    'Cabeceiras de Basto',
    'Alcanede',
    'Vale de Acor',
    'Gradil',
    'Sao Pedro da Cadeira',
    'Encarnacao',
    'Folgados',
    'Linhaceira',
    'Igreja Nova',
    'Santa Catarina da Serra',
    'Figueira de Lorvao',
    'Vila Nova de Cerveira',
    'Valenca',
    'Mocarria',
    'Constance',
    'Albergaria-a-Velha',
    'Oia',
    'Monsanto',
    'Neiva',
    'Amorim',
    'Agucadoura',
    'Povoa',
    'Povoa',
    'Nine',
    'Macas de Caminho',
    'Dossaos',
    'Modivas',
    'Rebordosa',
    'Ota',
    'Margaride',
    'BaiÃ£o',
    'Nespereira',
    'Lourosa',
    'Paraiso',
    'Avintes',
    'Prazins',
    'Basto',
    'Salto',
    'Ronfe',
    'Mesao Frio',
    'Guardizela',
    'Gemunde',
    'Jovim',
    'Prado',
    'Alvelos',
    'Tamel',
    'Santiago da Guarda',
    'Olivais',
    'Landim',
    'Vila Nova de Paiva',
    'Vieira do Minho',
    'Montalvo',
    'Marteleira',
    'Barrosas',
    'Avanca',
    'Darque',
    'Pinheiro da Bemposta',
    'Nogueira',
    'Soalheira',
    'Tocha',
    'Sandim',
    'Cadaval',
    'Parada do Bispo',
    'Sever do Vouga',
    'Calvao',
    'Campo',
    'Cavaloes',
    'Avidos',
    'Quinchaes',
    'Negrelos',
    'Lagoa',
    'Vilar do Paraiso',
    'Ponte',
    'AlijÃ³ Municipality',
    'Arazede',
    'Ponta',
    'Arvore',
    'Dois Portos',
    'Samil',
    'Vale',
    'Beira',
    'Ramo',
    'Caramulo',
    'Terena',
    'Alqueidao da Serra',
    'Alcacer do Sal',
    'Vila do Bispo',
    'Ortiga',
    'Sete Rios',
    'Pereira',
    'Cabanelas',
    'Montes',
    'Telhado',
    'Real',
    'Apulia',
    'Baltar',
    'Palmeira',
    'Quinta',
    'Lagoas',
    'Ribeira Brava',
    'Castro Daire',
    'Catraia de Sao Paio',
    'Salreu',
    'Aldeia do Meio',
    'Ferreira do Alentejo',
    'Tourais',
    'Eixo',
    'Penedono',
    'Nogueira da Regedoura',
    'Fiaes',
    'Maceda',
    'Caldas',
    'Treixedo',
  ],
  tld: ['.pt'],
  cca2: 'PT',
  ccn3: '620',
  cca3: 'PRT',
  cioc: 'POR',
  currency: ['EUR'],
  callingCode: ['351'],
  capital: 'Lisbon',
  altSpellings: [
    'PT',
    'Portuguesa',
    'Portuguese Republic',
    'República Portuguesa',
  ],
  region: 'Europe',
  subregion: 'Southern Europe',
  languages: { por: 'Portuguese' },
  translations: {
    deu: { official: 'Portugiesische Republik', common: 'Portugal' },
    fra: { official: 'République portugaise', common: 'Portugal' },
    hrv: { official: 'Portugalska Republika', common: 'Portugal' },
    ita: { official: 'Repubblica portoghese', common: 'Portogallo' },
    jpn: { official: 'ポルトガル共和国', common: 'ポルトガル' },
    nld: { official: 'Portugese Republiek', common: 'Portugal' },
    por: { official: 'República português', common: 'Portugal' },
    rus: { official: 'Португальская Республика', common: 'Португалия' },
    spa: { official: 'República Portuguesa', common: 'Portugal' },
    fin: { official: 'Portugalin tasavalta', common: 'Portugali' },
  },
  latlng: [39.5, -8],
  demonym: 'Portuguese',
  landlocked: false,
  borders: ['ESP'],
  area: 92090,
  provinces: [
    'Acores (Azores)',
    'Aveiro',
    'Beja',
    'Braga',
    'Braganca',
    'Castelo Branco',
    'Coimbra',
    'Evora',
    'Faro',
    'Guarda',
    'Leiria',
    'Lisboa',
    'Madeira',
    'Portalegre',
    'Porto',
    'Santarem',
    'Setubal',
    'Viana do Castelo',
    'Vila Real',
    'Viseu',
  ],
  nativeName: 'Portugal',
  timezones: ['UTC−01:00', 'UTC'],
} as const;
