export const GUM = {
  name: {
    common: 'Guam',
    official: 'Guam',
    native: {
      cha: { official: 'Guåhån', common: 'Guåhån' },
      eng: { official: 'Guam', common: 'Guam' },
      spa: { official: 'Guam', common: 'Guam' },
    },
  },
  cities: [
    'Santa Rita',
    'HagÃ¥tÃ±a',
    'Barrigada Village',
    'Tamuning-Tumon-Harmon Village',
    'Yigo Village',
    'Inarajan Village',
    'Dededo Village',
  ],
  tld: ['.gu'],
  cca2: 'GU',
  ccn3: '316',
  cca3: 'GUM',
  cioc: 'GUM',
  currency: ['USD'],
  callingCode: ['1671'],
  capital: 'Hagåtña',
  altSpellings: ['GU', 'Guåhån'],
  region: 'Oceania',
  subregion: 'Micronesia',
  languages: { cha: 'Chamorro', eng: 'English', spa: 'Spanish' },
  translations: {
    deu: { official: 'Guam', common: 'Guam' },
    fra: { official: 'Guam', common: 'Guam' },
    hrv: { official: 'Guam', common: 'Guam' },
    ita: { official: 'Guam', common: 'Guam' },
    jpn: { official: 'グアム', common: 'グアム' },
    nld: { official: 'Guam', common: 'Guam' },
    por: { official: 'Guam', common: 'Guam' },
    rus: { official: 'Гуам', common: 'Гуам' },
    spa: { official: 'Guam', common: 'Guam' },
    fin: { official: 'Guam', common: 'Guam' },
  },
  latlng: [13.46666666, 144.78333333],
  demonym: 'Guamanian',
  landlocked: false,
  borders: [],
  area: 549,
} as const;
